import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormRecepcionVerSegRecepcionComponent } from '../form-recepcion-ver-seg-recepcion/form-recepcion-ver-seg-recepcion.component';
import {MatPaginator} from '@angular/material/paginator';
import { element, error } from 'protractor';
import { filter } from '../form-pedido-frecuencial/form-pedido-frecuencial.component';

@Component({
  selector: 'app-form-recepcion-ver-productos-recepcion',
  templateUrl: './form-recepcion-ver-productos-recepcion.component.html',
  styleUrls: ['./form-recepcion-ver-productos-recepcion.component.css']
})

export class FormRecepcionVerProductosRecepcionComponent implements OnInit {

  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  productos:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`
  ficha_proveedor: any;
  iva_proveedor: any;
  Subtotal:any = 0;
  SubtotalShow:any = 0;
  iva:any = 0 ;
  ret_iva:any = 0;
  ret_ivaShow:any = 0;
  Total:any = 0;
  TotalShow:any = 0;
  flete:any = 0;
  moneda:any;
  subtotalIva: any = 0;
  subtotalIvaShow: any = 0;
  DATA:any;
  reten_prov:any = '0';


  dataSource=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormRecepcionVerProductosRecepcionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {

      console.log(this.data,'la data 123')

      this.mostrarTabla()

    }
  
  
  
    mostrarTabla(){
  
      this.servicio.verRecepcionhttp(this.data.orden.cod_orden_compra).subscribe(response => {
        
  
        this.ELEMENT_DATA = response.consulta2;
  
        console.log(response);
  
        this.displayedColumns = [
            "cod_orden_req",
            "cod_cotizacion",
            "cod_producto",
            "des_producto",
            "cant_solicitada",
            "cant_recibido",
            "cant_restante",
            "tipo_unidad_medida",
            'accion'
        ];
  
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  
      },
      error =>{
  
        
        this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              "cod_orden_req",
              "cod_cotizacion",
              "cod_producto",
              "des_producto",
              "tipo_unidad_medida",
              "cant_solicitada",
              "cant_recibido",
              "cant_restante",
              'accion'
          ];
  
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
  
      })
  
    }

    verSegPrducto(element:any){

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
  
      setTimeout(() => {

        this.dialogSpin.close()
  
        var dialogRef;
        dialogRef = this.dialog.open(FormRecepcionVerSegRecepcionComponent,     {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data:element,
        disableClose:   true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });
  
        dialogRef.afterClosed().subscribe(resul => {
  
          if(resul != 'cerrar'){
  
            console.log('Se cerro la wea')
            
  
          }
  
       
      })
        
      }, 500);
  
       
     
    }
  
    cerrarmodal(): void {
      this.accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
               accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar') {
          this.cerrar();
        }
      });
    } 
  
    cerrar():void{
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
  
          this.dialogSpin.close();
          this.dialogRefp.close('cerrar');
        }, 1000);
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
}
