import { Injectable } from '@angular/core';
import { FormSpinnerComponent } from '../modal/form-spinner/form-spinner.component';
import { MatDialog } from '@angular/material/dialog';
import { FormVersionComponent } from '../modal/form-version/form-version.component';
import { HashKeyServices } from './app.hashkey.services';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { AtributosService } from './atributos.service';

@Injectable({
  providedIn: 'root'
})
export class AppUtilService {
  dialogSpin:any;
  tiempo:any;
  value:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    public dialog: MatDialog, 
    private hashkey:HashKeyServices,
    private snackBar: MatSnackBar,
    private atrb: AtributosService,
  ) { }

  async abrirSpinner(){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '30vw',
      height:'25vh',
      data: {},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
  }
  async cerrarSpinner(){
      this.dialogSpin.close();
  }
  agregarLocalStorage(response:any){
    try {
                this.tiempo = JSON.stringify((1000000000) + new Date().getTime())
                let key = 'Nombre';
                let key1 = 'cod_usuario';
                let key2 = 'Apellido';
                let key3 = 'Tiempo';
                let key4 = 'Unidad';

                let key5 = 'leer';
                let key6 = 'crear';
                let key7 = 'editar';
                let key8 = 'borrar';
                let key9 = 'especial';

                let key10 = 'cod_sesion';
                let key11 = 'dir_ip';
                let key12 = 'sesion_unica';
                let key13 = 'rlsr';
                let key14 = 'mprs';
                let key15 = 'lcnc';
                let key16 = 'prfl';
                let key17 = 'dpto';
                let key18 = 'app';

                /* let key5 = 'rol'; */
                let value = response[0][0];
                let accion = response[3];
                let rol = response[2][0]
                let cod_sesion =  this.hashkey.encrypt(response[4])+'//////'+this.hashkey.encrypt(response[7][0].cod_version);
                let dir_ip = response[5];
                let version = response[7][0].cod_version;
                let sesion_unica =  this.hashkey.encrypt(response[6]);
                let rlsr = this.hashkey.encrypt(value.cod_rol);
                let lcnc = this.hashkey.encrypt(value.mod_usuario);
                let mprs;
                let prfl = this.hashkey.encrypt(value.cod_perfil);
                let dpto = this.hashkey.encrypt(value.des_dpto);
                let app = this.hashkey.encrypt(this.atrb.APP);
                if (parseInt(this.hashkey.decrypt(rlsr)) === parseInt(this.atrb.CEO)  ) {
                  mprs = this.hashkey.encrypt(value.cod_usuario);
                }
                if (parseInt(this.hashkey.decrypt(rlsr)) === parseInt(this.atrb.ADMINISTRADOR)  ) {
                  mprs = this.hashkey.encrypt(value.cod_usuario);
                }
                if (parseInt(this.hashkey.decrypt(rlsr)) === parseInt(this.atrb.EMPRESA)  ) {
                  mprs = this.hashkey.encrypt(value.cod_usuario);
                }
                if (parseInt(this.hashkey.decrypt(rlsr)) === parseInt(this.atrb.USUARIO)  ) {
                  mprs = this.hashkey.encrypt(value.cod_empresa);
                }

            this.value = JSON.stringify(value);
            let pass = this.hashkey.encrypt(value.cod_usuario);
            let des_pass = this.hashkey.decrypt(pass);
            sessionStorage.setItem(key12,sesion_unica);

            localStorage.setItem(key,  this.hashkey.encrypt(value.nombre));
            localStorage.setItem(key2,  this.hashkey.encrypt(value.apellido));
            localStorage.setItem(key1, this.hashkey.encrypt(value.cod_usuario));
            localStorage.setItem(key3, this.tiempo);
            localStorage.setItem(key4,  this.hashkey.encrypt(value.des_unidad));
            localStorage.setItem(key10, cod_sesion);
            localStorage.setItem(key11, dir_ip);
            localStorage.setItem(key13, rlsr);
            localStorage.setItem(key14, mprs);
            localStorage.setItem(key15, lcnc);
            localStorage.setItem(key16, prfl);
            localStorage.setItem(key17, dpto);
            localStorage.setItem(key18, app);
    } catch (error) {
      
    }
  }
  quitarLocalStorage(){
    try {
              localStorage.removeItem('Nombre');
              localStorage.removeItem('cod_usuario');
              localStorage.removeItem('Apellido');
              localStorage.removeItem('Tiempo');
              localStorage.removeItem('Unidad');
              localStorage.removeItem('rol');
              localStorage.removeItem('permiso');
              localStorage.removeItem('cod_sub');
              localStorage.removeItem('sub_main');
              localStorage.removeItem('cod_valid');
              localStorage.removeItem('accion_1');
              localStorage.removeItem('accion_2');
              localStorage.removeItem('accion_3');
              localStorage.removeItem('accion_4');
              localStorage.removeItem('accion_5');
              localStorage.removeItem('cod_sesion');
              localStorage.removeItem('dir_ip');
              localStorage.removeItem('rlsr');
              localStorage.removeItem('mprs');
              localStorage.removeItem('lcnc');
              localStorage.removeItem('prfl');
              localStorage.removeItem('dpto');
              sessionStorage.removeItem('sesion_unica');
              localStorage.removeItem('app');
    } catch (error) {
      
    }
  }
  async version_incorrecta(){
    await this.abrirSpinner();
    await this.quitarLocalStorage();
    var dialogRef = this.dialog.open(FormVersionComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {},
      disableClose: true,
      panelClass:'pedidos_modal_login'
    });
    await this.cerrarSpinner(); 
  }
  async notificacionErrorApp(xvalor:any){
    this.snackBar.open(xvalor, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
    /* if (xvalor.resul.toLowerCase() === 'data') {
      this.snackBar.open(xvalor.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    if (xvalor.resul.toLowerCase() === 'sesion') {
      this.snackBar.open(xvalor.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    if (xvalor.resul.toLowerCase() === 'unica') {
      this.snackBar.open(xvalor.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    if (xvalor.resul.toLowerCase() === 'version') {
        await this.version_incorrecta();
    }
    if (xvalor.resul.toLowerCase() === 'rol') {
      this.snackBar.open(xvalor.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    } */
  }
  async remplazo_coma_punto(xvalor:any){
    try {
      return xvalor.toString().replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.');
    } catch (error) {
      console.log(error);
    }
  }
  async remplazo_especial(xvalor:any){
    try {
      return xvalor.replace(',',':');
    } catch (error) {
      console.log(error);
    }
  }
  remplazo_especial_restaurar(xvalor:any){
    try {
      return xvalor.replace(':',',');
    } catch (error) {
      console.log(error);
    }
  }
  remplazo_especial_ruta_img(xvalor:any){
    try {
        let val =  xvalor.split('\\');
        let ruta_nueva;
        if (val.length === 3) {
          ruta_nueva = val[0]+'\\\\'+val[1]+'\\\\'+val[2];
        }
        if (val.length === 5) {
          ruta_nueva = xvalor;
        }
        if (val.length === 1) {
          ruta_nueva = xvalor;
        }
      return ruta_nueva;
    } catch (error) {
      console.log(error);
    }
  }
  async agregar_coma_punto(xvalor:any){
    try {
      var identificar = xvalor.toString().split('.');
      if (identificar.length >= 2) {
        if (identificar[1].length < 2) {
          let comp = xvalor+'0';
          return comp.toString().replace(/\D/g, "")
                                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        }else{
          return xvalor.toString().replace(/\D/g, "")
                                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        }
      }else{
        
        return parseFloat(xvalor).toFixed(2).toString().replace(/\D/g, "")
                                           .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                           .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async agregar_coma_punto_und(xvalor:any){
    try {
      var identificar = xvalor.toString().split('.');
      if (identificar.length >= 2) {
        if (identificar[1].length < 2) {
          let comp = xvalor+'0';
          return comp.toString().replace(/\D/g, "")
                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        }else{
          return xvalor.toString().replace(/\D/g, "")
                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        }
      }else{
        
        return parseFloat(xvalor).toFixed(2).toString().replace(/\D/g, "")
                                           .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async numeroFlotante(num:any) {
    try {
      var identificar = num.toString().split('.');
      if (identificar.length >= 2) {
        let string = num.toString();
        return Number(string.substring(0, string.indexOf('.') + 3));
      }else{
        return num.toFixed(2).toString().replace(/\D/g, "")
                            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      }
    } catch (error) {
      console.log(error);
    }
  }
  remplazo_dinamico(xvalor:any,val_cat){
    try {
      
        let patron = new RegExp(Object.keys(xvalor).map(key => {  
                            return key.replace(/[-\/\\^$.*+?()[\]{}|]/g, '\\$&'); // Escapar caracteres especiales  
                        }).join('|'), 'g');
                        let resultado;
                        let arry_valcat = [];
                        for (const i in val_cat) {
                            resultado = val_cat[i].replace(patron, (coincidencia) => {  
                              return xvalor[coincidencia];  
                          } );
                          arry_valcat.push(resultado);
                        }
      return arry_valcat;
    } catch (error) {
      console.log(error);
    }
  }
}
