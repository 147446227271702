import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'puntoporcoma'
})
export class PuntoporcomaPipe implements PipeTransform {
  transform(num: any): any {
    if(!num){
      if(num == 0){
        return 0
      }
      return null
    }
    if(typeof num == 'string'){
      let num2
      if(num.includes('.') || num.includes(',') ){
        let valid1 = num.split(',')
        let valid2 = num.split('.')
        if(valid1.length == 2 && valid1[valid1.length-1].length > 2){
          return num;
        }
        if(valid1.length == 2 && valid1[valid1.length-1].includes('.') ){ 
          return num.replace('.','/').replace(/,/g,'.').replace('/',',')
        }
        if(valid1.length == 2 && !valid1[valid1.length-1].includes('.') ){
           return num.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        }
        if(valid2.length == 2 && valid2[valid2.length-1].length > 2){
          return num.replace('.','/').replace(/,/g,'.').replace('/',',') 
        }
        if(valid2.length == 2 && !valid2[valid2.length-1].includes('.')){
          return num.replace('.','/').replace(/,/g,'.').replace('/',',').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        }
      }else{
        num2 = Number(num).toFixed(2)
         .replace(/\D/g, "")
         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      }
      return num2;
    }else if(typeof num == 'number'){
     let num2 = num.toFixed(2)
         .replace(/\D/g, "")
         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      return num2;
    }else{
      return `tipo de dato no soportado ${typeof num}`
    }
  }  
}
