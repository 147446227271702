
<div id="login_margin" class="container ">
    <div class="row  d-flex justify-content-center h-100">
        <div class="card col-md-12 h-100 p-0">
            <div class="card-header w-100">
                <h3 class=" text-center">Iniciar sesión</h3>
            </div>
            <div class="card-body">
                <form [formGroup]="forma" (ngSubmit)="login('actualizar')">
                    <div class="col-auto py-1">
                        <label class="sr-only" for="inlineFormInputGroup1">Usuario</label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">
                                <mat-icon color="primary">account_circle</mat-icon>
                            </div>
                          </div>
                          <input type="text" class="form-control" id="inlineFormInputGroup1" formControlName="nom_usuario" placeholder="Usuario">
                        </div>
                      </div>
                    <div class="col-auto py-1">
                        <label class="sr-only" for="inlineFormInputGroup2">Contraseña</label>
                        <div class="input-group mb-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text" style="cursor: pointer;" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon color="primary">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </div>
                          </div>
                          <input [type]="hide ? 'password' : 'text'" class="form-control" id="inlineFormInputGroup2" formControlName="pass_usuario" placeholder="Contraseña">
                        </div>
                      </div>
                    <div class="col-auto py-1">
                        <button type="submit" [disabled]="forma.invalid" class=" form-control btn text-white bg-primary" >Ingresar</button>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center py-2">
                        <div (click)="recuperarPass()" class="text-white" style="cursor: pointer;"> <b> Olvidaste tu contraseña?</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
