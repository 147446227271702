import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary, forIn } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { FormNotaCreditoAdminSingleComponent } from '../form-nota-credito-admin-single/form-nota-credito-admin-single.component';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';


import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';

import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { FormNotaCreditoComponent } from 'src/app/componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormNotaCreditoAdminComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import { PdfServices } from '../../servicios/app.pdf.services';

interface interfacefactura{
  lote_producto: string;
  cod_producto:string;
  des_producto:string;
  cant_producto:string;
  costo_producto:string;
  subtotal_producto:string;
}
interface interfacefactura2{
  des_concepto: string;
  subtotal_concepto:string;

}
interface interdatosfacefactura{
cod_factura:string;
des_cliente: string;
cod_cliente:string;
des_vendedor:string;
fecha_e_factura:string;
fecha_v_factura:string;
cod_pedido:string;
telefono1_cliente:string;
telefono2_cliente:string;
}
type TableRow = [string, string, string, string, string, string];

type TableRow2 = [string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-form-notacredito-notas',
  templateUrl: './form-notacredito-notas.component.html',
  styleUrls: ['./form-notacredito-notas.component.css']
})
export class FormNotacreditoNotasComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_cliente',{static:true}) cod_cliente:ElementRef;
  @ViewChild('des_cliente',{static:true}) des_cliente:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;

obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicio:nkServices,
    private formb:FormBuilder,
    private fb:FormBuilder,
    private pdf : PdfServices,
    private dialogRefp: MatDialogRef<FormNotacreditoNotasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

    }

    ngOnInit(): void {
      this.listar_notas_credito(null);
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource.paginator);
    }
  listar_notas_credito(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listarNotasCreditoNotashttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.respuesta) {
  
                    this.ELEMENT_DATA.push({
                      cod_factura:response.respuesta[i].cod_factura,
                      cod_nota_credito: response.respuesta[i].cod_nota_credito,
                      cod_lista_precio:response.respuesta[i].cod_lista_precio,
                      cod_cliente:response.respuesta[i].cod_cliente,
                      des_cliente:response.respuesta[i].des_cliente,
                      cod_usuario:response.respuesta[i].cod_usuario,
                      des_usuario:response.respuesta[i].des_usuario,
                      fecha_inicio:response.respuesta[i].fecha_inicio,
                      hora_inicio:response.respuesta[i].hora_inicio,
                      estatus:response.respuesta[i].estatus,
                      tipo_nota: response.respuesta[i].tipo_nota_credito,
                      subtotal_dev_costo: response.respuesta[i].subtotal_dev_costo,
                      total_dev_costo:response.respuesta[i].total_dev_costo,
                      iva_dev:response.respuesta[i].iva_dev,
                      iva_dev_monto:response.respuesta[i].iva_dev_monto,
                      desc_dev:response.respuesta[i].desc_dev,
                      desc_dev_monto: response.respuesta[i].desc_dev_monto,
                      iva_dev_exento:response.respuesta[i].iva_dev_exento,
                      dev_monto_exento:response.respuesta[i].dev_monto_exento,
                      accion:'',
                      accion2:'',
                      accion3:'',
                      accion4:''
                    });
                  }
                  this.displayedColumns = [
                                          'cod_factura',
                                          'cod_nota_credito',
                                          'cod_lista_precio',
                                          'cod_cliente',
                                          'des_cliente',
                                          
                                          'estatus',
                                          'tipo_nota',
                                          'fecha_inicio',
                                          'hora_inicio',
                                          'imprimir'];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                      'cod_factura',
                                      'cod_nota_credito',
                                      'cod_lista_precio',
                                      'cod_cliente',
                                      'des_cliente',
                                     
                                      'estatus',
                                      'tipo_nota',
                                      'fecha_inicio',
                                      'hora_inicio',
                                      'imprimir'];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }

  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
}
imprimirnota(xvalor:any){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        const pdf = new PdfMakeWrapper();
        var valor = {cod_factura:xvalor.cod_factura, cod_nota_credito:xvalor.cod_nota_credito,tipo_nota:xvalor.tipo_nota}
        this.servicio.listarNotaCreditohttp(valor).subscribe(
         async response  =>{
            this.dialogSpin.close();
            
            switch (xvalor.tipo_nota) {
              case 'ntntrg':
                          response.pdf4 = response.pdf2;
                          response.pdf6 = response.pdf7;
                          response.pdf2[0].comentarios = '';
                          
                          response.pdf4[0].subtotal_costo_pedido = response.pdf4[0].subtotal_dev_costo;
                          response.pdf4[0].desc_pedido = response.pdf4[0].desc_dev;
                          response.pdf4[0].desc_pedido_monto = response.pdf4[0].desc_dev_monto;
                          response.pdf4[0].monto_exento = response.pdf4[0].dev_monto_exento;
                          response.pdf4[0].iva_pedido = response.pdf4[0].iva_dev;
                          response.pdf4[0].iva_monto = response.pdf4[0].iva_dev_monto;
                          response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;

                          response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;

                          response.pdf4[0].tipo_pedido = response.pdf2[0].tipo_nota_credito;
                          console.log(response);
                          this.pdf.pdfVentas(response,'dd');
               
                break;
                case 'adminnt':
                          response.pdf4 = response.pdf2;
                          response.pdf6 = response.pdf7;
                          response.pdf2[0].comentarios = '';
                          
                          response.pdf4[0].subtotal_costo_pedido = response.pdf4[0].subtotal_dev_costo;
                          response.pdf4[0].desc_pedido = response.pdf4[0].desc_dev;
                          response.pdf4[0].desc_pedido_monto = response.pdf4[0].desc_dev_monto;
                          response.pdf4[0].monto_exento = response.pdf4[0].dev_monto_exento;
                          response.pdf4[0].iva_pedido = response.pdf4[0].iva_dev;
                          response.pdf4[0].iva_monto = response.pdf4[0].iva_dev_monto;
                          response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;

                          response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;

                          response.pdf4[0].tipo_pedido = response.pdf2[0].tipo_nota_credito;

                          response.pdf[0].cod_producto = 'N/A';
                     response.pdf[0].des_producto = response.pdf[0].des_concepto;
                     response.pdf[0].cant_producto = '1';
                     response.pdf[0].costo_producto = '0,00';
                     response.pdf[0].desc_prod = '0,00';
                     response.pdf[0].desc_prod_monto = '0,00';
                     response.pdf[0].subtotal_producto = response.pdf[0].subtotal_concepto ;
                          console.log(response);
                          this.pdf.pdfVentas(response,'dd');
               
                break;
              default:
                break;
            }
          },
          error =>{
            this.dialogSpin.close();
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
       }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error ,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }

}
