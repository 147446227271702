import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { nkServices } from '../servicios/app.nkservicios.services';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../modal/form-spinner/form-spinner.component';
import { MatDialog } from '@angular/material/dialog';
import { FormmMsgComponent } from '../modal/formm-msg/formm-msg.component';
import { HashKeyServices } from '../servicios/app.hashkey.services';
import { AppUtilService } from '../servicios/app.util.service';
import { AtributosService } from '../servicios/atributos.service';
import { ToastNotificationService } from '../servicios/toast-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
sesion:any;
menu:any=[];
menu2:any = [];
menu3:any = [];
menu4:any = [];
unidad:any;
dialogSpin:any;
accion:any;
usuario:any;
menuorder3:any;
menuoreder4:any;
local:any;
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
verticalPosition: MatSnackBarVerticalPosition = 'top';
img:any = this.atrb.IMAGENES_FIJAS.sin_img;
  constructor(
    private dialog: MatDialog, 
    private app:AppComponent, 
    private servicio:nkServices, 
    private router:Router,
    private snackBar: MatSnackBar,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ) { 
      this.getParametrosApp();
    }

  ngOnInit(){
      this.sesion = this.hashkey.decrypt(this.atrb.COD_PERFIL.cod_perfil);
    this.usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
    this.buscarusuariorol(this.sesion);
  }
  buscarusuariorol(sesion:any){
    this.servicio.buscarUSUARIOROLhttp(sesion).subscribe(
      response =>{
        for (const i in response) {
          this.menu.push(response[i].cod_menu, response[i].padre_menu);
        }
        const result = this.menu.reduce((acc,item)=>{
          if(!acc.includes(item)){
            acc.push(item);
          }
          return acc;
        },[]);
        this.cargarmenu(result.sort());
  },
  error =>{
    console.log(<any>error.error.message);
  }
    );
  }
  cargarmenu(arrymenu:any){
    for (const i in arrymenu) {
      this.servicio.buscarMENUDIRhttp(arrymenu[i]).subscribe(
        response =>{
          for (const i in response) {
            response[i].padre_menu = parseInt(response[i].padre_menu);
            this.menu2.push(response[i]);
            if(response[i].padre_menu != 0){
              this.menu3.push(response[i]);
              this.unidad = localStorage.getItem('Unidad');
            }else{
              this.menu4.push(response[i]);
            }
          }
          this.menu4.sort(function (a, b){
            return (a.ord_menu - b.ord_menu);
        })
        this.menu3.sort(function (a, b){
          return (a.ord_menu  - b.ord_menu );
      })
    }, 
    error =>{
      console.log(<any>error.error.message);
    }
      );
    }
  }
 async enviarcodmenu(cod:any,dir:any){
    try {
            await this.util.abrirSpinner();
            await  this.servicio.consultar_sesionhttp(null).toPromise();
        this.local = localStorage.getItem('cod_valid');
        if (!this.local) {
          await this.util.cerrarSpinner();
          let key = 'cod_sub';
        let key2 = 'cod_valid';
        localStorage.removeItem('cod_sub');
        localStorage.removeItem('cod_valid');
        localStorage.setItem(key,cod);
        localStorage.setItem(key2,'1');
        this.router.navigate(['/',dir]);

        }else{
          await this.util.cerrarSpinner();
          if (this.local !== false && this.local === 2) {
            let key2 = 'cod_valid';
            localStorage.setItem(key2,'1');
            this.router.navigate(['/',dir]);
          }else{
            this.router.navigate(['/',dir]);
            let key = 'cod_sub';
            let key2 = 'cod_valid';
            localStorage.removeItem('cod_sub');
            localStorage.removeItem('cod_valid');
            localStorage.setItem(key,cod);
            localStorage.setItem(key2,'1');
          }
        }
    } catch (error) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error);
    }
  }

  cerrarsesion(){
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'auto',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'salir') {
        dialogRef.close();
      }else{
        /* dialogRef.close(); */
        this.app.cerrarsesion();
      }
    });
  }

  async getParametrosApp(){
    try {
      let valor = {
        cod_empresa : await this.atrb.COD_EMPRESA.cod_empresa,
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarParametroGaleria)
      }
      const response = await this.servicio.ApiResthttp(valor).toPromise();
      let img_val;
      for (const i in response) {
        img_val = response[i].constante_img.split('_');
        if (img_val[0].toUpperCase() === await this.atrb.IMG.toUpperCase()) {
          response[i].img = environment.path_storage+response[i].val_def;
          if (response[i].constante_img.toUpperCase() === await this.atrb.CONSTANTES_GALERIA.IMG_LOGO.toUpperCase()) {
            this.img = response[i].img;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}
