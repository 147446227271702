import { Component, ElementRef, OnInit } from '@angular/core';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import { Router } from '@angular/router';
import { ViewChild} from '@angular/core';
import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormmMsgComponent } from 'src/app/componentes/shared/modal/formm-msg/formm-msg.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormDetalleGuiaComponent } from 'src/app/componentes/shared/modal/form-detalle-guia/form-detalle-guia.component';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';

interface interfacefactura{
  cod_lote: string;
  cant_producto:string;
  cod_producto:string;
  des_producto:string;
  tamano_producto:string;
  cant_blts:string;
  total_peso:string;
}
interface interdatosfacefactura{
cod_guia_despacho:string;
cod_factura:string;
cod_pedido:string;
fecha_inicio:string;
}
type TableRow = [string, string, string, string, string, string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-guia-despacho',
  templateUrl: './guia-despacho.component.html',
  styleUrls: ['./guia-despacho.component.css']
})

export class GuiaDespachoComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  contador:any;

  dialogSpin:any;
  sesion:any;
  cod_menu:any;
  object1:any;
  menu:any=[];
  menu2:any = [];
  menu3:any = [];
  menu4:any = [];
  roulink:any;
  unidad:any;
  usuario:any;
  menuorder3:any;
  menuoreder4:any;
  mostrar:any;
  ruta:any;
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:any[];
  dataSource=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('link') link: ElementRef;
  constructor(
    private servicio:nkServices,
    private dialog: MatDialog,
    private pdf: PdfServices,  
    private snackBar: MatSnackBar, 
    private router:Router){
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};;

servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
response => {
this.auth_menu = response;
console.log(response);
/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
localStorage.removeItem('accion_1');
localStorage.removeItem('accion_2');
localStorage.removeItem('accion_3');
localStorage.removeItem('accion_4');
localStorage.removeItem('accion_5');
for (const i in this.auth_menu) {
switch (this.auth_menu[i].des_accion) {
case this.key1:
localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
break;
case this.key2:
localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
break;
case this.key3:
localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
break;
case this.key4:
localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
break;
case this.key5:
localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
break;
default:
break;
}
}

this.accion_1 = localStorage.getItem('accion_1');
this.accion_2 = localStorage.getItem('accion_2');
this.accion_3 = localStorage.getItem('accion_3');
this.accion_4 = localStorage.getItem('accion_4');
this.accion_5 = localStorage.getItem('accion_5');

for (const i in this.auth_menu) {
if (this.auth_menu[i].cod_accion === this.accion_1) {
this.read = true;
}
if (this.auth_menu[i].cod_accion === this.accion_2) {
this.create = true;
}
if (this.auth_menu[i].cod_accion === this.accion_3) {
this.update = true;
}
if (this.auth_menu[i].cod_accion === this.accion_4) {
this.delete = true;
}
if (this.auth_menu[i].cod_accion === this.accion_5) {
this.special = true;
}
}

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
},
error =>{
localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
console.log(error);
}
);
  }

  ngOnInit(): void {
    this.mostrar = parseInt(localStorage.getItem('cod_valid'));
    this.sesion = localStorage.getItem('cod_usuario');
    console.log(typeof this.mostrar);
    this.usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
    this.object1 ={cod_usuario:this.sesion, cod_menu:this.cod_menu}
    this.listarGuiaDespacho(null);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  detalleGuiaPedido(xvalor:any){
    try {
      var dialogRef = this.dialog.open(FormDetalleGuiaComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {cod_factura:xvalor.cod_factura,
               objeto:xvalor,
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
      });
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  listarGuiaDespacho(msj:any){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;

        var cod_usuario = localStorage.getItem('cod_usuario');
        var nombre_usuario = localStorage.getItem('Nombre');
        var apellido_usuario = localStorage.getItem('Apellido');
        var nombre = nombre_usuario+' '+apellido_usuario;
        this.servicio.listarGuiaDespachohttp().subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              console.log(response[0],'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
    
                    this.ELEMENT_DATA.push({
                        id_guia_despacho:response[i].id_guia_despacho,
                        cod_factura:response[i].cod_factura,
                        lote:response[i].lote,
                        cod_guia_despacho:response[i].cod_guia_despacho,
                        fecha_inicio:response[i].fecha_inicio,
                        hora_inicio:response[i].hora_inicio,
                        estatus:response[i].estatus,
                        tipo_despacho:response[i].tipo_despacho,
                        des_cliente:response[i].des_cliente,
                        des_usuario:response[i].des_usuario,
                        nombre:nombre,
                        cod_usuario:cod_usuario,
                        um_guia:response[i].um_guia,
                    });
                  }
                  this.displayedColumns = ['cod_guia_despacho',
                                            'cod_factura',
                                            'des_cliente',
                                            'des_usuario',
                                            'tipo_despacho',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'estatus',
                                            'um_guia',
                                            'detalle',
                                            'imprimir'
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.ELEMENT_DATA = [];
            this.displayedColumns = ['cod_guia_despacho',
                                      'cod_factura',
                                      'des_cliente',
                                      'des_usuario',
                                      'tipo_despacho',
                                      'fecha_inicio',
                                      'hora_inicio',
                                      'estatus',
                                      'um_guia',
                                      'detalle',
                                      'imprimir'
                                    ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
          );
    
          if(msg == 'actualizar'){
            this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  //////////////////////FUNCION DE ESTRUCTURA/////////////////////////

  imprimir_descargar(xvalor:any,text:any): void {
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            this.accion = text;
            switch (this.accion) {
              case 'imprimir':
                this.accion = 'enviarpedido';
                var dialogRef = this.dialog.open(FormmMsgComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                  data: {id:'',
                        accion:this.accion},
                  disableClose: true,
                  panelClass: 'pedidos_modal',
                  backdropClass: "bdrop"
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result != 'cerrar' && result != 'salir' ) {
                    dialogRef.close();
                    this.imprimirguialamacen(xvalor);
                  }else{
                    dialogRef.close();
                  }
        
                });
                break;
                case 'descargar':
                this.accion = 'enviarpedido';
                var dialogRef = this.dialog.open(FormmMsgComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                  data: {id:'',
                        accion:this.accion},
                  disableClose: true,
                  panelClass: 'pedidos_modal',
                  backdropClass: "bdrop"
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result != 'cerrar' && result != 'salir' ) {
                    dialogRef.close();
                    this.imprimirguialamacen(xvalor);
                  }else{
                    dialogRef.close();
        
                  }
        
                });
                break;
        
              default:
                break;
            } 
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      ); 
      
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  imprimirguialamacen(xvalor:any){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
  
      setTimeout(() => {
        xvalor.val_def = 'GA';
        xvalor.tipo_operacion = 'IMPRIMIRGA';
        xvalor.cod_usuario = localStorage.getItem('cod_usuario');
        this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
          response => {
            const pdf = new PdfMakeWrapper();
                  console.log(xvalor);
                  console.log(response);
              
                  var cod_usuario = localStorage.getItem('cod_usuario');
                  var nombre_usuario = localStorage.getItem('Nombre');
                  var apellido_usuario = localStorage.getItem('Apellido');
                  var nombre = nombre_usuario+' '+apellido_usuario;
  
                  if (xvalor.tipo_despacho.toUpperCase() === 'F') {
                    this.servicio.listarGuiaDespachoFacturahttp(xvalor).subscribe(
                      async response  =>{
                        this.dialogSpin.close();
                        this.listarGuiaDespacho(null);
                        this.snackBar.open('Guia de almacen procesada', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                       
                      },
                      error =>{
                        this.dialogSpin.close();
                        this.listarGuiaDespacho(null);
  
                        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                      }
                    );
                    
                  }
                  if (xvalor.tipo_despacho.toUpperCase() === 'N') {
                    this.servicio.listarGuiaDespachoFacturahttp(xvalor).subscribe(
                      async response  =>{
                        this.dialogSpin.close();
                        this.listarGuiaDespacho(null);
                        this.snackBar.open('Guia de almacen procesada', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                      },
                      error =>{
                        this.dialogSpin.close();
                        this.listarGuiaDespacho(null);
                        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                      }
                    );
                    
                  }
                  if (xvalor.tipo_despacho.toUpperCase() === 'C') {
                    this.servicio.listarGuiaDespachoConsignacionhttp(xvalor).subscribe(
                      async response  =>{
                        this.dialogSpin.close();
                        this.listarGuiaDespacho(null);
                        this.snackBar.open('Guia de almacen procesada', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                        
                      },
                      error =>{
                        this.dialogSpin.close();
                        this.listarGuiaDespacho(null);
                        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                      }
                    );
  
                    
                  }
          },
          error=>{
            this.dialogSpin.close();
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
        console.log(error);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

}
