import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormConsultarFormulaMppComponent } from '../form-consultar-formula-mpp/form-consultar-formula-mpp.component';
import { FormRevisarOrdenesMppComponent } from '../form-revisar-ordenes-mpp/form-revisar-ordenes-mpp.component';
import * as moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../shared/servicios/formato_datapick';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-reporte-devoluciones',
  templateUrl: './form-reporte-devoluciones.component.html',
  styleUrls: ['./form-reporte-devoluciones.component.css']
})
export class FormReporteDevolucionesComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;
  arry: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
  formato:any;
  fecha:any;
forma:FormGroup;
dialogSpin:any;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
cod_prod:any;
des_prod:any;
cod_prod_mpp:any;
des_prod_mpp:any;
prod_mpp_faltante:any;
prod_mpp_excedente:any;
fila:any;
producto:any = [];
orden_prod:any;
orden_prod_mpp:any;
cant_producto_fabricado:any;
cant_orden_producto:any;
cant_orden_producto_mpp:any;
cant_formula:any;
cant_inv_prod_mp;
total_mp:any;
sugerido:any;
codigo:any = [];
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
cod_pro:any;

  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormReporteDevolucionesComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.accion = data.accion;
      console.log(data, 'primero');
      this.fecha = new Date().toLocaleDateString();
      this.forma = fb.group({
        'cod_usuario':'',
        'des_usuario':'',
        'cod_producto':'',
        'des_producto':'',
        'cod_producto_mpp':'',
        'des_producto_mpp':'',
        'cant_orden_prod':'',
        'cant_producto_solicitado':'',
        'cant_producto_fabricado':'',
        'cant_formula_pt':'',
        'estatus':'',
        'cod_orden_producto_mpp':'',
        'cod_orden_producto':'',
        'causa':'',
        'ajuste_prod':'',
        'faltante':'',
        'fila':'',
        'fecha_inicio':['',[Validators.required]],
        'fecha_fin':['',[Validators.required]]

      });
    }

  ngOnInit(): void {
    var tiempo =  moment();
    console.log(tiempo.year());
    this.listarproducto();
  }
  ngAfterViewInit() {

  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 );
    }
    console.log(this.stateGroups);
    return this.stateGroups;
 }
  listarproducto(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarProductoTermhttp().subscribe(
      response =>{

        this.arry = this.forma.get('cod_producto')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }

                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.producto
                  }];

                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

modalrespuesta(termino:any){
  try {
    var accion = "enviarordenprodmpp";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
              maxWidth: '100vw',
              height:'100%',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(termino);
       this.dialogRefp.close(termino);
      }
    });
  } catch (error) {
    this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

reporte_fecha_rango(){
  try {
    var accion = "enviarordenprodmpp";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
              maxWidth: '100vw',
              height:'100%',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        var cod = this.forma.value.cod_producto.split(' ');
        this.forma.value.fecha_inicio = moment(this.forma.value.fecha_inicio).format('DD/MM/YYYY');
        this.forma.value.fecha_fin = moment(this.forma.value.fecha_fin).format('DD/MM/YYYY');
        this.forma.value.cod_producto = cod[0];
        console.log(this.forma.value);
       this.dialogRefp.close(this.forma.value);
      }
    });
  } catch (error) {
    this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
cerrarmodal(): void {
  var accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100%',
    data: {id:'',
          accion:accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
}

cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 2000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }


}
