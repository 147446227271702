
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row" style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255);"><mat-icon >autorenew</mat-icon></span>
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255);"><b style=" border-bottom: 1px solid rgb(255, 255, 255);">DEVOLUCIONES</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarSolicitudDevolucion("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                   <!--  <li *ngIf="create" class="nav-item"><button matTooltip="NOTAS DE CREDITO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button  style="height: 35px; color: rgb(255, 255, 255); background-color: rgb(3, 141, 84); " (click)='notas_credito_admin_single("","AD-SN")'><mat-icon >account_balance_wallet</mat-icon></button></li> -->
                </ul>
                 <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div> 
            </div>
        </nav>
    </div>
    <div class="row " style="height: 78.7%;">
        <div class="col-md-12 px-0  border h-100 " style="background-color: steelblue; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_solicitud_devolucion">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Devolución</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_solicitud_devolucion}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_documento">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Documento</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_documento}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Rif</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_cliente}} </td>
                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_cliente}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cedula</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_usuario}} </td>
                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Nombre</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_usuario}} </td>
                </ng-container>

                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.fecha}} </td>
                </ng-container>

                <ng-container matColumnDef="hora">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.hora}} </td>
                </ng-container>

                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.tipo_devolucion}} </td>
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>estatus</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "  > {{element.estatus}} </td>
                </ng-container>

                <ng-container matColumnDef="nota_dev" >
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Devolución</strong></h5>
                    </th>
                    <td [ngClass]="{'dvl_dev':  element.tipo_devolucion === 'DVLCN','dvl_re':  element.tipo_devolucion === 'RCNDCNMNT'}" mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button class="rounded-pill"  [disabled]="element.estatus === 'C' ? true :false"  [style.background-color]="element.estatus === 'C' ? 'black' :'rgb(66, 43, 167)'"  matTooltip="DEVOLUCIONES" mat-raised-button (click)="validar_nota_credito(element,'dev')"  style="height: 35px; color:rgb(255, 255, 255);"> <!-- *ngIf="create" -->
                            <mat-icon>credit_card</mat-icon>
                        </button>
                       <!-- <button  *ngIf="!create" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >credit_card</mat-icon>
                        </button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>

           <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 
        </div>
    </div>
</div>

