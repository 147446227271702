import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-prov',
  templateUrl: './form-prov.component.html',
  styleUrls: ['./form-prov.component.css']
})
export class FormProvComponent implements OnInit {
  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/
  Regex:any = /^[J,V,G,E]{1}\d{9}/;
  hide = true;
  nombre:any;
  dialogSpin:any;
  forma:FormGroup;
  accion:any;
  accion2:any;
  unidad:any;
  email = new FormControl('', [Validators.required, Validators.email]);
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
    constructor(private snackBar: MatSnackBar, public dialog: MatDialog, fb:FormBuilder,
      public dialogRef: MatDialogRef<FormProvComponent>,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data, 'primero');
      this.accion = data.accion;

        this.forma = fb.group({
          'id_prov':data.id_prov,
          'cod_prov':[data.cod_prov,[Validators.required,Validators.pattern(this.Regex),Validators.maxLength(10)]],
          'des_prov':[data.des_prov,[Validators.required]],
          'tipo_prov':[data.tipo_prov,[Validators.required]],
          'dir_prov':[data.dir_prov,[Validators.required]],
          'estatus_prov':[data.estatus_prov,[Validators.required]],
          'pais_prov':[data.pais_prov,[Validators.required]],
          'pais_estado_prov':[data.pais_estado_prov,[Validators.required]],
          'reten_prov':[data.reten_prov,[Validators.required]],
          'correo_prov':[data.correo_prov,[Validators.required,Validators.pattern(this.emailRegex)]],
          'telefono1_prov':[data.telefono1_prov,[Validators.required,Validators.pattern(this.telRegex),Validators.maxLength(12)]],
          'telefono2_prov':[data.telefono2_prov,[Validators.required,Validators.pattern(this.telRegex),Validators.maxLength(12)]]
        });
      }

    ngOnInit(): void {
    }

    modalrespuesta(){
      this.accion2 = 'actualizarprov';
        var dialogRefp = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'100%',
          data: {id:this.forma.value.cod_prov,
                accion:this.accion2},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRefp.afterClosed().subscribe(result => {
          if(result != 'cerrar'){

            console.log(result);
            this.dialogRef.close(this.forma.value);
          }
        });
    }

    actualizardatos(res:any){

      if(res == 'actualizar'){
        console.log(this.forma.value);
        this.dialogRef.close(this.forma.value);
      }else{

      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
      }

    }

    cerrarmodal(): void {
      var accion = 'salir';
      var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
                maxWidth: '100vw',
                height:'100%',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRefp.afterClosed().subscribe(result => {
        if (result != 'cerrar') {
          this.cerrar();
        }

      });

    }
    cerrar():void{
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          this.dialogSpin.close();
          this.dialogRef.close('cerrar');
        }, 2000);
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

}
