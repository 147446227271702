<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;"> 

  <div class="row " style="height: 8%; ">
    <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark nav justify-content-between" style=" background:#1e212c; padding: 5px 7px 5px 7px; margin: 0px;">
      <span class="navbar-brand d-flex align-items-center" style=" font-size: medium; color: #FFF; " > <mat-icon>assignment</mat-icon> <b class="ml-2">ORDENES DE COMPRA</b></span>
      <div>
        <ul class="navbar-nav mr-auto">
          <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="btn btn-primary my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button style="height: 35px; " (click)='mostrarTabla("actualizar")'><mat-icon >sync</mat-icon></button></li>
          <!-- <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
          <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
          <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li> -->
          <li *ngIf="read" class="nav-item"><button matTooltip="ORDENES COMPLETAS" class="btn btn-info my-2 my-sm-0 mr-sm-2 rounded-pill bg-info text-light" mat-raised-button style="height: 35px;" (click)='verOrdenesComrpasCompletas()'><mat-icon >alarm_on</mat-icon></button></li>
        </ul>
      </div>
      <div class="">
        <input class="form-control me-2 rounded-pill" type="text" #cod_lista1 placeholder="Buscar" autocomplete="off" (keyup)="applyFilter($event)">
      </div>
    </nav>
  </div>

  <div class="row " style="height: 78.7%;">
    
      <div id="htmlData" class="col-md-12 px-0   h-100 " style=" overflow-y: auto;">

              <table id="htmlData" #htmlData mat-table [dataSource]="dataSource" class=" text-center ">
                <!-- Weight Column -->
                <ng-container matColumnDef="cod_orden_compra">
                  <th  *matHeaderCellDef class=" text-center" style=" color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Codigo</strong></h4>
                  </th>
                  <td class="" mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> {{element.cod_orden_compra}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_proveedor">
                  <th  *matHeaderCellDef class=" text-center" style=" color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>RIF/CI</strong></h4>
                  </th>
                  <td class="" mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> {{element.cod_proveedor}} </td>
                </ng-container>


                <ng-container matColumnDef="des_proveedor">
                  <th  *matHeaderCellDef class=" text-center" style=" color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Proveedor</strong></h4>
                  </th>
                  <td class="" mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> <strong>{{element.des_proveedor}}</strong> </td>
                </ng-container>


                <ng-container matColumnDef="estatus">
                  <th  *matHeaderCellDef class=" text-center" style=" color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Estatus</strong></h4>
                  </th>
                  <td class="" mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;" [ngSwitch]="element.estatus">
                    
                    
                      <div *ngSwitchCase="'P'" class="mx-2" style="background-color: var(--warning); display: flex; justify-content: center; align-items: center; border-radius: 1rem;opacity: 0.8;"><strong  class=" py-2">Por Recibir</strong></div> 
                      <div *ngSwitchCase="'C'" class="mx-2" style="background-color: var(--success); display: flex; justify-content: center; align-items: center; border-radius: 1rem;opacity: 0.8;"><strong  class="text-white py-2">Completa</strong></div> 

                      <strong *ngSwitchDefault >{{element.estatus}}</strong> 
                      
                  </td>
                    
                </ng-container>


                <ng-container matColumnDef="fecha">
                  <th  *matHeaderCellDef class=" text-center" style=" color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Fecha</strong></h4>
                  </th>
                  <td class="" mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> <strong>{{element.fecha}}</strong> </td>
                </ng-container>

                <ng-container  matColumnDef="opciones">
                  <th  *matHeaderCellDef class=" text-center" style=" color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Opciones</strong></h4>
                  </th>
                
                  <td class="" mat-cell *matCellDef="let element"   [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="text-center" >
                    <button matTooltip="VER" (click)='verOrdenDCompra(element, "ver")' class="btn btn-secondary btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); border: 2px solid; background-color: #A15A99;">
                      <mat-icon>remove_red_eye</mat-icon>
                    </button>
                    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)='verOrdenDCompra(element, "ver")' >
                        <mat-icon>remove_red_eye</mat-icon>
                        <span>Ver</span>
                      </button>
                      <button mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                      </button>
                      <button mat-menu-item>
                        <mat-icon>do_not_disturb_alt</mat-icon>
                        <span>Anular</span>
                      </button>
                    </mat-menu> -->
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>

            <mat-paginator class="w-100 mat-paginator-sticky " [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 
          
      </div>
    
  </div>

  
</div>