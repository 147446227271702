import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Console } from 'console';
import { MatPaginator } from '@angular/material/paginator';
import { FormKeyComponent } from '../form-key/form-key.component';
import { FormHistorialParametrosVentasComponent } from '../form-historial-parametros-ventas/form-historial-parametros-ventas.component';
import { FormAccionesAccesosUsuariosComponent } from '../form-acciones-accesos-usuarios/form-acciones-accesos-usuarios.component';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-parametros-acciones',
  templateUrl: './form-parametros-acciones.component.html',
  styleUrls: ['./form-parametros-acciones.component.css']
})
export class FormParametrosAccionesComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('des_definicion',{static:true}) des_definicion:ElementRef;
  @ViewChild('operacion',{static:true}) operacion:ElementRef;
  @ViewChild('descrip_definicion',{static:true}) descrip_definicion:ElementRef;
  @ViewChild('var_defi',{static:true}) var_defi:ElementRef;

  producto:any = [];
  producto_2:any = [];
  dialogSpin:any;
  codigo:any = [];
  codigo_2:any = [];
  forma:FormGroup;
  accion:any;
  roles:any;
  stateForm: FormGroup;
  stateGroups: StateGroup[];
  stateGroups2: StateGroup[];
  arry: Observable<StateGroup[]>;
  arry2: Observable<StateGroup[]>;
  rol = [];
  unidad=[];
  unidadp=[];

  parametros_precios:any = [];
  parametros_operacion:any = [];
  parametros_montos:any = [];
  key_hash:any;
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  secuencia:any;
  menur3:any;
  unidades:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    public dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private servicio:nkServices,
    private formb:FormBuilder, 
    private fb:FormBuilder,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private dialogRefp: MatDialogRef<FormParametrosAccionesComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

    console.log(data, 'primero');

    this.forma = fb.group({
      'cod_definicion': '',
      'des_definicion': '',
      'descrip_definicion':'',
      'cod_usuario':'',
      'des_usuario':'',
      'operacion':'',
      'tipo_monto':'',
      'val_def':'0,00',
      'modo':''
    });
   }

ngOnInit(): void {
  this.listar_parametros_acciones_accesos(null);
  this.listarDefiniciones(localStorage.getItem('sub_main'));
}
@ViewChild(MatPaginator) paginator: MatPaginator;

ngAfterViewInit() {
  this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    this.dataSource.paginator = this.paginator;
}
listarDefiniciones(objeto:any){
  try {
    var xvalor ={cod_menu:objeto};
      this.servicio.listarDefinicionesConceptoshttp(xvalor).subscribe(
        response =>{
          console.log(response);
          this.parametros_precios= [];
          this.parametros_operacion= [];
          for (const i in response) {
            if (response[i].des_definicion.toUpperCase() ===  'CONCEPTOS DE ACCESOS') {
              this.parametros_precios.push(response[i]);
            }
            console.log(this.parametros_precios);
            if (response[i].des_definicion.toUpperCase() === 'OPERACIONES') {
              this.parametros_operacion.push(response[i]);
            }
          }
        },
        error =>{
          console.log(<any>error.error.message,'error definiciones');
        }
      );
      return null;
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
/////////////////////////////////////////////////////////////////////
async listar_parametros_acciones_accesos(msj:any){
  try {
   await this.util.abrirSpinner();
   await this.servicio.consultar_sesionhttp(null);
      let msg = msj;
     const response = await this.servicio.listarParametrosAccionesAccesoshttp().toPromise();
     this.ELEMENT_DATA = [];
          for (const i in response) {

            if (response[i].operacion.toUpperCase() === 'FCTRCN' && !this.accion) {
              response[i].operacion = 'FACTURACIÓN';
            }

            this.ELEMENT_DATA.push({
              cod_definicion:response[i].cod_definicion,
              des_definicion:response[i].des_definicion,
              descrip_definicion:response[i].descrip_definicion,
              cod_usuario:response[i].cod_usuario,
              des_usuario:response[i].des_usuario,
              estatus:response[i].estatus,
              operacion:response[i].operacion,
              val_def:response[i].val_def,
              fecha_inicio:response[i].fecha_inicio,
              hora_inicio:response[i].hora_inicio,
              accion:'',
              accion2:'',
              accion3:''
            });
          }
          if (!this.accion) {
            this.displayedColumns = [
              'cod_definicion',
              'des_definicion',
              'descrip_definicion',
              'val_def',
              'operacion',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'usuarios',
              'definiciones',
    
            ];
          }
          if (this.accion) {
            this.displayedColumns = [
              'cod_definicion',
              'des_definicion',
              'descrip_definicion',
              'val_def',
              'operacion',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'usuarios',
              'definiciones',
    
            ];
          }
    
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
     
      if(msg == 'actualizar'){
        await this.util.cerrarSpinner();
          await this.util.notificacionErrorApp('Lista actualizada.');
      }else{
        await this.util.cerrarSpinner();
      }
  } catch (error) {
    await this.util.cerrarSpinner();
    this.displayedColumns = [
      'cod_definicion',
      'des_definicion',
      'descrip_definicion',
      'val_def',
      'operacion',
      'estatus',
      'fecha_inicio',
      'hora_inicio',
      'definiciones',

    ];
    await this.util.notificacionErrorApp(error.error.message);
  }
}
async activar_desactivar_acciones(xvalor:any){
  try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null);
            this.forma.value.cod_definicion = xvalor.cod_definicion;
            this.forma.value.des_definicion = xvalor.des_definicion;
            this.forma.value.descrip_definicion = xvalor.descrip_definicion;
            this.forma.value.val_def = xvalor.val_def;
            this.forma.value.estatus = xvalor.estatus;
            this.forma.value.modo = this.atrb.ACCION_ACTUALIZAR;
            this.gestionDefinicionesSistemaAcciones(this.forma.value);
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.util.notificacionErrorApp(error.error.message);
  }
}
async modalrespuesta(){
  try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null);
    if ((document.getElementById("descrip_definicion") as HTMLInputElement).value !== '' &&
        (document.getElementById("des_definicion") as HTMLInputElement).value !== '' &&
        (document.getElementById("operacion") as HTMLInputElement).value !== '') {
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: { 
              accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop" 
      });
      dialogRef.afterClosed().subscribe(async result => {
        if(result != 'cerrar'){
          await this.util.cerrarSpinner();
          this.forma.patchValue({
            objeto_definicion:this.ELEMENT_DATA,
            cod_usuario: await this.atrb.COD_USUARIO.cod_usuario,
            des_usuario: await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
            modo: await this.atrb.ACCION_CREAR
          });
           this.gestionDefinicionesSistemaAcciones(this.forma.value);
        } else{
          await this.util.cerrarSpinner();
        }
      });
    }else{
      await this.util.cerrarSpinner();
        await this.util.notificacionErrorApp('Existen campos vacios');
    }
  } catch (error) {
    await this.util.cerrarSpinner();
    console.log(error);
         await this.util.notificacionErrorApp(error);
  }
}
async gestionDefinicionesSistemaAcciones(xvalor:any){
  try {
      const response = await this.servicio.gestionDefinicionesSistemaAccioneshttp(xvalor).toPromise();
        await this.util.cerrarSpinner();
          await this.util.notificacionErrorApp(response.message);
          this.listar_parametros_acciones_accesos(null);
  } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await this.util.notificacionErrorApp(error.error.message);
    this.listar_parametros_acciones_accesos(null);
  }
 }
async cerrarmodal(){
  await this.util.abrirSpinner();
  var accion_ = 'salir'
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
    data: {id:'',
          accion:accion_},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(async result => {
    await this.util.cerrarSpinner();
    if (result != 'cerrar') {
      this.dialogRefp.close('cerrar');
    }
  });
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}
async parametros_acciones_usuarios(text:any){
  try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null);
                          var accion = text;
                          var dialogRef = this.dialog.open(FormAccionesAccesosUsuariosComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                            data: {id:'',
                                  cod_definicion:text.cod_definicion,
                                  accion:accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                          dialogRef.afterClosed().subscribe(async result => {
                            if(result != 'cerrar'){
                              await this.util.cerrarSpinner();
                            }else{
                              await this.util.cerrarSpinner();
                            }
                          });
  } catch (error) {
    await this.util.cerrarSpinner();
         await this.util.notificacionErrorApp(error.error.message);
  }
}
}
