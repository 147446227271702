import { Component, ElementRef, OnInit } from '@angular/core';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import { Routes, RouterModule, Router } from '@angular/router';

import { Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormMateriaPrimaPreparadaComponent } from 'src/app/componentes/shared/modal/form-materia-prima-preparada/form-materia-prima-preparada.component';
import { FormFormulacionMppComponent } from 'src/app/componentes/shared/modal/form-formulacion-mpp/form-formulacion-mpp.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormOrdHistorialMppComponent } from 'src/app/componentes/shared/modal/form-ord-historial-mpp/form-ord-historial-mpp.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';

@Component({
  selector: 'app-mp-producto-preparado',
  templateUrl: './mp-producto-preparado.component.html',
  styleUrls: ['./mp-producto-preparado.component.css']
})
export class MpProductoPreparadoComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  sesion:any;
  cod_menu:any;
  object1:any;
  menu:any=[];
  menu2:any = [];
  menu3:any = [];
  menu4:any = [];
  roulink:any;
  unidad:any;
  dialogSpin:any;
  usuario:any;
  menuorder3:any;
  menuoreder4:any;
  mostrar:any;
  ruta:any;
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:any[];
  dataSource=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('link') link: ElementRef;
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router){

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.mostrar = parseInt(localStorage.getItem('cod_valid'));
    this.sesion = localStorage.getItem('cod_usuario');
    console.log(typeof this.mostrar);
    this.usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
    this.object1 ={cod_usuario:this.sesion, cod_menu:this.cod_menu}
    this.listarProductoMateriaPrimaPreparada(null);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    this.dataSource.paginator = this.paginator;

  }
  listarProductoMateriaPrimaPreparada(msj:any){
    try {
      let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        this.servicio.listarProductoMateriaPrimaPreparadahttp().subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              console.log(response[0],'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
    
                    this.ELEMENT_DATA.push({
                      id_producto_preparado:response[i].id_producto_preparado,
                        cod_formula:response[i].cod_formula,
                        cod_producto:response[i].cod_producto,
                        des_producto: response[i].des_producto,
                        estatus_producto:response[i].estatus_producto,
                        tamano_producto:response[i].tamano_producto,
                        tipo_producto : response[i].tipo_producto,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [
                                          'cod_formula',
                                          'cod_producto',
                                          'des_producto',
                                          'estatus_producto',
                                          'historial',
                                          'formulacion',
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                    'cod_formula',
                                    'cod_producto',
                                    'des_producto',
                                    'estatus_producto',
                                    'historial',
                                    'formulacion',
                                    ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
          );
    
          if(msg == 'actualizar'){
            this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  //////////////////////FUNCION DE ACCION/////////////////////////

  formulacion_prod_mpp(obj:any, text:any){

    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          console.log(obj, 'segundo');
                          console.log(this.xobj3,'UNIDADES');
                            var dialogRef = this.dialog.open(FormFormulacionMppComponent, {
                              width: '100vw',
                                maxWidth: '100vw',
                                height:'100vh',
                              data: {cod_usuario:localStorage.getItem('cod_usuario'),
                                    des_usuario:localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido'),
                                    cod_producto:obj.cod_producto,
                                     des_producto:obj.des_producto,
                                     cod_formula: obj.cod_formula,
                                     tipo_producto: obj.tipo_producto,
                                    accion:this.accion},
                                    disableClose: true,
                                    panelClass: 'pedidos_modal3',
                                    backdropClass: "bdrop"
                            });
                      
                            dialogRef.afterClosed().subscribe(result => {
                              if(result != 'cerrar'){
                                console.log(result);
                                
                              }else{
                                this.listarProductoMateriaPrimaPreparada(null);
                              }
                      
                            });
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

   gestionformulaMpp(xvalor:any){
     try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        var resultado = this.servicio.gestionFormulaMpphttp(xvalor).subscribe(
          response =>{
            this.dialogSpin.close();
    
                this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
          },
          error =>{
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
    
        return resultado;
      }, 1000);

     } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
     }
   
  }
//////////////////////FUNCION DE ACCION/////////////////////////

   //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////
   async historialFormulas(xvalor:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
      cod_menu: localStorage.getItem('sub_main'),
      cod_sesion: localStorage.getItem('cod_sesion'),
      dir_ip_client: localStorage.getItem('dir_ip'),
    sesion_unica:sessionStorage.getItem('sesion_unica')};
     this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
       response =>{
         xvalor.termino = 'MP';
         if (response.resul) {
         var dialogRef =   this.dialog.open(FormKeyComponent, {
             width: '100vw',
             maxWidth: '100vw',
             height:'100%',
             data:    {objeto: xvalor,
                      accion:'FRMP'},
                       disableClose: true,
                       panelClass: 'pedidos_modal',
                       backdropClass: "bdrop"
           });
           dialogRef.afterClosed().subscribe(result => {
            if(result != 'cerrar'){
              console.log(result);
              this.listarProductoMateriaPrimaPreparada(null);
            }else{
              this.listarProductoMateriaPrimaPreparada(null);
            }
    
          });
         }else{
           this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,});
         }
       },
       error =>{
         this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
       }
     ); 
    } catch (error) {
     this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
       verticalPosition: this.verticalPosition,})
    }
 }
 
   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
}






















