import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-unidad',
  templateUrl: './form-unidad.component.html',
  styleUrls: ['./form-unidad.component.css']
})
export class FormUnidadComponent implements OnInit {

  forma:FormGroup;
  accion:any;
  unidad:any;
  unidadp:any = [];
  menu:any;
  des:any;
  email = new FormControl('', [Validators.required, Validators.email]);
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  estatus:any;
    constructor( 
      private dialog: MatDialog, 
      private fb:FormBuilder,
      private servicio:nkServices, 
      private hashkey:HashKeyServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private notifi:ToastNotificationService,
      private dialogRef: MatDialogRef<FormUnidadComponent>,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) private data: any) {
        console.log(data, 'primero');
      this.accion = data.accion;
      this.des = data.des;
      this.unidad = data.unidad;

      for (const i in this.unidad) {
        if (parseInt(this.unidad[i].cod_unidad_padre) <= 0) {
          this.unidadp.push(this.unidad[i]);
        }
      }
            if (this.data.operacion.toLowerCase() === this.atrb.ACCION_ACTUALIZAR.toLowerCase()) {
              this.forma = fb.group({
                'cod_unidad':[data.cod_unidad],
                'des_unidad':[data.des_unidad,[Validators.required]],
                'respon_unidad':[data.respon_unidad,[Validators.required]],
                'cod_unidad_padre':[data.cod_unidad_padre,[Validators.required]],
                'des_unidad_padre':[data.des_unidad_padre],
                'estatus':[data.estatus],
                'operacion':[data.operacion]
              });
        }
        if (this.data.operacion.toLowerCase() === this.atrb.ACCION_CREAR.toLowerCase()) {
          this.forma = fb.group({
                'des_unidad':['',[Validators.required]],
                'respon_unidad':['',[Validators.required]],
                'cod_unidad_padre':['',[Validators.required]],
                'des_unidad_padre':[''],
                'estatus':[''],
                'operacion':[data.operacion]
          });
        }
    }
    ngOnInit(){
      this.estatus = this.atrb.ESTATUS;
    }

    async  modalrespuesta(){
      try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        await this.util.cerrarSpinner();
        var accion2 = '';
        var dialogRefp = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: {id:this.data.id_cliente,
                accion:accion2},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRefp.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result !== await this.atrb.CERRAR){
            let unidad =  this.forma.value.cod_unidad_padre;
            let valor_unidad;
            if (parseInt(unidad) > 0) {
                valor_unidad = this.unidadp.filter( function(elem){
                return elem.cod_unidad.toString()  ===  unidad.toString();
              });
              this.forma.patchValue({
                des_unidad_padre: valor_unidad[0].des_unidad,
              });
            }else{
              this.forma.patchValue({
                des_unidad_padre: await this.atrb.UNIDAD_PADRE,
              });
            }
              await this.util.cerrarSpinner();
              this.dialogRef.close(this.forma.value);
          }else{
            await this.util.cerrarSpinner();
          }
        });
      } catch (error) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
      }
    }
    async cerrarmodal(){
      try {
        let accion = 'salir';
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: {id:'',
                accion:accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if (result != 'cerrar') {
            await this.util.cerrarSpinner();
            this.dialogRef.close('cerrar');
          }else{
            await this.util.cerrarSpinner();
          }
        });
      } catch (error) {
        console.log(error);
     }
    }
}
