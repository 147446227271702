import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { promise } from 'protractor';
import { nkServices } from '../servicios/app.nkservicios.services';
import { ToastNotificationService } from '../servicios/toast-notification.service';
import { AppUtilService } from '../servicios/app.util.service';
import { AtributosService } from '../servicios/atributos.service';
import { HashKeyServices } from '../servicios/app.hashkey.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  tituloapp:any;
  company:any;
  img:any;
  constructor(private toast:ToastNotificationService,
              private servicio:nkServices,
              private util:AppUtilService,
              private atrb:AtributosService,
              private hashkey:HashKeyServices
              ){
                this.getParametrosApp();
              }

  ngOnInit(){
    this.tituloapp = 'Vector';
    this.company = 'Nerd';

      /* this.notificaciones_compras();
      this.notificaciones_devoluciones();
      this.notificaciones_ordenes_pt();
      this.notificaciones_ordenes_mpp();
      this.notificaciones_pedidos();
      this.notificaciones_pedidospf();
      this.notificaciones_cotizaciones();
      this.notificaciones_fechanacimiento(); */
    
  }

 /*  redir(){
    console.log('hola')
    this.router.navigate(['/ordenes_comp']);
  } */

  notificaciones_compras(){
    try {
      var xvalor = {
        val_def:'NT',
        tipo_operacion:'COMPRAS',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        () =>{
          
          this.servicio.listarSolicituComprahttp(localStorage.getItem('cod_usuario')).subscribe(
          response => {

            if(response.empleado.dt_cargo.includes('CORDINADOR') || response.empleado.dt_cargo.includes('COORDINADOR') || response.empleado.dt_cargo.includes('GERENTE')){
              console.log(response);
             let array:Array<any> = response.result.filter(element => element.estatus == 'P')
      
             if(array.length){
      
               this.toast.customToastNotification(
                'COMPRAS', 
               array.length > 1 
               ?`<div class="text-decoration-none text-dark">Tienes ${array.length} Requisiciónes Pendientes por Aprobar</div>`
               : `<div class="text-decoration-none text-dark">Tienes ${array.length} Requisición Pendiente por Aprobar</div>`
               )
             }
            }
            
          },
          () => {
      
            this.toast.danger('ERROR', 'Error connection compras')
      
          }
        );
      },
        error =>{
          console.log(error)
        }
      );
      
    } catch (error) {
      console.log(error);
    }
  }
  notificaciones_cotizaciones(){
    try {
      var xvalor = {
        val_def:'NT',
        tipo_operacion:'COTIZACIONES',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response =>{
          
          this.servicio.listarCotizacionesNuevashttp().subscribe(
          response => {
            
              console.log(response);

             let array:Array<any> = response.result.filter(element => element.estatus == 'CTP')
      
             if(array.length){
      
               this.toast.customToastNotification(
                'COTIZACIONES', 
               array.length > 1 
               ?`<div class="text-decoration-none text-dark">Tienes ${array.length} Cotizaciones Pendientes por Aprobar</div>`
               : `<div class="text-decoration-none text-dark">Tienes ${array.length} Cotización Pendiente por Aprobar</div>`
               )

            }
            
          },
          error => {
      
            this.toast.danger('ERROR', 'Error connection compras')
      
          }
        );
      },
      error =>{
          
        }
      );
      
    } catch (error) {
      console.log(error);
    }
  }
  notificaciones_devoluciones(){
    try {
      var xvalor = {
        val_def:'NT',
        tipo_operacion:'DEVOLUCIONES',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response=>{
          this.servicio.listardevolucioneshttp().subscribe(
            response => {
              console.log(response);
               let array:Array<any> = response.filter(element => element.estatus == 'P')
        
               if(array.length){
        
                 this.toast.danger(
                  'DEVOLUCIONES', 
                 array.length > 1 
                 ?`<div class="text-decoration-none text-dark">Tienes ${array.length} Devoluciones Pendientes por Aprobar</div>`
                 : `<div class="text-decoration-none text-dark">Tienes ${array.length}  Devolucion Pendiente por Aprobar</div>`
                 )
               }
            },
            error => {
        
              this.toast.danger('ERROR', 'Error connection devoluciones')
        
            }
          ) 
        },
        error =>{
          console.log(error)
        }
      );
    } catch (error) {
      console.log(error);
    }  
  }
  notificaciones_ordenes_pt(){
    try {
      var xvalor = {
        val_def:'NT',
        tipo_operacion:'ORDENESPT',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response=>{
          var valor = {termino:'P'}
          this.servicio.listarOrdenesProdhttp(valor).subscribe(
            response => {
              console.log(response);
               let array:Array<any> = response.filter(element => element.estatus == 'P')
        
               if(array.length){
        
                 this.toast.danger(
                  'ORDENES PT', 
                 array.length > 1 
                 ?`<div class="text-decoration-none text-dark">Tienes ${array.length} Ordenes PT Pendientes por Aprobar</div>`
                 : `<div class="text-decoration-none text-dark">Tienes ${array.length}  Orden PT Pendiente por Aprobar</div>`
                 )
               }
            },
            error =>{
              console.log(error)
            }
          ) 
        },
        error=>{}
      );
    } catch (error) {
      console.log(error);
    }  
  }
  notificaciones_ordenes_mpp(){
    try {
      var xvalor = {
        val_def:'NT',
        tipo_operacion:'ORDENESMPP',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response=>{
          var valor = {termino:'P'}
          this.servicio.listarOrdenesProdMpphttp(valor).subscribe(
            response => {
              console.log(response);
               let array:Array<any> = response.filter(element => element.estatus == 'P')
        
               if(array.length){
        
                 this.toast.warning(
                  'ORDENES MPP', 
                 array.length > 1 
                 ?`<div class="text-decoration-none text-dark">Tienes ${array.length} Ordenes MPP Pendientes por Aprobar</div>`
                 : `<div class="text-decoration-none text-dark">Tienes ${array.length}  Orden MPP Pendiente por Aprobar</div>`
                 )
               }
            },
            error => {
        
              this.toast.danger('ERROR', 'Error connection mpp')
        
            }
          ) 
        },
        error =>{
          console.log(error)
        }
      );
    } catch (error) {
      console.log(error);
    }  
  }
async  notificaciones_pedidos(){
    try {
      var xvalor = {
        val_def:'NT',
        tipo_operacion:'PEDIDOSP',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
       await  this.servicio.validar_acceso_acciones_http(xvalor).toPromise();
          let xobjeto = {
            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
          }
        const response = await  this.servicio.listarPedidosFhttp(xobjeto).toPromise();
               let array:Array<any> = response.filter(element => element.estatus_pedido == 'P')
               if(array.length){
                 this.toast.warning(
                  'PEDIDOS', 
                 array.length > 1 
                 ?`<div class="text-decoration-none text-dark">Tienes ${array.length} Pedidos Pendientes por Facturar</div>`
                 : `<div class="text-decoration-none text-dark">Tienes ${array.length}  Pedido Pendiente por Facturar</div>`
                 )
               }
    } catch (error) {
      console.log(error);
    }  
  }
async  notificaciones_pedidospf(){
    try {
      var xvalor = {
        val_def:'NT',
        tipo_operacion:'PROFORMASP',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
      await  this.servicio.validar_acceso_acciones_http(xvalor).toPromise();
          let xobjeto = {
            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
          }
          const response = await  this.servicio.listarPedidosFhttp(xobjeto).toPromise();
               let array:Array<any> = response.filter(element => element.estatus_pedido == 'V')
               if(array.length){
                 this.toast.warning(
                  'PROFORMAS', 
                 array.length > 1 
                 ?`<div class="text-decoration-none text-dark">Tienes ${array.length} Pro-formas Pendientes por Facturar</div>`
                 : `<div class="text-decoration-none text-dark">Tienes ${array.length}  Pro-forma Pendiente por Facturar</div>`
                 )
               }
    } catch (error) {
      console.log(error);
    }  
  }
  notificaciones_fechanacimiento(){
    try {
      var xvalor = {
        val_def:'NT',
        tipo_operacion:'NACIMIENTO',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response=>{
          this.servicio.listarFechaNacimientohttp(xvalor).subscribe(
            response => {
              var titulo = '🥳🎉¡¡¡¡ FELIZ CUMPLEAÑOS !!!! 🥳🎉'+'🎁🎂 " '+localStorage.getItem('Nombre').toUpperCase()+' '+localStorage.getItem('Apellido').toUpperCase()+' "🎁🎂';
              console.log(response);
          
                 this.toast.success(
                  titulo,''
                 )
            },
            error => {
        
            }
          ) 
        },
        error =>{
          console.log(error)
        }
      );
    } catch (error) {
      console.log(error);
    }  
  }
  async getParametrosApp(){
    try {
        await this.servicio.consultar_sesionhttp(null).toPromise();
      let valor = {
        cod_empresa : await this.atrb.COD_EMPRESA.cod_empresa,
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarParametroGaleria)
      }
      const response = await this.servicio.ApiResthttp(valor).toPromise();
      let img_val;
      for (const i in response) {
        img_val = response[i].constante_img.split('_');
        if (img_val[0].toUpperCase() === await this.atrb.IMG.toUpperCase()) {
          response[i].img = environment.path_storage+response[i].val_def;
          if (response[i].constante_img.toUpperCase() === await this.atrb.CONSTANTES_GALERIA.IMG_INICIO.toUpperCase()) {
            this.img = response[i].img;
          }
        }
      }
      await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
    }
  }
}
