import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormPreciosComponent } from 'src/app/componentes/shared/modal/form-precios/form-precios.component';
import { FormConsultaListaComponent } from 'src/app/componentes/shared/modal/form-consulta-lista/form-consulta-lista.component';
import { FormPreciosActualizarComponent } from 'src/app/componentes/shared/modal/form-precios-actualizar/form-precios-actualizar.component';
import { FormTraladoInvComponent } from 'src/app/componentes/shared/modal/form-tralado-inv/form-tralado-inv.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormReportesComponent } from 'src/app/componentes/shared/modal/form-reportes/form-reportes.component';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-inventario-pt',
  templateUrl: './inventario-pt.component.html',
  styleUrls: ['./inventario-pt.component.css']
})
export class InventarioPtComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
    cod_menu: localStorage.getItem('sub_main'),
    cod_sesion: localStorage.getItem('cod_sesion'),
    dir_ip_client: localStorage.getItem('dir_ip')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.listarProductoTerminado(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////
  listarProductoTerminado(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        this.servicio.listarProductoTerminadohttp().subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              console.log(response[0],'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {

                    this.ELEMENT_DATA.push({
                      id_producto_pt:response[i].id_producto_pt,
                      cod_inv: response[i].cod_inv,
                      des_inv:response[i].des_inv,
                      lote_producto:response[i].lote_producto,
                      cod_producto:response[i].cod_producto,
                      des_producto:response[i].des_producto,
                      cant_producto:response[i].cant_producto,
                      estatus_producto:response[i].estatus_producto,
                      unidad_medida_producto:response[i].unidad_medida_producto,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = ['cod_producto',
                                            'des_producto',
                                            'cant_producto',
                                            'unidad_medida_producto',
                                              'ajustar',
                                              'desajustar'];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error[0].message);
            this.displayedColumns = ['cod_producto',
                                            'des_producto',
                                            'cant_producto',
                                            'estatus_producto',
                                            'unidad_medida_producto',
                                              'ajustar',
                                              'desajustar'];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  //////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////

ajustes_almacen_pt(obj:any,termino:any){
  try {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
    cod_menu: localStorage.getItem('sub_main'),
    cod_sesion: localStorage.getItem('cod_sesion'),
    dir_ip_client: localStorage.getItem('dir_ip')};
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
        if (response.resul) {
          var dialogRef:any;
          let datos = localStorage.getItem('cod_usuario');
          let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
          this.accion = 'PT';
            dialogRef = this.dialog.open(FormKeyComponent, {
              width: '100vw',
                    maxWidth: '100vw',
                    height:'100%',
              data: {cod_producto:obj.cod_producto,
                    des_producto:obj.des_producto,
                    cant_producto:obj.cant_producto,
                      accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
            });
      
            dialogRef.afterClosed().subscribe(result => {
              if(result != 'cerrar'){
                result.tipo_operacion = termino;
                console.log(result);
      
            this.ajustar_almacen_productos_terminados(result);
              }
            });
        }else{
          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 
   
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

ajustar_almacen_productos_terminados(xvalor:any){
  try {

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    console.log(xvalor);
    setTimeout(() => {
      this.servicio.ajustar_almacen_productos_terminadoshttp(xvalor).subscribe(
        response =>{
          if(response){
            this.dialogSpin.close();
            console.log(response,'una respuesta');
            this.xobj2 = response;
            this.ELEMENT_DATA = [];
                for (const i in response.resul) {

                  this.ELEMENT_DATA.push({
                    id_producto_pt:response.resul[i].id_producto_pt,
                    cod_inv: response.resul[i].cod_inv,
                    des_inv:response.resul[i].des_inv,
                    lote_producto:response.resul[i].lote_producto,
                    cod_producto:response.resul[i].cod_producto,
                    des_producto:response.resul[i].des_producto,
                    cant_producto:response.resul[i].cant_producto,
                    estatus_producto:response.resul[i].estatus_producto,
                    unidad_medida_producto:response.resul[i].unidad_medida_producto,
                    accion:'',
                    accion2:'',
                    accion3:''
                  });
                }
                this.displayedColumns = ['cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'unidad_medida_producto',
                                          'ajustar',
                                        'desajustar'];
                 /* this.dataSource = this.ELEMENT_DATA; */
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;

                this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition});
          }
        },
        error =>{
          this.dialogSpin.close();
          console.log('una respuesta');
          this.displayedColumns = ['cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'unidad_medida_producto',
                                          'ajustar',
                                          'desajustar'];
          this.dataSource = new MatTableDataSource<any>();
                this.dataSource.paginator = this.paginator;
                this.snackBar.open(<any>error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition});
        }
      );
    }, 3000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
//////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////
  
///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////

descargar_inv(text:any){
  try {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
    cod_menu: localStorage.getItem('sub_main'),
    cod_sesion: localStorage.getItem('cod_sesion'),
    dir_ip_client: localStorage.getItem('dir_ip')};
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
        if (response.resul) {
          var dialogRef:any;
          this.accion = text;
          let datos = localStorage.getItem('cod_usuario');
          let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
            dialogRef = this.dialog.open(FormReportesComponent, {
              width: '100vw',
                    maxWidth: '100vw',
                    height:'100%',
              data: {
                      accion:this.accion},
              panelClass: 'pedidos_modal_rep',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result != 'cerrar'){
                console.log(result);
                switch (result) {
                  case 'xlsx':
                    this.reportes_inventario_xlsx();
                    break;
                  case 'pdf':
        
                    break;
                  case 'txt':
        
                    break;
                  case 'csv':
        
                    break;
        
                  default:
                    this.reportes_rango_fecha_inventario_xlsx(result);
                    break;
                }
              }
            });
        }else{
          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

reportes_rango_fecha_inventario_xlsx(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      this.servicio.buscarInvPorRangoFechahttp(xvalor).subscribe(
        response=>{
          this.dialogSpin.close();
          console.log(response.resul);

          this.descarga_l001_seguimiento_xlsx(response.resul);
        },
        error=>{
          this.dialogSpin.close();
          console.log(error.error.resul);
          console.log(error.error.message);
          this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
      );
    }, 3000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

reportes_inventario_xlsx(){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      this.servicio.listarProductoTerminadohttp().subscribe(
        response=>{
          this.dialogSpin.close();
          for (const i in response) {
            response[i].cant_producto = parseInt(response[i].cant_producto.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.')).toFixed();
          }
          this.descarga_xlsx(response);
        },
        error=>{
          this.dialogSpin.close();
          console.log(error);
        }
      );
    }, 3000);


  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }

}

descarga_xlsx(xdatos:any){
  try {
    console.log(xdatos,'llego a la descarga');
    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet("Inventario(LOO1)");

    let header=["Id","Codigo","Producto", "Cantidad","Estatus","Unidad"];
    let headerRow = worksheet.addRow(header);

    for (let x1 of xdatos)
{
let x2=Object.keys(x1);
let temp=[]
for(let y of x2)
{
  temp.push(x1[y])
}
worksheet.addRow(temp)
}

let fname="Inventario de productos terminados (L001)";


workbook.xlsx.writeBuffer().then((data) => {
let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
});

  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

descarga_l001_seguimiento_xlsx(xdatos:any){
try {
  console.log(xdatos,'llego a la descarga');
  let workbook = new Workbook();

  let worksheet = workbook.addWorksheet("Inventario(LOO1)");

  let header=["Salida",
  "Entrada",
   "Codigo",
   "Producto",
   "Usuario",
   "Operación",
   "Cantidad",
   "Cantidad actual",
   "Restante",
   "Fecha",
   "Hora",
   "Comentarios",
   "Solicitud",
   "Factura/Nota",
   "Cliente"];
  let headerRow = worksheet.addRow(header);

  for (let x1 of xdatos)
{
let x2=Object.keys(x1);
console.log(x2);
let temp=[]
for(let y of x2)
{
temp.push(x1[y])
}
worksheet.addRow(temp)
}

let fname="Seguimiento del SKU '"+xdatos[0].cod_producto+"' (L001)";


workbook.xlsx.writeBuffer().then((data) => {
let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
});

} catch (error) {
  console.log(error);
  this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,})
}
}


applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
sin_permiso():void{
  try {
    this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
} 
}

