import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { parse } from 'querystring';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-smtp',
  templateUrl: './form-smtp.component.html',
  styleUrls: ['./form-smtp.component.css']
})
export class FormSmtpComponent implements OnInit {
admin:any = false;
nombre:any;
forma:FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
empresa:any = [];
menu:any;
des:any;
unidad_menu:any = false;
estatus:any;
nivel:any;

  constructor( 
      private dialog: MatDialog, 
      private fb:FormBuilder,
      private servicio:nkServices, 
      private hashkey:HashKeyServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private notifi:ToastNotificationService,
      private dialogRef: MatDialogRef<FormSmtpComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.menu = data.menu;
    this.unidad = data.unidad;
    this.des = data.des;
    if (this.data.operacion.toLowerCase() === this.atrb.ACCION_ACTUALIZAR.toLowerCase()) {
      
          this.forma = this.fb.group({
            'cod_smtp':[this.data.data.cod_smtp],
            'des_smtp':[this.data.data.des_smtp,[Validators.required]],
            'dir_smtp':[this.data.data.dir_smtp,[Validators.required]],
            'host_smtp':[this.data.data.host_smtp,[Validators.required]],
            'user_smtp':[this.data.data.user_smtp,[Validators.required]],
            'pass_smtp':[this.data.data.pass_smtp,[Validators.required]],
            'ssl_smtp':[this.data.data.ssl_smtp,[Validators.required]],
            'port_smtp':[this.data.data.port_smtp,[Validators.required]],
            'estatus':[this.data.data.estatus,[Validators.required]],
            'cod_empresa':[this.data.data.cod_empresa],
            'operacion':[this.data.operacion]
          });
    }
    if (this.data.operacion.toLowerCase() === this.atrb.ACCION_CREAR.toLowerCase()) {
      if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) <= 1 ) {
                this.forma = this.fb.group({
                  'des_smtp':['',[Validators.required]],
                  'dir_smtp':['',[Validators.required]],
                  'host_smtp':['',[Validators.required]],
                  'user_smtp':['',[Validators.required]],
                  'pass_smtp':['',[Validators.required]],
                  'ssl_smtp':['',[Validators.required]],
                  'port_smtp':['',[Validators.required]],
                  'estatus':['',[Validators.required]],
                  'cod_empresa':['',[Validators.required]],
                  'operacion':[this.data.operacion]
            });
      }else{
            this.forma = this.fb.group({
                  'des_smtp':['',[Validators.required]],
                  'dir_smtp':['',[Validators.required]],
                  'host_smtp':['',[Validators.required]],
                  'user_smtp':['',[Validators.required]],
                  'pass_smtp':['',[Validators.required]],
                  'ssl_smtp':['',[Validators.required]],
                  'port_smtp':['',[Validators.required]],
                  'estatus':['',[Validators.required]],
                  'cod_empresa':[''],
                  'operacion':[this.data.operacion]
            });
      }
    }
    }
 async ngOnInit(){
      if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) <= 1 && 
              this.data.operacion.toLowerCase() === this.atrb.ACCION_CREAR.toLowerCase()) {
        this.admin = true;
        await this.listarEmpresa();
      }
    this.estatus = this.atrb.ESTATUS;
  }
  async listarEmpresa(){
    try {
      let xvalor = {
        cod_empresa:this.atrb.COD_EMPRESA.cod_empresa,
        cod_rol:await this.atrb.ROL_USUARIOS.cod_rol,
        cod_app: await this.hashkey.encrypt(environment.path_token),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarUsuarios)
      }
      const response = await this.servicio.ApiResthttp(xvalor).toPromise();
        this.empresa = [...response[0]];
    } catch (error) {
      console.log(error);
    }
  }
  async  modalrespuesta(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
      var accion2 = '';
      var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:this.data.id_cliente,
              accion:accion2},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRefp.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result !== await this.atrb.CERRAR){
          if (this.data.operacion.toLowerCase() === this.atrb.ACCION_ACTUALIZAR.toLowerCase()) {
            this.forma.patchValue({
              cod_empresa: await this.hashkey.encrypt(this.forma.value.cod_empresa),
              pass_smtp: await this.hashkey.encrypt(this.forma.value.pass_smtp),
            });
           }
          if (this.data.operacion.toLowerCase() === this.atrb.ACCION_CREAR.toLowerCase()) {
            if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) <= 1 ) {
              this.forma.patchValue({
                cod_empresa: await this.hashkey.encrypt(this.forma.value.cod_empresa),
                pass_smtp: await this.hashkey.encrypt(this.forma.value.pass_smtp),
              });
             }else{
              this.forma.patchValue({
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa,
                pass_smtp: await this.hashkey.encrypt(this.forma.value.pass_smtp),
              });
             }
           }
            await this.util.cerrarSpinner();
            this.dialogRef.close(this.forma.value);
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async cerrarmodal(){
    try {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRef.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
   }
  }
}
