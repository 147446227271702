import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-inv-spl',
  templateUrl: './form-inv-spl.component.html',
  styleUrls: ['./form-inv-spl.component.css']
})
export class FormInvSplComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_cliente',{static:true}) cod_cliente:ElementRef;
  @ViewChild('des_cliente',{static:true}) des_cliente:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;

accion:any;
dialogSpin:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormInvSplComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

    }

  ngOnInit(): void {

    this.cod_cliente.nativeElement.value = this.data.cod_cliente;
    this.des_cliente.nativeElement.value = this.data.des_cliente;
    this.buscarInventarioSPL(null);

  }
  ngAfterViewInit() {

  }

  buscarInventarioSPL(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        var valor = {cod_cliente:this.data.cod_cliente}
         this.servicio.buscarInventarioSPLhttp(valor).subscribe(
           response =>{
            this.dialogSpin.close();
             console.log(response);
               this.ELEMENT_DATA = [];
                   for (const i in response) {

                     this.ELEMENT_DATA.push({
                       cod_cliente:response[i].cod_cliente,
                       des_cliente: response[i].des_cliente,
                       cod_producto:response[i].cod_producto,
                       des_producto:response[i].des_producto,
                       cant_producto:response[i].cant_producto,
                       estatus:response[i].estatus,
                     });
                   }
                   this.displayedColumns = [
                                             'cod_producto',
                                             'des_producto',
                                             'cant_producto',
                                             'estatus',
                                             'ajustar',
                                             'desajustar'

                                           ];
                   this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);

           },
           error =>{
            this.dialogSpin.close();
             console.log(error);
             console.log(<any>error.error.message);
             this.displayedColumns = [
                                      'cod_producto',
                                      'des_producto',
                                      'cant_producto',
                                      'estatus',
                                      'ajustar',
                                      'desajustar'

                                    ];
             this.dataSource1 = new MatTableDataSource<any>();
             this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition});
           }
         );

         if(msg == 'actualizar'){
           this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition});
         }
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }
  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  ajustes_almacen_spl(obj:any,termino:any){
    var dialogRef:any;
    this.accion = 'SPCL';
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
      dialogRef = this.dialog.open(FormKeyComponent, {
        width: '100vw',
      maxWidth: '100vw',
      height:'100%',
        data: {cod_producto:obj.cod_producto,
              des_producto:obj.des_producto,
              cod_cliente:this.data.cod_cliente,
              des_cliente:this.data.des_cliente,
              cant_producto:obj.cant_producto,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          result.tipo_operacion = termino;
          console.log(result);

      this.ajustar_almacen_productos_terminados_spl(result);
        }
      });
  }

  ajustar_almacen_productos_terminados_spl(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.servicio.ajustar_almacen_productos_terminados_splhttp(xvalor).subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');

              this.ELEMENT_DATA = [];
                  for (const i in response.resul) {

                    this.ELEMENT_DATA.push({
                      id_producto_pt:response.resul[i].id_producto_pt,
                      cod_producto:response.resul[i].cod_producto,
                      des_producto:response.resul[i].des_producto,
                      cant_producto:response.resul[i].cant_producto,
                      estatus:response.resul[i].estatus,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [
                                            'cod_producto',
                                            'des_producto',
                                            'cant_producto',
                                            'estatus',
                                            'ajustar',
                                            'desajustar'

                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);

                  this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log(error,'una respuesta');
            this.displayedColumns = [
                                        'cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'estatus',
                                        'ajustar',
                                        'desajustar'

                                      ];
            this.dataSource1 = new MatTableDataSource<any>();
                  this.snackBar.open(<any>error.message, 'Cerrar',{duration:150000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
          }
        );
      }, 3000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
