<div id="modal_carrito" style="background-color:#0076C7;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>{{AlertMensaje}}.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container"  >

        <form [formGroup]="forma" id="carrito_p1" class="row justify-content-center mt-1 mb-0" style=" height: auto; background-color: #f1ede9; border: 2px solid #0076C7;">
            <!-- <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#ca4aaf;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Cliente</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_cliente" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div> -->

                <div id="content_2" class=" mx-1 mat-elevation-z8 my-2 col-4  " style="border-radius: 1rem; background-color:#0076C7">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #FFF; font-size: 11px;">Productos</mat-label>
                        <input style="font-size: 12px; color: #FFF;" type="text" matInput placeholder="" (keyup)="captar($event)" class="inputP" formControlName="des_producto" [matAutocomplete]="auto" >
                        <mat-autocomplete  #auto="matAutocomplete" class="px-1">
                                <mat-option   style="font-size: 14px; color: #000;"(onSelectionChange)="captarlo($event)" *ngFor="let option of filteredOptions | async"  [value]="option.des_producto">
                                    <span>{{option.cod_producto}}</span> |
                                    <small>{{option.des_producto}}</small>
                                </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

            
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-2" style="border-radius: 1rem; border: 2px solid #0076C7; background-color:#0076C7">
                    <mat-form-field class="materialinput py-1 px-1" style="text-align: end;">
                        <mat-label style="color: #FFF; font-size: 11px;text-align: end;">Cantidad</mat-label>
                        <input style="font-size: 12px; color: #FFF;text-align: end; "  maxlength="11" type="text" (keyup)="ponerPuntosyComas($event)" id="inputCantidad" matInput placeholder="0,00" class="inputC" formControlName="cantidad" autocomplete="off">
                    </mat-form-field>
                </div>


            <!-- <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#1f39ce">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Unidad de manejo</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="unidad_manejo" #unidad_manejo autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(255, 94, 0);" >report_problem</mat-icon>
                </span>
                    </mat-form-field>
                </div>
            </div> -->


                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-2" style="border-radius: 1rem; border: 2px solid #0076C7; background-color:#0076C7">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #FFF; font-size: 11px;">Unidad de Medida</mat-label>
                        <input style="font-size: 12px; color: #FFF; " matTooltip="unidad de medida" maxlength="11" type="text " matInput placeholder="" #unidad_manejo autocomplete="off" [value]="UM" readonly>
                        <span style="color: #FFF; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                </span>
                    </mat-form-field>
                </div>


                <div >
                    
                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1"  (click)="onClickInput()" matTooltip="SUBIR FORMATO"  style=" color:rgb(255, 255, 255); background-color: var(--primary); border-radius: 1rem; height: 85%;" >
                        <mat-icon>file_upload</mat-icon>
                        <input id="import" class="upload_listas btn btn-block btn-lg btn-light d-none" type="file" (change)="onFileChange($event)" [multiple]="true" accept=".xlsx, .csv">
                    </button>


                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" (click)="filter_date_print()" type="submit"  matTooltip="DESCARGAR FORMATO" style=" color:rgb(255, 255, 255); background-color: var(--info); border-radius: 1rem; height: 85%;">
                        <mat-icon >file_download</mat-icon>
                    </button>

                
                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" (click)="add()" type="submit"  matTooltip="AGREGAR PRODUCTOS" style=" color:rgb(255, 255, 255); background-color: var(--success); border-radius: 1rem; height: 85%;">
                        <mat-icon >add</mat-icon>
                    </button>
      
      
                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" (click)="send()" matTooltip="GENERAR SOLICITUD" style=" color:rgb(255, 255, 255); background-color: #00B0FF; border-radius: 1rem;height: 85%;">
                        <mat-icon >done</mat-icon>
                    </button>
      
      
                    <button class="btn btn-sm btn-danger mat-elevation-z8 px-3 mt-1 mr-1" style="border-radius: 1rem; height: 85%;" (click)="cerrarmodal()"matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>

                </div>


        </form>  

        <div id="carrito_p6" class="row mt-2 mat-elevation-z8" style=" border: 2px solid #0076C7; background-color: #f1ede9; ">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                <table #table mat-table [dataSource]="dataSource" class=" text-center table-striped table-bordered table-condensed table-hover">
  
  
                  <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #0076C7;">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #0076C7;">
                        <h5><strong>Descripcion</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="cantidad">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #0076C7;">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cantidad | puntoporcoma}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="tipo_unidad_medida">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #0076C7;">
                        <h5><strong>Unidad de medida</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                  </ng-container>

                  <ng-container  matColumnDef="eliminar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #0076C7;">
                        <h5><strong></strong></h5>
                    </th>
                  
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button matTooltip="ELIMINAR" (click)='Delete(element.cod_producto)' class="btn btn-danger rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                            <mat-icon>cancel</mat-icon>
                        </button>
                       <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon>edit</mat-icon>
                        </button> -->
                    </td>
                  </ng-container>
  
  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>

                <mat-toolbar-row>
                    <mat-paginator class="w-100 mat-paginator-sticky border" style="background-color: #0076C7; border-radius: 0 0 1rem 1rem;" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                </mat-toolbar-row>

            </div>

        </div>
       
    </div>
</div>
