import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import * as moment from 'moment';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../servicios/formato_datapick';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormDireccionesComponent } from '../form-direcciones/form-direcciones.component';
import { MatCheckbox } from '@angular/material/checkbox';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}
export interface StateGroup2 {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
;  
  return opt.filter(item => item.toLowerCase().includes(filterValue));
};
export const _filter2 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().includes(filterValue));
};


@Component({
  selector: 'app-form-fechas',
  templateUrl: './form-fechas.component.html',
  styleUrls: ['./form-fechas.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class FormFechasComponent implements OnInit {

    range = new FormGroup({
      start: new FormControl(),
      end: new FormControl()
    });
    
  
    @ViewChild('table') table:MatTable<any>;
    @ViewChild('cod_dep',{static:true}) cod_dep:ElementRef;
    @ViewChild('des_prod',{static:true}) des_prod:ElementRef;
    @ViewChild('descrip_dep',{static:true}) descrip_dep:ElementRef;
    @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;
  
  value1 = '';
  nombre:any;
  forma:FormGroup;
  stateForm: FormGroup;
  parametro:any;
  accion:any;
  dialogSpin:any;
  unidad:any;
  unidadp:any = [];
stateGroupOptions: Observable<StateGroup[]>;
stateGroupOptions2: Observable<StateGroup2[]>;
stateGroups: StateGroup[];
stateGroups2: StateGroup2[];
  menu:any;
  producto:any = [];
  codigo:any = [];
  producto2:any = [];
  codigo2:any = [];
  lotepro:any = [];
  cant:any = false;
  lote:any = true;
  des:any;
  guard:any = false;
  email = new FormControl('', [Validators.required, Validators.email]);
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  dataSource=new MatTableDataSource<any>();
  serializedDate = new FormControl((new Date()).toISOString());
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource1=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
      datos: {} = {
              fecha_inicio: '',
              fecha_fin: '',
      }
  btnDesabilitado: boolean = true;
  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormFechasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data.parametro, 'data.parametro');
      this.parametro = data.parametro;
      if (this.parametro  !== undefined) {
        if (data.parametro === '8' ) {
          this.forma = fb.group({
            'cod_usuario':'',
            'des_usuario':'',
            'parametro':data.parametro,
            'cod_producto':'',
            'cod_cliente':'',
            'fecha_inicio':['',[Validators.required]],
            'fecha_fin':['',[Validators.required]],
            'deposito': '',
            'cargo': false,
            'descargo': false,
            'traslado': false,
            'accion':data.accion
    
          });
        }
        if (data.parametro === '9' ) {
          this.forma = fb.group({
            'cod_usuario':'',
            'des_usuario':'',
            'parametro':data.parametro,
            'cod_producto':'',
            'cod_cliente':['',[Validators.required]],
            'fecha_inicio':['',[Validators.required]],
            'fecha_fin':['',[Validators.required]],
            'deposito': '',
            'cargo': false,
            'descargo': false,
            'traslado': false,
            'accion':data.accion
    
          });
        }
        if(data.parametro === '15' ){
          this.forma = fb.group({
            'cod_usuario':'',
            'des_usuario':'',
            'parametro':'',
            'cod_producto':'',
            'cod_cliente':'',
            'cod_deposito':'',
            'fecha_inicio':['',[Validators.required]],
            'fecha_fin':['',[Validators.required]],
            'deposito': '',
            'productos': '',
            'cargo': false,
            'descargo': false,
            'traslado': false,
            'produccion': false,
            'embalaje': false,
            'consignacion': false,
            'todos': false,
            'devolucion': false,
            'facturacion': false,
            'anulacion': false,
            'accion':data.accion
    
          });
        }
        if(data.parametro === '3'){
          this.forma = fb.group({
            'cod_usuario':'',
            'des_usuario':'',
            'parametro':'',
            'fecha_inicio':['',[Validators.required]],
            'fecha_fin':['',[Validators.required]],
            'accion':data.accion
          });
        }
      }else{
        console.log('paso');
        this.forma = fb.group({
          'cod_usuario':'',
          'des_usuario':'',
          'parametro':'',
          'fecha_inicio':['',[Validators.required]],
          'fecha_fin':['',[Validators.required]],
          'accion':data.accion
  
        });
      }
    }

  ngOnInit(): void {
    if(this.data.parametro == '8' || this.data.parametro == '9'){

      this.stateGroupOptions = this.forma.get('cod_cliente')!.valueChanges
                                  .pipe(
                                    startWith(''),
                                    map(value => this._filterGroup(value))
                                  );
                    for (const i in this.data.clientes) {
                      console.log('pasobucle');
                      this.producto.push(this.data.clientes[i].cod_cliente+'-'+this.data.clientes[i].des_cliente);
                      this.codigo.push(this.data.clientes[i].des_cliente+'-'+this.data.clientes[i].cod_cliente);
                    }
                   
                    this.stateGroups= [{
                      letter: 'Clientes',
                      names: this.producto,
                      code: this.codigo
                    }];
    }

    if(this.data.parametro === '15'){

      this.listarDepositosEntrada();
      this.listarProductos()
    }
  }
  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }

    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup2[] {
    if (value) {
      return this.stateGroups2
        .map(group => ({letter: group.letter, names: _filter2(group.names, value), code: _filter2(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }

    return this.stateGroups2;
  }
  
  checked(event:any){
    /* this.forma.controls.cargo[0].value = true */
    console.log(event,'event')
    let values = [
      'cargo',
      'descargo',
      'traslado',
      'produccion',
      'embalaje',
      'consignacion',
      'devolucion',
      'facturacion',
      'anulacion',
    ]

    if(event.checked){
      for (const val of values) {
        this.forma.get(val).setValue(true)
        
      }

    }else{

      for (const val of values) {
        this.forma.get(val).setValue(false)
        
      }
      
    }

    console.log(this.forma,'this.forma')
    /*     console.log(this.forma.value.cargo,'cargo');
    console.log(this.forma.value.descargo,'descargo');
    console.log(this.forma.value.traslado,'traslado'); */
  }

  listarDepositosEntrada(){
    this.codigo = [];
    this.producto = [];
  
    this.servicio.listarDepositosReporthttp().subscribe(
      response =>{
  
        this.stateGroupOptions = this.forma.get('deposito')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_deposito+' '+response[i].des_cliente);
                    this.codigo.push(response[i].cod_deposito+' '+response[i].des_deposito);
                  }
  
                  this.stateGroups= [{
                    letter: 'Deposito',
                    names: this.producto,
                    code: this.codigo
                  }];
  
                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }
  listarProductos(){
    this.codigo2 = [];
    this.producto2 = [];
  
    this.servicio.listarProductosReporthttp().subscribe(
      response =>{
  console.log(response,'response del 2')
        this.stateGroupOptions2 = this.forma.get('productos')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup2(value))
                                );
                  for (const i in response) {
                    this.producto2.push(response[i].cod_producto2+' '+response[i].des_producto);
                    this.codigo2.push(response[i].cod_producto);
                  }
  
                  this.stateGroups2= [{
                    letter: 'productos',
                    names: this.producto2,
                    code: this.codigo2
                  }];
  
                  console.log(this.stateGroups2);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  modalrespuesta(){
    var accion2 = 'enviarcliente';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
      data: {id:this.data.id_cliente,
            accion:accion2},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          if(this.data.parametro == '15'){
            if(this.forma.value.cargo == false && this.forma.value.descargo == false && this.forma.value.traslado == false
              && this.forma.value.produccion == false && this.forma.value.embalaje == false && this.forma.value.consignacion == false
              && this.forma.value.devolucion == false && this.forma.value.facturacion == false && this.forma.value.anulacion == false){
              this.snackBar.open('Debe seleccionar al menos una operacion en el checkbox', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,})
                return null
            }
            if(this.forma.value.deposito == ''){
              this.snackBar.open('Debe seleccionar al menos un Deposito ', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,})
                return null
            }
          }

          this.forma.value.fecha_inicio =  moment(this.forma.value.fecha_inicio).format('DD/MM/YYYY');
          this.forma.value.fecha_fin =  moment(this.forma.value.fecha_fin).format('DD/MM/YYYY');
          
        this.dialogRefp.close(this.forma.value);
        }
      });
    }
    
  Hoy(){
    var accion2 = 'enviarcliente';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
      data: {id:this.data.id_cliente,
            accion:accion2},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          if(this.data.parametro == '15'){
          if(this.forma.value.cargo == false && this.forma.value.descargo == false && this.forma.value.traslado == false
            && this.forma.value.produccion == false && this.forma.value.embalaje == false && this.forma.value.consignacion == false
            && this.forma.value.devolucion == false && this.forma.value.facturacion == false && this.forma.value.anulacion == false){
            this.snackBar.open('Debe seleccionar al menos una operacion en el checkbox', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,})
              return null
          }
          if(this.forma.value.deposito == ''){
            this.snackBar.open('Debe seleccionar al menos un Deposito ', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,})
              return null
          }
          this.forma.value.fecha_inicio =  moment().format('DD/MM/YYYY');
          this.forma.value.fecha_fin =  moment().format('DD/MM/YYYY');
          
        }
          this.forma.value.fecha_inicio =  moment().format('DD/MM/YYYY');
          this.forma.value.fecha_fin =  moment().format('DD/MM/YYYY');

          console.log(this.forma.value,'this.forma.value');
          this.dialogRefp.close(this.forma.value);
        }
      });

  }

  /* leerDatos(e:any){

      let fecha = moment(e.target.value).format('DD/MM/YYYY')
      let nombre = e.target.name 

      this.datos = {...this.datos, [nombre]:fecha}

      if(this.datos[0] !== '' && this.datos[1] !== '' ){
          this.btnDesabilitado = false
      }

  }
  enviarDatos(){
    return this.datos
  }

  Hoy(){
    this.datos = {
      fecha_inicio: moment().format('DD/MM/YYYY'),
      fecha_fin: moment().format('DD/MM/YYYY')
    }

    return this.datos
  } */

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
