<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 5px;">
            <form [formGroup]="forma" class="">

                <div class="modal-content h-100">
                     <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-left"><strong>Nuevo centro de costo</strong> </h3>

                     </div>
                      
                      <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                
                                <div class=""> 
                                    <div class="col-md-12 col-sm-12 col-xs-12 ">
                                       <mat-form-field class="materialinput">
                                           <mat-label style="color: #000">Descripcion del centro de costo:</mat-label>
                                           <textarea
                                               rows="2" cols="12"
                                               style="font-size: 12px; color: black;" 
                                               matInput placeholder="Descripcion" 
                                               autocomplete="off" formControlName="des_centro"> </textarea>
                                       </mat-form-field>
                                     </div>
                               </div>
                               
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <mat-form-field class="materialinput">
                                        <mat-select style="font-size: 12px; color: black;" name="Departamentos" placeholder="Departamentos" formControlName="des_unidad" autocomplete="off">
                                            <mat-option *ngFor="let item of ELEMENT_DATA" [value]="item.departamentos" >{{item.cc}} - {{item.departamentos}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                  </div>
                                </div>
                            </div>
                        </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
        
                            <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button> <!-- [disabled]="forma.invalid" -->
                        </div>
                    </div>

                </div>
               
            </form>
        
        </div>

    </div>

</div>
