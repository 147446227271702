import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormProductosComponent } from 'src/app/componentes/shared/modal/form-productos/form-productos.component';
import { FormFormulacionComponent } from 'src/app/componentes/shared/modal/form-formulacion/form-formulacion.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormOrdHistorialMppComponent } from 'src/app/componentes/shared/modal/form-ord-historial-mpp/form-ord-historial-mpp.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { environment } from 'src/environments/environment';
import { FormDocumentosEmpresaComponent } from 'src/app/componentes/shared/modal/form-documentos-empresa/form-documentos-empresa.component';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-gestionar-documentos',
  templateUrl: './gestionar-documentos.component.html',
  styleUrls: ['./gestionar-documentos.component.css']
})
export class GestionarDocumentosComponent implements OnInit {

  documentos:any = [];
  parametros:any = [];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    public dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ) {}

  ngOnInit(){
    this.listarDocumentos(null);
    this.buscardefiniciones();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
  }

  /////////////////////////////////////////////////////////////////////
 async listarDocumentos(msj:any){
    try {
      let msg = msj;
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let xvalor = {
        cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
      }
       const response = await this.servicio.listarDocumentoshttp(xvalor).toPromise();
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                                cod_documento:response[i].cod_documento,
                                des_documento:response[i].des_documento,
                                cod_empresa: response[i].cod_empresa,
                                estatus:response[i].estatus,
                                fecha_inicio:response[i].fecha_inicio,
                                hora_inicio:response[i].hora_inicio
                              });
                  }
                  this.displayedColumns = [
                                            'cod_documento',
                                            'des_documento',
                                            'cod_empresa',
                                            'estatus',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'procesos'
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                  if(msg === await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                      await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                  }else{
                    await this.util.cerrarSpinner();
                  }
    } catch (error) {
      console.log(error);
      this.displayedColumns = [
                              'cod_documento',
                              'des_documento',
                              'cod_empresa',
                              'estatus',
                              'fecha_inicio',
                              'hora_inicio',
                              'procesos'
      ];
      this.dataSource = new MatTableDataSource<any>();
            this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await error.error.message);
    }
  }

 async buscardefiniciones(){
    try {
      var valor = {cod_menu: localStorage.getItem('sub_main')};
        const response = await this.servicio.buscardefinicioneshttp(valor).toPromise();
            this.documentos = [];
            this.parametros = [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === this.atrb.DOCUMENTOS.documentos.toUpperCase()) {
                this.documentos.push(response[i]);
              }
            }
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === this.atrb.DOCUMENTOS.parametros_documentos.toUpperCase()) {
                this.parametros.push(response[i]);
              }
            }
    } catch (error) {
      console.log(error.error.message);
    }
  }

//////////////////////FUNCION DE ACCION/////////////////////////

async  update_insert_data(obj:any, text:any){
  try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
                        if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def;
                                  val_def = {
                                    val_def:await this.atrb.ACCESO_VALDEF.gestionar_documentos,
                                    tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                    cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                                  }
                              await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                          }
                          await this.util.cerrarSpinner();
                          this.accion = text;
                          var dialogRef = this.dialog.open(FormmMsgComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {id_producto:obj.id_producto,
                              cod_producto:obj.cod_producto,
                              des_producto:obj.des_producto,
                              estatus_producto:obj.estatus_producto,
                              tamano_producto:obj.tamano_producto,
                              estatus_fiscal:obj.estatus_fiscal,
                              unidad_medida_producto:obj.unidad_medida_producto,
                              unidad_manejo:obj.unidad_manejo,
                              tipo_producto:obj.tipo_producto,
                              documentos:this.documentos,
                              accion:this.accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal_perfiles',
                            backdropClass: "bdrop"
                          });
                        }else{
                          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def;
                                  val_def = {
                                    val_def:await this.atrb.ACCESO_VALDEF.gestionar_documentos,
                                    tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                    cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                                  }
                              await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                          }
                          let objeto = {
                            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
                            mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
                            des_parametro: await this.atrb.OPERACIONES.documentos
                          };
                          await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
                          await this.util.cerrarSpinner();
                          this.accion = text;
                           dialogRef = this.dialog.open(FormmMsgComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {
                              documentos:this.documentos,
                              accion: await this.atrb.PARAMETROS_MSM.crear_documentos
                            },
                            disableClose: true,
                            panelClass: 'pedidos_modal_perfiles',
                            backdropClass: "bdrop"
                          });
                        }
                        dialogRef.afterClosed().subscribe(async result => {
                          await this.util.abrirSpinner();
                          if(result != 'cerrar'){
                            result.cod_empresa = await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa);
                            this.gestionDocumento(result);
                          }else{
                            await this.util.cerrarSpinner();
                          }
                        });
  } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}
async  definicionesDocumentos(obj:any){
  try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
                          let val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_documentos,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.select,
                            cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                          }
                          await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                          await this.util.cerrarSpinner();
                        let dialogRef = this.dialog.open(FormDocumentosEmpresaComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {
                              data:obj,
                              parametros:this.parametros,
                             accion:''
                            },
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                        dialogRef.afterClosed().subscribe(async result => {
                          if(result != 'cerrar'){
                            await this.util.cerrarSpinner();
                          }else{
                            await this.util.cerrarSpinner();
                          }
                        });
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}

async gestionDocumento(xvalor:any){
      try {
          const response = await this.servicio.gestionDocumentoshttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
          await this.listarDocumentos(null);
          await this.notifi.success(response.message);
      } catch (error) {
        console.log(error);
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
        await this.listarDocumentos(null);
      }
   }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
