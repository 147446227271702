import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-historial-parametros-ventas',
  templateUrl: './form-historial-parametros-ventas.component.html',
  styleUrls: ['./form-historial-parametros-ventas.component.css']
})
export class FormHistorialParametrosVentasComponent implements OnInit {

  producto:any = [];
  producto_2:any = [];
dialogSpin:any;
codigo:any = [];
codigo_2:any = [];
  forma:FormGroup;
  accion:any;
  roles:any;

  rol = [];
  unidad=[];
  unidadp=[];

  parametros_precios:any = [];
  parametros_operacion:any = [];
  parametros_montos:any = [];
  key_hash:any;
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  secuencia:any;
  menur3:any;
  unidades:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormHistorialParametrosVentasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

    console.log(data, 'primero');

    this.forma = fb.group({
      'cod_definicion': '',
      'des_definicion': '',
      'descrip_definicion':'',
      'cod_usuario':localStorage.getItem('cod_usuario'),
      'des_usuario':localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido'),
      'operacion':'',
      'tipo_monto':'',
      'val_def':'0,00'
    });
   }

ngOnInit(): void {
  this.listar_historial_oper_ventas(null);
}
@ViewChild(MatPaginator) paginator: MatPaginator;

ngAfterViewInit() {
  this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    this.dataSource.paginator = this.paginator;
}

/////////////////////////////////////////////////////////////////////
listar_historial_oper_ventas(msj:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      let msg = msj;
      this.ELEMENT_DATA = [];
      this.servicio.listarHistorialOperVentashttp(this.data).subscribe(
        response =>{
          console.log(response);
          this.dialogSpin.close();
          for (const i in response) {

            this.ELEMENT_DATA.push({
              cod_definicion:response[i].cod_definicion,
              des_definicion:response[i].des_definicion,
              descrip_definicion:response[i].descrip_definicion,
              cod_usuario:response[i].cod_usuario,
              des_usuario:response[i].des_usuario,
              val_before:response[i].val_before,
              val_after:response[i].val_after,
              operacion:response[i].operacion,
              fecha:response[i].fecha,
              hora:response[i].hora,
              accion:'',
              accion2:'',
              accion3:''
            });
          }
            this.displayedColumns = [
              'cod_definicion',
              'des_definicion',
              'descrip_definicion',
              'val_before',
              'val_after',
              'operacion',
              'cod_usuario',
              'des_usuario',
              'fecha',
              'hora',
            ];
          
    
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        },
        error =>{
          this.dialogSpin.close();
          this.displayedColumns = [
            'cod_definicion',
            'des_definicion',
            'descrip_definicion',
            'val_before',
            'val_after',
            'operacion',
            'cod_usuario',
            'des_usuario',
            'fecha',
            'hora',
          ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
        }
      );
     
      if(msg == 'actualizar'){
        this.dialogSpin.close();
        this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
      return null;
    }, 1000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}


cerrarmodal(): void {
  var accion_ = 'salir'
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
    data: {id:'',
          accion:accion_},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
}

cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 500);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
