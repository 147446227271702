
<div id="modal_carrito" class=" container-fluid" *ngIf="accion">
        <div class="row h-100">
            <div  class="container-fluid rounded" style=" border: 2px solid #2b69dd; background-color:#29388f;">
                <div class="row">
                    <div class="col-md-3">
                        <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                            <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; border-radius: 1rem 0 0 0; background-color:#202333;">
                                <div class="col-md-12 px-0 py-2 h-100">
                                    <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                                        <mat-form-field  class="materialinput py-1 px-1 ">
                                            <mat-label style="color: rgb(255, 255, 255); font-size: 12px;">Definición</mat-label>
                                            <mat-select id="des_definicion"  #des_definicion  style="font-size: 12px; color: rgb(255, 255, 255);" formControlName="des_definicion" autocomplete="off">
                                                <mat-option  *ngFor="let item of parametros_precios" [value]="item.concepto_definicion">{{item.concepto_definicion}}-{{item.descrip_definicion}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
                                
                                <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                                    <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4;">
                                        <mat-form-field  class="materialinput py-1 px-1 ">
                                            <mat-label style="color: rgb(255, 255, 255); font-size: 12px;">Operación</mat-label>
                                            <mat-select id="operacion"  #operacion  style="font-size: 12px; color: rgb(255, 255, 255);" formControlName="operacion" autocomplete="off">
                                                <mat-option  *ngFor="let item of parametros_operacion" [value]="item.concepto_definicion">{{item.concepto_definicion}}-{{item.descrip_definicion}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                 
                            </div>
                            <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
                                <div id="content_1" class="col-md-6 px-0 py-2 h-100">
                                   
                                </div>
                                
                            </div>
                            <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
            
                                <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                                    <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#0f0a6b">
                                        <mat-form-field class="materialinput py-1 px-1">
                                            <mat-label style="color: #fff; font-size: 11px;">Descripción</mat-label>
                                            <input id="descrip_definicion" style="font-size: 12px; color: #fff; " type="text " matInput placeholder="" formControlName="descrip_definicion" #descrip_definicion autocomplete="off">
                                            <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                                        </mat-form-field>
                                    </div>
                                </div>
            
                            </div>
                            <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
                    
                              
                                
                    
                            </div>
                            <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
                    
                            
                    
                            </div>
                            <div id="carrito_p1_c" class="row mat-elevation-z8 d-flex justify-content-end" style=" border: 2px solid #475bca; background-color:#202333;">
                    
                                <div id="content_boton1" class=" col-md-3 px-0 py-2 h-100">
                                    <div class=" mx-1 h-100">
                                        <button class="btn btn-block btn-lg btn-light h-100" (click)="listar_parametros_acciones_accesos('actualizar')" matTooltip="ACTUALIZAR" style=" color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                                      <mat-icon >cached</mat-icon>
                                    </button>
                                    </div>
                                </div>
                                
                                <div id="content_boton1" class=" col-md-3 px-0 py-2 h-100">
                                    <div class=" mx-1 h-100">
                                        <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GUARDAR" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                                          <mat-icon >save</mat-icon>
                                      </button>
                                    </div>
                                </div>
                                <div class=" col-md-3 px-0 py-2 h-100">
                                    <div class="  mx-1 h-100">
                                        <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                                          <mat-icon >exit_to_app</mat-icon>
                                      </button>
                                    </div>
                                </div>
                    
                            </div>
                            <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333; border-radius: 0 0 0 1rem;">
                                <div class="col-md-12 px-0 py-2 h-100">
                                    <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4;">
                                        <mat-form-field class="materialinput py-1 px-1">
                                            <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                                            <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              <mat-icon>search</mat-icon>
                                          </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                              
                            </div>
                        </form>
                    </div>
                    <div class="col-md-9">
                        <div class="row py-1">
                            <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-start">
                                <span class="text-white fw-bold">Definición de acciones del sistema</span>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                                <input class="form-control mr-1" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                                <button (click)="modalrespuesta()" matTooltip="GUARDAR"  class="col-md-1 col-sm-12 col-xs-12 mr-1" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(54, 63, 190);"><mat-icon >save</mat-icon></button>
                                <button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)="listar_parametros_acciones_accesos('actualizar')"><mat-icon >sync</mat-icon></button>
                                <button matTooltip="SALIR" (click)="cerrarmodal()" class="col-md-1 col-sm-12 col-xs-12" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(216, 68, 58);"><mat-icon >exit_to_app</mat-icon></button>
                            </div>
                        </div>
                        <div class="row modalgeneral" style=" border: 2px solid #2b69dd; background-color:#29388f;">
                            <div class="col-md-12 table-responsive  h-100" >
                                <table #table mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">

                                    <ng-container matColumnDef="cod_definicion">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Codigo</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_definicion}}</b></td>
                                
                                    </ng-container>
                
                                    <ng-container matColumnDef="des_definicion">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Definición</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_definicion}}</b></td>
                                
                                    </ng-container>
                
                                    <ng-container matColumnDef="descrip_definicion">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Descripción</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.descrip_definicion}}</b></td>
                                
                                    </ng-container>
                
                                    <ng-container matColumnDef="cod_usuario">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Id</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_usuario}}</b></td>
                                
                                    </ng-container>
                                    <ng-container matColumnDef="des_usuario">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Usuario</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_usuario}}</b></td>
                                
                                    </ng-container>
                                    <ng-container matColumnDef="estatus">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Estatus</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                                
                                    </ng-container>
                                    <ng-container matColumnDef="operacion">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Operación</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.operacion}}</b></td>
                                
                                    </ng-container>
        
                                    <ng-container matColumnDef="fecha_inicio">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Fecha</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
                                
                                    </ng-container>
                                    <ng-container matColumnDef="val_def">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Valor</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;  border-radius: 1rem; "><b>{{element.val_def}}</b></td>
                                
                                    </ng-container>
                                    <ng-container matColumnDef="hora_inicio">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Hora</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
                                
                                    </ng-container>
                            
                                    <ng-container matColumnDef="usuarios">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                                            <h5><strong>Usuarios</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px; color: #fff;">
                                            <button class="rounded-pill"  matTooltip="USUARIOS" (click)='parametros_acciones_usuarios(element)' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color:#4979d3; border:  outset 3px rgba(47, 40, 119, 0.692);">
                                        <mat-icon >accessibility_new</mat-icon>
                                        </button>
                                        </td>
                                        
                                    </ng-container>
        
                                    <ng-container matColumnDef="definiciones">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                                            <h5><strong>A/I</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                                            <button *ngIf="element.estatus === 'I'" class="rounded-pill"  matTooltip="ACTIVAR" (click)='activar_desactivar_acciones(element)' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(42, 77, 194);border: outset 3px rgba(47, 40, 119, 0.692);">
                                                <mat-icon >power_settings_new</mat-icon>
                                            </button>
                                            <button *ngIf="element.estatus === 'A'" class="rounded-pill"  matTooltip="DESACTIVAR" (click)='activar_desactivar_acciones(element)' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(196, 45, 43);border: outset 3px rgba(47, 40, 119, 0.692);">
                                                <mat-icon >power_settings_new</mat-icon>
                                            </button>
                                        
                                        </td>
                                    
                                    </ng-container>
        
                                    <ng-container matColumnDef="tiempo">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                                            <h5><strong>Historial</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                                            <button class="rounded-pill"  matTooltip="HISTORIAL" (click)='historial_parametros_ventas(element)' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(15, 177, 34); border: outset 3px rgba(47, 40, 119, 0.692);">
                                                <mat-icon >schedule</mat-icon>
                                            </button>
                                        
                                        </td>
                                    
                                    </ng-container>
                
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                                </table>
                                <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>

<div id="modal_carrito" class=" container-fluid" *ngIf="!accion">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div class="row " style="height: 80%;">
            <div  class="col-md-1">
                
            </div>
            
            <div class="col-md-10 h-100" style="border-radius: 1rem 1rem 0 0;" >
                <div id="carrito_p8" class="row h-100" style=" border: 4px outset #2b69dd; border-radius: 1rem 1rem 0 0; background-color:#29388f;">
                    <div class="col-md-12 col-sm-12 col-xs-12 px-2 py-2 table-responsive  h-100" >
                        <table #table mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">

                            <ng-container matColumnDef="cod_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Codigo</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="des_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Definición</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="descrip_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Descripción</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.descrip_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="cod_usuario">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Id</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_usuario}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="des_usuario">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Usuario</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_usuario}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="estatus">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Estatus</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="operacion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Operación</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.operacion}}</b></td>
                        
                            </ng-container>

                            <ng-container matColumnDef="fecha_inicio">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Fecha</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="hora_inicio">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Hora</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="val_def">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Valor</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;  border-radius: 1rem;"><b>{{element.val_def}}</b></td>
                        
                            </ng-container>

                            <ng-container matColumnDef="tipo_monto">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Tipo</strong></h5>
                                </th>
                                <td [ngClass]="{'pametro_ventas_usd':  element.tipo_monto === 'USD','pametro_ventas_bs':  element.tipo_monto === 'BS'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;  "><b>{{element.tipo_monto}}</b></td>
                        
                            </ng-container>
                       
                            <ng-container matColumnDef="update">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Editar</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px; color: #fff;">
                                    <button class="rounded-pill"  matTooltip="EDITAR" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color:#4979d3; border:  outset 3px rgba(47, 40, 119, 0.692);">
                                  <mat-icon >edit</mat-icon>
                                  </button>
                                </td>
                                
                            </ng-container>

                            <ng-container matColumnDef="definiciones">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff;">
                                    <h5><strong>Definiciones</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                                    <button class="rounded-pill"  matTooltip="DEFINIR VALOR" (click)='monto_parametro_ventas(element)' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(43, 78, 196); border: outset 3px rgba(47, 40, 119, 0.692);">
                                        <mat-icon >settings</mat-icon>
                                    </button>
                                   
                                </td>
                               
                            </ng-container>
                            <ng-container matColumnDef="tiempo">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff;">
                                    <h5><strong>Historial</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                                    <button class="rounded-pill"  matTooltip="HISTORIAL" (click)='historial_parametros_ventas(element)' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(15, 177, 34); border: outset 3px rgba(47, 40, 119, 0.692);">
                                        <mat-icon >schedule</mat-icon>
                                    </button>
                                   
                                </td>
                               
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                        </table>
                        <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
        <div class="row " style="height: 10%;">
            <div  class="col-md-1">
                
            </div>
            <div  class="col-md-10 py-2" style=" border: 4px inset #2b69dd; border-radius: 0 0 1rem 1rem; background-color:#29388f;">
                <div class="row">
                    <div class="col-md-10 ">
                        <mat-form-field class="materialinput py-1 px-1 ">
                            <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                            <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                              <mat-icon>search</mat-icon>
                          </span>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 d-flex  justify-content-end py-2">
                        <button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(221, 19, 97); border: outset 3px rgb(216, 91, 139);" (click)="listar_parametros_oper_ventas('actualizar')"><mat-icon >sync</mat-icon></button>
                        <button matTooltip="SALIR" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: #c71010; border: outset 3px #af4b4b;" (click)='cerrarmodal()'><mat-icon >forward</mat-icon></button>
                    </div>
                </div>
            </div>
            <div  class="col-md-1">
                
            </div>
        </div>
    </form>
</div>

