import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { map, startWith } from 'rxjs/operators';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-form-licencia',
  templateUrl: './form-licencia.component.html',
  styleUrls: ['./form-licencia.component.css']
})
export class FormLicenciaComponent implements OnInit {

  rlsr:any ;
  hide = true;
  nombre:any;
  forma:FormGroup;
  accion:any;
  unidad:any;
  rol:any;
  unidadp:any=[];
  roles:any=[];
  stateGroups: StateGroup[];
  serializedDate = new FormControl((new Date()).toISOString());
  tipo_licencias:any;
  licencia:any = [];
  codigo:any;
  estatus:any;
    constructor( 
      private fb:FormBuilder,
      private dialog: MatDialog, 
      private hashkey:HashKeyServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private dialogRef: MatDialogRef<FormLicenciaComponent>,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.rlsr =  hashkey.decrypt(localStorage.getItem('rlsr'));;
        console.log(this.data, 'primero');
     this.accion = data.accion;
     this.licencia = data.tipo_licencia;
     if (this.accion.toLowerCase() === this.atrb.ACCION_ACTUALIZAR.toLowerCase()) {
      this.forma = fb.group({
        'tipo_licencias':[data.data.des_licencia,[Validators.required]],
        'estatus':[data.data.estatus,[Validators.required]],
        'des_licencia':'',
        'descrip_licencia':[data.data.descrip_licencia,[Validators.required,Validators.maxLength(50)]],
        'modo':'',
        'cod_licencia':data.data.cod_licencia
      });
     }
     if (this.accion.toLowerCase() === this.atrb.ACCION_CREAR.toLowerCase()) {
      this.forma = fb.group({
        'tipo_licencias':['',[Validators.required]],
        'estatus':['',[Validators.required]],
        'des_licencia':'',
        'descrip_licencia':['',[Validators.required,Validators.maxLength(50)]],
        'modo':''
      });
     }
      }
  
    ngOnInit(){
      /* this.tipo_licencias = this.forma.get('tipo_licencias')!.valueChanges
            .pipe(
              startWith(''),
              map(value => this.filterGroup(value))
            );
          for (const i in this.data.tipo_licencia) {
                this.licencia.push(this.data.tipo_licencia[i].concepto_definicion);
          }

        this.stateGroups= [{
          letter: 'Licencias',
          names: this.licencia,
          code: this.licencia
        }]; */
        this.estatus = this.atrb.ESTATUS;
    }

    /* private filterGroup(value: string): StateGroup[] {
      if (value) {
        return this.stateGroups
          .map(group => ({letter: group.letter, names: filter(group.names, value), code: filter(group.code, value)}))
          .filter(group => group.names.length > 0 );
      }
      return this.stateGroups;
   } */
    
   async actualizardatos(res:any){
    await this.util.abrirSpinner();
            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
              data: {id:'',
                    accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(async result => {
              if (result != 'cerrar') {
                await this.util.cerrarSpinner();
                if(this.accion.toLowerCase() === this.atrb.ACCION_ACTUALIZAR.toLowerCase()){
                  this.forma.patchValue({
                    des_licencia: this.forma.value.tipo_licencias,
                    modo: await this.atrb.ACCION_ACTUALIZAR
                  });
                  this.dialogRef.close(this.forma.value);
                }else{
                  this.forma.patchValue({
                    des_licencia: this.forma.value.tipo_licencias,
                    modo: await this.atrb.ACCION_CREAR
                  });
                  this.dialogRef.close(this.forma.value);
                }
              }else{
                  await this.util.cerrarSpinner();
              }
            });
    }
   async cerrarmodal(){
            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
              data: {id:'',
                    accion:'salir'},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(async result => {
              
              if (result != 'cerrar') {
                await this.util.abrirSpinner();
                this.dialogRef.close('cerrar');
              }
            });
    }
}
