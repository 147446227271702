import { Injectable, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { nkServices } from './app.nkservicios.services';

@Injectable({
  providedIn: 'root'
})
export class authService {
  private  read:any;
  private create:any;
  private update:any;
  private delete:any;
  private special:any;
  private accion_1:any;
  private accion_2:any;
  private accion_3:any;
  private accion_4:any;
  private accion_5:any;
  private key_hash:any;
  private key1:any = 'leer';
  private key2:any = 'crear';
  private key3:any = 'editar';
  private key4:any = 'borrar';
  private key5:any = 'especial';
  private auth_menu:any = [];

constructor(
    private servicio:nkServices,
    private snackBar: MatSnackBar) {  
        this.validar_permisos_acciones();  
 }

  validar_permisos_acciones(){
      try {
        this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
        cod_menu: localStorage.getItem('sub_main'),
        cod_sesion: localStorage.getItem('cod_sesion'),
        dir_ip_client: localStorage.getItem('dir_ip'),
      sesion_unica:sessionStorage.getItem('sesion_unica')};

this.servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
response => {
this.auth_menu = response;

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
localStorage.removeItem('accion_1');
localStorage.removeItem('accion_2');
localStorage.removeItem('accion_3');
localStorage.removeItem('accion_4');
localStorage.removeItem('accion_5');
for (const i in this.auth_menu) {
switch (this.auth_menu[i].des_accion) {
case this.key1:
localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
break;
case this.key2:
localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
break;
case this.key3:
localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
break;
case this.key4:
localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
break;
case this.key5:
localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
break;
default:
break;
}
}

this.accion_1 = localStorage.getItem('accion_1');
this.accion_2 = localStorage.getItem('accion_2');
this.accion_3 = localStorage.getItem('accion_3');
this.accion_4 = localStorage.getItem('accion_4');
this.accion_5 = localStorage.getItem('accion_5');

for (const i in this.auth_menu) {
if (this.auth_menu[i].cod_accion === this.accion_1) {
this.read = true;
}
if (this.auth_menu[i].cod_accion === this.accion_2) {
this.create = true;
}
if (this.auth_menu[i].cod_accion === this.accion_3) {
this.update = true;
}
if (this.auth_menu[i].cod_accion === this.accion_4) {
this.delete = true;
}
if (this.auth_menu[i].cod_accion === this.accion_5) {
this.special = true;
}

}

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
},
error =>{
localStorage.removeItem('accion_1');
localStorage.removeItem('accion_2');
localStorage.removeItem('accion_3');
localStorage.removeItem('accion_4');
localStorage.removeItem('accion_5');

}
);
      } catch (error) {
        console.log(error);
      }
  }
}
