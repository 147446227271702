import { Component, ElementRef, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { nkServices } from '../../shared/servicios/app.nkservicios.services';
import { Routes, RouterModule, Router } from '@angular/router';
import { HashKeyServices } from '../../shared/servicios/app.hashkey.services';
import { AtributosService } from '../../shared/servicios/atributos.service';
import { AppUtilService } from '../../shared/servicios/app.util.service';
import { ToastNotificationService } from '../../shared/servicios/toast-notification.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  sesion:any;
  cod_menu:any;
  object1:any;
  menu:any=[];
  menu2:any = [];
  menu3:any = [];
  menu4:any = [];
  roulink:any;
  unidad:any;
  usuario:any;
  menuorder3:any;
  menuoreder4:any;
  mostrar:any;
  ruta:any;
  @ViewChild('link') link: ElementRef;
  constructor(
    private servicio:nkServices, 
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ){}

  ngOnInit(){
    this.sesion = this.hashkey.decrypt(this.atrb.COD_PERFIL.cod_perfil);
    this.mostrar = parseInt(localStorage.getItem('cod_valid')); 
    console.log(typeof this.mostrar);
    this.usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
     this.object1 ={cod_usuario:this.sesion, cod_menu:this.cod_menu}
    this.buscarusuariorol(this.object1);
  }

 async buscarusuariorol(xobj:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      this.cod_menu=localStorage.getItem('cod_sub');
      this.object1.cod_menu = this.cod_menu;
      const response = await this.servicio.buscarUSUARIOROLsubmenuhijohttp(xobj).toPromise();
          for (const i in response) {
            this.menu.push(response[i].cod_menu, response[i].padre_menu);
          }
          const result = this.menu.reduce((acc,item)=>{
            if(!acc.includes(item)){
              acc.push(item);
            }
            return acc;
          },[]);
          await this.util.cerrarSpinner();
          this.cargarmenu(result.sort());
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
 async cargarmenu(arrymenu:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      for (const i in arrymenu) {
      const response = await  this.servicio.buscarMENUDIRhttp(arrymenu[i]).toPromise();
            for (const i in response) {
              response[i].padre_menu = parseInt(response[i].padre_menu);
              if(parseInt(response[i].nivel_padre_menu)  != 1){
                this.menu3.push(response[i]);
              }
            }
            for (const i in this.menu3) {
              let cod_menu = localStorage.getItem('cod_menu');
              if (this.menu3[i].padre_menu === cod_menu) {
                this.menu4.push(this.menu3[i]);
              }
            }
          this.menu3.sort(function (a, b){
            return (a.ord_menu  - b.ord_menu );
        })
      }
      await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  cambiarrouter(ruta:any){
    this.mostrar = 2;
    this.ruta = ruta;
  }
}
