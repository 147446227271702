<!-- <div class="container-fluid mat-elevation-z8" style="height: 100%; padding: 5%;">
    <form [formGroup]="forma" class="materialformtamaño">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 ">
                <table class=" table-condensed table-striped">
                    <thead class="text-center" style="font-size: 14px; background-color: grey; padding: 5px;">
                        <th>Orden</th>
                        <th>Codigo</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                    </thead>
                    <tbody>
                        <tr class="text-center" style="font-size: 12px; background-color: #cccccc;">
                            <td>{{orden_prod}}</td>
                            <td>{{cod_prod}}</td>
                            <td>{{des_prod}}</td>
                            <td>{{cant_orden_producto}}</td>
                        </tr>
                        <tr class="text-center" style="font-size: 12px; background-color: #cccccc;">
                            <td>{{orden_prod_mpp}}</td>
                            <td>{{cod_prod_mpp}}</td>
                            <td>{{des_prod_mpp}}</td>
                            <td>{{cant_orden_producto_mpp}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="row">

            <div class="col-md-6 col-sm-6 col-xs-6 ">

                <p style="color: black; font-size: 13px;">
                    Cantidad de materia prima preparada solicitada
                </p>
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Cantidad solicitada</mat-label>
                        <input #cant_inv_sg matInput placeholder="" formControlName="cant_producto_solicitado" autocomplete="off" readonly>
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                      <mat-icon>g</mat-icon>
                      </span>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 ">
                <p style="color: black; font-size: 13px;">
                    Resultado obtenido del proceso de fabricación
                </p>
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Resultado de fabricación</mat-label>
                        <input maxlength="17" #cantidad matInput placeholder="" formControlName="cant_producto_fabricado" autocomplete="off" readonly>
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                      <mat-icon>g</mat-icon>
                      </span>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12  text-center">
                <table class="table-condensed table-striped">
                    <thead class="text-center" style="font-size: 14px; background-color: grey;">
                        <th>Total fabricado</th>
                        <th></th>
                        <th>Total en inv</th>
                        <th></th>
                        <th>Total actual</th>
                        <th></th>
                        <th>Cantidad formula</th>
                        <th></th>
                        <th>Cantidad sugerida</th>
                    </thead>
                    <tbody>
                        <tr class="text-center" style="font-size: 12px; background-color: #cccccc;">
                            <td>{{cant_producto_fabricado}}</td>
                            <td>+</td>
                            <td>{{cant_inv_prod_mp}}</td>
                            <td>=</td>
                            <td>{{total_mp}}</td>
                            <td>/</td>
                            <td>{{cant_formula}}</td>
                            <td>=</td>
                            <td>{{sugerido}} / Unidades</td>
                        </tr>
                    </tbody>
                </table>
            </div>

             <div class="col-md-3 col-sm-3 col-xs-3  text-center">
                Total fabricado: <strong><span style="border-bottom: 1px solid #000;">{{cant_producto_fabricado}}</span></strong>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-1   text-center">
                <strong><span style="border-bottom: 1px solid #000;">/</span></strong>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3  text-center">
                Cantidad formula: <strong><span style="border-bottom: 1px solid #000;">{{cant_formula}}</span></strong>
            </div>
            <div class="col-md-1 col-sm-1 col-xs-1  text-center">
                <strong><span style="border-bottom: 1px solid #000;">=</span></strong>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3  text-center">
                Cantidad sugerida: <strong><span style="border-bottom: 1px solid #000;">{{cant_formula}}</span></strong>
            </div>

        </div>
        <br>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12  text-center" *ngIf="fila ==='faltante'">
                <table class="table-condensed table-striped">
                    <thead class="text-center" style="font-size: 14px; background-color: grey;">
                        <th>Total solicitado</th>
                        <th></th>
                        <th>Total fabricado</th>
                        <th></th>
                        <th>Total faltante</th>
                    </thead>
                    <tbody>
                        <tr class="text-center" style="font-size: 12px; background-color: #cccccc;">
                            <td>{{cant_orden_producto_mpp}}</td>
                            <td>-</td>
                            <td>{{cant_producto_fabricado}}</td>
                            <td>=</td>
                            <td>{{prod_mpp_faltante}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12  text-center" *ngIf="fila ==='excedente'">
                <table class="table-condensed table-striped">
                    <thead class="text-center" style="font-size: 14px; background-color: grey;">
                        <th>Total solicitado</th>
                        <th></th>
                        <th>Total fabricado</th>
                        <th></th>
                        <th>Total excedente</th>
                    </thead>
                    <tbody>
                        <tr class="text-center" style="font-size: 12px; background-color: #cccccc;">
                            <td>{{cant_orden_producto_mpp}}</td>
                            <td>-</td>
                            <td>{{cant_producto_fabricado}}</td>
                            <td>=</td>
                            <td>{{prod_mpp_excedente}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <br>
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12  text-left">
                <mat-form-field class="materialinput">
                    <mat-label>Ingrese por favor la razon de la diferencia en el resultado*</mat-label>
                    <textarea class="form-control" matInput placeholder="" autocomplete="off" formControlName="causa"> </textarea>
                </mat-form-field>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-2 text-center">
            </div>
            <div class="col-md-4 text-center">
                <p>

                    <button class="btn-block" style="padding: 5px;" [disabled]="forma.invalid" (click)="modalrespuesta()" mat-raised-button color="primary"><mat-icon>done_all</mat-icon></button>
                </p>
                ( Aceptar )
            </div>
            <div class="col-md-4 text-center">
                <p>
                    <button class="btn-block" style="padding: 5px;" (click)="cerrarmodal()" mat-raised-button color="warn"><mat-icon>arrow_back</mat-icon></button>
                </p>
                ( Salir )
            </div>
            <div class="col-md-2 text-center">
            </div>
        </div>
    </form>
</div> -->

<div class="container-fluid px-0 " id="modal" style="background-color: #fff;">
    <div class="row  h-100 mx-0">
        <div class="col-md-12 h-100 mx-0 px-0" style=" border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class=" modal-content mx-0 h-100 py-0">
                    <div class="modal-header my-0 " style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-center"><strong>Datos complementarios para la revisión de la orden</strong> </h3>
                    </div>
                    <div class="modal-body my-0 mx-0 py-0 px-0 ">
                        <div class="row my-0 mx-0 py-0 px-0">
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 table-responsive">
                                <table class="my-1 mx-0 table-dark table-bordered table-hover table-condensed table-striped">
                                    <thead class="text-center">
                                        <th>Orden</th>
                                        <th>Codigo</th>
                                        <th>Descripción</th>
                                        <th>Cantidad</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center">
                                            <td>{{orden_prod}}</td>
                                            <td>{{cod_prod}}</td>
                                            <td>{{des_prod}}</td>
                                            <td>{{cant_orden_producto}}</td>
                                        </tr>
                                        <tr class="text-center">
                                            <td>{{orden_prod_mpp}}</td>
                                            <td>{{cod_prod_mpp}}</td>
                                            <td>{{des_prod_mpp}}</td>
                                            <td>{{cant_orden_producto_mpp}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="row my-0 mx-0 py-0 px-0">
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 table-responsive">
                                <table class="my-1 mx-0  table-dark table-bordered table-hover table-condensed table-striped">
                                    <thead class="text-center">
                                        <th>Total fabricado</th>
                                        <th></th>
                                        <th>Total en inv</th>
                                        <th></th>
                                        <th>Total actual</th>
                                        <th></th>
                                        <th>Cantidad formula</th>
                                        <th></th>
                                        <th>Cantidad sugerida</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center">
                                            <td>{{cant_producto_fabricado}}</td>
                                            <td>+</td>
                                            <td>{{cant_inv_prod_mp}}</td>
                                            <td>=</td>
                                            <td>{{total_mp}}</td>
                                            <td>/</td>
                                            <td>{{cant_formula}}</td>
                                            <td>=</td>
                                            <td>{{sugerido}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="row my-0 mx-0 py-0 px-0">
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 table-responsive" *ngIf="fila ==='faltante'">
                                <table class="my-1 mx-0  table-dark table-bordered table-hover table-condensed table-striped">
                                    <thead class="text-center">
                                        <th>Total solicitado</th>
                                        <th></th>
                                        <th>Total fabricado</th>
                                        <th></th>
                                        <th>Total faltante</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center">
                                            <td>{{cant_orden_producto_mpp}}</td>
                                            <td>-</td>
                                            <td>{{cant_producto_fabricado}}</td>
                                            <td>=</td>
                                            <td>{{prod_mpp_faltante}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 table-responsive" *ngIf="fila ==='excedente'">
                                <table class="my-1 mx-0  table-dark table-bordered table-hover table-condensed table-striped">
                                    <thead class="text-center">
                                        <th>Total solicitado</th>
                                        <th></th>
                                        <th>Total fabricado</th>
                                        <th></th>
                                        <th>Total excedente</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center">
                                            <td>{{cant_orden_producto_mpp}}</td>
                                            <td>-</td>
                                            <td>{{cant_producto_fabricado}}</td>
                                            <td>=</td>
                                            <td>{{prod_mpp_excedente}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row my-0 mx-0 py-0 px-0">
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 ">
                                <p class="py-1"><strong>Ingrese por favor la razon de la diferencia en el resultado*</strong> </p>
                                <div class="form-group py-1 px-1 my-1" style="border: 2px solid #000;">
                                    <textarea maxlength="150" class="border form-control " matInput placeholder="" autocomplete="off" formControlName="causa"> </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer ">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button [disabled]="forma.invalid" mat-button class=" btn-primary" (click)="modalrespuesta()"> Enviar</button>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>