import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-enviar-reporte-daily',
  templateUrl: './form-enviar-reporte-daily.component.html',
  styleUrls: ['./form-enviar-reporte-daily.component.css']
})
export class FormEnviarReporteDailyComponent implements OnInit {

  @ViewChild('cant_inv_sg',{static:true}) cant_inv_sg: ElementRef;
  @ViewChild('cantidad',{static:true}) cantidad: ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
cod_cuenta_gasto:any;
des_cuenta_gasto:any;
cant_cuenta_gasto:any;
presupuesto:any;
cod_centro:any;
dialogSpin:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
nombre_producto:any;
codigo_producto:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  msg:any;
  telRegex:any = /\d{2}-\d{5}\d/;

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, fb:FormBuilder, private servicio:nkServices,
    public dialogRefp: MatDialogRef<FormEnviarReporteDailyComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
          this.forma = fb.group({
            'cod_usuario':'',
            'des_usuario':'',
            'cantidad': '0',
            'tipo_operacion':'',
          });
    }

    ngOnInit(): void {

    }

    modalrespuesta(){
      try {
        console.log(this.data.parametro);
        var cod_usuario = localStorage.getItem('cod_usuario');
        var des_usuario = localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido');

        this.forma.value.cod_usuario = cod_usuario;
        this.forma.value.des_usuario = des_usuario;

        this.dialogRefp.close(this.forma.value);
        this.enviar_correo(this.data.parametro);

      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:20000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
  }

  enviar_correo(parametro:any):void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        var valor = {parametro:parametro,
                    cod_usuario:localStorage.getItem('cod_usuario'),
                    des_usuario:localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido')}
        this.servicio.reporteFactDailyhttp(valor).subscribe(
          response =>{
            this.dialogSpin.close();
              /* console.log(response,'valores'); */
              this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                console.log(response.descargarPDF);
                this.dialogRefp.close();

          },
          error =>{
            this.dialogSpin.close();
            console.log(<any>error.error.message,'error definiciones');
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
        
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

    actualizardatos(res:any){

      if(res == 'actualizar'){
        console.log(this.forma.value);
        this.dialogRefp.close(this.forma.value);
      }else{

      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
      }

    }

    cerrarmodal(): void {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
              maxWidth: '100vw',
              height:'100%',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar') {
          this.cerrar();
        }

      });

    }
    cerrar():void{
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          this.dialogSpin.close();
          this.dialogRefp.close('cerrar');
        }, 2000);
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

    format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }


}
