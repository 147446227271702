
<div class="container-fluid rounded" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <div class="row">
            <div class="col-md-2 col-sm-12 col-xs-12 py-0">
                <mat-form-field class="materialinput my-1" >
                    <mat-label style="color: #fff; font-size: 12px;">Codigo: </mat-label>
                    <input style=" color: #fff; font-size: 12px;" #cod_dep matInput placeholder="" autocomplete="off" readonly>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 py-0">
                <mat-form-field class="materialinput my-1">
                    <mat-label style="color: #fff; font-size: 12px;">Deposito</mat-label>
                    <input style="color: #fff; font-size: 12px;" #des_dep matInput placeholder="" autocomplete="off" readonly>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 py-0">
                <mat-form-field class="materialinput my-1">
                    <mat-label style="color: #fff; font-size: 12px;">Descripción</mat-label>
                    <input style="color: #fff; font-size: 12px;" #descrip_dep matInput placeholder="" autocomplete="off" readonly>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                </mat-form-field>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end  py-0">
                <button (click)="buscarProductoDeposito('actualizar')" matTooltip="ACTUALIZAR LISTA" color="accent" class="my-2 mr-1 col-md-1 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255);"><mat-icon >sync</mat-icon></button>
                <button (click)="filter_date_print()" matTooltip="DESCARGAR INVENTARIO" class="my-2 mr-1 col-md-1 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(19, 106, 236);"><mat-icon >get_app</mat-icon></button>
                <button matTooltip="SALIR" (click)="cerrarmodal()" class="my-2 mr-1 col-md-1 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(216, 68, 58);"><mat-icon >exit_to_app</mat-icon></button>
                <input class="form-control col-md-4 col-sm-12 col-xs-12 my-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
            </div>
    </div>
    <div  class="row modalgeneral" style=" border: 2px solid #2b69dd; background-color:#29388f;">
        <div class=" table-responsive  h-100" >
            <table #table mat-table [dataSource]="dataSource1" class=" table-dark text-center table-striped  table-condensed table-hover">

                <ng-container matColumnDef="cod_img">
                    <th mat-header-cell *matHeaderCellDef class="text-center " style="background-color:#29388f; padding: 5px; color: #fff; " >
                        <h5><strong>Imagen</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> <img width="50px" height="50px" class="rounded" [src]="element.cod_img" alt=""></td>
                </ng-container>

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Cod producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;">{{element.cod_producto}}</td>
                    
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.des_producto}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="unidad_manejo">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>UM</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.unidad_manejo}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="um_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Bultos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.um_producto}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cant_producto}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.estatus}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.tipo_producto}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="ajustar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Cargo de producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="ajustes_almacen(element,'cargo')">
                        <button matTooltip="CARGAR PRODUCTO" mat-button class="rounded-pill " style="border: outset 3px rgb(48, 47, 84); height: 35px; color:rgb(255, 255, 255); background-color: rgb(28, 27, 82);">
                          <mat-icon >trending_up</mat-icon>
                          </button>
                    </td>
                    
                </ng-container>

                <ng-container matColumnDef="desajustar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Descargo de producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="ajustes_almacen(element,'descargo')">
                        <button matTooltip="DESCARGAR PRODUCTO" mat-button class="rounded-pill " style="border: outset 3px rgb(230, 74, 146); height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 8, 114);">
                          <mat-icon >trending_down</mat-icon>
                          </button>
                    </td>
                   
                </ng-container>

                <ng-container matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Procesos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center col-md-2" >

                        <button  class=" bg-primary mr-1"   matTooltip="CARGAR PRODUCTO" mat-mini-fab (click)="ajustes_almacen(element,'cargo')">
                                    <mat-icon >trending_up</mat-icon>
                        </button>
                        <button  class=" bg-info mr-1"   matTooltip="DESCARGAR PRODUCTO" mat-mini-fab (click)="ajustes_almacen(element,'descargo')">
                                   <mat-icon >trending_down</mat-icon>
                        </button>
                        <button  class=" bg-success"   matTooltip="MOVIMIENTOS" mat-mini-fab (click)="movimientos_prod(element)">
                            <mat-icon >access_time</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
