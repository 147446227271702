<div id="modal_carrito" style="background-color:#0076C7;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alertSuccess alert-success alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10000; position: absolute;" role="alert">
       <span style="font-size: 20px;"><mat-icon >assignment_turned_in</mat-icon></span><strong class="mr-1">Listo!</strong> Operacion Exitosa.
       <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button> -->
   </div>
   <!--///////////////////////////ALERTA CODE////////////////////////////-->
   <div class=" container"  >

        <div class="row mt-3 justify-content-between px-3">
            <h2 class="m-0 text-light">Ver Documento</h2>
            <button (click)="cerrar()" class="boton"> <mat-icon style="color: #ff2b34;" >clear</mat-icon> </button>
        </div>

       <div id="carrito_p6" class="row " style=" border: 2px solid #0076C7; background-color: #f1ede9; ">
           <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
            <div class="container">
                <div class="row">
                  <div class="col d-flex justify-content-center align-items-center">
                    <img [src]="imagen" alt="imagen" *ngIf="!imagenZoom" style="width: 350px; cursor: zoom-in;"(click)="zoomImage(true, $event)" class=" mt-4">
                    <img [src]="imagen" alt="imagen" *ngIf="imagenZoom" style="width: 600px;cursor: zoom-out; "(click)="zoomImage(false, $event)" class=" mt-4">
                  </div>
                  <div class="col d-flex align-items-center" id="div-forma">
                    <form [formGroup]="forma" class="row">

                      <div class="col-12">
                        <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Tipo de Documento</mat-label>
                            <mat-select name="tipoDoc" disabled formControlName="tipo_documento">
                                <mat-option  value="NE">
                                    NE |
                                    <small>Nota de Entrega</small>
                                </mat-option>
                                <mat-option  value="FAC">
                                    FAC |
                                    <small>Factura</small>
                                </mat-option>
                              </mat-select>
                        </mat-form-field>
                      </div>
                                
                        <div class="col-6">
                            <div class="col">
                                <mat-form-field >
                                  <mat-label>Codigo documento</mat-label>
                                  <input readonly matInput placeholder="00000000" formControlName="cod_documento" >
                                </mat-form-field>
                                <mat-form-field class="materialinput py-1 px-1">
                                    <mat-label >Fecha Emision</mat-label>
                                    <input readonly matInput placeholder="DD/MM/AAAA" formControlName="fecha_emision" id="fecha_emision" >
                                </mat-form-field>                                         
                                  <mat-form-field >
                                    <mat-label>Flete</mat-label>
                                    <input readonly matInput formControlName="flete" id="flete"  >
                                  </mat-form-field><span>{{moneda}}</span>
                                  <mat-form-field >
                                    <mat-label>Sub Total</mat-label>
                                    <input readonly matInput formControlName="sub_total" id="sub_total"  >
                                  </mat-form-field><span>{{moneda}}</span>
                                
                            </div>

                        </div>
                        <div class="col-6">
                            <div class="col">
                                <mat-form-field >
                                  <mat-label>N° Control</mat-label>
                                  <input matInput readonly placeholder="00000000" formControlName="cod_control" >
                                </mat-form-field>
                                <mat-form-field class="materialinput py-1 px-1">
                                    <mat-label >Fecha Recepcion</mat-label>
                                    <input matInput readonly placeholder="DD/MM/AAAA"  formControlName="fecha_recepcion" id="fecha_recepcion" >
                                </mat-form-field>

                                <mat-form-field >
                                    <mat-label>IVA</mat-label>
                                    <input matInput formControlName="iva"  id="iva" readonly >
                                </mat-form-field><span>{{moneda}}</span>

                                <mat-form-field >
                                    <mat-label>Ret. IVA</mat-label>
                                    <input matInput formControlName="ret_iva"  id="ret_iva" readonly>
                                </mat-form-field><span>{{moneda}}</span>
                                
                                <mat-form-field >
                                   <mat-label>Total</mat-label>
                                   <input matInput formControlName="total"  id="total" readonly>
                                </mat-form-field><span>{{moneda}}</span>

                            </div>

                        </div>
                           

                    </form>
                  </div>
                </div>
            </div>  
            
            
             </div>
       </div>
      
   </div>
</div>
