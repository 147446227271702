<div id="modal_carrito" style="background-color: rgba(0,118,199,0.9);;;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container" >

        <div class="row mt-3 justify-content-between mx-3">
            <h2 *ngIf="!existe" class="m-0 text-light">Crear Proveedor</h2>
            <h2 *ngIf="existe" class="m-0 text-light">Actualizar Proveedor</h2>
            <button (click)="cerrarmodal()" class="boton"> <mat-icon style="color: #ff2b34;" >clear</mat-icon> </button>
        </div>

        <div id="carrito_p6" class="row mt-2 mat-elevation-z8" style=" border: 2px solid #000; background-color: #f1ede9; ">
            <div class="col-md-12 col-sm-12 col-xs-12 table-responsive border py-3" style="overflow: hidden;">
                <!-- <form [formGroup]="firstFormGroup" >

                    <div class="row">

                      <div class="col-6">

                        
                            
                        <mat-form-field class="w-100">
                            <mat-label >Documento de Identificacion</mat-label>
                            <input  maxlength="11" type="text"  matInput  formControlName="cod_proveedor" >
                        </mat-form-field>
                            
                        

                        <mat-form-field  class="w-100">
                            <mat-label>País</mat-label>
                            <mat-select (selectionChange)="verificarPais($event)"  formControlName="pais_prov" >
                                <mat-option  *ngFor=" let option of pais" [value]="option.PaisNombre">
                                    {{option.PaisNombre}}
                                </mat-option>
                             </mat-select>
                        </mat-form-field>

                        
                        <mat-form-field class="w-100">
                            <mat-label>Estado</mat-label>
                            <input  type="text" matInput formControlName="estado_prov" [matAutocomplete]="auto" >
                            <mat-autocomplete  #auto="matAutocomplete" class="px-1">
                                    <mat-option   style="font-size: 14px; color: #000;"(onSelectionChange)="filtrarCiudades($event)" *ngFor="let option of filteredOptionsEstados | async"  [value]="option.estado">
                                        {{option.estado}}
                                    </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>


                        <mat-form-field class="w-100">
                            <mat-label>Ciudad</mat-label>
                            <input  type="text" matInput formControlName="ciudad_prov" [matAutocomplete]="auto1" >
                            <mat-autocomplete  #auto1="matAutocomplete" class="px-1">
                                    <mat-option   style="font-size: 14px; color: #000;" *ngFor="let option of filteredOptionsCiudades | async"  [value]="option.ciudad">
                                        {{option.ciudad}}
                                    </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Dirección</label>
                            <textarea style="resize: none;" class="form-control" formControlName="dir_prov" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        
                        
                      </div>

                      <div class="col-6">
                          
                        <mat-form-field class="w-100">
                            <mat-label >Nombre</mat-label>
                            <input   type="text"   matInput  formControlName="des_proveedor" >
                        </mat-form-field>

                        <mat-form-field class="w-100">
                            <mat-label >Email</mat-label>
                            <input   type="email"   matInput  formControlName="email_prov" >
                        </mat-form-field>

                        <mat-form-field class="w-100">
                            <mat-label >Telefono celular</mat-label>
                            <input   type="tel"   placeholder="0424-123-4567" matInput  formControlName="telefono1_prov" >
                        </mat-form-field>

                        <mat-form-field class="w-100">
                            <mat-label >Telefono local</mat-label>
                            <input   type="tel"   placeholder="0212-123-4567" matInput  formControlName="telefono2_prov" >
                        </mat-form-field>

                        <mat-form-field class="w-100">
                            <mat-label>Tipo de producto</mat-label>
                            <mat-select formControlName="tipo_prov">
                              <mat-option *ngFor="let option of tiposProducto" [value]="option.tipo_producto">
                                {{option.tipo_producto}} |
                                <small>{{option.des_tipo_producto}}</small>
                              </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-group">

                            <label>Retención del IVA</label>
                            <div>
                                <mat-radio-group  formControlName="reten_prov" >
                                    <mat-radio-button value="100%" class="mr-2" checked="true" >100%</mat-radio-button>
                                    <mat-radio-button value="75%" class="mr-2" >75%</mat-radio-button>
                                    <mat-radio-button value="XCNT" class="mr-2" >Exento</mat-radio-button>
                                </mat-radio-group>
                                
                            </div>
                           
                        </div>

                        <div class="col-12 d-flex justify-content-end">
                            
                            <button type="submit" class="btn btn-success" 

                            [disabled]="firstFormGroup.invalid"

                            (click)="Send()"
                            
                            >Enviar</button>

                        </div>

                        
                      </div>
                    </div>

                </form> -->
                <mat-horizontal-stepper [linear]="isLinear" #stepper>

                    <mat-step [stepControl]="firstFormGroup" >
                        <ng-template matStepLabel>Datos principales</ng-template>
                        <form class="container" [formGroup]="firstFormGroup" >

                            <div class="row">

                              <div class="col-6">

                                
                                    
                                <mat-form-field class="w-100">
                                    <mat-label >Documento de Identificacion</mat-label>
                                    <input  maxlength="11" type="text"  matInput  formControlName="cod_proveedor" >
                                    <mat-error *ngIf="firstFormGroup.controls.cod_proveedor.errors?.pattern">
                                        Patron no valido, Ejem:V1234567852
                                    </mat-error>
                                </mat-form-field>
                                    
                                

                                <mat-form-field  class="w-100">
                                    <mat-label>País</mat-label>
                                    <mat-select (selectionChange)="verificarPais($event)"  formControlName="pais_prov" >
                                        <mat-option  *ngFor=" let option of pais" [value]="option.PaisNombre">
                                            {{option.PaisNombre}}
                                        </mat-option>
                                     </mat-select>
                                </mat-form-field>

                                
                                <mat-form-field class="w-100">
                                    <mat-label>Estado</mat-label>
                                    <input  type="text" matInput formControlName="estado_prov" [matAutocomplete]="auto" >
                                    <mat-autocomplete  #auto="matAutocomplete" class="px-1">
                                            <mat-option   style="font-size: 14px; color: #000;"(onSelectionChange)="filtrarCiudades($event)" *ngFor="let option of filteredOptionsEstados | async"  [value]="option.estado">
                                                {{option.estado}}
                                            </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                               

                                

                                <mat-form-field class="w-100">
                                    <mat-label>Ciudad</mat-label>
                                    <input  type="text" matInput formControlName="ciudad_prov" [matAutocomplete]="auto1" >
                                    <mat-autocomplete  #auto1="matAutocomplete" class="px-1">
                                            <mat-option   style="font-size: 14px; color: #000;" *ngFor="let option of filteredOptionsCiudades | async"  [value]="option.ciudad">
                                                {{option.ciudad}}
                                            </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                                
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">Dirección</label>
                                    <textarea style="resize: none;" class="form-control" formControlName="dir_prov" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </div>
                                
                                
                              </div>

                              <div class="col-6">
                                  
                                <mat-form-field class="w-100">
                                    <mat-label >Nombre</mat-label>
                                    <input   type="text"   matInput  formControlName="des_proveedor" >
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Email</mat-label>
                                    <input   type="email"   matInput placeholder="correoemail@factorycosmetics.com.ve"  formControlName="email_prov" >
                                    <mat-error *ngIf="firstFormGroup.controls.email_prov.errors?.email">
                                        Patron no valido, Ejem: correoemail@factorycosmetics.com.ve
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Telefono celular</mat-label>
                                    <input   type="tel" maxlength="12"  placeholder="0424-1234567" matInput  formControlName="telefono1_prov" >
                                    <mat-error *ngIf="firstFormGroup.controls.telefono1_prov.errors?.pattern">
                                        Patron no valido, Ejem:0424-1234567
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Telefono local</mat-label>
                                    <input   type="tel" maxlength="12"  placeholder="0212-123-4567" matInput  formControlName="telefono2_prov" >
                                    <mat-error *ngIf="firstFormGroup.controls.telefono2_prov.errors?.pattern">
                                        Patron no valido, Ejem:0424-1234567
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label>Tipo de producto</mat-label>
                                    <mat-select formControlName="tipo_prov">
                                      <mat-option *ngFor="let option of tiposProducto" [value]="option.tipo_producto">
                                        {{option.tipo_producto}} |
                                        <small>{{option.des_tipo_producto}}</small>
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="form-group">

                                    <label>Retención del IVA</label>
                                    <div>
                                        <mat-radio-group  formControlName="reten_prov" >
                                            <mat-radio-button value="100%" class="mr-2" checked="true" >100%</mat-radio-button>
                                            <mat-radio-button value="75%" class="mr-2" >75%</mat-radio-button>
                                            <mat-radio-button value="XCNT" class="mr-2" >Exento</mat-radio-button>
                                        </mat-radio-group>
                                        
                                    </div>
                                   
                                </div>

                                <div class="col-12 d-flex justify-content-end">
                                    
                                    <button type="submit" matStepperNext class="btn btn-primary">Siguiente</button>
                                    

                                </div>

                                
                              </div>
                            </div>

                        </form>
    
                    </mat-step>

                    <mat-step [stepControl]="secondFormGroup" >
                        <ng-template matStepLabel>Personas Contacto</ng-template>
                        <div class="container" [formGroup]="secondFormGroup">

                            <div class="row">

                              <div class="col-6">
                                <h4><strong>Datos del Contacto de la empresa</strong> </h4>

                                <mat-form-field class="w-100">
                                    <mat-label >Documento de Identificacion</mat-label>
                                    <input type="text" maxlength="9" placeholder="V12345678" matInput formControlName="cod_contacto_prov" >
                                    <mat-error *ngIf="secondFormGroup.controls.cod_contacto_prov.errors?.pattern">
                                        Patron no valido, Ejem:V12345678
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Nombre</mat-label>
                                    <input  type="text"  matInput  formControlName="des_contacto_prov" >
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Email</mat-label>
                                    <input  type="email"  matInput formControlName="email_contacto_prov" >
                                    <mat-error *ngIf="secondFormGroup.controls.email_contacto_prov.errors?.email">
                                        Patron no valido, Ejem: correoemail@factorycosmetics.com.ve
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Telefono 1</mat-label>
                                    <input  type="email" maxlength="12" matInput formControlName="telefono1_contacto_prov" >
                                    <mat-error *ngIf="secondFormGroup.controls.telefono1_contacto_prov.errors?.pattern">
                                        Patron no valido, Ejem:0424-1234567
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Telefono 2</mat-label>
                                    <input  type="email" maxlength="12" matInput formControlName="telefono2_contacto_prov" >
                                    <mat-error *ngIf="secondFormGroup.controls.telefono2_contacto_prov.errors?.pattern">
                                        Patron no valido, Ejem:0424-1234567
                                    </mat-error>
                                </mat-form-field>

                                <div class=" col-6 p-1 ">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Fecha de cumpleaños</mat-label>
                                        <input matInput formControlName="cumpleanho_contacto_prov"  [matDatepicker]="picker1" >
                                        <mat-datepicker-toggle matSuffix [for]="picker1" ></mat-datepicker-toggle>
                                        <mat-datepicker #picker1 ></mat-datepicker>
                                    </mat-form-field>
                                </div>


                                <div class="col-12 d-flex justify-content-start">
                                    
                                    <button type="submit" matStepperPrevious class="btn btn-primary">Regresar</button>


                                </div>
                                
                              </div>
                              <div class="col-6">
                                  <h4><strong>Datos del Propietario de la empresa</strong> </h4>
                                <mat-form-field class="w-100">
                                    <mat-label >Documento de Identificacion</mat-label>
                                    <input  maxlength="9" type="text" placeholder="V12345678"  matInput formControlName="cod_propietario_prov">
                                    <mat-error *ngIf="secondFormGroup.controls.cod_propietario_prov.errors?.pattern">
                                        Patron no valido, Ejem:V12345678
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Nombre</mat-label>
                                    <input  type="text"  matInput formControlName="des_propietario_prov">
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Email</mat-label>
                                    <input  type="email"  matInput formControlName="email_propietario_prov">
                                    <mat-error *ngIf="secondFormGroup.controls.email_propietario_prov.errors?.email">
                                        Patron no valido, Ejem: correoemail@factorycosmetics.com.ve
                                    </mat-error>
                                </mat-form-field>

                                <div class=" w-100 ">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Fecha de cumpleaños</mat-label>
                                        <input matInput  [matDatepicker]="picker2" formControlName="cumpleanho_propietario_prov" >
                                        <mat-datepicker-toggle matSuffix [for]="picker2" ></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 ></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div style="height: 120px;" >
                                    

                                </div>

                                <div class="w-100 d-flex justify-content-end">
                                    
                                    <button type="submit" matStepperNext class="btn btn-primary">Siguiente</button>


                                </div>

                                
                              </div>

                              
                            </div>

                        </div>
    
                    </mat-step>

                    <mat-step [stepControl]="thirdFormGroup" >
                        <ng-template matStepLabel>Datos Bancarios</ng-template>
                        <div class="container" [formGroup]="thirdFormGroup" >

                            <div class="row">

                              <div class="col-6">

                                <h4><strong>Datos del Bancarios del Proveedor 1</strong> </h4>
     
                                <mat-form-field class="w-100">
                                    <mat-label >Nombre Beneficiario</mat-label>
                                    <input   type="text"   matInput  formControlName="des_cuenta_bene" >
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Documento del Beneficiario</mat-label>
                                    <input   type="email" maxlength="10"  matInput placeholder="V123456789"  formControlName="cod_cuenta_bene" >
                                    <mat-error *ngIf="thirdFormGroup.controls.cod_cuenta_bene.errors?.pattern">
                                        Patron no valido, Ejem:V123456789
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field *ngIf="nacional" class="w-100">
                                    <mat-label>Banco</mat-label>
                                    <mat-select  formControlName="banco_cuenta_bene" >
                                        <mat-option  *ngFor=" let option of bancos" [value]="option.nombre">
                                            {{option.codigo}} | {{option.nombre}}
                                        </mat-option>
                                        </mat-select>
                                </mat-form-field>

                                 
                                <mat-form-field *ngIf="!nacional" class="w-100">
                                    <mat-label >Banco</mat-label>
                                    <input   type="text"    matInput   formControlName="banco_cuenta_bene" >
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Numero de Cuenta</mat-label>
                                    <input   type="text" maxlength="24"  placeholder="0000-0000-0000-0000-0000" matInput  formControlName="cod_cuenta_banco_bene" >
                                    <mat-error *ngIf="thirdFormGroup.controls.cod_cuenta_banco_bene.errors?.pattern">
                                        Patron no valido, Ejem:0000-0000-0000-0000-0000
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Telefono del Beneficiario</mat-label>
                                    <input   type="tel" maxlength="12"  placeholder="0412-1234567" matInput  formControlName="telefono_cuenta_bene" >
                                    <mat-error *ngIf="thirdFormGroup.controls.telefono_cuenta_bene.errors?.pattern">
                                        Patron no valido, Ejem:0424-1234567
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label>Moneda</mat-label>
                                    <mat-select formControlName="moneda">
                                        <mat-option value="BOLIVARES">
                                            BOLIVARES
                                          </mat-option>
                                          <mat-option  value="DOLAR">
                                            DOLAR
                                          </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="col-12 d-flex justify-content-start">
                                    
                                    <button type="submit" matStepperPrevious class="btn btn-primary">Regresar</button>


                                </div>
                                
                                
                              </div>

                              <div class="col-6">

                                <h4><strong>Datos del Bancarios del Proveedor 2</strong> </h4>
                                  
                                <mat-form-field class="w-100">
                                    <mat-label >Nombre Beneficiario</mat-label>
                                    <input   type="text"   matInput  formControlName="des_cuenta_bene2" >
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Documento del Beneficiario</mat-label>
                                    <input   type="email"  maxlength="10" matInput placeholder="V12345678" formControlName="cod_cuenta_bene2" >
                                    <mat-error *ngIf="thirdFormGroup.controls.cod_cuenta_bene2.errors?.pattern">
                                        Patron no valido, Ejem:V123456789
                                    </mat-error>
                                </mat-form-field>


                                <mat-form-field *ngIf="nacional" class="w-100">
                                    <mat-label>Banco</mat-label>
                                    <mat-select  formControlName="banco_cuenta_bene2" >
                                        <mat-option  *ngFor=" let option of bancos" [value]="option.nombre">
                                            {{option.codigo}} | {{option.nombre}}
                                        </mat-option>
                                        </mat-select>
                                </mat-form-field>

                                 
                                <mat-form-field *ngIf="!nacional" class="w-100">
                                    <mat-label >Banco</mat-label>
                                    <input   type="tel"    matInput   formControlName="banco_cuenta_bene2" >
                                </mat-form-field>
                                    

                                
                                <mat-form-field class="w-100">
                                    <mat-label >Numero de Cuenta</mat-label>
                                    <input   type="text" maxlength="24"  placeholder="0000-0000-0000-0000-0000" matInput  formControlName="cod_cuenta_banco_bene2" >
                                    <mat-error *ngIf="thirdFormGroup.controls.cod_cuenta_banco_bene2.errors?.pattern">
                                        Patron no valido, Ejem:0000-0000-0000-0000-0000
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label >Telefono del Beneficiario</mat-label>
                                    <input   type="tel" maxlength="12"  placeholder="0412-1234567" matInput  formControlName="telefono_cuenta_bene2" >
                                    <mat-error *ngIf="thirdFormGroup.controls.telefono_cuenta_bene.errors?.pattern">
                                        Patron no valido, Ejem:0424-1234567
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="w-100">
                                    <mat-label>Moneda</mat-label>
                                    <mat-select formControlName="moneda2">
                                      <mat-option value="BOLIVARES">
                                        BOLIVARES
                                      </mat-option>
                                      <mat-option  value="DOLAR">
                                        DOLAR
                                      </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="col-12 d-flex justify-content-end">
                                    
                                    <button type="submit" class="btn btn-success" 

                                    [disabled]="firstFormGroup.invalid ||
                                                secondFormGroup.invalid ||
                                                thirdFormGroup.invalid"

                                    (click)="Send()"
                                    
                                    >Enviar</button>
    
    
                                </div>
                                
                              </div>

                                
                            </div>

                        </div>
    
                    </mat-step>

                    
                 
                </mat-horizontal-stepper>
            </div>

        </div>
        
    </div>
</div>
