import { Component, ElementRef, OnInit } from '@angular/core';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import { Routes, RouterModule, Router } from '@angular/router';

import { Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

import { FormInventarioPdComponent } from '../../../../shared/modal/form-inventario-pd/form-inventario-pd.component';


@Component({
  selector: 'app-inventario-pd',
  templateUrl: './inventario-pd.component.html',
  styleUrls: ['./inventario-pd.component.css']
})

export class InventarioPdComponent implements OnInit {

  sesion:any;
  cod_menu:any;
  object1:any;
  menu:any=[];
  menu2:any = [];
  menu3:any = [];
  menu4:any = [];
  roulink:any;
  unidad:any;
  usuario:any;
  menuorder3:any;
  menuoreder4:any;
  mostrar:any;
  ruta:any;
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:any[];
  dataSource=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('link') link: ElementRef;
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router){}

  ngOnInit(): void {
    this.mostrar = parseInt(localStorage.getItem('cod_valid'));
    this.sesion = localStorage.getItem('cod_usuario');
    console.log(typeof this.mostrar);
    this.usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
    this.object1 ={cod_usuario:this.sesion, cod_menu:this.cod_menu}
    this.listarInventarioPD(null);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }

  listarInventarioPD(msj:any){
    let msg = msj;
    this.servicio.listarInventarioPDhttp().subscribe(
      response =>{
        if(response){
          console.log(response[0],'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                    id_producto_danado:response[i].id_producto_danado,
                    cod_producto:response[i].cod_producto,
                    des_producto: response[i].des_producto,
                    razon: response[i].razon,
                    fecha_registro: response[i].fecha_registro,
                    hora: response[i].hora,
                    usuario: response[i].usuario,
                    almacen: response[i].almacen,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = ['cod_lote',
                                       'cod_producto',
                                       'des_producto',
                                       'cod_usuario',
                                       'des_usuario',
                                       'des_causa',
                                       'fecha',
                                       'hora'];
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.displayedColumns = ['cod_lote',
                                       'cod_producto',
                                       'des_producto',
                                       'cod_usuario',
                                       'des_usuario',
                                       'des_causa',
                                       'fecha',
                                       'hora'];
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
      }
      );

      if(msg == 'actualizar'){
        this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
  }
  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

}
