import { Component, OnInit, Inject,AfterViewInit, ViewChild } from '@angular/core';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { FormEstructuraComponent } from 'src/app/componentes/shared/modal/form-estructura/form-estructura.component';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Component({
  selector: 'app-gestion-estrutura-empresa',
  templateUrl: './gestion-estructura-empresa.component.html',
  styleUrls: ['./gestion-estructura-empresa.component.css']
})
export class GestionEstructuraEmpresaComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  cargos:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
  constructor(
       private servicio:nkServices,
       private dialog: MatDialog,
       private util:AppUtilService,
       private atrb:AtributosService,
       private hashkey:HashKeyServices,
       private notifi:ToastNotificationService,
       ){}
  ngOnInit(){
    this.listarOrganizacion(null);
    this.buscardefiniciones();
  }
  
  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator; 
  }
  /////////////////////////////////////////////////////////////////////
 async listarOrganizacion(msj:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let msg = msj;
      let xvalor = {
        cod_empresa: this.atrb.COD_EMPRESA.cod_empresa,
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarEstructuraOrganizacion),
      }
      const response =  await  this.servicio.ApiResthttp(xvalor).toPromise();
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                        cod_estructura:response[i].cod_estructura,
                        cod_gerencia:response[i].cod_gerencia,
                        des_gerencia:response[i].des_gerencia,
                        cod_dpto: response[i].cod_dpto,
                        des_dpto:response[i].des_dpto,
                        cod_cargo:response[i].cod_cargo,
                        des_cargo:response[i].des_cargo,
                        estatus:response[i].estatus,
                        fecha_inicio:response[i].fecha_inicio,
                        hora_inicio:response[i].hora_inicio,
                        cod_empresa: response[i].cod_empresa,
                    });
                  }
                  this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_STR_EMPRESA;
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            if(msg == await this.atrb.ACCION_ACTUALIZAR){
                await this.util.cerrarSpinner();
                await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
            }else{
              await this.util.cerrarSpinner();
            }

    } catch (error) {
        console.log(error);
      await this.util.cerrarSpinner();
      this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_STR_EMPRESA;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  //////////////////////FUNCION DE ACCION/////////////////////////
  async buscardefiniciones(){
    try {
      var xvalor = {
        cod_menu: localStorage.getItem('sub_main'),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.buscardefiniciones),
      };
        const response = await this.servicio.ApiResthttp(xvalor).toPromise();
            this.cargos = [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === this.atrb.PARAMETROS_ESTRUCTURA.cargo.toUpperCase()) {
                this.cargos.push(response[i]);
              }
            }
    } catch (error) {
      console.log(error.error.message);
    }
  }
  async update_insert_data(obj:any, text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
        var dialogRef;
      if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
        if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
          let val_def;
              val_def = {
                val_def:await this.atrb.ACCESO_VALDEF.gestionar_cargos,
                tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
              }
          await this.servicio.ApiResthttp(val_def).toPromise();
    }
        await this.util.cerrarSpinner();
        this.accion = text;
         dialogRef = this.dialog.open(FormEstructuraComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: {
                  accion:await this.atrb.ACCION_ACTUALIZAR,
                },
          disableClose: true,
          panelClass: 'pedidos_modal_perfiles',
          backdropClass: "bdrop"
        });
      }else{
        if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
          let val_def;
              val_def = {
                val_def:await this.atrb.ACCESO_VALDEF.gestionar_cargos,
                tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
              }
          await this.servicio.ApiResthttp(val_def).toPromise();
    }
        await this.util.cerrarSpinner();
        dialogRef = this.dialog.open(FormEstructuraComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'auto',
            data: {
                      accion:await this.atrb.ACCION_CREAR,
                      cargos:this.cargos,
                      operacion: await this.atrb.ACCION_CREAR
                  },
            disableClose: true,
            panelClass: 'pedidos_modal_perfiles',
            backdropClass: "bdrop"
          });
      }
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result !== 'cerrar'){
            await this.util.cerrarSpinner();
            console.log(result)
         await this.gestionOrganizacion(result);
        }else{
          await this.util.cerrarSpinner();
        }
      });  
    } catch (error) {
        console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async gestionOrganizacion(xvalor:any){
    try {
        xvalor.api = await this.hashkey.encrypt(await this.atrb.API_REST.gestionOrganizacion)
        const response = await this.servicio.ApiResthttp(xvalor).toPromise();
        await this.util.cerrarSpinner();
          await this.notifi.success(response.message);
           await this.listarOrganizacion(null);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.listarOrganizacion(null);
      await this.notifi.customToastNotification(error.error.message);
    }
  }
//////////////////////FUNCION DE ACCION/////////////////////////
 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}


