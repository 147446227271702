import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormConsultarFormulaMppComponent } from '../form-consultar-formula-mpp/form-consultar-formula-mpp.component';
import { FormRevisarOrdenesMppComponent } from '../form-revisar-ordenes-mpp/form-revisar-ordenes-mpp.component';

@Component({
  selector: 'app-form-inspeccion-ord',
  templateUrl: './form-inspeccion-ord.component.html',
  styleUrls: ['./form-inspeccion-ord.component.css']
})
export class FormInspeccionOrdComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
cod_prod:any;
des_prod:any;
cod_prod_mpp:any;
des_prod_mpp:any;
prod_mpp_faltante:any;
prod_mpp_excedente:any;
fila:any;
producto:any = [];
orden_prod:any;
orden_prod_mpp:any;
cant_producto_fabricado:any;
cant_orden_producto:any;
cant_orden_producto_mpp:any;
cant_formula:any;
cant_inv_prod_mp;
total_mp:any;
sugerido:any;
codigo:any = [];
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
cod_pro:any;

  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormInspeccionOrdComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
      this.orden_prod = this.data.cod_orden_producto;
      this.orden_prod_mpp = this.data.cod_orden_producto_mpp;
      this.cod_prod_mpp = this.data.objeto2[0].cod_producto2
      this.des_prod_mpp = this.data.objeto2[0].des_producto2
      this.cant_producto_fabricado = this.data.objeto2[0].cant_producto2;
      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'des_producto':'',
        'cod_producto_mpp':this.data.objeto[0].cod_producto,
        'des_producto_mpp':this.data.objeto[0].des_producto,
        'cant_orden_prod':'',
        'cant_producto_solicitado':this.data.objeto[0].cant_producto,
        'cant_producto_fabricado':this.data.objeto2[0].cant_producto2,
        'cant_formula_pt':'',
        'estatus':this.data.estatus,
        'cod_orden_producto_mpp':data.cod_orden_producto_mpp,
        'cod_orden_producto':data.cod_orden_producto,
        'causa':['',[Validators.required,Validators.minLength(10)]],
        'ajuste_prod':'',
        'faltante':'',
        'excedente':'',
        'fila':''

      });
    }

  ngOnInit(): void {
    this.buscardatosOredenProd(this.data.cod_orden_producto);

  }
  ngAfterViewInit() {

  }

  buscardatosOredenProd(xvalor:any){
    var valor = {cod_orden_producto:xvalor}
    console.log (valor, '1')
    this.servicio.buscardatosOredenProdhttp(valor).subscribe(
      response =>{
        var resul;
        var suma;
        var calcular_faltante;
        var calcular_excedente;
        console.log(response);
        this.cod_prod= response.formula[0].cod_prod_pt;
        this.des_prod = response.formula[0].des_prod_pt;
        this.cant_formula = response.formula[0].cantidad_prod_mp;
        this.cant_orden_producto = response.articulo[0].cant_producto;
        this.cant_orden_producto_mpp = this.data.objeto[0].cant_producto;
        this.cant_inv_prod_mp = response.inventario[0].cant_producto;

        suma = parseFloat(this.data.objeto2[0].cant_producto2.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')) + parseFloat(response.inventario[0].cant_producto.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'));

        resul = suma / parseFloat(response.formula[0].cantidad_prod_mp.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'));

        if (parseFloat(this.data.objeto[0].cant_producto.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')) > parseFloat(this.data.objeto2[0].cant_producto2.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'))) {

          calcular_faltante = parseFloat(this.data.objeto[0].cant_producto.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')) - parseFloat(this.data.objeto2[0].cant_producto2.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'));

        this.prod_mpp_faltante = calcular_faltante.toFixed(0).replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

        this.fila = 'faltante';

        }else{
          calcular_excedente = parseFloat(this.data.objeto2[0].cant_producto2.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) - parseFloat(this.data.objeto[0].cant_producto.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'));


          this.prod_mpp_excedente = calcular_excedente.toFixed(0).replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

        this.fila = 'excedente';
        }

        this.total_mp = suma.toFixed(2).replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


        this.sugerido = resul.toFixed(2).replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1,$2');



      },
      error =>{
        console.log(error);

        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }

modalrespuesta(){

    this.accion = "enviarordenprodmpp";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
    maxWidth: '100vw',
  height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        this.forma.value.cod_producto = this.cod_prod;
        this.forma.value.cant_formula_pt = this.cant_formula;
        this.forma.value.des_producto= this.des_prod;
        this.forma.value.cant_orden_prod = this.cant_orden_producto;
        this.forma.value.faltante = this.prod_mpp_faltante;
        this.forma.value.excedente = this.prod_mpp_excedente;
        this.forma.value.fila = this.fila;
        this.forma.value.ajuste_prod= this.sugerido;
        this.forma.value.ord_producto = this.ELEMENT_DATA;
        console.log(this.forma.value);
       this.dialogRefp.close(this.forma.value);

      }
    });
}
  cerrarmodal(): void {
    this.dialogRefp.close('cerrar');
  }
}
