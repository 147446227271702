import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-unidades',
  templateUrl: './unidades.component.html',
  styleUrls: ['./unidades.component.css']
})
export class UnidadesComponent implements OnInit, AfterViewInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  dialogSpin:any;
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

   constructor(
    private servicio:nkServices,
    private dialog: MatDialog, 
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
) {}

  ngOnInit(){
    this.listarunidad(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

  async listarunidad(msj:any){
    try {
          await this.util.abrirSpinner();
        let msg = msj;
        let xvalor = {
          cod_rol: await this.atrb.ROL_USUARIOS.cod_rol,
          cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa,
          api: await this.hashkey.encrypt(await this.atrb.API_REST.listarUnidades),
        }
      const response = await this.servicio.ApiResthttp(xvalor).toPromise();
      this.xobj3 = [];
            for (const i in response) {
              this.xobj3.push(response[i]);
            }
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
    
                    this.ELEMENT_DATA.push({
                      cod_unidad:response[i].cod_unidad,
                      des_unidad: response[i].des_unidad,
                      respon_unidad:response[i].respon_unidad,
                      cod_unidad_padre:response[i].cod_unidad_padre,
                      des_unidad_padre:response[i].des_unidad_padre,
                      estatus:response[i].estatus
                    });
                  }
                  this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_UNIDADES;
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
       
                  if(msg == await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                    await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                }else{
                  await this.util.cerrarSpinner();
                }
    } catch (error) {
      this.ELEMENT_DATA = [];
      this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_UNIDADES;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
    }
  }

async update_insert_data(obj:any,text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
                          var dialogRef:any;
                          this.accion = text;
                          if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                            if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def = {
                                val_def:await this.atrb.ACCESO_VALDEF.gestionar_licencias,
                                tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil),
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                              }
                              await this.servicio.ApiResthttp(val_def).toPromise();
                            }
                            await this.util.cerrarSpinner();
                            dialogRef = this.dialog.open(FormUnidadComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                      cod_unidad:obj.cod_unidad,
                                      des_unidad: obj.des_unidad,
                                      respon_unidad: obj.respon_unidad,
                                      cod_unidad_padre:obj.cod_unidad_padre,
                                      des_unidad_padre:obj.des_unidad_padre,
                                      estatus:obj.estatus,
                                      unidad:this.xobj3,
                                      accion:this.accion,
                                      operacion: await this.atrb.ACCION_ACTUALIZAR
                              },
                                disableClose: true,
                                panelClass: 'pedidos_modal_perfiles',
                                backdropClass: "bdrop"
                            });
                          }else{
                            if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def = {
                                val_def:await this.atrb.ACCESO_VALDEF.gestionar_licencias,
                                tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil),
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                              }
                              await this.servicio.ApiResthttp(val_def).toPromise();
                            }
                            await this.util.cerrarSpinner();
                            dialogRef = this.dialog.open(FormUnidadComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                    unidad:this.xobj3,
                                    accion:this.accion,
                                    operacion: await this.atrb.ACCION_CREAR
                                  },
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }
                            dialogRef.afterClosed().subscribe(async result => {
                              await this.util.abrirSpinner()
                              if(result !== await this.atrb.CERRAR){
                             console.log(result)
                                  this.gestionunidades(result)
                              }else{
                                await this.util.cerrarSpinner();
                              }
                            });
    } catch (error) {
      await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
    }
  }
async  gestionunidades(xvalor:any){
    try {
      xvalor.api = await this.hashkey.encrypt(await this.atrb.API_REST.gestionunidades)
    const response = await this.servicio.ApiResthttp(xvalor).toPromise();
     await this.util.cerrarSpinner();
        await this.listarunidad(null);
             await this.notifi.success(response.message);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
      await this.listarunidad(null);
    }
   }

async buscarUnidades(xvalor:string){
      var valor:any = {valor:xvalor}
      console.log(valor);
    this.servicio.buscarUnidadhttp(valor).subscribe(
      response =>{
        if(response){
          console.log(response, 'busqueda' );
          this.ELEMENT_DATA = [];
          for (const i in response) {

            this.ELEMENT_DATA.push({
              cod_unidad:response[i].cod_unidad,
              des_unidad: response[i].des_unidad,
              respon_unidad:response[i].respon_unidad,
              cod_unidad_padre:response[i].cod_unidad_padre,
              des_unidad_padre:response[i].des_unidad_padre,
              accion:'',
              accion2:'',
              accion3:''
            });
          }
          this.displayedColumns = ['cod_unidad',
                                    'des_unidad',
                                    'respon_unidad',
                                    'cod_unidad_padre',
                                    'des_unidad_padre',
                                    'Eliminar',
                                    'Actualizar'];
             this.dataSource = new MatTableDataSource<PeriodicElementunidad>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
       
      }
    );
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
