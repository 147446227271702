
<div id="modal_carrito" class=" container-fluid">

    <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #3649b6; border-radius: 12px 12px 0 0; background-color: #045fb4; ">
        <div id="content_2" class="col-md-3 px-0 py-2 h-100">
            <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#045fb4;">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 12px;">Codigo de centro de costo: </mat-label>
                    <input style="color: #fff; font-size: 12px;" #cod_cent matInput placeholder="" autocomplete="off" readonly>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                </mat-form-field>
            </div>
        </div>
        <div id="content_2" class="col-md-7 px-0 py-2 h-100">
            <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#045fb4;">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 12px;">Cuenta</mat-label>
                    <input style="color: #fff; font-size: 12px;" [value]="this.data.cod_cuenta_gasto" matInput placeholder="" autocomplete="off" readonly>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                </mat-form-field>
            </div>
        </div>
<!--         <div id="content_2" class="col-md-3 px-0 py-2 h-100">
            <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#045fb4;">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 12px;">Descripción</mat-label>
                    <input style="color: #fff; font-size: 12px;" #descrip_dep matInput placeholder="" autocomplete="off" readonly>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                </mat-form-field>
            </div> 
        </div>-->
<!--         <div class=" col-md-1 px-0 py-2 h-100"> (click)="dowload_store()" 
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100"  matTooltip="DESCARGAR INVENTARIO" style="color:rgb(255, 255, 255); background-color: rgb(74, 25, 163);">
                      <mat-icon >get_app</mat-icon>
                    </button>
            </div>
        </div> -->

        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)="listarSegCuentas('actualizar')" matTooltip="ACTUALIZAR LISTA" style="border-radius: 1rem; border: outset 3px rgb(4, 39, 104); color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                      <mat-icon >sync</mat-icon>
                    </button>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" style ="border-radius: 1rem; border: outset 3px rgb(116, 14, 21);" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                </button>
            </div>
        </div>

    </div>
    <div id="carrito_p2" class="row" style=" border: 2px solid #374fd6; border-radius: 0 0 12px 12px;">
        <div class="col-md-12 px-0  border h-100" style="background-color: #045fb4; border-radius: 0 0 12px 12px;">
            <table #table mat-table [dataSource]="dataSource1" class=" table-primary text-center table-striped   table-condensed table-hover">

                <ng-container matColumnDef="cod_cuenta_gasto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Cod cuenta</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.cod_cuenta_gasto}}</td>
                    
                </ng-container>

                <ng-container matColumnDef="cod_presupuesto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Cod presupuesto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.cod_presupuesto}}</td>
                    
                </ng-container>

                <ng-container matColumnDef="des_cuenta_gasto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Descripcion</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_cuenta_gasto}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="operacion">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Operacion</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.operacion}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cant_cuenta_gasto_desc">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_cuenta_gasto_desc}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cant_cuenta_gasto_actual">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Actual</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_cuenta_gasto_actual}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cant_cuenta_gasto_cambio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Restante</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_cuenta_gasto_cambio}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="comentario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Comentario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.comentario}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Cod usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_usuario}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="fecha_cambio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha_cambio}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="hora_cambio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_cambio}} </td>
                    
                </ng-container>

               <!--  <ng-container matColumnDef="movimientos">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Movimientos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="movimientos_cuentas(element)">
                        <button matTooltip="MOVIMIENTOS" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(22, 173, 22);">
                          <mat-icon >access_time</mat-icon>
                          </button>
                    </td>
                   
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" style=" background-color: #045fb4;" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #3649b6; background-color: #045fb4; border-radius: 12px; ">
        
        <div class="col-md-12 px-0 py-2 h-100">
            <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 3px solid #374fd6;">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                    <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
