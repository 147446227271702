import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import {Observable} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-crear-centro-costos',
  templateUrl: './form-crear-centro-costos.component.html',
  styleUrls: ['./form-crear-centro-costos.component.css']
})
export class FormCrearCentroCostosComponent implements OnInit {

  @ViewChild('des_unidad') des_unidad:ElementRef;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  arry: Observable<StateGroup[]>;

  forma:FormGroup;
  accion:any;
  dialogSpin:any;
  stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
departamentos:any = [];
cc:any = [];
xobj2:any=[];
xobj3:any=[];
xobj4:any=[];
xobj5:any = [];
ELEMENT_DATA:any[]=[]
displayedColumns:string[];
dataSource=new MatTableDataSource<any>();

constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
  public dialogRefp: MatDialogRef<FormCrearCentroCostosComponent>,
  
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any
){
  this.accion = data.accion;
  this.forma = fb.group({
    /* DATOS PERSONALES */
    'des_centro':[data.des_centro,[Validators.required]],
    'des_unidad':'',
    'cod_usuario':localStorage.getItem('cod_usuario'),
    'des_usuario':localStorage.getItem('Nombre'),
  });
 }
  ngOnInit(): void {
    this.listardepartamento()
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {

  }

  listardepartamento(){
    this.servicio.listarDepartamentoshttp().subscribe(
      response =>{
        /////////////MENU//////////////////
        if(response){
          this.ELEMENT_DATA = [];
              for (const i in response) { 
                this.cc = response[i].min_concepto_definicion.split('-')   
                this.ELEMENT_DATA.push({
                  departamentos:response[i].descrip_definicion.toUpperCase(),
                  cc: this.cc[1],
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              console.log(this.ELEMENT_DATA,'ELEMENT_DATA');
             this.displayedColumns = ['departamentos'];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log('una respuesta');
        console.log(<any>error.error[0].message);
        this.displayedColumns = ['departamentos'];
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
      }
    );
  }
  
  modalrespuesta(){

    var accion2 = 'enviarcliente';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:this.data.cod_usuario,
                     cod_usuario:localStorage.getItem('cod_usuario'),
                     des_usuario:localStorage.getItem('Nombre'),
            accion:accion2},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(this.forma.value, 'informacion 2');
        this.dialogRefp.close(this.forma.value);
      }
    });
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
