<div id="modal_carrito" style="background-image: linear-gradient(to right bottom, #0070b7, #0085be, #0095a9, #00a17d, #28a745);;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container" >
        <div class="row mt-3 justify-content-between px-3">
            <h2 class="m-0 text-light">Crear Orden de Compra</h2>
            <button (click)="cerrarmodal()" class="boton"> <mat-icon style="color: #ff2b34;" >clear</mat-icon> </button>
        </div>

        <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style="  background-color: #f1ede9;height: 80% !important; ">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
        
                    <table #table mat-table [dataSource]="dataSource" class=" text-center table-striped  table-condensed table-hover">

                        <ng-container matColumnDef="cod_proveedor">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                                <h5><strong>RIF/CI Proveedor </strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_proveedor}}</strong> </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="des_proveedor">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                                <h5><strong>Empresa del Proveedor</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_proveedor}}</strong> </td>
                        </ng-container>
        
                        <ng-container matColumnDef="cant_cotizaciones">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                                <h5><strong>Descripcion</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_cotizaciones}}</strong> </td>
                        </ng-container>
    
                        <ng-container matColumnDef="accion">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #003f66; padding: 5px; color: #fff;">
                                <h5><strong>Seleccionar</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                <button matTooltip="ELEGIR" (click)="tomar_data(element)"  class="btn btn-outline-success rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                    <mat-icon>my_library_books</mat-icon>
                                </button>
    
                            </td>
                            <td mat-footer-cell *matFooterCellDef style="background-color: #003f66; color: #fff;"> </td>
                        </ng-container>
    
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
    
                    </table> 
            </div>
        </div>
        
    </div>
                
</div>


