import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-confirm-consignacion',
  templateUrl: './form-confirm-consignacion.component.html',
  styleUrls: ['./form-confirm-consignacion.component.css']
})
export class FormConfirmConsignacionComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('des_cliente',{static:true}) des_cliente:ElementRef;
  @ViewChild('cod_consignacion',{static:true}) cod_consignacion:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
dialogSpin:any;
inventario:any = [];
observador:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormConfirmConsignacionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');


      this.forma = fb.group({
        'cod_consignacion':data.cod_consignacion,
        'cod_cliente':data.cod_cliente,
        'des_cliente':data.des_cliente,
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'tipo_um':data.tipo_um,
        'pedido':''

      });
    }

  ngOnInit(): void {

    this.des_cliente.nativeElement.value = this.data.des_cliente;
    this.cod_consignacion.nativeElement.value = this.data.cod_consignacion;
    this.listarConsignacion(this.data);

  }
  ngAfterViewInit() {

  }

  listarConsignacion(xvalor:any){
    var valor = {cod_consignacion:xvalor}
   this.servicio.buscarProductoConsignacionhttp(xvalor).subscribe(
     response =>{
       console.log(response);
         this.ELEMENT_DATA = [];

         for (const i in response.producto1) {
         
          if ( parseFloat(response.producto1[i].cant_producto.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) >  parseFloat(response.producto2[i].cant_producto.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.'))) {

            this.observador = true;
            
          }else{
            this.observador = false;
          }

           this.ELEMENT_DATA.push({
                 cod_producto:response.producto1[i].cod_producto,
                 des_producto:response.producto1[i].des_producto,
                 cant_unidad_producto:response.producto1[i].cant_producto,
                 observador:this.observador
           });
         }

         for (const i in response.producto2) {

          if ( parseFloat(response.producto1[i].cant_producto.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) >  parseFloat(response.producto2[i].cant_producto.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.'))) {

            this.observador = true;
            
          }else{
            this.observador = false;
          }

          this.ELEMENT_DATA2.push({
                    cod_producto2:response.producto2[i].cod_producto,
                    des_producto2:response.producto2[i].des_producto,
                    cant_producto2:response.producto2[i].cant_producto,
                    observador:this.observador
          });

          this.inventario.push(response.producto2[i].cod_deposito);
          this.inventario.push(response.producto2[i].des_desposito);
        }
        console.log(this.inventario,'inventario');
             /* for (const i in response.producto1) {
               this.ELEMENT_DATA.push({
                 cod_producto:response.producto1[i].cod_producto,
                 des_producto:response.producto1[i].des_producto,
                 cant_unidad_producto:response.producto1[i].cant_producto,
                 observador:this.observador
               });
             }
             for (const i in response.producto2) {

              this.ELEMENT_DATA2.push({
                cod_producto2:response.producto2[i].cod_producto,
                des_producto2:response.producto2[i].des_producto,
                cant_producto2:response.producto2[i].cant_producto,
                observador:this.observador
              });
            } */
             this.displayedColumns = [
                                       'cod_producto',
                                       'des_producto',
                                       'cant_producto'
                                     ];
              this.displayedColumns2 = [
                                       'cod_producto2',
                                       'des_producto2',
                                       'cant_producto2'
                                     ];
             this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);

     },
     error =>{
       console.log(error);
       console.log(<any>error.error.message);
                this.displayedColumns = [
                  'cod_producto',
                  'des_producto',
                  'cant_producto'
                ];
                this.displayedColumns2 = [
                        'cod_producto2',
                        'des_producto2',
                        'cant_producto2'
                      ];
       this.dataSource1 = new MatTableDataSource<any>();
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
   );
 }

 modalrespuesta(){
   try {
    var key = true;
    var xvalor;
    if (this.ELEMENT_DATA.length > 0) {
      for (const i in this.ELEMENT_DATA) {
        console.log(this.ELEMENT_DATA2[i]);
        if (this.ELEMENT_DATA2[i] === undefined || this.ELEMENT_DATA[i] === undefined) {
          key = false;
          break;
        }else{
          if (parseInt(this.ELEMENT_DATA[i].cant_unidad_producto.replace('.', '')
                                          .replace('.', '')
                                          .replace('.', '')
                                          .replace(',', '.')) > parseInt(this.ELEMENT_DATA2[i].cant_producto2.replace('.', '')
                                          .replace('.', '')
                                          .replace('.', '')
                                          .replace(',', '.'))  ) {
            key = false;
            break;
          }
        }
      }
      if (key) {
        this.accion = false;
        var dialogRef
          dialogRef = this.dialog.open(FormmMsgComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
            data: {id:'',
                  accion:this.accion,
                  tipo_pedido:this.data.tipo_pedido},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
          });
  
  
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
  
            this.forma.value.pedido = this.ELEMENT_DATA;
            console.log(this.forma.value);
            this.dialogRefp.close(this.forma.value);
  
          }
        });
      }else{
        this.snackBar.open('Inventario insuficiente para cumplir con la consignación Nº: '+this.data.cod_consignacion+'', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }else{
      this.snackBar.open('No hay productos a consignar', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
   } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
   }

}

cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_unidad_producto)
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.')) ;
       });

     return suma.toFixed(0).replace(/\D/g, "")
     .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

  }
  cantidad_productos_2() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA2) {
        matriz.push(this.ELEMENT_DATA2[i].cant_producto2)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });

       return suma.toFixed(0).replace(/\D/g, "")
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

    }
  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }
  cantidad_item_2() {
    try {
      return this.ELEMENT_DATA2.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

}
