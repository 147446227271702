import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportes-app',
  templateUrl: './reportes-app.component.html',
  styleUrls: ['./reportes-app.component.css']
})
export class ReportesAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
