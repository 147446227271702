import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';


export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};


@Component({
  selector: 'app-form-pedido-frecuencial',
  templateUrl: './form-pedido-frecuencial.component.html',
  styleUrls: ['./form-pedido-frecuencial.component.css']
})
export class FormPedidoFrecuencialComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('producto_pedido') producto_pedido:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('lote_producto',{static:true}) lote_producto:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('cod_lista_precio',{static:true}) cod_lista_precio:ElementRef;
  @ViewChild('iva',{static:true}) iva:ElementRef;
  @ViewChild('items',{static:true}) items:ElementRef;
  @ViewChild('desc_pedido_monto',{static:true}) desc_pedido_monto:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('exento_monto',{static:true}) exento_monto:ElementRef;




  stateGroupOptions: Observable<StateGroup[]>;
  stateGroupOptions2: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
lista:any;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
tipo_cliente:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
codigprod:any;
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormPedidoFrecuencialComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

      this.forma = fb.group({
        'id_pedido':data.id,
        'cod_cliente':'( '+data.cod_cliente +' )'+' '+ data.des_cliente,
        'des_cliente':data.des_cliente,
        'tipo_cliente':data.tipo_cliente,
        'cod_vendedor':'',
        'des_vendedor':data.des_vendedor,
        'estatus_cliente':data.estatus_cliente,
        'estatus_pedido':data.estatus_pedido,
        'estatus_fiscal':'',
        'tipo2_cliente':data.tipo2_cliente,
        'credito_dias_cliente':data.credito_dias_cliente,
        'reten_cliente':data.reten_cliente,
        'comercializacion_pedido':data.comercializacion,
        'producto_pedido':'',
        'lote_producto':'',
        'dispo_pedido':'',
        'cantidad_pedido':'',
        'iva_exento':'0,00',
        'monto_exento':'0,00',
        'subtotal_pedido':'0,00',
        'total_pedido':'0,00',
        'iva_pedido':'16',
        'iva_monto':'0,00',
        'desc_pedido':'0,00',
        'desc_pedido_monto':'0,00',
        'tipo_pedido':'FF',
        'cod_lista_precio':'',

      });

      this.stateForm = this.formb.group({
        stateGroup: '',
      });
    }

  ngOnInit(): void {
    this.items.nativeElement.value = '0';
    this.consultarPedido(this.data.cod_pedido);
    this.lote = true;
    this.formulario();
    this.listarproductot();

    if (this.data.cod_pedido === undefined ||  this.data.cod_pedido === '') {
        console.log('entro a buscar la lista');
      this.buscarLprecios();
    }else{
      var valor={cod_cliente:this.data.cod_cliente}
      this.servicio.listarClientePedidoTipohttp(valor ).subscribe(
        response =>{
          console.log(response);
          this.tipo_cliente = response.resul[0].tipo_cliente;
          this.recalcularPrecio(0,this.tipo_cliente);
        },
        error =>{
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    }


  }
  ngAfterViewInit() {

  }
  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }

    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups2
        .map(group2 => ({letter: group2.letter, names: filter(group2.names, value), code: filter(group2.code, value)}))
        .filter(group2 => group2.names.length > 0 || group2.code.length > 0 );
    }

    return this.stateGroups2;
  }

  listarproductot(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarProductoTermhttp().subscribe(
      response =>{
        this.stateGroupOptions = this.forma.get('producto_pedido')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }
                  console.log(this.producto);
                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.codigo
                  }];
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }
  listarClientePedidoTipo(xvalor:any){
    try {
      var valor={cod_cliente:xvalor}
      this.servicio.listarClientePedidoTipohttp(valor).subscribe(
        response =>{
          console.log(response);
          this.tipo_cliente = response.resul[0].tipo_cliente;
        },
        error =>{
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  buscarLprecios(){

      var client = this.forma.value.cod_cliente.split(' ');
      var cod = this.forma.value.producto_pedido.split(' ');
      var lod = this.forma.value.lote_producto.split(' ');
      console.log(this.forma.value.tipo_cliente);
    var cod_p = cod[0].trim();
    var cod_l = lod[0].trim();
    var cliente = client[1];
    var tipo = this.forma.value.tipo_cliente;
    var valor:any = {cod_producto:cod_p,lote_producto:cod_l,cod_cliente:cliente,tipo_cliente:tipo}
    this.servicio.buscarLprecioshttp(valor).subscribe(
      response =>{
        console.log(response[0].cod_lista_precio_producto);
        this.cod_lista_precio.nativeElement.value = response[0].cod_lista_precio_producto;
        this.snackBar.open('Su lista de precios es la ultima actualización', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );

  }
  recalcularPrecio(termino:any, xvalor:any){
    var resul_final_recal;
     if (termino != 'calcular') {
       this.tipo_cliente = xvalor;
     }

       var client = this.forma.value.cod_cliente.split(' ');
       var cod = this.forma.value.producto_pedido.split(' ');
       var lod = this.forma.value.lote_producto.split(' ');
       console.log(client);
     var cod_p = cod[0].trim();
     var cod_l = lod[0].trim();
     var cliente = client[1];
     var tipo = this.forma.value.tipo_cliente;
     console.log(tipo);
     var valor:any = {cod_producto:cod_p,lote_producto:cod_l,cod_cliente:cliente,tipo_cliente:tipo}
     this.servicio.buscarLprecioshttp(valor).subscribe(
       response =>{
         console.log(response[0]);
         console.log(this.cod_lista_precio.nativeElement.value);
         if (response[0].cod_lista_precio_producto != this.cod_lista_precio.nativeElement.value) {
           switch (termino) {
             case 'calcular':

               this.accion = "recalcularpedido";
                             var dialogRef = this.dialog.open(FormmMsgComponent, {
                               width: '25%',
                               height:'25%',
                               data: {id:'',
                                     accion:this.accion},
                               disableClose: true,
                               panelClass: 'pedidos_modal'
                             });
                             dialogRef.afterClosed().subscribe(result => {
                               if(result != 'cerrar'){
                                 var lista = response[0];
                                 this.servicio.buscarLpreciosRelacionhttp(response[0]).subscribe(
                                   response=>{
                                     var resul = 0;
                                     var valor;
                                     console.log(response);
                                     console.log(this.ELEMENT_DATA);
                                     for (const i in this.ELEMENT_DATA) {
                                         var indice = this.ELEMENT_DATA[i].cod_producto;
                                       for (const k in response) {
                                         if (indice.toUpperCase() === response[k].cod_producto) {
                                           valor = response[k].precio_producto_base_bs;
                                           break;
                                         }
                                       }

                                       console.log(valor);
                                         var subtotal = parseFloat(this.ELEMENT_DATA[i].cant_producto) * parseFloat(valor.replace('.','')
                                         .replace('.','')
                                         .replace('.','')
                                         .replace(',','.'));
                                         this.ELEMENT_DATA[i].costo_producto = valor;
                                         this.ELEMENT_DATA[i].subtotal_producto = subtotal.toFixed(2).replace(/\D/g, "")
                                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                         this.ELEMENT_DATA[i].cod_lista_precio = response[0].cod_lista_precio_producto;
   var monto_nuevo = 0;
   var monto_exc_nuevo = 0;

             for (const i in this.ELEMENT_DATA) {



                 if (this.ELEMENT_DATA[i].estatus_fiscal.toUpperCase() === 'XCNT') {
                   monto_exc_nuevo = monto_exc_nuevo + parseFloat(this.ELEMENT_DATA[i].subtotal_producto.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.'));
                 }else{
                   monto_nuevo = monto_nuevo + parseFloat(this.ELEMENT_DATA[i].subtotal_producto.replace('.','')
                   .replace('.','')
                   .replace('.','')
                   .replace('.','')
                   .replace('.','')
                   .replace(',','.'));
                 }

             }

             let porcentaje = (monto_exc_nuevo  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.'))/100);

             let porcentaje1 = (monto_nuevo  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.'))/100);

             let desc_nuevo = porcentaje1 + porcentaje;

             let xcnt = monto_exc_nuevo - porcentaje;

             let nxcnt = monto_nuevo - porcentaje1;

             let sub_total_nuevo = xcnt + nxcnt;

             let iva_nuevo = nxcnt * parseFloat(this.iva.nativeElement.value) / 100;

             this.exento_monto.nativeElement.value = xcnt.toFixed(2).replace(/\D/g, "")
             .replace(/([0-9])([0-9]{2})$/, '$1,$2')
             .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

             this.subtotal_pedido.nativeElement.value = sub_total_nuevo.toFixed(2).replace(/\D/g, "")
                                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                                       this.iva_monto.nativeElement.value = iva_nuevo.toFixed(2).replace(/\D/g, "")
                                       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

           this.desc_pedido_monto.nativeElement.value = desc_nuevo.toFixed(2).replace(/\D/g, "")
           .replace(/([0-9])([0-9]{2})$/, '$1,$2')
           .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

           let resul_final = sub_total_nuevo + iva_nuevo;

           this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
           .replace(/([0-9])([0-9]{2})$/, '$1,$2')
           .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


    if (this.desc_pedido.nativeElement.value === "") {
      var cero = '0,00';
             let porcentaje = (monto_exc_nuevo  * parseFloat(cero)/100);

             let porcentaje1 = (monto_nuevo  * parseFloat(cero)/100);

             let desc_nuevo = porcentaje1 + porcentaje;

             let xcnt = monto_exc_nuevo - porcentaje;

             let nxcnt = monto_nuevo - porcentaje1;

             let sub_total_nuevo = xcnt + nxcnt;

             let iva_nuevo = nxcnt * parseFloat(this.iva.nativeElement.value) / 100;

             this.exento_monto.nativeElement.value = xcnt.toFixed(2).replace(/\D/g, "")
             .replace(/([0-9])([0-9]{2})$/, '$1,$2')
             .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

             this.subtotal_pedido.nativeElement.value = sub_total_nuevo.toFixed(2).replace(/\D/g, "")
                                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                                       this.iva_monto.nativeElement.value = iva_nuevo.toFixed(2).replace(/\D/g, "")
                                       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


           this.desc_pedido_monto.nativeElement.value = desc_nuevo.toFixed(2).replace(/\D/g, "")
           .replace(/([0-9])([0-9]{2})$/, '$1,$2')
           .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

           let resul_final = sub_total_nuevo + iva_nuevo;

           this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
           .replace(/([0-9])([0-9]{2})$/, '$1,$2')
           .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    }
                                     }


                                   },
                                   error=>{
                                     console.log(<any>error.error.message);
                                     this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                       verticalPosition: this.verticalPosition,});
                                   }

                                 );

                                 /* let cod_vendedor = localStorage.getItem('cod_usuario');
                                 let cod_cliente = this.forma.value.cod_cliente.split(' ');
                                 let lote_producto = this.forma.value.lote_producto.split(' ');
                                 this.forma.value.cod_vendedor = cod_vendedor;
                                 this.forma.value.lote_producto = lote_producto[0];
                                 this.forma.value.cod_cliente = cod_cliente[1];
                                 this.forma.value.producto_pedido = this.ELEMENT_DATA;
                                 this.forma.value.iva_monto = this.iva_monto.nativeElement.value;
                                 this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                                 this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                                 this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                                 this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                                 console.log(this.forma.value);
                               this.dialogRefp.close(this.forma.value); */
                               this.snackBar.open('Usted realizo la actualizacion de la lista de precios de forma exitosa', 'Cerrar',{duration:5000,horizontalPosition: this.horizontalPosition,
                                 verticalPosition: this.verticalPosition,});
                               }

                             });

               break;

             default:
               if (this.cod_lista_precio.nativeElement.value === response[0].cod_lista_precio_producto) {
                 this.snackBar.open('Su lista de precios es la ultima actualización', 'Cerrar',{duration:5000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
               }else{
                 this.snackBar.open('Su lista de precios no esta actualizada', 'Cerrar',{duration:5000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
               }
               break;
           }
         }else{
           console.log(termino);
           if (termino === 0) {
             this.snackBar.open('Su lista de precios es la ultima actualización', 'Cerrar',{duration:5000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
           }else{
             this.snackBar.open('Ya usted realizo la actualizacion de la lista de precios', 'Cerrar',{duration:5000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
           }

         }


       },
       error =>{
         console.log(<any>error.error.message);
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
       }
     );

   }
  buscarlote(xvalor:any){
    this.lote_producto.nativeElement.value = '';
    this.lotepro = [];
    var cod = xvalor.split(' ')

    var cod_p = cod[0].trim();
    this.codigprod = cod_p;
    var valor:any = {cod_producto:cod_p}
    console.log(valor);
    this.lote = true;
    this.servicio.buscarPRODUCTOTcodttp(valor).subscribe(
      response =>{
        console.log(response);
        this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup2(value))
                                );
                  for (const i in response) {
                    if (response[i].cant_producto > 0) {
                      this.lotepro.push(response[i].lote_producto);
                    }
                  }

                  this.stateGroups2= [{
                    letter: 'Lotes',
                    names: this.lotepro,
                    code: this.lotepro
                  }];
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  buscarcantidad(xvalor:any){

    var cod = xvalor.split(' ')
    console.log(this.codigprod);

    var lot_p = cod[0].trim();
    var valor:any = {cod_producto:this.codigprod,lote_producto:lot_p};
    console.log(valor);
    this.cant = true;
    this.servicio.buscarLOTEcodttp(valor).subscribe(
      response =>{

        this.dispo_pedido.nativeElement.value = response[0].cant_producto+' /Unidades';

        if (response[0].cant_producto > 0) {
          this.cant = true;
        }else{
          this.cant = false;
          this.snackBar.open('Producto agotado !.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }


  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
    }else{
    console.log(this.forma.value);
    this.dialogRefp.close(this.forma.value);
    }

  }

/* separador(val:any){
    var value;
    value = parseFloat(val.replace(/,/g, ""))
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.cantidad.nativeElement.value = value;
                    console.log(value);

  } */

public consultarPedido(xvalor:any){
  console.log(xvalor);
  var valor = {cod_pedido:xvalor};
  try {
    this.servicio.buscarPedidoProductocodttp(valor).subscribe(
      response =>{
      console.log(response);
      for (const i in response.resul) {
        this.ELEMENT_DATA.push({
          cod_producto:response.resul[i].cod_producto,
          des_producto:response.resul[i].des_producto,
          cod_lista_precio:response.resul2[0].cod_lista_precio,
          cant_producto:response.resul[i].cant_producto,
          costo_producto:response.resul[i].costo_producto,
          tipo_producto:response.resul[i].tipo_producto,
          subtotal_producto: response.resul[i].subtotal_producto,
          tamano_producto:response.resul[i].tamano_producto,
          estatus_fiscal:response.resul[i].estatus_fiscal
        });
      }
      this.items.nativeElement.value = this.ELEMENT_DATA.length;
      this.desc_pedido.nativeElement.value = response.resul2[0].desc_pedido;
      this.exento_monto.nativeElement.value = response.resul2[0].monto_exento;
      this.subtotal_pedido.nativeElement.value = response.resul2[0].subtotal_costo_pedido;
      this.iva_monto.nativeElement.value = response.resul2[0].iva_monto;
      this.desc_pedido_monto.nativeElement.value = response.resul2[0].desc_pedido_monto
      this.total_pedido.nativeElement.value = response.resul2[0].total_costo_pedido;
      this.cod_lista_precio.nativeElement.value = response.resul2[0].cod_lista_precio;
      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      console.log(this.ELEMENT_DATA);
      this.lista = true;
      },
      error =>{
        console.log(<any>error.error.message);
        this.lista = false;
      }
    );

  } catch (error) {
    this.snackBar.open(error.toString(), 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
formulario():void{
  /* this.ELEMENT_DATA = []; */
  this.cant = false;
  this.displayedColumns = [
    'cod_lista_precio',
    'cod_producto',
    'des_producto',
    'cant_producto',
    'costo_producto',
    'subtotal_producto',
    'remov_elem'
   ];
/* this.dataSource = this.ELEMENT_DATA; */
this.dataSource1 = new MatTableDataSource<PeriodicElementfacturas>(this.ELEMENT_DATA);
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource1.filter = filterValue.trim().toLowerCase();
}

cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_producto)
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.')) ;
       });
       console.log(suma);

     return suma;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

  }
  costo_producto() {

    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].costo_producto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
         console.log(suma);

       return suma.toFixed(3).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{3})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  sub_total_producto() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].subtotal_producto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
         console.log(suma);

       return suma.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }

addElement() {
  var resul_final;
  let arry = [];
  let arry2 = [];
  console.log(this.num.nativeElement.value);

  if (parseFloat(this.num.nativeElement.value) > 0  && this.producto_pedido.nativeElement.value != '') {
    var client = this.forma.value.cod_cliente.split(' ');
    var cod = this.forma.value.producto_pedido.split(' ');
    var lod = this.forma.value.lote_producto.split(' ');
    console.log(cod);
  var cod_p = cod[0].trim();
  var cod_l = lod[0].trim();
  var cliente = client[1];
  var tipo = this.forma.value.tipo_cliente;
  var valor:any = {cod_producto:cod_p,lote_producto:cod_l,cod_cliente:cliente,tipo_cliente:tipo}
  this.servicio.buscarPRODUCTOTttp(valor).subscribe(
    response =>{
      console.log(response[1]);
      if (response[1].length > 0 &&  parseFloat(response[1][0].precio_producto_base_bs.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) > 0) {
        console.log(response);
      this.producto_pedido.nativeElement.value = '';
        let subtotal = (parseFloat(response[1][0].precio_producto_base_bs.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'))  *  parseFloat(this.num.nativeElement.value.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')));

        let costoprodcto = parseFloat(response[1][0].precio_producto_base_bs);


      if (this.ELEMENT_DATA.length == 0) {
        this.items.nativeElement.value = parseFloat(this.items.nativeElement.value) + 1;
        this.codigo = [];
        this.producto = [];
        this.stateGroups2 =[];

        this.lotepro = [];
        this.listarproductot();
        this.ELEMENT_DATA.push({
          cod_producto:response[0][0].cod_producto,
          des_producto:response[0][0].des_producto,
          cod_lista_precio:response[2][0].cod_lista_precio_producto,
          cant_producto:this.num.nativeElement.value,
          costo_producto:response[1][0].precio_producto_base_bs,
          estatus_fiscal:response[0][0].estatus_fiscal,
          tipo_producto:response[0][0].tipo_producto,

          subtotal_producto: subtotal.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),

          lote_producto:response[0][0].lote_producto,
          tamano_producto:response[0][0].tamano_producto
        });


       let porcentaje = (subtotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'))/100);

        let monto_sub = subtotal - porcentaje;

        this.subtotal_pedido.nativeElement.value = monto_sub.toFixed(2).replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

        let total = (monto_sub * parseFloat(this.iva.nativeElement.value) / 100);

        this.iva_monto.nativeElement.value = total.toFixed(2).replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

        let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')));


        this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(2).replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

        if (response[0][0].estatus_fiscal.toUpperCase() === 'XCNT') {

          this.exento_monto.nativeElement.value = monto_sub.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          var iva_exento = monto_sub * parseFloat(this.iva.nativeElement.value) / 100;

          var iva_menos_exento = parseFloat(this.iva_monto.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) - iva_exento;

          this.iva_monto.nativeElement.value = iva_menos_exento.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          var resta_iva = montoTotal - iva_exento;

          /* var porcentaje_exento = (resta_iva  *  parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'))/100);

          this.desc_pedido_monto.nativeElement.value = porcentaje_exento.toFixed(2).replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");  */

          resul_final = resta_iva ;

        }else{

           resul_final = montoTotal;
        }

        this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

        this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);

        this.producto_pedido.nativeElement.value = '';
        this.num.nativeElement.value = '';
        this.forma.value.cantidad_pedido = '';
        this.cant = false;
        this.lote = false;
        this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                              .pipe(
                                startWith(''),
                                map(value => this._filterGroup2(value))
                              );

                this.stateGroups2= [{
                  letter: 'Lotes',
                  names: '',
                  code: ''
                }];
      }else{
        if (this.ELEMENT_DATA.length <=38) {

          for (const i in this.ELEMENT_DATA) {
            arry.push(this.ELEMENT_DATA[i].cod_producto);
            arry2.push(this.ELEMENT_DATA[i].lote_producto);
           }
          console.log(this.num.nativeElement.value);
           let lote = arry2.includes(response[0][0].lote_producto);
           let index = arry.includes(response[0][0].cod_producto);

           if (!index && !lote || index && !lote || !index && lote) {
            this.items.nativeElement.value = parseFloat(this.items.nativeElement.value) + 1;
             this.lotepro = [];
             this.stateGroups2 =[];
             this.codigo = [];
             this.producto = [];
               this.listarproductot();
               this.ELEMENT_DATA.push({
                 cod_producto:response[0][0].cod_producto,
                 des_producto:response[0][0].des_producto,
                 cod_lista_precio:response[2][0].cod_lista_precio_producto,
                 cant_producto:this.num.nativeElement.value,
                 costo_producto:response[1][0].precio_producto_base_bs,
                 tipo_producto:response[0][0].tipo_producto,
                 subtotal_producto: subtotal.toFixed(2).replace(/\D/g, "")
                 .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                 estatus_fiscal:response[0][0].estatus_fiscal,
                 tamano_producto:response[0][0].tamano_producto
               });

               let porcentaje = (subtotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.'))/100);

                let monto_sub = subtotal - porcentaje;

               this.subtotal_pedido.nativeElement.value = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace(',','.'))) +  (monto_sub);



               this.subtotal_pedido.nativeElement.value = parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(2).replace(/\D/g, "")
               .replace(/([0-9])([0-9]{2})$/, '$1,$2')
               .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

               let total = parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace(',','.')) * parseFloat(this.iva.nativeElement.value) / 100;

               this.iva_monto.nativeElement.value = total.toFixed(2).replace(/\D/g, "")
               .replace(/([0-9])([0-9]{2})$/, '$1,$2')
               .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')));

                let suma_descuentos = (parseFloat(this.desc_pedido_monto.nativeElement.value.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.') ) + porcentaje);


                      this.desc_pedido_monto.nativeElement.value = suma_descuentos.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                      if (response[0][0].estatus_fiscal.toUpperCase() === 'XCNT') {

                        var sumar_exento = monto_sub + parseFloat(this.exento_monto.nativeElement.value.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'));

                        this.exento_monto.nativeElement.value = sumar_exento.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                        var iva_exento = parseFloat(this.exento_monto.nativeElement.value.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) * parseFloat(this.iva.nativeElement.value) / 100;

                        var iva_menos_exento = parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) - iva_exento;

                        this.iva_monto.nativeElement.value = iva_menos_exento.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                        let montoTotal_especial = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace(',','.')));

                        /* let porcentaje_especial = (montoTotal_especial  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'))/100);

                         this.desc_pedido_monto.nativeElement.value = porcentaje_especial.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".") */

                        var resta_iva = montoTotal - iva_exento;

                        resul_final = resta_iva;

                      }else{

                        if (parseFloat(this.exento_monto.nativeElement.value.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) > 0) {

                          var iva_exento_restar = parseFloat(this.exento_monto.nativeElement.value.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) * parseFloat(this.iva.nativeElement.value) / 100;

                        var iva_exento_restar_format = iva_exento_restar.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                        var iva_menos_exento_restar = parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) - parseFloat(iva_exento_restar_format.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'));

                        this.iva_monto.nativeElement.value = iva_menos_exento_restar.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                        let montoTotal_especial = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace('.','')
                          .replace(',','.')));

                          var monto_especial_format = montoTotal_especial.toFixed(2).replace(/\D/g, "")
                          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                        /* let porcentaje_especial = (parseFloat(monto_especial_format.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'))  *  parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'))/100);

                        console.log(monto_especial_format);

                        this.desc_pedido_monto.nativeElement.value = porcentaje_especial.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".") */

                        var resta_iva_restar = (parseFloat(monto_especial_format.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'))) - parseFloat(iva_exento_restar_format.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'));

                        console.log(resta_iva_restar);

                        resul_final = montoTotal_especial;
                        }else{
                          console.log('aqui entro')
                          resul_final = montoTotal ;
                        }

                      }

               this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
               .replace(/([0-9])([0-9]{2})$/, '$1,$2')
               .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


               this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);

               this.producto_pedido.nativeElement.value = '';
               this.num.nativeElement.value = '';
               this.forma.value.cantidad_pedido = '';
               this.cant = false;
               this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                               .pipe(
                                 startWith(''),
                                 map(value => this._filterGroup2(value))
                               );

                 this.stateGroups2= [{
                   letter: 'Lotes',
                   names: '',
                   code: ''
                 }];

           }else{
             this.stateGroups2 =[];
             this.lotepro = [];
             this.codigo = [];
             this.producto = [];
             this.listarproductot();
             this.producto_pedido.nativeElement.value = '';
             this.num.nativeElement.value = '';
             this.forma.value.cantidad_pedido = '';
               this.cant = false;
               this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                               .pipe(
                                 startWith(''),
                                 map(value => this._filterGroup2(value))
                               );

                 this.stateGroups2= [{
                   letter: 'Lotes',
                   names: '',
                   code: ''
                 }];
               this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
           }
        }else{
          this.snackBar.open('Solo puede agregar un maximo de  39 items', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }

      }
      }else{
        this.stateGroups2 =[];
             this.lotepro = [];
             this.codigo = [];
             this.producto = [];
             this.listarproductot();
             this.producto_pedido.nativeElement.value = '';
             this.num.nativeElement.value = '';
             this.forma.value.cantidad_pedido = '';
               this.cant = false;
               this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                               .pipe(
                                 startWith(''),
                                 map(value => this._filterGroup2(value))
                               );

                 this.stateGroups2= [{
                   letter: 'Lotes',
                   names: '',
                   code: ''
                 }];
                 this.snackBar.open('Este producto no esta asignado a este cliente', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
      }


    },
    error =>{
      console.log(<any>error.error.message);
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  );
  }else{

      if (parseInt(this.num.nativeElement.value) <= 0 || isNaN(this.num.nativeElement.value) || this.num.nativeElement.value === "") {
        this.snackBar.open('La cantidad debe ser mayor a 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }

    if (this.producto_pedido.nativeElement.value === '') {
      this.snackBar.open('Debe seleccionar el producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  console.log(this.ELEMENT_DATA);

}

  remove(val:any){
    var resta_iva;
    var iva_menos_exento;
    var iva_exento;
    var resul_final2;
   let resta = this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
   this.items.nativeElement.value = parseInt(this.items.nativeElement.value) - 1;
   this.cod_lista1.nativeElement.value = '';

   var monto_nuevo = 0;
  var monto_exc_nuevo = 0;

            for (const i in this.ELEMENT_DATA) {



                if (this.ELEMENT_DATA[i].estatus_fiscal.toUpperCase() === 'XCNT') {
                  monto_exc_nuevo = monto_exc_nuevo + parseFloat(this.ELEMENT_DATA[i].subtotal_producto.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.'));
                }else{
                  monto_nuevo = monto_nuevo + parseFloat(this.ELEMENT_DATA[i].subtotal_producto.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.'));
                }

            }

            let porcentaje = (monto_exc_nuevo  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.'))/100);

            let porcentaje1 = (monto_nuevo  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.'))/100);

            let desc_nuevo = porcentaje1 + porcentaje;

            let xcnt = monto_exc_nuevo - porcentaje;

            let nxcnt = monto_nuevo - porcentaje1;

            let sub_total_nuevo = xcnt + nxcnt;

            let iva_nuevo = nxcnt * parseFloat(this.iva.nativeElement.value) / 100;

            this.exento_monto.nativeElement.value = xcnt.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

            this.subtotal_pedido.nativeElement.value = sub_total_nuevo.toFixed(2).replace(/\D/g, "")
                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                                      this.iva_monto.nativeElement.value = iva_nuevo.toFixed(2).replace(/\D/g, "")
                                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          this.desc_pedido_monto.nativeElement.value = desc_nuevo.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

          let resul_final = sub_total_nuevo + iva_nuevo;

          this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          if (this.desc_pedido.nativeElement.value === "") {
            var cero = '0,00';
                   let porcentaje = (monto_exc_nuevo  * parseFloat(cero)/100);

                   let porcentaje1 = (monto_nuevo  * parseFloat(cero)/100);

                   let desc_nuevo = porcentaje1 + porcentaje;

                   let xcnt = monto_exc_nuevo - porcentaje;

                   let nxcnt = monto_nuevo - porcentaje1;

                   let sub_total_nuevo = xcnt + nxcnt;

                   let iva_nuevo = nxcnt * parseFloat(this.iva.nativeElement.value) / 100;

                   this.exento_monto.nativeElement.value = xcnt.toFixed(2).replace(/\D/g, "")
                   .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                   this.subtotal_pedido.nativeElement.value = sub_total_nuevo.toFixed(2).replace(/\D/g, "")
                                               .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                               .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                                             this.iva_monto.nativeElement.value = iva_nuevo.toFixed(2).replace(/\D/g, "")
                                             .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                             .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                 this.desc_pedido_monto.nativeElement.value = desc_nuevo.toFixed(2).replace(/\D/g, "")
                 .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

                 let resul_final = sub_total_nuevo + iva_nuevo;

                 this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
                 .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          }

    this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
    this.table.renderRows();
  }
  buscarproductot(xvalor:any){
    console.log(xvalor);
    var valor:any = {valor:xvalor}
    this.servicio.buscarPRODUCTOTparamhttp(valor).subscribe(
      response =>{
        this.producto = response;
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  modalrespuesta(){
    if (this.ELEMENT_DATA.length === 0) {
      this.snackBar.open('No haz seleccionado ningun producto', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }else{
      this.accion = "enviarpedido";
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '25%',
        height:'25%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
      dialogRef.afterClosed().subscribe(result => {

        if(result != 'cerrar'){
          switch (this.data.cod_pedido) {
            case '':
              console.log(this.ELEMENT_DATA);
                    let cod_vendedor = localStorage.getItem('cod_usuario');
                    let cod_cliente = this.forma.value.cod_cliente.split(' ');
                    let lote_producto = this.forma.value.lote_producto.split(' ');
                    this.forma.value.cod_vendedor = cod_vendedor;
                    this.forma.value.lote_producto = lote_producto[0];
                    this.forma.value.cod_cliente = cod_cliente[1];
                    this.forma.value.producto_pedido = this.ELEMENT_DATA;
                    this.forma.value.iva_monto = this.iva_monto.nativeElement.value;
                    this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                    this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                    this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                    this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                    this.forma.value.desc_pedido_monto = this.desc_pedido_monto.nativeElement.value;
                    this.forma.value.monto_exento = this.exento_monto.nativeElement.value;
                    console.log(this.forma.value);
                  this.dialogRefp.close(this.forma.value);
              break;

            default:
              console.log(this.ELEMENT_DATA);
                    let cod_vendedor1 = localStorage.getItem('cod_usuario');
                    let cod_cliente1 = this.forma.value.cod_cliente.split(' ');
                    let lote_producto1 = this.forma.value.lote_producto.split(' ');
                    this.forma.value.cod_pedido = this.data.cod_pedido;
                    this.forma.value.cod_vendedor = cod_vendedor1;
                    this.forma.value.lote_producto = lote_producto1[0];
                    this.forma.value.cod_cliente = cod_cliente1[1];
                    this.forma.value.producto_pedido = this.ELEMENT_DATA;
                    this.forma.value.iva_monto = this.iva_monto.nativeElement.value;
                    this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                    this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                    this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                    this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                    this.forma.value.desc_pedido_monto = this.desc_pedido_monto.nativeElement.value;
                    this.forma.value.monto_exento = this.exento_monto.nativeElement.value;
                    console.log(this.forma.value);
                  this.dialogRefp.close(this.forma.value);
              break;
          }

          /* let resultado = this.eliminarunidad(result.id); */
        }
      });
    }

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '25%',
      height:'25%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.dialogRefp.close('cerrar');
      }

    });

  }
  porcentaje(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  var monto_nuevo = 0;
  var monto_exc_nuevo = 0;

            for (const i in this.ELEMENT_DATA) {



                if (this.ELEMENT_DATA[i].estatus_fiscal.toUpperCase() === 'XCNT') {
                  monto_exc_nuevo = monto_exc_nuevo + parseFloat(this.ELEMENT_DATA[i].subtotal_producto.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.'));
                }else{
                  monto_nuevo = monto_nuevo + parseFloat(this.ELEMENT_DATA[i].subtotal_producto.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.'));
                }

            }

            let porcentaje = (monto_exc_nuevo  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.'))/100);

            let porcentaje1 = (monto_nuevo  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.'))/100);

            let desc_nuevo = porcentaje1 + porcentaje;

            let xcnt = monto_exc_nuevo - porcentaje;

            let nxcnt = monto_nuevo - porcentaje1;

            let sub_total_nuevo = xcnt + nxcnt;

            let iva_nuevo = nxcnt * parseFloat(this.iva.nativeElement.value) / 100;

            this.exento_monto.nativeElement.value = xcnt.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

            this.subtotal_pedido.nativeElement.value = sub_total_nuevo.toFixed(2).replace(/\D/g, "")
                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                                      this.iva_monto.nativeElement.value = iva_nuevo.toFixed(2).replace(/\D/g, "")
                                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          this.desc_pedido_monto.nativeElement.value = desc_nuevo.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

          let resul_final = sub_total_nuevo + iva_nuevo;

          this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


   if (this.desc_pedido.nativeElement.value === "") {
     var cero = '0,00';
            let porcentaje = (monto_exc_nuevo  * parseFloat(cero)/100);

            let porcentaje1 = (monto_nuevo  * parseFloat(cero)/100);

            let desc_nuevo = porcentaje1 + porcentaje;

            let xcnt = monto_exc_nuevo - porcentaje;

            let nxcnt = monto_nuevo - porcentaje1;

            let sub_total_nuevo = xcnt + nxcnt;

            let iva_nuevo = nxcnt * parseFloat(this.iva.nativeElement.value) / 100;

            this.exento_monto.nativeElement.value = xcnt.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

            this.subtotal_pedido.nativeElement.value = sub_total_nuevo.toFixed(2).replace(/\D/g, "")
                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                                      this.iva_monto.nativeElement.value = iva_nuevo.toFixed(2).replace(/\D/g, "")
                                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


          this.desc_pedido_monto.nativeElement.value = desc_nuevo.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

          let resul_final = sub_total_nuevo + iva_nuevo;

          this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
   }

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
