<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarclientesp("actualizar")'><mat-icon >sync</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>CONSULTAR PEDIDOS</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table">
        <div class="col-md-12 px-0  border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class=" table-primary text-center table-striped  table-condensed table-hover">

                <ng-container matColumnDef="cod_pedido">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Pedido</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_pedido}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_lista">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Precio</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_lista_precio}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Cod cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="des_vendedor">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Vendedor</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_vendedor}} </td>

                </ng-container>

                <ng-container matColumnDef="tipo_pedido">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_pedido}} </td>

                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.fecha_inicio}} </td>

                </ng-container>

                <ng-container matColumnDef="estatus_pedido">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus_pedido}} </td>

                </ng-container>

                <ng-container matColumnDef="mod_factur">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>UM</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.mod_factur}} </td>

                </ng-container>

                <ng-container matColumnDef="fecha_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.fecha_fin}} </td>

                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="td_style">
                        <button class="rounded-pill"  matTooltip="DESCARGAR PRESUPUESTO" mat-raised-button (click)="imprimirPedido(element,'imprimir')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 86, 247);">
                              <mat-icon >get_app</mat-icon>
                        </button>
                       
                    </td>

                </ng-container>

                <ng-container  matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Procesos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="col-md-2">
                        <button class=" bg-dark mr-1" mat-mini-fab (click)='imprimirPedido(element)' matTooltip="DESCARGAR">
                            <mat-icon >get_app</mat-icon>
                        </button>
                        <button class=" bg-primary mr-1" mat-mini-fab mat-raised-button (click)="update_insert_data(element)" matTooltip="EDITAR">
                            <mat-icon >edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="td_style"></tr>

            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>