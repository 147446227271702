import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-materia-prima',
  templateUrl: './form-materia-prima.component.html',
  styleUrls: ['./form-materia-prima.component.css']
})
export class FormMateriaPrimaComponent implements OnInit {
  @ViewChild('cod_prov') cod_prov: ElementRef;

archivo:File;
rlsr:any ;
estatus:any;
stateGroups: StateGroup[];
proveedor:any[];
arry: Observable<StateGroup[]>;
nombre:any;
medida:any;
definicion_1:any;
definicion_2:any;
forma:FormGroup;
dialogSpin:any;
accion:any;
accion2:any;
unidad:any;
ELEMENT_DATA:any[]=[];
displayedColumns:string[];
dataSource1=new MatTableDataSource<any>();
objectURL:any;
constructor(
  private dialog: MatDialog, 
  private fb:FormBuilder,
  private servicio:nkServices, 
  private hashkey:HashKeyServices,
  private util:AppUtilService,
  private atrb:AtributosService,
  private notifi:ToastNotificationService,
  private dialogRefp: MatDialogRef<FormMateriaPrimaComponent>,
  ///en el injectable llega la informacion del metodo padre en data
  @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data, 'primero');
    this.rlsr =  hashkey.decrypt(localStorage.getItem('rlsr'));
    this.objectURL = this.atrb.IMAGENES_FIJAS.sin_img_prod;
    this.accion = data.accion;
    this.medida = data.tipo_prod;
    this.definicion_1 = data.definicion_1;
    this.definicion_2 = data.definicion_2;
    if (data.accion.toUpperCase() === this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {

      this.forma = fb.group({
        'cod_producto':[data.cod_producto],
        'des_producto':[data.des_producto,[Validators.required,Validators.maxLength(45)]],
        'estatus_producto':[data.estatus_producto,[Validators.required]],
        'tamano_producto':[data.tamano_producto,[Validators.required]],
        'unidad_medida_producto':[data.unidad_medida_producto,[Validators.required]],
        'tipo_producto':[data.tipo_producto,[Validators.required]],
        'cod_img':[''],
        'img_actual':[data.cod_img],
        'archivo':[''],
        'formdata':[''],
        'des_usuario': '',
        'cod_usuario': '',
        'cod_empresa':''
      });
    }
    if (data.accion.toUpperCase() === this.atrb.ACCION_CREAR.toUpperCase()) {
      this.forma = fb.group({
        'cod_producto':[data.cod_producto],
        'des_producto':[data.des_producto,[Validators.required,Validators.maxLength(45)]],
        'estatus_producto':[data.estatus_producto,[Validators.required]],
        'tamano_producto':[data.tamano_producto,[Validators.required]],
        'unidad_medida_producto':[data.unidad_medida_producto,[Validators.required]],
        'tipo_producto':[data.tipo_producto,[Validators.required]],
        'cod_img':[''],
        'archivo':['',[Validators.required]],
        'formdata':[''],
        'des_usuario': '',
        'cod_usuario': '',
        'cod_empresa':''
      });
    }
  }

 async ngOnInit(){
  this.estatus = await this.atrb.ESTATUS;
  if (this.accion.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
    /* this.tipo_producto_update = document.getElementById("tipo_prod") as HTMLInputElement;
    for (const i in this.tipo_producto) {
      if (this.tipo_producto[i].concepto_definicion.toLowerCase() === this.data.tipo_producto.toLowerCase()) {
        this.tipo_producto_update.value = this.tipo_producto[i].descrip_definicion;
        
      }
    } */
    this.objectURL = this.data.cod_img;
  }
    /* this.listarprov(); */
  }
  ngOnDestroy() {
    URL.revokeObjectURL(this.objectURL);
  }
  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
  async cargar_img(xvalor:any){
    try {
      await this.util.abrirSpinner();
      this.objectURL = URL.createObjectURL(xvalor.target.files[0]);
     this.archivo = xvalor.target.files[0];
     
    await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }

  /* listarprov(){
    this.proveedor = [];

    this.servicio.listarProvhttp().subscribe(
      response =>{
        if (this.accion === 'insertar') {

          this.arry = this.forma.get('cod_prov')!.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterGroup(value))
          );
                for (const i in response) {
                this.proveedor.push(response[i].cod_prov+' '+'( '+response[i].des_prov+' )');
                }

                this.stateGroups= [{
                letter: 'Proveedores',
                names: this.proveedor,
                code: this.proveedor
                }];

                console.log(this.stateGroups);
        }

        if (this.accion === 'actualizar') {
          this.arry = this.forma.get('des_prov_act')!.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterGroup(value))
          );
                for (const i in response) {
                this.proveedor.push(response[i].cod_prov+' '+'( '+response[i].des_prov+' )');
                }

                this.stateGroups= [{
                letter: 'Proveedores',
                names: this.proveedor,
                code: this.proveedor
                }];

                console.log(this.stateGroups);
        }

      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  } */

  async  modalrespuesta(valor:any){
    try {
      let cant_peso = document.querySelector(`#${valor}`) as HTMLInputElement;
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      if (parseFloat(await this.util.remplazo_coma_punto(cant_peso.value)) <= 0) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.peso_mayor_0);
        return;
      }
      await this.util.cerrarSpinner();
      var accion2 = '';
      var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:this.data.id_cliente,
              accion:accion2},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRefp.afterClosed().subscribe(async result => {
        if(result != 'cerrar'){
          await this.util.cerrarSpinner();
          if (parseInt(this.rlsr) > 1) {
            this.forma.patchValue({
              mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario)
            });
        }
          const formData = new FormData();
          formData.append('prod_img',this.archivo);
          this.forma.patchValue({
            cod_usuario:await this.atrb.COD_USUARIO.cod_usuario,
            des_usuario: await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
            cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
            tamano_producto:cant_peso.value,
            formdata:formData
          });
         this.dialogRefp.close(this.forma.value);
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
    }

async cerrarmodal(){
  try {
    let accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result != 'cerrar') {
        await this.util.cerrarSpinner();
        this.dialogRefp.close('cerrar');
      }else{
        await this.util.cerrarSpinner();
      }
    });
  } catch (error) {
    console.log(error);
  }
  }

async format(event: any, valor:any, termino:any)
    {
      try {
        var num;
        if (termino.toUpperCase() !== this.atrb.BASE.toUpperCase() &&
            termino.toUpperCase() !== this.atrb.TASA.toUpperCase() &&
            termino.toUpperCase() !== this.atrb.DESCUENTO.toUpperCase()
            ) {
          let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
          if(inputId.value.length == 1){
            let noa = inputId.value.split('')
            noa.unshift('0')
            noa.unshift('0')
            inputId.value = noa.join('')
          }else if(inputId.value.length == 3){
            let noa = inputId.value.split('')
            noa.unshift('0')
            inputId.value = noa.join('')
          }else if(inputId.value.length >= 4 ){
            let noa:any = inputId.value.split('')
            for(let i = 0 ; i < 2; i++){
              if(noa[0] == 0){
                noa.shift()
              }else{
                break;
              }
            }
            inputId.value = noa.join('')
          }
          let counter = 0
          let comaExiste = false
          let num = inputId.value.replace(/\D/g,'');
          const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
            for(let i = array.length - 1; i > -1 ; i--){
              if(array[i] === ','){
                comaExiste = true
              }
              if(comaExiste){
                if(counter === 3){
                  if(array[i-1]){
                    array.splice(i,0,'.')
                    counter = 1
                  }
                }else{
                  counter++
                }
              }
            }
            inputId.value = array.join('');
        }
        if (termino.toUpperCase() === this.atrb.BASE.toUpperCase()) {
          let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
          num = inputId.value.replace(/\D/g,'');
          if(!isNaN(parseFloat(num))){
            num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
            num = num.split('').reverse().join('').replace(/\D/g, "")
                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
            this.forma.patchValue({cant_producto:num});
            inputId.value = num;
            }else{ 
              inputId.value = inputId.value.replace(/[^\d\.]*/g,'');
            }
        }
        if (termino.toUpperCase() === this.atrb.TASA.toUpperCase()) {
          let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
          if(inputId.value.length == 1){
            let noa = inputId.value.split('')
            noa.unshift('0')
            noa.unshift('0')
            inputId.value = noa.join('')
          }else if(inputId.value.length == 3){
            let noa = inputId.value.split('')
            noa.unshift('0')
            inputId.value = noa.join('')
          }else if(inputId.value.length >= 4 ){
            let noa:any = inputId.value.split('')
            for(let i = 0 ; i < 2; i++){
              if(noa[0] == 0){
                noa.shift()
              }else{
                break;
              }
            }
            inputId.value = noa.join('');
          }
          let counter = 0
          let comaExiste = false
          let num = inputId.value.replace(/\D/g,'');
          const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
            for(let i = array.length - 1; i > -1 ; i--){
              if(array[i] === ','){
                comaExiste = true
              }
              if(comaExiste){
                if(counter === 3){
                  if(array[i-1]){
                    array.splice(i,0,'.')
                    counter = 1
                  }
                }else{
                  counter++
                }
              }
            }
            inputId.value = array.join('');
        }
      } catch (error) {
        console.log(error);
      }
    }

}
