import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { forIn, parseInt } from 'lodash';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';


export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-traslados-dep',
  templateUrl: './form-traslados-dep.component.html',
  styleUrls: ['./form-traslados-dep.component.css']
})
export class FormTrasladosDepComponent implements OnInit {
  @ViewChild('table') table:MatTable<any>;
  @ViewChild('unidad_m',{static:true}) unidad_m:ElementRef;
  @ViewChild('manejo_salida',{static:true}) manejo_salida:ElementRef;
  @ViewChild('manejo_entrada',{static:true}) manejo_entrada:ElementRef;
  @ViewChild('producto_pedido',{static:true}) producto_pedido:ElementRef;
  @ViewChild('cant_lote',{static:true}) cant_lote:ElementRef;
  @ViewChild('total_lote',{static:true}) total_lote:ElementRef;
  @ViewChild('cant_um',{static:true}) cant_um:ElementRef;
  @ViewChild('cod_lote',{static:true}) cod_lote:ElementRef;
  @ViewChild('cantidad',{static:true}) cantidad:ElementRef;

  @ViewChild('cant_salida',{static:true}) cant_salida:ElementRef;
  @ViewChild('cant_entrada',{static:true}) cant_entrada:ElementRef;

  @ViewChild('comentarios',{static:true}) comentarios:ElementRef;

  @ViewChild('inv_salida',{static:true}) inv_salida:ElementRef;
  @ViewChild('inv_entrada',{static:true}) inv_entrada:ElementRef;

  @ViewChild('num_lotes',{static:true}) num_lotes:ElementRef;
  @ViewChild('total_prod_lotes',{static:true}) total_prod_lotes:ElementRef;

  @ViewChild('total_blts',{static:true}) total_blts:ElementRef;

  @ViewChild('pr_um',{static:true}) pr_um:ElementRef;
  @ViewChild('total_prod_inv',{static:true}) total_prod_inv:ElementRef;

  @ViewChild('total_blt',{static:true}) total_blt:ElementRef;

  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
stateGroupOptions: Observable<StateGroup[]>;
stateGroupOptions2: Observable<StateGroup[]>;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
forma:FormGroup;
accion:any;
unidad_manejo:any;
llave_1:any;
llave_2:any;
dialogSpin:any;
unidad:any;
listas:any = [];
tipo_cliente = [];
bandera_:any = true;
bandera:any = false;
bandera2:any = true;
alerta:any;
producto:any = [];
codigo:any = [];
producto2:any = [];
codigo2:any = [];
deposito_pt = [];
almacen:any = [];
productos:any = [];
tipo_um_und = [];
asociar:any = false;
actualizar:any = false;
displayedColumns:string[];
ELEMENT_DATA:any[]=[];
total_valor:any;
total_actual:any;
total_restante:any;
pi:any;
pl:any;
bp:any;
ba:any;
br:any;
total_items:any;
dataSource=new MatTableDataSource<any>();
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices,
    private snackBar: MatSnackBar, 
    private dialog: MatDialog, 
    private fb:FormBuilder,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private dialogRef: MatDialogRef<FormTrasladosDepComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

    for (const i in this.data.productos) {
     
        this.productos.push(this.data.productos[i]);
  }
    for (const i in this.data.cod_dep) {
      if (this.data.cod_dep[i].tipo_deposito === 'PT') {
        this.almacen.push(this.data.cod_dep[i]);
      } 
  }
      this.forma = fb.group({
        'inv_salida':['',[Validators.required]],
        'manejo_salida':'',
        'inv_entrada':['',[Validators.required]],
        'manejo_entrada':'',
        'cant_producto':'0',
        'cant_um':'0',
        'solicitud':'',
        'cod_producto':['',[Validators.required]],
        'cod_lote':['',[Validators.required]],
        'cant_lote':'0',
        'total_lote':'0',
        'des_producto':'',
        'total_prod_lotes':'0',
        'num_lotes':'0',
        'unidad_manejo':'0',
        'comentario':['',[Validators.required]],
        'cant_salida':'0',
        'cant_entrada':'0',
        'cod_usuario':localStorage.getItem('cod_usuario'),
        'des_usuario':localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido'),
        'lote_grupo':'',
        'total_blts':'0',
        'pr_um':'0',
        'total_prod_inv':'0',
        'total_blt':'0'
      });

      this.cantidad_item();
      this.cantidad_productos();
      this.cantidad_actual();
      this.cantidad_restante();
      this.bp_();
      this.ba_();
      this.br_();

    }
  ngOnInit(): void {
    this.formulario();
  }

  cargar_producto(){
    try {
      this.producto = [];
      this.codigo = [];
      this.stateGroupOptions = this.forma.get('cod_producto')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterGroup(value))
      );
      for (const i in this.data.productos) {
      this.producto.push(this.data.productos[i].cod_producto+'-'+this.data.productos[i].des_producto);
      this.codigo.push(this.data.productos[i].cod_producto+'-'+this.data.productos[i].des_producto);
      }
      console.log(this.producto);
      this.stateGroups= [{
      letter: 'Productos',
      names: this.producto,
      code: this.codigo
      }];
    } catch (error) {
        console.log(error);
    }
  }
  
  formulario():void{
    /* this.ELEMENT_DATA = []; */

    this.displayedColumns = [
      'lote',
      'solicitud',
      'actual',
      'restante',
      'solicitud_bt',
      'actual_bt',
      'restante_bt',
      'remov_elem'
     ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);

  this.servicio.listarGuiaDespachohttp().subscribe(
    response =>{
      this.snackBar.open('Existen guias de almacen pendientes por procesar!!!!!!','Cerrar',{duration:50000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    },
    error => {
      
    }
  );
  }
  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups2
        .map(group2 => ({letter: group2.letter, names: filter(group2.names, value), code: filter(group2.code, value)}))
        .filter(group2 => group2.names.length > 0 || group2.code.length > 0 );
    }
    return this.stateGroups2;
  }
  cantidad_item() {
    try {
      this.total_items = this.ELEMENT_DATA.length;
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  cantidad_productos() {
        try {
          var suma = 0;
          var matriz = [];
          for (const i in this.ELEMENT_DATA) {
            matriz.push(this.ELEMENT_DATA[i].solicitud_lote)
          }
          matriz.forEach (function(numero){
            if (isNaN(numero)) {
              suma = 0;
            }else{
              suma += parseInt(numero.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) ;
            }
             
         });
            this.total_valor = suma;
          return suma;
        } catch (error) {
          this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    }

    cantidad_actual() {
      try {
        var suma = 0;
        var matriz = [];
        for (const i in this.ELEMENT_DATA) {
          matriz.push(this.ELEMENT_DATA[i].actual_lote)
        }
        matriz.forEach (function(numero){
          if (isNaN(numero)) {
            suma = 0;
          }else{
            suma += parseInt(numero.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.')) ;
          }
           
       });
           this.total_actual = suma;
         return suma;
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      }

      cantidad_restante() {
        try {
          var suma = 0;
          var matriz = [];
          for (const i in this.ELEMENT_DATA) {
            matriz.push(this.ELEMENT_DATA[i].restante_lote)
          }
          matriz.forEach (function(numero){
            if (isNaN(numero)) {
              suma = 0;
            }else{
              suma += parseInt(numero.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) ;
            }
             
         });
             this.total_restante = suma;
           return suma;
        } catch (error) {
          console.log(error);
          this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        
        }

        bp_() {
          try {
            var suma = 0;
            var matriz = [];
            for (const i in this.ELEMENT_DATA) {
              matriz.push(this.ELEMENT_DATA[i].solicitud_bt)
            }
            matriz.forEach (function(numero){
              if (isNaN(numero)) {
                suma = 0;
              }else{
                suma += parseInt(numero.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) ;
              }
               
           });
               this.bp = suma;
             return suma;
          } catch (error) {
            console.log(error);
            this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
          
          }

          ba_() {
            try {
              var suma = 0;
              var matriz = [];
              for (const i in this.ELEMENT_DATA) {
                matriz.push(this.ELEMENT_DATA[i].actual_bt)
              }
              matriz.forEach (function(numero){
                    if (isNaN(numero)) {
                      suma = 0;
                    }else{
                      suma += parseInt(numero.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) ;
                    }
                     
                 });
                 this.ba = suma;
               return suma;
            } catch (error) {
              console.log(error);
              this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
            
            }

            br_() {
              try {
                var suma = 0;
                var matriz = [];
                for (const i in this.ELEMENT_DATA) {
                  matriz.push(this.ELEMENT_DATA[i].restante_bt)
                }
                
                matriz.forEach (function(numero){
                  if (isNaN(numero)) {
                    suma = 0;
                  }else{
                    suma += parseInt(numero.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.')) ;
                  }
                   
               });
                   this.br = suma;
                 return suma;
              } catch (error) {
                console.log(error);
                this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
              
              }

  modalrespuesta(){
    try {

      this.servicio.listarGuiaDespachohttp().subscribe(
        response =>{
          this.snackBar.open('Existen guias de almacen pendientes por procesar','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        },
        error => {
          if (this.ELEMENT_DATA.length > 0 && this.producto_pedido.nativeElement.value !== '' && this.comentarios.nativeElement.value !== '') {
            if (this.forma.value.inv_salida.toUpperCase() !== this.forma.value.inv_entrada.toUpperCase()) {
              
             if (parseInt(this.cantidad.nativeElement.value) <= parseInt(this.cant_salida.nativeElement.value)) {
             
               if (parseFloat(this.total_blt.nativeElement.value.replace('.','')
                               .replace('.','')
                               .replace('.','')
                               .replace('.','')
                               .replace('.','')
                               .replace(',','.')) - Math.floor(parseFloat(this.total_blt.nativeElement.value.replace('.','')
                               .replace('.','')
                               .replace('.','')
                               .replace('.','')
                               .replace('.','')
                               .replace(',','.')) ) === 0) {
   
                                 var accion2 = 'enviarcliente';
                                 var dialogRefp = this.dialog.open(FormmMsgComponent, {
                                   width: '100vw',
                                       maxWidth: '100vw',
                                       height:'100vh',
                                   data: {id:this.data.id_cliente,
                                         accion:accion2},
                                   disableClose: true,
                                   panelClass: 'pedidos_modal',
                                   backdropClass: "bdrop"
                                 });
                                   dialogRefp.afterClosed().subscribe(result => {
                                     if(result != 'cerrar'){
                                       var codigo = this.producto_pedido.nativeElement.value.split('-');
                     
                                       this.forma.value.cant_entrada = this.cant_entrada.nativeElement.value;
                                       this.forma.value.cant_salida = this.cant_salida.nativeElement.value;
                                       this.forma.value.cant_producto = this.cantidad.nativeElement.value;
                     
                                       this.forma.value.manejo_salida = this.manejo_salida.nativeElement.value;
                                       this.forma.value.manejo_entrada = this.manejo_entrada.nativeElement.value;
                     
                                       this.forma.value.tipo_operacion = 'traslado';
                                       this.forma.value.tipo_operacion = 'traslado';
                     
                                       this.forma.value.unidad_manejo = this.unidad_m.nativeElement.value;
                                       this.forma.value.total_blts = this.total_blts.nativeElement.value;
                                       this.forma.value.cod_producto = codigo[0];
                                       this.forma.value.des_producto = codigo[1];
                                       this.forma.value.lote_grupo = this.ELEMENT_DATA
                     
                                       this.forma.value.pr_um = this.pr_um.nativeElement.value;
                                       this.forma.value.total_blt = this.total_blt.nativeElement.value;
                     
                                       console.log(this.forma.value,'this.forma.value');
                                       this.procesar_traslado(this.forma.value);
                                     }
                                   });
                 
               }else{
                 this.snackBar.open('Se debe calcular lotes completos para continuar el proceso', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
               }
               
              }else{
               this.snackBar.open('La cantidad solicitada no puede ser mayor a la cantidad del inventario de salida', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
              }
            }else{
             this.snackBar.open('No es posible usar el mismo inventario en la salida como en la entrada para el proceso de traslado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
            }
           
         }else{
             if (this.ELEMENT_DATA.length <= 0) {
               this.snackBar.open('Debes selecionar por lo menos un lote', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
             }
   
             if (this.producto_pedido.nativeElement.value !== '' || this.comentarios.nativeElement.value !== '') {
               this.snackBar.open('Hay campos vacios', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
             }
   
           this.snackBar.open('No debe dejar campos vacios, ni montos en 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,});
         }
        }
      );
      
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
        }

        procesar_traslado(xvalor:any):void{
          try {
            this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
              width: '15%',
              height:'25%',
              data: {
                      accion:this.accion},
              disableClose: true,
              panelClass: 'spinner',
              backdropClass: "bdrop"
            });
            setTimeout(() => {
              this.dialogSpin.close();
              xvalor.val_def = 'ALMACEN-ENV',
              xvalor.tipo_operacion_env ='TRASLADOSDEP',
              this.servicio.procesarTrasladoshttp(xvalor).subscribe(
                response =>{
                  this.dialogSpin.close();
                    console.log(response,'valores');
                    this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                      this.dialogRef.close();
                },
                error =>{
                  console.log(error);
                  this.dialogSpin.close();
                  this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
                }
              );
             
            }, 500);
          } catch (error) {
            console.log(error);
            this.dialogSpin.close();
            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }

        buscarLotes(xvalor:any):void{
          try {
            this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
              width: '15%',
              height:'25%',
              data: {
                      accion:this.accion},
              disableClose: true,
              panelClass: 'spinner',
              backdropClass: "bdrop"
            });
            setTimeout(() => {
              this.dialogSpin.close();
               var producto = this.producto_pedido.nativeElement.value.split('-');
              var lotes = {
                                cod_lote:xvalor,
                                manejo_salida: this.manejo_salida.nativeElement.value}
              this.servicio.listarValorLoteshttp(lotes).subscribe(
                response =>{
                  this.alerta = '';
                  this.bandera2 = true;
                  this.dialogSpin.close();
                    console.log(response,'valores');
                    this.cant_lote.nativeElement.value = '0';
                      this.cant_um.nativeElement.value = '0';
                    this.total_lote.nativeElement.value = response.lote[0].cant_producto.replace('.', '')
                                                                                          .replace('.', '')
                                                                                          .replace('.', '')
                                                                                          .replace(',', '.');
                      
                                                                                          
                      this.total_blts.nativeElement.value = (parseInt(response.lote[0].cant_producto.replace('.', '')
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                                                            .replace('.', '')
                                                            .replace('.', '')
                                                            .replace(',', '.'))).toFixed(2).replace(/\D/g, "")
                                                                                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");;
                },
                error =>{
                  this.dialogSpin.close();
                  this.cant_lote.nativeElement.value = '0';
                      this.cant_um.nativeElement.value = '0';
                      this.total_blts.nativeElement.value = '0';
                      this.total_lote.nativeElement.value = '0';
                  console.log(<any>error.error.message,'error definiciones');
                  this.alerta = <any>error.error.message;
                  this.bandera2 = false;
                  /* this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,}); */
                }
              );

            }, 500);
          } catch (error) {
            this.dialogSpin.close();
            console.log(error);
            this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }

        buscarDataUnidadManejo(xvalor:any):void{
          try {
            this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
              width: '15%',
              height:'25%',
              data: {
                      accion:this.accion},
              disableClose: true,
              panelClass: 'spinner',
              backdropClass: "bdrop"
            });
            setTimeout(() => {
              this.dialogSpin.close();
               var producto = this.producto_pedido.nativeElement.value.split('-');
              var depositos = {cod_deposito_1:this.forma.value.inv_salida, 
                                cod_deposito_2:this.forma.value.inv_entrada,
                                cod_producto:producto[0],
                                manejo_salida: this.manejo_salida.nativeElement.value,
                                manejo_entrada: this.manejo_entrada.nativeElement.value,
                                pr_um: this.pr_um.nativeElement.value}
              this.servicio.listarValorProductohttp(depositos).subscribe(
                response =>{
                  this.alerta = '';
                  this.bandera2 = true;
                  this.dialogSpin.close();
                    console.log(response,'valores');
                    this.cant_salida.nativeElement.value = response.dep_salida.replace('.', '')
                    .replace('.', '')
                    .replace('.', '')
                    .replace(',', '.');
                    this.cant_entrada.nativeElement.value = response.dep_entrada.replace('.', '')
                    .replace('.', '')
                    .replace('.', '')
                    .replace(',', '.');

                    this.cantidad.nativeElement.value = '0';
                    this.total_lote.nativeElement.value = '0';
                    this.total_blts.nativeElement.value = '0';
                    this.total_blt.nativeElement.value = '0';

                    this.total_prod_lotes.nativeElement.value = '0';
                    this.total_prod_inv.nativeElement.value = '0';
                    
                    this.cant_um.nativeElement.value = '0';
                    this.cant_lote.nativeElement.value = '';
                    this.cod_lote.nativeElement.value = '';
                    this.ELEMENT_DATA  = [];
                    this.dataSource.data = [];
                    this.total_valor = '0';
                    this.total_items = '0';
                    this.table.renderRows();

                    this.num_lotes.nativeElement.value = response.lotes.length;
                  var matriz_ = [];
                  var suma_ = 0;
                    for (const i in response.lotes) {
                      matriz_.push(response.lotes[i].cant_producto)
                    }
                    matriz_.forEach (function(numero){
                      suma_ += parseInt(numero.replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace(',','.')) ;
                       });
                       this.total_prod_lotes.nativeElement.value = suma_;
                       this.total_prod_inv.nativeElement.value = response.inv;
                       this.pi = response.inv;
                       this.pl = suma_;

                    this.producto2 = [];
                    this.codigo2 = [];

                    this.stateGroupOptions2 = this.forma.get('cod_lote')!.valueChanges
                          .pipe(
                            startWith(''),
                            map(value => this._filterGroup2(value))
                          );
                          for (const i in response.lotes) {
                          this.producto2.push(response.lotes[i].cod_lote);
                          this.codigo2.push(response.lotes[i].cod_lote);
                          }
                          this.stateGroups2= [{
                          letter: 'Productos',
                          names: this.producto2,
                          code: this.codigo2
                          }];
                },
                error =>{
                  this.dialogSpin.close();
                  console.log(<any>error.error,'error definiciones');
                  this.alerta = <any>error.error.message;
                  this.bandera2 = false;
                  this.cant_salida.nativeElement.value = <any>error.error.dep_salida.replace('.', '')
                    .replace('.', '')
                    .replace('.', '')
                    .replace(',', '.');
                    this.cant_entrada.nativeElement.value = <any>error.error.dep_entrada.replace('.', '')
                    .replace('.', '')
                    .replace('.', '')
                    .replace(',', '.');

                    this.cantidad.nativeElement.value = '0';
                    this.total_lote.nativeElement.value = '0';
                    this.total_blts.nativeElement.value = '0';
                    this.total_blt.nativeElement.value = '0';

                    this.total_prod_lotes.nativeElement.value = '0';
                    this.total_prod_inv.nativeElement.value = '0';
                    
                    this.cant_um.nativeElement.value = '0';
                    this.cant_lote.nativeElement.value = '';
                    this.cod_lote.nativeElement.value = '';
                    this.ELEMENT_DATA  = [];
                    this.dataSource.data = [];
                    this.total_valor = '0';
                    this.total_items = '0';
                    this.table.renderRows();

                    this.num_lotes.nativeElement.value = <any>error.error.lotes.length;
                  var matriz_ = [];
                  var suma_ = 0;
                    for (const i in <any>error.error.lotes) {
                      matriz_.push(<any>error.error.lotes[i].cant_producto)
                    }
                    matriz_.forEach (function(numero){
                      suma_ += parseInt(numero.replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace(',','.')) ;
                       });
                       this.total_prod_lotes.nativeElement.value = suma_;
                       this.total_prod_inv.nativeElement.value = <any>error.error.inv;
                       this.pi = <any>error.error.inv;
                       this.pl = suma_;

                    this.producto2 = [];
                    this.codigo2 = [];

                    this.stateGroupOptions2 = this.forma.get('cod_lote')!.valueChanges
                          .pipe(
                            startWith(''),
                            map(value => this._filterGroup2(value))
                          );
                          for (const i in <any>error.error.lotes) {
                          this.producto2.push(<any>error.error.lotes[i].cod_lote);
                          this.codigo2.push(<any>error.error.lotes[i].cod_lote);
                          }
                          this.stateGroups2= [{
                          letter: 'Productos',
                          names: this.producto2,
                          code: this.codigo2
                          }];
                  /* this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,}); */
                }
              );

              if (this.manejo_salida.nativeElement.value !== '' && this.manejo_entrada.nativeElement.value !== '') {
                this.cant_lote.nativeElement.value = '';
                this.cantidad.nativeElement.value = '0';
                var objeto = xvalor.split('-');
                console.log(objeto[0],'ver producto');
                if (this.manejo_salida.nativeElement.value === 'UM'  &&   this.manejo_entrada.nativeElement.value === 'UM'  || 
                    this.manejo_salida.nativeElement.value === 'UM'  &&   this.manejo_entrada.nativeElement.value === 'UNDP' ||
                    this.manejo_salida.nativeElement.value === 'UNDP'  &&   this.manejo_entrada.nativeElement.value === 'UM' ||
                    this.manejo_salida.nativeElement.value === 'UM'  &&   this.manejo_entrada.nativeElement.value === 'UND'  ||
                    this.manejo_salida.nativeElement.value === 'UND'  &&   this.manejo_entrada.nativeElement.value === 'UM' ) {
                  for (const i in this.productos) {
                    if (objeto[0] === this.productos[i].cod_producto) {
                      this.unidad_m.nativeElement.value = this.productos[i].unidad_manejo;
                      break;
                    }
                  }
                }

                if (this.manejo_salida.nativeElement.value === 'UNDP' && this.manejo_entrada.nativeElement.value === 'UNDP'  ||
                  this.manejo_salida.nativeElement.value === 'UND' && this.manejo_entrada.nativeElement.value === 'UND'      ||
                  this.manejo_salida.nativeElement.value === 'UNDP' && this.manejo_entrada.nativeElement.value === 'UND'   ||
                  this.manejo_salida.nativeElement.value === 'UND' && this.manejo_entrada.nativeElement.value === 'UNDP'  ) {
                  for (const i in this.productos) {
                    
                      this.unidad_m.nativeElement.value = '1';
                  }
                }

                
                
                if (this.unidad_m.nativeElement.value === '' || this.unidad_m.nativeElement.value === undefined || this.unidad_m.nativeElement.value === null) {
                  this.bandera_ = false;
                  this.bandera_= false;
                }else{
                  console.log('entro en el false');
                  this.bandera_ = true;
                  this.bandera = true;
                }
              }else{
                this.cantidad.nativeElement.value = '0';
                this.cant_lote.nativeElement.value = '';
                this.producto_pedido.nativeElement.value = '';
                this.unidad_m.nativeElement.value = '';
                this.cod_lote.nativeElement.value = '';
                this.snackBar.open('Debe seleccionar los inventarios a gestionar', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
            }, 500);
          } catch (error) {
            this.dialogSpin.close();
            console.log(error);
            this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }

        buscarUnidadManejoInv(xvalor:any, termino:any):void{
          try {
            this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
              width: '15%',
              height:'25%',
              data: {
                      accion:this.accion},
              disableClose: true,
              panelClass: 'spinner',
              backdropClass: "bdrop"
            });
            setTimeout(() => {
              this.dialogSpin.close();
          
                var objeto = xvalor.value.split('-');

                    this.ELEMENT_DATA  = [];
                    this.dataSource.data = [];
                    this.total_valor = '0';
                    this.total_items = '0';
                    this.table.renderRows();

                    this.cant_salida.nativeElement.value = '0';
                    this.cant_entrada.nativeElement.value = '0';
                    
                    this.cod_lote.nativeElement.value = '';
                    this.forma.value.cod_lote = '';

                    this.stateGroupOptions2 = this.forma.get('cod_lote')!.valueChanges
                          .pipe(
                            startWith(''),
                            map(value => this._filterGroup2(value))
                          );
                          
                          this.producto2 = [];
                          this.codigo2 = [];
                          
                          this.stateGroups2= [{
                          letter: 'Productos',
                          names: this.producto2,
                          code: this.codigo2
                          }];
              
                if (termino.toUpperCase() === 'SALIDA') {
                  this.cargar_producto();
                  for (const i in this.almacen) {
                    if (objeto[0] === this.almacen[i].cod_deposito) {
                      this.manejo_salida.nativeElement.value = this.almacen[i].tipo_manejo;
                      this.unidad_m.nativeElement.value = '0';
                      this.producto_pedido.nativeElement.value = '';
                      this.cantidad.nativeElement.value = '0';
                      this.total_lote.nativeElement.value = '0';
                      this.total_blts.nativeElement.value = '0';
                      this.cant_um.nativeElement.value = '0';
                      this.total_blt.nativeElement.value = '0';
                      this.total_prod_lotes.nativeElement.value = '0';
                      this.total_prod_inv.nativeElement.value = '0';
                      this.num_lotes.nativeElement.value = '0';
                      this.cant_lote.nativeElement.value = '0';
                      this.llave_1 = true;

                      this.cantidad_item();
                    this.cantidad_productos();
                    this.cantidad_actual();
                    this.cantidad_restante();
                    this.bp_();
                    this.ba_();
                    this.br_();
                      break;
                    }
                  }
                }
                if (termino.toUpperCase() === 'ENTRADA') {
                  this.cargar_producto();
                  for (const i in this.almacen) {
                    if (objeto[0] === this.almacen[i].cod_deposito) {
                      this.manejo_entrada.nativeElement.value = this.almacen[i].tipo_manejo;
                      this.unidad_m.nativeElement.value = '0';
                      this.producto_pedido.nativeElement.value = '';
                      this.cantidad.nativeElement.value = '0';
                      this.total_lote.nativeElement.value = '0';
                      this.total_blts.nativeElement.value = '0';
                      this.total_prod_lotes.nativeElement.value = '0';
                      this.total_prod_inv.nativeElement.value = '0';
                      this.num_lotes.nativeElement.value = '0';
                      this.cant_um.nativeElement.value = '0';
                      this.total_blt.nativeElement.value = '0';
                      this.cant_lote.nativeElement.value = '0';
                      this.llave_2 = true;

                      this.cantidad_item();
                    this.cantidad_productos();
                    this.cantidad_actual();
                    this.cantidad_restante();
                    this.bp_();
                    this.ba_();
                    this.br_();
                      break;
                    }
                  }
                }

                if (this.manejo_salida.nativeElement.value === 'UM' && this.manejo_entrada.nativeElement.value === 'UNDP' ) {
                  this.pr_um.nativeElement.value = 1;
                  }
                  if (this.manejo_salida.nativeElement.value === 'UM' && this.manejo_entrada.nativeElement.value === 'UND' ) {
                              this.pr_um.nativeElement.value = 2;
                  }
                  if (this.manejo_salida.nativeElement.value === 'UNDP' && this.manejo_entrada.nativeElement.value === 'UM' ) {
                              this.pr_um.nativeElement.value = 3;
                  }
                  if (this.manejo_salida.nativeElement.value === 'UNDP' && this.manejo_entrada.nativeElement.value === 'UND' ) {
                              this.pr_um.nativeElement.value = 4;
                  }
                  if (this.manejo_salida.nativeElement.value === 'UND' && this.manejo_entrada.nativeElement.value === 'UND' ) {
                              this.pr_um.nativeElement.value = 5;
                  }
                  if (this.manejo_salida.nativeElement.value === 'UND' && this.manejo_entrada.nativeElement.value === 'UNDP' ) {
                              this.pr_um.nativeElement.value = 6;
                  }
                  if (this.manejo_salida.nativeElement.value === 'UND' && this.manejo_entrada.nativeElement.value === 'UM' ) {
                              this.pr_um.nativeElement.value = 7;
                  }
                  if (this.manejo_salida.nativeElement.value === 'UNDP' && this.manejo_entrada.nativeElement.value === 'UNDP' ) {
                    this.pr_um.nativeElement.value = 0;
                  }
                  if (this.manejo_salida.nativeElement.value === 'UM' && this.manejo_entrada.nativeElement.value === 'UM' ) {
                    this.pr_um.nativeElement.value = 0;
                  }
            }, 500);
          } catch (error) {
            this.dialogSpin.close();
            this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }
        calculos_traslado(valor:any):void{
          try {
            if ( isNaN(this.unidad_m.nativeElement.value) || this.unidad_m.nativeElement.value === '' || parseInt(this.unidad_m.nativeElement.value) <= 0) {
              this.snackBar.open('El campo unidad de manejo no tiene ningun valor a ser calculado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                this.cant_lote.nativeElement.value = '';
            }else{
              
              if (isNaN(valor.value) || valor.value === '') {
                this.cantidad.nativeElement.value = '0';
              }else{
                var xvalor = parseInt(valor.value) * parseInt(this.unidad_m.nativeElement.value);
                this.cantidad.nativeElement.value = xvalor;
              }
            }
          } catch (error) {
            this.dialogSpin.close();
            this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }

        calculos_cant_lote(valor:any):void{
          try {
            if ( isNaN(this.cant_lote.nativeElement.value)
             || this.unidad_m.nativeElement.value === '' 
             || parseInt(this.unidad_m.nativeElement.value) <= 0 
             || this.cod_lote.nativeElement.value === '') {
              this.snackBar.open('El campo unidad de manejo no tiene ningun valor a ser calculado o no has seleccionado un lote', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                this.cant_lote.nativeElement.value = '0';
            }else{
              
              if (isNaN(valor.value) || valor.value === '') {
                this.cant_um.nativeElement.value = '0';
              }else{
                
                if (parseInt(this.pr_um.nativeElement.value) === 3 ||  parseInt(this.pr_um.nativeElement.value) === 7) {
                  var xvalor = parseInt(valor.value) * 1;
                  this.cant_um.nativeElement.value = xvalor;
                }else{
                  var xvalor = parseInt(valor.value) * parseInt(this.unidad_m.nativeElement.value);
                  this.cant_um.nativeElement.value = xvalor;
                }
              }
            }
          } catch (error) {
            this.dialogSpin.close();
            this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
              maxWidth: '100vw',
              height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result != 'cerrar') {
        await this.util.abrirSpinner();
        this.dialogRef.close('cerrar');
      }
    });
  }

  addLote(){
    try {

      console.log(this.pi);
      console.log(this.pl);
         if (this.pr_um.nativeElement.value !== '' && parseInt(this.pr_um.nativeElement.value) !== 0) {
           let arry = [];
           this.forma.value.cant_producto = this.cant_lote.nativeElement.value.replace('.','')
                                                                              .replace('.','')
                                                                              .replace('.','')
                                                                              .replace(',','.');
   
     
           var bulto_restante;
     
           if (parseInt(this.cant_lote.nativeElement.value) > parseInt(this.total_blts.nativeElement.value)) {
             bulto_restante = parseInt(this.cant_lote.nativeElement.value) - parseInt(this.total_blts.nativeElement.value);
           }else{
             bulto_restante =  parseInt(this.total_blts.nativeElement.value) -  parseInt(this.cant_lote.nativeElement.value);
           }
     /////////////////////////////////////////CASO 3 y 7//////////////////////////////////////////////////////////////////////////////////
           if (parseInt(this.pr_um.nativeElement.value) === 3 || parseInt(this.pr_um.nativeElement.value) === 7) {
             if (parseInt(this.cant_um.nativeElement.value) > 0 && this.cod_lote.nativeElement.value !== '' 
             && parseInt(this.cant_um.nativeElement.value) <= parseInt(this.total_lote.nativeElement.value)
             && this.pl === this.pi) {
           var restante;
     
           restante = parseInt(this.total_lote.nativeElement.value) - parseInt(this.cant_um.nativeElement.value) ;
     
         if (this.ELEMENT_DATA.length === 0) {
     
           /* this.listarproducto(); */
     
           this.ELEMENT_DATA.push({
             cod_lote:this.cod_lote.nativeElement.value,
             solicitud_lote:this.cant_um.nativeElement.value,
             actual_lote:this.total_lote.nativeElement.value,
             restante_lote:restante.toString(),
             solicitud_bt:'N/A',
             actual_bt:'N/A',
             restante_bt:'N/A'
           });
                   this.cantidad.nativeElement.value = this.cant_um.nativeElement.value
                   this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                 
                   this.cant_lote.nativeElement.value = '0';
                   this.cant_um.nativeElement.value = '0';
   
                   var operacion = parseInt(this.cantidad.nativeElement.value.replace('.', '')
                   .replace('.', '')
                   .replace('.', '')
                   .replace('.', '')
                   .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                   .replace('.', '')
                   .replace('.', '')
                   .replace('.', '')
                   .replace(',', '.'));
   
                   this.total_blt.nativeElement.value = operacion.toFixed(2).replace(/\D/g, "")
                   .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");;
                  
                   this.cantidad_item();
                   this.cantidad_productos();
                   this.cantidad_actual();
                   this.cantidad_restante();
                   this.bp_();
                   this.ba_();
                   this.br_();
                   
         }else{
     
           console.log(this.ELEMENT_DATA);
             for (const i in this.ELEMENT_DATA) {
              arry.push(this.ELEMENT_DATA[i].cod_lote);
             }
             var index = arry.includes(this.cod_lote.nativeElement.value);
     
             if (!index) {
               if (this.ELEMENT_DATA.length <= 10) {
     
               /* this.listarproducto(); */
     
               this.ELEMENT_DATA.push({
                 cod_lote:this.cod_lote.nativeElement.value,
                 solicitud_lote:this.cant_um.nativeElement.value,
                 actual_lote:this.total_lote.nativeElement.value,
                 restante_lote:restante.toString(),
                 solicitud_bt:'N/A',
                 actual_bt:'N/A',
                 restante_bt:'N/A'
               });
     
               this.cantidad.nativeElement.value = parseInt(this.cant_um.nativeElement.value.replace('.', '')
                                                                 .replace('.', '')
                                                                 .replace('.', '')
                                                                 .replace('.', '')
                                                                 .replace(',', '.')) + parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                                 .replace('.', '')
                                                                 .replace('.', '')
                                                                 .replace('.', '')
                                                                 .replace(',', '.'))
     
               this.cantidad.nativeElement.value = this.cantidad.nativeElement.value
     
                     
                     this.cant_lote.nativeElement.value = '0';
                     this.cant_um.nativeElement.value = '0';
                    
     
                     this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
   
                     var operacion = parseInt(this.cantidad.nativeElement.value.replace('.', '')
                     .replace('.', '')
                     .replace('.', '')
                     .replace('.', '')
                     .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                     .replace('.', '')
                     .replace('.', '')
                     .replace('.', '')
                     .replace(',', '.'));
   
                     this.total_blt.nativeElement.value = operacion.toFixed(2).replace(/\D/g, "")
                                                                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
     
                     this.cantidad_item();
                     this.cantidad_productos();
                     this.cantidad_actual();
                     this.cantidad_restante();
                     this.bp_();
                     this.ba_();
                     this.br_();
     
               }else{
                 this.snackBar.open('Solo puede asignar 10 lotes como maximo', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
               }
     
             }else{
     
               if (index) {
                 this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
                   
                   this.cant_lote.nativeElement.value = '0';
                   this.cant_um.nativeElement.value = '0';
                   
               }
     
               /* this.listarproducto(); */
     
             }
         }
       
     }else{
     
     if (parseInt(this.pl.toString()) !== parseInt(this.pi.toString())) {
         this.snackBar.open('El total en el inventario ( PI ), no es igual al total del producto por lote  ( PL )', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
       } 
     
         if (parseInt(this.cant_um.nativeElement.value) <= 0 || isNaN(this.cant_um.nativeElement.value) || this.cant_um.nativeElement.value === "") {
           this.snackBar.open('La cantidad debe ser mayor a 0 y solo puede ingresar numeros', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,});
         }
         if (this.cod_lote.nativeElement.value  === "") {
           this.snackBar.open('Debes seleccionar al menos un lote', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,});
         }
         if (parseInt(this.cant_um.nativeElement.value)  > parseInt(this.total_lote.nativeElement.value)) {
           this.snackBar.open('Excedes la cantidad disponible en el lote: " '+this.cod_lote.nativeElement.value+' "', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,});
         }
     
     }
           }
   
           /////////////////////////////////////////CASO 3 y 7//////////////////////////////////////////////////////////////////////////////////
   
   /////////////////////////////////////////CASO 1 y 2//////////////////////////////////////////////////////////////////////////////////
   if (parseInt(this.pr_um.nativeElement.value) === 1 || parseInt(this.pr_um.nativeElement.value) === 2) {
     if (parseInt(this.cant_um.nativeElement.value) > 0 && this.cod_lote.nativeElement.value !== '' 
                   && parseInt(this.cant_um.nativeElement.value) <= parseInt(this.total_lote.nativeElement.value)
                   && parseInt(this.total_prod_lotes.nativeElement.value) === parseInt(this.total_prod_inv.nativeElement.value)) {
                 var restante;
     
                 restante = parseInt(this.total_lote.nativeElement.value) - parseInt(this.cant_um.nativeElement.value) ;
     
               if (this.ELEMENT_DATA.length === 0) {
       
                 /* this.listarproducto(); */
     
                 this.ELEMENT_DATA.push({
                   cod_lote:this.cod_lote.nativeElement.value,
                   solicitud_lote:this.cant_um.nativeElement.value,
                   actual_lote:this.total_lote.nativeElement.value,
                   restante_lote:restante.toString(),
                   solicitud_bt:this.cant_lote.nativeElement.value,
                   actual_bt:this.total_blts.nativeElement.value,
                   restante_bt:bulto_restante.toString()
                 });
     
                         this.cantidad.nativeElement.value = this.cant_um.nativeElement.value

                         let   resta_lote = parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) ;
                          this.total_blt.nativeElement.value = resta_lote.toFixed(2).replace(/\D/g, "")
                                                                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                         this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                       
                         this.cant_lote.nativeElement.value = '0';
                         this.cant_um.nativeElement.value = '0';


                        
                         this.cantidad_item();
                         this.cantidad_productos();
                         this.cantidad_actual();
                         this.cantidad_restante();
                         this.bp_();
                         this.ba_();
                         this.br_();
                         
               }else{
       
                 console.log(this.ELEMENT_DATA);
                   for (const i in this.ELEMENT_DATA) {
                    arry.push(this.ELEMENT_DATA[i].cod_lote);
                   }
                   var index = arry.includes(this.cod_lote.nativeElement.value);
       
                   if (!index) {
                     if (this.ELEMENT_DATA.length <= 10) {
     
                     /* this.listarproducto(); */
     
                     this.ELEMENT_DATA.push({
                       cod_lote:this.cod_lote.nativeElement.value,
                       solicitud_lote:this.cant_um.nativeElement.value,
                       actual_lote:this.total_lote.nativeElement.value,
                       restante_lote:restante.toString(),
                       solicitud_bt:this.cant_lote.nativeElement.value,
                       actual_bt:this.total_blts.nativeElement.value,
                       restante_bt:bulto_restante.toString()
                     });
     
                     this.cantidad.nativeElement.value = parseInt(this.cant_um.nativeElement.value.replace('.', '')
                                                                       .replace('.', '')
                                                                       .replace('.', '')
                                                                       .replace('.', '')
                                                                       .replace(',', '.')) + parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                                       .replace('.', '')
                                                                       .replace('.', '')
                                                                       .replace('.', '')
                                                                       .replace(',', '.'))
     
                           this.cantidad.nativeElement.value = this.cantidad.nativeElement.value
       
                           this.cant_lote.nativeElement.value = '0';
                           this.cant_um.nativeElement.value = '0';

                        let   resta_lote = parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) ;
                          this.total_blt.nativeElement.value = resta_lote.toFixed(2).replace(/\D/g, "")
                          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                           this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
     
                           this.cantidad_item();
                           this.cantidad_productos();
                           this.cantidad_actual();
                           this.cantidad_restante();
                           this.bp_();
                           this.ba_();
                           this.br_();
       
                     }else{
                       this.snackBar.open('Solo puede asignar 10 lotes como maximo', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                         verticalPosition: this.verticalPosition,});
                     }
     
                   }else{
       
                     if (index) {
                       this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                         verticalPosition: this.verticalPosition,});
                         
                         this.cant_lote.nativeElement.value = '0';
                         this.cant_um.nativeElement.value = '0';
                         
                     }
     
                     /* this.listarproducto(); */
       
                   }
               }
             
           }else{
     
             if (parseInt(this.total_prod_lotes.nativeElement.value) !== parseInt(this.total_prod_inv.nativeElement.value)) {
               this.snackBar.open('El total en el inventario ( PI ), no es igual al total del producto por lote  ( PL )', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
             }
     
               if (parseInt(this.cant_um.nativeElement.value) <= 0 || isNaN(this.cant_um.nativeElement.value) || this.cant_um.nativeElement.value === "") {
                 this.snackBar.open('La cantidad debe ser mayor a 0 y solo puede ingresar numeros', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
               }
               if (this.cod_lote.nativeElement.value  === "") {
                 this.snackBar.open('Debes seleccionar al menos un lote', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
               }
               if (parseInt(this.cant_um.nativeElement.value)  > parseInt(this.total_lote.nativeElement.value)) {
                 this.snackBar.open('Excedes la cantidad disponible en el lote: " '+this.cod_lote.nativeElement.value+' "', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
               }
     
           }
   }
   
   /////////////////////////////////////////CASO 1 y 2//////////////////////////////////////////////////////////////////////////////////
   
   /////////////////////////////////////////CASO 4,5 y 6//////////////////////////////////////////////////////////////////////////////////
   if (parseInt(this.pr_um.nativeElement.value) === 4 ||
       parseInt(this.pr_um.nativeElement.value) === 5 ||
       parseInt(this.pr_um.nativeElement.value) === 6) {
         if (parseInt(this.cant_um.nativeElement.value) > 0 && this.cod_lote.nativeElement.value !== '' 
         && parseInt(this.cant_um.nativeElement.value) <= parseInt(this.total_lote.nativeElement.value)
         && parseInt(this.total_prod_lotes.nativeElement.value) === parseInt(this.total_prod_inv.nativeElement.value)) {
       var restante;
   
       restante = parseInt(this.total_lote.nativeElement.value.replace('.', '')
       .replace('.', '')
       .replace('.', '')
       .replace('.', '')
       .replace(',', '.')) - parseInt(this.cant_um.nativeElement.value.replace('.', '')
       .replace('.', '')
       .replace('.', '')
       .replace('.', '')
       .replace(',', '.')) ;
   
     if (this.ELEMENT_DATA.length === 0) {
   
       /* this.listarproducto(); */
   
       this.ELEMENT_DATA.push({
         cod_lote:this.cod_lote.nativeElement.value,
         solicitud_lote:this.cant_um.nativeElement.value,
         actual_lote:this.total_lote.nativeElement.value,
         restante_lote:restante.toString(),
         solicitud_bt:'N/A',
         actual_bt:'N/A',
         restante_bt:'N/A'
       });
               this.cantidad.nativeElement.value = this.cant_um.nativeElement.value
               this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
             
               this.cant_lote.nativeElement.value = '0';
               this.cant_um.nativeElement.value = '0';

               let   resta_lote = parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) ;
                          this.total_blt.nativeElement.value = resta_lote.toFixed(2).replace(/\D/g, "")
                          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
              
               this.cantidad_item();
               this.cantidad_productos();
               this.cantidad_actual();
               this.cantidad_restante();
               this.bp_();
               this.ba_();
               this.br_();
               
     }else{
   
       console.log(this.ELEMENT_DATA);
         for (const i in this.ELEMENT_DATA) {
          arry.push(this.ELEMENT_DATA[i].cod_lote);
         }
         var index = arry.includes(this.cod_lote.nativeElement.value);
   
         if (!index) {
           if (this.ELEMENT_DATA.length <= 10) {
   
           /* this.listarproducto(); */
   
           this.ELEMENT_DATA.push({
             cod_lote:this.cod_lote.nativeElement.value,
             solicitud_lote:this.cant_um.nativeElement.value,
             actual_lote:this.total_lote.nativeElement.value,
             restante_lote:restante.toString(),
             solicitud_bt:'N/A',
             actual_bt:'N/A',
             restante_bt:'N/A'
           });
   
           this.cantidad.nativeElement.value = parseInt(this.cant_um.nativeElement.value.replace('.', '')
                                                             .replace('.', '')
                                                             .replace('.', '')
                                                             .replace('.', '')
                                                             .replace(',', '.')) + parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                             .replace('.', '')
                                                             .replace('.', '')
                                                             .replace('.', '')
                                                             .replace(',', '.'))
   
           this.cantidad.nativeElement.value = this.cantidad.nativeElement.value
   
                 
                 this.cant_lote.nativeElement.value = '0';
                 this.cant_um.nativeElement.value = '0';
                
                 let   resta_lote = parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) ;
                          this.total_blt.nativeElement.value = resta_lote.toFixed(2).replace(/\D/g, "")
                          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
   
                 this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
   
                 this.cantidad_item();
                 this.cantidad_productos();
                 this.cantidad_actual();
                 this.cantidad_restante();
                 this.bp_();
                 this.ba_();
                 this.br_();
   
           }else{
             this.snackBar.open('Solo puede asignar 10 lotes como maximo', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
           }
   
         }else{
   
           if (index) {
             this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
               
               this.cant_lote.nativeElement.value = '';
               this.cant_um.nativeElement.value = '0';
               
           }
   
           /* this.listarproducto(); */
   
         }
     }
   
   }else{
   
   if (parseInt(this.total_prod_lotes.nativeElement.value) !== parseInt(this.total_prod_inv.nativeElement.value)) {
     this.snackBar.open('El total en el inventario ( PI ), no es igual al total del producto por lote  ( PL )', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
       verticalPosition: this.verticalPosition,});
   } 
   
     if (parseInt(this.cant_um.nativeElement.value) <= 0 || isNaN(this.cant_um.nativeElement.value) || this.cant_um.nativeElement.value === "") {
       this.snackBar.open('La cantidad debe ser mayor a 0 y solo puede ingresar numeros', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
     if (this.cod_lote.nativeElement.value  === "") {
       this.snackBar.open('Debes seleccionar al menos un lote', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
     if (parseInt(this.cant_um.nativeElement.value)  > parseInt(this.total_lote.nativeElement.value)) {
       this.snackBar.open('Excedes la cantidad disponible en el lote: " '+this.cod_lote.nativeElement.value+' "', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   
   }
   }
   
   /////////////////////////////////////////CASO 4, 5 y 6//////////////////////////////////////////////////////////////////////////////////
     
         }else{
           this.snackBar.open('Debes seleccionar los dos depositos para continuar el proceso'+this.cod_lote.nativeElement.value+' "', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,});
         }
   
    } catch (error) {
      console.log(error);
    }
  }

  remove(val:any){
    console.log(val);
    this.dataSource.data.splice(this.ELEMENT_DATA.indexOf(val), 1);

    if (parseInt(val.solicitud_lote.replace('.', '')
                              .replace('.', '')
                              .replace('.', '')
                              .replace('.', '')
                              .replace(',', '.')) >= parseInt(this.cantidad.nativeElement.value.replace('.', '')
                              .replace('.', '')
                              .replace('.', '')
                              .replace('.', '')
                              .replace(',', '.'))) {

      this.cantidad.nativeElement.value = parseInt(val.solicitud_lote.replace('.', '')
                                                                      .replace('.', '')
                                                                      .replace('.', '')
                                                                      .replace('.', '')
                                                                      .replace(',', '.')) - parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                                      .replace('.', '')
                                                                      .replace('.', '')
                                                                      .replace('.', '')
                                                                      .replace(',', '.'));

      var resta_lote= parseInt(val.solicitud_lote.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace('.', '')
                                                    .replace(',', '.')) ;

                if (resta_lote > parseFloat(this.total_blt.nativeElement.value.replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace(',', '.'))) {

                  var resul =  parseFloat(this.total_blt.nativeElement.value.replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace(',', '.')) - resta_lote;  

                  this.total_blt.nativeElement.value = resul.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                }else{
                  var resul = resta_lote -  parseFloat(this.total_blt.nativeElement.value.replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace(',', '.'));  

                  this.total_blt.nativeElement.value = resul.toFixed(2).replace(/\D/g, "")
                                                            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                }

          

    }else{
      this.cantidad.nativeElement.value = parseInt(this.cantidad.nativeElement.value.replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace(',', '.')) - parseInt(val.solicitud_lote.replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace(',', '.'));
                var resta_lote= parseInt(val.solicitud_lote.replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace(',', '.')) / parseInt(this.unidad_m.nativeElement.value.replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace('.', '')
                                                                                    .replace(',', '.')) ;
                                
                                                if (resta_lote > parseFloat(this.total_blt.nativeElement.value.replace('.', '')
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace('.', '')
                                                .replace(',', '.'))) {
                                
                                                  var resul =  parseFloat(this.total_blt.nativeElement.value.replace('.', '')
                                                  .replace('.', '')
                                                  .replace('.', '')
                                                  .replace('.', '')
                                                  .replace(',', '.')) - resta_lote;  
                                
                                                  this.total_blt.nativeElement.value = resul.toFixed(2).replace(/\D/g, "")
                                                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                                  
                                                }else{
                                                  var resul = resta_lote -  parseFloat(this.total_blt.nativeElement.value.replace('.', '')
                                                  .replace('.', '')
                                                  .replace('.', '')
                                                  .replace('.', '')
                                                  .replace(',', '.'));  
                                
                                                  this.total_blt.nativeElement.value = resul.toFixed(2).replace(/\D/g, "")
                                                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                                }
    }

    this.cantidad_item();
    this.cantidad_productos();
    this.cantidad_actual();
    this.cantidad_restante();
    this.bp_();
    this.ba_();
    this.br_();

     this.table.renderRows();
   }
}
