import { Component, OnInit, Inject,AfterViewInit, ViewChild } from '@angular/core';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { FormLicenciaComponent } from 'src/app/componentes/shared/modal/form-licencia/form-licencia.component';
import { FormParametrosLicenciaComponent } from 'src/app/componentes/shared/modal/form-parametros-licencia/form-parametros-licencia.component';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Component({
  selector: 'app-licenciamiento-control',
  templateUrl: './licenciamiento-control.component.html',
  styleUrls: ['./licenciamiento-control.component.css']
})
export class LicenciamientoControlComponent implements OnInit {
  tipo_licencia:any;
  parametros_licencia:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();
  constructor(
              private servicio:nkServices,
              private dialog: MatDialog, 
              private util:AppUtilService,
              private atrb:AtributosService,
              private hashkey:HashKeyServices,
              private notifi:ToastNotificationService,
              ){}
  ngOnInit(){
    this.listarLicencias(null);
    this.buscardefiniciones();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }
 async listarLicencias(msj:any){
    try {
          await this.util.abrirSpinner();
          await this.servicio.consultar_sesionhttp(null).toPromise();
        let msg = msj;
        let xvalor={
          api: await this.hashkey.encrypt(await this.atrb.API_REST.listarLicencias),
        }
      const response = await this.servicio.ApiResthttp(xvalor).toPromise();
              this.ELEMENT_DATA = [];
                  for (const i in response.data) {
                    this.ELEMENT_DATA.push({
                      cod_licencia:response.data[i].cod_licencia,
                      des_licencia: response.data[i].des_licencia,
                      descrip_licencia: response.data[i].descrip_licencia,
                      estatus:response.data[i].estatus,
                      fecha_inicio:response.data[i].fecha_inicio,
                      hora_inicio: response.data[i].hora_inicio
                    });
                  }
                  this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_LICENCIAS;
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
       
                  if(msg == await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                    await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                }else{
                  await this.util.cerrarSpinner();
                }
    } catch (error) {
      await this.util.cerrarSpinner();
          this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_LICENCIAS;
          this.dataSource = new MatTableDataSource<any>();
          this.dataSource.paginator = this.paginator;
        await this.notifi.customToastNotification(error.error.message);
    }
  }
 async update_insert_data(xobjeto:any,text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
                          var dialogRef:any;
                          this.accion = text;
                          if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                            if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def;
                                  val_def = {
                                    val_def:await this.atrb.ACCESO_VALDEF.gestionar_licencias,
                                    tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                    cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                    api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                  }
                              await this.servicio.ApiResthttp(val_def).toPromise();
                        }
                            await this.util.cerrarSpinner();
                            dialogRef = this.dialog.open(FormLicenciaComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                      accion:this.accion,
                                      data:xobjeto,
                                      tipo_licencia:this.tipo_licencia},
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }else{
                            if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def;
                                  val_def = {
                                    val_def:await this.atrb.ACCESO_VALDEF.gestionar_licencias,
                                    tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                    cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                    api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                  }
                              await this.servicio.ApiResthttp(val_def).toPromise();
                        }
                            await this.util.cerrarSpinner();
                            dialogRef = this.dialog.open(FormLicenciaComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                      tipo_licencia:this.tipo_licencia,
                                      accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }
                            dialogRef.afterClosed().subscribe(async result => {
                              if(result != 'cerrar'){
                                this.gestionLicencias(result);
                              }else{
                                await this.util.cerrarSpinner();
                              }
                            });
    } catch (error) {
      await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
    }
  }
 async parametrosLicencia(xobjeto:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
            let val_def;
                val_def = {
                  val_def:await this.atrb.ACCESO_VALDEF.gestionar_licencias,
                  tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
                  cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                  api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                }
            await this.servicio.ApiResthttp(val_def).toPromise();
      }
      await this.util.cerrarSpinner();
                          var dialogRef:any;
                            dialogRef = this.dialog.open(FormParametrosLicenciaComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                      accion:this.accion,
                                      data:xobjeto,
                                      tipo_licencia:this.tipo_licencia,
                                      parametros_licencia:this.parametros_licencia
                                    },
                              disableClose: true,
                              panelClass: 'pedidos_modal3',
                              backdropClass: "bdrop"
                            });
                            dialogRef.afterClosed().subscribe(async result => {
                              if(result != 'cerrar'){
                                this.gestionLicencias(result);
                              }else{
                                await this.util.cerrarSpinner();
                              }
                            });
    } catch (error) {
      await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
    }
  }
  async  gestionLicencias(xvalor:any){
    try {
      xvalor.api = await this.hashkey.encrypt(await this.atrb.API_REST.gestionLicencias);
     const response = await this.servicio.ApiResthttp(xvalor).toPromise();
     await this.util.cerrarSpinner();
        await this.listarLicencias(null);
             await this.notifi.success(response.message);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
      await this.listarLicencias(null);
    }
   }
async buscardefiniciones(){
    try {
      var xvalor = {
        cod_menu: localStorage.getItem('sub_main'),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.buscardefiniciones),
      };
        const response = await this.servicio.ApiResthttp(xvalor).toPromise();
        this.tipo_licencia = [];
            this.parametros_licencia = [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() ===  await this.atrb.LICENCIAMIENTO.tipos.toUpperCase()) {
                this.tipo_licencia.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() ===  await this.atrb.LICENCIAMIENTO.parametros_licencia.toUpperCase()) {
                this.parametros_licencia.push(response[i]);
              }
            }
    } catch (error) {
      console.log(error.error.message);
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
