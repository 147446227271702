<div id="modal_carrito" class=" container-fluid">

    <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid rgb(88, 42, 126); background-color: rgb(131, 70, 180); ">

        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)="listar_notas_credito('actualizar')" matTooltip="ACTUALIZAR LISTA" style="color:rgb(255, 255, 255); background-color: rgb(160, 34, 122);">
                      <mat-icon >sync</mat-icon>
                    </button>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)="en_construccion()" matTooltip="ESTADISTICAS" style="color:rgb(255, 255, 255); background-color: rgb(20, 78, 185);">
                      <mat-icon >bar_chart</mat-icon>
                    </button>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)="en_construccion()" matTooltip="SEGUIMIENTO" style="color:rgb(255, 255, 255); background-color: rgb(88, 42, 126);">
                      <mat-icon >search</mat-icon>
                    </button>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)="en_construccion()" matTooltip="REPORTES" style="color:rgb(255, 255, 255); background-color: rgb(233, 112, 31);">
                      <mat-icon >list</mat-icon>
                    </button>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)='notas_credito_admin_single("","AD-SN")' matTooltip="NOTAS DE CREDITO" style="color:rgb(255, 255, 255); background-color: rgb(42, 168, 11);">
                      <mat-icon >add</mat-icon>
                    </button>
            </div>
        </div>

        <div class="col-md-6 px-0 py-2 h-100">
            <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(88, 42, 126);">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                    <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
            </div>
        </div>
    </div>
    <div id="carrito_p2" class="row" style=" border: 2px solid rgb(88, 42, 126);">
        <div class="col-md-12 px-0  border h-100" style="background-color: rgb(131, 70, 180);">
            <table mat-table [dataSource]="dataSource" class="table-danger text-center table-striped table-bordered table-condensed table-hover">

                <ng-container matColumnDef="cod_factura">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Factura</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.cod_factura}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">{{cantidad_item()}}</td>
                </ng-container>

                <ng-container matColumnDef="cod_nota_credito">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Nota credito</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.cod_nota_credito}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Nota credito</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cod_lista_precio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Lista precios</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.cod_lista_precio}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Lista precios</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Cod cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.cod_cliente}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Cod cliente</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tipo_nota">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Tipo nota</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.tipo_nota}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Tipo nota</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.des_cliente}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Cliente</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Cod usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.cod_usuario}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Cod usuario</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.des_usuario}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Usuario</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.estatus}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Estatus</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Fecha inicio</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.fecha_inicio}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Fecha inicio</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="hora">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Hora inicio</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: black;"> {{element.hora_inicio}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Hora inicio</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="imprimirnota(element)">
                        <button matTooltip="IMPRIMIR NOTA DE CREDITO" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 86, 247);">
                            <mat-icon >print</mat-icon>
                            </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Imprimir</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="descargar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="en_construccion()">
                        <button matTooltip="DESCARGAR NOTA DE CREDITO" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(158, 0, 250);">
                              <mat-icon >get_app</mat-icon>
                              </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(131, 70, 180); color: #fff;">
                        <h5><strong>Descargar</strong></h5>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
        </div>
    </div>

</div>