import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-precio-cotizar',
  templateUrl: './form-precio-cotizar.component.html',
  styleUrls: ['./form-precio-cotizar.component.css']
})
export class  FormPrecioCotizarComponent implements OnInit {

  @ViewChild('cant_ingresada',{static:true}) cant_ingresada:ElementRef;
  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];

  producto:any=[];
  codigo:any=[];
  AlertMensaje:string;
  excede:boolean = false

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormPrecioCotizarComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      console.log(data, 'dataaaaaa')

      this.producto = data.des_producto;
      this.codigo = data.cod_producto;
      this.forma = fb.group({
        'cant_producto_soli':data.cant_producto_ap,
        'cant_ingresada':['',[Validators.required]],
        'cant_producto_cot':['',[Validators.required]]
      });

    }

  ngOnInit(): void {
  }


  devolucion_prod(xvalor:any, xvalor2:any){
    
    console.log(xvalor2);
    console.log(this.data.cant_producto_ap);
    if(Number(xvalor2.replace(/\./g,'').replace(',','.')) > Number(this.data.cant_producto_ap.replace(/\./g,'').replace(',','.'))){
      return this.snackBar.open('Lo cotizado no puede ser mayor a lo requerido.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

    let elSelect = document.querySelector("#elSelect") as HTMLSelectElement

     try {
        var xobj
        console.log(Number(this.cant_ingresada.nativeElement.value), 'estamos aqui, aqui nos quedamos');
        if (!(this.cant_ingresada.nativeElement.value == '' ) && !(parseFloat(this.cant_ingresada.nativeElement.value.replace(',','.')) == 0) ) {


            xobj =  {
                      cant_producto_soli:this.data.cant_producto_ap, 
                      cant_ingresada:xvalor.replace(/\./g,'')+' '+elSelect.value,
                      cod_producto:this.data.cod_producto,
                      cant_producto_cot: xvalor2.replace(/\./g,'')
                    }

             this.dialogRefp.close(xobj);

          
        }else{
          this.snackBar.open('Debe ser mayor a 0.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    } 
  }

  ponerPuntosyComas(event:any, id:any){

    let validacion = '1234567890'
    console.log(event.key)
    let inputCantidad = document.querySelector(`#${id}`) as HTMLInputElement
    if(id != 'cant_ingresada'){
      if(inputCantidad.value.length == 1){
        let noa = inputCantidad.value.split('')
        noa.unshift('0')
        noa.unshift('0')
        inputCantidad.value = noa.join('')
      }else if(inputCantidad.value.length == 3){
        let noa = inputCantidad.value.split('')
        noa.unshift('0')
        inputCantidad.value = noa.join('')
      }else if(inputCantidad.value.length >= 4 ){
        let noa:any = inputCantidad.value.split('')
        for(let i = 0 ; i < 2; i++){
          if(noa[0] == 0){
            noa.shift()
          }else{
            break;
          }
        }
        inputCantidad.value = noa.join('')
      }
      let counter = 0
      let comaExiste = false
      let num = inputCantidad.value.replace(/\D/g,'');
      const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
        for(let i = array.length - 1; i > -1 ; i--){
          if(array[i] === ','){
            comaExiste = true
          }
          if(comaExiste){
            if(counter === 3){
              if(array[i-1]){
                array.splice(i,0,'.')
                counter = 1
              }
            }else{
              counter++
            }
          }
        }
        inputCantidad.value = array.join('');
    }else{
      if(this.data.tipo_unidad_medida.toLowerCase() == 'grs'){
        if(inputCantidad.value.length == 1){
          let noa = inputCantidad.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          noa.unshift('0')
          noa.unshift('0')
          noa.unshift('0')
          inputCantidad.value = noa.join('')
        }else if(inputCantidad.value.length == 6){
          let noa = inputCantidad.value.split('')
          noa.unshift('0')
          inputCantidad.value = noa.join('')
        }else if(inputCantidad.value.length >= 7 ){
          let noa:any = inputCantidad.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputCantidad.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        const num = inputCantidad.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{5})$/, '$1,$2').split('')
        for(let i = array.length - 1; i > -1 ; i--){
          if(array[i] === ','){
            comaExiste = true
          }
          if(comaExiste){
            if(counter === 3){
              if(array[i-1]){
                array.splice(i,0,'.')
                counter = 1
              }
            }else{
              counter++
            }
          }
        }
        inputCantidad.value = array.join('');
      }else{
        if(inputCantidad.value.length == 1){
          let noa = inputCantidad.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          noa.unshift('0')
          noa.unshift('0')
          inputCantidad.value = noa.join('')
        }else if(inputCantidad.value.length == 5){
          let noa = inputCantidad.value.split('')
          noa.unshift('0')
          inputCantidad.value = noa.join('')
        }else if(inputCantidad.value.length >= 6 ){
          let noa:any = inputCantidad.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputCantidad.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        const num = inputCantidad.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{4})$/, '$1,$2').split('')
        for(let i = array.length - 1; i > -1 ; i--){
          if(array[i] === ','){
            comaExiste = true
          }
          if(comaExiste){
            if(counter === 3){
              if(array[i-1]){
                array.splice(i,0,'.')
                counter = 1
              }
            }else{
              counter++
            }
          }
        }
        inputCantidad.value = array.join('');
      }
    }
    if(parseInt(inputCantidad.value) > parseInt(this.data.cant_producto_soli)){
      this.excede = true
    }else{
      this.excede = false
    }
    if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
      this.snackBar.open('Solo numeros', 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  cambiarMoneda(e:any){
    console.log(e.target.value)
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
