import { Component, OnInit, ViewChild} from '@angular/core';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';

import 'offline-js'
//esto es de mi desarrollo 
import {MatDialog} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import { FormCotizacionReqComponent } from 'src/app/componentes/shared/modal/form-cotizacion-req/form-cotizacion-req.component';
import { FormAprobarCotizacionComponent } from 'src/app/componentes/shared/modal/form-aprobar-cotizacion/form-aprobar-cotizacion.component';
import { FormCrearCotizacionComponent } from 'src/app/componentes/shared/modal/form-crear-cotizacion/form-crear-cotizacion.component';
import { FormCotizacionVerCotizacionComponent } from 'src/app/componentes/shared/modal/form-cotizacion-ver-cotizacion/form-cotizacion-ver-cotizacion.component';
import { FormmMsgComponent } from '../../../../shared/modal/formm-msg/formm-msg.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import iziToast from 'izitoast';

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.css']
})
export class CotizacionComponent implements OnInit {

  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;

  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  aprobar:any=false

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {
    
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }
/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
    
  }

  ngOnInit(): void {
    this.listarSolicitudesCompra()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  listarSolicitudesCompra(){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        this.servicio.listarCotizacionesNuevashttp().subscribe(
          response =>{
            console.log(response, )
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              let xobj2 = response.result;
              let array = []
              this.ELEMENT_DATA = [];

              xobj2.forEach(orden => {

                array.unshift({
                  id_solicitud_compra:orden.id_solicitud_compra,
                  cod_solicitud_compra: orden.cod_solicitud_compra,
                  cod_orden_req:orden.cod_orden_req ? orden.cod_orden_req : '-',
                  cod_cotizacion_req:orden.cod_cotizacion ? orden.cod_cotizacion : '-',
                  tipo_solicitud_compra:orden.tipo_solicitud_compra,
                  tipo_producto: orden.tipo_producto,
                  cod_usuario:orden.cod_usuario,
                  des_usuario:orden.des_usuario,
                  departamento: orden.departamento,
                  cod_centro_costo: orden.cod_centro_costo,
                  observaciones: orden.observaciones,
                  observaciones_aprobacion: orden.observaciones_aprobacion,
                  modalidad: orden.modalidad,
                  prioridad: orden.prioridad,
                  fecha_estimada: orden.fecha_estimada,
                  estatus:orden.estatus,
                  fecha:orden.fecha,
                  accion:''
                })

              })


               let pendienteCotizar = array.filter(element => element.estatus === "P") 
               let pendienteAprobar = array.filter(element => element.estatus === "CTP" ||element.estatus === "CTM") 
               let aprobado = array.filter(element => element.estatus == "CTA") 

               this.ELEMENT_DATA = [...pendienteCotizar,...pendienteAprobar]

                 
              this.displayedColumns = [
                'cod_cotizacion_req',
                'analista',
                'estatus',
                'fecha',
                'cotizar',
                'opciones',
              ];
                /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              'cod_cotizacion_req',
              'analista',
              'estatus',
              'fecha',
              'cotizar',
              'opciones',
            ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
          }
        );
        return this.xobj2;
      }, 500);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  crearCotizacion(){

    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
              cod_menu: localStorage.getItem('sub_main'),
              cod_sesion: localStorage.getItem('cod_sesion'),
              dir_ip_client: localStorage.getItem('dir_ip'),
            sesion_unica:sessionStorage.getItem('sesion_unica')};

      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

        this.dialogSpin.close()

        var dialogRef;
        dialogRef = this.dialog.open(FormCrearCotizacionComponent,     {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data:'',
        disableClose:   true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });

        dialogRef.afterClosed().subscribe(resul => {

          if(resul != 'cerrar'){

            console.log('Se cerro la wea')
            this.listarSolicitudesCompra()

          }

      })
      },
      error =>{
        this.dialogSpin.close()
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
      )
      
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }

  Cotizar(info:any){

    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                          cod_menu: localStorage.getItem('sub_main'),
                          cod_sesion: localStorage.getItem('cod_sesion'),
                          dir_ip_client: localStorage.getItem('dir_ip'),
                          sesion_unica:sessionStorage.getItem('sesion_unica')};

              this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

              this.dialogSpin.close()

              var dialogRef;
              dialogRef = this.dialog.open(FormCotizacionReqComponent,{
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              data:info,
              disableClose:   true,
              panelClass: 'pedidos_modal3',
              backdropClass: "bdrop"
              });

              dialogRef.afterClosed().subscribe(resul => {

              if(resul != 'cerrar'){

              console.log('Se cerro la wea1234')
              this.listarSolicitudesCompra()

              }


              })
              },
              error =>{
              this.dialogSpin.close()
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
              }

              );
      }, 500);
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
   

  }

  verCotizacion(info:any){

    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
                width: '15%',
                height:'25%',
                data: {
                        accion:this.accion},
                disableClose: true,
                panelClass: 'spinner',
                backdropClass: "bdrop"
      });
      setTimeout(() => {
              this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

              this.servicio.listarCotizacioneshttp(info.cod_cotizacion_req).subscribe(
              response => {

              this.dialogSpin.close()

              response.accion = 'ver'

              var dialogRef;
              dialogRef = this.dialog.open(FormCotizacionReqComponent,     {
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              data:response,
              disableClose:   true,
              panelClass: 'pedidos_modal3',
              backdropClass: "bdrop"
              });

              dialogRef.afterClosed().subscribe(resul => {

              if(resul != 'cerrar'){

                      this.listarSolicitudesCompra();

              }
              },
              error =>{
              this.dialogSpin.close()
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
              }
              )
              })
              },
              error =>{
              this.dialogSpin.close()
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
              }
              );
      }, 500);
      
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }
  async editarCotizacion(info:any){

    try {
      const obj = {val_def:'CMPRS',tipo_operacion:'EDITCOTIZ',cod_usuario:localStorage.getItem('cod_usuario')}
      await this.servicio.validar_acceso_acciones_http(obj).toPromise()
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

        this.servicio.listarCotizacioneshttp(info.cod_cotizacion_req).subscribe(
          response => {

          this.dialogSpin.close()

          response.accion = 'editar'
  
          var dialogRef;
          dialogRef = this.dialog.open(FormCotizacionReqComponent,     {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data:response,
          disableClose:   true,
          panelClass: 'pedidos_modal3',
          backdropClass: "bdrop"
          });
  
          dialogRef.afterClosed().subscribe(resul => {
  
            if(resul != 'cerrar'){
  
              console.log('Se cerro la wea')
              this.listarSolicitudesCompra()
  
            }
  
         
        },
        error =>{
          this.dialogSpin.close()
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        )


        })

       
      },
      error =>{
        this.dialogSpin.close()
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
      )
      
    } catch (error) {
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
   

  }

  async aprobarCotizacion(info:any, accion:any){

    try {

      const obj = {val_def:'CMPRS',tipo_operacion:'APROBARCOTIZ',cod_usuario:localStorage.getItem('cod_usuario')}

      await this.servicio.validar_acceso_acciones_http(obj).toPromise()

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });      

      this.servicio.listarCotizacioneshttp(info.cod_cotizacion_req).subscribe(result2 => {

        this.dialogSpin.close()

        info.cotizacion = result2

        var dialogRef;
        dialogRef = this.dialog.open(FormAprobarCotizacionComponent,{
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data:info,
        disableClose:   true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });

        dialogRef.afterClosed().subscribe(resul => {

          if(resul != 'cerrar'){

            console.log('Se cerro la wea')
            this.listarSolicitudesCompra()

          }

        })
      })
      
    } catch (error) {
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
   

  }

  verCotzacionesAprobadas(info:any,){

    
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {


      this.servicio.listarCotizacionesAprobadashttp(info.cod_cotizacion_req).subscribe(result2 =>{

        this.dialogSpin.close()

        info.cotizacion = result2

        info.accion = 'ver'

        console.log(result2);

        var dialogRef;
        dialogRef = this.dialog.open(FormAprobarCotizacionComponent,{
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data:info,
        disableClose:   true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });

        dialogRef.afterClosed().subscribe(resul => {

          if(resul != 'cerrar'){

            console.log('Se cerro la wea')
            this.listarSolicitudesCompra()

          }

       
        })

      })

    },
    error =>{
      this.dialogSpin.close()
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
  )
    
  }


  cotizacionesAporbadas(){

    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });


      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {
         

          this.dialogSpin.close()
  
          var dialogRef;
          dialogRef = this.dialog.open(FormCotizacionVerCotizacionComponent,     {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data:'',
          disableClose:   true,
          panelClass: 'pedidos_modal3',
          backdropClass: "bdrop"
          });
  
          dialogRef.afterClosed().subscribe(resul => {
  
            if(resul != 'cerrar'){
  
              console.log('Se cerro la wea')
              this.listarSolicitudesCompra()
  
            }
        })
      },
      error =>{
        this.dialogSpin.close()
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
      ); 
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }

  async anularCotizacionGenerada(element:any){

    try {
       /* const obj = {val_def:'CMPRS',tipo_operacion:'APROBARCOTIZ',cod_usuario:localStorage.getItem('cod_usuario')}

    await this.servicio.validar_acceso_acciones_http(obj).toPromise() */

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    console.log(element);
      const usuario = { cod_usuario: localStorage.getItem('cod_usuario'), des_usuario: localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido')}
      await this.servicio.anularCotizacionGenerada(element.cod_cotizacion_req, usuario).toPromise()

      iziToast.success({message:'Anulado con exito!'})

      this.dialogSpin.close()

      this.listarSolicitudesCompra()
      
    } catch (error) {
      this.dialogSpin.close()
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
    }
   


  }

  async volverACotizar(element:any){

    try {

      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      const data = await dialogRef.afterClosed().toPromise()

      if (data != 'cerrar') {
        
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
  
        console.log(element);
        const usuario = { cod_usuario: localStorage.getItem('cod_usuario'), des_usuario: localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido')}
        await this.servicio.volverACotizar(element.cod_cotizacion_req, usuario).toPromise()
  
        iziToast.success({title:'Listo!',message:'Operacion exitosa!'})
  
        this.dialogSpin.close()
  
        this.listarSolicitudesCompra()
        
      }
      
    } catch (error) {
      this.dialogSpin.close()
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
    }
   

  }

  en_construccion(): void {

    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    } catch (error) {
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
