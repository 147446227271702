
$(document).ready(()=>{
    $('#formulario').submit((e)=>{
        e.preventDefault();
        console.log(e);
    });
    $('#correo').submit((e)=>{
        e.preventDefault();
        console.log(e);
    });

    $("#menu-toggle").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
      });
});