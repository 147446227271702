import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormCrearDevolucionComponent } from 'src/app/componentes/shared/modal/form-crear-devolucion/form-crear-devolucion.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import { FormListarProducDevolucionComponent } from 'src/app/componentes/shared/modal/form-listar-produc-devolucion/form-listar-produc-devolucion.component';

@Component({
  selector: 'app-devolucion-productos',
  templateUrl: './devolucion-productos.component.html',
  styleUrls: ['./devolucion-productos.component.css']
})
export class DevolucionProductosComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  tipo_dev:any = [];

  accion:any;
  displayedColumns:string[] = [];
  ELEMENT_DATA:any[]=[];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;

  dataSource=new MatTableDataSource<any>();
  
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) { 

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
         /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }
    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
    }
     /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL//////////////////////////////////
      },
      error =>{
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
          console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.listarSolicitudDevolucion(null);
    this.listarDefiniciones(localStorage.getItem('sub_main'));
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  crear_devolucion(obj:any, text:any){

    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
      cod_menu: localStorage.getItem('sub_main'),
      cod_sesion: localStorage.getItem('cod_sesion'),
      dir_ip_client: localStorage.getItem('dir_ip'),
    sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
      console.log(obj, 'segundo');
      console.log(this.xobj3,'UNIDADES');
      if (text == 'insertar') {
        this.accion = text;
         dialogRef = this.dialog.open(FormCrearDevolucionComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: {
                 tipo_dev: this.tipo_dev,
                 accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
        });
      }else{
        this.accion = text;
        dialogRef = this.dialog.open(FormCrearDevolucionComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              data: {cod_usuario:localStorage.getItem('cod_usuario'),
                     des_usuario:localStorage.getItem('Nombre'),
                     accion:this.accion},
            
          disableClose: true,
          panelClass: 'pedidos_modal_devolucion',
          backdropClass: "bdrop"
        });
      }

      dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
            console.log(result,);

              this.gestionDevolucion(result);
              
          }
      });
      
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }

  listarDefiniciones(objeto:any){
    try {
      var xvalor ={cod_menu:objeto};
        this.servicio.listarDefinicionesConceptoshttp(xvalor).subscribe(
          response =>{
            console.log(response,'definiciones');
            this.tipo_dev= [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === 'TIPO DEVOLUCION') {
                  this.tipo_dev.push(response[i]);
              }
            }
          },
          error =>{
            console.log(<any>error.error.message,'error definiciones');

          }
        );
        return this.xobj2;
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }

  gestionDevolucion(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        var resultado = this.servicio.gestionarDevolucioneshttp(xvalor).subscribe(
          response =>{
            console.log(response)
            this.dialogSpin.close();
            this.listarSolicitudDevolucion(null);
            this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          },
          error =>{
            this.dialogSpin.close();
            this.displayedColumns = [
              'cod_solicitud_devolucion',
              'cod_documento',
              'des_dev',
              'cod_usuario',
              'des_usuario',
              'fecha',
              'hora',
              'tipo',
              'estatus',
              'nota_dev',
            ];
            this.dataSource = new MatTableDataSource<FormCrearDevolucionComponent>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        return resultado;
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

//se llama al cargar el componentes para mostrar todas las devoluciones pendientes 

  listarSolicitudDevolucion(msj:any){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listardevolucioneshttp().subscribe(
          response =>{
            this.dialogSpin.close();
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];

                for (const i in response) {
                  if(this.ELEMENT_DATA.some(e =>e.cod_documento == response[i].cod_documento)){

                  }else{

                    this.ELEMENT_DATA.push({
                      cod_solicitud_devolucion:response[i].cod_solicitud_devolucion,
                      cod_documento:response[i].cod_documento,
                      cod_cliente:response[i].cod_cliente,
                      des_cliente:response[i].des_cliente,
                      des_dev: response[i].des_dev,
                      cod_usuario:response[i].cod_usuario,
                      des_usuario:response[i].des_usuario,
                      fecha:response[i].fecha,
                      hora:response[i].hora,
                      tipo_devolucion:response[i].tipo_devolucion,
                      estatus:response[i].estatus,
                      accion:this.accion
                          });
                  }
                  this.displayedColumns = ['cod_solicitud_devolucion',
                                            'cod_documento',
                                            'cod_cliente',
                                            'des_cliente',
                                            'cod_usuario',
                                            'des_usuario',
                                            'fecha',
                                            'hora',
                                            'tipo',
                                            'estatus',
                                            'nota_dev',
                                          ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
              }
            }
          },
          error =>{
            this.dialogSpin.close();
            this.ELEMENT_DATA = [];
            console.log('una respuesta');
            console.log(<any>error);
            this.displayedColumns = ['cod_solicitud_devolucion',
                                      'cod_documento',
                                      'cod_cliente',
                                      'des_cliente',
                                      'cod_usuario',
                                      'des_usuario',
                                      'fecha',
                                      'hora',
                                      'tipo',
                                      'estatus',
                                      'nota_dev',
                                    ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
          }
        );
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  async dev_productos(obj:any,xvalor:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                            
                            var dialogRef;
                                dialogRef =  this.dialog.open(FormListarProducDevolucionComponent, {
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    height:'100vh',
                                    data: {
                                           cod_solicitud_devolucion: obj.cod_solicitud_devolucion,
                                           cod_documento: obj.cod_documento,
                                           cod_producto:obj.cod_producto,
                                           des_producto:obj.des_producto,
                                           cant_producto:obj.cant_producto,
                                           cod_usuario:localStorage.getItem('cod_usuario'),
                                           des_usuario:localStorage.getItem('Nombre'),
                                           tipo_devolucion:obj.tipo_devolucion,
                                           fecha:obj.fecha,
                                           hora:obj.hora,
                                           estatus:obj.estatus
                                          },
                                          disableClose: true,
                                  panelClass: 'pedidos_modal3',
                                  backdropClass: "bdrop"
                                });
                            dialogRef.afterClosed().subscribe(result => {
                            if(result !== 'cerrar' && result !== 'salir'){
                              console.log(result,'DEVOLUCION-PRODUCTOS');
                              this.fnal_dev(result);
                             }
                          });
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  fnal_dev(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.servicio.actualizacionRelocalizacionDevProductoshttp(xvalor).subscribe(
          response=>{
            this.dialogSpin.close();
            this.listarSolicitudDevolucion(null);
    
             this.snackBar.open(response.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          },
          error=>{
            this.dialogSpin.close();
            this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
      
          );
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }    
    } 
}
