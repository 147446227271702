
<div  class="container-fluid rounded" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <div class="row">
        <div class="col-md-3">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                <div id="carrito_p1_c" class="row " style=" border: 2px solid #475bca; background-color:#202333;">
                    <div class="col-md-12 px-0 py-2 h-100">
                        <div id="content_2" class="py-2 px-2 mx-1  h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <span class="text-white mr-2">Pedido:</span>
                            <span class="text-white">{{data.cod_pedido}} </span>
                        </div>
                    </div>
                </div>
                <div id="carrito_p1_c" class="row " style=" border: 2px solid #475bca; background-color:#202333;">
                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                        <div id="sub_content_1" class="py-2 px-2  mx-1  h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4;">
                            <span class="text-white mr-2">Lista de precio:</span>
                            <span class="text-white">{{data.cod_lista_precio}} </span>
                        </div>
                    </div>
                </div>
                <div id="carrito_p1_c" class="row" style=" border: 2px solid #475bca; background-color:#202333;">
                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                        <div id="sub_content_1" class="py-2 px-2  mx-1 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <span class="text-white mr-2">Tasa:</span>
                            <span class="text-white">{{data.tasa}} bs </span>
                        </div>
                    </div>
                </div>
                <div id="carrito_p1_c" class="row " style=" border: 2px solid #475bca; background-color:#202333;">
                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                        <div id="sub_content_1" class="py-2 px-2 mx-1  h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <span class="text-white mr-2">Cliente:</span>
                            <span class="text-white">{{data.des_cliente}}</span>
                        </div>
                    </div>
                </div>
                <div id="carrito_p1_c" class="row " style=" border: 2px solid #475bca; background-color:#202333;">
                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                        <div id="sub_content_1" class="py-2 px-2 mx-1  h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <span class="text-white mr-2">Inventario:</span>
                            <span class="text-white">{{data.des_deposito}} {{data.cod_deposito}}</span>
                        </div>
                    </div>
                </div>
                <div id="carrito_p1_c" class="row " style=" border: 2px solid #475bca; background-color:#202333;">
                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                        <div id="sub_content_1" class="py-2 px-2 mx-1  h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <span class="text-white mr-2">Tipo documento:</span>
                            <span class="text-white">{{data.tipo_pedido}}</span>
                        </div>
                    </div>
                </div>
                <div id="carrito_p1_c" class="row " style=" border: 2px solid #475bca; background-color:#202333;">
                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                        <div id="sub_content_1" class="py-2 px-2 mx-1  h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                        </div>
                    </div>
                </div>
                <div id="carrito_p1_c" class="row " style=" border: 2px solid #475bca; background-color:#202333;">
                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                        <div id="sub_content_1" class="py-2 px-2 mx-1  h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-9">
            <div class="row py-1">
                <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-start">
                    <span class="text-white fw-bold">Facturación</span>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                    <input class="form-control mr-1" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                    <button (click)="modalrespuesta()" matTooltip="FACTURAR"  class="col-md-1 col-sm-12 col-xs-12 mr-1" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);"><mat-icon >credit_card</mat-icon></button>
                    <button matTooltip="SALIR" (click)="cerrarmodal()" class="col-md-1 col-sm-12 col-xs-12" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(216, 68, 58);"><mat-icon >exit_to_app</mat-icon></button>
                </div>
        </div>
        <div class="row modalgeneral" style=" border: 2px solid #2b69dd; background-color:#29388f;">
            <div class="col-md-6 table-responsive  h-100" >
                <table #table mat-table [dataSource]="dataSource1" class="   table-dark text-center table-striped table-condensed table-hover">

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; " >
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cod_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; color: #fff; " > {{cantidad_item()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_inicial">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; " >
                            <h5><strong>Pedido inicial</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;">
                            {{element.cant_inicial}}

                        </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; color: #fff; " >{{cantidad_productos()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_unidad_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; " >
                            <h5><strong>Pedido final</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;">
                            <div style="display: flex;justify-content: center; align-items: center; height: 1px;"> {{element.cant_unidad_producto}}
                                <mat-icon matTooltip="EDITAR CANTIDAD" (click)='editar_cantidad_pedido(element)' style="border: outset 3px #2b3b75; cursor: pointer; border-radius: 1rem; font-size: 10; background-color: #0e2370; display: flex;justify-content: center; align-items: center;"
                                    class="mx-3">edit</mat-icon>
                            </div>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; color: #fff; " >{{cantidad_productos()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="costo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; " >
                            <h5><strong>Costo</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;">{{element.costo_producto}}</td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; color: #fff; " >{{costo_producto()}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
            <div class="col-md-6 table-responsive  h-100" >
                <table #table mat-table [dataSource]="dataSource2" class="  table-dark text-center table-striped  table-condensed table-hover">

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; " >
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cod_producto2}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; color: #fff; " > {{cantidad_item_2()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; " >
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.des_producto2}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; color: #fff; " >INVENTARIO ( {{inventario}}-{{cod_deposito}} )</td>
                    </ng-container>


                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; " >
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;">{{element.cant_producto2}}</td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; color: #fff; " > {{cantidad_productos_2()}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns2; sticky: true" ></tr>
                </table>
            </div>
        </div>
        </div>
    </div>
</div>