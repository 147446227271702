import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormPedidosComponent } from 'src/app/componentes/shared/modal/form-pedidos/form-pedidos.component';
import { PeriodicElementpedidos } from 'src/app/componentes/shared/models/interfacepedidos';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { LayoutModule } from '@angular/cdk/layout';
import { size } from 'lodash';
import { FormFacturarComponent } from 'src/app/componentes/shared/modal/form-facturar/form-facturar.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import * as moment from 'moment';

interface interfacefactura{
              lote_producto: string;
              cod_producto:string;
              des_producto:string;
              cant_producto:string;
              costo_producto:string;
              subtotal_producto:string;
}
interface interdatosfacefactura{
  cod_factura:string;
  des_cliente: string;
  cod_cliente:string;
  des_vendedor:string;
  fecha_inicio:string;
  fecha_v_factura:string;
  cod_pedido:string;
  telefono1_cliente:string;
  telefono2_cliente:string;
}
type TableRow = [ string, string, string, string, string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-gestion-preforma',
  templateUrl: './gestion-preforma.component.html',
  styleUrls: ['./gestion-preforma.component.css']
})
export class GestionPreformaComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  lbe:any = false;

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  cliente:any;
  dialogSpin:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.listarpedidosPF(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////
  listarpedidosPF(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        this.servicio.listarPedidosPFhttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
    
                    this.ELEMENT_DATA.push({
                      cod_pedido:response[i].cod_pedido,
                      cod_lista_precio:response[i].cod_lista_precio,
                      cod_cliente: response[i].cod_cliente,
                      des_cliente:response[i].des_cliente,
                      fecha_inicio:response[i].fecha_inicio,
                      estatus_pedido:response[i].estatus_pedido,
                      cod_vendedor: response[i].cod_vendedor,
                      des_vendedor:response[i].des_vendedor,
                      tipo_pedido:response[i].tipo_pedido,
                      fecha_fin:response[i].fecha_fin,
                      subtotal_costo_pedido:response[i].subtotal_costo_pedido,
                      total_costo_pedido:response[i].total_costo_pedido,
                      iva_monto:response[i].iva_monto,
                      iva_pedido:response[i].iva_pedido,
                      desc_pedido:response[i].desc_pedido,
                      cod_deposito:response[i].cod_deposito,
                      des_deposito:response[i].des_deposito,
                      descrip_deposito:response[i].descrip_deposito,
                      tasa:response[i].tasa,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                 this.displayedColumns = [
                                          'cod_pedido',
                                          'cod_lista_precio',
                                          'des_cliente',
                                          'des_vendedor',
                                          'tipo_pedido',
                                          'fecha_inicio',
                                          'facturar'
                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log(error);
            console.log(<any>error.error.message);
            if (!<any>error.error.valor) {
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition});
            }
            this.displayedColumns = [
                                    'cod_pedido',
                                    'cod_lista_precio',
                                    'des_cliente',
                                    'des_vendedor',
                                    'tipo_pedido',
                                    'fecha_inicio',
                                    'facturar'
                                    ];
            this.dataSource = new MatTableDataSource<PeriodicElementpedidos>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    
  }

 //////////////////////FUNCION DE ACCION/////////////////////////

  facturar_pedidos(obj:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {

            this.accion = "enviarpedido";
            var dialogRef = this.dialog.open(FormFacturarComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              data: {cod_pedido:obj.cod_pedido,
                    cod_lista_precio:obj.cod_lista_precio,
                    des_cliente:obj.des_cliente,
                    tipo_pedido:obj.tipo_pedido,
                    cod_deposito:obj.cod_deposito,
                    des_deposito:obj.des_deposito,
                    descrip_deposito:obj.descrip_deposito,
                    tasa:obj.tasa,
                    accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal2',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result != 'cerrar'){
      
                console.log(result);
                obj.cod_vendedor = localStorage.getItem('cod_usuario');
                obj.des_vendedor = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
                obj.accion3 = result.cod_control;
                obj.accion2 = result.comentarios;
                obj.val_def = 'F';
                obj.tipo_operacion = 'FACTURACION';
                obj.cod_usuario = localStorage.getItem('cod_usuario');
                console.log(obj);
      
                switch (obj.tipo_pedido) {
                  case 'PF':
                    this.gestionfacturas(obj);
                    break;
              
                  default:
                       this.gestionfacturas(obj);
                    break;
                }
      
              }
            });
              
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  gestionfacturas(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(async() => {
        this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
          response=>{
            var resultado = this.servicio.gestionNotasEntregahttp(xvalor).subscribe(
              response =>{
                this.dialogSpin.close();
                this.listarpedidosPF(null);
                      this.snackBar.open('Pedido facturado con exito, vaya a la sección consultas para su impresión', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition});
                        console.log(response.guia[0]);
                        this.servicio.listarGuiaDespachoFacturahttp(response.guia[0]).subscribe(
                          async response  =>{
                            console.log(response);
                          },
                          error =>{
                            this.dialogSpin.close();
                            console.log(error);
                            
        
                            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition});
                          }
                        );
              },
              error =>{
                this.dialogSpin.close();
                this.listarpedidosPF(null);
                      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition});
              }
             );
          },
          error=>{
            this.dialogSpin.close();
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
      }, 500);
       return null;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
   }

   //////////////////////FUNCION DE ACCION/////////////////////////

  async detallepedido(obj:any){

   this.dialog.open(FormFacturaComponent, {
      width: '100%',
      height:'99%',
      data: {cod_pedido: obj.cod_pedido,
              cod_lista_precio:obj.cod_lista_precio,
              cod_cliente:obj.cod_cliente,
              des_cliente:obj.des_cliente,
              iva_pedido:obj.iva_pedido,
              iva_monto:obj.iva_monto,
              desc_pedido:obj.desc_pedido,
              subtotal_costo_pedido:obj.subtotal_costo_pedido,
              total_costo_pedido:obj.total_costo_pedido,
              termini:'pedido'},
      panelClass: 'pedidos_modal'
    });
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}
}
