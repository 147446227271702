import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import * as moment from 'moment';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-precios-actualizar',
  templateUrl: './form-precios-actualizar.component.html',
  styleUrls: ['./form-precios-actualizar.component.css']
})
export class FormPreciosActualizarComponent implements OnInit {
  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto',{static:true}) cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('tasa_base',{static:true}) tasa_base:ElementRef;

  arry: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
formaTxt:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
parametro:any;
unidad:any;
dialogSpin:any;
observador:any = false;
asignado:any = [];
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
data2:any = [];
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();

  rx:any;
  constructor(
    private dialog: MatDialog, 
    private servicio:nkServices,
    private formb:FormBuilder, 
    private fb:FormBuilder,
    private hashkey:HashKeyServices,
    private point: BreakpointObserver,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    private dialogRefp: MatDialogRef<FormPreciosActualizarComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_lista_precio_producto':data.cod_lista_precio_producto,
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_cliente': data.cod_cliente,
        'des_cliente': data.des_cliente,
        'cod_producto':'',
        'precio_base':'',
        'tasa_precio':'',
        'termino':data.termino

      });
      this.formaTxt = this.fb.group({
        'val_tex':['',Validators.required],
        'val_def':[''],
      });
    }

  ngOnInit(): void {
    this.listarproducto();
    this.cod_lista1.nativeElement.value = this.data.cod_lista_precio_producto;
    this.tasa_base.nativeElement.value = this.data.tasa_precio;
    this.listarproductoListaprecio(this.data.cod_lista_precio_producto);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
      this.dataSource1.paginator = this.paginator;
  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
 async listarproducto(){
      try {
            this.codigo = [];
            this.producto = [];
            const response = await this.servicio.listarProductoshttp(await this.atrb.COD_EMPRESA).toPromise();
            this.producto = [...response];
                /* this.arry = this.forma.get('cod_producto')!.valueChanges
                                        .pipe(
                                          startWith(''),
                                          map(value => this._filterGroup(value))
                                        );
                          for (const i in response) {
                            this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                            this.codigo.push(response[i].cod_producto);
                          }
                          this.stateGroups= [{
                            letter: 'Productos',
                            names: this.producto,
                            code: this.codigo
                          }]; */
      } catch (error) {
        console.log(error);
      }
  }

  async listarproductoListaprecio(xvalor:any){
    try {
      await this.util.abrirSpinner();
      const response = await  this.servicio.listarProductoListapreciohttp(xvalor).toPromise();
      console.log(response);
          this.ELEMENT_DATA = [];
              for (const i in response) {
                if (parseFloat( await this.util.remplazo_coma_punto(response[i].precio_producto_base)) > 0) {
                this.asignado.push(response[i].cod_producto);
                this.parametro = await this.atrb.TXT;
                this.observador = true;
              }else{
                this.parametro = await this.atrb.TXT;
                this.observador = false;
              }
              if (response[i].cod_img === '' || response[i].cod_img === null) {
                response[i].cod_img = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
              }else{
                response[i].cod_img = environment.path_storage+response[i].cod_img;
              }
                this.ELEMENT_DATA.push({
                  cod_producto:response[i].cod_producto,
                  des_producto: response[i].des_producto,
                  precio_producto_base:response[i].precio_producto_base,
                  precio_producto_base_bs:response[i].precio_producto_base_bs,
                  estatus_fiscal:response[i].estatus_fiscal,
                  cod_img:response[i].cod_img,
                  observador:this.observador,
                  parametro:this.parametro
                });
              }
              this.displayedColumns = [
                                        'cod_img',
                                        'cod_producto',
                                        'des_producto',
                                        'estatus_fiscal',
                                        'precio_producto_base_bs',
                                        'precio_producto_base',
                                      ];
              this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource1.paginator = this.paginator;
              await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      this.displayedColumns = [
                                'cod_img',
                                'cod_producto',
                                'des_producto',
                                'estatus_fiscal',
                                'precio_producto_base_bs',
                                'precio_producto_base',
                              ];
        this.dataSource1 = new MatTableDataSource<any>();
        this.dataSource1.paginator = this.paginator;
        await this.notifi.customToastNotification(error.error.message);
    }
  }

  modalmodificarprecios(obj:any, text:any){
    this.accion = text;
      var dialogRef = this.dialog.open(FormModifiPrecioComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {cod_producto:obj.cod_producto,
                precio_producto_base:obj.precio_producto_base,
                precio_producto_base_bs:obj.precio_producto_base_bs,
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);
          this.recalculoPrecioProducto(result);

        }
      });
  }

  formulario():void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    this.displayedColumns = [
                            'cod_producto',
                            'des_producto',
                            'estatus_fiscal',
                            'precio_producto_base',
                            'precio_producto_base_bs',
                            'remov_elem'
                            ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<PeriodicElementfacturas>(this.ELEMENT_DATA);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

 async addElement() {
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      var inputId = document.querySelector('#precio_base_id') as HTMLInputElement;
      
      let arry = [];
      let arry2 = [];
    this.forma.value.precio_base = inputId.value;
    this.forma.value.tasa_precio = this.tasa_base.nativeElement.value;
    console.log(inputId.value);
    if (parseFloat(await this.util.remplazo_coma_punto(inputId.value)) >= 0 &&
                         this.tasa_base.nativeElement.value !== '' &&
                         this.forma.value.cod_producto !== '' ) {
                          console.log(inputId.value);
      var cod = this.forma.value.cod_producto;
      var valor:any = {cod_producto:cod}
      const response = await this.servicio.buscarPRODUCTOBttp(valor).toPromise();
      console.log(inputId.value);
          this.forma.patchValue({
            cod_producto:''
          });
          this.precio_base.nativeElement.value = '';
          if (response[0].cod_img === '' || response[0].cod_img === null) {
            response[0].cod_img = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
          }else{
            response[0].cod_img = environment.path_storage+response[0].cod_img
          }
          await this.util.cerrarSpinner();
        if (this.ELEMENT_DATA.length <= 0) {
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];
         await this.listarproducto();
          this.ELEMENT_DATA.push({
            cod_img:response[0].cod_img,
            cod_producto:response[0].cod_producto,
            des_producto:response[0].des_producto,
            precio_producto_base:this.forma.value.precio_base,
            precio_producto_base_bs:this.forma.value.precio_bs,
            estatus_fiscal:response[0].estatus_fiscal
          });
          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
        }else{
          console.log(inputId.value);
            for (const i in this.ELEMENT_DATA) {
             arry.push(this.ELEMENT_DATA[i].cod_producto);
            }
            let index = arry.includes(response[0].cod_producto);
            if (!index) {
              console.log(this.forma.value.precio_base);
             let xvalor1 = parseFloat(await this.util.remplazo_coma_punto(this.forma.value.precio_base))
                         * parseFloat(await this.util.remplazo_coma_punto(this.tasa_base.nativeElement.value));
             console.log(xvalor1);
              this.codigo = [];
              this.producto = [];
                this.parametro = await this.atrb.TXT;
                this.observador = true;
               await this.listarproducto();
                this.ELEMENT_DATA.push({
                  cod_img:response[0].cod_img,
                  cod_producto:response[0].cod_producto,
                  des_producto:response[0].des_producto,
                  precio_producto_base:this.forma.value.precio_base,
                  precio_producto_base_bs: await this.util.agregar_coma_punto(xvalor1),
                  estatus_fiscal:response[0].estatus_fiscal,
                  observador:this.observador,
                  parametro:this.parametro
                });
                this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
            }else{
              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];
             await this.listarproducto();
              await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.prod_select_ya);
            }
        }
    }else{
          await this.util.cerrarSpinner();
        if (parseFloat(this.precio_base.nativeElement.value) <= 0 || isNaN(this.precio_base.nativeElement.value) || this.precio_base.nativeElement.value === "") {
          await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.precio_mayor_0);
        }
        if (parseFloat(this.tasa_base.nativeElement.value) <= 0 || isNaN(this.tasa_base.nativeElement.value) || this.tasa_base.nativeElement.value === "") {
          await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.tasa_mayor_0);
        }
        if (this.forma.value.cod_producto === '') {
          await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.debe_seleccionar_prod);
        }
    }
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async recalculoPrecioProducto(xvalor:any){
try {
    if (this.tasa_base.nativeElement.value != '' && parseFloat(this.tasa_base.nativeElement.value) > 0) {
      let tasa = parseFloat( await this.util.remplazo_coma_punto(this.tasa_base.nativeElement.value));
      for (const i in this.ELEMENT_DATA) {
        if (this.ELEMENT_DATA[i].cod_producto === xvalor.cod_producto) {
            var precio_base_nuevo = parseFloat( await this.util.remplazo_coma_punto(xvalor.x_valor));
            var precio_bs_nuevo = (precio_base_nuevo)*(tasa);
            this.ELEMENT_DATA[i].observador = true;
            this.ELEMENT_DATA[i].precio_producto_base = xvalor.x_valor;
            this.ELEMENT_DATA[i].precio_producto_base_bs = await this.util.agregar_coma_punto(await this.util.numeroFlotante(precio_bs_nuevo));
        }
      }
        await this.util.cerrarSpinner();
        await this.notifi.success(await this.atrb.MENSAJES_FIJOS.precio_actualizado);
    }else{
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.tasa_base_0);
    }
} catch (error) {
  await this.util.cerrarSpinner();
  console.log(error);
}
}

async cambioPrecio(){
  try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null);
    await this.util.cerrarSpinner();
    if (this.tasa_base.nativeElement.value != '') {
      this.accion = "enviarprecios";
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result != 'cerrar'){
          await this.util.cerrarSpinner();
          this.cambioPrecioBsPorTasa();
        }else{
          await this.util.cerrarSpinner();
        }
      });
    }else{
        await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.tasa_base_0);
    }
  } catch (error) {
    console.log(error);
  }
  }

async cambioPrecioBsPorTasa(){
  try {
    await this.util.abrirSpinner();
     let bandera = false;
      if (this.tasa_base.nativeElement.value != '') {
        let tasa = parseFloat(await this.util.remplazo_coma_punto(this.tasa_base.nativeElement.value));

        for (const i in this.ELEMENT_DATA) {
            var precio_base_nuevo = parseFloat(await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].precio_producto_base));
            if (tasa > 0) {
              if (precio_base_nuevo > 0) {
                var precio_bs_nuevo = (tasa)*(precio_base_nuevo);
              this.ELEMENT_DATA[i].observador = true;
              this.ELEMENT_DATA[i].precio_producto_base_bs =  await this.util.numeroFlotante(precio_bs_nuevo);
              bandera = true;
              }
            }else{
                await this.util.cerrarSpinner();
                await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.tasa_base_0);
              break;
            }
        } 
        if (bandera) {
          await this.util.cerrarSpinner();
            await this.notifi.success(this.atrb.MENSAJES_FIJOS.precio_recalculado);
        }else{
          await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.precio_almenos_producto);
        }
      }else{
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.tasa_base_0);
      }
  } catch (error) {
    await this.util.cerrarSpinner();
    console.log(error)
  }
}

cantidad_item() {
  try {
    return this.ELEMENT_DATA.length;
  } catch (error) {
  }
}

cantidad_asignado() {
  try {
    return this.asignado.length;
  } catch (error) {
  }
}

async modalrespuesta(){
  try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null);
    await this.util.cerrarSpinner();
    if (parseFloat(await this.util.remplazo_coma_punto(this.tasa_base.nativeElement.value)) > 0) {
      this.accion = "enviarprecios";
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'auto',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result != 'cerrar'){
          let split;
          for (const i in this.ELEMENT_DATA) {
                split = this.ELEMENT_DATA[i].cod_img.split(environment.path_storage);
                this.ELEMENT_DATA[i].cod_img = split[1];
          } 
          this.forma.value.producto_pedido = this.ELEMENT_DATA;
          this.forma.value.tasa_precio = this.tasa_base.nativeElement.value;
          this.forma.value.operacion = this.atrb.ACCION_ACTUALIZAR;
          await this.util.cerrarSpinner();
          this.dialogRefp.close(this.forma.value);
          /* let resultado = this.eliminarunidad(result.id); */
        }else{
          await this.util.cerrarSpinner();
        }
      });
    }else{
      await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.tasa_base_0);
    }
  } catch (error) {
    await this.util.cerrarSpinner();
    console.log(error);
  }
}

async format(event: any, valor:any, termino:any)
  {
    try {
      var num;
      if (termino.toUpperCase() !== this.atrb.BASE.toUpperCase() && termino.toUpperCase() !== this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
      if (termino.toUpperCase() === this.atrb.BASE.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        num = inputId.value.replace(/\D/g,'');
        if(!isNaN(parseFloat(num))){
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/\D/g, "")
                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          inputId.value = num;
          }else{ 
            if(await this.atrb.NUM.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
              await this.util.notificacionErrorApp(this.atrb.MENSAJES_FIJOS.solo_numeros);
          }
            inputId.value = inputId.value.replace(/[^\d\.]*/g,'');
          }
      }
      if (termino.toUpperCase() === this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
    } catch (error) {
      console.log(error);
    }
  }
 async cerrarmodal(){
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result != 'cerrar') {
        await this.util.cerrarSpinner();
        this.dialogRefp.close('cerrar');
      }else{
        await this.util.cerrarSpinner();
      }
    });
  }

  dowload_store(): void {
    this.accion = 'enviarordenprod';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.filter_date_print();
      }

    });
  }

  filter_date_print(){
    try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          this.dialogSpin.close();
          var xobjeto_ = [];
          var fecha = false;
          for (const i in this.ELEMENT_DATA) {
         
            xobjeto_.push(this.ELEMENT_DATA[i]);
                           
        }

        console.log(xobjeto_,'pasos');

  let workbook = new Workbook();

  let worksheet = workbook.addWorksheet(this.data.cod_lista_precio_producto);

  let header=[
        "cod_producto",
        "des_producto",
        "precio_producto_base",
        "precio_producto_base_bs",
        "estatus_fiscal",
        "observador",
   ];
  let headerRow = worksheet.addRow(header);

            for (let x1 of xobjeto_)
          {
                  let x2=Object.keys(x1);

                  let temp=[]
                  for(let y of x2)
                  {
                  temp.push(x1[y])
                  }
                  worksheet.addRow(temp)
          }
      var fecha_soporte = moment().format('DD/MM/YYYY');
      var hora_soporte = moment().format('hh:mm:ss a');
      let fname="Lista de precios N°: '"+this.data.cod_lista_precio_producto+"'"+' '+ fecha_soporte+' '+hora_soporte;

      workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
      });
        }, 500);
        
    } catch (error) {

    }
  }

  onFileChange(evt:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        const target : DataTransfer = <DataTransfer>(evt.target);

        if(target.files.length !== 1) throw new Error('No se pueden seleccionar multiples archivos');
    
        const reader : FileReader = new FileReader();
    
        reader.onload = (e: any) => {
         const bstr : string = e.target.result;
    
         const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'})
    
         const wsname : string = wb.SheetNames[0];
    
         const ws : XLSX.WorkSheet = wb.Sheets[wsname];
    
         this.data2 = (XLSX.utils.sheet_to_json(ws));
          this.ELEMENT_DATA = [];

         if(this.data2.length > 0 ){
           for (const i in this.data2) {
            
             var precio_final = parseFloat(this.data2[i].precio_producto_base.toString())  * parseFloat(this.tasa_base.nativeElement.value.replace('.','')
                                                                                                      .replace('.','')
                                                                                                      .replace('.','')
                                                                                                      .replace(',','.'));
           
                                    this.data2[i].precio_producto_base_bs = precio_final.toFixed(2)
                                                                                        .replace(/\D/g, "")
                                                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
              this.data2[i].precio_producto_base = parseFloat(this.data2[i].precio_producto_base).toFixed(2)
                                                                                      .replace(/\D/g, "")
                                                                                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
             this.ELEMENT_DATA.push(this.data2[i]);
           }
           this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
         }
        };
        reader.readAsBinaryString(target.files[0]);
      }, 500);
    } catch (error) {
    
    }
  }

  async getTxtConfig(xobjeto:any){
    try {
       await this.util.abrirSpinner();
       let inputId = document.querySelector(`#${xobjeto.cod_producto}`) as HTMLInputElement;
       if (inputId === null || inputId === undefined) {
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: { 
                accion: ''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          if(result != 'cerrar'){
            for (const i in this.ELEMENT_DATA) {
              if (this.ELEMENT_DATA[i].cod_producto.toUpperCase() === xobjeto.cod_producto.toUpperCase()) {
                this.ELEMENT_DATA[i].precio_producto_base = await this.atrb.MONTO_0;
                this.ELEMENT_DATA[i].precio_producto_base_bs = await this.atrb.MONTO_0;
                this.ELEMENT_DATA[i].observador = false;
              }
            }
            this.displayedColumns = [
                               'cod_img',
                               'cod_producto',
                               'des_producto',
                               'estatus_fiscal',
                               'precio_producto_base_bs',
                               'precio_producto_base',
            ];
            this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource1.paginator = this.paginator;
            await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.agregar_valor);
          } else{
            await this.util.cerrarSpinner();
          }
        });
        return;
       }
       if (parseFloat(await this.util.remplazo_coma_punto(inputId.value)) <= 0 ||
           isNaN(parseFloat(await this.util.remplazo_coma_punto(inputId.value)))) {
         var dialogRef = this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'100vh',
           data: { 
                 accion: ''},
                 disableClose: true,
                 panelClass: 'pedidos_modal',
                 backdropClass: "bdrop" 
         });
         dialogRef.afterClosed().subscribe(async result => {
           if(result != 'cerrar'){
             for (const i in this.ELEMENT_DATA) {
               if (this.ELEMENT_DATA[i].cod_producto.toUpperCase() === xobjeto.cod_producto.toUpperCase()) {
                 this.ELEMENT_DATA[i].precio_producto_base = await this.atrb.MONTO_0;
               }
             }
             this.displayedColumns = [
                                'cod_img',
                                'cod_producto',
                                'des_producto',
                                'estatus_fiscal',
                                'precio_producto_base_bs',
                                'precio_producto_base',
             ];
             this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource1.paginator = this.paginator;
             await this.util.cerrarSpinner();
             await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.agregar_valor);
           } else{
             await this.util.cerrarSpinner();
           }
         });
         return;
       }
       await this.servicio.consultar_sesionhttp(null).toPromise();
       await this.util.cerrarSpinner();
         var dialogRef = this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'100vh',
           data: { 
                 accion:''},
                 disableClose: true,
                 panelClass: 'pedidos_modal',
                 backdropClass: "bdrop" 
         });
         dialogRef.afterClosed().subscribe(async result => {
           await this.util.abrirSpinner();
           if(result != 'cerrar'){
            xobjeto.x_valor = inputId.value;
            this.recalculoPrecioProducto(xobjeto);
           } else{
             await this.util.cerrarSpinner();
           }
         });
    } catch (error) {
     console.log(error);
     await this.util.cerrarSpinner();
     await this.notifi.customToastNotification(error.error.message);
    }
    }
  async remove(val:any){
      try {
                await this.util.abrirSpinner();
                await this.servicio.consultar_sesionhttp(null).toPromise();
                await this.util.cerrarSpinner();
                var dialogRef = this.dialog.open(FormmMsgComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'auto',
                  data: { 
                        accion:''},
                        disableClose: true,
                        panelClass: 'pedidos_modal',
                        backdropClass: "bdrop" 
                });
                dialogRef.afterClosed().subscribe(async result => {
                  await this.util.abrirSpinner();
                  if(result !== 'cerrar'){
                    this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
                    this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                    this.table.renderRows();
                    await this.util.cerrarSpinner();
                    await this.notifi.success(await this.atrb.MENSAJES_FIJOS.quitar_producto_lista);
                  } else{
                    await this.util.cerrarSpinner();
                  }
                });
      } catch (error) {
        console.log(error);
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
      } 
    }
}
