<div id="modal_carrito" class=" container-fluid" style="background-color:steelblue; border-radius: 1rem;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid steelblue; border-radius: 1rem 1rem 0 0;">
            <div id="content_1" class=" col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Cliente</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_cliente" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="  col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Factura</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput placeholder="" formControlName="cod_factura" #num autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>

            </div>
            <div class="  col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Devolución</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput placeholder="" formControlName="cod_dev" #num_dev autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>

            </div>
            <div class="  col-md-3 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GENERAR NOTA DE CREDITO" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                        <mat-icon >save</mat-icon>
                    </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row" style=" border: 2px solid steelblue;">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100">
                <table #table mat-table [dataSource]="dataSource1" class=" table-secondary  text-center table-striped  table-condensed table-hover">
                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(20, 80, 99); color: #fff;">{{cantidad_item()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="lista_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Lista precios N°</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">CANTIDAD DE PRODUCTOS EN EL PEDIDO </td>
                    </ng-container>

                    <ng-container matColumnDef="lote_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Lote</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.lote_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">CANTIDAD DE PRODUCTOS EN EL PEDIDO </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.des_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">CANTIDAD DE PRODUCTOS EN EL PEDIDO </td>
                    </ng-container>
                    <ng-container matColumnDef="cod_dev">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Devolución</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px;"><b>{{element.cod_dev}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; background-color: rgb(20, 80, 99); color: #fff;"><b>{{element.cant_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">{{cantidad_productos()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="contador">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Contador</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; background-color: rgb(20, 80, 99);; color: #fff;"><b>{{element.contador}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">{{cantidad_dev()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dev_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Devolución</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; background-color: rgb(20, 80, 99); color: #fff;"><b>{{element.dev_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">{{cantidad_dev()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="costo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Costo unidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.costo_producto}} </b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">{{costo_producto()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Tipo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tipo_producto}}</b> </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">CANTIDAD DE PRODUCTOS EN EL PEDIDO </td>
                    </ng-container>

                    <ng-container matColumnDef="tamaño_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Tamaño</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tamano_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">CANTIDAD DE PRODUCTOS EN EL PEDIDO </td>
                    </ng-container>

                    <ng-container matColumnDef="subtotal_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Sub-total</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.subtotal_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">{{sub_total_producto()}} </td>
                    </ng-container>
                    <ng-container matColumnDef="estatus_fiscal">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Estatus fiscal</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.estatus_fiscal}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;"></td>
                    </ng-container>

                    <ng-container matColumnDef="descuento">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Descuento</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.desc_prod}} % </b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{descuento()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="monto_prod_descuento">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Monto descuento</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.desc_prod_monto}} Bs </b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{descuento_monto()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dev_subtotal_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Dev Sub-total</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.dev_subtotal_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;">{{cantidad_dev_sub()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(20, 80, 99); padding: 5px; color: #fff;">
                            <h5><strong>Seleccionar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <section style="margin-top: 10px;">
                                <div class="example-button-container" class="text-center">
                                    <mat-checkbox [id]="element.cod_producto" (change)="tomar_data($event.checked, element)" class="example-margin"></mat-checkbox>
                                </div>
                            </section>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(20, 80, 99); color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>

            </div>

        </div>
        <input style="font-size: 12px;" style="display: none;" placeholder="" formControlName="cod_lista_precio" #cod_lista_precio autocomplete="off" readonly>
        <input style="font-size: 12px;" style="display: none;" #iva placeholder="" formControlName="iva_pedido" autocomplete="off" readonly>
        <div id="carrito_p3" class="row mat-elevation-z8" style=" border: 2px solid steelblue; border-radius: 0 0 1rem 1rem;">
            <div class="col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Items</mat-label>
                        <input style="font-size: 12px; color: #fff;" #items matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Descuento</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="6" #desc_pedido matInput placeholder="" formControlName="desc_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">%</span>
                    </mat-form-field>
                </div>

            </div>

            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Exento</mat-label>
                        <input style="font-size: 12px; color: #fff;" #exento_monto matInput placeholder="Sub-total:" formControlName="monto_exento" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Sub-total</mat-label>
                        <input style="font-size: 12px; color: #fff;" #subtotal_pedido matInput placeholder="Sub-total:" formControlName="subtotal_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">I.V.A</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="I.V.A:" formControlName="iva_monto" autocomplete="off" #iva_monto readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1 ">
                        <mat-label style="color: #fff; font-size: 11px;">Descuento total</mat-label>
                        <input style="font-size: 12px; color: #fff;" #desc_pedido_monto matInput placeholder="" formControlName="desc_pedido_monto" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(18, 110, 138);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Total</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" #total_pedido matInput placeholder="Total:" formControlName="total_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs. </span>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </form>

</div>