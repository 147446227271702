<div id="modal_carrito" class=" container-fluid" style="background-color:#2b69dd; border-radius: 1rem;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #2b69dd; background-color: #2b69dd; border-radius: 1rem 1rem 0 0;">
            <div id="content_1" class=" col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #2b69dd; background-color: #2b69dd">
                    <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff; font-size: 11px;">Codigo documento</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="Cliente:" formControlName="cod_documento" autocomplete="off" readonly>
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field> 
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #2b69dd; background-color: #2b69dd;">
                   <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff; font-size: 11px;">Cod de la Devolución</mat-label>
                       <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput placeholder="" formControlName="cod_solicitud_devolucion" #num autocomplete="off" readonly>
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class=" col-md-5 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #2b69dd">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255); font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_boton1" class="col-md-1 px-0 py-2 h-100">
                <div class="mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()"  matTooltip="PROCESAR DEVOLUCION" style=" color:rgb(255, 255, 255); background-color: rgb(25, 0, 255)">
                        <mat-icon>save</mat-icon>
                    </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="salir">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div id="carrito_p2" class="row" style=" border: 2px solid #2b69dd; background-color: #2b69dd;">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive  h-100">
                <table #table mat-table [dataSource]="dataSource" class=" table-primary  text-center table-striped table-condensed table-hover">
                   
                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #2f3033; padding: 5px; color: #fff;">
                            <h5><strong>Codigo Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #2f3033; color: #fff;"><!-- CANTIDAD DE PRODUCTOS EN EL PEDIDO --> </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #2f3033; padding: 5px; color: #fff;">
                            <h5><strong>Descripcion Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.des_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #2f3033; color: #fff;"> CANTIDAD DE PRODUCTOS DEVUELTOS </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #2f3033; padding: 5px; color: #fff;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #2f3033; color: #fff;">{{cantidad_productos()}} </td>  
                    </ng-container>

                    <ng-container matColumnDef="dev_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #2f3033; padding: 5px; color: #fff;">
                            <h5><strong>Devolución</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"  class="text-center" >
                        <button class="rounded-pill"  matTooltip="DEVOLUCIONES" [id]="element.cod_producto" mat-raised-button (click)="formulas(element,'dev')"  style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);"> <!-- *ngIf="create" -->
                            <mat-icon >pie_chart</mat-icon>
                        </button>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #2f3033; color: #fff;">  </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
    </form>
</div>





