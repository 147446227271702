import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { nkServices } from '../../servicios/app.nkservicios.services';

@Component({
  selector: 'app-form-direcciones',
  templateUrl: './form-direcciones.component.html',
  styleUrls: ['./form-direcciones.component.css']
})
export class FormDireccionesComponent implements OnInit {
  hide = true;
  dir:any;
  mail:any;
  montos:any = [];
  detalle:any = [];
  tel:any = [];
  fech:any = [];
  tiempo:any = [];
  tiempo_ordenes:any= [];
  tiempo_ord_pt:any= [];
  tiempo_ord_mp:any= [];
  persona_m:any=[];
  tiempo_m:any= [];
  comentarios_m:any = [];
  factura_m:any = [];
  descrip:any;
  descrips:any;
  hora:any=[];
  nombre:any;
  forma:FormGroup;
  accion:any;
  unidad:any;
  email = new FormControl('', [Validators.required, Validators.email]);
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

    constructor(private servicio:nkServices, fb:FormBuilder,
      public dialogRef: MatDialogRef<FormDireccionesComponent>,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data);
        switch (data.termino) {
          case 'dir':
            this.detalle = false;
            this.tel = false;
            this.mail = false;
            this.fech = false;
            this.montos = false;
            this.descrip = false;
            this.tiempo = false;
            this.tiempo_ordenes= false;
            this.tiempo_ord_pt = false;
            this.tiempo_ord_mp = false;
            this.tiempo_m = false;
            this.comentarios_m = false;
            this.persona_m = false;
            this.factura_m = false;
            this.detalle = false;
            this.dir = data.dir_cliente;
            break;
          case 'mail':
            this.tel = false;
            this.dir = false;
            this.fech = false;
            this.montos = false;
            this.descrip = false;
            this.tiempo = false;
            this.tiempo_ordenes= false;
            this.tiempo_ord_pt = false;
            this.tiempo_ord_mp = false;
            this.tiempo_m = false;
            this.comentarios_m = false;
            this.persona_m = false;
            this.factura_m = false;
            this.detalle = false;
            this.mail = data.correo_cliente;
              break;
          case 'tel':
            this.dir = false;
            this.mail = false;
            this.fech = false;
            this.montos = false;
            this.descrip = false;
            this.tiempo = false;
            this.tiempo_ordenes= false;
            this.tiempo_ord_pt = false;
            this.tiempo_ord_mp = false;
            this.tiempo_m = false;
            this.comentarios_m = false;
            this.persona_m = false;
            this.factura_m = false;
            this.detalle = false;
              this.tel.push(data.telefono1_cliente);
              this.tel.push(data.telefono2_cliente);
              break;
          case 'fech':
            this.dir = false;
            this.mail = false;
            this.tel = false;
            this.montos = false;
            this.descrip = false;
            this.tiempo = false;
            this.tiempo_ordenes= false;
            this.tiempo_ord_pt = false;
            this.tiempo_ord_mp = false;
            this.tiempo_m = false;
            this.comentarios_m = false;
            this.persona_m = false;
            this.factura_m = false;
            this.detalle = false;
                this.fech.push(data.fecha_inicio);
                this.fech.push(data.fecha_r_factura);
                this.fech.push(data.fecha_v_factura);
                this.fech.push(data.fecha_c_factura);
                this.hora.push(data.hora_inicio);
                this.hora.push(data.hora_recepcion);
                this.hora.push(data.hora_vencimiento);
                console.log(this.fech);
              break;
              case 'tiempo':
                this.dir = false;
                this.fech = false;
                this.mail = false;
                this.tel = false;
                this.montos = false;
                this.descrip = false;
                this.tiempo_ordenes= false;
                this.tiempo_ord_pt = false;
                this.tiempo_ord_mp = false;
                this.tiempo_m = false;
                this.comentarios_m = false;
                this.persona_m = false;
                this.factura_m = false;
                this.detalle = false;
                var ticket = {cod_ticket: data.cod_ticket}
                this.servicio.listarTicketSoporteAdminhttp(ticket).subscribe(
                  response =>{
                    console.log(response);
                    this.tiempo.push(data.fecha_ini);
                    this.tiempo.push(data.fecha_fin);
                    this.tiempo.push(response[0].duracion_soporte);
                  },
                  error =>{
                    console.log(error);
                  }
                );
                
                    
                  break;
                  case 'tiempo_ord_pt':
                this.dir = false;
                this.fech = false;
                this.mail = false;
                this.tel = false;
                this.montos = false;
                this.descrip = false;
                this.tiempo_ordenes= false;
                this.tiempo = false;
                this.tiempo_ord_mp = false;
                this.tiempo_m = false;
                this.comentarios_m = false;
                this.persona_m = false;
                this.factura_m = false;
                this.detalle = false;
                var orden = {cod_orden_producto: data.cod_orden_producto}
                this.servicio.listarTiempoOrdenProdhttp(orden).subscribe(
                  response =>{
                    console.log(response);
                    this.tiempo_ord_pt.push(data.fecha_ini);
                    
                    this.tiempo_ord_pt.push(response[0].duracion_soporte);
                  },
                  error =>{
                    console.log(error);
                  }
                );
                
                    
                  break;

                  case 'tiempo_ord_mp':
                this.dir = false;
                this.fech = false;
                this.mail = false;
                this.tel = false;
                this.montos = false;
                this.descrip = false;
                this.tiempo_ordenes= false;
                this.tiempo = false;
                this.tiempo_ord_pt = false;
                this.tiempo_m = false;
                this.comentarios_m = false;
                this.persona_m = false;
                this.factura_m = false;
                this.detalle = false;
                var orden_mpp = {cod_orden_producto_mpp: data.cod_orden_producto_mpp}
                this.servicio.listarTiempoOrdenProdMpphttp(orden_mpp).subscribe(
                  response =>{
                    console.log(response);
                    this.tiempo_ord_mp.push(data.fecha_ini);
                    
                    this.tiempo_ord_mp.push(response[0].duracion_soporte);
                  },
                  error =>{
                    console.log(error);
                  }
                );
                
                    
                  break;
                  case 'tiempo_ordenes':
                this.dir = false;
                this.fech = false;
                this.mail = false;
                this.tel = false;
                this.montos = false;
                this.descrip = false;
                this.tiempo = false;
                this.tiempo_ord_pt = false;
                this.tiempo_ord_mp = false;
                this.tiempo_m = false;
                this.comentarios_m = false;
                this.persona_m = false;
                this.factura_m = false;
                this.detalle = false;
                
                    this.tiempo_ordenes.push(data.fecha_inicio);
                    this.tiempo_ordenes.push(data.fecha_fin);
                    this.tiempo_ordenes.push(data.duracion_orden);
         
                  break;
                  case 'tiempo_m':
                      this.dir = false;
                      this.fech = false;
                      this.mail = false;
                      this.tel = false;
                      this.montos = false;
                      this.descrip = false;
                      this.tiempo = false;
                      this.tiempo_ordenes= false;
                      this.tiempo_ord_pt = false;
                      this.tiempo_ord_mp = false;
                      this.comentarios_m = false;
                      this.persona_m = false;
                      this.factura_m = false;
                      this.detalle = false;
                          this.tiempo_m.push(data.fecha_ini);
         
                  break;
                  case 'comentarios_m':
                      this.dir = false;
                      this.fech = false;
                      this.mail = false;
                      this.tel = false;
                      this.montos = false;
                      this.descrip = false;
                      this.tiempo = false;
                      this.tiempo_ordenes= false;
                      this.tiempo_ord_pt = false;
                      this.tiempo_ord_mp = false;
                      this.tiempo_m = false;
                      this.persona_m = false;
                      this.factura_m = false;
                      this.detalle = false;
                          this.comentarios_m.push(data.comentarios);
         
                  break;
                  case 'persona_m':
                      this.dir = false;
                      this.fech = false;
                      this.mail = false;
                      this.tel = false;
                      this.montos = false;
                      this.descrip = false;
                      this.tiempo = false;
                      this.tiempo_ordenes= false;
                      this.tiempo_ord_pt = false;
                      this.tiempo_ord_mp = false;
                      this.tiempo_m = false;
                      this.comentarios_m = false;
                      this.factura_m = false;
                      this.detalle = false;
                  
                          this.persona_m.push(data.usuario);
                          this.persona_m.push(data.cliente);
         
                  break;
                  case 'factura_m':
                      this.dir = false;
                      this.fech = false;
                      this.mail = false;
                      this.tel = false;
                      this.montos = false;
                      this.descrip = false;
                      this.tiempo = false;
                      this.tiempo_ordenes= false;
                      this.tiempo_ord_pt = false;
                      this.tiempo_ord_mp = false;
                      this.tiempo_m = false;
                      this.comentarios_m = false;
                      this.persona_m = false;
                      this.detalle = false;
                  
                          this.factura_m.push(data.cod_factura);
                          this.factura_m.push(data.cod_nota);
                          this.factura_m.push(data.cod_pedido);
         
                  break;
              case 'fech_nota':

            this.dir = false;
            this.mail = false;
            this.tel = false;
            this.montos = false;
            this.descrip = false;
            this.tiempo = false;
            this.tiempo_ordenes= false;
            this.tiempo_ord_pt = false;
            this.tiempo_ord_mp = false;
            this.tiempo_m = false;
            this.comentarios_m = false;
            this.persona_m = false;
            this.factura_m = false;
            this.detalle = false;
                this.fech.push(data.fecha_inicio);
                this.fech.push(data.fecha_recep);
                this.fech.push(data.fecha_venc);
                this.hora.push(data.hora_inicio);
                this.hora.push(data.hora_recep);
                this.hora.push(data.hora_venc);
                console.log(this.fech);
              break;
          case 'dirprov':
                  this.tel = false;
                  this.mail = false;
                  this.fech = false;
                  this.montos = false;
                  this.descrip = false;
                  this.tiempo = false;
                  this.tiempo_ord_pt = false;
                  this.tiempo_ord_mp = false;
                  this.tiempo_ordenes= false;
                  this.tiempo_m = false;
                  this.comentarios_m = false;
                  this.persona_m = false;
                  this.factura_m = false;
                  this.detalle = false;
                    this.dir = data.dir_prov;
              break;
          case 'mailprov':
                  this.tel = false;
                  this.dir = false;
                  this.fech = false;
                  this.montos = false;
                  this.descrip = false;
                  this.tiempo = false;
                  this.tiempo_ordenes= false;
                  this.tiempo_ord_pt = false;
                  this.tiempo_ord_mp = false;
                  this.tiempo_m = false;
                  this.comentarios_m = false;
                  this.persona_m = false;
                  this.factura_m = false;
                  this.detalle = false;
                  this.mail = data.correo_prov;
              break;
          case 'telprov':
                  this.dir = false;
                  this.mail = false;
                  this.fech = false;
                  this.montos = false;
                  this.descrip = false;
                  this.tiempo = false;
                  this.tiempo_ordenes= false;
                  this.tiempo_ord_pt = false;
                  this.tiempo_ord_mp = false;
                  this.tiempo_m = false;
                  this.comentarios_m = false;
                  this.persona_m = false;
                  this.factura_m = false;
                  this.detalle = false;
                    this.tel.push(data.telefono1_prov);
                    this.tel.push(data.telefono2_prov);
              break;
          case 'montos':
                  this.dir = false;
                  this.mail = false;
                  this.fech = false;
                  this.tel = false;
                  this.descrip = false;
                  this.tiempo = false;
                  this.tiempo_ordenes= false;
                  this.tiempo_ord_pt = false;
                  this.tiempo_ord_mp = false;
                  this.tiempo_m = false;
                  this.comentarios_m = false;
                  this.persona_m = false;
                  this.factura_m = false;
                  this.detalle = false;
                  this.montos.push(data.monto_pendiente);
                  this.montos.push(data.monto_contador);
                    this.montos.push(data.monto_cobrado);

              break;
              case 'descrip':
                this.dir = false;
                this.mail = false;
                this.fech = false;
                this.tel = false;
                this.montos = false;
                this.tiempo = false;
                this.tiempo_ordenes= false;
                this.tiempo_ord_pt = false;
                this.tiempo_ord_mp = false;
                this.tiempo_m = false;
                this.comentarios_m = false;
                this.persona_m = false;
                this.factura_m = false;
                this.detalle = false;
                this.descrips= data.descripcion_soporte;

            break;
            case 'detalle':
              this.dir = false;
              this.mail = false;
              this.fech = false;
              this.tel = false;
              this.montos = false;
              this.tiempo = false;
              this.tiempo_ordenes= false;
              this.tiempo_ord_pt = false;
              this.tiempo_ord_mp = false;
              this.tiempo_m = false;
              this.comentarios_m = false;
              this.persona_m = false;
              this.factura_m = false;
              this.descrip = false;
              this.descrips = false;

              this.detalle = this.data.objeto;

          break;
          default:
            break;
        }

      }

    ngOnInit(): void {
    }
    cerrarmodal(): void {
      this.dialogRef.close('cerrar');
    }

}
