import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {Observable} from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { FormNotaCreditoComponent } from 'src/app/componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormNotaCreditoAdminComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import { FormReportesComponent } from 'src/app/componentes/shared/modal/form-reportes/form-reportes.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormReportesVentasComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas/form-reportes-ventas.component';
import { FormNotaCreditoAdminSingleComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin-single/form-nota-credito-admin-single.component';
import { FormNotaCreditoAdminBaseComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin-base/form-nota-credito-admin-base.component';
import * as moment from 'moment';
import { ConnectionServices } from '../../../../../componentes/shared/servicios/app.connection.services';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { FormReportesVentasNotasCreditoComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas-notas-credito/form-reportes-ventas-notas-credito.component';
import axios from 'axios';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';
interface interfacefactura{
  lote_producto: string;
  cod_producto:string;
  des_producto:string;
  cant_producto:string;
  costo_producto:string;
  subtotal_producto:string;
}
interface interfacefactura2{
  des_concepto: string;
  subtotal_concepto:string;
}
interface interdatosfacefactura{
cod_nota:string;
des_cliente: string;
cod_cliente:string;
des_vendedor:string;
fecha_inicio:string;
fecha_v_factura:string;
cod_pedido:string;
telefono1_cliente:string;
telefono2_cliente:string;
}
type TableRow = [string, string, string, string, string, string];
type TableRow2 = [string, string];

@Component({
  selector: 'app-consultar-preforma',
  templateUrl: './consultar-preforma.component.html',
  styleUrls: ['./consultar-preforma.component.css']
})
export class ConsultarPreformaComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  fecha1:any;
  fecha2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices,
              public dialog: MatDialog, 
              private snackBar: MatSnackBar,
              private pdf : PdfServices,
              private router:Router,
              private conexion:ConnectionServices) {


    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                cod_menu: localStorage.getItem('sub_main'),
                cod_sesion: localStorage.getItem('cod_sesion'),
                dir_ip_client: localStorage.getItem('dir_ip'),
              sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }
  ngOnInit(): void {
    this.listarfactura(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }
  /////////////////////////////////////////////////////////////////////
 listarfactura(msj:any){
 
    try {
     
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        this.servicio.listarPFhttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.respuesta) {

                    this.ELEMENT_DATA.push({
                      cod_nota:response.respuesta[i].cod_nota,
                      cod_pedido: response.respuesta[i].cod_pedido,
                      cod_lista_precio:response.respuesta[i].cod_lista_precio,
                      cod_cliente:response.respuesta[i].cod_cliente,
                      des_cliente:response.respuesta[i].des_cliente,
                      fecha_inicio: response.respuesta[i].fecha_inicio,
                      hora_inicio:response.respuesta[i].hora_inicio,
                      fecha_r_factura:response.respuesta[i].fecha_r_factura,
                      hora_recepcion:response.respuesta[i].hora_recepcion,
                      fecha_v_factura:response.respuesta[i].fecha_v_factura,
                      hora_vencimiento:response.respuesta[i].hora_vencimiento,
                      credito_dias_clientes:response.respuesta[i].credito_dias_clientes,
                      estatus_nota:response.respuesta[i].estatus_nota,
                      monto_base: response.respuesta[i].monto_pendiente_factura,
                      monto_pendiente: response.respuesta[i].monto_base_factura,
                      monto_cobrado:response.respuesta[i].monto_cobrado_factura,
                      remanente_cliente:response.respuesta[i].remanente_cliente,
                      comprobante_factura:response.respuesta[i].comprobante_factura,
                      fecha_c_factura:response.respuesta[i].fecha_c_factura,
                      monto_contador: response.respuesta2[i].total_factura,
                      accion:'',
                      accion2:'',
                      accion3:'',
                      accion4:''
                    });
                  }
                  this.displayedColumns = [
                                          'cod_pedido',
                                          'cod_factura',
                                          'cod_lista_precio',
                                          'des_cliente',
                                          'estatus_factura',
                                          'detalle',
                                          'fechas',
                                          'imprimir',
                                          'nota_dev',
                                          'nota_admin',
                                          'anular'];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                      'cod_pedido',
                                      'cod_factura',
                                      'cod_lista_precio',
                                      'des_cliente',
                                      'estatus_factura',
                                      'detalle',
                                      'fechas',
                                      'imprimir',
                                      'nota_dev',
                                      'nota_admin',
                                      'anular'];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
//////////////////////FUNCION DE ACCION/////////////////////////

imprimirProforma(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
                xvalor.val_def = 'F';
                xvalor.tipo_operacion = 'IMPRIMIRF';
                xvalor.cod_usuario = localStorage.getItem('cod_usuario');
      const pdf = new PdfMakeWrapper();
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response=>{
          this.servicio.listarProdPedidoNotasEntregahttp(xvalor).subscribe(
            async response  =>{
             this.dialogSpin.close();
             response.pdf4[0].tipo_pedido = 'PFN';
             this.pdf.pdfVentas(response,'dd');
       
             },
             error =>{
               this.dialogSpin.close();
               console.log(error);
               console.log(<any>error.error.message);
               this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition});
             }
           );
        },
        error=>{
          this.dialogSpin.close();
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
      
    }, 1000);
  }

  consultarDev(xvalor:any, text:any){
    try {

      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            
            var dialogRef =  this.dialog.open(FormmMsgComponent, {
                    width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                  data: {cod_factura: xvalor.cod_nota,
                          accion:text},
                          disableClose: true,
                  panelClass: 'pedidos_modal',
                  backdropClass: "bdrop"
                });
         
          dialogRef.afterClosed().subscribe(result => {
            if(result != 'cerrar' && result != 'salir'){
              
                xvalor.cod_dev = result.cod_dev;

                console.log(xvalor);
               this.notasCredito(xvalor,'dev');
            }
          });

          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

 notasCredito(obj:any,xvalor:any){
  try {
    var valor = {
      val_def:'F',
      tipo_operacion : 'IMPRIMIRF',
      cod_usuario : localStorage.getItem('cod_usuario')
    }
    this.servicio.validar_acceso_acciones_http(valor).subscribe(
      response =>{
        this.key_hash = {};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            this.servicio.buscarDocumentoAnuladohttp(obj).subscribe(
              response =>{
                this.snackBar.open('El documento " '+obj.cod_nota +' "  ya fue anulado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition});
              },
              error =>{
                var dialogRef;
            switch (xvalor) {
              case 'dev':
                dialogRef =  this.dialog.open(FormNotaCreditoComponent, {
                    width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                  data: {cod_factura: obj.cod_nota,
                          cod_pedido: obj.cod_pedido,
                          cod_lista_precio:obj.cod_lista_precio,
                          cod_cliente:obj.cod_cliente,
                          cod_dev:obj.cod_dev,
                          des_cliente:obj.des_cliente,
                          iva_pedido:obj.iva_pedido,
                          iva_monto:obj.iva_monto,
                          desc_pedido:obj.desc_pedido,
                          subtotal_costo_pedido:obj.subtotal_costo_pedido,
                          total_costo_pedido:obj.total_costo_pedido,
                          tipo:'enviarpedido',
                          termini:'pedido'},
                          disableClose: true,
                  panelClass: 'pedidos_modal3',
                  backdropClass: "bdrop"
                });
                break;
              default:
                break;
            }
      
          dialogRef.afterClosed().subscribe(result => {
            if(result != 'cerrar' && result != 'salir'){
              
              result.tipo_nota = 'ntntrg';

              console.log(result);
             this.gestionNotaCredito(result);
            }
          });
              }
            );
            
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
      },
      error=>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
      
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
gestionNotaCredito(xvalor:any){
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });
  setTimeout( async() => {
    this.servicio.gestionNotaCreditoNotaEntregahttp(xvalor).subscribe(
      response =>{
        this.dialogSpin.close();
        this.cod_nota_credito = response.nota;
        this.listarfactura(null);
        this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});

      },
      error =>{
        this.dialogSpin.close();
        console.log('una respuesta');
        console.log(<any>error);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }, 1000);
  return this.xobj2;
}
  
  //////////////////////FUNCION DE ACCION/////////////////////////
 
   //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////

   fechas(obj:any, text:any){
    this.accion = text;
    this.dialog.open(FormDireccionesComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: { fecha_inicio:obj.fecha_inicio,
              hora_inicio:obj.hora_inicio,
              fecha_r_factura:obj.fecha_r_factura,
              hora_recepcion:obj.hora_recepcion,
              fecha_v_factura:obj.fecha_v_factura,
              hora_vencimiento:obj.hora_vencimiento,
              fecha_c_factura:obj.fecha_c_factura,
              termino: this.accion
            },
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
    });
}
detallepedido(obj:any, text:any){
  console.log(this.ELEMENT_DATA);
  console.log(obj);
  this.accion = text;
    this.dialog.open(FormDireccionesComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: { monto_pendiente:obj.monto_pendiente,
              monto_cobrado:obj.monto_cobrado,
              monto_contador:obj.monto_contador,
              termino: this.accion
            },
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
    });
}
 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  anular_nota(obj:any,termino:any){
    try {
      var valor = {
        val_def:'F',
        tipo_operacion : 'ANULACION',
        cod_usuario : localStorage.getItem('cod_usuario')
      }
      this.servicio.validar_acceso_acciones_http(valor).subscribe(
        response =>{
          this.servicio.buscarDocumentoAnuladohttp(obj).subscribe(
            response =>{
              this.snackBar.open('El documento " '+obj.cod_nota +' "  ya fue anulado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition});
            },
            error =>{
              var dialogRef:any;
                          this.accion = 'anulacion_ventas';
                            dialogRef = this.dialog.open(FormmMsgComponent, {
                              width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                              data: {
                                      accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal',
                              backdropClass: "bdrop"
                            });
                      
                            dialogRef.afterClosed().subscribe(result => {
                              if(result !== 'cerrar'){
                                obj.comentarios = result.comentarios;
                                obj.cod_usuario = localStorage.getItem('cod_usuario');
                                obj.des_usuario = localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido');
                                console.log(obj);
                      
                                 this.anularNotaEntrega(obj);
                              }
                        }
                      );
              });
        },
        error=>{
          this.snackBar.open(error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
    } catch (error) {
      console.log(<any>error);
    }
  }

  anularNotaEntrega(xvalor:any){
    try {
     
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout( async() => {
        this.servicio.gestionAnulacionVentahttp(xvalor).subscribe(
          response =>{
            this.dialogSpin.close();
            this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
              this.listarfactura(null);
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
      }, 1000);
      return this.xobj2;
    } catch (error) {
      console.log(<any>error);
    }
  }

      ajustes_almacen(obj:any,termino:any){
        var dialogRef:any;
        this.accion = 'DLY';
        let datos = localStorage.getItem('cod_usuario');
        let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
          dialogRef = this.dialog.open(FormKeyComponent, {
            width: '100vw',
          maxWidth: '100vw',
          height:'100%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if(result !== 'cerrar'){
                console.log(result,'el result');
                
            /* this.liberarProdTerminadoCuarentena(result); */
              
            }
          });
      }
      ajustes_almacen2(){
        var dialogRef:any;
        this.accion = 'DLYRPRT';
        let datos = localStorage.getItem('cod_usuario');
        let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
          dialogRef = this.dialog.open(FormKeyComponent, {
            width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
            data: {parametro:'2',
                    accion:this.accion},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if(result !== 'cerrar'){
                
            }
          });
      }

}
