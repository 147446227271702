import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import { FormCrearCentroCostosComponent } from 'src/app/componentes/shared/modal/form-crear-centro-costos/form-crear-centro-costos.component';
import { FormCrearPartidasComponent } from 'src/app/componentes/shared/modal/form-crear-partidas/form-crear-partidas.component'
import { FormCrearCuentasComponent } from 'src/app/componentes/shared/modal/form-crear-cuentas/form-crear-cuentas.component'
import { FormListarCuentasCreadasComponent } from 'src/app/componentes/shared/modal/form-listar-cuentas-creadas/form-listar-cuentas-creadas.component';
import { FormListarPartidasCreadasComponent } from 'src/app/componentes/shared/modal/form-listar-partidas-creadas/form-listar-partidas-creadas.component';
import { FormImportarCentroDeCostosComponent } from 'src/app/componentes/shared/modal/form-importar-centro-de-costos/form-importar-centro-de-costos.component';
import { FormGestionarCcUsuariosComponent } from 'src/app/componentes/shared/modal/form-gestionar-cc-usuarios/form-gestionar-cc-usuarios.component';
@Component({
  selector: 'app-gestion-centro-costo',
  templateUrl: './gestion-centro-costo.component.html',
  styleUrls: ['./gestion-centro-costo.component.css']
})
export class GestionCentroCostoComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  accion:any;
  displayedColumns:string[] = [];
  ELEMENT_DATA:any[]=[];
  xobj:any;
  total_cc = 0;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;

  dataSource=new MatTableDataSource<any>();
  
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) { 

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                      cod_menu: localStorage.getItem('sub_main'),
                      cod_sesion: localStorage.getItem('cod_sesion'),
                      dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};
    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
         /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }
    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
    }
     /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL//////////////////////////////////
      },
      error =>{
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
          console.log(error);
      }
    );
  }

  ngOnInit(): void {
     this.listarCentroCosto(null)
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  crear_centro_costo(obj:any, text:any){

    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
      cod_menu: localStorage.getItem('sub_main'),
      cod_sesion: localStorage.getItem('cod_sesion'),
      dir_ip_client: localStorage.getItem('dir_ip'),
    sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
      console.log(obj, 'segundo');
      console.log(this.xobj3,'UNIDADES');
      if (text == 'insertar') {
        this.accion = text;
         dialogRef = this.dialog.open(FormCrearCentroCostosComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: {
                 des_centro: obj.des_centro,
                 des_unidad: obj.des_unidad,
                 accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
        });
      }else{
        this.accion = text;
        dialogRef = this.dialog.open(FormCrearCentroCostosComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'100%',
              data: {cod_usuario:localStorage.getItem('cod_usuario'),
                     des_usuario:localStorage.getItem('Nombre'),
                     accion:this.accion},
            
          disableClose: true,
          panelClass: 'pedidos_modal_devolucion',
          backdropClass: "bdrop"
        });
      }

      dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
            console.log(result,);
              this.gestionCentroCosto(result);
          }
      });
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }

  gestionCentroCosto(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        var resultado = this.servicio.gestionCentroCostohttp(xvalor).subscribe(
          response =>{
            console.log(response)
            this.dialogSpin.close();
            this.listarCentroCosto(null);
            this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          },
          error =>{
            this.dialogSpin.close();
            this.displayedColumns = [
              'cod_centro',
              'des_unidad',
              'cant_centro',
              'cod_usuario',
              'des_usuario',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'cuentas',
              'listar_cuentas',
              'listar_partidas'
            ];
            this.dataSource = new MatTableDataSource<FormCrearCentroCostosComponent>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        return resultado;
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
//se llama al cargar el componentes para mostrar todas las devoluciones pendientes 
  listarCentroCosto(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listarCentroCostohttp().subscribe(
          response =>{
            this.dialogSpin.close();
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
              this.total_cc = 0;
                for (const i in response) {
                  
                  this.total_cc += parseFloat(response[i].cant_centro.replace('.', '')
                                                                    .replace('.', '')
                                                                    .replace('.', '')
                                                                    .replace('.', '')
                                                                    .replace('.', '')
                                                                    .replace(',', '.'))
              this.ELEMENT_DATA.push({
                cod_centro: response[i].cod_centro,
                des_centro: response[i].des_centro,
                cod_unidad: response[i].cod_unidad,
                des_unidad: response[i].des_unidad.toUpperCase(),
                cant_centro: response[i].cant_centro,
                cod_usuario: response[i].cod_usuario,
                des_usuario: response[i].des_usuario,
                estatus:response[i].estatus,
                fecha_inicio:response[i].fecha_inicio,
                hora_inicio:response[i].hora_inicio,
                accion:this.accion
                    });
                  }
                  
                  this.displayedColumns = ['cod_centro',
                                           'des_unidad',
                                           'cant_centro',
                                           'cod_usuario',
                                           'des_usuario',
                                           'estatus',
                                           'fecha_inicio',
                                           'hora_inicio',
                                           'cuentas',
                                           'listar_cuentas',
                                           'listar_partidas'
                                          ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            this.ELEMENT_DATA = [];
            console.log('una respuesta');
            console.log(<any>error);
            this.displayedColumns = ['cod_centro',
                                      'des_unidad',
                                      'cant_centro',
                                      'cod_usuario',
                                      'des_usuario',
                                      'estatus',
                                      'fecha_inicio',
                                      'hora_inicio',
                                      'cuentas',
                                      'listar_cuentas',
                                      'listar_partidas',
                                    ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
          }
        );
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
  ///////////////////////////////////////////CUENTAS///////////////////////////////////////////
  cuentas(obj:any,xvalor:any){  
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                      cod_menu: localStorage.getItem('sub_main'),
                      cod_sesion: localStorage.getItem('cod_sesion'),
                      dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                            
                            var dialogRef;
                                dialogRef =  this.dialog.open(FormCrearCuentasComponent, {
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    height:'100%',
                                    data: {
                                           cod_centro: obj.cod_centro,
                                           des_centro: obj.des_centro,
                                           descrip_definicion: obj.descrip_definicion,
                                           cant_cuenta_gasto: obj.cant_cuenta_gasto,
                                           cod_usuario:localStorage.getItem('cod_usuario'),
                                           des_usuario:localStorage.getItem('Nombre'),
                                           fecha:obj.fecha,
                                           hora:obj.hora,
                                           estatus:obj.estatus
                                          },
                                          disableClose: true,
                                  panelClass: 'pedidos_modal4',
                                  backdropClass: "bdrop"
                                });
                            dialogRef.afterClosed().subscribe(result => {
                            if(result !== 'cerrar' && result !== 'salir'){
                              console.log(result,'cuentas');
                              this.cuentas_final(result);
                             }
                          });
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  cuentas_final(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.servicio.gestionCuentasPresupuestohttp(xvalor).subscribe(
          response=>{
            this.dialogSpin.close();
            this.listarCentroCosto(null);
    
             this.snackBar.open(response.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          },
          error=>{
            this.dialogSpin.close();
            this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
      
          );
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }    
    }


  
  ///////////////////////////////////////////LISTAR CUENTAS///////////////////////////////////////////
     listar_cuentas(obj:any,xvalor:any){
      try {
        this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                      cod_menu: localStorage.getItem('sub_main'),
                      cod_sesion: localStorage.getItem('cod_sesion'),
                      dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};
                        this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                          response =>{
                            if (response.resul) {
                              
                              var dialogRef;
                                  dialogRef =  this.dialog.open(FormListarCuentasCreadasComponent, {
                                      width: '100vw',
                                      maxWidth: '100vw',
                                      height:'100%',
                                      data: {
                                             cod_centro: obj.cod_centro,
                                             des_unidad: obj.des_unidad,
                                             des_centro: obj.des_centro,
                                             descrip_definicion: obj.descrip_definicion,
                                             cant_cuenta_gasto: obj.cant_cuenta_gasto,
                                             cod_usuario:localStorage.getItem('cod_usuario'),
                                             des_usuario:localStorage.getItem('Nombre'),
                                             fecha:obj.fecha,
                                             hora:obj.hora,
                                             estatus:obj.estatus
                                            },
                                            disableClose: true,
                                    panelClass: 'pedidos_modal4',
                                    backdropClass: "bdrop"
                                  });
                              dialogRef.afterClosed().subscribe(result => {
                              if(result !== 'cerrar' && result !== 'salir'){
                                console.log(result,'cuentas');
                                this.listar_cuentas_final(result);
                               }else{
                                 console.log(result,'result');
                                this.listarCentroCosto('actualizar');
                               }
                            });
                            }else{
                              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,});
                            }
                          },
                          error =>{
                            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        );
      } catch (error) {
        console.log(error);
        this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    }
  
    listar_cuentas_final(xvalor:any){
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          this.servicio.listarCuentasCreadashttp(xvalor).subscribe(
            response=>{
              this.dialogSpin.close();
              /* this.listarCentroCosto('actualizar'); */
      
               this.snackBar.open(response.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            },
            error=>{
              this.dialogSpin.close();
              this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
        
            );
        }, 1000);
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }    
      }
      
    ///////////////////////////////////////////LISTAR PARTIDAS///////////////////////////////////////////
    
      listar_partidas(obj:any,xvalor:any){
        try {
          this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                      cod_menu: localStorage.getItem('sub_main'),
                      cod_sesion: localStorage.getItem('cod_sesion'),
                      dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};
                          this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                            response =>{
                              if (response.resul) {
                                
                                var dialogRef;
                                dialogRef =  this.dialog.open(FormListarPartidasCreadasComponent, {
                                width: '100vw',
                                        maxWidth: '100vw',
                                        height:'100%',
                                        data: {
                                               cod_centro: obj.cod_centro,
                                               des_unidad: obj.des_unidad,
                                               descrip_definicion: obj.descrip_definicion,
                                               cant_cuenta_gasto: obj.cant_cuenta_gasto,
                                               cod_usuario:localStorage.getItem('cod_usuario'),
                                               des_usuario:localStorage.getItem('Nombre'),
                                               fecha:obj.fecha,
                                               hora:obj.hora,
                                               estatus:obj.estatus
                                              },
                                              disableClose: true,
                                      panelClass: 'pedidos_modal4',
                                      backdropClass: "bdrop"
                                    });
                                dialogRef.afterClosed().subscribe(result => {
                                if(result !== 'cerrar' && result !== 'salir'){
                                  console.log(result,'cuentas');
                                  this.listar_partidas_final(result);
                                 }
                              });
                              }else{
                                this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                  verticalPosition: this.verticalPosition,});
                              }
                            },
                            error =>{
                              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,});
                            }
                          );
        } catch (error) {
          console.log(error);
          this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      }
    
      listar_partidas_final(xvalor:any){
        try {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          setTimeout(() => {
            this.servicio.listarCuentasCreadashttp(xvalor).subscribe(
              response=>{
                this.dialogSpin.close();
                this.listarCentroCosto(null);
        
                 this.snackBar.open(response.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              },
              error=>{
                this.dialogSpin.close();
                this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
          
              );
          }, 1000);
        } catch (error) {
          this.dialogSpin.close();
          this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }    
        }


    importar_crear_centro_costo(obj:any, text:any){

      try {
        this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
        cod_menu: localStorage.getItem('sub_main'),
        cod_sesion: localStorage.getItem('cod_sesion'),
        dir_ip_client: localStorage.getItem('dir_ip'),
      sesion_unica:sessionStorage.getItem('sesion_unica')};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                            var dialogRef;
        console.log(obj, 'segundo');
        console.log(this.xobj3,'UNIDADES');
        if (text == 'insertar') {
          this.accion = text;
            dialogRef = this.dialog.open(FormImportarCentroDeCostosComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'100%',
            data: {
                    des_centro: obj.des_centro,
                    des_unidad: obj.des_unidad,
                    accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal4',
                backdropClass: "bdrop"
          });
        }else{
          this.accion = text;
          dialogRef = this.dialog.open(FormImportarCentroDeCostosComponent, {
            width: '100vw',
                maxWidth: '100vw',
                height:'100%',
                data: {cod_usuario:localStorage.getItem('cod_usuario'),
                        des_usuario:localStorage.getItem('Nombre'),
                        accion:this.accion},
              
            disableClose: true,
            panelClass: 'pedidos_modal4',
            backdropClass: "bdrop"
          });
        }
  
        dialogRef.afterClosed().subscribe(result => {
            if(result != 'cerrar'){
              console.log(result,);
                this.gestionCentroCostoImport(result);
            }
        });
              }else{
                this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
            },
            error =>{
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
          } catch (error) {
          this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          }
    }

    gestionCentroCostoImport(xvalor:any){
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          var resultado = this.servicio.gestionCentroCostoImporthttp(xvalor).subscribe(
            response =>{
              console.log(response)
              this.dialogSpin.close();
              this.listarCentroCosto(null);
              this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            },
            error =>{
              this.dialogSpin.close();
              this.displayedColumns = [
                'cod_centro',
                'des_unidad',
                'cant_centro',
                'cod_usuario',
                'des_usuario',
                'estatus',
                'fecha_inicio',
                'hora_inicio',
                'cuentas',
                'listar_cuentas',
                'listar_partidas'
              ];
              this.dataSource = new MatTableDataSource<FormImportarCentroDeCostosComponent>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
              console.log(<any>error.error.message);
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
          return resultado;
        }, 1000);
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

    parametros_acciones_create(text:any){
    
      try {
        this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                      cod_menu: localStorage.getItem('sub_main'),
                      cod_sesion: localStorage.getItem('cod_sesion'),
                      dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                           
                              var accion = text;
                              var dialogRef = this.dialog.open(FormGestionarCcUsuariosComponent, {
                                width: '100vw',
                                maxWidth: '100vw',
                                height:'100vh',
                                data: '',
                                disableClose: true,
                                panelClass: 'pedidos_modal3',
                                backdropClass: "bdrop"
                              });
                              dialogRef.afterClosed().subscribe(result => {
                                if(result != 'cerrar'){
                                }
                              });
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
      } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
    
}
