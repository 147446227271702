import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormTraladoInvComponent } from '../form-tralado-inv/form-tralado-inv.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormOrdHistorialMppComponent } from '../form-ord-historial-mpp/form-ord-historial-mpp.component';
import { FormTrasladosDepComponent } from '../form-traslados-dep/form-traslados-dep.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormCargarMontoDailyComponent } from '../form-cargar-monto-daily/form-cargar-monto-daily.component';
import { FormEnviarReporteDailyComponent } from '../form-enviar-reporte-daily/form-enviar-reporte-daily.component';
import { FormCargarMontoPartidasComponent } from '../form-cargar-monto-partidas/form-cargar-monto-partidas.component';
import { FormTransferirPartidasComponent } from '../form-transferir-partidas/form-transferir-partidas.component';
import { FormReporteCuotasVsVentasComponent } from '../form-reporte-cuotas-vs-ventas/form-reporte-cuotas-vs-ventas.component';
import { FormDescargarMontoPartidasComponent } from '../form-descargar-monto-partidas/form-descargar-monto-partidas.component';

@Component({
  selector: 'app-form-key',
  templateUrl: './form-key.component.html',
  styleUrls: ['./form-key.component.css']
})
export class FormKeyComponent implements OnInit {

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
dialogSpin:any;
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  msg:any;
  telRegex:any = /\d{2}-\d{5}\d/;
  constructor(fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormKeyComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices, public dialog: MatDialog) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.forma = fb.group({
      'cod_factura':'',
      'cod_control':['',[Validators.pattern(this.telRegex),Validators.required]],
      'cod_lote':this.data.cod_lote,
      'cod_orden_producto':this.data.cod_orden_producto,
      'accion':''
    });
    }

  ngOnInit(): void {
  }

  eliminarregistros(res:any){

    if(res == 'eliminar'){
      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
    }
  }

  buscarHash(obj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        console.log(obj);
        var dialogRef:any;
      var datos = localStorage.getItem('cod_usuario');
          var valor = {hash_key:this.forma.value.cod_control,cod_usuario: datos}
          this.servicio.buscarHashKeyhttp(valor).subscribe(
            response =>{
              console.log(response);
              if (response.resul === true) {
                this.dialogSpin.close();
                switch (this.data.accion) {
                  case 'INV':
                    dialogRef = this.dialog.open(FormTraladoInvComponent, {
                      width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                      data: {cod_producto:this.data.cod_producto,
                              des_producto:this.data.des_producto,
                              cant_producto:this.data.cant_producto,
                              objeto:this.data.objeto,
                              cod_orden_producto:this.data.cod_orden_producto,
                              cod_lote:this.data.cod_lote,
                              accion:this.accion},
                      disableClose: true,
                      panelClass: 'pedidos_modal_rep',
                      backdropClass: "bdrop"
                    });
                          dialogRef.afterClosed().subscribe(result => {
                            console.log(result);
                              this.dialogRefp.close(result);
                        });
                    break;
                    case 'DLY':
                    dialogRef = this.dialog.open(FormCargarMontoDailyComponent, {
                      width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                      data: {
                            accion:this.accion},
                      disableClose: true,
                      panelClass: 'pedidos_modal_rev',
                      backdropClass: "bdrop"
                    }); 
                      this.dialogRefp.close();
                    break;

                          case 'DLYRPRT':
                    dialogRef = this.dialog.open(FormEnviarReporteDailyComponent, {
                      width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                      data: {parametro:this.data.parametro,
                            accion:this.accion},
                      disableClose: true,
                      panelClass: 'pedidos_modal_rev',
                      backdropClass: "bdrop"
                    }); 
                      this.dialogRefp.close();
                    break;
                  case 'PT':
                    dialogRef = this.dialog.open(FormTraladoInvComponent, {
                      width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                      data: {cod_producto:this.data.cod_producto,
                              des_producto:this.data.des_producto,
                              cod_cliente:this.data.cod_cliente,
                              des_cliente:this.data.des_cliente,
                              cant_producto:this.data.cant_producto,
                              cod_orden_producto:this.data.cod_orden_producto,
                              cod_lote:this.data.cod_lote,
                              accion:this.accion},
                      disableClose: true,
                      panelClass: 'pedidos_modal_rep',
                      backdropClass: "bdrop"
                    });
                          dialogRef.afterClosed().subscribe(result => {
                            console.log(result);
                              this.dialogRefp.close(result);
                        });
                    break;
                    case 'CRNTN':
                    dialogRef = this.dialog.open(FormTraladoInvComponent, {
                      width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                      data: {cod_producto:this.data.cod_producto,
                              des_producto:this.data.des_producto,
                              cod_cliente:this.data.cod_cliente,
                              des_cliente:this.data.des_cliente,
                              cant_producto:this.data.cant_producto,
                              cod_orden_producto:this.data.cod_orden_producto,
                              cod_lote:this.data.cod_lote,
                              accion:this.accion},
                      disableClose: true,
                      panelClass: 'pedidos_modal_rep',
                      backdropClass: "bdrop"
                    });
                          dialogRef.afterClosed().subscribe(result => {
                            console.log(result);
                              this.dialogRefp.close(result);
                        });
                    break;
                    case 'SPCL':
                    dialogRef = this.dialog.open(FormTraladoInvComponent, {
                      width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                      data: {cod_producto:this.data.cod_producto,
                              des_producto:this.data.des_producto,
                              cod_cliente:this.data.cod_cliente,
                              des_cliente:this.data.des_cliente,
                              cant_producto:this.data.cant_producto,
                              cod_orden_producto:this.data.cod_orden_producto,
                              cod_lote:this.data.cod_lote,
                              accion:this.accion},
                      disableClose: true,
                      panelClass: 'pedidos_modal_rep',
                      backdropClass: "bdrop"
                    });
                          dialogRef.afterClosed().subscribe(result => {
                            console.log(result);
                              this.dialogRefp.close(result);
                        });
                    break;
                    case 'MP':
                      dialogRef = this.dialog.open(FormTraladoInvComponent, {
                        width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                        data: {cod_producto:this.data.cod_producto,
                                des_producto:this.data.des_producto,
                                cod_cliente:this.data.cod_cliente,
                                des_cliente:this.data.des_cliente,
                                cant_producto:this.data.cant_producto,
                                cod_orden_producto:this.data.cod_orden_producto,
                                cod_lote:this.data.cod_lote,
                                accion:this.accion},
                        disableClose: true,
                        panelClass: 'pedidos_modal_rep',
                        backdropClass: "bdrop"
                      });
                            dialogRef.afterClosed().subscribe(result => {
                              console.log(result);
                                this.dialogRefp.close(result);
                          });
                      break;
                      case 'FRPT':
                        dialogRef = this.dialog.open(FormOrdHistorialMppComponent, {
                          width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                          data: {objeto: this.data.objeto},
                          disableClose: true,
                          panelClass: 'pedidos_modal3',
                          backdropClass: "bdrop"
                        });
                              
                        dialogRef.afterClosed().subscribe(result => {
                          console.log(result);
                            this.dialogRefp.close(result);
                      });
                            
                        break;
                        case 'FRMP':
                          dialogRef = this.dialog.open(FormOrdHistorialMppComponent, {
                            width: '100vw',
                        maxWidth: '100vw',
                        height:'100vh',
                            data: {objeto: this.data.objeto},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                                
                          dialogRef.afterClosed().subscribe(result => {
                            console.log(result);
                              this.dialogRefp.close(result);
                        });
                              
                          break;
                          case 'TRSLDS':
                    dialogRef = this.dialog.open(FormTrasladosDepComponent, {
                      width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                      data: {cod_dep:this.data.cod_dep,
                              productos:this.data.productos,
                              accion:this.accion},
                      disableClose: true,
                      panelClass: 'pedidos_modal_rev',
                      backdropClass: "bdrop"
                    }); 
                      this.dialogRefp.close();
                    break;

                    case 'NLRVNT':
                                dialogRef = this.dialog.open(FormmMsgComponent, {
                                  width: '100vw',
                              maxWidth: '100vw',
                              height:'100vh',
                                  data: {cod_dep:this.data.cod_dep,
                                          productos:this.data.productos,
                                          accion:'anulacion_ventas'},
                                  disableClose: true,
                                  panelClass: 'pedidos_modal',
                                  backdropClass: "bdrop"
                                }); 
                                dialogRef.afterClosed().subscribe(result => {
                                    this.dialogRefp.close(result);
                              });
                    break;
                    case 'dscnt':
                                dialogRef = this.dialog.open(FormmMsgComponent, {
                                  width: '100vw',
                              maxWidth: '100vw',
                              height:'100vh',
                                  data: {cod_dep:this.data.cod_dep,
                                          productos:this.data.productos,
                                          accion:this.data.accion},
                                  disableClose: true,
                                  panelClass: 'pedidos_modal',
                                  backdropClass: "bdrop"
                                }); 
                                dialogRef.afterClosed().subscribe(result => {
                                    this.dialogRefp.close(result);
                              });
                    break;
                    
                    case 'TRNSFRNC':
                      dialogRef = this.dialog.open(FormTransferirPartidasComponent, {
                        width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                        data: {cod_centro:this.data.cod_centro,
                              des_centro: this.data.des_centro,
                              cod_cuenta_gasto:this.data.cod_cuenta_gasto,
                              des_cuenta_gasto:this.data.des_cuenta_gasto,
                              accion:this.accion},
                        disableClose: true,
                        panelClass: 'pedidos_modal_rev',
                        backdropClass: "bdrop"
                      }); 
                      dialogRef.afterClosed().subscribe(result => {
                        this.dialogRefp.close(result);
                      });
                      break;
  
                            case 'CRG':
                      dialogRef = this.dialog.open(FormCargarMontoPartidasComponent, {
                        width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                        data: {cod_centro: this.data.cod_centro,
                              des_centro: this.data.des_centro,
                              cod_cuenta_gasto: this.data.cod_cuenta_gasto,
                              des_cuenta_gasto: this.data.des_cuenta_gasto,
                              cant_cuenta_gasto: this.data.cant_cuenta_gasto,
                              cod_presupesto: this.data.cod_presupesto,
                              accion:this.accion},
                        disableClose: true,
                        panelClass: 'pedidos_modal_rev',
                        backdropClass: "bdrop"
                      }); 
                      dialogRef.afterClosed().subscribe(result => {
                        this.dialogRefp.close(result);
                      });
                      break;
                      case 'OPVNTS':
                        dialogRef = this.dialog.open(FormmMsgComponent, {
                          width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                          data: {cod_dep:this.data.cod_dep,
                                  productos:this.data.productos,
                                  accion:'OPVNTS'},
                          disableClose: true,
                          panelClass: 'pedidos_modal',
                          backdropClass: "bdrop"
                        }); 
                        dialogRef.afterClosed().subscribe(result => {
                            this.dialogRefp.close(result);
                      });
                  break;
                  case 'LTS':
                        dialogRef = this.dialog.open(FormmMsgComponent, {
                          width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                          data: { productos:this.data.productos,
                                  accion:'LTS'},
                          disableClose: true,
                          panelClass: 'pedidos_modal',
                          backdropClass: "bdrop"
                        }); 
                        dialogRef.afterClosed().subscribe(result => {
                            this.dialogRefp.close(result);
                      });
                  break;
                  case 'LTS_A':
                        dialogRef = this.dialog.open(FormmMsgComponent, {
                          width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                          data: { 
                                  accion:'LTS_A'},
                          disableClose: true,
                          panelClass: 'pedidos_modal',
                          backdropClass: "bdrop"
                        }); 
                        dialogRef.afterClosed().subscribe(result => {
                            this.dialogRefp.close(result);
                      });
                  break;
                  case 'CTSVSVNTS':
                    dialogRef = this.dialog.open(FormReporteCuotasVsVentasComponent, {
                      width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                      data: {cod_usuario :this.data.cod_usuario,
                              productos:this.data.productos,
                              accion:this.data.accion},
                      disableClose: true,
                      panelClass: 'pedidos_modal3',
                      backdropClass: "bdrop"
                    }); 
                    dialogRef.afterClosed().subscribe(result => {
                        this.dialogRefp.close(result);
                  });
                    break;
                  case 'DSCRGP':
                    dialogRef = this.dialog.open(FormDescargarMontoPartidasComponent, {
                      width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                      data: {cod_centro: this.data.cod_centro,
                              des_centro: this.data.des_centro,
                              cod_cuenta_gasto: this.data.cod_cuenta_gasto,
                              des_cuenta_gasto: this.data.des_cuenta_gasto,
                              cant_cuenta_gasto: this.data.cant_cuenta_gasto,
                              cod_presupesto: this.data.cod_presupesto,
                              accion:this.accion},
                      disableClose: true, 
                      panelClass: 'pedidos_modal_rev',
                      backdropClass: "bdrop"
                    }); 
                    dialogRef.afterClosed().subscribe(result => {
                        this.dialogRefp.close(result);
                  });
                    break;
                
                  default:
                        this.dialogRefp.close();
                    break;
                }
              }
            },
            error =>{
              this.dialogSpin.close();
              console.log(error);
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition});
            }
          );
      }, 500);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

    }
  cerrarmodal(): void {
    this.dialogRefp.close('cerrar');
  }

  salir(): void {
    this.dialogRefp.close('salir');
  }
}
