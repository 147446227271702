import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { Router } from '@angular/router';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
@Component({
  selector: 'app-form-recuperacion',
  templateUrl: './form-recuperacion.component.html',
  styleUrls: ['./form-recuperacion.component.css']
})
export class FormRecuperacionComponent implements OnInit {

  hide = true;
  nombre:any;
  forma_correo:FormGroup;
  forma_codigo:FormGroup;
  forma_pass:FormGroup;
  accion:any;
  unidad:any;
  menu:any;
  des:any;
  operacion:any;
  
    constructor( 
      private fb:FormBuilder,
      private servicio:nkServices,
      private dialog: MatDialog, 
      private dialogRef: MatDialogRef<FormRecuperacionComponent>,
      private hashkey:HashKeyServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private router:Router,
      private notifi:ToastNotificationService,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data, 'primero');
      this.accion = data.accion;
      this.menu = data.menu;
      this.des = data.des;
      this.unidad = data.unidad;
      this.operacion = this.atrb.RECUPERACION.correo_rep.toUpperCase();
        this.forma_correo = fb.group({
          'des_correo': ['',[Validators.required, Validators.email]],
          'api': [''],
          'cod_empresa': [''],
          'operacion' : [this.atrb.RECUPERACION.recuperar_pass]
        });
        this.forma_codigo = fb.group({
          'des_codigo': ['',[Validators.required]],
          'api': [''],
          'cod_empresa': [''],
        });
        this.forma_pass = fb.group({
          'pass_usuario': ['',[Validators.required]],
          'cpass': ['',[Validators.required,RxwebValidators.compare({ fieldName: 'pass_usuario' })]],
          'api': [''],
          'des_correo': [''],
          'cod_empresa': [''],
          'token': [hashkey.encrypt(atrb.TOKEN)],
        });
      }
    ngOnInit(){}
  
   async correoRecuperar(){
      try {
        await this.util.abrirSpinner();
        this.forma_correo.patchValue({
          api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionRecuperacion),
          cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
        });
        const response =  await this.servicio.ApiResthttp(this.forma_correo.value).toPromise();
        this.operacion = this.atrb.RECUPERACION.codigo_rep.toUpperCase();
        await this.util.cerrarSpinner();
        await this.notifi.success(response.message);
      } catch (error) {
        console.log(error);
          await this.util.cerrarSpinner();
          await this.notifi.customToastNotification(error.error.message);
      }
    }
   async confirmarCodigo(){
      try {
        await this.util.abrirSpinner();
        this.forma_codigo.patchValue({
          api: await this.hashkey.encrypt(await this.atrb.API_REST.validarCodigoRecu),
        });
        const response =  await this.servicio.ApiResthttp(this.forma_codigo.value).toPromise();
        this.operacion = this.atrb.RECUPERACION.pass_rep.toUpperCase();
        await this.util.cerrarSpinner();
        await this.notifi.success(response.message);
      } catch (error) {
        console.log(error);
          await this.util.cerrarSpinner();
          await this.notifi.customToastNotification(error.error.message);
      }
    }
   async cambiarPass(){
      try {
        await this.util.abrirSpinner();
        this.forma_pass.patchValue({
          des_correo: this.forma_correo.value.des_correo,
          pass_usuario: await this.hashkey.encrypt(this.forma_pass.value.pass_usuario),
          api: await this.hashkey.encrypt(await this.atrb.API_REST.cambiarContrasena),
        });
        const response =  await this.servicio.ApiResthttp(this.forma_pass.value).toPromise();
        this.operacion = this.atrb.RECUPERACION.correo_rep.toUpperCase();
        await this.util.cerrarSpinner();
        await this.notifi.success(response.message);
      } catch (error) {
        console.log(error);
          await this.util.cerrarSpinner();
          await this.notifi.customToastNotification(error.error.message);
      }
    }
    async cerrarmodal(){
      try {
        let accion = 'salir';
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: {id:'',
                accion:accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if (result !== await this.atrb.CERRAR) {
            await this.util.cerrarSpinner();
            let valor = {proceso:await this.atrb.CERRAR}
                this.dialogRef.close(valor);
          }else{
            await this.util.cerrarSpinner();
          }
        });
      } catch (error) {
        console.log(error);
      }
  }
}
