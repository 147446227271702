import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-definiciones-sistema',
  templateUrl: './form-definiciones-sistema.component.html',
  styleUrls: ['./form-definiciones-sistema.component.css']
})
export class FormDefinicionesSistemaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
