import { Component, OnInit, Inject, Injectable, ElementRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {BehaviorSubject} from 'rxjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatPaginator } from '@angular/material/paginator';
import { FormPermisosAccionesComponent } from '../form-permisos-acciones/form-permisos-acciones.component';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { forIn } from 'lodash';

@Component({
  selector: 'app-form-parametros-licencia',
  templateUrl: './form-parametros-licencia.component.html',
  styleUrls: ['./form-parametros-licencia.component.css']
})
export class FormParametrosLicenciaComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('unidad_front') unidad_front:ElementRef;
  @ViewChild('menu_front') menu_front:ElementRef;
  @ViewChild('submenu_front',{static:true}) submenu_front:ElementRef;
  @ViewChild('submenu_hijo',{static:true}) submenu_hijo:ElementRef;
  
  dialogSpin:any;
  forma:FormGroup;
  formaTxt:FormGroup;
  accion:any;
  roles:any;
  rol = [];
  parametros_licencia=[];
  parametro:any;
  unidadp=[];
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  menur3:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  rx:any;
  constructor(
    private service:nkServices,
    private fb:FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog, 
    private servicio:nkServices,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private point: BreakpointObserver,
    public dialogRefp: MatDialogRef<FormParametrosLicenciaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data, 'primero');
    this.accion = data.accion;
    this.parametros_licencia = data.parametros_licencia;
    
      this.forma = fb.group({
        'des_parametro':['',Validators.required],
        'cod_licencia':[data.data.cod_licencia],
        'val_def':['',Validators.required],
        'modo':[''],
      });
      this.formaTxt = this.fb.group({
        'des_parametro':[''],
        'cod_licencia':[data.data.cod_licencia],
        'val_tex':['',Validators.required],
        'val_def':[''],
        'modo':[''],
      });
     }

  ngOnInit(){
    this.listarParametrosLicencia();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    this.dataSource.paginator = this.paginator;
    
  }

 async listarParametrosLicencia(){
    try {
      this.ELEMENT_DATA = [];
      this.cant = false;
      var valor = {cod_licencia: this.data.data.cod_licencia};
      console.log(valor,'valor');
     const response = await  this.servicio.buscarPerametrosLicenciahttp(valor).toPromise();
  
          for (const i in response.data) {
            if (response.data[i].val_def === '' || response.data[i].val_def === null) {
                this.parametro = await this.atrb.TXT;
            }else{
                this.parametro = await this.atrb.TXT;
            }
            this.ELEMENT_DATA.push({
              des_parametro:response.data[i].des_parametro,
              val_def:response.data[i].val_def,
              fecha_inicio: response.data[i].fecha_inicio,
              hora_inicio: response.data[i].hora_inicio,
              parametro: this.parametro
            });
          }
          console.log(this.ELEMENT_DATA);
          this.displayedColumns = [
            'des_parametro',
            'val_def',
            'fecha_inicio',
            'hora_inicio',
            'procesos'
           ];
  
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      this.displayedColumns = [
            'des_parametro',
            'val_def',
            'fecha_inicio',
            'hora_inicio',
            'procesos'
           ];
       this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
        await this.util.notificacionErrorApp(error.error.message);
    }
  }

  permisos_acciones_app(){
    try {
      if (this.ELEMENT_DATA.length > 0) {
        this.accion = '';
        var dialogRef = this.dialog.open(FormPermisosAccionesComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: {unidades:this.unidadp, 
            cod_usuario:this.data.cod_usuario,
            des_usuario:this.data.nombre+' '+this.data.apellido,
                accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal_permisos_acciones',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
           
            console.log(result);
            /* this.gestionarusuriorol(result); */
           
          } 
        });
      }else{
        this.snackBar.open('No existe nada en su lista', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
    } catch (error) {
      console.log(<any>error.error.message);
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
    }
    
  }

 async modalrespuesta(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
      console.log(this.forma.value);
      if (isNaN(parseInt(this.forma.value.val_def))) {
          await this.util.notificacionErrorApp('Debe usar solo numeros para definir el valor del parametro');
        return;
      }
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            this.forma.patchValue({
              modo: await this.atrb.ACCION_CREAR
            });
           await this.gestionarPametroLicencia(this.forma.value);
          } else{
            await this.util.cerrarSpinner();
          }
        });
      
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.util.notificacionErrorApp(error.error.message);
    }
    
  }
async gestionarPametroLicencia(xvalor:any){
    try {
      console.log(xvalor);
        const response = await this.servicio.gestionParametroLicenciahttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
          await  this.listarParametrosLicencia();
          await this.util.notificacionErrorApp(response.message);
    } catch (error) {
      await this.util.cerrarSpinner();
      await  this.listarParametrosLicencia();
      await this.util.notificacionErrorApp(error.error.message);
    }
   }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result != 'cerrar') {
        this.dialogRefp.close('cerrar');
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 async getQuitarParametro(xobjeto:any){
   try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            this.forma.patchValue({
              des_parametro: xobjeto.des_parametro,
              cod_licencia: this.data.data.cod_licencia,
              modo: await this.atrb.ACCION_BORRAR,
            });
           const response =  await this.servicio.gestionParametroLicenciahttp(this.forma.value).toPromise();
              await  this.listarParametrosLicencia();
              await this.util.notificacionErrorApp(response.message);
          } else{
            await this.util.cerrarSpinner();
          }
        });
   } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await  this.listarParametrosLicencia();
    await this.util.notificacionErrorApp(error.error.message);
   }
   }
 async getTxtConfig(xobjeto:any){
    try {
       await this.util.abrirSpinner();
       if (xobjeto.val_def !== null) {
         var dialogRef = this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'auto',
           data: { 
                 accion: ''},
                 disableClose: true,
                 panelClass: 'pedidos_modal',
                 backdropClass: "bdrop" 
         });
         dialogRef.afterClosed().subscribe(async result => {
           if(result != 'cerrar'){
             for (const i in this.ELEMENT_DATA) {
               if (this.ELEMENT_DATA[i].des_parametro.toUpperCase() === xobjeto.des_parametro.toUpperCase()) {
                 this.ELEMENT_DATA[i].val_def = null;
               }
             }
             this.displayedColumns = [
              'des_parametro',
              'val_def',
              'fecha_inicio',
              'hora_inicio',
              'procesos'
             ];
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
             await this.util.cerrarSpinner();
             await this.util.notificacionErrorApp(await this.atrb.MENSAJES_FIJOS.agregar_valor);
           } else{
             await this.util.cerrarSpinner();
           }
         });
         return;
       }
       if (this.formaTxt.invalid) {
         await this.util.cerrarSpinner();
         await this.util.notificacionErrorApp(await this.atrb.MENSAJES_FIJOS.agregar_valor);
           return;
         }
       await this.servicio.consultar_sesionhttp(null).toPromise();
       await this.util.cerrarSpinner();
         var dialogRef = this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'auto',
           data: { 
                 accion:''},
                 disableClose: true,
                 panelClass: 'pedidos_modal',
                 backdropClass: "bdrop" 
         });
         dialogRef.afterClosed().subscribe(async result => {
           await this.util.abrirSpinner();
           if(result != 'cerrar'){
             await this.util.cerrarSpinner();
         this.formaTxt.patchValue({
           cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
           des_parametro:xobjeto.des_parametro,
           val_def:this.formaTxt.value.val_tex,
           val_tex:'',
           modo:await this.atrb.ACCION_ACTUALIZAR
         });
         console.log(this.formaTxt.value);
           const response = await this.servicio.gestionParametroLicenciahttp(this.formaTxt.value).toPromise();
             await  this.listarParametrosLicencia();
             await this.util.notificacionErrorApp(response.message);
           } else{
             await this.util.cerrarSpinner();
           }
         });
    } catch (error) {
     console.log(error);
     await this.util.cerrarSpinner();
     await  this.listarParametrosLicencia();
     await this.util.notificacionErrorApp(error.error.message);
    }
    }
}
