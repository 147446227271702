
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    
        <div class="row " style="height: 8%; ">
            <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark nav_componente  nav py-1">
                
                <div class=" navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li  class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarlprecios("actualizar")'><mat-icon >sync</mat-icon></button></li>
                        <li  class="nav-item"><button matTooltip="CREAR NUEVA LISTA DE PRECIOS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("", "crear_lista_precios")'><mat-icon >add</mat-icon></button></li>
                        <!-- <li  class="nav-item"><button matTooltip="RECALCULAR LISTAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="border: outset 3px rgb(55, 162, 87); height: 35px; color:rgb(255, 255, 255); background-color: rgb(6, 126, 42);" (click)="update_price_value(tasa_base_input,'recalcular_tasa')"><mat-icon >credit_card</mat-icon></button></li> -->
                        <li  class="nav-item"><button matTooltip="PARAMETROS VENTAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style=" height: 35px; color:rgb(255, 255, 255); background-color: rgb(44, 73, 135);" (click)="parametros_precios_create(false)"><mat-icon >build</mat-icon></button></li>
                        <!-- <li  class="nav-item"><input class="form-control me-2" #tasa_base_input placeholder="Coloca un monto en Bs." (keyup)="format(tasa_base_input)" formControlName="tasa_base" autocomplete="off" maxlength="17"></li> -->
                    </ul>
                    <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>DEFINICIONES PRECIOS</b></span>
                    <div class=" ">
                        <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                    </div>
                </div>
            </nav>
        </div>

        <div class="row altura_table">
            <div class="col-md-12 px-0  border h-100 table_overflow_color">
                <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-condensed table-hover">
    
                    <ng-container matColumnDef="cod_lista_precio_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Lista </strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.cod_lista_precio_producto}} </td>
                        
                    </ng-container>
    
                    <ng-container matColumnDef="cod_cliente">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Cod cliente </strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.cod_cliente}} </td>
                        
                    </ng-container>
    
                    <ng-container matColumnDef="des_cliente">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Cliente </strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.des_cliente}} </td>
                        
                    </ng-container>
    
                    <ng-container matColumnDef="cod_usuario">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Cod usuario </strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.cod_usuario}} </td>
                        
                    </ng-container>
    
                    <ng-container matColumnDef="des_usuario">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Usuario </strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.des_usuario}} </td>
                       
                    </ng-container>
    
                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Estatus </strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.estatus}} </td>
                        
                    </ng-container>
    
                    <ng-container matColumnDef="fecha_inicio">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Fecha</strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.fecha_inicio}} </td>
                       
                    </ng-container>
    
                    <ng-container matColumnDef="hora_inicio">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Hora</strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.hora_inicio}} </td>
                       
                    </ng-container>
    
                    <ng-container matColumnDef="tipo_lista">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Tipo</strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_lista}} </td>
                       
                    </ng-container>
    
                    <ng-container matColumnDef="tasa_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Tasa</strong></h5>
                        </th>
                        <td  mat-cell *matCellDef="let element" class="td_style"> {{element.tasa_precio}} </td>
                        
                    </ng-container>
    
                    <ng-container  matColumnDef="procesos">
                        <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                            <h5><strong>Procesos</strong></h5>
                        </th>
                      
                        <td  mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                            <button class=" bg-primary mr-1"   matTooltip="EDITAR" mat-mini-fab (click)='update_insert_data(element, "actualizar")'>
                                <mat-icon >edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    
                </table>
                <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
            </div>
            <!-- <mat-paginator [pageSizeOptions]="[3, 5, 7]" showFirstLastButtons></mat-paginator> -->
        </div>
  
</div>
