import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { AtributosService } from '../../servicios/atributos.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { nkServices } from '../../servicios/app.nkservicios.services';

@Component({
  selector: 'app-form-menu-rol',
  templateUrl: './form-menu-rol.component.html',
  styleUrls: ['./form-menu-rol.component.css']
})
export class FormMenuRolComponent implements OnInit {
forma:FormGroup;
accion:any;
estatus:any;
cargo:any;
gerencia:any;
dpto:any;

  constructor( 
    private fb:FormBuilder,
    private dialog: MatDialog, 
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    private servicio:nkServices,
    private dialogRef: MatDialogRef<FormMenuRolComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.accion = data.accion;
    if (this.data.accion.toUpperCase() === this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
      console.log(this.data, 'actualizar');
      this.forma = this.fb.group({
        'cod_gerencia':[this.data.data.cod_gerencia],
        'des_gerencia':[this.data.data.des_gerencia],
        'cod_dpto':[this.data.data.cod_dpto],
        'des_dpto':[this.data.data.des_dpto],
        'des_rol': [this.data.data.des_rol],
        'cod_rol': [this.data.data.cod_rol],
        'estatus': [this.data.data.estatus,[Validators.required]],
        'comentarios': [this.data.data.comentarios,[Validators.required,Validators.maxLength(30)]],
        'operacion': [this.data.operacion]
      });
    }else{
      console.log(this.data, 'insertar');
      this.forma = this.fb.group({
        'cod_gerencia':['',[Validators.required]],
        'des_gerencia':[''],
        'cod_dpto':['',[Validators.required]],
        'des_dpto':[''],
        'des_rol': ['',[Validators.required]],
        'estatus': ['',[Validators.required]],
        'comentarios': ['',[Validators.required,Validators.maxLength(30)]],
        'operacion': [this.data.operacion]
      });
    }
    }

  ngOnInit(){
    this.listarUnidad(null);
  }

  async listarUnidad(xvalor:any){
    try {
      await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        const response = await this.servicio.listarUnidadhttp().toPromise();
        this.cargo = [];
        this.estatus = await this.atrb.ESTATUS;
      if (xvalor !== null) {
        console.log(xvalor);
        if (parseInt(xvalor.value.cod_unidad_padre) <= 0) {
          let xobj = [];
        for (const i in response) {
        if (parseInt(xvalor.value.cod_unidad) ===  parseInt(response[i].cod_unidad_padre) ) {
          xobj.push(response[i]);
        }
        }
          this.dpto = xobj;
          await this.util.cerrarSpinner();
        }else{
          let valor = {
            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
            cod_dpto:xvalor.value.cod_unidad.toString()
          }
          console.log(valor);
          let response = await this.servicio.listarCargoshttp(valor).toPromise();
          console.log(response);
          let xobj = [];
          for (const i in response) {
            xobj.push(response[i].des_cargo);
          }
          this.cargo = xobj;
          await this.util.cerrarSpinner();
        }
    }else{
      let xobj = [];
      console.log(response);
      for (const i in response) {
       if (parseInt(response[i].cod_unidad_padre) <= 0) {
         xobj.push(response[i]);
       }
      }
         this.gerencia = xobj;
         await this.util.cerrarSpinner();
    }
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.util.notificacionErrorApp(error.error.message);
    }
  }
  
  async modalrespuesta(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result !== 'cerrar'){
            await this.util.cerrarSpinner();
            this.forma.patchValue({
              cod_gerencia: this.forma.value.cod_gerencia.cod_unidad,
              des_gerencia: this.forma.value.cod_gerencia.des_unidad,
              cod_dpto: this.forma.value.cod_dpto.cod_unidad,
              des_dpto: this.forma.value.cod_dpto.des_unidad,
            });
            this.dialogRef.close(this.forma.value);
          } else{
            await this.util.cerrarSpinner();
          }
        });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  cerrarmodal(){
    let accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner()
      if (result != 'cerrar') {
        await this.util.cerrarSpinner();
        this.dialogRef.close('cerrar');
      }else{
        await this.util.cerrarSpinner()
      }
    });
  }
}
