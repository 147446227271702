
<div id="modal_carrito" class=" container-fluid">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div class="row h-100">
            <div class="col-md-3 h-100 border" style="background-color:#7f0b91; border-radius: 1rem 0 0 1rem;">
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; border-radius: 1rem 0 0 0; background-color:#7f0b91;">
                    <div class="col-md-12 px-0 py-2 h-100">
                        <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#191970">
                            <mat-form-field  class="materialinput py-1 px-1 ">
                                <mat-label style="color: rgb(255, 255, 255); font-size: 12px;">Cod Centro de Costo</mat-label>
                                <input id="cod_definicion" style="font-size: 12px; color: #fff; " type="text " matInput placeholder="" formControlName="cod_centro" #cod_definicion autocomplete="off" readonly>
                                <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#7f0b91;">
                    
                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                        <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#191970;">
                            <mat-form-field  class="materialinput py-1 px-1 ">
                                <mat-label style="color: rgb(255, 255, 255); font-size: 12px;">Usuarios</mat-label>
                                <mat-select id="usuarios"  #usuarios  style="font-size: 12px; color: rgb(255, 255, 255);" formControlName="cod_usuario" autocomplete="off">
                                    <mat-option  *ngFor="let item of usuarios_objeto" [value]="item.cod_usuario">{{item.nombre}} {{item.apellido}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
     
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#7f0b91;">
                    <div id="content_1" class="col-md-6 px-0 py-2 h-100">
                       
                    </div>
                    
                </div>
                
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#7f0b91;">

                    <div id="content_1" class="col-md-12 px-0 py-2 h-100">
                      
                    </div>

                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#7f0b91;">
        
                  
                    
        
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#7f0b91;">
        
                
        
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8 d-flex justify-content-end" style=" border: 2px solid #475bca; background-color:#7f0b91;">
        
                    <div id="content_boton1" class=" col-md-4 px-0 py-2 h-100">
                        <div class=" mx-1 h-100">
                            <button class="btn btn-block btn-lg btn-light h-100" (click)="listar_parametros_acciones_accesos_usuario()" matTooltip="ACTUALIZAR" style=" color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                          <mat-icon >cached</mat-icon>
                        </button>
                        </div>
                    </div>
                    
                    <div id="content_boton1" class=" col-md-4 px-0 py-2 h-100">
                        <div class=" mx-1 h-100">
                            <button class="btn btn-block btn-lg btn-light h-100" (click)="crearUsuario()" matTooltip="GUARDAR" style=" color:rgb(255, 255, 255); background-color: #191970;">
                              <mat-icon >save</mat-icon>
                          </button>
                        </div>
                    </div>
                    <div class=" col-md-4 px-0 py-2 h-100">
                        <div class="  mx-1 h-100">
                            <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                              <mat-icon >exit_to_app</mat-icon>
                          </button>
                        </div>
                    </div>
        
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#7f0b91; border-radius: 0 0 0 1rem;">
                    <div class="col-md-12 px-0 py-2 h-100">
                        <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#191970;">
                            <mat-form-field class="materialinput py-1 px-1">
                                <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                                <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                                <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                  <mat-icon>search</mat-icon>
                              </span>
                            </mat-form-field>
                        </div>
                    </div>
                  
                </div>
            </div>
            <div class="col-md-9 h-100 border" style="border-radius: 0 1rem 1rem 0;" >
                <div id="carrito_p8" class="row h-100" style=" border: 2px solid #2b69dd; border-radius: 0 1rem  1rem 0; background-color:#191970;">
                    <div class="col-md-12 col-sm-12 col-xs-12 px-2 py-2 table-responsive  h-100" >
                        <table #table mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">

                            <ng-container matColumnDef="cod_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Codigo</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="des_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Definición</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="descrip_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Descripción</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.descrip_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="cod_usuario">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Id</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_usuario}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="des_usuario">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Usuario</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_usuario}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="estatus">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Estatus</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="operacion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Operación</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.operacion}}</b></td>
                        
                            </ng-container>

                            <ng-container matColumnDef="fecha_inicio">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Fecha</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="val_def">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Valor</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;  border-radius: 1rem; "><b>{{element.val_def}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="hora_inicio">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Hora</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
                        
                            </ng-container>
                       
                            <ng-container matColumnDef="usuarios">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Usuarios</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px; color: #fff;">
                                    <button class="rounded-pill"  matTooltip="USUARIOS" (click)='monto_parametro_ventas(element)' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color:#4979d3; border:  outset 3px rgba(47, 40, 119, 0.692);">
                                  <mat-icon >accessibility_new</mat-icon>
                                  </button>
                                </td>
                                
                            </ng-container>

                            <ng-container matColumnDef="eliminar">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff;">
                                    <h5><strong>Eliminar</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                                    <button  class="rounded-pill"  matTooltip="ELIMINAR" (click)='borrarusuarioacciones(element)'  mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(42, 77, 194);border: outset 3px rgba(47, 40, 119, 0.692);">
                                        <mat-icon >delete</mat-icon>
                                    </button>
                                </td>
                               
                            </ng-container>

                            <ng-container matColumnDef="tiempo">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff;">
                                    <h5><strong>Historial</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                                    <button class="rounded-pill"  matTooltip="HISTORIAL" (click)='historial_parametros_ventas(element)' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(15, 177, 34); border: outset 3px rgba(47, 40, 119, 0.692);">
                                        <mat-icon >schedule</mat-icon>
                                    </button>
                                   
                                </td>
                               
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                        </table>
                        <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                    </div>
        
                </div>
            </div>
        </div>
        
    </form>
</div>



