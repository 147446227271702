

<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 1rem;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; background-color: rgb(36, 62, 155); border-radius: 1rem;">
                    <div class="modal-header" style="background-color: rgb(36, 62, 155); color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" class=" text-center"><strong>Para continuar debe colocar su Hash Key.</strong> </h3>


                    </div>
                    <div class="modal-body" style="background-color: rgb(36, 62, 155);">
                        <div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12">

                                    <div class="input-group form-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-key"></i></span>
                                        </div>
                                        <input type="password" class="form-control" placeholder="Ejemplo: 00-000000" autocomplete="off" formControlName="cod_control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" rounded-pill btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button mat-button [disabled]="forma.invalid" class=" rounded-pill btn-primary" (click)="buscarHash('eliminar')"> Enviar</button>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>