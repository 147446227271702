import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AtributosService } from '../../servicios/atributos.service';
import { AppUtilService } from '../../servicios/app.util.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { nkServices } from '../../servicios/app.nkservicios.services';

@Component({
  selector: 'app-form-clientes',
  templateUrl: './form-clientes.component.html',
  styleUrls: ['./form-clientes.component.css']
})

export class FormClientesComponent implements OnInit {

  @ViewChild('tipo_factura',{static:true}) tipo_factura:ElementRef;
  @ViewChild('tipo_consig',{static:true}) tipo_consig:ElementRef;
  @ViewChild('tipo',{static:true}) tipo:ElementRef;

  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V,G]{1}\d{9}/;
  hide:any = true;
nombre:any;
forma:FormGroup;
accion:any;
dialogSpin:any;
unidad:any;
listas:any = [];
tipo_cliente = [];
deposito_pt = [];
almacen:any = [];
almacen_cdn:any = [];
almacen_dstrbdr:any = [];
almacen_pvp:any = [];
tipo_um_und = [];
tipo_um_und_consig = [];
estados:any = [];
ciudad:any = [];
asociar:any;
estatus:any;
actualizar:any = false;
  constructor(
    private dialog: MatDialog,
    private servicio:nkServices,
    private fb:FormBuilder,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    private dialogRef: MatDialogRef<FormClientesComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) private data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.estados = data.estados;
        for (const i in this.data.tipos) {
            this.tipo_cliente.push(this.data.tipos[i]);
        }

        for (const i in this.data.tipo_um_und) {
          this.tipo_um_und.push(this.data.tipo_um_und[i]);
        }
        for (const i in this.data.tipo_um_und_consig) {
          this.tipo_um_und_consig.push(this.data.tipo_um_und_consig[i]);
        }

      this.forma = fb.group({
        'id_cliente':data.id_cliente,
        'cod_cliente':[data.cod_cliente,[Validators.required,Validators.pattern(this.atrb.CODREGEX),Validators.maxLength(10)]],
        'des_cliente':[data.des_cliente,[Validators.required]],
        'tipo_cliente':[data.tipo_cliente,[Validators.required]],
        'tipo_update':['',[Validators.required]],
        'cod_usuario': this.atrb.COD_USUARIO.cod_usuario,
        'des_usuario': this.atrb.NOMBRE_USUARIO.nombre+' '+this.atrb.APELLIDO_USUARIO.apellido,
        'listas_cliente':[data.precio ,[Validators.required]],
        'almacen_cliente': [data.dep,[Validators.required]],
        'dir_cliente':[data.dir_cliente,[Validators.required]],
        'pais_cliente':[''],
        'pais_estado_cliente':[data.pais_estado_cliente,[Validators.required]],
        'ciudad_cliente':[data.ciudad_cliente,[Validators.required]],
        'des_vend_cliente':[data.des_vend_cliente,[Validators.required]],
        'estatus_cliente':[data.estatus_cliente,[Validators.required]],
        'credito_dias_cliente':[data.credito_dias_cliente,[Validators.required]],
        'reten_cliente':[''],
        'telefono1_cliente':[data.telefono1_cliente,[Validators.required,Validators.pattern(this.atrb.TELREGEX),Validators.maxLength(12)]],
        'telefono2_cliente':[data.telefono2_cliente,[Validators.required,Validators.pattern(this.atrb.TELREGEX),Validators.maxLength(12)]],
        'correo_cliente':[data.correo_cliente,[Validators.required,Validators.pattern(this.atrb.EMAILREGEX)]],
        'mod_factur':[data.mod_factur,[Validators.required]],
        'mod_consig':[data.mod_consig,[Validators.required]],
        'cod_empresa':this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
      });
    }
  ngOnInit(){
    this.estatus = this.atrb.ESTATUS;
                      //////////////////////////////CDN////////////////////////////////////////
                if (this.data.tipo_cliente === this.atrb.CDN && this.data.mod_consig !== this.atrb.NO_APLICA) {
                  this.almacen_cdn = [];
                  for (const i in this.data.almacen_cdn) {
                    this.almacen_cdn.push(this.data.almacen_cdn[i]);
                }
                this.asociar = this.atrb.CDN;
                }
                if (this.data.tipo_cliente === this.atrb.CDN && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UM) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  }
                  this.asociar = this.atrb.PT;
                }
                if (this.data.tipo_cliente === this.atrb.CDN && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UND) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  }
                  this.asociar = this.atrb.PT;
                }
                if (this.data.tipo_cliente === this.atrb.CDN && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UNDP) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  }
                  this.asociar = this.atrb.PT;
                }
                //////////////////////////////CDN////////////////////////////////////////
                //////////////////////////////DSTRBDR////////////////////////////////////////
                if (this.data.tipo_cliente === this.atrb.DSTRBDR && this.data.mod_consig !== this.atrb.NO_APLICA) {
                  this.almacen_dstrbdr = [];
                  for (const i in this.data.almacen_cdn) {
                    this.almacen_cdn.push(this.data.almacen_cdn[i]);
                }
                this.asociar = this.atrb.DSTRBDR;
                }
                if (this.data.tipo_cliente === this.atrb.DSTRBDR && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UM) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  }
                  this.asociar = this.atrb.PT;
                }
                if (this.data.tipo_cliente === this.atrb.DSTRBDR && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UND) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  }
                  this.asociar = this.atrb.PT;
                }
                if (this.data.tipo_cliente === this.atrb.DSTRBDR && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UNDP) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  }
                  this.asociar = this.atrb.PT;
                }

                //////////////////////////////DSTRBDR////////////////////////////////////////
                //////////////////////////////PVP////////////////////////////////////////
                if (this.data.tipo_cliente === this.atrb.PVP && this.data.mod_consig !== this.atrb.NO_APLICA) {
                  this.almacen_pvp = [];
                  for (const i in this.data.almacen_pvp) {
                    this.almacen_pvp.push(this.data.almacen_pvp[i]);
                }
                console.log(this.almacen_pvp)
                this.asociar = this.atrb.PVP;
                }
                if (this.data.tipo_cliente === this.atrb.PVP && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UM) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  } 
                  console.log(this.deposito_pt)
                  this.asociar = this.atrb.PT;
                }
                if (this.data.tipo_cliente === this.atrb.PVP && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UND) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  }
                  console.log(this.deposito_pt)
                  console.log(this.forma.value.almacen_cliente)
                  this.asociar = this.atrb.PT;
                }
                if (this.data.tipo_cliente === this.atrb.PVP && this.data.mod_consig === this.atrb.NO_APLICA && this.data.mod_factur === this.atrb.UNDP) {
                  this.deposito_pt = [];
                  for (const i in this.data.deposito) {
                    if (this.data.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
                  }
                  console.log(this.deposito_pt)
                  this.asociar = this.atrb.PT;
                }

                //////////////////////////////PVP////////////////////////////////////////
                //////////////////////////////SPCL////////////////////////////////////////

                if (this.data.tipo_cliente === this.atrb.SPCL) {
                            this.listas = [];
                            this.almacen = [];
                            for (const i in this.data.almacen) {
                              this.almacen.push(this.data.almacen[i]);
                          }
                          for (const i in this.data.listas) {
                            this.listas.push(this.data.listas[i]);
                          }
                          this.asociar = this.atrb.SPCL;
                }
//////////////////////////////SPCL////////////////////////////////////////
this.ciudad_cliente_update(this.data.pais_estado_cliente);
  }
async modalrespuesta(){
          try {
            await this.util.abrirSpinner();
            await this.servicio.consultar_sesionhttp(null).toPromise();
            await this.util.cerrarSpinner();
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                maxWidth: '100vw',
                height:'auto',
                data: { 
                      accion:''},
                      disableClose: true,
                      panelClass: 'pedidos_modal',
                      backdropClass: "bdrop" 
              });
              dialogRef.afterClosed().subscribe(async result => {
                if(result != 'cerrar'){
                  this.dialogRef.close(this.forma.value);
                }else{
                  await this.util.cerrarSpinner();
                }
              });
          } catch (error) {
            await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(error.error.message);
          }
        }

 async asociarLista(xvalor:any){
    try {
      await this.util.abrirSpinner();
          if (xvalor.value === await this.atrb.SPCL && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {
            this.almacen = [];
            this.listas = [];
            for (const i in this.data.almacen) {
              this.almacen.push(this.data.almacen[i]);
          }
          for (const i in this.data.listas) {
            this.listas.push(this.data.listas[i]);
          }
            this.asociar = await this.atrb.SPCL;
          }
 
          if (xvalor.value === await this.atrb.SPCL && this.forma.value.mod_consig === await this.atrb.NO_APLICA) {
            this.almacen = [];
            this.listas = [];
            this.asociar = await this.atrb.SPCL;
              this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.seleccionar_consig);
          }

          if ( this.forma.value.mod_consig === await this.atrb.NO_APLICA && xvalor.value !== await this.atrb.SPCL) {
            this.deposito_pt = [];
            for (const i in this.data.deposito) {
                    if (this.forma.value.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                              
                      this.deposito_pt.push(this.data.deposito[i]);
                  }
            }
                    this.asociar = await this.atrb.PT;
          }
          if ( this.forma.value.mod_consig !== await this.atrb.NO_APLICA && xvalor.value !== await this.atrb.SPCL && xvalor.value !== await this.atrb.CDN) {
            this.deposito_pt = [];
            for (const i in this.data.deposito) {
              if (this.forma.value.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                        
                this.deposito_pt.push(this.data.deposito[i]);
            }
            }
                    this.asociar = await this.atrb.PT;
          }
/////////////////////////////////CDN///////////////////////////////////////////////
          if (xvalor.value === await this.atrb.CDN && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {
                    this.almacen_cdn = [];
                    for (const i in this.data.almacen_cdn) {
                      this.almacen_cdn.push(this.data.almacen_cdn[i]);
                  }
                    this.asociar = await this.atrb.CDN;
          }
/////////////////////////////////CDN///////////////////////////////////////////////
/////////////////////////////////DSTRBDR///////////////////////////////////////////////
          if (xvalor.value === await this.atrb.DSTRBDR && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {
                      this.almacen_dstrbdr = [];
                      for (const i in this.data.almacen_cdn) {
                        this.almacen_dstrbdr.push(this.data.almacen_dstrbdr[i]);
                    }
                      this.asociar = await this.atrb.DSTRBDR;
          }
/////////////////////////////////DSTRBDR///////////////////////////////////////////////
/////////////////////////////////PVP///////////////////////////////////////////////
          if (xvalor.value === await this.atrb.PVP && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {
                      this.almacen_pvp = [];
                      for (const i in this.data.almacen_pvp) {
                        this.almacen_pvp.push(this.data.almacen_pvp[i]);
                    }
                      this.asociar = await this.atrb.PVP;
          }
/////////////////////////////////PVP///////////////////////////////////////////////
        await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
async  modo_consig(xvalor:any){
    try {
     await this.util.abrirSpinner();

          if (xvalor.value !== await this.atrb.NO_APLICA && this.forma.value.tipo_cliente === await this.atrb.SPCL) {
            this.almacen = [];
            for (const i in this.data.almacen) {
              this.almacen.push(this.data.almacen[i]);
          }
          for (const i in this.data.listas) {
            this.listas.push(this.data.listas[i]);
          }
            this.asociar = await this.atrb.SPCL;
          }

          if (xvalor.value === await this.atrb.NO_APLICA && this.forma.value.tipo_cliente === await this.atrb.SPCL) {
            this.almacen = [];
            this.listas = [];
              this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.seleccionar_consig);
          }

          if ( xvalor.value === await this.atrb.NO_APLICA && this.forma.value.tipo_cliente !== await this.atrb.SPCL) {
            this.deposito_pt = [];
            for (const i in this.data.deposito) {
              if (this.forma.value.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                        
                this.deposito_pt.push(this.data.deposito[i]);
            }
            }
                    this.asociar = await this.atrb.PT;
          }

          //////////////////////////////CDN/////////////////////////////////////////
 
          if (xvalor.value !== await this.atrb.NO_APLICA && this.forma.value.tipo_cliente === await this.atrb.CDN) {
            this.almacen_cdn = [];
            for (const i in this.data.almacen_cdn) {
              this.almacen_cdn.push(this.data.almacen_cdn[i]);
          }
            this.asociar = await this.atrb.CDN;
          }
          if (xvalor.value === await this.atrb.NO_APLICA && this.forma.value.tipo_cliente === await this.atrb.CDN) {
            this.deposito_pt = [];
            for (const i in this.data.deposito) {
              if (this.forma.value.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                        
                this.deposito_pt.push(this.data.deposito[i]);
            }
            }
                    this.asociar = await this.atrb.PT;
          }
          //////////////////////////////CDN/////////////////////////////////////////

          //////////////////////////////DSTRBDR/////////////////////////////////////////
 
          if (xvalor.value !== await this.atrb.NO_APLICA && this.forma.value.tipo_cliente === await this.atrb.DSTRBDR) {
            this.almacen_dstrbdr = [];
            for (const i in this.data.almacen_dstrbdr) {
              this.almacen_dstrbdr.push(this.data.almacen_dstrbdr[i]);
          }
            this.asociar = await this.atrb.DSTRBDR;
          }
          if (xvalor.value === await this.atrb.NO_APLICA && this.forma.value.tipo_cliente === await this.atrb.DSTRBDR) {
            this.deposito_pt = [];
            for (const i in this.data.deposito) {
              if (this.forma.value.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                        
                this.deposito_pt.push(this.data.deposito[i]);
            }
            }
                    this.asociar = await this.atrb.PT;
          }
          //////////////////////////////DSTRBDR/////////////////////////////////////////

          //////////////////////////////PVP/////////////////////////////////////////
 
          if (xvalor.value !== await this.atrb.NO_APLICA && this.forma.value.tipo_cliente === await this.atrb.PVP) {
            this.almacen_pvp = [];
            for (const i in this.data.almacen_pvp) {
              this.almacen_pvp.push(this.data.almacen_pvp[i]);
          }
            this.asociar = await this.atrb.PVP;
          }
          if (xvalor.value === await this.atrb.NO_APLICA && this.forma.value.tipo_cliente === await this.atrb.PVP) {
            this.deposito_pt = [];
            for (const i in this.data.deposito) {
              if (this.forma.value.mod_factur.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                        
                this.deposito_pt.push(this.data.deposito[i]);
            }
            }
                    this.asociar = await this.atrb.PT;
          }
          //////////////////////////////PVP/////////////////////////////////////////
          await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
 async tipoupdate(xvalor:any){
    try {
     await this.util.abrirSpinner();
          if (xvalor.value === await this.atrb.COMPLETO) {
            this.actualizar = true;
            if (this.data.tipo_cliente === await this.atrb.SPCL) {
              this.asociar = await this.atrb.SPCL;
            }

            ///////////////////////////////CDN//////////////////////////////////////////////
            if (this.data.tipo_cliente === await this.atrb.CDN && this.data.mod_consig !== await this.atrb.NO_APLICA) {
              this.asociar = await this.atrb.CDN;
            }
        
            ///////////////////////////////CDN//////////////////////////////////////////////
            ///////////////////////////////DSTRBDR//////////////////////////////////////////////
            if (this.data.tipo_cliente === await this.atrb.DSTRBDR && this.data.mod_consig !== await this.atrb.NO_APLICA) {
              this.asociar = await this.atrb.DSTRBDR;
            }
            ///////////////////////////////DSTRBDR//////////////////////////////////////////////
            ///////////////////////////////PVP//////////////////////////////////////////////
            if (this.data.tipo_cliente === await this.atrb.PVP && this.data.mod_consig !== await this.atrb.NO_APLICA) {
              this.asociar = await this.atrb.PVP;
            }
            ///////////////////////////////PVP//////////////////////////////////////////////
            if ( this.data.mod_consig === await this.atrb.NO_APLICA) {
              this.asociar = await this.atrb.PT;
            }

          }else{
            this.actualizar = false;
          }
          await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }

  async asociarAlmacen(xvalor:any){
    try {
          await this.util.abrirSpinner();
          if (xvalor.value === await this.atrb.SPCL) {
            this.asociar = await this.atrb.SPCL;
          }

          ///////////////////////////////CDN//////////////////////////////////////////////
          if (xvalor.value === await this.atrb.CDN && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {
            this.asociar = await this.atrb.CDN;
          }

          ///////////////////////////////CDN//////////////////////////////////////////////
          ///////////////////////////////DSTRBDR//////////////////////////////////////////////
          if (xvalor.value === await this.atrb.DSTRBDR && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {
            this.asociar = await this.atrb.DSTRBDR;
          }
          ///////////////////////////////DSTRBDR//////////////////////////////////////////////
          ///////////////////////////////PVP//////////////////////////////////////////////
          if (xvalor.value === await this.atrb.PVP && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {
            this.asociar = await this.atrb.PVP;
          }
          ///////////////////////////////PVP//////////////////////////////////////////////
          if (this.forma.value.mod_consig === await this.atrb.NO_APLICA) {
            this.asociar = await this.atrb.PT;
          }         
          await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }

 async dep_unid_manejo(xvalor:any){
    try {
            await this.util.abrirSpinner();
          if (this.forma.value.tipo_cliente === await this.atrb.DSTRBDR ||
           this.forma.value.tipo_cliente === await this.atrb.CDN && this.forma.value.mod_consig === await this.atrb.NO_APLICA ||
            this.forma.value.tipo_cliente === await this.atrb.PVP) {
            this.deposito_pt = [];
            for (const i in this.data.deposito) {
             
              if (xvalor.value.toLowerCase() === this.data.deposito[i].tipo_manejo.toLowerCase()) {
                  
                  this.deposito_pt.push(this.data.deposito[i]);
              }
            }
          
          this.asociar = await this.atrb.PT;
          }
          if (this.forma.value.tipo_cliente === await this.atrb.CDN && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {

                 this.asociar = await this.atrb.CDN;
          }
                    if (this.forma.value.tipo_cliente === await this.atrb.DSTRBDR && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {

                      this.asociar = await this.atrb.DSTRBDR;
              }
              if (this.forma.value.tipo_cliente === await this.atrb.PVP && this.forma.value.mod_consig !== await this.atrb.NO_APLICA) {

                this.asociar = await this.atrb.PVP;
          }
          if (this.forma.value.tipo_cliente === await this.atrb.SPCL) {

            this.asociar = await this.atrb.SPCL;
     }
     await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
  async ciudad_cliente_update(xvalor:any){
    try {
      this.ciudad = [];
        await this.util.abrirSpinner();
              for (const i in this.data.ciudad) {
                if (xvalor.toUpperCase() === this.data.ciudad[i].estado.toUpperCase()) {
                  this.ciudad.push(this.data.ciudad[i].ciudad.toUpperCase());
                }
              }
     await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
  async ciudad_cliente(xvalor:any){
    try {
      this.ciudad = [];
        await this.util.abrirSpinner();
              for (const i in this.data.ciudad) {
                if (xvalor.value.toUpperCase() === this.data.ciudad[i].estado.toUpperCase()) {
                  this.ciudad.push(this.data.ciudad[i].ciudad.toUpperCase());
                }
              }
     await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
  async cerrarmodal(){
    try {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRef.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
    }
    }
}
