<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row" style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: rgb(13, 62, 102); padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>SEGUIMIENTO DE LAS ORDENES DE PRODUCCIÓN</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarSegOrdenesProd("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                </ul>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.4%; overflow-y: auto;">
        <div class="col-md-12 px-0  border h-100" style="background-color: rgb(13, 62, 102);">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
                <!-- Weight Column -->
                <ng-container matColumnDef="cod_orden_prod">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Cod orden</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_orden_prod}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">{{cantidad_item()}} </td>
                </ng-container>

                <ng-container matColumnDef="estatus_actual">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Estatus A</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus_actual}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Estatus A </td>
                </ng-container>

                <ng-container matColumnDef="estatus_cambio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Estatus C</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus_cambio}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Estatus C </td>
                </ng-container>

                <ng-container matColumnDef="cod_usuario_ini">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Cod usuario ini</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_usuario_ini}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Cod usuario ini </td>
                </ng-container>

                <ng-container matColumnDef="des_usuario_ini">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Usuario ini</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario_ini}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Usuario ini </td>
                </ng-container>

                <ng-container matColumnDef="tarea_ord">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Tarea</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.tarea_ord}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Tarea </td>
                </ng-container>

                <ng-container matColumnDef="fecha_ini">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Fecha ini</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha_ini}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Fecha ini </td>
                </ng-container>

                <ng-container matColumnDef="hora_ini">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Hora ini </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_ini}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Hora ini </td>
                </ng-container>

                <ng-container matColumnDef="cod_usuario_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Cod usuario fin</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_usuario_fin}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Cod usuario fin</td>
                </ng-container>

                <ng-container matColumnDef="des_usuario_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Usuario fin</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario_fin}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Usuario fin </td>
                </ng-container>


                <ng-container matColumnDef="fecha_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Fecha fin </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha_fin}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Fecha fin </td>
                </ng-container>

                <ng-container matColumnDef="hora_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Hora fin</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_fin}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Hora fin </td>
                </ng-container>

                <ng-container matColumnDef="estatus_tarea">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus_tarea}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Estatus </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="detalle">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Detalle</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center" (click)='detalleOrdenProd(element)'>
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                      <mat-icon>image_search</mat-icon>
                                    </button>
                            </div>
                        </section>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Detalle </td>
                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center">
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                        <mat-icon>print</mat-icon>
                                      </button>
                            </div>
                        </section>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Imprimir </td>
                </ng-container>

                <ng-container matColumnDef="descargar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center">
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" style="margin: 1%;" aria-label="Example icon button with a delete icon">
                                        <mat-icon>get_app</mat-icon>
                                      </button>
                            </div>
                        </section>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Descargar </td>
                </ng-container>
                <ng-container matColumnDef="confirmar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(13, 62, 102);">
                        <h5><strong>Confirmar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center" (click)='confirmacionOrdenProd(element)'>
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" style="margin: 1%;" aria-label="Example icon button with a delete icon">
                                      <mat-icon>check</mat-icon>
                                    </button>
                            </div>
                        </section>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(13, 62, 102); color: #fff;">Confirmar </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true "></tr>
            </table>
        </div>

        <!-- <mat-paginator [pageSizeOptions]="[5, 7, 9]" showFirstLastButtons></mat-paginator> -->
    </div>
</div>