import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-estructura',
  templateUrl: './form-estructura.component.html',
  styleUrls: ['./form-estructura.component.css']
})
export class FormEstructuraComponent implements OnInit {
  forma:FormGroup;
  accion:any;
  gerencia:any;
dpto:any;
cargo:any;
estatus:any;
rlsr:any;
  constructor(
    private dialog: MatDialog,
    private fb:FormBuilder,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    private servicio:nkServices,
    private dialogRef: MatDialogRef<FormEstructuraComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {
    console.log(data, 'primero');
    this.accion = data.accion;
    this.rlsr =  hashkey.decrypt(localStorage.getItem('rlsr'));
    this.forma = fb.group({
      'gerencia':['',[Validators.required]],
      'dpto':['',[Validators.required]],
      'des_cargo':['',[Validators.required,Validators.maxLength(40)]],
      'estatus':['',[Validators.required]],
      'operacion':[data.operacion],
      'cod_empresa':[''],
    });
   }

  ngOnInit(){
    this.listarUnidad(null);
  }

  async listarUnidad(xvalor:any){
    try {
      await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        let valor = {
          api: await this.hashkey.encrypt(await this.atrb.API_REST.listarUnidades),
        }
        const response = await this.servicio.ApiResthttp(valor).toPromise();
        this.cargo = [];
        this.estatus = await this.atrb.ESTATUS;
      if (xvalor !== null) {
        if (parseInt(xvalor.value.cod_unidad_padre) <= 0) {
          let xobj = [];
        for (const i in response) {
        if (parseInt(xvalor.value.cod_unidad) ===  parseInt(response[i].cod_unidad_padre) ) {
          xobj.push(response[i]);
        }
        }
          this.dpto = xobj;
          await this.util.cerrarSpinner();
        }else{
          let xobj = [];
          for (const i in this.data.cargos) {
            xobj.push(this.data.cargos[i].concepto_definicion);
          }
          this.cargo = xobj;
          await this.util.cerrarSpinner();
        }
    }else{
      let xobj = [];
      if ((parseInt(this.rlsr) <= 1)) {
        for (const i in response) {
          if (parseInt(response[i].cod_unidad_padre) <= 0) {
            xobj.push(response[i]);
          }
         }
      }else{
        for (const i in response) {
          if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() !== await this.atrb.GENERAL.toUpperCase()) {
            xobj.push(response[i]);
          }
         }
      }
         this.gerencia = xobj;
         await this.util.cerrarSpinner();
    }
    
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.util.notificacionErrorApp(error.error.message);
    }
  }
  async guardarEstructura(res:any){
    try {
      var accion = '';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner()
        if (result !== 'cerrar') {
          await this.util.cerrarSpinner();
          this.forma.patchValue({
            cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
          });
          this.dialogRef.close(this.forma.value);
        }else{
          await this.util.cerrarSpinner()
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  cerrarmodal(){
    let accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner()
      if (result != 'cerrar') {
        await this.util.cerrarSpinner();
        this.dialogRef.close('cerrar');
      }else{
        await this.util.cerrarSpinner()
      }
    });
  }
}
