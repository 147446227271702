import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatTableDataSource} from '@angular/material/table';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import * as moment from 'moment'
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormPrecioCotizarComponent } from '../form-precio-cotizar/form-precio-cotizar.component';
import { FormSelectTCotizacionComponent } from '../form-select-t-cotizacion/form-select-t-cotizacion.component';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';


@Component({
  selector: 'app-form-cotizacion-req',
  templateUrl: './form-cotizacion-req.component.html',
  styleUrls: ['./form-cotizacion-req.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false, showError: true}
  }]
})
export class FormCotizacionReqComponent implements OnInit {

  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA1:any[]=[];
  ELEMENT_DATA2:any[]=[];
  ELEMENT_DATA3:any[]=[];
  displayedColumns:string[];
  dolar1:any = 0
  dolar2:any = 0
  dolar3:any = 0
  bolivar1:any = 0
  bolivar2:any = 0
  bolivar3:any = 0
  filteredOptions1: Observable<any[]>;
  options1:any;
  filteredOptions2: Observable<any[]>;
  options2:any;
  filteredOptions3: Observable<any[]>;
  options3:any;
  ver:any = false;
  editar:any = false;
  cod:any;

  /*CONTROLADOR DEL FORMULARIO*/
  CantidadCot:any 
  /*CONTROLADOR DEL FORMULARIO*/
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  dataSource1=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  dataSource3=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 verticalPosition: MatSnackBarVerticalPosition = 'top';

 constructor( public dialog: MatDialog,fb:FormBuilder,
  public dialogRefp: MatDialogRef<FormCotizacionReqComponent>,
  ///en el injectable llega la informacion del metodo padre en data
 @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices) { 

  if(data.accion == 'ver'){
    this.ver = true
  }
  if(data.accion == 'editar'){
    this.editar = true
  }

  this.cod = data.cod_cotizacion_req

  if(this.ver || this.editar){

    this.firstFormGroup = fb.group({
      rif: [data.cotizacion_1.rif, Validators.required],
      nombre: [data.cotizacion_1.empresa, Validators.required],
      direccion: [data.cotizacion_1.direccion, Validators.required],
      observacion: [data.cotizacion_1.observacion, Validators.required],
    });
    this.secondFormGroup = fb.group({
      rif: [data.cotizacion_2.rif, Validators.required],
      nombre: [data.cotizacion_2.empresa, Validators.required],
      direccion: [data.cotizacion_2.direccion, Validators.required],
      observacion: [data.cotizacion_2.observacion, Validators.required],
    });
    this.thirdFormGroup = fb.group({
      rif: [data.cotizacion_3.rif, Validators.required],
      nombre: [data.cotizacion_3.empresa, Validators.required],
      direccion: [data.cotizacion_3.direccion, Validators.required],
      observacion: [data.cotizacion_3.observacion, Validators.required],
    });

  }else{

    this.firstFormGroup = fb.group({
      rif: [''],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      observacion: ['', Validators.required],
    });
    this.secondFormGroup =  fb.group({
      rif: [''],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      observacion: ['', Validators.required],
    });
    this.thirdFormGroup = fb.group({
      rif: [''],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      observacion: ['', Validators.required],
    });

  }

  
  

  console.log(data, 'la data')
 
 }
  ngOnInit(): void {

    console.log(this.firstFormGroup)

    if(this.ver|| this.editar){

      this.CantidadCot = this.data.cant_cot

      this.cod = this.data.infoCot.cod_cotizacion
      if(this.editar){
        this.mostrarTablaEditar()

        this.servicio.listarProveedoreshttp().subscribe((responde: any[]) => {

          this.options1 = responde.filter(ele => Number(ele.isActive))
          this.options2 = responde.filter(ele => Number(ele.isActive))
          this.options3 = responde.filter(ele => Number(ele.isActive))
  
          this.filteredOptions1 = this.firstFormGroup.controls.rif.valueChanges.pipe(
            startWith(''),
            map(state => (state ? this._filter1(state) : this.options1.slice())));
          console.log(this.filteredOptions1,'filteredOptions1' )
  
          this.filteredOptions2 = this.secondFormGroup.controls.rif.valueChanges.pipe(
            startWith(''),
            map(state => (state ? this._filter2(state) : this.options1.slice())));
          console.log(this.filteredOptions2,'filteredOptions2' )
  
          this.filteredOptions3 = this.thirdFormGroup.controls.rif.valueChanges.pipe(
            startWith(''),
            map(state => (state ? this._filter3(state) : this.options1.slice())));
          console.log(this.filteredOptions3,'filteredOptions3' )
  
        })
      }else{
        this.mostrarTablaVer()
      }

    }else{

      this.formCantidadCotizacion()

      this.CantidadCot = '3'

      this.mostrarTabla()

      this.servicio.listarProveedoreshttp().subscribe((responde: any[]) => {

        this.options1 = responde.filter(ele => Number(ele.isActive))
        this.options2 = responde.filter(ele => Number(ele.isActive))
        this.options3 = responde.filter(ele => Number(ele.isActive))

        this.filteredOptions1 = this.firstFormGroup.controls.rif.valueChanges.pipe(
          startWith(''),
          map(state => (state ? this._filter1(state) : this.options1.slice())));
        console.log(this.filteredOptions1,'filteredOptions1' )

        this.filteredOptions2 = this.secondFormGroup.controls.rif.valueChanges.pipe(
          startWith(''),
          map(state => (state ? this._filter2(state) : this.options1.slice())));
        console.log(this.filteredOptions2,'filteredOptions2' )

        this.filteredOptions3 = this.thirdFormGroup.controls.rif.valueChanges.pipe(
          startWith(''),
          map(state => (state ? this._filter3(state) : this.options1.slice())));
        console.log(this.filteredOptions3,'filteredOptions3' )

      })

      
    }

    
  }

  private _filter1(value: string): any[] {
    const filterValue = value.toLowerCase();  
    return this.options1.filter(option => option.cod_proveedor.toLowerCase().includes(filterValue));
  }
  private _filter2(value: string): any[] {
    const filterValue = value.toLowerCase();  
    return this.options2.filter(option => option.cod_proveedor.toLowerCase().includes(filterValue));
  }
  private _filter3(value: string): any[] {
    const filterValue = value.toLowerCase();  
    return this.options3.filter(option => option.cod_proveedor.toLowerCase().includes(filterValue));
  }

  mostrarTabla(){

      this.servicio.listarProductosCotizacionhttp(this.data.cod_cotizacion_req).subscribe(result => {
  
        console.log(result.result)
  
        if(result.result){
          result.result.forEach(element => {

            if(element.cant_producto_ap != 0){

              let obj1 = {
                cod_orden_req:element.cod_orden_req,
                cod_producto:element.cod_producto,
                des_producto:element.des_producto,
                cant_aprobada:element.cant_producto_ap,
                cant_producto_cot:'0',
                precio:'0',
                precio_total:'0',
                tipo_unidad_medida:element.tipo_unidad_medida,
                tipo_producto:element.tipo_producto,
                check_estatus: false,
                tabla: '1'
                  }
              let obj2 = {
                cod_orden_req:element.cod_orden_req,
                cod_producto:element.cod_producto,
                des_producto:element.des_producto,
                cant_aprobada:element.cant_producto_ap,
                cant_producto_cot:'0',
                precio:'0',
                precio_total:'0',
                tipo_unidad_medida:element.tipo_unidad_medida,
                tipo_producto:element.tipo_producto,
                check_estatus: false,
                tabla: '2'
                  }
              let obj3 = {
                cod_orden_req:element.cod_orden_req,
                cod_producto:element.cod_producto,
                des_producto:element.des_producto,
                cant_aprobada:element.cant_producto_ap,
                cant_producto_cot:'0',
                precio:'0',
                precio_total:'0',
                tipo_unidad_medida:element.tipo_unidad_medida,
                tipo_producto:element.tipo_producto,
                check_estatus: false,
                tabla: '3'
                  }

                this.ELEMENT_DATA1.push(obj1)
                this.ELEMENT_DATA2.push(obj2)
                this.ELEMENT_DATA3.push(obj3)

            }
  
           
          });
        }
      
  
        this.displayedColumns = [
          'cod_producto',
          'des_producto',
          'cant_aprobada',
          'cant_producto_cot',
          'tipo_unidad_medida',
          'precio',
          'precio_total',
          'tomar_data_ingresada', 
        ];
        
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA1);
        this.dataSource1.paginator = this.paginator;
        this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
        this.dataSource2.paginator = this.paginator;
        this.dataSource3 = new MatTableDataSource<any>(this.ELEMENT_DATA3);
        this.dataSource3.paginator = this.paginator;

      
  
      },
      error => {
        
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
        
          this.displayedColumns = [
            'cod_producto',
            'des_producto',
            'cant_aprobada',
            'cant_producto_cot',
            'tipo_unidad_medida',
            'precio',
            'precio_total',
            'tomar_data_ingresada', 
          ];
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA1);
        this.dataSource1.paginator = this.paginator;
        this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
        this.dataSource2.paginator = this.paginator;
        this.dataSource3 = new MatTableDataSource<any>(this.ELEMENT_DATA3);
        this.dataSource3.paginator = this.paginator;

      })

  }

  mostrarTablaVer(){

        this.ELEMENT_DATA1 = this.data.cotizacion_1.productos
        this.ELEMENT_DATA2 = this.data.cotizacion_2.productos
        this.ELEMENT_DATA3 = this.data.cotizacion_3.productos

        this.sumarPrecioTotal(this.ELEMENT_DATA1)
        this.sumarPrecioTotal(this.ELEMENT_DATA2)
        this.sumarPrecioTotal(this.ELEMENT_DATA3)
      
  
        this.displayedColumns = [
          'cod_producto',
          'des_producto',
          'cant_producto_cot',
          'tipo_unidad_medida',
          'precio',
          'precio_total',
        ];
        
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA1);
        this.dataSource1.paginator = this.paginator;
        this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
        this.dataSource2.paginator = this.paginator;
        this.dataSource3 = new MatTableDataSource<any>(this.ELEMENT_DATA3);
        this.dataSource3.paginator = this.paginator;
     
    
  }
  mostrarTablaEditar(){

    this.ELEMENT_DATA1 = this.data.cotizacion_1.productos.map(element => ({...element,check_estatus: true,tabla: '1'}))
    this.ELEMENT_DATA2 = this.data.cotizacion_2.productos.map(element => ({...element,check_estatus: true,tabla: '2'}))
    this.ELEMENT_DATA3 = this.data.cotizacion_3.productos.map(element => ({...element,check_estatus: true,tabla: '3'}))

    this.sumarPrecioTotal(this.ELEMENT_DATA1)
    this.sumarPrecioTotal(this.ELEMENT_DATA2)
    this.sumarPrecioTotal(this.ELEMENT_DATA3)
  

    this.displayedColumns = [
      'cod_producto',
      'des_producto',
      'cant_producto_cot',
      'tipo_unidad_medida',
      'precio',
      'precio_total', 
      'tomar_data_ingresada',
    ];
    
    /* this.dataSource = this.ELEMENT_DATA; */
    this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA1);
    this.dataSource1.paginator = this.paginator;
    this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
    this.dataSource2.paginator = this.paginator;
    this.dataSource3 = new MatTableDataSource<any>(this.ELEMENT_DATA3);
    this.dataSource3.paginator = this.paginator;
 

  }

  captar(ev:any, tabla:any){
    let forma = tabla == 1 ? this.firstFormGroup : tabla == 2 ? this.secondFormGroup : this.thirdFormGroup
    if(ev.target.value.length === 0){
      return forma.reset()
    }
    return
  }

  captarlo(ev:any, tabla:any){
    console.log(ev);
    const forma:any = tabla == 1 ? this.firstFormGroup : tabla == 2 ? this.secondFormGroup : this.thirdFormGroup
    const forma2:any = tabla == 1 ? this.secondFormGroup : tabla == 2 ? this.firstFormGroup : this.secondFormGroup
    const forma3:any = tabla == 1 ? this.thirdFormGroup : tabla == 2 ? this.thirdFormGroup : this.firstFormGroup
    const opciones = tabla == 1 ? this.options1 : tabla == 2 ? this.options2 : this.options3

    const elobjeto = opciones.find(element => element.cod_proveedor == ev.option.value)

    if(elobjeto.cod_proveedor === forma2.value.rif || elobjeto.cod_proveedor === forma3.value.rif){
      this.snackBar.open(`Ya has cotizado con ${elobjeto.des_proveedor}`, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      return
    }else{
      if(elobjeto.cod_proveedor === ev.option.value){
        console.log(elobjeto.des_proveedor,ev.option.value);
        forma.patchValue({
          direccion:`${elobjeto.pais_prov ? elobjeto.pais_prov+',' : ''}${elobjeto.estado_prov ? elobjeto.estado_prov+',' : ''}${elobjeto.ciudad_prov ? elobjeto.ciudad_prov+',' : ''}${elobjeto.dir_prov ? elobjeto.dir_prov+',' : ''}`,
          nombre: elobjeto.des_proveedor,
          rif:elobjeto.cod_proveedor
        });
      }
      
    }
  }

  tomar_data(xvalor:any, xvalor2:any, xvalor3:any){

    let elementData = xvalor3 == '1' ? this.ELEMENT_DATA1 : xvalor3 == '2' ? this.ELEMENT_DATA2 : this.ELEMENT_DATA3
    console.log(xvalor2,'valor2');
    if(xvalor){ 
      var dialogRef = this.dialog.open(FormPrecioCotizarComponent, {
        width:'100vw',
        maxWidth:'100vw',
        height:'100%',
        data: {des_producto:xvalor2.des_producto,
              cant_producto_soli:xvalor2.cant_producto_soli,
              cod_producto:xvalor2.cod_producto,
              cant_ingresada:xvalor2.cant_ingresada,
              tipo_unidad_medida:xvalor2.tipo_unidad_medida,
              cant_producto_ap: this.editar ? xvalor2.cant_solicitada :xvalor2.cant_aprobada,
              fase:this.data.fase
              },
              disableClose:true,
        panelClass:'pedidos_modal',
        backdropClass:"bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {

        if(result != 'cerrar'){

          elementData.forEach(element=> {

            if(element.cod_producto == result.cod_producto && xvalor3 === element.tabla && element.cod_orden_req == xvalor2.cod_orden_req){

              element.precio = result.cant_ingresada
              element.check_estatus = true
              element.cod_orden_req = xvalor2.cod_orden_req
              element.cant_producto_cot = result.cant_producto_cot

              console.log(element)

              let moneda = this.editar ? element.moneda : element.precio.split(' ')[1]

              let day = parseFloat(result.cant_ingresada.replace(',','.').split(' ')[0]) 

              let o =  parseFloat(element.cant_producto_cot.replace(',','.'))

              let laFormula = o*day


              if(moneda == '$'){

                if(xvalor3 == '1'){

                  this.dolar1 = (Number(this.dolar1) + laFormula).toFixed(2)
                  element.precio_total = laFormula.toFixed(2)+' '+moneda
                  
                }
                if(xvalor3 == '2'){

                  this.dolar2 = (Number(this.dolar2) + laFormula).toFixed(2)
                  element.precio_total = laFormula.toFixed(2)+' '+moneda

                }
                if(xvalor3 == '3'){

                  this.dolar3 = (Number(this.dolar3) + laFormula).toFixed(2)
                  element.precio_total =laFormula.toFixed(2)+' '+moneda

                }
                
              }

              if(moneda == 'Bs'){
            
                if(xvalor3 == '1'){

                  this.bolivar1 = (Number(this.bolivar1) + laFormula).toFixed(2)
                  element.precio_total = laFormula.toFixed(2)+' '+moneda
                  
                }
                if(xvalor3 == '2'){

                  this.bolivar2 = (Number(this.bolivar2) + laFormula).toFixed(2)
                  element.precio_total = laFormula.toFixed(2)+' '+moneda

                }
                if(xvalor3 == '3'){

                  this.bolivar3 = (Number(this.bolivar3) + laFormula).toFixed(2)
                  element.precio_total = laFormula.toFixed(2)+' '+moneda
                  
                }
                
              }
              

            }


          })
  
          

        }else{

          
          elementData.forEach(element=> {
            if(element.cod_producto == result.cod_producto){
              
              element.check_estatus = false

            }
          })
  

        }

        console.log(xvalor3)
       
      })

    }else{

      elementData.forEach(element=> {

        if(element.cod_producto == xvalor2.cod_producto && xvalor3 === element.tabla && element.cod_orden_req == xvalor2.cod_orden_req){
          console.log(xvalor2,'valor2');
          let moneda = this.editar ? element.moneda : element.precio.split(' ')[1]

          let day = parseFloat(element.precio.replace(',','.').split(' ')[0]) 

          let o =  parseFloat(element.cant_producto_cot.replace(',','.'))

          let laFormula = o*day

          if(moneda == '$'){

            if(xvalor3 == '1'){

              this.dolar1 = (Number(this.dolar1) - laFormula).toFixed(2)
              element.precio_total = '0'
              
            }
            if(xvalor3 == '2'){

              this.dolar2 = (Number(this.dolar2) - laFormula).toFixed(2)
              element.precio_total = '0'

            }
            if(xvalor3 == '3'){

              this.dolar3 = (Number(this.dolar3) - laFormula).toFixed(2)
              element.precio_total = '0'
            }
            
          }

          if(moneda == 'Bs'){

            if(xvalor3 == '1'){

              this.bolivar1 = (Number(this.bolivar1) - laFormula).toFixed(2)
              element.precio_total = '0'
              
            }
            if(xvalor3 == '2'){

              this.bolivar2 = (Number(this.bolivar1) - laFormula).toFixed(2)
              element.precio_total = '0'

            }
            if(xvalor3 == '3'){

              this.bolivar3 = (Number(this.bolivar1) - laFormula).toFixed(2)
              element.precio_total = '0'
              
            }
            
          }
              
          element.check_estatus = false

          element.precio = '0'        
          element.cant_producto_cot = '0'        

        }

      })

    }

  }

  enviarCotizacion(){

    let contador = 0
    let obj

    this.ELEMENT_DATA1.forEach(element =>{if(element.precio == '0')contador++})
    if(this.CantidadCot == '3'){
      this.ELEMENT_DATA2.forEach(element =>{if(element.precio == '0')contador++})
      this.ELEMENT_DATA3.forEach(element =>{if(element.precio == '0')contador++})
    }
   
    console.log(contador)

    if(contador){
      this.snackBar.open(`Tenes ${contador} precios en 0`, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      return
    }

    this.firstFormGroup.value.productos = this.ELEMENT_DATA1

    if(this.CantidadCot == '3'){
      this.secondFormGroup.value.productos = this.ELEMENT_DATA2
      this.thirdFormGroup.value.productos = this.ELEMENT_DATA3

      obj = {
        cod_cotizacion_req: this.editar? this.data.infoCot.cod_cotizacion : this.data.cod_cotizacion_req,
        cotizaciones: this.CantidadCot,
        info:this.data,
        cotizacion_1:this.firstFormGroup.value,
        cotizacion_2:this.secondFormGroup.value,
        cotizacion_3:this.thirdFormGroup.value,
        usuario: {
                    cod_usuario: localStorage.getItem('cod_usuario'),
                    des_usuario: localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido')
                  }
        }

    }else{

      obj = {
        cod_cotizacion_req: this.editar? this.data.infoCot.cod_cotizacion : this.data.cod_cotizacion_req,
        cotizaciones: this.CantidadCot,
        info:this.data,
        cotizacion_1:this.firstFormGroup.value,
        usuario: {
                    cod_usuario: localStorage.getItem('cod_usuario'),
                    des_usuario: localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido')
                  }
        }


    }

    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
              accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(
      result => {
      if (result != 'cerrar') {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });

        /* this.servicio.validarProveedoreshttp(
                                              this.firstFormGroup.value.rif,
                                              this.secondFormGroup.value.rif,
                                              this.thirdFormGroup.value.rif,
                                            ).subscribe(response => { */

            this.servicio.crearCotizacionOrdenhttp(obj).subscribe(result => {

              this.dialogSpin.close()
              this.dialogRefp.close()

              console.log(result)

            },
            error => {
              this.dialogSpin.close()

              this.snackBar.open(JSON.stringify(error), 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            })    

        /* },
        error => {
          console.log(error)
        this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }) */

      }
    })
  
  }

  formCantidadCotizacion(){

    var dialogRef = this.dialog.open(FormSelectTCotizacionComponent, {
      width:'100vw',
      maxWidth:'100vw',
      height:'100%',
      data: '',
      disableClose:true,
      panelClass:'pedidos_modal',
      backdropClass:"bdrop"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){

        console.log(result);

        this.CantidadCot = result

      }else{

        this.dialogRefp.close()

      }
    })

  }

  async imprimirCotizacion(){
    try {
        const fontsSize:any = 9 
        var data:any = this.data.resumen
        var items:any= 0;
        const pdfMake = window["pdfMake"]
        const tablaEnLista:any = generarTabla(data)
        var dia = moment(this.data.infoCot.fecha, 'DD/MM/YYYY').format('DD')
        var mes = moment(this.data.infoCot.fecha, 'DD/MM/YYYY').format('MM')
        var anho = moment(this.data.infoCot.fecha, 'DD/MM/YYYY').format('YYYY')

        function generarTabla(dato:any){
          
          /* for(let i = 0; i < 5 ; i++ ){
                          
            if(dato[i] === undefined){
              
              let articuloVacio =  {
                   cod_producto: ' ',
                   des_producto: ' ',
                   cant_producto: ' ',
                   partida: ' ',
                   des_partida:'',
                   tipo_pro:''
              }
              dato[i] = articuloVacio
            }
          } */
            let arry:any =[
              [
                  {text: 'Codigo', alignment: 'center', fontSize: 7, border: [true, true, true, true],bold:true}, 
                  {text: 'Descripción de producto/servicio', alignment: 'center', fontSize: 7, border: [false, true, true, true],bold:true}, 
                  {text: 'Unidad de medida', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'Cantidad solicitada', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  //PROV 1
                  {text: 'Cant. min de venta', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'P. Unitario', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'Total proveedor', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  //PROV 2
                  {text: 'Cant. min de venta', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'P. Unitario', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'Total proveedor', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  //PROV 3
                  {text: 'Cant. min de venta', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'P. Unitario', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'Total proveedor', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
              ],
          ]
          for (const i in dato) {

              if(dato[i].cod_producto > 0){

                  items = dato[i].length
              }
                
              let articulo:any = [
                  {text: dato[i].cod_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
                  {text: dato[i].des_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
                  {text: dato[i].tipo_unidad_medida, alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].cant_solicitada, alignment: 'center', fontSize: 6,border: [false, false, true, false],},
                  //PROV 1
                  {text: dato[i].cant_producto_cot[0], alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_cot_1+' '+dato[i].moneda_1 , alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
                  {text: dato[i].precio_total_cot_1+' '+dato[i].moneda_1, alignment: 'center', fontSize: 6,border: [false, false, true, false],},
                  //PROV 2
                  {text: dato[i].cant_producto_cot[1], alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_cot_2 ? dato[i].precio_cot_2+' '+dato[i].moneda_2 : '-', alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_total_cot_2 ? dato[i].precio_total_cot_2+' '+dato[i].moneda_2 : '-', alignment: 'center', fontSize: 6,border: [false, false, true, false],},
                  //PROV 3
                  {text: dato[i].cant_producto_cot[2], alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_cot_3 ? dato[i].precio_cot_3+' '+dato[i].moneda_3 : '-', alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_total_cot_3 ? dato[i].precio_total_cot_3+' '+dato[i].moneda_3 : '-', alignment: 'center', fontSize: 6,border: [false, false, true, false],},
              ]
              arry.push(articulo)
          }
          return arry;
        }
          
        
        const pdfDefinition: any = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          background: [
            { image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/fondo_pdf_horizontal.png"), width: 850, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
          ],
          info:{
            title:`Cuadro comparativo de cotización ${this.data.infoCot.cod_cotizacion}`,
            author: 'FACTORY COSMETICS 3DG, C.A',
            subject:`Cuadro comparativo de cotización ${this.data.infoCot.cod_cotizacion}`,
          },
          content: [
            {
              style:'header',
              table:{
              widths: [100, 480, 150,],
              body:
                [
                  [
                    {image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/logo-factory.png"), fit:[80,80],alignment:'left',},
                    {text:'\nFACTORY COSMETICS 3DG, C.A.\n \
                            RIF.: J-41031797-3',alignment:'center',fontSize:11,bold:true,},
                    /* {text:'    ', colSpan: 1,}, */
                    {style:'fecha',
                      table: {
                        body: [
                          [
                            {text:'Fecha',alignment:'center',fontSize:7,colSpan:3,border:[true, true, true, true],},
                            {text:'',alignment:'center',fontSize:7,},
                            {text:'',alignment:'center',fontSize:7,},
                          ],
                          [
                            {text:'Dia',alignment:'center',fontSize:7,border:[true, true, true, true],},
                            {text:'Mes',alignment:'center',fontSize:7,border:[true, true, true, true],},
                            {text:'Año',alignment:'center',fontSize:7,border:[true, true, true, true],},
                          ],
                          [
                            {text:dia,alignment:'center',fontSize:7,border:[true, true, true, true],},
                            {text:mes,alignment:'center',fontSize:7,border:[true, true, true, true],},
                            {text:anho,alignment:'center',fontSize:7,border:[true, true, true, true],},
                          ],
                        ],
                      },
                    },
                  ],
                ],
              },layout: {
                defaultBorder: false,
              },
            },
            {text:`CUADRO COMPARATIVO DE COTIZACIÓN - ${this.data.infoCot.cod_cotizacion}`, alignment:'center',bold:true,fontSize:11},
            {
              style:'proveedores',
              table: {
                widths: [40,190.8,148,148.2,148.1],
                body: [
                  [
                      {text:' ',border:[false, false, false, false],},
                      {text:' ',border:[false, false, false, false],},
                      {text:'PROVEEDOR 1',border:[true, true, true, false],bold:true,fontSize:7,alignment:'center'},
                      {text:'PROVEEDOR 2',border:[true, true, true, false],bold:true,fontSize:7,alignment:'center'},
                      {text:'PROVEEDOR 3',border:[true, true, true, false],bold:true,fontSize:7,alignment:'center'},
                  ],
                ],
              },
            },
            {
              style:'proveedores_info',
              table: {
                widths: [40,190.8,148,148.2,148.1],
                body: [
                  [
                      {text:' ',border:[false, false, false, false],},
                      {text:'Nombre o Razon Social',border:[true, true, true, false],fontSize:7,alignment:'center',},
                      {text:this.data.cotizacion_1.empresa ,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text:this.data.cotizacion_2.empresa == 'N/A' ? '-' : this.data.cotizacion_2.empresa ,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text:this.data.cotizacion_3.empresa == 'N/A' ? '-' : this.data.cotizacion_3.empresa,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                  ],
                  [
                      {text:' ',border:[false, false, false, false],},
                      {text:'RIF',border:[true, true, true, false],fontSize:7,alignment:'center',},
                      {text: this.data.cotizacion_1.rif,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text: this.data.cotizacion_2.rif == 'N/A' ? '-' : this.data.cotizacion_2.rif ,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text: this.data.cotizacion_3.rif == 'N/A' ? '-' : this.data.cotizacion_3.rif ,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                  ],
                  [
                      {text:' ',border:[false, false, false, false],},
                      {text:'Direccion',border:[true, true, true, true],fontSize:7,alignment:'center',},
                      {text: this.data.cotizacion_1.direccion,border:[true, true, true, true],bold:false,fontSize:6,alignment:'center'},
                      {text:this.data.cotizacion_2.direccion == 'N/A' ? '-' : this.data.cotizacion_2.direccion ,border:[true, true, true, true],bold:false,fontSize:6,alignment:'center'},
                      {text:this.data.cotizacion_3.direccion == 'N/A' ? '-' : this.data.cotizacion_3.direccion ,border:[true, true, true, true],bold:false,fontSize:6,alignment:'center'},
                  ],
                ],
              },
            },
            {
              style:'vacio',
              table: {
                widths: [40,190.8,148,148.2,148.1],
                heights: [15,15,15,15,15],
                body: [
                  [
                      {text:'',border:[false, false, false, false],},
                      {text:'',border:[false, false, true, false],fontSize:7,alignment:'center',},
                      {text:'',border:[false, false, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text:'',border:[false, false, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text:'',border:[false, false, true, false],bold:false,fontSize:6,alignment:'center'},
                  ],
                ],
              },
            },
            { 
              style: 'Descripcion',
              table: {
                  headerRows: 1,
                  widths: [23,80,50,60,35,25,70,35,25,70,35,25,70],
                  body: tablaEnLista,
              }, 
            },
            {
              style:'total_monto',
              table: {
                widths: [239.7,35.8,25,70,34.5,25,70.3,35,25,69.8],
                body: [
                  [
                      {text:'MONTO TOTAL',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center',},
                      {text:' ',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center',},
                      {text:'0.00',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text: this.dolar1+' $' ,border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text:' ',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text:'0.00',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center',},
                      {text: this.dolar2+' $',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center',},
                      {text:'',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text:'0.00',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text: this.dolar3+' $',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                  ],
                ],
              },
            },
            { style:'resumen',
              table: {
                widths:['auto','auto','auto','auto', 10],
                body: [
                  [
                    {text:'\n\nResumen:',alignment:'',rowSpan:4,fontSize:7,border:[false, false, false, false],bold:true,},
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'   ',alignment:'center',fontSize:7,border:[false, false, false, false],},
                  ],
                  [
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'PROVEEDOR',alignment:'',fontSize:7,border:[true, true, true, true],},
                    {text:'1',alignment:'center',fontSize:7,},
                    {text:this.dolar1+' $',alignment:'center',fontSize:7,},
                    {text:'   ',alignment:'center',fontSize:7,},
                  ],
                  [ 
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'PROVEEDOR',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:'2',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:this.dolar2+' $',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:'   ',alignment:'center',fontSize:7,border:[true, true, true, true],},
                  ],
                  [ 
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'PROVEEDOR',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:'3',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:this.dolar3+' $',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:'   ',alignment:'center',fontSize:7,border:[true, true, true, true],},
                  ],
                ],
              },
            },  
            { style:'firma',
              table: {
                widths:[120],
                body: [
                  [
                    {text:'Firma',alignment:'center',fontSize:10,border:[false, true, false, false],bold:true,},
                  ],
                  [
                    {text:'',alignment:'',fontSize:10,border:[false, false, false, false],bold:false,},
                  ],
                ],
              },
            },
          ],
          styles: {
            header: {
              margin: [ 0, 0, 0, 25]
            },
            cuadros: {
                margin: [ 0, 0, 0, 25]
            },
            titulo:{
              margin:[0,0,0,20]
            },
            proveedores_info:{
              margin:[0,0,0,0]
            },
            resumen:{
              margin:[555.5,20,0,0]
            },
            fecha:{
              margin: [51, 0, 0, 0]
            },
            firma:{
              margin: [100, 0, 0, 0]
            },
            proveedores:{
              margin: [0, 20, 0, 0]
            },
        },
    }
   
        const pdf = pdfMake.createPdf(pdfDefinition);
      
        pdf.open();

    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  getBase64ImageFromURLdaily(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

  sumarPrecioTotal(tabla) {


    tabla.forEach(element => {

        let moneda = element.moneda
        let nTabla = element.n_cot

        console.log(element.precio.replace(',','.'))
        let precio_total = parseFloat(element.precio_total.replace(',','.'))

      if(moneda == '$'){

        if(nTabla == '1'){

          this.dolar1 += precio_total
          
        }
        if(nTabla == '2'){

          this.dolar2 += precio_total
          

        }
        if(nTabla == '3'){

          this.dolar3 += precio_total
          

        }
        
      }

      if(moneda == 'Bs'){
    
        if(nTabla == '1'){

          this.bolivar1 += precio_total
          
        }
        if(nTabla == '2'){

          this.bolivar2 += precio_total
          

        }
        if(nTabla == '3'){

          this.bolivar3 += precio_total
          
          
        }
        
      }
      


    });

  }



  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    this.dataSource2.filter = filterValue.trim().toLowerCase();
    this.dataSource3.filter = filterValue.trim().toLowerCase();
  }

}
