import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-control-excepciones',
  templateUrl: './control-excepciones.component.html',
  styleUrls: ['./control-excepciones.component.css']
})
export class ControlExcepcionesComponent implements OnInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;
  LISTA_SPCL:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  constructor(
    private servicio:nkServices,
    private dialog: MatDialog, 
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    ) {}

  ngOnInit(){
    this.listarExcepciones(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }
  async listarExcepciones(msj:any){
    try {
          await this.util.abrirSpinner();
        let msg = msj;
        let xvalor = {
          api: await this.hashkey.encrypt(await this.atrb.API_REST.listarLogs)
        }
      const response = await this.servicio.ApiResthttp(xvalor).toPromise();
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                      id_seg_system_logs:response[i].id_seg_system_logs,
                      des_controlador:response[i].des_controlador,
                      des_metodo: response[i].des_metodo,
                      cod_error:response[i].cod_error,
                      des_error:response[i].des_error,
                      fecha_ini:response[i].fecha_ini,
                      hora_ini: response[i].hora_ini,
                    });
                  }
                  this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_LOG;
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                  if(msg == await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                    await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                }else{
                  await this.util.cerrarSpinner();
                }
    } catch (error) {
      await this.util.cerrarSpinner();
      this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_LOG;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
        await this.notifi.customToastNotification(error.error.message);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
