
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConsignacionesComponent } from 'src/app/componentes/shared/modal/form-consignaciones/form-consignaciones.component';
import { FormConfirmConsignacionComponent } from 'src/app/componentes/shared/modal/form-confirm-consignacion/form-confirm-consignacion.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-gestion-consignaciones',
  templateUrl: './gestion-consignaciones.component.html',
  styleUrls: ['./gestion-consignaciones.component.css']
})
export class GestionConsignacionesComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
    cod_menu: localStorage.getItem('sub_main'),
    cod_sesion: localStorage.getItem('cod_sesion'),
    dir_ip_client: localStorage.getItem('dir_ip'),
  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.listarConsignaciones(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////
  listarConsignaciones(msj:any){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        var valor = {termino:false}
        this.servicio.listarConsignacioneshttp(valor).subscribe(
          response =>{
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {

                    this.ELEMENT_DATA.push({
                      cod_consignacion:response[i].cod_consignacion,
                      cod_usuario:response[i].cod_usuario,
                      des_usuario: response[i].des_usuario,
                      cod_cliente:response[i].cod_cliente,
                      des_cliente: response[i].des_cliente,
                      estatus:response[i].estatus,
                      tipo_um:response[i].tipo_um,
                      fecha_inicio:response[i].fecha_inicio,
                      hora_inicio:response[i].hora_inicio,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [ 'cod_consignacion',
                                            'cod_cliente',
                                            'des_cliente',
                                            'cod_usuario',
                                            'des_usuario',
                                            'estatus',
                                            'tipo_um',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'Confirmar',
                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [ 'cod_consignacion',
                                            'cod_cliente',
                                            'des_cliente',
                                            'cod_usuario',
                                            'des_usuario',
                                            'estatus',
                                            'tipo_um',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'Confirmar',
                                          ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }
///////////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////
  update_insert_data(obj:any,text:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            var dialogRef:any;
            this.accion = text;
            let datos = localStorage.getItem('cod_usuario');
            let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
            if (this.accion === 'actualizar') {
              dialogRef = this.dialog.open(FormConsultaOrdenesComponent, {
                width: '100vw',
                height:'85vh',
                data: {cod_orden_producto: obj.cod_orden_producto,
                        cod_usuario:datos,
                        des_usuario: nombre,
                        accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
            }else{
              dialogRef = this.dialog.open(FormConsignacionesComponent, {
                width: '100vw',
                maxWidth: '100vw',
                height:'100vh',
                data: {cod_lista_precio_producto:'',
                        cod_usuario:datos,
                        des_usuario: nombre,
                        accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal3',
                backdropClass: "bdrop"
              });
            }
        
              dialogRef.afterClosed().subscribe(result => {
                if(result != 'cerrar'){
                  console.log(result);
        
             this.gestionConsignaciones(result);
                }
              });
            
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      ); 
      
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

public  gestionConsignaciones(xvalor:any){
  try {

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      var resultado = this.servicio.gestionConsignacioneshttp(xvalor).subscribe(
        response =>{
          this.dialogSpin.close();
          this.listarConsignaciones(null);
                this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
            
        },
        error =>{
          this.dialogSpin.close();
          this.listarConsignaciones(null);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
      return resultado;
    }, 1000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

 }
///////////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////

///////////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////

 confirmarConsignacion(xvalor:any){
   try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};;
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
        if (response.resul) {
          var dialogRef = this.dialog.open(FormConfirmConsignacionComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
            data: {cod_consignacion:xvalor.cod_consignacion,
                   des_usuario:xvalor.des_usuario,
                   cod_usuario:xvalor.cod_usuario,
                   cod_cliente:xvalor.cod_cliente,
                   des_cliente:xvalor.des_cliente,
                   cod_orden_producto: xvalor.cod_orden_producto,
                   tipo_um: xvalor.tipo_um,
                   estatus:xvalor.estatus},
            disableClose: true,
            panelClass: 'pedidos_modal3',
            backdropClass: "bdrop"
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result !== 'cerrar'){
              console.log(result);
             this.gestionConfirmarConsignaciones(result);
            }
          });
        }else{
          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 

   } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
   }

}

gestionConfirmarConsignaciones(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      console.log(xvalor);
      var resultado = this.servicio.gestionConfirmarConsignacioneshttp(xvalor).subscribe(
         response =>{
          this.dialogSpin.close();
          this.listarConsignaciones(null);
                this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
                  console.log(response.guia[0]);
          this.servicio.listarGuiaDespachoConsignacionhttp(response.guia[0]).subscribe(
                        async response  =>{
                          console.log(response);
                        },
                        error =>{
                          this.dialogSpin.close();
                          console.log(error);
                
                          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition});
                        }
                      );
         },
         error =>{
          this.dialogSpin.close();
           console.log(<any>error.error.message);
           this.displayedColumns = [ 'cod_consignacion',
                                             'cod_usuario',
                                             'des_usuario',
                                             'cod_cliente',
                                             'des_cliente',
                                             'estatus',
                                             'fecha_inicio',
                                             'hora_inicio',
                                             'Confirmar',
                                           ];
           this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition,});
         }
       );

       return resultado;
    }, 1000);

  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(<any>error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

 }
 ///////////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////
 
  ///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////


 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
sin_permiso():void{
  try {
    this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
} 
 }
