<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">

  <div class="row " style="height: 8%; ">
    <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav justify-content-between" style="background:#1e212c; padding: 5px 7px 5px 7px; margin: 0px;">
      <span class="navbar-brand d-flex align-items-center" style=" font-size: medium; color: rgb(255, 255, 255); " > <mat-icon>monetization_on</mat-icon> <b class="ml-2">COTIZACIONES</b></span>
      <div>
        <ul class="navbar-nav mr-auto">
          <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="btn-danger my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button  style="height: 35px;" (click)='listarSolicitudesCompra("actualizar")'><mat-icon >sync</mat-icon></button></li>
         <!--  <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
          <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
          <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
          <li *ngIf="read" class="nav-item"><button matTooltip="DESCARGA" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="warn" style="height: 35px; background: green; " (click)='en_construccion()'><mat-icon >get_app</mat-icon></button></li> -->
          <li *ngIf="create" class="nav-item"><button matTooltip="CREAR " class="btn-success my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button style="height: 35px;" (click)='crearCotizacion("","insertar")' ><mat-icon >add</mat-icon></button></li>
          <li *ngIf="read" class="nav-item"><button matTooltip="COTIZACIONES APROBADAS" class="btn-info my-2 my-sm-0 mr-sm-2 bg-info text-light rounded-pill" mat-raised-button style="height: 35px;" (click)='cotizacionesAporbadas()'><mat-icon >alarm_on</mat-icon></button></li>
        </ul>
      </div>
      <div class="">
        <input class="form-control me-2 rounded-pill" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
      </div>
    </nav>
  </div>

  <div class="row " style="height: 78.7%;">

      <div id="htmlData" class="col-md-12 px-0  border h-100 " style="color: #000; background-color: #f4f6f9; overflow: auto;">

            <table id="htmlData" #htmlData mat-table [dataSource]="dataSource" class=" text-center">
               <!-- Weight Column -->

                <ng-container matColumnDef="cod_cotizacion_req">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Codigo de Cotización</strong></h4>
                  </th>
                  <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="font-size: 12px; color: black;"> {{element.cod_cotizacion_req}} </td>
                </ng-container>


                <ng-container  matColumnDef="analista">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Analista</strong></h4>
                  </th>

                  <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="text-center" >

                    {{element.des_usuario | uppercase}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="estatus">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Estatus</strong></h4>
                  </th>
                  <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="font-size: 12px; color: black;" [ngSwitch]="element.estatus">


                      <div *ngSwitchCase="'P'" style="background-color: var(--primary); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;" class="py-3 mx-2"><strong  style="color: #fff;">Esperando por Cotización</strong></div>
                      <div *ngSwitchCase="'CTP'" style="background-color: var(--warning); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;" class="py-3 mx-2"><strong>Esperando Aprobación</strong></div>
                      <div *ngSwitchCase="'CTM'" style="background-color: hsl(305, 100%, 84%); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;" class="py-3 mx-2"><strong>Productos Devueltos <br> Esperando Aprobación</strong></div>
                      <div *ngSwitchCase="'CTA'" style="background-color: var(--success); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;" class="py-3 mx-2"><strong  style="color: #fff;">Aprobada</strong> </div>
                      <!-- <div *ngSwitchCase="'SCD'" style="background-color: var(--danger); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Desaprobada</strong></div>
                      <strong *ngSwitchDefault >{{element.estatus}}</strong> -->

                  </td>

                </ng-container>


                <ng-container matColumnDef="departamento">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Deparatamento</strong></h4>
                  </th>
                  <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="font-size: 12px; color: black;text-transform: uppercase;">  <strong>{{element.departamento}}</strong> </td>
                </ng-container>


                <ng-container matColumnDef="fecha">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Fecha</strong></h4>
                  </th>
                  <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="font-size: 12px; color: black;"> <strong>{{element.fecha}}</strong> </td>
                </ng-container>

                <ng-container matColumnDef="fecha_estimada">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Fecha Estimada</strong></h4>
                  </th>
                  <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="font-size: 12px; color: black;"> <strong>{{element.fecha_estimada}}</strong> </td>
                </ng-container>

                <ng-container  matColumnDef="actualizar">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Editar</strong></h4>
                  </th>

                  <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="text-center" >
                      <button matTooltip="EDITAR" (click)='update_insert_data(element, "actualizar")' class="btn rounded-pill btn-sm" [disabled]="element.estatus == 'P'" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(171, 20, 241);">
                          <mat-icon>edit</mat-icon>
                      </button>
                     <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                          <mat-icon>edit</mat-icon>
                      </button> -->
                  </td>
                </ng-container>


                <ng-container  matColumnDef="cotizar">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Cotizar</strong></h4>
                  </th>

                  <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="text-center" >
                      <button *ngIf="element.estatus === 'P'" matTooltip="COTIZAR"   (click)='Cotizar(element, "aprobar")' class="btn btn-success btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);border: 2px solid; background-color: #00887B;">
                          <mat-icon>monetization_on</mat-icon>
                      </button>
                      <button *ngIf="element.estatus === 'CTP'|| element.estatus === 'CTM' || element.estatus === 'CTM'" matTooltip="APROBAR" (click)='aprobarCotizacion(element, "aprobar")' class="btn btn-success btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); border: 2px solid;">
                        <mat-icon>done_all</mat-icon>
                      </button>
                     <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                          <mat-icon>edit</mat-icon>
                      </button> -->
                  </td>
                </ng-container>
                <ng-container disabled="aprobar" matColumnDef="opciones">
                  <th *matHeaderCellDef class="text-center" style="color: #000; background-color: #f4f6f9;">
                      <h4 class="m-0"><strong>Opciones</strong></h4>
                  </th>

                  <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="text-center" >
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button *ngIf="element.estatus === 'CTP'|| element.estatus === 'CTM' " mat-menu-item (click)='verCotizacion(element, "aprobar")' >
                        <mat-icon>remove_red_eye</mat-icon>
                        <span>Ver</span>
                      </button>
                      <button mat-menu-item *ngIf="element.estatus === 'CTP'|| element.estatus === 'CTM'" (click)="volverACotizar(element)">
                        <mat-icon>reply</mat-icon>
                        <span>Volver a Cotizar</span>
                      </button>
                      <button mat-menu-item (click)="editarCotizacion(element)" *ngIf="element.estatus === 'CTP'|| element.estatus === 'CTM'">
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                      </button>
                      <button mat-menu-item *ngIf="element.estatus === 'P'" (click)="anularCotizacionGenerada(element)">
                        <mat-icon>do_not_disturb_alt</mat-icon>
                        <span>Anular</span>
                      </button>
                    </mat-menu>
                     <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                          <mat-icon>edit</mat-icon>
                      </button> -->
                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>

            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>

      </div>

  </div>


</div>
