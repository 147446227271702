import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormPedidosComponent } from 'src/app/componentes/shared/modal/form-pedidos/form-pedidos.component';
import { PeriodicElementclientesp } from 'src/app/componentes/shared/models/interface-clientesp';
import { FormPedidosNComponent } from 'src/app/componentes/shared/modal/form-pedidos-n/form-pedidos-n.component';
import { FormSeleccionComponent } from 'src/app/componentes/shared/modal/form-seleccion/form-seleccion.component';
import { Columns, Img, ITable, PdfMakeWrapper, Table } from 'pdfmake-wrapper';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormPedidosNBsComponent } from 'src/app/componentes/shared/modal/form-pedidos-n-bs/form-pedidos-n-bs.component';
import { FormPedidosUmComponent } from 'src/app/componentes/shared/modal/form-pedidos-um/form-pedidos-um.component';
import { FormPedidosNUmComponent } from 'src/app/componentes/shared/modal/form-pedidos-n-um/form-pedidos-n-um.component';
import { FormPedidosNBsUmComponent } from 'src/app/componentes/shared/modal/form-pedidos-n-bs-um/form-pedidos-n-bs-um.component';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormBackorderComponent } from 'src/app/componentes/shared/modal/form-backorder/form-backorder.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Component({
  selector: 'app-gestion-pedidos',
  templateUrl: './gestion-pedidos.component.html',
  styleUrls: ['./gestion-pedidos.component.css']
})
export class GestionPedidosComponent implements OnInit {

  tasa_precio:any;

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;
  cliente:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar,
    private pdf : PdfServices,
    private router:Router,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    ) {}

  ngOnInit(): void {
    this.listarclientesp(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
  }

 async listarclientesp(msj:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let msg = msj;
      let xvalor = {
        cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
      }
      const response = await  this.servicio.listarClientesActivoshttp(xvalor).toPromise();
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                      cod_cliente:response[i].cod_cliente,
                      des_cliente: response[i].des_cliente,
                      tipo_cliente:response[i].tipo_cliente,
                      des_vendedor:response[i].des_vendedor,
                      estatus_cliente:response[i].estatus_cliente,
                      tipo2_cliente: response[i].tipo2_cliente,
                      credito_dias_cliente:response[i].credito_dias_cliente,
                      reten_cliente:response[i].reten_cliente,
                      comercializacion:response[i].comercializacion,
                      mod_factur:response[i].mod_factur,
                      dep_spcl:response[i].dep_spcl,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                 this.displayedColumns = [
                                          'cod_cliente',
                                          'des_cliente',
                                          'des_vendedor',
                                          'estatus_cliente',
                                          'procesos'
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
        if(msg == await this.atrb.ACCION_ACTUALIZAR){
            await this.util.cerrarSpinner();
            await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
        }else{
          await this.util.cerrarSpinner();
        }
    } catch (error) {
      console.log(error);
      this.displayedColumns = [
                      'cod_cliente',
                      'des_cliente',
                      'des_vendedor',
                    'estatus_cliente',
                          'procesos'
        ];
        this.dataSource = new MatTableDataSource<any>();
        this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
 async consulta_back(obj:any){
    try {
                  await this.util.abrirSpinner();
                  await this.servicio.consultar_sesionhttp(null).toPromise();
                    let val_def;
                        if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) <= 0 ) {
                          val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_pedidos,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
                            cod_usuario : await this.atrb.COD_USUARIO.cod_usuario
                          }
                        }else{
                          val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_pedidos,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
                            cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                          }
                        }
                    await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                    await this.util.cerrarSpinner();
                      let dialogRef;
                        dialogRef = this.dialog.open(FormBackorderComponent, {
                          width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                          data: {cod_cliente:obj.cod_cliente,
                                  des_cliente: obj.des_cliente,
                                  accion:''},
                          disableClose: true,
                          panelClass: 'pedidos_modal3',
                          backdropClass: "bdrop"
                        });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async  tomar_pedido_cliente(obj:any, text:any){
    try {
                  await this.util.abrirSpinner();
                  await this.servicio.consultar_sesionhttp(null).toPromise();
                    if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                      let val_def;
                            val_def = {
                              val_def:await this.atrb.ACCESO_VALDEF.gestionar_pedidos,
                              tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                              cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                            }
                        await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                    }
                    let objeto = {
                      cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
                      mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
                      des_parametro: await this.atrb.OPERACIONES.pedidos
                    };
                    await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
                    var dialogRef:any;
                          if (obj.mod_factur) {
                            await this.util.cerrarSpinner();
                              this.accion = text;
                              dialogRef = this.dialog.open(FormPedidosComponent, {
                              width: '100vw',
                                  maxWidth: '100vw',
                                  height:'100vh',
                              data: {
                                      id:'', 
                                      cod_cliente:obj.cod_cliente,
                                      des_cliente: obj.des_cliente,
                                      des_vendedor:obj.des_vendedor,
                                      tipo_cliente:obj.tipo_cliente,
                                      mod_factur:obj.mod_factur,
                                      dep_spcl:obj.dep_spcl,
                                      forma:text,
                                      accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal3',
                              backdropClass: "bdrop"
                            });
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                            if(result !== 'cerrar' && result !== 'salir'){
                                await this.gestionpedidos(result);
                            }else{
                              await this.util.cerrarSpinner();
                            }
                          });
                          }else{
                            await this.util.cerrarSpinner();
                            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.cliente_sin_um);
                          }
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async gestionpedidos(xvalor:any){
    try {
      const response = await this.servicio.gestionPedidoshttp(xvalor).toPromise();
          xvalor.cod_pedido = response.pdf4[0].cod_pedido;
      const productos =  await this.servicio.listarProdPedidoParamhttp(xvalor).toPromise();
           if (productos.pdf4[0].tipo_pedido ===  await this.atrb.TIPO_PEDIDO.F) {
             productos.pdf4[0].tipo_pedido = await this.atrb.TIPO_PEDIDO.PSF;
           }
           if (productos.pdf4[0].tipo_pedido ===  await this.atrb.TIPO_PEDIDO.ND) {
             productos.pdf4[0].tipo_pedido =  await this.atrb.TIPO_PEDIDO.PSND;
           }
           if (productos.pdf4[0].tipo_pedido ===  await this.atrb.TIPO_PEDIDO.NB) {
             productos.pdf4[0].tipo_pedido =  await this.atrb.TIPO_PEDIDO.PSNB;
           }
           if (productos.pdf4[0].tipo_pedido ===  await this.atrb.TIPO_PEDIDO.PF) {
             productos.pdf4[0].tipo_pedido =  await this.atrb.TIPO_PEDIDO.PSPF;
           }
           productos.pdf2[0].comentarios =  await this.atrb.NO_APLICA;
           productos.pdf6 = productos.pdf5;
           productos.pdf6[0].cod_lista_precio_producto = productos.pdf4[0].cod_lista_precio;
           productos.pdf6[0].comentarios = await this.atrb.NO_APLICA;
           await this.pdf.pdfVentas(productos, await this.atrb.DD);
           await this.util.cerrarSpinner();
           await this.notifi.success(response.message);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  buscarClientes(xvalor:any,termino:any){
  var valor:any = {cod_cliente:xvalor.cod_cliente}
  this.servicio.buscarClienteshttp(valor).subscribe(
    response =>{
      if(response){
        var dialogRef = this.dialog.open(FormDireccionesComponent, {
          width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
          data: {objeto:response[0],
                  termino:termino
                 },
                 panelClass: 'pedidos_modal_rev',
                 disableClose: true,
                 backdropClass: "bdrop"
        });
        }
    },
    error =>{
      console.log(<any>error.error[0].message);
      this.snackBar.open(<any>error.error[0].message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
