<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); ">
                    <div class="modal-header" style="background-color: rgb(32, 126, 155); color: #fff;">
                        <h3 class="modal-title"><strong>Ingrese una cantidad del producto a devolver</strong> </h3>
                    </div>
                    <div class="modal-body">
                        <div style="width: 100%;">
                            <div class="row">

                                <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                                    Codigo: <strong>{{codigo}}</strong>
                                </div>
                                <br>
                                <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                                    Producto: <strong>{{producto}}</strong>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad del producto</mat-label>
                                            <input style="font-size: 12px;" #cant_producto maxlength="17" matInput placeholder="" autocomplete="off" formControlName="cant_producto" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                          </span>
                                        </mat-form-field> -->
                                    <div class="form-group">
                                        <label for="cantidad_prod_mp">Cantidad del producto</label>
                                        <input id="cantidad_prod_mp" style="font-size: 12px; text-align: right;" #cantidad_prod_mp class="form-control" type="text" autocomplete="off" formControlName="cantidad_prod_mp" readonly>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad a devolver</mat-label>
                                            <input style="font-size: 12px;" #cant_dev maxlength="6" matInput placeholder="" autocomplete="off" formControlName="cant_dev">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                            </span>
                                    </mat-form-field> -->

                                    <div class="form-group">
                                        <label for="cant_dev">Cantidad a devolver</label>
                                        <input id="cant_dev" style="font-size: 12px; text-align: right;" #cant_dev class="form-control" type="text" autocomplete="off" formControlName="cant_dev">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                      <div class="form-group">
                        <button class="btn btn-success" (click)="devolucion_prod(cant_dev.value)" >Aceptar</button> <!-- [disabled]="forma.invalid" -->
                      </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

