import { Component, OnInit, ViewChild} from '@angular/core';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';

import 'offline-js'
//esto es de mi desarrollo 
import {MatDialog} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import { FormCuestionarioComprasComponent } from 'src/app/componentes/shared/modal/form-cuestionario-compras/form-cuestionario-compras.component';
import { FormOrdenReqComponent } from 'src/app/componentes/shared/modal/form-orden-req/form-orden-req.component';
import { FormAprobarSolicitudCompraComponent } from 'src/app/componentes/shared/modal/form-aprobar-solicitud-compra/form-aprobar-solicitud-compra.component';
import { FormCotizacionVerSolicitudesComponent } from 'src/app/componentes/shared/modal/form-cotizacion-ver-solicitudes/form-cotizacion-ver-solicitudes.component';
import { FormmMsgComponent } from '../../../../shared/modal/formm-msg/formm-msg.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';


@Component({
  selector: 'app-orden-requisicion',
  templateUrl: './orden-requisicion.component.html',
  styleUrls: ['./orden-requisicion.component.css']
})
export class OrdenRequisicionComponent implements OnInit {

  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  aprobar:any=false

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }
/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }

  ngOnInit(): void {

    this.listarSolicitudesCompra()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  mostrarTabla(){
    this.displayedColumns = [
                              'cod_solicitud_compra',
                              'tipo_orden',
                              'estatus',
                              'fecha',
                              'departamento',
                              'actualizar',
                              'aprobar'
                            ];
     /* this.dataSource = this.ELEMENT_DATA; */
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }

  listarSolicitudesCompra(){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        this.servicio.listarSolicituComprahttp(localStorage.getItem('cod_usuario')).subscribe(
          response =>{
            console.log(response)
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
                
              let xobj2 = response.result;
              let array = [];
                  for (const i in xobj2) {

                array.unshift({
                      
                id_solicitud_compra:xobj2[i].id_solicitud_compra,
                cod_solicitud_compra: xobj2[i].cod_solicitud_compra,
                cod_orden_req:xobj2[i].cod_orden_req ? xobj2[i].cod_orden_req : '-',
                cod_cotizacion_req:xobj2[i].cod_cotizacion_req,
                tipo_solicitud_compra:xobj2[i].tipo_solicitud_compra,
                tipo_producto: xobj2[i].tipo_producto,
                cod_usuario:xobj2[i].cod_usuario,
                des_usuario:xobj2[i].des_usuario,
                departamento: xobj2[i].departamento,
                cod_centro_costo: xobj2[i].cod_centro_costo,
                cod_cuenta_gasto: xobj2[i].cod_cuenta_gasto,
                cod_presupuesto: xobj2[i].cod_presupuesto,
                observaciones: xobj2[i].observaciones,
                observaciones_aprobacion: xobj2[i].observaciones_aprobacion,
                modalidad: xobj2[i].modalidad,
                prioridad: xobj2[i].prioridad,
                fecha_estimada: xobj2[i].fecha_estimada,
                estatus:xobj2[i].estatus,
                fecha:xobj2[i].fecha,
                 accion:''
                    });
                  }

                
                let pendiente = array.filter(element => element.estatus == 'P')
                

                this.ELEMENT_DATA = pendiente

                console.log( response.empleado.dt_cargo)
              
                  this.displayedColumns = response.empleado.dt_cargo.includes('CORDINADOR') || response.empleado.dt_cargo.includes('COORDINADOR') || response.empleado.dt_cargo.includes('GERENTE')
                  ? [
                    'cod_solicitud_compra',
                    'cod_orden_req',
                    'tipo_solicitud_compra',
                    'tipo_producto',
                    'estatus',
                    'departamento',
                    'fecha',
                    'fecha_estimada',
                    'prioridad',
                    'aprobar',
                    'aprobado'
                  ]
                  : [
                    'cod_solicitud_compra',
                    'cod_orden_req',
                    'tipo_solicitud_compra',
                    'tipo_producto',
                    'estatus',
                    'departamento',
                    'fecha',
                    'fecha_estimada',
                    'prioridad',
                    'aprobado'
                  ]
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }

            if(!response.empleado.dt_cargo){

              this.snackBar.open('Data del empleado desactualizada por favor comunicarse con RRHH','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});

            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              'cod_solicitud_compra',
              'cod_orden_req',
              'tipo_solicitud_compra',
              'tipo_producto',
              'estatus',
              'departamento',
              'fecha',
              'fecha_estimada',
              'prioridad',
              'aprobar',
              'aprobado'
            ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
          }
        );
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  crearSolicitudCompra(){
    try {
      
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });

        setTimeout(() => {
          this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
          cod_menu: localStorage.getItem('sub_main'),
          cod_sesion: localStorage.getItem('cod_sesion'),
          dir_ip_client: localStorage.getItem('dir_ip'),
        sesion_unica:sessionStorage.getItem('sesion_unica')};

              this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
              response =>{
                if (response.resul) {

                this.dialogSpin.close()

                var dialogRef;
                  dialogRef = this.dialog.open(FormCuestionarioComprasComponent, {
                    width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                    data: 'hola',
                    disableClose: true,
                    panelClass: 'cuestionario_compras_component',
                    backdropClass: "bdrop"
                  });
                }
                dialogRef.afterClosed().subscribe(result => {

                  console.log(result)

                  if(result.accion == 'listo'){
                    this.llamarCrearSolicitud(result.info);
                  }
                })
                
              },
              error =>{
                this.dialogSpin.close();
                this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              });
        }, 500);

      } catch (error) {
        this.dialogSpin.close();
          this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
        }
  }

  llamarCrearSolicitud(info:any){
    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
                this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                cod_menu: localStorage.getItem('sub_main'),
                cod_sesion: localStorage.getItem('cod_sesion'),
                dir_ip_client: localStorage.getItem('dir_ip'),
              sesion_unica:sessionStorage.getItem('sesion_unica')};

            this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
              response => {

            this.dialogSpin.close();

            var dialogRef;
            dialogRef = this.dialog.open(FormOrdenReqComponent,     {
            width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
            data:info,
            disableClose:   true,
            panelClass: 'pedidos_modal3',
            backdropClass: "bdrop"
            });

            dialogRef.afterClosed().subscribe(resul => {

            if(resul != 'cerrar'){

              resul.modulo = 'REQUISICIONES'
              resul.tipo = 'requisicion'
              resul.titulo = 'requisicion creada'

              this.servicio.crearSolicitudComprahttp(resul).subscribe(Re => {

                this.listarSolicitudesCompra();

              });

            }
            })
            },
            error =>{
              this.dialogSpin.close();
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
            }); 
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }

  aprobarSolicitudCompra(element){
    try {

      let dialogRef = this.dialog.open(FormKeyComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: '',
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });

      dialogRef.afterClosed().subscribe(
        result => {
          if(result != 'cerrar'){
            
            this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
              width: '15%',
              height:'25%',
              data: {
                      accion:this.accion},
              disableClose: true,
              panelClass: 'spinner',
              backdropClass: "bdrop"
            });
        
            setTimeout(() => {
        
              this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                        cod_menu: localStorage.getItem('sub_main'),
                        cod_sesion: localStorage.getItem('cod_sesion'),
                        dir_ip_client: localStorage.getItem('dir_ip'),
                         sesion_unica:sessionStorage.getItem('sesion_unica')};
        
              this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {
                
                this.dialogSpin.close()

                var dialogRef;
                dialogRef = this.dialog.open(FormAprobarSolicitudCompraComponent,{
                width: '100vw',
                maxWidth: '100vw',
                height:'100vh',
                disableClose:   true,
                data:element,
                panelClass: 'pedidos_modal3',
                backdropClass: "bdrop"
                });
        
                dialogRef.afterClosed().subscribe(resul => {
                  this.listarSolicitudesCompra()
                })
        
              })
            }, 1000);    
      
          }
        }
      )
     

      
    }  catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }

  verAprobacion(element){
    try {

      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                        cod_menu: localStorage.getItem('sub_main'),
                        cod_sesion: localStorage.getItem('cod_sesion'),
                        dir_ip_client: localStorage.getItem('dir_ip'),
                         sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response => {

              element.accion = 'ver';

              var dialogRef;
              dialogRef = this.dialog.open(FormAprobarSolicitudCompraComponent,{
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              disableClose:   true,
              data:element,
              panelClass: 'pedidos_modal3',
              backdropClass: "bdrop"
              });

              dialogRef.afterClosed().subscribe(resul => {
                this.listarSolicitudesCompra()
              })
      },
      error =>{
      
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      });
      
    }  catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }

  verSolicitudesAprobadas(){

    try{

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    
    setTimeout(() => {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response => {
    
          this.dialogSpin.close();
  
          var dialogRef;
          dialogRef = this.dialog.open(FormCotizacionVerSolicitudesComponent,     {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data:'',
          disableClose:   true,
          panelClass: 'pedidos_modal3',
          backdropClass: "bdrop"
          });
  
          dialogRef.afterClosed().subscribe(resul => {
  
            if(resul != 'cerrar'){
  
              console.log('Se cerro la wea')
              this.listarSolicitudesCompra()
  
            }
        })
      },
      error =>{
        this.dialogSpin.close();
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      );
    }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }

  en_construccion(): void {
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    } catch (error) {
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }

}
