import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { FormNotaCreditoComponent } from 'src/app/componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormNotaCreditoAdminComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import { FormReportesVentasNotasCreditoComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas-notas-credito/form-reportes-ventas-notas-credito.component';
import * as fs from 'file-saver';
import { FormNotacreditoNotasComponent } from 'src/app/componentes/shared/modal/form-notacredito-notas/form-notacredito-notas.component';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';
import axios from 'axios';

interface interfacefactura{
  lote_producto: string;
  cod_producto:string;
  des_producto:string;
  cant_producto:string;
  costo_producto:string;
  subtotal_producto:string;
}
interface interfacefactura2{
  des_concepto: string;
  subtotal_concepto:string;

}
interface interdatosfacefactura{
cod_factura:string;
des_cliente: string;
cod_cliente:string;
des_vendedor:string;
fecha_e_factura:string;
fecha_v_factura:string;
cod_pedido:string;
telefono1_cliente:string;
telefono2_cliente:string;
}
type TableRow = [string, string, string, string, string, string];

type TableRow2 = [string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-consultar-notas',
  templateUrl: './consultar-notas.component.html',
  styleUrls: ['./consultar-notas.component.css']
})
export class ConsultarNotasComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  fecha1:any;
  fecha2:any;

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices,
     public dialog: MatDialog,
     private pdf : PdfServices,
      private snackBar: MatSnackBar, 
      private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
    cod_menu: localStorage.getItem('sub_main'),
    cod_sesion: localStorage.getItem('cod_sesion'),
    dir_ip_client: localStorage.getItem('dir_ip')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );

  }

  ngOnInit(): void {
    this.listar_notas_credito(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////
  listar_notas_credito(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listarNotashttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.respuesta) {
  
                    this.ELEMENT_DATA.push({
                      cod_factura:response.respuesta[i].cod_factura,
                      cod_nota_credito: response.respuesta[i].cod_nota_credito,
                      cod_lista_precio:response.respuesta[i].cod_lista_precio,
                      cod_cliente:response.respuesta[i].cod_cliente,
                      des_cliente:response.respuesta[i].des_cliente,
                      cod_usuario:response.respuesta[i].cod_usuario,
                      des_usuario:response.respuesta[i].des_usuario,
                      fecha_inicio:response.respuesta[i].fecha_inicio,
                      hora_inicio:response.respuesta[i].hora_inicio,
                      estatus:response.respuesta[i].estatus,
                      tipo_nota: response.respuesta[i].tipo_nota_credito,
                      subtotal_dev_costo: response.respuesta[i].subtotal_dev_costo,
                      total_dev_costo:response.respuesta[i].total_dev_costo,
                      iva_dev:response.respuesta[i].iva_dev,
                      iva_dev_monto:response.respuesta[i].iva_dev_monto,
                      desc_dev:response.respuesta[i].desc_dev,
                      desc_dev_monto: response.respuesta[i].desc_dev_monto,
                      iva_dev_exento:response.respuesta[i].iva_dev_exento,
                      dev_monto_exento:response.respuesta[i].dev_monto_exento,
                      accion:'',
                      accion2:'',
                      accion3:'',
                      accion4:''
                    });
                  }
                  this.displayedColumns = [
                                          'cod_factura',
                                          'cod_nota_credito',
                                          'cod_lista_precio',
                                          'des_cliente',
                                          'des_usuario',
                                          'estatus',
                                          'tipo_nota',
                                          'fecha',
                                          'hora',
                                          'imprimir',
                                          'descargar',
                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                      'cod_factura',
                                      'cod_nota_credito',
                                      'cod_lista_precio',
                                      'des_cliente',
                                      'des_usuario',
                                      'estatus',
                                      'tipo_nota',
                                      'fecha',
                                      'hora',
                                      'imprimir',
                                      'descargar',
                                      ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }
  
  descargar_reporte_ventas(text:any){
    try {
  
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                            var dialogRef:any;
    this.accion = text;
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
      dialogRef = this.dialog.open(FormReportesVentasNotasCreditoComponent, {
        width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
        data: {
                accion:this.accion},
        panelClass: 'pedidos_modal_rep',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);
          switch (result) {
            case 'xlsx':
              /* this.reportes_ventas_xlsx(); */
              break;
            case 'pdf':
  
              break;
            case 'txt':
  
              break;
            case 'csv':
  
              break;
  
            default:
              this.reportes_rango_fecha_ventas_xlsx(result);
              break;
          }
        }
      });
                             
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
        
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  
  reportes_rango_fecha_ventas_xlsx(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.fecha1 = xvalor.fecha_inicio;
        this.fecha2 = xvalor.fecha_fin;
        this.servicio.reporteNotasCreditoshttp(xvalor).subscribe(
          response=>{
            this.dialogSpin.close();
            console.log(response.xobjeto_unico);
            this.descarga_prod_ventas_por_fecha_xlsx(response.xobjeto_unico);
          },
          error=>{
            this.dialogSpin.close();
            console.log(error.error.xobjeto_unico);
            console.log(error.error.message);
            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,})
          }
        );
      }, 3000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  
    async descarga_prod_ventas_por_fecha_xlsx(xdatos:any){
      try {
        console.log(xdatos,'llego a la descarga');
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Reporte de Notas de Credito",{views: [{showGridLines: false}]});

        const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
        const imageId2 = workbook.addImage({
          buffer: imageBuffer.data,
          extension: 'png',
        });
        worksheet.addImage(imageId2,'B2:B7')

        worksheet.mergeCells('B1:U1')
        worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
        worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D1').font={bold: true};
    
        worksheet.mergeCells('B2:U2')
        worksheet.getCell('D2').value='Rif J-41031797-3';
        worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D2').font={bold: true};
        
        worksheet.mergeCells('B3:U3')
        worksheet.getCell('D3').value='Reporte de Notas de Credito';
        worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D3').font={bold: true};
    
        worksheet.mergeCells('B4:U4')
        worksheet.getCell('D4').value='A la fecha "'+this.fecha1+'"-"'+this.fecha1+'" ';
        worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D4').font={bold: true};
    
        worksheet.mergeCells('B5:U5')
        worksheet.getCell('D5').value='Expresado en Bolivares';
        worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D5').font={bold: true};
    
        worksheet.mergeCells('B6:U6')
    
        worksheet.autoFilter = {
          from: 'B8',
          to: 'U8',
        }
        var fondo_gris_claro =['D7','E7','F7','G7','H7']
        fondo_gris_claro.map(celdas => {
          worksheet.getCell(celdas).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF'},
            
          };
        })
        let header=[" ","Fecha","Hora","Nro. de factura","Nro. nota de credito","Nro. control","RIF","Cliente","Tipo de nota de credito","Usuario",
                    "Tipo de cliente","Tasa","Base imponible","Exento","Monto exento","%Descuento","Descuento","%IVA",
                    "IVA","Monto total","Estatus",];
        let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};
        
        var bordes_headers = ['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8','P8',
                            'Q8','R8','S8','T8','U8']
        bordes_headers.map(celdas =>{
          worksheet.getCell(celdas).border = {
            top: {style:'double', color: {argb:'00000000'}},
            bottom: {style:'double', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}}
          };
        })

        var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                                    'P8','Q8','R8','S8','T8','U8']
        fondo_gris_claro_plus.map(celdas => {
          worksheet.getCell(celdas).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0050a0'},
            
          };
        })
        fondo_gris_claro_plus.map(centrar => {
          worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
        })
        worksheet.getColumn(1).width = 3.5;
        worksheet.getColumn(2).width = 17;
        worksheet.getColumn(3).width = 17;
        worksheet.getColumn(4).width = 17;
        worksheet.getColumn(5).width = 17;
        worksheet.getColumn(6).width = 17;
        worksheet.getColumn(7).width = 20;
        worksheet.getColumn(8).width = 45;
        worksheet.getColumn(9).width = 20;
        worksheet.getColumn(10).width = 17;
        worksheet.getColumn(11).width = 17;
        worksheet.getColumn(12).width = 17;
        worksheet.getColumn(13).width = 17;
        worksheet.getColumn(14).width = 17;
        worksheet.getColumn(15).width = 17;
        worksheet.getColumn(16).width = 17;
        worksheet.getColumn(17).width = 17;
        worksheet.getColumn(18).width = 17;
        worksheet.getColumn(19).width = 17;
        worksheet.getColumn(20).width = 17;
        worksheet.getColumn(20).width = 17;
                          
        /////////////////////wrap//////////////////////
        var espacio_interno =['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                              'P8','Q8','R8','S8','T8','U8']
        espacio_interno.map(celdas =>{
        worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        })
        /////////////////////wrap//////////////////////
        
        /////////////////////ROW//////////////////////
        worksheet.getRow(8).height= 30
        /////////////////////ROW//////////////////////

        var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
        worksheet.getColumn(11).numFmt =numFmtStr;
        worksheet.getColumn(12).numFmt =numFmtStr;
        worksheet.getColumn(13).numFmt =numFmtStr;
        worksheet.getColumn(14).numFmt =numFmtStr;
        worksheet.getColumn(15).numFmt =numFmtStr;
        worksheet.getColumn(16).numFmt =numFmtStr;
        worksheet.getColumn(17).numFmt =numFmtStr;
        worksheet.getColumn(18).numFmt =numFmtStr;
        worksheet.getColumn(19).numFmt =numFmtStr;
        worksheet.getColumn(20).numFmt =numFmtStr;

        for (let x1 of xdatos)
      {
        x1.fecha_inicio = new Date(x1.fecha_inicio)
      let x2=Object.keys(x1);
      
      let temp=[]
      for(let y of x2)
      {
      temp.push(x1[y])
      }
      worksheet.addRow(temp)
      }
      for (let i = 0; i <= xdatos.length; i++) {

        if(i == 0){
          worksheet.getCell('B9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('C9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('D9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('E9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('F9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('G9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('H9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('I9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('J9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('K9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('L9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('M9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('N9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('O9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('P9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Q9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('R9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('S9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('T9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('U9').alignment = { vertical: 'top', horizontal: 'center' }
         
        }else{
          var numActu = i + 9 -1;
          var numActuString = numActu.toString()
    
          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`T${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`U${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        
          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`J${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`K${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`L${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`M${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`N${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`O${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`P${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Q${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`R${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`S${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`T${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`U${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
        
        if(i == xdatos.length){
            var numActu = i + 9 -1;
            var final_mas_uno = i + 9 + 1
            var final_mas_uno_s = final_mas_uno.toString()
    
            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`K${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`L${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`M${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`N${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`O${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`P${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Q${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`R${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`S${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`T${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`U${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
        }
      }
      let fname="Reporte de Notas de Credito desde '"+this.fecha1+"' hasta '"+this.fecha2+"'";
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
      });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

//////////////////////FUNCION DE ACCION/////////////////////////

/*  imprimir_descargar(xvalor:any,text:any): void {
   try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
        if (response.resul) {
          this.accion = text;
          switch (this.accion) {
            case 'imprimir':
              this.accion = 'enviarpedido';
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                      maxWidth: '100vw',
                      height:'100%',
                data: {id:'',
                      accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cerrar' && result != 'salir' ) {
                  dialogRef.close();
                  this.imprimirnota(xvalor);
                }else{
                  dialogRef.close();
                }
              });
              break;
              case 'descargar':
              this.accion = 'enviarpedido';
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                      maxWidth: '100vw',
                      height:'100%',
                data: {id:'',
                      accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cerrar' && result != 'salir' ) {
                  dialogRef.close();
                  this.descargarnota(xvalor);
                }else{
                  dialogRef.close();
                  
                }
            
              });
              break;
          
            default:
              break;
          }
          
        }else{
          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 

   } catch (error) {
    this.dialogSpin.close();
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
   }
} */

imprimirnota(xvalor:any, text:any){

    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        const pdf = new PdfMakeWrapper();
        var valor = {
                    val_def :'F',
                    tipo_operacion :'IMPRIMIRNC',
                    cod_usuario :localStorage.getItem('cod_usuario'),
                    cod_factura:xvalor.cod_factura,
                    cod_nota_credito:xvalor.cod_nota_credito,
                    tipo_nota:xvalor.tipo_nota}

        this.servicio.validar_acceso_acciones_http(valor).subscribe(
          response=>{
            this.servicio.listarNotaCreditohttp(valor).subscribe(
              async response  =>{
                 console.log(response);
                 this.dialogSpin.close();
                 console.log(xvalor);
     
                 switch (xvalor.tipo_nota) {
                   case 'dev':
                     response.pdf4 = response.pdf2;
                     response.pdf2[0].comentarios = '';
                     
                     response.pdf4[0].subtotal_costo_pedido = response.pdf4[0].subtotal_dev_costo;
                     response.pdf4[0].desc_pedido = response.pdf4[0].desc_dev;
                     response.pdf4[0].desc_pedido_monto = response.pdf4[0].desc_dev_monto;
                     response.pdf4[0].monto_exento = response.pdf4[0].dev_monto_exento;
                     response.pdf4[0].iva_pedido = response.pdf4[0].iva_dev;
                     response.pdf4[0].iva_monto = response.pdf4[0].iva_dev_monto;
                     response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;
     
                     response.pdf6[0].tasa_precio = response.pdf7[0].tasa_precio;
                     
                     response.pdf4[0].tipo_pedido = response.pdf2[0].tipo_nota_credito;

                     if (text.toUpperCase() === 'DESCARGAR') {
                      this.pdf.pdfVentas(response,'dd');
                     }
                     if (text.toUpperCase() === 'IMPRIMIR') {
                      this.pdf.pdfVentas(response,'d');
                    }
                     
                     break;
                     case 'ntntrg':
                  
                     break;
                   case 'admin':
     
                     response.pdf4 = response.pdf2;
                     response.pdf2[0].comentarios = '';
                     
                     response.pdf4[0].subtotal_costo_pedido = response.pdf4[0].subtotal_dev_costo;
                     response.pdf4[0].desc_pedido = response.pdf4[0].desc_dev;
                     response.pdf4[0].desc_pedido_monto = response.pdf4[0].desc_dev_monto;
                     response.pdf4[0].monto_exento = response.pdf4[0].dev_monto_exento;
                     response.pdf4[0].iva_pedido = response.pdf4[0].iva_dev;
                     response.pdf4[0].iva_monto = response.pdf4[0].iva_dev_monto;
                     response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;
     
                     response.pdf6[0].tasa_precio = response.pdf7[0].tasa_precio;
                     
                     response.pdf4[0].tipo_pedido = response.pdf2[0].tipo_nota_credito;
     
                     response.pdf[0].cod_producto = 'N/A';
                     response.pdf[0].des_producto = response.pdf[0].des_concepto;
                     response.pdf[0].cant_producto = '1';
                     response.pdf[0].costo_producto = '0,00';
                     response.pdf[0].desc_prod = '0,00';
                     response.pdf[0].desc_prod_monto = '0,00';
                     response.pdf[0].subtotal_producto = response.pdf[0].subtotal_concepto ;
                     
                     if (text.toUpperCase() === 'DESCARGAR') {
                      this.pdf.pdfVentas(response,'dd');
                     }
                     if (text.toUpperCase() === 'IMPRIMIR') {
                      this.pdf.pdfVentas(response,'d');
                    }
                     
                       break;
     
                   default:
                     break;
                 }
     
               },
               error =>{
                 console.log(error);
                 this.dialogSpin.close();
                 console.log(<any>error.error.message);
                 this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition});
               }
             );
          },
          error=>{
            this.dialogSpin.close();
               this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition});
          }
        );
       }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error ,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }
  async descargarnota(xvalor:any){

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      const pdf = new PdfMakeWrapper();

      this.servicio.listarProdPedidohttp(xvalor).subscribe(
       async response  =>{
        this.dialogSpin.close();
          console.log(xvalor);
          
        },
        error =>{
          console.log(error);
          this.dialogSpin.close();
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
    }, 3000);

  }
 
//////////////////////FUNCION DE ACCION/////////////////////////

//////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

sin_permiso():void{
  try {
    this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

}
