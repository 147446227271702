<!-- <div class="container-fluid" style="height: 100%; width: 100%;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div class="row mat-elevation-z8" style="height: 13%;">
            <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style="background-color:rgb(241, 0, 133); padding: 5px; margin: 0px;">

                <div class="collapse navbar-collapse col-md-12 col-sm-12 col-xs-12" id="navbarSupportedContent" style="padding: 0px; margin: 0px;">

                    <ul class="navbar-nav mr-auto col-md-12 col-sm-12 col-xs-12 " style="padding: 0px; margin: 0px; ">
                        <li class="nav-item col-md-3 col-sm-3 col-xs-3" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px; ">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                            <mat-label style="color: #000; font-size: 11px;">Clientes</mat-label>
                                            <input style="font-size: 11px;" type="text" matInput placeholder="" formControlName="cod_cliente" [matAutocomplete]="autoGroup" #cliente>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>search</mat-icon>
                                                </span>
                                            <mat-autocomplete #autoGroup="matAutocomplete">
                                                <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                                    <mat-option style="font-size: 11px;" *ngFor="let i of group.code" [value]="i">
                                                        {{i}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Cod de la factura</mat-label>
                                            <input style="font-size: 12px;" maxlength="11" type="text" matInput placeholder="" formControlName="cod_factura" #cod_factura autocomplete="off">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                #
                                            </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px;  ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;   height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                            <mat-label style="color: #000; font-size: 11px;">Concepto</mat-label>
                                            <input style="font-size: 11px;" type="text" matInput placeholder="" formControlName="producto_pedido" #producto_pedido autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000; font-size: 11px;">Monto</mat-label>
                                            <input style="font-size: 12px;" maxlength="17" type="text " matInput placeholder="" (keyup)="format(num)" formControlName="cantidad_pedido" #num autocomplete="off">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            #
                                        </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; ">
                            <button class="btn btn-block btn-lg btn-light" (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" style="padding: 5px; height: 100%; width: 100%; color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                      <mat-icon >add_shopping_cart</mat-icon>
                    </button>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; ">
                            <button class="btn btn-block btn-lg btn-light" (click)="modalrespuesta()" matTooltip="GENERAR NOTA DE DEBITO" style="padding: 5px; height: 100%; width: 100%; color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                      <mat-icon >save</mat-icon>
                    </button>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; ">
                            <button class="btn btn-block btn-lg btn-danger" (click)="cerrarmodal()" matTooltip="SALIR" style="padding: 5px; height: 100%; width: 100%;">
                            <mat-icon >exit_to_app</mat-icon>
                      </button>
                        </li>

                    </ul>

                </div>

            </nav>
        </div>
        <div class="row" style="height: 4%; ">
            <div class="col-md-12 col-sm-12 col-xs-12"></div>
        </div>
        <div class="row mat-elevation-z8" style="height: 66%; overflow-y: auto;">

            <table #table mat-table [dataSource]="dataSource1" class=" table-secondary  text-center table-striped table-bordered table-condensed table-hover">

                <ng-container matColumnDef="item_concepto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Item</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.item_nota}}</b></td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(241, 0, 133); color: #fff;">{{cantidad_item()}}</td>
                </ng-container>
                <ng-container matColumnDef="lista_precio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Lista precios N°</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;">TOTAL ITEMS  </td>
                </ng-container>

                <ng-container matColumnDef="lote_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Lote</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.lote_producto}}</b></td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;">TOTAL ITEMS  </td>
                </ng-container>

                <ng-container matColumnDef="Concepto_nota">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Descripción</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.concepto_nota}}</b></td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;">TOTAL DEL CONCEPTO ADMINISTRATIVO </td>
                </ng-container>


                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;">TOTAL ITEMS </td>
                </ng-container>

                <ng-container matColumnDef="costo_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Costo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.costo_producto}} </b></td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;">TOTAL ITEMS  </td>
                </ng-container>

                <ng-container matColumnDef="tipo_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tipo_producto}}</b> </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;">TOTAL ITEMS  </td>
                </ng-container>

                <ng-container matColumnDef="tamaño_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Tamaño</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tamano_producto}}</b></td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;">TOTAL ITEMS  </td>
                </ng-container>

                <ng-container matColumnDef="subtotal_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Sub-total</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.subtotal_producto}}</b></td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;"> {{sub_total_producto()}}</td>
                </ng-container>

                <ng-container matColumnDef="remov_elem">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(241, 0, 133);">
                        <h5><strong>Eliminar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                        <button (click)="remove(element)" matTooltip="QUITAR PRODUCTO DE LA LISTA" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);">
                          <mat-icon >remove_shopping_cart</mat-icon>
                          </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(241, 0, 133); color: #fff;"></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>

        </div>
        <div class="row" style="height: 4%; ">
            <div class="col-md-12 col-sm-12 col-xs-12"></div>
        </div>
        <input style="font-size: 12px;" style="display: none;" placeholder="" formControlName="cod_lista_precio" #cod_lista_precio autocomplete="off" readonly>
        <input style="font-size: 12px;" style="display: none;" #iva placeholder="" formControlName="iva_pedido" autocomplete="off" readonly>
        <div class="row mat-elevation-z8" style="height: 13%;">

            <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style="background-color:rgb(241, 0, 133); padding: 5px; margin: 0px;">

                <div class="collapse navbar-collapse col-md-12 col-sm-12 col-xs-12" id="navbarSupportedContent" style="padding: 0px;  margin: 0px;">

                    <ul class="navbar-nav mr-auto col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px; ">
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Items</mat-label>
                                            <input style="font-size: 12px;" #items matInput placeholder="" autocomplete="off" readonly>
                                            <span style="font-size: 12px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            #
                                            </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Descuento</mat-label>
                                            <input style="font-size: 12px;" maxlength="6" (keyup)="porcentaje(desc_pedido)" #desc_pedido matInput placeholder="" formControlName="desc_pedido" autocomplete="off" readonly>
                                            <span style="font-size: 12px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            %
                                            </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Monto restante</mat-label>
                                            <input style="font-size: 11px;" #exento_monto matInput placeholder="Sub-total:" formControlName="monto_exento" autocomplete="off" readonly>
                                            <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              Bs.
                                              </span>
                                        </mat-form-field>

                                    </div>
                                </div>

                            </div>
                        </li>
                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Sub-total</mat-label>
                                            <input style="font-size: 11px;" #subtotal_pedido matInput placeholder="Sub-total:" formControlName="subtotal_pedido" autocomplete="off" readonly>
                                            <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            Bs.
                                            </span>
                                        </mat-form-field>

                                    </div>
                                </div>

                            </div>
                        </li>
                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">I.V.A</mat-label>
                                            <input style="font-size: 11px;" matInput placeholder="I.V.A:" formControlName="iva_monto" autocomplete="off" #iva_monto readonly>
                                            <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            Bs.
                                            </span>
                                        </mat-form-field>

                                    </div>
                                </div>

                            </div>
                        </li>
                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Descuento</mat-label>
                                            <input style="font-size: 11px;" #desc_pedido_monto matInput placeholder="" formControlName="desc_pedido_monto" autocomplete="off" readonly>
                                            <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                Bs.
                                            </span>
                                        </mat-form-field>

                                    </div>
                                </div>

                            </div>
                        </li>
                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Total</mat-label>
                                            <input class="" style="font-size: 11px; color: #000;" #total_pedido matInput placeholder="Total:" formControlName="total_pedido" autocomplete="off" readonly>
                                            <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                Bs.
                                            </span>
                                        </mat-form-field>

                                    </div>
                                </div>

                            </div>
                        </li>

                    </ul>

                </div>

            </nav>

        </div>
    </form>
</div> -->

<div id="modal_carrito" class=" container-fluid" style="background-color:rgb(189, 66, 134);">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid rgb(189, 66, 134);">
            <div id="content_1" class=" col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Clientes</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="" formControlName="cod_cliente" [matAutocomplete]="autoGroup" #cliente>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete #autoGroup="matAutocomplete">
                            <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                <mat-option style="font-size: 11px;" *ngFor="let i of group.code" [value]="i">
                                    {{i}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="  col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Cod de la factura</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput placeholder="" formControlName="cod_factura" #cod_factura autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            #
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="  col-md-2 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Concepto</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="" formControlName="producto_pedido" #producto_pedido autocomplete="off">
                    </mat-form-field>
                </div>
            </div>
            <div class="  col-md-2 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Monto</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="17" type="text " matInput placeholder="" (keyup)="format(num)" formControlName="cantidad_pedido" #num autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          #
                      </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" style=" color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                        <mat-icon >add_shopping_cart</mat-icon>
                      </button>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GENERAR NOTA DE CREDITO" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                        <mat-icon >save</mat-icon>
                    </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row" style="background-color:rgb(189, 66, 134);">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100">
                <table #table mat-table [dataSource]="dataSource1" class=" table-danger text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="item_concepto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Item</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.item_nota}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(228, 22, 135); color: #fff;">{{cantidad_item()}}</td>
                    </ng-container>
                    <ng-container matColumnDef="lista_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Lista precios N°</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="lote_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Lote</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.lote_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="Concepto_nota">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Descripción</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.concepto_nota}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;">TOTAL DEL CONCEPTO ADMINISTRATIVO </td>
                    </ng-container>


                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="costo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Costo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.costo_producto}} </b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Tipo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tipo_producto}}</b> </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="tamaño_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Tamaño</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tamano_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="subtotal_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Sub-total</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.subtotal_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;"> {{sub_total_producto()}}</td>
                    </ng-container>

                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(228, 22, 135);">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="remove(element)" matTooltip="QUITAR PRODUCTO DE LA LISTA" mat-raised-button style="border: 2px solid rgb(128, 6, 73); height: 35px; color:rgb(255, 255, 255); background-color: rgb(228, 22, 135);">
                                <mat-icon >remove_shopping_cart</mat-icon>
                                </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(228, 22, 135); color: #fff;"></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>

        </div>
        <input style="font-size: 12px;" style="display: none;" placeholder="" formControlName="cod_lista_precio" #cod_lista_precio autocomplete="off" readonly>
        <input style="font-size: 12px;" style="display: none;" #iva placeholder="" formControlName="iva_pedido" autocomplete="off" readonly>
        <div id="carrito_p3" class="row mat-elevation-z8" style=" border: 2px solid rgb(189, 66, 134);">
            <div class="col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Items</mat-label>
                        <input style="font-size: 12px; color: #fff;" #items matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Descuento</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="6" #desc_pedido matInput placeholder="" formControlName="desc_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">%</span>
                    </mat-form-field>
                </div>

            </div>

            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Monto restante</mat-label>
                        <input style="font-size: 12px; color: #fff;" #exento_monto matInput placeholder="Sub-total:" formControlName="monto_exento" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            Bs.
                            </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Sub-total</mat-label>
                        <input style="font-size: 12px; color: #fff;" #subtotal_pedido matInput placeholder="Sub-total:" formControlName="subtotal_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">I.V.A</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="I.V.A:" formControlName="iva_monto" autocomplete="off" #iva_monto readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1 ">
                        <mat-label style="color: #fff; font-size: 11px;">Descuento total</mat-label>
                        <input style="font-size: 12px; color: #fff;" #desc_pedido_monto matInput placeholder="" formControlName="desc_pedido_monto" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(128, 6, 73); background-color: rgb(228, 22, 135);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Total</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" #total_pedido matInput placeholder="Total:" formControlName="total_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs. </span>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </form>

</div>