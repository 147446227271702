import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { nkServices } from '../../servicios/app.nkservicios.services';

@Component({
  selector: 'app-form-evaluacion-soporte',
  templateUrl: './form-evaluacion-soporte.component.html',
  styleUrls: ['./form-evaluacion-soporte.component.css']
})
export class FormEvaluacionSoporteComponent implements OnInit {

  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
forma:FormGroup;
accion:any;
unidad:any;
dialogSpin:any;
listas:any = [];
solicitud:any;
solucion_soporte:any = [];
atencion_soporte:any = [];
tiempo_soporte:any = [];
tarea_hija:any = false;
tarea_campo:any = false;
almacen:any = [];
asociar:any = false;
actualizar:any = false;
@ViewChild('tarea',{static:true}) tarea:ElementRef;
@ViewChild('atencion_soporte_v',{static:true}) atencion_soporte_v: ElementRef;
@ViewChild('tiempo_soporte_v',{static:true}) tiempo_soporte_v: ElementRef;
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, private snackBar: MatSnackBar, public dialog: MatDialog, fb:FormBuilder,
    public dialogRef: MatDialogRef<FormEvaluacionSoporteComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.solicitud = data.tarea_padre;
    for (const i in this.data.listas) {
        this.listas.push(this.data.listas[i]);
    }
    for (const i in this.data.almacen) {
      this.almacen.push(this.data.almacen[i]);
  }
      this.forma = fb.group({
        'cod_ticket':'',
        'atencion_soporte':['',[Validators.required]],
        'solucion_soporte':['',[Validators.required]],
        'tiempo_soporte':['',[Validators.required]],
        'descripcion_soporte':['',[Validators.required]],
        'cod_usuario':'',
        'des_usuario':'',
        'objeto_data':data.objeto_data,
      });
    }
  ngOnInit(): void {
    this.solucion_soporte = ['COMPLETA','PARCIAL','DEFICIENTE'];
    this.atencion_soporte = ['EXCELENTE','BUENO','DEFICIENTE'];
            this.tiempo_soporte = ['EXCELENTE','BUENO','DEFICIENTE'];
  }
  modalrespuesta(){
        var accion2 = 'enviarcliente';
        var dialogRefp = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: {id:this.data.id_cliente,
                accion:accion2},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
          dialogRefp.afterClosed().subscribe(result => {
            if(result != 'cerrar'){
              console.log(this.forma.value);
              this.forma.value.cod_usuario= localStorage.getItem('cod_usuario');
              this.forma.value.des_usuario= localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido');
            this.dialogRef.close(this.forma.value);
            }
          });
        }

  actualizardatos(res:any){
    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
    }else{

    console.log(this.forma.value);
    this.dialogRef.close(this.forma.value);
    }
  }

  buscar_opciones_evaluacion(xvalor:any){
    try {
        switch (xvalor) {
          case 'S':
            this.tarea_hija = true;
            this.tarea_campo = true;
            this.atencion_soporte = [];
            this.tiempo_soporte = [];
            this.tiempo_soporte = [];
            this.solucion_soporte = ['EXCELENTE','BUENO','DEFICIENTE'];
            this.atencion_soporte = ['EXCELENTE','BUENO','DEFICIENTE'];
            this.tiempo_soporte = ['EXCELENTE','BUENO','DEFICIENTE'];
            break;

          default:
            this.tarea_hija = false;
            this.tarea_campo = false;
            this.atencion_soporte = ['N/A'];
            this.tiempo_soporte = ['N/A'];
            break;
        }
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRef.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }


}
