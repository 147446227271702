import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import * as fs from 'file-saver';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { FormConsignacionesComponent } from 'src/app/componentes/shared/modal/form-consignaciones/form-consignaciones.component';
import { FormConfirmConsignacionComponent } from 'src/app/componentes/shared/modal/form-confirm-consignacion/form-confirm-consignacion.component';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { Workbook } from 'exceljs';
import { FormReportesConsignacionesComponent } from 'src/app/componentes/shared/modal/form-reportes-consignaciones/form-reportes-consignaciones.component';

interface interfacefactura{
  cod_lote: string;
  cant_producto:string;
  cod_producto:string;
  des_producto:string;
  cant_req:string;
  restante:string;
}
interface interdatosfacefactura{
cod_guia_despacho:string;
cod_factura:string;
cod_pedido:string;
fecha_inicio:string;
}
type TableRow = [string, string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-consultar-consignaciones',
  templateUrl: './consultar-consignaciones.component.html',
  styleUrls: ['./consultar-consignaciones.component.css']
})
export class ConsultarConsignacionesComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  fecha1:any;
  fecha2:any;

  obj:any[] = [];
  result: any= [];
  xobj:any;
  dialogSpin:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  cod_orden_producto:any;
  cantidad_prod:any;
  cod_prod:any;
  des_prod:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );


  }

  ngOnInit(): void {
    this.listarConsignacionesC(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////
  listarConsignacionesC(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

        setTimeout(() => {
          let msg = msj;
    var valor = {termino:false}
    this.servicio.listarConsignacionesChttp(valor).subscribe(
      response =>{
        ////////////////UNIDADES//////////////////////
        this.xobj3 = [];
        for (const i in response[0]) {
          this.xobj3.push(response[0][i]);
        }
        console.log(this.xobj3);
        if(response){
          this.dialogSpin.close();
          console.log(response,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                  cod_consignacion:response[i].cod_consignacion,
                  cod_usuario:response[i].cod_usuario,
                  des_usuario: response[i].des_usuario,
                  cod_cliente:response[i].cod_cliente,
                  des_cliente: response[i].des_cliente,
                  estatus:response[i].estatus,
                  fecha_inicio:response[i].fecha_inicio,
                  hora_inicio:response[i].hora_inicio,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = [ 'cod_consignacion',
                                        'cod_cliente',
                                        'des_cliente',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Imprimir',

                                      ];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        this.dialogSpin.close();
        console.log('una respuesta');
        console.log(<any>error.error.message);
        this.displayedColumns = [ 'cod_consignacion',
                                        'cod_cliente',
                                        'des_cliente',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Imprimir',

                                      ];
        this.dataSource = new MatTableDataSource<any>();
              this.dataSource.paginator = this.paginator;
      }
    );
    console.log(msg);
    if(msg == 'actualizar'){
      this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    return this.xobj2;
        }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  ///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  descargar_reporte_ventas2(text:any){
    try {
  
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                            var dialogRef:any;
    this.accion = text;
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
      dialogRef = this.dialog.open(FormReportesConsignacionesComponent, {
        width: '100vw',
              maxWidth: '100vw',
              height:'100%',
        data: {
                accion:this.accion},
        panelClass: 'pedidos_modal_rep',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);
          switch (result) {
            case 'xlsx':
              /* this.reportes_ventas_xlsx(); */
              break;
            case 'pdf':
  
              break;
            case 'txt':
  
              break;
            case 'csv':
  
              break;
  
            default:
              this.reportes_rango_fecha_ventas_xlsx2(result);
              break;
          }
        }
      });
                             
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
        
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  
   reportes_rango_fecha_ventas_xlsx2(xvalor:any){
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          this.fecha1 = xvalor.fecha_inicio;
          this.fecha2 = xvalor.fecha_fin;
          this.servicio.reporteConsignacioneshttp(xvalor).subscribe(
            response=>{
              this.dialogSpin.close();
              console.log(response);
              this.descarga_prod_ventas_por_fecha_xlsx2(response);
            },
            error=>{
              this.dialogSpin.close();
              console.log(error.error);
              console.log(error.error.message);
              this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,})
            }
          );
        }, 3000);
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,})
      }
    }
  
  
    descarga_prod_ventas_por_fecha_xlsx2(xdatos:any){
      try {
        console.log(xdatos,'llego a la descarga');
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Reporte de ventas");
        let header1 = ["Reporte de consignaciones de '"+xdatos[0].des_cliente+"' desde '"+this.fecha1+"' hasta '"+this.fecha2+"'"]
        worksheet.addRow(header1);
        worksheet.addRow(' ');
        /* let worksheet = workbook.addWorksheet("Reporte de ventas desde '"+this.fecha1+"' hasta '"+this.fecha2+"'"); */
        let header=["Fecha","Consignacion","RIF","Cliente","Cod. producto","Producto","Cantidad",
                    "Usuario","Estatus"];
        let headerRow = worksheet.addRow(header);
  
          for (let x1 in xdatos)
          {
            let x2= xdatos[x1];
            console.log(x2,'x2');
            let temp=[]
            for(let y in x2)
              {
              temp.push(xdatos[x1][y])
              }
            worksheet.addRow(temp)
          }
      let fname="Reporte de consignaciones de '"+xdatos[0].des_cliente+"' desde '"+this.fecha1+"' hasta '"+this.fecha2+"'";
      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
      });
      } catch (error) {
        console.log(error);
        this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,})
      }
      }

  imprimir_descargar(xvalor:any,text:any): void {
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            this.accion = text;
            switch (this.accion) {
              case 'imprimir':
                this.accion = 'enviarpedido';
                var dialogRef = this.dialog.open(FormmMsgComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'100%',
                  data: {id:'',
                        accion:this.accion},
                  disableClose: true,
                  panelClass: 'pedidos_modal',
                  backdropClass: "bdrop"
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result != 'cerrar' && result != 'salir' ) {
                    dialogRef.close();
                    this.imprimirfactura(xvalor);
                  }else{
                    dialogRef.close();
        
                  }
        
                });
                break;
                case 'descargar':
                this.accion = 'enviarpedido';
                var dialogRef = this.dialog.open(FormmMsgComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'100%',
                  data: {id:'',
                        accion:this.accion},
                  disableClose: true,
                  panelClass: 'pedidos_modal',
                  backdropClass: "bdrop"
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result != 'cerrar' && result != 'salir' ) {
                    dialogRef.close();
                    this.imprimirfactura(xvalor);
                  }else{
                    dialogRef.close();
        
                  }
        
                });
                break;
        
              default:
                break;
            } 
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      ); 
      
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  imprimirfactura(xvalor:any){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
setTimeout(() => {
  const pdf = new PdfMakeWrapper();
  var valor = {cod_consignacion:xvalor.cod_consignacion}
 this.servicio.buscarProdConsighttp(valor).subscribe(
  async  response =>{
    this.dialogSpin.close();
     console.log(response);
       this.ELEMENT_DATA = [];

           for (const i in response.producto1) {

             this.ELEMENT_DATA.push({
               cod_producto:response.producto1[i].cod_producto,
               des_producto:response.producto1[i].des_producto,
               cant_unidad_producto:response.producto1[i].cant_producto,
             });
           }
           console.log(this.ELEMENT_DATA);
           pdf.info({
        title: 'Traslado por consignacion N°'+xvalor.cod_consignacion+'',
        author: 'Factory cosmetics C.A.',
        subject: 'Orden de consignación',
    });
    var titulo = 'FACTORY COSMETICS 3DG C.A.';
    var texto =
                  'Calle Bolivar con Calle Regulo Franquiz Local S/N,\n\
                    Sector Pueblo Arriba. Guarenas, Miranda.\n\
                    Zona Postal 1220. Telf.: (0212) 519.43.98 / 365.46.24 / 714.03.42 /425.2.83\n\
                    E-mail:contacto@factorycosmetics.com.ve / www.factorycosmetics.com.ve \n\
                                  RIF.: J-41031797-3';
      pdf.pageMargins([20,5,20,5]);
      pdf.add(pdf.ln(1));
      pdf.add(
        new Columns([ new Table([['',titulo,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).bold().absolutePosition(20,58).end]).columnGap(5).end,
                            );
      pdf.add(
        new Columns([ new Table([[await new Img("../../../../../../assets/img/Factory (1).png").build(),'','']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(10,15).end]).columnGap(5).end,
                            );
      pdf.add(
            new Columns([ new Table([['',texto,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(20,70).end]).columnGap(5).end,
                            );

      pdf.add(this.crearTabla25(xvalor,xvalor.fecha_inicio,xvalor.hora_inicio,this.cod_prod, this.des_prod));
      pdf.pageSize('A4');
      pdf.add(this.headerTabla5(this.ELEMENT_DATA));
      pdf.add(pdf.ln(1));
       pdf.create().open();

   },
   error =>{
    this.dialogSpin.close();
     console.log(error);
     console.log(<any>error.error.message);
     this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
       verticalPosition: this.verticalPosition});
   }
 );
}, 3000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(<any>error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }


  }

  //////////////////////REPORTES////////////////////////////

  /* numerofactura(data:any):ITable{
    return new Table([
      ['Asociado a la Factura Nº:  '+data+'', '', '', '', ''],
      ]).widths([200,'*','*','*','*','*']).alignment('left').fontSize(10).layout('noBorders').end;
  }
  numerodespacho(data:any):ITable{
    return new Table([
      ['Guía de Despacho Nº:  '+data.cod_guia_despacho+'', '', '', '', '', 'Emisión: 00000000'],
      ]).widths([200,'*','*','*','*','100']).alignment('left').bold().fontSize(10).layout('noBorders').end;
  }

  headerTabla():ITable{
  return new Table([
    ['Lote', 'Código', 'Descripción', 'Cantidad en Lote', 'Despacho', 'Restante']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*', '*', 200, '*', '*', '*']).alignment('center').fontSize(8).end;
  }
  crearTabla(data:interfacefactura[]):ITable{
  return new Table([
  ...this.extraerData(data)

  ]).layout('lightHorizontalLines').widths(['*', '*', 200, '*', '*', '*']).alignment('center').fontSize(10).end;
  }
  extraerData(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>[row.cod_lote,
                               row.cod_producto,
                               row.des_producto,
                               row.cant_producto,'','']);
      } */

      //////////////////////NOTAS ND////////////////////////////
  getBase64ImageFromURL5(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
  }
  headerTabla5(data:any):ITable{
  return new Table([
    ['Codigo','Descripción del producto','Cantidad solicitada'],
    ...this.extraerData5(data)
  ]).layout('lightHorizontalLines').widths(['*',350,'*']).alignment('center').fontSize(10).absolutePosition(20,200).end;
  }
  crearTabla555(data:interfacefactura[]):ITable{
  return new Table([
  ...this.extraerData5(data)

  ]).widths([20,'*','*','*','*','*']).alignment('left').fontSize(9).layout('lightHorizontalLines').absolutePosition(20,218).margin(40).end;
  }
  crearTabla55(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData55(data)

  ]).layout('lightHorizontalLines').widths(['*','*','*','*','*',20]).alignment('right').fontSize(9).absolutePosition(20,218).margin(40).end;
  }
  crearTabla5(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData555(data)

    ]).layout('lightHorizontalLines').widths(['*','*','*',200,'*','*']).alignment('left').fontSize(9).absolutePosition(20,218).margin(40).end;
    }
  /* async cinta85(data:interdatosfacefactura[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  } */
  numerofact5(data:any):ITable{

  return new Table([
    ['','','','', '','Orden de Producción Nº: '+data.cod_guia_despacho+''],
  ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('lightHorizontalLines').absolutePosition(20,135).end;
  }
  crearTabla25(data:any, data1:any, data2:any, data3:any, data4:any){
    var nombre = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
    var codigo = localStorage.getItem('cod_usuario');
    return  new Columns([new Table([

        ['Orden de entrega Nº: '+data.cod_consignacion+'','Usuario: '+nombre+''],
        ['Codigo del cliente: '+data.cod_cliente+'','Codigo del usuario: '+codigo+''],
        ['Cliente: '+data.des_cliente+'',''],


      ]).widths([200,'*']).alignment('left').fontSize(9).bold().layout('noBorders').absolutePosition(20,150).end,
      new Table([

        [ '','Emisión: '+data1+''],
        [ '','Hora: '+data2+''],
        [ '',' ']

      ]).widths(['*',250]).alignment('right').fontSize(9).bold().layout('noBorders').absolutePosition(50,150).end]).columnGap(5).end;
      }
  /* crearTabla35(data:interdatosfacefactura[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    } */
     crearTabla45(data:any){

      return  new Columns([ new Table([
        ['Observaciones:','','','','',''],
        [' ','','','', '',''],
        [' ','','','','',''],
        [' ','','','','',''],
      ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(10).bold().layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
          }
      }).absolutePosition(20,730).end,
      new Table([
        [' ','','','','',''],
        [' ','','','','',''],
        [' ','','','','',''],
        [' ','','','','','']
      ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(10).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          }
      }).absolutePosition(20,730).end
    ]).columnGap(5).end

      }

      extraerData5(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>[row.cod_producto,row.des_producto,
          row.cant_unidad_producto]);
      }
      extraerData55(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>[ '',
                            row.cant_producto, row.cant_producto]);
      }
      extraerData555(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>[
                             '', row.des_producto,
                            '']);
      }

}
