import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormCantDevProductComponent } from '../form-cant-dev-product/form-cant-dev-product.component';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { FormCantDevProdReComponent } from '../form-cant-dev-prod-re/form-cant-dev-prod-re.component';

@Component({
  selector: 'app-form-listar-produc-devolucion',
  templateUrl: './form-listar-produc-devolucion.component.html',
  styleUrls: ['./form-listar-produc-devolucion.component.css']
})
export class FormListarProducDevolucionComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  forma: FormGroup;
  stateForm: FormGroup;
  
  producto_devolucion:any = [];
  accion:any;
  dialogSpin:any;
  dataSource1=new MatTableDataSource<any>();
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();

  response:any= [];

  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  xobj:any;
  xobj2:any;
  xobj3:any=[];
  xobj4:any=[];
  xobj5:any=[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('dev_producto',{static:true})dev_producto:ElementRef;
 
  cant:any=false;

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormListarProducDevolucionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.accion = data.accion;
      console.log(data,'primero');

      this.forma = fb.group({
        'des_usuario':localStorage.getItem('Nombre') +' '+localStorage.getItem('Apellido'),
        'cod_usuario':localStorage.getItem('cod_usuario'),
        'cod_documento':data.cod_documento,
        'cod_producto': data.cod_producto,
        'des_producto': data.des_producto,
        'cant_producto': data.cant_producto,
        'cod_solicitud_devolucion':data.cod_solicitud_devolucion,
        'dev_producto':'',
        'tipo_devolucion':data.tipo_devolucion,
        'producto_devolucion':''
      });
      this.stateForm = this.formb.group({
        stateGroup:'',
      });              
    }

  ngOnInit(): void {
    this.ListarProductosFacturas(this.data);
    this.formulario();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  
  formulario():void{
    this.cant = false;
  }
    ListarProductosFacturas(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data:{
            accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout( async() => {
      this.servicio.mostrarProductosDevolucioneshttp(xvalor).subscribe(
        response =>{
          this.xobj3 = [];
          for (const i in response[0]){
            this.xobj3.push(response[0][i]);
          }
          if(response){
            this.dialogSpin.close();
            console.log(response.result,'una respuesta ultra final');
            this.xobj2 = response;
            if (this.data.tipo_devolucion === 'DVLCN') {
              this.ELEMENT_DATA = [];
              for (const i in response.result) {  
                if (parseInt(response.result[i].cant_producto) > 0 ) {
                  this.ELEMENT_DATA.push({      
                    id_producto:response.result[i].id_producto,  
                    cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,   
                    cod_guia_despacho:response.result[i].cod_guia_despacho,             
                    cod_documento:response.result[i].cod_documento,              
                    cod_producto:response.result[i].cod_producto,         
                    des_producto:response.result[i].des_producto,
                    des_usuario:localStorage.getItem('Nombre'), 
                    cod_usuario:localStorage.getItem('cod_usuario'),          
                    cant_producto:response.result[i].cant_producto,
                    dev_producto:'0',
                    lote_1:'',
                    valor_1:'',
                    lote_2:'',
                    valor_2:'',
                    lote_3:'',
                    valor_3:'',
                    accion:this.accion
                    });
                }
                }

                console.log(this.ELEMENT_DATA,'aqui esta tu codigo')
                this.displayedColumns = [
                    'cod_producto',
                    'des_producto',
                    'cant_producto',
                    'dev_producto',
                    'lote_1',
                    'valor_1',
                    'lote_2',
                    'valor_2',
                    'lote_3',
                    'valor_3',
                    'remov_elem'
                 ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
            }
            if (this.data.tipo_devolucion !== 'DVLCN') {
              this.ELEMENT_DATA = [];
              for (const i in response.result) {  
                if (parseInt(response.result[i].cant_producto) > 0) {
                  this.ELEMENT_DATA.push({      
                    id_producto:response.result[i].id_producto,  
                    cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,   
                    cod_guia_despacho:response.result[i].cod_guia_despacho,             
                    cod_documento:response.result[i].cod_documento,              
                    cod_producto:response.result[i].cod_producto,         
                    des_producto:response.result[i].des_producto,
                    des_usuario:localStorage.getItem('Nombre'), 
                    cod_usuario:localStorage.getItem('cod_usuario'),          
                    cant_producto:response.result[i].cant_producto,
                    dev_producto:'0',
                    lote_1:'',
                    valor_1:'',
                    lote_2:'',
                    valor_2:'',
                    lote_3:'',
                    valor_3:'',
                    accion:this.accion
                    });
                }
                }
                console.log(this.ELEMENT_DATA,'aqui esta tu codigo')
                this.displayedColumns = [
                    'cod_producto',
                    'des_producto',
                    'cant_producto',
                    'dev_producto',
                    'remov_elem'
                 ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
            }
            
          }
        },
        error => {
          this.dialogSpin.close();
          if (this.data.tipo_devolucion === 'DVLCN') {
            this.displayedColumns = [
              'cod_producto',
              'des_producto',
              'cant_producto',
              'dev_producto',
              'lote_1',
              'valor_1',
              'lote_2',
              'valor_2',
              'lote_3',
              'valor_3',
              'remov_elem'
           ];
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
          if (this.data.tipo_devolucion !== 'DVLCN') {
            this.displayedColumns = [
              'cod_producto',
              'des_producto',
              'cant_producto',
              'dev_producto',
              'remov_elem'
           ];
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }
      );
    },1000);
    return this.xobj2;
  } 

  tomar_data(xvalor:any,xvalor2:any){
    try {      
      if(xvalor){           
            var dialogRef
            if (this.data.tipo_devolucion === 'DVLCN') {
              dialogRef = this.dialog.open(FormCantDevProductComponent, {
                width:'100vw',
                maxWidth:'100vw',
                height:'100vh',
                data: {cod_producto:xvalor2.cod_producto,des_producto:xvalor2.des_producto,
                        cod_usuario:localStorage.getItem('cod_usuario'),
                        des_usuario:localStorage.getItem('Nombre'),
                        cant_producto:xvalor2.cant_producto,
                        id_producto:xvalor2.id_producto,cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
                        tipo_devolucion:this.data.tipo_devolucion,
                        cod_documento: this.data.cod_documento,
                      },
                        disableClose: true,
                panelClass:'pedidos_modal_rev',
                backdropClass:"bdrop"
              });

              dialogRef.afterClosed().subscribe(result => {
                console.log(result,'informacion 3');
                for (const i in this.ELEMENT_DATA) {
                  if (this.ELEMENT_DATA[i].cod_producto == result.cod_producto) {
                    this.ELEMENT_DATA[i].dev_producto = result.cant_dev;
                    this.ELEMENT_DATA[i].lote_1 = result.lote_1;
                    this.ELEMENT_DATA[i].valor_1 = result.valor_1;

                    this.ELEMENT_DATA[i].lote_2 = result.lote_2;
                    this.ELEMENT_DATA[i].valor_2 = result.valor_2;

                    this.ELEMENT_DATA[i].lote_3 = result.lote_3;
                    this.ELEMENT_DATA[i].valor_3 = result.valor_3;
                  }
              
                }
          
                this.producto_devolucion.push(result);
            })
            }
            if (this.data.tipo_devolucion !== 'DVLCN') {
              dialogRef = this.dialog.open(FormCantDevProdReComponent, {
                width:'100vw',
                maxWidth:'100vw',
                height:'100vh',
                data: {cod_producto:xvalor2.cod_producto,des_producto:xvalor2.des_producto,
                        cod_usuario:localStorage.getItem('cod_usuario'),
                        des_usuario:localStorage.getItem('Nombre'),
                        cant_producto:xvalor2.cant_producto,cod_documento:xvalor2.cod_documento,
                        id_producto:xvalor2.id_producto,cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
                        tipo_devolucion:this.data.tipo_devolucion
                      },
                        disableClose: true,
                panelClass:'pedidos_modal',
                backdropClass:"bdrop"
              });

              dialogRef.afterClosed().subscribe(result => {
                console.log(result,'informacion 3');
                  for (const i in this.ELEMENT_DATA) {
                    if (this.ELEMENT_DATA[i].cod_producto === result.cod_producto) {
                      this.ELEMENT_DATA[i].dev_producto = result.cant_dev;

                    }
                
                  }
                  this.producto_devolucion.push(result);
            })
            }        
      }else{
        for (const i in this.ELEMENT_DATA) {
          if (this.ELEMENT_DATA[i].cod_producto === xvalor2.cod_producto) {
              this.ELEMENT_DATA[i].cant_producto = this.ELEMENT_DATA[i].cant_producto;
              this.ELEMENT_DATA[i].dev_producto = '0';

                    this.ELEMENT_DATA[i].lote_1 = '';
                    this.ELEMENT_DATA[i].valor_1 = '';

                    this.ELEMENT_DATA[i].lote_2 = '';
                    this.ELEMENT_DATA[i].valor_2 = '';

                    this.ELEMENT_DATA[i].lote_3 = '';
                    this.ELEMENT_DATA[i].valor_3 = '';
          }
      
        }
        this.producto_devolucion = [];
      }
    } catch (error) {
      console.log(error);
    }            
  }

  modalrespuesta(){
    if (this.producto_devolucion.length === 0) {
      this.snackBar.open('No haz seleccionado ningun producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }else{ 

      this.accion = "enviarcliente";

      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width:'100vw',
        maxWidth:'100vw',
        height:'100vh',
        data:{id:'',
        accion:this.accion},
        disableClose:true,
        panelClass:'pedidos_modal',
        backdropClass:"bdrop"
       });
       dialogRef.afterClosed().subscribe(result => {

        if(result != 'cerrar'){
          this.forma.value.producto_devolucion =this.producto_devolucion;
          console.log( this.forma.value,'ver cantidad dev');
          this.dialogRefp.close(this.forma.value);
        }
      });
    } 
  }

  cantidad_dev() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].dev_producto)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.'));
         });
       return suma;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
 cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_producto)
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.'));
       });
    return suma;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
