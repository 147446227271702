import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { environment } from 'src/environments/environment';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-formulacion',
  templateUrl: './form-formulacion.component.html',
  styleUrls: ['./form-formulacion.component.css']
})
export class FormFormulacionComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('cod_formula') cod_formula:ElementRef;
  @ViewChild('cod_producto_mp') cod_producto_mp:ElementRef;
  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;

  arry: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
dialogSpin:any;
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private servicio:nkServices,
    private formb:FormBuilder, 
    private fb:FormBuilder,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    private dialogRefp: MatDialogRef<FormFormulacionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_producto':data.cod_producto,
        'des_producto':data.des_producto,
        'cod_formula':data.cod_formula,
        'cod_usuario':'',
        'des_usuario':'',
        'tipo_producto':data.tipo_producto,
        'cant_prod':'',
        'cod_producto_mp':'',
        'unidad_medida':'',
        'formula':'',
        'cod_empresa':''
      });
    }

  ngOnInit(){
    this.listarFormula(this.data);
    this.listarProdMp();
  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
 async listarProdMp(){
      try {
        this.codigo = [];
        this.producto = [];
    if (this.data.tipo_producto.toUpperCase() === await this.atrb.TIPO_PRODUCTO.PTO.toUpperCase()) {
     let response =  await this.servicio.listarProductoTerminadohttp().toPromise();
  
          this.arry = this.forma.get('cod_producto_mp')!.valueChanges
                                  .pipe(
                                    startWith(''),
                                    map(value => this._filterGroup(value))
                                  );
                    for (const i in response) {
                      this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                      this.codigo.push(response[i].cod_producto);
                    }
  
                    this.stateGroups= [{
                      letter: 'Productos',
                      names: this.producto,
                      code: this.producto
                    }];
    }
    if (this.data.tipo_producto.toUpperCase() === await this.atrb.TIPO_PRODUCTO.MPP.toUpperCase()) {
     let response =  await this.servicio.listarProductoMateriaPrimahttp().toPromise();
  
          this.arry = this.forma.get('cod_producto_mp')!.valueChanges
                                  .pipe(
                                    startWith(''),
                                    map(value => this._filterGroup(value))
                                  );
                    for (const i in response) {
                      if (this.data.cod_producto !== response[i].cod_producto) {
                        this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                        this.codigo.push(response[i].cod_producto);
                      }
                    }
  
                    this.stateGroups= [{
                      letter: 'Materia prima',
                      names: this.producto,
                      code: this.producto
                    }];
    }
    if (this.data.tipo_producto.toUpperCase() !== await this.atrb.TIPO_PRODUCTO.PTO.toUpperCase() && 
        this.data.tipo_producto.toUpperCase() !== await this.atrb.TIPO_PRODUCTO.MPP.toUpperCase()) {
      let response = await this.servicio.listarProductoMateriaPrimahttp().toPromise();
          this.arry = this.forma.get('cod_producto_mp')!.valueChanges
                                  .pipe(
                                    startWith(''),
                                    map(value => this._filterGroup(value))
                                  );
                    for (const i in response) {
                      this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                      this.codigo.push(response[i].cod_producto);
                    }
  
                    this.stateGroups= [{
                      letter: 'Productos',
                      names: this.producto,
                      code: this.producto
                    }];
    }
      } catch (error) {
        await this.notifi.customToastNotification(error.error.message);
      }
  }

 async listarFormula(xvalor:any){
        try {
          await this.util.abrirSpinner();
          await this.servicio.consultar_sesionhttp(null).toPromise();
          this.cant = false;
          let response;
          if (this.data.tipo_producto.toUpperCase() === await this.atrb.TIPO_PRODUCTO.MPP.toUpperCase()) {
            response = await this.servicio.buscarFormulaMpphttp(xvalor).toPromise();
          }else{
            response = await this.servicio.buscarFormulahttp(xvalor).toPromise();
          }
          this.ELEMENT_DATA = [];
              for (const i in response) {
                if (response[i].cod_img === '' || response[i].cod_img === null) {
                  response[i].cod_img = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                }else{
                  response[i].cod_img = environment.path_storage+response[i].cod_img
                }
                this.ELEMENT_DATA.push({
                  cod_img:response[i].cod_img,
                  cod_prod_mp:response[i].cod_prod_mp,
                  des_prod_mp:response[i].des_prod_mp,
                  cantidad_prod_mp: response[i].cantidad_prod_mp,
                  tipo_producto: response[i].tipo_producto_mp,
                  unidad_prod_mp:response[i].unidad_prod_mp,
                });
              }
              console.log(this.ELEMENT_DATA);
              this.displayedColumns = [
                'cod_img',
                'cod_prod_mp',
                'des_prod_mp',
                'tipo_producto',
                'cantidad_prod_mp',
                'unidad_prod_mp',
                'procesos'
              ];
            this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
            await this.util.cerrarSpinner();
        } catch (error) {
          console.log(error);
          this.displayedColumns = [
            'cod_img',
            'cod_prod_mp',
            'des_prod_mp',
            'tipo_producto',
            'cantidad_prod_mp',
            'unidad_prod_mp',
            'procesos'
          ];
        this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
          await this.util.cerrarSpinner();
          await this.notifi.customToastNotification(error.error.message);
        }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

 async addElement() {
      try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        let arry = [];
        let arry2 = [];
        if (parseInt(this.cant_producto.nativeElement.value) >= 0 && 
                    this.forma.value.cod_producto_mp !== '' && 
                    this.forma.value.cod_producto_mp !== undefined){
          var cod = this.forma.value.cod_producto_mp.split(' ');
        var valor:any = {cod_producto:cod[0],
                          tipo_producto:this.forma.value.tipo_producto}
       const response = await this.servicio.buscarPRODUCTOmpttp(valor).toPromise();
            if (response[0].cod_img === '' || response[0].cod_img === null) {
              response[0].cod_img = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
            }else{
              response[0].cod_img = environment.path_storage+response[0].cod_img
            }
            if (this.ELEMENT_DATA.length == 0) {
              this.codigo = [];
              this.producto = [];
              this.stateGroups2 =[];
             await this.listarProdMp();
              this.ELEMENT_DATA.push({
                cod_img:response[0].cod_img,
                cod_prod_mp:response[0].cod_producto,
                des_prod_mp:response[0].des_producto,
                cantidad_prod_mp:this.cant_producto.nativeElement.value,
                tipo_producto: response[0].tipo_producto,
                unidad_prod_mp:response[0].unidad_medida_producto
              });
              this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
              this.cod_producto_mp.nativeElement.value = '';
              this.cant_producto.nativeElement.value = '';
              await this.util.cerrarSpinner();
              await this.notifi.success(await this.atrb.MENSAJES_FIJOS.agregar_item_lista);
            }else{
                for (const i in this.ELEMENT_DATA) {
                 arry.push(this.ELEMENT_DATA[i].cod_prod_mp);
                }
                let index = arry.includes(response[0].cod_producto);
                if (!index) {
                  this.codigo = [];
                  this.producto = [];
                   await this.listarProdMp();
                    this.ELEMENT_DATA.push({
                      cod_img:response[0].cod_img,
                      cod_prod_mp:response[0].cod_producto,
                      des_prod_mp:response[0].des_producto,
                      cantidad_prod_mp:this.cant_producto.nativeElement.value,
                      tipo_producto: response[0].tipo_producto,
                      unidad_prod_mp:response[0].unidad_medida_producto
                    });
                    this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                    this.cod_producto_mp.nativeElement.value = '';
                    this.cant_producto.nativeElement.value = '';
                    await this.util.cerrarSpinner();
                    await this.notifi.success(await this.atrb.MENSAJES_FIJOS.agregar_item_lista);
                }else{
                  this.stateGroups2 =[];
                  this.lotepro = [];
                  this.codigo = [];
                  this.producto = [];
                 await this.listarProdMp();
                  this.cod_producto_mp.nativeElement.value = '';
                  this.cant_producto.nativeElement.value = '';
                  await this.util.cerrarSpinner();
                  await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.prod_select_ya);
                }
            }
        }else{
             if (parseInt(this.cant_producto.nativeElement.value) <= 0 || 
                  isNaN(this.cant_producto.nativeElement.value) || 
                  this.cant_producto.nativeElement.value === "") {
                    await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.mayor_0_solo_num);
            }
            if ( this.cod_producto_mp.nativeElement.value === "") {
              await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.select_prod_base);
            }
            await this.util.cerrarSpinner();
        }
      } catch (error) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
      }
  }

async eliminarItems(val:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
     this.table.renderRows();
     await this.notifi.success(await this.atrb.MENSAJES_FIJOS.quitar_item_lista);
     await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
   }
//////////////////////FUNCION DE ACCION/////////////////////////
async modalrespuesta(){
  try {
     await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
    if (this.ELEMENT_DATA.length > 0) {
      await this.util.cerrarSpinner();
      this.accion = "";
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'auto',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result != 'cerrar'){
          let img;
          for (const i in this.ELEMENT_DATA) {
                img = this.ELEMENT_DATA[i].cod_img.split(environment.path_storage);
            this.ELEMENT_DATA[i].cod_img = img[1];
          }
         this.forma.patchValue({
          formula:this.ELEMENT_DATA,
          cod_usuario:await this.atrb.COD_USUARIO.cod_usuario,
          des_usuario: await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
          cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
         });
         if (this.data.tipo_producto.toUpperCase() === await this.atrb.TIPO_PRODUCTO.MPP.toUpperCase() ) {
          console.log(this.forma.value);
          await this.gestionformulaMp(this.forma.value);
         }else{
          await this.gestionformula(this.forma.value);
         }
        }else{
          await this.util.cerrarSpinner();
        }
      });
    }else{
        await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.select_prod_base);
    }
  } catch (error) {
    await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
  }
}

async gestionformula(xvalor:any){
  try {
      const response = await this.servicio.gestionFormulahttp(xvalor).toPromise();
          this.cod_formula.nativeElement.value = response.resul[0].cod_formula;
          await this.util.cerrarSpinner();
          this.data.cod_formula = response.resul[0].cod_formula;
          await this.listarFormula(this.data);
          await this.notifi.success(response.message);
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }

}
async gestionformulaMp(xvalor:any){
  try {
      const response = await this.servicio.gestionFormulaMphttp(xvalor).toPromise();
          this.cod_formula.nativeElement.value = response.resul[0].cod_formula;
          await this.util.cerrarSpinner();
          this.data.cod_formula = response.resul[0].cod_formula;
          await this.listarFormula(this.data);
          await this.notifi.success(response.message);
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }

}
//////////////////////FUNCION DE ACCION/////////////////////////

async format_final(event: any, valor:any, termino:any)
  {
    try {
      var num;
      if (termino.toUpperCase() !== this.atrb.BASE.toUpperCase() &&
          termino.toUpperCase() !== this.atrb.TASA.toUpperCase() &&
          termino.toUpperCase() !== this.atrb.DESCUENTO.toUpperCase()
          ) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
      if (termino.toUpperCase() === this.atrb.BASE.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        num = inputId.value.replace(/\D/g,'');
        if(!isNaN(parseFloat(num))){
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/\D/g, "")
                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          this.forma.patchValue({cant_producto:num});
          inputId.value = num;
          }else{ 
            inputId.value = inputId.value.replace(/[^\d\.]*/g,'');
          }
      }
      if (termino.toUpperCase() === this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
    } catch (error) {
      console.log(error);
    }
  }
async cerrarmodal(){
    try {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRefp.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
    }
    }
}
