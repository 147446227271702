import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormPreciosComponent } from 'src/app/componentes/shared/modal/form-precios/form-precios.component';
import { FormConsultaListaComponent } from 'src/app/componentes/shared/modal/form-consulta-lista/form-consulta-lista.component';
import { FormPreciosActualizarComponent } from 'src/app/componentes/shared/modal/form-precios-actualizar/form-precios-actualizar.component';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormParametrosComponent } from 'src/app/componentes/shared/modal/form-parametros/form-parametros.component';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.css']
})
export class PreciosComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  forma:FormGroup;
  tipo_cliente:any = [];
  parametros_precios:any = [];
  parametros_operacion:any = [];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  spinner:any = false;
  SPCL:any;
  STNDR:any;
  CDN:any;
  DSTRBDR:any;
  dialogSpin:any;
  mode: ProgressSpinnerMode = 'indeterminate';
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
  constructor(
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,  
    private fb:FormBuilder,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ) {

    this.forma = fb.group({
      'tasa_base':'',
    });
  }

  ngOnInit(){
    this.listarlprecios(null);
    this.buscardefiniciones();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
  }

  /////////////////////////////////////////////////////////////////////
 async listarlprecios(msj:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let msg = msj;
        var clientes = {des_definicion:'clientes'}
    const response = await this.servicio.listarLprecioshttp(this.atrb.COD_EMPRESA).toPromise();
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response) {
                this.ELEMENT_DATA.push({
                  cod_lista_precio_producto:response[i].cod_lista_precio_producto,
                  cod_usuario: response[i].cod_usuario,
                  des_usuario:response[i].des_usuario,
                  estatus:response[i].estatus,
                  fecha_inicio:response[i].fecha_inicio,
                  hora_inicio:response[i].hora_inicio,
                  fecha_fin:response[i].fecha_fin,
                  hora_fin:response[i].hora_fin,
                  tipo_lista:response[i].tipo_lista,
                  tasa_precio:response[i].tasa_precio,
                  cod_cliente:response[i].cod_cliente,
                  des_cliente:response[i].des_cliente,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = ['cod_lista_precio_producto',
                                        'cod_cliente',
                                        'des_cliente',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'tipo_lista',
                                        'tasa_precio',
                                        'procesos'];
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
              if(msg === await this.atrb.ACCION_ACTUALIZAR){
                await this.util.cerrarSpinner();
                  await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
              }else{
                await this.util.cerrarSpinner();
              }
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      this.displayedColumns = ['cod_lista_precio_producto',
                                        'cod_cliente',
                                        'des_cliente',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'tipo_lista',
                                        'tasa_precio',
                                        'procesos'];
        this.dataSource = new MatTableDataSource<any>();
              this.dataSource.paginator = this.paginator;
        await this.notifi.customToastNotification(error.error.message);
    }
  }
  async buscardefiniciones(){
    try {
      var xvalor = {
        cod_menu: localStorage.getItem('sub_main'),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.buscardefiniciones),
      };
        const response = await this.servicio.ApiResthttp(xvalor).toPromise();
        this.tipo_cliente= [];
        for (const i in response) {
          if (response[i].des_definicion.toUpperCase() === this.atrb.PRECIOS.PRECIOS.toUpperCase()) {
            this.tipo_cliente.push(response[i]);
          }
        }
    } catch (error) {
      console.log(error.error.message);
    }
  }
async parametros_precios_create(text:any){
    try {
                 await this.util.abrirSpinner();
                 await this.servicio.consultar_sesionhttp(null).toPromise();
                 let val_def = {
                  val_def:await this.atrb.ACCESO_VALDEF.listas_precios,
                  tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
                  cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                }
                await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                await this.util.cerrarSpinner();
                            var accion = text;
                            var dialogRef = this.dialog.open(FormParametrosComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'100vh',
                              data: {id:'',
                                    parametros:this.parametros_precios,
                                    operacion:this.parametros_operacion,
                                    accion:accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal3',
                              backdropClass: "bdrop"
                            });
                            dialogRef.afterClosed().subscribe(async result => {
                              await this.util.abrirSpinner();
                              if(result != 'cerrar'){
                                await this.util.cerrarSpinner();
                              }else{
                                await this.util.cerrarSpinner();
                              }
                            });
    } catch (error) {
      await this.util.cerrarSpinner();
        await this.util.notificacionErrorApp(error.error.message);
    }
  }
async update_insert_data(obj:any,termino:any){
  try {
            await this.util.abrirSpinner();
              const response = await  this.servicio.consultar_sesionhttp(null).toPromise();
                        var dialogRef:any;
                        this.accion = termino;
                        let datos =  this.atrb.COD_USUARIO.cod_usuario
                        let nombre = this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre) +' '+
                                     this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido);
                        if (this.accion.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def;
                                val_def = {
                                  val_def:await this.atrb.ACCESO_VALDEF.listas_precios,
                                  tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                  cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                  api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                }
                            await this.servicio.ApiResthttp(val_def).toPromise();
                      }
                          await this.util.cerrarSpinner();
                          dialogRef = this.dialog.open(FormPreciosActualizarComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {cod_lista_precio_producto:obj.cod_lista_precio_producto,
                                    cod_cliente:obj.cod_cliente,
                                    des_cliente:obj.des_cliente,
                                    cod_usuario:datos,
                                    des_usuario: nombre,
                                    termino:obj.tipo_lista,
                                    fecha_inicio:obj.fecha_inicio,
                                    tasa_precio:obj.tasa_precio,
                                    tipo_lista:obj.tipo_lista,
                                    accion:this.accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                        }else{
                          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def;
                                val_def = {
                                  val_def:await this.atrb.ACCESO_VALDEF.listas_precios,
                                  tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                  cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                  api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                }
                            await this.servicio.ApiResthttp(val_def).toPromise();
                            let objeto = {
                              cod_empresa: this.atrb.COD_EMPRESA.cod_empresa,
                              mod_usuario: this.atrb.MOD_USUARIO.mod_usuario,
                              des_parametro: await this.atrb.OPERACIONES.precios,
                              api: await this.hashkey.encrypt(await this.atrb.API_REST.validarLicenciaProcesos)
                            };
                            await this.servicio.ApiResthttp(objeto).toPromise();
                      }
                          await this.util.cerrarSpinner();
                          dialogRef = this.dialog.open(FormmMsgComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {cod_lista_precio_producto:'',
                                    cod_usuario:datos,
                                    des_usuario: nombre,
                                    termino:termino,
                                    tipo_cliente:this.tipo_cliente,
                                    accion:this.accion},
                                    disableClose: true,
                                    panelClass: 'pedidos_modal_login',
                                    backdropClass: "bdrop"
                          });
                        }
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                            if(result != 'cerrar'){
                              console.log(result);
                              result.cod_usuario = await this.atrb.COD_USUARIO.cod_usuario;
                              result.cod_empresa = await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa);
                              result.des_usuario = await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre) +' '+
                                                   await  this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido);
                              await this.gestionprecios(result);
                            }else{
                              await this.util.cerrarSpinner();
                            }
                          });
  } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}
async gestionprecios(xvalor:any){
    try {
        const response = await this.servicio.gestionListaprecioshttp(xvalor).toPromise();
                await this.util.cerrarSpinner();
                await this.listarlprecios(null);
             await this.notifi.success
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.listarlprecios(null);
      await this.notifi.customToastNotification(error.error.message);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
