<div class="body4 fadeIn2 animated fast" style="z-index: -1;">
    <div class="sidebar">
        <span class="text-center active">Reportes</span>
        <a #link *ngFor="let i of menu3" class="text-left " (click)="cambiarrouter('entrar')" [routerLink]="[{ outlets: {'reportes_app': [i.dir_menu] } }]"><i style="font-size: 15px; margin-right: 5px;" class="fa fa-check-square" aria-hidden="true"></i>{{i.des_menu}}</a>
        <a (click)="cambiarrouter('entrar')" [routerLink]="[{ outlets: {'infouser': ['infouser'] } }]" class=" text-left"><i style="font-size: 20px; margin-right: 5px;" class="fa fa-thumb-tack" aria-hidden="true"></i> Acerca de</a>
    </div>
    <div class="content" *ngIf="ruta =='entrar'">
        <router-outlet name="reportes_app"></router-outlet>
        <router-outlet class=""></router-outlet>
    </div>
</div>