<!-- <mat-progress-spinner [mode]="mode" color="accent" style="background-color: rgb(255, 255, 255); border-radius: 50%; padding: 1%;">
   
</mat-progress-spinner> -->

<!-- <mat-progress-spinner [mode]="mode" diameter="100" strokeWidth="12">
</mat-progress-spinner> -->

<!-- <button class="btn btn-primary" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">Loading...</span>
  </button> -->
<!-- <button class="btn btn-danger" type="button" disabled>
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Cargando...
  </button> -->

<!-- <button class="btn btn-primary" type="button" disabled>
    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">Loading...</span>
  </button> -->
<!-- <button class="btn btn-dark" style="color: #fff; background-color: rgba(18, 100, 155, 0.993);" type="button" disabled>
    <span class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
    Cargando...
  </button> -->
<button class="btn btn-dark p-1" style="border-radius: 1rem; color: #fff;" disabled>
    <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
    <span class="mx-1 p-1">Cargando...</span>
  </button>