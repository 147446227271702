
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav_componente nav py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarTareasSoporte("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="CREAR EMPLEADO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","insertar")' ><mat-icon >add</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>DEFINICIONES TAREAS DE SOPORTE</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>
  
    <div class="row altura_table">
        <div class="col-md-12 px-0  border h-100 table_overflow_color">
           <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped  table-condensed table-hover">

            <ng-container matColumnDef="cod_solicitud">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Cod solicitud</strong></h5>
                </th>
                <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_solicitud}} </td>
               
            </ng-container>

            <ng-container matColumnDef="des_solicitud">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Solicitud</strong></h5>
                </th>
                <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_solicitud}} </td>
              
            </ng-container>

            <ng-container matColumnDef="cod_solicitud_padre">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Cod solitud padre</strong></h5>
                </th>
                <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_solicitud_padre}} </td>
               
            </ng-container>

            <ng-container matColumnDef="des_solicitud_padre">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Solicitud padre</strong></h5>
                </th>
                <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_solicitud_padre}} </td>
               
            </ng-container>

            <ng-container matColumnDef="nivel_solicitud_padre">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Nivel</strong></h5>
                </th>
                <td mat-cell *matCellDef="let element" class="td_style"> {{element.nivel_solicitud_padre}} </td>
              
            </ng-container>

            <ng-container matColumnDef="estatus">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Estatus</strong></h5>
                </th>
                <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus}} </td>
               
            </ng-container>

            <ng-container matColumnDef="ord_solicitud">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Orden</strong></h5>
                </th>
                <td mat-cell *matCellDef="let element" class="td_style"> {{element.ord_solicitud}} </td>
                
            </ng-container>

            <ng-container matColumnDef="procesos">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Editar</strong></h5>
                </th>
                <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" style="padding: 1px; font-size: 12px;" class="col-md-1">
                    <button class=" bg-primary mr-1" mat-mini-fab (click)='update_insert_data(element, "actualizar")' matTooltip="EDITAR">
                        <mat-icon >edit</mat-icon>
                      </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            
        </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
  </div>