import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormFormulaUnidadMppComponent } from '../form-formula-unidad-mpp/form-formula-unidad-mpp.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-consulta-ordenes-mpp',
  templateUrl: './form-consulta-ordenes-mpp.component.html',
  styleUrls: ['./form-consulta-ordenes-mpp.component.css']
})
export class FormConsultaOrdenesMppComponent implements OnInit {


  @ViewChild('table') table:MatTable<any>;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('tasa_base',{static:true}) tasa_base:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef

  arry: Observable<StateGroup[]>;
  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
bandera:any;
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormConsultaOrdenesMppComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'Seleccione un producto',
        'cant_producto':'',
        'ord_producto':'',
        'cod_orden_producto':''

      });

    }

  ngOnInit(): void {
    this.cod_orden_producto.nativeElement.value = this.data.cod_orden_producto;
    this.listarProductoOrdenesProdMpp(this.data.cod_orden_producto);
    this.listarproductompp();

  }
  ngAfterViewInit() {

  }
  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
  listarproductompp(){
    this.codigo = [];
    this.producto = [];
  
    this.servicio.listarProductoMateriaPrimaPreparadahttp().subscribe(
      response =>{
  
        this.arry = this.forma.get('cod_producto')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }
  
                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.codigo
                  }];
  
                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }
  listarProductoOrdenesProdMpp(xvalor:any){
    var valor = {cod_orden_producto:xvalor}
    console.log(valor, 'aqui')
    this.servicio.buscarProductoOrdenesProdMpphttp(valor).subscribe(
      response =>{
        console.log(response);
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                  cod_producto:response[i].cod_producto,
                  des_producto:response[i].des_producto,
                  cant_producto:response[i].cant_producto
                });
              }
              this.displayedColumns = [
                                        'cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'detalle'
                                      ];
              this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);

      },
      error =>{
        console.log(error);
        console.log(<any>error.error.message);
        this.displayedColumns = [
                                  'cod_producto',
                                  'des_producto',
                                  'cant_producto',
                                  'detalle'
                                ];
        this.dataSource1 = new MatTableDataSource<any>();
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }
  formulario():void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    this.displayedColumns = [
      'cod_producto',
      'des_producto',
      'cant_producto',
      'detalle'
     ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

    cerrarmodal(): void {
      this.dialogRefp.close('cerrar');
    }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource1.filter = filterValue.trim().toLowerCase();
    }
    async detalleOrdenProdMpp(xvalor:any){
      console.log(xvalor);
      this.dialog.open(FormFormulaUnidadMppComponent, {
        width: '100%',
        height:'90%',
        data: {producto: xvalor},
        panelClass: 'pedidos_modal'
      });
}
}