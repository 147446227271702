<div id="modal_carrito" class=" container-fluid h-100" style="background-color:#2b69dd;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" class="h-100">
        <div id="carrito_p1" class="row mat-elevation-z8 " style=" border: 2px solid #475bca;">
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Codigo definición</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput placeholder="" formControlName="cod_definicion" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Definiciones</mat-label>
                        <input style="font-size: 11px; color: #fff;" #definicion matInput placeholder="" formControlName="concepto_definicion" autocomplete="off" >
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon >edit</mat-icon>
                    </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-3 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Descripción</mat-label>
                        <input style="font-size: 11px; color: #fff;" #descrip matInput placeholder="" formControlName="descrip_definicion" autocomplete="off" >
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon >edit</mat-icon>
                    </span>
                    </mat-form-field>
                </div>
            </div>
          
            <div class=" col-md-2 px-0 py-2 h-100">
                <div class=" fileUpload btn btn-primary"  style="display: flex; justify-content: center; align-items: center;">
                    <span>Upload</span>
                    <input class="upload" type="file" (change)="onFileChange($event)" multiple="false">
                </div>
            </div>

            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button  class="btn btn-block btn-lg btn-light h-100" (click)="addElement()" matTooltip="AGREGAR ACCESO" style=" color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                  <mat-icon >add</mat-icon>
                </button>
                </div>
            </div>

            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button  class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GUARDAR" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                      <mat-icon >save</mat-icon>
                  </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                      <mat-icon >exit_to_app</mat-icon>
                  </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row " style=" border: 2px solid #374fd6;">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100">
                <table #table mat-table [dataSource]="dataSource" class=" table-primary  text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="cod_concepto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_concepto}}</b></td>
                
                    </ng-container>

                    <ng-container matColumnDef="concepto_definicion">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Concepto definición</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.concepto_definicion}}</b></td>
                
                    </ng-container>

                    <ng-container matColumnDef="descrip_definicion">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Descripción</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.descrip_definicion}}</b></td>
                
                    </ng-container>

                    <ng-container matColumnDef="min_concepto_definicion">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Concepto definición min</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.min_concepto_definicion}}</b></td>
                
                    </ng-container>
               
                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="remove(element)" matTooltip="ELIMINAR" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color:#4979d3; border: solid 2px rgba(47, 40, 119, 0.692);">
                          <mat-icon >delete</mat-icon>
                          </button>
                        </td>
                        
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                </table>
                <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
            </div>
            
        </div>
        <div id="carrito_p3" class="row py-1 px-1" style=" border: 2px solid #374fd6;">
           
            <div class="col-md-12 px-0 py-1 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>search</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>

