<!-- <div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: rgb(53, 35, 50); padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>ALMACEN  MATERIA PRIMA</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarInventarioMP("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='descargar_inv("mp")'><mat-icon >list</mat-icon></button></li>
                </ul>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.4%; overflow-y: auto;">
        <div class="col-md-12 px-0  border h-100" style="background-color: rgb(53, 35, 50);">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
                
                <ng-container matColumnDef="cod_inv">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Cod Inv </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_inv}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Cod Inv </strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="des_inv">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Inventario </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_inv}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Inventario </strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tipo_producto_mp">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Tipo de Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.tipo_producto_mp}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Tipo de Producto</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Cod producto </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;"> {{cantidad_item()}} </td>
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Producto </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Producto </strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lote_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Lote </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.lote_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Lote </strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Cantidad </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Cantidad </strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="estatus_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Estatus</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="unidad_medida_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Unidad de medida</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.unidad_medida_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Unidad de medida</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="traslados">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Traslados</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;" (click)='traslados(element)'>
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-raised-button color="primary" aria-label="Example icon button with a delete icon">
                                    <mat-icon matTooltip="TRASLADOS DE INVENTARIO">swap_horiz</mat-icon>
                                  </button>
                            </div>
                        </section>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Traslados</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ajustar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Cargo de producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="ajustes_almacen_MP(element,'cargo')">
                        <button matTooltip="CARGAR PRODUCTO" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(28, 27, 82);">
          <mat-icon >trending_up</mat-icon>
          </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Cargo de producto</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="desajustar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Descargo de producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="ajustes_almacen_MP(element,'descargo')">
                        <button matTooltip="DESCARGAR PRODUCTO" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 8, 114);">
          <mat-icon >trending_down</mat-icon>
          </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Descargo de producto</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;" (click)='detallepedido(element)'>
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                    <mat-icon>print</mat-icon>
                                  </button>
                            </div>
                        </section>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Imprimir</strong></h5>
                    </td>
                </ng-container>
                <ng-container matColumnDef="descargar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;" (click)='detallepedido(element)'>
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                    <mat-icon>get_app</mat-icon>
                                  </button>
                            </div>
                        </section>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Descargar</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Actualizar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Editar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center" (click)='editardatos(element, "actualizar")' style="padding: 5px;">
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" style="margin: 1%;" aria-label="Example icon button with a delete icon">
                                    <mat-icon>sync</mat-icon>
                                  </button>
                            </div>
                        </section>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Editar</strong></h5>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
        </div>
    </div>
</div> -->

<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>ALMACEN  MATERIA PRIMA</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarInventarioMP("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='descargar_inv("mp")'><mat-icon >list</mat-icon></button></li>
                </ul>
                <div class="">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.7%; ">
        <div class="col-md-12 px-0  border h-100" style="background-color: steelblue; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
                
                <ng-container matColumnDef="cod_inv">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod Inv </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_inv}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="des_inv">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Inventario </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_inv}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="tipo_producto_mp">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Tipo de Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.tipo_producto_mp}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod producto </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_producto}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Producto </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_producto}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="lote_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Lote </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.lote_producto}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cantidad </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_producto}} </td>
                </ng-container>

                <ng-container matColumnDef="estatus_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus_producto}} </td>
                </ng-container>

                <ng-container matColumnDef="unidad_medida_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Unidad de medida</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.unidad_medida_producto}} </td>
                </ng-container>

                <ng-container matColumnDef="ajustar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cargo de producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button *ngIf="special" matTooltip="CARGAR PRODUCTO" mat-raised-button (click)="ajustes_almacen_MP(element,'cargo')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(28, 27, 82);">
                            <mat-icon >trending_up</mat-icon>
                        </button>
                        <button *ngIf="!special" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >trending_up</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="desajustar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Descargo de producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button *ngIf="special" matTooltip="DESCARGAR PRODUCTO" mat-raised-button (click)="ajustes_almacen_MP(element,'descargo')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 8, 114);">
                            <mat-icon >trending_down</mat-icon>
                        </button>
                        <button *ngIf="!special" matTooltip="SIN PERMISOS" (click)='sin_permiso()'  mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >trending_down</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
               
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    </div>