<div class="fadeIn2 animated fast">
    <nav class="navbar navbar-expand-lg " style="height: 7%; border:1px solid #cccccccc ; background-color: rgba(156, 152, 152, 0.26);">
        <span class="navbar-brand" style=" font-size: 14px; color: black; border-bottom: 1px solid #000;"><b>PRODUCTOS DEVUELTOS</b></span>
        <div class=" navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarCuaentenaProdDevoluciones("actualizar")'><mat-icon >sync</mat-icon></button></li>
                <li class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                <li class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                <li class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
            </ul>
            <div class=" ">
                <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
            </div>
        </div>
    </nav>
    <div class="container-fluid">
        <div class="">
            <div class="col-md-12 col-sm-12 col-xs-12 ">
                <br>
                <div class="example-container mat-elevation-z8 table-responsive">

                    <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
                        <ng-container matColumnDef="cod_dev">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Cod devolucion</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_dev}} </td>
                        </ng-container>
                        <ng-container matColumnDef="cod_factura">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Cod factura</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_factura}} </td>
                        </ng-container>
                        <ng-container matColumnDef="cod_nota_credito">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Cod nota de credito</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_nota_credito}} </td>
                        </ng-container>
                        <ng-container matColumnDef="estatus">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Estatus</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fecha_inicio">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Fecha ini</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha_inicio}} </td>
                        </ng-container>

                        <ng-container matColumnDef="hora_inicio">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Hora ini</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_inicio}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fecha_fin">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Fecha fin</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha_fin}} </td>
                        </ng-container>

                        <ng-container matColumnDef="hora_fin">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Hora fin</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_fin}} </td>
                        </ng-container>

                        <ng-container matColumnDef="liberar">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 1px; color: black;">
                                <h5><strong>Liberar productos</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="traslados(element)">
                                <button matTooltip="LIBERAR PRODUCTOS" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(122, 11, 48);">
                          <mat-icon >done_all</mat-icon>
                          </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 7, 9]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>

    </div>

</div>