import { Routes, RouterModule, Router } from '@angular/router';

import { InicioComponent } from './componentes/shared/inicio/inicio.component';

import { EstadisticasComponent } from './componentes/publicidad/mercadeo/estadisticas/estadisticas.component';
import { PublicidadComponent } from './componentes/publicidad/mercadeo/publicidad/publicidad.component';
import { MensajeriaComponent } from './componentes/publicidad/mercadeo/publicidad/mensajeria/mensajeria.component';
import { ListarregistrosComponent } from './componentes/publicidad/mercadeo/publicidad/listarregistros/listarregistros.component';

import { ModificarregistrosComponent } from './componentes/publicidad/mercadeo/publicidad/modificarregistros/modificarregistros.component';
import { PubliacercadeComponent } from './componentes/publicidad/mercadeo/publicidad/publiacercade/publiacercade.component';

import { UsuariosComponent } from './componentes/sistemas/usuarios/usuarios.component';
import { GestionarFacturasComponent } from './componentes/administracion/cobranzas/facturas-pendientes/gestionar-facturas/gestionar-facturas.component';
import { FacturasPendientesComponent } from './componentes/administracion/cobranzas/facturas-pendientes/facturas-pendientes.component';
import { GestionClientesComponent } from './componentes/sistemas/configurador/gestion-clientes/gestion-clientes.component';
import { ClientesPendientesComponent } from './componentes/administracion/cobranzas/clientes-pendientes/clientes-pendientes.component';
import { GestionCobrosComponent } from './componentes/administracion/cobranzas/gestion-cobros/gestion-cobros.component';
import { ProcesarCobrosComponent } from './componentes/administracion/cobranzas/gestion-cobros/procesar-cobros/procesar-cobros.component';

import { ConfigComponent } from './componentes/sistemas/config/config.component';
import { UnidadesComponent } from './componentes/sistemas/config/unidades/unidades.component';

import { PerfilesComponent } from './componentes/sistemas/usuarios/app.perfiles/app.perfiles.component';
import { MenuUsuComponent } from './componentes/sistemas/config/menu-usu/menu-usu.component';
import { AcercadeComponent } from './componentes/sistemas/usuarios/acercade/acercade.component';
import { RolesComponent } from './componentes/sistemas/usuarios/roles/roles.component';
import { AuthGuardService } from '../app/componentes/shared/servicios/auth-guard.service';
import { AppComponent } from '../app/app.component';
import { InventariosysComponent } from './componentes/sistemas/inventariosys/inventariosys.component';
import { ComputadorasComponent } from './componentes/sistemas/inventariosys/computadoras/computadoras.component';
import { PerifericosComponent } from './componentes/sistemas/inventariosys/perifericos/perifericos.component';
import { FacturacionComponent } from './componentes/administracion/ventas/facturacion/facturacion.component';
import { ConsultarFactComponent } from './componentes/administracion/ventas/facturacion/consultar-fact/consultar-fact.component';
import { FacturasComponent } from './componentes/administracion/ventas/facturacion/facturas/facturas.component';
import { PedidosComponent } from './componentes/administracion/ventas/pedidos/pedidos.component';
import { GestionPedidosComponent } from './componentes/administracion/ventas/pedidos/gestion-pedidos/gestion-pedidos.component';
import { InventarioAComponent } from './componentes/logistica/almacen/inventario/inventario-a.component';
import { ConsultarPedidosComponent } from './componentes/administracion/ventas/pedidos/consultar-pedidos/consultar-pedidos.component';
import { ConfiguradorComponent } from './componentes/sistemas/configurador/configurador.component';
import { PreciosComponent } from './componentes/sistemas/configurador/precios/precios.component';
import { ConsignacionesComponent } from './componentes/logistica/almacen/consignaciones/consignaciones.component';
import { GestionConsignacionesComponent } from './componentes/logistica/almacen/consignaciones/gestion-consignaciones/gestion-consignaciones.component';
import { ProductoFactoryComponent } from './componentes/sistemas/configurador/producto-factory/producto-factory.component';
import { OrdenesProduccionComponent } from './componentes/operaciones/produccion/ordenes-produccion/ordenes-produccion.component';
import { GestionOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/gestion-ordenes/gestion-ordenes.component';
import { MpProductoFactoryComponent } from './componentes/sistemas/configurador/mp-producto-factory/mp-producto-factory.component';
import { GestionProveedoresComponent } from './componentes/sistemas/configurador/gestion-proveedores/gestion-proveedores.component';
import { InventarioMpComponent } from './componentes/logistica/almacen/inventario/inventario-mp/inventario-mp.component';
import { InventarioPdComponent } from './componentes/logistica/almacen/inventario/inventario-pd/inventario-pd.component';
import { InventarioPtComponent } from './componentes/logistica/almacen/inventario/inventario-pt/inventario-pt.component';
import { MpProductoPreparadoComponent } from './componentes/sistemas/configurador/mp-producto-preparado/mp-producto-preparado.component';
import { GestionOrdenesElaboracionComponent } from './componentes/operaciones/produccion/ordenes-produccion/gestion-ordenes-elaboracion/gestion-ordenes-elaboracion.component';
import { SeguimientoOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/seguimiento-ordenes/seguimiento-ordenes.component';
import { GestionOrdenesMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/gestion-ordenes-mpp/gestion-ordenes-mpp.component';
import { OrdenesProduccionMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/ordenes-produccion-mpp.component';
import { GestionOrdenesElaboracionMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/gestion-ordenes-elaboracion-mpp/gestion-ordenes-elaboracion-mpp.component';
import { SeguimientoOrdenesMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/seguimiento-ordenes-mpp/seguimiento-ordenes-mpp.component';
import { AuditoriaOrdProdComponent } from './componentes/operaciones/produccion/auditoria-ord-prod/auditoria-ord-prod.component';
import { OrdenesProdRevisionComponent } from './componentes/operaciones/produccion/auditoria-ord-prod/ordenes-prod-revision/ordenes-prod-revision.component';
import { InventarioLtComponent } from './componentes/logistica/almacen/inventario/inventario-lt/inventario-lt.component';
import { DespachoComponent } from './componentes/logistica/almacen/despacho/despacho.component';
import { GuiaDespachoComponent } from './componentes/logistica/almacen/despacho/guia-despacho/guia-despacho.component';
import { CrearOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/crear-ordenes/crear-ordenes.component';
import { ConsultarOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/consultar-ordenes/consultar-ordenes.component';
import { EditarOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/editar-ordenes/editar-ordenes.component';
import { ConfirmarOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/confirmar-ordenes/confirmar-ordenes.component';
import { ConsultarOrdenesMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/consultar-ordenes-mpp/consultar-ordenes-mpp.component';
import { InventarioSgComponent } from './componentes/logistica/almacen/inventario/inventario-sg/inventario-sg.component';
import { InventarioSplComponent } from './componentes/logistica/almacen/inventario/inventario-spl/inventario-spl.component';
import { OrdenesProdMppRevisionComponent } from "./componentes/operaciones/produccion/auditoria-ord-prod/ordenes-prod-mpp-revision/ordenes-prod-mpp-revision.component";
import { ConsultarConsignacionesComponent } from './componentes/logistica/almacen/consignaciones/consultar-consignaciones/consultar-consignaciones.component';
import { ConsultarGuiaDespachoComponent } from './componentes/logistica/almacen/despacho/consultar-guia-despacho/consultar-guia-despacho.component';
import { EntradaInventarioComponent } from './componentes/logistica/almacen/entrada-inventario/entrada-inventario.component';
import { MateriaPrimaComponent } from './componentes/logistica/almacen/entrada-inventario/materia-prima/materia-prima.component';
import { CuarentenaComponent } from './componentes/calidad/aseguramiento/cuarentena/cuarentena.component';
import { MateriaPrimaEntradaComponent } from './componentes/calidad/aseguramiento/cuarentena/materia-prima-entrada/materia-prima-entrada.component';
import { OrdenesComprasComponent } from './componentes/administracion/compras/ordenes-compras/ordenes-compras/ordenes-compras.component';
import { OrdenesfrontComprasComponent } from './componentes/administracion/compras/ordenes-compras/ordenes-front-compras.component';
import { GestionComprasComponent } from './componentes/administracion/compras/ordenes-compras/gestion-compras/gestion-compras.component';
import { CalidadProductoTerminadoComponent } from './componentes/calidad/aseguramiento/cuarentena/calidad-producto-terminado/calidad-producto-terminado.component';
import { ControlExcepcionesComponent } from './componentes/sistemas/config/control-excepciones/control-excepciones.component';
import { ConsultarNotasComponent } from './componentes/administracion/ventas/facturacion/consultar-notas/consultar-notas.component';
import { ConsultarNotasEntregaComponent } from './componentes/administracion/ventas/facturacion/consultar-notas-entrega/consultar-notas-entrega.component';
import { DevolucionesProductoComponent } from './componentes/calidad/aseguramiento/cuarentena/devoluciones-producto/devoluciones-producto.component';
import { ConsultarNotasDebitoComponent } from './componentes/administracion/ventas/facturacion/consultar-notas-debito/consultar-notas-debito.component';
import { IntranetComponent } from './componentes/sistemas/intranet/intranet.component';
import { SoporteItComponent } from './componentes/sistemas/intranet/soporte-it/soporte-it.component';
import { SoporteConfigComponent } from './componentes/sistemas/config/soporte-config/soporte-config.component';
import { SistemasComponent } from './componentes/sistemas/sistemas/sistemas.component';
import { SoporteTecnicoAdminComponent } from './componentes/sistemas/sistemas/soporte-tecnico-admin/soporte-tecnico-admin.component';
import { SesionesUsuariosComponent } from './componentes/sistemas/sistemas/sesiones-usuarios/sesiones-usuarios.component';
import { EstatusOrdenesProdComponent } from './componentes/sistemas/sistemas/estatus-ordenes-prod/estatus-ordenes-prod.component';
import { DefinicionesSistemaComponent } from './componentes/sistemas/config/definiciones-sistema/definiciones-sistema.component';
import { DepositosComponent } from './componentes/logistica/almacen/inventario/depositos/depositos.component';
import { EmpleadosComponent } from './componentes/rrhh/empleados/empleados.component';
import { ComedorComponent } from './componentes/rrhh/empleados/comedor/comedor.component';
import { AsistenciasComponent } from './componentes/rrhh/empleados/asistencias/asistencias.component';
import { EmpleadoComponent } from './componentes/rrhh/empleados/empleado/empleado.component';
import { ReportGenAsistenciaComponent } from './componentes/rrhh/empleados/report-gen-asistencia/report-gen-asistencia.component';
import { ReportMesEmpleadoComponent } from './componentes/rrhh/empleados/report-mes-empleado/report-mes-empleado.component';
import { DevolucionesComponent } from './componentes/logistica/almacen/devoluciones/devoluciones.component';
import { DevolucionProductosComponent } from './componentes/logistica/almacen/devoluciones/devolucion-productos/devolucion-productos.component';
import { ProductosDevueltosComponent } from './componentes/logistica/almacen/devoluciones/productos-devueltos/productos-devueltos.component';
import { DevolucionCompletaComponent } from './componentes/logistica/almacen/devoluciones/devolucion-completa/devolucion-completa.component';
import { ReportesAppComponent } from './componentes/sistemas/reportes/reportes-app/reportes-app.component';
import { ConsultarReportesComponent } from './componentes/sistemas/reportes/consultar-reportes/consultar-reportes.component';
import { ReportesComponent } from './componentes/sistemas/reportes/reportes.component';
import { OrdenRequisicionComponent } from './componentes/administracion/compras/ordenes-compras/orden-requisicion/orden-requisicion.component';
import { CotizacionComponent } from './componentes/administracion/compras/ordenes-compras/cotizacion/cotizacion.component';
import { GestionCentroCostoComponent } from './componentes/sistemas/configurador/gestion-centro-costo/gestion-centro-costo.component';
import { RecepcionComponent } from './componentes/logistica/almacen/recepcion/recepcion.component';
import { GestionarRecepcionesComponent } from './componentes/logistica/almacen/recepcion/gestionar-recepciones/gestionar-recepciones.component';
import { TomaFisicaInevntarioComponent } from './componentes/auditoria/inventario/toma-fisica-inevntario/toma-fisica-inevntario.component';
import { CrearTomaFisicaComponent } from './componentes/auditoria/inventario/toma-fisica-inevntario/crear-toma-fisica/crear-toma-fisica.component';
import { RecepcionesRecibidasComponent } from './componentes/logistica/almacen/recepcion/recepciones-recibidas/recepciones-recibidas.component';
import { PreFormaComponent } from './componentes/administracion/ventas/pre-forma/pre-forma.component';
import { GestionPreformaComponent } from './componentes/administracion/ventas/pre-forma/gestion-preforma/gestion-preforma.component';
import { ConsultarPreformaComponent } from './componentes/administracion/ventas/pre-forma/consultar-preforma/consultar-preforma.component';
import { FciComponent } from './componentes/fci/fci.component';
import { PedidosFciComponent } from './componentes/fci/pedidos-fci/pedidos-fci.component';
import { CalidadRecepcionComponent } from './componentes/calidad/aseguramiento/cuarentena/calidad-recepcion/calidad-recepcion.component';
import { PreOrdenesComprasComponent } from './componentes/administracion/compras/ordenes-compras/pre-ordenes-compras/pre-ordenes-compras.component';
import { RolUsuariosComponent } from './componentes/sistemas/config/rol-usuarios/rol-usuarios.component';
import { LicenciamientoControlComponent } from './componentes/sistemas/config/licenciamiento-control/licenciamiento-control.component';
import { GestionarDocumentosComponent } from './componentes/sistemas/configurador/gestionar-documentos/gestionar-documentos.component';
import { GestionEstructuraEmpresaComponent } from './componentes/sistemas/configurador/gestion-estructura-empresa/gestion-estructura-empresa.component';
import { PosComponent } from './componentes/administracion/ventas/pos/pos.component';
import { GestionPosComponent } from './componentes/administracion/ventas/pos/gestion-pos/gestion-pos.component';
import { GestionarAppComponent } from './componentes/sistemas/configurador/gestionar-app/gestionar-app.component';
import { SmtpUsuComponent } from './componentes/sistemas/config/smtp-usu/smtp-usu.component';
import { nkServices } from './componentes/shared/servicios/app.nkservicios.services';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { AtributosService } from './componentes/shared/servicios/atributos.service';
import { OnInit } from '@angular/core';

const RUTAS: Routes = [
{ path: 'facturas_pendientes', component: FacturasPendientesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'facturas por cobrar'}, children:[
    { path: 'gestion_facturas', component: GestionarFacturasComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar facturas'}, outlet: 'facturas_pendientes'}] },
{ path: 'gestion_cobros', component: GestionCobrosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar cobros'} , children:[
    { path: 'procesar_cobros', component: ProcesarCobrosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'procesar cobros'}, outlet: 'gestion_cobros'}]},

////////VENTAS/////////////

{ path: 'facturacion', component: FacturacionComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'facturacion'}, children:[
    { path: 'facturar_pedidos', component: FacturasComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'facturar pedidos'}, outlet: 'facturacion'},
    { path: 'consultarfact', component: ConsultarFactComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar facturas'}, outlet: 'facturacion'},
    { path: 'consultar_notas_credito', component: ConsultarNotasComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar notas de credito'}, outlet: 'facturacion'},
    { path: 'consultar_notas_debito', component: ConsultarNotasDebitoComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar notas de debito'}, outlet: 'facturacion'},
    { path: 'consultar_notas_entrega', component: ConsultarNotasEntregaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar notas de entrega'}, outlet: 'facturacion'}] },
{ path: 'pedidos', component: PedidosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'pedidos'}, children:[
    { path: 'gestion_pedidos', component: GestionPedidosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar pedidos'}, outlet: 'pedidos'},
    { path: 'consultar_pedidos', component: ConsultarPedidosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar pedidos'}, outlet: 'pedidos'},] },
{ path: 'exportaciones_ventas', component: FciComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'exportaciones'}, children:[
    { path: 'pedidos_fci', component: PedidosFciComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar pedidos fci'}, outlet: 'exportaciones'},] },
{ path: 'pre_forma', component: PreFormaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'pro-formas'}, children:[
    { path: 'gestionar_preformas', component: GestionPreformaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar pro-formas'}, outlet: 'pre_forma'},
    { path: 'consultar_proforma', component: ConsultarPreformaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar pro-formas'}, outlet: 'pre_forma'}] },

////////POS/////////////

{ path: 'pos_ventas', component: PosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'pos ventas'}, children:[
    { path: 'gestionar_pos', component: GestionPosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar pos'}, outlet: 'pos_ventas'}] },

////////COMPRAS/////////////

    { path:'ordenes_comp', component: OrdenesfrontComprasComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'ordenes de compras'}, children:[
        {path:'orden-requisicion', component: OrdenRequisicionComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'requisiciones'}, outlet:'ordenes_comp'},
        {path:'cotizacion_compras', component: CotizacionComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'cotizaciones'}, outlet:'ordenes_comp'},
        {path:'pre-orden-compra', component: PreOrdenesComprasComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'gestionar pre-compras'}, outlet:'ordenes_comp'},
        {path:'orden-compra', component: OrdenesComprasComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'gestionar compras'}, outlet:'ordenes_comp'},
    ]},

//////////////////////////////////////////////////////GESTION HUMANA///////////////////////////////////////////////////////////////////////////////////////////////////////////
{ path: 'empleados', component: EmpleadosComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'empleados'}, children:[
    {path:'comedor', component: ComedorComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'comedor'}, outlet:'empleados'},
    {path:'asistencia', component:  AsistenciasComponent, canActivate : [AuthGuardService], data: {permiso: '1', menu:'asistencia'}, outlet:'empleados'},
    {path:'gestion_empleados', component: EmpleadoComponent, canActivate : [AuthGuardService], data: {permiso: '1', menu:'nomina'}, outlet:'empleados'},
    {path:'reporte_mes_x_empleados', component: ReportMesEmpleadoComponent, canActivate : [AuthGuardService], data: {permiso: '1', menu:'consultar empleados'}, outlet:'empleados'},
    {path:'reporte_general_asistencia', component: ReportGenAsistenciaComponent, canActivate : [AuthGuardService], data: {permiso: '1', menu:'consultar asistencia'}, outlet:'empleados'}
]},
//////////////////////////////////////////////////////GESTION HUMANA///////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////RUTAS COMPONENTES lOGISTICA////////////////////////////////////////////////////////////////////////////////
////////ALMACEN/////////////
{ path: 'almacenes', component: InventarioAComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'almacenes'}, children:[
    { path: 'l001_al', component: InventarioPtComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'l001'}, outlet: 'almacenes'},
    { path: 'dep01_al', component: InventarioMpComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'dep01'}, outlet: 'almacenes'},
    { path: 'lotes_al', component: InventarioLtComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'lotes'}, outlet: 'almacenes'},
    { path: 'inventario_sg', component: InventarioSgComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'inventario sg'}, outlet: 'almacenes'},
    { path: 'c100_ppv', component: InventarioSplComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'c100-ppv'}, outlet: 'almacenes'},
    { path: 'depositos', component: DepositosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'inventarios'}, outlet: 'almacenes'},
    { path: 'l002_al', component: InventarioPdComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'l002'}, outlet: 'almacenes'}] },

{ path: 'consignaciones', component: ConsignacionesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consignaciones'}, children:[
    { path: 'gestion_consignacion', component: GestionConsignacionesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar consignaciones'}, outlet: 'consignaciones'},
    { path: 'consultar_consignacion', component: ConsultarConsignacionesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar consignaciones'}, outlet: 'consignaciones'}] },

{ path: 'entrada_inventario', component: EntradaInventarioComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'entrada de inventarios'}, children:[
    { path: 'materia_prima', component: MateriaPrimaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'materia prima'}, outlet: 'entrada_inventario'}] },

{ path: 'despacho', component: DespachoComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'despacho'}, children:[
    { path: 'guia_despacho', component: GuiaDespachoComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'guia despacho'}, outlet: 'despacho'},
    { path: 'consultar_guia_despacho', component: ConsultarGuiaDespachoComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar guia despacho'}, outlet: 'despacho'}] },

{ path: 'devoluciones', component: DevolucionesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'devoluciones'}, children:[
    { path: 'devolucion-productos', component: DevolucionProductosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar devolucion'}, outlet: 'devoluciones'},
    { path: 'devolucion-completa', component: DevolucionCompletaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'devolucion unidades'}, outlet: 'devoluciones'}] },

{ path: 'recepcion', component: RecepcionComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'recepcion'}, children:[
        { path: 'gestionar_recepcion', component: GestionarRecepcionesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar recepciones'}, outlet: 'recepcion'},
        { path: 'recepciones-recibidas', component: RecepcionesRecibidasComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'recepciones recibidas'}, outlet: 'recepcion'}] },
////////////////////////////////////////////////RUTAS COMPONENTES OPERACIONES////////////////////////////////////////////////////////////////////////////////
////////PRODUCCION/////////////

{ path: 'auditoria_ord_prod', component: AuditoriaOrdProdComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'auditoria ordenes de produccion'}, children:[
  { path: 'ordenes_revision_mpp', component: OrdenesProdMppRevisionComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'revisar ordenes mpp'}, outlet: 'auditoria_ord_prod'},
  { path: 'ordenes_revision', component: OrdenesProdRevisionComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'revisar ordenes'}, outlet: 'auditoria_ord_prod'}] },

{ path: 'ordenes_pro', component: OrdenesProduccionComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'ordenes de produccion'}, children:[
    { path: 'gestionar_ordenes', component: GestionOrdenesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar ordenes'}, outlet: 'ordenes_pro'},
    { path: 'crear_ordenes', component: CrearOrdenesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'crear ordenes'}, outlet: 'ordenes_pro'},
    { path: 'consultar_ordenes', component: ConsultarOrdenesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar ordenes'}, outlet: 'ordenes_pro'},
    { path: 'editar_ordenes', component: EditarOrdenesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'editar ordenes'}, outlet: 'ordenes_pro'},
    { path: 'confirmar_ordenes', component: ConfirmarOrdenesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'confirmar ordenes'}, outlet: 'ordenes_pro'},
    { path: 'ordenes_laboratorio', component: GestionOrdenesElaboracionComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'laboratorio'}, outlet: 'ordenes_pro'},
    { path: 'seguimiento_ordenes', component: SeguimientoOrdenesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'auditar ordenes'}, outlet: 'ordenes_pro'}] },

    { path: 'ordenes_pro_mpp', component: OrdenesProduccionMppComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'ordenes de produccion mpp'}, children:[
        { path: 'gestionar_ordenes_mpp', component: GestionOrdenesMppComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar ordenes mpp'}, outlet: 'ordenes_pro_mpp'},
        { path: 'consultar_ordenes_mpp', component: ConsultarOrdenesMppComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'consultar ordenes mpp'}, outlet: 'ordenes_pro_mpp'},
        { path: 'ordenes_lab_mpp', component: GestionOrdenesElaboracionMppComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'laboratorio mpp'}, outlet: 'ordenes_pro_mpp'},
        { path: 'seguimiento_ordenes_mpp', component: SeguimientoOrdenesMppComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'auditar ordenes mpp'}, outlet: 'ordenes_pro_mpp'}] },

        ////////////////////////////////////////////////RUTAS COMPONENTES CALIDAD////////////////////////////////////////////////////////////////////////////////
////////ASEGURAMIENTO DE LA CALIDAD/////////////

{ path: 'cuarentena_calidad', component: CuarentenaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'cuarentena'}, children:[
  { path: 'materia_prima_entrada', component: MateriaPrimaEntradaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'entrada mat prima'}, outlet: 'cuarentena_calidad'},
  { path: 'calidad_producto_terminado', component: CalidadProductoTerminadoComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'calidad prod terminado'}, outlet: 'cuarentena_calidad'},
  { path: 'productos-devueltos', component: ProductosDevueltosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'reacondicionamiento'}, outlet: 'cuarentena_calidad'},
  { path: 'devoluciones_producto', component: DevolucionesProductoComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'devoluciones'}, outlet: 'cuarentena_calidad'},
  { path: 'calidad-recepcion', component: CalidadRecepcionComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'calidad recepcion'}, outlet:'cuarentena_calidad'},] },
//////////////////////////////////////////////////////RUTAS COMPONENTES MERCADEO///////////////////////////////////////////////////////////////////////////////////////////////////////////

        { path: 'estadisticas', component: EstadisticasComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'estadisticas'} },
        { path: 'publicidad', component: PublicidadComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'publicidad masiva'} , children:[
            { path: 'mensajeria', component: MensajeriaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'mensajeria'}, outlet: 'mercadeo'},
            { path: 'listarregistros', component: ListarregistrosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'correos'}, outlet: 'mercadeo'},
            { path: 'modificarregistros', component: ModificarregistrosComponent,canActivate : [AuthGuardService], data: {unidad: 'mercadeo'}, outlet: 'mercadeo'},
            { path: 'acercade', component: PubliacercadeComponent,canActivate : [AuthGuardService], data: {unidad: 'mercadeo'}, outlet: 'mercadeo'},
           ]},

////////////////////////////////////////////////RUTAS COMPONENTES UNIDAD SISTEMAS////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////AUDITORIA////////////////////////////////////////////////////////////////////////////////
{ path: 'toma-fisica', component: TomaFisicaInevntarioComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'toma fisica'}, children:[
    {path:'crear-toma-fisica', component: CrearTomaFisicaComponent ,canActivate : [AuthGuardService], data: {permiso: '1', menu:'auditoria inventarios'}, outlet:'toma-fisica'},
]}, 

////////////////////////////////////////////////AUDITORIA////////////////////////////////////////////////////////////////////////////////

        { path: 'config', component: ConfigComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'configuraciones'}, children:[
            {path:'menu', component: MenuUsuComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'menu'}, outlet:'config'},
            {path:'unidades', component: UnidadesComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'unidades'}, outlet:'config'},
            {path:'accesos', component: PerfilesComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'accesos'}, outlet:'config'},
            {path:'permisos', component: RolesComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'permisos'}, outlet:'config'},
            {path:'control_excepciones', component: ControlExcepcionesComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'logs'}, outlet:'config'},
            {path:'soporte_config', component: SoporteConfigComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'tareas de soporte'}, outlet:'config'},
            {path:'definiciones_sistema', component: DefinicionesSistemaComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'definiciones del sistema'}, outlet:'config'},
            {path:'rol_menu', component: RolUsuariosComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'roles'}, outlet:'config'},
            {path:'licencia_parametros', component: LicenciamientoControlComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'licenciamiento'}, outlet:'config'},
            {path: 'gestionar_estrutura_empresa', component: GestionEstructuraEmpresaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar organizacion'}, outlet: 'config'},
            {path: 'aplicaciones', component: GestionarAppComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'aplicaciones'}, outlet: 'config'},
            {path: 'gestionar_smtp', component: SmtpUsuComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar smtp'}, outlet: 'config'},
        ]},

        { path: 'equipos', component: InventariosysComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'equipos'}, children:[
            {path:'computadoras', component: ComputadorasComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'computadoras'}, outlet:'equipos'},
            {path:'perifericos', component: PerifericosComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'perifericos'}, outlet:'equipos'},
        ]},

        { path: 'configurador', component: ConfiguradorComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'configurador'}, children:[
            {path:'precios', component: PreciosComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'lista de precios'}, outlet:'configurador'},
            {path: 'gestion_clientes', component: GestionClientesComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar clientes'}, outlet: 'configurador'},
            {path: 'producto_factory', component: ProductoFactoryComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar productos'}, outlet: 'configurador'},
            {path: 'mp_producto_factory', component: MpProductoFactoryComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'mp producto'}, outlet: 'configurador'},
            {path: 'mp_producto_preparado', component: MpProductoPreparadoComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'producto preparado'}, outlet: 'configurador'},
            {path: 'gestion_prov', component: GestionProveedoresComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar proveedores'}, outlet: 'configurador'},
            {path: 'gestion-centro-costo', component: GestionCentroCostoComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'centro de costos'}, outlet: 'configurador'},
            {path: 'gestionar_documentos', component: GestionarDocumentosComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar documentos'}, outlet: 'configurador'},
            {path: 'gestionar_estrutura_empresa', component: GestionEstructuraEmpresaComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar organizacion'}, outlet: 'configurador'},
            {path: 'gestionar_app', component: GestionarAppComponent,canActivate : [AuthGuardService], data: {permiso: '1',menu:'gestionar app'}, outlet: 'configurador'},
        ]},

        { path:'soporte_it', component: IntranetComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'soporte'}, children:[
            {path:'solicitar_soporte', component: SoporteItComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'solicitar soporte'}, outlet:'soporte_it'},
        ]},
        
        { path:'reportes_app', component: ReportesComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'reportes'}, children:[
            {path:'reportes_app_con', component: ConsultarReportesComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'consultar reportes'}, outlet:'reportes_app'},
        ]},

        { path:'soporte_tecnico_admin', component: SistemasComponent ,canActivate : [AuthGuardService], data: {permiso: '1',menu:'soporte tecnico'}, children:[
            {path:'admin_soporte', component: SoporteTecnicoAdminComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'gestionar soporte'}, outlet:'soporte_tecnico_admin'},
            {path:'sesiones_usuarios', component: SesionesUsuariosComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'gestionar sesiones'}, outlet:'soporte_tecnico_admin'},
            {path:'estatus_ordenes', component: EstatusOrdenesProdComponent,canActivate : [AuthGuardService], data: {permiso: '1', menu:'estatus ordenes'}, outlet:'soporte_tecnico_admin'},
        ]},
 
////////////////////////////////////////////////RUTAS COMPONENTES UNIDAD INICIALES////////////////////////////////////////////////////////////////////////////////

    { path: 'inicio', component: InicioComponent },
    { path: 'auth', component: AppComponent },
    { path: '**', pathMatch:'full', redirectTo:'auth'},

    //{ path: 'path/:routeParam', component: MyComponent },
    //{ path: 'staticPath', component: ... },
    //{ path: '**', component: ... },
    //{ path: 'oldPath', redirectTo: '/staticPath' },
    //{ path: ..., component: ..., data: { message: 'Custom' }
];


export const APP_ROUTING = RouterModule.forRoot(RUTAS,{ useHash: true, relativeLinkResolution: 'legacy' });
