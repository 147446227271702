import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { FormNotaCreditoComponent } from 'src/app/componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormNotaCreditoAdminComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import { FormNotaDebitoComponent } from 'src/app/componentes/shared/modal/form-nota-debito/form-nota-debito.component';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormReportesNotasDebitoComponent } from 'src/app/componentes/shared/modal/form-reportes-notas-debito/form-reportes-notas-debito.component';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';
import axios from 'axios';

interface interfacefactura{
  lote_producto: string;
  cod_producto:string;
  des_producto:string;
  cant_producto:string;
  costo_producto:string;
  subtotal_producto:string;
}
interface interfacefactura2{
  des_concepto: string;
  subtotal_concepto:string;

}
interface interdatosfacefactura{
cod_factura:string;
des_cliente: string;
cod_cliente:string;
des_vendedor:string;
fecha_e_factura:string;
fecha_v_factura:string;
cod_pedido:string;
telefono1_cliente:string;
telefono2_cliente:string;
}
type TableRow = [string, string, string, string, string, string];

type TableRow2 = [string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-consultar-notas-debito',
  templateUrl: './consultar-notas-debito.component.html',
  styleUrls: ['./consultar-notas-debito.component.css']
})
export class ConsultarNotasDebitoComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  fecha1:any;
  fecha2:any;

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private pdf : PdfServices,) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );

  }
  ngOnInit(): void {
    this.listar_notas_debito(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }
  /////////////////////////////////////////////////////////////////////
  listar_notas_debito(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listarNotasDebitohttp().subscribe(
          response =>{
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.respuesta) {
  
                    this.ELEMENT_DATA.push({
                      cod_factura:response.respuesta[i].cod_factura,
                      cod_nota_debito: response.respuesta[i].cod_nota_debito,
                      cod_lista_precio:response.respuesta[i].cod_lista_precio,
                      cod_cliente:response.respuesta[i].cod_cliente,
                      des_cliente:response.respuesta[i].des_cliente,
                      cod_usuario:response.respuesta[i].cod_usuario,
                      des_usuario:response.respuesta[i].des_usuario,
                      fecha_inicio:response.respuesta[i].fecha_inicio,
                      hora_inicio:response.respuesta[i].hora_inicio,
                      estatus:response.respuesta[i].estatus,
                      subtotal_costo: response.respuesta[i].subtotal_costo,
                      total_costo:response.respuesta[i].total_costo,
                      iva_nota:response.respuesta[i].iva_nota,
                      iva_monto:response.respuesta[i].iva_monto,
                      desc_nota:response.respuesta[i].desc_nota,
                      desc_monto: response.respuesta[i].desc_monto,
                      iva_exento:response.respuesta[i].iva_exento,
                      monto_exento:response.respuesta[i].monto_exento,
                      accion:'',
                      accion2:'',
                      accion3:'',
                      accion4:''
                    });
                  }
                  this.displayedColumns = [
                                          'cod_factura',
                                          'cod_nota_debito',
                                          'cod_cliente',
                                          'des_cliente',
                                          'des_usuario',
                                          'estatus',
                                          'fecha',
                                          'hora',
                                          'imprimir',
                                          'descargar'];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                      'cod_factura',
                                      'cod_nota_debito',
                                      'cod_cliente',
                                      'des_cliente',
                                      'des_usuario',
                                      'estatus',
                                      'fecha',
                                      'hora',
                                      'imprimir',
                                      'descargar'];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  //////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////

  async notasDebito(obj:any,xvalor:any){

    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
      cod_menu: localStorage.getItem('sub_main'),
      cod_sesion: localStorage.getItem('cod_sesion'),
      dir_ip_client: localStorage.getItem('dir_ip'),
    sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            var dialogRef;
            switch (xvalor) {
              case 'dev':
                dialogRef =  this.dialog.open(FormNotaCreditoComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'100%',
                  data: {cod_factura: obj.cod_factura,
                          cod_pedido: obj.cod_pedido,
                          cod_lista_precio:obj.cod_lista_precio,
                          cod_cliente:obj.cod_cliente,
                          des_cliente:obj.des_cliente,
                          iva_pedido:obj.iva_pedido,
                          iva_monto:obj.iva_monto,
                          desc_pedido:obj.desc_pedido,
                          subtotal_costo_pedido:obj.subtotal_costo_pedido,
                          total_costo_pedido:obj.total_costo_pedido,
                          termini:'pedido'},
                          disableClose: true,
                  panelClass: 'pedidos_modal3'
                });
                break;
              case 'admin':
                dialogRef =  this.dialog.open(FormNotaDebitoComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'100%',
                  data: {cod_factura: obj.cod_factura,
                          cod_pedido: obj.cod_pedido,
                          cod_lista_precio:obj.cod_lista_precio,
                          cod_cliente:obj.cod_cliente,
                          des_cliente:obj.des_cliente,
                          iva_pedido:obj.iva_pedido,
                          iva_monto:obj.iva_monto,
                          desc_pedido:obj.desc_pedido,
                          subtotal_costo_pedido:obj.subtotal_costo_pedido,
                          total_costo_pedido:obj.total_costo_pedido,
                          termini:'pedido'},
                          disableClose: true,
                  panelClass: 'pedidos_modal3',
                  backdropClass: "bdrop"
                });
                  break;
              default:
                break;
            }
    
          dialogRef.afterClosed().subscribe(result => {
    
            if(result != 'cerrar' && result != 'salir'){
    
                  console.log(result);
    
              this.gestionNotaDeDebito(result);
              /* let resultado = this.eliminarunidad(result.id); */
            }
          });
            
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  gestionNotaDeDebito(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner'
    });
    setTimeout( async() => {
      this.servicio.gestionNotaDeDebitohttp(xvalor).subscribe(
        response =>{
          this.dialogSpin.close();
          this.cod_nota_credito = response.nota;
          this.listar_notas_debito(null);
          this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        },
        error =>{
          this.dialogSpin.close();
          console.log('una respuesta');
          console.log(<any>error);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );

     /*  setTimeout(() => {
        const pdf = new PdfMakeWrapper();
        var valor = {cod_factura:xvalor.cod_factura, cod_nota_credito:this.cod_nota_credito,tipo_nota:xvalor.tipo_nota}
        this.servicio.listarNotaDebitohttp(valor).subscribe(
         async response  =>{
            console.log(response);
            console.log(xvalor);
                pdf.info({
                  title: 'Nota de debito N°'+response.pdf2[0].cod_nota_credito+'',
                  author: 'Factory cosmetics C.A.',
                  subject: 'Facturas',
              });

              pdf.pageMargins([20,115,20,60]);
                pdf.add(pdf.ln(1));
                pdf.add( this.numerofact9NCADMIN(response.pdf2));
                pdf.add(this.crearTabla29NCADMIN(response.pdf2,response.pdf3));
                pdf.pageSize('A4');
                pdf.add(this.headerTablaNCADMIN());
                pdf.add(this.crearTabla9NCADMIN(response.pdf));
                pdf.add(this.crearTabla99NCADMIN(response.pdf));
                pdf.add(this.crearTabla999NCADMIN(response.pdf));
                pdf.add(pdf.ln(1));
                pdf.add(this.crearTabla49NCADMIN(response.pdf2,xvalor,response.pdf5,response.fecha,response.pdf6));
                pdf.create().open();

          },
          error =>{
            console.log(error);
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
       }, 3000); */
    }, 1000);

    return this.xobj2;
  }
  //////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////

  //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////

  async detallepedido(obj:any, text:any){
    console.log(this.ELEMENT_DATA);
    console.log(obj);
    this.accion = text;
      this.dialog.open(FormDireccionesComponent, {
        width: '35%',
        height:'35%',
        data: { monto_pendiente_factura:obj.monto_pendiente_factura,
                hora_emismonto_cobrado_facturaion:obj.monto_cobrado_factura,
                monto_contador_factura:obj.monto_contador_factura,
                termino: this.accion
              },
              panelClass: 'pedidos_modal'
      });
 }

 imprimir_descargar(xvalor:any,text:any): void {
   try {
    this.key_hash = {};
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
        if (response.resul) {
          
          this.accion = text;
          switch (this.accion) {
            case 'imprimir':
              this.accion = 'enviarpedido';
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                      maxWidth: '100vw',
                      height:'100%',
                data: {id:'',
                      accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cerrar' && result != 'salir' ) {
                  dialogRef.close();
                  this.imprimirNotaDebito(xvalor);
                }else{
                  dialogRef.close();
                  
                }
            
              });
              break;
              case 'descargar':
              this.accion = 'enviarpedido';
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                maxWidth: '100vw',
                height:'100%',
                data: {id:'',
                      accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cerrar' && result != 'salir' ) {
                  dialogRef.close();
                  this.descargarnota(xvalor);
                }else{
                  dialogRef.close();
                  
                }
            
              });
              break;
          
            default:
              break;
          }
        }else{
          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 

   } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
   }
}

descargar_reporte_ventas(text:any){
  try {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef:any;
  this.accion = text;
  let datos = localStorage.getItem('cod_usuario');
  let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    dialogRef = this.dialog.open(FormReportesNotasDebitoComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {
              accion:this.accion},
      panelClass: 'pedidos_modal_rep',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);
        switch (result) {
          case 'xlsx':
            /* this.reportes_ventas_xlsx(); */
            break;
          case 'pdf':

            break;
          case 'txt':

            break;
          case 'csv':

            break;

          default:
            this.reportes_rango_fecha_ventas_xlsx(result);
            break;
        }
      }
    });             
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
      
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
reportes_rango_fecha_ventas_xlsx(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.fecha1 = xvalor.fecha_inicio;
      this.fecha2 = xvalor.fecha_fin;
      this.servicio.reporteNotasDebitohttp(xvalor).subscribe(
        response=>{
          this.dialogSpin.close();
          console.log(response.xobjeto_unico);
          this.descarga_prod_ventas_por_fecha_xlsx(response.xobjeto_unico);
        },
        error=>{
          this.dialogSpin.close();
          console.log(error.error.xobjeto_unico);
          console.log(error.error.message);
          this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
      );
    }, 3000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

async descarga_prod_ventas_por_fecha_xlsx(xdatos:any){
try {
  console.log(xdatos,'llego a la descarga');
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet("Reporte de Notas de Debito",{views: [{showGridLines: false}]});

  const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
  const imageId2 = workbook.addImage({
    buffer: imageBuffer.data,
    extension: 'png',
  });
  worksheet.addImage(imageId2,'B2:B7')
    
  worksheet.mergeCells('B1:S1')
  worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
  worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
  worksheet.getCell('D1').font={bold: true};

  worksheet.mergeCells('B2:S2')
  worksheet.getCell('D2').value='Rif J-41031797-3';
  worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
  worksheet.getCell('D2').font={bold: true};
  
  worksheet.mergeCells('B3:S3')
  worksheet.getCell('D3').value='Reporte de Notas de Debito';
  worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
  worksheet.getCell('D3').font={bold: true};

  worksheet.mergeCells('B4:S4')
  worksheet.getCell('D4').value='A la fecha "'+this.fecha1+'"-"'+this.fecha1+'" ';
  worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
  worksheet.getCell('D4').font={bold: true};

  worksheet.mergeCells('B5:S5')
  worksheet.getCell('D5').value='Expresado en Bolivares';
  worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
  worksheet.getCell('D5').font={bold: true};

  worksheet.mergeCells('B6:S6')

  worksheet.autoFilter = {
    from: 'B8',
    to: 'S8',
  }
  var fondo_gris_claro =['D7','E7','F7','G7','H7']
  fondo_gris_claro.map(celdas => {
    worksheet.getCell(celdas).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFF'},
      
    };
  })
  let header=[" ","Fecha","Hora","Nro. de factura","Nro. nota de debito","Nro. control","RIF","Cliente","Usuario",
              "Concepto","Base imponible","Exento","Monto exento","%Descuento","Descuento","%IVA",
              "IVA","Monto total","Estatus"];
  let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

  var bordes_headers = ['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8','P8',
                        'Q8','R8','S8']
  bordes_headers.map(celdas =>{
    worksheet.getCell(celdas).border = {
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
  })

  var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                            'P8','Q8','R8','S8']
  fondo_gris_claro_plus.map(celdas => {
    worksheet.getCell(celdas).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0050a0'},
      
    };
  })
  fondo_gris_claro_plus.map(centrar => {
    worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
  })

  worksheet.getColumn(1).width = 3.5;
  worksheet.getColumn(2).width = 17;
  worksheet.getColumn(3).width = 17;
  worksheet.getColumn(4).width = 17;
  worksheet.getColumn(5).width = 17;
  worksheet.getColumn(6).width = 17;
  worksheet.getColumn(7).width = 17;
  worksheet.getColumn(8).width = 45;
  worksheet.getColumn(9).width = 20;
  worksheet.getColumn(10).width = 30;
  worksheet.getColumn(11).width = 17;
  worksheet.getColumn(12).width = 17;
  worksheet.getColumn(13).width = 17;
  worksheet.getColumn(14).width = 17;
  worksheet.getColumn(15).width = 17;
  worksheet.getColumn(16).width = 17;
  worksheet.getColumn(17).width = 17;
  worksheet.getColumn(18).width = 17;
  worksheet.getColumn(19).width = 17;

  /////////////////////wrap//////////////////////
  var espacio_interno =['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                        'P8','Q8','R8','S8']
  espacio_interno.map(celdas =>{
  worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
  })
  /////////////////////wrap//////////////////////
  
  /////////////////////ROW//////////////////////
  worksheet.getRow(8).height= 30
  /////////////////////ROW//////////////////////

  var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
  worksheet.getColumn(11).numFmt =numFmtStr;
  worksheet.getColumn(12).numFmt =numFmtStr;
  worksheet.getColumn(13).numFmt =numFmtStr;
  worksheet.getColumn(14).numFmt =numFmtStr;
  worksheet.getColumn(15).numFmt =numFmtStr;
  worksheet.getColumn(16).numFmt =numFmtStr;
  worksheet.getColumn(17).numFmt =numFmtStr;
  worksheet.getColumn(18).numFmt =numFmtStr;

  for (let x1 of xdatos)
{
  x1.fecha_inicio = new Date(x1.fecha_inicio)
let x2=Object.keys(x1);
let temp=[]
for(let y of x2)
{
temp.push(x1[y])
}
worksheet.addRow(temp)
}

 for (let i = 0; i <= xdatos.length; i++) {

    if(i == 0){
      worksheet.getCell('B9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('C9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('D9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('E9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('F9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('G9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('H9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('I9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('J9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('K9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('L9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('M9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('N9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('O9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('P9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('Q9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('R9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('S9').alignment = { vertical: 'top', horizontal: 'center' }
     
    }else{
      var numActu = i + 9 -1;
      var numActuString = numActu.toString()

      worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
    
      worksheet.getCell(`B${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`C${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`D${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`E${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`F${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`G${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`H${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`I${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`J${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`K${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`L${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`M${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`N${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`O${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`P${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`Q${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`R${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`S${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}},
      };
    }
    
    if(i == xdatos.length){
        var numActu = i + 9 -1;
        var final_mas_uno = i + 9 + 1
        var final_mas_uno_s = final_mas_uno.toString()

        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`K${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`L${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`M${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`N${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`O${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`P${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`Q${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`R${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`S${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
    }
  }
let fname="Reporte de Notas de Debito desde '"+this.fecha1+"' hasta '"+this.fecha2+"'";
workbook.xlsx.writeBuffer().then((data) => {
let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
});
} catch (error) {
  console.log(error);
  this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,})
}
}

imprimirNotaDebito(xvalor:any){

    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        const pdf = new PdfMakeWrapper();
        var valor = {cod_factura:xvalor.cod_factura, cod_nota_debito:xvalor.cod_nota_debito}
        this.servicio.listarNotaDebitohttp(valor).subscribe(
         async response  =>{
            console.log(response);
            this.dialogSpin.close();
            response.pdf4 = response.pdf2;
            response.pdf2[0].comentarios = '';
            
            response.pdf4[0].subtotal_costo_pedido = response.pdf4[0].subtotal_costo;
            response.pdf4[0].desc_pedido = response.pdf4[0].desc_nota;
            response.pdf4[0].desc_pedido_monto = response.pdf4[0].desc_monto;
            response.pdf4[0].monto_exento = '0,00';
            response.pdf4[0].iva_pedido = response.pdf4[0].iva_nota;
            /* response.pdf4[0].iva_monto = response.pdf4[0].iva_monto; */
            response.pdf4[0].total_costo_pedido = response.pdf4[0].total_costo;

            response.pdf6[0].tasa_precio =  response.fecha[0].tasa_precio;
            
            response.pdf4[0].tipo_pedido = 'NDB';

            response.pdf[0].cod_producto = 'N/A';
            response.pdf[0].des_producto = response.pdf[0].des_concepto;
            response.pdf[0].cant_producto = '1';
            response.pdf[0].costo_producto = '0,00';
            response.pdf[0].desc_prod = '0,00';
            response.pdf[0].desc_prod_monto = '0,00';
            response.pdf[0].subtotal_producto = response.pdf[0].subtotal_concepto ;
            
             this.pdf.pdfVentas(response,'d');
           
          },
          error =>{
            console.log(error);
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
       }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error ,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }
  async descargarnota(xvalor:any){

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner'
    });

    setTimeout(() => {
      const pdf = new PdfMakeWrapper();

      this.servicio.listarProdPedidohttp(xvalor).subscribe(
       async response  =>{
        this.dialogSpin.close();
          console.log(xvalor);
          pdf.info({
            title: 'Factura N°'+response.pdf2[0].cod_pedido+'',
            author: 'Factory cosmetics C.A.',
            subject: 'Facturas',
        });
        var titulo = 'FACTORY COSMETICS 3DG C.A.';
          var texto =
                        'Calle Bolivar con Calle Regulo Franquiz Local S/N,\n\
                           Sector Pueblo Arriba. Guarenas, Miranda.\n\
                           Zona Postal 1220. Telf.: (0212) 519.43.98 / 365.46.24 / 714.03.42 /425.2.83\n\
                          E-mail:contacto@factorycosmetics.com.ve / www.factorycosmetics.com.ve \n\
                                        RIF.: J-41031797-3';
            pdf.pageMargins([20,5,20,5]);
            pdf.add(pdf.ln(1));
            pdf.add(
              new Columns([ new Table([['',titulo,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).bold().absolutePosition(20,58).end]).columnGap(5).end,
                                  );
            pdf.add(
              new Columns([ new Table([[await new Img("../../../../../../assets/img/Factory (1).png").build(),'','']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(10,15).end]).columnGap(5).end,
                                  );
            pdf.add(
                  new Columns([ new Table([['',texto,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(20,70).end]).columnGap(5).end,
                                  );
          pdf.add( this.numerofact8(response.pdf2));
          pdf.add(this.crearTabla28(response.pdf2,response.pdf3));
          /* pdf.add(this.crearTabla3(response.pdf2)); */
          pdf.pageSize('A4');
          pdf.add(this.headerTabla8());
          pdf.add(this.crearTabla8(response.pdf));
          pdf.add(this.crearTabla88(response.pdf));
          pdf.add(this.crearTabla888(response.pdf));
          pdf.add(pdf.ln(1));
          pdf.add(this.crearTabla48(response.pdf4,xvalor,response.pdf5));
          pdf.create().download('Factura N°'+response.pdf2[0].cod_pedido+'');
        },
        error =>{
          console.log(error);
          this.dialogSpin.close();
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
    }, 3000);

  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  } 
  getBase64ImageFromURL8(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
  headerTabla8():ITable{
    return new Table([
      ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
    ]).layout({
      vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
        },
        hLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
        }
    }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,203).end;
    }
  crearTabla8(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData9(data)

  ]).layout('noBorders').widths([30,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,216).margin(40).end;
  }
  crearTabla88(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData99(data)

    ]).layout('noBorders').widths(['*','*',200,'*','*',20]).alignment('right').fontSize(6).absolutePosition(20,216).margin(40).end;
    }
    crearTabla888(data:interfacefactura[]):ITable{
      return new Table([
        ...this.extraerData999(data)

      ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,216).margin(40).end;
      }
  async cinta8(data:interdatosfacefactura[]){

    return new Table([
      [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
    ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
    }
    numerofact8(data:interdatosfacefactura[]):ITable{

      return new Table([
        ['','','','', '','Factura Nº: '+data[0].cod_pedido+''],
      ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,138).end;
      }
      crearTabla28(data:any[], data2:any){
        return  new Columns([new Table([
            ['Cliente: '+data2[0].des_cliente+'',''],
            ['R.I.F.: '+data2[0].cod_cliente+'',''],
            ['Vendedor: '+data2[0].des_vendedor+'',''],
            ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
          ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,150).end,
          new Table([
            [ '',''],
            [ '','Emisión: '+data[0].fecha_e_factura+''],
            [ '','Venc: '+data[0].fecha_e_factura+''],
            [ '','Dirección: '+data2[0].dir_cliente+'']
          ]).widths(['*',250]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,150).end]).columnGap(5).end;
          }
    crearTabla38(data:interdatosfacefactura[]):ITable{
      return new Table([
        ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
      ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
      }

        crearTabla48(data:any[],data2:any,data3:any){

          return  new Columns([ new Table([
            ['Notas:','','','','',''],
            ['LISTA DE PRECIOS Nº: '+data[0].cod_lista_precio+'','','','', '',''],
            ['CODIGO DE CONTROL Nº: '+data3[0].cod_control+'','','','','',''],
            ['TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A','','','','',''],
            ['DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.','','','', '',''],
            ['BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064  BANCAMIGA: 0172-0194-8419-4444-9550','','','', '',''],
          ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
              }
          }).absolutePosition(20,646).end,
          new Table([
            ['','','','', 'Total base imponible: ',''+data[0].subtotal_costo_pedido+''],
            ['','','','', 'Total exento: ',''+data[0].monto_exento+''],
            ['','','','', 'Descuento: '+data[0].desc_pedido+' %',''+data[0].desc_pedido_monto+''],
            ['','','','', 'Sub-total: ',''+data[0].subtotal_costo_pedido+''],
            ['','','','', 'I.V.A : '+data[0].iva_pedido+' %',''+data[0].iva_monto+''],
            ['','','','', 'Total: ',''+data[0].total_costo_pedido+''],
          ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
              }
          }).absolutePosition(20,646).end
        ]).columnGap(5).end

          }

        extraerData8(data:interfacefactura[]):TableRow[]{

          let rows =[];

          for (const i in data) {
           rows.push(data[i]);

          }
          console.log(rows);
          return rows.map(row =>['',row.cod_producto,
                               row.des_producto, '',
                              '', '']);
        }
        extraerData88(data:interfacefactura[]):TableRow[]{

          let rows =[];

          for (const i in data) {
           rows.push(data[i]);

          }
          console.log(rows);
          return rows.map(row =>['',
                               '', '',
                              row.costo_producto, row.subtotal_producto,'']);
        }
        extraerData888(data:interfacefactura[]):TableRow[]{

          let rows =[];

          for (const i in data) {
           rows.push(data[i]);

          }
          console.log(rows);
          return rows.map(row =>['','',
                               '', row.cant_producto,
                              '', '']);
        }


      //////////////////////IMPRMIR////////////////////////////
getBase64ImageFromURL9(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}
headerTabla9():ITable{
  return new Table([
    ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,190).end;
  }
crearTabla9(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData9(data)

]).layout('noBorders').widths([27,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,205).margin(40).end;
}
crearTabla99(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData99(data)

  ]).layout('noBorders').widths(['*','*',200,'*','*',17]).alignment('right').fontSize(6).absolutePosition(20,205).margin(40).end;
  }
  crearTabla999(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData999(data)

    ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,205).margin(40).end;
    }
async cinta9(data:interdatosfacefactura[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  }
  numerofact9(data:interdatosfacefactura[]):ITable{

    return new Table([
      ['','','','', '','Factura  Nº: '+data[0].cod_pedido+''],
    ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,130).end;
    }
  crearTabla29(data:any[], data2:any){
    return  new Columns([new Table([
        ['Cliente: '+data2[0].des_cliente+'',''],
        ['R.I.F.: '+data2[0].cod_cliente+'',''],
        ['Vendedor: '+data2[0].des_vendedor+'',''],
        ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
      ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,140).end,
      new Table([
        [ '',''],
        [ '','Emisión: '+data[0].fecha_e_factura+''],
        [ '','Venc: '+data[0].fecha_e_factura+''],
        [ '','Dirección: '+data2[0].dir_cliente+'']
      ]).widths(['*',250]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,140).end]).columnGap(5).end;
      }
  crearTabla39(data:interdatosfacefactura[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    }
    crearTabla49(data:any[],data2:any,data3:any){

      return  new Columns([ new Table([
        ['Notas:','','','','',''],
        ['LISTA DE PRECIOS Nº: '+data[0].cod_lista_precio+'','','','', '',''],
        ['CODIGO DE CONTROL Nº: '+data3[0].cod_control+'','','','','',''],
        ['TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A','','','','',''],
        ['DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.','','','', '',''],
        ['BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064  BANCAMIGA: 0172-0194-8419-4444-9550','','','', '',''],
      ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          }
      }).absolutePosition(20,637).end,
      new Table([
        ['','','','', 'Total base imponible: ',''+data[0].subtotal_costo_pedido+''],
        ['','','','', 'Total exento: ',''+data[0].monto_exento+''],
        ['','','','', 'Descuento: '+data[0].desc_pedido+' %',''+data[0].desc_pedido_monto+''],
        ['','','','', 'Sub-total: ',''+data[0].subtotal_costo_pedido+''],
        ['','','','', 'I.V.A : '+data[0].iva_pedido+' %',''+data[0].iva_monto+''],
        ['','','','', 'Total: ',''+data[0].total_costo_pedido+''],
      ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          }
      }).absolutePosition(20,637).end
    ]).columnGap(5).end

      }

      extraerData9(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',row.cod_producto,
                             row.des_producto, '',
                            '', '']);
      }
      extraerData99(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',
                             '', '',
                            row.costo_producto, row.subtotal_producto,'']);
      }
      extraerData999(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['','',
                             '', row.cant_producto,
                            '', '']);
      }


      //////////////////////IMPRMIR NOTA DE CREDITO DEV////////////////////////////
getBase64ImageFromURLNC(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}
headerTablaNC():ITable{
  return new Table([
    ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,190).end;
  }
crearTabla9NC(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData9NC(data)

]).layout('noBorders').widths([27,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,205).margin(40).end;
}
crearTabla99NC(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData99NC(data)

  ]).layout('noBorders').widths(['*','*',200,'*','*',17]).alignment('right').fontSize(6).absolutePosition(20,205).margin(40).end;
  }
  crearTabla999NC(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData999NC(data)

    ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,205).margin(40).end;
    }
async cinta9NC(data:interdatosfacefactura[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  }
  numerofact9NC(data:any[]):ITable{

    return new Table([
      ['','','','', '','Nota de credito  Nº: '+data[0].cod_nota_credito+''],
    ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,130).end;
    }
  crearTabla29NC(data:any[], data2:any){
    return  new Columns([new Table([
        ['Cliente: '+data2[0].des_cliente+'',''],
        ['R.I.F.: '+data2[0].cod_cliente+'',''],
        ['Vendedor: '+data2[0].des_vendedor+'',''],
        ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
      ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,140).end,
      new Table([
        [ '',''],
        [ '','Emisión: '+data[0].fecha_inicio+''],
        [ '','Venc: '+data[0].fecha_inicio+''],
        [ '','Dirección: '+data2[0].dir_cliente+'']
      ]).widths(['*',250]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,140).end]).columnGap(5).end;
      }
  crearTabla39NC(data:interdatosfacefactura[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    }
    crearTabla49NC(data:any[],data2:any,data3:any,data4:any,data5:any){
      console.log(data);
      console.log(data2);
      console.log(data3);

      var base_imponible = parseFloat(data[0].subtotal_dev_costo.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) - parseFloat(data[0].dev_monto_exento.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'));
      var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      return  new Columns([ new Table([
        ['Notas:','','','','',''],
        ['AFECTA LA FACTURA Nº: '+data[0].cod_factura+'','','','','',''],
        ['CODIGO DE CONTROL FACTURA Nº: '+data3[0].cod_control+'','','','','',''],
        ['CODIGO DE CONTROL NOTA DE CREDITO Nº: '+data5[0].cod_control+'','','','', '',''],
        ['FECHA DE LA FACTURA: '+data4[0].fecha_e_factura+'','','','', '',''],
        ['MONTO BASE DE LA FACTURA: '+data4[0].monto_base_factura+'','','','', '',''],
      ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          }
      }).absolutePosition(20,637).end,
      new Table([
        ['','','','', 'Total base imponible: ',''+formato_base_impnible+''],
        ['','','','', 'Total exento: ',''+data[0].dev_monto_exento+''],
        ['','','','', 'Descuento: '+data[0].desc_dev+' %',''+data[0].desc_dev_monto+''],
        ['','','','', 'Sub-total: ',''+data[0].subtotal_dev_costo+''],
        ['','','','', 'I.V.A : '+data[0].iva_dev+' %',''+data[0].iva_dev_monto+''],
        ['','','','', 'Total: ',''+data[0].total_dev_costo+''],
      ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          }
      }).absolutePosition(20,637).end
    ]).columnGap(5).end

      }

      extraerData9NC(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',row.cod_producto,
                             row.des_producto, '',
                            '', '']);
      }
      extraerData99NC(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',
                             '', '',
                            row.costo_producto, row.subtotal_producto,'']);
      }
      extraerData999NC(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['','',
                             '', row.cant_producto,
                            '', '']);
      }


      //////////////////////IMPRMIR NOTA DE DEBITO ADMIN////////////////////////////
getBase64ImageFromURLNCADMIN(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}
headerTablaNCADMIN():ITable{
  return new Table([
    ['Descripción','Sub-Total']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 2 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*','*']).alignment('center').fontSize(10).absolutePosition(20,190).end;
  }
crearTabla9NCADMIN(data:any[]):ITable{
return new Table([
  ...this.extraerData9NCADMIN(data)

]).layout('noBorders').widths(['*','*']).alignment('center').fontSize(9).absolutePosition(20,205).margin(40).end;
}
crearTabla99NCADMIN(data:any[]):ITable{
  return new Table([
    ...this.extraerData99NCADMIN(data)

  ]).layout('noBorders').widths(['*','*']).alignment('center').fontSize(9).absolutePosition(20,205).margin(40).end;
  }
  crearTabla999NCADMIN(data:any[]):ITable{
    return new Table([
      ...this.extraerData999NCADMIN(data)

    ]).layout('noBorders').widths(['*','*']).alignment('center').fontSize(9).absolutePosition(20,205).margin(40).end;
    }
async cinta9NCADMIN(data:any[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  }
  numerofact9NCADMIN(data:any[]):ITable{

    return new Table([
      ['','','','', '','Nota de debito  Nº: '+data[0].cod_nota_debito+''],
    ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,130).end;
    }
  crearTabla29NCADMIN(data:any[], data2:any){
    return  new Columns([new Table([
        ['Cliente: '+data2[0].des_cliente+'',''],
        ['R.I.F.: '+data2[0].cod_cliente+'',''],
        ['Vendedor: '+data2[0].des_vendedor+'',''],
        ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
      ]).widths([350,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,140).end,
      new Table([
        [ '',''],
        [ '','Emisión: '+data[0].fecha_inicio+''],
        [ '','Venc: '+data[0].fecha_inicio+''],
        [ '','Dirección: '+data2[0].dir_cliente+'']
      ]).widths(['*',400]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,140).end]).columnGap(5).end;
      }
  crearTabla39NCADMIN(data:any[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    }
    crearTabla49NCADMIN(data:any[],data2:any,data3:any,data4:any,data5:any){
      try {
        console.log(data,'data');
        console.log(data2,'data2');
        console.log(data3,'data3');
        console.log(data4,'data4');
        console.log(data5,'data5');
        var llave = false;
        var fecha_soporte = moment('01/10/2021', 'DD/MM/YYYY');
        var fecha_doc =  moment(data[0].fecha_inicio, 'DD/MM/YYYY') ;
        if (fecha_doc.isSameOrAfter(fecha_soporte)) {
  
          console.log(fecha_doc,'fecha inicio');
          console.log(fecha_soporte,'fecha finnn');
          llave = true;
        }else{
          console.log(fecha_doc,'fecha inicio');
          console.log(fecha_soporte,'fecha finnn');
          llave = false;
        }
          
        if (llave) {
    
          console.log('fecha mayor o igual');
     
          var base_imponible = parseFloat(data[0].subtotal_costo.replace('.','')
                                                                          .replace('.','')
                                                                          .replace('.','')
                                                                          .replace('.','')
                                                                          .replace('.','')
                                                                          .replace(',','.')) - parseFloat(data[0].monto_exento.replace('.','')
                                                                          .replace('.','')
                                                                          .replace('.','')
                                                                          .replace('.','')
                                                                          .replace('.','')
                                                                          .replace(',','.'));
    
            var base_factura = parseFloat(data[0].subtotal_costo.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) + parseFloat(data[0].desc_monto.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.'));
    
            var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

            var monto_base_nd = base_factura.toFixed(2).replace(/\D/g, "")
              .replace(/([0-9])([0-9]{2})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
           
            //////////////////////////RECONVERSION/////////////////////////////
            var idea_hp_de_maduro = 1000000;

            var b_fact = base_factura * idea_hp_de_maduro;
            var  formato_b_fact = b_fact.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
            var b_imp = base_imponible * idea_hp_de_maduro;
            var  formato_b_imp = b_imp.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
            var r_excento = parseFloat(data[0].monto_exento.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.'))  * idea_hp_de_maduro;
            var  formato_r_excento = r_excento.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
            var r_desc_pedido = parseFloat(data[0].desc_monto.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.')) * idea_hp_de_maduro;
            var  formato_r_desc_pedido = r_desc_pedido.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
            var r_subtotal_costo_pedido = parseFloat(data[0].subtotal_costo.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.')) * idea_hp_de_maduro;
            var  formato_r_subtotal_costo_pedido = r_subtotal_costo_pedido.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
            var r_iva_monto = parseFloat(data[0].iva_monto.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.')) * idea_hp_de_maduro;
            var  formato_r_iva_monto = r_iva_monto.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
            var r_total_costo_pedido = parseFloat(data[0].total_costo.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.')) * idea_hp_de_maduro;
            var  formato_r_total_costo_pedido = r_total_costo_pedido.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          //////////////////////////RECONVERSION/////////////////////////////
          var xvalor_1;
          var xvalor_2;
          var xvalor_3;
          var xvalor_4;
          if (data3 === undefined ||
                   data3 === null ||
                   data3 === 'N/A') {
                    xvalor_1 = data3;
            
          }else{
            xvalor_1 = data3[0].cod_control;
          }

          if (data4 === undefined ||
                   data4 === null ||
                   data4 === 'N/A') {
                    xvalor_2 = data4;
          
          }else{
            xvalor_2 = data4[0].fecha_inicio;
          }

          if (data4 === undefined ||
                   data4 === null ||
                   data4 === 'N/A') {
                    xvalor_3 = data4;
                    xvalor_4 = data4;
          
          }else{
            xvalor_3 = data4[0].monto_base_factura;
            xvalor_4 = data4[0].fecha_inicio;
          }
         
          return  new Columns([ new Table([
            ['Notas:','','','','',''],
            ['AFECTA LA FACTURA Nº: '+data[0].cod_factura+'','','','','',''],
            ['CODIGO DE CONTROL FACTURA Nº: '+xvalor_1+'','','','','',''],
            ['CODIGO DE CONTROL NOTA DE CREDITO Nº: '+data5[0].cod_control+'','','','', '',''],
            ['FECHA DE LA FACTURA: '+xvalor_4+'','','','', '',''],
            ['MONTO BASE DE LA FACTURA: '+xvalor_3+'','','','', '',''],
          ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
              }
          }).absolutePosition(20,620).end,
        new Table([

          ['','','','Monto base: ', ''+formato_b_fact+' Bs',''], 
          ['','','','Descuento: '+data[0].desc_nota+' %', ''+formato_r_desc_pedido+' Bs',''],
          ['','','','Total exento: ', ''+formato_r_excento+' Bs',''],
          ['','','','Sub-total: ', ''+formato_r_subtotal_costo_pedido+' Bs',''],
          ['','','','Total base imponible: ', ''+formato_b_imp+' Bs',''],
          ['','','','I.V.A : '+data[0].iva_nota+' %', ''+formato_r_iva_monto+' Bs',''],
          ['','','','Total: ', ''+formato_r_total_costo_pedido+' Bs',''],


         /*  ['','','','Total base imponible: ',''+formato_b_imp+' bs',''],
          ['','','','Total exento: ',''+formato_r_excento+' bs',''],
          ['','','','Descuento: '+data[0].desc_nota+' %',''+formato_r_desc_pedido+' bs',''],
          ['','','','Sub-total: ',''+formato_r_subtotal_costo_pedido+' bs',''],
          ['','','','I.V.A : '+data[0].iva_nota+' %',''+formato_r_iva_monto+' bs',''],
          ['','','','Total: ',''+formato_r_total_costo_pedido+' bs',''], */
    
        ]).widths(['*','*','*',70,70,60]).alignment('right').fontSize(7).layout({
          vLineWidth:(i:number, node:any, col:number)=>{
              return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
            },
            hLineWidth:(i:number, node:any, col:number)=>{
              return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
            }
        }).absolutePosition(20,620).end,
    
        new Table([
          ['','','','','', ''+monto_base_nd+' Bs'],
          ['','','','', '', ''+formato_base_impnible+'  Bs'],
          ['','','','', '', ''+data[0].monto_exento+'  Bs'],
          ['','','','', '', ''+data[0].desc_monto+'  Bs'],
          /* ['','','','', '', '',''+formato_factura_base_imponible_divisa+'  $'], */
          ['','','','', '', ''+data[0].subtotal_costo+'  Bs'],
          ['','','','', '', ''+data[0].iva_monto+'  Bs'],
          ['','','','', '', ''+data[0].total_costo+'  Bs'],
        ]).widths(['*','*','*','*','*',60]).alignment('right').bold().fontSize(7).layout({
          vLineWidth:(i:number, node:any, col:number)=>{
              return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
            },
            hLineWidth:(i:number, node:any, col:number)=>{
              return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
            }
        }).absolutePosition(20,620).end
      ]).columnGap(5).end
  
        }else{
  
          console.log(data[0],'fecha anterior');
          var base_imponible = parseFloat(data[0].subtotal_costo.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) - parseFloat(data[0].monto_exento.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'));
    
          
    var base_factura = parseFloat(data[0].subtotal_costo.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.')) + parseFloat(data[0].desc_monto.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'));
    
    var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
    var monto_base_nd = base_factura.toFixed(2).replace(/\D/g, "")
              .replace(/([0-9])([0-9]{2})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    //////////////////////////RECONVERSION/////////////////////////////
    var idea_hp_de_maduro = 1000000;

    var b_fact = base_factura / idea_hp_de_maduro;
            var  formato_b_fact = b_fact.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
    var b_imp = base_imponible / idea_hp_de_maduro;
    var  formato_b_imp = b_imp.toFixed(2).replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
    var r_excento = parseFloat(data[0].monto_exento.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'))  / idea_hp_de_maduro;
    var  formato_r_excento = r_excento.toFixed(2).replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
    var r_desc_pedido = parseFloat(data[0].desc_monto.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'))  / idea_hp_de_maduro;
    var  formato_r_desc_pedido = r_desc_pedido.toFixed(2).replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
    var r_subtotal_costo_pedido = parseFloat(data[0].subtotal_costo.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'))  / idea_hp_de_maduro;
    var  formato_r_subtotal_costo_pedido = r_subtotal_costo_pedido.toFixed(2).replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
    var r_iva_monto = parseFloat(data[0].iva_monto.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'))  / idea_hp_de_maduro;
    var  formato_r_iva_monto = r_iva_monto.toFixed(2).replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
    var r_total_costo_pedido = parseFloat(data[0].total_costo.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'))  / idea_hp_de_maduro;
    var  formato_r_total_costo_pedido = r_total_costo_pedido.toFixed(2).replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    //////////////////////////RECONVERSION/////////////////////////////

    var xvalor_1;
          var xvalor_2;
          var xvalor_3;
          var xvalor_4;
          if (data3 === undefined ||
                   data3 === null ||
                   data3 === 'N/A') {
                    xvalor_1 = data3;
            
          }else{
            xvalor_1 = data3[0].cod_control;
          }

          if (data4 === undefined ||
                   data4 === null ||
                   data4 === 'N/A') {
                    xvalor_2 = data4;
          
          }else{
            xvalor_2 = data4[0].fecha_inicio;
          }

          if (data4 === undefined ||
                   data4 === null ||
                   data4 === 'N/A') {
                    xvalor_3 = data4;
                    xvalor_4 = data4;
          
          }else{
            xvalor_3 = data4[0].monto_base_factura;
            xvalor_4 = data4[0].fecha_inicio;
          }
         
          return  new Columns([ new Table([
            ['Notas:','','','','',''],
            ['AFECTA LA FACTURA Nº: '+data[0].cod_factura+'','','','','',''],
            ['CODIGO DE CONTROL FACTURA Nº: '+xvalor_1+'','','','','',''],
            ['CODIGO DE CONTROL NOTA DE CREDITO Nº: '+data5[0].cod_control+'','','','', '',''],
            ['FECHA DE LA FACTURA: '+xvalor_4+'','','','', '',''],
            ['MONTO BASE DE LA FACTURA: '+xvalor_3+'','','','', '',''],
          ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
              }
          }).absolutePosition(20,620).end,
    new Table([
 
          ['','','','Monto base: ', ''+monto_base_nd+' Bs',''], 
          ['','','','Descuento: '+data[0].desc_nota+' %', ''+data[0].desc_monto+' Bs',''],
          ['','','','Total exento: ', ''+data[0].monto_exento+' Bs',''],
          ['','','','Sub-total: ', ''+data[0].subtotal_costo+' Bs',''],
          ['','','','Total base imponible: ', ''+formato_base_impnible+' Bs',''],
          ['','','','I.V.A : '+data[0].iva_nota+' %', ''+data[0].iva_monto+' Bs',''],
          ['','','','Total: ', ''+data[0].total_costo+' Bs',''],
    ]).widths(['*','*','*',70,70,60]).alignment('right').fontSize(7).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
    }
    }).absolutePosition(20,620).end,
    
    new Table([

    ['','','','','', ''+formato_b_fact+' Bs'],
    ['','','','', '', ''+formato_r_desc_pedido+' Bs'],
    ['','','','', '', ''+formato_r_excento+' Bs'],
    ['','','','', '', ''+formato_r_subtotal_costo_pedido+' Bs'],
    /* ['','','','', '', '',''+formato_factura_base_imponible_divisa+'  $'], */
    ['','','','', '', ''+formato_b_imp+' Bs'],
    ['','','','', '', ''+formato_r_iva_monto+' Bs'],
    ['','','','', '', ''+formato_r_total_costo_pedido+' Bs'],
    ]).widths(['*','*','*','*','*',60]).alignment('right').bold().fontSize(7).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
    }
    }).absolutePosition(20,620).end
    ]).columnGap(5).end
        }
      } catch (error) {
        console.log(error);
      }
  
      }

      extraerData9NCADMIN(data:any[]):TableRow2[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>[row.des_concepto,
                             '']);
      }
      extraerData99NCADMIN(data:any[]):TableRow2[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',
                           row.subtotal_concepto]);
      }
      extraerData999NCADMIN(data:any[]):TableRow2[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>[ '',
                             '']);
      }

}
