<div class="col-md-12 col-sm-12 col-xs-12 my-0" style=" border-radius: 1rem; ">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div class="modal-content text-white " style=" width: 100%; background-color: #1E212C; border-radius: 1rem;">
            <div class="modal-header px-5" style="background-color: #1E212C;  border-radius: 1rem 1rem 0 0;">
                <h3 class="modal-title text-center" ><strong class="text-center">Editar precio base</strong> </h3>
            </div>
            <div class="modal-body bg-light">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <mat-form-field class="materialinput " style="padding: 10px; margin: 0px;">
                            <mat-label>Precio base</mat-label>
                            <input style="color: #1E212C;" #precio_base_dol (keyup)="format(precio_base_dol)" maxlength="6" matInput placeholder="Precio base" autocomplete="off" formControlName="precio_producto_base">
                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>$</mat-icon>
                          </span>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="modal-footer bg-light" style="border-radius: 0 0 1rem 1rem;">
                <div class="form-group">
                    <button mat-button class=" btn-danger mr-1" (click)="cerrarmodal()">Cancelar</button>
                    <button [disabled]="forma.invalid" mat-button class=" btn-primary"  (click)="cambiarPrecio(precio_base_dol.value)">Aceptar</button>
                </div>
            </div>
        </div>
    </form>
</div>