import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { AtributosService } from './atributos.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaServicesService {
  rx:any;
  constructor(
    private media: BreakpointObserver,
    private atrb: AtributosService,
  ) { 
  }

  mediaQuery(xvalor:any){
    this.media
    .observe(Breakpoints.Small)
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
      //AQUI SERA TRUE SOLO SI ESTA EN RESOLUCION DE CELULAR
      xvalor = 'phone';
    }
  });

  this.media
  .observe(Breakpoints.Web)
  .subscribe((state: BreakpointState) => {
    if (state.matches) {
      //AQUI SERA TRUE SOLO SI ES RESOLUCION PARA WEB
      xvalor = 'web';
    }
  });
  return xvalor;
 }
}
