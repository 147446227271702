 
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 1rem;">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                <div class="modal-content h-100" style="border-radius: 1rem;">
                    <div class="modal-header head_formularios" style=" color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" class=" text-left"><strong>Cliente nuevo</strong> </h3>
                    </div>
                    <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div class="row py-0">
                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Rif:</mat-label>
                                                <input style="font-size: 12px; color: black;" maxlength="10" matInput placeholder="J/V/G" formControlName="cod_cliente" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          <mat-icon>business</mat-icon>
                                        </span>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Descrip cliente</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" placeholder="Descrip cliente:" formControlName="des_cliente" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                        <mat-icon>group</mat-icon>
                                        </span>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Correo</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" placeholder="" formControlName="correo_cliente" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          <mat-icon>mail</mat-icon>
                                          </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Unidad de facturación</mat-label>
                                                <mat-select style="font-size: 12px; color: black;"  (selectionChange)="dep_unid_manejo(tipo_factura)" #tipo_factura name="estatus"  formControlName="mod_factur" autocomplete="off">
                                                    <mat-option style="font-size: 12px; color: black;"  value="N/A">N/A</mat-option>
                                                    <mat-option style="font-size: 12px; color: black;" *ngFor="let item of tipo_um_und" [value]="item.concepto_definicion">{{item.descrip_definicion}}-{{item.concepto_definicion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Unidad de consignación</mat-label>
                                                <mat-select style="font-size: 12px; color: black;" #tipo_consig (selectionChange)="modo_consig(tipo_consig)" name="estatus"  formControlName="mod_consig" autocomplete="off">
                                                    <mat-option  value="N/A">N/A</mat-option>
                                                    <mat-option *ngFor="let item of tipo_um_und_consig" [value]="item.concepto_definicion">{{item.descrip_definicion}}-{{item.concepto_definicion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-3 col-sm-3 col-xs-3 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Tipo</mat-label>
                                                <mat-select style="font-size: 12px; color: black;" (selectionChange)="asociarLista(tipo)" #tipo name="estatus"  formControlName="tipo_cliente" autocomplete="off">
                                                    <mat-option *ngFor="let item of tipo_cliente" [value]="item.concepto_definicion">{{item.descrip_definicion}}-{{item.concepto_definicion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Estatus</mat-label>
                                                <mat-select style="font-size: 12px; color: black;" name="estatus" placeholder="" formControlName="estatus_cliente" autocomplete="off">
                                                    <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Credito</mat-label>
                                                <input style="font-size: 12px; color: black;" matInput placeholder="" autocomplete="off" formControlName="credito_dias_cliente">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          <mat-icon>payment</mat-icon>
                                          </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-4 col-sm-4 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Asociar a listas de precios</mat-label>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'N/A'"  formControlName="listas_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'CDN'"  formControlName="listas_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'DSTRBDR'"  formControlName="listas_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'PVP'"  formControlName="listas_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'PT'"  formControlName="listas_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'SPCL'"  formControlName="listas_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                    <mat-option *ngFor="let item of listas" [value]="item.cod_cliente">{{item.cod_cliente}}-{{item.des_cliente}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Asociar almacenes</mat-label>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'N/A'"  formControlName="almacen_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'PT'"  formControlName="almacen_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                    <mat-option *ngFor="let item of deposito_pt" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'SPCL'"  formControlName="almacen_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                    <mat-option *ngFor="let item of almacen" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'CDN'"  formControlName="almacen_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                    <mat-option *ngFor="let item of almacen_cdn" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'DSTRBDR'"  formControlName="almacen_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                    <mat-option *ngFor="let item of almacen_dstrbdr" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                                                </mat-select>
                                                <mat-select style="font-size: 12px; color: black;" *ngIf="asociar === 'PVP'"  formControlName="almacen_cliente" autocomplete="off">
                                                    <mat-option value="N/A">N/A</mat-option>
                                                    <mat-option *ngFor="let item of almacen_pvp" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                                                </mat-select>

                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Dirección</mat-label>
                                                <textarea style="font-size: 12px; color: black;" matInput placeholder="" autocomplete="off" formControlName="dir_cliente"> </textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-3 col-sm-3 col-xs-3 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Telefono 1</mat-label>
                                                <input style="font-size: 13px; color: black;" matInput placeholder="0000-0000000" autocomplete="off" formControlName="telefono1_cliente">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                        <mat-icon>call</mat-icon>
                                        </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-3 col-sm-3 col-xs-3 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Telefono 2</mat-label>
                                                <input style="font-size: 12px; color: black;" matInput placeholder="0000-0000000" autocomplete="off" formControlName="telefono2_cliente">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                        <mat-icon>call</mat-icon>
                                        </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-3 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Estado</mat-label>
                                                <mat-select style="font-size: 12px; color: black;" #estado (selectionChange)="ciudad_cliente(estado)" formControlName="pais_estado_cliente" autocomplete="off">
                                                    <mat-option *ngFor="let item of estados" [value]="item">{{item}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label style="color: #000">Ciudad</mat-label>
                                                <mat-select style="font-size: 12px; color: black;" formControlName="ciudad_cliente" autocomplete="off">
                                                    <mat-option *ngFor="let item of ciudad" [value]="item">{{item}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class="  btn-danger mx-2" (click)="cerrarmodal()" >Cancelar</button>
                            <button mat-button class=" btn-primary"  (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>