
<div class="" id="modal" *ngIf="accion === 'crear'">
    <div class="row" style="border-radius: 1rem;">
            <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 1rem;">
                <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                    <div class="modal-content h-100" style="border-radius: 1rem;">
                        <div class="modal-header" style="background-color: rgb(57, 55, 177); color: #fff; border-radius: 1rem 1rem 0 0;">
                            <h3 class="modal-title" class=" text-left"><strong> Crear unidad del sistema</strong> </h3>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="row py-0">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Descripcion de la unidad</mat-label>
                                                    <textarea matInput  autocomplete="off" formControlName="des_unidad"> </textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row py-0">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Responsable de la unidad</mat-label>
                                                    <input class="" matInput  formControlName="respon_unidad" autocomplete="off">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row py-0">
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Unidad padre</mat-label>
                                                    <mat-select  formControlName="cod_unidad_padre" autocomplete="off">
                                                        <mat-option value="0">Unidad padre</mat-option>
                                                        <mat-option *ngFor="let item of unidadp" [value]="item.cod_unidad">{{item.des_unidad}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label >Estatus</mat-label>
                                                    <mat-select formControlName="estatus" autocomplete="off">
                                                        <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                                <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
</div>
<div class="" id="modal" *ngIf="accion === 'actualizar'">
    <div class="row" style="border-radius: 1rem;">
            <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 1rem;">
                <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                    <div class="modal-content h-100" style="border-radius: 1rem;">
                        <div class="modal-header d-block justify-content-start" style="background-color: rgb(57, 55, 177); color: #fff; border-radius: 1rem 1rem 0 0;">
                            <h3 class="modal-title"><strong> Editar unidad del sistema</strong> </h3>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="row py-0 mb-3">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0">
                                                <div matInput  class=" text-left"><strong> Codigo: {{data.cod_unidad}} </strong> </div>
                                            </div>
                                        </div>
                                        <div class="row py-0">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Descripcion de la unidad</mat-label>
                                                    <textarea matInput  autocomplete="off" formControlName="des_unidad"> </textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row py-0">
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Responsable de la unidad</mat-label>
                                                    <input class="" matInput  formControlName="respon_unidad" autocomplete="off">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label >Estatus</mat-label>
                                                    <mat-select formControlName="estatus" autocomplete="off">
                                                        <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row py-0">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Unidad padre</mat-label>
                                                    <mat-select  formControlName="cod_unidad_padre" autocomplete="off">
                                                        <mat-option value="0">Unidad padre</mat-option>
                                                        <mat-option *ngFor="let item of unidadp" [value]="item.cod_unidad">{{item.des_unidad}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                                <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
</div>
