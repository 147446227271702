import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};
@Component({
  selector: 'app-form-precios',
  templateUrl: './form-precios.component.html',
  styleUrls: ['./form-precios.component.css']
})
export class  FormPreciosComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('tasa_base',{static:true}) tasa_base:ElementRef;

  arry: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
dialogSpin:any;
observador:any = false;
asignado:any = [];
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormPreciosComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_cliente': data.cod_cliente,
        'des_cliente': data.des_cliente,
        'cod_producto':'',
        'precio_base':'',
        'tasa_precio':'',
        'termino':data.termino
      });
    }

  ngOnInit(): void {
    this.listarproducto();
    this.cod_lista1.nativeElement.value = this.data.cod_lista_precio_producto;
    this.tasa_base.nativeElement.value = this.data.tasa_precio;
    this.listarproductoListaprecio(this.data.cod_lista_precio_producto);
  }
  ngAfterViewInit() {}

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
  listarproducto(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarProductoTermhttp().subscribe(
      response =>{

        this.arry = this.forma.get('cod_producto')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }

                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.codigo
                  }];

                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  listarproductoListaprecio(xvalor:any){
   console.log(xvalor);
    this.servicio.listarProductoListapreciohttp(xvalor).subscribe(
      response =>{
        console.log(response);
          this.ELEMENT_DATA = [];
              for (const i in response) {

                if (parseFloat(response[i].precio_producto_base.replace('.', '')
              .replace('.', '')
              .replace('.', '')
              .replace(',', '.')) > 0) {
                this.asignado.push(response[i].cod_producto);
                this.observador = true;

              }else{
                this.observador = false;
              }

                this.ELEMENT_DATA.push({
                  cod_producto:response[i].cod_producto,
                  des_producto: response[i].des_producto,
                  precio_producto_base:response[i].precio_producto_base,
                  precio_producto_base_bs:response[i].precio_producto_base_bs,
                  estatus_fiscal:response[i].estatus_fiscal,
                  observador:this.observador
                });
              }
              this.displayedColumns = [
                                        'cod_producto',
                                        'des_producto',
                                        'estatus_fiscal',
                                        'precio_producto_base',
                                        'precio_producto_base_bs',
                                        'update_elem'
                                      ];
              this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);

      },
      error =>{
        console.log(error);
        console.log(<any>error.error.message);
        this.displayedColumns = [
                                  'cod_producto',
                                  'des_producto',
                                  'estatus_fiscal',
                                  'precio_producto_base',
                                  'precio_producto_base_bs',
                                  'update_elem'
                                ];
        this.dataSource1 = new MatTableDataSource<any>();
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }

  modalmodificarprecios(obj:any, text:any){
    this.accion = text;
      var dialogRef = this.dialog.open(FormModifiPrecioComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {cod_producto:obj.cod_producto,
          precio_producto_base:obj.precio_producto_base,
          precio_producto_base_bs:obj.precio_producto_base_bs,
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);
          this.recalculoPrecioProducto(result);

        }
      });
  }

  formulario():void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    this.displayedColumns = [

      'cod_producto',
      'des_producto',
      'estatus_fiscal',
      'precio_producto_base',
      'precio_producto_base_bs',
      'remov_elem'
     ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<PeriodicElementfacturas>(this.ELEMENT_DATA);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  addElement() {
    let arry = [];
    let arry2 = [];
    this.forma.value.precio_base = this.precio_base.nativeElement.value;
    this.forma.value.tasa_precio = this.tasa_base.nativeElement.value;

    console.log(this.forma);

    if (parseInt(this.forma.value.precio_base) >= 0 && this.tasa_base.nativeElement.value != '' && this.cod_producto.nativeElement.value != '' ) {
      var cod = this.forma.value.cod_producto;
    var valor:any = {cod_producto:cod}
    this.servicio.buscarPRODUCTOBttp(valor).subscribe(
      response =>{
        console.log(response);

          this.cod_producto.nativeElement.value = '';
          this.precio_base.nativeElement.value = '';
          let subtotal = (parseInt(response[0].costo_producto)  *  parseInt(this.forma.value.tasa_base) )
          let costoprodcto = parseInt(response[0].costo_producto);
          console.log(typeof costoprodcto);
        if (this.ELEMENT_DATA.length == 0) {
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];

          this.listarproducto();
          this.ELEMENT_DATA.push({
            cod_producto:response[0].cod_producto,
            des_producto:response[0].des_producto,
            precio_producto_base:this.forma.value.precio_base,
            precio_producto_base_bs:this.forma.value.precio_bs,
            estatus_fiscal:response[0].estatus_fiscal
          });

          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);


        }else{
          console.log(response);
            for (const i in this.ELEMENT_DATA) {
             arry.push(this.ELEMENT_DATA[i].cod_producto);
            }
            let index = arry.includes(response[0].cod_producto);

            if (!index) {
             let xvalor1 = parseFloat(this.forma.value.precio_base.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) * parseFloat(this.tasa_base.nativeElement.value.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.'));
              this.codigo = [];
              this.producto = [];
                this.listarproducto();
                this.ELEMENT_DATA.push({
                  cod_producto:response[0].cod_producto,
                  des_producto:response[0].des_producto,
                  precio_producto_base:this.forma.value.precio_base,
                  precio_producto_base_bs:xvalor1.toFixed(3).toString().replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                  estatus_fiscal:response[0].estatus_fiscal
                });
                this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);



            }else{
              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];
              this.listarproducto();




                this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
            }
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
    }else{
        if (parseInt(this.precio_base.nativeElement.value) <= 0 || isNaN(this.precio_base.nativeElement.value) || this.precio_base.nativeElement.value === "") {
          this.snackBar.open('La cantidad debe ser mayor a 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (parseInt(this.tasa_base.nativeElement.value) <= 0 || isNaN(this.tasa_base.nativeElement.value) || this.tasa_base.nativeElement.value === "") {
          this.snackBar.open('El campo Tasa Base no tiene el dato correcto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.cod_producto.nativeElement.value === '') {
          this.snackBar.open('Debe seleccionar un producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    }
  }

recalculoPrecioProducto(xvalor:any){
try {
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });

  setTimeout(() => {
    this.dialogSpin.close();
    if (this.tasa_base.nativeElement.value != '') {
      let tasa = parseFloat(this.tasa_base.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'));

      console.log(tasa);
      console.log(xvalor);

      console.log(this.ELEMENT_DATA);

      for (const i in this.ELEMENT_DATA) {
        if (this.ELEMENT_DATA[i].cod_producto === xvalor.cod_producto) {
          var precio_base_nuevo = parseFloat(xvalor.valor.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'));

            var precio_bs_nuevo = (tasa)*(precio_base_nuevo);
            console.log(precio_bs_nuevo.toFixed(2).toString().replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."));

            this.ELEMENT_DATA[i].precio_producto_base = xvalor.valor;
            this.ELEMENT_DATA[i].precio_producto_base_bs = precio_bs_nuevo.toFixed(3).toString().replace(/\D/g, "")
            .replace(/([0-9])([0-9]{3})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          console.log(precio_bs_nuevo,'este es el correcto');

        }
      }
      this.snackBar.open('El precio de este producto fue actualizado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }else{
      this.snackBar.open('El campo Tasa Base se encuentra vacio', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }, 1000);

} catch (error) {
  this.dialogSpin.close();
  this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
}
}

cambioPrecio(){
  try {
    if (this.tasa_base.nativeElement.value != '') {
      this.accion = "enviarprecios";
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          this.cambioPrecioBsPorTasa();

        }
      });
    }else{
      this.snackBar.open('El campo Tasa Base se encuentra vacio', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

  }

cambioPrecioBsPorTasa(){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      this.dialogSpin.close();
      if (this.tasa_base.nativeElement.value != '') {
        let tasa = parseFloat(this.tasa_base.nativeElement.value.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'));

        for (const i in this.ELEMENT_DATA) {
            var precio_base_nuevo = parseFloat(this.ELEMENT_DATA[i].precio_producto_base.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.'));

              var precio_bs_nuevo = (tasa)*(precio_base_nuevo);

              this.ELEMENT_DATA[i].precio_producto_base_bs = precio_bs_nuevo.toFixed(3).toString().replace(/\D/g, "")
              .replace(/([0-9])([0-9]{3})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

        }
        this.snackBar.open('Los precios han sido recalculados.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }else{
        this.snackBar.open('El campo Tasa Base se encuentra vacio', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }, 1000);

  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

cantidad_item() {
  try {
    return this.ELEMENT_DATA.length;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

cantidad_asignado() {
  try {
    return this.asignado.length;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

modalrespuesta(){
if (this.tasa_base.nativeElement.value != '') {
  this.accion = "enviarprecios";
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result != 'cerrar'){

      this.forma.value.producto_pedido = this.ELEMENT_DATA;
      this.forma.value.tasa_precio = this.tasa_base.nativeElement.value;
      this.dialogRefp.close(this.forma.value);
      /* let resultado = this.eliminarunidad(result.id); */
    }
  });
}else{
  this.snackBar.open('El campo Tasa Base se encuentra vacio', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
}

}

format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  }

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
