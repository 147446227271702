import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-ordenesprodmpp',
  templateUrl: './form-ordenesprodmpp.component.html',
  styleUrls: ['./form-ordenesprodmpp.component.css']
})
export class FormOrdenesprodmppComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('tasa_base',{static:true}) tasa_base:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  arry: Observable<StateGroup[]>;


  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormOrdenesprodmppComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');


      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'cant_producto':'',
        'ord_producto':'',
        'cod_orden_producto':''

      });
    }

  ngOnInit(): void {
    this.formulario();
    this.listarproducto();

  }
  ngAfterViewInit() {

  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
  listarproducto(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarProductoMateriaPrimaPreparadahttp().subscribe(
      response =>{

        this.arry = this.forma.get('cod_producto')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }

                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.codigo
                  }];

                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  formulario():void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    this.displayedColumns = [

      'cod_producto',
      'des_producto',
      'cant_producto',
      'detalle',
      'remov_elem'
     ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  addElement() {
    let arry = [];
    let arry2 = [];
    this.forma.value.cant_producto = this.cant_producto.nativeElement.value;

    console.log(this.forma);

    if (parseInt(this.forma.value.cant_producto) > 0 ) {
      var cod = this.forma.value.cod_producto;
    var valor:any = {cod_producto:cod}
    this.servicio.buscarProductoMpphttp(valor).subscribe(
      response =>{
        console.log(response);

        if (this.ELEMENT_DATA.length == 0) {
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];

          this.listarproducto();
          this.ELEMENT_DATA.push({
            cod_producto:response[0].cod_producto,
            des_producto:response[0].des_producto,
            cant_producto:this.forma.value.cant_producto
          });

          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
          this.cod_producto.nativeElement.value = '';
          this.cant_producto.nativeElement.value = '';

        }else{
          console.log(response);
            for (const i in this.ELEMENT_DATA) {
             arry.push(this.ELEMENT_DATA[i].cod_producto);
            }
            let index = arry.includes(response[0].cod_producto);

            if (!index) {
              this.codigo = [];
              this.producto = [];
                this.listarproducto();
                this.ELEMENT_DATA.push({
                  cod_producto:response[0].cod_producto,
                  des_producto:response[0].des_producto,
                  cant_producto:this.forma.value.cant_producto
                });
                this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);

                this.cod_producto.nativeElement.value = 'Seleccione un producto';
                this.cant_producto.nativeElement.value = '';

            }else{
              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];
              this.listarproducto();

              this.cod_producto.nativeElement.value = 'Seleccione un producto';
              this.cant_producto.nativeElement.value = '';;


                this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
            }
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
    }else{
        if (parseInt(this.cant_producto.nativeElement.value) <= 0 || isNaN(this.cant_producto.nativeElement.value) || this.cant_producto.nativeElement.value === "") {
          this.snackBar.open('La cantidad debe ser mayor a 0 y solo puede ingresar numeros', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    }
  }

modalrespuesta(){
  if (this.ELEMENT_DATA.length > 0) {
    this.accion = "enviarordenprodmpp";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '25%',
      height:'25%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){

        this.forma.value.ord_producto = this.ELEMENT_DATA;
        console.log(this.forma.value);
        this.dialogRefp.close(this.forma.value);

      }
    });
  }else{
    this.snackBar.open('Debes seleccionar al menos un producto', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }



}
eliminarItems(val:any){
  this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
   this.table.renderRows();
 }
  cerrarmodal(): void {
    this.dialogRefp.close('cerrar');
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

}
