import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-form-spinner',
  templateUrl: './form-spinner.component.html',
  styleUrls: ['./form-spinner.component.css']
})
export class FormSpinnerComponent implements OnInit {
  /* mode: ProgressSpinnerMode = 'indeterminate'; */
  constructor() { }

  ngOnInit(): void {
  }

}
