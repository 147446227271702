<div class="fadeIn2 fast animated">

    <nav class="navbar navbar-expand-lg  nav" style="height: 7%; border:1px solid #cccccccc ;">
        <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto ">
                <li class="nav-item">

                </li>
                <li class="nav-item">
                    <form class="form-inline my-2 my-lg-0" (ngSubmit)="enviarcorreos(formulario1)" #formulario1="ngForm">
                        <button class="my-2 my-sm-0 mr-sm-2" mat-button color="accent"><mat-icon style="margin: 0% 2% 0% 0%;">open_in_new</mat-icon>Publicar NewLetters</button>
                        <mat-form-field class="my-2 my-sm-0 mr-sm-4">
                            <mat-label>Asunto:</mat-label>
                            <input matInput placeholder="Asunto:" autocomplete="off" name="asunto" ngModel>
                        </mat-form-field>
                    </form>
                </li>
            </ul>

            <a href="http://localhost:3700/api/mostrarplantilla" target="_blank" class="my-2 my-sm-0 mr-sm-2" title="Visualizar NewLetters"><button class=" my-2 my-sm-0" mat-button color="accent" style="padding: 10%;"><mat-icon style=" margin: 0% 2% 0% 0%;">image_search</mat-icon></button></a>
            <button class="my-2 my-sm-0 mr-sm-2" mat-button color="primary" (click)="cargarImagenes(formulario)" [disabled]="archivos.length === 0"> <mat-icon style="margin: 0% 2% 0% 0%;">update</mat-icon>Cargar imagenes</button>
            <button (click)="limpiarArchivos()" class=" my-2 my-sm-0" mat-button color="warn"><mat-icon style="margin: 0% 2% 0% 0%;">delete</mat-icon>Limpiar bandeja</button>
        </div>
    </nav>
</div>
<div class=" container-fluid fadeIn2 fast animated" style="height: 93%;">
    <div class="row" style="height: 100%;">

        <div class="col-md-12 col-sm-12 col-xs-12">
            <br>
            <div class="row">

                <div class="col-md-12 col-sm-12 col-xs-12 ">
                    <form #formulario="ngForm">
                        <div class=" mat-elevation-z8 example-container2 ">

                            <table mat-table [dataSource]="dataSource" class=" table-bordered table-condensed table-striped ">

                                <ng-container matColumnDef="Nombre_archivo">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Archivo</strong> </h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" [ngClass]="{ 'text-green': element.progreso >= 100 }" style="width: 10%;"> {{element.nombreArchivo}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Tamaño">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Tamaño</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 7%;">
                                        <div style="width: 100%;">{{ element.archivo.size / 1024 /1024 | number: '.2-2' }} MB</div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Tipo">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Tipo</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 20%;">
                                        <mat-select name="tipo" placeholder="Tipo" style="width: 90%; border-bottom: 1px dotted #000;" ngModel>
                                            <mat-option value="header">Encabezado del NewLetters</mat-option>
                                            <mat-option value="body">Cuerpo del mensaje(imagenes principales)</mat-option>
                                            <mat-option value="mini">Cuerpo del mensaje dos columnas</mat-option>
                                            <mat-option value="social">Social</mat-option>
                                            <mat-option value="footer">Pie del NewLetters</mat-option>
                                        </mat-select>

                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Nivel">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Nivel</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 7%;">

                                        <mat-select name="nivel" placeholder="Nivel" style="width: 70%; border-bottom: 1px dotted #000;" ngModel>
                                            <mat-option value="1">1</mat-option>
                                            <mat-option value="2">2</mat-option>
                                            <mat-option value="3">3</mat-option>
                                            <mat-option value="4">4</mat-option>
                                        </mat-select>

                                    </td>
                                </ng-container>


                                <ng-container matColumnDef="Enlace">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Enlace</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 10%;">

                                        <input class="" matInput placeholder="Direccion" name="enlace" style="width: 90%; border-bottom: 1px dotted #000;" ngModel>

                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Mensaje">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Mensaje</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 15%;">

                                        <input matInput placeholder="Ingrese un mensaje" name="texto" style="width: 90%; border-bottom: 1px dotted #000; " ngModel>

                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Color">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Color del boton</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 5%;">

                                        <input matInput type="color" (change)="codigocolor($event)" placeholder="Ingrese un mensaje" name="color" style="width: 90%; cursor: pointer; " #input ngModel>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Colorf">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Color de fondo</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 5%;">

                                        <input matInput type="color" (change)="codigocolor($event)" placeholder="Ingrese un mensaje" name="colorf" style="width: 90%; cursor: pointer; " #input ngModel>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Texto">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong>Texto del boton</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 15%;">

                                        <input matInput class="inputespecial" placeholder="Ingrese un mensaje" name="textob" style="width: 90%; border-bottom: 1px dotted #000;" ngModel>

                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Progreso">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                                        <h5><strong class="text-center">Progreso</strong></h5>
                                    </th>
                                    <td class="text-center" mat-cell *matCellDef="let element" style="width: 10%;">
                                        <div class="progress " style="margin-left: 25%;">
                                            <div class="progress-bar " role="progressbar" [ngStyle]="{ 'width': element.progreso + '%' }"></div>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                        </div>

                        <div class="row">

                            <div class="col-md-6 col-sm-6 col-xs-6 fixed-bottom" style="margin-left: 30% ; margin-bottom: 3%;">
                                <div class="form-group">
                                    <div appNgDropFiles [archivos]="archivos" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{ 'file-over': estaSobreElemento }" class="drop-zone">
                                        <h4>Deje caer aquí los archivos</h4>
                                        <img src="assets/img/drop-images.png" width="100px">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="text-center" style="width: 15%; position: fixed; margin-left: 15% ; margin-right: 50%; left: 0%; bottom: 12%; border: 2px #000 dotted; padding: 1%;">
                                        <label for="color" class="">Color para el fondo de la plantilla</label>
                                        <input id="color" matInput type="color" (change)="codigocolor($event)" placeholder="Ingrese un mensaje" name="colorfondo" style="width: 100%; cursor: pointer; " #input ngModel>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>