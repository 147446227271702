<div class="container-fluid">
    <form [formGroup]="forma">
        <div class="row mat-elevation-z8">
            <div class="col-md-12" style="margin: 0px; padding: 0px;">
                <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style="background-color:steelblue; padding: 5px; margin: 0px;">

                    <div class="collapse navbar-collapse " id="navbarSupportedContent" style="padding: 0px; width: 100%; margin: 0px;">

                        <ul class="navbar-nav mr-auto " style="padding: 0px; margin: 0px; width: 100%;">
                            <li class="nav-item" style="padding: 0px; margin: 0px; width: 100%;">
                                <div class=" form-inline my-2 my-lg-0" style="padding: 0px; margin: 0px; width: 100%;">

                                    <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px; width: 25%; height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; width: 100%;">

                                            <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">

                                                <input type="text" matInput placeholder="Codigo del producto" formControlName="cod_producto" [matAutocomplete]="autoGroup2" #cod_producto>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>shop_two</mat-icon>
                                                </span>
                                                <mat-autocomplete #autoGroup2="matAutocomplete">
                                                    <mat-optgroup *ngFor="let group of arry | async" [label]="group.letter">
                                                        <mat-option *ngFor="let i of group.code" [value]="i">
                                                            {{i}}
                                                        </mat-option>
                                                    </mat-optgroup>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 15%; height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; width: 100%;">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label>Cantidad</mat-label>
                                                <input maxlength="8" type="text" matInput placeholder="Cantidad" formControlName="cant_producto" #cant_producto autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">
                                                    <mat-icon>#</mat-icon>
                                            </span>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 42.5%; height: 50px; background-color:#cccccccc;">

                                        <div class=" form-group mat-elevation-z8" style="margin: 0px 0px 15px 0px; padding: 0px 5px 0px 5px; width: 100%; height: 40px;">
                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px 0px 0px 0px;">
                                                <mat-label>Buscar</mat-label>
                                                <input #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          <mat-icon>search</mat-icon>
                                          </span>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 5%; height: 50px; ">
                                        <div class=" form-group mat-elevation-z8 " style="margin: 0px 0px 0px 0px; padding: 0% 0% 15% 14%; width:100% ; height: 40px; background-color:#cccccccc;">
                                            <mat-icon class="pedidos" (click)="addElement()" style="font-size: 30px; margin: 0px;" title="Salir">add_shopping_cart</mat-icon>
                                        </div>
                                    </div>
                                    <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 5%; height: 50px; ">
                                        <div class=" form-group mat-elevation-z8 " style="margin: 0px 0px 0px 0px; padding: 0% 0% 15% 14%; width:100% ; height: 40px; background-color:#cccccccc;">
                                            <mat-icon class="pedidos" (click)="modalrespuesta()" style="font-size: 30px; margin: 0px;" title="Salir">save</mat-icon>
                                        </div>
                                    </div>
                                    <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 5%; height: 50px; ">
                                        <div class=" form-group mat-elevation-z8 " style="margin: 0px 0px 0px 0px; padding: 0% 0% 15% 14%; width:100% ; height: 40px; background-color:#000; color: #fff;">
                                            <mat-icon class="pedidos2" (click)="cerrarmodal()" style="font-size: 30px; margin: 0px;" title="Salir">exit_to_app</mat-icon>
                                        </div>

                                    </div>
                                </div>
                            </li>

                        </ul>

                    </div>

                </nav>
            </div>
        </div>

        <div class="row container-precios mat-elevation-z8 " style="margin-top: 1%;">

            <div class="container-pedidos2  table-responsive">

                <table #table mat-table [dataSource]="dataSource1" class="  text-center table-striped table-bordered table-condensed table-hover">
                    <!-- Weight Column -->
                    <!-- <ng-container matColumnDef="id_factura">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                            <h5><strong>Id</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.id_factura}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.cod_producto}} </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.des_producto}} </td>
                    </ng-container>


                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px;">{{element.cant_producto}}</td>
                    </ng-container>

                    <ng-container matColumnDef="detalle">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                            <h5><strong>Detalle</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" (click)="detalleOrdenProd(element)" class="text-center" style="padding: 5px;">
                            <section>
                                <div class="example-button-container" class="text-center">
                                    <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                  <mat-icon>image_search</mat-icon>
                                </button>
                                </div>
                            </section>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;">
                            <section>
                                <div class="example-button-container" class="text-center">
                                    <button (click)="eliminarItems(element)" mat-icon-button color="warn" aria-label="Example icon button with a delete icon">
                                  <mat-icon>remove_shopping_cart</mat-icon>
                                </button>
                                </div>
                            </section>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                </table>
            </div>
        </div>
    </form>
</div>