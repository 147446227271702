<div class="body">
    <div class="d-flex fadeIn2 animated fast" id="wrapper">

        <div class="bg-light border-right" id="sidebar-wrapper">
            <div class="sidebar-heading">
                <h2>Cobros</h2>
            </div>
            <div class="list-group list-group-flush">
                <ng-container *ngFor="let i of menu3">
                    <a (click)="cambiarrouter('entrar')" [routerLink]="[{ outlets: {'gestion_cobros': [i.dir_menu] } }]" class="list-group-item list-group-item-action bg-light" #link>{{i.des_menu}}</a>
                </ng-container>
                <a (click)="cambiarrouter('entrar')" [routerLink]="[{ outlets: {'infouser': ['infouser'] } }]" class="list-group-item list-group-item-action bg-light">Acerca de</a>
            </div>
        </div>


        <div *ngIf="mostrar === 1">
            Modulo en construcción.....
        </div>
        <!-- router menu -->
        <div id="page-content-wrapper">
            <div *ngIf="ruta =='entrar'">
                <router-outlet name="gestion_cobros"></router-outlet>
            </div>
        </div>
        <router-outlet class=""></router-outlet>
    </div>
</div>