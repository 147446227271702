<div class="col-md-12 col-sm-12 col-xs-12 my-0" style=" border-radius: 1rem; ">
    <form [formGroup]="forma" class="">
        <div class="modal-content text-white" style="width: 100%; border-radius: 1rem;">
            <div class="modal-header head_formularios" style=" border-radius: 1rem 1rem 0 0;">
                <h3 class="modal-title text-center">
                    <strong *ngIf="accion === 'crear'" class="text-center">Crear estructura</strong> 
                    <strong *ngIf="accion === 'actualizar'" class="text-center">Actualizar estructura</strong> 
                </h3>
            </div>
            <div class="modal-body">
                    <div class="row" *ngIf="accion === 'crear'">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label >Gerencia</mat-label>
                                <mat-select formControlName="gerencia" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                    <mat-option *ngFor="let i of gerencia" [value]="i">{{i.des_unidad}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label >Coordinacion</mat-label>
                                <mat-select formControlName="dpto" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                    <mat-option *ngFor="let i of dpto" [value]="i">{{i.des_unidad}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label >Cargo</mat-label>
                                <mat-select formControlName="des_cargo" autocomplete="off">
                                    <mat-option *ngFor="let i of cargo" [value]="i">{{i}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-50">
                                <mat-label>Estatus</mat-label>
                                <mat-select style="font-size: 12px; color: black;" formControlName="estatus" autocomplete="off">
                                  <mat-option  *ngFor="let i of estatus" [value]="i.cod_estatus">
                                    {{i.des_estatus}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="accion === 'actualizar'">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label >Gerencia</mat-label>
                                <mat-select formControlName="gerencia" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                    <mat-option *ngFor="let i of gerencia" [value]="i">{{i.des_unidad}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label >Coordinacion</mat-label>
                                <mat-select formControlName="dpto" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                    <mat-option *ngFor="let i of dpto" [value]="i">{{i.des_unidad}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label >Cargo</mat-label>
                                <mat-select formControlName="des_cargo" autocomplete="off">
                                    <mat-option *ngFor="let i of cargo" [value]="i">{{i}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-50">
                                <mat-label>Estatus</mat-label>
                                <mat-select style="font-size: 12px; color: black;" formControlName="estatus" autocomplete="off">
                                  <mat-option  *ngFor="let i of estatus" [value]="i.cod_estatus">
                                    {{i.des_estatus}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
            </div>
            <div class="modal-footer" style="border-radius: 0 0 1rem 1rem;">
                <div class="form-group">
                    <button mat-button class=" btn-danger" (click)="cerrarmodal()" style=" margin-right: 5px;">Cancelar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" (click)="guardarEstructura('guardar')"> Guardar</button>
                </div>
            </div>
        </div>
    </form>
</div>
