<div id="modal_carrito" class=" container-fluid" style="border-radius: 1rem ;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border-radius: 1rem 1rem 0 0; border: 2px solid #3649b6; background-color: steelblue ">
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Orden N°: </mat-label>
                        <input style="font-size: 11px; color: #fff;" #cod_orden_producto matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <!-- <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Dep ME</mat-label>
                        <mat-select #inventario_me style="font-size: 11px; color: #fff;"    formControlName="me" autocomplete="off" >
                            <mat-option  *ngFor="let item of ME" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Dep ME</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="me" [matAutocomplete]="autoGroup" #inv_me>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete #autoGroup="matAutocomplete" class="px-1">
                            <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                <mat-option style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                    <b>{{i}}</b>
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <!-- <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Dep MP</mat-label>
                        <mat-select #inventario_mp style="font-size: 11px; color: #fff;"   formControlName="mp" autocomplete="off" >
                            <mat-option *ngFor="let item of MP" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Dep MP</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="mp" [matAutocomplete]="autoGroup2" #inv_mp>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete #autoGroup2="matAutocomplete" class="px-1">
                            <mat-optgroup *ngFor="let group of stateGroupOptions2 | async" [label]="group.letter">
                                <mat-option style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                    <b>{{i}}</b>
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <!-- <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Dep MPP</mat-label>
                        <mat-select #inventario_mpp style="font-size: 11px; color: #fff;"   formControlName="mpp" autocomplete="off" >
                            <mat-option  *ngFor="let item of MPP" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Dep MPP</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="mpp" [matAutocomplete]="autoGroup3" #inv_mpp>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete #autoGroup3="matAutocomplete" class="px-1">
                            <mat-optgroup *ngFor="let group of stateGroupOptions3 | async" [label]="group.letter">
                                <mat-option style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                    <b>{{i}}</b>
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <!-- <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Dep MPP</mat-label>
                        <mat-select #inventario_mpp style="font-size: 11px; color: #fff;"   formControlName="mpp" autocomplete="off" >
                            <mat-option  *ngFor="let item of MPP" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.des_deposito}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Dep PT</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="pt" [matAutocomplete]="autoGroup4" #inv_pt>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete #autoGroup4="matAutocomplete" class="px-1">
                            <mat-optgroup *ngFor="let group of stateGroupOptions4 | async" [label]="group.letter">
                                <mat-option style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                    <b>{{i}}</b>
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="CONFIRMAR ORDEN" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                    <mat-icon >done_all</mat-icon>
                  </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                          <mat-icon >exit_to_app</mat-icon>
                      </button>
                </div>
            </div>

        </div>
        <div id="carrito_p2" class="row" style=" border: 2px solid #374fd6;">
            <div class="col-md-12 px-0  border h-100" style="background-color: steelblue;">
                <table #table mat-table [dataSource]="dataSource1" class=" table-primary  text-center table-striped table-condensed table-hover">

                    <ng-container matColumnDef="cod_formula">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                            <h5><strong>Formula</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_formula}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.des_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.cant_producto}}</td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="ME">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                            <h5><strong>Inventario ME</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="detalleOrdenProd(element,'ME')" matTooltip="INVENTARIO ME" mat-raised-button style="height: 35px; border: 2px solid #374fd6; color:rgb(255, 255, 255); background-color: steelblue;">
                                    <mat-icon >image_search</mat-icon>
                                    </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="MP">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                            <h5><strong>Inventario MP</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="detalleOrdenProd(element,'MP')" matTooltip="INVENTARIO MP" mat-raised-button style="height: 35px; border: 2px solid #374fd6; color:rgb(255, 255, 255); background-color: rgb(142, 81, 177);">
                                    <mat-icon >image_search</mat-icon>
                                    </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="MPP">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                            <h5><strong>Inventario MPP</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="detalleOrdenProd(element,'MPP')" matTooltip="INVENTARIO MPP" mat-raised-button style="height: 35px; border: 2px solid #374fd6; color:rgb(255, 255, 255); background-color: rgb(55, 134, 148);">
                                    <mat-icon >image_search</mat-icon>
                                    </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="PT">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                            <h5><strong>Inventario PT</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="detalleOrdenProd(element,'PT')" matTooltip="INVENTARIO PT" mat-raised-button style="height: 35px; border: 2px solid #374fd6; color:rgb(255, 255, 255); background-color: rgb(143, 70, 21);">
                                    <mat-icon >image_search</mat-icon>
                                    </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border-radius: 0 0 1rem 1rem; border: 2px solid #3649b6; background-color: steelblue; ">
            <div class="col-md-12 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color: #4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>search</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>