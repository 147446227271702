
<div class="container-fluid px-0 " id="modal">
    <div class="row  h-100 mx-0">
        <div class="col-md-12 h-100 mx-0 px-0" style=" border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content mx-0 h-100 py-0" style=" border: solid 2px rgb(12, 40, 49); ">
                    <div class="modal-header my-0" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-center"><strong>¿Estas seguro que desea continuar?</strong> </h3>
                    </div>
                    <div class="modal-body my-0 mx-0 py-0 px-0">
                        <div class="row my-0 mx-0 py-0 px-0">
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 table-responsive">
                                <table class="my-1 mx-0 table table-dark table-bordered table-hover table-condensed table-striped">
                                    <thead class="text-center">
                                        <th>Orden</th>
                                        <th>Codigo</th>
                                        <th>Descripción</th>
                                        <th>Cantidad</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center">
                                            <td>{{orden_prod}}</td>
                                            <td>{{cod_prod}}</td>
                                            <td>{{des_prod}}</td>
                                            <td>{{cant_orden_producto}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="row my-0 mx-0 py-0 px-0">
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 table-responsive" *ngIf="fila ==='faltante'">
                                <table class="my-1 mx-0 table table-dark table-bordered table-hover table-condensed table-striped">
                                    <thead class="text-center">
                                        <th>Total solicitado</th>
                                        <th></th>
                                        <th>Total fabricado</th>
                                        <th></th>
                                        <th>Total faltante</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center">
                                            <td>{{cant_orden_producto_mpp}}</td>
                                            <td>-</td>
                                            <td>{{cant_producto_fabricado}}</td>
                                            <td>=</td>
                                            <td>{{prod_mpp_faltante}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 table-responsive" *ngIf="fila ==='excedente'">
                                <table class="my-1 mx-0 table table-dark table-bordered table-hover table-condensed table-striped">
                                    <thead class="text-center">
                                        <th>Total solicitado</th>
                                        <th></th>
                                        <th>Total fabricado</th>
                                        <th></th>
                                        <th>Total excedente</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center">
                                            <td>{{cant_orden_producto_mpp}}</td>
                                            <td>-</td>
                                            <td>{{cant_producto_fabricado}}</td>
                                            <td>=</td>
                                            <td>{{prod_mpp_excedente}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row my-0 mx-0 py-0 px-0">
                            <div class="col-md-12 my-0 mx-0 py-0 px-1 ">
                                <p class="py-1"><strong>Ingrese por favor la razon de la diferencia en el resultado*</strong> </p>
                                <div class="form-group py-1 px-1 my-1" style="border: 2px solid #000;">
                                    <textarea maxlength="150" class="border form-control " matInput placeholder="" autocomplete="off" formControlName="causa"> </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button [disabled]="forma.invalid" mat-button class=" btn-primary" (click)="modalrespuesta()"> Enviar</button>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>