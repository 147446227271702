<div class="" id="modal" *ngIf="operacion_inicio === operacion_descargo">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 1rem;">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border-radius: 1rem;">
                    <div class="modal-header head_formularios" style="color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" *ngIf="accion === 'PT'" class=" text-center">Ajuste manual del Almacen de <strong>PRODUCTOS TERMINADOS</strong> </h3>
                        <h3 class="modal-title" *ngIf="accion === 'MP'" class=" text-center">Ajuste manual del Almacen de <strong>MATERIA PRIMA</strong> </h3>
                        <h3 class="modal-title" *ngIf="accion === 'CRNTN'" class=" text-center">Liberar desde<strong>Cuarentena de productos terminados</strong> </h3>
                        <h3 class="modal-title" *ngIf="accion === 'SPCL'" class=" text-center">Ajuste manual del Almacen del cliente <strong> {{cliente}} </strong></h3>
                        <h3 class="modal-title" *ngIf="accion === 'INV'" class=" text-center">Ajuste de Inventario (Descargos): <strong> {{inventario.des_desposito}} ( {{inventario.cod_deposito}} ) </strong></h3>
                    </div>
                        <div class="modal-body">
                            <div>
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="row py-0">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-3 d-flex justify-content-center">
                                                <span class="fw-bold">{{mensaje}} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="row py-0">
                                            <div class="col-md-8 col-sm-12 col-xs-12 py-0">
                                                <div class="form-group">
                                                    <span><strong>Producto: </strong><b class="mx-2" style="font-size: 12px;">{{data.des_producto}}</b></span>
                                                </div>
                                                <div class="form-group">
                                                    <span *ngIf="formato_cantidad" ><strong>Actual: </strong><b class="mx-2">{{data.cant_producto}}</b> Grs</span>
                                                    <span *ngIf="!formato_cantidad"><strong>Actual: </strong><b class="mx-2">{{data.cant_producto}}</b> Und</span>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-xs-12 py-0 d-flex justify-content-end">
                                                <img  [src]="inventario.cod_img | pipeServices " class="border"  style="aspect-ratio: 1/1; width:75px; object-fit: 100% 100%; border-radius: 1rem;">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <div class="form-group">
                                                    <label for="cant_prod">Cantidad</label>
                                                    <input *ngIf="formato_cantidad" id="cant_prod" maxlength="12" class="form-control text-right" placeholder="0,00" (keyup)="format($event,'cant_prod', 'tasa')" autocomplete="off" formControlName="cant_producto">
                                                    <input *ngIf="!formato_cantidad" id="cant_prod" maxlength="12" class="form-control text-right" placeholder="0" (keyup)="format($event,'cant_prod', 'base')" autocomplete="off" formControlName="cant_producto">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0">
                                                <label for="comentarios">Comentarios</label>
                                                <textarea  id="comentarios" maxlength="200" class="form-control" rows="5" formControlName="comentarios"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="modal-footer" style=" border-radius: 0 0 1rem 1rem;">
                        <div class="form-group">
                            <button mat-button class=" btn btn-danger mr-1" (click)="cerrarmodal()">Cancelar</button>
                            <button mat-button class="btn   btn-primary" [disabled]="forma.invalid" (click)="modalrespuesta()">Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="" id="modal" *ngIf="operacion_inicio === operacion_cargo">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 1rem;">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border-radius: 1rem;">
                    <div class="modal-header head_formularios" style="color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" *ngIf="accion === 'PT'" class=" text-center">Ajuste manual del Almacen de <strong>PRODUCTOS TERMINADOS</strong> </h3>
                        <h3 class="modal-title" *ngIf="accion === 'MP'" class=" text-center">Ajuste manual del Almacen de <strong>MATERIA PRIMA</strong> </h3>
                        <h3 class="modal-title" *ngIf="accion === 'CRNTN'" class=" text-center">Liberar desde<strong>Cuarentena de productos terminados</strong> </h3>
                        <h3 class="modal-title" *ngIf="accion === 'SPCL'" class=" text-center">Ajuste manual del Almacen del cliente <strong> {{cliente}} </strong></h3>
                        <h3 class="modal-title" *ngIf="accion === 'INV'" class=" text-center">Recepción (Cargos): <strong> {{inventario.des_desposito}} ( {{inventario.cod_deposito}} ) </strong></h3>
                    </div>
                        <div class="modal-body">
                            <div>
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="row py-0">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0">
                                                <div class="form-group">
                                                    <label for="img">Factura</label>
                                                    <input type="file" class="form-control-file form-control p-1" formControlName="img" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row py-0">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                                <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                        <div class="row py-0">
                                            <div class="col-md-8 col-sm-12 col-xs-12 py-0">
                                                <div class="form-group">
                                                    <span><strong>Producto: </strong><b class="mx-2" style="font-size: 12px;">{{data.des_producto}}</b></span>
                                                </div>
                                                <div class="form-group">
                                                    <span *ngIf="formato_cantidad" ><strong>Actual: </strong><b class="mx-2">{{data.cant_producto}}</b> Grs</span>
                                                    <span *ngIf="!formato_cantidad"><strong>Actual: </strong><b class="mx-2">{{data.cant_producto}}</b> Und</span>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-xs-12 py-0 d-flex justify-content-end">
                                                <img  [src]="inventario.cod_img | pipeServices " class="border"  style="aspect-ratio: 1/1; width:75px; object-fit: 100% 100%; border-radius: 1rem;">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <div class="form-group">
                                                    <label for="comentarios">Factura</label>
                                                    <input  class="form-control text-right" autocomplete="off" formControlName="cod_recepcion">
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-xs-12 py-0">
                                                <div class="form-group">
                                                    <label for="cant_prod">Cantidad</label>
                                                    <input *ngIf="formato_cantidad" id="cant_prod" maxlength="12" class="form-control text-right" placeholder="0,00" (keyup)="format($event,'cant_prod', 'tasa')" autocomplete="off" formControlName="cant_producto">
                                                    <input *ngIf="!formato_cantidad" id="cant_prod" maxlength="12" class="form-control text-right" placeholder="0" (keyup)="format($event,'cant_prod', 'base')" autocomplete="off" formControlName="cant_producto">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-xs-12 py-0">
                                                <label for="comentarios">Comentarios</label>
                                                <textarea  id="comentarios" maxlength="200" class="form-control" rows="5" formControlName="comentarios"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="modal-footer" style=" border-radius: 0 0 1rem 1rem;">
                        <div class="form-group">
                            <button mat-button class=" btn btn-danger mr-1" (click)="cerrarmodal()">Cancelar</button>
                            <button mat-button class="btn   btn-primary" [disabled]="forma.invalid" (click)="modalrespuesta()">Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>