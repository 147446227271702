<div id="login_margin" class="container ">
    <div class="row  d-flex justify-content-center h-100">
        <div class="card col-md-12 h-100 p-0">
            <div class="card-header w-100">
                <h3 class=" text-center">Recuperar contraseña</h3>
            </div>
            <div class="card-body">
                <form *ngIf="operacion === 'CORREO'" [formGroup]="forma_correo" (ngSubmit)="correoRecuperar()">
                    <div class="row pb-2">
                        <div class="col-12 d-flex justify-content-center">
                            <mat-form-field appearance="outline" class="w-100 mb-2">
                                <mat-label class="text-white">Correo</mat-label>
                                <input type="text" matInput    autocomplete="off" formControlName="des_correo">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-auto py-1">
                        <button [disabled]="forma_correo.invalid" type="submit" class="btn bg-primary form-control text-white" >Enviar</button>
                    </div>
                </form>
                <form *ngIf="operacion === 'CODIGO'" [formGroup]="forma_codigo" (ngSubmit)="confirmarCodigo()">
                    <div class="row pb-2">
                        <div class="col-12 d-flex justify-content-center">
                            <mat-form-field appearance="outline" class="w-100 mb-2">
                                <mat-label class="text-white">Codigo</mat-label>
                                <input type="text" matInput    autocomplete="off" formControlName="des_codigo">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-auto py-1">
                        <button [disabled]="forma_codigo.invalid" type="submit" class="btn bg-primary form-control text-white" >Aceptar</button>
                    </div>
                </form>
                <form *ngIf="operacion === 'PASS'" [formGroup]="forma_pass" (ngSubmit)="cambiarPass()">
                        <div class="row pb-2">
                            <div class="col-12 d-flex justify-content-center">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label class="text-white">Contraseña</mat-label>
                                    <input [type]="hide ? 'password' : 'text'" matInput    autocomplete="off" formControlName="pass_usuario">
                                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </span>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="col-12 d-flex justify-content-center">
                                <mat-form-field appearance="outline" class="w-100 mb-2">
                                    <mat-label class="text-white">Confirma tu Contraseña</mat-label>
                                    <input [type]="hide ? 'password' : 'text'" matInput    autocomplete="off" formControlName="cpass">
                                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon style="cursor: pointer;" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </span>
                                </mat-form-field>
                            </div>
                        </div>
                      <div class="col-auto py-1">
                        <button [disabled]="forma_pass.invalid" type="submit" class="btn bg-primary form-control text-white" >Enviar</button>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-12 d-flex justify-content-end">
                        <button (click)="cerrarmodal()"  class="btn text-white bg-danger mr-2" >Volver</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
