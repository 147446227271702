import { Component, ElementRef, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import { Routes, RouterModule, Router } from '@angular/router';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Component({
  selector: 'app-facturas-pendientes',
  templateUrl: './facturas-pendientes.component.html',
  styleUrls: ['./facturas-pendientes.component.css']
})
export class FacturasPendientesComponent implements OnInit {
  sesion:any;
  cod_menu:any;
  object1:any;
  menu:any=[];
  menu2:any = [];
  menu3:any = [];
  menu4:any = [];
  roulink:any;
  unidad:any;
  usuario:any;
  menuorder3:any;
  menuoreder4:any;
  mostrar:any;
  ruta:any;
  @ViewChild('link') link: ElementRef;
  constructor(
    private servicio:nkServices, 
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ){}

  ngOnInit(){
    this.sesion = this.hashkey.decrypt(this.atrb.COD_PERFIL.cod_perfil);
    this.mostrar = parseInt(localStorage.getItem('cod_valid')); 
    this.usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
     this.object1 ={cod_usuario:this.sesion, cod_menu:this.cod_menu}
    this.buscarusuariorol(this.object1);
  }
  buscarusuariorol(xobj:any){
    this.cod_menu=localStorage.getItem('cod_sub');
    this.object1.cod_menu = this.cod_menu;
    console.log(xobj);
    this.servicio.buscarUSUARIOROLsubmenuhijohttp(xobj).subscribe(
      response =>{
        console.log(response);
        for (const i in response) {
          this.menu.push(response[i].cod_menu, response[i].padre_menu);

        }
        
        const result = this.menu.reduce((acc,item)=>{
          if(!acc.includes(item)){
            acc.push(item);
          }
          return acc;
        },[])
        console.log(result.sort(),'ver arreglo');
        this.cargarmenu(result.sort());
  },
  error =>{
    console.log(<any>error.error.message);
  }
    );
  }
  cargarmenu(arrymenu:any){
    console.log(arrymenu,'al llamar al menu');
    for (const i in arrymenu) {
      console.log(arrymenu[i],'al llamar al menu despues');
      this.servicio.buscarMENUDIRhttp(arrymenu[i]).subscribe(
        response =>{
          console.log(response,'en la respuesta del menu');
          for (const i in response) {
            console.log(response[i]);
            response[i].padre_menu = parseInt(response[i].padre_menu);
            if(parseInt(response[i].nivel_padre_menu)  != 1){
              this.menu3.push(response[i]);
              console.log(this.menu3 );
            }
          }
          for (const i in this.menu3) {
            let cod_menu = localStorage.getItem('cod_menu');
            if (this.menu3[i].padre_menu === cod_menu) {
              this.menu4.push(this.menu3[i]);
            }
          }
        this.menu3.sort(function (a, b){
          return (a.ord_menu  - b.ord_menu );
      })
            console.log(this.menu3);
              console.log(this.menu4);
    }, 
    error =>{
      console.log(<any>error.error.message);
    
    }
      );
    }

    console.log(this.menu2);
  }
  cambiarrouter(ruta:any){
    this.mostrar = 2;
    console.log(this.mostrar);
    this.ruta = ruta;
    console.log(this.ruta);
  }
}
