<div class="d-flex justify-content-center align-items-center " id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
            <form  class="">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); border-radius: 1rem;">
                    <div class="modal-header" style="background-image: linear-gradient(to left top, #90b9dc, #70a3d3, #518cca, #3276bf, #045fb4); color: #fff; border-radius: 0.9rem 0.9rem 0 0;">
                        <h3 class="modal-title"><strong>Numero de cotizaciones</strong> </h3>
                    </div>
                    <div class="modal-body">
                        <p class="text-center">¿Cuántas cotizaciones va a poseer esta solicitud? </p>
                        <div style="width: 100%;">
                            <div class="row">
                            </div>
                            <br>
                            <div class="row justify-content-center ">
                                <div class="col-6">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad a devolver</mat-label>
                                            <input style="font-size: 12px;" #cant_dev maxlength="6" matInput placeholder="" autocomplete="off" formControlName="cant_dev">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                            </span>
                                    </mat-form-field> -->

                                    <div class="form-group">
                                        <div class="form-check">
                                            <input class="form-check-input " (change)="CantidadCotizacion($event)" type="radio" name="cotizacion" id="cotizacion1" value="1">
                                            <label class="form-check-label label" matTooltip="PARA PRODUCTOS CON PROVEEDORES FIJOS" for="cotizacion1">
                                              1 Cotización
                                            </label>
                                          </div>
                                          <div class="form-check">
                                            <input class="form-check-input" (change)="CantidadCotizacion($event)" type="radio" name="cotizacion" id="cotizacion3" value="3">
                                            <label class="form-check-label label" matTooltip="PARA PRODUCTOS CON PROVEEDORES POCO COMUNES"  for="cotizacion3">
                                              3 Cotizaciones
                                            </label>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                      <div class="form-group">
                        <button class="btn btn-danger rounded-pill mr-2" (click)="cerrarmodal()" >Salir</button> <!-- [disabled]="forma.invalid" -->
                        <button class="btn btn-primary rounded-pill" (click)="guardarCantidad(xobj)">Aceptar</button> <!-- [disabled]="forma.invalid" -->
                      </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


