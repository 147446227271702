
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav_componente nav py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarclientes("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="CREAR CLIENTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style= " height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","insertar")'><mat-icon >add</mat-icon></button></li>
                    <!-- <li class="nav-item"><button matTooltip="INGRESO MASIVO DE DATOS EN EXCEL" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style=" height: 35px; color:rgb(255, 255, 255); background-color: rgb(15, 131, 44);"><mat-icon >attach_file</mat-icon></button></li> -->
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>DEFINICIONES CLIENTES</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table">
        <div class="col-md-12 px-0  border h-100 table_overflow_color">
            <table  mat-table [dataSource]="dataSource" class=" table-secondary text-center table-striped  table-condensed table-hover">

                <ng-container matColumnDef="id_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Id cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.id_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Cod cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_cliente}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="dep_spcl">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Dep</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.dep_spcl}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="lista_precio_spcl">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Precio</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.lista_precio_spcl}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="mod_factur">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Facturación</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.mod_factur}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="mod_consig">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Consignación</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.mod_consig}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="tipo_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="pais_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Pais</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.pais_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="pais_estado_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Estado</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.pais_estado_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_vend_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Vendedor</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_vendedor}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="estatus_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Estatus </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="credito_dias_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>credito</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.credito_dias_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="reten_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Reten</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.reten_cliente}} </td>
                   
                </ng-container>

                <ng-container  matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Procesos</strong></h5>
                    </th>
                  
                    <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;">
                        <button class=" bg-info mr-1" matTooltip="DETALLES" mat-mini-fab (click)='detalles_cliente(element,"detalle")'>
                            <mat-icon >assignment</mat-icon>
                        </button>
                        <button class=" bg-primary"   matTooltip="EDITAR" mat-mini-fab (click)='update_insert_data(element, "actualizar")'>
                            <mat-icon >edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>