import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import { ConnectionServices } from '../../../../componentes/shared/servicios/app.connection.services';
import { FormMovimientosPartidasComponent } from '../form-movimientos-partidas/form-movimientos-partidas.component';

@Component({
  selector: 'app-form-listar-partidas-creadas',
  templateUrl: './form-listar-partidas-creadas.component.html',
  styleUrls: ['./form-listar-partidas-creadas.component.css']
})
export class FormListarPartidasCreadasComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_cent',{static:true}) cod_cent:ElementRef;
  /* @ViewChild('des_centro',{static:true}) des_centro:ElementRef; */
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  value1 = '';
  nombre:any;
  forma:FormGroup;
  stateForm: FormGroup;
  accion:any;
  dialogSpin:any;
  unidad:any;
  unidadp:any = [];
  menu:any;
  producto:any = [];
  codigo:any = [];
  lotepro:any = [];
  cant:any = false;
  lote:any = true;
  des:any;
  guard:any = false;
  email = new FormControl('', [Validators.required, Validators.email]);
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  dataSource=new MatTableDataSource<any>();
  serializedDate = new FormControl((new Date()).toISOString());
  ELEMENT_DATA:any[]=[];
    displayedColumns:string[];
     dataSource1=new MatTableDataSource<any>();
     horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
      public dialogRefp: MatDialogRef<FormListarPartidasCreadasComponent>,private conexion: ConnectionServices,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data, 'sera este');
      }

      ngOnInit(): void {
   
        this.cod_cent.nativeElement.value = this.data.cod_centro+' - '+this.data.des_unidad;
        /* this.des_centro.nativeElement.value = this.data.des_centro.toUpperCase(); */
        this.listarPartidasCreadas(null);
      }

      @ViewChild(MatPaginator) paginator: MatPaginator;

      ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
      }

      listarPartidasCreadas(xvalor){
      try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = xvalor;
        var valor = {cod_centro:this.data.cod_centro}
         this.servicio.listarPartidasCreadashttp(valor).subscribe(
           response =>{
            this.dialogSpin.close();
             console.log(response,'detallesS');
               this.ELEMENT_DATA = [];
                   for (const i in response) {

                     this.ELEMENT_DATA.push({
                       cod_centro:response[i].cod_centro,
                       cod_presupuesto: response[i].cod_presupuesto,
                       cant_presupuesto:response[i].cant_presupuesto,
                       cod_usuario:response[i].cod_usuario,
                       des_usuario:response[i].des_usuario,
                       fecha_inicio:response[i].fecha_inicio,
                       hora_inicio:response[i].hora_inicio,
                       estatus:response[i].estatus,
                     });
                   }
                   console.log(this.ELEMENT_DATA,'ELEMENT_DATA');
                    this.displayedColumns = [
                      'cod_presupuesto',
                      'cant_presupuesto',
                      'cod_usuario',
                      'des_usuario',
                      'fecha_inicio',
                      'hora_inicio',
                      'estatus',
                      'movimientos'

                    ];
                   this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
                   this.dataSource1.paginator = this.paginator;
           },
           error =>{
            this.dialogSpin.close();
             console.log(error);
             console.log(<any>error.error.message);
             this.displayedColumns = [
              'cod_presupuesto',
              'cant_presupuesto',
              'cod_usuario',
              'des_usuario',
              'fecha_inicio',
              'hora_inicio',
              'estatus',
              'movimientos'
            ];

             this.dataSource1 = new MatTableDataSource<any>();
             this.dataSource1.paginator = this.paginator;
             this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition});
           }
         );

         if(msg == 'actualizar'){
           this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition});
         }
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  movimientos_partidas(obj:any){
    var dialogRef:any;
    this.accion = 'INV';
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
      dialogRef = this.dialog.open(FormMovimientosPartidasComponent, {
        width: '100vw',
      maxWidth: '100vw',
      height:'100%',
        data: {cod_centro:obj.cod_centro,
              des_unidad: this.data.des_unidad,
              des_centro: this.ELEMENT_DATA,
              cod_cuenta_gasto:obj.cod_cuenta_gasto,
              des_cuenta_gasto:obj.des_cuenta_gasto,
              cod_presupuesto:obj.cod_presupuesto,
              objeto: obj,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
      });
  }

}



