<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark nav_componente  nav py-1">
            
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li  class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarProductos("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li  class="nav-item"><button matTooltip="CREAR NUEVO PRODUCTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","crear")'><mat-icon >add</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>DEFINICIONES PRODUCTOS</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table">
        <div class="col-md-12 px-0  border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-condensed table-hover">
                <ng-container matColumnDef="cod_formula">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Formula</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_formula}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_img">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Imagen</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> <img width="50px" height="50px" class="rounded" [src]="element.cod_img" alt=""></td>

                </ng-container>
                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Cod producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_producto}} </td>

                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Producto</strong></h5>
                    </th>

                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_producto}} </td>

                </ng-container>

                <ng-container matColumnDef="estatus_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus_producto}} </td>

                </ng-container>

                <ng-container matColumnDef="estatus_fiscal">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Estatus fiscal</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus_fiscal}} </td>

                </ng-container>

                <ng-container matColumnDef="unidad_manejo">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Unidad manejo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.unidad_manejo}} </td>

                </ng-container>

                <ng-container matColumnDef="tipo_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_producto}} </td>

                </ng-container>

                <ng-container matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Procesos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class=" bg-success mr-1"   matTooltip="HISTORIAL" mat-mini-fab (click)='historialFormulas(element)'>
                            <mat-icon >history</mat-icon>
                        </button>
                        <button class=" bg-info mr-1"   matTooltip="FORMULA" mat-mini-fab (click)='formulacion_prod_pt(element)'>
                            <mat-icon >science</mat-icon>
                        </button>
                        <button class=" bg-primary"   matTooltip="EDITAR" mat-mini-fab (click)='update_insert_data(element, "actualizar")'>
                            <mat-icon >edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
        <!-- <mat-paginator [pageSizeOptions]="[3, 5, 7]" showFirstLastButtons></mat-paginator> -->
    </div>

</div>