
<div id="modal_carrito" class=" container-fluid">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #3649b6; background-color: rgb(37, 39, 150); ">
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Formula: </mat-label>
                        <input style="font-size: 11px; color: #fff;" #cod_formula matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        #
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Codigo del producto: </mat-label>
                        <input style="font-size: 11px; color: #fff;" #cod_producto matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        #
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_2" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">

                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Cantidad del producto: </mat-label>
                        <input style="font-size: 11px; color: #fff;" #cantidad_prod matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        #
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-5 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>search</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                          <mat-icon >exit_to_app</mat-icon>
                      </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row" style=" border: 2px solid #374fd6;">
            <div class="col-md-12 px-0  border h-100" style="background-color: rgb(37, 39, 150);">
                <table #table mat-table [dataSource]="dataSource1" class=" table-primary text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td [ngClass]="element.observador" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(37, 39, 150);; color: #fff;"> {{cantidad_item()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Descrip producto</strong></h5>
                        </th>
                        <td [ngClass]="element.observador" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.des_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150);; color: #fff;">FORMULACIÓN REQUERIDA </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo_producto_mp">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong> Tipo</strong></h5>
                        </th>
                        <td [ngClass]="element.observador" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.tipo_producto_mp}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150);; color: #fff;">Tipo </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_unidad_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Formula/Uni</strong></h5>
                        </th>
                        <td [ngClass]="element.observador" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cant_unidad_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150);; color: #fff;"> Formula/Uni</td>
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Requerido</strong></h5>
                        </th>
                        <td [ngClass]="element.observador" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.cant_producto}}</td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150);; color: #fff;"> Requerido</td>
                    </ng-container>
                    <ng-container matColumnDef="unidad_medida">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Unidad de medida</strong></h5>
                        </th>
                        <td [ngClass]="element.observador" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.unidad_prod_mp}}</td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150);; color: #fff;"> Unidad de medida</td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
    </form>

</div>