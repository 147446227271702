<footer class="footer text-center" style="background-color: #000;">
    <nav class="navbar navbar-expand-md navbar-dark  nav" style="background-color: #000;">
        <div class="row col-md-12 col-sm-12 col-xs-12 ">
            <div class="col-md-4 col-sm-4 col-xs-4 text-left ">
                <div> <strong > {{licencia | uppercase}} ( {{perfil}} ) </strong></div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-4 text-center ">
                <div> {{empresa | uppercase}}&copy; {{fecha}}</div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-4  text-right" style="left: 50px;">
                <div class="">
                    <div class=""><strong > {{usuario | uppercase}} ( {{dpto}} )</strong></div>
                </div>
            </div>
        </div>
    </nav>
</footer>
