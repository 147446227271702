<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>CONSULTAR NOTAS DE DEBITO</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listar_notas_debito("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='descargar_reporte_ventas("ventas")'><mat-icon >list</mat-icon></button></li>
                    <li *ngIf="create" class="nav-item"><button matTooltip="CREAR NOTA DE DEBITO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='notasDebito("","admin")'><mat-icon >add</mat-icon></button></li>

                </ul>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table">
        <div class="col-md-12 px-0  border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_factura">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Factura</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_factura}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_nota_debito">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Nota</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_nota_debito}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_lista_precio">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Precios</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_lista_precio}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Cod cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="tipo_nota">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_nota}} </td>

                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Cod usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_usuario}} </td>

                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_usuario}} </td>

                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus}} </td>

                </ng-container>

                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.fecha_inicio}} </td>

                </ng-container>

                <ng-container matColumnDef="hora">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.hora_inicio}} </td>

                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button *ngIf="read" class="rounded-pill" matTooltip="IMPRIMIR NOTA DE DEBITO" mat-raised-button (click)="imprimir_descargar(element,'imprimir')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 86, 247);">
                            <mat-icon >print</mat-icon>
                        </button>
                        <button *ngIf="!read" class="rounded-pill" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >print</mat-icon>
                        </button>
                    </td>

                </ng-container>

                <ng-container matColumnDef="descargar">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button *ngIf="read" class="rounded-pill" matTooltip="DESCARGAR NOTA DE DEBITO" mat-raised-button (click)="en_construccion()" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(158, 0, 250);">
                            <mat-icon >get_app</mat-icon>
                        </button>
                        <button *ngIf="!read" class="rounded-pill" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >get_app</mat-icon>
                        </button>
                    </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>