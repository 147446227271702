<div class="container-fluid rounded" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <div class="row py-1">
        <form [formGroup]="forma" class="form-inline my-0 mr-2" (keydown.enter)="$event.preventDefault()">
            <div class="col-md-1 col-sm-12 col-xs-12 ">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label style="color: #fff;">Lista N°: </mat-label>
                    <input style="font-size: 11px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" readonly>
                </mat-form-field>
            </div>
            <div class="col-md-1 col-sm-12 col-xs-12 ">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label style="color: #fff;">Tasa</mat-label>
                    <input class="text-right" style="font-size: 11px; color: #fff;" #tasa_base id="tasa_base_id" (keyup)="format($event,'tasa_base_id','tasa')" maxlength="17" matInput placeholder="0,00" autocomplete="off">
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 ">
                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label style="color: #fff; font-size: 11px;">Productos</mat-label>
                    <input style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup2" #cod_producto>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>search</mat-icon>
                    </span>
                    <mat-autocomplete #autoGroup2="matAutocomplete">
                        <mat-optgroup *ngFor="let group of arry | async" [label]="group.letter">
                            <mat-option style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                {{i}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-autocomplete>
                </mat-form-field> -->
                <mat-form-field  appearance="outline" class="w-100">
                    <mat-label style="color: rgb(255, 255, 255); font-size: 12px;">Productos</mat-label>
                    <mat-select id="cod_producto"    style="font-size: 12px; color: rgb(255, 255, 255);" formControlName="cod_producto" autocomplete="off">
                        <mat-option  *ngFor="let item of producto" [value]="item.cod_producto">{{item.des_producto}}-{{item.cod_producto}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-1 col-sm-12 col-xs-12 ">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label style="color: #fff;">Precio</mat-label>
                    <input class="text-right" style="font-size: 11px; color: #fff;" placeholder="0,00" maxlength="6" id="precio_base_id" (keyup)="format($event,'precio_base_id','tasa')" type="text " matInput placeholder="" formControlName="precio_base" #precio_base autocomplete="off">
                </mat-form-field>
            </div>
            <div class="col-md-5 col-sm-12 col-xs-12 d-flex justify-content-end">
                <button (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" class="mx-1 col-md-2 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);"><mat-icon >add</mat-icon></button>
                <button (click)="cambioPrecio()" matTooltip="RECALCULAR SU LISTA" class="mr-1 col-md-2 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(19, 117, 236);"><mat-icon >iso</mat-icon></button>
                <button (click)="dowload_store()" matTooltip="DESCARGAR" class="mr-1 col-md-2 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(137, 24, 202);"><mat-icon >file_download</mat-icon></button>
                <button  matTooltip="SUBIR" class="mr-1 col-md-2 col-sm-12 col-xs-12 fileUpload_listas" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(11, 81, 116); ">
                    <mat-icon >file_upload</mat-icon>
                    <input class="upload_listas btn btn-block btn-lg btn-light" type="file"(change)="onFileChange($event)" accept=".xls,.xlsx" multiple>
                </button>
                <button (click)="modalrespuesta()" matTooltip="GUARDAR" class="col-md-2 col-sm-12 col-xs-12 mr-1" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(221, 17, 82);"><mat-icon >save</mat-icon></button>
                <button matTooltip="SALIR" (click)="cerrarmodal()" class="col-md-2 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(216, 68, 58);"><mat-icon >exit_to_app</mat-icon></button>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 d-flex justify-content-center">
                <input class="form-control" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
            </div>
        </form>
    </div>
    <div class="row modalgeneral" style=" border: 2px solid #2b69dd; background-color:#29388f;">
        <div class=" table-responsive  h-100" >
            <table #table mat-table [dataSource]="dataSource1" class="table-primary  text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_img">
                    <th mat-header-cell *matHeaderCellDef class="text-center " style="background-color:#29388f; padding: 5px; color: #fff; " >
                        <h5><strong>Imagen</strong></h5>
                    </th>
                    <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element"> <img width="50px" height="50px" class="rounded" [src]="element.cod_img" alt=""></td>
                </ng-container>

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color:#fff;"> {{element.cod_producto}} </td>
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td [ngClass]="{'resaltar_precio_asignado': element.observador} " class="text-left" mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color:#fff;"> {{element.des_producto}} </td>
                </ng-container>

                <ng-container matColumnDef="estatus_fiscal">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Estatus fiscal</strong></h5>
                    </th>
                    <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color:#fff;"> {{element.estatus_fiscal}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="precio_producto_base">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Precio base</strong></h5>
                    </th>
                    <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" class="col-md-4" style="padding: 5px; font-size: 12px; color:#fff;">
                        <div *ngIf="element.parametro === 'txt'" class="col-md-12 d-flex justify-content-center ">
                            <div class="row  w-100">
                                <div class="col-md-8 d-flex justify-content-center">
                                    <div *ngIf="element.precio_producto_base === '0,00'"  class="form-inline my-0 w-100 mx-1">
                                        <input (keyup)="format($event,element.cod_producto,'xx')"  [id]="element.cod_producto" maxlength="12" placeholder="0,00"    type="text" class="text-right form-control font-weight-bold mr-1 p-1 w-100" >
                                    </div>
                                    <div class="mr-2 my-3" *ngIf="element.precio_producto_base !== '0,00'"><b>{{element.precio_producto_base}}</b></div>
                                </div>
                                <div class="col-md-4 d-flex justify-content-end py-1">
                                    <button  class=" bg-primary mr-1"   matTooltip="ACTUALIZAR DATOS" mat-mini-fab (click)='getTxtConfig(element)'>
                                        <mat-icon >edit</mat-icon>
                                    </button>
                                    <button  class=" bg-danger"   matTooltip="ELIMINAR" mat-mini-fab (click)='remove(element)'>
                                        <mat-icon >delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="precio_producto_base_bs">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Precio Bs</strong></h5>
                    </th>
                    <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color:#fff;"> {{element.precio_producto_base_bs}} </td>
                </ng-container>

                <!-- <ng-container matColumnDef="update_elem">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Editar</strong></h5>
                    </th>
                    <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px; color:#fff;">
                          <button  class=" bg-primary"   matTooltip="CAMBIAR EL PRECIO BASE" mat-mini-fab (click)='modalmodificarprecios(element)'>
                            <mat-icon >sync</mat-icon>
                        </button>
                    </td>
                </ng-container> -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
