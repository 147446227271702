import { Component, ElementRef, OnInit } from '@angular/core';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import { Routes, RouterModule, Router } from '@angular/router';

import { Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

import { FormInventarioMpComponent } from '../../../../shared/modal/form-inventario-mp/form-inventario-mp.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';


@Component({
  selector: 'app-inventario-lt',
  templateUrl: './inventario-lt.component.html',
  styleUrls: ['./inventario-lt.component.css']
})

export class InventarioLtComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  sesion:any;
  cod_menu:any;
  object1:any;
  menu:any=[];
  menu2:any = [];
  dialogSpin:any;
  menu3:any = [];
  menu4:any = [];
  roulink:any;
  unidad:any;
  usuario:any;
  menuorder3:any;
  menuoreder4:any;
  mostrar:any;
  ruta:any;
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:any[];
  dataSource=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('link') link: ElementRef;
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router){
    
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.mostrar = parseInt(localStorage.getItem('cod_valid'));
    this.sesion = localStorage.getItem('cod_usuario');
    console.log(typeof this.mostrar);
    this.usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
    this.object1 ={cod_usuario:this.sesion, cod_menu:this.cod_menu}
    this.listarInventarioLT(null);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  listarInventarioLT(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        this.servicio.listarInventarioLThttp().subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              this.xobj5 = [];
              console.log(response,'una respuesta');
              for (const i in response.productos) {
                this.xobj5.push(response.productos[i]);
              }
            
              this.ELEMENT_DATA = [];
                  for (const i in response.lotes) {
    
                    this.ELEMENT_DATA.push({
                        inv_lote:response.lotes[i].inv_lote,
                        tipo_lote:response.lotes[i].tipo_lote,
                        cod_orden_producto:response.lotes[i].cod_orden_producto,
                        cant_orden:response.lotes[i].cant_orden,
                        um_prod:response.lotes[i].um_prod,
                        cod_producto: response.lotes[i].cod_producto,
                        des_producto: response.lotes[i].des_producto,
                        cant_producto: response.lotes[i].cant_producto,
                        cant_blts:response.lotes[i].cant_blts,
                        cant_prod_liberado: response.lotes[i].cant_prod_liberado,
                        cod_lote:response.lotes[i].cod_lote,
                        estatus:response.lotes[i].estatus,
                        fecha_inicio:response.lotes[i].fecha_inicio,
                        hora_inicio:response.lotes[i].hora_inicio,
                        fecha_fin:response.lotes[i].fecha_fin,
                        hora_fin:response.lotes[i].hora_fin
    
                    });
                  }
                  this.displayedColumns = [
                                            'cod_orden_producto',
                                            'cant_orden',
                                            'cod_lote',
                                            'tipo_lote',
                                            'cod_producto',
                                            'um_prod',
                                            
                                            'cant_producto',
                                            'cant_blts',
                                            'cant_prod_liberado',
                                            'estatus',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'fecha_fin',
                                            'hora_fin',
                                            'ajuste_lote',
                                            'um_und'
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
    
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log(<any>error.error.message);
                            this.displayedColumns = [
                              'cod_orden_producto',
                              'cant_orden',
                              'cod_lote',
                              'tipo_lote',
                              'cod_producto',
                              'um_prod',
                              
                              'cant_producto',
                              'cant_blts',
                              'cant_prod_liberado',
                              'estatus',
                              'fecha_inicio',
                              'hora_inicio',
                              'fecha_fin',
                              'hora_fin',
                              'ajuste_lote',
                              'um_und'
                            ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
          );
    
          if(msg == 'actualizar'){
            this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    
  }
   ///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////

   update_insert_data(text:any,operacion:any,objeto:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (operacion.toUpperCase() === 'CREAR') {
                          if (response.resul) {
                            var dialogRef;
       
                                this.accion = text;
                                dialogRef = this.dialog.open(FormKeyComponent, {
                                  width: '100vw',
                                  maxWidth: '100vw',
                                  height:'100vh',
                                  data: {accion:text,
                                        productos:this.xobj5
                                        },
                                  disableClose: true,
                                  panelClass: 'pedidos_modal',
                                  backdropClass: "bdrop"
                                });
                              
                              dialogRef.afterClosed().subscribe(result => {
                                if(result !== 'cerrar'){
                                  result.operacion = operacion.toUpperCase();
                                  this.gestionlotes(result);
                                    
                                }
                              });  
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        }

                        if (operacion.toUpperCase() === 'AJUSTAR') {
                          if (response.resul) {
                            var dialogRef;
       
                                this.accion = text;
                                dialogRef = this.dialog.open(FormKeyComponent, {
                                  width: '100vw',
                                  maxWidth: '100vw',
                                  height:'100vh',
                                  data: {accion:'LTS_A',
                                        productos:this.xobj5
                                        },
                                  disableClose: true,
                                  panelClass: 'pedidos_modal',
                                  backdropClass: "bdrop"
                                });
                              
                              dialogRef.afterClosed().subscribe(result => {
                                if(result !== 'cerrar'){
 
                                  result.operacion = operacion.toUpperCase();
                                  result.cod_lote = objeto.cod_lote;
                                  result.cod_producto = objeto.cod_producto;
                                  result.des_producto = objeto.des_producto;
                                  result.um_prod = objeto.um_prod;
                                  result.tipo_lote = objeto.tipo_lote;
                                  result.cant_blts = objeto.cant_blts;
                                  result.cant_producto = objeto.cant_producto;
                                  result.cod_usuario = localStorage.getItem('cod_usuario');
                                  result.des_usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
                                  this.gestionlotes(result);
                                    
                                }
                              });  
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        }
                        
                        if (operacion.toUpperCase() === 'CONSOLIDAR') {
                          if (response.resul) {
                            var dialogRef;
       
                                this.accion = text;
                                dialogRef = this.dialog.open(FormmMsgComponent, {
                                  width: '100vw',
                                  maxWidth: '100vw',
                                  height:'100vh',
                                  data: {accion:false
                                        },
                                  disableClose: true,
                                  panelClass: 'pedidos_modal',
                                  backdropClass: "bdrop"
                                });
                              
                              dialogRef.afterClosed().subscribe(result => {
                                if(result !== 'cerrar'){
                                  objeto.operacion = operacion.toUpperCase();
                                  objeto.cod_usuario = localStorage.getItem('cod_usuario');
                                  objeto.des_usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
                                  console.log(objeto)
                                  this.gestionlotes(objeto);
                                    
                                }
                              });  
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        }
                        
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  gestionlotes(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
    
      setTimeout(() => {
        var resultado = this.servicio.gestionLoteshttp(xvalor).subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              this.listarInventarioLT(null);
              this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.dialogSpin.close();
           
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        return resultado;
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.listarInventarioLT(null);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
sin_permiso():void{
  try {
    this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
} 


}
