<div class="fadeIn2 animated fast" style="height: 100%; width: 100%; border-radius: 1rem;">
    
    <div class="row " style="height: 8%; border-radius: 1rem 1rem 0 0;">
        <nav  class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px; border-radius: 1rem 1rem 0 0;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>ORDENES DE PRODUCCIÓN HISTORIAL</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarordenesprodHistorial("actualizar")'><mat-icon >sync</mat-icon></button></li>
                </ul>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
                <div class=" ">
                    <button matTooltip="SALIR" class="my-2 my-sm-0 mr-sm-1 ml-sm-2" mat-raised-button  style="height: 35px; background-color: rgb(175, 45, 45); color: #fff;" (click)='cerrarmodal()'><mat-icon >exit_to_app</mat-icon></button>
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.7%; border-radius: 0 0 0 1rem;">
        <div class="col-md-12 px-0  h-100" style="background-color: steelblue; overflow-y: auto; border-radius: 0 0 0 1rem;">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-condensed table-hover" style="border-radius: 0 0 0 1rem;">

                <ng-container matColumnDef="cod_formula">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Formula</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_formula}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_orden_prod_mpp">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Orden MPP</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_orden_prod_mpp}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_orden_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Orden PT</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_orden_producto}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_lote">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Lote</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_lote}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_producto}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_producto}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cant inicial</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_producto}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cant_final">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cant fin</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_final}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Usuario inicio</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_usuario_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Usuario cierre</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario_fin}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus_tarea}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="tiempo">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Tiempo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"
                        class="text-center" >
                        <button  matTooltip="TIEMPO" mat-raised-button (click)='mostrar_tiempo(element,"tiempo_ordenes")' style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(22, 175, 80);">
                            <mat-icon>alarm</mat-icon>
                        </button>
                       
                    </td>
                </ng-container>

                <ng-container matColumnDef="Imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button  matTooltip="IMPRIMIR ORDEN DE PRODUCCIÓN" mat-raised-button (click)="imprimir_descargar(element,'imprimir')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 86, 247);">
                            <mat-icon >print</mat-icon>
                        </button>
                       
                    </td>
                   
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons style="border-radius: 0 0 0 1rem;"></mat-paginator>
        </div>
    </div>
    </div>
