import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-cant-dev-prod-re',
  templateUrl: './form-cant-dev-prod-re.component.html',
  styleUrls: ['./form-cant-dev-prod-re.component.css']
})
export class FormCantDevProdReComponent implements OnInit {

  @ViewChild('cant_dev',{static:true}) cant_dev:ElementRef;
  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  forma:FormGroup;
  producto:any = [];
  codigo:any = [];
  tipo_dev:any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCantDevProdReComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log(data, 'primero');
      if (data.tipo_devolucion === 'DVLCN') {
        this.tipo_dev = true;
      }else{
        this.tipo_dev = false;
      }
      
      this.producto = data.des_producto;
      this.codigo = data.cod_producto;

      this.forma = fb.group({
        'cant_producto':data.cant_producto,
        'cant_dev':'',
        'cod_producto':data.cod_producto,
        'des_producto':data.des_producto,
        'cod_documento':data.cod_documento,
        'lote_1':'N/A',
        'valor_1':'N/A',
        'lote_2':'N/A',
        'valor_2':'N/A',
        'lote_3':'N/A',
        'valor_3':'N/A',

      });
    }

  ngOnInit(): void {
  }

  devolucion_prod(xvalor:any){
    try {
        if (!isNaN(this.cant_dev.nativeElement.value)) {
          if (parseFloat(this.cant_dev.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) <= parseFloat(this.cant_producto.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) && parseFloat(this.cant_dev.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) > 0  ) {
            this.forma.value.cant_dev = this.cant_dev.nativeElement.value;
                    console.log(this.forma.value,'objeto valores');
                   this.dialogRefp.close(this.forma.value);
          }else{
            this.snackBar.open('La cantidad debe ser menor ó igual a la cantidad del producto facturado y mayor a 0 ', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }else{
          this.snackBar.open('Use solo numeros por favor.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
