import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../servicios/excel.service';



@Component({
  selector: 'app-form-orden-req',
  templateUrl: './form-orden-req.component.html',
  styleUrls: ['./form-orden-req.component.css']
})
export class FormOrdenReqComponent implements OnInit {

  @ViewChild('inputCantidad',{static:true}) inputCantidad:ElementRef;
  
  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  listaProductos:any[]=[];
  tipoProducto:string;
  AlertMensaje:string;
  filteredOptions: Observable<any[]>;
  options:any;
  UM:any = ''

  dataSource=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormOrdenReqComponent>,private excel:ExcelService,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any){

      console.log(data)

      this.tipoProducto = data.tipo_producto

      this.forma = fb.group({
        'des_producto':'',
        'cantidad':'',
      });



    }

  ngOnInit(): void {

    this.servicio.listarProductosXTipohttp(this.tipoProducto).subscribe(response => {

      let arryFiltrado = response.filter(element => element.tipo_producto == this.tipoProducto)

      console.log(arryFiltrado)

      this.options = arryFiltrado

      this.mostrarTabla()

      console.log( this.forma,'op2')

      this.filteredOptions = this.forma.controls.des_producto.valueChanges.pipe(
        startWith(''),
        map(state => (state ? this._filter(state) : this.options.slice())));
      console.log(this.filteredOptions,'456' )

    })   

  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();  
    return this.options.filter(option => option.des_producto.toLowerCase().includes(filterValue) || option.cod_producto.toLowerCase().includes(filterValue));
  }

    mostrarTabla(){

      this.displayedColumns = [
                                'cod_producto',
                                'des_producto',
                                'cantidad',
                                'tipo_unidad_medida',
                                'eliminar'
                              ];
      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    }

  captar(ev:any){

    let elobjeto = this.options.find(element => element.des_producto.toLowerCase() == ev.target.value.toLowerCase())

    if(elobjeto){
      this.UM = elobjeto.unidad_medida_producto
    }else{
      this.UM = ''
    }

  }
  captarlo(ev:any){

    let elobjeto = this.options.find(element => element.des_producto == ev.source.value)

    if(elobjeto){
      this.UM = elobjeto.unidad_medida_producto
    }else{
      this.UM = ''
    }
    let inputCantidad = document.querySelector('#inputCantidad') as HTMLInputElement
    inputCantidad.value = ''

  }


  add(){

    let validacionProd = this.ELEMENT_DATA.find(element => element.des_producto == this.forma.value.des_producto)
    let elobjeto = this.options.find(element => element.des_producto == this.forma.value.des_producto)
    let inputCantidad = document.querySelector('#inputCantidad') as HTMLInputElement

    console.log(inputCantidad.value)

    if(!validacionProd){

      if(!(this.forma.value.des_producto == '') && !(this.forma.value.cantidad == '') && !(inputCantidad.value == '')){

        if(elobjeto){

          let producto = {
            cod_producto:elobjeto.cod_producto,
            des_producto:elobjeto.des_producto,
            cantidad: inputCantidad.value,
            cantidad_2: inputCantidad.value,
            tipo_unidad_medida:this.UM,
            tipo_producto:elobjeto.tipo_producto
          }
          this.ELEMENT_DATA.unshift(producto)
          console.log(this.ELEMENT_DATA)
      
          this.displayedColumns = [
            'cod_producto',
            'des_producto',
            'cantidad',
            'tipo_unidad_medida',
            'eliminar'
          ];
      
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource.paginator = this.paginator;
      
          console.log(elobjeto)
      
          this.forma.value.des_producto = ''
          this.forma.value.cantidad = ''
      
          let inputP = document.querySelector('.inputP') as HTMLInputElement
          let inputC = document.querySelector('.inputC') as HTMLInputElement
      
          inputC.value = ''
          inputP.value = ''

          this.filteredOptions = this.forma.controls.des_producto.valueChanges.pipe(
            startWith(''),
            map(state => (state ? this._filter(state) : this.options.slice())));
          console.log(this.filteredOptions,'456' )

        }else{

          ///////////////////////////ALERTA CODE////////////////////////////
          let alerta = document.querySelector('.alert') as HTMLDivElement
        
          this.AlertMensaje = 'Ese producto no existe'
            
          alerta.classList.add('show')
        
          setTimeout(() => {
        
            alerta.classList.remove('show')
        
          }, 1500);
    
          ///////////////////////////ALERTA CODE////////////////////////////

        }
      
    
      }else{
      ///////////////////////////ALERTA CODE////////////////////////////
      let alerta = document.querySelector('.alert-warning') as HTMLDivElement
    
      this.AlertMensaje = 'Producto o Cantidad estan vacios'
        
      alerta.classList.add('show')
    
      setTimeout(() => {
    
        alerta.classList.remove('show')
    
      }, 1500);
    
      ///////////////////////////ALERTA CODE////////////////////////////
        
      }

    }else{
      ///////////////////////////ALERTA CODE////////////////////////////
      let alerta = document.querySelector('.alert-warning') as HTMLDivElement
    
      this.AlertMensaje = 'Ya existe este producto'
        
      alerta.classList.add('show')
    
      setTimeout(() => {

        alerta.classList.remove('show')
    
      }, 1500);
    
      ///////////////////////////ALERTA CODE////////////////////////////
        
      }

  }

  Delete(Codigo){

    this.ELEMENT_DATA = this.ELEMENT_DATA.filter(element => element.cod_producto != Codigo)

    this.displayedColumns = [
      'cod_producto',
      'des_producto',
      'cantidad',
      'tipo_unidad_medida',
      'eliminar'
    ];

    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;

  }

  send(){

    this.data.productos = this.ELEMENT_DATA

    if(this.ELEMENT_DATA.length){
      console.log(this.data)

      var dialogRefx = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
                maxWidth: '100vw',
                height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
  
      dialogRefx.afterClosed().subscribe(result=> {

        if(result != 'cerrar'){
          
          this.dialogRefp.close(this.data)


        }

      })

    }else{

       ///////////////////////////ALERTA CODE////////////////////////////
       let alerta = document.querySelector('.alert-warning') as HTMLDivElement
    
       this.AlertMensaje = 'No has elegido productos'
         
       alerta.classList.add('show')
     
       setTimeout(() => {
 
         alerta.classList.remove('show')
     
       }, 1500);
     
       ///////////////////////////ALERTA CODE////////////////////////////

    }


  }

  ponerPuntosyComas(event:any){

    let validacion = '1234567890'
    console.log(event.key)

    let inputCantidad = document.querySelector('#inputCantidad') as HTMLInputElement
    
    if(inputCantidad.value.length == 1){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
      
    }else if(inputCantidad.value.length <= 2){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length >= 4){
      let noa:any = inputCantidad.value.split('')
      for(let i = 0 ; i < 2; i++){
        if(noa[0] == 0){
          noa.shift()
        }else{
          break;
        }
      }
      inputCantidad.value = noa.join('')
    }

    var num = inputCantidad.value.replace(/\D/g,'');

    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    num = num.split('').reverse().join('').replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2')
    inputCantidad.value = num;

    if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){

      let alerta = document.querySelector('.alert-warning') as HTMLDivElement
      
      console.log(alerta)

      this.AlertMensaje = 'Solo numeros'
        
      alerta.classList.add('show')
    
      setTimeout(() => {

        alerta.classList.remove('show')
    
      }, 1500);
    
    }


  }

  onClickInput(){
    let input = document.querySelector('#import') as HTMLInputElement
      console.log(input)
      input.click()

  }

  onFileChange(evt:any){

    try {
      
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        const target : DataTransfer = <DataTransfer>(evt.target);

        if(target.files.length !== 1) throw new Error('No se pueden seleccionar multiples archivos');
    
        const reader : FileReader = new FileReader();
    
        reader.onload = (e: any) => {
         const bstr : string = e.target.result;
    
         const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'})
    
         const wsname : string = wb.SheetNames[0];
    
         const ws : XLSX.WorkSheet = wb.Sheets[wsname];
         let data2 = [];
            data2 = (XLSX.utils.sheet_to_json(ws));

            console.log(data2)
          this.cargaDProductos(data2);
        };
    
        reader.readAsBinaryString(target.files[0]);

      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
    }
    
  }

  cargaDProductos(elemento){

    for (const prod of elemento) {

      let elobjeto = this.options.find(element => element.cod_producto == prod.cod_producto)
      
      if(elobjeto){
        
        let cantidad = Number(prod.cantidad).toFixed(2).replace('.',',')

        let producto = {
          cod_producto:elobjeto.cod_producto,
          des_producto:elobjeto.des_producto,
          cantidad,
          tipo_unidad_medida:elobjeto.unidad_medida_producto,
          tipo_producto:elobjeto.tipo_producto
        }
        this.ELEMENT_DATA.unshift(producto)
        
      }else{

        ///////////////////////////ALERTA CODE////////////////////////////
        let alerta = document.querySelector('.alert') as HTMLDivElement
      
        this.AlertMensaje = 'Ese producto no existe'
          
        alerta.classList.add('show')
      
        setTimeout(() => {
      
          alerta.classList.remove('show')
      
        }, 1500);
  
        ///////////////////////////ALERTA CODE////////////////////////////

        break

      }
  
      
    }

    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);


  }

  filter_date_print(){

    try {

      let obj = {
        cod_producto:'',
        cantidad:'',
      }
        
     this.excel.excelt_import_masivo(obj, 'Import SKU')
        
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }



  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
              maxWidth: '100vw',
              height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.dialogRefp.close('cerrar');
      }

    });

  }

  

}
