import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormFormulaUnidadComponent } from '../form-formula-unidad/form-formula-unidad.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-consulta-ordenes',
  templateUrl: './form-consulta-ordenes.component.html',
  styleUrls: ['./form-consulta-ordenes.component.css']
})
export class FormConsultaOrdenesComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('tasa_base',{static:true}) tasa_base:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef

  arry: Observable<StateGroup[]>;
  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
bandera:any;
accion:any;
unidad:any;
dialogSpin:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormConsultaOrdenesComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'cant_producto':'',
        'ord_producto':'',
        'cod_orden_producto':''

      });
    }

  ngOnInit(): void {
    this.cod_orden_producto.nativeElement.value = this.data.cod_orden_producto;
    this.listarProductoOrdenesProd(this.data.cod_orden_producto);
    this.listarproducto();

  }
  ngAfterViewInit() {

  }
  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }

 listarproducto(){
  this.codigo = [];
  this.producto = [];

  this.servicio.listarProductoTermhttp().subscribe(
    response =>{

      this.arry = this.forma.get('cod_producto')!.valueChanges
                              .pipe(
                                startWith(''),
                                map(value => this._filterGroup(value))
                              );
                for (const i in response) {
                  this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                  this.codigo.push(response[i].cod_producto);
                }

                this.stateGroups= [{
                  letter: 'Productos',
                  names: this.producto,
                  code: this.producto
                }];

                console.log(this.stateGroups);
    },
    error =>{
      console.log(<any>error.error.message);
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  );
}


  listarProductoOrdenesProd(xvalor:any){
     var valor = {cod_orden_producto:xvalor}
    this.servicio.buscarProductoOrdenesProdhttp(valor).subscribe(
      response =>{
        console.log(response);
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                  cod_formula:response[i].cod_formula,
                  cod_producto:response[i].cod_producto,
                  des_producto:response[i].des_producto,
                  cant_producto:response[i].cant_producto

                });
              }
              if (this.ELEMENT_DATA[0].cod_formula === undefined || this.ELEMENT_DATA[0].cod_formula === '' || this.ELEMENT_DATA[0].cod_formula === null) {
                  this.ELEMENT_DATA[0].cod_formula = 'SNFRML';
              }
              this.displayedColumns = [
                                        'cod_formula',
                                        'cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'detalle',
                                        'remov_elem'
                                      ];
              this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);

      },
      error =>{
        console.log(error);
        console.log(<any>error.error.message);
        this.displayedColumns = [
                                'cod_formula',
                                'cod_producto',
                                'des_producto',
                                'cant_producto',
                                'detalle',
                                'remov_elem'
                              ];
        this.dataSource1 = new MatTableDataSource<any>();
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }

  formulario():void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    this.displayedColumns = [
      'cod_formula',
      'cod_producto',
      'des_producto',
      'cant_producto',
      'detalle',
      'remov_elem'
    ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  addElement() {
    let arry = [];
    let arry2 = [];
    this.forma.value.cant_producto = this.cant_producto.nativeElement.value;

    console.log(this.forma);

    if (parseInt(this.forma.value.cant_producto) > 0 && this.cod_producto.nativeElement.value != '') {
      var cod = this.forma.value.cod_producto.split(' ');
    var valor:any = {cod_producto:cod[0]}
    this.servicio.buscarPRODUCTOBttp(valor).subscribe(
      response =>{
        console.log(response);

        if (this.ELEMENT_DATA.length == 0) {
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];

          this.listarproducto();
          this.ELEMENT_DATA.push({
            cod_formula:response[0].cod_formula,
            cod_producto:response[0].cod_producto,
            des_producto:response[0].des_producto,
            cant_producto:parseInt(this.forma.value.cant_producto).toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2'),
          });

          if (this.ELEMENT_DATA[0].cod_formula === undefined || this.ELEMENT_DATA[0].cod_formula === '' || this.ELEMENT_DATA[0].cod_formula === null) {
            this.ELEMENT_DATA[0].cod_formula = 'SNFRML';
        }

          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
          this.cod_producto.nativeElement.value = '';
          this.cant_producto.nativeElement.value = '';

        }else{

          console.log(this.ELEMENT_DATA);
            for (const i in this.ELEMENT_DATA) {
             arry.push(this.ELEMENT_DATA[i].cod_producto);
            }
            var index = arry.includes(response[0].cod_producto);

            if (!index) {
              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];
              this.listarproducto();

              this.cod_producto.nativeElement.value = '';
              this.cant_producto.nativeElement.value = '';;


                this.snackBar.open('Solo puede asignar un producto en la orden de produccion', 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});

            }else{

              if (index) {
                this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
                  this.cod_producto.nativeElement.value = '';
                  this.cant_producto.nativeElement.value = '';
              }

              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];
              this.listarproducto();


            }
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.cod_producto.nativeElement.value = '';
              this.cant_producto.nativeElement.value = '';
      }
    );
    }else{
        if (parseInt(this.cant_producto.nativeElement.value) <= 0 || isNaN(this.cant_producto.nativeElement.value) || this.cant_producto.nativeElement.value === "") {
          this.snackBar.open('La cantidad debe ser mayor a 0 y solo puede ingresar numeros', 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.cod_producto.nativeElement.value  === "") {
          this.snackBar.open('Debes seleccionar al menos un producto', 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    }
  }

modalrespuesta(){
  try {
    if (this.data.cod_formula != 'SNFRML' &&  this.ELEMENT_DATA[0].cod_formula === 'SNFRML') {
      this.snackBar.open('La orden "'+this.data.cod_orden_producto+'" ya tiene un producto formulado, no puede actualizarla por un producto sin formula', 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }else{
      if (this.ELEMENT_DATA.length > 0 ) {
        this.accion = "enviarordenprod";
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
            maxWidth: '100vw',
            height:'100%',
          data: {id:'',
                accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
            this.forma.value.cod_orden_producto = this.cod_orden_producto.nativeElement.value;
            this.forma.value.ord_producto = this.ELEMENT_DATA;
            console.log(this.forma.value);
            this.dialogRefp.close(this.forma.value);
    
          }
        });
      }else{
        if (this.ELEMENT_DATA.length < 0) {
          this.snackBar.open('Debes seleccionar al menos un producto', 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        } 
        if (this.ELEMENT_DATA[0].cod_formula != '') {
          this.snackBar.open('Debe revisar la formula de este producto', 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      }
    }
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:7000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

eliminarItems(val:any){
  this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
   this.table.renderRows();
 }

cerrarmodal(): void {
  this.accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100%',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
}
cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 2000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  async detalleOrdenProd(xvalor:any){
    console.log(xvalor);
    xvalor.cod_orden_producto = this.data.cod_orden_producto;
    this.dialog.open(FormFormulaUnidadComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {producto: xvalor},
      panelClass: 'pedidos_modal3',
      backdropClass: "bdrop"
    });
}
format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
