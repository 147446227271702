import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { FormNotaCreditoComponent } from 'src/app/componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormNotaCreditoAdminComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import * as moment from 'moment';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { Workbook } from 'exceljs';
import { FormReportesVentasNotasComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas-notas/form-reportes-ventas-notas.component';
import * as fs from 'file-saver';
import { FormNotacreditoNotasComponent } from 'src/app/componentes/shared/modal/form-notacredito-notas/form-notacredito-notas.component';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import axios from 'axios';

interface interfacefactura{
  lote_producto: string;
  cod_producto:string;
  des_producto:string;
  cant_producto:string;
  costo_producto:string;
  subtotal_producto:string;
}
interface interfacefactura2{
  des_concepto: string;
  subtotal_concepto:string;

}
interface interdatosfacefactura{
cod_factura:string;
des_cliente: string;
cod_cliente:string;
des_vendedor:string;
fecha_inicio:string;
fecha_v_factura:string;
cod_pedido:string;
telefono1_cliente:string;
telefono2_cliente:string;
}
type TableRow = [string, string, string, string, string, string];

type TableRow2 = [string, string];
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-consultar-notas-entrega',
  templateUrl: './consultar-notas-entrega.component.html',
  styleUrls: ['./consultar-notas-entrega.component.css']
})
export class ConsultarNotasEntregaComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  fecha1:any;
  fecha2:any;

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices,
              private dialog: MatDialog,
              private pdf : PdfServices,
              private snackBar: MatSnackBar,
              private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );

  }
  ngOnInit(): void {
    this.listar_notas_entrega(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }
  /////////////////////////////////////////////////////////////////////
  listar_notas_entrega(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        this.servicio.listarNotasEntregahttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.respuesta) {
  
                    this.ELEMENT_DATA.push({
                      cod_pedido:response.respuesta[i].cod_pedido,
                      cod_nota: response.respuesta[i].cod_nota,
                      cod_lista_precio:response.respuesta[i].cod_lista_precio,
                      cod_cliente:response.respuesta[i].cod_cliente,
                      des_cliente:response.respuesta[i].des_cliente,
                      fecha_inicio:response.respuesta[i].fecha_inicio,
                      hora_inicio:response.respuesta[i].hora_inicio,
                      fecha_recep:response.respuesta[i].fecha_recep,
                      hora_recep:response.respuesta[i].hora_recep,
                      fecha_venc:response.respuesta[i].fecha_venc,
                      hora_venc:response.respuesta[i].hora_venc,
                      estatus_nota:response.respuesta[i].estatus_nota,
                      tipo_nota:response.respuesta[i].tipo_nota,
                      credito_dias_clientes: response.respuesta[i].credito_dias_clientes,
                      monto_base: response.respuesta[i].monto_base,
                      monto_cobrado:response.respuesta[i].monto_cobrado,
                      monto_pendiente:response.respuesta[i].monto_pendiente,
                      remanente:response.respuesta[i].remanente,
                      accion:'',
                      accion2:'',
                      accion3:'',
                      accion4:''
                    });
                  }
                  this.displayedColumns = [
                                          'cod_pedido',
                                          'cod_nota',
                                          'cod_lista_precio',
                                          'cod_cliente',
                                          'des_cliente',
                                          'estatus_nota',
                                          'tipo_nota',
                                          'nota_dev',
                                          'nota_admin',
                                          'detalle',
                                        'fechas',
                                        'imprimir',
                                        'anular',
                                        ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                      'cod_pedido',
                                      'cod_nota',
                                      'cod_lista_precio',
                                      'cod_cliente',
                                      'des_cliente',
                                      'estatus_nota',
                                      'tipo_nota',
                                      'nota_dev',
                                      'nota_admin',
                                      'detalle',
                                    'fechas',
                                    'imprimir',
                                    'anular',
                                    ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }
  notas_credito_admin_single(obj:any,xvalor:any){

    try {
     
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                      cod_menu: localStorage.getItem('sub_main'),
                      cod_sesion: localStorage.getItem('cod_sesion'),
                      dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                            var dialogRef;
                            switch (xvalor) {
                        
                              case 'dev':
                                dialogRef =  this.dialog.open(FormNotacreditoNotasComponent, {
                                  width: '100vw',
                                  maxWidth: '100vw',
                                  height:'100vh',
                                  data: {cod_factura: obj.cod_factura,
                                          cod_pedido: obj.cod_pedido,
                                          cod_lista_precio:obj.cod_lista_precio,
                                          cod_cliente:obj.cod_cliente,
                                          des_cliente:obj.des_cliente,
                                          iva_pedido:obj.iva_pedido,
                                          iva_monto:obj.iva_monto,
                                          desc_pedido:obj.desc_pedido,
                                          tipo_nota:xvalor,
                                          subtotal_costo_pedido:obj.subtotal_costo_pedido,
                                          total_costo_pedido:obj.total_costo_pedido,
                                          termini:'pedido'},
                                          disableClose: true,
                                  panelClass: 'pedidos_modal3',
                                  backdropClass: "bdrop"
                                });
                                  break;
                              default:
                                break;
                            }
                      
                          dialogRef.afterClosed().subscribe(result => {
                      
                            if(result != 'cerrar' && result != 'salir'){
                      
                               
                            }
                          });
                           
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
  
      
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  consultarDev(xvalor:any, text:any){
    try {

      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            
            var dialogRef =  this.dialog.open(FormmMsgComponent, {
                    width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                  data: {cod_factura: xvalor.cod_nota,
                          accion:text},
                          disableClose: true,
                  panelClass: 'pedidos_modal',
                  backdropClass: "bdrop"
                });
         
          dialogRef.afterClosed().subscribe(result => {
            if(result != 'cerrar' && result != 'salir'){
              
                xvalor.cod_dev = result.cod_dev;

                console.log(xvalor);
               this.notasCredito(xvalor,'dev');
            }
          });

          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
 async notasCredito(obj:any,xvalor:any){
  try {
    
      this.key_hash = {};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            this.servicio.buscarDocumentoAnuladohttp(obj).subscribe(
              response =>{
                this.snackBar.open('El documento " '+obj.cod_nota +' "  ya fue anulado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition});
              },
              error =>{
                var dialogRef;
            switch (xvalor) {
              case 'dev':
                dialogRef =  this.dialog.open(FormNotaCreditoComponent, {
                    width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                  data: {cod_factura: obj.cod_nota,
                          cod_pedido: obj.cod_pedido,
                          cod_lista_precio:obj.cod_lista_precio,
                          cod_cliente:obj.cod_cliente,
                          cod_dev:obj.cod_dev,
                          des_cliente:obj.des_cliente,
                          iva_pedido:obj.iva_pedido,
                          iva_monto:obj.iva_monto,
                          desc_pedido:obj.desc_pedido,
                          subtotal_costo_pedido:obj.subtotal_costo_pedido,
                          total_costo_pedido:obj.total_costo_pedido,
                          tipo:'enviarpedido',
                          termini:'pedido'},
                          disableClose: true,
                  panelClass: 'pedidos_modal3',
                  backdropClass: "bdrop"
                });
                break;
                case 'admin':
                  dialogRef =  this.dialog.open(FormNotaCreditoAdminComponent, {
                    width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                    data: {cod_factura: obj.cod_nota,
                            cod_pedido: obj.cod_pedido,
                            cod_lista_precio:obj.cod_lista_precio,
                            cod_cliente:obj.cod_cliente,
                            des_cliente:obj.des_cliente,
                            iva_pedido:obj.iva_pedido,
                            iva_monto:obj.iva_monto,
                            desc_pedido:obj.desc_pedido,
                            subtotal_costo_pedido:obj.subtotal_costo_pedido,
                            total_costo_pedido:obj.total_costo_pedido,
                            termini:'pedido',
                            xvalor:xvalor},
                            disableClose: true,
                    panelClass: 'pedidos_modal3',
                    backdropClass: "bdrop"
                  });
                    break;
              default:
                break;
            }
      
          dialogRef.afterClosed().subscribe(result => {
            if(result != 'cerrar' && result != 'salir'){
              if (xvalor.toLowerCase() === 'admin') {
                result.tipo_nota = 'adminnt';
              }
              if (xvalor.toLowerCase() === 'dev') {
                result.tipo_nota = 'ntntrg';
              }
              
              console.log(result);
             this.gestionNotaCredito(result);
            }
          });
              }
            );
            
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
gestionNotaCredito(xvalor:any){
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });
  setTimeout( async() => {
    this.servicio.gestionNotaCreditoNotaEntregahttp(xvalor).subscribe(
      response =>{
        this.dialogSpin.close();
        this.cod_nota_credito = response.nota;
        this.listar_notas_entrega(null);
        this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});

      },
      error =>{
        this.dialogSpin.close();
        console.log('una respuesta');
        console.log(<any>error);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }, 1000);
  return this.xobj2;
}
descargar_reporte_ventas(text:any){
  try {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef:any;
  this.accion = text;
  let datos = localStorage.getItem('cod_usuario');
  let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    dialogRef = this.dialog.open(FormReportesVentasNotasComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: {
              accion:this.accion},
      panelClass: 'pedidos_modal_rep',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);
        switch (result) {
          case 'xlsx':
            /* this.reportes_ventas_xlsx(); */
            break;
          case 'pdf':

            break;
          case 'txt':

            break;
          case 'csv':

            break;

          default:
            this.reportes_rango_fecha_ventas_xlsx(result);
            break;
        }
      }
    });
                           
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
      
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
reportes_rango_fecha_ventas_xlsx(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.fecha1 = xvalor.fecha_inicio;
      this.fecha2 = xvalor.fecha_fin;
      this.servicio.reporteNotashttp(xvalor).subscribe(
        response=>{
          this.dialogSpin.close();
          console.log(response.xobjeto_unico);
          this.descarga_prod_ventas_por_fecha_xlsx(response.xobjeto_unico);
        },
        error=>{
          this.dialogSpin.close();
          console.log(error.error.xobjeto_unico);
          console.log(error.error.message);
          this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
      );
    }, 3000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
async descarga_prod_ventas_por_fecha_xlsx(xdatos:any){
  try {
    console.log(xdatos,'llego a la descarga');
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Reporte de Notas Entregas",{views: [{showGridLines: false}]});

    const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
    });
    worksheet.addImage(imageId2,'B2:B7')
      
    worksheet.mergeCells('B1:T1')
    worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
    worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D1').font={bold: true};

    worksheet.mergeCells('B2:T2')
    worksheet.getCell('D2').value='Rif J-41031797-3';
    worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D2').font={bold: true};
    
    worksheet.mergeCells('B3:T3')
    worksheet.getCell('D3').value='Reporte de Ventas de Notas de Entregas';
    worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D3').font={bold: true};

    worksheet.mergeCells('B4:T4')
    worksheet.getCell('D4').value='A la fecha "'+this.fecha1+'"-"'+this.fecha1+'" ';
    worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D4').font={bold: true};

    worksheet.mergeCells('B5:T5')
    worksheet.getCell('D5').value='Expresado en Bolivares y Dolares';
    worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D5').font={bold: true};

    worksheet.mergeCells('B6:T6')

    worksheet.autoFilter = {
      from: 'B8',
      to: 'T8',
    }
    var fondo_gris_claro =['D7','E7','F7','G7','H7']
    fondo_gris_claro.map(celdas => {
      worksheet.getCell(celdas).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF'},
        
      };
    })
      
    let header=[" ","Fecha","Hora","Nro. de nota","Tipo de nota","RIF","Cliente","Vendedor","Tipo de cliente","Tasa",
               "Base imponible","Exento","Monto exento","%Descuento","Descuento","%IVA","IVA","Monto total","Monto total divisa",
               "Estatus"];
    let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

    var bordes_headers = ['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8','P8',
                            'Q8','R8','S8','T8']
    bordes_headers.map(celdas =>{
      worksheet.getCell(celdas).border = {
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
    })

    var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                                'P8','Q8','R8','S8','T8']
    fondo_gris_claro_plus.map(celdas => {
      worksheet.getCell(celdas).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0050a0'},
        
      };
    })
    fondo_gris_claro_plus.map(centrar => {
      worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
    })
    worksheet.getColumn(1).width = 3.5;
    worksheet.getColumn(2).width = 17;
    worksheet.getColumn(3).width = 17;
    worksheet.getColumn(4).width = 17;
    worksheet.getColumn(5).width = 17;
    worksheet.getColumn(6).width = 17;
    worksheet.getColumn(7).width = 45;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 17;
    worksheet.getColumn(10).width = 17;
    worksheet.getColumn(11).width = 17;
    worksheet.getColumn(12).width = 17;
    worksheet.getColumn(13).width = 17;
    worksheet.getColumn(14).width = 17;
    worksheet.getColumn(15).width = 17;
    worksheet.getColumn(16).width = 17;
    worksheet.getColumn(17).width = 17;
    worksheet.getColumn(18).width = 17;
    worksheet.getColumn(19).width = 17;
    worksheet.getColumn(20).width = 17;

    /////////////////////wrap//////////////////////
    var espacio_interno =['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                          'P8','Q8','R8','S8','T8']
    espacio_interno.map(celdas =>{
    worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
    })
    /////////////////////wrap//////////////////////
    
    /////////////////////ROW//////////////////////
    worksheet.getRow(8).height= 30
    /////////////////////ROW//////////////////////

    var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
    worksheet.getColumn(11).numFmt =numFmtStr;
    worksheet.getColumn(12).numFmt =numFmtStr;
    worksheet.getColumn(13).numFmt =numFmtStr;
    worksheet.getColumn(14).numFmt =numFmtStr;
    worksheet.getColumn(15).numFmt =numFmtStr;
    worksheet.getColumn(16).numFmt =numFmtStr;
    worksheet.getColumn(17).numFmt =numFmtStr;
    worksheet.getColumn(18).numFmt =numFmtStr;
    worksheet.getColumn(19).numFmt =numFmtStr;
  
    for (let x1 of xdatos)
  {
    x1.fecha_inicio = new Date(x1.fecha_inicio)
  let x2=Object.keys(x1);
  let temp=[]
  for(let y of x2)
  {
  temp.push(x1[y])
  }
  worksheet.addRow(temp)
  }

  for (let i = 0; i <= xdatos.length; i++) {

    if(i == 0){
      worksheet.getCell('B9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('C9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('D9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('E9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('F9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('G9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('H9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('I9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('J9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('K9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('L9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('M9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('N9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('O9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('P9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('Q9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('R9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('S9').alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell('T9').alignment = { vertical: 'top', horizontal: 'center' }
     
    }else{
      var numActu = i + 9 -1;
      var numActuString = numActu.toString()

      worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
      worksheet.getCell(`T${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
    
      worksheet.getCell(`B${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`C${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`D${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`E${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`F${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`G${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`H${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`I${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`J${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`K${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`L${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`M${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`N${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`O${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`P${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`Q${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`R${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`S${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`T${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}},
      };
    }
    
    if(i == xdatos.length){
        var numActu = i + 9 -1;
        var final_mas_uno = i + 9 + 1
        var final_mas_uno_s = final_mas_uno.toString()

        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`K${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`L${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`M${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`N${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`O${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`P${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`Q${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`R${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`S${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`T${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
    }
  }
  let fname="Reporte de Ventas de Notas de Entregas desde '"+this.fecha1+"' hasta '"+this.fecha2+"'";
  workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
  });
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
  }
  //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////
  imprimir_descargar(xvalor:any,text:any): void {
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          this.accion = text;
                          switch (this.accion) {
                            case 'imprimir':
                              this.accion = 'enviarpedido';
                              var dialogRef = this.dialog.open(FormmMsgComponent, {
                                width: '100vw',
                                      maxWidth: '100vw',
                                      height:'100%',
                                data: {id:'',
                                      accion:this.accion},
                                disableClose: true,
                                panelClass: 'pedidos_modal',
                                backdropClass: "bdrop"
                              });
                              dialogRef.afterClosed().subscribe(result => {
                                if (result != 'cerrar' && result != 'salir' ) {
                                  dialogRef.close();
                                  this.imprimirnotraentrega(xvalor);
                                }else{
                                  dialogRef.close();
                                  
                                }
                            
                              });
                              break;
                              case 'descargar':
                              this.accion = 'enviarpedido';
                              var dialogRef = this.dialog.open(FormmMsgComponent, {
                                width: '100vw',
                                      maxWidth: '100vw',
                                      height:'100%',
                                data: {id:'',
                                      accion:this.accion},
                                disableClose: true,
                                panelClass: 'pedidos_modal',
                                backdropClass: "bdrop"
                              });
                              dialogRef.afterClosed().subscribe(result => {
                                if (result != 'cerrar' && result != 'salir' ) {
                                  dialogRef.close();
                                  this.imprimirnotraentrega(xvalor);
                                }else{
                                  dialogRef.close();
                                  
                                }
                            
                              });
                              break;
                          
                            default:
                              break;
                          }
                          
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
      
    } catch (error) {
      console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
    }
  }
    imprimirnotraentrega(xvalor:any){
  
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
  
      setTimeout(() => {
        var valor = {
          val_def :'F',
          tipo_operacion :'IMPRIMIRN',
          cod_usuario :localStorage.getItem('cod_usuario'),
          cod_factura:xvalor.cod_factura,
          cod_nota_credito:xvalor.cod_nota_credito,
          tipo_nota:xvalor.tipo_nota}
        this.servicio.validar_acceso_acciones_http(valor).subscribe(
          response => {
            const pdf = new PdfMakeWrapper();
  
        this.servicio.listarProdPedidoNotasEntregahttp(xvalor).subscribe(
         async response  =>{
          this.dialogSpin.close();
            console.log(xvalor);
             switch (xvalor.tipo_nota) {
              
                case 'ND':
                  this.pdf.pdfVentas(response,'dd');
               
                break;
                case 'NB':

                  this.pdf.pdfVentas(response,'dd');
                
                break;
  
              default:
                break;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log(error);
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
          },
          error =>{
            this.dialogSpin.close();
            console.log(error);
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
        
      }, 1000);
  
    }
  fechas(obj:any, text:any){

    this.accion = text;
    this.dialog.open(FormDireccionesComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: { fecha_inicio:obj.fecha_inicio,
              hora_inicio:obj.hora_inicio,
              fecha_recep:obj.fecha_r_factura,
              hora_recep:obj.hora_recepcion,
              fecha_venc:obj.fecha_v_factura,
              hora_venc:obj.hora_vencimiento,
              termino: this.accion
            },
            panelClass: 'pedidos_modal'
    });
}
async detallepedido(obj:any, text:any){
  console.log(this.ELEMENT_DATA);
  console.log(obj);
  this.accion = text;
    this.dialog.open(FormDireccionesComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: { monto_pendiente:obj.monto_base,
              monto_cobrado:obj.monto_cobrado,
              monto_contador:obj.monto_contador,
              termino: this.accion
            },
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
    });

}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
headerTabla5():ITable{
  return new Table([
    ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,203).end;
  }
crearTabla555(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData5(data)

]).layout('noBorders').widths([30,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,216).margin(40).end;
}
crearTabla55(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData55(data)

  ]).layout('noBorders').widths(['*','*',200,'*','*',20]).alignment('right').fontSize(6).absolutePosition(20,216).margin(40).end;
  }
  crearTabla5(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData555(data)

    ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,216).margin(40).end;
    }
async cinta85(data:interdatosfacefactura[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  }
numerofact5(data:any[]):ITable{

  return new Table([
    ['','','','', '','Nota de entrega  Nº: '+data[0].cod_nota+''],
  ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(14).layout('noBorders').absolutePosition(20,135).end;
  }
  crearTabla25(data:any[], data2:any){
    return  new Columns([new Table([
        ['Cliente: '+data2[0].des_cliente+'',''],
        ['R.I.F.: '+data2[0].cod_cliente+'',''],
        ['Vendedor: '+data2[0].des_vendedor+'',''],
        ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
      ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,150).end,
      new Table([
        [ '',''],
        [ '','Emisión: '+data[0].fecha_inicio+''],
        [ '','Venc: '+data[0].fecha_inicio+''],
        [ '','Dirección: '+data2[0].dir_cliente+'']
      ]).widths(['*',250]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,150).end]).columnGap(5).end;
      }
  crearTabla35(data:interdatosfacefactura[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    }
    crearTabla45(data:any[],data2:any,data3:any,data4:any){

      try {
        var llave = false;
        var fecha_soporte = moment('01/10/2021', 'DD/MM/YYYY');
        var fecha_doc =  moment(data[0].fecha_inicio, 'DD/MM/YYYY') ;
        if (fecha_doc.isSameOrAfter(fecha_soporte)) {
    
          console.log(fecha_doc,'fecha inicio');
          console.log(fecha_soporte,'fecha finnn');
          llave = true;
        }else{
          console.log(fecha_doc,'fecha inicio');
          console.log(fecha_soporte,'fecha finnn');
          llave = false;
        }
              
            if (llave) {
    
              console.log(data,'data1');
              console.log(data4,'data2');
              console.log(data2,'data3');
        
                      var base_imponible = parseFloat(data[0].subtotal_costo_pedido.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) - parseFloat(data[0].monto_exento.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.'));
                
                      var base_factura = parseFloat(data[0].subtotal_costo_pedido.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) + parseFloat(data[0].desc_pedido_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.'));
                      var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                      var factura_base = base_factura.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
                      var factura_base_divisa = (base_factura / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_factura_base_divisa = factura_base_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var factura_base_imponible_divisa = (base_imponible / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_factura_base_imponible_divisa = factura_base_imponible_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var subtotal_divisa = (parseFloat(data[0].subtotal_costo_pedido.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_subtotal_divisa = subtotal_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                
                      var iva_monto_divisa = (parseFloat(data[0].iva_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_iva_monto_divisa = iva_monto_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var total_divisa = (parseFloat(data[0].total_costo_pedido.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_total_divisa = total_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var des_divisa = (parseFloat(data[0].desc_pedido_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_des_divisa = des_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var excento_divisa = (parseFloat(data[0].monto_exento.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_excento_divisa = excento_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                
                      /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
        
                  //////////////////////////RECONVERSION/////////////////////////////
                  var idea_hp_de_maduro = 1000000;
        
                  var b_fact = base_factura * idea_hp_de_maduro;
                  var  formato_b_fact = b_fact.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var b_imp = base_imponible * idea_hp_de_maduro;
                  var  formato_b_imp = b_imp.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_excento = parseFloat(data[0].monto_exento.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.'))  * idea_hp_de_maduro;
                  var  formato_r_excento = r_excento.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_desc_pedido = parseFloat(data[0].desc_pedido_monto.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) * idea_hp_de_maduro;
                  var  formato_r_desc_pedido = r_desc_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_subtotal_costo_pedido = parseFloat(data[0].subtotal_costo_pedido.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) * idea_hp_de_maduro;
                  var  formato_r_subtotal_costo_pedido = r_subtotal_costo_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_iva_monto = parseFloat(data[0].iva_monto.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) * idea_hp_de_maduro;
                  var  formato_r_iva_monto = r_iva_monto.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_total_costo_pedido = parseFloat(data[0].total_costo_pedido.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) * idea_hp_de_maduro;
                  var  formato_r_total_costo_pedido = r_total_costo_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                //////////////////////////RECONVERSION/////////////////////////////
              return  new Columns([ new Table([
                ['Notas: '+data3.comentarios+'','','','','',''],
                ['LISTA DE PRECIOS Nº: '+data[0].cod_lista_precio+'','','','', '',''],
                ['TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A','','','','',''],
                ['DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.','','','', '',''],
                ['BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064 ','','','', '',''],
                ['BANCAMIGA: 0172-0194-8419-4444-9550','','','','',''],
              ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
                vLineWidth:(i:number, node:any, col:number)=>{
                    return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
                  },
                  hLineWidth:(i:number, node:any, col:number)=>{
                    return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
                  }
              }).absolutePosition(20,620).end,
            new Table([
              ['','','Monto base: ', ''+formato_b_fact+' Bs','',''],
                ['','','Descuento: '+data[0].desc_pedido+' %', ''+formato_r_desc_pedido+' Bs','',''],
                ['','','Total exento: ', ''+formato_r_excento+' Bs','',''],
                ['','','Sub-total: ', ''+formato_r_subtotal_costo_pedido+' Bs','',''],
                ['','','Total base imponible: ', ''+formato_b_imp+' Bs','',''],
                ['','','I.V.A : '+data[0].iva_pedido+' %', ''+formato_r_iva_monto+' Bs','',''],
                ['','','Total: ', ''+formato_r_total_costo_pedido+' Bs','',''],
    
            ]).widths(['*','*',70,70,60,60]).alignment('right').fontSize(7).layout({
              vLineWidth:(i:number, node:any, col:number)=>{
                  return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
                },
                hLineWidth:(i:number, node:any, col:number)=>{
                  return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
                }
            }).absolutePosition(20,620).end,
    
            new Table([
              ['','','','', '',''+formato_factura_base_divisa+' $'],
                  ['','','','', '',''+formato_des_divisa+'  $'],
                  ['','','','', '',''+formato_excento_divisa+'  $'],
                  ['','','','', '',''+formato_subtotal_divisa+'  $'],
                  ['','','','', '',''+formato_factura_base_imponible_divisa+'  $'],
                  ['','','','', '',''+formato_iva_monto_divisa+'  $'],
                  ['','','','', '',''+formato_total_divisa+'  $'],
            ]).widths(['*','*','*','*',60,60]).alignment('right').bold().fontSize(7).layout({
              vLineWidth:(i:number, node:any, col:number)=>{
                  return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
                },
                hLineWidth:(i:number, node:any, col:number)=>{
                  return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
                }
            }).absolutePosition(20,620).end,
    
            new Table([
                  ['','','','', ''+factura_base+' Bs',''],
                  ['','','','', ''+data[0].desc_pedido_monto+' Bs',''],
                  ['','','','', ''+data[0].monto_exento+' Bs',''],
                  ['','','','', ''+data[0].subtotal_costo_pedido+' Bs',''],
                  ['','','','', ''+formato_base_impnible+' Bs',''],
                  /* ['','','','', '',''+formato_factura_base_imponible_divisa+'  $'], */
                  ['','','','', ''+data[0].iva_monto+' Bs',''],
                  ['','','','', ''+data[0].total_costo_pedido+' Bs',''],
            ]).widths(['*','*','*','*',60,60]).alignment('right').bold().fontSize(7).layout({
              vLineWidth:(i:number, node:any, col:number)=>{
                  return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
                },
                hLineWidth:(i:number, node:any, col:number)=>{
                  return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
                }
            }).absolutePosition(20,620).end
          ]).columnGap(5).end
            }else{
              console.log(data,'data1');
              console.log(data4,'data2');
              console.log(data2,'data3');
        
                      var base_imponible = parseFloat(data[0].subtotal_costo_pedido.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) - parseFloat(data[0].monto_exento.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.'));
                
                      var base_factura = parseFloat(data[0].subtotal_costo_pedido.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) + parseFloat(data[0].desc_pedido_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.'));
                      var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                      var factura_base = base_factura.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
                      var factura_base_divisa = (base_factura / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_factura_base_divisa = factura_base_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var factura_base_imponible_divisa = (base_imponible / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_factura_base_imponible_divisa = factura_base_imponible_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var subtotal_divisa = (parseFloat(data[0].subtotal_costo_pedido.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_subtotal_divisa = subtotal_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                
                      var iva_monto_divisa = (parseFloat(data[0].iva_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_iva_monto_divisa = iva_monto_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var total_divisa = (parseFloat(data[0].total_costo_pedido.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_total_divisa = total_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var des_divisa = (parseFloat(data[0].desc_pedido_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_des_divisa = des_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var excento_divisa = (parseFloat(data[0].monto_exento.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data4[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_excento_divisa = excento_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                
                      /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
        
                  //////////////////////////RECONVERSION/////////////////////////////
                  var idea_hp_de_maduro = 1000000;
        
                  var b_fact = base_factura / idea_hp_de_maduro;
                  var  formato_b_fact = b_fact.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var b_imp = base_imponible / idea_hp_de_maduro;
                  var  formato_b_imp = b_imp.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_excento = parseFloat(data[0].monto_exento.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.'))  / idea_hp_de_maduro;
                  var  formato_r_excento = r_excento.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_desc_pedido = parseFloat(data[0].desc_pedido_monto.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) / idea_hp_de_maduro;
                  var  formato_r_desc_pedido = r_desc_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_subtotal_costo_pedido = parseFloat(data[0].subtotal_costo_pedido.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) / idea_hp_de_maduro;
                  var  formato_r_subtotal_costo_pedido = r_subtotal_costo_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_iva_monto = parseFloat(data[0].iva_monto.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) / idea_hp_de_maduro;
                  var  formato_r_iva_monto = r_iva_monto.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_total_costo_pedido = parseFloat(data[0].total_costo_pedido.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) / idea_hp_de_maduro;
                  var  formato_r_total_costo_pedido = r_total_costo_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                //////////////////////////RECONVERSION/////////////////////////////
                return  new Columns([ new Table([
                ['Notas: '+data3.comentarios+'','','','','',''],
                ['LISTA DE PRECIOS Nº: '+data[0].cod_lista_precio+'','','','', '',''],
                ['TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A','','','','',''],
                ['DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.','','','', '',''],
                ['BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064 ','','','', '',''],
                ['BANCAMIGA: 0172-0194-8419-4444-9550','','','','',''],
    ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
    }
    }).absolutePosition(20,620).end,
    new Table([
      ['','','Monto base: ', ''+factura_base+' Bs','',''],
      ['','','Descuento: '+data[0].desc_pedido+' %', ''+data[0].desc_pedido_monto+' Bs','',''],
      ['','','Total exento: ', ''+data[0].monto_exento+' Bs','',''],
      ['','','Sub-total: ', ''+data[0].subtotal_costo_pedido+' Bs','',''],
      ['','','Total base imponible: ', ''+formato_base_impnible+' Bs','',''],
      ['','','I.V.A : '+data[0].iva_pedido+' %', ''+data[0].iva_monto+' Bs','',''],
      ['','','Total: ', ''+data[0].total_costo_pedido+' Bs','',''],
    ]).widths(['*','*',70,70,60,60]).alignment('right').fontSize(7).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
    }
    }).absolutePosition(20,620).end,
    
    new Table([
      ['','','','', '',''+formato_factura_base_divisa+' $'],
      ['','','','', '',''+formato_des_divisa+'  $'],
      ['','','','', '',''+formato_excento_divisa+'  $'],
      ['','','','', '',''+formato_subtotal_divisa+'  $'],
      ['','','','', '',''+formato_factura_base_imponible_divisa+'  $'],
      ['','','','', '',''+formato_iva_monto_divisa+'  $'],
      ['','','','', '',''+formato_total_divisa+'  $'],
    ]).widths(['*','*','*','*',60,60]).alignment('right').bold().fontSize(7).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
    }
    }).absolutePosition(20,620).end,
    
    new Table([
      ['','','','', ''+formato_b_fact+' Bs',''],
      ['','','','', ''+formato_r_desc_pedido+' Bs',''],
      ['','','','', ''+formato_r_excento+' Bs',''],
      ['','','','', ''+formato_r_subtotal_costo_pedido+' Bs',''],
      ['','','','', ''+formato_b_imp+' Bs',''],
      /* ['','','','', '',''+formato_factura_base_imponible_divisa+'  $'], */
      ['','','','', ''+formato_r_iva_monto+' Bs',''],
      ['','','','', ''+formato_r_total_costo_pedido+' Bs',''],
    ]).widths(['*','*','*','*',60,60]).alignment('right').bold().fontSize(7).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
    return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
    }
    }).absolutePosition(20,620).end
    ]).columnGap(5).end
            }
      } catch (error) {
        console.log(error);
      }
        }
        crearTabla44(data:any[],data2:any){

          return  new Columns([ new Table([
            ['Notas: '+data2.comentarios+'','','','','',''],
            ['','','','', '',''],
            ['Lista de precios Nº: '+data[0].cod_lista_precio+'','','','','',''],
            ['Cuentas:','','','','',''],
            ['UNIICAS CUENTAS AUTORIZADAS PARA EL PAGO:','','','', '',''],
            ['BANK OF AMERICA: 898110972780  BANESCO PANAMA: 110000075677  ZELLE: FACTORY COSMETICS INTERNATIONAL CORP.','','','', '',''],
          ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
              }
          }).absolutePosition(20,646).end,
          new Table([
            ['','','','', 'Total base imponible: ',''+data[0].subtotal_costo_pedido+''],
            ['','','','', 'Total exento: ',''+data[0].monto_exento+''],
            ['','','','', 'Descuento: '+data[0].desc_pedido+' %',''+data[0].desc_pedido_monto+''],
            ['','','','', 'Sub-total: ',''+data[0].subtotal_costo_pedido+''],
            ['','','','', 'I.V.A : 0,00 %','0,00'],
            ['','','','', 'Total: ',''+data[0].total_costo_pedido+''],
          ]).widths(['*','*','*','*',70,50]).alignment('right').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
              }
          }).absolutePosition(20,646).end
        ]).columnGap(5).end
    
          }
      extraerData5(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',row.cod_producto,
                             row.des_producto, '',
                            '', '']);
      }
      extraerData55(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',
                             '', '',
                            row.costo_producto, row.subtotal_producto,'']);
      }
      extraerData555(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['','',
                             '', row.cant_producto,
                            '', '']);
      }
      //////////////////////NOTAS ND////////////////////////////
headerTablaND():ITable{
  return new Table([
    ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,203).end;
  }
crearTablaND1(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData5(data)

]).layout('noBorders').widths([30,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,216).margin(40).end;
}
crearTablaND2(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData55(data)

  ]).layout('noBorders').widths(['*','*',200,'*','*',20]).alignment('right').fontSize(6).absolutePosition(20,216).margin(40).end;
  }
  crearTablaND3(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData555(data)

    ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,216).margin(40).end;
    }
async cintaND(data:interdatosfacefactura[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  }
numerofactND(data:any[]):ITable{

  return new Table([
    ['','','','', '','Nota de entrega  Nº: '+data[0].cod_nota+''],
  ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(14).layout('noBorders').absolutePosition(20,135).end;
  }
  crearTablaND25(data:any[], data2:any){
    return  new Columns([new Table([
        ['Cliente: '+data2[0].des_cliente+'',''],
        ['R.I.F.: '+data2[0].cod_cliente+'',''],
        ['Vendedor: '+data2[0].des_vendedor+'',''],
        ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
      ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,150).end,
      new Table([
        [ '',''],
        [ '','Emisión: '+data[0].fecha_inicio+''],
        [ '','Venc: '+data[0].fecha_inicio+''],
        [ '','Dirección: '+data2[0].dir_cliente+'']
      ]).widths(['*',250]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,150).end]).columnGap(5).end;
      }
  crearTablaND35(data:interdatosfacefactura[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    }
    crearTablaND45(data:any[],data2:any){

      return  new Columns([ new Table([
        ['Notas:','','','','',''],
  ['LISTA DE PRECIOS Nº: '+data[0].cod_lista_precio+'','','','', '',''],
  ['','','','','',''],
  ['TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A','','','','',''],
  ['DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.','','','', '',''],
  ['BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064  BANCAMIGA: 0172-0194-8419-4444-9550','','','', '',''],
      ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          }
      }).absolutePosition(20,646).end,
      new Table([
        ['','','','', 'Total base imponible: ',''+data[0].subtotal_costo_pedido+''],
        ['','','','', 'Total exento: ',''+data[0].monto_exento+''],
        ['','','','', 'Descuento: '+data[0].desc_pedido+' %',''+data[0].desc_pedido_monto+''],
        ['','','','', 'Sub-total: ',''+data[0].subtotal_costo_pedido+''],
        ['','','','', 'I.V.A : 0,00 %','0,00'],
        ['','','','', 'Total: ',''+data[0].total_costo_pedido+''],
      ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          }
      }).absolutePosition(20,646).end
    ]).columnGap(5).end

      }
      extraerDataND5(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',row.cod_producto,
                             row.des_producto, '',
                            '', '']);
      }
      extraerDataND55(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',
                             '', '',
                            row.costo_producto, row.subtotal_producto,'']);
      }
      extraerDataND555(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['','',
                             '', row.cant_producto,
                            '', '']);
      }
      headerTablaNC():ITable{
        return new Table([
          ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
        ]).layout({
          vLineWidth:(i:number, node:any, col:number)=>{
              return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
            },
            hLineWidth:(i:number, node:any, col:number)=>{
              return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
            }
        }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,190).end;
        }
      crearTabla9NC(data:interfacefactura[]):ITable{
      return new Table([
        ...this.extraerData9NC(data)
      
      ]).layout('noBorders').widths([27,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,205).margin(40).end;
      }
      crearTabla99NC(data:interfacefactura[]):ITable{
        return new Table([
          ...this.extraerData99NC(data)
      
        ]).layout('noBorders').widths(['*','*',200,'*','*',17]).alignment('right').fontSize(6).absolutePosition(20,205).margin(40).end;
        }
        crearTabla999NC(data:interfacefactura[]):ITable{
          return new Table([
            ...this.extraerData999NC(data)
      
          ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,205).margin(40).end;
          }
      async cinta9NC(data:interdatosfacefactura[]){
      
        return new Table([
          [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
        ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
        }
        numerofact9NC(data:any[]):ITable{
      
          return new Table([
            ['','','','', '','Nota Devolución  Nº: '+data[0].cod_nota_credito+''],
          ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,130).end;
          }
        crearTabla29NC(data:any[], data2:any){
          return  new Columns([new Table([
              ['Cliente: '+data2[0].des_cliente+'',''],
              ['R.I.F.: '+data2[0].cod_cliente+'',''],
              ['Vendedor: '+data2[0].des_vendedor+'',''],
              ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
            ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,140).end,
            new Table([
              [ '',''],
              [ '','Emisión: '+data[0].fecha_inicio+''],
              [ '','Venc: '+data[0].fecha_inicio+''],
              [ '','Dirección: '+data2[0].dir_cliente+'']
            ]).widths(['*',350]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,140).end]).columnGap(5).end;
            }
        crearTabla39NC(data:interdatosfacefactura[]):ITable{
          return new Table([
            ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
          ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
          }
          crearTabla49NC(data:any[],data2:any,data3:any,data4:any, data5:any, data6:any){
            try {
              var llave = false;
              var fecha_soporte = moment('01/10/2021', 'DD/MM/YYYY');
              var fecha_doc =  moment(data[0].fecha_inicio, 'DD/MM/YYYY') ;
              if (fecha_doc.isSameOrAfter(fecha_soporte)) {
        
                console.log(fecha_doc,'fecha inicio');
                console.log(fecha_soporte,'fecha finnn');
                llave = true;
              }else{
                console.log(fecha_doc,'fecha inicio');
                console.log(fecha_soporte,'fecha finnn');
                llave = false;
              }
                
              if (llave) {
          
                console.log(data,'data1');
              console.log(data6,'data2');
              console.log(data2,'data3');
        
                      var base_imponible = parseFloat(data[0].subtotal_dev_costo.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) - parseFloat(data[0].dev_monto_exento.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.'));
                
                      var base_factura = parseFloat(data[0].subtotal_dev_costo.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) + parseFloat(data[0].desc_dev_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.'));
                      var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                      var factura_base = base_factura.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
                      var factura_base_divisa = (base_factura / parseFloat(data6[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_factura_base_divisa = factura_base_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var factura_base_imponible_divisa = (base_imponible / parseFloat(data6[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_factura_base_imponible_divisa = factura_base_imponible_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var subtotal_divisa = (parseFloat(data[0].subtotal_dev_costo.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_subtotal_divisa = subtotal_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                
                      var iva_monto_divisa = (parseFloat(data[0].iva_dev_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_iva_monto_divisa = iva_monto_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var total_divisa = (parseFloat(data[0].total_dev_costo.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_total_divisa = total_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var des_divisa = (parseFloat(data[0].desc_dev_monto.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_des_divisa = des_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                      var excento_divisa = (parseFloat(data[0].dev_monto_exento.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace('.','')
                      .replace(',','.')));
                
                      var formato_excento_divisa = excento_divisa.toFixed(2).replace(/\D/g, "")
                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                
                
                      /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
        
                  //////////////////////////RECONVERSION/////////////////////////////
                  var idea_hp_de_maduro = 1000000;
        
                  var b_fact = base_factura * idea_hp_de_maduro;
                  var  formato_b_fact = b_fact.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var b_imp = base_imponible * idea_hp_de_maduro;
                  var  formato_b_imp = b_imp.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_excento = parseFloat(data[0].dev_monto_exento.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.'))  * idea_hp_de_maduro;
                  var  formato_r_excento = r_excento.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_desc_pedido = parseFloat(data[0].desc_dev_monto.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) * idea_hp_de_maduro;
                  var  formato_r_desc_pedido = r_desc_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_subtotal_costo_pedido = parseFloat(data[0].subtotal_dev_costo.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) * idea_hp_de_maduro;
                  var  formato_r_subtotal_costo_pedido = r_subtotal_costo_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_iva_monto = parseFloat(data[0].iva_dev_monto.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) * idea_hp_de_maduro;
                  var  formato_r_iva_monto = r_iva_monto.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
                  var r_total_costo_pedido = parseFloat(data[0].total_dev_costo.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) * idea_hp_de_maduro;
                  var  formato_r_total_costo_pedido = r_total_costo_pedido.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                //////////////////////////RECONVERSION/////////////////////////////
        
                return  new Columns([ new Table([
                  ['Notas:','','','','',''],
                  ['AFECTA LA NOTA DE ENTREGA Nº: '+data[0].cod_factura+'','','','','',''],
                  ['CODIGO DE CONTROL FACTURA Nº: N/A','','','','',''],
                  ['CODIGO DE CONTROL NOTA DE CREDITO Nº: N/A','','','', '',''],
                  ['FECHA DE LA NOTA DE ENTREGA: '+data4[0].fecha_inicio+'','','','', '',''],
                  ['','','','', '',''],
                ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
                  vLineWidth:(i:number, node:any, col:number)=>{
                      return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
                    },
                    hLineWidth:(i:number, node:any, col:number)=>{
                      return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
                    }
                }).absolutePosition(20,620).end,
                new Table([
                  ['','','Monto base: ', ''+formato_b_fact+' Bs','',''],
                  ['','','Descuento: '+data[0].desc_dev+' %', ''+formato_r_desc_pedido+' Bs','',''],
                  ['','','Total exento: ', ''+formato_r_excento+' Bs','',''],
                  ['','','Sub-total: ', ''+formato_r_subtotal_costo_pedido+' Bs','',''],
                  ['','','Total base imponible: ', ''+formato_b_imp+' Bs','',''],
                  ['','','I.V.A : '+data[0].iva_dev+' %', ''+formato_r_iva_monto+' Bs','',''],
                  ['','','Total: ', ''+formato_r_total_costo_pedido+' Bs','',''],
        
              ]).widths(['*','*',70,70,60,60]).alignment('right').fontSize(7).layout({
                vLineWidth:(i:number, node:any, col:number)=>{
                    return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
                  },
                  hLineWidth:(i:number, node:any, col:number)=>{
                    return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
                  }
              }).absolutePosition(20,620).end,
        
              new Table([
                ['','','','', '',''+formato_factura_base_divisa+' $'],
                    ['','','','', '',''+formato_des_divisa+'  $'],
                    ['','','','', '',''+formato_excento_divisa+'  $'],
                    ['','','','', '',''+formato_subtotal_divisa+'  $'],
                    ['','','','', '',''+formato_factura_base_imponible_divisa+'  $'],
                    ['','','','', '',''+formato_iva_monto_divisa+'  $'],
                    ['','','','', '',''+formato_total_divisa+'  $'],
              ]).widths(['*','*','*','*',60,60]).alignment('right').bold().fontSize(7).layout({
                vLineWidth:(i:number, node:any, col:number)=>{
                    return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
                  },
                  hLineWidth:(i:number, node:any, col:number)=>{
                    return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
                  }
              }).absolutePosition(20,620).end,
        
              new Table([
        
                    ['','','','', ''+factura_base+' Bs',''],
                    ['','','','', ''+data[0].desc_dev_monto+' Bs',''],
                    ['','','','', ''+data[0].dev_monto_exento+' Bs',''],
                    ['','','','', ''+data[0].subtotal_dev_costo+' Bs',''],
                    ['','','','', ''+formato_base_impnible+' Bs',''],
                    /* ['','','','', '',''+formato_factura_base_imponible_divisa+'  $'], */
                    ['','','','', ''+data[0].iva_dev_monto+' Bs',''],
                    ['','','','', ''+data[0].total_dev_costo+' Bs',''],
              ]).widths(['*','*','*','*',60,60]).alignment('right').bold().fontSize(7).layout({
                vLineWidth:(i:number, node:any, col:number)=>{
                    return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
                  },
                  hLineWidth:(i:number, node:any, col:number)=>{
                    return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
                  }
              }).absolutePosition(20,620).end
            ]).columnGap(5).end
        
              }else{
        
                console.log(data,'data1');
                console.log(data6,'data2');
                console.log(data2,'data3');
          
                        var base_imponible = parseFloat(data[0].subtotal_dev_costo.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) - parseFloat(data[0].dev_monto_exento.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'));
                  
                        var base_factura = parseFloat(data[0].subtotal_dev_costo.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) + parseFloat(data[0].desc_dev_monto.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.'));
                        var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                        var factura_base = base_factura.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                        /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
                        var factura_base_divisa = (base_factura / parseFloat(data6[0].tasa_precio.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')));
                  
                        var formato_factura_base_divisa = factura_base_divisa.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                        var factura_base_imponible_divisa = (base_imponible / parseFloat(data6[0].tasa_precio.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')));
                  
                        var formato_factura_base_imponible_divisa = factura_base_imponible_divisa.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                        var subtotal_divisa = (parseFloat(data[0].subtotal_dev_costo.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')));
                  
                        var formato_subtotal_divisa = subtotal_divisa.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                  
                        var iva_monto_divisa = (parseFloat(data[0].iva_dev_monto.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')));
                  
                        var formato_iva_monto_divisa = iva_monto_divisa.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                        var total_divisa = (parseFloat(data[0].total_dev_costo.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')));
                  
                        var formato_total_divisa = total_divisa.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                        var des_divisa = (parseFloat(data[0].desc_dev_monto.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')));
                  
                        var formato_des_divisa = des_divisa.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                        var excento_divisa = (parseFloat(data[0].dev_monto_exento.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')) / parseFloat(data6[0].tasa_precio.replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace('.','')
                        .replace(',','.')));
                  
                        var formato_excento_divisa = excento_divisa.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  
                  
                        /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
          
                    //////////////////////////RECONVERSION/////////////////////////////
                    var idea_hp_de_maduro = 1000000;
          
                    var b_fact = base_factura * idea_hp_de_maduro;
                    var  formato_b_fact = b_fact.toFixed(2).replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          
                    var b_imp = base_imponible * idea_hp_de_maduro;
                    var  formato_b_imp = b_imp.toFixed(2).replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          
                    var r_excento = parseFloat(data[0].dev_monto_exento.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.'))  * idea_hp_de_maduro;
                    var  formato_r_excento = r_excento.toFixed(2).replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          
                    var r_desc_pedido = parseFloat(data[0].desc_dev_monto.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.')) * idea_hp_de_maduro;
                    var  formato_r_desc_pedido = r_desc_pedido.toFixed(2).replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          
                    var r_subtotal_costo_pedido = parseFloat(data[0].subtotal_dev_costo.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.')) * idea_hp_de_maduro;
                    var  formato_r_subtotal_costo_pedido = r_subtotal_costo_pedido.toFixed(2).replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          
                    var r_iva_monto = parseFloat(data[0].iva_dev_monto.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.')) * idea_hp_de_maduro;
                    var  formato_r_iva_monto = r_iva_monto.toFixed(2).replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          
                    var r_total_costo_pedido = parseFloat(data[0].total_dev_costo.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.')) * idea_hp_de_maduro;
                    var  formato_r_total_costo_pedido = r_total_costo_pedido.toFixed(2).replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                  //////////////////////////RECONVERSION/////////////////////////////
          return  new Columns([ new Table([
                  ['Notas:','','','','',''],
                  ['AFECTA LA FACTURA Nº: '+data[0].cod_factura+'','','','','',''],
                  ['CODIGO DE CONTROL FACTURA Nº: '+data3[0].cod_control+'','','','','',''],
                  ['CODIGO DE CONTROL NOTA DE CREDITO Nº: '+data5[0].cod_control+'','','','', '',''],
                  ['FECHA DE LA FACTURA: '+data4[0].fecha_inicio+'','','','', '',''],
                  ['MONTO BASE DE LA FACTURA: '+data4[0].monto_base_factura+'','','','', '',''],
          ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
              }
          }).absolutePosition(20,620).end,
          new Table([
            ['','','Monto base: ', ''+formato_b_fact+' Bs','',''],
            ['','','Descuento: '+data[0].desc_dev+' %', ''+formato_r_desc_pedido+' Bs','',''],
            ['','','Total exento: ', ''+formato_r_excento+' Bs','',''],
            ['','','Sub-total: ', ''+formato_r_subtotal_costo_pedido+' Bs','',''],
            ['','','Total base imponible: ', ''+formato_b_imp+' Bs','',''],
            ['','','I.V.A : '+data[0].iva_dev+' %', ''+formato_r_iva_monto+' Bs','',''],
            ['','','Total: ', ''+formato_r_total_costo_pedido+' Bs','',''],
      
          ]).widths(['*','*',70,70,60,60]).alignment('right').fontSize(7).layout({
          vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
          }
          }).absolutePosition(20,620).end,
          
          new Table([
            ['','','','', '',''+formato_factura_base_divisa+' $'],
            ['','','','', '',''+formato_des_divisa+'  $'],
            ['','','','', '',''+formato_excento_divisa+'  $'],
            ['','','','', '',''+formato_subtotal_divisa+'  $'],
            ['','','','', '',''+formato_factura_base_imponible_divisa+'  $'],
            ['','','','', '',''+formato_iva_monto_divisa+'  $'],
            ['','','','', '',''+formato_total_divisa+'  $'],
          ]).widths(['*','*','*','*',60,60]).alignment('right').bold().fontSize(7).layout({
          vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 7 ? 1:0;
          }
          }).absolutePosition(20,620).end,
          
          new Table([
      
            ['','','','', ''+factura_base+' Bs',''],
            ['','','','', ''+data[0].desc_dev_monto+' Bs',''],
            ['','','','', ''+data[0].dev_monto_exento+' Bs',''],
            ['','','','', ''+data[0].subtotal_dev_costo+' Bs',''],
            ['','','','', ''+formato_base_impnible+' Bs',''],
            /* ['','','','', '',''+formato_factura_base_imponible_divisa+'  $'], */
            ['','','','', ''+data[0].iva_dev_monto+' Bs',''],
            ['','','','', ''+data[0].total_dev_costo+' Bs',''],
          ]).widths(['*','*','*','*',60,60]).alignment('right').bold().fontSize(7).layout({
          vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
          }
          }).absolutePosition(20,620).end
          ]).columnGap(5).end
              }
            } catch (error) {
              console.log(error);
            }
        
            }     
            extraerData9NC(data:interfacefactura[]):TableRow[]{
      
              let rows =[];
      
              for (const i in data) {
               rows.push(data[i]);
      
              }
              console.log(rows);
              return rows.map(row =>['',row.cod_producto,
                                   row.des_producto, '',
                                  '', '']);
            }
            extraerData99NC(data:interfacefactura[]):TableRow[]{
      
              let rows =[];
      
              for (const i in data) {
               rows.push(data[i]);
      
              }
              console.log(rows);
              return rows.map(row =>['',
                                   '', '',
                                  row.costo_producto, row.subtotal_producto,'']);
            }
            extraerData999NC(data:interfacefactura[]):TableRow[]{
      
              let rows =[];
      
              for (const i in data) {
               rows.push(data[i]);
      
              }
              console.log(rows);
              return rows.map(row =>['','',
                                   '', row.cant_producto,
                                  '', '']);
            }
            anular_nota(obj:any,termino:any){
              try {
                obj.val_def = 'F';
                obj.tipo_operacion = 'ANULACION';
                obj.cod_usuario = localStorage.getItem('cod_usuario');
                  this.servicio.validar_acceso_acciones_http(obj).subscribe(
                    response =>{
                      this.servicio.buscarDocumentoAnuladohttp(obj).subscribe(
                        response =>{
                          this.snackBar.open('El documento " '+obj.cod_nota +' "  ya fue anulado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition});
                        },
                        error =>{
                          var dialogRef:any;
                          this.accion = 'anulacion_ventas';
                            dialogRef = this.dialog.open(FormmMsgComponent, {
                              width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                              data: {
                                      accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal',
                              backdropClass: "bdrop"
                            });
                      
                            dialogRef.afterClosed().subscribe(result => {
                              if(result !== 'cerrar'){
                                obj.comentarios = result.comentarios;
                                obj.cod_usuario = localStorage.getItem('cod_usuario');
                                obj.des_usuario = localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido');
                                console.log(obj);
                      
                                this.anularNotaEntrega(obj);
                              }
                        }
                      );     
                          });
                    },
                    error =>{
                      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition});
                    }
                  );
              } catch (error) {
                console.log(<any>error);
              }
            }
            anularNotaEntrega(xvalor:any){
              try {
               
                this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
                  width: '15%',
                  height:'25%',
                  data: {
                          accion:this.accion},
                  disableClose: true,
                  panelClass: 'spinner',
                  backdropClass: "bdrop"
                });
                setTimeout( async() => {
                  xvalor.val_def = 'VENTAS-ENV';
                  xvalor.tipo_operacion = 'ANULARNOTA';
                  this.servicio.gestionAnulacionVentahttp(xvalor).subscribe(
                    response =>{
                      this.dialogSpin.close();
                      this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition});
                        this.listar_notas_entrega(null);
                    },
                    error =>{
                      this.dialogSpin.close();
                      console.log('una respuesta');
                      console.log(<any>error);
                      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition});
                    }
                  );
                }, 1000);
                return this.xobj2;
              } catch (error) {
                console.log(<any>error);
              }
            }
}
