
<div id="modal_carrito" class=" container-fluid">
    <form  (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #3649b6; background-color:#475bca ;">
            <div id="content_1" class="col-md-6 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Codigo del Daily: </mat-label>
                        <input style="font-size: 11px; color: #fff;" #cod_producto matInput placeholder="" autocomplete="off" [value]="data.cod_daily" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                  #
                  </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-5 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>search</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>

            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrar()" matTooltip="SALIR">
                          <mat-icon >exit_to_app</mat-icon>
                      </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row" style=" border: 2px solid #2EB3E7;">
            <div class="col-md-6 col-sm-6 col-xs-6 py-2  h-100" style="background-color: steelblue;">
                <h4 class="text-light"> <b>Documentos</b> </h4>
                <div >
                    <table #table mat-table [dataSource]="dataSource1" class="   table-primary text-center table-striped  table-condensed table-hover">

                        <ng-container matColumnDef="cod_documento">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                                <h5><strong>Codigo</strong></h5>
                            </th>
                            <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_documento}} </td>
                            <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;"> </td>
                        </ng-container>
    
                        <ng-container matColumnDef="fecha_inicio">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                                <h5><strong>Fecha</strong></h5>
                            </th>
                            <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.fecha_inicio}} </td>
                            <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;"> </td>
                        </ng-container>
    
                        <ng-container matColumnDef="des_cliente">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                                <h5><strong>Cliente</strong></h5>
                            </th>
                            <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.des_cliente}} </td>
                            <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;"> </td>
                        </ng-container>
    
                        <ng-container matColumnDef="monto_base_usd">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                                <h5><strong>Monto</strong></h5>
                            </th>
                            <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.monto_base_usd}}</td>
                            <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;"></td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                    </table>

                </div>
               
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 py-2  h-100" style="background-color: rgb(5, 145, 35);">
                <h4 class="text-light"><b>FCI</b></h4>
                <table #table mat-table [dataSource]="dataSource2" class="  table-success text-center table-striped  table-condensed table-hover">

                    <ng-container matColumnDef="cod_documento">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_documento}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha_inicio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Fecha</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.fecha_inicio}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="des_cliente">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Cliente</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.des_cliente}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="monto_base_usd">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Monto</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_objeto':  element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.monto_base_usd}}</td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;"></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns2; sticky: true"></tr>
                </table>
            </div>
        </div>
    </form>

</div>