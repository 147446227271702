import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-editar-ordenes',
  templateUrl: './editar-ordenes.component.html',
  styleUrls: ['./editar-ordenes.component.css']
})
export class EditarOrdenesComponent implements OnInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

  }

  ngOnInit(): void {
    this.listarordenesprod(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }

  /////////////////////////////////////////////////////////////////////
  listarordenesprod(msj:any){
    let msg = msj;
    var valor = {termino:false}
    this.servicio.listarOrdenesProdhttp(valor).subscribe(
      response =>{
        ////////////////UNIDADES//////////////////////
        this.xobj3 = [];
        for (const i in response[0]) {
          this.xobj3.push(response[0][i]);
        }
        console.log(this.xobj3);
        if(response){
          console.log(response,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                  cod_orden_producto:response[i].cod_orden_producto,
                  cod_usuario:response[i].cod_usuario,
                  des_usuario: response[i].des_usuario,
                  estatus:response[i].estatus,
                  fecha_inicio:response[i].fecha_inicio,
                  hora_inicio:response[i].hora_inicio,
                  cod_usuario_cierre: response[i].cod_usuario_cierre,
                  des_usuario_cierre:response[i].des_usuario_cierre,
                  fecha_fin:response[i].fecha_fin,
                  hora_fin:response[i].hora_fin,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Actualizar'
                                      ];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log('una respuesta');
        console.log(<any>error.error.message);
        this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Actualizar'
                                      ];
        this.dataSource = new MatTableDataSource<any>();
              this.dataSource.paginator = this.paginator;
      }
    );
    console.log(msg);
    if(msg == 'actualizar'){
      this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    return this.xobj2;
  }
   eliminarclientes(id:any){
    console.log('eliminar');
    this.servicio.eliminarclienteshttp(id).subscribe(
      response =>{
        this.xobj3 = [];
        this.listarordenesprod(null);
              this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.listarordenesprod(null);
      }
    );

    return this.obj;
  }

  modalrespuesta(obj:any, text:any){
    this.accion = text;
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '24%',
        height:'35%',
        data: {id:obj.cod_cliente,
              accion:this.accion},
        disableClose: true,
        panelClass: 'elem_modal'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log('The dialog was closed');
          console.log(result);
          let resultado = this.eliminarclientes(result.id);
          console.log(resultado);
        }
      });
  }

  editardatos(obj:any,text:any){
    var dialogRef:any;
    this.accion = text;
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    if (this.accion === 'actualizar') {
      dialogRef = this.dialog.open(FormConsultaOrdenesComponent, {
        width: '100%',
        height:'90%',
        data: {cod_orden_producto: obj.cod_orden_producto,
                cod_usuario:datos,
                des_usuario: nombre,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
    }else{
      dialogRef = this.dialog.open(FormOrdenesprodComponent, {
        width: '100%',
        height:'90%',
        data: {cod_lista_precio_producto:'',
                cod_usuario:datos,
                des_usuario: nombre,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
    }

      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);

     this.gestionOrdenProd(result);
        }
      });
  }

public  gestionOrdenProd(xvalor:any){
  console.log(xvalor);
  var resultado = this.servicio.gestionOrdenProdhttp(xvalor).subscribe(
     response =>{
       console.log(response,'una respuesta');
       if(response.resul){
         console.log(response.resul,'una respuesta');
         this.xobj2 = response;
         this.ELEMENT_DATA = [];
             for (const i in response.resul) {
                 console.log(response[i]);

               this.ELEMENT_DATA.push({
                cod_orden_producto:response.resul[i].cod_orden_producto,
                cod_usuario:response.resul[i].cod_usuario,
                des_usuario: response.resul[i].des_usuario,
                estatus:response.resul[i].estatus,
                fecha_inicio:response.resul[i].fecha_inicio,
                hora_inicio:response.resul[i].hora_inicio,
                cod_usuario_cierre: response.resul[i].cod_usuario_cierre,
                des_usuario_cierre:response.resul[i].des_usuario_cierre,
                fecha_fin:response.resul[i].fecha_fin,
                hora_fin:response.resul[i].hora_fin,
                accion:'',
                accion2:'',
                accion3:''
              });
             }
             this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'Actualizar'
                                      ];
              /* this.dataSource = this.ELEMENT_DATA; */
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
             this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
       }
     },
     error =>{
       console.log(<any>error.error.message);
       this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Actualizar'
                                      ];
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   );

   return resultado;
 }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
   public buscarClientes(xvalor:string){
      var valor:any = {valor:xvalor}
      console.log(valor);
    this.servicio.buscarClienteshttp(valor).subscribe(
      response =>{
        if(response){
          console.log(response, 'busqueda' );
          this.ELEMENT_DATA = [];
          for (const i in response) {

            this.ELEMENT_DATA.push({
              id_cliente:response[i].id_cliente,
              cod_cliente:response[i].cod_cliente,
              des_cliente: response[i].des_cliente,
              tipo_cliente:response[i].tipo_cliente,
              dir_cliente:response[i].dir_cliente,
              pais_cliente:response[i].pais_cliente,
              pais_estado_cliente: response[i].pais_estado_cliente,
              des_vend_cliente:response[i].des_vend_cliente,
              tipo2_cliente:response[i].tipo2_cliente,
              estatus_cliente:response[i].estatus_cliente,
              credito_dias_cliente:response[i].credito_dias_cliente,
              reten_cliente:response[i].reten_cliente,
              telefono1_cliente:response[i].telefono1_cliente,
              telefono2_cliente:response[i].telefono2_cliente,
              correo_cliente:response[i].correo_cliente,
              comercializacion:response[i].comercializacion,
              accion:'',
              accion2:'',
              accion3:''
            });
          }
          this.displayedColumns = [ 'id_cliente',
                                        'cod_cliente',
                                        'des_cliente',
                                        'tipo_cliente',
                                        'pais_cliente',
                                        'pais_estado_cliente',
                                        'des_vend_cliente',
                                        'estatus_cliente',
                                        'tipo2_cliente',
                                        'credito_dias_cliente',
                                        'reten_cliente',
                                        'comercializacion',
                                        'dir_cliente',
                                        'telefonos_cliente',
                                        'correo_cliente',
                                        'Eliminar',
                                        'Actualizar'
                                      ];
             this.dataSource = new MatTableDataSource<PeriodicElementclientes>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log(<any>error.error[0].message);
        this.snackBar.open(<any>error.error[0].message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  async detalleOrdenProd(xvalor:any){
      this.dialog.open(FormConsultaOrdenesComponent, {
        width: '100%',
        height:'90%',
        data: {cod_orden_producto: xvalor.cod_orden_producto},

        panelClass: 'pedidos_modal'
      });

 }
 async confirmacionOrdenProd(xvalor:any){
  var dialogRef = this.dialog.open(FormConfirmacionInvMpComponent, {
    width: '100%',
    height:'90%',
    data: {cod_usuario:xvalor.cod_usuario,
           des_usuario:xvalor.des_usuario,
           cod_orden_producto: xvalor.cod_orden_producto,
           estatus:xvalor.estatus},
    disableClose: true,
    panelClass: 'pedidos_modal'
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result != 'cerrar'){
      console.log(result);
     this.gestionOrdenLaboratorio(result);
    }
  });
}
public  gestionOrdenLaboratorio(xvalor:any){
  console.log(xvalor);
  var resultado = this.servicio.gestionOrdenLabhttp(xvalor).subscribe(
     response =>{
       console.log(response.resul,'una respuesta');
       if(response.resul){
         console.log(response.resul,'una respuesta');
         this.xobj2 = response;
         this.ELEMENT_DATA = [];
             for (const i in response.resul) {
                 console.log(response[i]);

               this.ELEMENT_DATA.push({
                cod_orden_producto:response.resul[i].cod_orden_producto,
                cod_usuario:response.resul[i].cod_usuario,
                des_usuario: response.resul[i].des_usuario,
                estatus:response.resul[i].estatus,
                fecha_inicio:response.resul[i].fecha_inicio,
                hora_inicio:response.resul[i].hora_inicio,
                cod_usuario_cierre: response.resul[i].cod_usuario_cierre,
                des_usuario_cierre:response.resul[i].des_usuario_cierre,
                fecha_fin:response.resul[i].fecha_fin,
                hora_fin:response.resul[i].hora_fin,
                accion:'',
                accion2:'',
                accion3:''
              });
             }
             this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'detalle',
                                        'Actualizar',
                                        'confirmar'
                                      ];
              /* this.dataSource = this.ELEMENT_DATA; */
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
             this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
       }
     },
     error =>{
       console.log(<any>error.error.message);
       this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'detalle',
                                        'Actualizar',
                                        'confirmar'
                                      ];
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   );

   return resultado;
 }


  /* handleFile(event) {
    let file = event.target.files[0];

    var id = document.getElementById('file');
    console.log(file);
    var nombre = 'Codigo de Unidad';
    this.servicio.processFileToJson({}, file).subscribe(data => {
      let arry = data.sheets.Hoja1;
      console.log(arry);
      for (const i in arry) {
          console.log(arry[i]);
          this.result.push(arry[i]);
      }
      if (this.result.length != 0) {
        console.log('entro en el if');
          this.servicio.insertarmasivohttp(this.result).subscribe(
            response =>{
              if(response){
                console.log(response,'una respuesta');
                let xobj2 = response.resul;
                this.ELEMENT_DATA = [];
                    for (const i in xobj2) {

                      this.ELEMENT_DATA.push({id:xobj2[i].id, Nombre: xobj2[i].nombre_usu, Apellido:  xobj2[i].apellido_usu , Direccion:  xobj2[i].direccion_usu,  Correo: xobj2[i].correo_usu, Accion: '', Accion2: ''});
                    }
                   this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];

                    this.dataSource = new MatTableDataSource<PeriodicElement2>(this.ELEMENT_DATA);
                    this.dataSource.paginator = this.paginator;
                 var bool = true;
                 console.log(response.resul);
                this.snackBar.open(response.message,'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                 return bool;
              }
            },
            error =>{
              console.log(<any>error);
            }
          );
      }
      /* this.result = JSON.stringify(data['sheets'].Sheet1); */
      /* this.snackBar.open('El archivo se cargo de forma correcta en la base de datos', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,}); */
  /*   })
    event.srcElement.value = null;
  } */
  /////DATATABLE
  /* applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
  } */

}
