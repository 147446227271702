
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;"> 
  <div class="row " style="height: 8%; ">
    <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
      <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>GESTIÓN ASISTENCIA</b></span>
      <div class=" navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
              <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarDepositos("actualizar")'><mat-icon >sync</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="DESCARGA" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; background: green; " (click)='descargarAsistenciaEmpleados("","listar")'><mat-icon >get_app</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="FECHA"  class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; background: rgb(42, 116, 177); " (click)='buscarAsistenciaEmpleados("","listar")'><mat-icon >calendar_today</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="MASIVO"  class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; background: rgb(74, 76, 222); " (click)='cargarMasivo("","listar")'><mat-icon >file_upload</mat-icon></button></li>
          </ul>
          <div class="">
              <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
          </div>
      </div>
    </nav>
  </div>
      <div class="row " style="height: 78.7%;">
          
          <div id="htmlData" class="col-md-12 px-0  border h-100 " style="background-color: steelblue; overflow-y: auto;">
            
                <table  mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-bordered table-condensed table-hover">
                  <!-- Weight Column -->
                    <ng-container matColumnDef="cod_usuario">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>Id cedula</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> <strong>CI</strong>.{{element.cod_usuario}} </td>
                    </ng-container>


                    <ng-container matColumnDef="des_usuario">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>Nombres y apellidos</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario}} </td>
                    </ng-container>

                    <ng-container matColumnDef="unidad">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>Departamento</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> {{element.unidad}} </td>
                    </ng-container>

                    <ng-container matColumnDef="retraso">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>Retraso</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> {{element.retraso}} </td>
                    </ng-container>

                    <ng-container matColumnDef="salida_temprano">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>Saldia temprano</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> {{element.salida_temprano}} </td>
                    </ng-container>

                    <ng-container matColumnDef="hora">
                      <th mat-header-cell *matHeaderCellDef class="text-center"  style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>Hora</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora}} </td>
                    </ng-container>

                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>fecha</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha}} </td>
                    </ng-container>

                    <ng-container matColumnDef="entrada_salida">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>Entrada y salida</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> {{element.entrada_salida}} </td>
                    </ng-container>

                    <ng-container matColumnDef="dt_comedor">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                          <h5><strong>Beneficio del Comedor</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.entrada_salida  === 'E' ? '#abeabd' : '#e8e6ab' }" style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.dt_comedor}}</strong> </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

                </table>

              <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 
              
          </div>
        
      </div>

</div>
