import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-reporte-movimiento-cdt',
  templateUrl: './form-reporte-movimiento-cdt.component.html',
  styleUrls: ['./form-reporte-movimiento-cdt.component.css']
})
export class FormReporteMovimientoCdtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
