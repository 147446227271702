
<div class="" id="modal">
    <div class="row justify-content-center">
        <div class="col-md-5 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); ">
                    <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-center">Enviar <strong> DAILY REPORT</strong> </h3>
                    </div>
                    <div class="modal-body">
                       
                        
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">

    </div>
</div>