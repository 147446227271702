<div id="modal_carrito" style="background-color:#0076C7;border-radius: 1rem; border: 2px solid;">
    
    <div class=" container"  >
 
         <div class="row mt-3 justify-content-between px-3">
             <h2 class="m-0 text-light">Seguiento de Recepcion</h2>
             <button (click)="cerrar()" class="boton"> <mat-icon style="color: #ff2b34;" >clear</mat-icon> </button>
         </div>
 
        <div id="carrito_p6" class="row " style=" border: 2px solid #0076C7; background-color: #f1ede9; ">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                <table #table mat-table [dataSource]="dataSource" class=" text-center table-striped  table-condensed table-hover">
 
                    <ng-container matColumnDef="cod_factura">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Codigo <br> de Documento</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_factura}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
 
                    <ng-container matColumnDef="cod_producto">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Código </strong></h5>
                      </th>
                     
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
  
                    <ng-container matColumnDef="des_producto">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Descripción</strong></h5>
                      </th>
    
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
  
                    <ng-container matColumnDef="cant_desc">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Cantidad Recibida</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_desc | puntoporcoma}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
 
                    <ng-container matColumnDef="cant_actual">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Había por recibir</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_actual | puntoporcoma}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
 
                    <ng-container matColumnDef="cant_cambio">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Resta por recibir</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element" [style.background-color]="element.recibido > element.cant_producto_cot ? '#fff3cd' : null "   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_cambio | puntoporcoma}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
  
  
                    <ng-container matColumnDef="des_usuario">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Usuario</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_usuario}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
 
                    <ng-container matColumnDef="fecha">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Fecha</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.fecha}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
 
                    <ng-container matColumnDef="hora">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Hora</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.hora}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
                   
                     
                     <ng-container matColumnDef="accion">
                         <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #003f66; padding: 5px; color: #fff;">
                             <h5><strong>Documento</strong></h5>
                         </th>
                         <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                             <button matTooltip="Ver Documento" (click)="verDocumento(element, $event)"  class="btn btn-outline-primary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                 <mat-icon>receipt</mat-icon>
                             </button>
                            
 
                         </td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: #003f66; color: #fff;"> </td>
                     </ng-container>
 
                     
 
 
                 <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                 <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 
                 </table> 
 
              </div>
        </div>
       
    </div>
 </div>
 
