import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { FormImportReporteCuotasVsVentasComponent } from '../form-import-reporte-cuotas-vs-ventas/form-import-reporte-cuotas-vs-ventas.component';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-form-reporte-cuotas-vs-ventas',
  templateUrl: './form-reporte-cuotas-vs-ventas.component.html',
  styleUrls: ['./form-reporte-cuotas-vs-ventas.component.css']
})
export class FormReporteCuotasVsVentasComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_cliente',{static:true}) cod_cliente:ElementRef;
  @ViewChild('des_cliente',{static:true}) des_cliente:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;

obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
unidad:any;
unidadp:any = [];
key_hash:any;
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormReporteCuotasVsVentasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

    }

    ngOnInit(): void {
      this.listarCuotasVsVentas(null);
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
     }
   listarCuotasVsVentas(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listarVentasVsCuotasReporthttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.ELEMENT_DATA = [];
           console.log(response);
                  for (const i in response) {

                    this.ELEMENT_DATA.push({
                      cod_cuotas_ventas_report:response[i].cod_cuotas_ventas_report,
                      lbe_total:response[i].lbe_total,
                      lbe_subtotal: response[i].lbe_subtotal,
                      cuota_total:response[i].cuota_total,
                      cuota_total_porcentaje:response[i].cuota_total_porcentaje,
                      mts_general:response[i].mts_general,
                      cuota_total_real_porcentaje: response[i].cuota_total_real_porcentaje,
                      fecha_inicio: response[i].fecha_inicio,
                      hora_inicio: response[i].hora_inicio,
                      accion3:''
                    });
  
                  }
                  
                this.displayedColumns = [
                  'cod_cuotas_ventas_report',
                  'lbe_total',
                  'lbe_subtotal',
                  'cuota_total',
                  'cuota_total_porcentaje',
                  'mts_general',
                  'cuota_total_real_porcentaje',
                  'fecha_inicio',
                  'hora_inicio',
                  'imprimir',
                  'descargar',
                  'enviar',
                ];
                                          
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.ELEMENT_DATA = [];
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
              this.displayedColumns = [
                'cod_cuotas_ventas_report',
                'lbe_total',
                'lbe_subtotal',
                'cuota_total',
                'cuota_total_porcentaje',
                'mts_general',
                'cuota_total_real_porcentaje',
                'fecha_inicio',
                'hora_inicio',
                'imprimir',
                'descargar',
                'enviar',
              ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }
  async descarga_cuotas(xdatos:any, accion:any, msj:any){
    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
        console.log(xdatos,'llego a la descarga');
        setTimeout(() => {
          let msg = msj;
          this.servicio.listarVentasCuotasFrontPDFhttp(xdatos).subscribe(
            async response =>{
              this.dialogSpin.close();
              ////////////////UNIDADES//////////////////////
             console.log(response);
               
             const fontsSize = 6.5
             const fontsSizeTableDown = 7.5
 
             const tablaEnLista = generarTabla(response.cuotas_clientes,)
 
             function generarTabla(data){
 
                 
 
                 console.log(data)
                 let arry =[
                             [
                                 {text: 'Cliente', alignment: 'center', fontSize: fontsSize, border: [true, true, false, true],}, 
                                 {text: 'Cuota', alignment: 'right', fontSize: fontsSize, border: [false, true, false, true],}, 
                                 {text: '% Cuota', alignment: 'right', fontSize: fontsSize,border: [false, true, false, true],},
                                 {text: data[0].month, alignment: 'right', fontSize: fontsSize,border: [false, true, false, true],},
                                 {text: '% Real', alignment: 'right', fontSize: fontsSize,border: [false, true, true, true],},
                             ],
                             ]
                 for (const producto of data) {
                   
                  if(parseFloat(producto.monto_mes.replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace(',', '.')) > parseFloat(producto.monto_cuota.replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace(',', '.'))){
                      var color = 'green'
                    }else{
                        var color= 'red'
                    }
                  let articulo = [
                    {text: producto.des_cliente, alignment: 'center', fontSize: fontsSize,border: [false, false, false, false],}, 
                    {text: '$ '+producto.monto_cuota, alignment: 'right', fontSize: fontsSize,border: [false, false, false, false],},
                    {text: producto.porcentaje_cuota+' %', alignment: 'right', fontSize: fontsSize,border: [false, false, false, false],},
                    {text: '$ '+ producto.monto_mes, alignment: 'right', fontSize: fontsSize,border: [false, false, false, false],},
                    {text: producto.porcentaje_real+' %', alignment: 'right', fontSize: fontsSize,border: [false, false, false, false],color: color },
                ] 
                     arry.push(articulo)
                     
                 }
 
                 return arry
             }
             
             /* console.log(imagen.src) */
            for (const i in response.datos_totales) {
              var montoTotalCouta = response.datos_totales[i].cuota_total;
              var montoTotalMes = response.datos_totales[i].mts_c;
              var porcentajeTotalReal = response.datos_totales[i].cuota_total_real_porcentaje;
              var porcentajeTotalCuota = response.datos_totales[i].cuota_total_porcentaje;
              var monto_total_general = response.datos_totales[i].mts_dc;
              var porcentaje_final = response.datos_totales[i].meta_porcentaje;
              var monto_final = response.datos_totales[i].mts_general;
              var lbe_subtotal = response.datos_totales[i].lbe_subtotal;
              var lbe_total = response.datos_totales[i].lbe_total

              var PDF ={
              pageSize: 'A4',
              info:{
                title: 'Reporte de cuotas vs ventas',
                author: 'Factory Cosmetics',
                subject: 'Reporte de cuotas vs ventas',                
              },
              background:[
                {image: await this.getBase64ImageFromURLCuotas("../../../../../../assets/img/marca_compas_pdf.png"), width: 680, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0}}
                ],
              pageMargins: [ 20, 20, 20, 20 ],
              content :[
                          [
                              {image: await this.getBase64ImageFromURLCuotas("../../../../../../assets/img/logo-factory.png"), fit:[80,80],alignment:'left',},
                              {text:'FACTORY COSMETICS 3DG, C.A.\n Reporte de Cuotas Vs Ventas\n Confidential Information\n "'+ response.datos_totales[i].fecha_inicio+'" \n"'+response.datos_totales[i].cod_cuotas_ventas_report+'"',alignment: 'center',margin: [0, 5, 0, 5],},
                              {text:'    ', colSpan: 1,},
                          ],
                      {
                          style: 'tableExample',
                          table: {
                              
                              headerRows: 1,
                              widths: ['*', 60 ,60, 60, 60,],
                              body: tablaEnLista,
                              fontSize: fontsSize,
                          },
      
                      },
                      {
                          style: 'tableExample2',
                          table: {
                              headerRows: 1,
                              widths: [268,1,120,120,],
                              body: [
                                      [
                                          {text:  'CUOTA TOTAL:  $ '+montoTotalCouta, alignment: 'center', fontSize: fontsSizeTableDown, border: [true, true, false, false],bold:true,}, 
                                          {text: '', fontSize: fontsSizeTableDown, border: [false, true, false, false]}, 
                                          /* {text: ' ', alignment: 'right', fontSize: fontsSizeTableDown,border: [false, false, false, false],}, */
                                          {text: 'MTS-C:  $ '+montoTotalMes, alignment: 'left', fontSize: fontsSizeTableDown,border: [true, true, true, false],bold:true,},
                                          {text: 'CUOTA TOTAL REAL%:  '+porcentajeTotalReal+' %  ', alignment: 'left', fontSize: fontsSizeTableDown,border: [false, true, true, false],bold:true,},
                                      ],
                                      [
                                          {text: 'CUOTA TOTAL%:  '+porcentajeTotalCuota+' %', alignment: 'center', fontSize: fontsSizeTableDown, border: [true, false, false, false],bold:true,}, 
                                          {text: '' ,fontSize: fontsSizeTableDown, border: [false, false, false, false],}, 
                                          /* {text: ' ', alignment: 'right', fontSize: fontsSizeTableDown,border: [false, false, false, false]}, */
                                          {text: 'MTS-DC:  $ '+monto_total_general, alignment: 'left', fontSize: fontsSizeTableDown,border: [true, false, true, false],bold:true,},
                                          {text: 'META%: '+porcentaje_final+' %  ', alignment: 'left', fontSize: fontsSizeTableDown,border: [false, false, true, false],bold:true,},
      
                                      ],
                                      [
                                          {text: 'LBE SUBTOTAL:  $ '+lbe_subtotal, alignment: 'center', fontSize: fontsSizeTableDown, border: [true, false, false, false],bold:true,}, 
                                          {text: '', fontSize: fontsSizeTableDown, border: [false, false, false, false],}, 
                                          /* {text: '', alignment: 'right', fontSize: fontsSizeTableDown,border: [false, false, false, false],color:'green',}, */
                                          {text: 'MTS GENERAL:  $ '+ monto_final, alignment: 'left', fontSize: fontsSizeTableDown,border: [true, false, true, false],bold:true,},
                                          {text: ' ', alignment: 'left', fontSize: fontsSizeTableDown,border: [false, false, true, false],color:'green',},
      
                                      ],
                                  
                                      [
                                          {text: ' LBE:  $ '+lbe_total, alignment: 'center', fontSize: fontsSizeTableDown, border:[true, false, false, true],bold:true}, 
                                          {text: ' ',fontSize: fontsSizeTableDown, border:[false, false, true, true],}, 
                                          {text: ' ', alignment: 'right', fontSize: fontsSizeTableDown,border: [false, false, true, true],},
                                          {text: ' ', alignment: 'right', fontSize: fontsSizeTableDown,border: [false, false, true, true],},
      
                                      ],
                                  ],
                              fontSize: fontsSize,
                          },
      
                      },
                      {text: 'Leyenda de terminos', style: 'header2'},
                      {
                          ul: [
                              {text: 'CUOTA TOTAL: Es la la sumatoria de las cuotas de los clientes.',fontSize: 11,},
                              {text: 'CUOTA TOTAL%: Es la sumatoria del porcentaje de las cuotas de los clientes.',fontSize: 11,},
                              {text: 'LBE SUBTOTAL: Es la sumatoria de las cuotas de los clientes, a excepcion de aquellos que tienen el porcentaje en 0.',fontSize: 11,},
                              {text: 'LBE: Es la meta de ventas que se establece en el mes.',fontSize: 11,},
                              {text: 'MTS-C: Es la venta mensual de los clientes mostrados en el reporte.',fontSize: 11,},
                              {text: 'MTS-DC: Es la venta mensual de los demas distribuidores y clientes que no se muestran en el reporte.',fontSize: 11,},
                              {text: 'MTS GENERAL: Es la suma del MTS-C + MTS-DC ',fontSize: 11,},
                              {text: 'CUOTA TOTAL REAL%:Es el porcentaje que se obtiene de acuerdo a venta del mes (MTS-C / LBE SUBTOTAL * 100%).',fontSize: 11,},
                              {text: 'META%: Es el porcentaje que se lleva de acuerdo la venta del mes (MTS GENERAL / LBE * 100%).',fontSize: 11,},
                          ]
                      },
                  ],
                  styles: {
                      imagen:{
                          margin: [0, 100, 0, 0]
                      },
                      header: {
                      fontSize: 10,
                      alignment: 'right',
                      bold: true,
                      },
                      header2: {
                      margin: [0, 20, 0, 10],
                      fontSize: 10,
                      alignment: 'left',
                      bold: true,
                      },
                      leal:{
                          alignment: 'right',
                      },
                      tableExample2:{
                          margin:[0,40,0,0]
                      },
                  defaultStyle: {
                      columnGap: 10
                  }
              }
          }
             
            if(accion == 'imprimir' ){

              pdfMake.createPdf(PDF).open()

              this.dialogSpin.close()

            }else{

              pdfMake.createPdf(PDF).download('Reporte de Cuotas vs Ventas"'+response.datos_totales[0].fecha_inicio+'"')

              this.dialogSpin.close()
              

            }
  
            }
        });
          console.log(msg);
          if(msg == 'actualizar'){
            this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
          return this.xobj2;
        }, 1000);
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  getBase64ImageFromURLCuotas(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

  enviar(obj:any){
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {

        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        var dialogRef;
        var xobjeto = {
          val_def : 'RP',
          tipo_operacion: 'RENVCUOTAS',
          cod_usuario: localStorage.getItem('cod_usuario')
        };
           
      this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
        response =>{
        this.servicio.enviarReporteCuotasVsVentasHttp(obj).subscribe(
          response =>{

            this.dialogSpin.close();

            this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});

            this.listarCuotasVsVentas(null);

          },
          error =>{
            this.dialogSpin.close();
            this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});

          }
        )
      },
      error=>{
        this.dialogSpin.close();
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      )
    }
  });

  }
  crear(){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                      cod_menu: localStorage.getItem('sub_main'),
                      cod_sesion: localStorage.getItem('cod_sesion'),
                      dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                            
                            var dialogRef;
                                dialogRef =  this.dialog.open(FormImportReporteCuotasVsVentasComponent, {
                                  width: '100vw',
                                  maxWidth: '100vw',
                                  height:'100vh',
                                    data: {
                                           cod_usuario:localStorage.getItem('cod_usuario'),
                                           des_usuario:localStorage.getItem('Nombre'),
                                          },
                                          disableClose: true,
                                  panelClass: 'pedidos_modal3',
                                  backdropClass: "bdrop"
                                });
                            dialogRef.afterClosed().subscribe(result => {
                            if(result !== 'cerrar' && result !== 'salir'){
                              console.log(result,'cuentas');
                              this.import_cuotas_clientes(result);
                             }
                          });
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
  
  generarReporte(){
    try {
        this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
        cod_menu: localStorage.getItem('sub_main'),
        cod_sesion: localStorage.getItem('cod_sesion'),
        dir_ip_client: localStorage.getItem('dir_ip'),
        sesion_unica:sessionStorage.getItem('sesion_unica')};
        this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
          response =>{
            if (response.resul) {
              
              this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
                width: '15%',
                height:'25%',
                data: {
                        accion:this.accion},
                disableClose: true,
                panelClass: 'spinner',
                backdropClass: "bdrop"
              });
              var datos = {
                cod_usuario:localStorage.getItem('cod_usuario'),
                des_usuario:localStorage.getItem('Nombre')
              }
              this.servicio.generarReporteCuotasHttp(datos).subscribe(
                response =>{
      
                  this.dialogSpin.close();
      
                  this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
                  
                  this.listarCuotasVsVentas(null);
      
                },
                error =>{
                  this.dialogSpin.close();
                  this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
      
                }
              )       
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
      ); 
    } catch (error) {
      console.log(error);
      this.dialogSpin.close();
      this.listarCuotasVsVentas(null);
    } 
  }
  import_cuotas_clientes(xdatos){
    try {

          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
  
          this.servicio.gestionVentasCuotasReporthttp(xdatos).subscribe(
            response =>{
  
              this.dialogSpin.close();
  
              this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
              
              this.listarCuotasVsVentas(null);
  
            },
            error =>{
              this.dialogSpin.close();
              this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
  
            }
          )        
      
    } catch (error) {
      console.log(error);
      this.dialogSpin.close();
      this.listarCuotasVsVentas(null);
    } 
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

}
