import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';


@Component({
  selector: 'app-form-daily-vista-documento',
  templateUrl: './form-daily-vista-documento.component.html',
  styleUrls: ['./form-daily-vista-documento.component.css']
})
export class FormDailyVistaDocumentoComponent implements OnInit {


  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
dialogSpin:any;
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
inventario :any;
cod_deposito:any;
observador:any = false;
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormDailyVistaDocumentoComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

    }

  ngOnInit(): void {

    this.listarFormulaprod();

  }
  ngAfterViewInit() {

  }

  listarFormulaprod(){

          this.ELEMENT_DATA = this.data.facturas;
          this.ELEMENT_DATA2 = this.data.fci;

             this.displayedColumns = [
                                       'cod_documento',
                                       'fecha_inicio',
                                       'des_cliente',
                                       'monto_base_usd',
                                     ];
              this.displayedColumns2 = [
                                        'cod_documento',
                                        'fecha_inicio',
                                        'des_cliente',
                                        'monto_base_usd',
                                     ];
             this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
             
 }




  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

 

}
