import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { ToastNotificationService } from '../../servicios/toast-notification.service';


@Component({
  selector: 'app-form-soporte',
  templateUrl: './form-soporte.component.html',
  styleUrls: ['./form-soporte.component.css']
})
export class FormSoporteComponent implements OnInit {

  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
forma:FormGroup;
accion:any;
unidad:any;
dialogSpin:any;
listas:any = [];
solicitud:any;
tarea_hija:any = [];
almacen:any = [];
asociar:any = false;
actualizar:any = false;
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    private snackBar: MatSnackBar, 
    private dialog: MatDialog, 
    private fb:FormBuilder,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    private dialogRef: MatDialogRef<FormSoporteComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.solicitud = data.tarea_padre;
    for (const i in this.data.listas) {
        this.listas.push(this.data.listas[i]);
    }
    for (const i in this.data.almacen) {
      this.almacen.push(this.data.almacen[i]);
  }
      this.forma = fb.group({
        'cod_ticket':'',
        'tipo_solicitud':['',[Validators.required]],
        'tipo_soporte':['',[Validators.required]],
        'descripcion_soporte':['',[Validators.required]],
        'cod_usuario':'',
        'des_usuario':'',

      });
    }
  ngOnInit(): void {
  }
  modalrespuesta(){
        var accion2 = 'enviarcliente';
        var dialogRefp = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: {id:this.data.id_cliente,
                accion:accion2},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
          dialogRefp.afterClosed().subscribe(async result => {
            if(result !== 'cerrar'){
              this.forma.patchValue({
                cod_usuario: await this.atrb.COD_USUARIO.cod_usuario,
                des_usuario: await this.hashkey.decrypt(await this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(await this.atrb.APELLIDO_USUARIO.apellido)
              });
            this.dialogRef.close(this.forma.value);
            }
          });
        }

  actualizardatos(res:any){
    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
    }else{

    console.log(this.forma.value);
    this.dialogRef.close(this.forma.value);
    }
  }

 async buscar_Tarea_hija(xvalor:any){
    try {
    const response = await this.servicio.buscarTareaHijahttp(xvalor).toPromise();
        this.tarea_hija = [];
        for (const i in response) {
          this.tarea_hija.push(response[i]);
        }
    } catch (error) {
      console.log(error);
    }
  }

  cerrarmodal(){
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
              maxWidth: '100vw',
              height:'auto',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner()
      if (result != 'cerrar') {
        await this.util.cerrarSpinner();
        this.dialogRef.close('cerrar');
      }else{
        await this.util.cerrarSpinner()
      }
    });
  
  }

}
