import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormRolesComponent} from '../../../shared/modal/form-roles/form-roles.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {FormRolmenuComponent} from '../../../shared/modal/form-rolmenu/form-rolmenu.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'; 
import { PeriodicElementroles } from '../../../shared/models/intefaceroles';
import { PeriodicElementrolemenu } from '../../../shared/models/interfacerolmenu';
import { PeriodicElementusuariorol } from '../../../shared/models/interfaceusuariorol';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit, AfterViewInit {
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any=[];
  xobj4:any=[];
  xobj5:any=[];

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  
  accion:any;
  ELEMENT_DATA:PeriodicElementroles[]=[];
  ELEMENT_DATA2:PeriodicElementrolemenu[]=[];
  ELEMENT_DATA3:PeriodicElementusuariorol[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
  displayedColumns3:string[];
   dataSource=new MatTableDataSource<PeriodicElementroles>();
   dataSource1=new MatTableDataSource<PeriodicElementrolemenu>();
   dataSource2=new MatTableDataSource<PeriodicElementusuariorol>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) { 
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        console.log(error);
      }
    );
  }

  ngOnInit(): void {
    this.listarusuariorol(null);
  }
  @ViewChild('paginat') paginator: MatPaginator;
  @ViewChild('paginat2') paginator2: MatPaginator;
  @ViewChild('paginat3') paginator3: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource1.paginator = this.paginator2;
    this.dataSource2.paginator = this.paginator3;
    console.log(this.dataSource.paginator);
    console.log(this.dataSource1.paginator);
    console.log(this.dataSource2.paginator);
  }

  /////////////////////////////////////////////////////////////////////
  
  listarusuariorol(msj:any){
    let msg = msj;
    this.servicio.listarUsuarioRolhttp().subscribe(
      response =>{
        if(response){
          console.log(response,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA3 = [];
              for (const i in response) {
                  
                this.ELEMENT_DATA3.push({
                  cod_usuario_rol:response[i].cod_usuario_rol,  
                  cod_usuario: response[i].cod_usuario, 
                  des_usuario:response[i].des_usuario, 
                  cod_rol:response[i].cod_rol, 
                  des_rol:response[i].des_rol,
                  cod_menu:response[i].cod_menu, 
                  des_menu:response[i].des_menu,
                  padre_menu:response[i].padre_menu, 
                  des_padre_menu:response[i].des_padre_menu, 
                  accion:'',
                  accion2:''
                });
              }
             this.displayedColumns3 = ['cod_usuario_rol', 
                                      'cod_usuario',
                                      'des_usuario',
                                      'cod_rol',
                                      'des_rol',
                                      'cod_menu',
                                      'des_menu',
                                      'padre_menu',
                                      'des_padre_menu',
                                      'Eliminar', 
                                      'Actualizar'];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource2 = new MatTableDataSource<PeriodicElementusuariorol>(this.ELEMENT_DATA3);
              this.dataSource2.paginator = this.paginator3;
        }
      },
      error =>{
        console.log('una respuesta');
        console.log(<any>error.error[0].message);
        this.displayedColumns3 = ['cod_usuario_rol', 
                                      'cod_usuario',
                                      'des_usuario',
                                      'cod_rol',
                                      'des_rol',
                                      'cod_menu',
                                      'des_menu',
                                      'padre_menu',
                                      'des_padre_menu',
                                      'Eliminar', 
                                      'Actualizar'];
        this.dataSource2 = new MatTableDataSource<PeriodicElementusuariorol>();
              this.dataSource2.paginator = this.paginator3;
      }
    );
    console.log(msg);
    if(msg == 'actualizar'){
      this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    return this.xobj2;
  }

   eliminarusuariorol(id:any){
    console.log('eliminar');
    this.servicio.eliminarusuariorolmenuhttp(id).subscribe(
      response =>{
        this.xobj3=[];
        this.xobj4=[];
        this.xobj5=[];
        this.listarusuariorol(null);
              this.snackBar.open(response[0].message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          
      }
    );
    
    return this.obj;
  }
 
  modalrespuesta(obj:any, text:any){
    this.accion = text;
          var dialogRef = this.dialog.open(FormmMsgComponent, {
            width: '24%',
            height:'25%',
            data: {id:obj.cod_usuario_rol, 
                  accion:this.accion},
            disableClose: true,
            panelClass: 'elem_modal' 
          });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result.id);
          let resultado = this.eliminarusuariorol(result.id);
          
        } 
      });
  }
  
  editardatos(obj:any, text:any){
    console.log(obj, 'segundo');
    if (text == 'actualizar') {
      this.accion = text;
      var dialogRef = this.dialog.open(FormRolesComponent, {
        width: '35%',
        height:'67%',
                data: {cod_rol:obj.cod_rol, 
                des_rol: obj.des_rol,
                estatus_rol: obj.estatus_rol,
                cod_unidad: obj.cod_unidad,
                des_unidad: obj.des_unidad,
                tipo_rol: obj.tipo_rol,
                unidad:this.xobj3,
                codmenu:this.xobj4,
                desmenu:this.xobj5,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal' 
      });
    }else{
      this.accion = text;
       dialogRef = this.dialog.open(FormRolesComponent, {
        width: '35%',
        height:'46%',
        data: {id:'', unidad:this.xobj3, codmenu:this.xobj4, desmenu:this.xobj5, accion:this.accion},
        disableClose: true 
      });
    }
    
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log('The dialog was closed');
        console.log(result);
        result.des_unidad = result.cod_unidad.des_unidad;
        result.cod_unidad = result.cod_unidad.cod_unidad;
        console.log(result,'seteado');
        this.xobj5=[];
         let resultado = this.actualizardatos(result);
        
      }
    });
  }

   actualizardatos(xvalor:any){
   
   var resultado = this.servicio.gestionRoleshttp(xvalor).subscribe(
      response =>{
        if(response){
             console.log(response,'una respuesta');
            let xobj2 = response.resul;
            this.ELEMENT_DATA = [];
                for (const i in xobj2) {
                    
                  this.ELEMENT_DATA.push({
                    cod_rol:xobj2[i].cod_rol,  
                    estatus_rol: xobj2[i].estatus_rol,
                    cod_unidad: xobj2[i].cod_unidad,
                    des_rol:xobj2[i].des_rol,
                    des_unidad:xobj2[i].des_unidad,
                    tipo_rol:xobj2[i].tipo_rol, 
                    accion:'',
                    accion2:'',
                  });
                }
                this.displayedColumns = ['cod_rol', 
                                      'des_rol',
                                      'tipo_rol',
                                      'cod_unidad',
                                      'des_unidad',
                                      'estatus_rol',
                                      'Eliminar', 
                                      'Actualizar'];
               
                this.dataSource = new MatTableDataSource<PeriodicElementroles>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
             var bool = true; 
            
                this.xobj5=[];
             for (const i in response.resul) {
              this.xobj5.push(response.resul[i]);
            }
            console.log(this.xobj5);
            this.snackBar.open(response.message,'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
             return bool;
        }
        
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
    
    
    return resultado;
  }
   public buscarUsuariorol(xvalor:string){
      var valor:any = {valor:xvalor}
      console.log(valor);
    this.servicio.buscarUSUARIOROLparamhttp(valor).subscribe(
      response =>{
        if(response){
          console.log(response, 'busqueda' );
          this.ELEMENT_DATA3 = [];
          for (const i in response) {
                  
            this.ELEMENT_DATA3.push({
              cod_usuario_rol:response[i].cod_usuario_rol,  
              cod_usuario: response[i].cod_usuario, 
              des_usuario:response[i].des_usuario, 
              cod_rol:response[i].cod_rol, 
              des_rol:response[i].des_rol,
              cod_menu:response[i].cod_menu, 
              des_menu:response[i].des_menu,
              padre_menu:response[i].padre_menu, 
              des_padre_menu:response[i].des_padre_menu,
              accion:'',
              accion2:''
            });
          }
          this.displayedColumns3 = ['cod_usuario_rol', 
                                    'cod_usuario',
                                    'des_usuario',
                                    'cod_rol',
                                    'des_rol',
                                    'cod_menu',
                                    'des_menu',
                                    'padre_menu',
                                    'des_padre_menu',
                                    'Eliminar', 
                                    'Actualizar'];
                  /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource2 = new MatTableDataSource<PeriodicElementusuariorol>(this.ELEMENT_DATA3);
                  this.dataSource2.paginator = this.paginator3;
        }
      },
      error =>{
        console.log(<any>error.error[0].message);
        this.ELEMENT_DATA3 = [];
        this.displayedColumns3 = ['cod_usuario_rol', 
                                    'cod_usuario',
                                    'des_usuario',
                                    'cod_rol',
                                    'des_rol',
                                    'cod_menu',
                                    'des_menu',
                                    'padre_menu',
                                    'des_padre_menu',
                                    'Eliminar', 
                                    'Actualizar'];
                  /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource2 = new MatTableDataSource<PeriodicElementusuariorol>(this.ELEMENT_DATA3);
                  this.dataSource2.paginator = this.paginator3;
        this.snackBar.open(<any>error.error[0].message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  } 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }
  /* handleFile(event) {
    let file = event.target.files[0];
    
    var id = document.getElementById('file');
    console.log(file);
    var nombre = 'Codigo de Unidad';
    this.servicio.processFileToJson({}, file).subscribe(data => {
      let arry = data.sheets.Hoja1;
      console.log(arry);
      for (const i in arry) {
          console.log(arry[i]);
          this.result.push(arry[i]);
      }
      if (this.result.length != 0) {
        console.log('entro en el if');
          this.servicio.insertarmasivohttp(this.result).subscribe(
            response =>{
              if(response){
                console.log(response,'una respuesta');
                let xobj2 = response.resul;
                this.ELEMENT_DATA = [];
                    for (const i in xobj2) {
                        
                      this.ELEMENT_DATA.push({id:xobj2[i].id, Nombre: xobj2[i].nombre_usu, Apellido:  xobj2[i].apellido_usu , Direccion:  xobj2[i].direccion_usu,  Correo: xobj2[i].correo_usu, Accion: '', Accion2: ''});
                    }
                   this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];
                   
                    this.dataSource = new MatTableDataSource<PeriodicElement2>(this.ELEMENT_DATA);
                    this.dataSource.paginator = this.paginator;
                 var bool = true; 
                 console.log(response.resul);
                this.snackBar.open(response.message,'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                 return bool;
              }
            },
            error =>{
              console.log(<any>error);
            }
          );
      }
      /* this.result = JSON.stringify(data['sheets'].Sheet1); */
      /* this.snackBar.open('El archivo se cargo de forma correcta en la base de datos', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,}); */
  /*   })
    event.srcElement.value = null;
  } */ 
  /////DATATABLE
  /* applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
  } */

}
