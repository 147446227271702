import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { Element } from '@angular/compiler';
import { element } from 'protractor';
import * as moment from 'moment'
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { FormCrearOcProductosxproveedorComponent } from '../form-crear-oc-productosxproveedor/form-crear-oc-productosxproveedor.component';
import { FormRecepcionVerProductosRecepcionComponent } from '../form-recepcion-ver-productos-recepcion/form-recepcion-ver-productos-recepcion.component';


@Component({
  selector: 'app-form-recepciones-ver-completas',
  templateUrl: './form-recepciones-ver-completas.component.html',
  styleUrls: ['./form-recepciones-ver-completas.component.css']
})
export class FormRecepcionesVerCompletasComponent implements OnInit {

  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dolar1:any = 0
  dolar2:any = 0
  dolar3:any = 0
  bolivar1:any = 0
  bolivar2:any = 0
  bolivar3:any = 0

  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  aprobar:any=false

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';



  constructor( public dialog: MatDialog,fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormRecepcionesVerCompletasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
   @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices) {}


   ngOnInit(): void {
    this.mostrarTabla()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  mostrarTabla(){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {

        
        this.servicio.listarOrdenesDCompraPendientehttp().subscribe(
          response =>{

              this.dialogSpin.close();

              console.log(response)

              let array = response.result.filter(element => element.estatus == "C")

              this.ELEMENT_DATA = array.reverse();
                 
              this.displayedColumns = [
                'cod_orden_compra',
                'cod_proveedor',
                'des_proveedor',
                'estatus',
                'fecha',
                'ver'
              ];
                /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;

          },
          error =>{
            this.dialogSpin.close();

            this.displayedColumns = [
              'cod_orden_compra',
              'cod_proveedor',
              'des_proveedor',
              'estatus',
              'fecha',
              'ver'
            ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
          }
        );
        
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  
  verRecepcion(element:any){

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

        this.dialogSpin.close()
  
        var dialogRef;
        dialogRef = this.dialog.open(FormRecepcionVerProductosRecepcionComponent,     {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data:{orden:element},
        disableClose:   true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });
  
        dialogRef.afterClosed().subscribe(resul => {
  
          if(resul != 'cerrar'){
  
            this.mostrarTabla()
  
          }
  
       
      })
      },
      error =>{
        this.dialogSpin.close()
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
      )


    }, 1000);    
  }
 
  

  en_construccion(): void {

    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    } catch (error) {
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
