<div id="modal_carrito" style=" background-image: linear-gradient(to left top, #90b9dc, #70a3d3, #518cca, #3276bf, #045fb4);;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container" >

        <div class="row mt-3 justify-content-between px-3">
            <h2 class="m-0 text-light">Solicitudes Aprobadas</h2>
            <button (click)="cerrar()" class="boton"> <mat-icon style="color: #ff2b34;" >clear</mat-icon> </button>
        </div>

        <div id="carrito_p6" class="row mt-2 mat-elevation-z8" style=" border: 2px solid #045fb4; background-color: #f1ede9; ">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                <table id="htmlData" #htmlData mat-table [dataSource]="dataSource" class=" text-center table-striped table-bordered table-condensed table-hover">
                    <!-- Weight Column -->
                     <ng-container matColumnDef="cod_solicitud_compra">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Codigo de Solicitud</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_solicitud_compra}} </td>
                     </ng-container>
   
                     <ng-container matColumnDef="cod_orden_req">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Codigo de Requisición</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_orden_req}} </td>
                     </ng-container>
     
     
                     <ng-container matColumnDef="tipo_solicitud_compra">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Tipo de Solicitud</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_solicitud_compra}}</strong> </td>
                     </ng-container>
   
                     <ng-container matColumnDef="tipo_producto">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Tipo de Producto</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_producto}}</strong> </td>
                     </ng-container>
   
                     <ng-container matColumnDef="estatus">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Estatus</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;" [ngSwitch]="element.estatus">
                        
                         
                           <div *ngSwitchCase="'P'" style="background-color: var(--warning); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Esperando Aprobacion</strong></div> 
                           <div *ngSwitchCase="'SCA'" style="background-color: var(--success); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Aprobada</strong> </div>
                           <div *ngSwitchCase="'SCD'" style="background-color: var(--danger); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Desaprobada</strong></div>
                           <strong *ngSwitchDefault >{{element.estatus}}</strong> 
                           
                       </td>
                        
                     </ng-container>
     
   
                     <ng-container matColumnDef="departamento">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Deparatamento</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;text-transform: uppercase;">  <strong>{{element.departamento}}</strong> </td>
                     </ng-container>
   
                     <ng-container matColumnDef="prioridad">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Prioridad</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }" style="padding: 5px; font-size: 12px;text-transform: uppercase;" [ngSwitch]="element.prioridad">  
                         
                         <div *ngSwitchCase="'Baja'" style="background-color: #00D0FF; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong></div> 
                         <div *ngSwitchCase="'Media'" style="background-color: #FFC35E; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong> </div>
                         <div *ngSwitchCase="'Alta'" style="background-color: red; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong></div>
                         <strong *ngSwitchDefault >{{element.prioridad}}</strong> 
                         
                       </td>
                     </ng-container>
   
                     <ng-container matColumnDef="fecha">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Fecha</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.fecha}}</strong> </td>
                     </ng-container>
   
                     <ng-container matColumnDef="fecha_estimada">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Fecha Estimada</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.fecha_estimada}}</strong> </td>
                     </ng-container>
   
                     <ng-container  matColumnDef="actualizar">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Editar</strong></h5>
                       </th>
                     
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="text-center" >
                           <button matTooltip="EDITAR" (click)='update_insert_data(element, "actualizar")' class="btn rounded-pill btn-sm" [disabled]="element.estatus != 'P'" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(171, 20, 241);">
                               <mat-icon>edit</mat-icon>
                           </button>
                          <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                               <mat-icon>edit</mat-icon>
                           </button> -->
                       </td>
                     </ng-container>
   
                     <ng-container disabled="aprobado" matColumnDef="aprobado">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Ver</strong></h5>
                       </th>
                     
                       <td mat-cell *matCellDef="let element"   [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="text-center" >
                           <button matTooltip="MOSTRAR" *ngIf="element.cod_solicitud_compra != ''"  (click)='verAprobacion(element, "aprobar")' class="btn btn-secondary btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); border: 2px solid; background-color: #A15A99;">
                               <mat-icon>remove_red_eye</mat-icon>
                           </button>
                          <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                               <mat-icon>edit</mat-icon>
                           </button> -->
                       </td>
                     </ng-container>
   
                     <ng-container disabled="aprobar" matColumnDef="aprobar">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Aprobar</strong></h5>
                       </th>
                     
                       <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }" style="padding: 1px; font-size: 12px;" class="text-center" >
                           <button matTooltip="APROBAR" *ngIf="element.cod_solicitud_compra != ''" [disabled]="element.estatus != 'P'"(click)='aprobarSolicitudCompra(element, "aprobar")' class="btn btn-success btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); border: 2px solid;">
                               <mat-icon>done_all</mat-icon>
                           </button>
                          <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                               <mat-icon>edit</mat-icon>
                           </button> -->
                       </td>
                     </ng-container>
     
     
                    
     
                     <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                     <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
       
                 </table>
     
                 <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 
               
            </div>

        </div>
    </div>
</div>
