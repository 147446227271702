import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-cantidad-toma-fisica-completa',
  templateUrl: './form-cantidad-toma-fisica-completa.component.html',
  styleUrls: ['./form-cantidad-toma-fisica-completa.component.css']
})
export class FormCantidadTomaFisicaCompletaComponent implements OnInit {

  @ViewChild('cant_ingresada',{static:true}) cant_ingresada:ElementRef;
  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  forma:FormGroup;
  producto:any=[];
  codigo:any=[];

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCantidadTomaFisicaCompletaComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { 


      this.producto = data.des_producto;
      this.codigo = data.cod_producto;
      this.forma = fb.group({
        'cant_producto':data.cant_producto,
        'cant_ingresada':['',[Validators.required]]
      });

    }

  ngOnInit(): void {
  }

  devolucion_prod(xvalor:any){
     try {
        var xobj
        console.log(this.cant_ingresada.nativeElement.value, 'estamos aqui, aqui nos quedamos');
        if (!isNaN(this.cant_ingresada.nativeElement.value)) {
          
            xobj =  {cant_producto:this.data.cant_producto, 
                     cant_ingresada:xvalor,
                     cod_producto:this.data.cod_producto,
                     fase:this.data.fase
                    }
            this.dialogRefp.close(xobj);
          
        }else{
          this.snackBar.open('Use solo numeros por favor.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    } 
  }

}
