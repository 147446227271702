<div class="fadeIn2 animated fast">
    <nav class="navbar navbar-expand-lg " style="height: 7%; border:1px solid #cccccccc ; background-color: rgba(156, 152, 152, 0.26);">
        <span class="navbar-brand" style=" font-size: 14px; color: black; border-bottom: 1px solid #000;"><b>ALMACEN  PRODUCTO DAÑADOS</b></span>
        <div class=" navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarInventarioPD("actualizar")'><mat-icon >sync</mat-icon></button></li>
                <li class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                <li class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                <li class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
            </ul>
            <div class=" ">
                <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
            </div>
        </div>
    </nav>
    <div class="container-fluid">
        <div class="">
            <div class="col-md-12 col-sm-12 col-xs-12 ">
                <br>
                <div class="example-container mat-elevation-z8 table-responsive">

                    <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
                        <ng-container matColumnDef="cod_lote">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Codigo del lote</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_lote}} </td>
                        </ng-container>
                        <ng-container matColumnDef="cod_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Cod producto</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_producto}} </td>
                        </ng-container>
                        <ng-container matColumnDef="des_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Producto</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_producto}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cod_usuario">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Cod usuario</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_usuario}} </td>
                        </ng-container>
                        <ng-container matColumnDef="des_usuario">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Usuario</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario}} </td>
                        </ng-container>

                        <ng-container matColumnDef="des_causa">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Causa</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_causa}} </td>
                        </ng-container>
                        <ng-container matColumnDef="fecha">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Fecha</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha}} </td>
                        </ng-container>
                        <ng-container matColumnDef="hora">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Hora</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 7, 9]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>


</div>
