import { forIn, result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormFormulaUnidadComponent } from '../form-formula-unidad/form-formula-unidad.component';

interface interfacefactura{
  cod_lote: string;
  cant_producto:string;
  cod_producto:string;
  des_producto:string;
  cant_req:string;
  restante:string;
}
interface interdatosfacefactura{
cod_guia_despacho:string;
cod_factura:string;
cod_pedido:string;
fecha_inicio:string;
}
type TableRow = [string, string, string, string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-form-ord-historial-mpp',
  templateUrl: './form-ord-historial-mpp.component.html',
  styleUrls: ['./form-ord-historial-mpp.component.css']
})
export class FormOrdHistorialMppComponent implements OnInit {
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  forma:FormGroup;
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  cod_orden_producto:any;
  cantidad_prod:any;
  cod_prod:any;
  des_prod:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormOrdHistorialMppComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {

      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'cant_producto':'',
        'ord_producto':'',
        'unidad_prod':'',
        'cod_orden_producto':''

      });

  }
  ngOnInit(): void {
    this.listarFormulasHistorial(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////


  listarFormulasHistorial(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        var valor = {termino:this.data.termino}
        this.servicio.listarFormulasHistorialhttp(this.data.objeto).subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              switch (this.data.objeto.termino) {
                case 'PT':
                  console.log(response,'una respuesta');
                  this.ELEMENT_DATA = [];
                      for (const i in response) {
                        if (response[i].cod_formula === null || response[i].cod_formula === '') {
                          response[i].cod_formula = 'SNFRML';
                        }
        
                        this.ELEMENT_DATA.push({
                          cod_formula:response[i].cod_formula,
                          cod_usuario:response[i].cod_usuario,
                          des_usuario:response[i].des_usuario,
                          cod_usuario_cambio: response[i].cod_usuario_cambio,
                          des_usuario_cambio: response[i].des_usuario_cambio,
                          cod_prod:response[i].cod_prod,
                          des_prod:response[i].des_prod,
                          fecha_inicio:response[i].fecha_inicio,
                          hora_inicio:response[i].hora_inicio,
                          fecha_cambio:response[i].fecha_cambio,
                          hora_cambio:response[i].hora_cambio,
                          estatus:response[i].estatus,
                          accion:'',
                          accion2:'',
                          accion3:''
                        });
                      }
                      this.displayedColumns = [ 'cod_formula',
                                                'cod_prod',
                                                'des_prod',
                                                'cod_usuario',
                                                'des_usuario',
                                                'fecha_inicio',
                                                'hora_inicio',
                                                'cod_usuario_cambio',
                                                'des_usuario_cambio',
                                                'fecha_cambio',
                                                'hora_cambio',
                                                'estatus',
                                                'detalle',
                                                'activar',
                                              ];
                       /* this.dataSource = this.ELEMENT_DATA; */
                      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                      this.dataSource.paginator = this.paginator;
                  break;
                case 'MP':
                  console.log(response,'una respuesta');
                  this.ELEMENT_DATA = [];
                      for (const i in response) {
                        if (response[i].cod_formula === null || response[i].cod_formula === '') {
                          response[i].cod_formula = 'SNFRML';
                        }
        
                        this.ELEMENT_DATA.push({
                          cod_formula:response[i].cod_formula,
                          cod_usuario:response[i].cod_usuario,
                          des_usuario:response[i].des_usuario,
                          cod_usuario_cambio: response[i].cod_usuario_cambio,
                          des_usuario_cambio: response[i].des_usuario_cambio,
                          cod_prod:response[i].cod_prod,
                          des_prod:response[i].des_prod,
                          fecha_inicio:response[i].fecha_inicio,
                          hora_inicio:response[i].hora_inicio,
                          fecha_cambio:response[i].fecha_cambio,
                          hora_cambio:response[i].hora_cambio,
                          estatus:response[i].estatus,
                          accion:'',
                          accion2:'',
                          accion3:''
                        });
                      }
                      this.displayedColumns = [ 'cod_formula',
                      'cod_prod',
                      'des_prod',
                      'cod_usuario',
                      'des_usuario',
                      'fecha_inicio',
                      'hora_inicio',
                      'cod_usuario_cambio',
                      'des_usuario_cambio',
                      'fecha_cambio',
                      'hora_cambio',
                      'estatus',
                      'detalle',
                      'activar',
                    ];
                       /* this.dataSource = this.ELEMENT_DATA; */
                      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                      this.dataSource.paginator = this.paginator;
                  break;
              
                default:
                  break;
              }
              
            }
          },
          error =>{
            this.dialogSpin.close();
            switch (this.data.objeto.termino) {
              case 'PT':
                this.dialogSpin.close();
                console.log('una respuesta');
                console.log(<any>error.error.message);
                this.displayedColumns = [ 'cod_formula',
                'cod_prod',
                'des_prod',
                'cod_usuario',
                'des_usuario',
                'fecha_inicio',
                'hora_inicio',
                'cod_usuario_cambio',
                'des_usuario_cambio',
                'fecha_cambio',
                'hora_cambio',
                'estatus',
                'detalle',
                'activar',
              ];
                this.dataSource = new MatTableDataSource<any>();
                      this.dataSource.paginator = this.paginator;
                      this.snackBar.open(<any>error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,});
                break;
              case 'MP':
                this.dialogSpin.close();
                console.log('una respuesta');
                console.log(<any>error.error.message);
                this.displayedColumns = [ 'cod_formula',
                'cod_prod',
                'des_prod',
                'cod_usuario',
                'des_usuario',
                'fecha_inicio',
                'hora_inicio',
                'cod_usuario_cambio',
                'des_usuario_cambio',
                'fecha_cambio',
                'hora_cambio',
                'estatus',
                'detalle',
                'activar',
              ];
                this.dataSource = new MatTableDataSource<any>();
                      this.dataSource.paginator = this.paginator;
                      this.snackBar.open(<any>error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,});
                break;
              default:
                break;
            }
            
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
  }
  
 //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////

 async detalleOrdenProd(xvalor:any){
  console.log(xvalor);
  xvalor.cod_orden_producto = 'N/A';
  xvalor.cant_producto = '0';
  xvalor.cod_producto = xvalor.cod_prod;
  this.dialog.open(FormFormulaUnidadComponent, {
    width: '100vw',
    maxWidth: '100vw',
    height:'100vh',
    data: {producto: xvalor},
    panelClass: 'pedidos_modal3',
    backdropClass: "bdrop"
  });
}

 activar_formulas_pt(xvalor:any,text:any): void {
  try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
    cod_menu: localStorage.getItem('sub_main'),
    cod_sesion: localStorage.getItem('cod_sesion'),
    dir_ip_client: localStorage.getItem('dir_ip'),
  sesion_unica:sessionStorage.getItem('sesion_unica')};
   this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
     response =>{
       if (response.resul) {
         this.accion = text;
         switch (this.data.objeto.termino) {
           case 'PT':
             
             var dialogRef = this.dialog.open(FormmMsgComponent, {
               width: '100vw',
               maxWidth: '100vw',
               height:'100vh',
               data: {id:'',
                     accion:this.accion},
               disableClose: true,
               panelClass: 'pedidos_modal',
               backdropClass: "bdrop"
             });
             dialogRef.afterClosed().subscribe(result => {
               if (result != 'cerrar' && result != 'salir' ) {
                 dialogRef.close();
                 xvalor.termino = this.data.objeto.termino;
                 xvalor.comentario = result.comentario;
                 console.log(xvalor,'valor de formula');
                 this.gestionar_activacion_formula(xvalor);
               }else{
                 dialogRef.close();
                 
               }
           
             });
             break;
             case 'MP':
             
             var dialogRef = this.dialog.open(FormmMsgComponent, {
               width: '100vw',
               maxWidth: '100vw',
               height:'100vh',
               data: {id:'',
                     accion:this.accion},
               disableClose: true,
               panelClass: 'pedidos_modal',
               backdropClass: "bdrop"
             });
             dialogRef.afterClosed().subscribe(result => {
               if (result != 'cerrar' && result != 'salir' ) {
                 dialogRef.close();
                 xvalor.termino = this.data.objeto.termino;
                 xvalor.comentario = result.comentario;
                 this.gestionar_activacion_formula(xvalor);
               }else{
                 dialogRef.close();
                 
               }
           
             });
             break;
         
           default:
             break;
         }
       }else{
         this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
       }
     },
     error =>{
       this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   ); 
    
  } catch (error) {
   console.log(error);
   this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
     verticalPosition: this.verticalPosition,})
  }
}

gestionar_activacion_formula(xvalor:any){

  console.log(this.cod_prod);
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });
  setTimeout(() => {
   this.servicio.activarFormulasProdhttp(xvalor).subscribe(
    async  response =>{
      this.dialogSpin.close();
      
         this.ELEMENT_DATA = [];
         this.listarFormulasHistorial(null);
         this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
     },
     error =>{
      this.dialogSpin.close();
       console.log(error);
       console.log(<any>error.error.message);
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
   );
  }, 1000);
}

 imprimir_descargar(xvalor:any,text:any): void {
   try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                cod_menu: localStorage.getItem('sub_main'),
                cod_sesion: localStorage.getItem('cod_sesion'),
                dir_ip_client: localStorage.getItem('dir_ip'),
              sesion_unica:sessionStorage.getItem('sesion_unica')};
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
        if (response.resul) {
          this.accion = text;
          switch (this.accion) {
            case 'imprimir':
              this.accion = 'enviarpedido';
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                maxWidth: '100vw',
                height:'100%',
                data: {id:'',
                      accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cerrar' && result != 'salir' ) {
                  dialogRef.close();
                  this.imprimirordenes(xvalor);
                }else{
                  dialogRef.close();
                  
                }
            
              });
              break;
              case 'descargar':
              this.accion = 'enviarpedido';
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                maxWidth: '100vw',
                height:'100%',
                data: {id:'',
                      accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cerrar' && result != 'salir' ) {
                  dialogRef.close();
                  this.imprimirordenes(xvalor);
                }else{
                  dialogRef.close();
                  
                }
            
              });
              break;
          
            default:
              break;
          }
        }else{
          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 
     
   } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
   }
}

  imprimirordenes(xvalor:any){

    console.log(this.cod_prod);
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      const pdf = new PdfMakeWrapper();
      var valor = {cod_orden_producto:xvalor.cod_orden_prod}
     this.servicio.buscarFormulaProdhttp(xvalor).subscribe(
      async  response =>{
        this.dialogSpin.close();
         console.log(response);
           this.ELEMENT_DATA = [];

               for (const i in response.producto4) {
                 var resul = parseFloat(response.producto4[i].cantidad_prod_mp.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.')) * parseFloat(response.producto3[0].cant_producto.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.'));
                 this.ELEMENT_DATA.push({
                   cod_producto:response.producto4[i].cod_prod_mp,
                   des_producto:response.producto4[i].des_prod_mp,
                   cant_unidad_producto:response.producto4[i].cantidad_prod_mp,
                   tipo_producto_mp:response.producto4[i].tipo_producto_mp,
                   cant_producto:resul.toFixed(2).replace(/\D/g, "")
                   .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                   unidad_prod_mp:response.producto4[i].unidad_prod_mp,
                 });
               }
               console.log(this.ELEMENT_DATA);
               pdf.info({
            title: 'Orden de Producción N°'+xvalor.cod_orden_prod+'',
            author: 'Factory cosmetics C.A.',
            subject: 'Guia de despacho',
        });
        var titulo = 'FACTORY COSMETICS 3DG C.A.';
        var texto =
                      'Ordenes de Producción';
          pdf.pageMargins([20,5,20,5]);
          pdf.add(pdf.ln(1));
          pdf.add(
            new Columns([ new Table([['',titulo,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(14).bold().absolutePosition(20,58).end]).columnGap(5).end,
                                );
          pdf.add(
            new Columns([ new Table([[await new Img("../../../../../../assets/img/Factory (1).png").build(),'','']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(10,15).end]).columnGap(5).end,
                                );
          pdf.add(
                new Columns([ new Table([['',texto,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(12).absolutePosition(20,80).end]).columnGap(5).end,
                                );

          pdf.add(this.crearTabla25(xvalor,xvalor.fecha_inicio,xvalor.hora_inicio,response.producto4[0].cod_prod_pt, response.producto4[0].des_prod_pt,response.producto3[0].cant_producto,xvalor.cod_lote));
          pdf.pageSize('LETTER');
          pdf.add(this.headerTabla5(this.ELEMENT_DATA));
          pdf.add(this.crearTabla45(null));
          pdf.add(this.crearTabla46(null));
          pdf.add(pdf.ln(1));
           pdf.create().open();

       },
       error =>{
        this.dialogSpin.close();
         console.log(error);
         console.log(<any>error.error.message);
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition});
       }
     );
    }, 3000);
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

sin_permiso():void{
  try {
    this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

cerrarmodal(): void {
  this.accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100%',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
}

cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 2000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

//////////////////////REPORTES////////////////////////////

/* numerofactura(data:any):ITable{
  return new Table([
    ['Asociado a la Factura Nº:  '+data+'', '', '', '', ''],
    ]).widths([200,'*','*','*','*','*']).alignment('left').fontSize(10).layout('noBorders').end;
}
numerodespacho(data:any):ITable{
  return new Table([
    ['Guía de Despacho Nº:  '+data.cod_guia_despacho+'', '', '', '', '', 'Emisión: 00000000'],
    ]).widths([200,'*','*','*','*','100']).alignment('left').bold().fontSize(10).layout('noBorders').end;
}

headerTabla():ITable{
return new Table([
  ['Lote', 'Código', 'Descripción', 'Cantidad en Lote', 'Despacho', 'Restante']
]).layout({
  vLineWidth:(i:number, node:any, col:number)=>{
      return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
      return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
    }
}).widths(['*', '*', 200, '*', '*', '*']).alignment('center').fontSize(8).end;
}
crearTabla(data:interfacefactura[]):ITable{
return new Table([
...this.extraerData(data)

]).layout('lightHorizontalLines').widths(['*', '*', 200, '*', '*', '*']).alignment('center').fontSize(10).end;
}
extraerData(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[row.cod_lote,
                             row.cod_producto,
                             row.des_producto,
                             row.cant_producto,'','']);
    } */

    //////////////////////NOTAS ND////////////////////////////
getBase64ImageFromURL5(url) {
return new Promise((resolve, reject) => {
  var img = new Image();
  img.setAttribute("crossOrigin", "anonymous");

  img.onload = () => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    var dataURL = canvas.toDataURL("image/png");

    resolve(dataURL);
  };

  img.onerror = error => {
    reject(error);
  };

  img.src = url;
});
}
headerTabla5(data:any):ITable{
return new Table([
  ['Codigo del producto','Descripción del producto','Cantidad requerida', 'tipo','unidad'],
  ...this.extraerData5(data)
]).layout('lightHorizontalLines').widths([100,250,100,'*','*']).alignment('center').fontSize(10).absolutePosition(20,210).end;
}
crearTabla555(data:interfacefactura[]):ITable{
return new Table([
...this.extraerData5(data)

]).widths([20,'*','*','*','*','*']).alignment('left').fontSize(9).layout('lightHorizontalLines').absolutePosition(20,218).margin(40).end;
}
crearTabla55(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData55(data)

]).layout('lightHorizontalLines').widths(['*','*','*','*','*',20]).alignment('right').fontSize(9).absolutePosition(20,218).margin(40).end;
}
crearTabla5(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData555(data)

  ]).layout('lightHorizontalLines').widths(['*','*','*',200,'*','*']).alignment('left').fontSize(9).absolutePosition(20,218).margin(40).end;
  }
/* async cinta85(data:interdatosfacefactura[]){

return new Table([
  [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
} */
numerofact5(data:any):ITable{

return new Table([
  ['','','','', '','Orden de Producción Nº: '+data.cod_guia_despacho+''],
]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('lightHorizontalLines').absolutePosition(20,135).end;
}
crearTabla25(data:any, data1:any, data2:any, data3:any, data4:any, data5:any, data6:any){
  var nombre = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
  var codigo = localStorage.getItem('cod_usuario');
  if (data.cod_formula === null || data.cod_formula === undefined) {
    data.cod_formula = 'Formula no registrada';
  }
  return  new Columns([new Table([

      ['Orden de Producción Nº: '+data.cod_orden_producto+'','Usuario: '+data.des_usuario+''],
      ['Orden de Producción Primaria Nº: N/A','Codigo del usuario: '+data.cod_usuario+''],
      ['Codigo del producto Nº: '+data3+'','Codigo del lote Nº: '+data6+''],
      ['Descripción '+data4+'','Formula: '+data.cod_formula+''],
      ['Estatus: COMPLETA ',''],


    ]).widths([250,'*']).alignment('left').fontSize(9).bold().layout('noBorders').absolutePosition(20,125).end,
    new Table([

      [ '','Emisión: '+data1+''],
      [ '','Hora: '+data2+''],
      [ '','Cantidad solicitada: '+data5+''],
      ['','Cantidad fabricada: '+data.cant_final+''],

    ]).widths(['*',250]).alignment('right').fontSize(9).bold().layout('noBorders').absolutePosition(50,125).end]).columnGap(5).end;
    }
/* crearTabla35(data:interdatosfacefactura[]):ITable{
  return new Table([
    ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
  ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
  } */
   crearTabla45(data:any){

    return  new Columns([ new Table([
      ['Observaciones:','','','','',''],
      [' ','','','', '',''],
      [' ','','','','',''],
      [' ','','','','',''],
    ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(10).bold().layout({
      vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
        },
        hLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
        }
    }).absolutePosition(20,700).end,
    new Table([
      [' ','','','','',''],
      [' ','','','','',''],
      [' ','','','','',''],
      [' ','','','','','']
    ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(10).layout({
      vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
        }
    }).absolutePosition(20,700).end
  ]).columnGap(5).end

    }

    crearTabla46(data:any){

      return  new Columns([ new Table([
        ['Coordinador de operaciones: ______________________','','','','',''],
        [' ','','','', '','']

      ]).widths([250,'*','*','*','*','*']).alignment('left').fontSize(10).bold().layout('noBorders').absolutePosition(20,660).end,
      new Table([
        [' ','','','','','Analista de inventario: ______________________'],
        [' ','','','','','']
      ]).widths(['*','*','*','*','*',250]).alignment('right').fontSize(10).layout('noBorders').absolutePosition(20,660).end
    ]).columnGap(5).end

      }

    extraerData5(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[row.cod_producto,row.des_producto,
        row.cant_producto, row.tipo_producto_mp,row.unidad_prod_mp]);
    }
    extraerData55(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[ '',
                          row.cant_producto, row.cant_producto,'','']);
    }
    extraerData555(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[
                           '', row.des_producto,
                          '', '','']);
    }


}
