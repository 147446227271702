<div id="modal_carrito" class=" container-fluid" style="border-radius: 1rem;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid rgb(34, 50, 63); background-color: rgb(42, 14, 80); border-radius: 1rem 1rem 0 0;">
            <div id="content_1" class=" col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: rgb(42, 14, 80);">
                    <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff; font-size: 11px;">Codigo documento</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="Cliente:" formControlName="cod_documento" autocomplete="off" readonly>
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field> 
                </div>
            </div>
           <!--  <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: steelblue;">
                   <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff; font-size: 11px;">Cod de la factura</mat-label>
                       <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput placeholder="" formControlName="cod_factura" #num autocomplete="off" readonly>
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field> 
                </div>
            </div> -->
            <div class=" col-md-7 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255); font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_boton1" class="col-md-1 px-0 py-2 h-100">
                <div class="mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GENERAR NOTA DE CREDITO" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                        <mat-icon>save</mat-icon>
                    </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="salir">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div id="carrito_p2" class="row" style=" border: 2px solid steelblue; border-radius: 0 0 1rem 1rem;">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0  border h-100" style="background-color: rgb(42, 14, 80);">
                <table #table mat-table [dataSource]="dataSource" class=" table-primary  text-center table-striped table-bordered table-condensed table-hover">
                   
                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>codigo Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;"><!-- CANTIDAD DE PRODUCTOS EN EL PEDIDO --> </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.des_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;">  </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;"> {{cantidad_productos()}}  </td>    
                    </ng-container>

                    <ng-container matColumnDef="dev_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Devolución</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"><b>{{element.dev_producto}}</b></td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;"> {{cantidad_dev()}}  </td> 
                    </ng-container>
                    <ng-container matColumnDef="lote_1">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Lote A</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 9px; color: #fff; background-color: rgb(82, 36, 156);"><b>{{element.lote_1}}</b></td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;">   </td> 
                    </ng-container>
                    <ng-container matColumnDef="valor_1">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Valor A</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff; background-color: rgb(82, 36, 156);"><b>{{element.valor_1}}</b></td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;">   </td> 
                    </ng-container>
                    <ng-container matColumnDef="lote_2">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Lote B</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 9px; color: #fff; background-color: rgb(104, 64, 168);"><b>{{element.lote_2}} </b></td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;">  </td> 
                    </ng-container>
                    <ng-container matColumnDef="valor_2">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Valor B</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff; background-color: rgb(104, 64, 168);"><b>{{element.valor_2}} </b></td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;">  </td> 
                    </ng-container>
                    <ng-container matColumnDef="lote_3">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Lote C</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 9px; color: #fff; background-color: rgb(135, 99, 192);"><b>{{element.lote_3}}</b></td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;">   </td> 
                    </ng-container>
                    <ng-container matColumnDef="valor_3">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Valor C</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff; background-color: rgb(135, 99, 192);"><b>{{element.valor_3}}</b></td>
                         <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;">   </td> 
                    </ng-container>

                      <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: rgb(42, 14, 80); padding: 5px; color: #fff;">
                            <h5><strong>Seleccionar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <section style="margin-top: 10px;">
                                <div class="example-button-container" class="text-center">
                                    <mat-checkbox (change)="tomar_data($event.checked, element)"  class="example-margin"></mat-checkbox>
                                </div>
                            </section>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(42, 14, 80); color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
    </form>
</div>
