<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 5px;">
            <form [formGroup]="forma" class="">

                <div class="modal-content h-100">
                     <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-left"><strong>Nueva devolucion</strong> </h3>
          
                     </div>

                      <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class=""> 
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <mat-form-field class="materialinput">
                                            <mat-label style="color: #000">Codigo del documento:</mat-label>
                                            <input style="font-size: 12px; color: black;" maxlength="20" matInput placeholder="factura" formControlName="cod_documento" autocomplete="off">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            
                                            </span>
                                        </mat-form-field>
                                     </div>
                                </div>
                                
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                   
                                    <mat-form-field class="materialinput py-1 px-1">
                                        <mat-label style="color: #000;">Tipo devolución</mat-label>
                                        <input style="font-size: 11px; color: #000;" type="text" matInput placeholder="" formControlName="tipo_devolucion" [matAutocomplete]="autoGroup3" #tipo_dev_>
                                        <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>assignment</mat-icon>
                                            </span>
                                        <mat-autocomplete #autoGroup3="matAutocomplete">
                                            <mat-optgroup style="color: #000; font-size: 12px; " *ngFor="let group of tipo_dev | async" [label]="group.letter">
                                                <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                                    {{i}}
                                                </mat-option>
                
                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                  </div>
                                  <div class=""> 
                                    <div class="col-md-12 col-sm-12 col-xs-12 ">
                                       <mat-form-field class="materialinput" >
                                           <mat-label style="color: #000">Descripcion de devolucion:</mat-label>
                                           <textarea
                                               rows="2" cols="12"
                                               style="font-size: 12px; color: black;" 
                                               matInput placeholder="Descripcion" 
                                               autocomplete="off" formControlName="des_dev"> </textarea>
                                       </mat-form-field>
                                     </div>
                               </div>
                               <div class="materialinput py-0 px-3 d-flex w-100 flex-column col-12 align-items-center">    
                                    <mat-checkbox class='' formControlName="factura" >Factura</mat-checkbox>
                                    <mat-checkbox class='' formControlName="nota" >Nota  </mat-checkbox>
                                </div>
                            </div>
                        </div>
                      </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
        
                            <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button> <!-- [disabled]="forma.invalid" -->
                        </div>
                    </div>

                </div>
               
            </form>
        
        </div>

    </div>

</div>
