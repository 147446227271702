import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormCrearTareasComponent } from 'src/app/componentes/shared/modal/form-crear-tareas/form-crear-tareas.component';
import { FormPermisosAccionesComponent } from 'src/app/componentes/shared/modal/form-permisos-acciones/form-permisos-acciones.component';
import { FormDefinicionesModulosComponent } from 'src/app/componentes/shared/modal/form-definiciones-modulos/form-definiciones-modulos.component';
import { FormParametrosComponent } from 'src/app/componentes/shared/modal/form-parametros/form-parametros.component';
import { FormParametrosAccionesComponent } from 'src/app/componentes/shared/modal/form-parametros-acciones/form-parametros-acciones.component';
import { FormParametrosCorreosComponent } from 'src/app/componentes/shared/modal/form-parametros-correos/form-parametros-correos.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-definiciones-sistema',
  templateUrl: './definiciones-sistema.component.html',
  styleUrls: ['./definiciones-sistema.component.css']
})
export class DefinicionesSistemaComponent implements OnInit {
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  parametros_precios:any = [];
  parametros_operacion:any = [];
  accion:any;
  auth_menu:any = [];
  menu:any;
  cod_orden_producto:any;
  cantidad_prod:any;
  cod_prod:any;
  des_prod:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
  dataSource1=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  dataSource=new MatTableDataSource<any>();
  constructor(
              private servicio:nkServices, 
              private dialog: MatDialog, 
              private util:AppUtilService,
              private atrb:AtributosService,
              private hashkey:HashKeyServices,
              private notifi:ToastNotificationService,
    ){}
ngOnInit(){
    this.listarDefinicionesSistema(null);
  }
@ViewChild(MatPaginator) paginator: MatPaginator;

ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }
async listarDefinicionesSistema(msj:any){
    try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null).toPromise();
        let msg = msj;
       const response = await this.servicio.listarDefinicionesSistemahttp().toPromise();
              this.menu = response.resul;
              this.ELEMENT_DATA = [];
                  for (const i in response.def) {
                    this.ELEMENT_DATA.push({
                      cod_definicion:response.def[i].cod_definicion,
                      des_definicion:response.def[i].des_definicion,
                      cod_menu:response.def[i].cod_menu,
                      des_menu: response.def[i].des_menu,
                      cod_usuario:response.def[i].cod_usuario,
                      des_usuario: response.def[i].des_usuario,
                      fecha_inicio: response.def[i].fecha_inicio,
                      hora_inicio:response.def[i].hora_inicio,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [ 'cod_definicion',
                                            'des_definicion',
                                            'cod_menu',
                                            'des_menu',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'proceso'
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                  await this.util.cerrarSpinner();
                if(msg == await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                    await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                }else{
                  await this.util.cerrarSpinner();
                }
    } catch (error) {
            this.displayedColumns = [ 'cod_definicion',
                                            'des_definicion',
                                            'cod_menu',
                                            'des_menu',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'proceso'
                                          ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  await this.util.cerrarSpinner();
                  await this.notifi.customToastNotification(error.error.message);
    }
  }
async parametros_precios_create(text:any){
  try {
                  await this.util.abrirSpinner();
                  await this.servicio.consultar_sesionhttp(null).toPromise();
                          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def = {
                              val_def:await this.atrb.ACCESO_VALDEF.gestionar_definiciones,
                              tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
                              cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                            }
                            await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                          }
                          await this.util.cerrarSpinner();
                          var accion = text;
                          var dialogRef = this.dialog.open(FormParametrosComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                            data: {id:'',
                                  parametros:this.parametros_precios,
                                  operacion:this.parametros_operacion,
                                  accion:accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                                if(result !== 'cerrar'){
                                  await this.util.cerrarSpinner();
                                }else{
                                  await this.util.cerrarSpinner();
                                }
                          });
  } catch (error) {
    await this.util.cerrarSpinner();
          console.log(error);
      await this.notifi.customToastNotification(error.error.message);
  }
}
async parametros_acciones_create(text:any){
  try {
            await this.util.abrirSpinner();
            await this.servicio.consultar_sesionhttp(null).toPromise();
                 if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                  let val_def = {
                    val_def:await this.atrb.ACCESO_VALDEF.gestionar_definiciones,
                    tipo_operacion:await this.atrb.ACCESO_OPERACION.let_join,
                    cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                  }
                  await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                }
                await this.util.cerrarSpinner();
                          var accion = text;
                          var dialogRef = this.dialog.open(FormParametrosAccionesComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                            data: {id:'',
                                  parametros:this.parametros_precios,
                                  operacion:this.parametros_operacion,
                                  accion:accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                                if(result !== 'cerrar'){
                                  await this.util.cerrarSpinner();
                                }else{
                                  await this.util.cerrarSpinner();
                                }
                          });
  } catch (error) {
    await this.util.cerrarSpinner();
          console.log(error);
      await this.notifi.customToastNotification(error.error.message);
  }
}
async parametros_correos_create(text:any){
  try {
                  await this.util.abrirSpinner();
                  await this.servicio.consultar_sesionhttp(null).toPromise();
                          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def = {
                              val_def:await this.atrb.ACCESO_VALDEF.gestionar_definiciones,
                              tipo_operacion:await this.atrb.ACCESO_OPERACION.inner_join,
                              cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                            }
                            await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                          }
                          await this.util.cerrarSpinner();
                          var accion = text;
                          var dialogRef = this.dialog.open(FormParametrosCorreosComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                            data: {id:'',
                                  parametros:this.parametros_precios,
                                  operacion:this.parametros_operacion,
                                  accion:accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                                if(result !== 'cerrar'){
                                  await this.util.cerrarSpinner();
                                }else{
                                  await this.util.cerrarSpinner();
                                }
                          });
  } catch (error) {
      await this.util.cerrarSpinner();
          console.log(error);
      await this.notifi.customToastNotification(error.error.message);
  }
} 
async definiciones_modulos(obj:any){
  try {
          var dialogRef:any;
          await this.util.abrirSpinner();
          await this.servicio.consultar_sesionhttp(null).toPromise();
                        if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                          let val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_definiciones,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.store_procedure,
                            cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                          }
                          await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                        }
                        await this.util.cerrarSpinner();
                          dialogRef = this.dialog.open(FormDefinicionesModulosComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                            data: {objeto:obj},
                            disableClose: true,
                            panelClass: 'pedidos_modal_permisos_acciones',
                            backdropClass: "bdrop"
                          });
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                                if(result !== 'cerrar'){
                                  await this.util.cerrarSpinner();
                                  this.gestionDefinicionesSistema(result);
                                }else{
                                  await this.util.cerrarSpinner();
                                }
                          });
  } catch (error) {
      await this.util.cerrarSpinner();
          console.log(error);
      await this.notifi.customToastNotification(error.error.message);
  }
}
async update_insert_data(obj:any,text:any){
    try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        await this.util.cerrarSpinner();
                          var dialogRef:any;
                          this.accion = text;
                          let cod_usuario = this.atrb.COD_USUARIO.cod_usuario;
                          let des_usuario = this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+ this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido);
                          if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                            if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def = {
                                val_def:await this.atrb.ACCESO_VALDEF.gestionar_definiciones,
                                tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                              }
                              await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                            }
                            await this.util.cerrarSpinner();
                            dialogRef = this.dialog.open(FormConsultaOrdenesComponent, {
                              width: '100vh',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {cod_orden_producto: obj.cod_orden_producto,
                                      cod_usuario:cod_usuario,
                                      des_usuario: des_usuario,
                                      accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }else{
                            if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def = {
                                val_def:await this.atrb.ACCESO_VALDEF.gestionar_definiciones,
                                tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                              }
                              await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                            }
                            await this.util.cerrarSpinner();
                            var termino = text;
                            dialogRef = this.dialog.open(FormmMsgComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                      des_usuario:des_usuario,
                                      cod_usuario: cod_usuario,
                                      menu:this.menu,
                                      accion:termino},
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }
                            dialogRef.afterClosed().subscribe(async result => {
                              await this.util.abrirSpinner();
                                if(result !== 'cerrar'){
                                  await this.util.cerrarSpinner();
                                    this.gestionDefinicionesSistema(result);
                                }else{
                                  await this.util.cerrarSpinner();
                                }
                            });
    } catch (error) {
      await this.util.cerrarSpinner();
          console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
}
async gestionDefinicionesSistema(xvalor:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      const response = await this.servicio.gestionDifinicionesSistemahttp(xvalor).toPromise();
      await this.util.cerrarSpinner();
          await this.listarDefinicionesSistema(null);
          await this.notifi.success(response.message);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.listarDefinicionesSistema(null);
      await this.notifi.customToastNotification(error.error.message);
    }
}
//////////////////////FUNCION DE ACCION/////////////////////////
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
