import { Component, OnInit, Inject, Injectable, ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {BehaviorSubject, Observable} from 'rxjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatPaginator } from '@angular/material/paginator';
import { map, startWith } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}



export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-definiciones-modulos',
  templateUrl: './form-definiciones-modulos.component.html',
  styleUrls: ['./form-definiciones-modulos.component.css']
})
export class FormDefinicionesModulosComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('definicion',{static:true}) definicion:ElementRef;
  @ViewChild('descrip',{static:true}) descrip:ElementRef;

  
  producto:any = [];
  producto_2:any = [];
dialogSpin:any;
codigo:any = [];
codigo_2:any = [];
  forma:FormGroup;
  accion:any;
  roles:any;
  stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
arry: Observable<StateGroup[]>;
arry2: Observable<StateGroup[]>;
  rol = [];
  unidad=[];
  unidadp=[];
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  secuencia:any;
  menur3:any;
  unidades:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  dataSource1=new MatTableDataSource<any>();
   dataSource=new MatTableDataSource<any>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private postrest:HttpClient, private service:nkServices,
    fb:FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog, 
    private servicio:nkServices,
    public dialogRefp: MatDialogRef<FormDefinicionesModulosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data, 'primero');

      this.forma = fb.group({
        'cod_definicion': data.objeto.cod_definicion,
        'des_definicion': data.objeto.des_definicion,
        'descrip_definicion':'',
        'concepto_definicion':'',
        'objeto_definicion':''
      });
     }
     data2: [][];

  ngOnInit(): void {
    this.formulario(this.data.objeto);
  }
  
  @ViewChild(MatPaginator) paginator: MatPaginator;

  onFileChange(evt:any){
    const target : DataTransfer = <DataTransfer>(evt.target);

    if(target.files.length !== 1) throw new Error('No se pueden seleccionar multiples archivos');

    const reader : FileReader = new FileReader();

    reader.onload = (e: any) => {
     const bstr : string = e.target.result;

     const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'})

     const wsname : string = wb.SheetNames[0];

     const ws : XLSX.WorkSheet = wb.Sheets[wsname];

     console.log(ws);

     this.data2 = (XLSX.utils.sheet_to_json(ws));

     if(this.data2.length > 0 ){
       for (const i in this.data2) {
         
         this.ELEMENT_DATA2.push(this.data2[i])
       }
       for (const i in this.ELEMENT_DATA2) {
         console.log(this.ELEMENT_DATA2,'this.ELEMENT_DATA2');
        var arry = [];
        var arry2 = [];
        
        var xobjeto;
        
        if (this.ELEMENT_DATA2[i].concepto_definicion != '' && isNaN(parseInt(this.ELEMENT_DATA2[i].concepto_definicion)) && 
            this.ELEMENT_DATA2[i].descrip_definicion != '' && isNaN(parseInt(this.ELEMENT_DATA2[i].descrip_definicion))) {
          var min_concepto = (str)=>{
            let resultado = str.replace(/[aáAÁeéEÉiíIÍoOóÓuúUÚ]/g, '')
              return resultado
          }
          this.ELEMENT_DATA2[i].concepto_definicion_codigo = min_concepto(this.ELEMENT_DATA2[i].concepto_definicion).toUpperCase();
        
          this.servicio.CrerCodigohttp(this.ELEMENT_DATA2[i]).subscribe(
            response => {
                console.log(response.resul[0].cod_correlativo,'response');
                this.secuencia = response.resul[0].cod_correlativo;

                if (this.ELEMENT_DATA.length == 0) {
            
                  this.ELEMENT_DATA.push({ 
                    cod_concepto: this.secuencia,
                    concepto_definicion: this.ELEMENT_DATA2[i].concepto_definicion.toUpperCase(),
                    descrip_definicion: this.ELEMENT_DATA2[i].descrip_definicion,
                    des_definicion: this.forma.value.des_definicion.toUpperCase(),
                    min_concepto_definicion:min_concepto(this.ELEMENT_DATA2[i].concepto_definicion).toUpperCase(),
                  });
                  this.definicion.nativeElement.value = '';
                  this.descrip.nativeElement.value = '';
                  this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
          
            }else{
              console.log(this.forma.value.cod_submenu_accion);
                for (const i in this.ELEMENT_DATA) {
                arry.push(this.ELEMENT_DATA[i].concepto_definicion.toLowerCase());
                }
            
                  let index = arry.includes(this.ELEMENT_DATA2[i].concepto_definicion.toLowerCase());

                  if (!index) {
                    this.ELEMENT_DATA.push({ 
                      cod_concepto: this.secuencia,
                      concepto_definicion:this.ELEMENT_DATA2[i].concepto_definicion.toUpperCase(),
                      descrip_definicion:this.ELEMENT_DATA2[i].descrip_definicion,
                    des_definicion:this.forma.value.des_definicion.toUpperCase(),
                    min_concepto_definicion:min_concepto(this.ELEMENT_DATA2[i].concepto_definicion).toUpperCase(),
                    });
                    this.definicion.nativeElement.value = '';
                    this.descrip.nativeElement.value = '';
                    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                    this.dataSource.paginator = this.paginator;
              
                }else{
                  this.snackBar.open('Esta definición ya esta en su lista', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
                }
            }
            },
            error => {
              console.log(error,'error');
            }
          );
        
        }else{

            if (!isNaN(parseInt(this.ELEMENT_DATA2[i].concepto_definicion)) || !isNaN(parseInt(this.ELEMENT_DATA2[i].descrip_definicion))) {
              this.snackBar.open('No puede ingresar numeros', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
            if (this.ELEMENT_DATA2[i].concepto_definicion === '' || this.ELEMENT_DATA2[i].descrip_definicion === '') {
              this.snackBar.open('Existen campos vacios', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }  
        }
      }
          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
        }
        console.log(this.data2)
        };

        reader.readAsBinaryString(target.files[0]);
      }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    this.dataSource.paginator = this.paginator;
    
  }

  formulario(xvalor:any):void{
    this.ELEMENT_DATA = [];
    this.servicio.buscarConceptosDefinicioneshttp(xvalor).subscribe(
      response =>{
        for (const i in response) {

          this.ELEMENT_DATA.push({
            cod_concepto:response[i].cod_concepto,
            concepto_definicion:response[i].concepto_definicion,
            descrip_definicion:response[i].descrip_definicion,
            min_concepto_definicion:response[i].min_concepto_definicion,
            cod_definicion:response[i].cod_definicion,
            accion:'',
            accion2:'',
            accion3:''
          });
        }
        this.displayedColumns = [
          'cod_concepto',
          'concepto_definicion',
          'descrip_definicion',
          'min_concepto_definicion',
          'remov_elem'
         ];

      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      },
      error =>{
        console.log(<any>error.error.message);
        this.displayedColumns = [
          'cod_concepto',
          'concepto_definicion',
          'descrip_definicion',
          'min_concepto_definicion',
          'remov_elem'
         ];
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

 async addElement() {
  try {
    var arry = [];
    var arry2 = [];
    var xobjeto;
    if (this.forma.value.concepto_definicion !== '' && 
        this.forma.value.descrip_definicion !== '' ) {
      var min_concepto = (str)=>{
        let resultado = str.replace(/[aáAÁeéEÉiíIÍoOóÓuúUÚ]/g, '')
          return resultado
      }
      this.forma.value.concepto_definicion_codigo = min_concepto(this.forma.value.concepto_definicion).toUpperCase();
     const response = await this.servicio.CrerCodigohttp(this.forma.value).toPromise();
     console.log(response.resul);
            this.secuencia = response.resul[0].cod_correlativo;
            if (this.ELEMENT_DATA.length == 0) {
              this.ELEMENT_DATA.push({ 
                cod_concepto: this.secuencia,
                concepto_definicion: this.forma.value.concepto_definicion.toUpperCase(),
                descrip_definicion: this.forma.value.descrip_definicion,
                des_definicion: this.forma.value.des_definicion.toUpperCase(),
                min_concepto_definicion:min_concepto(this.forma.value.concepto_definicion).toUpperCase(),
              });
              this.definicion.nativeElement.value = '';
              this.descrip.nativeElement.value = '';
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
      
        }else{
          console.log(this.forma.value.cod_submenu_accion);
            for (const i in this.ELEMENT_DATA) {
             arry.push(this.ELEMENT_DATA[i].concepto_definicion.toLowerCase());
            }
         
              let index = arry.includes(this.forma.value.concepto_definicion.toLowerCase());

              if (!index) {
                this.ELEMENT_DATA.push({ 
                  cod_concepto: this.secuencia,
                  concepto_definicion:this.forma.value.concepto_definicion.toUpperCase(),
                  descrip_definicion:this.forma.value.descrip_definicion,
                des_definicion:this.forma.value.des_definicion.toUpperCase(),
                min_concepto_definicion:min_concepto(this.forma.value.concepto_definicion).toUpperCase(),
                });
                this.definicion.nativeElement.value = '';
                this.descrip.nativeElement.value = '';
                this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
          
            }else{
              this.snackBar.open('Esta definición ya esta en su lista', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
        }
    
    }else{
        if (!isNaN(parseInt(this.forma.value.concepto_definicion)) || !isNaN(parseInt(this.forma.value.descrip_definicion))) {
          this.snackBar.open('No puede ingresar numeros', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.forma.value.concepto_definicion === '' || this.forma.value.descrip_definicion === '') {
          this.snackBar.open('Existen campos vacios', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }  
    }
  } catch (error) {
    console.log(error);
  }
  }

  modalrespuesta(){
    try {
      if (this.ELEMENT_DATA.length > 0) {
        this.accion = '';
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: { 
                accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
           
            this.forma.value.objeto_definicion = this.ELEMENT_DATA;
            
            this.gestionConceptosDefiniciones(this.forma.value);
           
          } 
        });
      }else{
        this.snackBar.open('No existe nada en su lista', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
    } catch (error) {
      console.log(<any>error.error.message);
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
    }
  }

  gestionConceptosDefiniciones(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        var resultado = this.servicio.gestionConceptosDefinicioneshttp(xvalor).subscribe(
          response =>{
            this.dialogSpin.close();
            this.formulario(this.data.objeto);
           this.snackBar.open(response.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
            
          },
          error =>{
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.displayedColumns = [
              'cod_concepto',
              'concepto_definicion',
              'descrip_definicion',
              'min_concepto_definicion',
              'remov_elem'
             ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        
        return resultado;
      }, 3000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
         this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
    }
   }
 
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  }

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  remove(val:any){
    console.log(val);
    this.dataSource.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
     this.table.renderRows();
   }


}
