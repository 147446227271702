
<div class="body4 fadeIn2 animated fast" style="z-index: -1;">
    <mat-drawer-container autosize>
        <mat-drawer #drawer class="sidebar" opened="true" mode="side">
            <span class="text-center active">ALMACENES</span>
            <a #link *ngFor="let i of menu3" class="text-left " (click)="cambiarrouter('entrar')" [routerLink]="[{ outlets: {'almacenes': [i.dir_menu] } }]"><i style="font-size: 15px; margin-right: 5px;" class="fa fa-check-square" aria-hidden="true"></i>{{i.des_menu}}</a>
            <a (click)="cambiarrouter('entrar')" [routerLink]="[{ outlets: {'infouser': ['infouser'] } }]" class=" text-left"><i style="font-size: 20px; margin-right: 5px;" class="fa fa-thumb-tack" aria-hidden="true"></i> Acerca de</a>
        </mat-drawer>
        <button class=" bg-success py-0"   matTooltip="MENU" mat-mini-fab (click)="drawer.toggle()" style="color: #fff; background-color: transparent; cursor: pointer; position: absolute;z-index: 1000; bottom: 15vh; margin-left: 5px;">
            <mat-icon >menu</mat-icon>
        </button>
        <div class="content" style="margin-left: 0;" *ngIf="ruta =='entrar'">
            <router-outlet name="almacenes"></router-outlet>
            <router-outlet class=""></router-outlet>
        </div>
    </mat-drawer-container>
</div>