<div id="modal_carrito" class=" container-fluid h-100" style="background-color:#2b69dd;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" class="h-100">
        <div id="carrito_p1" class="row mat-elevation-z8 " style=" border: 2px solid #475bca;">
            <div class="col-md-3 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-select (selectionChange)="this.listar_submenu_acciones(unidad_front )" #unidad_front placeholder="Unidades" formControlName="unidades" autocomplete="off">
                            <mat-option *ngFor="let item of unidades" [value]="item">({{item.cod_unidad}}) {{item.des_unidad}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-3 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3">
                    <!-- <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Menu acción</mat-label>
                        <input style="font-size: 11px; color: #fff;" type="text" matInput placeholder="" formControlName="cod_submenu_accion" [matAutocomplete]="autoGroup" #cod_submenu_accion>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                        <mat-autocomplete #autoGroup="matAutocomplete">
                            <mat-optgroup style="color: #000; font-size: 11px; " *ngFor="let group of arry2 | async" [label]="group.letter">
                                <mat-option style="color: #000; font-size: 12px; " *ngFor="let i of group.code" [value]="i">
                                    {{i}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field> -->

                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-select  #menu_front placeholder="Sub menu" formControlName="cod_submenu_accion" autocomplete="off">
                            <mat-option *ngFor="let item of producto_2" [value]="item">({{item.cod_menu}}) {{item.des_menu}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-3 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3">
                    <!-- <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Acción permitida</mat-label>
                        <input style="font-size: 11px; color: #fff;" type="text" matInput placeholder="" formControlName="cod_accion" [matAutocomplete]="autoGroup2" #cod_producto_mp>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                        <mat-autocomplete #autoGroup2="matAutocomplete">
                            <mat-optgroup style="color: #000; font-size: 11px; " *ngFor="let group of arry | async" [label]="group.letter">
                                <mat-option style="color: #000; font-size: 12px; " *ngFor="let i of group.code" [value]="i">
                                    {{i}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field> -->
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-select style="font-size: 11px; color: #fff;"   #cod_producto_mp placeholder="Acción permitida" formControlName="cod_accion" autocomplete="off">
                            <mat-option *ngFor="let item of producto" [value]="item">({{item.cod_accion}}) {{item.des_accion}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button  class="btn btn-block btn-lg btn-light h-100" (click)="addElement()" matTooltip="AGREGAR ACCESO" style=" color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                  <mat-icon >add_shopping_cart</mat-icon>
                </button>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button  class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GUARDAR" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                      <mat-icon >save</mat-icon>
                  </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                      <mat-icon >exit_to_app</mat-icon>
                  </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row " style=" border: 2px solid #374fd6;">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100">
                <table #table mat-table [dataSource]="dataSource" class=" table-primary  text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="rel">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Id</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.id_rol}}</b></td>
                
                    </ng-container>
               
                    <ng-container matColumnDef="cod_menu">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_menu}}</b></td>
                
                    </ng-container>
                    <ng-container matColumnDef="des_menu">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Menu</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.des_menu}}</b></td>
              
                    </ng-container>

                    <ng-container matColumnDef="cod_accion">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_accion}}</b></td>

                    </ng-container>

                    <ng-container matColumnDef="des_accion">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Acción</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.des_accion}}</b></td>

                    </ng-container>

                    <ng-container matColumnDef="accion_rol">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Accion</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.accion_rol}}</b></td>
                        
                    </ng-container>

                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#4979d3;">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="remove(element)" matTooltip="ELIMINAR" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color:#4979d3; border: solid 2px rgba(8, 56, 100, 0.692);">
                          <mat-icon >remove_shopping_cart</mat-icon>
                          </button>
                        </td>
                        
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                </table>
                <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
            </div>
            
        </div>
        <div id="carrito_p3" class="row py-1 px-1" style=" border: 2px solid #374fd6;">
            <div class="col-md-2 px-0 py-1 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Usuario</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_usuario" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-10 px-0 py-1 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>search</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>
