
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>ALMACEN  CLIENTES ESPECIALES</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarInventarioSPL("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                </ul>
                <div class="">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.7%; ">
        <div class="col-md-12 px-0  border h-100" style="background-color: steelblue; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
               
                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod cliente </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cliente </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha_inicio}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_inicio}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="inventario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Inventario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button *ngIf="special" matTooltip="DETALLE DEL INVENTARIO" mat-raised-button (click)='InventarioSPL(element)' style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(166, 9, 197);">
                            <mat-icon >swap_horiz</mat-icon>
                        </button>
                        <button *ngIf="!special" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >swap_horiz</mat-icon>
                        </button>
                    </td>
                    
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    </div>