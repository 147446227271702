import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import { Router } from '@angular/router';
import { FormCrearQrEmpleadosComponent } from '../../../shared/modal/form-crear-qr-empleados/form-crear-qr-empleados.component';
import {MatTableDataSource} from '@angular/material/table';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Pipe, PipeTransform } from '@angular/core';

import 'offline-js'
//esto es de mi desarrollo 
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { style } from '@angular/animations';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-empleado',
  templateUrl: './empleado.component.html',
  styleUrls: ['./empleado.component.css']
})
export class EmpleadoComponent implements OnInit {

  @ViewChild('resultado', {static:true}) resultado:ImageData;
  @ViewChild('Indentificador', {static:true}) Indentificador:ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    ){}
  ngOnInit(){
    this.listarEmpleados(null);
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

async listarEmpleados(msj:any){
  try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
    let msg = msj;
    let xvalor = {
      cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
    }
      const response = await this.servicio.listarEmpleadoshttp(xvalor).toPromise();
          ////////////////Empleados//////////////////////
          this.xobj3 = [];
          for (const i in response[0]) {
            this.xobj3.push(response[0][i]);
          }
            this.xobj2 = response;
            this.ELEMENT_DATA = response;
                
                this.displayedColumns = [
                                          'cod_usuario',
                                          'dt_primer_nombre',
                                          'dt_primer_apellido',
                                          'unidad',
                                          'dt_cargo',
                                          'estatus',
                                          'Qr_Indentificador',
                                          'procesos',
                                          
                                        ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
          if(msg == await this.atrb.ACCION_ACTUALIZAR){
            await this.util.cerrarSpinner();
            await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
        }else{
          await this.util.cerrarSpinner();
        }
  } catch (error) {
    this.displayedColumns = [
                                          'cod_usuario',
                                          'dt_primer_nombre',
                                          'dt_primer_apellido',
                                          'unidad',
                                          'dt_cargo',
                                          'estatus',
                                          'Qr_Indentificador',
                                          'procesos',
        ];
        this.dataSource = new MatTableDataSource<any>();
        this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
  }
}

async  update_insert_data(obj:any, text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
        var dialogRef;
      if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()){
        let val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.gestionar_empleados,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
          cod_usuario : await this.atrb.COD_USUARIO.cod_usuario
        }
        await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
        let objeto = {
          cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
          mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
          des_parametro: await this.atrb.OPERACIONES.empleados
        };
        await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
        await this.util.cerrarSpinner();
        obj.accion == 'edit'
        this.accion = text;
         dialogRef = this.dialog.open(FormCrearQrEmpleadosComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: obj,
              disableClose: true,
              panelClass: 'pedidos_modal3',
              backdropClass: "bdrop"
        });
      }else{
        let val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.gestionar_empleados,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
          cod_usuario : await this.atrb.COD_USUARIO.cod_usuario
        }
        await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
        let objeto = {
          cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
          mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
          des_parametro: await this.atrb.OPERACIONES.empleados
        };
        await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
        await this.util.cerrarSpinner();
        this.accion = text;
        dialogRef = this.dialog.open(FormCrearQrEmpleadosComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'auto',
              data: {cod_usuario:'', 
                     accion:this.accion},
            
          disableClose: true,
          panelClass: 'pedidos_modal3',
          backdropClass: "bdrop"
        });
      }
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
          if(result !== 'cerrar'){
            await this.util.cerrarSpinner();
              this.gestionempleado(result);
          }else{
            await this.util.cerrarSpinner();
          }
      });
        } catch (error) {
          await this.util.cerrarSpinner();
          console.log(error);
          await this.notifi.customToastNotification(error.error.message);
        }
  }

 async gestionempleado(xvalor:any){
    try {
      xvalor.usuarioCreador = {
        cod_usuario: await this.atrb.COD_USUARIO.cod_usuario,
        des_usuario: await this.hashkey.decrypt(await this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(await this.atrb.APELLIDO_USUARIO.apellido)
      }
       const response =  await this.servicio.insertarempleadosnuevos(xvalor).toPromise();
        this.listarEmpleados(null);
        await this.notifi.success(response.message);
    } catch (error) {
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 }
