import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { StateGroup } from '../form-pedidos/form-pedidos.component';
import { map, startWith } from 'rxjs/operators';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-form-crear-devolucion',
  templateUrl: './form-crear-devolucion.component.html',
  styleUrls: ['./form-crear-devolucion.component.css']
})
export class FormCrearDevolucionComponent implements OnInit {

  stateGroups: StateGroup[];
  
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('tipo_dev_',{static:true})tipo_dev_:ElementRef;

  forma:FormGroup;
  accion:any;
  dialogSpin:any;
  tipo_dev:any;
  producto:any = [];
  codigo:any = [];

  constructor(private snackBar: MatSnackBar, public dialog: MatDialog, fb:FormBuilder,
    public dialogRef: MatDialogRef<FormCrearDevolucionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any
) {
  console.log(data,'llegada');
  this.accion = data.accion;
  this.forma = fb.group({
    /* DATOS PERSONALES */
    'cod_documento':[data.cod_documento,[Validators.required]],
    'des_dev':[data.des_dev,[Validators.required]],
    'tipo_devolucion':[data.tipo_devolucion,[Validators.required]], 
    'cod_usuario':localStorage.getItem('cod_usuario'),
    'des_usuario':localStorage.getItem('Nombre'),
    'factura':false,
    'nota':false,
  });

  this.tipo_dev = this.forma.get('tipo_devolucion')!.valueChanges
                      .pipe(
                        startWith(''),
                        map(value => this._filterGroup(value))
                      );
                    for (const i in this.data.tipo_dev) {
                          this.producto.push(this.data.tipo_dev[i].concepto_definicion+'-'+this.data.tipo_dev[i].descrip_definicion );
                          this.codigo.push(this.data.tipo_dev[i].concepto_definicion );
                    }

        this.stateGroups= [{
          letter: 'Devolución',
          names: this.producto,
          code: this.producto
        }];
 }

  ngOnInit(): void {
    
  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 );
    }
    console.log(this.stateGroups);
    return this.stateGroups;
 }

  modalrespuesta(){
    if(this.forma.value.factura === true && this.forma.value.nota === true){
      this.snackBar.open('Solo se debe seleccionar un tipo de documento (Factura o Nota)', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
        return null
    }
    if(this.forma.value.factura === false && this.forma.value.nota === false){
      this.snackBar.open('Se debe seleccionar al menos un tipo de documento (Factura o Nota)', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
        return null
    }

    var accion2 = 'enviarcliente';
    var dialogRefp = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:this.data.cod_usuario,
                     cod_usuario:localStorage.getItem('cod_usuario'),
                     des_usuario:localStorage.getItem('Nombre'),
            accion:accion2},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
    });

    dialogRefp.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        var dev_defi = this.tipo_dev_.nativeElement.value.split('-');
        this.forma.value.tipo_devolucion = dev_defi[0];
        console.log(this.forma.value, 'informacion 2');
        this.dialogRef.close(this.forma.value);
      }
    });
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRef.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }


}
