import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  constructor() {}

  puntoporcoma(num: any): any {
    if (!num) {
      if (num == 0) {
        return 0;
      }

      return null;
    }

    if (typeof num == 'string') {
      let num2;

      if (num.includes('.') || num.includes(',')) {
        let valid1 = num.split(',');
        let valid2 = num.split('.');

        console.log(valid1, valid2);

        if (valid1.length == 2 && valid1[valid1.length - 1].length > 2) {
          return num;
        }

        if (valid1.length == 2 && valid1[valid1.length - 1].includes('.')) {
          return num.replace('.', '/').replace(/,/g, '.').replace('/', ',');
        }

        if (valid1.length == 2 && !valid1[valid1.length - 1].includes('.')) {
          return num.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, '.');
        }

        if (valid2.length == 2 && valid2[valid2.length - 1].length > 2) {
          return num.replace('.', '/').replace(/,/g, '.').replace('/', ',');
        }

        if (valid2.length == 2 && !valid2[valid2.length - 1].includes('.')) {
          return num
            .replace('.', '/')
            .replace(/,/g, '.')
            .replace('/', ',')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, '.');
        } else {
        }
      } else {
        num2 = Number(num)
          .toFixed(2)
          .replace(/\D/g, '')
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, '.');
      }

      return num2;
    } else if (typeof num == 'number') {
      let num2 = num
        .toFixed(2)
        .replace(/\D/g, '')
        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, '.');

      return num2;
    } else {
      return `tipo de dato no soportado ${typeof num}`;
    }
  }
  ordernarArrayPorCodProducto(data: any[]) {
    const objeto_unico = [];
    let letras = {};
    for (const prod of data) {
      const prodS = prod.cod_producto.split('');
      const stringsUnidas = prodS.filter((e) => e != parseInt(e)).join('');
      const numbersUnidos = prodS.filter((e) => parseInt(e) >= 0).join('');

      const objCompleto = { ...prod };
      objCompleto.cod_producto_str = stringsUnidas;
      objCompleto.cod_producto_nbr = Number(numbersUnidos);

      if (letras[stringsUnidas]) {
        letras[stringsUnidas].push(objCompleto);
      } else {
        letras[stringsUnidas] = [objCompleto];
      }
    }
    const ordered = Object.keys(letras)
      .sort()
      .reduce((obj, key) => {
        obj[key] = letras[key];
        return obj;
      }, {});
    letras = ordered;

    for (const letra in letras) {
      letras[letra].sort((a, b) => b.cod_producto_nbr - a.cod_producto_nbr);
      objeto_unico.push(...letras[letra]);
    }
    for (const prod of objeto_unico) {
      delete prod.cod_producto_str;
      delete prod.cod_producto_nbr;
    }

    return objeto_unico;
  }
}
