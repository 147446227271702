
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>ORDENES DE PRODUCCIÓN MPP</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarordenesprodmpp("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="HISTORIAL" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(4, 119, 29);" (click)='historialOrdProd("MP")'><mat-icon >history</mat-icon></button></li>
                </ul>
                <div class="">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.7%; ">
        <div class="col-md-12 px-0  border h-100" style="background-color: steelblue; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
                <ng-container matColumnDef="cod_orden_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Ord pt</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_orden_producto}} </td>
                </ng-container>
                <ng-container matColumnDef="cod_orden_producto_mpp">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Ord mpp</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_orden_producto_mpp}} </td>
                </ng-container>
                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_producto}} </td>
                </ng-container>
                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_producto}} </td>
                </ng-container>
                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cant_producto}} </td>
                </ng-container>
                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod usuario</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_usuario}} </td>
                </ng-container>
                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_usuario}} </td>
                </ng-container>
                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.estatus}} </td>
                </ng-container>
                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.fecha_inicio}} </td>
                </ng-container>
                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.hora_inicio}} </td>
                </ng-container>
                <ng-container matColumnDef="cod_usuario_cierre">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod usuario cierre</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_usuario_cierre}} </td>
                </ng-container>
                <ng-container matColumnDef="des_usuario_cierre">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Usuario cierre </strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_usuario_cierre}} </td>
                </ng-container>
                <ng-container matColumnDef="fecha_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha fin</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.fecha_fin}} </td>
                </ng-container>
                <ng-container matColumnDef="hora_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora fin</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.hora_fin}} </td>
                </ng-container>
                <ng-container matColumnDef="tiempo">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Tiempo</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button *ngIf="read" matTooltip="TIEMPO" mat-raised-button (click)="mostrar_tiempo(element,'tiempo_ord_mp')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(22, 175, 80);">
                            <mat-icon >alarm</mat-icon>
                        </button>
                        <button *ngIf="!read" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >alarm</mat-icon>
                        </button>
                    </td>
                   
                </ng-container>
                <ng-container matColumnDef="Imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button *ngIf="read" matTooltip="IMPRIMIR ORDEN DE PRODUCCIÓN MPP" mat-raised-button (click)="imprimir_descargar(element,'imprimir')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 86, 247);">
                            <mat-icon >print</mat-icon>
                        </button>
                        <button *ngIf="!read" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button  style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >print</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="confirmar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Confirmar</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_v':  element.estatus === 'V','ord_prod_estatus_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button *ngIf="special" matTooltip="CONFIRMAR ORDEN DE PRODUCCIÓN MPP" mat-raised-button (click)='confirmacionOrdenProdMpp(element)' style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(6, 81, 124);">
                            <mat-icon >done_all</mat-icon>
                        </button>
                        <button *ngIf="!special" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >done_all</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    </div>