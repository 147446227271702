
<div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="border-radius: 1rem;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border-radius: 1rem; background-color: rgb(42, 14, 80);">
                    <div class="modal-header" style="background-color: rgb(42, 14, 80); color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title"><strong>Ingrese una cantidad y el lote del producto</strong> </h3>
                    </div>
                    <div class="modal-body">
                        <div style="width: 100%;">
                            <div class="row">

                                <div class="col-md-12 col-sm-12 col-xs-12 text-left" style="color: #fff;">
                                    Codigo: <strong>{{codigo}}</strong>
                                </div>
                                <br>
                                <div class="col-md-12 col-sm-12 col-xs-12 text-left" style="color: #fff;">
                                    Producto: <strong>{{producto}}</strong>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-6 col-sm-12" style="color: #fff;">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad del producto</mat-label>
                                            <input style="font-size: 12px;" #cant_producto maxlength="17" matInput placeholder="" autocomplete="off" formControlName="cant_producto" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                          </span>
                                        </mat-form-field> -->
                                    <div class="form-group">
                                        <label for="cant_producto">Cantidad del producto</label>
                                        <input id="cant_producto" style="font-size: 12px; text-align: right;" #cant_producto class="form-control" type="text" autocomplete="off" formControlName="cant_producto" readonly>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12" style="color: #fff;">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad a devolver</mat-label>
                                            <input style="font-size: 12px;" #cant_dev maxlength="6" matInput placeholder="" autocomplete="off" formControlName="cant_dev">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                            </span>
                                    </mat-form-field> -->

                                    <div class="form-group">
                                        <label for="cant_dev">Cantidad a devolver</label>
                                        <input id="cant_dev" style="font-size: 12px; text-align: right;" #cant_dev class="form-control" type="text" autocomplete="off" formControlName="cant_dev" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-12" style="color: #fff;">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad del producto</mat-label>
                                            <input style="font-size: 12px;" #cant_producto maxlength="17" matInput placeholder="" autocomplete="off" formControlName="cant_producto" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                          </span>
                                        </mat-form-field> -->
                                    <div class="form-group">
                                        <label for="cant_producto">Lote A</label>
                                        <input id="cant_producto" style="font-size: 12px; text-align: right;" (keyup)='restar_dev_lote(valor_1,lote_1)' #lote_1 class="form-control" type="text" autocomplete="off" formControlName="lote_1" >
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12" style="color: #fff;">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad a devolver</mat-label>
                                            <input style="font-size: 12px;" #cant_dev maxlength="6" matInput placeholder="" autocomplete="off" formControlName="cant_dev">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                            </span>
                                    </mat-form-field> -->

                                    <div class="form-group">
                                        <label for="cant_dev">Cantidad a devolver</label>
                                        <input id="cant_dev" style="font-size: 12px; text-align: right;"  (keyup)='sumar_dev_lote(lote_1.value,valor_1)' #valor_1 class="form-control" type="text" autocomplete="off" formControlName="valor_1">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-12" style="color: #fff;">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad del producto</mat-label>
                                            <input style="font-size: 12px;" #cant_producto maxlength="17" matInput placeholder="" autocomplete="off" formControlName="cant_producto" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                          </span>
                                        </mat-form-field> -->
                                    <div class="form-group">
                                        <label for="cant_producto">Lote B</label>
                                        <input id="cant_producto" style="font-size: 12px; text-align: right;" #lote_2 (keyup)='restar_dev_lote(valor_2,lote_2)'  class="form-control" type="text" autocomplete="off" formControlName="lote_2" >
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12" style="color: #fff;">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad a devolver</mat-label>
                                            <input style="font-size: 12px;" #cant_dev maxlength="6" matInput placeholder="" autocomplete="off" formControlName="cant_dev">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                            </span>
                                    </mat-form-field> -->

                                    <div class="form-group">
                                        <label for="cant_dev">Cantidad a devolver</label>
                                        <input id="cant_dev" style="font-size: 12px; text-align: right;" (keyup)='sumar_dev_lote(lote_2.value,valor_2)' #valor_2 class="form-control" type="text" autocomplete="off" formControlName="valor_2">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-12" style="color: #fff;">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad del producto</mat-label>
                                            <input style="font-size: 12px;" #cant_producto maxlength="17" matInput placeholder="" autocomplete="off" formControlName="cant_producto" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                          </span>
                                        </mat-form-field> -->
                                    <div class="form-group">
                                        <label for="cant_producto">Lote C</label>
                                        <input id="cant_producto" style="font-size: 12px; text-align: right;" #lote_3 (keyup)='restar_dev_lote(valor_3,lote_3)'  class="form-control" type="text" autocomplete="off" formControlName="lote_3" >
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12" style="color: #fff;">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad a devolver</mat-label>
                                            <input style="font-size: 12px;" #cant_dev maxlength="6" matInput placeholder="" autocomplete="off" formControlName="cant_dev">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                            </span>
                                    </mat-form-field> -->

                                    <div class="form-group">
                                        <label for="cant_dev">Cantidad a devolver</label>
                                        <input id="cant_dev" style="font-size: 12px; text-align: right;" (keyup)='sumar_dev_lote(lote_3.value,valor_3)' #valor_3 class="form-control" type="text" autocomplete="off" formControlName="valor_3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                      <div class="form-group">
                        <button  class="btn btn-danger rounded-pill" (click)="cerrarmodal()" style="margin-right: 5px;">Salir</button>
                        <button class="btn btn-success rounded-pill" (click)="devolucion_prod(cant_dev.value)" >Aceptar</button> <!-- [disabled]="forma.invalid" -->
                      </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

