import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, merge, Observable} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormClientesNuevoComponent } from 'src/app/componentes/shared/modal/form-clientes-nuevo/form-clientes-nuevo.component';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatSort, SortDirection } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-gestion-clientes',
  templateUrl: './gestion-clientes.component.html',
  styleUrls: ['./gestion-clientes.component.css']
})
export class GestionClientesComponent implements OnInit {

  auth_menu:any = [];

  data: any[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  obj:any[] = [];
  tipo_cliente:any = [];
  tipo_manejo:any = [];
  tipo_manejo_consig:any = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;
  LISTA_SPCL:any = [];
  ALMACEN_SPCL:any = [];
  ALMACEN_CDN:any = [];
  ALMACEN_DSTRBDR:any = [];
  ALMACEN_PVP:any = [];
  ALMACEN_PT:any = [];
  CIUDADES:any = [];
  ESTADOS:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
       private servicio:nkServices,
       private dialog: MatDialog,
       private snackBar: MatSnackBar,
       private router:Router,
       private util:AppUtilService,
       private atrb:AtributosService,
       private hashkey:HashKeyServices,
       private notifi:ToastNotificationService,
       ) {
  }
  ngOnInit(): void {
    this.listarclientes(null);
    this.listarListaPrecioSPCL();
    this.listarAlmacenSPCL();
    this.listarAlmacenCdnDtrbdrPvp();
    this.listarAlmacenes_PT();
    this.listarDefiniciones(null);
    this.listarEstados(null);
  }
  
  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator; 
  }

  /////////////////////////////////////////////////////////////////////
 async listarclientes(msj:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let msg = msj;
      let xvalor = {
        cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
      }
      const response = await  this.servicio.listarClienteshttp(xvalor).toPromise();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            if(response){
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                      id_cliente:response[i].id_cliente,
                      cod_cliente:response[i].cod_cliente,
                      des_cliente: response[i].des_cliente,
                      tipo_cliente:response[i].tipo_cliente,
                      dir_cliente:response[i].dir_cliente,
                      pais_cliente:response[i].pais_cliente,
                      pais_estado_cliente: response[i].pais_estado_cliente,
                      ciudad_cliente: response[i].ciudad_cliente,
                      des_vendedor:response[i].des_vendedor,
                      estatus_cliente:response[i].estatus_cliente,
                      credito_dias_cliente:response[i].credito_dias_cliente,
                      reten_cliente:response[i].reten_cliente,
                      telefono1_cliente:response[i].telefono1_cliente,
                      telefono2_cliente:response[i].telefono2_cliente,
                      correo_cliente:response[i].correo_cliente,
                      comercializacion:response[i].comercializacion,
                      dep_spcl:response[i].dep_spcl,
                      lista_precio_spcl:response[i].lista_precio_spcl,
                      mod_factur:response[i].mod_factur,
                      mod_consig:response[i].mod_consig,
                    });
                  }
                  this.displayedColumns = [
                                            'cod_cliente',
                                            'des_cliente',
                                            'tipo_cliente',
                                            'des_vend_cliente',
                                            'estatus_cliente',
                                            'credito_dias_cliente',
                                            'reten_cliente',
                                            'mod_factur',
                                            'mod_consig',
                                            'procesos',
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
            if(msg === await this.atrb.ACCION_ACTUALIZAR){
              await this.util.cerrarSpinner();
                await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
            }else{
              await this.util.cerrarSpinner();
            }
    } catch (error) {
      await this.util.cerrarSpinner();
      this.displayedColumns = [
        'cod_cliente',
        'des_cliente',
        'tipo_cliente',
        'des_vend_cliente',
        'estatus_cliente',
        'credito_dias_cliente',
        'reten_cliente',
        'mod_factur',
        'procesos',
      ];
      this.dataSource = new MatTableDataSource<any>();
            this.dataSource.paginator = this.paginator;
      await this.notifi.customToastNotification(await error.error.message);
    }
  }
  //////////////////////FUNCION DE ACCION/////////////////////////
  async listarDefiniciones(objeto:any){
    try {
      var xvalor ={cod_menu:localStorage.getItem('sub_main')};
      const response = await  this.servicio.listarDefinicionesConceptoshttp(xvalor).toPromise();
            this.tipo_cliente= [];
            this.tipo_manejo= [];
            this.tipo_manejo_consig= [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === 'CLIENTES') {
                this.tipo_cliente.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() === 'MANEJO UNIDAD') {
                this.tipo_manejo.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() === 'MANEJO DE UNIDAD CONSIGNACIONES') {
                this.tipo_manejo_consig.push(response[i]);
              }
            }
    } catch (error) {
     console.log(error);
    }
  }
  async listarEstados(objeto:any){
    try {
      this.CIUDADES = [];
      this.ESTADOS = [];
      const response = await  this.servicio.listarEstadoshttp(null).toPromise();
        const estados = response.data.reduce((accumulator, item) => {
          console.log(accumulator,'ver data');
          return accumulator.includes(item.estado) ? accumulator : [...accumulator, item.estado];
        }, []);
        this.ESTADOS = estados;
        this.CIUDADES = [...response.data];
    } catch (error) {
     console.log(error);
    }
  }
  async update_insert_data(obj:any, text:any){
    try {
      await this.util.abrirSpinner();
      const response = await this.servicio.consultar_sesionhttp(null).toPromise();
        var dialogRef;
      if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
        let val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.gestionar_clientes,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
          cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
        }
        await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
        await this.util.cerrarSpinner();
        this.accion = text;
         dialogRef = this.dialog.open(FormClientesComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: {id_cliente:obj.id_cliente,
                 cod_cliente: obj.cod_cliente,
                 des_cliente: obj.des_cliente,
                 tipo_cliente:obj.tipo_cliente,
                 dir_cliente:obj.dir_cliente,
                 pais_cliente: obj.pais_cliente,
                 pais_estado_cliente:obj.pais_estado_cliente,
                 ciudad_cliente:obj.ciudad_cliente,
                 des_vend_cliente:obj.des_vendedor,
                 estatus_cliente: obj.estatus_cliente,
                 credito_dias_cliente:obj.credito_dias_cliente,
                 reten_cliente:obj.reten_cliente,
                 telefono1_cliente:obj.telefono1_cliente,
                 telefono2_cliente: obj.telefono2_cliente,
                 correo_cliente:obj.correo_cliente,
                 comercializacion:obj.comercializacion,
                 mod_factur:obj.mod_factur,
                 mod_consig:obj.mod_consig,
                 dep:obj.dep_spcl,
                 precio:obj.lista_precio_spcl,
                accion:this.accion,
                listas:this.LISTA_SPCL,
                almacen:this.ALMACEN_SPCL,
                almacen_cdn:this.ALMACEN_CDN,
                almacen_dstrbdr:this.ALMACEN_DSTRBDR,
                almacen_pvp:this.ALMACEN_PVP,
                tipos:this.tipo_cliente,
                deposito:this.ALMACEN_PT,
                tipo_um_und: this.tipo_manejo,
                tipo_um_und_consig: this.tipo_manejo_consig,
                ciudad: this.CIUDADES,
                estados:this.ESTADOS},
          disableClose: true,
          panelClass: 'pedidos_modal_perfiles',
          backdropClass: "bdrop"
        });
      }else{
        let val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.gestionar_clientes,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
          cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
        }
        await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
        let objeto = {
          cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
          mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
          des_parametro: await this.atrb.OPERACIONES.clientes
        };
        await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
        await this.util.cerrarSpinner();
        this.accion = text;
        dialogRef = this.dialog.open(FormClientesNuevoComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'auto',
          data: {id_cliente:'', accion:this.accion,
                  listas:this.LISTA_SPCL,
                  almacen:this.ALMACEN_SPCL,
                  almacen_cdn:this.ALMACEN_CDN,
                  almacen_dstrbdr:this.ALMACEN_DSTRBDR,
                  almacen_pvp:this.ALMACEN_PVP,
                  tipos:this.tipo_cliente,
                  deposito:this.ALMACEN_PT,
                  tipo_um_und: this.tipo_manejo,
                  tipo_um_und_consig: this.tipo_manejo_consig,
                  ciudad: this.CIUDADES,
                  estados:this.ESTADOS},
          disableClose: true,
          panelClass: 'pedidos_modal_perfiles',
          backdropClass: "bdrop"
        });
      }
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result != 'cerrar'){
         await this.gestionclientes(result);
        }else{
          await this.util.cerrarSpinner();
        }
      });  
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async gestionclientes(xvalor:any){
    try {
        const response = await this.servicio.gestionClienteshttp(xvalor).toPromise();
        await this.util.cerrarSpinner();
          await this.notifi.success(response.message);
           await this.listarclientes(null);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.listarclientes(null);
      await this.notifi.customToastNotification(error.error.message);
    }
  }
//////////////////////FUNCION DE ACCION/////////////////////////
  //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////

 async detalles_cliente(obj:any, text:any){
  try {
    await this.util.abrirSpinner();
    const response = await this.servicio.consultar_sesionhttp(null).toPromise();
  let val_def = {
    val_def:await this.atrb.ACCESO_VALDEF.gestionar_clientes,
    tipo_operacion:await this.atrb.ACCESO_OPERACION.select,
    cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
  }
  await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
  await this.util.cerrarSpinner();
  var dialogRef = this.dialog.open(FormDireccionesComponent, {
    width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
    data: {objeto:obj,
            termino:text
          },
          panelClass: 'pedidos_modal_rev',
          disableClose: true,
          backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(async result => {
    await this.util.abrirSpinner();
    if(result != 'cerrar'){
      await this.util.cerrarSpinner();
    }else{
      await this.util.cerrarSpinner();
    }
  }); 
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
  }
  getIngresarClienteMasivo(event) {
    let file = event.target.files[0];

    var id = document.getElementById('file');
    console.log(file);
    var nombre = 'Codigo de Unidad';
    this.servicio.processFileToJson({}, file).subscribe(data => {
      let arry = data.sheets.Hoja1;
      console.log(arry);
      for (const i in arry) {
          console.log(arry[i]);
          this.result.push(arry[i]);
      }
      console.log(this.result);
      if (this.result.length != 0) {
        console.log('entro en el if');
          this.servicio.insertarmasivoClientesFactoryhttp(this.result).subscribe(
            response =>{
              ////////////////UNIDADES//////////////////////
              this.xobj3 = [];
              for (const i in response[0]) {
                this.xobj3.push(response[0][i]);
              }
              console.log(this.xobj3);
              if(response){
                console.log(response,'una respuesta');
                this.xobj2 = response;
                this.ELEMENT_DATA = [];
                    for (const i in response.resul) {

                      this.ELEMENT_DATA.push({
                        id_cliente:response.resul[i].id_cliente,
                        cod_cliente:response.resul[i].cod_cliente,
                        des_cliente: response.resul[i].des_cliente,
                        tipo_cliente:response.resul[i].tipo_cliente,
                        dir_cliente:response.resul[i].dir_cliente,
                        pais_cliente:response.resul[i].pais_cliente,
                        pais_estado_cliente: response.resul[i].pais_estado_cliente,
                        des_vend_cliente:response.resul[i].des_vendedor,
                        estatus_cliente:response.resul[i].estatus_cliente,
                        credito_dias_cliente:response.resul[i].credito_dias_cliente,
                        reten_cliente:response.resul[i].reten_cliente,
                        telefono1_cliente:response.resul[i].telefono1_cliente,
                        telefono2_cliente:response.resul[i].telefono2_cliente,
                        correo_cliente:response.resul[i].correo_cliente,
                        comercializacion:response.resul[i].comercializacion,
                        mod_factur:response[i].mod_factur,
                        mod_consig:response[i].mod_consig,
                        accion:'',
                        accion2:'',
                        accion3:''
                      });
                    }
                    this.displayedColumns = [
                      'cod_cliente',
                      'des_cliente',
                      'tipo_cliente',
                      'des_vend_cliente',
                      'estatus_cliente',
                      'credito_dias_cliente',
                      'reten_cliente',
                      'mod_factur',
                      'mod_consig',
                      'detalles',
                      'Actualizar'
                    ];
                    /* this.dataSource = this.ELEMENT_DATA; */
                    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                    this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                    this.dataSource.paginator = this.paginator;
              }
            },
            error =>{
              console.log('una respuesta');
              console.log(<any>error.error.message);
              this.displayedColumns = [
                'cod_cliente',
                'des_cliente',
                'tipo_cliente',
                'des_vend_cliente',
                'estatus_cliente',
                'credito_dias_cliente',
                'reten_cliente',
                'mod_factur',
                'mod_consig',
                'detalles',
                'Actualizar'
              ];
              this.dataSource = new MatTableDataSource<any>();
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                    this.dataSource.paginator = this.paginator;
            }
          );
      }
    })
    event.srcElement.value = null;
  }
 async listarListaPrecioSPCL(){
    try {
     const response =  await this.servicio.listarListaPrecioSPCLhttp(this.atrb.COD_EMPRESA).toPromise();
          this.LISTA_SPCL = [];
          for (const i in response) {
            this.LISTA_SPCL.push(response[i]);
          }
    } catch (error) {
      console.log(error);
    }
  }
 async listarAlmacenSPCL(){
    try {
     const response = await this.servicio.listarAlmacenSPCLhttp(this.atrb.COD_EMPRESA).toPromise();
          this.ALMACEN_SPCL = [];
          for (const i in response) {
            this.ALMACEN_SPCL.push(response[i]);
          }
    } catch (error) {
      console.log(error);
    }
  }
 async listarAlmacenCdnDtrbdrPvp(){
    try {
     const response = await this.servicio.listarAlmacenCDNhttp(this.atrb.COD_EMPRESA).toPromise();
          this.ALMACEN_CDN = [];
          this.ALMACEN_DSTRBDR = [];
          this.ALMACEN_PVP = [];
          for (const i in response) {
            if (response[i].tipo_deposito.toUpperCase() === await this.atrb.CDN.toUpperCase()) {
              this.ALMACEN_CDN.push(response[i]);
            }
            if (response[i].tipo_deposito.toUpperCase() === await this.atrb.DSTRBDR.toUpperCase()) {
              this.ALMACEN_DSTRBDR.push(response[i]);
            }
            if (response[i].tipo_deposito.toUpperCase() === await this.atrb.PVP.toUpperCase()) {
              this.ALMACEN_PVP.push(response[i]);
            }
          }
    } catch (error) {
      console.log(error);
    }
  }
 async listarAlmacenes_PT(){
    try {
      const response = await this.servicio.listarAlmacen_PThttp(this.atrb.COD_EMPRESA).toPromise();
          this.ALMACEN_PT = [];
          for (const i in response) {
            if (response[i].tipo_deposito.toUpperCase() === await this.atrb.PT.toUpperCase() ||
                 response[i].tipo_deposito.toUpperCase() ===  await this.atrb.PTE.toUpperCase()||
                  response[i].tipo_deposito.toUpperCase() === await this.atrb.PTO.toUpperCase()) {
              this.ALMACEN_PT.push(response[i]);
            }
          }
    } catch (error) {
      console.log(error);
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}


