import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';

@Component({
  selector: 'app-form-formula-unidad-mpp',
  templateUrl: './form-formula-unidad-mpp.component.html',
  styleUrls: ['./form-formula-unidad-mpp.component.css']
})
export class FormFormulaUnidadMppComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto',{static:true}) cod_producto:ElementRef;
  @ViewChild('cantidad_prod',{static:true}) cantidad_prod:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormFormulaUnidadMppComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');


      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'cant_producto':'',
        'ord_producto':'',
        'cod_orden_producto':''

      });
    }

  ngOnInit(): void {

    this.cod_producto.nativeElement.value = this.data.producto.cod_producto;
    this.cantidad_prod.nativeElement.value = this.data.producto.cant_producto;
    this.listarFormulaprodMpp(this.data.producto.cod_producto);

  }
  ngAfterViewInit() {

  }

  listarFormulaprodMpp(xvalor:any){
    var valor = {cod_producto:xvalor}
   this.servicio.buscarFormulaProdMpphttp(valor).subscribe(
     response =>{
       console.log(response);
         this.ELEMENT_DATA = [];
             for (const i in response.producto1) {
               var resul = parseInt(response.producto1[i].cantidad_prod_mp.replace('.', '')
               .replace('.', '')
               .replace('.', '')
               .replace(',', '.'))*parseInt(this.data.producto.cant_producto.replace('.', '')
               .replace('.', '')
               .replace('.', '')
               .replace(',', '.'));
               this.ELEMENT_DATA.push({
                 cod_producto:response.producto1[i].cod_prod_mp,
                 des_producto:response.producto1[i].des_prod_mp,
                 cant_unidad_producto:response.producto1[i].cantidad_prod_mp,
                 cant_producto:resul.toFixed(2).replace(/\D/g, "")
                 .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                 unidad_prod_mp:response.producto1[i].unidad_prod_mp,
               });
             }
             for (const i in response.producto2) {

              this.ELEMENT_DATA2.push({
                cod_producto2:response.producto2[i].cod_producto,
                des_producto2:response.producto2[i].des_producto,
                cant_producto2:response.producto2[i].cant_producto
              });
            }
             this.displayedColumns = [
                                       'cod_producto',
                                       'cant_unidad_producto',
                                       'cant_producto',
                                       'unidad_medida'
                                     ];
              this.displayedColumns2 = [
                                       'cod_producto',
                                       'des_producto',
                                       'cant_producto'
                                     ];
             this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);

     },
     error =>{
       console.log(error);
       console.log(<any>error.error.message);
       this.displayedColumns = [
                                'cod_producto',
                                'cant_unidad_producto',
                                'cant_producto',
                                'unidad_medida'
                              ];
      this.displayedColumns2 = [
                                'cod_producto',
                                'des_producto',
                                'cant_producto'
                              ];
       this.dataSource1 = new MatTableDataSource<any>();
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
   );
 }

modalrespuesta(){
  if (this.ELEMENT_DATA.length > 0) {
    this.accion = "enviarordenprod";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '25%',
      height:'25%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){

        this.forma.value.ord_producto = this.ELEMENT_DATA;
        console.log(this.forma.value);
        this.dialogRefp.close(this.forma.value);

      }
    });
  }else{
    this.snackBar.open('Debes seleccionar al menos un producto', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

  cerrarmodal(): void {
    this.dialogRefp.close('cerrar');
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

}












