import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { Router } from '@angular/router';

import * as moment from 'moment';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../servicios/excel.service';


export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-pedidos-n-bs-um',
  templateUrl: './form-pedidos-n-bs-um.component.html',
  styleUrls: ['./form-pedidos-n-bs-um.component.css']
})
export class FormPedidosNBsUmComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('producto_pedido') producto_pedido:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;

  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('desc_pedido_monto',{static:true}) desc_pedido_monto:ElementRef;
  @ViewChild('lote_producto',{static:true}) lote_producto:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('cod_lista_precio',{static:true}) cod_lista_precio:ElementRef;
  @ViewChild('iva',{static:true}) iva:ElementRef;
  
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;

  @ViewChild('unidad_manejo',{static:true}) unidad_manejo:ElementRef;
  @ViewChild('cantidad_existencia',{static:true}) cantidad_existencia:ElementRef;

  @ViewChild('cantidad_bultos',{static:true}) cantidad_bultos:ElementRef;
  @ViewChild('cantidad_pedido',{static:true}) cantidad_pedido:ElementRef;

  @ViewChild('desc_prod',{static:true}) desc_prod:ElementRef;

  stateGroupOptions: Observable<StateGroup[]>;
  stateGroupOptions2: Observable<StateGroup[]>;
  bandera:any = true;
  bandera_2:any = true;
  value1 = '';
  nombre:any;
forma:FormGroup;
lista:any;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
key_hash:any;
accion:any;
tipo_cliente:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
codigprod:any;
dialogSpin:any;
data2:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    public dialog: MatDialog,
     private snackBar: MatSnackBar,
      private servicio:nkServices,
      private excel:ExcelService,
      private formb:FormBuilder,
      private router:Router,
       fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormPedidosNBsUmComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

      this.forma = fb.group({
        'id_pedido':data.id,
        'cod_cliente':data.cod_cliente,
        'des_cliente':data.des_cliente,
        'tipo_cliente':data.tipo_cliente,
        'mod_factur':data.mod_factur+' / '+data.tipo_cliente,
        'cod_vendedor':'',
        'des_vendedor':data.des_vendedor,
        'estatus_cliente':data.estatus_cliente,
        'estatus_pedido':data.estatus_pedido,
        'tipo2_cliente':data.tipo2_cliente,
        'credito_dias_cliente':data.credito_dias_cliente,
        'reten_cliente':data.reten_cliente,
        'comercializacion_pedido':data.comercializacion,
        'producto_pedido':'',
        'lote_producto':'',
        'dispo_pedido':'',
        'cantidad_pedido':'',
        'iva_exento':'0,00',
        'monto_exento':'0,00',
        'subtotal_pedido':'0,00',
        'total_pedido':'0,00',
        'iva_pedido':'16',
        'iva_monto':'0,00',
        'desc_pedido':'0,00',
        'desc_pedido_monto':'0,00',
        'tipo_pedido':'NB',
        'cod_lista_precio':'',
        'unidad_manejo':'',
        'cantidad_existencia':'',
        'cantidad_bultos':'',
        'tasa_precio':'',
        'desc_prod':'0',
        'desc_prod_monto':'0'

      });

      this.stateForm = this.formb.group({
        stateGroup: '',
      });
    }

  ngOnInit(): void {
    
    this.consultarPedido(this.data.cod_pedido);
    this.lote = true;
    this.formulario();
    this.listarproductot();

    if (this.data.cod_pedido === undefined ||  this.data.cod_pedido === '') {
        console.log('entro a buscar la lista');
      this.buscarLprecios();
    }else{
      var valor={cod_cliente:this.data.cod_cliente}
      this.servicio.listarClientePedidoTipohttp(valor ).subscribe(
        response =>{
          console.log(response);
          this.tipo_cliente = response.resul[0].tipo_cliente;
          this.recalcularPrecio(0,this.tipo_cliente);
        },
        error =>{
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    }


  }
  ngAfterViewInit() {

  }
  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }

    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups2
        .map(group2 => ({letter: group2.letter, names: filter(group2.names, value), code: filter(group2.code, value)}))
        .filter(group2 => group2.names.length > 0 || group2.code.length > 0 );
    }

    return this.stateGroups2;
  }

  send_product(){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                      cod_menu: localStorage.getItem('sub_main'),
                      cod_sesion: localStorage.getItem('cod_sesion'),
                      dir_ip_client: localStorage.getItem('dir_ip'),
                    sesion_unica:sessionStorage.getItem('sesion_unica')};
  
  this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
  response =>{
  if (response.resul) {
  var dialogRef:any;
  let datos = localStorage.getItem('cod_usuario');
  let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
  this.accion = 'dscnt';
    dialogRef = this.dialog.open(FormKeyComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);
  
    this.desc_prod.nativeElement.value = result.descuento;
      }
    });
  }else{
  this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
  verticalPosition: this.verticalPosition,});
  }
  },
  error =>{
  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
  verticalPosition: this.verticalPosition,});
  }
  );
      
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
  }

  buscarDataProductoUmCant(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        var cod_producto = xvalor.split('-');
        var datos = {cod_producto:cod_producto[0], 
                    mod_factur:this.data.mod_factur}
        this.servicio.buscarDataProductoUmCanthttp(datos).subscribe(
          response =>{
            this.dialogSpin.close();
            console.log(response,'respuestas');
            if (parseInt(response.cant_producto) <= 0) {
              this.bandera = false;
            }else{
              this.bandera = true;
            }

            if (parseInt(response.unidad_manejo) <= 0 || response.unidad_manejo === null || response.unidad_manejo === undefined || response.unidad_manejo === '') {
              this.bandera_2 = false;
            }else{
             
              this.bandera_2 = true;
            }
            
            this.unidad_manejo.nativeElement.value = response.unidad_manejo;
            this.cantidad_existencia.nativeElement.value = response.cant_producto;
          
          },
          error =>{
            this.dialogSpin.close();
            console.log(<any>error);
            this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  listarproductot(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarProductoTerminadoPTEPTPTOhttp(this.data).subscribe(
      response =>{
        this.stateGroupOptions = this.forma.get('producto_pedido')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto+'-'+response[i].des_producto+' ( '+response[i].cod_deposito+' )');
                  }
                  console.log(this.producto);
                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.codigo
                  }];
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }
  listarClientePedidoTipo(xvalor:any){
    try {
      var valor={cod_cliente:xvalor}
      this.servicio.listarClientePedidoTipohttp(valor).subscribe(
        response =>{
          console.log(response);
          this.tipo_cliente = response.resul[0].tipo_cliente;
        },
        error =>{
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  buscarLprecios(){

      var cod = this.forma.value.producto_pedido.split(' ');
    var cod_p = cod[0].trim();


    var valor:any = {cod_producto:cod_p,cod_cliente:this.data.cod_cliente,tipo_cliente:this.data.tipo_cliente}
    this.servicio.buscarLprecioshttp(valor).subscribe(
      response =>{
        console.log(response[0].cod_lista_precio_producto);
        this.cod_lista_precio.nativeElement.value = response[0].cod_lista_precio_producto;
        this.snackBar.open('Su lista de precios es la ultima actualización', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );

  }
  recalcularPrecio(termino:any, xvalor:any){
    if (termino != 'calcular') {
      this.tipo_cliente = xvalor;
    }

      var cod = this.forma.value.producto_pedido.split(' ');
    var cod_p = cod[0].trim();

    var valor:any = {cod_producto:cod_p,cod_cliente:this.data.cod_cliente,tipo_cliente:this.data.tipo_cliente}
    this.servicio.buscarLprecioshttp(valor).subscribe(
      response =>{
        console.log(response[0]);
        console.log(this.cod_lista_precio.nativeElement.value);
        if (response[0].cod_lista_precio_producto != this.cod_lista_precio.nativeElement.value) {
          switch (termino) {
            case 'calcular':
              this.accion = "recalcularpedido";
                            var dialogRef = this.dialog.open(FormmMsgComponent, {
                              width: '100%',
                              height:'100%',
                              data: {id:'',
                                    accion:this.accion},
                              backdropClass: "bdrop",
                              disableClose: true,
                              panelClass: 'pedidos_modal'
                            });
                            dialogRef.afterClosed().subscribe(result => {
                              if(result != 'cerrar'){
                                var lista = response[0];
                                this.servicio.buscarLpreciosRelacionhttp(response[0]).subscribe(
                                  response=>{
                                    var resul = 0;
                                    var valor;
                                    console.log(response);
                                    console.log(this.ELEMENT_DATA);
                                    for (const i in this.ELEMENT_DATA) {
                                        var indice = this.ELEMENT_DATA[i].cod_producto;
                                      for (const k in response) {
                                        if (indice.toUpperCase() === response[k].cod_producto) {
                                          valor = response[k].precio_producto_base_bs;
                                          break;
                                        }
                                      }
                                        var subtotal = parseFloat(this.ELEMENT_DATA[i].cant_producto.replace('.','')
                                                                                                    .replace('.','')
                                                                                                    .replace('.','')
                                                                                                    .replace(',','.')) * parseFloat(valor.replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace(',','.'));
                                      
                                      var monto_prod_descuento = parseFloat(this.ELEMENT_DATA[i].desc_prod.replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace(',','.')) * subtotal / 100;
                            
                                            if (subtotal > monto_prod_descuento) {
                                              subtotal = subtotal - monto_prod_descuento;
                                            }else{
                                              subtotal = monto_prod_descuento - subtotal;
                                            }                             

                                        this.ELEMENT_DATA[i].costo_producto = valor;
                                        this.ELEMENT_DATA[i].cod_lista_precio = response[0].cod_lista_precio_producto;
                                        this.ELEMENT_DATA[i].subtotal_producto = subtotal.toFixed(3).replace(/\D/g, "")
                                        .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                                        resul = parseFloat(this.ELEMENT_DATA[i].subtotal_producto.replace('.','')
                                        .replace('.','')
                                        .replace('.','')
                                        .replace(',','.')) + resul;


                                    }

                                   this.subtotal_pedido.nativeElement.value = resul.toFixed(3).replace(/\D/g, "")
                                   .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                                   let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace(',','.')));

                                    let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace(',','.'))/100);

                                    this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(3).replace(/\D/g, "")
                                    .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

                                    let resul_final = montoTotal - porcentaje;

                                    this.total_pedido.nativeElement.value = resul_final.toFixed(3).replace(/\D/g, "")
                                    .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                                    this.cod_lista_precio.nativeElement.value = response[0].cod_lista_precio_producto;
                                    console.log(this.ELEMENT_DATA);
                                  },
                                  error=>{
                                    console.log(<any>error.error.message);
                                    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                                      verticalPosition: this.verticalPosition,});
                                  }

                                );

                                /* let cod_vendedor = localStorage.getItem('cod_usuario');
                                let cod_cliente = this.forma.value.cod_cliente.split(' ');
                                let lote_producto = this.forma.value.lote_producto.split(' ');
                                this.forma.value.cod_vendedor = cod_vendedor;
                                this.forma.value.lote_producto = lote_producto[0];
                                this.forma.value.cod_cliente = cod_cliente[1];
                                this.forma.value.producto_pedido = this.ELEMENT_DATA;
                                this.forma.value.iva_monto = this.iva_monto.nativeElement.value;
                                this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                                this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                                this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                                this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                                console.log(this.forma.value);
                              this.dialogRefp.close(this.forma.value); */
                              this.snackBar.open('Usted realizo la actualizacion de la lista de precios de forma exitosa', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,});
                              }

                            });

              break;

            default:
              if (this.cod_lista_precio.nativeElement.value === response[0].cod_lista_precio_producto) {
                this.snackBar.open('Su lista de precios es la ultima actualización', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }else{
                this.snackBar.open('Su lista de precios no esta actualizada', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
              break;
          }
        }else{
          console.log(termino);
          if (termino === 0) {
            this.snackBar.open('Su lista de precios es la ultima actualización', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }else{
            this.snackBar.open('Ya usted realizo la actualizacion de la lista de precios', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }

        }


      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );

  }

  recalcularPedido(termino:any, xvalor:any){
    if (termino != 'calcular') {
      this.tipo_cliente = xvalor;
    }

      var cod = this.forma.value.producto_pedido.split(' ');
    var cod_p = cod[0].trim();

    var valor:any = {cod_producto:cod_p,cod_cliente:this.data.cod_cliente,tipo_cliente:this.data.tipo_cliente}
    this.servicio.buscarLprecioshttp(valor).subscribe(
      response =>{
       
          switch (termino) {
            case 'calcular':
              this.accion = "recalcularpedido";
                            var dialogRef = this.dialog.open(FormmMsgComponent, {
                              width: '100%',
                              height:'100%',
                              data: {id:'',
                                    accion:this.accion},
                              backdropClass: "bdrop",
                              disableClose: true,
                              panelClass: 'pedidos_modal'
                            });
                            dialogRef.afterClosed().subscribe(result => {
                              if(result != 'cerrar'){
                                var lista = response[0];
                                this.servicio.buscarLpreciosRelacionhttp(response[0]).subscribe(
                                  response=>{
                                    var resul = 0;
                                    var valor;
                                    console.log(response);
                                    console.log(this.ELEMENT_DATA);
                                    for (const i in this.ELEMENT_DATA) {
                                        var indice = this.ELEMENT_DATA[i].cod_producto;
                                      for (const k in response) {
                                        if (indice.toUpperCase() === response[k].cod_producto) {
                                          valor = response[k].precio_producto_base_bs;
                                          break;
                                        }
                                      }
                                        var subtotal = parseFloat(this.ELEMENT_DATA[i].cant_producto.replace('.','')
                                                                                                    .replace('.','')
                                                                                                    .replace('.','')
                                                                                                    .replace(',','.')) * parseFloat(valor.replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace(',','.'));
                                      
                                      var monto_prod_descuento = parseFloat(this.ELEMENT_DATA[i].desc_prod.replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace('.','')
                                                                                                                    .replace(',','.')) * subtotal / 100;
                            
                                            if (subtotal > monto_prod_descuento) {
                                              subtotal = subtotal - monto_prod_descuento;
                                            }else{
                                              subtotal = monto_prod_descuento - subtotal;
                                            }                             

                                        this.ELEMENT_DATA[i].costo_producto = valor;
                                        this.ELEMENT_DATA[i].cod_lista_precio = response[0].cod_lista_precio_producto;
                                        this.ELEMENT_DATA[i].subtotal_producto = subtotal.toFixed(3).replace(/\D/g, "")
                                        .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                                        resul = parseFloat(this.ELEMENT_DATA[i].subtotal_producto.replace('.','')
                                        .replace('.','')
                                        .replace('.','')
                                        .replace(',','.')) + resul;


                                    }

                                   this.subtotal_pedido.nativeElement.value = resul.toFixed(3).replace(/\D/g, "")
                                   .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                                   let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace(',','.')));

                                    let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace(',','.'))/100);

                                    this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(3).replace(/\D/g, "")
                                    .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

                                    let resul_final = montoTotal - porcentaje;

                                    this.total_pedido.nativeElement.value = resul_final.toFixed(3).replace(/\D/g, "")
                                    .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                                    this.cod_lista_precio.nativeElement.value = response[0].cod_lista_precio_producto;
                                    console.log(this.ELEMENT_DATA);
                                  },
                                  error=>{
                                    console.log(<any>error.error.message);
                                    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                                      verticalPosition: this.verticalPosition,});
                                  }

                                );

                                /* let cod_vendedor = localStorage.getItem('cod_usuario');
                                let cod_cliente = this.forma.value.cod_cliente.split(' ');
                                let lote_producto = this.forma.value.lote_producto.split(' ');
                                this.forma.value.cod_vendedor = cod_vendedor;
                                this.forma.value.lote_producto = lote_producto[0];
                                this.forma.value.cod_cliente = cod_cliente[1];
                                this.forma.value.producto_pedido = this.ELEMENT_DATA;
                                this.forma.value.iva_monto = this.iva_monto.nativeElement.value;
                                this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                                this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                                this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                                this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                                console.log(this.forma.value);
                              this.dialogRefp.close(this.forma.value); */
                              this.snackBar.open('Usted realizo la actualizacion de la lista de precios de forma exitosa', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,});
                              }

                            });

              break;

            default:
              if (this.cod_lista_precio.nativeElement.value === response[0].cod_lista_precio_producto) {
                this.snackBar.open('Su lista de precios es la ultima actualización', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }else{
                this.snackBar.open('Su lista de precios no esta actualizada', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
              break;
          }
        


      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );

  }


  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
    }else{
    console.log(this.forma.value);
    this.dialogRefp.close(this.forma.value);
    }

  }

  public consultarPedido(xvalor:any){
    console.log(xvalor);
    var valor = {cod_pedido:xvalor};
    try {
      this.servicio.buscarPedidoProductocodttp(valor).subscribe(
        response =>{
        console.log(response);
        this.ELEMENT_DATA = [];
        for (const i in response.resul) {
          if (parseFloat(response.resul[i].desc_prod_monto) <= 0 
          || parseFloat(response.resul[i].desc_prod) <= 0
          || response.resul[i].desc_prod_monto === null || response.resul[i].desc_prod_monto === undefined || response.resul[i].desc_prod_monto === ''
          || response.resul[i].desc_prod === null || response.resul[i].desc_prod === undefined || response.resul[i].desc_prod === '') {
            response.resul[i].desc_prod_monto = '0,00';
            response.resul[i].desc_prod = '0,00';
          }
          this.ELEMENT_DATA.push({
            cod_producto:response.resul[i].cod_producto,
            des_producto:response.resul[i].des_producto,
            cod_lista_precio:response.resul2[0].cod_lista_precio,
            cant_producto:response.resul[i].cant_producto,
            costo_producto:response.resul[i].costo_producto,
            tipo_producto:response.resul[i].tipo_producto,
            desc_prod:response.resul[i].desc_prod,
            desc_prod_monto:response.resul[i].desc_prod_monto,
            subtotal_producto: response.resul[i].subtotal_producto,
            tamano_producto:response.resul[i].tamano_producto,
            estatus_fiscal:response.resul[i].estatus_fiscal
          });
        }
     
        this.desc_pedido.nativeElement.value = response.resul2[0].desc_pedido;
        
        this.subtotal_pedido.nativeElement.value = response.resul2[0].subtotal_costo_pedido;
       
        this.desc_pedido_monto.nativeElement.value = response.resul2[0].desc_pedido_monto
        this.total_pedido.nativeElement.value = response.resul2[0].total_costo_pedido;
        this.cod_lista_precio.nativeElement.value = response.resul2[0].cod_lista_precio;
        this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
        console.log(this.ELEMENT_DATA);
        this.lista = true;
        },
        error =>{
          console.log(<any>error.error.message);
          this.lista = false;
        }
      );
  
    } catch (error) {
      this.snackBar.open(error.toString(), 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
formulario():void{
  /* this.ELEMENT_DATA = []; */
  this.cant = false;
  this.displayedColumns = [
    'cod_lista_precio',
    'tasa_lista_precio',
    'cod_producto',
    'des_producto',
    'cant_producto',
    'costo_producto',
    'descuento',
    'monto_prod_descuento',
    'subtotal_producto',
    'remov_elem'
   ];
/* this.dataSource = this.ELEMENT_DATA; */
this.dataSource1 = new MatTableDataSource<PeriodicElementfacturas>(this.ELEMENT_DATA);
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource1.filter = filterValue.trim().toLowerCase();
}

cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_producto.toString())
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.')) ;
       });


     return suma;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

  }
  costo_producto() {

    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].costo_producto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });


       return suma.toFixed(3).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{3})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  costo_producto_descuento() {

    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].desc_prod)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
  
       return suma.toFixed(3).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{3})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  
  costo_producto_descuento_monto() {
  
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].desc_prod_monto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
  
       return suma.toFixed(3).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{3})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  sub_total_producto() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].subtotal_producto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });


       return suma.toFixed(3).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{3})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }

  cantidad_bultos_prod() {
    try {
      var canti_bultos_unidad = parseInt(this.cantidad_bultos.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) * parseInt(this.unidad_manejo.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'))
      this.num.nativeElement.value = canti_bultos_unidad.toFixed(0).replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  

  addElement() {
    try {

      if (parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                                            .replace('.','')
                                            .replace('.','')
                                            .replace('.','')
                                            .replace('.','')
                                            .replace(',','.')) === 0) {
                                              let arry = [];
                                              let arry2 = [];
                                              console.log(this.num.nativeElement.value);
                                          
                                          
                                              if (parseInt(this.num.nativeElement.value) > 0  && this.producto_pedido.nativeElement.value !== '' && 
                                              parseInt(this.cantidad_bultos.nativeElement.value) > 0  && this.unidad_manejo.nativeElement.value > 0) {
                                          
                                                var cod = this.forma.value.producto_pedido.split('-');
                                              var cod_p = cod[0].trim();
                                          
                                              /* var valor:any = {cod_producto:cod_p,cod_cliente:this.data.cod_cliente,tipo_cliente:this.data.tipo_cliente} */
                                              var valor:any = {cod_producto:cod_p,
                                                cod_cliente:this.data.cod_cliente,
                                                tipo_cliente:this.data.tipo_cliente}
                                                valor.val_def = 'P';
                                                valor.tipo_operacion = 'AGREGAR';
                                                valor.cod_usuario = localStorage.getItem('cod_usuario');
                                                this.servicio.validar_acceso_acciones_http(valor).subscribe(
                                                  response=>{
                                                    this.servicio.buscarProductotcodBolivaressinIvanhttp(valor).subscribe(
                                                      response =>{
                                                        console.log(response,'obejeto d efuego');
                                                        if (response[1].length > 0 &&  parseFloat(response[1][0].precio_producto_base_bs.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')) > 0) {
                                                          console.log(response);
                                                        this.producto_pedido.nativeElement.value = '';
                                                          let subtotal = (parseFloat(response[1][0].precio_producto_base_bs.replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace(',','.'))  *  parseInt(this.num.nativeElement.value.replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace(',','.')));
                                              
                                                          var monto_prod_descuento = parseFloat(this.desc_prod.nativeElement.value.replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace('.','')
                                                                                                                          .replace(',','.')) * subtotal / 100;
                                              
                                                                                        if (subtotal > monto_prod_descuento) {
                                                                                          subtotal = subtotal - monto_prod_descuento;
                                                                                        }else{
                                                                                          subtotal = monto_prod_descuento - subtotal;
                                                                                        }
                                                
                                                          let costoprodcto = parseFloat(response[1][0].precio_producto_base_bs);
                                                
                                                        if (this.ELEMENT_DATA.length == 0) {
                                                          
                                                          this.codigo = [];
                                                          this.producto = [];
                                                          this.stateGroups2 =[];
                                                
                                                          this.lotepro = [];
                                                          this.listarproductot();
                                                          this.ELEMENT_DATA.push({
                                                            cod_producto:response[0][0].cod_producto,
                                                            des_producto:response[0][0].des_producto,
                                                            cod_lista_precio:response[2][0].cod_lista_precio_producto,
                                                            tasa_lista_precio:response[2][0].tasa_precio,
                                                            cant_producto:this.num.nativeElement.value,
                                                            costo_producto:response[1][0].precio_producto_base_bs,
                                                
                                                            tipo_producto:response[0][0].tipo_producto,
                                                            desc_prod:parseFloat(this.desc_prod.nativeElement.value).toFixed(3).replace(/\D/g, "")
                                                                                .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                            desc_prod_monto:monto_prod_descuento.toFixed(3).replace(/\D/g, "")
                                                                                                  .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                
                                                            subtotal_producto: subtotal.toFixed(3).replace(/\D/g, "")
                                                            .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                
                                                            lote_producto:response[0][0].lote_producto,
                                                            tamano_producto:response[0][0].tamano_producto
                                                          });
                                                
                                                          this.subtotal_pedido.nativeElement.value = subtotal.toFixed(3).replace(/\D/g, "")
                                                          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                                
                                                          /* let total = (subtotal * 16 / 100);
                                                
                                                          console.log(total); */
                                                
                                                          /* this.iva_monto.nativeElement.value = total.toFixed(3).replace(/\D/g, "")
                                                          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."); */
                                                          let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                                                          .replace('.','')
                                                          .replace('.','')
                                                          .replace(',','.')));
                                                
                                                          let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                                                          .replace('.','')
                                                          .replace('.','')
                                                          .replace(',','.'))/100);
                                                
                                                          this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(3).replace(/\D/g, "")
                                                          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
                                                
                                                          let resul_final = montoTotal - porcentaje;
                                                
                                                          this.total_pedido.nativeElement.value = resul_final.toFixed(3).replace(/\D/g, "")
                                                          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                                
                                                          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                                                
                                                          this.producto_pedido.nativeElement.value = '';
                                                            this.num.nativeElement.value = '';
                                                            this.cantidad_existencia.nativeElement.value = '';
                                                            this.cantidad_bultos.nativeElement.value = '';
                                                            this.unidad_manejo.nativeElement.value = '';
                                                            this.desc_prod.nativeElement.value = '0';
                                                            this.bandera = true;
                                                            this.bandera_2 = true;
                                                          this.forma.value.cantidad_pedido = '';
                                                          this.cant = false;
                                                          this.lote = false;
                                                          this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                                                                .pipe(
                                                                                  startWith(''),
                                                                                  map(value => this._filterGroup2(value))
                                                                                );
                                                
                                                                  this.stateGroups2= [{
                                                                    letter: 'Lotes',
                                                                    names: '',
                                                                    code: ''
                                                                  }];
                                                                  console.log(this.ELEMENT_DATA,'this.ELEMENT_DATA');
                                                        }else{
                                                          if (this.ELEMENT_DATA.length <=45) {
                                                
                                                            for (const i in this.ELEMENT_DATA) {
                                                              arry.push(this.ELEMENT_DATA[i].cod_producto);
                                                              arry2.push(this.ELEMENT_DATA[i].lote_producto);
                                                             }
                                                            console.log(this.num.nativeElement.value);
                                                             let lote = arry2.includes(response[0][0].lote_producto);
                                                             let index = arry.includes(response[0][0].cod_producto);
                                                
                                                             if (!index && !lote || index && !lote || !index && lote) {
                                                              
                                                               this.lotepro = [];
                                                               this.stateGroups2 =[];
                                                               this.codigo = [];
                                                               this.producto = [];
                                                                 this.listarproductot();
                                                                 this.ELEMENT_DATA.push({
                                                                   cod_producto:response[0][0].cod_producto,
                                                                   des_producto:response[0][0].des_producto,
                                                                   cod_lista_precio:response[2][0].cod_lista_precio_producto,
                                                                   tasa_lista_precio:response[2][0].tasa_precio,
                                                                   cant_producto:this.num.nativeElement.value,
                                                                   costo_producto:response[1][0].precio_producto_base_bs,
                                                                   tipo_producto:response[0][0].tipo_producto,
                                                                   desc_prod:parseFloat(this.desc_prod.nativeElement.value).toFixed(3).replace(/\D/g, "")
                                                                                .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                                   desc_prod_monto:monto_prod_descuento.toFixed(3).replace(/\D/g, "")
                                                                                                                  .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                                   subtotal_producto: subtotal.toFixed(3).replace(/\D/g, "")
                                                                   .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                                   lote_producto:response[0][0].lote_producto,
                                                                   tamano_producto:response[0][0].tamano_producto
                                                                 });
                                                
                                                                 this.subtotal_pedido.nativeElement.value = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                                                                 .replace('.','')
                                                                 .replace('.','')
                                                                 .replace(',','.'))) +  (subtotal);
                                                                 this.subtotal_pedido.nativeElement.value = parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(3).replace(/\D/g, "")
                                                                 .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                                
                                                                 let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                                                                 .replace('.','')
                                                                 .replace('.','')
                                                                 .replace(',','.')));
                                                
                                                                 let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                                                                 .replace('.','')
                                                                 .replace('.','')
                                                                 .replace(',','.'))/100);
                                                
                                                                 this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(3).replace(/\D/g, "")
                                                                 .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
                                                
                                                                 let resul_final = montoTotal - porcentaje;
                                                
                                                                 this.total_pedido.nativeElement.value = resul_final.toFixed(3).replace(/\D/g, "")
                                                                 .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                                
                                                
                                                                 this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                                                console.log(this.ELEMENT_DATA.sort(function(a, b) {
                                                
                                                  var nameA = a.cod_producto.toUpperCase();
                                                  var nameB = b.cod_producto.toUpperCase();
                                                
                                                  if (nameA > nameB) {     return -1;   }      if (nameA < nameB) {
                                                    return 1;
                                                  }
                                                
                                                  return 0;
                                                
                                                }),'ordenar');
                                                
                                                                    this.producto_pedido.nativeElement.value = '';
                                                                    this.num.nativeElement.value = '';
                                                                    this.cantidad_existencia.nativeElement.value = '';
                                                                    this.cantidad_bultos.nativeElement.value = '';
                                                                    this.unidad_manejo.nativeElement.value = '';
                                                                    this.desc_prod.nativeElement.value = '0';
                                                                    this.bandera = true;
                                                                    this.bandera_2 = true;
                                                                  this.forma.value.cantidad_pedido = '';
                                                                  this.cant = false;
                                                                  this.lote = false;
                                                                 this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                                                                 .pipe(
                                                                                   startWith(''),
                                                                                   map(value => this._filterGroup2(value))
                                                                                 );
                                                
                                                                   this.stateGroups2= [{
                                                                     letter: 'Lotes',
                                                                     names: '',
                                                                     code: ''
                                                                   }];
                                                
                                                             }else{
                                                               this.stateGroups2 =[];
                                                               this.lotepro = [];
                                                               this.codigo = [];
                                                               this.producto = [];
                                                               this.listarproductot();
                                                               this.producto_pedido.nativeElement.value = '';
                                                            this.num.nativeElement.value = '';
                                                            this.cantidad_existencia.nativeElement.value = '';
                                                            this.cantidad_bultos.nativeElement.value = '';
                                                            this.unidad_manejo.nativeElement.value = '';
                                                            this.desc_prod.nativeElement.value = '0';
                                                            this.bandera = true;
                                                            this.bandera_2 = true;
                                                              this.forma.value.cantidad_pedido = '';
                                                              this.cant = false;
                                                              this.lote = false;
                                                                 this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                                                                 .pipe(
                                                                                   startWith(''),
                                                                                   map(value => this._filterGroup2(value))
                                                                                 );
                                                
                                                                   this.stateGroups2= [{
                                                                     letter: 'Lotes',
                                                                     names: '',
                                                                     code: ''
                                                                   }];
                                                                 this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                                                                   verticalPosition: this.verticalPosition,});
                                                             }
                                                          }else{
                                                            this.snackBar.open('Solo puede agregar un maximo de  45 items', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                                                              verticalPosition: this.verticalPosition,});
                                                          }
                                                        }
                                                        }else{
                                                          this.stateGroups2 =[];
                                                               this.lotepro = [];
                                                               this.codigo = [];
                                                               this.producto = [];
                                                               this.listarproductot();
                                                               this.producto_pedido.nativeElement.value = '';
                                                               this.num.nativeElement.value = '';
                                                               this.cantidad_existencia.nativeElement.value = '';
                                                               this.cantidad_bultos.nativeElement.value = '';
                                                               this.unidad_manejo.nativeElement.value = '';
                                                               this.desc_prod.nativeElement.value = '0';
                                                               this.bandera = true;
                                                               this.bandera_2 = true;
                                                                 this.forma.value.cantidad_pedido = '';
                                                                 this.cant = false;
                                                                 this.lote = false;
                                                                 this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                                                                 .pipe(
                                                                                   startWith(''),
                                                                                   map(value => this._filterGroup2(value))
                                                                                 );
                                                
                                                                   this.stateGroups2= [{
                                                                     letter: 'Lotes',
                                                                     names: '',
                                                                     code: ''
                                                                   }];
                                                                   this.snackBar.open('Este producto no esta asignado a este cliente', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                                    verticalPosition: this.verticalPosition,});
                                                        }
                                                      },
                                                      error =>{
                                                        console.log(<any>error.error.message);
                                                        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                                                          verticalPosition: this.verticalPosition,});
                                                      }
                                                    );
                                                  },
                                                  error=>{
                                                    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                      verticalPosition: this.verticalPosition,});
                                                  }
                                                );
                                              
                                              }else{
                                          
                                                if (parseInt(this.num.nativeElement.value) <= 0 || isNaN(this.num.nativeElement.value) || this.num.nativeElement.value === "") {
                                                  this.snackBar.open('La cantidad a solicitar debe ser mayor a 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                    verticalPosition: this.verticalPosition,});
                                                }
                                                if (parseInt(this.unidad_manejo.nativeElement.value) <= 0 || isNaN(this.unidad_manejo.nativeElement.value) || this.unidad_manejo.nativeElement.value === "") {
                                                  this.snackBar.open('No tiene unidad de manejo', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                    verticalPosition: this.verticalPosition,});
                                                }
                                                if (parseInt(this.cantidad_bultos.nativeElement.value) <= 0 || isNaN(this.cantidad_bultos.nativeElement.value) || this.cantidad_bultos.nativeElement.value === "") {
                                                  this.snackBar.open('Debe indicar la cantidad de unidades a solicitar', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                    verticalPosition: this.verticalPosition,});
                                                }
                                              if (this.producto_pedido.nativeElement.value === '') {
                                                this.snackBar.open('Debe seleccionar el producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                  verticalPosition: this.verticalPosition,});
                                              }
                                              }
                                              console.log(this.ELEMENT_DATA);
                                            }else{
                                              this.snackBar.open('Para poder continuar el campo descuento debe estar en 0,00 %', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                verticalPosition: this.verticalPosition,});
                                            }
     
    } catch (error) {
      console.log(error,'try');
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  remove(val:any){
    if (parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.')) === 0) { 
      let resta = this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
   
   this.cod_lista1.nativeElement.value = '';
   this.subtotal_pedido.nativeElement.value = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
   .replace('.','')
   .replace('.','')
   .replace(',','.')) -  parseFloat(resta[0].subtotal_producto.replace('.','')
   .replace('.','')
   .replace('.','')
   .replace(',','.')));

   let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')));

          let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'))/100);

          this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(3).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

          let resul_final = montoTotal - porcentaje;

          this.total_pedido.nativeElement.value = resul_final.toFixed(3).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          this.subtotal_pedido.nativeElement.value =parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(3).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
    this.table.renderRows();
     }else{
      this.snackBar.open('Para poder continuar el campo descuento debe estar en 0,00 %', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
   
  }

  buscarproductot(xvalor:any){
    console.log(xvalor);
    var valor:any = {valor:xvalor}
    this.servicio.buscarPRODUCTOTparamhttp(valor).subscribe(
      response =>{
        this.producto = response;
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

 /*  modalrespuesta(){
    if (this.ELEMENT_DATA.length === 0) {
      this.snackBar.open('No haz seleccionado ningun producto', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }else{
      this.accion = "enviarpedido";
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {

        if(result != 'cerrar'){
          switch (this.data.cod_pedido) {
            case '':
              console.log(this.ELEMENT_DATA);
                    let cod_vendedor = localStorage.getItem('cod_usuario');

                    this.forma.value.cod_vendedor = cod_vendedor;

                    this.forma.value.producto_pedido = this.ELEMENT_DATA;
                    this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                    this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                    this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                    this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                    this.forma.value.tasa_precio = this.ELEMENT_DATA[0].tasa_lista_precio;
                    console.log(this.forma.value);
                  this.dialogRefp.close(this.forma.value);
              break;

            default:
              console.log(this.ELEMENT_DATA);
                    let cod_vendedor1 = localStorage.getItem('cod_usuario');
                    this.forma.value.cod_pedido = this.data.cod_pedido;
                    this.forma.value.cod_vendedor = cod_vendedor1;
                    this.forma.value.producto_pedido = this.ELEMENT_DATA;
                    this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                    this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                    this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                    this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                    this.forma.value.tasa_precio = this.ELEMENT_DATA[0].tasa_lista_precio;
                    this.forma.value.desc_pedido_monto = this.desc_pedido_monto.nativeElement.value;
                    console.log(this.forma.value);
                  this.dialogRefp.close(this.forma.value);
              break;
          }
        }
      });
    }

  } */

  modalrespuesta(){
    try {
      var valor:any = {};
      valor.val_def = 'P';
      valor.tipo_operacion = 'PRESUPUESTO';
      valor.cod_usuario = localStorage.getItem('cod_usuario');
      this.servicio.validar_acceso_acciones_http(valor).subscribe(
        response=>{
          if (this.ELEMENT_DATA.length === 0) {
            this.snackBar.open('No haz seleccionado ningun producto', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }else{
            this.accion = "enviarpedido";
            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              data: {id:'',
                    accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(result => {
      
              if(result != 'cerrar'){
                switch (this.data.cod_pedido) {
                  case '':
                    console.log(this.ELEMENT_DATA);
                          let cod_vendedor = localStorage.getItem('cod_usuario');
      
                          this.forma.value.cod_vendedor = cod_vendedor;
      
                          this.forma.value.producto_pedido = this.ELEMENT_DATA;
                          this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                          this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                          this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                          this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                          this.forma.value.tasa_precio = this.ELEMENT_DATA[0].tasa_lista_precio;
                          console.log(this.forma.value);
                        this.dialogRefp.close(this.forma.value);
                    break;
      
                  default:
                    console.log(this.ELEMENT_DATA);
                          let cod_vendedor1 = localStorage.getItem('cod_usuario');
                          this.forma.value.cod_pedido = this.data.cod_pedido;
                          this.forma.value.cod_vendedor = cod_vendedor1;
                          this.forma.value.producto_pedido = this.ELEMENT_DATA;
                          this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                          this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                          this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                          this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                          this.forma.value.tasa_precio = this.ELEMENT_DATA[0].tasa_lista_precio;
                          this.forma.value.desc_pedido_monto = this.desc_pedido_monto.nativeElement.value;
                          console.log(this.forma.value);
                        this.dialogRefp.close(this.forma.value);
                    break;
                }
      
                /* let resultado = this.eliminarunidad(result.id); */
              }
            });
          }
      
        },
        error=>{
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      console.log(error);
    }
   
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
        maxWidth: '100vw',
        height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  porcentaje(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{3})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;
  let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')));

          let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'))/100);

          this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(3).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

          let resul_final = montoTotal - porcentaje;

          this.total_pedido.nativeElement.value = resul_final.toFixed(3).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

   if (this.desc_pedido.nativeElement.value === "") {
     var cero = '0,00';

     let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')));

     let porcentaje = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.') ) * parseFloat(cero)/100);

     this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(3).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")


          let resul_final = montoTotal - porcentaje;

          this.total_pedido.nativeElement.value = resul_final.toFixed(3).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
   }

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{3})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;
  let porcentaje = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.') ) * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'))/100);

          let montoTotal = (parseFloat(this.subtotal_pedido.nativeElement.value) - porcentaje);

          this.total_pedido.nativeElement.value = montoTotal.toFixed(3).replace(/\D/g, "")
   .replace(/([0-9])([0-9]{3})$/, '$1,$2')
   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
   this.subtotal_pedido.nativeElement.value = parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(3).replace(/\D/g, "")
   .replace(/([0-9])([0-9]{3})$/, '$1,$2')
   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  protec_readonly(valor:any)
  {
    try {
     
      this.cerrarsesion();
            while (parseInt(valor) === 1) {
              this.snackBar.open('Intento de hackeo de la aplicacion detectado', 'Cerrar',{duration:500,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
              console.log('Intento de hackeo de la aplicacion detectado');
    }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  public cerrarsesion(): void {
    var datos_sesion = {cod_usuario:localStorage.getItem('cod_usuario'),
                        des_usuario:localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido'),
                        cod_sesion:localStorage.getItem('cod_sesion'),
                        alerta:'alrt',
                        comentario:'desbloqueo forzado de un campo clave'}
    this.servicio.cerrar_sesionhttp(datos_sesion).subscribe(
      response =>{
    localStorage.removeItem('Nombre');
    localStorage.removeItem('cod_usuario');
    localStorage.removeItem('Apellido');
    localStorage.removeItem('Tiempo');
    localStorage.removeItem('Unidad');
    localStorage.removeItem('rol');
    localStorage.removeItem('permiso');
    localStorage.removeItem('cod_sub');
    localStorage.removeItem('sub_main');
    localStorage.removeItem('cod_valid');

    localStorage.removeItem('accion_1');
    localStorage.removeItem('accion_2');
    localStorage.removeItem('accion_3');
    localStorage.removeItem('accion_4');
    localStorage.removeItem('accion_5');

    localStorage.removeItem('cod_sesion');
    localStorage.removeItem('dir_ip');
    sessionStorage.removeItem('sesion_unica');

     this.router.navigate(['/']);
  
    console.log('entro en el cerrar sesion response');
    this.snackBar.open(response.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
      },
      error =>{
        this.router.navigate(['/']);
        console.log(error.error.message);
      }
    );  
  }
  filter_date_print(){
    try {
        
     this.excel.plantilla_pedido_producto(this.ELEMENT_DATA);
        
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  onFileChange(evt:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        const target : DataTransfer = <DataTransfer>(evt.target);

        if(target.files.length !== 1) throw new Error('No se pueden seleccionar multiples archivos');
    
        const reader : FileReader = new FileReader();
    
        reader.onload = (e: any) => {
         const bstr : string = e.target.result;
    
         const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'})
    
         const wsname : string = wb.SheetNames[0];
    
         const ws : XLSX.WorkSheet = wb.Sheets[wsname];
         this.data2 = [];
         this.data2 = (XLSX.utils.sheet_to_json(ws));
         this.cargarPedidoMasivo(this.data2);
        };
    
        reader.readAsBinaryString(target.files[0]);

      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
    }
    
  }

  cargarPedidoMasivo(objeto:any){
    try {
      
      if(objeto.length > 0 ){
          this.ELEMENT_DATA = [];
         
          
          this.subtotal_pedido.nativeElement.value = '0,00';
          this.desc_pedido_monto.nativeElement.value = '0,00';
          
          this.total_pedido.nativeElement.value = '0,00';
        let hash = {};
        objeto = objeto.filter(o => hash[o.cod_producto] ? false : hash[o.cod_producto] = true);
        var valor:any = {
          pedido:objeto,
          cod_cliente:this.data.cod_cliente,
          tipo_cliente:this.data.tipo_cliente}
            valor.val_def = 'P';
            valor.tipo_operacion = 'MASIVO';
            valor.cod_usuario = localStorage.getItem('cod_usuario');

            this.servicio.validar_acceso_acciones_http(valor).subscribe(
              response=>{
                this.servicio.buscarProductoMasivohttp(valor).subscribe(
                  response =>{
      
                    if (parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.')) === 0) {
                      var resul_final;
                      let arry = [];
                      let arry2 = [];

                      let valor_0 = true;

                      for (const i in objeto) {
                        if (parseInt(objeto[i].cantidad_pedido) === 0) {
                          
                          valor_0 = false;
                          break;
                        }
                      }

                      if (valor_0) {
                        let valor_residuo = true;
                      for (const i in objeto) {
                        for (const k in response[0]) {
                          if (objeto[i].cod_producto.toUpperCase() === response[0][k].cod_producto.toUpperCase()) {
                            console.log(parseInt(objeto[i].cantidad_pedido))
                            console.log(parseInt(response[0][k].um_producto))
                            var resul_decimal = parseInt(objeto[i].cantidad_pedido) / parseInt(response[0][k].um_producto);
                            var prod_sb = objeto[i].cod_producto.toUpperCase();
                            console.log(resul_decimal)
                            if (resul_decimal % 1 !== 0) {
                              valor_residuo = false;
                              break;
                          } 
                            
                          }
                        }
                        if (valor_residuo === false) {
                          break;
                        }
                      }
                      if (valor_residuo) {
                        for (const i in objeto) {
                          for (const k in response[1]) {
                            if (objeto[i].cod_producto.toUpperCase() === response[1][k].cod_producto.toUpperCase()) {
                              console.log(response[1][k]);
                              if (response[1].length > 0 &&  parseFloat(response[1][k].precio_producto_base_bs.replace('.','')
                              .replace('.','')
                              .replace('.','')
                              .replace('.','')
                              .replace('.','')
                              .replace(',','.')) > 0) {
            
                                                      let subtotal = (parseFloat(response[1][k].precio_producto_base_bs.replace('.','')
                                                      .replace('.','')
                                                      .replace('.','')
                                                      .replace('.','')
                                                      .replace('.','')
                                                      .replace(',','.'))  *  parseInt(objeto[i].cantidad_pedido.toString().replace('.','')
                                                      .replace('.','')
                                                      .replace('.','')
                                                      .replace('.','')
                                                      .replace('.','')
                                                      .replace(',','.')));
            
                                                      var monto_prod_descuento = parseFloat(objeto[i].desc_prod) * subtotal / 100;
            
                                                      if (subtotal > monto_prod_descuento) {
                                                      subtotal = subtotal - monto_prod_descuento;
                                                      }else{
                                                      subtotal = monto_prod_descuento - subtotal;
                                                      }
        
                                                      if (this.ELEMENT_DATA.length <=45) {
                    
                                                             this.ELEMENT_DATA.push({
                                                              cod_producto:response[1][k].cod_producto,
                                                              des_producto:response[1][k].des_producto,
                                                              cod_lista_precio:response[2][0].cod_lista_precio_producto,
                                                              tasa_lista_precio:response[2][0].tasa_precio,
                                                              cant_producto:objeto[i].cantidad_pedido.toString(),
                                                              costo_producto:response[1][k].precio_producto_base_bs,
                                                              tipo_producto:response[1][k].tipo_producto,
                                                              desc_prod:parseFloat(objeto[i].desc_prod).toFixed(3).replace(/\D/g, "")
                                                              .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                               desc_prod_monto:monto_prod_descuento.toFixed(3).replace(/\D/g, "")
                                                               .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                               .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                              subtotal_producto: subtotal.toFixed(3).replace(/\D/g, "")
                                                                          .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                                                              estatus_fiscal:response[1][k].estatus_fiscal,
                                                              tamano_producto:''
                                                            });
                                            
                                                            this.subtotal_pedido.nativeElement.value = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace(',','.'))) +  (subtotal);
                                                            this.subtotal_pedido.nativeElement.value = parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(3).replace(/\D/g, "")
                                                            .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                           
                                                            let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace(',','.')));
                                           
                                                            let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace(',','.'))/100);
                                           
                                                            this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(3).replace(/\D/g, "")
                                                            .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
                                           
                                                            resul_final = montoTotal - porcentaje;
                                           
                                                      }else{
                                                        this.snackBar.open('Solo puede agregar un maximo de  45 items', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                          verticalPosition: this.verticalPosition,});
                                                        break;
                                                      }
            
                                                      }else{
                                                        this.snackBar.open('Hay productos que no estan asiganados a este cliente, consulte su lista de precios actual', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                                          verticalPosition: this.verticalPosition});
                                                          break;
                                                      }
                            }
                          }
                         }
                       }else{
                        this.snackBar.open('Necesita tener bultos completos en su pedido, '+prod_sb+' /  Bultos: '+resul_decimal+'', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                      
                      console.log(this.ELEMENT_DATA);
                      }else{
                        this.snackBar.open('Tiene productos en 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                      }else{
                      this.snackBar.open('Para poder continuar el campo descuento debe estar en 0,00 %', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                      }
                      if (resul_final !== undefined && resul_final !== null) {
                        this.total_pedido.nativeElement.value = resul_final.toFixed(3).replace(/\D/g, "")
                                                                  .replace(/([0-9])([0-9]{3})$/, '$1,$2')
                                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                      }
                      
                     
                  this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                },
                  error =>{
                    console.log(<any>error.error.message);
                    
                    this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                  }
                );
              },
              error =>{}
            );
          
       }else{
        this.snackBar.open('El archivo se encuentra vacio', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
       }
    } catch (error) {
      
      console.log(error);
    }
  }
}
