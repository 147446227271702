<div class="container" style="border-radius: 1rem;">
    <div class="row" style="border-radius: 1rem;">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 1rem;">
            <div class="modal-content" style="height: 100%; width: 100%; border-radius: 1rem;">
                <div class="modal-header" style="background-color: steelblue; color: #fff; border-radius: 1rem 1rem 0 0;">
                    <h3 class="modal-title" class=" text-center"><strong>Fecha de la consulta</strong> </h3>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div [formGroup]="forma" id="sub_content_2" *ngIf="parametro === '9'" class=" mx-1">
                                    <!-- <mat-form-field class="materialinput py-0 px-1 ">
                                        <mat-label style="color: rgb(0, 49, 155); font-size: 12px;">Clientes</mat-label>
                                        <mat-select id="operacion" #operacion style="font-size: 12px; color: rgb(0, 49, 155);" formControlName="cod_cliente" autocomplete="off">
                                            <mat-option *ngFor="let item of data.clientes" [value]="item.cod_cliente">{{item.cod_cliente}}-{{item.des_cliente}}</mat-option>
                                        </mat-select>
                                    </mat-form-field> -->

                                    <mat-form-field class="materialinput py-1 px-1">
                                        <mat-label style="color: #000; font-size: 11px;">Clientes</mat-label>
                                        <input class="" style="font-size: 12px; color: #000;" type="text" matInput placeholder="" formControlName="cod_cliente" [matAutocomplete]="autoGroup" #producto_pedido>
                                        <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>search</mat-icon>
                                            </span>
                                        <mat-autocomplete  #autoGroup="matAutocomplete" class="px-1">
                                            <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                                <mat-option   style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                                    <b>{{i}}</b>
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div [formGroup]="forma" id="sub_content_2" class=" mx-1">
                                    <mat-form-field class="materialinput py-0 px-1">
                                        <mat-label style="color: rgb(0, 49, 155); font-size: 12px;">Seleccione un rango de fecha</mat-label>
                                        <mat-date-range-input [formGroup]="forma" [rangePicker]="picker">
                                            <input style="color: rgb(0, 49, 155);" matStartDate formControlName="fecha_inicio" placeholder="Fecha inicio" autocomplete="off">
                                            <input style="color: rgb(0, 49, 155);" matEndDate formControlName="fecha_fin" placeholder="Fecha fin" autocomplete="off">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                                    </mat-form-field>
                                </div>
                                <div [formGroup]="forma" id="sub_content_2" *ngIf="parametro === '15'" class=" mx-1">
                                    <mat-form-field class="materialinput py-0 px-1">
                                        <mat-label style="color: #000; font-size: 11px;">Deposito</mat-label>
                                        <input class="" style="font-size: 12px; color: #000;" type="text" matInput placeholder="" formControlName="deposito" [matAutocomplete]="autoGroup">
                                        <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>search</mat-icon>
                                            </span>
                                        <mat-autocomplete  #autoGroup="matAutocomplete" class="px-1">
                                            <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                                <mat-option   style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                                    <b>{{i}}</b>
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    
                                    <mat-form-field class="materialinput py-0 px-1">
                                        <mat-label style="color: #000; font-size: 11px;">Productos</mat-label>
                                        <input class="" style="font-size: 12px; color: #000;" type="text" matInput placeholder="" formControlName="productos" [matAutocomplete]="autoGroup2">
                                        <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>search</mat-icon>
                                            </span>
                                        <mat-autocomplete  #autoGroup2="matAutocomplete" class="px-1">
                                            <mat-optgroup *ngFor="let group of stateGroupOptions2 | async" [label]="group.letter">
                                                <mat-option   style="font-size: 14px; color: #000;" *ngFor="let e of group.code" [value]="e">
                                                    <b>{{e}}</b>
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <div class="row p-3">
                                        <div class="materialinput py-0 px-3 d-flex w-100 flex-column col-4">    
                                            <mat-checkbox class='' formControlName="cargo" >Cargo</mat-checkbox>
                                            <mat-checkbox class='' formControlName="descargo" >Descago</mat-checkbox>
                                            <mat-checkbox class='' formControlName="traslado" >Traslado</mat-checkbox>
                                        </div>
                                        <div class="materialinput py-0 d-flex w-100 flex-column col-4">
                                            <mat-checkbox class='' formControlName="produccion" >Producción</mat-checkbox>
                                            <mat-checkbox class='' formControlName="embalaje" >Embalaje</mat-checkbox>
                                            <mat-checkbox class='' formControlName="consignacion" >Consignación</mat-checkbox>
                                            <mat-checkbox class='' formControlName="todos" (change)="checked($event)">Todos</mat-checkbox>
                                        </div>
                                        <div class="materialinput py-0 px-3 d-flex w-100 flex-column col-4">
                                            <mat-checkbox class='' formControlName="devolucion" >Devolución</mat-checkbox>
                                            <mat-checkbox class='' formControlName="facturacion" >Facturación</mat-checkbox>
                                            <mat-checkbox class='' formControlName="anulacion" >Anulación</mat-checkbox>
                                        </div>

                                    </div>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="form-group">
                        <button mat-button class=" btn-danger rounded-pill" (click)="cerrarmodal()" style="border: outset 3px rgb(245, 94, 94); margin-right: 5px;">Cancelar</button>
                        <button [disabled]="forma.invalid" mat-button (click)="modalrespuesta()" class=" mr-2 btn-primary" style="border: outset 3px rgb(99, 99, 236);"> Enviar</button>
                        <button mat-button (click)="Hoy()" class=" btn-success " style="border: outset 3px rgb(66, 211, 66);"> Hoy</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>