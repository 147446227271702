import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormCuestionarioComprasComponent } from 'src/app/componentes/shared/modal/form-cuestionario-compras/form-cuestionario-compras.component';
import {MatPaginator} from '@angular/material/paginator';
import { element, error } from 'protractor';

@Component({
  selector: 'app-form-crear-cotizacion',
  templateUrl: './form-crear-cotizacion.component.html',
  styleUrls: ['./form-crear-cotizacion.component.css']
})
export class FormCrearCotizacionComponent implements OnInit {

  dialogSpin:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`

  dataSource=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCrearCotizacionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.mostrarTabla()
  }

  mostrarTabla(){

    try {

      this.servicio.listaProdCotizarhttp().subscribe(result => {

  
        console.log(result)
  
        if(result){
          result.forEach(element => {
  
            let obj = {
                      cod_orden_req:element.cod_orden_req,
                      cod_producto:element.cod_producto,
                      des_producto:element.des_producto,
                      cant_producto_soli:element.cant_producto_soli,
                      cant_aprobada:element.cant_producto_ap,
                      tipo_unidad_medida:element.tipo_unidad_medida,
                      prioridad:element.prioridad,
                      check_estatus: false
                        }
            this.ELEMENT_DATA.push(obj)
          });
        }

  
        this.displayedColumns = [
          'cod_orden_req',
          'cod_producto',
          'des_producto',
          'prioridad',
          'cant_producto_soli',
          'cant_aprobada',
          'tipo_unidad_medida',
          'tomar_data_ingresada', 
        ];
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      
  
      })
      
    } catch (error) {

      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
      this.displayedColumns = [
        'cod_orden_req',
        'cod_producto',
        'des_producto',
        'prioridad',
        'cant_producto_soli',
        'cant_aprobada',
        'tipo_unidad_medida',
        'tomar_data_ingresada', 
      ];
      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    
      
    }

  }

  tomar_data(booleano:any, elemento:any, tabla:any){

    console.log(elemento);

    if(booleano){ 

      this.ELEMENT_DATA.forEach(element=> {

        if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req ){

          element.check_estatus = true

        }

      })

      this.ELEMENT_DATA2.push(elemento)


    }else{


      this.ELEMENT_DATA.forEach(element=> {

        if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req ){
              
          element.check_estatus = false


        }

      })
      this.ELEMENT_DATA2 =   this.ELEMENT_DATA2.filter(element => element.cod_producto != elemento.cod_producto )

      console.log('aqui estoy')
    }

    this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);

  }

  enviarCrearCoti(){

    let obj = {
      productos:this.ELEMENT_DATA2,
      usuario: {
                  cod_usuario: localStorage.getItem('cod_usuario'),
                  des_usuario: localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido')
                }
      }

      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(
        result => {
        if (result != 'cerrar') {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          this.servicio.generarCotizacionOrdenhttp(obj).subscribe(result2 => {

            console.log(result2);
            this.dialogSpin.close();
            this.dialogRefp.close();
      
          },
          error =>{
            this.dialogSpin.close();
            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
      
          }
          )
        }
      },
        error => {
          this.snackBar.open(JSON.stringify(error), 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );



    

  }


  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
