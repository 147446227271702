<!-- <div class="container-fluid  mat-elevation-z8" style="border: 1px solid #cccccc; height: 100%; background-color: rgb(255, 255, 255); border-radius: 2% 2% 2% 2%;">
    <form [formGroup]="forma" class="">
        <div class="row" style="border-radius: 10px 10px 0px 0px; border-bottom: 1px solid #cccccccc; background-color: rgb(70, 128, 216); color: #fff; height: 20%; padding-top: 5%;">
            <div class="col-md-12">
                <h2 mat-dialog-title style="border-bottom: 1px solid #fff;">Confirmar y evaluar la solicitud</h2>
            </div>
        </div>
        <div class="row" style="height: 20%; margin: 5px 2.5px 5px 2.5px; padding: 15px 5px 15px 5px; border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
            <div class="col-md-4 col-sm-4 col-xs-4">
                <div class="form-group">
                    <mat-form-field class="materialinput">
                        <mat-label style="color: #000">Solución</mat-label>
                        <mat-select class="" style="font-size: 12px; color: black; border: none;" #atencion_soporte_v name="estatus" placeholder="" formControlName="solucion_soporte" autocomplete="off">
                            <mat-option *ngFor="let item of solucion_soporte" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-4">
                <div class="form-group">
                    <mat-form-field class="materialinput">
                        <mat-label style="color: #000">Atencion</mat-label>
                        <mat-select class="" style="font-size: 12px; color: black; border: none;" #atencion_soporte_v name="estatus" placeholder="" formControlName="atencion_soporte" autocomplete="off">
                            <mat-option *ngFor="let item of atencion_soporte" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-4">
                <div class="form-group">
                    <mat-form-field class="materialinput">
                        <mat-label style="color: #000">Tiempo</mat-label>
                        <mat-select class="" style="font-size: 12px; color: black; border: none;" #tiempo_soporte_v name="estatus" placeholder="" formControlName="tiempo_soporte" autocomplete="off">
                            <mat-option *ngFor="let item of tiempo_soporte" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
        </div>
        <div class="row" style="height: 20%;margin: 5px 2.5px 5px 2.5px; padding: 15px 5px 15px 5px; border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
            <div class="col-md-12">
                <div class="form-group">
                    <mat-form-field class="materialinput">
                        <mat-label style="color: #000">Descripción</mat-label>
                        <textarea style="font-size: 12px; color: black;" maxlength="150" matInput placeholder="" autocomplete="off" formControlName="descripcion_soporte"> </textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row" style="height: 20%; "></div>
        <div class="row" style="height: 20%; ">
            <div class="modal-footer">
                <div class="form-group">
                    <button mat-button class=" btn-danger" style="margin-right: 5px;" (click)="cerrarmodal()">Cancelar</button>
                    <button [disabled]="forma.invalid" (click)="modalrespuesta()" mat-button class=" btn-primary">Enviar</button>
                </div>
            </div>

        </div>
    </form>
</div> -->



<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); ">
                    <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-center"><strong>Confirmar y evaluar la solicitud</strong> </h3>

                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row px-2">
                                <div class="col-md-12 col-sm-12">
                                    <div class="row my-1" style=" border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Solución</mat-label>
                                                    <mat-select class="" style="font-size: 12px; color: black; border: none;" #atencion_soporte_v name="estatus" placeholder="" formControlName="solucion_soporte" autocomplete="off">
                                                        <mat-option *ngFor="let item of solucion_soporte" [value]="item">{{item}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Atencion</mat-label>
                                                    <mat-select class="" style="font-size: 12px; color: black; border: none;" #atencion_soporte_v name="estatus" placeholder="" formControlName="atencion_soporte" autocomplete="off">
                                                        <mat-option *ngFor="let item of atencion_soporte" [value]="item">{{item}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Tiempo</mat-label>
                                                    <mat-select class="" style="font-size: 12px; color: black; border: none;" #tiempo_soporte_v name="estatus" placeholder="" formControlName="tiempo_soporte" autocomplete="off">
                                                        <mat-option *ngFor="let item of tiempo_soporte" [value]="item">{{item}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row my-1" style=" border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Descripción</mat-label>
                                                    <textarea style="font-size: 12px; color: black;" maxlength="150" matInput placeholder="" autocomplete="off" formControlName="descripcion_soporte"> </textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" style="margin-right: 5px;" (click)="cerrarmodal()">Cancelar</button>
                            <button [disabled]="forma.invalid" (click)="modalrespuesta()" mat-button class=" btn-primary">Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>