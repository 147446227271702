import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { FormMovimientosProdComponent } from '../form-movimientos-prod/form-movimientos-prod.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import { ConnectionServices } from '../../../../componentes/shared/servicios/app.connection.services';
import { FormTraladoInvComponent } from '../form-tralado-inv/form-tralado-inv.component';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { environment } from 'src/environments/environment';
import { ExcelService } from '../../servicios/excel.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

@Component({
  selector: 'app-form-depositos',
  templateUrl: './form-depositos.component.html',
  styleUrls: ['./form-depositos.component.css']
})
export class FormDepositosComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_dep',{static:true}) cod_dep:ElementRef;
  @ViewChild('des_dep',{static:true}) des_dep:ElementRef;
  @ViewChild('descrip_dep',{static:true}) descrip_dep:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  title = 'InternetTest';
  status = 'ONLINE';
  isConnected = true;

value1 = '';
nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
dialogSpin:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  rx:any;
  constructor(
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private servicio:nkServices,
    private formb:FormBuilder, 
    private fb:FormBuilder,
    private dialogRefp: MatDialogRef<FormDepositosComponent>,
    private conexion: ConnectionServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private excel:ExcelService,
    private notifi:ToastNotificationService,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
      this.forma = fb.group({
        'unidad': '',
        'menu_padre': '',
        'submenu':'',
        'submenu1':'',
        'permisos_menu_usuario':''
      });
    }
  ngOnInit(): void {
   
    this.cod_dep.nativeElement.value = this.data.objeto.cod_deposito;
    this.descrip_dep.nativeElement.value = this.data.objeto.descrip_deposito.toUpperCase();
    this.des_dep.nativeElement.value = this.data.objeto.des_deposito.toUpperCase();
    this.buscarProductoDeposito(null);

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  async  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = await this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

  async buscarProductoDeposito(xvalor){
    try {
      await this.util.abrirSpinner();
        let msg = xvalor;
        var valor = {
                    cod_deposito:this.data.objeto.cod_deposito,
                    tipo_deposito:this.data.objeto.tipo_deposito,
                    cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
            }
       const response = await this.servicio.buscarProductoDepositohttp(valor).toPromise();
               this.ELEMENT_DATA = [];
                   for (const i in response) {
                    if (response[i].cod_img === '' || response[i].cod_img === null) {
                      response[i].cod_img = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                    }else{
                      response[i].cod_img = environment.path_storage+response[i].cod_img
                    }
                     this.ELEMENT_DATA.push({
                      vacio:'',
                      cod_deposito:response[i].cod_deposito,
                      des_desposito: response[i].des_desposito,
                      cod_producto:response[i].cod_producto,
                      tipo_producto:response[i].tipo_producto,
                       des_producto:response[i].des_producto,
                       cant_producto:response[i].cant_producto,
                       um_producto:response[i].um_producto,
                       unidad_manejo:response[i].unidad_manejo,
                       cod_img:response[i].cod_img
                     });
                   }
                   if (this.data.objeto.tipo_manejo.toUpperCase() === await this.atrb.UM && this.data.objeto.tipo_deposito.toUpperCase() === await this.atrb.PT ) {
                    this.displayedColumns = await this.atrb.FORM_DEP_UM_PT;
                    for (const i in this.ELEMENT_DATA) {
                      if (this.ELEMENT_DATA[i].unidad_manejo !== '' || this.ELEMENT_DATA[i].unidad_manejo !== null) {
                          if (this.ELEMENT_DATA[i].cant_producto !== null && this.ELEMENT_DATA[i].unidad_manejo !== null) {
                            var resultado = parseInt(this.ELEMENT_DATA[i].cant_producto.replace('.', '')
                            .replace('.', '')
                            .replace('.', '')
                            .replace('.', '')
                            .replace(',', '.')) / parseInt(this.ELEMENT_DATA[i].unidad_manejo.replace('.', '')
                            .replace('.', '')
                            .replace('.', '')
                            .replace('.', '')
                            .replace(',', '.'));
                            this.ELEMENT_DATA[i].um_producto = resultado;
                          }else{
                            this.ELEMENT_DATA[i].um_producto = 'S/N';
                          }
                        
                        }else{
                          this.ELEMENT_DATA[i].um_producto = 'S/N';
                        }
                     }
                   }
                   if (this.data.objeto.tipo_manejo.toUpperCase() === await this.atrb.UM && this.data.objeto.tipo_deposito.toUpperCase() !== await this.atrb.PT ) {
                    this.displayedColumns = await this.atrb.FORM_DEP_UM_D_PT;
                    for (const i in this.ELEMENT_DATA) {
                      if (this.ELEMENT_DATA[i].unidad_manejo !== '' || this.ELEMENT_DATA[i].unidad_manejo !== null) {
                          if (this.ELEMENT_DATA[i].cant_producto !== null && this.ELEMENT_DATA[i].unidad_manejo !== null) {
                            var resultado = parseInt(this.ELEMENT_DATA[i].cant_producto.replace('.', '')
                            .replace('.', '')
                            .replace('.', '')
                            .replace('.', '')
                            .replace(',', '.')) / parseInt(this.ELEMENT_DATA[i].unidad_manejo.replace('.', '')
                            .replace('.', '')
                            .replace('.', '')
                            .replace('.', '')
                            .replace(',', '.'));
                            this.ELEMENT_DATA[i].um_producto = resultado;
                          }else{
                            this.ELEMENT_DATA[i].um_producto = 'S/N';
                          }
                        
                        }else{
                          this.ELEMENT_DATA[i].um_producto = 'S/N';
                        }
                     }
                   }
                   if (this.data.objeto.tipo_manejo.toUpperCase() !== await this.atrb.UM && this.data.objeto.tipo_deposito.toUpperCase() === await this.atrb.PT) {
                    this.displayedColumns = await this.atrb.FORM_DEP_D_UM_PT;
                    for (const i in this.ELEMENT_DATA) {
                          this.ELEMENT_DATA[i].um_producto = 'S/N';
                     }
                   }
                   if (this.data.objeto.tipo_manejo.toUpperCase() !== await this.atrb.UM && this.data.objeto.tipo_deposito.toUpperCase() !== await this.atrb.PT) {
                    this.displayedColumns = await this.atrb.FORM_DEP_D_UM_D_PT;
                    for (const i in this.ELEMENT_DATA) {
                          this.ELEMENT_DATA[i].um_producto = 'S/N';
                     }
                   }
                   this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
                   this.dataSource1.paginator = this.paginator;
         if(msg === await this.atrb.ACCION_ACTUALIZAR){
          await this.util.cerrarSpinner();
             await this.util.notificacionErrorApp(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
         }else{
          await this.util.cerrarSpinner();
         }
    } catch (error) {
      await this.util.cerrarSpinner();
      if (this.data.objeto.tipo_manejo.toUpperCase() === await this.atrb.UM && this.data.objeto.tipo_deposito.toUpperCase() === await this.atrb.PT ) {
        this.displayedColumns = await this.atrb.FORM_DEP_UM_PT;
       }
       if (this.data.objeto.tipo_manejo.toUpperCase() === await this.atrb.UM && this.data.objeto.tipo_deposito.toUpperCase() !== await this.atrb.PT ) {
        this.displayedColumns = await this.atrb.FORM_DEP_UM_D_PT;
       }
       if (this.data.objeto.tipo_manejo.toUpperCase() !== await this.atrb.UM && this.data.objeto.tipo_deposito.toUpperCase() === await this.atrb.PT) {
        this.displayedColumns = await this.atrb.FORM_DEP_D_UM_PT;
       }
       if (this.data.objeto.tipo_manejo.toUpperCase() !== await this.atrb.UM && this.data.objeto.tipo_deposito.toUpperCase() !== await this.atrb.PT) {
        this.displayedColumns = await this.atrb.FORM_DEP_D_UM_D_PT;
       }
      await this.util.notificacionErrorApp(error.error.message);
    }
  }
  //////////////////////////////////////////////////////////////////////

 async filter_date_print(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      console.log(this.ELEMENT_DATA);
            if (this.ELEMENT_DATA.length <= 0) {
              await this.util.cerrarSpinner();
              await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_registos);
              return;
            }
          await this.util.cerrarSpinner();
        this.accion = '';
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: {id:'',
                accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if (result != 'cerrar') {
            await this.excel.descarga_xlsx_inventario_producto(this.ELEMENT_DATA, this.forma.value);
            await this.util.cerrarSpinner();
            await this.notifi.success(await this.atrb.MENSAJES_FIJOS.reporte_excel);
          }else{
            await this.util.cerrarSpinner();
          }
        });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

 async cerrarmodal(){
  try {
    
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result != 'cerrar') {
        await this.util.cerrarSpinner();
        this.dialogRefp.close('cerrar');
      }else{
        await this.util.cerrarSpinner();
      }
    });
  } catch (error) {
    console.log(error);
  }
  }

 async ajustes_almacen(obj:any,termino:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
      var dialogRef:any;
      dialogRef = this.dialog.open(FormTraladoInvComponent, {
            width: '100vw',
        maxWidth: '100vw',
        height:'auto',
            data: {
                    tipo_operacion:termino.toUpperCase(),
                    cod_producto:obj.cod_producto,
                    des_producto:obj.des_producto,
                    cant_producto:obj.cant_producto,
                    objeto:obj,
                    cod_orden_producto:obj.cod_orden_producto,
                    cod_lote:obj.cod_lote,
                    accion:await this.atrb.INV
                  },
            disableClose: true,
            panelClass: 'pedidos_modal_rev',
            backdropClass: "bdrop"
          });
      
            dialogRef.afterClosed().subscribe(async result => {
              await this.util.abrirSpinner();
              if(result !== 'cerrar'){
                    result.tipo_operacion = termino.toUpperCase();
                    result.cod_deposito = obj.cod_deposito;
                    result.des_deposito = obj.des_deposito;
                    result.tipo_producto = obj.tipo_producto;
                this.ajustar_almacen_productos(result);
              }else{
                await this.util.cerrarSpinner();
              }
        });
    } catch (error) {
      console.log(error);
    }
  }

 async ajustar_almacen_productos(xvalor:any){
    try {
            if (xvalor.formdata.get('fac_img') !== await this.atrb.UNDEFINED) {
                const img = await this.servicio.gestionarImgFachttp(xvalor.formdata).toPromise();
                    xvalor.cod_img = img.file.path;
                    xvalor.des_img = img.file.filename;
            }else{
                    xvalor.cod_img = false;
                    xvalor.des_img = false;
            }
          const response = await  this.servicio.ajustar_almacen_productoshttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
          await this.buscarProductoDeposito(null);
          await this.util.notificacionErrorApp(response.message);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.buscarProductoDeposito(null);
      await this.util.notificacionErrorApp(error.error.message);
    }
  }

 async movimientos_prod(obj:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
      var dialogRef:any;
      this.accion = 'INV';
        dialogRef = this.dialog.open(FormMovimientosProdComponent, {
          width: '100vw',
        maxWidth: '100vw',
        height:'auto',
          data: {cod_producto:obj.cod_producto,
                des_producto:obj.des_producto,
                objeto: obj,
                cant_producto:obj.cant_producto,
                  accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal3',
          backdropClass: "bdrop"
        });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.buscarProductoDeposito(null);
      await this.util.notificacionErrorApp(error.error.message);
    }
  }
}
