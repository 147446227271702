import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import * as moment from 'moment';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../servicios/formato_datapick';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormDireccionesComponent } from '../form-direcciones/form-direcciones.component';


@Component({
  selector: 'app-form-fechas-empleado',
  templateUrl: './form-fechas-empleado.component.html',
  styleUrls: ['./form-fechas-empleado.component.css']
})
export class FormFechasEmpleadoComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_dep',{static:true}) cod_dep:ElementRef;
  @ViewChild('des_prod',{static:true}) des_prod:ElementRef;
  @ViewChild('descrip_dep',{static:true}) descrip_dep:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;

accion:any;
dialogSpin:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

datos: {} = {
  fecha_inicio: '',
  fecha_fin: '',
}
btnDesabilitado: boolean = true;
constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
  public dialogRefp: MatDialogRef<FormFechasEmpleadoComponent>,
  ///en el injectable llega la informacion del metodo padre en data
  @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data, 'primero');

    this.forma = fb.group({
      'cod_usuario':['',[Validators.required]],
      'des_usuario':'',
      'cod_producto':'',
      'fecha_inicio':['',[Validators.required]],
      'fecha_fin':['',[Validators.required]],
      'accion':data.accion

    });
  }

    ngOnInit(): void {

    }

    modalrespuesta(){
      var accion2 = 'enviarcliente';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'100%',
        data: {id:this.data.id_cliente,
              accion:accion2},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
            
            this.forma.value.cod_usuario = this.forma.value.cod_usuario;
            this.forma.value.fecha_inicio =  moment(this.forma.value.fecha_inicio).format('DD/MM/YYYY');
            this.forma.value.fecha_fin =  moment(this.forma.value.fecha_fin).format('DD/MM/YYYY');

            console.log(this.forma.value,'this.forma.value');
            this.dialogRefp.close(this.forma.value);

          }
        });
      }
  


    cerrarmodal(): void {
      this.accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar') {
          this.cerrar();
        }

      });

    }

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
