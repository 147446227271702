
export class FileItem {

    public archivo: File;
    public nombreArchivo: string;
    public url: string;
    public estaSubiendo: boolean;
    public progreso: number;
    public tipo:string;
    public nivel: number;
    public enlace: string;
    public texto: string;
    public color: string;
    public colorf: string;
    public colorfondo: string;
    public textob: string;

    constructor( archivo: File ) {

        this.archivo = archivo;
        this.nombreArchivo = archivo.name;

        this.estaSubiendo = false;
        this.progreso = 0;

    }

}
