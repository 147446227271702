import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {Observable} from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { FormNotaCreditoComponent } from 'src/app/componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormNotaCreditoAdminComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import { FormReportesComponent } from 'src/app/componentes/shared/modal/form-reportes/form-reportes.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormReportesVentasComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas/form-reportes-ventas.component';
import { FormNotaCreditoAdminSingleComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin-single/form-nota-credito-admin-single.component';
import { FormNotaCreditoAdminBaseComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin-base/form-nota-credito-admin-base.component';
import * as moment from 'moment';
import { ConnectionServices } from '../../../../componentes/shared/servicios/app.connection.services';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { FormReportesVentasNotasCreditoComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas-notas-credito/form-reportes-ventas-notas-credito.component';
import axios from 'axios';
import { FormDailyVistaPreviaComponent } from 'src/app/componentes/shared/modal/form-daily-vista-previa/form-daily-vista-previa.component';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { FormMenuRolComponent } from 'src/app/componentes/shared/modal/form-menu-rol/form-menu-rol.component';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';
import { FormDefinicionesAccesosComponent } from 'src/app/componentes/shared/modal/form-definiciones-accesos/form-definiciones-accesos.component';
import { environment } from 'src/environments/environment';
interface interfacefactura{
  lote_producto: string;
  cod_producto:string;
  des_producto:string;
  cant_producto:string;
  costo_producto:string;
  subtotal_producto:string;
}
interface interfacefactura2{
  des_concepto: string;
  subtotal_concepto:string;
}
interface interdatosfacefactura{
cod_factura:string;
des_cliente: string;
cod_cliente:string;
des_vendedor:string;
fecha_inicio:string;
fecha_v_factura:string;
cod_pedido:string;
telefono1_cliente:string;
telefono2_cliente:string;
}
type TableRow = [string, string, string, string, string, string];
type TableRow2 = [string, string];

@Component({
  selector: 'app-rol-usuarios',
  templateUrl: './rol-usuarios.component.html',
  styleUrls: ['./rol-usuarios.component.css']
})
export class RolUsuariosComponent implements OnInit {
  auth_menu:any = [];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  fecha1:any;
  fecha2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  roles:any;
  dialogSpin:any;
  cod_nota_credito:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();
  constructor(
              private servicio:nkServices,
              private dialog: MatDialog, 
              private snackBar: MatSnackBar,
              private util:AppUtilService,
              private atrb:AtributosService,
              private hashkey:HashKeyServices,
              private notifi:ToastNotificationService,
              ){}
  ngOnInit(){
    this.listarRolMenu(null);
    this.listardatos(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }
  /////////////////////////////////////////////////////////////////////
async listarRolMenu(msj:any){
    try {
          await this.util.abrirSpinner();
          await this.servicio.consultar_sesionhttp(null).toPromise();
        let msg = msj;
      const response = await  this.servicio.listarRolMenuhttp().toPromise();
      console.log(response);
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                      cod_dpto:response[i].cod_dpto,
                      des_dpto:response[i].des_dpto,
                      cod_gerencia: response[i].cod_gerencia,
                      des_gerencia: response[i].des_gerencia,
                      cod_rol:response[i].cod_rol,
                      des_rol:response[i].des_rol,
                      comentarios:response[i].comentarios,
                      estatus:response[i].estatus,
                      estructura_rol:response[i].estructura_rol,
                      fecha_inicio:response[i].fecha_inicio,
                      hora_inicio: response[i].hora_inicio
                    });
                  }
                  this.displayedColumns = [
                                        'cod_rol',
                                        'des_gerencia',
                                        'des_dpto',
                                        'des_rol',
                                        'estatus',
                                        'comentarios',
                                        'procesos',
                                      ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            if(msg == await this.atrb.ACCION_ACTUALIZAR){
              await this.util.cerrarSpinner();
              await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
          }else{
            await this.util.cerrarSpinner();
          }
    } catch (error) {
                this.displayedColumns = [
                                        'cod_rol',
                                        'des_gerencia',
                                        'des_dpto',
                                        'des_rol',
                                        'estatus',
                                        'comentarios',
                                        'procesos',
                ];
          this.dataSource = new MatTableDataSource<any>();
          this.dataSource.paginator = this.paginator;
          await this.util.cerrarSpinner();
          await this.notifi.customToastNotification(error.error.message);
    }
  }
async  listardatos(msj:any){
    try {
      let msg = msj;
      await this.servicio.consultar_sesionhttp(null).toPromise();
      var xobjeto = {
        cod_rol:this.atrb.ROL_USUARIOS.cod_rol,
        cod_empresa:this.atrb.COD_EMPRESA.cod_empresa,
        cod_app: await this.hashkey.encrypt(environment.path_token),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarUsuarios),
      }
     const response =  await  this.servicio.ApiResthttp(xobjeto).toPromise();
            this.xobj3=[];
            this.xobj4=[];
            this.xobj5=[];
            for (const i in response[1]) {
              this.xobj3.push(response[1][i]);
            }
            for (const i in response[2]) {
              this.xobj4.push(response[2][i]);
            }
            for (const i in response[3]) {
              this.xobj5.push(response[3][i]);
            }
    } catch (error) {
      console.log(error);
      this.xobj3=[];
            this.xobj4=[];
            this.xobj5=[];
            for (const i in error.error[0].resul2) {
              this.xobj3.push(error.error[0].resul2[i]);
            }
            for (const i in error.error[0].resul3) {
              this.xobj4.push(error.error[0].resul3[i]);
            }
            for (const i in error.error[0].resul4) {
              this.xobj5.push(error.error[0].resul4[i]);
            }
    }
    
  }
async update_insert_data(xobjeto:any,text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
                          var dialogRef:any;
                          this.accion = text;
                          if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                            if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def = {
                                val_def:await this.atrb.ACCESO_VALDEF.gestionar_roles,
                                tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                              }
                              await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                            }
                            await this.util.cerrarSpinner();
                            dialogRef = this.dialog.open(FormMenuRolComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                      data:xobjeto,
                                      operacion: await this.atrb.ACCION_ACTUALIZAR,
                                      accion:this.accion
                                    },
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }else{
                            if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def = {
                                val_def:await this.atrb.ACCESO_VALDEF.gestionar_roles,
                                tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                              }
                              await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                            }
                            await this.util.cerrarSpinner();
                            dialogRef = this.dialog.open(FormMenuRolComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                      data:xobjeto,
                                      operacion: await this.atrb.ACCION_CREAR,
                                      accion:this.accion
                                    },
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }
                            dialogRef.afterClosed().subscribe(async result => {
                              await this.util.abrirSpinner();
                                if(result !== 'cerrar'){
                                  await this.util.cerrarSpinner();
                                    this.gestionDefinicionesSistema(result);
                                }else{
                                  await this.util.cerrarSpinner();
                                }
                            });
    } catch (error) {
      await this.util.cerrarSpinner();
          console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async  gestionDefinicionesSistema(xvalor:any){
    try {
      await this.util.abrirSpinner();
     const response = await this.servicio.gestionRoleshttp(xvalor).toPromise();
     await this.util.cerrarSpinner();
       this.listarRolMenu(null);
       await this.notifi.success(response.message);
    } catch (error) {
      await this.util.cerrarSpinner();
          console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
   }
async permisosUsuarios(obj:any, text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
        let val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.gestionar_roles,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
          cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
        }
        await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
      }
      await this.util.cerrarSpinner();
            this.accion = text;
            var dialogRef = this.dialog.open(FormPermisosComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'auto',
              data: {id:obj.id,
                    cod_usurio_rol:'', 
                    cod_usuario: obj.cod_rol,
                    cod_unidad: obj.cod_gerencia,
                    des_unidad: obj.des_gerencia, 
                    nombre:obj.des_gerencia,
                    apellido:obj.des_gerencia,
                    rol_usuario:obj.cod_rol,
                    roles:this.xobj3,
                    menu:this.xobj5,
                    unidad:this.xobj4,
                    accion:this.accion},
                    disableClose: true,
                    panelClass: 'pedidos_modal3',
                    backdropClass: "bdrop" 
            });
            dialogRef.afterClosed().subscribe(async result => {
              if(result != 'cerrar'){
              }else{
                await this.util.cerrarSpinner();
              }
            });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async definicionesAcciones(xobjeto:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
        let val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.gestionar_roles,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.let_join,
          cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
        }
        await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
      }
      await this.util.cerrarSpinner();
      xobjeto.nombre = xobjeto.cod_rol;
      xobjeto.cod_usuario = xobjeto.cod_rol;
            var dialogRef = this.dialog.open(FormDefinicionesAccesosComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'auto',
              data: {
                    data:xobjeto,
                    accion:true
                  },
                    disableClose: true,
                    panelClass: 'pedidos_modal3',
                    backdropClass: "bdrop" 
            });
            dialogRef.afterClosed().subscribe(async result => {
              await this.util.abrirSpinner();
              if(result != 'cerrar'){
                await this.util.cerrarSpinner();
              }else{
                await this.util.cerrarSpinner();
              }
            });
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
        await this.notifi.customToastNotification(error.error.message);
    }
  }
async gestionarusuriorol(xvalor:any){
    try {
        const response = await this.servicio.gestionUsuarioRolhttp(xvalor).toPromise();
        await this.util.cerrarSpinner();
        await this.notifi.success(response.message);
       await this.listardatos(null);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.listardatos(null);
      await this.notifi.customToastNotification(error.error.message);
    }
   }
//////////////////////FUNCION DE ACCION/////////////////////////
 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
