import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormCrearOcProductosxproveedorComponent } from '../form-crear-oc-productosxproveedor/form-crear-oc-productosxproveedor.component';
import {MatPaginator} from '@angular/material/paginator';
import { element, error } from 'protractor';
import { ToastNotificationService } from '../../servicios/toast-notification.service';


@Component({
  selector: 'app-form-crear-orden-compra',
  templateUrl: './form-crear-orden-compra.component.html',
  styleUrls: ['./form-crear-orden-compra.component.css']
})
export class FormCrearOrdenCompraComponent implements OnInit {

  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  productos:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`

  dataSource=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  constructor(public dialog: MatDialog,private toast:ToastNotificationService ,private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCrearOrdenCompraComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.mostrarTabla()
  }

  mostrarTabla(){

    try {

      this.servicio.listarPorductosXProveedorhttp().subscribe(
        response =>{
            console.log(response,'una respuesta del corazon');
            let xobj2 = response.empresas;
            this.productos = response.filtrados

            xobj2.forEach(element => {

              this.ELEMENT_DATA.push({ 
                cod_proveedor: element.cod_proveedor,
                des_proveedor: element.des_proveedor,
                cant_cotizaciones: element.cant_cotizaciones,
                accion:''
              })

            })

               
            this.displayedColumns = [
              'cod_proveedor',
              'des_proveedor',
              'cant_cotizaciones',
              'accion'
            ];

            this.displayedColumns2 = [
                "cod_cotizacion",
                "cod_producto",
                "des_producto",
                "precio",
                "precio_total",
                "cant_solicitada",
                "tipo_unidad_medida",
                'accion'
            ];

            /* this.dataSource = this.ELEMENT_DATA; */
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
        },
        error =>{
          console.log('una respuesta');
          console.log(error.error.message);
          this.displayedColumns = [
            'cod_proveedor',
            'des_proveedor',
            'cant_cotizaciones',
            'accion'
          ];
          this.displayedColumns2 = [
            'cod_proveedor',
            'des_proveedor',
            'cod_producto',
            'des_producto',
            'cant_aprobada',
            'tipo_unidad_medida',
            'precio',
            'precio_total',
            'tomar_data_ingresada', 
          ];
          this.dataSource = new MatTableDataSource<any>();
          this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
                this.dataSource.paginator = this.paginator;
                this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
        }
      );
      
    } catch (error) {

      this.dialogSpin.close();
          console.log('una respuesta');
          console.log(error.error.message);
      this.displayedColumns = [
        'cod_proveedor',
        'des_proveedor',
        'cant_cotizaciones',
        'accion'
      ];
      this.displayedColumns2 = [
        'cod_proveedor',
        'des_proveedor',
        'cod_producto',
        'des_producto',
        'cant_aprobada',
        'tipo_unidad_medida',
        'precio',
        'precio_total',
        'tomar_data_ingresada', 
      ];
      this.dataSource = new MatTableDataSource<any>();
      this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
      this.dataSource.paginator = this.paginator;
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
    }

  }

  tomar_data( elemento:any){

      let obj = {
        proveedor: elemento ,
        productos: this.productos[elemento.cod_proveedor]
      }

      this.servicio.comprobarProveedorhttp(elemento.cod_proveedor).subscribe(response =>{
  
          if(!response.existe){
            return this.snackBar.open('Proveedor no creado, por favor comuniquese con Tecnologia','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }

          if(response.actualizar){

            return this.snackBar.open('A este proveedor le falta información, solicita que sea cargada ','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});

          }

          if(response.inactivo){

            return this.snackBar.open('Proveedor Inactivo Comunicate con gerencia','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});

          }
          if(response.preOrdenP){

            return this.snackBar.open('Proveedor Tiene una Preorden activa','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});

          }

          var dialogRef = this.dialog.open(FormCrearOcProductosxproveedorComponent,     {
            width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
            data:obj,
            disableClose:   true,
            panelClass: 'pedidos_modal3',
            backdropClass: "bdrop"
            });
    
            dialogRef.afterClosed().subscribe(result => {
    
              if(result == 'listo'){
                this.dialogRefp.close('listo')
                this.toast.success('Listo','Orden de Compra creada correctamente')

              }
            })

         
  
      },
      error =>{

        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
  
      })

    
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
