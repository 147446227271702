import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestionar-facturas',
  templateUrl: './gestionar-facturas.component.html',
  styleUrls: ['./gestionar-facturas.component.css']
})
export class GestionarFacturasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
