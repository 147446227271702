<div class="fadeIn2 animated fast">
    <nav class="navbar navbar-expand-lg " style="height: 7%; border:1px solid #cccccccc ;">

        <div class=" navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <div class="mat-elevation-z8" style="margin: 1px 0px 1px 0px;">
                    <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color="primary">Actualizar lista<mat-icon style="margin: 0% 0% 0% 4%;">sync</mat-icon></button></li>
                </div>
                <!--   <div class="mat-elevation-z8" style="margin: 1px 0px 1px 5px;">
                    <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color="primary" (click)='editardatos("","insertar")'>Crear Orden de producción<mat-icon style="margin: 0% 0% 0% 4%;">add</mat-icon></button></li>
                </div> -->
            </ul>
            <div class=" form-group mat-elevation-z8 " style=" margin: 1px 0px 1px 0px; width:200px ;padding: 0px 10px 10px 10px; height: 40px;">
                <mat-form-field class="materialinput" style="padding: 0px; margin: 0px 0px 0px 0px;">
                    <mat-label>Buscar</mat-label>
                    <input #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
    </nav>
    <div class="container-fluid">
        <div class="">
            <div class="col-md-12 col-sm-12 col-xs-12 ">
                <br>
                <div class="example-container mat-elevation-z8 table-responsive">

                    <table mat-table [dataSource]="dataSource" class="text-center table-striped table-bordered table-condensed table-hover">
                        <!-- Weight Column -->
                        <ng-container matColumnDef="cod_orden_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Cod ord prod</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.cod_orden_producto}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cod_usuario">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Cod usuario</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.cod_usuario}} </td>
                        </ng-container>

                        <ng-container matColumnDef="des_usuario">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Usuario</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.des_usuario}} </td>
                        </ng-container>

                        <ng-container matColumnDef="estatus">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Estatus</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.estatus}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fecha_inicio">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Fecha ini</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.fecha_inicio}} </td>
                        </ng-container>

                        <ng-container matColumnDef="hora_inicio">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Hora ini</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.hora_inicio}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cod_usuario_cierre">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Cod usuario cierre</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.cod_usuario_cierre}} </td>
                        </ng-container>

                        <ng-container matColumnDef="des_usuario_cierre">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Usuario cierre </strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.des_usuario_cierre}} </td>
                        </ng-container>

                        <ng-container matColumnDef="fecha_fin">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Fecha fin</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.fecha_fin}} </td>
                        </ng-container>

                        <ng-container matColumnDef="hora_fin">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                                <h5><strong>Hora fin</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.hora_fin}} </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="detalle">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 1px;">
                                <h5><strong>Detalle</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center" (click)='detalleOrdenProd(element)'>
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                    <mat-icon>image_search</mat-icon>
                                  </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Eliminar">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 1px;">
                                <h5><strong>Borrar</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center" (click)='modalrespuesta(element, "eliminarclientes")'>
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="warn" aria-label="Example icon button with a delete icon">
                                      <mat-icon>delete</mat-icon>
                                    </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Actualizar">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 1px;">
                                <h5><strong>Editar</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center" (click)='editardatos(element, "actualizar")'>
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="primary" style="margin: 1%;" aria-label="Example icon button with a delete icon">
                                      <mat-icon>sync</mat-icon>
                                    </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="confirmar">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 1px;">
                                <h5><strong>Confirmar</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center" (click)='confirmacionOrdenProd(element)'>
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="" style="margin: 1%;" aria-label="Example icon button with a delete icon">
                                    <mat-icon>check</mat-icon>
                                  </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 7, 9]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>

    </div>

    <!--    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <table class="table table-condensed">
                    <thead class="">
                        <th>NOMBRE</th>
                        <th>APELLIDO</th>
                        <th>DIRECCION</th>
                        <th>E-MAIL</th>
                        <th>ELIMINAR</th>
                        <th>ACTUALIZAR</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of obj" class="" style="font-size:120%;">
                            <td *ngIf="i.id">{{i.nombre_usu}} </td>
                            <td *ngIf="i.id">{{i.apellido_usu}}</td>
                            <td *ngIf="i.id">{{i.direccion_usu}}</td>
                            <td *ngIf="i.id">{{i.correo_usu}}</td>
                            <td *ngIf="i.id"><button (click)='eliminardatos(i)' class="btn btn-outline-light btn-block">Eliminar</button></td>
                            <td *ngIf="i.id"><button (click)='editardatos(i)' class="btn btn-outline-light btn-block">Editar</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div> -->
</div>