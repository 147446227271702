import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { FormNotaCreditoAdminSingleComponent } from '../form-nota-credito-admin-single/form-nota-credito-admin-single.component';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';


import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';

import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { FormNotaCreditoComponent } from 'src/app/componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormNotaCreditoAdminComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import { PdfServices } from '../../servicios/app.pdf.services';

interface interfacefactura{
  lote_producto: string;
  cod_producto:string;
  des_producto:string;
  cant_producto:string;
  costo_producto:string;
  subtotal_producto:string;
}
interface interfacefactura2{
  des_concepto: string;
  subtotal_concepto:string;

}
interface interdatosfacefactura{
cod_factura:string;
des_cliente: string;
cod_cliente:string;
des_vendedor:string;
fecha_e_factura:string;
fecha_v_factura:string;
cod_pedido:string;
telefono1_cliente:string;
telefono2_cliente:string;
}
type TableRow = [string, string, string, string, string, string];

type TableRow2 = [string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-form-nota-credito-admin-base',
  templateUrl: './form-nota-credito-admin-base.component.html',
  styleUrls: ['./form-nota-credito-admin-base.component.css']
})
export class FormNotaCreditoAdminBaseComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_cliente',{static:true}) cod_cliente:ElementRef;
  @ViewChild('des_cliente',{static:true}) des_cliente:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;

obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private dialog: MatDialog,
     private snackBar: MatSnackBar,
      private servicio:nkServices,
      private formb:FormBuilder,
      private pdf : PdfServices,
      private fb:FormBuilder,
      private dialogRefp: MatDialogRef<FormNotaCreditoAdminBaseComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

    }

    ngOnInit(): void {
      this.listar_notas_credito(null);
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource.paginator);
    }
  listar_notas_credito(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listarNotasAdminSinglehttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.respuesta) {
  
                    this.ELEMENT_DATA.push({
                      cod_factura:response.respuesta[i].cod_factura,
                      cod_nota_credito: response.respuesta[i].cod_nota_credito,
                      cod_lista_precio:response.respuesta[i].cod_lista_precio,
                      cod_cliente:response.respuesta[i].cod_cliente,
                      des_cliente:response.respuesta[i].des_cliente,
                      cod_usuario:response.respuesta[i].cod_usuario,
                      des_usuario:response.respuesta[i].des_usuario,
                      fecha_inicio:response.respuesta[i].fecha_inicio,
                      hora_inicio:response.respuesta[i].hora_inicio,
                      estatus:response.respuesta[i].estatus,
                      tipo_nota: response.respuesta[i].tipo_nota_credito,
                      subtotal_dev_costo: response.respuesta[i].subtotal_dev_costo,
                      total_dev_costo:response.respuesta[i].total_dev_costo,
                      iva_dev:response.respuesta[i].iva_dev,
                      iva_dev_monto:response.respuesta[i].iva_dev_monto,
                      desc_dev:response.respuesta[i].desc_dev,
                      desc_dev_monto: response.respuesta[i].desc_dev_monto,
                      iva_dev_exento:response.respuesta[i].iva_dev_exento,
                      dev_monto_exento:response.respuesta[i].dev_monto_exento,
                      accion:'',
                      accion2:'',
                      accion3:'',
                      accion4:''
                    });
                  }
                  this.displayedColumns = [
                                          'cod_factura',
                                          'cod_nota_credito',
                                          'cod_lista_precio',
                                          'cod_cliente',
                                          'des_cliente',
                                          'cod_usuario',
                                          'des_usuario',
                                          'estatus',
                                          'tipo_nota',
                                          'fecha',
                                          'hora',
                                          'imprimir',
                                          'descargar'];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                      'cod_factura',
                                      'cod_nota_credito',
                                      'cod_lista_precio',
                                      'cod_cliente',
                                      'des_cliente',
                                      'cod_usuario',
                                      'des_usuario',
                                      'estatus',
                                      'tipo_nota',
                                      'fecha',
                                      'hora',
                                      'imprimir',
                                      'descargar'];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }

  async notas_credito_admin_single(obj:any,xvalor:any){

    try {
      var dialogRef;
        switch (xvalor) {
    
          case 'AD-SN':
            dialogRef =  this.dialog.open(FormNotaCreditoAdminSingleComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              data: {cod_factura: obj.cod_factura,
                      cod_pedido: obj.cod_pedido,
                      cod_lista_precio:obj.cod_lista_precio,
                      cod_cliente:obj.cod_cliente,
                      des_cliente:obj.des_cliente,
                      iva_pedido:obj.iva_pedido,
                      iva_monto:obj.iva_monto,
                      desc_pedido:obj.desc_pedido,
                      tipo_nota:xvalor,
                      subtotal_costo_pedido:obj.subtotal_costo_pedido,
                      total_costo_pedido:obj.total_costo_pedido,
                      termini:'pedido'},
                      disableClose: true,
              panelClass: 'pedidos_modal3',
              backdropClass: "bdrop"
            });
              break;
          default:
            break;
        }
  
      dialogRef.afterClosed().subscribe(result => {
  
        if(result != 'cerrar' && result != 'salir'){
  
              console.log(result);
  
          this.gestionNotaCreditoAdminSingle(result);
          /* let resultado = this.eliminarunidad(result.id); */
        }
      });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
  
  gestionNotaCreditoAdminSingle(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner'
    });
    setTimeout( async() => {
      this.servicio.gestionNotaCreditoAdminSinglehttp(xvalor).subscribe(
        response =>{
          this.dialogSpin.close();
          this.cod_nota_credito = response.nota;
          this.listar_notas_credito(null);
          this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        },
        error =>{
          this.dialogSpin.close();
          console.log('una respuesta');
          console.log(<any>error);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
  
     /*  setTimeout(() => {
        const pdf = new PdfMakeWrapper();
        var valor = {cod_factura:xvalor.cod_factura, cod_nota_credito:this.cod_nota_credito,tipo_nota:xvalor.tipo_nota}
        this.servicio.listarNotaDebitohttp(valor).subscribe(
         async response  =>{
            console.log(response);
            console.log(xvalor);
                pdf.info({
                  title: 'Nota de debito N°'+response.pdf2[0].cod_nota_credito+'',
                  author: 'Factory cosmetics C.A.',
                  subject: 'Facturas',
              });
  
              pdf.pageMargins([20,115,20,60]);
                pdf.add(pdf.ln(1));
                pdf.add( this.numerofact9NCADMIN(response.pdf2));
                pdf.add(this.crearTabla29NCADMIN(response.pdf2,response.pdf3));
                pdf.pageSize('A4');
                pdf.add(this.headerTablaNCADMIN());
                pdf.add(this.crearTabla9NCADMIN(response.pdf));
                pdf.add(this.crearTabla99NCADMIN(response.pdf));
                pdf.add(this.crearTabla999NCADMIN(response.pdf));
                pdf.add(pdf.ln(1));
                pdf.add(this.crearTabla49NCADMIN(response.pdf2,xvalor,response.pdf5,response.fecha,response.pdf6));
                pdf.create().open();
  
          },
          error =>{
            console.log(error);
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
       }, 3000); */
    }, 3000);
  
    return this.xobj2;
  }

  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
}

imprimir_descargar(xvalor:any,text:any): void {
  this.accion = text;
  switch (this.accion) {
    case 'imprimir':
      this.accion = 'enviarpedido';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
              maxWidth: '100vw',
              height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        
        if (result != 'cerrar' && result != 'salir' ) {
          dialogRef.close();
          this.imprimirnota(xvalor);
        }else{
          dialogRef.close();
        }
      });
      break;
      case 'descargar':
      this.accion = 'enviarpedido';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
              maxWidth: '100vw',
              height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar' && result != 'salir' ) {
          dialogRef.close();
          this.descargarnota(xvalor);
        }else{
          dialogRef.close();
        }
      });
      break;
    default:
      break;
  }
}

imprimirnota(xvalor:any){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        const pdf = new PdfMakeWrapper();
        console.log(xvalor);
        var valor = {cod_factura:xvalor.cod_factura, cod_nota_credito:xvalor.cod_nota_credito,tipo_nota:xvalor.tipo_nota}
        this.servicio.listarNotaCreditohttp(valor).subscribe(
         async response  =>{
            console.log(response);
            this.dialogSpin.close();
            console.log(xvalor);

            switch (xvalor.tipo_nota) {
              case 'dev':
                pdf.info({
                  title: 'Nota de credito N°'+response.pdf2[0].cod_nota_credito+'',
                  author: 'Factory cosmetics C.A.',
                  subject: 'Notas de credito',
              });

              pdf.pageMargins([20,115,20,60]);
                pdf.add(pdf.ln(1));
                pdf.add( this.numerofact9NC(response.pdf2));
                pdf.add(this.crearTabla29NC(response.pdf2,response.pdf3));
               /*  pdf.add(this.crearTabla39(response.pdf2)); */
                pdf.pageSize('A4');
                pdf.add(this.headerTablaNC());
                pdf.add(this.crearTabla9NC(response.pdf));
                pdf.add(this.crearTabla99NC(response.pdf));
                pdf.add(this.crearTabla999NC(response.pdf));
                pdf.add(pdf.ln(1));
                pdf.add(this.crearTabla49NC(response.pdf2,xvalor,response.pdf5,response.fecha, response.pdf6));
                pdf.create().open();
                break;
              case 'admin':
                pdf.info({
                  title: 'Nota de credito N°'+response.pdf2[0].cod_nota_credito+'',
                  author: 'Factory cosmetics C.A.',
                  subject: 'Notas de credito',
              });

              pdf.pageMargins([20,115,20,60]);
                pdf.add(pdf.ln(1));
                pdf.add( this.numerofact9NCADMIN(response.pdf2));
                pdf.add(this.crearTabla29NCADMIN(response.pdf2,response.pdf3));
                pdf.pageSize('A4');
                pdf.add(this.headerTablaNCADMIN());
                pdf.add(this.crearTabla9NCADMIN(response.pdf));
                pdf.add(this.crearTabla99NCADMIN(response.pdf));
                pdf.add(this.crearTabla999NCADMIN(response.pdf));
                pdf.add(pdf.ln(1));
                pdf.add(this.crearTabla49NCADMIN(response.pdf2,xvalor,response.pdf5,response.fecha, response.pdf6));
                pdf.create().open();
                  break;
                  case 'AD-SN':
          
                    response.pdf4 = response.pdf2;
                response.pdf2[0].comentarios = '';
                
                response.pdf4[0].subtotal_costo_pedido = response.pdf4[0].subtotal_dev_costo;
                response.pdf4[0].desc_pedido = response.pdf4[0].desc_dev;
                response.pdf4[0].desc_pedido_monto = response.pdf4[0].desc_dev_monto;
                response.pdf4[0].monto_exento = response.pdf4[0].dev_monto_exento;
                response.pdf4[0].iva_pedido = response.pdf4[0].iva_dev;
                response.pdf4[0].iva_monto = response.pdf4[0].iva_dev_monto;
                response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;

                response.pdf6[0].tasa_precio = '0,00';
                response.pdf5 = response.pdf6;
                
                response.pdf4[0].tipo_pedido = response.pdf2[0].tipo_nota_credito;

                response.pdf[0].cod_producto = 'N/A';
                response.pdf[0].des_producto = response.pdf[0].des_concepto;
                response.pdf[0].cant_producto = '1';
                response.pdf[0].costo_producto = '0,00';
                response.pdf[0].desc_prod = '0,00';
                response.pdf[0].desc_prod_monto = '0,00';
                response.pdf[0].subtotal_producto = response.pdf[0].subtotal_concepto ;
                response.fecha = response.pdf4[0].fecha_inicio
                this.pdf.pdfVentas(response,'d');
                  break;
              default:
                break;
            }
          },
          error =>{
            console.log(error);
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
       }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error ,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }
  async descargarnota(xvalor:any){

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      const pdf = new PdfMakeWrapper();

      this.servicio.listarProdPedidohttp(xvalor).subscribe(
       async response  =>{
        this.dialogSpin.close();
          console.log(xvalor);
          pdf.info({
            title: 'Factura N°'+response.pdf2[0].cod_pedido+'',
            author: 'Factory cosmetics C.A.',
            subject: 'Facturas',
        });
        var titulo = 'FACTORY COSMETICS 3DG C.A.';
          var texto =
                        'Calle Bolivar con Calle Regulo Franquiz Local S/N,\n\
                           Sector Pueblo Arriba. Guarenas, Miranda.\n\
                           Zona Postal 1220. Telf.: (0212) 519.43.98 / 365.46.24 / 714.03.42 /425.2.83\n\
                          E-mail:contacto@factorycosmetics.com.ve / www.factorycosmetics.com.ve \n\
                                        RIF.: J-41031797-3';
            pdf.pageMargins([20,5,20,5]);
            pdf.add(pdf.ln(1));
            pdf.add(
              new Columns([ new Table([['',titulo,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).bold().absolutePosition(20,58).end]).columnGap(5).end,
                                  );
            pdf.add(
              new Columns([ new Table([[await new Img("../../../../../../assets/img/Factory (1).png").build(),'','']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(10,15).end]).columnGap(5).end,
                                  );
            pdf.add(
                  new Columns([ new Table([['',texto,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(20,70).end]).columnGap(5).end,
                                  );
          pdf.add( this.numerofact8(response.pdf2));
          pdf.add(this.crearTabla28(response.pdf2,response.pdf3));
          /* pdf.add(this.crearTabla3(response.pdf2)); */
          pdf.pageSize('A4');
          pdf.add(this.headerTabla8());
          pdf.add(this.crearTabla8(response.pdf));
          pdf.add(this.crearTabla88(response.pdf));
          pdf.add(this.crearTabla888(response.pdf));
          pdf.add(pdf.ln(1));
          pdf.add(this.crearTabla48(response.pdf4,xvalor,response.pdf5));
          pdf.create().download('Factura N°'+response.pdf2[0].cod_pedido+'');
        },
        error =>{
          console.log(error);
          this.dialogSpin.close();
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
    }, 3000);

  }
  //////////////////////REPORTES////////////////////////////
  getBase64ImageFromURL8(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
  headerTabla8():ITable{
    return new Table([
      ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
    ]).layout({
      vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
        },
        hLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
        }
    }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,203).end;
    }
  crearTabla8(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData9(data)

  ]).layout('noBorders').widths([30,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,216).margin(40).end;
  }
  crearTabla88(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData99(data)

    ]).layout('noBorders').widths(['*','*',200,'*','*',20]).alignment('right').fontSize(6).absolutePosition(20,216).margin(40).end;
    }
    crearTabla888(data:interfacefactura[]):ITable{
      return new Table([
        ...this.extraerData999(data)

      ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,216).margin(40).end;
      }
  async cinta8(data:interdatosfacefactura[]){

    return new Table([
      [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
    ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
    }
    numerofact8(data:interdatosfacefactura[]):ITable{

      return new Table([
        ['','','','', '','Factura Nº: '+data[0].cod_pedido+''],
      ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,138).end;
      }
      crearTabla28(data:any[], data2:any){
        return  new Columns([new Table([
            ['Cliente: '+data2[0].des_cliente+'',''],
            ['R.I.F.: '+data2[0].cod_cliente+'',''],
            ['Vendedor: '+data2[0].des_vendedor+'',''],
            ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
          ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,150).end,
          new Table([
            [ '',''],
            [ '','Emisión: '+data[0].fecha_e_factura+''],
            [ '','Venc: '+data[0].fecha_e_factura+''],
            [ '','Dirección: '+data2[0].dir_cliente+'']
          ]).widths(['*',250]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,150).end]).columnGap(5).end;
          }
    crearTabla38(data:interdatosfacefactura[]):ITable{
      return new Table([
        ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
      ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
      }

        crearTabla48(data:any[],data2:any,data3:any){

          return  new Columns([ new Table([
            ['Notas:','','','','',''],
            ['LISTA DE PRECIOS Nº: '+data[0].cod_lista_precio+'','','','', '',''],
            ['CODIGO DE CONTROL Nº: '+data3[0].cod_control+'','','','','',''],
            ['TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A','','','','',''],
            ['DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.','','','', '',''],
            ['BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064  BANCAMIGA: 0172-0194-8419-4444-9550','','','', '',''],
          ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
              }
          }).absolutePosition(20,646).end,
          new Table([
            ['','','','', 'Total base imponible: ',''+data[0].subtotal_costo_pedido+''],
            ['','','','', 'Total exento: ',''+data[0].monto_exento+''],
            ['','','','', 'Descuento: '+data[0].desc_pedido+' %',''+data[0].desc_pedido_monto+''],
            ['','','','', 'Sub-total: ',''+data[0].subtotal_costo_pedido+''],
            ['','','','', 'I.V.A : '+data[0].iva_pedido+' %',''+data[0].iva_monto+''],
            ['','','','', 'Total: ',''+data[0].total_costo_pedido+''],
          ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(7).layout({
            vLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
              },
              hLineWidth:(i:number, node:any, col:number)=>{
                return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
              }
          }).absolutePosition(20,646).end
        ]).columnGap(5).end

          }

        extraerData8(data:interfacefactura[]):TableRow[]{

          let rows =[];

          for (const i in data) {
           rows.push(data[i]);

          }
          console.log(rows);
          return rows.map(row =>['',row.cod_producto,
                               row.des_producto, '',
                              '', '']);
        }
        extraerData88(data:interfacefactura[]):TableRow[]{

          let rows =[];

          for (const i in data) {
           rows.push(data[i]);

          }
          console.log(rows);
          return rows.map(row =>['',
                               '', '',
                              row.costo_producto, row.subtotal_producto,'']);
        }
        extraerData888(data:interfacefactura[]):TableRow[]{

          let rows =[];

          for (const i in data) {
           rows.push(data[i]);

          }
          console.log(rows);
          return rows.map(row =>['','',
                               '', row.cant_producto,
                              '', '']);
        }


      //////////////////////IMPRMIR////////////////////////////
getBase64ImageFromURL9(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}
headerTabla9():ITable{
  return new Table([
    ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,190).end;
  }
crearTabla9(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData9(data)

]).layout('noBorders').widths([27,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,205).margin(40).end;
}
crearTabla99(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData99(data)

  ]).layout('noBorders').widths(['*','*',200,'*','*',17]).alignment('right').fontSize(6).absolutePosition(20,205).margin(40).end;
  }
  crearTabla999(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData999(data)

    ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,205).margin(40).end;
    }
async cinta9(data:interdatosfacefactura[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  }
  numerofact9(data:interdatosfacefactura[]):ITable{

    return new Table([
      ['','','','', '','Factura  Nº: '+data[0].cod_pedido+''],
    ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,130).end;
    }
  crearTabla29(data:any[], data2:any){
    return  new Columns([new Table([
        ['Cliente: '+data2[0].des_cliente+'',''],
        ['R.I.F.: '+data2[0].cod_cliente+'',''],
        ['Vendedor: '+data2[0].des_vendedor+'',''],
        ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
      ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,140).end,
      new Table([
        [ '',''],
        [ '','Emisión: '+data[0].fecha_e_factura+''],
        [ '','Venc: '+data[0].fecha_e_factura+''],
        [ '','Dirección: '+data2[0].dir_cliente+'']
      ]).widths(['*',250]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,140).end]).columnGap(5).end;
      }
  crearTabla39(data:interdatosfacefactura[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    }
    crearTabla49(data:any[],data2:any,data3:any){

      return  new Columns([ new Table([
        ['Notas:','','','','',''],
        ['LISTA DE PRECIOS Nº: '+data[0].cod_lista_precio+'','','','', '',''],
        ['CODIGO DE CONTROL Nº: '+data3[0].cod_control+'','','','','',''],
        ['TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A','','','','',''],
        ['DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.','','','', '',''],
        ['BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064  BANCAMIGA: 0172-0194-8419-4444-9550','','','', '',''],
      ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          }
      }).absolutePosition(20,637).end,
      new Table([
        ['','','','', 'Total base imponible: ',''+data[0].subtotal_costo_pedido+''],
        ['','','','', 'Total exento: ',''+data[0].monto_exento+''],
        ['','','','', 'Descuento: '+data[0].desc_pedido+' %',''+data[0].desc_pedido_monto+''],
        ['','','','', 'Sub-total: ',''+data[0].subtotal_costo_pedido+''],
        ['','','','', 'I.V.A : '+data[0].iva_pedido+' %',''+data[0].iva_monto+''],
        ['','','','', 'Total: ',''+data[0].total_costo_pedido+''],
      ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          }
      }).absolutePosition(20,637).end
    ]).columnGap(5).end

      }

      extraerData9(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',row.cod_producto,
                             row.des_producto, '',
                            '', '']);
      }
      extraerData99(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',
                             '', '',
                            row.costo_producto, row.subtotal_producto,'']);
      }
      extraerData999(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['','',
                             '', row.cant_producto,
                            '', '']);
      }


      //////////////////////IMPRMIR NOTA DE CREDITO DEV////////////////////////////
getBase64ImageFromURLNC(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}
headerTablaNC():ITable{
  return new Table([
    ['Codigo','Producto','Cantidad', 'Precio','Sub-Total']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 5 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*',200,'*','*','*']).alignment('center').fontSize(7).absolutePosition(20,190).end;
  }
crearTabla9NC(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData9NC(data)

]).layout('noBorders').widths([27,'*',200,'*','*','*']).alignment('left').fontSize(6).absolutePosition(20,205).margin(40).end;
}
crearTabla99NC(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData99NC(data)

  ]).layout('noBorders').widths(['*','*',200,'*','*',17]).alignment('right').fontSize(6).absolutePosition(20,205).margin(40).end;
  }
  crearTabla999NC(data:interfacefactura[]):ITable{
    return new Table([
      ...this.extraerData999NC(data)

    ]).layout('noBorders').widths(['*',115,'*','*','*','*']).alignment('center').fontSize(6).absolutePosition(20,205).margin(40).end;
    }
async cinta9NC(data:interdatosfacefactura[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  }
  numerofact9NC(data:any[]):ITable{

    return new Table([
      ['','','','', '','Nota de credito  Nº: '+data[0].cod_nota_credito+''],
    ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,130).end;
    }
  crearTabla29NC(data:any[], data2:any){
    return  new Columns([new Table([
        ['Cliente: '+data2[0].des_cliente+'',''],
        ['R.I.F.: '+data2[0].cod_cliente+'',''],
        ['Vendedor: '+data2[0].des_vendedor+'',''],
        ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
      ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,140).end,
      new Table([
        [ '',''],
        [ '','Emisión: '+data[0].fecha_inicio+''],
        [ '','Venc: '+data[0].fecha_inicio+''],
        [ '','Dirección: '+data2[0].dir_cliente+'']
      ]).widths(['*',350]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,140).end]).columnGap(5).end;
      }
  crearTabla39NC(data:interdatosfacefactura[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    }
    crearTabla49NC(data:any[],data2:any,data3:any,data4:any,data5:any){
      console.log(data);
      console.log(data2);
      console.log(data3);

      var base_imponible = parseFloat(data[0].subtotal_dev_costo.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) - parseFloat(data[0].dev_monto_exento.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'));
      var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      return  new Columns([ new Table([
        ['Notas:','','','','',''],
        ['AFECTA LA FACTURA Nº: '+data[0].cod_factura+'','','','','',''],
        ['CODIGO DE CONTROL FACTURA Nº: '+data3[0].cod_control+'','','','','',''],
        ['CODIGO DE CONTROL NOTA DE CREDITO Nº: '+data5[0].cod_control+'','','','', '',''],
        ['FECHA DE LA FACTURA: '+data4[0].fecha_e_factura+'','','','', '',''],
        ['MONTO BASE DE LA FACTURA: '+data4[0].monto_base_factura+'','','','', '',''],
      ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          }
      }).absolutePosition(20,637).end,
      new Table([
        ['','','','', 'Total base imponible: ',''+formato_base_impnible+''],
        ['','','','', 'Total exento: ',''+data[0].dev_monto_exento+''],
        ['','','','', 'Descuento: '+data[0].desc_dev+' %',''+data[0].desc_dev_monto+''],
        ['','','','', 'Sub-total: ',''+data[0].subtotal_dev_costo+''],
        ['','','','', 'I.V.A : '+data[0].iva_dev+' %',''+data[0].iva_dev_monto+''],
        ['','','','', 'Total: ',''+data[0].total_dev_costo+''],
      ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          }
      }).absolutePosition(20,637).end
    ]).columnGap(5).end

      }

      extraerData9NC(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',row.cod_producto,
                             row.des_producto, '',
                            '', '']);
      }
      extraerData99NC(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',
                             '', '',
                            row.costo_producto, row.subtotal_producto,'']);
      }
      extraerData999NC(data:interfacefactura[]):TableRow[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['','',
                             '', row.cant_producto,
                            '', '']);
      }


      //////////////////////IMPRMIR NOTA DE CREDITO ADMIN////////////////////////////
getBase64ImageFromURLNCADMIN(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}
headerTablaNCADMIN():ITable{
  return new Table([
    ['Descripción','Sub-Total']
  ]).layout({
    vLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 2 ? 1:0;
      },
      hLineWidth:(i:number, node:any, col:number)=>{
        return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
      }
  }).widths(['*','*']).alignment('center').fontSize(10).absolutePosition(20,190).end;
  }
crearTabla9NCADMIN(data:any[]):ITable{
return new Table([
  ...this.extraerData9NCADMIN(data)

]).layout('noBorders').widths(['*','*']).alignment('center').fontSize(9).absolutePosition(20,205).margin(40).end;
}
crearTabla99NCADMIN(data:any[]):ITable{
  return new Table([
    ...this.extraerData99NCADMIN(data)

  ]).layout('noBorders').widths(['*','*']).alignment('center').fontSize(9).absolutePosition(20,205).margin(40).end;
  }
  crearTabla999NCADMIN(data:any[]):ITable{
    return new Table([
      ...this.extraerData999NCADMIN(data)

    ]).layout('noBorders').widths(['*','*']).alignment('center').fontSize(9).absolutePosition(20,205).margin(40).end;
    }
async cinta9NCADMIN(data:any[]){

  return new Table([
    [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
  ]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
  }
  numerofact9NCADMIN(data:any[]):ITable{

    return new Table([
      ['','','','', '','Nota de credito  Nº: '+data[0].cod_nota_credito+''],
    ]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('noBorders').absolutePosition(20,130).end;
    }
  crearTabla29NCADMIN(data:any[], data2:any){
    return  new Columns([new Table([
        ['Cliente: '+data2[0].des_cliente+'',''],
        ['R.I.F.: '+data2[0].cod_cliente+'',''],
        ['Vendedor: '+data2[0].des_vendedor+'',''],
        ['Telf: '+data2[0].telefono1_cliente+'   Celular: '+data2[0].telefono2_cliente+'','']
      ]).widths([200,'*']).alignment('left').fontSize(7).layout('noBorders').absolutePosition(20,140).end,
      new Table([
        [ '',''],
        [ '','Emisión: '+data[0].fecha_inicio+''],
        [ '','Venc: '+data[0].fecha_inicio+''],
        [ '','Dirección: '+data2[0].dir_cliente+'']
      ]).widths(['*',350]).alignment('right').fontSize(7).layout('noBorders').absolutePosition(50,140).end]).columnGap(5).end;
      }
  crearTabla39NCADMIN(data:any[]):ITable{
    return new Table([
      ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
    ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
    }
    crearTabla49NCADMIN(data:any[],data2:any,data3:any,data4:any,data5:any){
      console.log(data);
      console.log(data2);
      console.log(data3);

      var base_imponible = parseFloat(data[0].subtotal_dev_costo.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) - parseFloat(data[0].dev_monto_exento.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'));
      var formato_base_impnible = base_imponible.toFixed(2).replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      return  new Columns([ new Table([
        ['Notas:','','','','',''],
        ['AFECTA LA FACTURA Nº: '+data[0].cod_factura+'','','','','',''],
        ['CODIGO DE CONTROL FACTURA Nº: N/A','','','','',''],
        ['CODIGO DE CONTROL NOTA DE CREDITO Nº: '+data5[0].cod_control+'','','','', '',''],
        ['FECHA DE LA FACTURA: N/A','','','', '',''],
        ['MONTO BASE DE LA FACTURA: N/A','','','', '',''],
      ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
          }
      }).absolutePosition(20,637).end,
      new Table([
        ['','','','', 'Total base imponible: ',''+data[0].subtotal_dev_costo+''],
        ['','','','', 'Total exento: ',''+data[0].dev_monto_exento+''],
        ['','','','', 'Descuento: '+data[0].desc_dev+' %',''+data[0].desc_dev_monto+''],
        ['','','','', 'Sub-total: ',''+data[0].subtotal_dev_costo+''],
        ['','','','', 'I.V.A : '+data[0].iva_dev+' %',''+data[0].iva_dev_monto+''],
        ['','','','', 'Total: ',''+data[0].total_dev_costo+''],
      ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(7).layout({
        vLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          },
          hLineWidth:(i:number, node:any, col:number)=>{
            return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
          }
      }).absolutePosition(20,637).end
    ]).columnGap(5).end

      }

      extraerData9NCADMIN(data:any[]):TableRow2[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>[row.des_concepto,
                             '']);
      }
      extraerData99NCADMIN(data:any[]):TableRow2[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>['',
                           row.subtotal_concepto]);
      }
      extraerData999NCADMIN(data:any[]):TableRow2[]{

        let rows =[];

        for (const i in data) {
         rows.push(data[i]);

        }
        console.log(rows);
        return rows.map(row =>[ '',
                             '']);
      }

 
}
