<div class="container-fluid">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div class="row mat-elevation-z8">
            <div class="col-md-12" style="margin: 0px; padding: 0px;">
                <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style="background-color:rgb(178, 134, 204);padding: 5px; margin: 0px;">

                    <div class="collapse navbar-collapse col-md-12 col-sm-12 col-xs-12" id="navbarSupportedContent" style="padding: 0px; margin: 0px;">

                        <ul class="navbar-nav mr-auto col-md-12 col-sm-12 col-xs-12 " style="padding: 0px; margin: 0px; ">
                            <li class="nav-item col-md-4 col-sm-4 col-xs-4" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px; ">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px;">
                                                <mat-label style="color: #000;">Cliente</mat-label>
                                                <input style="font-size: 11px;" matInput placeholder="Cliente:" formControlName="cod_cliente" autocomplete="off" readonly>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            #
                                            </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240); ">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;   height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                                <mat-label style="color: #000; font-size: 11px;">Productos</mat-label>
                                                <input style="font-size: 10px;" type="text" matInput placeholder="" formControlName="producto_pedido" [matAutocomplete]="autoGroup" #producto_pedido>
                                                <mat-autocomplete #autoGroup="matAutocomplete">
                                                    <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                                        <mat-option *ngFor="let i of group.code" [value]="i">
                                                            {{i}}
                                                        </mat-option>
                                                    </mat-optgroup>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label style="color: #000; font-size: 11px;">Cantidad</mat-label>
                                                <input style="font-size: 10px;" maxlength="11" type="text " matInput placeholder="" formControlName="cantidad_pedido" #num autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                #
                                            </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px 0px 0px 0px;">
                                                <mat-label style="color: #000; font-size: 11px;">Buscar</mat-label>
                                                <input #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                      <mat-icon>search</mat-icon>
                                      </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                                <button (click)="addElement()" matTooltip="AGREGAR" mat-raised-button style="height: 100%; width: 100%; color:rgb(255, 255, 255); background-color: rgb(28, 124, 235);">
                                  <mat-icon >add_shopping_cart</mat-icon>
                                </button>
                            </li>
                            <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                                <button (click)="recalcularPrecio('calcular','')" matTooltip="ACTUALIZAR LISTA DE PRECIOS" mat-raised-button style="height: 100%; width: 100%; color:rgb(255, 255, 255); background-color: rgb(223, 14, 205);">
                                  <mat-icon >credit_card</mat-icon>
                                </button>
                            </li>
                            <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                                <button (click)="modalrespuesta()" matTooltip="GUARDAR" mat-raised-button style="height: 100%; width: 100%; color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                                  <mat-icon >save</mat-icon>
                                </button>
                            </li>
                            <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                                <button (click)="cerrarmodal()" matTooltip="SALIR" mat-raised-button style="height: 100%; width: 100%; color:rgb(255, 255, 255); background-color: rgb(243, 101, 7);">
                                        <mat-icon >exit_to_app</mat-icon>
                                  </button>
                            </li>

                        </ul>

                    </div>

                </nav>
            </div>
        </div>

        <div class="row container-pedidos mat-elevation-z8 " style="margin-top: 1%;">

            <div class="container-pedidos2  table-responsive">

                <table #table mat-table [dataSource]="dataSource1" class=" table-secondary  text-center table-striped table-bordered table-condensed table-hover">
                    <ng-container matColumnDef="cod_lista_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:rgb(178, 134, 204); padding: 5px; color: #fff;">
                            <h5><strong>Codigo lista precios</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:rgb(178, 134, 204); color: #fff;"> TOTAL ITEMS ----></td>
                    </ng-container>

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:rgb(178, 134, 204); padding: 5px; color: #fff;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"><b>{{element.cod_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:rgb(178, 134, 204); color: #fff;">{{cantidad_item()}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lista_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:rgb(178, 134, 204); padding: 5px; color: #fff;">
                            <h5><strong>Lista precios N°</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:rgb(178, 134, 204); color: #fff;"> TOTAL ITEMS ----></td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:rgb(178, 134, 204); padding: 5px; color: #fff;">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"><b>{{element.des_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:rgb(178, 134, 204); color: #fff;">CANTIDAD DE PRODUCTOS EN EL PEDIDO -----> </td>
                    </ng-container>


                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:rgb(178, 134, 204); padding: 5px; color: #fff;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:rgb(178, 134, 204); color: #fff;">{{cantidad_productos()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="costo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:rgb(178, 134, 204); padding: 5px; color: #fff;">
                            <h5><strong>Costo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"><b>{{element.costo_producto}} </b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:rgb(178, 134, 204); color: #fff;">{{costo_producto()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="subtotal_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:rgb(178, 134, 204); padding: 5px; color: #fff;">
                            <h5><strong>Sub-total</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;"><b>{{element.subtotal_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:rgb(178, 134, 204); color: #fff;">{{sub_total_producto()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:rgb(178, 134, 204); padding: 5px; color: #fff;">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;">
                            <button (click)="remove(element)" matTooltip="QUITAR PRODUCTO DE LA LISTA" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);">
                                <mat-icon >remove_shopping_cart</mat-icon>
                                </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:rgb(178, 134, 204); color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
        <input style="font-size: 12px;" style="display: none;" placeholder="" formControlName="cod_lista_precio" #cod_lista_precio autocomplete="off" readonly>
        <input style="font-size: 12px;" style="display: none;" #iva placeholder="" formControlName="iva_pedido" autocomplete="off" readonly>
        <div class="  row mat-elevation-z8 col-md-12 col-sm-12 col-xs-12 " style="margin: 10px 0px 0px 0px; padding: 0px">
            <div class="col-md-12 col-sm-12 col-xs-12 " style="margin: 0px; padding: 0px;">

                <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style="background-color:rgb(178, 134, 204); padding: 5px; margin: 0px;">

                    <div class="collapse navbar-collapse col-md-12 col-sm-12 col-xs-12" id="navbarSupportedContent" style="padding: 0px;  margin: 0px;">

                        <ul class="navbar-nav mr-auto col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px; ">
                            <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid #000;">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label style="color: #000;">Items</mat-label>
                                                <input style="font-size: 12px;" #items matInput placeholder="" autocomplete="off" readonly>
                                                <span style="font-size: 12px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              #
                                              </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid #000;">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label style="color: #000;">Descuento</mat-label>
                                                <input style="font-size: 12px;" maxlength="6" (keyup)="porcentaje(desc_pedido)" #desc_pedido matInput placeholder="" formControlName="desc_pedido" autocomplete="off">
                                                <span style="font-size: 12px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              %
                                              </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; border: 1px solid #000;">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label style="color: #000;">Exento</mat-label>
                                                <input style="font-size: 11px;" #exento_monto matInput placeholder="Sub-total:" formControlName="monto_exento" autocomplete="off" readonly>
                                                <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                Bs.
                                                </span>
                                            </mat-form-field>

                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; border: 1px solid #000;">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label style="color: #000;">Sub-total</mat-label>
                                                <input style="font-size: 11px;" #subtotal_pedido matInput placeholder="Sub-total:" formControlName="subtotal_pedido" autocomplete="off" readonly>
                                                <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              Bs.
                                              </span>
                                            </mat-form-field>

                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; border: 1px solid #000;">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label style="color: #000;">I.V.A</mat-label>
                                                <input style="font-size: 11px;" matInput placeholder="I.V.A:" formControlName="iva_monto" autocomplete="off" #iva_monto readonly>
                                                <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              Bs.
                                              </span>
                                            </mat-form-field>

                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; border: 1px solid #000;">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label style="color: #000;">Descuento</mat-label>
                                                <input style="font-size: 11px;" #desc_pedido_monto matInput placeholder="" formControlName="desc_pedido_monto" autocomplete="off" readonly>
                                                <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                  Bs.
                                              </span>
                                            </mat-form-field>

                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; border: 1px solid #000;">
                                <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                    <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                        <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                            <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                                <mat-label style="color: #000;">Total</mat-label>
                                                <input class="" style="font-size: 11px; color: #000;" #total_pedido matInput placeholder="Total:" formControlName="total_pedido" autocomplete="off" readonly>
                                                <span style="font-size: 11px;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                  Bs.
                                              </span>
                                            </mat-form-field>

                                        </div>
                                    </div>

                                </div>
                            </li>

                        </ul>

                    </div>

                </nav>

            </div>

        </div>
    </form>
</div>