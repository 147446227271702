import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procesar-cobros',
  templateUrl: './procesar-cobros.component.html',
  styleUrls: ['./procesar-cobros.component.css']
})
export class ProcesarCobrosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
