import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';
import { ExcelService } from '../../servicios/excel.service';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { environment } from 'src/environments/environment';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-pos',
  templateUrl: './form-pos.component.html',
  styleUrls: ['./form-pos.component.css']
})
export class FormPosComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('producto_pedido') producto_pedido:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('lote_producto',{static:true}) lote_producto:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('cod_lista_precio',{static:true}) cod_lista_precio:ElementRef;
  @ViewChild('iva',{static:true}) iva:ElementRef;
  @ViewChild('desc_pedido_monto',{static:true}) desc_pedido_monto:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('exento_monto',{static:true}) exento_monto:ElementRef;
  @ViewChild('unidad_manejo',{static:true}) unidad_manejo:ElementRef;
  @ViewChild('cantidad_existencia',{static:true}) cantidad_existencia:ElementRef;
  @ViewChild('cantidad_bultos',{static:true}) cantidad_bultos:ElementRef;
  @ViewChild('cantidad_pedido',{static:true}) cantidad_pedido:ElementRef;
  @ViewChild('desc_prod',{static:true}) desc_prod:ElementRef;

  stateGroupOptions: Observable<StateGroup[]>;
  stateGroupOptions2: Observable<StateGroup[]>;
  sub_total_prod:any;
  cost_prod_desc_mont:any;
  cost_prod:any;
  cost_base:any;
  cant_prod:any;
  bandera:any = true;
  bandera_2:any = true;
  value1 = '';
  nombre:any;
forma:FormGroup;
lista:any;
mod:any;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
key_hash:any;
accion:any;
tipo_cliente:any;
unidad:any;
unidadp:any = [];
excede:boolean = false
menu:any;
producto:any = [];
depositos:any = [];
codigo:any = [];
codigprod:any;
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
documento:any;
moneda:any;
dialogSpin:any;
data2:any = [];
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA_1:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();

  constructor(
                private dialog: MatDialog,
                private servicio:nkServices,
                private excel:ExcelService,
                private formb:FormBuilder,
                private fb:FormBuilder,
                private router:Router,
                private util:AppUtilService,
                private atrb:AtributosService,
                private hashkey:HashKeyServices,
                private notifi:ToastNotificationService,
                private dialogRefp: MatDialogRef<FormPosComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.mod = true;

    if (data.accion.toLowerCase() === this.atrb.FACTURA.toLowerCase() || data.accion.toUpperCase() === this.atrb.FACTURA_T.toUpperCase()) {
      this.documento = this.atrb.FACTURA_T.toUpperCase();
      this.moneda = this.atrb.BOLIVARES;
    }
    if (data.accion.toLowerCase() === this.atrb.PRO_FORMA.toLowerCase() || data.accion.toUpperCase() === this.atrb.PRO_FORMA_T.toUpperCase()) {
      this.documento = this.atrb.PRO_FORMA_T.toUpperCase();
      this.moneda = this.atrb.BOLIVARES;
    }
    if (data.accion.toLowerCase() === this.atrb.NOTA_ND.toLowerCase() || data.accion.toUpperCase() === this.atrb.NOTA_ND_T.toUpperCase()) {
      this.documento = this.atrb.NOTA_ND_T.toUpperCase();
      this.moneda = this.atrb.DOLARES;
    }
    if (data.accion.toLowerCase() === this.atrb.NOTA_NB.toLowerCase() || data.accion.toUpperCase() === this.atrb.NOTA_NB_T.toUpperCase()) {
      this.documento = this.atrb.NOTA_NB_T.toUpperCase();
      this.moneda = this.atrb.BOLIVARES;
    }
    if (data.accion.toUpperCase() === this.atrb.POS.toUpperCase()) {
      this.documento = this.atrb.POS.toUpperCase();
      this.moneda = this.atrb.BOLIVARES;
    }
      this.forma = fb.group({
        'cod_pedido':[''],
        'id_pedido':[''],
        'cod_cliente':[''],
        'des_cliente':[''],
        'tipo_cliente':[data.tipo_cliente],
        'mod_factur':[data.mod_factur+' / '+data.tipo_cliente],
        'cod_vendedor':[''],
        'des_vendedor':[''],
        'estatus_cliente':[''],
        'estatus_pedido':[''],
        'forma':[data.forma],
        'producto_pedido':[''],
        'lote_producto':[''],
        'dispo_pedido':[''],
        'cantidad_pedido':[''],
        'iva_exento':[this.atrb.MONTO_0],
        'monto_exento':[this.atrb.MONTO_0],
        'subtotal_pedido':[this.atrb.MONTO_0],
        'total_pedido':[this.atrb.MONTO_0],
        'total_pedido_divisa':[this.atrb.MONTO_0],
        'iva_pedido':[this.atrb.MONTO_0],
        'iva_monto':[this.atrb.MONTO_0],
        'desc_pedido':[this.atrb.MONTO_0],
        'desc_pedido_monto':[this.atrb.MONTO_0],
        'tipo_pedido':this.documento,
        'cod_lista_precio':[''],
        'unidad_manejo':[''],
        'cantidad_existencia':'0',
        'cantidad_bultos':[''],
        'tasa_precio':[''],
        'desc_prod':[this.atrb.MONTO_0],
        'desc_prod_monto':[this.atrb.MONTO_0],
        'cod_deposito':[''],
        'des_deposito':[''],
        'cod_empresa':['']
      });

      this.stateForm = this.formb.group({
        stateGroup: '',
      });
    }

 async ngOnInit(){
           this.lote = true;
          await this.formulario();
          await this.listarDepositos();
          await this.sub_total_producto();
          await this.costo_producto_descuento_monto();
          await this.costo_producto();
          await this.cantidad_productos();
          await this.costo_base_producto();
     
  }
  ngAfterViewInit(){}
  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }

    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups2
        .map(group2 => ({letter: group2.letter, names: filter(group2.names, value), code: filter(group2.code, value)}))
        .filter(group2 => group2.names.length > 0 || group2.code.length > 0 );
    }

    return this.stateGroups2;
  }

  async listarproductot(xvalor_:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      this.codigo = [];
    this.producto = [];
    let xvalor;
    if (xvalor_ !== null) {
      this.producto_pedido.nativeElement.value = '';
      this.unidad_manejo.nativeElement.value = '';
      this.cantidad_existencia.nativeElement.value = await this.atrb.MONTO_00;
      this.stateGroups2 =[];
      this.lotepro = [];
      this.codigo = [];
      this.producto = [];
      this.producto_pedido.nativeElement.value = '';
      this.num.nativeElement.value = '';
      this.cantidad_existencia.nativeElement.value = '';
      this.cantidad_bultos.nativeElement.value = '';
      this.unidad_manejo.nativeElement.value = '';
      this.forma.value.cantidad_pedido = '';
      this.desc_prod.nativeElement.value = await this.atrb.MONTO_0;
      this.cant = false;
      xvalor = {
        tipo_cliente: this.data.tipo_cliente,
        cod_deposito: this.forma.value.cod_deposito,
        cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
      }
    }else{
      xvalor = {
        tipo_cliente: this.data.tipo_cliente,
        cod_cliente: this.data.cod_cliente,
        cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
      }
    }
    const response = await this.servicio.listarProductoTerminadoPTEPTPTOhttp(xvalor).toPromise();
    console.log(response);
        this.stateGroupOptions = this.forma.get('producto_pedido')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+'-'+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto+'-'+response[i].des_producto);
                  }
                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.codigo
                  }];
                  await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async listarDepositos(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
    this.depositos = [];
   let xvalor = {
      cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
    }
    const response = await this.servicio.listarDepositoshttp(xvalor).toPromise();
      for (const i in response) {
        if (response[i].tipo_manejo.toUpperCase() === this.data.mod_factur.toUpperCase() && 
            response[i].tipo_deposito.toUpperCase() === await this.atrb.TIPO_PRODUCTO.PT.toUpperCase()) {
          this.depositos.push(response[i]);
        }
      }
      if (this.depositos.length <= 0) {
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.almacen_no_dispo_um_und)
      }
    await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error.error.message);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
 
 async buscarLprecios(){
          try {
                var cod = this.forma.value.producto_pedido.split(' ');
                var cod_p = cod[0].trim();
                var valor:any = {
                  cod_producto:cod_p,
                  cod_cliente:this.data.cod_cliente,
                  tipo_cliente:this.data.tipo_cliente,
                  cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
                };
           const response = await this.servicio.buscarLprecioshttp(valor).toPromise();
                this.cod_lista_precio.nativeElement.value = response[0].cod_lista_precio_producto;
                  await this.notifi.success(await this.atrb.MENSAJES_FIJOS.ultimo_precio_actualizado);
          } catch (error) {
            console.log(error);
            await this.notifi.customToastNotification(error.error.message);
          }
  }
 async buscarCliente(xvalor:any){
          try {
            console.log(xvalor);
                /* var valor:any = {
                  cod_cliente:this.data.cod_cliente,
                  tipo_cliente:this.data.tipo_cliente,
                  cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
                };
           const response = await this.servicio.buscarLprecioshttp(valor).toPromise();
                this.cod_lista_precio.nativeElement.value = response[0].cod_lista_precio_producto;
                  await this.notifi.success(await this.atrb.MENSAJES_FIJOS.ultimo_precio_actualizado); */
          } catch (error) {
            console.log(error);
            await this.notifi.customToastNotification(error.error.message);
          }
  }

  async buscarDataProductoUmCant(xvalor:any){
    try {
      await this.util.abrirSpinner();
        var cod_producto = xvalor.split('-');
        let datos;
        if (this.data.tipo_cliente.toUpperCase() === await this.atrb.CLNTPS.toUpperCase()) {
          datos = {
            cod_producto:cod_producto[0],
            cod_deposito:this.forma.value.cod_deposito,
            cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
          }
        }else{
          datos = {
            cod_producto:cod_producto[0],
            cod_deposito:this.data.dep_spcl,
            cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
          }
        }
        
       const response = await this.servicio.buscarDataProductoUmCanthttp(datos).toPromise();
       console.log(response);
            if (parseInt(response.cant_producto) <= 0) {
              this.bandera = false;
            }else{
              this.bandera = true;
            }
            if (parseInt(response.unidad_manejo) <= 0 || 
                      response.unidad_manejo === null || 
                      response.unidad_manejo === undefined || 
                      response.unidad_manejo === '') {
              this.bandera_2 = false;
            }else{
              if (this.data.mod_factur.toUpperCase() !== await this.atrb.UM.toUpperCase()) {
                response.unidad_manejo = "1";
              }
              this.bandera_2 = true;
            }
            this.unidad_manejo.nativeElement.value = response.unidad_manejo;
            this.cantidad_existencia.nativeElement.value = response.cant_producto;
            await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }

 async  recalcularPrecio(termino:any, xvalor:any){
    try {
      var cod = this.forma.value.producto_pedido.split(' ');
      var cod_p = cod[0].trim();
      var resul_final_recal;
      if (termino !== await this.atrb.CALC) {
        this.tipo_cliente = xvalor;
      }
      let valor = {
        cod_producto:cod_p,
        cod_cliente:this.data.cod_cliente,
        tipo_cliente:this.tipo_cliente,
        cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
      }
      console.log(valor);
      const response = await  this.servicio.buscarLprecioshttp(valor).toPromise();
        if (response[0].cod_lista_precio_producto !== this.data.cod_lista_precio) {
          switch (termino) {
            case await this.atrb.CALC:
              this.accion = "";
                            var dialogRef = this.dialog.open(FormmMsgComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {id:'',
                                    accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal',
                              backdropClass: "bdrop"
                            });
                            dialogRef.afterClosed().subscribe(async result => {
                              if(result !== await this.atrb.CERRAR){
                                var lista = response[0];
                              const response_ = await this.servicio.buscarLpreciosRelacionhttp(response[0]).toPromise();
                                    var resul = 0;
                                    var valor;
                                    for (const i in this.ELEMENT_DATA) {
                                        var indice = this.ELEMENT_DATA[i].cod_producto;
                                      for (const k in response_) {
                                        if (indice.toUpperCase() === response_[k].cod_producto) {
                                          valor = response_[k].precio_producto_base_bs;
                                          break;
                                        }
                                      }
                                      let subtotal = (parseFloat(await this.util.remplazo_coma_punto(valor)) 
                                                  * parseInt(await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].cant_producto)));
                                      var monto_prod_descuento = (parseFloat(await this.util.numeroFlotante(await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].desc_prod))) * subtotal)
                                                              / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                                                if (subtotal > monto_prod_descuento) {
                                                  subtotal = subtotal - monto_prod_descuento;
                                                }else{
                                                  subtotal = monto_prod_descuento - subtotal;
                                                }
                                        this.ELEMENT_DATA[i].costo_producto = valor;
                                        this.ELEMENT_DATA[i].desc_prod_monto = await this.util.agregar_coma_punto(await this.util.numeroFlotante(monto_prod_descuento));
                                        this.ELEMENT_DATA[i].subtotal_producto = await this.util.agregar_coma_punto(await this.util.numeroFlotante(subtotal));
                                        this.ELEMENT_DATA[i].cod_lista_precio = response[0].cod_lista_precio_producto;
                                        var monto_nuevo = 0;
                                        var monto_exc_nuevo = 0;
            for (const i in this.ELEMENT_DATA) {
                if (this.ELEMENT_DATA[i].estatus_fiscal.toUpperCase() === await this.atrb.XCNT.toUpperCase()) {
                  monto_exc_nuevo = monto_exc_nuevo + parseFloat(await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].subtotal_producto));
                }else{
                  monto_nuevo = monto_nuevo + parseFloat(await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].subtotal_producto));
                }
            }
            let porcentaje = (parseFloat(await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value)) * monto_exc_nuevo)
                                                              / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
            let porcentaje1 = (parseFloat(await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value)) * monto_nuevo)
                                                              / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
            let desc_nuevo = porcentaje1 + porcentaje;
            let xcnt = monto_exc_nuevo - porcentaje;
            let nxcnt = monto_nuevo - porcentaje1;
            let sub_total_nuevo = monto_exc_nuevo + monto_nuevo;
            let total_nuevo = xcnt + nxcnt;
            let iva_nuevo = nxcnt * parseFloat(await this.util.remplazo_coma_punto(this.iva.nativeElement.value)) / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
            this.exento_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(xcnt));
            this.subtotal_pedido.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(sub_total_nuevo));
            this.iva_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(iva_nuevo));
            this.desc_pedido_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(desc_nuevo));
            let resul_final = total_nuevo + iva_nuevo;
            this.total_pedido.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(resul_final));
        if (this.desc_pedido.nativeElement.value === "") {
                                              var cero = await this.atrb.MONTO_0;
                                                      let porcentaje = (monto_exc_nuevo  * parseFloat(await this.util.remplazo_coma_punto(cero))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                                                      let porcentaje1 = (monto_nuevo  * parseFloat(await this.util.remplazo_coma_punto(cero))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                                                      let desc_nuevo = porcentaje1 + porcentaje;
                                                      let xcnt = monto_exc_nuevo - porcentaje;
                                                      let nxcnt = monto_nuevo - porcentaje1;
                                                      let sub_total_nuevo = monto_exc_nuevo + monto_nuevo;
                                                      let total_nuevo = xcnt + nxcnt;
                                                      let iva_nuevo = (monto_nuevo * parseFloat(await this.util.remplazo_coma_punto(nxcnt))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                                                      this.exento_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(xcnt));
                                                      this.subtotal_pedido.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(sub_total_nuevo));
                                                      this.iva_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(iva_nuevo));
                                                      this.desc_pedido_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(desc_nuevo));
                                                      let resul_final = total_nuevo + iva_nuevo;
                                                      this.total_pedido.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(resul_final));
                                            }
                                    }
                                await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_precio_actualizada_manual);
                              }
                            });
              break;
            default:
              if (this.data.cod_lista_precio === response[0].cod_lista_precio_producto) {
                  await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.ultimo_precio_actualizado);
              }else{
                  await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.lista_precio_no_actualizada);
              }
              break;
          }
        }else{
          if (termino === 0) {
              await this.notifi.success(await this.atrb.MENSAJES_FIJOS.ultimo_precio_actualizado);
          }else{
              await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_precio_actualizada);
          }
        }
    } catch (error) {
      console.log(error);
        await this.notifi.customToastNotification(error.error.message);
    }
  }

  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
    }else{
    console.log(this.forma.value);
    this.dialogRefp.close(this.forma.value);
    }
  }

  async consultarPedido(xvalor:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
    var valor = {
      cod_pedido:xvalor,
      cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
    };
    const response = await this.servicio.buscarPedidoProductocodttp(valor).toPromise();
    let img;
    let subtotal_monto;
        this.ELEMENT_DATA = [];
        for (const i in response.resul) {
          img = environment.path_storage+response.resul[i].cod_img;
          if (parseFloat(response.resul[i].desc_prod_monto) <= 0 
          || parseFloat(response.resul[i].desc_prod) <= 0
          || response.resul[i].desc_prod_monto === null || response.resul[i].desc_prod_monto === undefined || response.resul[i].desc_prod_monto === ''
          || response.resul[i].desc_prod === null || response.resul[i].desc_prod === undefined || response.resul[i].desc_prod === '') {
            response.resul[i].desc_prod_monto =  await this.atrb.MONTO_0;
            response.resul[i].desc_prod = await this.atrb.MONTO_0;
          }
          subtotal_monto = (parseFloat(await this.util.remplazo_coma_punto(response.resul[i].costo_producto)) * parseFloat(response.resul[i].cant_producto));
          this.ELEMENT_DATA.push({
            cod_img:img,
            cod_producto:response.resul[i].cod_producto,
            des_producto:response.resul[i].des_producto,
            cod_lista_precio:response.resul2[0].cod_lista_precio,
            cant_producto:response.resul[i].cant_producto,
            costo_producto:response.resul[i].costo_producto,
            tipo_producto:response.resul[i].tipo_producto,
            desc_prod:response.resul[i].desc_prod,
            desc_prod_monto:response.resul[i].desc_prod_monto,
            subtotal_producto: response.resul[i].subtotal_producto,
            costo_base_producto: await this.util.agregar_coma_punto(await this.util.numeroFlotante(subtotal_monto)),
            tamano_producto:response.resul[i].tamano_producto,
            estatus_fiscal:response.resul[i].estatus_fiscal
          });
        }
        this.desc_pedido.nativeElement.value = response.resul2[0].desc_pedido;
        this.exento_monto.nativeElement.value = response.resul2[0].monto_exento;
        this.subtotal_pedido.nativeElement.value = response.resul2[0].subtotal_costo_pedido;
        this.iva_monto.nativeElement.value = response.resul2[0].iva_monto;
        this.desc_pedido_monto.nativeElement.value = response.resul2[0].desc_pedido_monto
        this.total_pedido.nativeElement.value = response.resul2[0].total_costo_pedido;
        this.cod_lista_precio.nativeElement.value = response.resul2[0].cod_lista_precio;
        this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
        this.lista = true;
        await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
    }
  }
  formulario():void{
    /* this.ELEMENT_DATA = []; */
    this.cant = false;
    this.displayedColumns = [
      'cod_img',
      'cod_producto',
      'des_producto',
      'cant_producto',
      'costo_producto',
      'costo_base_producto',
      'descuento',
      'monto_prod_descuento',
      'subtotal_producto',
      'remov_elem'
    ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<PeriodicElementfacturas>(this.ELEMENT_DATA);
  }

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource1.filter = filterValue.trim().toLowerCase();
}
async cantidad_productos() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].cant_producto.toString())
      }
      matriz.forEach (function(numero){
            suma += parseInt(numero.replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace(',','.'));
        });
      return this.cant_prod = await this.util.agregar_coma_punto(await this.util.numeroFlotante(suma));
    } catch (error) {
      console.log(error);
    }
}
async costo_producto() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].costo_producto)
    }
    matriz.forEach (function(numero){
           suma += parseFloat(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.'));
       });
       this.cost_prod = await this.util.agregar_coma_punto(await this.util.numeroFlotante(suma));
  } catch (error) {
    console.log(error);
  }
}
async costo_base_producto() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].costo_base_producto)
    }
    matriz.forEach (function(numero){
           suma += parseFloat(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.'));
       });
       this.cost_base = await this.util.agregar_coma_punto(await this.util.numeroFlotante(suma));
  } catch (error) {
    console.log(error);
  }
}

costo_producto_descuento() {

  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].desc_prod)
    }
    matriz.forEach (function(numero){
           suma += parseFloat(numero) ;
       });

     return suma.toString().replace(/\D/g, "")
                          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  } catch (error) {
    console.log(error);
  }
}

async costo_producto_descuento_monto() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].desc_prod_monto)
    }
    matriz.forEach (function(numero){
           suma += parseFloat(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.')) ;
       });
       this.cost_prod_desc_mont = await this.util.agregar_coma_punto(await this.util.numeroFlotante(suma));
  } catch (error) {
    console.log(error);
  }
}

async sub_total_producto() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].subtotal_producto)
    }
    matriz.forEach (function(numero){
           suma += parseFloat(numero.replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace(',','.'));
       });
       this.sub_total_prod = await this.util.agregar_coma_punto(await this.util.numeroFlotante(suma));
  } catch (error) {
    console.log(error);
  }
}

cantidad_item() {
  try {
    return this.ELEMENT_DATA.length;
  } catch (error) {
    console.log(error);
  }
}

cantidad_bultos_prod() {
  try {
    var canti_bultos_unidad = parseInt(this.cantidad_bultos.nativeElement.value.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.')) * parseInt(this.unidad_manejo.nativeElement.value.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'))
    this.num.nativeElement.value = canti_bultos_unidad.toFixed(0).replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");;
  } catch (error) {
    console.log(error);
  }
}

async  addElement() {
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let xvalor = {
        cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
      };
      let iva_valor = '';
     const parametros =  await this.servicio.listarParametrosOperVentashttp(xvalor).toPromise();
            for (const i in parametros) {
              if (parametros[i].des_definicion.toUpperCase() === await this.atrb.IVA.toUpperCase()) {
                iva_valor = parametros[i].val_def;
                break;
              }
            }
          if (isNaN(parseFloat(iva_valor))) {
            await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_iva);
            return;
          }
          console.log(this.desc_pedido.nativeElement.value);
        if (parseFloat( await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value)) === 0) {
                                                    var resul_final;
                                                    let arry = [];
                                                    let arry2 = [];           
        if (parseInt(await this.util.remplazo_coma_punto(this.num.nativeElement.value)) > 0  &&
            this.producto_pedido.nativeElement.value !== '' && 
            parseInt( await this.util.remplazo_coma_punto(this.cantidad_bultos.nativeElement.value)) > 0  && 
            this.unidad_manejo.nativeElement.value > 0) {
                        var cod = this.forma.value.producto_pedido.split('-');
                        var cod_p = cod[0].trim();                  
                        var valor:any = {
                                        cod_producto:cod_p,
                                        cod_cliente:this.data.cod_cliente,
                                        tipo_cliente:this.data.tipo_cliente,
                                        cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
                                      }
                        const response = await this.servicio.buscarPRODUCTOTttp(valor).toPromise();
                        console.log(response);
                                if (response[1].length > 0) {
                                  let precio_tipo;
                                  let img = environment.path_storage+response[0][0].cod_img;
                                    if (this.data.accion.toLowerCase() === this.atrb.NOTA_ND.toLowerCase()) {
                                      precio_tipo = response[1][0].precio_producto_base;
                                      response[0][0].estatus_fiscal = await this.atrb.XCNT.toUpperCase();
                                    }else{
                                      if (this.data.accion.toLowerCase() === this.atrb.NOTA_NB.toLowerCase()) {
                                        precio_tipo = response[1][0].precio_producto_base_bs;
                                        response[0][0].estatus_fiscal = await this.atrb.XCNT.toUpperCase();
                                      }else{
                                        precio_tipo = response[1][0].precio_producto_base_bs;
                                      }
                                    }
                                    if (parseFloat(await this.util.remplazo_coma_punto(precio_tipo)) <= 0) {
                                                                                          this.stateGroups2 =[];
                                                                                          this.lotepro = [];
                                                                                          this.codigo = [];
                                                                                          this.producto = [];
                                                                                          this.producto_pedido.nativeElement.value = '';
                                                                                            this.num.nativeElement.value = '';
                                                                                            this.cantidad_existencia.nativeElement.value = '';
                                                                                            this.cantidad_bultos.nativeElement.value = '';
                                                                                            this.unidad_manejo.nativeElement.value = '';
                                                                                          this.forma.value.cantidad_pedido = '';
                                                                                          this.desc_prod.nativeElement.value = await this.atrb.MONTO_0;
                                                                                            this.cant = false;
                                                                                            this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                                                                                            .pipe(
                                                                                                              startWith(''),
                                                                                                              map(value => this._filterGroup2(value))
                                                                                                            );
                                                                                              this.stateGroups2= [{
                                                                                                letter: 'Lotes',
                                                                                                names: '',
                                                                                                code: ''
                                                                                              }];
                                      await this.util.cerrarSpinner();
                                      this.listarproductot(this.forma.value.cod_deposito);
                                      await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.prod_no_client);
                                      return;
                                    }                            
                                    this.producto_pedido.nativeElement.value = '';
                                    let subtotal = (parseFloat(await this.util.remplazo_coma_punto(precio_tipo)) 
                                                  * parseInt(await this.util.remplazo_coma_punto(this.num.nativeElement.value)));
                                    let subtotal_monto = subtotal;
                                    var monto_prod_descuento = (parseFloat(await this.util.numeroFlotante(await this.util.remplazo_coma_punto(this.desc_prod.nativeElement.value))) * subtotal)
                                                              / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                                          if (subtotal > monto_prod_descuento) {
                                                  subtotal = subtotal - monto_prod_descuento;
                                              }else{
                                                  subtotal = monto_prod_descuento - subtotal;
                                              }        
                                                if (this.ELEMENT_DATA.length <= 0) {
                                                                                      this.codigo = [];
                                                                                      this.producto = [];
                                                                                      this.stateGroups2 =[];
                                                                                      this.lotepro = [];
                                                                                      this.ELEMENT_DATA.push({
                                                                                        cod_img:img,
                                                                                        cod_producto:response[0][0].cod_producto,
                                                                                        des_producto:response[0][0].des_producto,
                                                                                        cod_lista_precio:response[2][0].cod_lista_precio_producto,
                                                                                        tasa_lista_precio:response[2][0].tasa_precio,
                                                                                        cant_producto:this.num.nativeElement.value,
                                                                                        costo_producto: await this.util.agregar_coma_punto(await this.util.remplazo_coma_punto(precio_tipo)),
                                                                                        costo_base_producto: await this.util.agregar_coma_punto(await this.util.numeroFlotante(subtotal_monto)),
                                                                                        estatus_fiscal:response[0][0].estatus_fiscal,
                                                                                        tipo_producto:response[0][0].tipo_producto,
                                                                                        desc_prod: this.desc_prod.nativeElement.value,
                                                                                        desc_prod_monto: await this.util.agregar_coma_punto(await this.util.numeroFlotante(monto_prod_descuento)),
                                                                                        subtotal_producto: await this.util.agregar_coma_punto(await this.util.numeroFlotante(subtotal)),
                                                                                        lote_producto:response[0][0].lote_producto,
                                                                                        tamano_producto:response[0][0].tamano_producto
                                                                                      });
                                                                                      await this.sub_total_producto();
                                                                                      await this.costo_producto_descuento_monto();
                                                                                      await this.costo_producto();
                                                                                      await this.cantidad_productos();
                                                                                      await this.costo_base_producto();
                                                                                        let porcentaje = (subtotal * parseFloat( await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value))
                                                                                                                / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                                                                                        let monto_sub = subtotal - porcentaje;
                                                                                        this.subtotal_pedido.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(monto_sub));
                                                                                        let total = (monto_sub * parseFloat(await this.util.remplazo_coma_punto(iva_valor))) 
                                                                                                            / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                                                                                        let montoTotal;
                                                                                        if (this.data.accion.toUpperCase() === await this.atrb.POS.toUpperCase() || 
                                                                                            this.data.accion.toUpperCase() === await this.atrb.PRO_FORMA.toUpperCase()) {
                                                                                              this.iva_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(total));
                                                                                              montoTotal = monto_sub + total;
                                                                                        }else{
                                                                                              montoTotal = monto_sub;
                                                                                        }
                                                                                        this.desc_pedido_monto.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(porcentaje));
                                                                                      if (response[0][0].estatus_fiscal.toUpperCase() === await this.atrb.XCNT.toUpperCase() &&
                                                                                          this.data.accion.toUpperCase() === await this.atrb.POS.toUpperCase() || 
                                                                                          response[0][0].estatus_fiscal.toUpperCase() === await this.atrb.XCNT.toUpperCase() &&
                                                                                          this.data.accion.toUpperCase() === await this.atrb.PRO_FORMA.toUpperCase()) {
                                                                                        this.exento_monto.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(monto_sub));
                                                                                        var iva_exento = (monto_sub * parseFloat(await this.util.remplazo_coma_punto(iva_valor))) 
                                                                                                                    / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                                                                                        var iva_menos_exento = total - iva_exento;
                                                                                        this.iva_monto.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(iva_menos_exento));
                                                                                        var resta_iva = montoTotal - iva_exento;
                                                                                        resul_final = resta_iva ;
                                                                                      }else{
                                                                                        resul_final = montoTotal;
                                                                                      }
                                                                                      this.total_pedido.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(resul_final));
                                                                                      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                                                                                      this.producto_pedido.nativeElement.value = '';
                                                                                      this.num.nativeElement.value = '';
                                                                                      this.cantidad_existencia.nativeElement.value = '';
                                                                                      this.cantidad_bultos.nativeElement.value = '';
                                                                                      this.unidad_manejo.nativeElement.value = '';
                                                                                      this.desc_prod.nativeElement.value = await this.atrb.MONTO_0;
                                                                                      this.bandera = true;
                                                                                      this.bandera_2 = true;
                                                                                      this.forma.value.cantidad_pedido = '';
                                                                                      this.cant = false;
                                                                                      this.lote = false;
                                                                                      this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                                                                                            .pipe(
                                                                                                              startWith(''),
                                                                                                              map(value => this._filterGroup2(value))
                                                                                                            );
                                                                                              this.stateGroups2= [{
                                                                                                letter: 'Lotes',
                                                                                                names: '',
                                                                                                code: ''
                                                                                              }];
                                                                                      await this.util.cerrarSpinner();
                                                                                      this.listarproductot(this.forma.value.cod_deposito);
                                                                                    }else{
                                                                                      if (this.ELEMENT_DATA.length <= 45) {
                                                                                        for (const i in this.ELEMENT_DATA) {
                                                                                          arry.push(this.ELEMENT_DATA[i].cod_producto);
                                                                                          arry2.push(this.ELEMENT_DATA[i].lote_producto);
                                                                                        }
                                                                                        let lote = arry2.includes(response[0][0].lote_producto);
                                                                                        let index = arry.includes(response[0][0].cod_producto);
                                                                                        if (!index && !lote || index && !lote || !index && lote) {
                                                                                          this.lotepro = [];
                                                                                          this.stateGroups2 =[];
                                                                                          this.codigo = [];
                                                                                          this.producto = [];
                                                                                            this.ELEMENT_DATA.push({
                                                                                              cod_img:img,
                                                                                              cod_producto:response[0][0].cod_producto,
                                                                                              des_producto:response[0][0].des_producto,
                                                                                              cod_lista_precio:response[2][0].cod_lista_precio_producto,
                                                                                              tasa_lista_precio:response[2][0].tasa_precio,
                                                                                              cant_producto:this.num.nativeElement.value,
                                                                                              costo_producto:await this.util.agregar_coma_punto(await this.util.remplazo_coma_punto(precio_tipo)),
                                                                                              costo_base_producto: await this.util.agregar_coma_punto(await this.util.numeroFlotante(subtotal_monto)),
                                                                                              tipo_producto:response[0][0].tipo_producto,
                                                                                              desc_prod: this.desc_prod.nativeElement.value,
                                                                                              desc_prod_monto: await this.util.agregar_coma_punto( await this.util.numeroFlotante(monto_prod_descuento)),
                                                                                              subtotal_producto: await this.util.agregar_coma_punto( await this.util.numeroFlotante(subtotal)),
                                                                                              estatus_fiscal:response[0][0].estatus_fiscal,
                                                                                              tamano_producto:response[0][0].tamano_producto
                                                                                            });
                                                                                            await this.sub_total_producto();
                                                                                            await this.costo_producto_descuento_monto();
                                                                                            await this.costo_producto();
                                                                                            await this.cantidad_productos();
                                                                                            await this.costo_base_producto();
                                                                                              let porcentaje = (subtotal  * parseFloat( await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value)) 
                                                                                                                          / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                                                                                              let monto_sub = subtotal - porcentaje;
                                                                                              let sub_monto = (parseFloat( await this.util.remplazo_coma_punto(this.subtotal_pedido.nativeElement.value))) + (monto_sub);
                                                                                              this.subtotal_pedido.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(sub_monto));
                                                                                              let total = (sub_monto * parseFloat(await this.util.remplazo_coma_punto(iva_valor)))
                                                                                                                    / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                                                                                              let montoTotal;
                                                                                                if (this.data.accion.toUpperCase() === await this.atrb.POS.toUpperCase() || 
                                                                                                    this.data.accion.toUpperCase() === await this.atrb.PRO_FORMA.toUpperCase()) {
                                                                                                      this.iva_monto.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(total));
                                                                                                      montoTotal = sub_monto + total;
                                                                                                }else{
                                                                                                      montoTotal = sub_monto;
                                                                                                }
                                                                                              let suma_descuentos = (parseFloat( await this.util.remplazo_coma_punto(this.desc_pedido_monto.nativeElement.value)) + porcentaje);
                                                                                              this.desc_pedido_monto.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(suma_descuentos));
                                                                            
                                                                                                    if (response[0][0].estatus_fiscal.toUpperCase() === await this.atrb.XCNT.toUpperCase() &&
                                                                                                        this.data.accion.toUpperCase() === await this.atrb.POS.toUpperCase() || 
                                                                                                        response[0][0].estatus_fiscal.toUpperCase() === await this.atrb.XCNT.toUpperCase() &&
                                                                                                        this.data.accion.toUpperCase() === await this.atrb.PRO_FORMA.toUpperCase()) {
                                                                                                      var sumar_exento = monto_sub + parseFloat(await this.util.remplazo_coma_punto(this.exento_monto.nativeElement.value));
                                                                                                      this.exento_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(sumar_exento));
                                                                                                      var iva_exento =  (sumar_exento * parseFloat(await this.util.remplazo_coma_punto(iva_valor))) 
                                                                                                                                      / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                                                                                                      var iva_menos_exento = total - iva_exento;
                                                                                                      this.iva_monto.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(iva_menos_exento));
                                                                                                      let montoTotal_especial = (sub_monto + total);
                                                                                                      var resta_iva = (montoTotal - iva_exento);
                                                                                                      resul_final = resta_iva;
                                                                                                    }else{
                                                                                                      if (parseFloat( await this.util.remplazo_coma_punto(this.exento_monto.nativeElement.value)) > 0) {
                                                                                                        var iva_exento_restar = (parseFloat( await this.util.remplazo_coma_punto(this.exento_monto.nativeElement.value))
                                                                                                                                  * parseFloat(await this.util.remplazo_coma_punto(iva_valor))) 
                                                                                                                                  / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                                                                                                        var iva_exento_restar_format = await this.util.agregar_coma_punto( await this.util.numeroFlotante(iva_exento_restar));
                                                                                                        var iva_menos_exento_restar = (total - iva_exento_restar);
                                                                                                        this.iva_monto.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(iva_menos_exento_restar));
                                                                                                        let montoTotal_especial = (sub_monto + iva_menos_exento_restar);
                                                                                                        var monto_especial_format =  await this.util.numeroFlotante(montoTotal_especial);
                                                                                                        var resta_iva_restar = (montoTotal_especial - iva_exento_restar);
                                                                                                        resul_final = montoTotal_especial;
                                                                                                      }else{
                                                                                                        resul_final = montoTotal ;
                                                                                                      }
                                                                                                    }
                                                                                            this.total_pedido.nativeElement.value = await this.util.agregar_coma_punto( await this.util.numeroFlotante(resul_final));
                                                                                            this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                                                                                            this.producto_pedido.nativeElement.value = '';
                                                                                            this.num.nativeElement.value = '';
                                                                                            this.cantidad_existencia.nativeElement.value = '';
                                                                                            this.cantidad_bultos.nativeElement.value = '';
                                                                                            this.unidad_manejo.nativeElement.value = '';
                                                                                            this.desc_prod.nativeElement.value = await this.atrb.MONTO_0;
                                                                                            this.bandera = true;
                                                                                            this.bandera_2 = true;
                                                                                            this.forma.value.cantidad_pedido = '';
                                                                                            this.cant = false;
                                                                                            this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                                                                                            .pipe(
                                                                                                              startWith(''),
                                                                                                              map(value => this._filterGroup2(value))
                                                                                                            );
                                                                            
                                                                                              this.stateGroups2= [{
                                                                                                letter: 'Lotes',
                                                                                                names: '',
                                                                                                code: ''
                                                                                              }];
                                                                                              await this.util.cerrarSpinner();
                                                                                        }else{
                                                                                          this.stateGroups2 =[];
                                                                                          this.lotepro = [];
                                                                                          this.codigo = [];
                                                                                          this.producto = [];
                                                                                          this.producto_pedido.nativeElement.value = '';
                                                                                          this.num.nativeElement.value = '';
                                                                                          this.cantidad_existencia.nativeElement.value = '';
                                                                                          this.cantidad_bultos.nativeElement.value = '';
                                                                                          this.unidad_manejo.nativeElement.value = '';
                                                                                          this.forma.value.cantidad_pedido = '';
                                                                                          this.desc_prod.nativeElement.value = await this.atrb.MONTO_0;
                                                                                            this.cant = false;
                                                                                            this.stateGroupOptions2 = this.forma.get('lote_producto')!.valueChanges
                                                                                                            .pipe(
                                                                                                              startWith(''),
                                                                                                              map(value => this._filterGroup2(value))
                                                                                                            );
                                                                                              this.stateGroups2= [{
                                                                                                letter: 'Lotes',
                                                                                                names: '',
                                                                                                code: ''
                                                                                              }];
                                                                                              await this.util.cerrarSpinner();
                                                                                              this.listarproductot(this.forma.value.cod_deposito);
                                                                                              await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.prod_select_ya);
                                                                                        }
                                                                                      }else{
                                                                                        await this.util.cerrarSpinner();
                                                                                        this.listarproductot(this.forma.value.cod_deposito);
                                                                                          await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.solo_45);
                                                                                      }
                                                                                    }
                                                            }else{
                                                                                      this.stateGroups2 =[];
                                                                                          this.lotepro = [];
                                                                                          this.codigo = [];
                                                                                          this.producto = [];
                                                                                          this.producto_pedido.nativeElement.value = '';
                                                                                            this.num.nativeElement.value = '';
                                                                                            this.cantidad_existencia.nativeElement.value = '';
                                                                                            this.cantidad_bultos.nativeElement.value = '';
                                                                                            this.unidad_manejo.nativeElement.value = '';
                                                                                          this.forma.value.cantidad_pedido = '';
                                                                                          this.desc_prod.nativeElement.value = await this.atrb.MONTO_0;
                                                                                            this.cant = false;
                                                                              await this.util.cerrarSpinner();
                                                                              this.listarproductot(this.forma.value.cod_deposito);
                                                                              await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.prod_no_client);
                                                                }
                                                    }else{
                                                      if (parseInt(this.num.nativeElement.value) <= 0 || isNaN(this.num.nativeElement.value) || this.num.nativeElement.value === "") {
                                                        await this.util.cerrarSpinner();
                                                          await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.prod_mayor_0);
                                                      }
                                                      if (parseInt(this.unidad_manejo.nativeElement.value) <= 0 || isNaN(this.unidad_manejo.nativeElement.value) || this.unidad_manejo.nativeElement.value === "") {
                                                        await this.util.cerrarSpinner();
                                                          await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.no_um);
                                                      }
                                                      if (parseInt(this.cantidad_bultos.nativeElement.value) <= 0 || isNaN(this.cantidad_bultos.nativeElement.value) || this.cantidad_bultos.nativeElement.value === "") {
                                                        await this.util.cerrarSpinner();
                                                          await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.unid_solicitar);
                                                      }
                                                    if (this.producto_pedido.nativeElement.value === '') {
                                                      await this.util.cerrarSpinner();
                                                        await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.prod_solicitar);
                                                    }
                                                    }
        }else{
          await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.descuento_0);
        }
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
    }
  }

async remove(val:any){
    try {
      if (parseFloat(await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value)) === 0) { 
                  var resta_iva;
                  var iva_menos_exento;
                  var iva_exento;
                  var resul_final2;
                  let resta = this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
                  this.cod_lista1.nativeElement.value = '';
                  var monto_nuevo = 0;
                  var monto_exc_nuevo = 0;
                  for (const i in this.ELEMENT_DATA) {
                  if (this.ELEMENT_DATA[i].estatus_fiscal.toUpperCase() === await this.atrb.XCNT.toUpperCase()) {
                      monto_exc_nuevo = monto_exc_nuevo + parseFloat( await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].subtotal_producto));
                      }else{
                      monto_nuevo = monto_nuevo + parseFloat( await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].subtotal_producto));
                      }
                    }
                    let porcentaje = (monto_exc_nuevo  * parseFloat( await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                    let porcentaje1 = (monto_nuevo  * parseFloat( await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                    let desc_nuevo = porcentaje1 + porcentaje;
                    let xcnt = monto_exc_nuevo - porcentaje;
                    let nxcnt = monto_nuevo - porcentaje1;
                    let sub_total_nuevo = xcnt + nxcnt;
                    let iva_nuevo = nxcnt * parseFloat(await this.util.remplazo_coma_punto(this.iva.nativeElement.value)) / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                    this.exento_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(xcnt));
                    this.subtotal_pedido.nativeElement.value =  await this.util.agregar_coma_punto(await this.util.numeroFlotante(sub_total_nuevo));
                    this.iva_monto.nativeElement.value =  await this.util.agregar_coma_punto( await this.util.numeroFlotante(iva_nuevo));
                    this.desc_pedido_monto.nativeElement.value =  await this.util.agregar_coma_punto( await this.util.numeroFlotante(desc_nuevo));
                    let resul_final = sub_total_nuevo + iva_nuevo;
                    this.total_pedido.nativeElement.value =  await this.util.agregar_coma_punto( await this.util.numeroFlotante(resul_final));
                  if (this.desc_pedido.nativeElement.value === "") {
                    var cero = await this.atrb.MONTO_0;
                    let porcentaje = (monto_exc_nuevo  * parseFloat(await this.util.remplazo_coma_punto(cero))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                    let porcentaje1 = (monto_nuevo  * parseFloat(await this.util.remplazo_coma_punto(cero))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                    let desc_nuevo = porcentaje1 + porcentaje;
                    let xcnt = monto_exc_nuevo - porcentaje;
                    let nxcnt = monto_nuevo - porcentaje1;
                    let sub_total_nuevo = xcnt + nxcnt;
                    let iva_nuevo = nxcnt * parseFloat(this.iva.nativeElement.value) / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                    this.exento_monto.nativeElement.value =  await this.util.agregar_coma_punto(await this.util.numeroFlotante(xcnt));
                    this.subtotal_pedido.nativeElement.value =  await this.util.agregar_coma_punto(await this.util.numeroFlotante(sub_total_nuevo));
                    this.iva_monto.nativeElement.value =  await this.util.agregar_coma_punto(await this.util.numeroFlotante(iva_nuevo));
                    this.desc_pedido_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(desc_nuevo));
                    let resul_final = sub_total_nuevo + iva_nuevo;
                    this.total_pedido.nativeElement.value =  await this.util.agregar_coma_punto(await this.util.numeroFlotante(resul_final));
                  }
                  await this.sub_total_producto();
                  await this.costo_producto_descuento_monto();
                  await this.costo_producto();
                  await this.cantidad_productos();
                  await this.costo_base_producto();
                  this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                  this.table.renderRows();
      }else{
        await this.util.notificacionErrorApp(this.atrb.MENSAJES_FIJOS.descuento_0);
      }
    } catch (error) {
      console.log(error);
    } 
  }
  buscarproductot(xvalor:any){
    console.log(xvalor);
    var valor:any = {valor:xvalor}
    this.servicio.buscarPRODUCTOTparamhttp(valor).subscribe(
      response =>{
        this.producto = response;
      },
      error =>{
        console.log(<any>error.error.message);
      }
    );
  }

async modalrespuesta(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
          if (this.ELEMENT_DATA.length === 0) {
            await this.util.cerrarSpinner();
              await this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.sin_prod);
              return;
          }
            await this.util.cerrarSpinner();
            let accion = "";
            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
                    maxWidth: '100vw',
                    height:'auto',
              data: {id:'',
                    accion:accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(async result => {
              await this.util.abrirSpinner();
              if(result != 'cerrar'){
                await this.util.cerrarSpinner();
                switch (this.data.cod_pedido) {
                  case '':
                          this.forma.patchValue({
                            cod_vendedor:await this.atrb.COD_USUARIO.cod_usuario,
                            producto_pedido:this.ELEMENT_DATA,
                            iva_monto:this.iva_monto.nativeElement.value,
                            desc_pedido:this.desc_pedido.nativeElement.value,
                            subtotal_pedido:this.subtotal_pedido.nativeElement.value,
                            total_pedido:this.total_pedido.nativeElement.value,
                            cod_lista_precio:this.ELEMENT_DATA[0].cod_lista_precio,
                            tasa_precio:this.ELEMENT_DATA[0].tasa_lista_precio,
                            desc_pedido_monto:this.desc_pedido_monto.nativeElement.value,
                            monto_exento:this.exento_monto.nativeElement.value,
                            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
                          });
                        this.dialogRefp.close(this.forma.value);
                    break;
                  default:
                          this.forma.patchValue({
                            cod_pedido:this.data.cod_pedido,
                            cod_vendedor:await this.atrb.COD_USUARIO.cod_usuario,
                            producto_pedido:this.ELEMENT_DATA,
                            iva_monto:this.iva_monto.nativeElement.value,
                            desc_pedido:this.desc_pedido.nativeElement.value,
                            subtotal_pedido:this.subtotal_pedido.nativeElement.value,
                            total_pedido:this.total_pedido.nativeElement.value,
                            cod_lista_precio:this.ELEMENT_DATA[0].cod_lista_precio,
                            tasa_precio:this.ELEMENT_DATA[0].tasa_lista_precio,
                            desc_pedido_monto:this.desc_pedido_monto.nativeElement.value,
                            monto_exento:this.exento_monto.nativeElement.value,
                            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
                          });
                        this.dialogRefp.close(this.forma.value);
                    break;
                 }
                }else{
                  await this.util.cerrarSpinner();
                }
            });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }

  async cerrarmodal(){
    try {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRefp.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
    }
    }

  async porcentaje(valor:any)
  {
    try {
      if(!isNaN(parseFloat(await this.util.remplazo_coma_punto(valor.value)))){
              var monto_nuevo = 0;
              var monto_exc_nuevo = 0;
            for (const i in this.ELEMENT_DATA) {
                if (this.ELEMENT_DATA[i].estatus_fiscal.toUpperCase() === await this.atrb.XCNT.toUpperCase()) {
                  monto_exc_nuevo = monto_exc_nuevo + parseFloat(await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].subtotal_producto));
                }else{
                  monto_nuevo = monto_nuevo + parseFloat(await this.util.remplazo_coma_punto(this.ELEMENT_DATA[i].subtotal_producto));
                }
            }
            if (this.desc_pedido.nativeElement.value === "") {
              var cero = await this.atrb.MONTO_0;
                      let porcentaje = (monto_exc_nuevo  * parseFloat(await this.util.remplazo_coma_punto(cero))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                      let porcentaje1 = (monto_nuevo  * parseFloat(await this.util.remplazo_coma_punto(cero))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
                      let desc_nuevo = porcentaje1 + porcentaje;
                      let xcnt = monto_exc_nuevo - porcentaje;
                      let nxcnt = monto_nuevo - porcentaje1;
                      let sub_total_nuevo = xcnt + nxcnt;
                      let iva_nuevo = (nxcnt * parseFloat( await this.util.remplazo_coma_punto(this.iva.nativeElement.value))) / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
                      this.exento_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(xcnt));
                      this.subtotal_pedido.nativeElement.value =  await this.util.agregar_coma_punto(await this.util.numeroFlotante(sub_total_nuevo));
                      this.iva_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(iva_nuevo));
                      this.desc_pedido_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(desc_nuevo));
                      let resul_final = sub_total_nuevo + iva_nuevo;
                      this.total_pedido.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(resul_final));
            }else{
              let porcentaje = (monto_exc_nuevo  * parseFloat(await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
              let porcentaje1 = (monto_nuevo  * parseFloat(await this.util.remplazo_coma_punto(this.desc_pedido.nativeElement.value))/parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO)));
              let desc_nuevo = porcentaje1 + porcentaje;
              console.log(desc_nuevo);
              let xcnt = monto_exc_nuevo - porcentaje;
              let nxcnt = monto_nuevo - porcentaje1;
              let sub_total_nuevo = xcnt + nxcnt;
              let iva_nuevo = nxcnt * parseFloat(await this.util.remplazo_coma_punto(this.iva.nativeElement.value)) / parseFloat(await this.util.remplazo_coma_punto(this.atrb.CIEN_PORCIENTO));
              this.exento_monto.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(monto_exc_nuevo));
              this.iva_monto.nativeElement.value =  await this.util.agregar_coma_punto(await this.util.numeroFlotante(iva_nuevo));
              this.desc_pedido_monto.nativeElement.value =  await this.util.agregar_coma_punto(await this.util.numeroFlotante(desc_nuevo));
              let resul_final = sub_total_nuevo + iva_nuevo;
              this.total_pedido.nativeElement.value = await this.util.agregar_coma_punto(await this.util.numeroFlotante(resul_final));
            }
  }else{ 
    await this.util.notificacionErrorApp(await this.atrb.MENSAJES_FIJOS.solo_numeros);
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      console.log(error);
    }
  }

  async format(event: any, valor:any, termino:any)
  {
    try {
      var num;
      if (termino.toUpperCase() !== this.atrb.BASE.toUpperCase() &&
          termino.toUpperCase() !== this.atrb.TASA.toUpperCase() &&
          termino.toUpperCase() !== this.atrb.DESCUENTO.toUpperCase()
          ) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
      if (termino.toUpperCase() === this.atrb.BASE.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        num = inputId.value.replace(/\D/g,'');
        if(!isNaN(parseFloat(num))){
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/\D/g, "")
                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          inputId.value = num;
          }else{ 
            if(await this.atrb.NUM.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
              await this.util.notificacionErrorApp(this.atrb.MENSAJES_FIJOS.solo_numeros);
          }
            inputId.value = inputId.value.replace(/[^\d\.]*/g,'');
          }
      }
      if (termino.toUpperCase() === this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
      if (termino.toUpperCase() === this.atrb.DESCUENTO.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
         await this.porcentaje(inputId, );
      }
    } catch (error) {
      console.log(error);
    }
  }

}
