
import * as _ from 'lodash';
import * as XLSX from 'ts-xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

//NG-OFFLINE

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from'firebase';
import { FileItem } from '../models/file-item';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { Observable, fromEvent, merge, empty, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { mapTo } from 'rxjs/operators';
import { ConnectionServices } from './app.connection.services';

import { environment } from '../../../../environments/environment';
import { AppComponent } from 'src/app/app.component';
import { FormVersionComponent } from '../modal/form-version/form-version.component';
import { MatDialog } from '@angular/material/dialog';
import { AppUtilService } from './app.util.service';
import { HashKeyServices } from './app.hashkey.services';
import { AtributosService } from './atributos.service';
import { ToastNotificationService } from './toast-notification.service';

type Usuario = {cod_usuario: string, des_usuario: string}

@Injectable({
    providedIn: 'root'
})
export class nkServices {
 private ruta:any;
  private CARPETA_IMAGENES = 'img';
 
private acciones_accesos:any=environment.path_prod+'validar_acceso_acciones_http';
private url_conexion:any=environment.path_prod+'conexionOn';
 private url:any=environment.path_prod+'listardatos';
 private url2:any=environment.path_prod+'guardar';
 private url3:any=environment.path_prod+'borrarresgistros';
 private url4:any=environment.path_prod+'gestionarregistros';
 private url5:any=environment.path_prod+'enviocorreo';
 private url6:any=environment.path_prod+'listarporparametros';
 private url7:any=environment.path_prod+'guadarimg';
 private url8:any=environment.path_prod+'guardardatosmasivo';
 private url9:any=environment.path_prod+'listarimg';
 private url10:any=environment.path_prod+'mostrarplantilla';
 private url11:any=environment.path_prod+'ingresoUsuario';
 private url12:any=environment.path_prod+'listarUsuarios';
 private url13:any=environment.path_prod+'eliminarUsuarios';
 private url14:any=environment.path_prod+'gestionmenu';
 private url15:any=environment.path_prod+'listarMenu';
 private url16:any=environment.path_prod+'eliminarmenu';
 private url17:any=environment.path_prod+'listarRoles';
 private url18:any=environment.path_prod+'gestionroles';
 private url19:any=environment.path_prod+'listarRoleMenu';
 private url20:any=environment.path_prod+'listarUsuariorol';
 private url21:any=environment.path_prod+'eliminarrol';
 private url22:any=environment.path_prod+'gestionrolemenu';
 private url23:any=environment.path_prod+'eliminarolmenu';
 private url24:any=environment.path_prod+'buscarroleMenu';
 private url25:any=environment.path_prod+'gestionusuariorol';
 private url26:any=environment.path_prod+'buscarMenu';
 private url27:any=environment.path_prod+'login';
 private url28:any=environment.path_prod+'buscarUsuariorol';
 private url29:any=environment.path_prod+'buscarMenudir';
 private url30:any=environment.path_prod+'buscarRolesubMenu';
 private url31:any=environment.path_prod+'eliminarusuariorol';
 private url32:any=environment.path_prod+'buscarusuariorolparam';
 private url33:any=environment.path_prod+'buscarUsuariorolsubmenuhijo';
 private url34:any=environment.path_prod+'listarUnidades';
 private url35:any=environment.path_prod+'eliminarUnidades';
 private url36:any=environment.path_prod+'gestionunidades';
 private url37:any=environment.path_prod+'buscarunidadesparam';
 private url38:any=environment.path_prod+'buscarmenuparam';
 private url39:any=environment.path_prod+'listarFacturas';
 private url40:any=environment.path_prod+'listarClientes';
 private url41:any=environment.path_prod+'gestionclientes';
 private url42:any=environment.path_prod+'eliminarclientes';
 private url43:any=environment.path_prod+'buscarclienteparam';
 private url44:any=environment.path_prod+'listarProductoterm';
 private url45:any=environment.path_prod+'buscarproductotparam';
 private url46:any=environment.path_prod+'buscarProductot';
 private url47:any=environment.path_prod+'buscarLoteProductot';
 private url48:any=environment.path_prod+'gestionpedido';
 private url49:any=environment.path_prod+'buscarProductotcod';
 private url50:any=environment.path_prod+'listarPedidos';
 private url51:any=environment.path_prod+'buscarPedidosF';
 private url52:any=environment.path_prod+'listarProductoPedido';
 private url53:any=environment.path_prod+'gestionfacturas';
 private url54:any=environment.path_prod+'listarProdPedido'
 private url55:any=environment.path_prod+'buscarPedidos';
 private url56:any=environment.path_prod+'listarlprecios';
 private url57:any=environment.path_prod+'buscarProductocod';
 private url58:any=environment.path_prod+'gestionprecios';
 private url59:any=environment.path_prod+'listarProductoLprecio';
 private url60:any=environment.path_prod+'listarClienteEspecial';
 private url61:any=environment.path_prod+'listarProductoTerminado';
 private url62:any=environment.path_prod+'listarProductos';
 private url63:any=environment.path_prod+'gestionMateriaPrima';
 private url64:any=environment.path_prod+'listarMateriaPrima';
 private url65:any=environment.path_prod+'listarProv';
 private url66:any=environment.path_prod+'listarinventariopd';
 private url67:any=environment.path_prod+'listarinventariomp';
 private url68:any=environment.path_prod+'gestioninvpd';
 private url69:any=environment.path_prod+'gestioninventariomp';
 private url70:any=environment.path_prod+'listarOrdenesProd';
 private url71:any=environment.path_prod+'buscarProductocod2';
 private url72:any=environment.path_prod+'gestionOrdenProd';
 private url73:any=environment.path_prod+'buscarProductoOrdenesProd';
 private url74:any=environment.path_prod+'buscarOrdenProd';
 private url75:any=environment.path_prod+'buscarProductocodmp';
 private url76:any=environment.path_prod+'gestionFormula';
 private url77:any=environment.path_prod+'buscarFormula';
 private url78:any=environment.path_prod+'gestionprov';
 private url79:any=environment.path_prod+'gestionProdPt';
 private url80:any=environment.path_prod+'buscarLprecios';
 private url81:any=environment.path_prod+'listarMateriaPrimaPreparada';
 private url82:any=environment.path_prod+'gestionMateriaPrimaPreparada';
 private url83:any=environment.path_prod+'buscarFormulaprod';
 private url84:any=environment.path_prod+'gestionOrdenLab';
 private url85:any=environment.path_prod+'listarSegOrdenesProd';
 private url86:any=environment.path_prod+'gestionFormulaMpp';
 private url87:any=environment.path_prod+'buscarFormulaMpp';
 private url88:any=environment.path_prod+'listarMateriaPrima2';
 private url89:any=environment.path_prod+'buscarProductosPedidos';
 private url90:any=environment.path_prod+'buscarLpreciosRelacion';
 private url91:any=environment.path_prod+'listarPedidosParam';
 private url92:any=environment.path_prod+'buscarProductoPedido';
 private url93:any=environment.path_prod+'buscarClientePedidoTipo';
 private url94:any=environment.path_prod+'guardardatosmasivoProductoFactory';
 private url95:any=environment.path_prod+'guardardatosmasivoProductoFactoryMP';
 private url96:any=environment.path_prod+'buscarProductoOrdenesProdMpp2';
 private url97:any=environment.path_prod+'buscarProductocod3';
 private url98:any=environment.path_prod+'gestionOrdenesMppVsFabricacion';
 private url99:any=environment.path_prod+'buscarProductoOrdenesProd2';
 private url100:any=environment.path_prod+'gestionOrdenesVsFabricacion';
 private url101:any=environment.path_prod+'listarInventarioSG';
 private url102:any=environment.path_prod+'listarInventarioSPL';
 private url103:any=environment.path_prod+'buscarInventarioSPL';
 private url104:any=environment.path_prod+'buscarProductotcodDivisa';
 private url105:any=environment.path_prod+'guardardatosmasivoClientesFactory';
 private url106:any=environment.path_prod+'listarListaPrecioSPCL';
 private url107:any=environment.path_prod+'buscarProductotcodBolivaressinIvan';
 private url108:any=environment.path_prod+'listarClientesActivo';
 private url109:any=environment.path_prod+'ConsultarCodControlFact';
 private url110:any=environment.path_prod+'buscaHasKey';
 private url111:any=environment.path_prod+'buscarProdInvSG';
 private url112:any=environment.path_prod+'ajustar_almacen_productos_terminados';
 private url113:any=environment.path_prod+'gestionTraslados_PT_SG';
 private url114:any=environment.path_prod+'buscarProdInvPT';
 private url115:any=environment.path_prod+'ajustar_almacen_productos_terminados_spl';
 private url116:any=environment.path_prod+'buscardatosOredenProd';
 private url117:any=environment.path_prod+'ajustardatosOredenProd';
 private url118:any=environment.path_prod+'buscarProductoOrdenesProdPt';
 private url119:any=environment.path_prod+'buscarOrdenProdPt';
 private url120:any=environment.path_prod+'gestionconsignaciones';
 private url121:any=environment.path_prod+'BuscarClientes';
 private url122:any=environment.path_prod+'listarConsignaciones';
 private url123:any=environment.path_prod+'buscarProductoConsignacion';
 private url124:any=environment.path_prod+'confirmarConsignaciones';
 private url125:any=environment.path_prod+'listarGuiaDespachoConsignacion';
 private url126:any=environment.path_prod+'listarConsignacionesC';
 private url127:any=environment.path_prod+'buscarProdConsig';
 private url128:any=environment.path_prod+'listarGuiaDespachoC';
 private url129:any=environment.path_prod+'ConsultarGuiaDespachoC';
 private url130:any=environment.path_prod+'gestionOrdenesVsRevision';
 private url131:any=environment.path_prod+'ConsultarCodControlNot';
 private url132:any=environment.path_prod+'gestionNotaCredito';
 private url133:any=environment.path_prod+'buscarContadorFacturaNotaCredito';
 private url134:any=environment.path_prod+'listarNotaCredito';
 private url135:any=environment.path_prod+'listarProdMpEntrada';
 private url136:any=environment.path_prod+'buscarProdMpEntrada';
 private url137:any=environment.path_prod+'guardardatosmasivoProveedoresFactory';
 private url138:any=environment.path_prod+'listarCuaentenaProdTerminadoLote';
 private url139:any=environment.path_prod+'liberarProdTerminado';
 private url140:any=environment.path_prod+'listarLogs';
 private url141:any=environment.path_prod+'ajustar_almacen_materi_prima';
 private url142:any=environment.path_prod+'listarNotasDeCredito';
 private url143:any=environment.path_prod+'listarAlmacenesEspeciales';
 private url144:any=environment.path_prod+'gestionNotasEntrega';
 private url145:any=environment.path_prod+'listarProdPedido_para_notas';
 private url146:any=environment.path_prod+'listarNotasDeEntrega';
 private url147:any=environment.path_prod+'listarCuaentenaProdDevoluciones';
 private url148:any=environment.path_prod+'listarSecuenciaLotes';
 private url149:any=environment.path_prod+'listarNotaDeDebito';
 private url150:any=environment.path_prod+'listarClientesNotaDebito';
 private url151:any=environment.path_prod+'gestionNotaDeDebito';
 private url152:any=environment.path_prod+'listarNotaDebito';
 private url153:any=environment.path_prod+'listarTareasSoporte';
 private url154:any=environment.path_prod+'gestionTareasSoporte';
 private url155:any=environment.path_prod+'listarTipoTareas';
 private url156:any=environment.path_prod+'listarSolicitudesSoporte';
 private url157:any=environment.path_prod+'buscarTareaHija';
 private url158:any=environment.path_prod+'gestionSolicitudTareas';
 private url159:any=environment.path_prod+'listarSolicitudesSoporteAdmin';
 private url160:any=environment.path_prod+'gestionTomarSolicitud';
 private url161:any=environment.path_prod+'gestionSolicitudSoporteFinalizar';
 private url162:any=environment.path_prod+'gestionSolicitudEvaluar';
 private url163:any=environment.path_prod+'listarTicketSoporteAdmin';
 private url164:any=environment.path_prod+'buscarComentarioSoporte';
 private url165:any=environment.path_prod+'gestionActualizarComentarios';
 private url166:any=environment.path_prod+'buscarInvPorRangoFecha';
 private url167:any=environment.path_prod+'buscarProductosVentasPorFecha';
 private url168:any=environment.path_prod+'gestionNotaDeCreditoSingle';
 private url169:any=environment.path_prod+'listarNotasAdminSingle';
 private url170:any=environment.path_prod+'buscarPermisosUsuario';
 private url171:any=environment.path_prod+'listar_menu_acciones_app';
 private url172:any=environment.path_prod+'buscarPermisosAcciones';
 private url173:any=environment.path_prod+'gestionPermisoAcciones';
 private url174:any=environment.path_prod+'consultar_sesion';
 private url175:any=environment.path_prod+'cerrar_sesion';
 private url176:any=environment.path_prod+'listar_submenu_acciones';
 private url177:any=environment.path_prod+'buscar_Acciones_menu';
 private url178:any=environment.path_prod+'listar_sesiones_activas';
 private url179:any=environment.path_prod+'listarHistorialOrdenes';
 private url180:any=environment.path_prod+'listarFormulasHistorial';
 private url181:any=environment.path_prod+'activarFormulas';
 private url182:any=environment.path_prod+'cambiarEstatusOrdenProdV';
 private url183:any=environment.path_prod+'listarTiempoOrdenProd';
 private url184:any=environment.path_prod+'cambiarEstatusOrdenProdMppV';
 private url185:any=environment.path_prod+'listarTiempoOrdenProdMpp';
 private url186:any=environment.path_prod+'cambiarEstatusOrdenProdV_P';
 private url187:any=environment.path_prod+'listarDefinicionesSistema';
 private url188:any=environment.path_prod+'gestionDefinicionesSistema';
 private url189:any=environment.path_prod+'listarConceptosDeiniciones';
 private url190:any=environment.path_prod+'gestionDefinicionesConceptosSistema';
 private url191:any=environment.path_prod+'listarDepositos';
 private url192:any=environment.path_prod+'crearCodigo';
 private url193:any=environment.path_prod+'listarDefinicionesConceptos';
 private url194:any=environment.path_prod+'gestionDepositos';
 private url195:any=environment.path_prod+'buscarProductoDeposito';
 private url196:any=environment.path_prod+'ajustar_deposito_productos';
 private url197:any=environment.path_prod+'buscarProductoMovimientos';
 private url198:any=environment.path_prod+'listarAlmacenesPt';
 private url199:any=environment.path_prod+'buscarDespositoFormula';
 private url200:any=environment.path_prod+'buscarFormulaprod_confirmar';
 private url201:any=environment.path_prod+'validarInventarioProdFormula';
 private url202:any=environment.path_prod+'buscarDespositoFormulaMpp';
 private url203:any=environment.path_prod+'buscarFormulaprodMpp_confirmar';
 private url204:any=environment.path_prod+'validarInventarioProdFormulaMpp';
 private url205:any=environment.path_prod+'buscardefinicionesprod';
 private url206:any=environment.path_prod+'buscarDataProductoUmCant';
 private url207:any=environment.path_prod+'listarValorProducto';
 private url208:any=environment.path_prod+'procesarTraslados';
 private url209:any=environment.path_prod+'consultarDevFactura';
 private url210:any=environment.path_prod+'buscarProductoPedidoGuia';

 private url300:any=environment.path_prod+'listarOrdenesProdMpp';
 private url301:any=environment.path_prod+'buscarProductoMpp';
 private url302:any=environment.path_prod+'gestionOrdenProdMpp';
 private url303:any=environment.path_prod+'buscarProductoOrdenesProdMpp';
 private url304:any=environment.path_prod+'buscarFormulaProdMpp';
 private url305:any=environment.path_prod+'gestionOrdenLabMpp';
 private url306:any=environment.path_prod+'listarSegOrdenesProdMpp';
 private url307:any=environment.path_prod+'listarInventariolt';
 private url308:any=environment.path_prod+'listarGuiaDespacho';
 private url309:any=environment.path_prod+'listarGuiaDespachoFactura';
 private url310:any=environment.path_prod+'buscarProductoOrdenesProdMppConfirm';
 private url311:any=environment.path_prod+'listarComedorRangoFecha';

 private url312:any=environment.path_prod+'datosPersonalesEmpleados';
 private url313:any=environment.path_prod+'listarEmpleados';
 private url314:any=environment.path_prod+'listarAsistenciaRangoFecha';
 private url315:any=environment.path_prod+'reporteEmpleadosAsistencia';
 private url316:any=environment.path_prod+'reporteGeneralEmpleadosAsistencia';
 private url317:any=environment.path_prod+'gestionarDevoluciones';
 private url318:any=environment.path_prod+'listarSolicitudDevolucion';
 private url319:any=environment.path_prod+'mostrarProductosDevoluciones';
 private url320:any=environment.path_prod+'actualizacionRelocalizacionDevProductos';
 private url321:any=environment.path_prod+'mostrarProductosDevueltos';
 private url322:any=environment.path_prod+'mostrarFormulaProductoDevoluciones';
 private url323:any=environment.path_prod+'devolucionNotasCredito';
 private url324:any=environment.path_prod+'listarSolicitudDevolucionAceptada';
 private url325:any=environment.path_prod+'devolverProductosFormulas';
 private url326:any=environment.path_prod+'devolverProductosCompletos';
 private url327:any=environment.path_prod+'listarSolicitudDevolucionAceptadaRCNDCNMNT';
 private url328:any=environment.path_prod+'listarSolicitudDevolucionAceptadaDVLCN';
 private url329:any=environment.path_prod+'gestionNotaDeCreditoNotaEntrega';
 private url330:any=environment.path_prod+'validar_lotes_dev';
 private url331:any=environment.path_prod+'anular_documento_ventas';
 private url332:any=environment.path_prod+'listarValorLotes';
 private url333:any=environment.path_prod+'buscarDatosLote';

 private url334:any=environment.path_prod+'reporteFacturas';
 private url335:any=environment.path_prod+'reporteNotas';
 private url336:any=environment.path_prod+'reporteNotasCreditos'
 private url337:any=environment.path_prod+'reporteNotasDebito'
 private url338:any=environment.path_prod+'reporteGeneralFNENDNC'
 private url339:any=environment.path_prod+'buscarUnidadesPorFacturasNotas'
 private url340:any=environment.path_prod+'movimientoInventarioResumen'
 private url341:any=environment.path_prod+'listarClienteConsig'
 private url342:any=environment.path_prod+'reporteConsignaciones'
 private url343:any=environment.path_prod+'gestionarMontoDaily';
 private url344:any=environment.path_prod+'reporteFactDaily';

 private url345:any=environment.path_prod+'crearCotizacionOrden';
 private url346:any=environment.path_prod+'listarCotizaciones';
 private url347:any=environment.path_prod+'aprobarCotizacion';
 private url348:any=environment.path_prod+'listaProdCotizar';
 private url349:any=environment.path_prod+'generarCotizacionOrden';
 private url350:any=environment.path_prod+'listarProductosCotizacion';
 private url351:any=environment.path_prod+'listarCotizacionesNuevas';
 private url352:any=environment.path_prod+'listarCotizacionesAprobadas';
 private url353:any=environment.path_prod+'listarPorductosXProveedor';
 private url354:any=environment.path_prod+'listarProveedores';
 private url355:any=environment.path_prod+'listaInfoPaisesCiudadEstadosVznl';
 private url356:any=environment.path_prod+'listarBancosVznl';
 private url357:any=environment.path_prod+'listarTipoProductos';
 private url358:any=environment.path_prod+'comprobarProveedor';
 private url359:any=environment.path_prod+'crearOactuzalizarProveedor';
 private url360:any=environment.path_prod+'crearOrdenDCompra';
 private url361:any=environment.path_prod+'listarOrdenesDCompra';
 private url362:any=environment.path_prod+'buscarCuentaDGastos';
 private url363:any=environment.path_prod+'listarPorductosXProveedorOrdenesDCompra';
 private url364:any=environment.path_prod+'verOrdenDCompra';
 private url365:any=environment.path_prod+'listarOrdenesDCompraPendiente';
 private url366:any=environment.path_prod+'listarOrdenesDCompraParaRecepcion';
 private url367:any=environment.path_prod+'verRecepcion';

 private url368:any=environment.path_prod+'SolicitarDepartamentos';
 private url369:any=environment.path_prod+'SolicitarCargo';
 private url370:any=environment.path_prod+'SolicitarCoordinacion';
 private url371:any=environment.path_prod+'listarDepositosActivos';
 private url372:any=environment.path_prod+'listarSolicituCompra';
 private url373:any=environment.path_prod+'listarProductosXTipo';
 private url374:any=environment.path_prod+'buscarCentroCostos';
 private url375:any=environment.path_prod+'crearSolicitudCompra';
 private url376:any=environment.path_prod+'listarProductosSolicituCompra';
 private url377:any=environment.path_prod+'aprobarSolicitudCompra';

 private url378:any=environment.path_prod+'listarCentroCosto';
 private url379:any=environment.path_prod+'listarDepartamentos';
 private url380:any=environment.path_prod+'gestionCentroCosto';
 private url381:any=environment.path_prod+'gestionCostoPartidas';
 private url382:any=environment.path_prod+'listarCuentas';
 private url383:any=environment.path_prod+'gestionCuentasPresupuesto';
 private url384:any=environment.path_prod+'listarCuentasCreadas';
 private url385:any=environment.path_prod+'listarCuentasVali';
 private url386:any=environment.path_prod+'listarSegCuentas';
 private url387:any=environment.path_prod+'listarSegPartidas';
 private url388:any=environment.path_prod+'listarPartidasCreadas';
 private url389:any=environment.path_prod+'listarPartidasPresupuestarias';
 private url390:any=environment.path_prod+'gestionCentroCostoImport';
 private url391:any=environment.path_prod+'listarPartidasActivas';
 private url392:any=environment.path_prod+'listarValorPartidas';
 private url393:any=environment.path_prod+'procesarTransferenciaPartidas';
 private url394:any=environment.path_prod+'buscarPartida';
 private url395:any=environment.path_prod+'procesarCarga';

 private url396:any=environment.path_prod+'listarParametrosOperVentas';
 private url397:any=environment.path_prod+'gestionDefinicionesSistemaOperVentas';

 private url398:any=environment.path_prod+'listarHistorialOperVentas';

 private url399:any=environment.path_prod+'listarAlmacenesCDN';

 private url400:any=environment.path_prod+'verificarFactura';
 private url401:any=environment.path_prod+'crearRecepcionDOrdenDCompra';
 private url402:any=environment.path_prod+'segRecepcionesXProducto';
 private url403:any=environment.path_prod+'facturasXRecepcion';
 private url404:any=environment.path_prod+'validarProveedores';
 private url405:any=environment.path_prod+'gestionLotes';

 private url406:any=environment.path_prod+'crearSolicitudTomaFisica';
 private url407:any=environment.path_prod+'listarInventarioPorDeposito';
 private url408:any=environment.path_prod+'listarSolicitudTomaFisica';
 private url409:any=environment.path_prod+'segTomaFisicaCompleta';
 private url410:any=environment.path_prod+'listarDepositosTF';
 private url411:any=environment.path_prod+'listarProductosDepItemEsp';
 private url412:any=environment.path_prod+'listarRecepcionesRecibidas';
 private url413:any=environment.path_prod+'verRecepcionRecibida';
 private url414:any=environment.path_prod+'liberarRecepcion';
 private url415:any=environment.path_prod+'listaRecepcionesLiberadas';
 private url416:any=environment.path_prod+'listarNotasCreditoNotas';

 private url417:any=environment.path_prod+'cuadre_masivo_lotes';

 private url418:any=environment.path_prod+'listarAuditoresTF';
 private url419:any=environment.path_prod+'listarLiderTF';
 private url420:any=environment.path_prod+'listarSupervisorTF';

 private url421:any=environment.path_prod+'buscarPedidosPF';
 private url422:any=environment.path_prod+'listarPF';
 private url423:any=environment.path_prod+'buscarDocumentoAnulado';
 private url424:any=environment.path_prod+'buscarDocumentoPF';

 private url425:any=environment.path_prod+'buscarProductoMasivo';

 private url426:any=environment.path_prod+'listarDailys';
 private url427:any=environment.path_prod+'reporteFactDaily';
 private url428:any=environment.path_prod+'enviarDailyReportMasivo';

 private url429:any=environment.path_prod+'listarParameAccesosAcciones';
 private url430:any=environment.path_prod+'gestionDefinicionesSistemaOperAccionesAccesos';

 private url431:any=environment.path_prod+'listarParameAccesosAccionesUsuarios';
 private url432:any=environment.path_prod+'gestionDefinicionesSistemaAccionesAccesosUsuarios';
 private url433:any=environment.path_prod+'listarBackOrder';
 private url434:any=environment.path_prod+'consultarbackcorder';

 private url435:any=environment.path_prod+'gestionpedidobackorder';
 private url436:any=environment.path_prod+'listarbackoderRangoFecha';

 private url437:any=environment.path_prod+'listarVentasVsCuotasReport';
 private url438:any=environment.path_prod+'gestionVentasCuotasReport';
 private url439:any=environment.path_prod+'listarVentasCuotasFrontPDF';
 private url440:any=environment.path_prod+'descargarPlantillaCuotas';
 private url441:any=environment.path_prod+'reporteUnidadesVendidasD56';
 private url442:any=environment.path_prod+'reporteVentasD56';
 private url443:any=environment.path_prod+'reporteClientes'
 private url444:any=environment.path_prod+'listarPedidosMKP';
 private url445:any=environment.path_prod+'listarProductosPedidosMKP';
 private url446:any=environment.path_prod+'reporteDevoluciones';
 private url447:any=environment.path_prod+'listarRelacionUsuariosCentroCosto';
 private url448:any=environment.path_prod+'enviarReporteCuotasVsVentas';
 private url449:any=environment.path_prod+'crearRelacionUsuariosCentroCosto';
 private url450:any=environment.path_prod+'eliminarRelacionUsuariosCentroCosto';
 private url451:any=environment.path_prod+'buscarPresupuestosPartidas';
 private url452:any=environment.path_prod+'generarReporteCuotas';
 private url453:any=environment.path_prod+'procesarDescarga';
 private url454:any=environment.path_prod+'enviarCorreoPorPermisos';
 private url455:any=environment.path_prod+'buscarPartidaAnulada';
 private url456:any=environment.path_prod+'anularPartida';

 private url457:any=environment.path_prod+'listarDepositosReport';
 private url458:any=environment.path_prod+'reporteCargosDescargos';

 private url459:any=environment.path_prod+'listarProductoinv';
 private url460:any=environment.path_prod+'BuscarFechaNacimiento';
 private url461:any=environment.path_prod+'bulkAsistencia';
 private url462:any=environment.path_prod+'reporteOrdenesProd';
 private url463:any=environment.path_prod+'reporteOrdenesProdOperaciones';
 private url464:any=environment.path_prod+'listarProductosReport';
 private url465:any=environment.path_prod+'gestionpedido_factura_pro';
 private url466:any=environment.path_prod+'buscarProductosPedidos_diferencial_pro';
 private url467:any=environment.path_prod+'listarParameAccesosCorreos';
 private url468:any=environment.path_prod+'gestionDefinicionesSistemaOperCorreosAccesos';
 private url469:any=environment.path_prod+'listarParameAccesosCorreosUsuarios';
 private url470:any=environment.path_prod+'gestionDefinicionesSistemaCorreosAccesosUsuarios';
 private url471:any=environment.path_prod+'activaOinactivarProveedor';
 private url472:any=environment.path_prod+'actualizarNota';
 private url473:any=environment.path_prod+'anularCotizacionGenerada';
 private url474:any=environment.path_prod+'volverACotizar';
 private url475:any=environment.path_prod+'listarPorductosPendientesOC';
 private url476:any=environment.path_prod+'listarCotizacionesProAprobados';
 private url477:any=environment.path_prod+'volverACotizarProdOC';
 private url478:any=environment.path_prod+'listarPreOrdenesDCompra';
 private url479:any=environment.path_prod+'crearPreOrdenDCompra';
 private url480:any=environment.path_prod+'verPreOrdenDCompra';
 private url481:any=environment.path_prod+'listarProductosPreOC';
 private url482:any=environment.path_prod+'listarPreOCXCodigo';
 private url483:any=environment.path_prod+'anularPreOC';
 private url484:any=environment.path_prod+'buscarUsuariorolguard';
 private url485:any=environment.path_prod+'listarRolMenu';
 private url486:any=environment.path_prod+'listarAccionesUsuario';
 private url487:any=environment.path_prod+'getQuitarPermiso';
 private url488:any=environment.path_prod+'getListaPrecioNueva';
 private url489:any=environment.path_prod+'listarLicencias';
 private url490:any=environment.path_prod+'gestionLicencias';
 private url491:any=environment.path_prod+'gestionParametroLicencia';
 private url492:any=environment.path_prod+'buscarPerametrosLicencia';
 private url493:any=environment.path_prod+'validarLicenciaProcesos';
 private url494:any=environment.path_prod+'gestionarImagenesProductos';
 private url495:any=environment.path_prod+'listarDocumentos';
 private url496:any=environment.path_prod+'buscardefiniciones';
 private url497:any=environment.path_prod+'gestionDocumentos';
 private url498:any=environment.path_prod+'listarParametroDocumentos';
 private url499:any=environment.path_prod+'gestionParametroDocumentos';
 private url500:any=environment.path_prod+'gestionarImgDoc';
 private url501:any=environment.path_prod+'gestionarImgFac';
 private url502:any=environment.path_prod+'buscarHoraServidor';
 private url503:any=environment.path_prod+'gestionOrganizacion';
 private url504:any=environment.path_prod+'listarEstructuraOrganizacion';
 private url505:any=environment.path_prod+'listarCargos';
 private url506:any=environment.path_prod+'listarProductosConfig';
 private url507:any=environment.path_prod+'listarRolesPerfil';
 private url508:any=environment.path_prod+'getQuitarParametroDoc';
 private url509:any=environment.path_prod+'listarEstados';
 private url510:any=environment.path_prod+'listarMateriaPrimaConfig';
 private url511:any=environment.path_prod+'gestionFormulaMp';
 private url512:any=environment.path_prod+'gestionAplicaciones';
 private url513:any=environment.path_prod+'gestionarImgApp';
 private url514:any=environment.path_prod+'gestionarImgUser';

 private datosimg:any;
 private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 private verticalPosition: MatSnackBarVerticalPosition = 'top';
 private conexion_red:any;

      constructor(
                  private postrest:HttpClient,
                  private db:AngularFirestore,
                  public dialog: MatDialog, 
                  private conexion: ConnectionServices,
                  private snackBar: MatSnackBar,
                  private hashkey:HashKeyServices,
                  private util:AppUtilService,
                  private atrb:AtributosService,
                  private notifi:ToastNotificationService,
                  ){}
                ApiResthttp(xobj:any):Observable<any>{
                    let headers = this.atrb.ENCAB.header_1;
                    this.ruta = this.postrest.post(this.hashkey.decrypt(xobj.api),xobj,{headers});
                    return this.ruta;
                  }
                  gestionarImgApphttp(xobj:any):Observable<any>{
                    this.ruta = this.postrest.post(this.url513, xobj);
                    return this.ruta;
                  }
                  gestionarImgUserhttp(xobj:any):Observable<any>{
                    this.ruta = this.postrest.post(this.url514, xobj);
                    return this.ruta;
                  }
                  gestionFormulaMphttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url511, xobj,{headers});
              
                    return this.ruta;
                  }
                  listarMateriaPrimaConfighttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url510,xobj,{headers});
                    return this.ruta;
                  }
                  listarEstadoshttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url509,xobj,{headers});
                    return this.ruta;
                  }
                  getQuitarParametroDochttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url508,xobj,{headers});
                    return this.ruta;
                  }
                  listarRolesPerfilhttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url507,xobj,{headers});
                    return this.ruta;
                  }
                  listarProductosConfighttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url506,xobj,{headers});
                    return this.ruta;
                  }
                  listarCargoshttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url505,xobj,{headers});
                    return this.ruta;
                  }
                  listarEstructuraOrganizacionhttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url504,xobj,{headers});
                    return this.ruta;
                  }
                  
                  gestionOrganizacionhttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','multipart/form-data');
                    this.ruta = this.postrest.post(this.url503, xobj);
                    return this.ruta;
                  }

                  buscarHoraServidorhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','multipart/form-data');
                    this.ruta = this.postrest.post(this.url502,{headers});
                    return this.ruta;
                  }
                  gestionarImgFachttp(xobj:any):Observable<any>{
                    this.ruta = this.postrest.post(this.url501, xobj);
                    return this.ruta;
                  }
                  gestionarImgDochttp(xobj:any):Observable<any>{
                    this.ruta = this.postrest.post(this.url500, xobj);
                    return this.ruta;
                  }
                  gestionParametroDocumentoshttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url499, xobj,{headers});
              
                    return this.ruta;
                  }

                  listarParametroDocumentoshttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    console.log(xvalor);
                    this.ruta = this.postrest.post<any>(this.url498, xvalor,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                   }

                  gestionDocumentoshttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url497, xobj,{headers});
                    return this.ruta;
                  }

                  buscardefinicioneshttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url496,xvalor,{headers});
                    return this.ruta;
                  }
                  listarDocumentoshttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url495,xvalor,{headers});
                    return this.ruta;
                  }

                  gestionarImagenesthttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','multipart/form-data');
                    this.ruta = this.postrest.post(this.url494, xobj);
                    return this.ruta;
                  }

                  validarLicenciaProcesoshttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url493, xvalor,{headers});
                    return this.ruta;
                   }
                  buscarPerametrosLicenciahttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url492, xvalor,{headers});
                    return this.ruta;
                   }

                  gestionParametroLicenciahttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url491, xobj,{headers});
              
                    return this.ruta;
                  }

                  gestionLicenciashttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    console.log(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url490, xobj,{headers});
              
                    return this.ruta;
                  }

                  listarLicenciashttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url489,{headers});
                    return this.ruta;
                  }

                  getListaPrecioNuevahttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url488, xobj,{headers});
                    return this.ruta;
                  }
                  getQuitarPermisohttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url487, xobj,{headers});
                    return this.ruta;
                  }

                  listarAccionesUsuariohttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url486,xobj,{headers});
                    return this.ruta;
                    } 

                  listarRolMenuhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url485,{headers});
                    return this.ruta;
                  }

                  getUsuarioshttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url12,xvalor,{headers});
                    return this.ruta;
                  }
                  
                  buscarUsuariorolguardhttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    console.log(xvalor);
                    this.ruta = this.postrest.post<any>(this.url484, xvalor,{headers});
                    console.log(this.ruta);
                    return this.ruta;
               }
                  anularPreOC(cod_pre_orden_compra:any):Observable<any>{
                    let  body = {cod_pre_orden_compra}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url483,body,{headers});
                    return this.ruta;
                  }
                  listarPreOCXCodigo(cod_pre_orden_compra:any):Observable<any>{
                    let  body = {cod_pre_orden_compra}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url482,body,{headers});
                    return this.ruta;
                  }
                  listarProductosPreOC(cod_pre_orden_compra:any):Observable<any>{
                    let  body = {cod_pre_orden_compra}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url481,body,{headers});
                    return this.ruta;
                  }
                  verPreOrdenDCompra(cod_pre_orden_compra:any):Observable<any>{
                    let  body = {cod_pre_orden_compra}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url480,body,{headers});
                    return this.ruta;
                  }
                  crearPreOrdenDCompra(obj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url479,obj,{headers});
                    return this.ruta;
                  }
                  listarPreOrdenesDCompra():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url478,{headers});
                    return this.ruta;
                  }
                  volverACotizarProdOC(productos:any[], usuario:Usuario):Observable<any>{
                    var body= {productos,usuario};
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url477,body,{headers});
                    return this.ruta;
                  }
                  listarCotizacionesProAprobados(cod_cotizacion_req:string):Observable<any>{
                    var body= {cod_cotizacion_req};
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url476,body,{headers});
                    return this.ruta;
                  }
                  listarPorductosPendientesOC():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url475,{headers});
                    return this.ruta;
                  }
                  volverACotizar(cod_cotizacion:string,usuario:Usuario):Observable<any>{
                    var body= {cod_cotizacion,usuario};
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url474,body,{headers});
                    return this.ruta;
                  }
                  anularCotizacionGenerada(cod_cotizacion:string,usuario:Usuario):Observable<any>{
                    var body= {cod_cotizacion,usuario};
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url473,body,{headers});
                    return this.ruta;
                  }
                  actualizarNotahttp(cod_orden_compra:string,nota:string):Observable<any>{
                    var body= {cod_orden_compra,nota};
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url472,body,{headers});
                    return this.ruta;
                  }
                  activaOinactivarProveedorhttp(cod_proveedor:string):Observable<any>{
                    var body= {cod_proveedor};
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url471,body,{headers});
                    return this.ruta;
                  }
                  gestionDefinicionesSistemaCorreosAccesosUsuarioshttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url470,xobj,{headers});
              
                    return this.ruta;
                  }

                  listar_parametros_correos_accesos_usuariohttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url469,{headers});
                    return this.ruta;
                  }

                  gestionDefinicionesSistemaOperCorreosAccesoshttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url468,xobj,{headers});
              
                    return this.ruta;
                  }

                  listarParametrosAccionesCorreoshttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url467,{headers});
                    return this.ruta;
                  }
                  buscarProductosPedidos_diferencial_prohttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url466, xvalor,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                   }
                  gestionpedido_factura_prohttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url465, xobj,{headers});
                    return this.ruta;
                  }
                  listarProductosReporthttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url464,{headers});
                    return this.ruta;
                  }
                  reporteOrdenesProdOperacioneshttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url463, xvalor,{headers});
                    return this.ruta;
                  }
                  reporteOrdenesProdhttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url462, xvalor,{headers});
                    return this.ruta;
                  }
                  bulkAsistenciahttp(info:any,formulario:any):Observable<any>{
                    let body = {info,formulario}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url461,body,{headers});
                    return this.ruta;
                  }
                  reporteCargosDescargoshttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url458, xvalor,{headers});
                    return this.ruta;
                  }
                  listarDepositosReporthttp():Observable<any>{
                      let headers = new HttpHeaders().set('Content-Type','application/json');
                      this.ruta = this.postrest.post<any>(this.url457,{headers});
                      return this.ruta;
                 }
                  listarFechaNacimientohttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url460,xvalor,{headers});
                    return this.ruta;
                  }
                  anularPartidahttp(xvalor:any):Observable<any>{
                      let headers = new HttpHeaders().set('Content-Type','application/json');
                      this.ruta = this.postrest.post<any>(this.url456, xvalor,{headers});
                      return this.ruta;
                 }
                  buscarPartidaAnuladahttp(xvalor:any):Observable<any>{
                    var respuesta =  (navigator.onLine);
                    if (respuesta) {
                      let headers = new HttpHeaders().set('Content-Type','application/json');
                      this.ruta = this.postrest.post<any>(this.url455, xvalor,{headers});
                      return this.ruta;
                    }else{
                      alert('conexion caida');
                    }
                 }
                  enviarCorreoPorPermisoshttp(titulo:string,modulo:string,mensaje:string,val_def:string,tipo_operacion:string,codigo?:string):Observable<any>{
                    let body = {
                      titulo,
                      modulo,
                      mensaje,
                      val_def,
                      tipo_operacion,
                      codigo
                    }

                    this.ruta = this.postrest.post(this.url454,body);
              
                    return this.ruta;
                    
                  }
                  buscarPresupuestosPartidashttp(data:any):Observable<any>{
                    let body = {cod_centro:data}

                    this.ruta = this.postrest.post(this.url451,body);
              
                    return this.ruta;
                    
                  }
                  eliminarRelacionUsuariosCentroCostohttp(data:any):Observable<any>{
                    let body = data

                    this.ruta = this.postrest.post(this.url450,body);
              
                    return this.ruta;
                    
                  }
                  crearRelacionUsuariosCentroCostohttp(data:any):Observable<any>{
                    let body = data
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url449,body,{headers});
                    return this.ruta;
                  }
                  procesarDescargahttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url453, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  generarReporteCuotasHttp(obj:any):Observable<any>{
                    let body = JSON.stringify(obj)
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url452,body,{headers});
              
                    return this.ruta;
                    
                  }
                  listarRelacionUsuariosCentroCostohttp(data:any):Observable<any>{
                    let body = {cod_centro: data}
                    
                    this.ruta = this.postrest.post(this.url447,body);
                  
                    return this.ruta;
                    
                  }
                  enviarReporteCuotasVsVentasHttp(obj:any):Observable<any>{
                    let body = JSON.stringify(obj)
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url448,body,{headers});
              
                    return this.ruta;
                    
                  }

                  reporteDevolucioneshttp(fechas:any):Observable<any>{
                    let body = fechas
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url446,body,{headers});
              
                    return this.ruta;
                    
                  }
                  
                  listarProductosPedidosMKPhttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url445, xobj,{headers});

                    return this.ruta;
                  }
                  listarPedidosMKPhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url444,{headers});

                    return this.ruta;
                  }
                  reporteClienteshttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url443,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }
                  reporteVentasD56http(xvalor:string):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    console.log(xvalor);
                    var body= {cod_solicitud_padre:xvalor};
                    this.ruta = this.postrest.post<any>(this.url442, xvalor,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }
                  reporteUnidadesVendidasD56http(xvalor:string):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    console.log(xvalor);
                    var body= {cod_solicitud_padre:xvalor};
                    this.ruta = this.postrest.post<any>(this.url441, xvalor,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }
                   descargarPlantillaCuotashttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url440,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }
                   listarVentasCuotasFrontPDFhttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url439,body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }
                   gestionVentasCuotasReporthttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url438,body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }
              
                  listarVentasVsCuotasReporthttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url437,{headers});
              
                    return this.ruta;
                    
                  }

                  listarbackoderRangoFechahttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url436, xobj,{headers});
              
                    return this.ruta;
                  }

                  gestionPedidosBackorderhttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url435, xobj,{headers});
              
                    return this.ruta;
                  }

                  ConsultarBackorderhttp(xvalor:string):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url434,xvalor,{headers});
                    return this.ruta;
                  }

                  listarBackOrderHttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url433,xvalor,{headers});
              
                    return this.ruta;
                  }

                  reporteDailyhttp():Observable<any>{
                    let body = {tipo_daily: '1'}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url427,body,{headers});
              
                    return this.ruta;
                    
                  }

                  validar_acceso_acciones_http(obj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.acciones_accesos,obj,{headers});
                    return this.ruta;
                  }

                  enviarDailyReportMasivoHttp(obj:any):Observable<any>{
                    let body = {obj}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url428,body,{headers});
              
                    return this.ruta;
                    
                  }
                  reporteFactDailyHttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url427,xvalor,{headers});
              
                    return this.ruta;
                    
                  }

                  listarDailysHttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url426,{headers});
              
                    return this.ruta;
                    
                  }

                buscarDocumentoPFhttp(xvalor:any):Observable<any>{
                    var respuesta =  (navigator.onLine);
                    if (respuesta) {
                      let headers = new HttpHeaders().set('Content-Type','application/json');
                      this.ruta = this.postrest.post<any>(this.url424, xvalor,{headers});
                      return this.ruta;
                    }else{
                      alert('conexion caida');
                    }
                 }

                buscarDocumentoAnuladohttp(xvalor:any):Observable<any>{
                    var respuesta =  (navigator.onLine);
                    if (respuesta) {
                      let headers = new HttpHeaders().set('Content-Type','application/json');
                      this.ruta = this.postrest.post<any>(this.url423, xvalor,{headers});
                      return this.ruta;
                    }else{
                      alert('conexion caida');
                    }
                 }

              listarAuditoresTFhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url418, {headers});
                    return this.ruta;
                  }
                  listarLiderTFhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url419, {headers});
                    return this.ruta;
                  }
                  listarSupervisorTFhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url420, {headers});
                    return this.ruta;
                  }

                  validar_lotes_devHttp(objeto:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url417,objeto,{headers});
                    return this.ruta;
                  }

                  listaRecepcionesLiberadasHttp(cod_recepcion:any):Observable<any>{
                    let body = {cod_recepcion}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url415,body,{headers});
              
                    return this.ruta;
                    
                  }
                  liberarRecepcionHttp(productos:any,usuarioInfo:any,recepcionInfo:any):Observable<any>{
                    let body = {productos,usuarioInfo,recepcionInfo}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url414,body,{headers});
              
                    return this.ruta;
                    
                  }
                  verRecepcionRecibida(cod_recepcion:any):Observable<any>{
                    let body = {cod_recepcion}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url413,body,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listarRecepcionesRecibidas():Observable<any>{
              
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url412,{headers});
              
                    return this.ruta;
                    
                  }

                  listarProductosDepItemEsphttp(xobj:any):Observable<any>{
                    var body= {cod_deposito:xobj};
                    console.log(xobj)
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url411, body, {headers});
                    return this.ruta;
                  }

                  listarDepositosTFhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url410, {headers});
                    return this.ruta;
                  }

                  segTomaFisicaCompletahttp(xobj:any,xobj2:any,xobj3:any):Observable<any>{
                    var body= {tomaFisicaC:xobj, fase:xobj2, tipo:xobj3};
                    var json= JSON.stringify(body);
                    console.log(json);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url409, body,{headers});
              
                    return this.ruta;
                  }

                  listarSolicitudTomaFisicahttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url408,{headers});
                    return this.ruta;
                  }

                  listarInventarioPorDepositohttp(xobj:any, xobj2:any, xobj3:any, xobj4:any):Observable<any>{
                    var body= {cod_deposito:xobj, fase:xobj2, cod_solicitud_toma_fisica:xobj3, tipo:xobj4};
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url407, body,{headers});
              
                    return this.ruta;
                    
                  }

                  crearSolicitudTomaFisicahttp(xobj:any):Observable<any>{
                    var body= xobj;
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url406, body,{headers});
              
                    return this.ruta;
                    
                  }

                  gestionLoteshttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url405,xobj,{headers});
              
                    return this.ruta;
                  }

                  validarProveedoreshttp(cod_prov_1:any,cod_prov_2:any,cod_prov_3:any):Observable<any>{
                    let body = {
                                  cod_prov_1,
                                  cod_prov_2,
                                  cod_prov_3
                                }
                    
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url404,body,{headers});
              
                    return this.ruta;
                    
                  }
                  facturasXRecepcionhttp(cod_factura:any):Observable<any>{
                    let body = {cod_factura}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url403,body,{headers});
              
                    return this.ruta;
                    
                  }
                  segRecepcionesXProductohttp(cod_orden_req:any,cod_producto:any):Observable<any>{
                    let body = {cod_orden_req,cod_producto}
                    console.log(body,'elbody')
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url402,body,{headers});
              
                    return this.ruta;
                    
                  }
                  crearRecepcionDOrdenDComprahttp(formData:any):Observable<any>{
                    /* let headers = new HttpHeaders().set('Content-Type','multipart/form-data; boundary="boundary"'); */
                    this.ruta = this.postrest.post(this.url401,formData);
              
                    return this.ruta;
                    
                  }
                  verificarFacturahttp(cod_factura:any):Observable<any>{
                    let  body = {cod_factura}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url400,body,{headers});
              
                    return this.ruta;
                    
                  }

                  gestionDefinicionesSistemaOperVentashttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url397,xobj,{headers});
              
                    return this.ruta;
                  }

                  gestionDefinicionesSistemaAccionesAccesosUsuarioshttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url432,xobj,{headers});
              
                    return this.ruta;
                  }

                  gestionDefinicionesSistemaAccioneshttp(xobj:any):Observable<any>{
                    var body= JSON.stringify(xobj);
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url430,xobj,{headers});
              
                    return this.ruta;
                  }

                  listarParametrosOperVentashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url396,xobj,{headers});
              
                    return this.ruta;
                    
                  }
                  listarParametrosAccionesAccesoshttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url429,{headers});
              
                    return this.ruta;
                    
                  }
                  listar_parametros_acciones_accesos_usuariohttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url431,{headers});
              
                    return this.ruta;
                    
                  }
                  listarHistorialOperVentashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url398,body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                    } 

                  listarCentroCostohttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url378,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                    }

                    listarDepartamentoshttp():Observable<any>{
                      let headers = new HttpHeaders().set('Content-Type','application/json');
                      this.ruta = this.postrest.post<any>(this.url379,{headers});
                      console.log(this.ruta);
                      return this.ruta;
                      }

                      gestionCentroCostohttp(xobj:any):Observable<any>{
                        let headers = new HttpHeaders().set('Content-Type','application/json');
                        var body= JSON.stringify(xobj)
                        this.ruta = this.postrest.post<any>(this.url380,body,{headers});
                        console.log(this.ruta);
                        return this.ruta;
                        }  
  
                  gestionCostoPartidashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url381, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                    } 

                  listarCuentashttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url382,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                }

                  gestionCuentasPresupuestohttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url383, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                }

                  listarCuentasCreadashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url384, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  listarCuentasValihttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url385, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  listarSegCuentashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url386, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  listarSegPartidashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url387, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  listarPartidasCreadashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url388, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  listarPartidasPresupuestariashttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url389,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  gestionCentroCostoImporthttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url390, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  listarPartidasActivashttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post<any>(this.url391,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  listarValorPartidashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url392, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  procesarTransferenciaPartidashttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url393, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  buscarPartidahttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url394, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }

                  procesarCargahttp(xobj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    var body= JSON.stringify(xobj)
                    this.ruta = this.postrest.post<any>(this.url395, body,{headers});
                    console.log(this.ruta);
                    return this.ruta;
                  }
                  
                  verRecepcionhttp(cod_orden_compra:any):Observable<any>{
                    let  body = {cod_orden_compra}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url367,body,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listarOrdenesDCompraParaRecepcionhttp(cod_proveedor:any):Observable<any>{
                    let  body = {cod_proveedor}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url366,body,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listarOrdenesDCompraPendientehttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url365,{headers});
              
                    return this.ruta;
                    
                  }
                  
                  verOrdenDComprahttp(cod_orden_compra:any):Observable<any>{
                    let  body = {cod_orden_compra}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url364,body,{headers});
              
                    return this.ruta;
                    
                  }
                  listarPorductosXProveedorOrdenesDCompra(cod_proveedor:any):Observable<any>{
                    let  body = {cod_proveedor}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url363,body,{headers});
              
                    return this.ruta;
                    
                  }
                  buscarCuentaDGastoshttp(cod_presupuesto:any, cod_cuenta_gastos:any):Observable<any>{
                    let  body = {cod_presupuesto,cod_cuenta_gastos}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url362,body,{headers});
              
                    return this.ruta;
                    
                  }
                  listarOrdenesDComprahttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url361,{headers});
              
                    return this.ruta;
                    
                  }
                  crearOrdenDComprahttp(obj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url360,obj,{headers});
              
                    return this.ruta;
                    
                  }
                  crearOactuzalizarProveedorhttp(obj:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url359,obj,{headers});
              
                    return this.ruta;
                    
                  }
                  comprobarProveedorhttp(obj:any):Observable<any>{
                    let body = {cod_proveedor:obj}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url358,body,{headers});
              
                    return this.ruta;
                    
                  }
                  listarTipoProductoshttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url357,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listarBancosVznlhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url356,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listaInfoPaisesCiudadEstadosVznlhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url355,{headers});
              
                    return this.ruta;
                    
                  }
                  listarProveedoreshttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url354,{headers});
              
                    return this.ruta;
                    
                  }
                  listarPorductosXProveedorhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url353,{headers});
              
                    return this.ruta;
                    
                  }
                  listarCotizacionesAprobadashttp(xvalor:any):Observable<any>{
                    let body = {cod_cotizacion_req:xvalor}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url352,body,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listarCotizacionesNuevashttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url351,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listarProductosCotizacionhttp(xvalor:any):Observable<any>{
                    let obj = { cod_cotizacion:xvalor }
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url350,obj,{headers});
              
                    return this.ruta;
                    
                  }
                  generarCotizacionOrdenhttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url349,xvalor,{headers});
              
                    return this.ruta;
                    
                  }
                  listaProdCotizarhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url348,{headers});
              
                    return this.ruta;
                    
                  }
                  aprobarCotizacionhttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url347,xvalor,{headers});
              
                    return this.ruta;
                    
                  }
                  listarCotizacioneshttp(xvalor:any):Observable<any>{
                    let body = {cod_cotizacion_req:xvalor}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url346,body,{headers});
              
                    return this.ruta;
                    
                  }
                  crearCotizacionOrdenhttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url345,xvalor ,{headers});
              
                    return this.ruta;
                    
                  }
                  aprobarSolicitudComprahttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url377,xvalor ,{headers});
              
                    return this.ruta;
                    
                  }
                  listarProductosSolicituComprahttp(xvalor:any):Observable<any>{
                    let body = {cod_solicitud_compra:xvalor}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url376,body ,{headers});
              
                    return this.ruta;
                    
                  }
                  crearSolicitudComprahttp(xvalor:any):Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url375,xvalor ,{headers});
              
                    return this.ruta;
                    
                  }
                  buscarCentroCostoshttp(xvalor:any):Observable<any>{
                    let body = {cod_usuario:xvalor}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url374,body ,{headers});
              
                    return this.ruta;
                    
                  }
                  listarProductosXTipohttp(xvalor:any):Observable<any>{
                    let body = {tipo_producto:xvalor}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url373,body,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listarSolicituComprahttp(cod_usuario:any):Observable<any>{
                    let body = {cod_usuario}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url372,body,{headers});
              
                    return this.ruta;
                    
                  }
              
                  listarDepositosActivoshttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url371,{headers});
              
                    return this.ruta;
                    
                  }
              
                  SolicitarCoordinacionhttp():Observable<any>{
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url370,{headers});
              
                    return this.ruta;
                    
                  }
              
                  SolicitarCargohttp(obj:any):Observable<any>{
                    var body = {departamento:obj};
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url369, body,{headers});
              
                    return this.ruta;
                    
                  }
              
                  SolicitarDepartamentoshttp(obj:any):Observable<any>{
                    let body = {coordinacion: obj}
                    let headers = new HttpHeaders().set('Content-Type','application/json');
                    this.ruta = this.postrest.post(this.url368,body,{headers});
              
                    return this.ruta;
                    
                  }
              

    reporteFactDailyhttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      var body= JSON.stringify(xobj)
      this.ruta = this.postrest.post<any>(this.url344,body,{headers});
      console.log(this.ruta);
      return this.ruta;
    }
    gestionarMontoDailyhttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      var body= JSON.stringify(xobj)
      this.ruta = this.postrest.post<any>(this.url343, body,{headers});
      console.log(this.ruta);
      return this.ruta;
    }

    reporteConsignacioneshttp(xvalor:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        console.log(xvalor);
        var body= {cod_solicitud_padre:xvalor};
        this.ruta = this.postrest.post<any>(this.url342, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
      }
    listarClienteConsighttp():Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url341,{headers});
        console.log(this.ruta);
        return this.ruta;
      }
    movimientoInventarioResumenhttp(xvalor:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        console.log(xvalor);
        var body= {cod_solicitud_padre:xvalor};
        this.ruta = this.postrest.post<any>(this.url340, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
      }

    buscarUnidadesPorFacturasNotashttp(xvalor:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        console.log(xvalor);
        var body= {cod_solicitud_padre:xvalor};
        this.ruta = this.postrest.post<any>(this.url339, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
        }
                          
    reporteGeneralFNENDNChttp(xvalor:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        console.log(xvalor);
        var body= {cod_solicitud_padre:xvalor};
        this.ruta = this.postrest.post<any>(this.url338, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
        }

    reporteNotasDebitohttp(xvalor:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        console.log(xvalor);
        var body= {cod_solicitud_padre:xvalor};
        this.ruta = this.postrest.post<any>(this.url337, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
        }
      
    reporteNotasCreditoshttp(xvalor:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        console.log(xvalor);
        var body= {cod_solicitud_padre:xvalor};
        this.ruta = this.postrest.post<any>(this.url336, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
        }

    reporteNotashttp(xvalor:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        console.log(xvalor);
        var body= {cod_solicitud_padre:xvalor};
        this.ruta = this.postrest.post<any>(this.url335, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
        }

    reporteFacturashttp(xvalor:string):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          console.log(xvalor);
          var body= {cod_solicitud_padre:xvalor};
          this.ruta = this.postrest.post<any>(this.url334, xvalor,{headers});
          console.log(this.ruta);
          return this.ruta;
          }
    buscarDatosLotehttp(xvalor:string):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url333, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
        }
    
    gestionAnulacionVentahttp(xobj:any):Observable<any>{
        var body= JSON.stringify(xobj);
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post(this.url331, xobj,{headers});
              
        return this.ruta;
        }
    listarValorLoteshttp(xvalor:any):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url332,xvalor,{headers});
          return this.ruta;
        }

    validar_lotes_devhttp(xvalor:any):Observable<any>{
        let headers = new HttpHeaders().set('Content-Type','application/json');
        this.ruta = this.postrest.post<any>(this.url330, xvalor,{headers});
        console.log(this.ruta);
        return this.ruta;
    }
              
    listarSolicitudDevolucionAceptadaDVLCNhttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url328, xobj, {headers});
      return this.ruta;
    }

    listarSolicitudDevolucionAceptadaRCNDCNMNThttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url327, xobj, {headers});
      return this.ruta;
    }

    devolverProductosCompletoshttp(xobj:any):Observable<any>{
      var body= {devolucion_unidad:xobj};
      var json= JSON.stringify(body);
      console.log(json);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url326, xobj,{headers});

      return this.ruta;
    }

    buscarProductoPedidoGuiahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url210, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }

    consultarDevhttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url209,xobj,{headers});
      return this.ruta;
    }

    devolverProductosFormulashttp(xobj:any):Observable<any>{
      var body= {devolucion_total:xobj};
      var json= JSON.stringify(body);
      console.log(json);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url325, body,{headers});

      return this.ruta;
    }

    listarSolicitudDevolucionAceptadahttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url324, xobj, {headers});
      return this.ruta;
    }

    devolucionNotasCreditohttp(xobj:any):Observable<any>{
      var body= xobj;
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url323, body,{headers});

      return this.ruta;
      
    }

    mostrarProductosformulaDevueltoshttp(xobj:any):Observable<any>{
      var body= {cod_producto:xobj};
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url322, body,{headers});

      return this.ruta;
      
    }

    mostrarProductosDevueltoshttp(xobj:any):Observable<any>{
      var body= {cod_solicitud_devolucion:xobj};
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url321, body,{headers});

      return this.ruta;
      
    }

    actualizacionRelocalizacionDevProductoshttp(xobj:any):Observable<any>{
      var body= {producto_devolucion:xobj};
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url320, body,{headers});

      return this.ruta;
      
    }

    mostrarProductosDevolucioneshttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url319, xobj, {headers});
      return this.ruta;
    }

    listarSolicitudDevolucionhttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url318, xobj, {headers});
      return this.ruta;
    }

    listardevolucioneshttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url318, {headers});
      return this.ruta;
    }

    gestionarDevolucioneshttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url317, xobj, {headers});
      return this.ruta;
    }

    reporteGeneralEmpleadosAsistencia(datos:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url316,datos,{headers});
      return this.ruta;
    }
    reporteEmpleadosAsistencia(datos:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url315,datos,{headers});
      return this.ruta;
    }

    buscarDataProductoUmCanthttp(datos:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url206,datos,{headers});
      return this.ruta;
    }
    listarComedor(datos:any):Observable<any>{
      console.log(datos);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url311,datos,{headers});
      console.log(this.ruta);
      return this.ruta;
    }
    insertarempleadosnuevos(xobj:any):Observable<any>{
      console.log(xobj);
       var body= JSON.stringify(xobj);
      console.log(body);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url312, body,{headers});

      return this.ruta;
    }

    listarEmpleadoshttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url313, xobj, {headers});
      return this.ruta;
    }

    listarAsistenciaRangoFechahttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url314, xobj, {headers});
      return this.ruta;
    }

    CrerCodigohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url192, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }

    validar_permisos_accioneshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url177, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }

     validarInventarioProdFormulahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url201, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     validarInventarioProdFormulaMpphttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url204, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }

    mostrarplantilla():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url10,{headers});
      console.log(this.ruta);
      return this.ruta;
    }
    correohttp(datos:any):Observable<any>{
      console.log(datos);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url5, datos, {headers});
      return this.ruta;
    }
    listarhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url,{headers});
      return this.ruta;
    }
    listarUsuarioshttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url12,{headers});
      return this.ruta;
    }
    listarMenuhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url15,{headers});
      return this.ruta;
    }
    listarLprecioshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url56,xvalor,{headers});
      return this.ruta;
    }
    listarProductoTerminadohttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url61,{headers});
      return this.ruta;
    }
    listarInventarioSPLhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url102,{headers});
      return this.ruta;
    }
    listarDepositoshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url191,xvalor,{headers});
      return this.ruta;
    }
    listarDefinicionesConceptoshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url193,xvalor,{headers});
      return this.ruta;
    }
    listarValorProductohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url207,xvalor,{headers});
      return this.ruta;
    }
    procesarTrasladoshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url208,xvalor,{headers});
      return this.ruta;
    }
    listarInventarioSGhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url101,{headers});
      return this.ruta;
    }
    listarUnidadhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url34,{headers});
      return this.ruta;
    }
    listarFacturahttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url39,{headers});
      return this.ruta;
    }
    listarPFhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url422,{headers});
      return this.ruta;
    }
    listarNotashttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url142,{headers});
      return this.ruta;
    }
    listarNotasAdminSinglehttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url169,{headers});
      return this.ruta;
    }
    listarNotasCreditoNotashttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url416,{headers});
      return this.ruta;
    }
    listarNotasDebitohttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url149,{headers});
      return this.ruta;
    }
    listarNotasEntregahttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url146,{headers});
      return this.ruta;
    }
    listarInventarioPDhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url66,{headers});
      return this.ruta;
    }
    listarInventarioMPhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url67,{headers});
      return this.ruta;
    }
    listarInventarioLThttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url307,{headers});
      return this.ruta;
    }
    listarCuaentenaProdTerminadoLotehttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url138,{headers});
      return this.ruta;
    }
    listarCuaentenaProdDevolucioneshttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url147,{headers});
      return this.ruta;
    }
    listarClienteshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url40,xvalor,{headers});
      return this.ruta;
    }
    listarLogshttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url140,{headers});
      return this.ruta;
    }
    listarClientesActivoshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url108,xvalor,{headers});
      return this.ruta;
    }
    listarListaPrecioSPCLhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url106,xvalor,{headers});
      return this.ruta;
    }
    listarAlmacenSPCLhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url143,xvalor,{headers});
      return this.ruta;
    }
    listarAlmacenCDNhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url399,xvalor,{headers});
      return this.ruta;
    }
    listarAlmacen_PThttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url198,xvalor,{headers});
      return this.ruta;
    }
    listarOrdenesProdhttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url70,termino,{headers});
      return this.ruta;
    }
    listarOrdenesProdHistorialhttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url179,termino,{headers});
      return this.ruta;
    }
    listarFormulasHistorialhttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url180,termino,{headers});
      return this.ruta;
    }
    listarSolicitudesSoportehttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url156,termino,{headers});
      return this.ruta;
    }
    listarSolicitudesSoporteAdminhttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url159,termino,{headers});
      return this.ruta;
    }
    listarTicketSoporteAdminhttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url163,termino,{headers});
      return this.ruta;
    }
    listarTiempoOrdenProdhttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url183,termino,{headers});
      return this.ruta;
    }
    listarTiempoOrdenProdMpphttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url185,termino,{headers});
      return this.ruta;
    }
    listarTareasSoportehttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url153,{headers});
      return this.ruta;
    }
    listarDefinicionesSistemahttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url187,{headers});
      return this.ruta;
    }
    listarSecuenciaLotettp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url148,{headers});
      return this.ruta;
    }
    listarSesionesActivashttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url178,{headers});
      return this.ruta;
    }
    listarConsignacioneshttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url122,termino,{headers});
      return this.ruta;
    }
    listarConsignacionesChttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url126,termino,{headers});
      return this.ruta;
    }
    listarOrdenesProdMpphttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url300,termino,{headers});
      return this.ruta;
    }
    listarSegOrdenesProdhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url85,{headers});
      return this.ruta;
    }
    listarSegOrdenesProdMpphttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url306,{headers});
      return this.ruta;
    }
    listarProvhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url65,{headers});
      return this.ruta;
    }
    listarProductoshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url62,xvalor,{headers});
      return this.ruta;
    }
    listarClienteEspecialhttp(termino:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url60, termino,{headers});
      return this.ruta;
    }
    listarRoleshttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url17,{headers});
      return this.ruta;
    }
    listarRoleMenuhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url19,{headers});
      return this.ruta;
    }

    listarUsuarioRolhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url20,{headers});
      return this.ruta;
    }
    listarimghttp(img:any):Observable<any>{
      let imgjson ={img:img};
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url9,imgjson,{headers});
      return this.ruta;
    }
    listarProductoTermhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url44,{headers});
      return this.ruta;
    }
    listarProductoTerminadoPTEPTPTOhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url459, xvalor,{headers});
      return this.ruta;
    }
    listarClientesNotaDebitohttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url150,{headers});
      return this.ruta;
    }
    listarProductoMphttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url135,{headers});
      return this.ruta;
    }
    listarClientePedidoTipohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url93,xvalor,{headers});
      return this.ruta;
    }
    listarProductoPedidohttp(xvalor:string):Observable<any>{
      var body= {cod_pedido:xvalor};
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url52,body,{headers});
      return this.ruta;
    }
    listarProductoListapreciohttp(xvalor:string):Observable<any>{
      var body= {cod_lista_precio_producto:xvalor};
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url59,body,{headers});
      return this.ruta;
    }
    listarProdPedidohttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url54,xvalor,{headers});
      return this.ruta;
    }
    listarProdPedidoNotasEntregahttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url145,xvalor,{headers});
      return this.ruta;
    }
    listarNotaCreditohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url134,xvalor,{headers});
      return this.ruta;
    }
    listarNotaDebitohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url152,xvalor,{headers});
      return this.ruta;
    }
    listarGuiaDespachohttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url308,{headers});
      return this.ruta;
    }
    listarGuiaDespachoChttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url128,{headers});
      return this.ruta;
    }
    listarGuiaDespachoFacturahttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url309,xvalor,{headers});
      return this.ruta;
    }
    ConsultarGuiaDespachoChttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url129,xvalor,{headers});
      return this.ruta;
    }
    listarGuiaDespachoConsignacionhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url125,xvalor,{headers});
      return this.ruta;
    }
    listarProdPedidoParamhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url91,xvalor,{headers});
      return this.ruta;
    }
    listarPedidoshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url50, xvalor,{headers});
      return this.ruta;
    }
    listarPedidosFhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url51,xvalor,{headers});
      return this.ruta;
    }
    listarPedidosPFhttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url421,{headers});
      return this.ruta;
    }
    listarProductoMateriaPrimahttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url64,{headers});
      return this.ruta;
    }
    listar_menu_acciones_apphttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url171,{headers});
      return this.ruta;
    }
    listar_submenu_accioneshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url176,xvalor,{headers});
      return this.ruta;
    }
    listarTipoTareashttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url155,{headers});
      return this.ruta;
    }
    listarMateriaPrimaPreparadahttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url88,{headers});
      return this.ruta;
    }
    listarProductoMateriaPrimaPreparadahttp():Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url81,{headers});
      return this.ruta;
    }
    insertarhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url2, xobj,{headers});

      return this.ruta;
    }
    insertarUsuarioshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url11, xobj,{headers});

      return this.ruta;
    }
    gestionMateriaPrimahttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url63, xobj, {headers});

      return this.ruta;
    }
    gestionMateriaPrimaPreparadahttp(xobj:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url82, xobj, {headers});

      return this.ruta;
    }
    gestionMenuhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url14, xobj,{headers});

      return this.ruta;
    }
    gestionUnidadeshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url36, xobj,{headers});

      return this.ruta;
    }
    gestionPedidoshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url48, xobj,{headers});

      return this.ruta;
    }
    gestionNotaCreditohttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url132, xobj,{headers});

      return this.ruta;
    }
    gestionNotaCreditoNotaEntregahttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url329, xobj,{headers});

      return this.ruta;
    }
    gestionNotaCreditoAdminSinglehttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url168, xobj,{headers});

      return this.ruta;
    }
    gestionNotaDeDebitohttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url151, xobj,{headers});

      return this.ruta;
    }
    gestionListaprecioshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url58, xobj,{headers});

      return this.ruta;
    }
    gestionOrdenProdhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url72, xobj,{headers});

      return this.ruta;
    }
    gestionSolicitudTareashttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url158, xobj,{headers});

      return this.ruta;
    }

    gestionSolicitudEvaluarhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url162, xobj,{headers});

      return this.ruta;
    }

    gestionTareasSoportehttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url154, xobj,{headers});

      return this.ruta;
    }
    gestionDifinicionesSistemahttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url188, xobj,{headers});

      return this.ruta;
    }
    gestionDepositoshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url194, xobj,{headers});

      return this.ruta;
    }
    gestionConsignacioneshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url120, xobj,{headers});

      return this.ruta;
    }
    gestionConfirmarConsignacioneshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url124, xobj,{headers});

      return this.ruta;
    }
    ajustardatosOredenProdhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url117, xobj,{headers});

      return this.ruta;
    }
    gestionOrdenesVsRevisionhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url130, xobj,{headers});

      return this.ruta;
    }
    gestionOrdenProdMpphttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url302, xobj,{headers});

      return this.ruta;
    }
    gestionOrdenLabhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url84, xobj,{headers});

      return this.ruta;
    }
    gestionEstatusOrdenhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url186, xobj,{headers});

      return this.ruta;
    }
    gestionTomarSolicitudhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url160, xobj,{headers});

      return this.ruta;
    }
    gestionActualizarComentariosSolicitudhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url165, xobj,{headers});

      return this.ruta;
    }
    gestionFinalizarSolicitudhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url161, xobj,{headers});

      return this.ruta;
    }
    gestionOrdenesMppVsFabricacionhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url98, xobj,{headers});

      return this.ruta;
    }
    gestionOrdenesVsFabricacionhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url100, xobj,{headers});

      return this.ruta;
    }
    gestionOrdenLabMpphttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url305, xobj,{headers});

      return this.ruta;
    }
    gestionInventarioPDhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url68, xobj,{headers});

      return this.ruta;
    }
    gestionInventarioMPhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url69, xobj,{headers});

      return this.ruta;
    }
    gestionFacturashttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url53, xobj,{headers});

      return this.ruta;
    }
    gestionNotasEntregahttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url144, xobj,{headers});

      return this.ruta;
    }
    gestionClienteshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url41, xobj,{headers});

      return this.ruta;
    }
    gestionProdPthttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url79, xobj,{headers});
      return this.ruta;
    }
    buscardefinicionesprodhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url205, xobj,{headers});

      return this.ruta;
    }
    gestionProvhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url78, xobj,{headers});

      return this.ruta;
    }
    gestionFormulahttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url76, xobj,{headers});

      return this.ruta;
    }
    ajustar_almacen_productos_terminadoshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url112, xobj,{headers});

      return this.ruta;
    }
    ajustar_almacen_materia_primahttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url141, xobj,{headers});

      return this.ruta;
    }
    gestionTraslados_PT_SGhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url113, xobj,{headers});

      return this.ruta;
    }
    liberarProdTerminadohttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url139, xobj,{headers});

      return this.ruta;
    }
    ajustar_almacen_productos_terminados_splhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url115, xobj,{headers});

      return this.ruta;
    }
    ajustar_almacen_productoshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url196, xobj,{headers});

      return this.ruta;
    }
    gestionFormulaMpphttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url86, xobj,{headers});

      return this.ruta;
    }
    gestionRoleshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url18, xobj,{headers});

      return this.ruta;
    }
    gestionRolemenuhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url22, xobj,{headers});

      return this.ruta;
    }
    gestionUsuarioRolhttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url25, xobj,{headers});

      return this.ruta;
    }
    gestionPermisosaccioneshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url173, xobj,{headers});

      return this.ruta;
    }
    gestionConceptosDefinicioneshttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url190, xobj,{headers});

      return this.ruta;
    }
    insertarmasivohttp(xobj:any):Observable<any>{
      var body= JSON.stringify(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url8, body,{headers});

      return this.ruta;
    }
    insertarmasivoProductoFactoryhttp(xobj:any):Observable<any>{
      console.log(xobj);
       var body= JSON.stringify(xobj);
      console.log(body);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url94, body,{headers});

      return this.ruta;
    }
    insertarmasivoClientesFactoryhttp(xobj:any):Observable<any>{
      console.log(xobj);
       var body= JSON.stringify(xobj);
      console.log(body);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url105, body,{headers});

      return this.ruta;
    }
    insertarmasivoProveedoresFactoryhttp(xobj:any):Observable<any>{
      console.log(xobj);
       var body= JSON.stringify(xobj);
      console.log(body);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url137, body,{headers});

      return this.ruta;
    }
    insertarmasivoProductoFactorymphttp(xobj:any):Observable<any>{
      console.log(xobj);
       var body= JSON.stringify(xobj);
      console.log(body);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url95, body,{headers});

      return this.ruta;
    }
    eliminarhttp(xobj:any):Observable<any>{
      var body= {id:xobj};
      var json= JSON.stringify(body);

      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url3, json,{headers});

      return this.ruta;
    }
    eliminarUsuarioshttp(xobj:any):Observable<any>{
      var body= {id:xobj};
      var json= JSON.stringify(body);

      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url13, json,{headers});

      return this.ruta;
    }
    eliminarmenuhttp(xobj:any):Observable<any>{
      var body= {cod_menu:xobj};
      var json= JSON.stringify(body);
     console.log(json);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url16, json,{headers});

      return this.ruta;
    }
    eliminarunidadhttp(xobj:any):Observable<any>{
      var body= {cod_unidad:xobj};
      var json= JSON.stringify(body);
     console.log(json);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url35, json,{headers});

      return this.ruta;
    }
    eliminarclienteshttp(xobj:any):Observable<any>{
      var body= {cod_cliente:xobj};
      var json= JSON.stringify(body);
     console.log(json);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url42, json,{headers});

      return this.ruta;
    }
    eliminarrolhttp(xobj:any):Observable<any>{
      var body= {cod_rol:xobj};
      var json= JSON.stringify(body);
     console.log(json);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url21, json,{headers});

      return this.ruta;
    }
    eliminarrolmenuhttp(xobj:any):Observable<any>{
      var body= {cod_rol_menu:xobj};
      var json= JSON.stringify(body);
     console.log(json);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url23, json,{headers});

      return this.ruta;
    }
    eliminarusuariorolmenuhttp(xobj:any):Observable<any>{
      var body= {cod_usuario_rol:xobj};
      var json= JSON.stringify(body);
     console.log(json);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url31, json,{headers});

      return this.ruta;
    }
    gestionarregistroshttp(xobj:any):Observable<any>{
      console.log(xobj);
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post(this.url4, xobj,{headers});
      console.log(this.ruta, 'en el observador');
      return this.ruta;
    }
    buscarROLMENUhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url24, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarROLsubMENUhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url30, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarUSUARIOROLhttp(xvalor:any):Observable<any>{
      var val_red = this.validar_conexion();
        if (val_red) {
          let headers = new HttpHeaders().set('Content-Type','application/json');
          var body= {cod_usuario:xvalor};
          this.ruta = this.postrest.post<any>(this.url28, body,{headers});
          return this.ruta;
        }else{
          this.snackBar.open('La red esta presentando problemas de conexión', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
            return this.ruta;
        }
     }
     buscarInvPorRangoFechahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      var body= {cod_solicitud_padre:xvalor};
      this.ruta = this.postrest.post<any>(this.url166, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProdVentasPorRangoFechahttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      var body= {cod_solicitud_padre:xvalor};
      this.ruta = this.postrest.post<any>(this.url167, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarTareaHijahttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      var body= {cod_solicitud_padre:xvalor};
      this.ruta = this.postrest.post<any>(this.url157, body,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarUSUARIOROLsubmenuhijohttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url33, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarUSUARIOROLparamhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      var body= {xvalor};
      this.ruta = this.postrest.post<any>(this.url32, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarMENUhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url26, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarUnidadhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url37, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarClienteshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url43, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarComentarioSolicitudhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url164, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarFormulahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url77, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPermisoshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url170, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPermisosAccioneshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url172, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarConceptosDefinicioneshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url189, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarMENUDIRhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      var body= {cod_menu:xvalor};
      this.ruta = this.postrest.post<any>(this.url29, body,{headers});
      return this.ruta;
     }
     buscarMENUparamRhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url38, xvalor,{headers});
      return this.ruta;
     }
     buscarPRODUCTOTparamhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url45, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPRODUCTOTcodttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url46, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarLprecioshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url80, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarLpreciosRelacionhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url90, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPRODUCTOTttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url49, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoMasivohttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url425, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPRODUCTOTDivisahttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url104, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductotcodBolivaressinIvanhttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url107, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPRODUCTOBttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url71, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProdMpEntradahttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url136, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPRODUCTOB2ttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url97, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoMpphttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url301, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPRODUCTOmpttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url75, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPedidoshttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url55, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarFormulaProdhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url83, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarFormulaprod_confirmarhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url200, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarDespositoFormulahttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url199, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarDespositoFormulaMpphttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url202, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     cambiarEstatusOrdenProdVhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url182, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     cambiarEstatusOrdenProdMppVhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url184, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     activarFormulasProdhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url181, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProdConsighttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url127, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProdInvSGhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url111, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProdInvPThttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url114, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProdInvSPCLhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url111, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarOrdenMppProdhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url96, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarOrdenPTProdhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url99, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarCodControlFacthttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url109, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarCodControlNothttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url131, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarHashKeyhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url110, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarFormulaProdMpphttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url304, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarFormulaprodMpp_confirmarhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url203, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoPedidohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url92, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoConsignacionhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url123, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoOrdenesProdhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url73, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoOrdenesProdMpphttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url303, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoOrdenesProdMpp2http(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url96, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoOrdenesProdPthttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url118, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscardatosOredenProdhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url116, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoOrdenesProdMppConfirmhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url310, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarOrdenProdhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url74, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarOrdenProdPthttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url119, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }

     buscarLOTEcodttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url47, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarClienteConsigttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url121, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarPedidoProductocodttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url89, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarContadorFacturaNotaCreditohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url133, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarInventarioSPLhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url103, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoDepositohttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url195, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     buscarProductoMovimientoshttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      this.ruta = this.postrest.post<any>(this.url197, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
     loginhttp(xvalor:string):Observable<any>{
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url27, xvalor,{headers});
          return this.ruta;
     }
 
     consultar_sesionhttp(xvalor:any):Observable<any>{
       try {
        var key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                                                cod_menu: localStorage.getItem('sub_main'),
                                                cod_sesion: localStorage.getItem('cod_sesion'),
                                                dir_ip_client: localStorage.getItem('dir_ip'),
                                                sesion_unica:sessionStorage.getItem('sesion_unica'),
                                                rlsr: localStorage.getItem('rlsr'),
                                                lcnc: localStorage.getItem('lcnc'),
                                                app: localStorage.getItem('app')
                                              };
        /* var respuesta =  (navigator.onLine); */
        var respuesta = true;
        if (respuesta) {
          let headers = new HttpHeaders().set('Content-Type','application/json');
          this.ruta = this.postrest.post<any>(this.url174, key_hash,{headers});
          this.ruta.subscribe(
                response=>{
                  console.log(response);
                },
              async  error =>{
                    console.log(error.error);
                }
          );
          return this.ruta;
        }else{
          alert('conexion caida');
          return this.ruta;
        }
       } catch (error) {
         console.log(error);
       }
        
     }
     
     cerrar_sesionhttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url175, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }
    buscardatoshttp(xvalor:string):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url6, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     /*  let arry:any=[];
      termino = termino.toLowerCase();

      for (let item of this.contenido) {
         let nombre:string = item.nombre.toLowerCase();
         let fechan:string = item.aparicion;
         let casa:string = item.casa.toLowerCase();
         if(nombre.indexOf(termino) >= 0 || fechan.indexOf(termino) >= 0 || casa.indexOf(termino)>=0){
             arry.push(item);
         }
      }
      return arry; */
     }
     buscarFormulaMpphttp(xvalor:any):Observable<any>{
      let headers = new HttpHeaders().set('Content-Type','application/json');
      console.log(xvalor);
      this.ruta = this.postrest.post<any>(this.url87, xvalor,{headers});
      console.log(this.ruta);
      return this.ruta;
     }

     guardarimghttp( xobj:any): Observable<any> {
        let headers = new HttpHeaders().set('Content-Type','application/json');
            this.ruta = this.postrest.post(this.url7, xobj,{headers});
            console.log(this.ruta );
        return this.ruta;
      }
    cargarImagenesFirebase( imagenes: FileItem[]) {
      var acceso:boolean = false;
      var contenedor:any = [];
      var img = [];
      for (const item of imagenes) {
        img.push(item.nombreArchivo);
        if(item.colorfondo == undefined)
        {
          item.colorfondo = '';
          console.log(item.colorfondo,'seteando el undefined');
        }
        contenedor.push(item);

      }
      for (const i in img) {
        console.log(img[i], 'escaneo de img');
        var consulta =  this.listarimghttp(img[i]).subscribe(
          response =>{
            console.log(response);
            if(response.length > 0){
              this.snackBar.open('La imagen ya existe en el servidor', 'Cerrar',{duration:2000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,})
            }else{

              if (response.length <= 0) {
                console.log(response,'<=0');
                console.log(contenedor[i].archivo,'iiiiiiii');
                 const storageRef = firebase.storage().ref();

                 contenedor[i].estaSubiendo = true;
                  if ( contenedor[i].progreso >= 100 ) {
                    return;
                  }
                  const uploadTask: firebase.storage.UploadTask =
                              storageRef.child(`${ this.CARPETA_IMAGENES }/${ contenedor[i].nombreArchivo }`)
                                        .put( contenedor[i].archivo );
                  uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED,
                    ( snapshot: firebase.storage.UploadTaskSnapshot ) =>
                    contenedor[i].progreso = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100,
                    ( error ) => console.error('Error al subir', error,' error aqui en el servicio' ),
                    () => {
                      console.log('Imagen cargada correctamente');
                        uploadTask.snapshot.ref.getDownloadURL().then((result)=>{
                          let  mensaje = result;
                          console.log(mensaje, 'dieccion de firebase');
                          contenedor[i].url = mensaje;
                          contenedor[i].estaSubiendo = false;
                          this.guardarImagen({
                            nombre: contenedor[i].nombreArchivo,
                            url: contenedor[i].url,
                            tipo: contenedor[i].tipo,
                            nivel:contenedor[i].nivel,
                            enlace:contenedor[i].enlace,
                            texto:contenedor[i].texto,
                            color:contenedor[i].color,
                            colorf:contenedor[i].colorf,
                            colorfondo:contenedor[i].colorfondo,
                            textob:contenedor[i].textob
                            });
                          })
                        });
                }
              }
          },
          error =>{
            console.log(error);
            var err=<any>error.error[0];
            this.snackBar.open(err.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,})
          }
        );
      }
      ////Se consulta la bd, si existe una img con en mismo nombre.

      /////evitar carga duplicada en el firestorage
      //////////////////////////////////////////

    }
    private guardarImagen( imagen: { nombre: string, url: string, tipo:string, nivel:number,enlace:string, texto:string, color:string, colorf:string, colorfondo:string, textob:string } ) {
      console.log(imagen);
       let res =  this.guardarimghttp(imagen).subscribe(
        response =>{
          if(response){
                this.snackBar.open(response.message, 'Cerrar',{duration:1000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,})
          }
        },
        error =>{
          var err=<any>error.error;
          this.snackBar.open(err.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
        );
      this.db.collection(`/${ this.CARPETA_IMAGENES }`)
              .add( imagen );
    }
    //////prueba excel
    public processFileToJson(object,file): Observable<any> {
      let reader = new FileReader();
      let _this = this;
      return Observable.create(observer => {
          reader.onload = function (e) {
              let data = e.target['result'];
              let workbook = XLSX.read(data, {
                  type: 'binary'
              });
              object.sheets = _this.parseWorksheet(workbook, true, true);
              observer.next(object);
              observer.complete();
          }
          reader.readAsBinaryString(file);
      });
    }
    parseWorksheet(workbook, readCells, toJSON) {
      if (toJSON === true) {
          return this.to_json(workbook);
      }
      let sheets = {};
      _.forEachRight(workbook.SheetNames, function (sheetName) {
          let sheet = workbook.Sheets[sheetName];
          sheets[sheetName] = this.parseSheet(sheet, readCells);
      });
      return sheets;
  }
  parseSheet(sheet, readCells) {
    let range = XLSX.utils.decode_range(sheet['!ref']);
    let sheetData = [];
    if (readCells === true) {
        _.forEachRight(_.range(range.s.r, range.e.r + 1), function (row) {
            let rowData = [];
            _.forEachRight(_.range(range.s.c, range.e.c + 1), function (column) {
                let cellIndex = XLSX.utils.encode_cell({
                    'c': column,
                    'r': row
                });
                console.log(cellIndex);
                let cell = sheet[cellIndex];
                rowData[column] = cell ? cell.v : undefined;
            });
            sheetData[row] = rowData;
        });
    }
    return {
        'sheet': sheetData,
        'name': sheet.name,
        'col_size': range.e.c + 1,
        'row_size': range.e.r + 1
    }
}
to_json(workbook) {
  let result = {};
  workbook.SheetNames.forEach(function (sheetName) {
      let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      if (roa.length > 0) {
          result[sheetName] = roa;
      }
  });
  return result;
}

async validar_conexion(){
  try {
    let headers = new HttpHeaders().set('Content-Type','application/json');
    var conexionOn =  await this.postrest.post<any>(this.url_conexion,{headers}).toPromise().then((data)=>{return data});
    return this.obtener_valor_red(conexionOn.conexion);
      } catch (error) {
        console.log(error);
        this.obtener_valor_red(false);
      }
    }
    obtener_valor_red(objeto){
      this.conexion_red = objeto;
      console.log(this.conexion_red);
      return this.conexion_red;
    }
 }
