import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { FormPermisosAccionesComponent } from '../form-permisos-acciones/form-permisos-acciones.component';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import * as XLSX from 'xlsx';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}
export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-import-masivo-asistencia',
  templateUrl: './form-import-masivo-asistencia.component.html',
  styleUrls: ['./form-import-masivo-asistencia.component.css']
})
export class FormImportMasivoAsistenciaComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('descrip_definicion') descrip_definicion:ElementRef;
  @ViewChild('cant_cuenta_gasto',{static:true}) cant_cuenta_gasto:ElementRef;

  arry: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
data2: [][];
producto:any = [];
codigo:any = [];
lotepro:any = [];
xobj3:any= [];
cant:any = false;
dialogSpin:any;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private service:nkServices,
    fb:FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog, 
    private servicio:nkServices,
    public dialogRefp: MatDialogRef<FormImportMasivoAsistenciaComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any) {
     
      this.forma = fb.group({
        'salida':false,
        'entrada':false,
        'comedor':false,
      });
      console.log(this.forma,'aqui estamos perro')
     }


  ngOnInit(): void {
    this.formulario()
    /* this.listarPartidasPresupuestarias() */
  }
  
  onFileChange(evt:any){
    const target : DataTransfer = <DataTransfer>(evt.target);

    if(target.files.length !== 1) throw new Error('No se pueden seleccionar multiples archivos');

    const reader : FileReader = new FileReader();

    reader.onload = (e: any) => {
     const bstr : string = e.target.result;

     const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'})

     const wsname : string = wb.SheetNames[0];

     const ws : XLSX.WorkSheet = wb.Sheets[wsname];

     console.log(ws);

     this.data2 = (XLSX.utils.sheet_to_json(ws));

     if(this.data2.length > 0 ){
       for (const i in this.data2) {
         this.ELEMENT_DATA.push(this.data2[i])
       }
       this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
     }
     console.log(this.data2)
    };

    reader.readAsBinaryString(target.files[0]);
  }
  
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
     
  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 );
    }
    console.log(this.stateGroups);
    return this.stateGroups;
 }


 formulario():void{
  this.ELEMENT_DATA = [];
  this.cant = false;
  this.displayedColumns = [
    'cod_usuario',
    'des_usuario',
    'dt_ubicacion',
    'hora_entrada',
    'hora_salida',
    'fecha',
    'remov_elem'
   ];
/*   this.dataSource11 = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  modalrespuesta(){
    try {

        if (this.ELEMENT_DATA.length > 0 ) {
          this.accion = "enviarordenprod";
          var dialogRef = this.dialog.open(FormmMsgComponent, {
            width: '100vw',
              maxWidth: '100vw',
              height:'100%',
            data: {id:'',
                  accion:this.accion},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result != 'cerrar'){
      
             /*  this.ELEMENT_DATA[0].cant_producto =  this.ELEMENT_DATA[0].cant_producto.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.'); */
              console.log(this.ELEMENT_DATA);
              this.forma.value.cuentas = this.ELEMENT_DATA;
              console.log(this.forma.value,'AQUIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII');
              this.dialogRefp.close(this.forma.value);
      
            }
          });
        }else{
         
            this.snackBar.open('Algunos campos estan vacios', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          
        }
      
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  
  eliminarItems(val:any){
    this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
     this.table.renderRows();
   }

  enviar(){
    console.log(this.forma.value)
    console.log(this.ELEMENT_DATA)

    if(!this.ELEMENT_DATA.length){
      return  this.snackBar.open('No hay data', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});

    }

    if(!this.forma.value.salida && !this.forma.value.entrada && !this.forma.value.comedor){

      return  this.snackBar.open('Elige algun dato', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});

    }

    if(this.forma.value.salida){
      for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
       
        if(!this.ELEMENT_DATA[i].hora_salida){
          return  this.snackBar.open('Las Horas de salidas estan vacias', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
        }
        
      }
    }
    if(this.forma.value.entrada){
      for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
       
        if(!this.ELEMENT_DATA[i].hora_entrada){
          return  this.snackBar.open('Las Horas de entradas estan vacias', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
        }
        
      }
    }

    for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
       
      this.ELEMENT_DATA[i].cod_usuario = typeof this.ELEMENT_DATA[i].cod_usuario === 'number' ? this.ELEMENT_DATA[i].cod_usuario.toString() : this.ELEMENT_DATA[i].cod_usuario
      
    }

    this.accion = "enviarordenprod";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
        maxWidth: '100vw',
        height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){

        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });

        this.service.bulkAsistenciahttp(this.ELEMENT_DATA, this.forma.value).subscribe(response => {

          console.log(response)
          console.log('listo')
          this.dialogSpin.close();

          this.ELEMENT_DATA = []

          this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);


            ///////////////////////////ALERTA CODE////////////////////////////
            let alerta = document.querySelector('.alertSuccess') as HTMLDivElement
                
            alerta.classList.add('show')
          
            setTimeout(() => {
          
              alerta.classList.remove('show')
          
            }, 3000);
            ///////////////////////////ALERTA CODE////////////////////////////
 
    
        },
        error =>{
          console.log(error)
          this.dialogSpin.close();
          return  this.snackBar.open(error.errno.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        })
      }
    });

   

  }
  
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  }
  
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource1.filter = filterValue.trim().toLowerCase();
    }

    ponerPuntosyComas(event:any){
    let validacion = '1234567890'
    console.log(event.key)
    let inputCantidad = document.querySelector('#cantidad') as HTMLInputElement
    if(inputCantidad.value.length == 1){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length <= 2){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length >= 4){
      let noa:any = inputCantidad.value.split('')
      for(let i = 0 ; i < 2; i++){
        if(noa[0] == 0){
          noa.shift()
        }else{
          break;
        }
      }
      inputCantidad.value = noa.join('')
    }
    var num = inputCantidad.value.replace(/\D/g,'');
    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    num = num.split('').reverse().join('').replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    inputCantidad.value = num;
    if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
      this.snackBar.open('Solo numeros', 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
