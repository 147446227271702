import { Component, OnInit } from '@angular/core';
import { HashKeyServices } from '../servicios/app.hashkey.services';
import { AppUtilService } from '../servicios/app.util.service';
import { AtributosService } from '../servicios/atributos.service';
import { nkServices } from '../servicios/app.nkservicios.services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  
  fecha:any;
  usuario:any;
  unidad:any;
  dpto:any;
  licencia:any;
  empresa:any;
  perfil:any;
  constructor(
    private servicio:nkServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices
  ) { }

 async ngOnInit(){
    this.fecha = await this.atrb.FECHA.yyyy;
    this.usuario = await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre) +' '+ await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido);
    this.unidad = await this.hashkey.decrypt(this.atrb.UNIDAD_USUARIO.unidad);
    this.dpto = await this.hashkey.decrypt(this.atrb.DPTO_USUARIO.dpto);
    this.perfil = await this.hashkey.decrypt(this.atrb.COD_PERFIL.cod_perfil);
    this.licencia = await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario);
    await this.consultarUsuarios();
  }

  async consultarUsuarios(){
    try {
      if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) === 2 || 
          parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) === 0 ) {
        let xvalor = {
           api: await this.hashkey.encrypt(await this.atrb.API_REST.consultarUsuarios),
           cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa,
           cod_usuario: await this.atrb.COD_USUARIO.cod_usuario
         }
         const response = await this.servicio.ApiResthttp(xvalor).toPromise();
         this.empresa = response[0].nombre;
     }
     if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) === 3 || 
         parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) === 1) {
      let xvalor = {
         api: await this.hashkey.encrypt(await this.atrb.API_REST.consultarUsuarios),
         cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa,
         cod_usuario: await this.atrb.COD_EMPRESA.cod_empresa
       }
       const response = await this.servicio.ApiResthttp(xvalor).toPromise();
       this.empresa = response[0].nombre;
   }
     
    } catch (error) {
        console.log(error);
    }
  }
}
