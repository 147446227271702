<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav_componente nav py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarclientesp("actualizar")'><mat-icon >sync</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>GESTIONAR PEDIDOS</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table">
        <div class="col-md-12 px-0  border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped  table-condensed table-hover">

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Rif</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_cliente}}</td>

                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style" style=" font-size: 12px; text-align: center;"> {{element.des_cliente}}</td>
                </ng-container>

                <ng-container matColumnDef="tipo_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="des_vendedor">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Vendedor</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_vendedor}} </td>

                </ng-container>

                <ng-container matColumnDef="estatus_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="tipo2_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Clase</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo2_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="credito_dias_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Credito</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.credito_dias_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="reten_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Retenciones</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.reten_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="mod_factur">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>UM</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.mod_factur}} </td>

                </ng-container>

                <ng-container  matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Procesos</strong></h5>
                    </th>
                  
                    <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="col-md-3">
                        <button class=" bg-primary mr-1" mat-mini-fab (click)='tomar_pedido_cliente(element,"pre_forma")' matTooltip="PRO FORMA">
                            <mat-icon >note_add</mat-icon>
                        </button>
                        <button class=" bg-danger mr-1" mat-mini-fab (click)='tomar_pedido_cliente(element,"factura")' matTooltip="FACTURA">
                            <mat-icon >note_add</mat-icon>
                        </button>
                        <button class=" bg-info mr-1" mat-mini-fab (click)='tomar_pedido_cliente(element,"notaNB")' matTooltip="NOTA BS">
                            <mat-icon >note_add</mat-icon>
                        </button>
                        <button class=" bg-success mr-1" mat-mini-fab (click)='tomar_pedido_cliente(element,"notaND")' matTooltip="NOTA $">
                            <mat-icon >note_add</mat-icon>
                        </button>
                        <button class=" bg-dark mr-1" mat-mini-fab (click)='consulta_back(element)' matTooltip="BACKORDER">
                            <mat-icon >shopping_cart</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>