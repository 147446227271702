import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable, of} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { Element } from '@angular/compiler';
import { element, error } from 'protractor';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../servicios/formato_datapick';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment'

@Component({
  selector: 'app-form-recepcion-factura',
  templateUrl: './form-recepcion-factura.component.html',
  styleUrls: ['./form-recepcion-factura.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class FormRecepcionFacturaComponent implements OnInit {

  dialogSpin:any;
  accion:any;
  forma:FormGroup;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  aprobar:any=false
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`
  Unidad:string = localStorage.getItem('Unidad')
  centro_costo:string;
  partidas:any;
  AlertMensaje:string;
  departamento:string;
  alertaDanger = document.querySelector('.alert-danger') as HTMLDivElement;
  reten_prov: any;
  iva:any = 0;
  ret_iva:any = 0 ;
  total:any = 0;
  subtotalIva:any= 0;
  subtotal:any= 0;
  flete:any =0;
  tipoDocus:any;
  moneda:any;
  archivo : any = [];
  previsualizacion: string;
  tipoDoc:any = [];
  cod_menu:any =localStorage.getItem('sub_main')
  

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor( public dialog: MatDialog,fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormRecepcionFacturaComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices,private sanitizer: DomSanitizer) {

      console.log(data,'la dataaa')

      this.moneda = data.orden.moneda

      console.log(this.cod_menu,'this.cod_menu')

      this.listarDefiniciones(this.cod_menu)
        
      this.forma = fb.group({
        'cod_documento':['', [Validators.required]],
        'cod_control':'',
        'fecha_emision':['', [Validators.required]],
        'fecha_recepcion':['', [Validators.required]],
        'flete':[''],
        'sub_total':[''],
        'iva':'',
        'ret_iva':'',
        'tipo_documento':['', [Validators.required]],
        'total':'',
      });
    }

  ngOnInit(): void {
    this.servicio.comprobarProveedorhttp(this.data.orden.cod_proveedor).subscribe(result => {

      this.reten_prov = result.proveedor.reten_prov == '75%' ? 0.25 : 1
      console.log(result)


    })

  }

  tipoDocu(e:any){

    console.log(e);

    this.tipoDocus = e.value

  }

  listarDefiniciones(objeto:any){
    try {
      var xvalor ={cod_menu:objeto};

      console.log(objeto);
        this.servicio.listarDefinicionesConceptoshttp(xvalor).subscribe(
          response =>{
            console.log(response);

            for (const i in response) {
    
              if (response[i].des_definicion.toUpperCase() == 'tipo de documento'.toUpperCase()) {
                this.tipoDoc.push(response[i]);
              }

            }
            
          },
          error =>{
            console.log(<any>error.error.message,'error definiciones');
          }
        );
        return null;
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
 
  ponerPuntosyComas(event:any, id:any){

   console.log(this.forma)

    let validacion = '1234567890'

    let inputCantidad = document.querySelector(`#${id}`) as HTMLInputElement
    let inputIva = document.querySelector(`#iva`) as HTMLInputElement
    let inputRetIva= document.querySelector(`#ret_iva`) as HTMLInputElement
    let inputTotal = document.querySelector(`#total`) as HTMLInputElement

    if(inputCantidad.value.length == 1){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
      
    }else if(inputCantidad.value.length == 2){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length == 3){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length >= 5 ){
      let noa:any = inputCantidad.value.split('')
      for(let i = 0 ; i < 2; i++){
        if(noa[0] == 0){
          noa.shift()
        }else{
          break;
        }
      }
      inputCantidad.value = noa.join('')
    }

    var num = inputCantidad.value.replace(/\D/g,'');

    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    num = num.split('').reverse().join('').replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1.$2')
    inputCantidad.value = num;

    if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){

      return this.snackBar.open('Solo numeros', 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
       verticalPosition: this.verticalPosition,});
    }

    if(this.tipoDocus != "NE"){

      if(id == 'sub_total'){

        this.subtotal = num
        this.iva = (parseFloat(num) * 0.16).toFixed(2)
        this.forma.controls['iva'].setValue(this.iva)
        this.ret_iva = (parseFloat(this.iva) * parseFloat(this.reten_prov)).toFixed(2)
        this.forma.controls['ret_iva'].setValue(this.ret_iva)
        this.subtotalIva = (Number(num) + Number(this.ret_iva)).toFixed(2)
        this.total = (parseFloat(this.flete) + parseFloat(this.subtotalIva)).toFixed(2)
        this.forma.controls['total'].setValue(this.total) 
        
      }

    }else{

      if(id == 'sub_total'){

        this.subtotal = Number(num)
        this.total = (parseFloat(this.flete) + parseFloat(this.subtotal)).toFixed(2)
        this.forma.controls['total'].setValue(this.total)
  
      }

    }

    

    if(id == 'flete'){

      this.flete = Number(num)
      this.total = (parseFloat(this.flete)+ parseFloat(this.subtotalIva)).toFixed(2)
      this.forma.controls['total'].setValue(this.total)
      
    }

  }

  tomarFoto(event:any){

    let archivoCapturado = event.target.files[0]

    this.extraerBase64(archivoCapturado).then((imagen: any) => {
      this.previsualizacion = imagen.base;
      console.log(imagen);

    })
    this.archivo.push(archivoCapturado)

    console.log(event.target.files[0])

  }

  extraerBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };

    } catch (e) {
      return null;
    }
  })

  

   listo(){

    
    this.servicio.verificarFacturahttp(this.forma.value.cod_factura).subscribe(resposnse => {

    
      if(!this.archivo.length){
        return this.snackBar.open('Debe tener la foto de la factura', 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }

      var dialogRefx = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
                maxWidth: '100vw',
                height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRefx.afterClosed().subscribe(result => {
  
        if(result != 'cerrar'){

          let inputFechaEmision= document.querySelector(`#fecha_emision`) as HTMLInputElement
          let inputFechaRecepcion = document.querySelector(`#fecha_recepcion`) as HTMLInputElement
      
          this.forma.value.iva = this.iva
          this.forma.value.ret_iva = this.ret_iva
          this.forma.value.total = this.total
          this.forma.value.subtotalIva = this.subtotalIva
          this.forma.value.subtotal = this.subtotal
          this.forma.value.imagen = this.archivo[0]
          this.forma.value.fecha_emision = inputFechaEmision.value
          this.forma.value.fecha_recepcion = inputFechaRecepcion.value
          this.forma.value.moneda = this.moneda
          this.forma.value.usuario = {
            cod_usuario: localStorage.getItem('cod_usuario'),
            des_usuario: this.NombreUsuario  
          }
          this.forma.value.cod_orden_compra = this.data.cod_orden_compra
          this.forma.value.proveedor = {
            cod_proveedor:this.data.orden.cod_proveedor,
            des_proveedor:this.data.orden.des_proveedor
          }

          this.data.factura = this.forma.value
          
  
          let laData = JSON.stringify(this.data)
  
          var formData = new FormData();
          formData.append("imagen", this.archivo[0])
          formData.append("data", laData)
  
          
  
          this.servicio.crearRecepcionDOrdenDComprahttp(formData).subscribe(result=> {
            if(result != 'cerrar' ){
  
              this.dialogRefp.close()
  
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          })

  
        
        }
  
      })

    },
    error =>{

      return this.snackBar.open(error.error.message, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});

    })

   
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {

        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
