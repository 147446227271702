import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perifericos',
  templateUrl: './perifericos.component.html',
  styleUrls: ['./perifericos.component.css']
})
export class PerifericosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
