import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';

@Component({
  selector: 'app-form-tralado-inv',
  templateUrl: './form-tralado-inv.component.html',
  styleUrls: ['./form-tralado-inv.component.css']
})
export class FormTraladoInvComponent implements OnInit {

  @ViewChild('cant_inv_sg',{static:true}) cant_inv_sg: ElementRef;
  @ViewChild('cantidad',{static:true}) cantidad: ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
operacion_cargo:any;
operacion_descargo:any;
operacion_inicio:any;
cliente:any;
inventario:any;
dialogSpin:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
mensaje:any;
formato_cantidad:any;
nombre_producto:any;
codigo_producto:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
objectURL:any;
archivo:File;
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  msg:any;
  telRegex:any = /\d{2}-\d{5}\d/;

    constructor(
      private snackBar: MatSnackBar, 
      private dialog: MatDialog, 
      private fb:FormBuilder, 
      private servicio:nkServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private hashkey:HashKeyServices,
      private dialogRefp: MatDialogRef<FormTraladoInvComponent>,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data, 'primero');
        this.objectURL = this.atrb.IMAGENES_FIJAS.sin_img_prod;
        this.accion = data.accion;
        this.cliente = this.data.des_cliente;
        this.inventario = this.data.objeto;
        this.operacion_inicio = this.data.tipo_operacion.toUpperCase();
        this.mensaje = this.atrb.MENSAJES_FIJOS.proceso_descargo;
        if (this.inventario.tipo_producto.toUpperCase() === this.atrb.TIPO_PRODUCTO.MPP || 
            this.inventario.tipo_producto.toUpperCase() === this.atrb.TIPO_PRODUCTO.MP) {
             this.formato_cantidad = true;
        }else{
             this.formato_cantidad = false;
        }
            if (this.data.accion.toUpperCase() === this.atrb.CUARENTENA.toUpperCase()) {
              this.forma = fb.group({
                'cod_usuario':'',
                'des_usuario':'',
                'cod_cliente':data.cod_cliente,
                'des_cliente':data.des_cliente,
                'cod_producto':data.cod_producto,
                'des_producto':data.des_producto,
                'cant_producto':['',[Validators.required]],
                'cant_producto_pt':this.data.cant_producto,
                'cant_producto_sg':this.data.cant_producto,
                'cod_lote':this.data.cod_lote,
                'cod_orden_producto':this.data.cod_orden_producto,
                'val_def':'',
                'tipo_operacion':'',
                'cod_empresa': '',
                'formdata':''
              });
            }else{
              if (this.operacion_inicio === this.atrb.CARGOS.toUpperCase()) {
                this.operacion_cargo = this.atrb.CARGOS.toUpperCase();
                this.forma = fb.group({
                  'cod_usuario':'',
                  'des_usuario':'',
                  'cod_cliente':data.cod_cliente,
                  'des_cliente':data.des_cliente,
                  'cod_producto':data.cod_producto,
                  'des_producto':data.des_producto,
                  'cant_producto':['',[Validators.required, Validators.maxLength(12)]],
                  'comentarios':['',[Validators.required, Validators.maxLength(200)]],
                  'cant_producto_pt':this.data.cant_producto,
                  'cant_producto_sg':this.data.cant_producto,
                  'cod_lote':this.data.cod_lote,
                  'cod_orden_producto':this.data.cod_orden_producto,
                  'val_def':'',
                  'tipo_operacion':'',
                  'img':['',[Validators.required]],
                  'cod_recepcion':['',[Validators.required]],
                  'cod_empresa': '',
                  'formdata':''
                });
              }
              if (this.operacion_inicio === this.atrb.DESCARGOS.toUpperCase()) {
                this.operacion_descargo = this.atrb.DESCARGOS.toUpperCase();
                this.forma = fb.group({
                  'cod_usuario':'',
                  'des_usuario':'',
                  'cod_cliente':data.cod_cliente,
                  'des_cliente':data.des_cliente,
                  'cod_producto':data.cod_producto,
                  'des_producto':data.des_producto,
                  'cant_producto':['',[Validators.required, Validators.maxLength(12)]],
                  'comentarios':['',[Validators.required, Validators.maxLength(200)]],
                  'cant_producto_pt':this.data.cant_producto,
                  'cant_producto_sg':this.data.cant_producto,
                  'cod_lote':this.data.cod_lote,
                  'cod_orden_producto':this.data.cod_orden_producto,
                  'val_def':'',
                  'tipo_operacion':'',
                  'cod_empresa': '',
                  'formdata':''
                });
              }
            }
      }

    ngOnInit(): void {
      this.codigo_producto = this.data.cod_producto;
      this.nombre_producto = this.data.des_producto;
    }

    buscarProdInvSG(xvalor:any){
      var valor = {cod_producto: xvalor}
      try {
          this.servicio.buscarProdInvSGhttp(valor).subscribe(

            response =>{

              this.cant_inv_sg.nativeElement.value = response[0].cant_producto;

            },
            error =>{
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }

          );
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
    buscarProdInvPT(xvalor:any){
      var valor = {cod_producto: xvalor}
      try {
          this.servicio.buscarProdInvPThttp(valor).subscribe(

            response =>{

              this.cant_inv_sg.nativeElement.value = response[0].cant_producto;

            },
            error =>{
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }

          );
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
    buscarProdInvSPCL(xvalor:any){
      var valor = {cod_producto: xvalor,cod_cliente:this.data.cod_cliente}
      try {
          this.servicio.buscarProdInvSPCLhttp(valor).subscribe(

            response =>{

              this.cant_inv_sg.nativeElement.value = response[0].cant_producto;

            },
            error =>{
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }

          );
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
  async  modalrespuesta(){
      try {
        if (parseInt(this.forma.value.cant_producto) > 0 ) {
        var accion2 = 'traslados';
        var dialogRefp = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
          data: {id:this.data.id_cliente,
                accion:accion2},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRefp.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            if (this.data.accion.toUpperCase() ===  await this.atrb.CUARENTENA.toUpperCase()) {
              await this.util.cerrarSpinner();
            this.forma.patchValue({
              cod_usuario: await this.atrb.COD_USUARIO.cod_usuario,
              des_usuario: await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
              val_def :''
            });
           this.dialogRefp.close(this.forma.value);
            }else{
              await this.util.cerrarSpinner();
              const formData = new FormData();
              formData.append('fac_img',this.archivo);
            this.forma.patchValue({
              cod_usuario: await this.atrb.COD_USUARIO.cod_usuario,
              des_usuario: await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
              val_def :this.data.objeto.tipo_producto,
              cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
              formdata:formData
            });
           this.dialogRefp.close(this.forma.value);
            }
          }else{
            await this.util.cerrarSpinner();
          }
        });
        }else{
            if (isNaN(parseInt(this.forma.value.cant_producto))) {
              await this.util.notificacionErrorApp(await this.atrb.MENSAJES_FIJOS.solo_numeros);
            }else{
              await this.util.notificacionErrorApp(await this.atrb.MENSAJES_FIJOS.cantidad_mayor_0);
            }
        }
      } catch (error) {
        console.log(error);
      }
    }
    actualizardatos(res:any){

      if(res == 'actualizar'){
        console.log(this.forma.value);
        this.dialogRefp.close(this.forma.value);
      }else{

      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
      }

    }
  async cargar_img(xvalor:any){
      try {
        await this.util.abrirSpinner();
        this.objectURL = URL.createObjectURL(xvalor.target.files[0]);
       this.archivo = xvalor.target.files[0];
      await this.util.cerrarSpinner();
      } catch (error) {
        await this.util.cerrarSpinner();
        console.log(error);
      }
    }
  async format(event: any, valor:any, termino:any)
  {
    try {
      var num;
      if (termino.toUpperCase() !== this.atrb.BASE.toUpperCase() &&
          termino.toUpperCase() !== this.atrb.TASA.toUpperCase() &&
          termino.toUpperCase() !== this.atrb.DESCUENTO.toUpperCase()
          ) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
      if (termino.toUpperCase() === this.atrb.BASE.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        num = inputId.value.replace(/\D/g,'');
        if(!isNaN(parseFloat(num))){
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/\D/g, "")
                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          this.forma.patchValue({cant_producto:num});
          inputId.value = num;
          }else{ 
            inputId.value = inputId.value.replace(/[^\d\.]*/g,'');
          }
      }
      if (termino.toUpperCase() === this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
    } catch (error) {
      console.log(error);
    }
  }
    cerrarmodal(){
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRefp.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    }
}
