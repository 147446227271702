import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { FormPermisosAccionesComponent } from '../form-permisos-acciones/form-permisos-acciones.component';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import * as XLSX from 'xlsx';


export interface StateGroup {
  letter: any;
  names: any;
  code: any
}
export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};


@Component({
  selector: 'app-form-crear-cuentas',
  templateUrl: './form-crear-cuentas.component.html',
  styleUrls: ['./form-crear-cuentas.component.css']
})
export class FormCrearCuentasComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('descrip_definicion') descrip_definicion:ElementRef;
  @ViewChild('cant_cuenta_gasto',{static:true}) cant_cuenta_gasto:ElementRef;

  arry: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
xobj3:any= [];
cant:any = false;
dialogSpin:any;
lote:any = true;
des:any;
data2:any [][];
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


   constructor(private service:nkServices,
     fb:FormBuilder,
     private snackBar: MatSnackBar,
     public dialog: MatDialog, 
     private servicio:nkServices,
     public dialogRefp: MatDialogRef<FormCrearCuentasComponent>,
 
     @Inject(MAT_DIALOG_DATA) public data: any) {
      
       this.forma = fb.group({
         'cod_centro': data.cod_centro,
         'cod_usuario':localStorage.getItem('cod_usuario'),
         'des_usuario':localStorage.getItem('Nombre'),
         'cod_unidad':data.cod_unidad,
         'des_unidad':data.des_unidad,
         'cuentas':'',
         'descrip_definicion':'',
         'cant_cuenta_gasto': '',
       });
       console.log(this.forma,'aqui estamos perro')
      }

     

   ngOnInit(): void {
    this.formulario()
     this.listarCuentas()
   }

   onFileChange(evt:any){
     const target : DataTransfer = <DataTransfer>(evt.target);

     if(target.files.length !== 1) throw new Error('No se pueden seleccionar multiples archivos');

     const reader : FileReader = new FileReader();

     reader.onload = (e: any) => {
      const bstr : string = e.target.result;

      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'})

      const wsname : string = wb.SheetNames[0];

      const ws : XLSX.WorkSheet = wb.Sheets[wsname];

      console.log(ws);

      this.data2 = (XLSX.utils.sheet_to_json(ws));

      if(this.data2.length > 0 ){
        for (const i in this.data2) {
          this.ELEMENT_DATA.push(this.data2[i])
        }
        this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      }
      console.log(this.data2)
     };

     reader.readAsBinaryString(target.files[0]);
   }
   
   @ViewChild(MatPaginator) paginator: MatPaginator;
 
   ngAfterViewInit() {
     
   }

   private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 );
    }
    console.log(this.stateGroups);
    return this.stateGroups;
 }

   listarCuentas(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarPartidasPresupuestariashttp().subscribe(
      response =>{

        this.arry = this.forma.get('descrip_definicion')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].descrip_definicion);
                    this.codigo.push(response[i].descrip_definicion);
                  }

                  this.stateGroups= [{
                    letter: 'Partidas',
                    names: this.producto,
                    code: this.producto
                  }];

                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  formulario():void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    this.displayedColumns = [
      'descrip_definicion',
      'cant_cuenta_gasto',
      'remov_elem'
     ];
/*   this.dataSource11 = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  addElement() {
    let arry = [];
    let arry2 = [];
    this.forma.value.cant_cuenta_gasto = this.cant_cuenta_gasto.nativeElement.value.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.');

    console.log(this.forma);

    if (parseFloat(this.forma.value.cant_cuenta_gasto) > 0 && this.descrip_definicion.nativeElement.value != '') {
      var descrip_definicion = this.forma.value.descrip_definicion;
    var valor:any = {descrip_definicion: descrip_definicion}
    this.servicio.listarCuentasValihttp(valor).subscribe(
      response =>{
        console.log(response,'response1');

        if (this.ELEMENT_DATA.length == 0) {
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];

          this.listarCuentas();
          this.ELEMENT_DATA.push({
            descrip_definicion:response[0].descrip_definicion,
            cant_cuenta_gasto:parseFloat(this.forma.value.cant_cuenta_gasto).toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2'),
          });
          console.log(this.ELEMENT_DATA,'aqui va la cosa')
          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
          this.descrip_definicion.nativeElement.value = '';
          this.cant_cuenta_gasto.nativeElement.value = '';

        }else{
              if(this.ELEMENT_DATA.some(e=> e.descrip_definicion == response[0].descrip_definicion)){

                this.snackBar.open('La cuenta ya fue seleccionada', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
                  this.descrip_definicion.nativeElement.value = '';
                  this.cant_cuenta_gasto.nativeElement.value = '';
              }else{

                this.listarCuentas();
                this.ELEMENT_DATA.push({
                  descrip_definicion:response[0].descrip_definicion,
                  cant_cuenta_gasto:parseFloat(this.forma.value.cant_cuenta_gasto).toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2'),
                });
                console.log(this.ELEMENT_DATA,'aqui va la cosa222222222')
                this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                this.descrip_definicion.nativeElement.value = '';
                this.cant_cuenta_gasto.nativeElement.value = '';
              }
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.descrip_definicion.nativeElement.value = '';
              this.cant_cuenta_gasto.nativeElement.value = '';
      }
    );
    }else{
        if (parseFloat(this.cant_cuenta_gasto.nativeElement.value) <= 0 || isNaN(this.cant_cuenta_gasto.nativeElement.value) || this.cant_cuenta_gasto.nativeElement.value === "") {
          this.snackBar.open('La cantidad debe ser mayor a 0 y solo puede ingresar numeros', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.descrip_definicion.nativeElement.value  === "") {
          this.snackBar.open('Debes seleccionar al menos una cuenta', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    }
  }

  modalrespuesta(){
    try {

        if (this.ELEMENT_DATA.length > 0 ) {
          this.accion = "enviarordenprod";
          var dialogRef = this.dialog.open(FormmMsgComponent, {
            width: '100vw',
              maxWidth: '100vw',
              height:'100%',
            data: {id:'',
                  accion:this.accion},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result != 'cerrar'){
      
             /*  this.ELEMENT_DATA[0].cant_producto =  this.ELEMENT_DATA[0].cant_producto.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.'); */
              
              this.forma.value.cuentas = this.ELEMENT_DATA;
              
              this.dialogRefp.close(this.forma.value);
      
            }
          });
        }else{
         
            this.snackBar.open('Algunos campos estan vacios', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          
        }
      
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  
  eliminarItems(val:any){
    this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
     this.table.renderRows();
   }
  
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  }
  
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource1.filter = filterValue.trim().toLowerCase();
    }

    ponerPuntosyComas(event:any){
    let validacion = '1234567890'
    console.log(event.key)
    let inputCantidad = document.querySelector('#cantidad') as HTMLInputElement
    if(inputCantidad.value.length == 1){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length <= 2){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length >= 4){
      let noa:any = inputCantidad.value.split('')
      for(let i = 0 ; i < 2; i++){
        if(noa[0] == 0){
          noa.shift()
        }else{
          break;
        }
      }
      inputCantidad.value = noa.join('')
    }
    var num = inputCantidad.value.replace(/\D/g,'');
    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    num = num.split('').reverse().join('').replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    inputCantidad.value = num;
    if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
      this.snackBar.open('Solo numeros', 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  
}
