
<div class="" id="modal">
    <div class="row justify-content-center">
        <div class="col-md-5 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); ">
                    <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-center">Carga de meta de ventas mensual</h3>
                    </div>
                    <div class="modal-body">
                        <div class="row my-2">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                            
                                <p style="color: black; font-size: 13px;">
                                    Monto a ingresar
                                </p>
                                <p>
                                    <mat-form-field class="materialinput">
                                        <mat-label>Monto a ingresar</mat-label>
                                        <input maxlength="17" #cantidad matInput placeholder="" formControlName="cantidad" id="cantidad" (keyup)="ponerPuntosyComas($event)"  autocomplete="off">
                                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>add_shopping_cart</mat-icon>
                                            </span>
                                    </mat-form-field>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row">

    </div>
</div>