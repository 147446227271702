<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarDefinicionesSistema("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li  class="nav-item"><button matTooltip="CREAR NUEVA DEFINICIÓN" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","crear_definicion_sistema")'><mat-icon >add</mat-icon></button></li>
                    <li  class="nav-item"><button matTooltip="PARAMETROS VENTAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(6, 44, 126);" (click)="parametros_precios_create(true)"><mat-icon >build</mat-icon></button></li>
                    <li  class="nav-item"><button matTooltip="PARAMETROS ACCIONES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="border: outset 3px rgb(112, 64, 185); height: 35px; color:rgb(255, 255, 255); background-color: rgb(88, 23, 185);" (click)="parametros_acciones_create(true)"><mat-icon >extension</mat-icon></button></li>
                    <li  class="nav-item"><button matTooltip="PARAMETROS CORREOS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="border: outset 3px rgb(62, 184, 112); height: 35px; color:rgb(255, 255, 255); background-color: rgb(23, 185, 90);" (click)="parametros_correos_create(true)"><mat-icon >markunread_mailbox</mat-icon></button></li>
                    <!-- <li class="nav-item"><button matTooltip="CONSULTAR LOTE SIGUIENTE" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(107, 3, 226);" (click)='consulta_lote_secuencia()'><mat-icon >layers</mat-icon></button></li> -->
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>DEFINICIONES DEL SISTEMA</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>
    <div class="row altura_table ">
        <div class="col-md-12 px-0 border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_definicion">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_definicion}} </td>

                </ng-container>

                <ng-container matColumnDef="des_definicion">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Definición</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_definicion | uppercase}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_menu}} </td>

                </ng-container>

                <ng-container matColumnDef="des_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Menu</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_menu}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_usuario}} </td>

                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_usuario}} </td>

                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.fecha_inicio}} </td>

                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.hora_inicio}} </td>

                </ng-container>
                <ng-container matColumnDef="proceso">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Procesos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class=" bg-primary mx-2" matTooltip="EDITAR" mat-mini-fab >
                            <mat-icon >edit</mat-icon>
                        </button>
                        <button class=" bg-success"   matTooltip="DEFINICIONES" mat-mini-fab (click)='definiciones_modulos(element)'>
                            <mat-icon >android</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>

        <!-- <mat-paginator [pageSizeOptions]="[5, 7, 9]" showFirstLastButtons></mat-paginator> -->
    </div>

</div>