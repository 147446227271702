<!-- <div class="container-fluid" style="height: 100%; width: 100%;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div class="row mat-elevation-z8" style="height: 13%;">
            <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style="background-color:rgb(42, 38, 70); padding: 5px; margin: 0px;">

                <div class="collapse navbar-collapse col-md-12 col-sm-12 col-xs-12" id="navbarSupportedContent" style="padding: 0px; margin: 0px;">

                    <ul class="navbar-nav mr-auto col-md-12 col-sm-12 col-xs-12 " style="padding: 0px; margin: 0px; ">
                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px;">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px; ">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px;">
                                            <mat-label style="color: #000;">Orden de producción N°: </mat-label>
                                            <input style="font-size: 12px;" #cod_orden_producto matInput placeholder="" formControlName="cod_orden_producto_mpp" autocomplete="off" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                  <mat-icon>#</mat-icon>
                                  </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px; ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;   height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Codigo del producto</mat-label>
                                            <input style="font-size: 12px;" type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup2" #cod_producto>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                  <mat-icon>shop_two</mat-icon>
                                  </span>
                                            <mat-autocomplete #autoGroup2="matAutocomplete">
                                                <mat-optgroup *ngFor="let group of arry | async" [label]="group.letter">
                                                    <mat-option *ngFor="let i of group.code" [value]="i">
                                                        {{i}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-2 col-sm-2 col-xs-2" style="padding: 0px; margin: 0px;">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                            <mat-label style="color: #000;">Cantidad</mat-label>
                                            <input style="font-size: 12px;" maxlength="12" type="text" matInput placeholder="" formControlName="cant_producto" #cant_producto autocomplete="off">
                                            <span mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">
                                      <mat-icon>#</mat-icon>
                              </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-3 col-sm-3 col-xs-3" style="padding: 0px; margin: 0px;">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 2px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px 0px 0px 0px;">
                                            <mat-label style="color: #000; font-size: 11px;">Buscar</mat-label>
                                            <input #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                              <mat-icon>search</mat-icon>
                              </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; ">
                            <button class="btn btn-block btn-lg btn-light" (click)="addElement()" matTooltip="AGREGAR PRODUCTO" style="padding: 5px; height: 100%; width: 100%; color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                      <mat-icon >add_shopping_cart</mat-icon>
                    </button>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; ">
                            <button class="btn btn-block btn-lg btn-light" (click)="modalrespuesta()" matTooltip="CONFIRMAR FABRICACIÓN" style="padding: 5px; height: 100%; width: 100%; color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                      <mat-icon >save</mat-icon>
                    </button>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 5px; margin: 0px; ">
                            <button class="btn btn-block btn-lg btn-danger" (click)="cerrarmodal()" matTooltip="SALIR" style="padding: 5px; height: 100%; width: 100%;">
                            <mat-icon >exit_to_app</mat-icon>
                      </button>
                        </li>

                    </ul>

                </div>

            </nav>
        </div>
        <div class="row" style="height: 4%; ">
            <div class="col-md-12 col-sm-12 col-xs-12"></div>
        </div>
        <div class="row mat-elevation-z8" style="height: 83%; overflow-y: auto;">

            <table #table mat-table [dataSource]="dataSource1" class="col-md-6 col-sm-6 col-xs-6  table-primary text-center table-striped table-bordered table-condensed table-hover">

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">{{cantidad_item()}}</td>
                </ng-container>

                <ng-container matColumnDef="cant_unidad_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                        <h5><strong>Formula/Uni</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cant_unidad_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">Requerido</td>
                </ng-container>

                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                        <h5><strong>Requerido</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.cant_producto}}</td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">
                        <h5><strong>Requerido</strong></h5>
                    </td>
                </ng-container>
                <ng-container matColumnDef="tipo_producto_mp">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.tipo_producto_mp}}</td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">Requerido</td>
                </ng-container>
                <ng-container matColumnDef="unidad_medida">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                        <h5><strong>Unidad de medida</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">Unidades</td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">
                        <h5><strong>Unidad de medida</strong></h5>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
            <table #table2 mat-table [dataSource]="dataSource2" class="col-md-6 col-sm-6 col-xs-6  table-success  text-center table-striped table-bordered table-condensed table-hover">

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                        <h5><strong>Codigo</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.des_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                        <h5><strong>Producto</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.cant_producto}}</td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                        <h5><strong>Cantidad</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="remove_elem">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                        <h5><strong>Eliminar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                        <button (click)="remove(element)" matTooltip="QUITAR PRODUCTO DE LA LISTA" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);">
                            <mat-icon >remove_shopping_cart</mat-icon>
                            </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;"> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns2; sticky: true"></tr>
            </table>
        </div>
    </form>
</div> -->

<div id="modal_carrito" class=" container-fluid">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #3649b6; background-color:#475bca ;">
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Orden de producción N°: </mat-label>
                        <input style="font-size: 11px; color: #fff;" #cod_orden_producto matInput placeholder="" formControlName="cod_orden_producto_mpp" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_2" class="col-md-5 px-0 py-2 h-100">
                <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Codigo del producto</mat-label>
                        <input style="font-size: 11px; color: #fff;" type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup2" #cod_producto>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                        <mat-autocomplete #autoGroup2="matAutocomplete">
                            <mat-optgroup *ngFor="let group of arry | async" [label]="group.letter">
                                <mat-option *ngFor="let i of group.code" [value]="i">
                                    {{i}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Cantidad</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="12" type="text" (keyup)="format(cant_producto)" matInput placeholder="" formControlName="cant_producto" #cant_producto autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="addElement()" matTooltip="AGREGAR PRODUCTO" style=" color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                        <mat-icon >add_shopping_cart</mat-icon>
                      </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="CONFIRMAR FABRICACIÓN" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                        <mat-icon >save</mat-icon>
                      </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                          <mat-icon >exit_to_app</mat-icon>
                      </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row" style=" border: 2px solid #374fd6;">
            <div class="col-md-6 col-sm-6 col-xs-6 px-0  border h-100" style="background-color: steelblue;">
                <table #table mat-table [dataSource]="dataSource1" class=" table-primary text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">{{cantidad_item()}}</td>
                    </ng-container>

                    <ng-container matColumnDef="cant_unidad_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Formula/Uni</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cant_unidad_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">Requerido</td>
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Requerido</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.cant_producto}}</td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Requerido</strong></h5>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="tipo_producto_mp">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Tipo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.tipo_producto_mp}}</td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">Requerido</td>
                    </ng-container>
                    <ng-container matColumnDef="unidad_medida">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Unidad de medida</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">Unidades</td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: steelblue; color: #fff;">
                            <h5><strong>Unidad de medida</strong></h5>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 px-0  border h-100" style="background-color: rgb(5, 145, 35);">
                <table #table2 mat-table [dataSource]="dataSource2" class="  table-success  text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                            <h5><strong>Codigo</strong></h5>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.des_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                            <h5><strong>Producto</strong></h5>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.cant_producto}}</td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                            <h5><strong>Cantidad</strong></h5>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="remove_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="remove(element)" matTooltip="QUITAR PRODUCTO DE LA LISTA" mat-raised-button style="height: 35px; border: 2px solid rgb(12, 102, 31); color:rgb(255, 255, 255); background-color: rgb(5, 145, 35);">
                                <mat-icon >remove_shopping_cart</mat-icon>
                                </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(5, 145, 35); color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns2;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns2; sticky: true"></tr>
                </table>
            </div>
        </div>
    </form>

</div>