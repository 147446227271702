import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormConsultarFormulaComponent } from '../form-consultar-formula/form-consultar-formula.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  console.log(opt, 'valores4');
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

export const filter_ = (opt: string[], value: string): string[] => {
  const filterValue2 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue2) === 0);
};

export const _filter_ = (opt: string[], value: string): string[] => {
  const filterValue3 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue3) === 0);
};

@Component({
  selector: 'app-form-confirmacion-inv-mp',
  templateUrl: './form-confirmacion-inv-mp.component.html',
  styleUrls: ['./form-confirmacion-inv-mp.component.css']
})
export class FormConfirmacionInvMpComponent implements OnInit {
  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('inv_me',{static:true}) inv_me:ElementRef;
  @ViewChild('inv_mp',{static:true}) inv_mp:ElementRef;
  @ViewChild('inv_mpp',{static:true}) inv_mpp:ElementRef;
  @ViewChild('inv_pt',{static:true}) inv_pt:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  stateGroupOptions: Observable<StateGroup[]>;
  stateGroupOptions2: Observable<StateGroup[]>;
  stateGroupOptions3: Observable<StateGroup[]>;
  stateGroupOptions4: Observable<StateGroup[]>;

forma:FormGroup;
lista:any;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
stateGroups3: StateGroup[];
stateGroups4: StateGroup[];
value1 = '';
nombre:any;

accion:any;
dialogSpin:any;
ME:any;
MP:any;
MPP:any;
PT:any;
key:any;
msg:any;
producto:any = [];
codigo:any = [];
producto2:any = [];
codigo2:any = [];
producto3:any = [];
producto4:any = [];
codigo3:any = [];
codigo4:any = [];
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
ELEMENT_DATA3:any[]=[];
ELEMENT_DATA4:any[]=[];
cod_pro:any;

  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicio:nkServices,
    private formb:FormBuilder,
    private fb:FormBuilder,
    private dialogRefp: MatDialogRef<FormConfirmacionInvMpComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'Seleccione un producto',
        'cant_producto':'',
        'ord_producto':'',
        'depositos':'',
        'estatus':this.data.estatus,
        'me':'',
        'mp':'',
        'mpp':'',
        'pt':'',
        'cod_orden_producto':this.data.cod_orden_producto

      });
    }

  ngOnInit(): void {
    this.cod_orden_producto.nativeElement.value = this.data.cod_orden_producto;
    this.listarProductoOrdenesProd(this.data.cod_orden_producto);

  }
  ngAfterViewInit() {

  }

  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      console.log(value,'valores');
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }

    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups2
        .map(group2 => ({letter: group2.letter, names: filter(group2.names, value), code: filter(group2.code, value)}))
        .filter(group2 => group2.names.length > 0 || group2.code.length > 0 );
    }

    return this.stateGroups2;
  }

  private filterGroup_(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups3
        .map(group3 => ({letter: group3.letter, names: filter_(group3.names, value), code: filter_(group3.code, value)}))
        .filter(group3 => group3.names.length > 0 || group3.code.length > 0 );
    }

    return this.stateGroups3;
  }

  private _filterGroup_(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups4
        .map(group4 => ({letter: group4.letter, names: _filter_(group4.names, value), code: _filter_(group4.code, value)}))
        .filter(group4 => group4.names.length > 0 || group4.code.length > 0 );
    }

    return this.stateGroups4;
  }

  listarProductoOrdenesProd(xvalor:any){
    var valor = {cod_orden_producto:xvalor}
   this.servicio.buscarProductoOrdenesProdhttp(valor).subscribe(
     response =>{
       console.log(response);
         this.ELEMENT_DATA = [];

         for (const i in response) {
          if (response[i].cod_formula === null || response[i].cod_formula === '') {
            response[i].cod_formula = 'SNFRML';
          }
           }
             for (const i in response) {

               this.ELEMENT_DATA.push({
                cod_formula:response[i].cod_formula,
                cod_orden_producto:response[i].cod_orden_producto,
                 cod_producto:response[i].cod_producto,
                 des_producto:response[i].des_producto,
                 cant_producto:response[i].cant_producto
               });
             }
             
             this.buscarDespositoFormula(this.ELEMENT_DATA[0]);
             this.ELEMENT_DATA3.push(this.ELEMENT_DATA[0]);
             this.cod_pro = this.ELEMENT_DATA[0].cant_producto;
             this.displayedColumns = [
                                        'cod_formula',
                                       'cod_producto',
                                       'des_producto',
                                       'cant_producto',
                                       'ME',
                                       'MP',
                                       'MPP',
                                       'PT',
                                     ];
             this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
        
     },
     error =>{
      
       console.log(error);
       console.log(<any>error.error.message);
       this.displayedColumns = [
        'cod_formula',
       'cod_producto',
       'des_producto',
       'cant_producto',
       'ME',
       'MP',
       'MPP',
       'PT',
     ];
       this.dataSource1 = new MatTableDataSource<any>();
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
   );
 }
 modalrespuesta(){
   
  var split_me = this.inv_me.nativeElement.value.split('-');
  var split_mp = this.inv_mp.nativeElement.value.split('-');
  var split_mpp = this.inv_mpp.nativeElement.value.split('-');
  var split_pt = this.inv_pt.nativeElement.value.split('-');
  this.ELEMENT_DATA3[0].inventario_me = split_me[0];
  this.ELEMENT_DATA3[0].inventario_mp = split_mp[0];
  this.ELEMENT_DATA3[0].inventario_mpp = split_mpp[0];
  this.ELEMENT_DATA3[0].inventario_pt = split_pt[0];
  this.ELEMENT_DATA3[0].cod_lote = this.data.cod_lote;
 this.servicio.validarInventarioProdFormulahttp(this.ELEMENT_DATA3[0]).subscribe(
   response =>{
     
       this.ELEMENT_DATA = [];
       this.ELEMENT_DATA2 = [];
           for (const i in response.producto1) {

             this.ELEMENT_DATA.push({
               cod_producto:response.producto1[i].cod_prod_mp,
               des_producto:response.producto1[i].des_prod_mp,
               cant_producto:response.producto1[i].cantidad_prod_mp,
               requerido_mp:''
             });
           }
           for (const i in response.producto2) {

            this.ELEMENT_DATA2.push({
              cod_producto2:response.producto2[i].cod_producto,
              des_producto2:response.producto2[i].des_producto,
              cant_producto2:response.producto2[i].cant_producto
            });
          }
  
  var key = true;
  var xvalor;

    for (const i in this.ELEMENT_DATA) {
      xvalor = parseFloat(this.ELEMENT_DATA[i].cant_producto.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.') ) * parseFloat(this.cod_pro.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.'));
      
    if ( xvalor > parseFloat(this.ELEMENT_DATA2[i].cant_producto2.replace('.', '')
    .replace('.', '')
    .replace('.', '')
    .replace(',', '.')) ||  this.ELEMENT_DATA2[i].cant_producto2 === null ||  this.ELEMENT_DATA2[i].cant_producto2 === "") {
      key = false;
      break;
    }
  }
  if (key) {
    this.accion = "enviarordenprod";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(this.ELEMENT_DATA,'this.ELEMENT_DATA2');
        console.log(this.cod_pro,'this.ELEMENT_DATA2');
        for (const i in this.ELEMENT_DATA) {
          xvalor = parseFloat(this.ELEMENT_DATA[i].cant_producto.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.') ) * parseFloat(this.cod_pro.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.'));

          this.ELEMENT_DATA[i].requerido_mp = xvalor.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      }
      this.forma.value.ord_producto2 = this.ELEMENT_DATA3;
        this.forma.value.ord_producto = this.ELEMENT_DATA;
        console.log(this.forma.value);
        this.dialogRefp.close(this.forma.value);

      }
    });
  }else{
    this.snackBar.open('Materia prima insuficiente para la fabricacion de este producto', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
   },
   error =>{
     console.log(<any>error.error.message);
      this.msg = <any>error.error.message;
      this.snackBar.open(this.msg, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
   }
 );
}

buscarDespositoFormula(xvalor:any){
  var valor = {cod_producto:xvalor}
 this.servicio.buscarDespositoFormulahttp(xvalor).subscribe(
   response =>{
     console.log(response,'depositos');
     this.ME = response.me;
     this.MP = response.mp;
     this.MPP = response.mpp;
     this.PT = response.pt;

     this.stateGroupOptions = this.forma.get('me')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response.me) {
                    this.producto.push(response.me[i].cod_deposito);
                    this.codigo.push(response.me[i].cod_deposito+'-'+response.me[i].des_deposito);
                  }
                  console.log(this.producto);
                  this.stateGroups= [{
                    letter: 'Depositos',
                    names: this.producto,
                    code: this.codigo
                  }];

    this.stateGroupOptions2 = this.forma.get('mp')!.valueChanges
                  .pipe(
                    startWith(''),
                    map(value => this._filterGroup2(value))
                  );
    for (const i in response.mp) {
      this.producto2.push(response.mp[i].cod_deposito);
      this.codigo2.push(response.mp[i].cod_deposito+'-'+response.mp[i].des_deposito);
    }
    console.log(this.producto);
    this.stateGroups2= [{
      letter: 'Depositos',
      names: this.producto2,
      code: this.codigo2
    }];

    this.stateGroupOptions3 = this.forma.get('mpp')!.valueChanges
                  .pipe(
                    startWith(''),
                    map(value => this.filterGroup_(value))
                  );
    for (const i in response.mpp) {
      this.producto3.push(response.mpp[i].cod_deposito);
      this.codigo3.push(response.mpp[i].cod_deposito+'-'+response.mpp[i].des_deposito);
    }
    console.log(this.producto);
    this.stateGroups3= [{
      letter: 'Depositos',
      names: this.producto3,
      code: this.codigo3
    }];

    this.stateGroupOptions4 = this.forma.get('pt')!.valueChanges
                  .pipe(
                    startWith(''),
                    map(value => this._filterGroup_(value))
                  );
    for (const i in response.pt) {
      this.producto4.push(response.pt[i].cod_deposito);
      this.codigo4.push(response.pt[i].cod_deposito+'-'+response.pt[i].des_deposito);
    }
    console.log(this.producto);
    this.stateGroups4= [{
      letter: 'Depositos',
      names: this.producto4,
      code: this.codigo4
    }];
    
   },
   error =>{
     console.log(error);
     console.log(<any>error.error.message);

     this.dataSource1 = new MatTableDataSource<any>();
     this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
       verticalPosition: this.verticalPosition});
   }
 );
}
cerrarmodal(): void {
  this.accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
}
cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 2000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  async detalleOrdenProd(xvalor:any, termino:any){
    console.log(xvalor);
    console.log(termino);
    var key = false;
    switch (termino) {
      case 'ME':
          if (this.forma.value.me) {
            var split = this.inv_me.nativeElement.value.split('-');
            xvalor.deposito = split[0];
            key = true;
          }
        break;
          case 'MP':
            if (this.forma.value.mp) {
              var split = this.inv_mp.nativeElement.value.split('-');
              xvalor.deposito = split[0];
              key = true;
            }
          break;
            case 'MPP':
              if (this.forma.value.mpp) {
                var split = this.inv_mpp.nativeElement.value.split('-');
                xvalor.deposito = split[0];
                key = true;
              }
            break;
              case 'PT':
                if (this.forma.value.pt) {
                  var split = this.inv_pt.nativeElement.value.split('-');
                  xvalor.deposito = split[0];
                  key = true;
                }
              break;
    
      default:
        break;
    }
    if (key) {
      this.dialog.open(FormConsultarFormulaComponent, {
        width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
        data: {producto: xvalor},
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
      });
    }else{
      this.snackBar.open('Inventario no asignado o no seleccionado en esta orden de producción', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
}

}
