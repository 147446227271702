import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-materia-prima-entrada',
  templateUrl: './materia-prima-entrada.component.html',
  styleUrls: ['./materia-prima-entrada.component.css']
})
export class MateriaPrimaEntradaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
