import { Component, OnInit, Inject, Injectable, ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {BehaviorSubject, Observable} from 'rxjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatPaginator } from '@angular/material/paginator';
import { map, startWith } from 'rxjs/operators';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-permisos-acciones',
  templateUrl: './form-permisos-acciones.component.html',
  styleUrls: ['./form-permisos-acciones.component.css']
})
export class FormPermisosAccionesComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto_mp') cod_producto_mp:ElementRef;
  @ViewChild('cod_submenu_accion') cod_submenu_accion:ElementRef;
  @ViewChild('menu_front') menu_front:ElementRef;
  
  producto:any = [];
  producto_2:any = [];
dialogSpin:any;
codigo:any = [];
codigo_2:any = [];
  forma:FormGroup;
  accion:any;
  roles:any;
  stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
arry: Observable<StateGroup[]>;
arry2: Observable<StateGroup[]>;
  rol = [];
  unidad=[];
  unidadp=[];
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  menur3:any;
  unidades:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private service:nkServices,
    fb:FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog, 
    private servicio:nkServices,
    public dialogRefp: MatDialogRef<FormPermisosAccionesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data, 'primero');
    this.accion = data.accion;
    this.unidades = data.unidades;
   
      this.forma = fb.group({
        'cod_usuario_rol': data.cod_usuario_rol,
        'id':data.id,
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_accion':'',
        'cod_submenu_accion':'',
        'des_accion':'',
        'permisos_acciones':'',
        'unidades':''
      });
     }

  ngOnInit(): void {
    this.formulario(this.data.cod_usuario);
    this.listar_menu_acciones_app();
    /* this.listar_submenu_acciones(); */
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    this.dataSource.paginator = this.paginator;
    
  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
 private _filterGroup2(value: string): StateGroup[] {
  console.log(value);
  if (value) {
    return this.stateGroups2
      .map(group => ({letter: group.letter, names: filter(group.names, value), code: filter(group.code, value)}))
      .filter(group => group.names.length > 0 || group.code.length > 0 );
  }
  return this.stateGroups2;
}
 
 
 listar_menu_acciones_app(){
  this.codigo = [];
  this.producto = [];

  this.servicio.listar_menu_acciones_apphttp().subscribe(
    response =>{
                for (const i in response) {
                  this.producto.push(response[i]);
                  this.codigo.push(response[i].cod_accion);
                }  
    },
    error =>{
      console.log(<any>error.error.message);
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  );
}

listar_submenu_acciones(xvalor:any){
  this.codigo_2 = [];
  this.producto_2 = [];
  console.log(xvalor.value.cod_unidad);
  var cod_unidad = {cod_unidad:xvalor.value.cod_unidad}

  this.servicio.listar_submenu_accioneshttp(cod_unidad).subscribe(
    response =>{
                for (const i in response) {
                  this.producto_2.push(response[i]);
                }
    },
    error =>{
      console.log(<any>error.error.message);
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  );
}


  formulario(cod:any):void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    var valor = {cod_usuario: cod};
    this.servicio.buscarPermisosAccioneshttp(valor).subscribe(
      response =>{
        for (const i in response) {

          this.ELEMENT_DATA.push({
            cod_accion:response[i].cod_accion,
            des_accion:response[i].des_accion,
            cod_menu:response[i].cod_menu,
            des_menu:response[i].des_menu,
            id_rol:response[i].id_rol,
            accion:'',
            accion2:'',
            accion3:''
          });
        }
        this.displayedColumns = [
          'rel',
          'cod_menu',
          'des_menu',
          'cod_accion',
          'des_accion',
          'remov_elem'
         ];

      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      },
      error =>{
        console.log(<any>error.error.message);
        this.displayedColumns = [
          'rel',
          'cod_menu',
          'des_menu',
          'cod_accion',
          'des_accion',
          'remov_elem'
         ];

      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  addElement() {
    var arry = [];
    var arry2 = [];
    console.log(this.forma.value.cod_accion.cod_accion);
    
    if (this.forma.value.cod_accion != '' && this.forma.value.cod_submenu_accion != '') {
      
        if (this.ELEMENT_DATA.length == 0) {
        
              this.ELEMENT_DATA.push({ 
                cod_accion:this.forma.value.cod_accion.cod_accion,
                des_accion:this.forma.value.cod_accion.des_accion,
                cod_menu:this.forma.value.cod_submenu_accion.cod_menu,
                des_menu:this.forma.value.cod_submenu_accion.des_menu,
                id_rol: this.forma.value.cod_submenu_accion.cod_menu+this.forma.value.cod_accion.cod_accion
              });
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
             /*  this.cod_producto_mp.nativeElement.value = '';
              this.cod_submenu_accion.nativeElement.value = '';
              this.menu_front.nativeElement.value = ''; */
              this.listar_menu_acciones_app();
              /* this.listar_submenu_acciones(); */
          
        }else{
          console.log(this.forma.value.cod_submenu_accion);
            for (const i in this.ELEMENT_DATA) {
             arry.push(this.ELEMENT_DATA[i].id_rol);
            }
           var id_accion = this.forma.value.cod_submenu_accion.cod_menu+this.forma.value.cod_accion.cod_accion;
           console.log(id_accion);
              let index = arry.includes(id_accion);

              if (!index) {
                this.ELEMENT_DATA.push({ 
                  cod_accion:this.forma.value.cod_accion.cod_accion,
                  des_accion:this.forma.value.cod_accion.des_accion,
                  cod_menu:this.forma.value.cod_submenu_accion.cod_menu,
                  des_menu:this.forma.value.cod_submenu_accion.des_menu,
                  id_rol: id_accion
                });
                this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
                /* this.cod_producto_mp.nativeElement.value = '';
                this.cod_submenu_accion.nativeElement.value = '';
                this.menu_front.nativeElement.value = ''; */
                this.listar_menu_acciones_app();
                /* this.listar_submenu_acciones(); */
            }else{
              this.snackBar.open('Ya tiene este permiso asignado', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                /* this.cod_producto_mp.nativeElement.value = '';
                this.cod_submenu_accion.nativeElement.value = '';
                this.menu_front.nativeElement.value = ''; */
                this.listar_menu_acciones_app();
                /* this.listar_submenu_acciones(); */
            }
        }
    }else{

          this.snackBar.open('Faltan opciones por seleccionar', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
    }
  }

  modalrespuesta(){
    try {
      if (this.ELEMENT_DATA.length > 0) {
        this.accion = '';
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: { 
                accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
           
            this.forma.value.permisos_acciones = this.ELEMENT_DATA;
            console.log(this.forma.value);
            this.gestionPermisosacciones(this.forma.value);
           
          } 
        });
      }else{
        this.snackBar.open('No existe nada en su lista', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
    } catch (error) {
      console.log(<any>error.error.message);
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
    }
    
  }

  gestionPermisosacciones(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        var resultado = this.servicio.gestionPermisosaccioneshttp(xvalor).subscribe(
          response =>{
            this.dialogSpin.close();
            this.ELEMENT_DATA = [];
           for (const i in response.resul) {
            
             this.ELEMENT_DATA.push({
               cod_accion:response.resul[i].cod_accion,
               des_accion:response.resul[i].des_accion,
               cod_menu:response.resul[i].cod_menu,
               des_menu:response.resul[i].des_menu,
               id_rol:response.resul[i].id_rol,
               accion2:'',
               accion3:''
             });
           }
           this.displayedColumns = [
            'rel',
            'cod_menu',
            'des_menu',
            'cod_accion',
            'des_accion',
            'remov_elem'
           ];

            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
           this.dataSource.paginator = this.paginator;
           this.snackBar.open(response.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
            
          },
          error =>{
            this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.displayedColumns = [
              'rel',
              'cod_menu',
              'des_menu',
              'cod_accion',
              'des_accion',
              'remov_elem'
             ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        
        return resultado;
      }, 3000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
         this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
    }
   
    
   }
 
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  }

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  remove(val:any){
    console.log(val);
    this.dataSource.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
     this.table.renderRows();
   }

}
