<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">

    <div class="row" style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>GESTIONAR TOMA FISICA</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="CREAR TOMA FISICA" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='crear_toma_fisica("","insertar")'><mat-icon >add</mat-icon></button></li> 
                </ul>
                 <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div> 
            </div>
        </nav>
    </div>

     <div class="row " style="height: 78.7%;">
      <div id="htmlData" class="col-md-12 px-0  border h-100 " style="background-color: steelblue; overflow-y: auto;">
         
            <table id="htmlData" #htmlData mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-bordered table-condensed table-hover">
               
                <ng-container matColumnDef="cod_solicitud_toma_fisica">
                  <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                      <h5><strong>Codigo solicitud</strong></h5>
                  </th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.cod_solicitud_toma_fisica}} </td>
                </ng-container>


                 <ng-container matColumnDef="cod_deposito">
                  <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                      <h5><strong>Codigo deposito</strong></h5>
                  </th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.cod_deposito}} </td>
                </ng-container>

                <ng-container matColumnDef="des_lider_inv">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Lider inventario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.des_lider_inv}} </td>
                  </ng-container>

                <ng-container matColumnDef="des_supervisor_inv">
                  <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                      <h5><strong>Supervisor inventario</strong></h5>
                  </th>
                  <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.des_supervisor_inv}} </td>
                </ng-container>

                <ng-container matColumnDef="des_auditor">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Auditor interno</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.des_auditor}} </td>
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.fecha_inicio}} </td>
                </ng-container>
                
                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.hora_inicio}} </td>
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;" [ngSwitch]="element.estatus">
                    
                    
                        <div *ngSwitchCase="'P'" style="background-color: var(--warning); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Pendiente</strong></div> 
                        <div *ngSwitchCase="'T'" style="background-color: var(--success); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Toma fisica completada</strong></div> 
  
                        <strong *ngSwitchDefault >{{element.estatus}}</strong> 
                        
                    </td>
                      
                </ng-container>

                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.tipo}} </td>
                </ng-container>

                <ng-container matColumnDef="fase">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fase</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.fase === 'N/A' ? 'Fase 1' : element.fase === 'fase_1' ? 'Fase 2' : 'Fase 3'}} </td>
                </ng-container>

                <ng-container  matColumnDef="boton">
                  <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                      <h5><strong>Editar</strong></h5>
                  </th>
                
                  <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.estatus  === 'No' ? '#c14b4b' : null }" [style.color]="element.estatus  === 'I' ? 'white' : null" style="padding: 1px; font-size: 12px;" class="text-center" >
                      <button matTooltip="EDITAR"  mat-raised-button  style="height: 35px;" class="btn btn-primary rounded-pill" (click)='insert_data(element,"actualizar")'>
                          <mat-icon>edit</mat-icon>
                      </button>
                     <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                          <mat-icon>edit</mat-icon>
                      </button> -->
                  </td>
              </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
  
            </table>

            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 
      </div>
  </div>
</div>   




   