import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { parse } from 'querystring';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-menu',
  templateUrl: './form-menu.component.html',
  styleUrls: ['./form-menu.component.css']
})
export class FormMenuComponent implements OnInit {

  nombre:any;
forma:FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
menup:any = [];
menu:any;
des:any;
unidad_menu:any = false;
estatus:any;
nivel:any;

  constructor( 
      private dialog: MatDialog, 
      private fb:FormBuilder,
      private servicio:nkServices, 
      private hashkey:HashKeyServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private notifi:ToastNotificationService,
      private dialogRef: MatDialogRef<FormMenuComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.menu = data.menu;
    this.unidad = data.unidad;
    this.des = data.des;
    if (this.data.operacion.toLowerCase() === this.atrb.ACCION_ACTUALIZAR.toLowerCase()) {
          this.forma = this.fb.group({
            'cod_menu':[this.data.cod_menu,[Validators.required]],
            'des_menu':[this.data.des_menu,[Validators.required]],
            'dir_menu':[this.data.dir_menu,[Validators.required]],
            'estatus_menu':[this.data.estatus_menu,[Validators.required]],
            'ord_menu':[this.data.ord_menu,[Validators.required]],
            'padre_menu':[this.data.padre_menu,[Validators.required]],
            'des_padre_menu':[this.data.des_padre_menu,[Validators.required]],
            'nivel_padre_menu':[this.data.nivel_padre_menu,[Validators.required]],
            'cod_unidad':[this.data.cod_unidad,[Validators.required]],
            'des_unidad':[this.data.des_unidad,[Validators.required]],
            'operacion':[this.data.operacion]
          });
    }
    if (this.data.operacion.toLowerCase() === this.atrb.ACCION_CREAR.toLowerCase()) {
      this.forma = this.fb.group({
        'des_menu':['',[Validators.required]],
        'dir_menu':['',[Validators.required]],
        'estatus_menu':['',[Validators.required]],
        'ord_menu':['',[Validators.required]],
        'padre_menu':['',[Validators.required]],
        'des_padre_menu':[''],
        'nivel_padre_menu':['',[Validators.required]],
        'cod_unidad':['',[Validators.required]],
        'des_unidad':[''],
        'operacion':[this.data.operacion]
      });
    }
    }
 async ngOnInit(){
    this.estatus = this.atrb.ESTATUS;
    this.nivel = this.atrb.NIVEL_MENU;
    if (this.data.operacion.toLowerCase() === this.atrb.ACCION_ACTUALIZAR.toLowerCase()) {
      await this.tipoMenu(this.data.nivel_padre_menu);
      await  this.unidades(this.data.padre_menu);
    }
  }
  
  async  modalrespuesta(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
      var accion2 = '';
      var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:this.data.id_cliente,
              accion:accion2},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRefp.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result !== await this.atrb.CERRAR){
          let unidad =  this.forma.value.cod_unidad;
            let menu_padre =  this.forma.value.padre_menu;
            let valor_unidad = this.data.unidad.filter( function(elem){
              return elem.cod_unidad.toString()  ===  unidad.toString();
            });
            let valor_menu_padre = this.data.menu.filter( function(elem){
              return elem.cod_menu.toString()  ===  menu_padre.toString();
            });
            if (parseInt(menu_padre) > 0) {
              this.forma.patchValue({
                des_unidad: valor_unidad[0].des_unidad,
                des_padre_menu:valor_menu_padre[0].des_menu,
                padre_menu:valor_menu_padre[0].cod_menu.toString()
              });
            }else{
              this.forma.patchValue({
                des_padre_menu: await this.atrb.MENU_PADRE,
                des_unidad: valor_unidad[0].des_unidad,
              });
            }
            await this.util.cerrarSpinner();
            this.dialogRef.close(this.forma.value);
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async unidades(xvalor:any){
    try {
      await this.util.abrirSpinner();
      this.unidadp = [];
      if (parseInt(xvalor) > 0) {
        let valor_menu = this.menu.filter( function(elem){
          return elem.cod_menu.toString()  ===  xvalor.toString();
        });
        let valor_unidad = this.unidad.filter( function(elem){
          return elem.cod_unidad.toString()  ===  valor_menu[0].cod_unidad;
        });
        this.unidadp.push(valor_unidad[0]);
      }else{
        for (const i in this.unidad) {
          if (parseInt(this.unidad[i].cod_unidad_padre) > 0) {
            this.unidad[i].cod_unidad = this.unidad[i].cod_unidad.toString();
            this.unidadp.push(this.unidad[i]);
          }
        }
      }
      await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error);
    }
  }
  async tipoMenu(xvalor:any){
    try {
      await this.util.abrirSpinner();
      this.menup = [];
      this.unidadp = [];
      if (parseInt(xvalor) > 0) {
        this.unidad_menu = false
        for (const i in this.menu) {
          if (parseInt(this.menu[i].nivel_padre_menu) <= 0) {
            this.menu[i].cod_menu = this.menu[i].cod_menu.toString();
            this.menup.push(this.menu[i]);
          }
        }
      }
      if(parseInt(xvalor) <= 0){ 
        this.unidad_menu = true
        this.menup = [];
      }
      if(isNaN(parseInt(xvalor))){ 
        this.unidad_menu = false
        for (const i in this.menu) {
          if (parseInt(this.menu[i].nivel_padre_menu) > 0) {
            this.menu[i].cod_menu = this.menu[i].cod_menu.toString();
            this.menup.push(this.menu[i]);
          }
        }
      }
      await this.util.cerrarSpinner();
    } catch (error) {
      console.log(error);
    }
  }
  async cerrarmodal(){
    try {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRef.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
   }
  }

}
