

<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>LABORATORIO DE PRODUCCIÓN</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarordenesprod("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
  
                </ul>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.7%; ">
        <div class="col-md-12 px-0  border h-100" style="background-color: steelblue; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
            
                <ng-container matColumnDef="cod_orden_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod ord prod</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_orden_producto}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cod_lote">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Lote</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_lote}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod producto</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_producto}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_producto}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cant_producto}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_usuario}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.estatus}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha ini</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.fecha_inicio}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora ini</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.hora_inicio}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cod_usuario_cierre">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod usuario cierre</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_usuario_cierre}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_usuario_cierre">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Usuario cierre </strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_usuario_cierre}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="fecha_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha fin</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.fecha_fin}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="hora_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora fin</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.hora_fin}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="detalle">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Detalle</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center" (click)='detalleOrdenProd(element)'>
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                      <mat-icon>image_search</mat-icon>
                                    </button>
                            </div>
                        </section>
                    </td>
                    
                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center">
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                        <mat-icon>print</mat-icon>
                                      </button>
                            </div>
                        </section>
                    </td>
                    
                </ng-container>

                <ng-container matColumnDef="descargar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element" style="padding: 1px;" class="text-center">
                        <section>
                            <div class="example-button-container" class="text-center">
                                <button mat-icon-button color="" style="margin: 1%;" aria-label="Example icon button with a delete icon">
                                        <mat-icon>get_app</mat-icon>
                                      </button>
                            </div>
                        </section>
                    </td>
                    
                </ng-container>

                <ng-container matColumnDef="confirmar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Confirmar</strong></h5>
                    </th>
                    <td [ngClass]="{'ord_prod_estatus_f':  element.estatus === 'F'} " mat-cell *matCellDef="let element " style="padding: 1px; font-size: 12px; " class="text-center " >
                        <button *ngIf="special" matTooltip="CONFIRMAR FABRICACIÓN " mat-raised-button (click)='confirmacionOrdenProd(element)' style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(6, 81, 124); ">
                            <mat-icon >done_all</mat-icon>
                        </button>
                        <button *ngIf="!special" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0); ">
                            <mat-icon >done_all</mat-icon>
                        </button>
                    </td>
                    
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true "></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; " class="text-center "></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>