import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { environment } from 'src/environments/environment';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

@Component({
  selector: 'app-form-productos',
  templateUrl: './form-productos.component.html',
  styleUrls: ['./form-productos.component.css']
})
export class FormProductosComponent implements OnInit {
  archivo:File;
  rlsr:any ;
  hide = true;
  nombre:any;
  mayusculas:any = /^[A-Z]{2}\d{4}/;
  forma:FormGroup;
  accion:any;
  unidad:any;
  estatus:any;
  estatus_fiscal:any;
  medida:any;
  tipo_producto:any;
  tipo_producto_update:any;
  definicion_1:any;

  objectURL:any;
    constructor(
      private dialog: MatDialog, 
      private fb:FormBuilder,
      private servicio:nkServices, 
      private hashkey:HashKeyServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private notifi:ToastNotificationService,
      private dialogRef: MatDialogRef<FormProductosComponent>,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.rlsr =  hashkey.decrypt(localStorage.getItem('rlsr'));
        console.log(data, 'primero');
      this.objectURL = this.atrb.IMAGENES_FIJAS.sin_img_prod;
      this.accion = data.accion;
      this.medida = data.medida;
      this.definicion_1 = data.definicion_1;
      this.tipo_producto = [...data.tipo_prod];

      if (data.accion.toUpperCase() === this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {

        this.forma = fb.group({
          'id_producto':data.id_producto,
          'cod_producto':[data.cod_producto],
          'des_producto':[data.des_producto,[Validators.required,Validators.maxLength(45)]],
          'estatus_producto':[data.estatus_producto,[Validators.required]],
          'estatus_fiscal':[data.estatus_fiscal,[Validators.required]],
          'tamano_producto':[data.tamano_producto,[Validators.required]],
          'unidad_medida_producto':[data.unidad_medida_producto,[Validators.required]],
          'unidad_manejo':[data.unidad_manejo,[Validators.required]],
          'tipo_producto':[data.tipo_producto,[Validators.required]],
          'cod_img':[''],
          'img_actual':[data.cod_img_sin],
          'archivo':[''],
          'formdata':[''],
          'des_usuario': '',
          'cod_usuario': '',
          'cod_empresa':''
        });
      }
      if (data.accion.toUpperCase() === this.atrb.ACCION_CREAR.toUpperCase()) {
        this.forma = fb.group({
          'id_producto':data.id_producto,
          'cod_producto':[data.cod_producto],
          'des_producto':[data.des_producto,[Validators.required,Validators.maxLength(45)]],
          'estatus_producto':[data.estatus_producto,[Validators.required]],
          'estatus_fiscal':[data.estatus_fiscal,[Validators.required]],
          'tamano_producto':[data.tamano_producto,[Validators.required]],
          'unidad_medida_producto':[data.unidad_medida_producto,[Validators.required]],
          'unidad_manejo':[data.unidad_manejo,[Validators.required]],
          'tipo_producto':[data.tipo_producto,[Validators.required]],
          'cod_img':[''],
          'archivo':['',[Validators.required]],
          'formdata':[''],
          'des_usuario': '',
          'cod_usuario': '',
          'cod_empresa':''
        });
      }
      }

   async ngOnInit(){
    this.estatus = this.atrb.ESTATUS;
    this.estatus_fiscal = this.atrb.ESTATUS_FISCAL;
      if (this.accion.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
        /* this.tipo_producto_update = document.getElementById("tipo_prod") as HTMLInputElement;
        for (const i in this.tipo_producto) {
          if (this.tipo_producto[i].concepto_definicion.toLowerCase() === this.data.tipo_producto.toLowerCase()) {
            this.tipo_producto_update.value = this.tipo_producto[i].descrip_definicion;
            
          }
        } */
        this.objectURL = this.data.cod_img;
      }
    }
    ngOnDestroy() {
      URL.revokeObjectURL(this.objectURL);
    }
    async cargar_img(xvalor:any){
      try {
        await this.util.abrirSpinner();
        this.objectURL = URL.createObjectURL(xvalor.target.files[0]);
       this.archivo = xvalor.target.files[0];
       
      await this.util.cerrarSpinner();
      } catch (error) {
        await this.util.cerrarSpinner();
        console.log(error);
      }
    }
  async  modalrespuesta(valor:any){
    try {
      let cant_peso = document.querySelector(`#${valor}`) as HTMLInputElement;
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      if (parseFloat(await this.util.remplazo_coma_punto(cant_peso.value)) <= 0) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.peso_mayor_0);
        return;
      }
      await this.util.cerrarSpinner();
      var accion2 = '';
      var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:this.data.id_cliente,
              accion:accion2},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRefp.afterClosed().subscribe(async result => {
        if(result != 'cerrar'){
          await this.util.cerrarSpinner();
          if (parseInt(this.rlsr) > 1) {
            this.forma.patchValue({
              mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario)
            });
        }
          const formData = new FormData();
          formData.append('prod_img',this.archivo);
          this.forma.patchValue({
            cod_usuario:await this.atrb.COD_USUARIO.cod_usuario,
            des_usuario: await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
            cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
            tamano_producto:cant_peso.value,
            formdata:formData
          });
         this.dialogRef.close(this.forma.value);
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }

  async format(event: any, valor:any, termino:any)
    {
      try {
        var num;
        if (termino.toUpperCase() !== this.atrb.BASE.toUpperCase() &&
            termino.toUpperCase() !== this.atrb.TASA.toUpperCase() &&
            termino.toUpperCase() !== this.atrb.DESCUENTO.toUpperCase()
            ) {
          let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
          if(inputId.value.length == 1){
            let noa = inputId.value.split('')
            noa.unshift('0')
            noa.unshift('0')
            inputId.value = noa.join('')
          }else if(inputId.value.length == 3){
            let noa = inputId.value.split('')
            noa.unshift('0')
            inputId.value = noa.join('')
          }else if(inputId.value.length >= 4 ){
            let noa:any = inputId.value.split('')
            for(let i = 0 ; i < 2; i++){
              if(noa[0] == 0){
                noa.shift()
              }else{
                break;
              }
            }
            inputId.value = noa.join('')
          }
          let counter = 0
          let comaExiste = false
          let num = inputId.value.replace(/\D/g,'');
          const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
            for(let i = array.length - 1; i > -1 ; i--){
              if(array[i] === ','){
                comaExiste = true
              }
              if(comaExiste){
                if(counter === 3){
                  if(array[i-1]){
                    array.splice(i,0,'.')
                    counter = 1
                  }
                }else{
                  counter++
                }
              }
            }
            inputId.value = array.join('');
        }
        if (termino.toUpperCase() === this.atrb.BASE.toUpperCase()) {
          let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
          num = inputId.value.replace(/\D/g,'');
          if(!isNaN(parseFloat(num))){
            num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
            num = num.split('').reverse().join('').replace(/\D/g, "")
                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
            this.forma.patchValue({cant_producto:num});
            inputId.value = num;
            }else{ 
              inputId.value = inputId.value.replace(/[^\d\.]*/g,'');
            }
        }
        if (termino.toUpperCase() === this.atrb.TASA.toUpperCase()) {
          let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
          if(inputId.value.length == 1){
            let noa = inputId.value.split('')
            noa.unshift('0')
            noa.unshift('0')
            inputId.value = noa.join('')
          }else if(inputId.value.length == 3){
            let noa = inputId.value.split('')
            noa.unshift('0')
            inputId.value = noa.join('')
          }else if(inputId.value.length >= 4 ){
            let noa:any = inputId.value.split('')
            for(let i = 0 ; i < 2; i++){
              if(noa[0] == 0){
                noa.shift()
              }else{
                break;
              }
            }
            inputId.value = noa.join('');
          }
          let counter = 0
          let comaExiste = false
          let num = inputId.value.replace(/\D/g,'');
          const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
            for(let i = array.length - 1; i > -1 ; i--){
              if(array[i] === ','){
                comaExiste = true
              }
              if(comaExiste){
                if(counter === 3){
                  if(array[i-1]){
                    array.splice(i,0,'.')
                    counter = 1
                  }
                }else{
                  counter++
                }
              }
            }
            inputId.value = array.join('');
        }
      } catch (error) {
        console.log(error);
      }
    }
  async unidad_manejo(xvalor:any){
    try {
      this.medida = [];
        await this.util.abrirSpinner();
                if (xvalor.value.toUpperCase() === await this.atrb.UND.toUpperCase()) {
                  this.medida.push( await this.atrb.cantidad_unidad);
                }else{
                  this.medida = [...this.data.medida];
                }
     await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
  async cerrarmodal(){
    try {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRef.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
   }
  }
}
