<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;"> 

    <div class="row " style="height: 8%; ">
      <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-image: linear-gradient(to left top, #90b9dc, #70a3d3, #518cca, #3276bf, #045fb4); padding: 5px 7px 5px 7px; margin: 0px;">
        <span class="navbar-brand d-flex align-items-center" style=" font-size: 14px; color: rgb(255, 255, 255); " > <mat-icon>assignment</mat-icon> <b style="text-decoration: underline;" class="ml-2">PROVEEDORES</b></span>
        <div class=" navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarSolicitudesCompra("actualizar")'><mat-icon >sync</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
              <li *ngIf="read" class="nav-item"><button matTooltip="DESCARGA" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; background: green; " (click)='en_construccion()'><mat-icon >get_app</mat-icon></button></li>
              <li *ngIf="create" class="nav-item"><button matTooltip="CREAR " class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='crearProveedor("","insertar")' ><mat-icon >add</mat-icon></button></li>
              
              
          </ul>
            <div class="">
              <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
          </div>
        </div>
      </nav>
    </div>
  
    <div class="row " style="height: 78.7%;">
      
        <div id="htmlData" class="col-md-12 px-0  border h-100 " style="background-color: steelblue; overflow-y: auto;">
           
              <table id="htmlData" #htmlData mat-table [dataSource]="dataSource" class=" text-center table-striped table-bordered table-condensed table-hover">
                 <!-- Weight Column -->
                  

                  <ng-container matColumnDef="cod_proveedor">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Rif/Cedula</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_proveedor}} </td>
                  </ng-container>
  
  
                  <ng-container matColumnDef="des_proveedor">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Nombre empresa</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_proveedor}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="tipo_prov">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Tipo de Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_prov}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;" [ngSwitch]="element.estatus">
                     
                      
                        <div *ngSwitchCase="'P'" style="background-color: var(--warning); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Pendiente por Actualizar</strong></div> 
                        <div *ngSwitchCase="'C'" style="background-color: var(--success); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Completado</strong></div> 
                        <!-- <div *ngSwitchCase="'SCD'" style="background-color: var(--danger); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Desaprobada</strong></div>
                        <strong *ngSwitchDefault >{{element.estatus}}</strong> --> 
                        
                    </td>
                     
                  </ng-container>


                  <ng-container  matColumnDef="actualizar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Editar</strong></h5>
                    </th>
                  
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button matTooltip="EDITAR" (click)='actualizarProveedor(element, "actualizar")' class="btn rounded-pill btn-sm" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(171, 20, 241);">
                            <mat-icon>edit</mat-icon>
                        </button>
                       <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon>edit</mat-icon>
                        </button> -->
                    </td>
                  </ng-container>
                  <ng-container  matColumnDef="activar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Accion</strong></h5>
                    </th>
                  
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        
                        <button class="button r" (click)="activaOinactivarProveedor(element)" [ngClass]="{'check':Number(element.isActive)}" id="button-1" >
                          <!-- <input type="checkbox" 
                          [checked]="Number(element.isActive)"
                          class="checkbox"> -->
                          <div class="knobs" >
                            <div *ngIf="element.loading" class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                          <div class="layer" ></div>
                        </button>
                       <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon>edit</mat-icon>
                        </button> -->
                    </td>
                  </ng-container>


                 
                 
  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
    
              </table>
  
              <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 
            
        </div>
      
    </div>
  
    
  </div>