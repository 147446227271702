import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { DevolverFormulaProductoComponent } from '../devolver-formula-producto/devolver-formula-producto.component';

@Component({
  selector: 'app-form-pt-devoluciones',
  templateUrl: './form-pt-devoluciones.component.html',
  styleUrls: ['./form-pt-devoluciones.component.css']
})
export class FormPtDevolucionesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('dev_producto',{static:true}) dev_producto:ElementRef;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  stateForm: FormGroup;
  forma:FormGroup;
  accion:any;
  dialogSpin:any;

  cant:any=false;

  devolucion_total:any = [];
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  ELEMENT_DATA3:any[]=[];
  xobj:any;
  xobj2:any;
  xobj3:any=[];
  xobj4:any=[];
  xobj5:any=[];
  key_hash:any;
  cantidaProducto:any;

  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormPtDevolucionesComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { 

        
      this.accion = data.accion;
      this.cantidaProducto = data.cantidad_productos;
      this.ELEMENT_DATA2 = data.ELEMENT_DATA2;
      
      this.forma = fb.group({
        'cod_solicitud_devolucion':data.cod_solicitud_devolucion,
        'cod_documento':data.cod_documento,
        'cod_producto':data.cod_producto+'-'+data.des_producto,
        'des_producto': data.des_producto,
        'cod_prod_mp': data.cod_prod_mp,
        'des_prod_mp': data.des_prod_mp,
        'tipo_producto_mp': data.tipo_producto_mp,
        'cantidad_prod_mp': data.cantidad_prod_mp,
        'unidad_prod_mp': data.unidad_prod_mp,
        'cod_usuario': data.cod_usuario,
        'des_usuario': data.des_usuario,
        'dev_producto':'',
      });
      this.stateForm = this.formb.group({
        stateGroup:'',
      });  

    }

  ngOnInit(): void {
    this.mostrarproductosPT(this.data.cod_producto);
    this.formulario();
  }

  formulario():void{
    this.cant = false;
  }

  mostrarproductosPT(xvalor:any){
  
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width:'15%',
      height:'25%',
      data:{
          accion:this.accion},
      disableClose: true,
      panelClass:'spinner',
      backdropClass:"bdrop"
    });
    setTimeout( async() => {
      this.servicio.mostrarProductosformulaDevueltoshttp(xvalor).subscribe(
        response =>{
          console.log(response, 'informacion 4')
          this.xobj3 = [];
          for (const i in response[0]){
            this.xobj3.push(response[0][i]);
          }
          if(response){
            this.dialogSpin.close();
            console.log(response.result,'una respuesta ultra final');
            this.xobj2 = response;
            this.ELEMENT_DATA = [];
                for (const i in response.result) {
            this.ELEMENT_DATA.push({      
                  cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
                  cod_documento:this.data.cod_documento,     
                  cod_producto:response.result[i].cod_producto,             
                  des_producto:response.result[i].des_producto,              
                  cod_prod_mp:response.result[i].cod_prod_mp,         
                  des_prod_mp:response.result[i].des_prod_mp,           
                  tipo_producto_mp:response.result[i].tipo_producto_mp,
                  cantidad_prod_mp:response.result[i].cantidad_prod_mp,
                  cantidad_producto:this.cantidaProducto,
                  unidad_prod_mp:response.result[i].unidad_prod_mp,
                  dev_producto:'0',
                  cod_usuario: this.data.cod_usuario,
                  des_usuario: this.data.des_usuario,
                  accion:this.accion
                  });
                }
                this.displayedColumns = [
                    'cod_prod_mp',
                    'des_prod_mp',
                    'tipo_producto_mp', 
                    'cantidad_prod_mp', 
                    'cantidad_producto',
                    'dev_producto',
                    'dev_producto_tomar_data', 
                 ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
          }
        },
        error=>{
          console.log(error.error.message);
        }
      );
    },1000);
    return this.xobj2;
  } 


  tomar_data(xvalor:any,xvalor2:any,cantidad_prod_mp:any){
    try {
      if(xvalor){

          var dialogRef
          console.log(cantidad_prod_mp, 'sigamos por aqui con cantidad_prod_mp ');
                  dialogRef = this.dialog.open(DevolverFormulaProductoComponent, {
                    width:'100vw',
                    maxWidth:'100vw',
                    height:'100vh',
                    data: { cod_usuario: this.data.cod_usuario,
                            des_usuario: this.data.des_usuario,
                            des_producto:xvalor2.des_producto,
                            cod_documento:this.data.cod_documento,
                            cantidad_prod_mp: this.data.cantidad_productos,
                            cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
                            cantidad_producto:cantidad_prod_mp,
                            tipo_producto_mp:xvalor2.tipo_producto_mp,
                            cod_prod_mp:xvalor2.cod_prod_mp, cod_producto:xvalor2.cod_producto,
                            des_prod_mp:xvalor2.des_prod_mp, unidad_prod_mp:xvalor2.unidad_prod_mp, dev_producto:xvalor2.dev_producto
                          },
                          disableClose:true,
                    panelClass:'pedidos_modal',
                    backdropClass:"bdrop"
                  });
                  dialogRef.afterClosed().subscribe(result => {

                    console.log(result, 'Aqui ta')

          for (const i in this.ELEMENT_DATA) {
            if (this.ELEMENT_DATA[i].cod_prod_mp === result.cod_prod_mp) {

              this.ELEMENT_DATA[i].dev_producto = result.dev_producto;

            }
          }
        })
      
      }else{

        for (const i in this.ELEMENT_DATA) {
          if (this.ELEMENT_DATA[i].cod_prod_mp === xvalor2.cod_prod_mp) {

              this.ELEMENT_DATA[i].dev_producto = '0';
          }
        }
      }
    } catch (error) {
      console.log(error);
    }             
  }
  
  cantidad_dev() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].dev_producto)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.'));
         });
       return suma;
    } catch (error) {
       this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

 cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_producto)
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.'));
       });
    return suma;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

/*   modalrespuesta(){
      this.ELEMENT_DATA2.push(this.ELEMENT_DATA)
      console.log(this.ELEMENT_DATA2, 'soy tu hijo')
  } */

  modalrespuesta(){
   
            this.accion = "enviarcliente";

            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              data: {id:'',
              accion:this.accion
              },
              disableClose: true,
              panelClass:'pedidos_modal',
              backdropClass: "bdrop",
            });
            dialogRef.afterClosed().subscribe(result => {
              
              if(result !== 'cerrar'){
                        this.dialogRefp.close(this.ELEMENT_DATA);  
              } 
            });
  }

}
