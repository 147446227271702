import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormCantidadTomaFisicaCompletaComponent } from '../form-cantidad-toma-fisica-completa/form-cantidad-toma-fisica-completa.component';
import axios from 'axios';

@Component({
  selector: 'app-form-toma-fisica-completa',
  templateUrl: './form-toma-fisica-completa.component.html',
  styleUrls: ['./form-toma-fisica-completa.component.css']
})
export class FormTomaFisicaCompletaComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  xobj2:any;
  forma:FormGroup;
  accion:any;
  dialogSpin:any;

  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  exceldata:any[]=[];

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormTomaFisicaCompletaComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.forma = fb.group({
        'cod_solicitud_toma_fisica':data.cod_solicitud_toma_fisica,
        'cod_deposito':data.cod_deposito,
        'cod_producto':data.cod_producto,
        'des_producto': data.des_producto,
        'tipo_producto':data.tipo_producto,
        'um_producto':data.um_producto,
        'cant_producto': data.cant_producto,
        'cant_ingresada':'',
        'tipo':data.tipo,
        'fase':data.fase,
        'fase_1':data.fase_1,
        'fase_2':data.fase_2,
        'fase_3':data.fase_3,
      });
    }

  ngOnInit(): void {
    this.listarCatidadProductos(this.data.cod_deposito, this.data.fase, this.data.cod_solicitud_toma_fisica, this.data.tipo);
  }
  
  modalrespuesta(){
    for (const element of this.ELEMENT_DATA) {
      if(element.cant_producto > 0){
        if(element.cant_ingresada > 0){
        }else{
          this.snackBar.open('Debe elegir todos los productos','Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
          return
        }
      }
    }
     /* activar validacion  */
     if (this.ELEMENT_DATA[0].fase == 'fase_3') { 
      this.snackBar.open('esta toma fisica concluyo', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
       }else{  
            this.accion = "enviarcliente";
            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100%',
              data: {id:'',
              accion:this.accion
              },
              disableClose: true,
              panelClass:'pedidos_modal',
              backdropClass: "bdrop",
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log('aqui bro lacra')
              if(result != 'cerrar'){
                this.servicio.segTomaFisicaCompletahttp(this.ELEMENT_DATA, this.data.fase, this.data.tipo).subscribe(
                  response =>{
                    console.log(response, 'servicio finisimo');
                  },
                  error => {
                    console.log(error)

                    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                  })
                  
              
                        this.dialogRefp.close(this.forma.value);  
                     
              } 
            });
       } 
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  
  listarCatidadProductos(xvalor:any, xvalor2:any, xvalor3:any, xvalor4:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width:'15%',
      height:'25%',
      data:{
          accion:this.accion},
      disableClose: true,
      panelClass:'spinner',
      backdropClass:"bdrop"
    });
    setTimeout( async() => {
      console.log(this.data.cod_deposito,'yo creo que estamos mejorando')
        this.servicio.listarInventarioPorDepositohttp(xvalor, xvalor2, xvalor3, xvalor4).subscribe(
          response =>{
            console.log(response, 'ahora listame esto')
            if(response){
              this.dialogSpin.close();
              console.log(response.result,'una respuesta ultra final');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.result) {
              this.ELEMENT_DATA.push({      
                    cod_solicitud_toma_fisica:this.data.cod_solicitud_toma_fisica,
                    cod_deposito:this.data.cod_deposito,     
                    cod_producto:response.result[i].cod_producto,             
                    des_producto:response.result[i].des_producto,
                    tipo_producto:response.result[i].tipo_producto,
                    unidad_medida_producto:response.result[i].unidad_medida_producto,
                    cant_producto:response.result[i].cant_producto,
                    cant_ingresada:'0',
                    fase:response.result[i].fase,
                    fase_1:response.result[i].fase_1 == 'N/A' ? '-': response.result[i].fase_1 ,
                    dif_fase_1:response.result[i].fase_1 == 'N/A' 
                    ? '-'
                    : (Number(response.result[i].fase_1.replaceAll('.','').replace(',','.')) - Number(response.result[i].cant_producto.replaceAll('.','').replace(',','.'))).toFixed(2).replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") ,
                    fase_2:response.result[i].fase_2 == 'N/A' 
                    ? '-'
                    : response.result[i].fase_2 ,
                    dif_fase_2:response.result[i].fase_2 == 'N/A' 
                    ? '-'
                    : (Number(response.result[i].fase_2.replaceAll('.','').replace(',','.')) - Number(response.result[i].cant_producto.replaceAll('.','').replace(',','.'))).toFixed(2).replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") ,
                    fase_3:response.result[i].fase_3 == 'N/A' 
                    ? '-'
                    : response.result[i].fase_3 ,  
                    dif_fase_3:response.result[i].fase_3 == 'N/A' 
                    ? '-'
                    : (Number(response.result[i].fase_3.replaceAll('.','').replace(',','.')) - Number(response.result[i].cant_producto.replaceAll('.','').replace(',','.'))).toFixed(2).replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") ,  
                    check_estatus: false,
                    accion:this.accion
                    });
                  }
                  this.displayedColumns = [
                      'cod_producto',
                      'des_producto', 
                      'tipo_producto', 
                      'unidad_medida_producto',
                      'cant_producto',
                      'fase_1',
                      'dif_fase_1',
                      'fase_2',
                      'dif_fase_2',
                      'fase_3',   
                      'dif_fase_3',
                      'tomar_data_ingresada', 
                  ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
            }
          });
        },1000);
        return this.xobj2;
  }

  tomar_data(xvalor:any,xvalor2:any,cant_producto:any){
    try {
     if(xvalor){
            var dialogRef
            console.log(cant_producto, 'sigamos por aqui con cantidad_prod_mp ');
                    dialogRef = this.dialog.open(FormCantidadTomaFisicaCompletaComponent, {
                      width:'100vw',
                      maxWidth:'100vw',
                      height:'100%',
                      data: {des_producto:xvalor2.des_producto,
                             cant_producto:xvalor2.cant_producto,
                             tipo_producto_mp:xvalor2.tipo_producto_mp,
                             cod_producto:xvalor2.cod_producto,
                             cant_ingresada:xvalor2.cant_ingresada,
                             unidad_medida_producto:xvalor2.unidad_medida_producto,
                             fase:this.data.fase
                            },
                            disableClose:true,
                      panelClass:'pedidos_modal',
                      backdropClass:"bdrop"
                    });
                    dialogRef.afterClosed().subscribe(result => {

                      if(result != 'cerrar'){

                        for (const i in this.ELEMENT_DATA) {
                          if (this.ELEMENT_DATA[i].cod_producto === result.cod_producto) {
                            this.ELEMENT_DATA[i].cant_ingresada = result.cant_ingresada;
                            this.ELEMENT_DATA[i].fase = this.data.fase;
                            this.ELEMENT_DATA[i].check_estatus = true

                            if(this.data.fase == 'N/A'){

                              this.ELEMENT_DATA[i].fase_1 = result.cant_ingresada
                              this.ELEMENT_DATA[i].dif_fase_1 = (Number(result.cant_ingresada.replaceAll('.','').replace(',','.')) - Number(this.ELEMENT_DATA[i].cant_producto.replaceAll('.','').replace(',','.'))).toFixed(2).replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                              
                            }
                            if(this.data.fase == 'fase_1'){

                              this.ELEMENT_DATA[i].fase_2 = result.cant_ingresada
                              this.ELEMENT_DATA[i].dif_fase_2 = (Number(result.cant_ingresada.replaceAll('.','').replace(',','.')) - Number(this.ELEMENT_DATA[i].cant_producto.replaceAll('.','').replace(',','.'))).toFixed(2).replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")

                            }
                            if(this.data.fase == 'fase_2'){

                              this.ELEMENT_DATA[i].fase_3 = result.cant_ingresada
                              this.ELEMENT_DATA[i].dif_fase_3 = (Number(result.cant_ingresada.replaceAll('.','').replace(',','.')) - Number(this.ELEMENT_DATA[i].cant_producto.replaceAll('.','').replace(',','.'))).toFixed(2).replace('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")

                            }

                          }
                        }
                        console.log(this.ELEMENT_DATA,'esto esta bello para enviar')
                        this.ELEMENT_DATA2.push(result);
                        
                      }
           
          });
      }else{
        for (const i in this.ELEMENT_DATA) {
          if (this.ELEMENT_DATA[i].cod_producto === xvalor2.cod_producto) {
              this.ELEMENT_DATA[i].cant_ingresada = '0';
              this.ELEMENT_DATA[i].check_estatus = false

              if(this.data.fase == 'N/A'){

                this.ELEMENT_DATA[i].fase_1 = '0'
                this.ELEMENT_DATA[i].dif_fase_1 = '0'
                
              }
              if(this.data.fase == 'fase_1'){

                this.ELEMENT_DATA[i].fase_2 = '0'
                this.ELEMENT_DATA[i].dif_fase_2 = '0'

              }
              if(this.data.fase == 'fase_2'){

                this.ELEMENT_DATA[i].fase_2 = '0'
                this.ELEMENT_DATA[i].dif_fase_3 = '0'

              }

          }
        }
      }
    } catch (error) {
      console.log(error);
    }             
  }

  excel(){
    let exceltData = []
    for (const i in this.ELEMENT_DATA) {
      exceltData.push({    
            vacio: ' ',  
            cod_producto:this.ELEMENT_DATA[i].cod_producto,             
            des_producto:this.ELEMENT_DATA[i].des_producto,
            tipo_producto:this.ELEMENT_DATA[i].tipo_producto,
            unidad_medida_producto:this.ELEMENT_DATA[i].unidad_medida_producto,
            cant_producto: parseFloat(this.ELEMENT_DATA[i].cant_producto.replaceAll('.','').replace(',','.')),
            fase_1: parseFloat(this.ELEMENT_DATA[i].fase_1.replaceAll('.','').replace(',','.')),
            dif_fase_1: parseFloat(this.ELEMENT_DATA[i].dif_fase_1.replaceAll('.','').replace(',','.')),
            fase_2: parseFloat(this.ELEMENT_DATA[i].fase_2.replaceAll('.','').replace(',','.')),
            dif_fase_2: parseFloat(this.ELEMENT_DATA[i].dif_fase_2.replaceAll('.','').replace(',','.')),
            fase_3: parseFloat(this.ELEMENT_DATA[i].fase_3.replaceAll('.','').replace(',','.')),             
            dif_fase_3: parseFloat(this.ELEMENT_DATA[i].dif_fase_3.replaceAll('.','').replace(',','.')),             
            });
          }
    this.descarga_xlsx(exceltData,this.data);
  }

  async descarga_xlsx(xdatos:any,datos:any){
      try {

        let workbook = new Workbook(); 

        var indicesExcel = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V',
                            'W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN','AO',
                            'AP','AQ','AR','AS','AT','AU','AV','AW','AX','AY','AZ'];
       
        let header=["Codigo de producto","Descripcion de producto","Tipo de producto", "Unidad de medida",
                    "Cantidad en inventario","Fase 1","Diferencia Fase 1 ","Fase 2","Diferencia Fase 2 ","Fase 3" ,"Diferencia Fase 3"];
        header.unshift(' ')

        var longitud = header.length

        var indicesExcelConHeader= [...indicesExcel].splice(1,longitud - 1)
        var ultimoIndice = indicesExcelConHeader[indicesExcelConHeader.length - 1]

        const worksheet = workbook.addWorksheet("Toma fisica",{views: [{showGridLines: false}]});

        const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
        const imageId2 = workbook.addImage({
          buffer: imageBuffer.data,
          extension: 'png',
        });
        
        worksheet.addImage(imageId2,`${indicesExcelConHeader[0]}2:${indicesExcelConHeader[0]}6`)

        worksheet.mergeCells(`${indicesExcelConHeader[0]}2:${ultimoIndice}2`)
        worksheet.getCell('D2').value='Factory Cosmetics 3DG, C.A.';
        worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D2').font={bold: true};
    
        worksheet.mergeCells(`${indicesExcelConHeader[0]}3:${ultimoIndice}3`)
        worksheet.getCell('D3').value='Rif J-41031797-3';
        worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D3').font={bold: true};
        
        worksheet.mergeCells(`${indicesExcelConHeader[0]}4:${ultimoIndice}4`)
        worksheet.getCell('D4').value='Reporte de Toma Fisica';
        worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D4').font={bold: true};
    
        worksheet.mergeCells(`${indicesExcelConHeader[0]}5:${ultimoIndice}5`)
        worksheet.getCell('D5').value='Reflejando lo contado en sus distintas fases';
        worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D5').font={bold: true};
        
        worksheet.mergeCells(`${indicesExcelConHeader[0]}6:${ultimoIndice}6`)
        
        worksheet.autoFilter = {
          from: `${indicesExcelConHeader[0]}7`,
          to: `${ultimoIndice}7`,
        }
       
        worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

        indicesExcelConHeader.map(celdas => {
          if(celdas){
            worksheet.getCell(`${celdas}7`).border = {
              top: {style:'double', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}}
            };
          }
        })
        indicesExcelConHeader.map(celdas => {
          if(celdas){
            worksheet.getCell(`${celdas}7`).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '0050a0'},
            };
          }
        })
        indicesExcelConHeader.map(centrar => {
          if(centrar){
            worksheet.getCell(`${centrar}7`).alignment = { vertical: 'top', horizontal: 'center' };
          }
        })
        var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
        worksheet.getColumn(6).numFmt = numFmtStr;
        worksheet.getColumn(7).numFmt = numFmtStr;
        worksheet.getColumn(8).numFmt = numFmtStr;
        worksheet.getColumn(9).numFmt = numFmtStr;
        worksheet.getColumn(10).numFmt = numFmtStr;
        worksheet.getColumn(11).numFmt = numFmtStr;

        /////////////////////wrap//////////////////////
        /* var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7'] */
        indicesExcelConHeader.map(celdas =>{
        worksheet.getCell(`${celdas}7`).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
          
        })
        /////////////////////wrap//////////////////////
        
        /////////////////////ROW//////////////////////
        worksheet.getRow(7).height= 30
        /////////////////////ROW//////////////////////
        
        for (let x1 of xdatos)
        {
          let x2=Object.keys(x1);
          let temp=[]
          for(let y of x2)
          {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
         }
        for (const i in indicesExcelConHeader) {
          worksheet.getColumn(1).width = 3.5

          var maximo = 0 
          worksheet.getColumn(indicesExcelConHeader[i]).eachCell({ includeEmpty: true },cell =>{
            maximo = cell.value ? cell.value.toString().length : 0; 
          });
          worksheet.getColumn(indicesExcelConHeader[i]).width = maximo < 17 ? 17 : maximo + 10
        } 
         
          for(let i = 0; i <= xdatos.length; i++) {
      
          if(i == 0){
            for (let j = 0; j <= indicesExcelConHeader.length; j++){
              if(indicesExcelConHeader[j]){

                var fila = `${indicesExcelConHeader[j].toString()}`
                if(j == 0){

                  worksheet.getCell(`${fila}8`).border = {
                    bottom: {style:'thin', color: {argb:'00000000'}}, 
                  };
                  worksheet.getCell(`${fila}8`).alignment = { vertical: 'top', horizontal: 'center' }
                  
                }else{
                  
                  worksheet.getCell(`${fila}8`).alignment = { vertical: 'top', horizontal: 'center' }
                }
              }
            }
          }else{
            var numActu = i + 8 -1;
            var numActuString = numActu.toString()
            for (let j = 0; j <= indicesExcelConHeader.length; j++){

              if(indicesExcelConHeader[j]){ 
                var fila = `${indicesExcelConHeader[j].toString()}`
                var ultimaFila = `${indicesExcelConHeader[indicesExcelConHeader.length - 1].toString()}`

                worksheet.getCell(`${fila}${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
                
                if(j == 0){

                  worksheet.getCell(`${fila}${numActuString}`).border = {
                    bottom: {style:'thin', color: {argb:'00000000'}},
                    left: {style:'double', color: {argb:'00000000'}},
                  };
                }else{
                  
                  worksheet.getCell(`${fila}${numActuString}`).border = {
                    bottom: {style:'thin', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`${ultimaFila}${numActuString}`).border = {
                    bottom: {style:'thin', color: {argb:'00000000'}},
                    right: {style:'double', color: {argb:'00000000'}},
                  };
                }
              }
            }
          }
          if(i == xdatos.length){
            var numActu = i + 8 -1;
            var final_mas_uno = i + 8 + 1
            var final_mas_uno_s = final_mas_uno.toString()

            for (let j = 0; j <= indicesExcelConHeader.length; j++){
              if(indicesExcelConHeader[j]){ 
                var fila = `${indicesExcelConHeader[j].toString()}`
                var ultimaFila = `${indicesExcelConHeader[indicesExcelConHeader.length - 1].toString()}`
                if(j == 0){

                  worksheet.getCell(`${fila}${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                    left: {style:'double', color: {argb:'00000000'}},
                  };

                }else{
                  worksheet.getCell(`${fila}${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`${ultimaFila}${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                    right: {style:'double', color: {argb:'00000000'}},
                  };
                }
              }
            }    
          }
        }
          let fname="Toma fisca";
          workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname+'-'+this.data.cod_solicitud_toma_fisica+'.xlsx');
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
  }


  actualizarInventario(){



  }


}


