import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormUsuariosComponent} from '../../../shared/modal/form-usuarios/form-usuarios.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'; 
import { PeriodicElement2 } from '../../../shared/models/interfaceUsuarios';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { FormParametrosComponent } from 'src/app/componentes/shared/modal/form-parametros/form-parametros.component';
import { FormDefinicionesAccesosComponent } from 'src/app/componentes/shared/modal/form-definiciones-accesos/form-definiciones-accesos.component';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';
import { environment } from 'src/environments/environment';
import { FormAppEmpresaComponent } from 'src/app/componentes/shared/modal/form-app-empresa/form-app-empresa.component';
import { FormGaleriaEmpresaComponent } from 'src/app/componentes/shared/modal/form-galeria-empresa/form-galeria-empresa.component';
import { AnySoaRecord } from 'dns';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-app.perfiles',
  templateUrl: './app.perfiles.component.html',
  styleUrls: ['./app.perfiles.component.css']
})
export class PerfilesComponent implements OnInit , AfterViewInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  acciones:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  licencias:any;
  accion:any;
  parametros:any = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:AnySoaRecord[];
  dataSource=new MatTableDataSource<any>();

  constructor( 
        private servicio:nkServices, 
        private dialog: MatDialog, 
        private snackBar: MatSnackBar, 
        private router:Router,
        private util:AppUtilService,
        private atrb:AtributosService,
        private hashkey:HashKeyServices,
        private notifi:ToastNotificationService,
    ) {}

  ngOnInit(){
    this.listardatos(null); 
    this.listarLicencias(null); 
    this.buscardefiniciones(); 
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
    
  }
/////////////////////////////////////////////////////////////////////
async  listardatos(msj:any){
    try {
      let msg = msj;
      await this.util.abrirSpinner();
      var xobjeto = {
        cod_rol:this.atrb.ROL_USUARIOS.cod_rol,
        cod_empresa:this.atrb.COD_EMPRESA.cod_empresa,
        cod_app: await this.hashkey.encrypt(environment.path_token),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarUsuarios),
      }
     const response =  await  this.servicio.ApiResthttp(xobjeto).toPromise();
            this.xobj3=[];
            this.xobj4=[];
            this.xobj5=[];
            for (const i in response[1]) {
              this.xobj3.push(response[1][i]);
            }
            for (const i in response[2]) {
              this.xobj4.push(response[2][i]);
            }
            for (const i in response[3]) {
              this.xobj5.push(response[3][i]);
            }
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response[0]) {
                    if (response[0][i].cod_img === '' || response[0][i].cod_img === null || parseInt(response[0][i].cod_img) === 0) {
                      response[0][i].cod_img = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                    }else{
                      response[0][i].cod_img = environment.path_storage+response[0][i].cod_img
                    }
                    this.ELEMENT_DATA.push({
                      id:response[0][i].id, 
                      cod_usuario:response[0][i].cod_usuario, 
                      cod_unidad: response[0][i].cod_unidad,
                      des_unidad: response[0][i].des_unidad,
                      cod_dpto: response[0][i].cod_dpto,
                      des_dpto: response[0][i].des_dpto,
                      nombre:response[0][i].nombre,
                      apellido:response[0][i].apellido,  
                      estatus:response[0][i].estatus,
                      correo:response[0][i].correo,
                      direccion:response[0][i].direccion,
                      telefono:response[0][i].telefono,
                      nom_usuario:response[0][i].nom_usuario,
                      pass_usuario:response[0][i].pass_usuario,
                      cod_rol: response[0][i].cod_rol,
                      des_rol: response[0][i].des_rol,
                      cod_perfil: response[0][i].cod_perfil,
                      des_perfil: response[0][i].des_perfil,
                      mod_usuario:response[0][i].mod_usuario,
                      cod_empresa:response[0][i].cod_empresa,
                      cod_img:response[0][i].cod_img,
                    });
                  }
                  this.displayedColumns = parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0 ? 
                                                    await this.atrb.TABLA_USUARIOS : await this.atrb.TABLA_USUARIOS_ADMIN;
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
        if(msg === await this.atrb.ACCION_ACTUALIZAR){
          await this.util.cerrarSpinner();
            await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
        }else{
          await this.util.cerrarSpinner();
        }
    } catch (error) {
      await this.util.cerrarSpinner();
      this.xobj3=[];
            this.xobj4=[];
            this.xobj5=[];
            for (const i in error.error.resul2) {
              this.xobj3.push(error.error.resul2[i]);
            }
            for (const i in error.error.resul3) {
              this.xobj4.push(error.error.resul3[i]);
            }
            for (const i in error.error.resul4) {
              this.xobj5.push(error.error.resul4[i]);
            }
            this.displayedColumns = parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0 ? 
              await this.atrb.TABLA_USUARIOS : await this.atrb.TABLA_USUARIOS_ADMIN;
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
        await this.notifi.customToastNotification(await error.error.message);
    }
  }
async  listarLicencias(msj:any){
    try {
      var xobjeto = {
        cod_rol:this.atrb.ROL_USUARIOS.cod_rol,
        cod_empresa:this.atrb.COD_EMPRESA.cod_empresa,
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarLicencias),
      }
      const response = await  this.servicio.ApiResthttp(xobjeto).toPromise();
              this.licencias = response.data;
    } catch (error) {
      console.log(error);
    }
  }
async buscardefiniciones(){
    try {
      var xvalor = {
        cod_menu: localStorage.getItem('sub_main'),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.buscardefiniciones),
      };
        const response = await this.servicio.ApiResthttp(xvalor).toPromise();
            this.parametros = [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === this.atrb.GALERIA_EMPRESA.parametros_galeria.toUpperCase()) {
                this.parametros.push(response[i]);
              }
            }
    } catch (error) {
      console.log(error.error.message);
    }
  }
  //////////////////////FUNCION DE ACCION/////////////////////////
async definicionesAcciones(xobjeto:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
            var dialogRef = this.dialog.open(FormDefinicionesAccesosComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'auto',
              data: {
                    data:xobjeto,
                    accion:true
                  },
                    disableClose: true,
                    panelClass: 'pedidos_modal3',
                    backdropClass: "bdrop" 
            });
            dialogRef.afterClosed().subscribe(async result => {
              await this.util.cerrarSpinner();
              if(result !== await this.atrb.CERRAR){
                console.log(result);
                let resultado = this.gestionarusuriorol(result);
              } 
            });
      
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
        await this.notifi.customToastNotification(error.error.message);
    }
  }
async definicionesCorreos(xobjeto:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
            var dialogRef = this.dialog.open(FormDefinicionesAccesosComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'auto',
              data: {
                    data:xobjeto,
                    accion:true
                  },
                    disableClose: true,
                    panelClass: 'pedidos_modal3',
                    backdropClass: "bdrop" 
            });
            dialogRef.afterClosed().subscribe(async result => {
              await this.util.cerrarSpinner();
              if(result !== await this.atrb.CERRAR){
                console.log(result);
                let resultado = this.gestionarusuriorol(result);
              } 
            });
      
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
        await this.notifi.customToastNotification(error.error.message);
    }
  }
async permisosUsuarios(obj:any, text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
            this.accion = text;
            var dialogRef = this.dialog.open(FormPermisosComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'auto',
              data: {id:obj.id,
                    cod_usurio_rol:'', 
                    cod_usuario: obj.cod_usuario,
                    cod_unidad: obj.cod_unidad,
                    des_unidad: obj.des_unidad, 
                    nombre:obj.nombre,
                    apellido:obj.apellido,
                    rol_usuario:obj.rol_usuario,
                    roles:this.xobj3,
                    menu:this.xobj5,
                    unidad:this.xobj4,
                    accion:this.accion},
                    disableClose: true,
                    panelClass: 'pedidos_modal3',
                    backdropClass: "bdrop" 
            });
            dialogRef.afterClosed().subscribe(async result => {
              await this.util.abrirSpinner();
              if(result !== await this.atrb.CERRAR){
                await this.util.cerrarSpinner();
                this.gestionarusuriorol(result);
      
              }else{
                await this.util.cerrarSpinner();
              }
            });
      
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async gestionarusuriorol(xvalor:any){
    try {
        const response = await this.servicio.gestionUsuarioRolhttp(xvalor).toPromise();
        await this.notifi.success(response.message);
        await this.util.cerrarSpinner();
       await this.listardatos(null);
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.listardatos(null);
      await this.notifi.customToastNotification(error.error.message);
    }
   }
async  update_insert_data(obj:any, text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
             if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
              if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def;
                                  val_def = {
                                    val_def:await this.atrb.ACCESO_VALDEF.gestionar_usuarios,
                                    tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                    cod_usuario :  await this.atrb.COD_PERFIL.cod_perfil,
                                    api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                  }
                              await this.servicio.ApiResthttp(val_def).toPromise();
              }
                        obj.cod_img_sin = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                              await this.util.cerrarSpinner();
                              this.accion = text;
                              var dialogRef = this.dialog.open(FormUsuariosComponent, {
                                width: '100vw',
                                maxWidth: '100vw',
                                height:'auto',
                                data: {
                                      data:obj,
                                      licencias:this.licencias,
                                      roles:this.xobj3,
                                      unidades:this.xobj4,
                                      accion:this.accion},
                                disableClose: true,
                                panelClass: 'pedidos_modal_perfiles',
                                backdropClass: "bdrop" 
                              });
                }else{
                  if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                              let val_def;
                                  val_def = {
                                    val_def:await this.atrb.ACCESO_VALDEF.gestionar_usuarios,
                                    tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                    cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                    api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                  }
                              await this.servicio.ApiResthttp(val_def).toPromise();
                              let objeto = {
                                cod_empresa: this.atrb.COD_EMPRESA.cod_empresa,
                                mod_usuario: this.atrb.MOD_USUARIO.mod_usuario,
                                des_parametro: await this.atrb.OPERACIONES.usuarios,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.validarLicenciaProcesos)
                              };
                              await this.servicio.ApiResthttp(objeto).toPromise();
                    }
                              await this.util.cerrarSpinner();
                              this.accion = text;
                              dialogRef = this.dialog.open(FormUsuariosComponent, {
                                width: '100vw',
                                maxWidth: '100vw',
                                height:'auto',
                                data: {
                                  id:'', 
                                  roles:this.xobj3, 
                                  licencias:this.licencias,
                                  unidades:this.xobj4, 
                                  accion:this.accion},
                                disableClose: true,
                                panelClass: 'pedidos_modal_perfiles',
                                backdropClass: "bdrop" 
                              });
                            }
                            dialogRef.afterClosed().subscribe(async result => {
                              if(result !== await this.atrb.CERRAR){
                                await this.gestionUsuarios(result);
                              }else{
                                await this.util.cerrarSpinner();
                              }
                            });
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async gestionUsuarios(xvalor:any){
    try {
            if (xvalor.formdata.get('user_img') !== await this.atrb.UNDEFINED) {
                const img = await this.servicio.gestionarImgUserhttp(xvalor.formdata).toPromise();
                    xvalor.cod_img = img.file.path;
                    xvalor.des_img = img.file.filename;
            }else{
                    xvalor.cod_img = false;
                    xvalor.des_img = false;
            }
            xvalor.api = await this.hashkey.encrypt(await this.atrb.API_REST.ingresoUsuario)
        const response = await this.servicio.ApiResthttp(xvalor).toPromise();
        await this.util.cerrarSpinner();
                await this.notifi.success(response.message);
                await this.listardatos(null);
        return;
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
   }

  async  definicionesGaleria(obj:any){
    try {
          await this.util.abrirSpinner();
          await this.servicio.consultar_sesionhttp(null).toPromise();
          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                          let val_def;
                              val_def = {
                                val_def:await this.atrb.ACCESO_VALDEF.gestionar_usuarios,
                                tipo_operacion:await this.atrb.ACCESO_OPERACION.store_procedure,
                                cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                              }
                          await this.servicio.ApiResthttp(val_def).toPromise();
                    }
                            await this.util.cerrarSpinner();
                          let dialogRef = this.dialog.open(FormGaleriaEmpresaComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                data:obj,
                                parametros:this.parametros,
                               accion:''
                              },
                              disableClose: true,
                              panelClass: 'pedidos_modal3',
                              backdropClass: "bdrop"
                            });
                          dialogRef.afterClosed().subscribe(async result => {
                            if(result !== await this.atrb.CERRAR){
                              await this.util.cerrarSpinner();
                            }else{
                              await this.util.cerrarSpinner();
                            }
                          });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
