<br>
<form [formGroup]="forma" class="materialformtamaño">
    <mat-form-field class="materialinput">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre" formControlName="nombre">
    </mat-form-field>

    <mat-form-field class="materialinput">
        <mat-label>Apellido</mat-label>
        <input class="" matInput placeholder="Apellido" formControlName="apellido">
    </mat-form-field>

    <mat-form-field class="materialinput">
        <mat-label>Direccion</mat-label>
        <input class="" matInput placeholder="Direccion" formControlName="direccion">
    </mat-form-field>

    <mat-form-field class="materialinput">
        <mat-label>E-mail</mat-label>
        <input class="" matInput placeholder="E-mail" formControlName="correo">
    </mat-form-field>

    <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="actualizardatos()" mat-raised-button color="warn">Actualizar</button>
    <button *ngIf="accion == 'insertar'" class="btn-block" (click)="actualizardatos()" mat-raised-button color="warn">Guardar</button>
</form>