import { Component, OnInit, Inject, Injectable, ElementRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {BehaviorSubject} from 'rxjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatPaginator } from '@angular/material/paginator';
import { FormPermisosAccionesComponent } from '../form-permisos-acciones/form-permisos-acciones.component';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { environment } from 'src/environments/environment';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

@Component({
  selector: 'app-form-documentos-empresa',
  templateUrl: './form-documentos-empresa.component.html',
  styleUrls: ['./form-documentos-empresa.component.css']
})
export class FormDocumentosEmpresaComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('unidad_front') unidad_front:ElementRef;
  @ViewChild('menu_front') menu_front:ElementRef;
  @ViewChild('submenu_front',{static:true}) submenu_front:ElementRef;
  @ViewChild('submenu_hijo',{static:true}) submenu_hijo:ElementRef;
  archivo:File;
  rlsr:any;
  dialogSpin:any;
  forma:FormGroup;
  formaImg:FormGroup;
  formaTxt:FormGroup;
  parametros:any = [];
  parametro:any;
  accion:any;
  cant:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
  rx:any;
  constructor(
    private service:nkServices,
    private fb:FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog, 
    private servicio:nkServices,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private point: BreakpointObserver,
    private notifi:ToastNotificationService,
    public dialogRefp: MatDialogRef<FormDocumentosEmpresaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data, 'primero');
    this.rlsr =  hashkey.decrypt(localStorage.getItem('rlsr'));
   this.parametros = [...data.parametros];
      this.forma = fb.group({
        'concepto_definicion': ['',Validators.required],
        'constante': [''],
        'tipo_val': [''],
        'cod_documento': [data.data.cod_documento],
        'cod_empresa': [''],
        'archivo': [''],
        'operacion': [this.atrb.ACCION_CREAR],
      });
      this.formaImg = this.fb.group({
        'archivo':['',[Validators.required]],
        'cod_documento': [data.data.cod_documento],
        'formdata':[''],
        'cod_empresa': [''],
        'operacion': [this.atrb.ACCION_ACTUALIZAR],
        'tipo': [this.atrb.IMG],
        'cod_img': [''],
        'des_img': [''],
        'concepto_definicion': ['']
      });
      this.formaTxt = this.fb.group({
        'val_tex':['',[Validators.required]],
        'val_def':[''],
        'cod_documento': [data.data.cod_documento],
        'cod_empresa': [''],
        'operacion': [this.atrb.ACCION_ACTUALIZAR],
        'tipo': [this.atrb.TXT],
        'concepto_definicion': ['']
      });
     }

  ngOnInit(){
    this.listarParametroDocumentos();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
  }
 
 async listarParametroDocumentos(){
    try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
      this.ELEMENT_DATA = [];
      this.cant = false;
      var valor = {
        cod_documento: this.data.data.cod_documento,
        cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
      };
     const response = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
          let definicion_split;
          let definicion_valor;
          for (const i in response) {
            if (response[i].val_def === '' || response[i].val_def === null) {
              definicion_split = response[i].constante_documento.split('_');
              definicion_valor = definicion_split[0];
              if (definicion_valor.toUpperCase() === await this.atrb.IMG.toUpperCase()) {
                this.parametro = await this.atrb.IMG;
              }else{
                this.parametro = await this.atrb.TXT;
              }
            }else{
              definicion_split = response[i].constante_documento.split('_');
              definicion_valor = definicion_split[0];
              if (definicion_valor.toUpperCase() === await this.atrb.IMG.toUpperCase()) {
                this.parametro = await this.atrb.IMG;
                response[i].val_def = environment.path_storage+response[i].val_def
              }else{
                this.parametro = await this.atrb.TXT;
              }
              /* this.parametro = await this.atrb.DATA; */
            }
            
            this.ELEMENT_DATA.push({
              cod_documento:response[i].cod_documento,
              cod_empresa:response[i].cod_empresa,
              definicion_documento:response[i].definicion_documento,
              constante_documento:response[i].constante_documento,
              val_def:response[i].val_def,
              estatus: response[i].estatus,
              fecha_inicio: response[i].fecha_inicio,
              hora_inicio: response[i].hora_inicio,
              parametro:this.parametro
            });
          }
          this.displayedColumns = [
                    'cod_documento',
                    'cod_empresa',
                    'definicion_documento',
                    'val_def',
                    'estatus',
                    'fecha_inicio',
                    'hora_inicio',
                    'procesos'
                  ];
  
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      this.displayedColumns = [
                'cod_documento',
                'cod_empresa',
                'definicion_documento',
                'val_def',
                'estatus',
                'fecha_inicio',
                'hora_inicio',
                'procesos'
              ];
       this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
        await this.notifi.customToastNotification(error.error.message);
    }
  }
  async cargar_img(xvalor:any){
    try {
      await this.util.abrirSpinner();
     this.archivo = xvalor.target.files[0];
    await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
 async modalrespuesta(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            this.forma.patchValue({
              cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
              constante: this.forma.value.concepto_definicion.concepto_definicion,
              concepto_definicion: this.forma.value.concepto_definicion.descrip_definicion,
            });
           await this.gestionParametroDocumentos(this.forma.value);
          } else{
            await this.util.cerrarSpinner();
          }
        });
      
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
    
  }
  async gestionParametroDocumentos(xvalor:any){
    try {
        const response = await this.servicio.gestionParametroDocumentoshttp(xvalor).toPromise();
        await this.util.cerrarSpinner();
          await  this.listarParametroDocumentos();
          await this.notifi.success(response.message);
    } catch (error) {
      await this.util.cerrarSpinner();
      await  this.listarParametroDocumentos();
      await this.notifi.customToastNotification(error.error.message);
    }
   }
 async cerrarmodal() {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'auto',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result != 'cerrar') {
        this.dialogRefp.close('cerrar');
      }else{
        await this.util.cerrarSpinner();
      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 async getQuitarParametro(xobjeto:any){
   try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            xobjeto.operacion = this.atrb.ACCION_ACTUALIZAR;
            xobjeto.tipo = this.atrb.ACCION_BORRAR;
            xobjeto.cod_empresa = await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa);
           const response =  await this.servicio.gestionParametroDocumentoshttp(xobjeto).toPromise();
           await this.util.cerrarSpinner();
            await  this.listarParametroDocumentos();
              await this.notifi.success(response.message);
          } else{
            await this.util.cerrarSpinner();
          }
        });
   } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await  this.listarParametroDocumentos();
    await this.notifi.customToastNotification(error.error.message);
   }
   }
 async getImgConfig(xobjeto:any){
   try {
      await this.util.abrirSpinner();
      if (xobjeto.val_def !== null) {
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: { 
                accion: ''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          if(result != 'cerrar'){
            for (const i in this.ELEMENT_DATA) {
              if (this.ELEMENT_DATA[i].definicion_documento === xobjeto.definicion_documento) {
                this.ELEMENT_DATA[i].val_def = null;
              }
            }
            this.displayedColumns = [
              'cod_documento',
              'cod_empresa',
              'definicion_documento',
              'val_def',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'procesos'
            ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.actualizar_img);
          } else{
            await this.util.cerrarSpinner();
          }
        });
        return;
      }
      if (this.formaImg.invalid) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.seleccione_imagen);
          return;
        }
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            await this.util.cerrarSpinner();
          const formData = new FormData();
          formData.append('doc_img',this.archivo);
          this.formaImg.patchValue({
            cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
            formdata:formData
          });
          const img = await this.servicio.gestionarImgDochttp(this.formaImg.value.formdata).toPromise();
          this.formaImg.patchValue({
              cod_img : img.file.path,
              des_img : img.file.filename,
              concepto_definicion:xobjeto.definicion_documento
          });
          const response = await this.servicio.gestionParametroDocumentoshttp(this.formaImg.value).toPromise();
            await  this.listarParametroDocumentos();
            await this.notifi.success(response.message);
          } else{
            await this.util.cerrarSpinner();
          }
        });
    
   } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await  this.listarParametroDocumentos();
    await this.notifi.customToastNotification(error.error.message);
   }
   }
 async getTxtConfig(xobjeto:any){
   try {
      await this.util.abrirSpinner();
      if (parseInt(this.formaTxt.value.val_tex.length)  > 90) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.maximo_caracteres);
        return;
      }
      if (xobjeto.val_def !== null) {
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: { 
                accion: ''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          if(result != 'cerrar'){
            for (const i in this.ELEMENT_DATA) {
              if (this.ELEMENT_DATA[i].definicion_documento === xobjeto.definicion_documento) {
                this.ELEMENT_DATA[i].val_def = null;
              }
            }
            this.displayedColumns = [
              'cod_documento',
              'cod_empresa',
              'definicion_documento',
              'val_def',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'procesos'
            ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.agregar_texto);
          } else{
            await this.util.cerrarSpinner();
          }
        });
        return;
      }
      if (this.formaTxt.invalid) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.agregar_texto);
          return;
        }
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            await this.util.cerrarSpinner();
        this.formaTxt.patchValue({
          cod_empresa:await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
          val_def:this.formaTxt.value.val_tex,
          concepto_definicion:xobjeto.definicion_documento,
          val_tex:''
        });
          const response = await this.servicio.gestionParametroDocumentoshttp(this.formaTxt.value).toPromise();
            await  this.listarParametroDocumentos();
            await this.notifi.success(response.message);
          } else{
            await this.util.cerrarSpinner();
          }
        });
    
   } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await  this.listarParametroDocumentos();
    await this.notifi.customToastNotification(error.error.message);
   }
   }
}
