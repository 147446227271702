import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormPedidosComponent } from 'src/app/componentes/shared/modal/form-pedidos/form-pedidos.component';
import { PeriodicElementpedidos } from 'src/app/componentes/shared/models/interfacepedidos';
import { Img, ITable, PdfMakeWrapper, Table, Columns, Txt } from 'pdfmake-wrapper';
import { FormPedidosNComponent } from 'src/app/componentes/shared/modal/form-pedidos-n/form-pedidos-n.component';
import { FormPedidosNBsComponent } from 'src/app/componentes/shared/modal/form-pedidos-n-bs/form-pedidos-n-bs.component';
import { FormPedidoFrecuencialComponent } from 'src/app/componentes/shared/modal/form-pedido-frecuencial/form-pedido-frecuencial.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormPedidosUmComponent } from 'src/app/componentes/shared/modal/form-pedidos-um/form-pedidos-um.component';
import { FormPedidosNUmComponent } from 'src/app/componentes/shared/modal/form-pedidos-n-um/form-pedidos-n-um.component';
import { FormPedidosNBsUmComponent } from 'src/app/componentes/shared/modal/form-pedidos-n-bs-um/form-pedidos-n-bs-um.component';
import * as moment from 'moment';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Component({
  selector: 'app-consultar-pedidos',
  templateUrl: './consultar-pedidos.component.html',
  styleUrls: ['./consultar-pedidos.component.css']
})
export class ConsultarPedidosComponent implements OnInit {
  auth_menu:any = [];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  cliente:any;
  accion:any;
  table:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
  constructor(
      private servicio:nkServices,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private pdf : PdfServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private hashkey:HashKeyServices,
      private notifi:ToastNotificationService
      ) {}

  ngOnInit(){
    this.listarclientesp(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

  /////////////////////////////////////////////////////////////////////

async listarclientesp(msj:any){
    try { 
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let msg = msj;
      let xvalor = {
        cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
      }
     const response = await  this.servicio.listarPedidoshttp(xvalor).toPromise();
     this.table = 'cod_pedido';
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                      cod_pedido:response[i].cod_pedido,
                      cod_lista_precio:response[i].cod_lista_precio,
                      cod_cliente: response[i].cod_cliente,
                      des_cliente:response[i].des_cliente,
                      fecha_inicio:response[i].fecha_inicio,
                      estatus_pedido:response[i].estatus_pedido,
                      cod_vendedor: response[i].cod_vendedor,
                      des_vendedor:response[i].des_vendedor,
                      tipo_pedido:response[i].tipo_pedido,
                      fecha_fin:response[i].fecha_fin,
                      subtotal_costo_pedido:response[i].subtotal_costo_pedido,
                      total_costo_pedido:response[i].total_costo_pedido,
                      iva_monto:response[i].iva_monto,
                      iva_pedido:response[i].iva_pedido,
                      desc_pedido:response[i].desc_pedido,
                      mod_factur:response[i].mod_factur,
                      tipo_cliente:response[i].tipo_cliente,
                    });
                  }
                 this.displayedColumns = await this.atrb.TABLE_CONSULTA_PEDIDOS;
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            if(msg == await this.atrb.ACCION_ACTUALIZAR){
              await this.util.cerrarSpinner();
              await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
          }else{
            await this.util.cerrarSpinner();
          }
    } catch (error) {
      this.displayedColumns = await this.atrb.TABLE_CONSULTA_PEDIDOS;
        this.dataSource = new MatTableDataSource<any>();
        this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
      
    }
  }

 async update_insert_data(obj:any){
      try {
                  await this.util.abrirSpinner();
                  await this.servicio.consultar_sesionhttp(null).toPromise();
                    let val_def;
                        if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) > 0 ) {
                          val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_pedidos,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                            cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                          }
                          await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                        }
                    var dialogRef:any;
                    var valor = {cod_cliente:obj.cod_cliente};
                    const response = await this.servicio.buscarClienteshttp(valor).toPromise();
                            await this.util.cerrarSpinner();
                              dialogRef = this.dialog.open(FormPedidosComponent, {
                                width: '100vw',
                                    maxWidth: '100vw',
                                    height:'100vh',
                                data: {
                                        cod_pedido:obj.cod_pedido,
                                        cod_lista_precio:obj.cod_lista_precio,
                                        cod_cliente:obj.cod_cliente,
                                        des_cliente: obj.des_cliente,
                                        des_vendedor:obj.des_vendedor,
                                        tipo_cliente:response[0].tipo_cliente,
                                        estatus_pedido:obj.estatus_pedido,
                                        mod_factur:obj.mod_factur,
                                        accion:obj.tipo_pedido
                                      },
                                disableClose: true,
                                panelClass: 'pedidos_modal3',
                                backdropClass: "bdrop"
                              });
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                            if(result !== 'cerrar' && result !== 'salir'){
                                await this.gestionpedidos(result);
                            }else{
                              await this.util.cerrarSpinner();
                            }
                          });
      } catch (error) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
      }
}
async gestionpedidos(xvalor:any){
  try {
    const response = await this.servicio.gestionPedidoshttp(xvalor).toPromise();
         await this.util.cerrarSpinner();
         await this.notifi.success(response.message);
         await this.listarclientesp(null);
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}

async imprimirPedido(xvalor:any){
  try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null).toPromise();
    let val_def;
                        if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) > 0 ) {
                          val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_pedidos,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.let_join,
                            cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                          }
                          await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                        }
    await this.util.cerrarSpinner();
    let accion = "";
            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
                    maxWidth: '100vw',
                    height:'auto',
              data: {id:'',
                    accion:accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(async result => {
              await this.util.abrirSpinner();
              if(result !== 'cerrar'){
                const productos =  await this.servicio.listarProdPedidoParamhttp(xvalor).toPromise();
                if (productos.pdf4[0].tipo_pedido ===  await this.atrb.TIPO_PEDIDO.F) {
                  productos.pdf4[0].tipo_pedido = await this.atrb.TIPO_PEDIDO.PSF;
                }
                if (productos.pdf4[0].tipo_pedido ===  await this.atrb.TIPO_PEDIDO.ND) {
                  productos.pdf4[0].tipo_pedido =  await this.atrb.TIPO_PEDIDO.PSND;
                }
                if (productos.pdf4[0].tipo_pedido ===  await this.atrb.TIPO_PEDIDO.NB) {
                  productos.pdf4[0].tipo_pedido =  await this.atrb.TIPO_PEDIDO.PSNB;
                }
                if (productos.pdf4[0].tipo_pedido ===  await this.atrb.TIPO_PEDIDO.PF) {
                  productos.pdf4[0].tipo_pedido =  await this.atrb.TIPO_PEDIDO.PSPF;
                }
                productos.pdf2[0].comentarios =  await this.atrb.NO_APLICA;
                productos.pdf6 = productos.pdf5;
                productos.pdf6[0].cod_lista_precio_producto = productos.pdf4[0].cod_lista_precio;
                productos.pdf6[0].comentarios = await this.atrb.NO_APLICA;
                await this.pdf.pdfVentas(productos, await this.atrb.DD);
                await this.util.cerrarSpinner();
                await this.notifi.success(await this.atrb.MENSAJES_FIJOS.descarga_completa);
                }else{
                  await this.util.cerrarSpinner();
                }
            });
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}
     //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
