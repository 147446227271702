import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Console } from 'console';
import { MatPaginator } from '@angular/material/paginator';
import { FormKeyComponent } from '../form-key/form-key.component';
import { FormHistorialParametrosVentasComponent } from '../form-historial-parametros-ventas/form-historial-parametros-ventas.component';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-usuarios-centro-costo',
  templateUrl: './form-usuarios-centro-costo.component.html',
  styleUrls: ['./form-usuarios-centro-costo.component.css']
})
export class FormUsuariosCentroCostoComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('des_definicion',{static:true}) des_definicion:ElementRef;
  @ViewChild('operacion',{static:true}) operacion:ElementRef;
  @ViewChild('descrip_definicion',{static:true}) descrip_definicion:ElementRef;
  @ViewChild('var_defi',{static:true}) var_defi:ElementRef;

  producto:any = [];
  producto_2:any = [];
dialogSpin:any;
codigo:any = [];
codigo_2:any = [];
  forma:FormGroup;
  accion:any;
  roles:any;
  stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
arry: Observable<StateGroup[]>;
arry2: Observable<StateGroup[]>;
  rol = [];
  unidad=[];
  unidadp=[];

  parametros_precios:any = [];
  usuarios_objeto:any = [];
  usuarios_array:any = [];
  parametros_montos:any = [];
  key_hash:any;
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  secuencia:any;
  menur3:any;
  unidades:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormUsuariosCentroCostoComponent>, private toast:ToastNotificationService,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

    console.log(data, 'primero');

    this.forma = fb.group({
      'cod_centro': this.data.cod_centro,
      'des_unidad': this.data.des_unidad,
      'cod_usuario':'',
      'des_usuario':''
    });
   }

ngOnInit(): void {
  this.listar_parametros_acciones_accesos_usuario();
  this.listar_usuario_sistema();
 
}
@ViewChild(MatPaginator) paginator: MatPaginator;

ngAfterViewInit() {
  this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    this.dataSource.paginator = this.paginator;
}

/////////////////////////////////////////////////////////////////////
listar_usuario_sistema(){
  try {
      this.servicio.listarUsuarioshttp().subscribe(
        response =>{
          console.log();
          this.usuarios_objeto= response[0].filter(element => element.estatus == 'A').sort((a:any, b:any) => (a['nombre'] < b['nombre']) ? -1 : ((a['nombre'] > b['nombre']) ? 1 : 0));

          console.log(this.usuarios_objeto);
        },
        error =>{

            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
        }
      );
     
      return null;
   
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
listar_parametros_acciones_accesos_usuario(){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      
      this.servicio.listarRelacionUsuariosCentroCostohttp(this.data.cod_centro).subscribe(
        response =>{
          console.log(response);

          this.ELEMENT_DATA = response;

          this.dialogSpin.close();
          
            this.displayedColumns = [
              'cod_usuario',
              'des_usuario',
              'eliminar',
            ];
      
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        },
        error =>{
          this.dialogSpin.close();
          console.log(error);

          if(error.error.data){
            this.ELEMENT_DATA = error.error.data
          }
        
          this.displayedColumns = [
            'cod_usuario',
            'des_usuario',
            'eliminar',
          ];;
          
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
        }
      );
     
      return null;
    }, 500);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

crearUsuario(){
  
  try {

    let validationRepeat = this.ELEMENT_DATA.find(element => element.cod_usuario == this.forma.value.cod_usuario )

    if(validationRepeat){

      this.toast.danger(validationRepeat.des_usuario, 'Ya existe este usuario')
      return
    }
    
    if ((document.getElementById("cod_definicion") as HTMLInputElement).value !== '' &&
        (document.getElementById("usuarios") as HTMLInputElement).value !== '') {
     
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: { 
              accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop" 
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){

          let info = this.usuarios_objeto.find(element => element.cod_usuario == this.forma.value.cod_usuario)
  
          this.forma.value.des_usuario = `${info.nombre} ${info.apellido}` ;
           this.gestionDefinicionesSistemaAccionesAccesosUsuarios(this.forma.value);
         
        } 
      });
    }else{
      this.snackBar.open('Existen campos vacios', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
  } catch (error) {
    console.log(<any>error.error.message);
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
  }
}
borrarusuarioacciones(objeto:any){
  try {
  
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: { 
              accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop" 
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){

          let info = this.usuarios_objeto.find(element => element.cod_usuario == objeto.cod_usuario)
         
          let obj = {
            cod_centro: this.data.cod_centro,
            cod_usuario:objeto.cod_usuario,
            des_usuario:`${info.nombre} ${info.apellido}`
          }
         
          this.servicio.eliminarRelacionUsuariosCentroCostohttp(obj).subscribe(
            response => {

              this.toast.success('Eliminado!', response.message)

              this.listar_parametros_acciones_accesos_usuario()

            },
            error =>{

              this.listar_parametros_acciones_accesos_usuario()

              console.log(<any>error.error.message);
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});

            }
          )
           
         
        } 
      });

  } catch (error) {
    console.log(<any>error.error.message);
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
  }
}

gestionDefinicionesSistemaAccionesAccesosUsuarios(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    
      var resultado = this.servicio.crearRelacionUsuariosCentroCostohttp(xvalor).subscribe(
        response =>{
          this.dialogSpin.close();
          this.listar_parametros_acciones_accesos_usuario();
          this.toast.success('Listo!', response.message)

        },
        error =>{
          this.dialogSpin.close();
          console.log(<any>error.error.message);
          this.listar_parametros_acciones_accesos_usuario();
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
            
        }
      );
    
  } catch (error) {
    this.dialogSpin.close();
    console.log(error);
       this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
  }
 }

cerrarmodal(): void {
  var accion_ = 'salir'
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
    data: {id:'',
          accion:accion_},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
}

cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 500);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
