import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Console } from 'console';
import { MatPaginator } from '@angular/material/paginator';
import { FormKeyComponent } from '../form-key/form-key.component';
import { FormHistorialParametrosVentasComponent } from '../form-historial-parametros-ventas/form-historial-parametros-ventas.component';
import { FormAccionesAccesosUsuariosComponent } from '../form-acciones-accesos-usuarios/form-acciones-accesos-usuarios.component';
import { AtributosService } from '../../servicios/atributos.service';
import { MediaServicesService } from '../../servicios/media-services.service';
import { AppUtilService } from '../../servicios/app.util.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};


@Component({
  selector: 'app-form-definiciones-accesos',
  templateUrl: './form-definiciones-accesos.component.html',
  styleUrls: ['./form-definiciones-accesos.component.css']
})
export class  FormDefinicionesAccesosComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('des_definicion',{static:true}) des_definicion:ElementRef;
  @ViewChild('operacion',{static:true}) operacion:ElementRef;
  @ViewChild('descrip_definicion',{static:true}) descrip_definicion:ElementRef;
  @ViewChild('var_defi',{static:true}) var_defi:ElementRef;

  producto:any = [];
  producto_2:any = [];
  dialogSpin:any;
  codigo:any = [];
  codigo_2:any = [];
  forma:FormGroup;
  accion:any;
  roles:any;
  stateForm: FormGroup;
  stateGroups: StateGroup[];
  stateGroups2: StateGroup[];
  arry: Observable<StateGroup[]>;
  arry2: Observable<StateGroup[]>;
  rol = [];
  unidad=[];
  unidadp=[];

  parametros_precios:any = [];
  parametros_operacion:any = [];
  parametros_montos:any = [];
  key_hash:any;
  cod_rol:any;
  screen_wi:any = screen.width;
  screen_he:any = screen.height;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  secuencia:any;
  menur3:any;
  unidades:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  displayedColumns_sm:string[];
  dataSource=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  media:any;
  rx:any;
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicio:nkServices,
    private formb:FormBuilder, 
    private fb:FormBuilder,
    private atrb: AtributosService,
    private media_query: MediaServicesService,
    private point: BreakpointObserver,
    private util:AppUtilService,
    private notifi:ToastNotificationService,
    public dialogRefp: MatDialogRef<FormDefinicionesAccesosComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
    
    console.log(data, 'primero');

    this.forma = fb.group({
      'cod_definicion': '',
      'des_definicion': '',
      'descrip_definicion':'',
      'cod_usuario':'',
      'des_usuario':'',
      'operacion':'',
      'tipo_monto':'',
      'val_def':'',
      'modo':''
    });

    this.mediaQuery();
   }

ngOnInit(){
  this.listar_parametros_acciones_accesos(null);
  this.listarDefiniciones(null);
}
@ViewChild(MatPaginator) paginator: MatPaginator;

ngAfterViewInit() {
  this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
}
mediaQuery(){
  this.point
  .observe(Breakpoints.Small)
  .subscribe((state: BreakpointState) => {
    if (state.matches) {
    //AQUI SERA TRUE SOLO SI ESTA EN RESOLUCION DE CELULAR
    this.rx = 'phone';
  }
});

this.point
.observe(Breakpoints.Web)
.subscribe((state: BreakpointState) => {
  if (state.matches) {
    //AQUI SERA TRUE SOLO SI ES RESOLUCION PARA WEB
    this.rx = 'web';
  }
});
}
async listarDefiniciones(objeto:any){
  try {
    var xvalor ={cod_menu:localStorage.getItem('sub_main')};
      const response = await this.servicio.listarDefinicionesConceptoshttp(xvalor).toPromise();
          this.parametros_precios= [];
          this.parametros_operacion= [];
          for (const i in response) {
            if (response[i].des_definicion.toUpperCase() ===  'CONCEPTOS DE ACCESOS') {
              this.parametros_precios.push(response[i]);
            }
            console.log(this.parametros_precios);
            if (response[i].des_definicion.toUpperCase() === 'OPERACIONES') {
              this.parametros_operacion.push(response[i]);
            }
          }
  } catch (error) {
   console.log(error);
  }
}
/////////////////////////////////////////////////////////////////////
async listar_parametros_acciones_accesos(msj:any){
  try {
      let msg = msj;
      this.ELEMENT_DATA = [];
      var data = {
        cod_usuario:this.data.data.cod_usuario
      }
      await this.servicio.consultar_sesionhttp(null).toPromise();
    const response = await  this.servicio.listarAccionesUsuariohttp(data).toPromise();
          for (const i in response) {
            if (response[i].operacion.toUpperCase() === 'FCTRCN' && !this.accion) {
              response[i].operacion = 'FACTURACIÓN';
            }

            this.ELEMENT_DATA.push({
              cod_definicion:response[i].cod_definicion,
              des_definicion:response[i].des_definicion,
              descrip_definicion:response[i].descrip_definicion,
              cod_usuario:response[i].cod_usuario,
              des_usuario:response[i].des_usuario,
              estatus:response[i].bandera,
              operacion:response[i].operacion,
              val_def:response[i].val_def,
              fecha_inicio:response[i].fecha_inicio,
              hora_inicio:response[i].hora_inicio,
              accion:'',
              accion2:'',
              accion3:''
            });
          }

            this.displayedColumns = [
              'cod_definicion',
              'des_definicion',
              'descrip_definicion',
              'val_def',
              'operacion',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'definiciones',
            ];
            this.displayedColumns_sm = [
              'descrip_definicion',
              'operacion',
              'definiciones',
            ];
          
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      if(msg == await this.atrb.ACCION_ACTUALIZAR){
        await this.util.cerrarSpinner();
        await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
    }else{
      await this.util.cerrarSpinner();
    }
  } catch (error) {
    await this.util.cerrarSpinner();
    console.log(error);
    this.displayedColumns = [
      'cod_definicion',
      'des_definicion',
      'descrip_definicion',
      'val_def',
      'operacion',
      'estatus',
      'fecha_inicio',
      'hora_inicio',
      'definiciones',
    ];
    this.displayedColumns_sm = [
      'descrip_definicion',
      'operacion',
      'definiciones',
    ];
    await this.notifi.customToastNotification(error.error.message);
  }
}
async activar_desactivar_acciones(xvalor:any, accion:any){
  try {
   await this.util.abrirSpinner();
   await this.servicio.consultar_sesionhttp(null).toPromise();
            if (accion === this.atrb.ACCION_CREAR) {
              this.forma.patchValue({
                cod_usuario: this.data.data.cod_usuario,
                cod_definicion:xvalor.cod_definicion,
                modo:this.atrb.ACCION_CREAR
              });
            }
            if (accion === this.atrb.ACCION_BORRAR) {
              this.forma.patchValue({
                cod_usuario: this.data.data.cod_usuario,
                cod_definicion:xvalor.cod_definicion,
                modo:this.atrb.ACCION_BORRAR
              });
            }
            await this.util.cerrarSpinner();
            await this.modalrespuesta(this.forma.value);
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}
async modalrespuesta(xobjeto:any){
  try {
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: { 
              accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop" 
      });
      dialogRef.afterClosed().subscribe(async result => {
        if(result != 'cerrar'){
           this.gestionDefinicionesSistemaAccionesAccesosUsuarios(xobjeto);
        }else{
          await this.util.cerrarSpinner();
        }
      });
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}
async borrarusuarioacciones(objeto:any){
  try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null).toPromise();
    await this.util.cerrarSpinner();
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: { 
              accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop" 
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result != 'cerrar'){
          this.forma.value.cod_usuario = objeto.cod_usuario;
          this.forma.value.cod_definicion = this.data.cod_definicion;
          this.forma.value.modo = this.atrb.ACCION_BORRAR;
          await this.gestionDefinicionesSistemaAccionesAccesosUsuarios(this.forma.value);
        }else{
          await this.util.cerrarSpinner();
        }
      });
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}
async gestionDefinicionesSistemaAccionesAccesosUsuarios(xvalor:any){
  try {
      const response = await this.servicio.gestionDefinicionesSistemaAccionesAccesosUsuarioshttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
          this.listar_parametros_acciones_accesos(null);
          await this.notifi.success(response.message);
  } catch (error) {
    await this.util.cerrarSpinner();
    this.listar_parametros_acciones_accesos(null);
    await this.notifi.customToastNotification(error.error.message);
  }
 }
 async cerrarmodal(){
  try {
    let accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result != 'cerrar') {
        await this.util.cerrarSpinner();
        this.dialogRefp.close('cerrar');
      }else{
        await this.util.cerrarSpinner();
      }
    });
  } catch (error) {
    console.log(error);
  }
  }
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
