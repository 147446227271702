<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 5px;">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" class="">

                <div class="modal-content h-100">
                    <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-left"><strong>Nueva Toma fisica</strong> </h3>
                    </div>

                      <div class="modal-body" style="height: 60vh; overflow-x: hidden;">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <mat-form-field class="materialinput" >
                                        <mat-select (selectionChange)="hideDiv($event)"   style="font-size: 12px; color: black;" name="tipo" placeholder="Tipo de toma fisica" formControlName="tipo" autocomplete="off"> 
                                            <mat-option value="TFC">Toma fisica completa</mat-option>
                                            <mat-option value="TFCL">Toma fisica ciclica</mat-option>
                                            <mat-option value="TFIE">Toma fisica específica</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div class="" id="div_toma_fisica_completa" [style.display]=" validacion == 'TFC' ? 'block' : 'none' "> 

                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select #unidad_front placeholder="Codigo depositos"  (selectionChange)="listarProductosDepItemEsp($event)"  formControlName="cod_deposito" autocomplete="off"> 
                                                  <mat-option *ngFor="let item of codigo_deposito"  [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.descrip_deposito}}</mat-option>  
                                                </mat-select >
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Auditor:"  formControlName="des_auditor" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of Auditores" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Lider:"  formControlName="des_lider_inv" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of LiderInventario" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Supervisor o Coordinador:"  formControlName="des_supervisor_inv" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of supervisorInventario" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                               
                                    <div id="div_toma_fisica_ciclica" [style.display]=" validacion == 'TFCL' ? 'block' : 'none'"> 
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                
                                                <mat-select #unidad_front placeholder="Codigo depositos"  (selectionChange)="listarProductosDepItemEsp($event)"  formControlName="cod_deposito" autocomplete="off"> 
                                                    <mat-option  *ngFor="let item of codigo_deposito" [value]="item.cod_deposito">{{item.cod_deposito}} {{item.descrip_deposito}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                        </div>

                                         <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-label style="color: #000">Item Top:</mat-label>
                                                <input type="number" style="font-size: 12px; color: black;"  matInput placeholder="Ingresar cantidad de productos a buscar" formControlName="cant_item_top" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                         </div>

                                         <div class="col-md-6 col-sm-6 col-xs-6">
                                            <mat-form-field class="materialinput">
                                                <mat-label style="color: #000">Item Aleatorio:</mat-label>
                                                <input type="number" style="font-size: 12px; color: black;"  matInput placeholder="Ingresar cantidad de productos a buscar" formControlName="cant_item_random" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                         </div>

                                         <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Auditor:"  formControlName="des_auditor" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of Auditores" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                         </div>

                                         <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Lider:"  formControlName="des_lider_inv" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of LiderInventario" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                         </div>

                                         <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Supervisor o Coordinador:"  formControlName="des_supervisor_inv" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of supervisorInventario" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                         </div>
                                    </div>

                                    <div id="div_toma_fisica_especifica" [style.display]=" validacion == 'TFIE' ? 'block' : 'none'"> 
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select #unidad_front placeholder="Codigo depositos"  (selectionChange)="listarProductosDepItemEsp($event)"  formControlName="cod_deposito" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of codigo_deposito" [value]="item.cod_deposito">{{item.cod_deposito}}-{{item.descrip_deposito}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                        </div>
                                        
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Auditor:"  formControlName="des_auditor" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of Auditores" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Lider:"  formControlName="des_lider_inv" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of LiderInventario" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-form-field class="materialinput">
                                                <mat-select placeholder="Supervisor o Coordinador:"  formControlName="des_supervisor_inv" autocomplete="off"> 
                                                    <mat-option *ngFor="let item of supervisorInventario" [value]="item.dt_primer_nombre +' '+ item.dt_primer_apellido">{{item.dt_primer_nombre}} {{item.dt_primer_apellido}}</mat-option>  
                                                </mat-select>
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                </span>
                                            </mat-form-field>
                                         </div>

                                         <div class="col-md-12 col-sm-12 col-xs-12 row justify-content-around">
                                                <mat-label style="color: #000">Agregar Producto</mat-label>
                                                <button matTooltip="ANADIR PRODUCTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='agregarNumberCantProducto()' ><mat-icon >add</mat-icon></button>
                                         </div>
                                         
                                        <div formArrayName="productos_cant" *ngFor="let product of productos.controls; let i = index" class="col-md-12 col-sm-12 col-xs-12">
                                            <div [formGroupName]="i">
                                                <div class="from-group">
                                                <mat-form-field class="materialinput py-1 px-1">
                                                    
                                                    <mat-label>Codigo Producto</mat-label>
                                                    <input type="text"
                                                            placeholder="Elige un producto"
                                                            matInput
                                                            [id]="'cod_producto'+i"
                                                            [formControl]="cod_producto"
                                                            [matAutocomplete]="auto"
                                                            autoActiveFirstOption
                                                            (keyup)="updateForma($event, 'cod_producto'+i, 'cod_producto', i)"
                                                    >
                                                    <mat-autocomplete #auto="matAutocomplete" >
                                                    <mat-option (onSelectionChange)="updateForm($event, 'cod_producto'+i, 'cod_producto', i)" *ngFor="let option of filteredOptions | async" [value]="option.cod_producto">
                                                        {{option.cod_producto}}-{{option.des_producto}}
                                                    </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                            <button mat-button class=" btn-primary" [disabled]="forma.invalid" (click)="modalrespuesta()" > Enviar</button> 
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>
