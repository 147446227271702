<div id="modal_carrito" style="background-color:#0076C7;border-radius: 1rem; border: 2px solid;">
     <!--///////////////////////////ALERTA CODE////////////////////////////-->
     <div class="alert alertSuccess alert-success alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10000; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >assignment_turned_in</mat-icon></span><strong class="mr-1">Listo!</strong> Operacion Exitosa.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container">

        <form [formGroup]="forma" id="carrito_p1" class="row justify-content-center mt-1 mb-0" style=" height: auto; background-color: #f1ede9; border: 2px solid #0076C7;">
            <!-- <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#ca4aaf;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Cliente</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_cliente" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div> -->

                <div id="content_2" class=" mx-1 my-2 col-2  " style="border-radius: 1rem; background-color:#0076C7">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #FFF; font-size: 11px;">RIF/CI</mat-label>
                        <input style="font-size: 12px; color: #FFF;" type="text" matInput placeholder=""  class="inputP" readonly  [value]="data.proveedor.cod_proveedor" >
                        
                    </mat-form-field>
                </div>

            
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-4" style="border-radius: 1rem; border: 2px solid #0076C7; background-color:#0076C7">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #FFF; font-size: 11px;">Nombre</mat-label>
                        <input style="font-size: 12px; color: #FFF; "  maxlength="11" type="text"  id="inputCantidad" matInput class="inputC" readonly [value]="data.proveedor.des_proveedor" >
                    </mat-form-field>
                </div>


            <!-- <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#1f39ce">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Unidad de manejo</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="unidad_manejo" #unidad_manejo autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(255, 94, 0);" >report_problem</mat-icon>
                </span>
                    </mat-form-field>
                </div>
            </div> -->


                <!-- <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-2" style="border-radius: 1rem; border: 2px solid #0076C7; background-color:#0076C7">
                    <div class="container">
                        <div class="row my-1">
                            <div class="col text-light"><strong>Moneda:</strong></div>
                        </div>
                        <div class="row my-1 justify-content-center align-items-center">

                            <div class="bg-light rounded-pill d-flex justify-content-center align-items-center">
                                <strong class="mx-2" >$</strong>
                                <mat-slide-toggle class="mat-elevation-z8" (change)="switchMoneda($event)" class="col" color="primary" disabled formControlName="moneda"></mat-slide-toggle>
                                <strong class="mx-2" >Bs</strong>

                            </div>

                            
                            
                        </div>
                    </div>
                    
                </div> -->


                <div >

                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" *ngIf="!(data.ver)"  matTooltip="GENERAR PREORDEN" (click)="enviarPreCrearOrden()" style=" color:rgb(255, 255, 255); background-color: #00B0FF; border-radius: 1rem;height: 85%;">
                        <mat-icon >done</mat-icon>
                    </button>
                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" *ngIf="!data.ver && this.data.accion === 'editar'"  matTooltip="CREAR ORDEN DE COMPRA" (click)="enviarPreCrearOrden(true)" style=" color:rgb(255, 255, 255); background-color: var(--success); border-radius: 1rem;height: 85%;">
                        <mat-icon>done_all</mat-icon>
                    </button>

                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" *ngIf="data.ver" (click)="imprimirOrdenCompra()" matTooltip="IMPRIMIR" style=" color:rgb(255, 255, 255); background-color: #0076C7; border-radius: 1rem;height: 80%;">
                        <mat-icon >print</mat-icon>
                    </button>
                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" *ngIf="data.ver" (click)="verNota()" matTooltip="VER NOTA" style=" color:rgb(255, 255, 255); background-color: #0076C7; border-radius: 1rem;height: 80%;">
                        <mat-icon >attach_file</mat-icon>
                    </button>
      
      
                    <button class="btn btn-sm btn-danger mat-elevation-z8 px-3 mt-1 mr-1" style="border-radius: 1rem; height: 85%;" (click)="cerrarmodal()"matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>

                </div>


        </form>  

        <div id="carrito_p6" class="row " style=" border: 2px solid #0076C7; background-color: #f1ede9; ">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                <table #table mat-table [dataSource]="dataSource" class=" text-center table-striped  table-condensed table-hover">

                    <ng-container matColumnDef="cod_orden_req">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong> Orden de Requisión</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_orden_req}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
                    <ng-container matColumnDef="cod_cotizacion">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong> Cotización</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_cotizacion}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="cod_producto">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Código </strong></h5>
                      </th>
                     
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}<span *ngIf="element.estatus_fiscal === 'XCNT'" matTooltip="Producto Excento" class="bg-danger text-white ml-1 py-1 px-1 rounded-circle" style=" font-size:smaller; ">E</span></strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
  
                    <ng-container matColumnDef="des_producto">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Descripción</strong></h5>
                      </th>
    
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}} </strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
  
                    <ng-container matColumnDef="cant_solicitada">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Aprobado</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>
  
  
                    <ng-container matColumnDef="tipo_unidad_medida">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Unidad de Medida</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="precio">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Precio</strong></h5>
                      </th>
                     
                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> 
                        <strong>{{element.precio | puntoporcoma}}</strong> {{element.moneda}}
                      </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;">Total </td>
                    </ng-container>
                    <ng-container matColumnDef="departamento">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Departamento</strong></h5>
                      </th>
                     
                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> 
                        <strong>{{element.departamento}}</strong> <br>
                        <small>{{element.cod_centro_costo}}</small>
                    </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;">Total </td>
                    </ng-container>
                    <ng-container matColumnDef="cant_cuenta_gasto">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Presupuesto de Partida</strong></h5>
                      </th>
                     
                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> 
                        <strong>{{element.cant_cuenta_gasto | puntoporcoma}}</strong> {{element.moneda}} <br>
                        <small>{{element.cod_cuenta_gasto}}</small>
                      </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;">Total </td>
                    </ng-container>

                    <ng-container matColumnDef="precio_total">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #003f66;">
                          <h5><strong>Total + IVA</strong></h5>
                      </th>
                      
                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total_2 | puntoporcoma}}</strong> {{element.moneda}}</td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> 
                       <!--  <div>{{dolar3Final}} $</div> 
                        <div class="mt-2">{{bolivar3Final}} Bs</div>  -->
                       </td>
                    </ng-container>

                  <ng-container matColumnDef="accion">
                      <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #003f66; padding: 5px; color: #fff;">
                          <h5><strong>Seleccionar</strong></h5>
                      </th>
                      <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                          <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, $event)" *ngIf="!element.check_estatus && element.cod_cotizacion != ''" class="btn btn-outline-secondary rounded-pill" [id]="element.cod_orden_req+'-'+element.cod_producto" mat-raised-button style="height: 35px; border:2px solid;">
                              <mat-icon>add_circle</mat-icon>
                          </button>
                          <button matTooltip="BORRAR" (click)="tomar_data(false, element, $event)" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                              <mat-icon>done</mat-icon>
                          </button>

                      </td>
                      <td mat-footer-cell *matFooterCellDef style="background-color: #003f66; color: #fff;"> </td>
                  </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              </table> 

            </div>

        </div>
        <div  class="row p-1 d-flex justify-content-between align-items-center" style="background-color: white;border-radius: 0 0 1rem 1rem ;">

                        
            <div id="sub_content_1" class="col-2 " style="border-radius: 1rem 0 0 1rem ; border: 2px solid #0076C7; background-color:#0076C7">
                
                    <div class="row my-1">
                        <div class="col-12 text-light p-0">
                            <small><strong>Subtotal</strong> </small>
                        </div>
                        <!-- <div class="col-7">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> Prueba</div>
                        </div> -->
                    </div>
                    <div class="row my-1">
                        <!-- <div class="col-5 text-light d-flex justify-content-center align-items-center p-0">
                            <small><strong>Total</strong> </small>
                        </div> -->
                        <div class="col-12">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> {{ Subtotal | puntoporcoma}} {{moneda}}</div>
                        </div>
                    </div>
                
            </div>
            <!-- <div id="sub_content_1" class="col-2 " style=" border: 2px solid #0076C7; background-color:#0076C7">
                <div class="container">
                    <div class="row my-1">
                        <div class="col-3 text-light d-flex justify-content-center align-items-center p-0">
                            <small><strong>Flete</strong> </small>
                        </div>
                        <div class="col-9 ">
                            <input id="flete" (keyup)="ponerPuntosyComas($event, 'flete')" disabled class=" form-control border-0 " style="height: 20px; text-align:end" placeholder="0.00" type="text">
                        </div>
                    </div>
                    <div class="row my-1">
                        <div class="col-3 text-light d-flex justify-content-center align-items-center p-0">
                            <small><strong>ISLR</strong> </small>
                        </div>
                        <div class="col-9">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> N/A</div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div id="sub_content_1" class="col-3 " style=" border: 2px solid #0076C7; background-color:#0076C7">
                
                    <div class="row my-1">
                        <div class="col-5 text-light d-flex justify-content-center align-items-center p-0">
                            <small><strong>IVA</strong> </small>
                        </div>
                        <div class="col-7">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> {{iva | puntoporcoma}} {{moneda}}</div>
                        </div>
                    </div>
                    <div class="row my-1">
                        <div class="col-5 text-light d-flex justify-content-center align-items-center p-0">
                            <small><strong style="font-size: 9px;">Ret IVA {{reten_prov}}</strong> </small>
                        </div>
                        <div class="col-7">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> {{ret_iva | puntoporcoma}} {{moneda}}</div>
                        </div>
                    </div>
                
            </div>
            <div id="sub_content_1" class="col-2 " style=" border: 2px solid #0076C7; background-color:#0076C7">
                
                    <div class="row my-1">
                        <div class="col-5  d-flex justify-content-center align-items-center p-0">
                            <small><strong style="color: #0076C7;">IVA</strong> </small>
                        </div>
                    </div>
                    <div class="row my-1" *ngIf="this.reten_prov == '75%'">
                        <div class="col-5 text-light d-flex justify-content-center align-items-center p-0">
                            <small><strong style="font-size: 9px;">IVA pagar</strong> </small>
                        </div>
                        <div class="col-7">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> {{ret_iva_25 | puntoporcoma}} {{moneda}}</div>
                        </div>
                    </div>
                    <div class="row my-1" *ngIf="this.reten_prov != '75%'">
                        <div class="col-5  d-flex justify-content-center align-items-center p-0">
                            <small><strong style="color: #0076C7;">IVA</strong> </small>
                        </div>
                    </div>

            </div>
            <div id="sub_content_1" class="col-3 " style=" border: 2px solid #0076C7; background-color:#0076C7">
                
                    <div class="row my-1">
                        <div class="col-12 text-light p-0">
                            <small><strong>Subtotal + IVA</strong> </small>
                        </div>
                        <!-- <div class="col-7">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> Prueba</div>
                        </div> -->
                    </div>
                    <div class="row my-1">
                        <!-- <div class="col-5 text-light d-flex justify-content-center align-items-center p-0">
                            <small><strong>Total</strong> </small>
                        </div> -->
                        <div class="col-12">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> {{subtotalIva | puntoporcoma}} {{moneda}}</div>
                        </div>
                    </div>
                </div>
           
            <div id="sub_content_1" class="col-2 " style="border-radius: 0 1rem 1rem 0; border: 2px solid #0076C7; background-color:#0076C7">
                
                    <div class="row my-1">
                        <div class="col-12 text-light p-0">
                            <small><strong>Total</strong> </small>
                        </div>
                        <!-- <div class="col-7">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> Prueba</div>
                        </div> -->
                    </div>
                    <div class="row my-1">
                        <!-- <div class="col-5 text-light d-flex justify-content-center align-items-center p-0">
                            <small><strong>Total</strong> </small>
                        </div> -->
                        <div class="col-12">
                            <div class=" border-0 text-light" style="background-color:#0076C7" type="text"> {{Total | puntoporcoma}} {{moneda}}</div>
                        </div>
                    </div>
                
            </div>
            
            

        </div>
       
    </div>
</div>
