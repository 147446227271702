<!-- FORMULARIO PARA ROL CONDICIONADO -->

<form [formGroup]="forma" class="materialformtamaño" *ngIf="accion == 'actualizar'">
    <div class="row">
        <div class="col-md-12 ">
            <p>
                <strong>Actualizar relacion roles menu</strong>
            </p>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Codigo rol menu</mat-label>
                    <input class="" matInput placeholder="Codigo rol menu:" formControlName="cod_rol_menu" autocomplete="off" readonly>
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>business</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>

    </div>
    <div class="row">
        <div class="col-md-3">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Codigo rol</mat-label>
                    <input class="" matInput placeholder="Codigo rol:" formControlName="cod_rol" autocomplete="off" readonly>
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>business</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-4">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Descripcion del rol</mat-label>
                    <input class="" matInput placeholder="Descripcion del rol:" formControlName="des_rol" autocomplete="off" readonly>
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>business</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-5">
            <p>
                <mat-form-field class="materialinput">
                    <mat-select name="rol" placeholder="Rol de usuario" formControlName="cod_rol" autocomplete="off">
                        <mat-option *ngFor="let item of roles" [value]="item">({{item.cod_rol}}) {{item.des_rol}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Codigo del menu</mat-label>
                    <input class="" matInput placeholder="Codigo del menu:" formControlName="cod_menu" autocomplete="off" readonly>
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>business</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-4">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Descripcion del menu</mat-label>
                    <input class="" matInput placeholder="Descripcion del menu:" formControlName="des_menu" autocomplete="off" readonly>
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>business</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-5">
            <p>
                <mat-form-field class="materialinput">
                    <mat-select name="menu" placeholder="Menu del sistema" formControlName="cod_menu" autocomplete="off">
                        <mat-option *ngFor="let item of menu" [value]="item">({{item.cod_menu}}) {{item.des_menu}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Unidad</mat-label>
                    <input class="" matInput placeholder="Unidad:" formControlName="des_unidad" autocomplete="off" readonly>
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>business</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-select name="unidad" placeholder="Unidad" formControlName="des_unidad" autocomplete="off">
                        <mat-option *ngFor="let item of unidad" [value]="item">({{item.cod_unidad}}) {{item.des_unidad}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <p>
                <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="actualizardatos('actualizar')" mat-raised-button color="accent">Actualizar</button>
                <button *ngIf="accion == 'insertar'" class="btn-block" (click)="actualizardatos()" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
            </p>
        </div>
    </div>
</form>

<!-- FORMULARIO PARA ROL CONDICIONADO -->

<form [formGroup]="forma" class="materialformtamaño" *ngIf="accion == 'insertar'">
    <div class="row">
        <div class="col-md-12 ">
            <p>
                <strong>Asignar roles para el menu del sistema</strong>
            </p>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-select (selectionChange)="cargarmenu(user)" name="rol_usuario" #user placeholder="Rol de usuario" formControlName="cod_rol" autocomplete="off">
                        <mat-option *ngFor="let item of roles" [value]="item">({{item.cod_rol}}) {{item.des_rol}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-6" *ngIf="xmenu.length != 0">
            <p>
                <mat-form-field class="materialinput">
                    <mat-select name="menu" placeholder="Menu del sistema" formControlName="cod_menu" autocomplete="off">
                        <mat-option *ngFor="let item of xmenu" [value]="item">({{item.cod_menu}}) {{item.des_menu}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>
                <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="actualizardatos('actualizar')" mat-raised-button color="accent">Actualizar</button>
                <button *ngIf="accion == 'insertar'" class="btn-block" (click)="actualizardatos()" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
            </p>
        </div>
    </div>
</form>