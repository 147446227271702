<div class="d-flex" id="wrapper">
    <div class="bg-light border-right" id="sidebar-wrapper">
      <div class="sidebar-heading"><h2>Estadisticas</h2> </div>
      <div class="list-group list-group-flush">
        <a [routerLink]="[{ outlets: {'sidebar': ['buscarfactura'] } }]" class="list-group-item list-group-item-action bg-light">Buscar Facturas</a>
        <a href="#" class="list-group-item list-group-item-action bg-light">Generar Nueva Factura</a>
        <a href="#" class="list-group-item list-group-item-action bg-light">Modificar Facturas</a>
        <a href="#" class="list-group-item list-group-item-action bg-light">Events</a>
        <a href="#" class="list-group-item list-group-item-action bg-light">Profile</a>
        <a href="#" class="list-group-item list-group-item-action bg-light">Status</a>
      </div>
    </div>
    <div id="page-content-wrapper">
      <div class="">
        <router-outlet name="sidebar"></router-outlet>
      </div>
  </div>
  <router-outlet class=""></router-outlet>