import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { FormDailyVistaDocumentoComponent } from '../form-daily-vista-documento/form-daily-vista-documento.component';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-form-daily-vista-previa',
  templateUrl: './form-daily-vista-previa.component.html',
  styleUrls: ['./form-daily-vista-previa.component.css']
})
export class FormDailyVistaPreviaComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_cliente',{static:true}) cod_cliente:ElementRef;
  @ViewChild('des_cliente',{static:true}) des_cliente:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

value1 = '';
nombre:any;
forma:FormGroup;
stateForm: FormGroup;

obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog,
     private snackBar: MatSnackBar,
      private servicio:nkServices,
      private formb:FormBuilder,
       fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormDailyVistaPreviaComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

    }

    ngOnInit(): void {
      this.listarDailys(null);
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    }
    listarDailys(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listarDailysHttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.ELEMENT_DATA = [];
           console.log(response);
                  for (const i in response) {

                    this.ELEMENT_DATA.push({
                      cod_daily:response[i].cod_daily,
                      date:response[i].date,
                      rate: response[i].rate,
                      dts_bs_s:response[i].dts_bs_s,
                      dts_bs_usd:response[i].dts_bs_usd,
                      dts_usd:response[i].dts_usd,
                      total_dts_previo: response[i].total_dts_previo,
                      total_dts_usd:response[i].total_dts_usd,
                      mts_bs_s:response[i].mts_bs_s,
                      mts_bs_usd:response[i].mts_bs_usd,
                      mts_usd:response[i].mts_usd,
                      total_mts_usd:response[i].total_mts_usd,
                      lbe:response[i].lbe,
                      mts:response[i].mts,
                      mts_porcentaje:response[i].mts_porcentaje,
                      nc:response[i].nc,
                      mts_mas_nc:response[i].mts_mas_nc,
                      por_lograr:response[i].por_lograr,
                      facturas:response[i].facturas,
                      fci:response[i].fci,
                      accion3:''
                    });
  
                  }
                  
                this.displayedColumns = [
                  'cod_daily',
                  'total_dts_previo',
                  'total_dts_usd',
                  'total_mts_usd',
                  'lbe',
                  'mts',
                  'nc',
                  'mts_mas_nc',
                  'por_lograr',
                  'date',
                  'detalles',
                  'imprimir',
                  'descargar',
                  'enviar'
                ];
                                          
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.ELEMENT_DATA = [];
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
            this.displayedColumns = [
              'cod_daily',
              'total_dts_previo',
              'total_dts_usd',
              'total_mts_usd',
              'lbe',
              'mts',
              'nc',
              'mts_mas_nc',
              'por_lograr',
              'date',
              'detalles',
              'imprimir',
              'descargar',
              'enviar'
            ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }

  gestionDailyReport(){
    try {
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
  
        if (result != 'cerrar') {
  
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          var xvalor = {
            val_def:'F',
            tipo_operacion:'DAILYREPORT',
            cod_usuario:localStorage.getItem('cod_usuario')
          }

          this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
            async response =>{
              this.dialogSpin.close();
              this.servicio.reporteFactDailyHttp(xvalor).subscribe(
                response =>{
      
                  this.dialogSpin.close();
      
                  this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
                  
                  this.listarDailys(null);
      
                },
                error =>{
                  this.dialogSpin.close();
                  this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
      
                }
              )
            },
            error=>{
              this.dialogSpin.close();
              this.snackBar.open(error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,})
            }
          );
        }
  
      });
    } catch (error) {
      console.log(error);
      this.dialogSpin.close();
      this.listarDailys(null);
    }
  }
  

  detalles(elemet:any){

    var dialogRef;
        dialogRef = this.dialog.open(FormDailyVistaDocumentoComponent,     {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data:elemet,
        disableClose:   true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });

        dialogRef.afterClosed().subscribe(resul => {

          if(resul != 'cerrar'){

            console.log('Se cerro la wea')
            

          }

      })

  }
  async descarga_daily(xdatos:any, accion:any){
    try {
      console.log(xdatos,'xdatos');
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        var xvalor = {
          val_def:'F',
          tipo_operacion:'IMPRIMIRRD',
          cod_usuario:localStorage.getItem('cod_usuario')
        }
          this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
            async response =>{
              this.dialogSpin.close();
              ////////////////////////Si mi mts_bs en bolivares es mayor a las NC_dts en bolivares////////////////////////
    
        const fontsSize:any = 9
        const tablaEnLista:any = generarTabla(xdatos.facturas);
        const tablaEnLista2:any = generarTabla(xdatos.fci);
    
                function generarTabla(dato:any){
                  
                    let arry:any =[
                      [
                          {text: 'Factura/Nota', alignment: 'center', fontSize: fontsSize, border: [true, true, false, true], }, 
                          {text: 'Fecha facturacion', alignment: 'center', fontSize: fontsSize, border: [false, true, false, true],}, 
                          {text: 'Cliente', alignment: 'center', fontSize: fontsSize,border: [false, true, false, true],},
                          {text: 'Monto base USD', alignment: 'center', fontSize: fontsSize,border: [false, true, true, true],},
                      ],
                  ]
                  for (const i in dato) {
                      
                      let articulo:any = [
                          {text: dato[i].cod_documento, alignment: 'center', fontSize: fontsSize,border: [false, false, false, false], }, 
                          {text: dato[i].fecha_inicio, alignment: 'center', fontSize: fontsSize,border: [false, false, false, false],}, 
                          {text: dato[i].des_cliente , alignment: 'center', fontSize: fontsSize,border: [false, false, false, false],},
                          {text: dato[i].monto_base_usd, alignment: 'center', fontSize: fontsSize,border: [false, false, false, false],},
                      ]
                      arry.push(articulo)
            
                  }
                  return arry;
                }
      
                  var  PDF:any ={
                    pageSize: 'A4',
                    background: [
                      { image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/marca_3.png"), width: 811, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
                    ],
                    info:{
                      title:`Daily Report del ${xdatos.date}`,
                      author: 'FACTORY COSMETICS 3DG, C.A',
                      subject:`Daily Report del ${xdatos.date}`,
                    },
                    content :[
                        {   style: 'tableExample',
                            table:{
                                body:[
                                    [
                                        {image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/logo-factory.png"), fit:[80,80],alignment:'left',},
                                        {text:'\nFACTORY COSMETICS 3DG, C.A.\n Daily Report\n Confidential Information',alignment: 'center',margin: [0, 5, 0, 5],},
                                        {text:'    ', colSpan: 1,},
                                    ],
                                    [    
                                        {   
                                            text: 'DATE\n',
                                            fontSize: 12,
                                            alignment:'center',
                                            border: [false, false, false, true],
                                        },
                                        {   
                                            text: 'SALES\n',
                                            fontSize: 12,
                                            alignment:'center',
                                            border: [false, false, false, true],
                                        },
              
                                        {   
                                            text: 'RATE\n',
                                            fontSize: 12,
                                            alignment:'center',
                                            border: [false, false, false, true],
                                        },
                                    ],
                                    [
                                        {   
                                            text: `${xdatos.date}\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        },
                                        {   
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {   
                                            text: '$ '+xdatos.rate+'\n',
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        },                                        
                                    ],
                                    [
                                        {   
                                            text: '\nDTS Bs. S',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {   
                                            text: '\nDTS Bs/USD.',
                                            fontSize: 12,
                                            alignment:'center',
                                            
                                        },
                                    ],
                                    [
                                        {
                                            text: `${xdatos.dts_bs_s} Bs. S\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: `$ ${xdatos.dts_bs_usd}\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        },
                                    ],
                                    [
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: '\nDTS USD',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                    ],
                                    [
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: `$ ${xdatos.dts_usd}\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        },
                                    ],
                                    [    {
                                            text: '\nTOTAL DTS PREVIO',
                                            fontSize: 12,
                                            alignment:'right',
                                            colSpan:'center',
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        }, 
                                        {
                                            text: '\nTOTAL DTS USD',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                    ],
                                    [
                                        {
                                            text: `$ ${xdatos.total_dts_previo}`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: `$ ${xdatos.total_dts_usd}`,
                                            fontSize: 10,
                                            alignment:'center',
                                            color: parseFloat(xdatos.total_dts_usd.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.')) > 0 ? 'green' : 'red' ,
                                            border: [true, true, true, true],
                                        },
                                    ],
                                    [    
                                        {
                                            text: '\nMTS Bs. S',
                                            fontSize: 12,
                                            alignment:'center',
                                            border:[false, true, false, false],
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'center',
                                            colSpan:1,
                                            border:[false, true, false, false],
                                        },
                                        {
                                            text: '\nMTS Bs/USD',
                                            fontSize: 12,
                                            alignment:'center',
                                            colSpan:1,
                                            border:[false, true, false, false],
                                        },
                                    ],
                                    [    
                                        {
                                            text: `${xdatos.mts_bs_s} Bs. S\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: `$ ${xdatos.mts_bs_usd}\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        }, 
                                    ],
                                    [    
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                    
                                        {
                                            text: '\nMTS USD',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                    ],
                                    [    
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: `$ ${xdatos.mts_usd}\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        }, 
                                    ],
                                    [    
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                    
                                        {
                                            text: 'TOTAL MTS USD\n',
                                            fontSize: 12,
                                            alignment:'center',
                                            
                                        },
                                    ],
                                    [    
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                            border: [false, false, false, true],
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                            border: [false, false, false, true],
                                        },
                                        {
                                            text: `$ ${xdatos.total_mts_usd}`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                            color: '#EA2D0F',
                                        }, 
                                    ],
                                    [    
                                        {
                                            text: '\nLBE',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                    
                                        {
                                            text: '\nMTS',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                    ],
                                    [    
                                        {
                                            text: `${xdatos.lbe} USD\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            color:'blue',
                                            border: [true, true, true, true],
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: `$ ${xdatos.mts} \n(${xdatos.mts_porcentaje}%)\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            color: parseFloat(xdatos.mts.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.')) < parseFloat(xdatos.lbe.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.')) ? 'red': 'green',
                                            border: [true, true, true, true],
                                        }, 
                                    ], 
                                    [    
                                        {
                                            text: '\nNC',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                    
                                        {
                                            text: '\nMTS + NC',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                    ],
                                    [    
                                        {
                                            text: `${xdatos.nc} USD\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            border: [true, true, true, true],
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'center',
                                            colSpan:1,
                                        },
                                        {
                                            text: `$ ${xdatos.mts_mas_nc} USD\n`,
                                            fontSize: 10,
                                            alignment:'center',
                                            color: parseFloat(xdatos.mts_mas_nc.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.')) < parseFloat(xdatos.lbe.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.')) ? 'red': 'green',
                                            border: [true, true, true, true],
                                        }, 
                                    ],
                                    [    
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: '\nPOR LOGRAR',
                                            fontSize: 12,
                                            alignment:'center',
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                    ],
                                    [    
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                        {
                                            text: `${xdatos.por_lograr}\n`,
                                            fontSize: 12,
                                            alignment:'center',
                                            color: parseFloat(xdatos.mts.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.')) < parseFloat(xdatos.lbe.replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace('.', '')
                                            .replace(',', '.')) ? 'red': 'green',
                                            border:[true,true,true,true],
                                        },
                                        {
                                            text: ' ',
                                            fontSize: 8,
                                            alignment:'right',
                                            colSpan:1,
                                        },
                                    ],
                                ],  
                            },layout: {
                                defaultBorder: false,
                            },
                        },
                        { 
                            style: 'tableExample2',
                            table: {
                                headerRows: 1,
                                widths: [80, 80, '*', 80,],
                                body: tablaEnLista,
                                fontSize: 8,
                            }, 
                        },
                        { 
                            style: 'tableExample3',
                            table: {
                                headerRows: 1,
                                widths: [80, 80, '*', 80,],
                                body: tablaEnLista2,
                                fontSize: 8,
                            }, 
                        },
                    ],
                    styles: {
                        tableExample: {
                            margin: [ 90, 110, 20, 150 ]
                        },
                        tableExample3: {
                            margin: [ 0, 40, 0, 0]
                        },
                    },
                } 
  
              if(accion == 'imprimir' ){
  
                pdfMake.createPdf(PDF).open()
  
                this.dialogSpin.close()
  
              }else{
  
                pdfMake.createPdf(PDF).download('Daily Report')
  
                this.dialogSpin.close()
                
              }
     
            },
            error=>{
              this.dialogSpin.close();
              this.snackBar.open(error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,})
            }
          );
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  getBase64ImageFromURLdaily(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

  enviar(obj:any){
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {

        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });

        setTimeout(() => {
          var xvalor = {
            val_def:'F',
            tipo_operacion:'ENVIARDAILY',
            cod_usuario:localStorage.getItem('cod_usuario')
          }
            this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
              async response =>{
                this.dialogSpin.close();
                this.servicio.enviarDailyReportMasivoHttp(obj).subscribe(
                  response =>{
        
                    this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
        
                    this.listarDailys(null);
        
                  },
                  error =>{
                    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                  }
                );
       
              },
              error=>{
                this.dialogSpin.close();
                this.snackBar.open(error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,})
              }
            );
        }, 1000);
      }

    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
}
