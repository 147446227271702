import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { identity } from 'lodash';


@Component({
  selector: 'app-form-aprobar-cotizacion',
  templateUrl: './form-aprobar-cotizacion.component.html',
  styleUrls: ['./form-aprobar-cotizacion.component.css']
})
export class FormAprobarCotizacionComponent implements OnInit {

  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA1:any[]=[];
  ELEMENT_DATA2:any[]=[];
  ELEMENT_DATA3:any[]=[];
  ELEMENT_FINAL:any[]=[];
  ELEMENT_FINAL1:any[]=[];
  ELEMENT_FINAL2:any[]=[];
  ELEMENT_FINAL3:any[]=[];
  ELEMENT_RESUMEN:any[]=[];
  displayedColumns:string[];
  displayedColumnsResumen:string[];
  displayedColumnsFinal:string[];
  dolar1:any = 0
  dolar2:any = 0
  dolar3:any = 0
  bolivar1:any = 0
  bolivar2:any = 0
  bolivar3:any = 0
  dolar1Final:any = 0
  dolar2Final:any = 0
  dolar3Final:any = 0
  bolivar1Final:any = 0
  bolivar2Final:any = 0
  bolivar3Final:any = 0
  /*CONTROLADOR DEL FORMULARIO*/
    CantidadCot:any 
  /*CONTROLADOR DEL FORMULARIO*/

  /*PARA VER*/
    ver:any = false;
  /*PARA VER*/
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  dataSource1=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  dataSource3=new MatTableDataSource<any>();
  dataSourceResumen=new MatTableDataSource<any>();
  dataSourceFinal=new MatTableDataSource<any>();
  dataSourceFinal1=new MatTableDataSource<any>();
  dataSourceFinal2=new MatTableDataSource<any>();
  dataSourceFinal3=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 verticalPosition: MatSnackBarVerticalPosition = 'top';

 constructor( public dialog: MatDialog,fb:FormBuilder,
  public dialogRefp: MatDialogRef<FormAprobarCotizacionComponent>,
  ///en el injectable llega la informacion del metodo padre en data
 @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices) { 

  if(data.accion == 'ver'){
    this.ver = true
  }

  if(!this.ver){

    this.firstFormGroup = fb.group({
      rif: [data.cotizacion.cotizacion_1.rif, Validators.required],
      nombre: [data.cotizacion.cotizacion_1.empresa, Validators.required],
      direccion: [data.cotizacion.cotizacion_1.direccion, Validators.required],
      observacion: [data.cotizacion.cotizacion_1.observacion, Validators.required],
    });
    this.secondFormGroup = fb.group({
      rif: [data.cotizacion.cotizacion_2.rif, Validators.required],
      nombre: [data.cotizacion.cotizacion_2.empresa, Validators.required],
      direccion: [data.cotizacion.cotizacion_2.direccion, Validators.required],
      observacion: [data.cotizacion.cotizacion_2.observacion, Validators.required],
    });
    this.thirdFormGroup = fb.group({
      rif: [data.cotizacion.cotizacion_3.rif, Validators.required],
      nombre: [data.cotizacion.cotizacion_3.empresa, Validators.required],
      direccion: [data.cotizacion.cotizacion_3.direccion, Validators.required],
      observacion: [data.cotizacion.cotizacion_3.observacion, Validators.required],
    });

  }else{

    
    this.firstFormGroup = fb.group({
      rif: ['', Validators.required],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      observacion: ['', Validators.required],
    });
    this.secondFormGroup = fb.group({
      rif: ['', Validators.required],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      observacion: ['', Validators.required],
    });
    this.thirdFormGroup = fb.group({
      rif: ['', Validators.required],
      nombre: ['', Validators.required],
      direccion: ['', Validators.required],
      observacion: ['', Validators.required],
    });


  }

   


  console.log(data, 'la data')
  this.forma = fb.group({
    'observaciones_aprobacion':[data.observaciones_aprobacion,[Validators.required, Validators.minLength(30)]]
  });
 }

  ngOnInit(): void {

    console.log(this.firstFormGroup)

    if(this.ver){

      this.mostrarTablaVer()

    }else{
      this.mostrarTabla()
    }


  }

  mostrarTablaVer(){

    this.CantidadCot = this.data.cotizacion.cant_cot

    this.ELEMENT_FINAL1 = this.data.cotizacion.tabla_1
    this.ELEMENT_FINAL2 = this.data.cotizacion.tabla_2
    this.ELEMENT_FINAL3 = this.data.cotizacion.tabla_3


    this.displayedColumnsFinal = [
      'cod_proveedor',
      'des_proveedor',
      'cod_producto',
      'des_producto',
      'cant_producto_cot',
      'tipo_unidad_medida',
      'precio',
      'precio_total',
    ];
    
    this.dataSourceFinal1 = new MatTableDataSource<any>(this.ELEMENT_FINAL1);
    this.dataSourceFinal1.paginator = this.paginator;
    this.dataSourceFinal2 = new MatTableDataSource<any>(this.ELEMENT_FINAL2);
    this.dataSourceFinal2.paginator = this.paginator;
    this.dataSourceFinal3 = new MatTableDataSource<any>(this.ELEMENT_FINAL3);
    this.dataSourceFinal3.paginator = this.paginator;
    

  }

  mostrarTabla(){

    try {

      this.CantidadCot = this.data.cotizacion.cant_cot
  
        console.log(this.data)
  
        if(this.data){

          this.data.cotizacion.cotizacion_1.productos.forEach(element => {
            element.check_estatus = false
            
          });
          this.data.cotizacion.cotizacion_2.productos.forEach(element => {
            element.check_estatus = false
            
          });
          this.data.cotizacion.cotizacion_3.productos.forEach(element => {
            element.check_estatus = false
            
          });

          this.ELEMENT_DATA1 = this.data.cotizacion.cotizacion_1.productos
          this.ELEMENT_DATA2 = this.data.cotizacion.cotizacion_2.productos
          this.ELEMENT_DATA3 = this.data.cotizacion.cotizacion_3.productos
          this.ELEMENT_RESUMEN = this.data.cotizacion.resumen

          this.sumarPrecioTotal(this.ELEMENT_DATA1)
          this.sumarPrecioTotal(this.ELEMENT_DATA2)
          this.sumarPrecioTotal(this.ELEMENT_DATA3)

        }
      
  
        this.displayedColumns = [
          'cod_producto',
          'des_producto',
          'cant_aprobada',
          'cant_producto_cot',
          'tipo_unidad_medida',
          'precio',
          'precio_total',
          'tomar_data_ingresada', 
        ];

        if(this.CantidadCot == '3'){

          this.displayedColumnsResumen = [
            'cod_producto',
            'des_producto',
            'cant_aprobada',
            'cant_producto_cot',
            'tipo_unidad_medida',
            'precio_cot_1',
            'precio_cot_2',
            'precio_cot_3',
          ];

        }else{

          this.displayedColumnsResumen = [
            'cod_producto',
            'des_producto',
            'cant_aprobada',
            'cant_producto_cot',
            'tipo_unidad_medida',
            'precio_cot_1',
          ];

        }

      

        this.displayedColumnsFinal = [
          'cod_proveedor',
          'des_proveedor',
          'cod_producto',
          'des_producto',
          'cant_aprobada',
          'cant_producto_cot',
          'tipo_unidad_medida',
          'precio',
          'precio_total',
          'tomar_data_ingresada', 
        ];
        
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA1);
        this.dataSource1.paginator = this.paginator;
        this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
        this.dataSource2.paginator = this.paginator;
        this.dataSource3 = new MatTableDataSource<any>(this.ELEMENT_DATA3);
        this.dataSource3.paginator = this.paginator;
        this.dataSourceResumen = new MatTableDataSource<any>(this.ELEMENT_RESUMEN);
        this.dataSourceResumen.paginator = this.paginator;
        this.dataSourceFinal = new MatTableDataSource<any>(this.ELEMENT_FINAL);
        this.dataSourceFinal.paginator = this.paginator;
        this.dataSourceFinal1 = new MatTableDataSource<any>(this.ELEMENT_FINAL1);
        this.dataSourceFinal1.paginator = this.paginator;
        this.dataSourceFinal2 = new MatTableDataSource<any>(this.ELEMENT_FINAL2);
        this.dataSourceFinal2.paginator = this.paginator;
        this.dataSourceFinal3 = new MatTableDataSource<any>(this.ELEMENT_FINAL3);
        this.dataSourceFinal3.paginator = this.paginator;
        
      
    } catch (error) {

      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
        this.displayedColumns = [
          'cod_producto',
          'des_producto',
          'cant_aprobada',
          'tipo_unidad_medida',
          'precio',
          'precio_total',
          'tomar_data_ingresada', 
        ];

        this.displayedColumnsResumen = [
          'cod_producto',
          'des_producto',
          'cant_aprobada',
          'tipo_unidad_medida',
          'precio_cot_1',
          'precio_cot_2',
          'precio_cot_3',
        ];

        this.displayedColumnsFinal = [
          'cod_proveedor',
          'des_proveedor',
          'cod_producto',
          'des_producto',
          'cant_aprobada',
          'tipo_unidad_medida',
          'precio',
          'precio_total',
          'tomar_data_ingresada', 
        ];
        
      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA1);
      this.dataSource1.paginator = this.paginator;
      this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
      this.dataSource2.paginator = this.paginator;
      this.dataSource3 = new MatTableDataSource<any>(this.ELEMENT_DATA3);
      this.dataSource3.paginator = this.paginator;
      this.dataSourceResumen = new MatTableDataSource<any>(this.ELEMENT_DATA3);
      this.dataSourceResumen.paginator = this.paginator;
      this.dataSourceFinal = new MatTableDataSource<any>(this.ELEMENT_FINAL);
      this.dataSourceFinal.paginator = this.paginator;
      this.dataSourceFinal = new MatTableDataSource<any>(this.ELEMENT_FINAL);
      this.dataSourceFinal.paginator = this.paginator;
      this.dataSourceFinal1 = new MatTableDataSource<any>(this.ELEMENT_FINAL1);
      this.dataSourceFinal1.paginator = this.paginator;
      this.dataSourceFinal2 = new MatTableDataSource<any>(this.ELEMENT_FINAL2);
      this.dataSourceFinal2.paginator = this.paginator;
      this.dataSourceFinal3 = new MatTableDataSource<any>(this.ELEMENT_FINAL3);
      this.dataSourceFinal3.paginator = this.paginator;
      
    }

  }

    tomar_data(booleano:any, elemento:any, tabla:any){
      let elementData = tabla == '1' ? this.ELEMENT_DATA1 : tabla == '2' ? this.ELEMENT_DATA2 : this.ELEMENT_DATA3
      let laInfo = tabla == '1' ? this.firstFormGroup : tabla == '2' ? this.secondFormGroup : this.thirdFormGroup
      let elBoton1,elBoton2;
      let precio_total = parseFloat(elemento.precio_total.replace(',','.'))
      let moneda = elemento.moneda

      console.log(elemento);

      if(this.CantidadCot == '3'){

        if(booleano){ 

          switch (tabla) {
            case '1':
    
               elBoton1 = document.querySelector(`#${elemento.cod_producto}-2`) as HTMLButtonElement
               elBoton2 = document.querySelector(`#${elemento.cod_producto}-3`) as HTMLButtonElement
        
              elBoton1.disabled = true
              elBoton2.disabled = true
    
              if(moneda == '$'){
                let elDolar = parseFloat(this.dolar1Final) + precio_total 
                this.dolar1Final = elDolar.toFixed(2)
              }
              if(moneda == 'Bs'){
                let elBolivar = parseFloat(this.bolivar1Final) + precio_total
                this.bolivar1Final = elBolivar.toFixed(2)
              }
              
              
              break;
    
            case '2':
              elBoton1 = document.querySelector(`#${elemento.cod_producto}-1`) as HTMLButtonElement
              elBoton2 = document.querySelector(`#${elemento.cod_producto}-3`) as HTMLButtonElement
        
              elBoton1.disabled = true
              elBoton2.disabled = true
    
              if(moneda == '$'){
                let elDolar = parseFloat(this.dolar2Final) + precio_total 
                this.dolar2Final = elDolar.toFixed(2)
              }
              if(moneda == 'Bs'){
                let elBolivar = parseFloat(this.bolivar2Final) + precio_total
                this.bolivar2Final = elBolivar.toFixed(2)
              }
    
              
              break;
    
            case '3':
              elBoton1 = document.querySelector(`#${elemento.cod_producto}-1`) as HTMLButtonElement
              elBoton2 = document.querySelector(`#${elemento.cod_producto}-2`) as HTMLButtonElement
        
              elBoton1.disabled = true
              elBoton2.disabled = true
    
              if(moneda == '$'){
                let elDolar = parseFloat(this.dolar3Final) + precio_total 
                this.dolar3Final = elDolar.toFixed(2)
              }
              if(moneda == 'Bs'){
                let elBolivar = parseFloat(this.bolivar3Final) + precio_total
                this.bolivar3Final = elBolivar.toFixed(2)
              }
              
              break;
          
            default:
              break;
          }
    
          elemento.cod_proveedor = laInfo.value.rif
          elemento.des_proveedor = laInfo.value.nombre
    
          this.ELEMENT_FINAL.push(elemento)
    
              elementData.forEach(element=> {
    
                if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req){
    
                  element.check_estatus = true
    
                }
    
              })
    
          this.ordenarTabla()
    
        }else{
    
          
    
          
          switch (tabla) {
            case '1':
    
               elBoton1 = document.querySelector(`#${elemento.cod_producto}-2`) as HTMLButtonElement
               elBoton2 = document.querySelector(`#${elemento.cod_producto}-3`) as HTMLButtonElement
        
              elBoton1.disabled = false
              elBoton2.disabled = false
    
              
              break;
    
            case '2':
              elBoton1 = document.querySelector(`#${elemento.cod_producto}-1`) as HTMLButtonElement
              elBoton2 = document.querySelector(`#${elemento.cod_producto}-3`) as HTMLButtonElement
        
              elBoton1.disabled = false
              elBoton2.disabled = false
    
    
              
              break;
    
            case '3':
              elBoton1 = document.querySelector(`#${elemento.cod_producto}-1`) as HTMLButtonElement
              elBoton2 = document.querySelector(`#${elemento.cod_producto}-2`) as HTMLButtonElement
        
              elBoton1.disabled = false
              elBoton2.disabled = false
    
              
              break;
          
            default:
              break;
          }
    
          elementData.forEach(element=> {
    
            if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req){
                  
              element.check_estatus = false
    
              element.cant_aprobada = '0'
    
            }
    
          })
    
          this.deleteCarrito(elemento, tabla)
         
          
          console.log('aqui estoy')
        }
    

      }else{

        
        if(booleano){ 

          this.ELEMENT_DATA1.forEach(element=> {
    
            if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req){

              element.check_estatus = true

            }

          })


          if(moneda == '$'){
            let elDolar = parseFloat(this.dolar1Final) + precio_total 
            this.dolar1Final = elDolar.toFixed(2)
          }
          if(moneda == 'Bs'){
            let elBolivar = parseFloat(this.bolivar1Final) + precio_total
            this.bolivar1Final = elBolivar.toFixed(2)
          }

          
          elemento.cod_proveedor = laInfo.value.rif
          elemento.des_proveedor = laInfo.value.nombre
          
          
          this.ELEMENT_FINAL.push(elemento)
          this.ELEMENT_FINAL1.push(elemento)

        }else{


          this.ELEMENT_DATA1.forEach(element=> {
    
            if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req){
                  
              element.check_estatus = false

    
            }
    
          })
          this.ELEMENT_FINAL1 =  this.ELEMENT_FINAL1.filter(element => element.cod_producto != elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req)
          this.ELEMENT_FINAL =  this.ELEMENT_FINAL.filter(element => element.cod_producto != elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req)

          if(moneda == '$'){
            let elDolar = parseFloat(this.dolar1Final) - precio_total 
            this.dolar1Final = elDolar.toFixed(2)
          }
          if(moneda == 'Bs'){
            let elBolivar = parseFloat(this.bolivar1Final) - precio_total
            this.bolivar1Final = elBolivar.toFixed(2)
          }

          console.log('aqui estoy')
        }

        this.dataSourceFinal1 = new MatTableDataSource<any>(this.ELEMENT_FINAL1);


      }
    


  }

  deleteCarrito(elemento:any, tabla:any){ 

    console.log(elemento);

    let precio_total = parseFloat(elemento.precio_total.replace(',','.'))
    let moneda = elemento.moneda
    let elBoton1,elBoton2,elBoton3;

    elBoton1 = document.querySelector(`#${elemento.cod_producto}-1`) as HTMLButtonElement
    elBoton2 = document.querySelector(`#${elemento.cod_producto}-2`) as HTMLButtonElement
    elBoton3 = document.querySelector(`#${elemento.cod_producto}-3`) as HTMLButtonElement

    if(elBoton1){
      elBoton1.disabled = false
    }
    if(elBoton2){
      elBoton2.disabled = false
    }
    if(elBoton3){
      elBoton3.disabled = false
    }
    
    
    this.ELEMENT_DATA1.forEach(element => {
      if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req){
        element.check_estatus = false
      }
      
    });
    this.ELEMENT_DATA2.forEach(element => {
      if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req){
        element.check_estatus = false
      }
      
    });
    this.ELEMENT_DATA3.forEach(element => {
      if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req){
        element.check_estatus = false
      }
      
    });

    switch (tabla) {
      case '1':

        if(moneda == '$'){
          let elDolar = parseFloat(this.dolar1Final) - precio_total 
          this.dolar1Final = elDolar.toFixed(2)
        }
        if(moneda == 'Bs'){
          let elBolivar = parseFloat(this.bolivar1Final) - precio_total
          this.bolivar1Final = elBolivar.toFixed(2)
        }

        
        break;

      case '2':

        if(moneda == '$'){
          let elDolar = parseFloat(this.dolar2Final) - precio_total 
          this.dolar2Final = elDolar.toFixed(2)
        }
        if(moneda == 'Bs'){
          let elBolivar = parseFloat(this.bolivar2Final) - precio_total
          this.bolivar2Final = elBolivar.toFixed(2)
        }

        
        break;

      case '3':

        if(moneda == '$'){
          let elDolar = parseFloat(this.dolar3Final) - precio_total
          this.dolar3Final = elDolar.toFixed(2)
        }
        if(moneda == 'Bs'){
          let elBolivar = parseFloat(this.bolivar3Final) - precio_total
          this.bolivar3Final = elBolivar.toFixed(2)
        }
        
        break;
    
      default:
        break;
    }

    this.ELEMENT_FINAL1 =   this.ELEMENT_FINAL1.filter(element => element.cod_producto != elemento.cod_producto && element.cod_orden_req != elemento.cod_orden_req )
    this.ELEMENT_FINAL2 =   this.ELEMENT_FINAL2.filter(element => element.cod_producto != elemento.cod_producto && element.cod_orden_req != elemento.cod_orden_req)
    this.ELEMENT_FINAL3 =   this.ELEMENT_FINAL3.filter(element => element.cod_producto != elemento.cod_producto && element.cod_orden_req != elemento.cod_orden_req)



    this.ELEMENT_FINAL = this.ELEMENT_FINAL.filter(element => element.cod_producto != elemento.cod_producto && element.cod_orden_req != elemento.cod_orden_req)

    console.log(this.ELEMENT_FINAL)

      this.dataSourceFinal1 = new MatTableDataSource<any>(this.ELEMENT_FINAL1);
      this.dataSourceFinal2 = new MatTableDataSource<any>(this.ELEMENT_FINAL2);
      this.dataSourceFinal3 = new MatTableDataSource<any>(this.ELEMENT_FINAL3);
  }

  ordenarTabla(){

    let Tabla1 = this.ELEMENT_FINAL.filter(element => element.cod_proveedor == this.firstFormGroup.value.rif )
    let Tabla2 = this.ELEMENT_FINAL.filter(element => element.cod_proveedor == this.secondFormGroup.value.rif )
    let Tabla3 = this.ELEMENT_FINAL.filter(element => element.cod_proveedor == this.thirdFormGroup.value.rif )

    this.ELEMENT_FINAL1 = Tabla1 
    this.ELEMENT_FINAL2 = Tabla2
    this.ELEMENT_FINAL3 = Tabla3

    this.dataSourceFinal1 = new MatTableDataSource<any>(this.ELEMENT_FINAL1);
    this.dataSourceFinal2 = new MatTableDataSource<any>(this.ELEMENT_FINAL2);
    this.dataSourceFinal3 = new MatTableDataSource<any>(this.ELEMENT_FINAL3);

  }

  sumarPrecioTotal(tabla) {


    tabla.forEach(element => {

        let moneda = element.moneda
        let nTabla = element.n_cot

        console.log(element.precio.replace(',','.'))
        let precio_total = parseFloat(element.precio_total.replace(',','.'))

      if(moneda == '$'){

        if(nTabla == '1'){

          this.dolar1 += precio_total
          
        }
        if(nTabla == '2'){

          this.dolar2 += precio_total
          

        }
        if(nTabla == '3'){

          this.dolar3 += precio_total
          

        }
        
      }

      if(moneda == 'Bs'){
    
        if(nTabla == '1'){

          this.bolivar1 += precio_total
          
        }
        if(nTabla == '2'){

          this.bolivar2 += precio_total
          

        }
        if(nTabla == '3'){

          this.bolivar3 += precio_total
          
          
        }
        
      }
      


    });

  }

  enviarCotizacion(){

    let ceros = 0;

    if(this.CantidadCot == '3'){

      ceros = (this.ELEMENT_DATA1.length - this.ELEMENT_FINAL.length)

    }else{

      ceros = (this.ELEMENT_DATA1.length - this.ELEMENT_FINAL1.length)

    }

    let obj = {
              info:this.data,
              prod_total:this.ELEMENT_DATA1,
              prod_aprobado:this.ELEMENT_FINAL,
              usuario: {
                          cod_usuario: localStorage.getItem('cod_usuario'),
                          des_usuario: localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido')
                        }
              }



    if(ceros){
      this.snackBar.open('Debes eligir un producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
      return
    
    }else{
      this.accion= null
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });

    dialogRef.afterClosed().subscribe(
      result => {
      if (result != 'cerrar') {

        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });


        this.servicio.aprobarCotizacionhttp(obj).subscribe(result => {
          this.dialogSpin.close();
          this.dialogRefp.close();
        })
      }
    },
      error => {
        this.dialogSpin.close();
        this.snackBar.open(JSON.stringify(error), 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );


    }



  }
  desaprobarTodaLaCotizacion(){

    let ceros = 0;

    if(this.CantidadCot == '3'){

      ceros = (this.ELEMENT_DATA1.length - this.ELEMENT_FINAL.length)

    }else{

      ceros = (this.ELEMENT_DATA1.length - this.ELEMENT_FINAL1.length)

    }

    let obj = {
              info:this.data,
              prod_total:this.ELEMENT_DATA1,
              prod_aprobado:[],
              usuario: {
                          cod_usuario: localStorage.getItem('cod_usuario'),
                          des_usuario: localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido')
                        }
              }




      this.accion= 'custom'
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
                accion:this.accion,
                mensaje:'¿Esta seguro de que desea DESAPROBAR toda la cotización?'},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });

    dialogRef.afterClosed().subscribe(
      result => {
      if (result != 'cerrar') {

        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });


        this.servicio.aprobarCotizacionhttp(obj).subscribe(result => {
          this.dialogSpin.close();
          this.dialogRefp.close();
        })
      }
    },
      error => {
        this.dialogSpin.close();
        this.snackBar.open(JSON.stringify(error), 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );

  }



  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    this.dataSource2.filter = filterValue.trim().toLowerCase();
    this.dataSource3.filter = filterValue.trim().toLowerCase();
  }

}
