import { Component, OnInit, Inject,AfterViewInit, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ModificarregistrosComponent} from '../modificarregistros/modificarregistros.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'; 
import { PeriodicElement } from '../../../../shared/models/interface';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';



@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-listarregistros',
  templateUrl: './listarregistros.component.html',
  styleUrls: ['./listarregistros.component.css']
})
export class ListarregistrosComponent implements OnInit, AfterViewInit {
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  accion:any;
  ELEMENT_DATA:PeriodicElement[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<PeriodicElement>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {}

  ngOnInit(): void {
    this.listardatos(null);  
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }

  listardatos(msj:any){
    let msg = msj;
    this.servicio.listarhttp().subscribe(
      response =>{
        if(response){
          console.log(response,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response) {
                  
                this.ELEMENT_DATA.push({id:response[i].id, Nombre: response[i].nombre_usu, Apellido:  response[i].apellido_usu , Direccion:  response[i].direccion_usu,  Correo: response[i].correo_usu, Accion: '', Accion2: ''});
              }
             this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log('una respuesta');
        console.log(<any>error.error[0].message);
        this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];
        this.dataSource = new MatTableDataSource<PeriodicElement>();
              this.dataSource.paginator = this.paginator;
      }
    );
    console.log(msg);
    if(msg == 'actualizar'){
      this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    return this.xobj2;
  }
  eliminardatos(id:any){
    
    this.servicio.eliminarhttp(id).subscribe(
      response =>{
        this.listardatos(null);
              this.snackBar.open(response[0].message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.listardatos(null);
      }
    );
    
    return this.obj;
  }

  editardatos(obj:any, text:any){
    console.log(obj, 'segundo');
    if (text == 'actualizar') {
      this.accion = text;
      var dialogRef = this.dialog.open(ModificarregistrosComponent, {
        width: '30%',
        height:'70%',
        data: {id:obj.id, nombre: obj.Nombre, apellido: obj.Apellido, direccion:obj.Direccion, correo:obj.Correo, accion:this.accion}
      });
    }else{
      this.accion = text;
       dialogRef = this.dialog.open(ModificarregistrosComponent, {
        width: '30%',
        height:'70%',
        data: {id:'', nombre: '', apellido: '', direccion:'', correo:'', accion:this.accion}
      });
    }
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      let resultado = this.actualizardatos(result);
      console.log(resultado);
      
    });

  }
  actualizardatos(xvalor:any){
   
    console.log(xvalor);
   var resultado = this.servicio.gestionarregistroshttp(xvalor).subscribe(
      response =>{
        if(response){
             console.log(response,'una respuesta');
            let xobj2 = response.resul;
            this.ELEMENT_DATA = [];
                for (const i in xobj2) {
                    
                  this.ELEMENT_DATA.push({id:xobj2[i].id, Nombre: xobj2[i].nombre_usu, Apellido:  xobj2[i].apellido_usu , Direccion:  xobj2[i].direccion_usu,  Correo: xobj2[i].correo_usu, Accion: '', Accion2: ''});
                }
               this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];
               
                this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
             var bool = true; 
             console.log(response.resul);
            this.snackBar.open(response.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
             return bool;
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
    
    var consulta = this.listardatos(null);
    console.log(consulta,'final del metodo');
    return resultado;
  }
  public buscarH(xvalor:string){
      
      var valor:any = {valor:xvalor}
      console.log(valor);
    this.servicio.buscardatoshttp(valor).subscribe(
      response =>{
        if(response){
          console.log(response, 'busqueda' );
          this.ELEMENT_DATA = [];
              for (const i in response) {
                console.log(response[i]);
                  
                this.ELEMENT_DATA.push({id:response[i].id,Nombre: response[i].nombre_usu, Apellido:  response[i].apellido_usu , Direccion:  response[i].direccion_usu,  Correo: response[i].correo_usu, Accion: '', Accion2: ''});
              }
              console.log(this.ELEMENT_DATA);
             this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];
             this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log(<any>error.error[0].message);
        this.snackBar.open(<any>error.error[0].message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }
  handleFile(event) {
    let file = event.target.files[0];
    
    var id = document.getElementById('file');
    console.log(file);
    var nombre = 'Codigo de Unidad';
    this.servicio.processFileToJson({}, file).subscribe(data => {
      let arry = data.sheets.Hoja1;
      console.log(arry);
      for (const i in arry) {
          console.log(arry[i]);
          this.result.push(arry[i]);
      }
      if (this.result.length != 0) {
        console.log('entro en el if');
          this.servicio.insertarmasivohttp(this.result).subscribe(
            response =>{
              if(response){
                console.log(response,'una respuesta');
                let xobj2 = response.resul;
                this.ELEMENT_DATA = [];
                    for (const i in xobj2) {
                        
                      this.ELEMENT_DATA.push({id:xobj2[i].id, Nombre: xobj2[i].nombre_usu, Apellido:  xobj2[i].apellido_usu , Direccion:  xobj2[i].direccion_usu,  Correo: xobj2[i].correo_usu, Accion: '', Accion2: ''});
                    }
                   this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];
                   
                    this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
                    this.dataSource.paginator = this.paginator;
                 var bool = true; 
                 console.log(response.resul);
                this.snackBar.open(response.message,'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                 return bool;
              }
            },
            error =>{
              console.log(<any>error);
            }
          );
      }
      /* this.result = JSON.stringify(data['sheets'].Sheet1); */
      /* this.snackBar.open('El archivo se cargo de forma correcta en la base de datos', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,}); */
    })
    event.srcElement.value = null;
  }
  /////DATATABLE
  /* applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
  } */
}
