
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>GESTIONAR CONSIGNACIONES</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarConsignaciones("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                    <li *ngIf="create" class="nav-item"><button matTooltip="CREAR CONSIGNACIÓN" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="border: outset 3px rgb(233, 179, 78); height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","insertar")'><mat-icon >add</mat-icon></button></li>
                </ul>
                <div class="">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.7%; ">
        <div class="col-md-12 px-0  border h-100" style="background-color: steelblue; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped  table-condensed table-hover">

                <ng-container matColumnDef="cod_consignacion">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod consignación</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_consignacion}} </td>
                 
                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod usuario</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_usuario}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_usuario}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cod cliente</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_cliente}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="tipo_um">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>UM</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.tipo_um}} </td>
                  
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.estatus}} </td>
                  
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha ini</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.fecha_inicio}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora ini</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.hora_inicio}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="Confirmar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Confirmar</strong></h5>
                    </th>
                    <td [ngClass]="{'cns_c':  element.estatus === 'C','cns_p':  element.estatus === 'P'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button class="rounded-pill" *ngIf="special" matTooltip="CONFIRMAR CONSIGNACIÓN" mat-raised-button (click)='confirmarConsignacion(element)' style="border: outset 3px rgb(36, 68, 128); height: 35px; color:rgb(255, 255, 255); background-color: rgb(5, 47, 126);">
                            <mat-icon >done_all</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!special" matTooltip="SIN PERMISOS" (click)='sin_permiso()'mat-raised-button style="border: outset 3px rgb(60, 58, 58); height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >done_all</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    </div>