import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormCrearTareasComponent } from 'src/app/componentes/shared/modal/form-crear-tareas/form-crear-tareas.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';
interface interfacefactura{
  cod_lote: string;
  cant_producto:string;
  cod_producto:string;
  des_producto:string;
  cant_req:string;
  restante:string;
}
interface interdatosfacefactura{
cod_guia_despacho:string;
cod_factura:string;
cod_pedido:string;
fecha_inicio:string;
}
type TableRow = [string, string, string, string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-soporte-config',
  templateUrl: './soporte-config.component.html',
  styleUrls: ['./soporte-config.component.css']
})
export class SoporteConfigComponent implements OnInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  auth_menu:any = [];


  cod_orden_producto:any;
  cantidad_prod:any;
  cod_prod:any;
  des_prod:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    public dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    ) {}

  ngOnInit(): void {
    this.listarTareasSoporte(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

  /////////////////////////////////////////////////////////////////////

  async listarTareasSoporte(msj:any){
    try {
        let msg = msj;
        const response = await this.servicio.listarTareasSoportehttp().toPromise();
            this.xobj4 = [];
            for (const i in response) {
              this.xobj4.push(response[i]);
            }
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                      cod_solicitud:response[i].cod_solicitud,
                      des_solicitud:response[i].des_solicitud,
                      cod_solicitud_padre:response[i].cod_solicitud_padre,
                      des_solicitud_padre: response[i].des_solicitud_padre,
                      nivel_solicitud_padre: response[i].nivel_solicitud_padre,
                      estatus:response[i].estatus,
                      ord_solicitud: response[i].ord_solicitud,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [ 
                                            'cod_solicitud',
                                            'des_solicitud',
                                            'cod_solicitud_padre',
                                            'des_solicitud_padre',
                                            'nivel_solicitud_padre',
                                            'estatus',
                                            'ord_solicitud',
                                            'procesos'
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
        if(msg == await this.atrb.ACCION_ACTUALIZAR){
            await this.util.cerrarSpinner();
            await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
        }else{
          await this.util.cerrarSpinner();
        }
    } catch (error) {
      this.displayedColumns = [
                                           'cod_solicitud',
                                            'des_solicitud',
                                            'cod_solicitud_padre',
                                            'des_solicitud_padre',
                                            'nivel_solicitud_padre',
                                            'estatus',
                                            'ord_solicitud',
                                            'procesos'
                                          ];
        this.dataSource = new MatTableDataSource<any>();
        this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }

  modalrespuesta(obj:any, text:any){
    this.accion = text;
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '24%',
        height:'35%',
        data: {id:obj.cod_cliente,
              accion:this.accion},
        disableClose: true,
        panelClass: 'elem_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log('The dialog was closed');
          console.log(result);
          let resultado = this.eliminarclientes(result.id);
          console.log(resultado);
        }
      });
  }

  eliminarclientes(id:any){
    console.log('eliminar');
    this.servicio.eliminarclienteshttp(id).subscribe(
      response =>{
        this.xobj3 = [];
        this.listarTareasSoporte(null);
              this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.listarTareasSoporte(null);
      }
    );

    return this.obj;
  }
//////////////////////FUNCION DE ACCION/////////////////////////
 async update_insert_data(obj:any,text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
                          var dialogRef:any;
                          this.accion = text;
                          if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                            dialogRef = this.dialog.open(FormConsultaOrdenesComponent, {
                              width: '100vw',
                              height:'100vh',
                              data: {
                                      accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal',
                              backdropClass: "bdrop"
                            });
                          }else{
                            dialogRef = this.dialog.open(FormCrearTareasComponent, {
                              width: '100vw',
                              height:'100vh',
                              data: {
                                      tareas:this.xobj4,
                                      accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal_rev',
                              backdropClass: "bdrop"
                            });
                          }
                      
                            dialogRef.afterClosed().subscribe(result => {
                              if(result != 'cerrar'){
                                console.log(result);
                      
                           this.gestionTareasSoporte(result);
                              }
                            });
    } catch (error) {
      await this.util.cerrarSpinner();
          console.log(error);
          await this.notifi.customToastNotification(error.error.message);
    }
  }

  public  gestionTareasSoporte(xvalor:any){
    var resultado
    console.log(xvalor);
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
       resultado = this.servicio.gestionTareasSoportehttp(xvalor).subscribe(
        response =>{
          console.log(response,'una respuesta');
          this.dialogSpin.close();
          if(response){
            this.xobj2 = response;
            this.ELEMENT_DATA = [];
            this.listarTareasSoporte(null);
                for (const i in response) {
                    console.log(response[i]);
  
                    this.ELEMENT_DATA.push({
                      cod_solicitud:response[i].cod_solicitud,
                      des_solicitud:response[i].des_solicitud,
                      cod_solicitud_padre:response[i].cod_solicitud_padre,
                      des_solicitud_padre: response[i].des_solicitud_padre,
                      nivel_solicitud_padre: response[i].nivel_solicitud_padre,
                      estatus:response[i].estatus,
                      ord_solicitud: response[i].ord_solicitud,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                }
                this.displayedColumns = [ 'cod_solicitud',
                                              'des_solicitud',
                                              'cod_solicitud_padre',
                                              'des_solicitud_padre',
                                              'nivel_solicitud_padre',
                                              'estatus',
                                              'ord_solicitud',
                                              'Actualizar'
                                            ];
                 /* this.dataSource = this.ELEMENT_DATA; */
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
                this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
         this.dialogSpin.close();
          console.log(error);
          this.displayedColumns = [ 'cod_solicitud',
                                              'des_solicitud',
                                              'cod_solicitud_padre',
                                              'des_solicitud_padre',
                                              'nivel_solicitud_padre',
                                              'estatus',
                                              'ord_solicitud',
                                              'Actualizar'
                                            ];
          this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    }, 1000);
  
     return resultado;
   }
//////////////////////FUNCION DE ACCION/////////////////////////


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

}
