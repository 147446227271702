import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment';
import { ConnectionServices } from '../../../../componentes/shared/servicios/app.connection.services';

@Component({
  selector: 'app-form-movimientos-partidas',
  templateUrl: './form-movimientos-partidas.component.html',
  styleUrls: ['./form-movimientos-partidas.component.css']
})
export class FormMovimientosPartidasComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
   @ViewChild('cod_cent',{static:true}) cod_cent:ElementRef;
/*    @ViewChild('des_cent',{static:true}) des_cent:ElementRef; */
 /* @ViewChild('des_cuenta',{static:true}) des_cuenta:ElementRef;
  @ViewChild('cod_cuenta',{static:true}) cod_cuenta:ElementRe */;

  title = 'InternetTest';
  status = 'ONLINE';
  isConnected = true;

  value1 = '';
  nombre:any;
  forma:FormGroup;
  stateForm: FormGroup;
  accion:any;
  dialogSpin:any;
  unidad:any;
  unidadp:any = [];
  menu:any;
  producto:any = [];
  codigo:any = [];
  lotepro:any = [];
  cant:any = false;
  lote:any = true;
  des:any;
  guard:any = false;
  email = new FormControl('', [Validators.required, Validators.email]);
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  dataSource=new MatTableDataSource<any>();
  serializedDate = new FormControl((new Date()).toISOString());
  ELEMENT_DATA:any[]=[];
    displayedColumns:string[];
     dataSource1=new MatTableDataSource<any>();
     horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
      public dialogRefp: MatDialogRef<FormMovimientosPartidasComponent>,private conexion: ConnectionServices,

      @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data, 'segundo');
      }

      ngOnInit(): void {

        this.cod_cent.nativeElement.value = this.data.cod_centro+' - '+this.data.des_unidad;
        this.listarSegPartidas(null);
      }
      @ViewChild(MatPaginator) paginator: MatPaginator;

      ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
      }

      listarSegPartidas(xvalor){
      try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = xvalor;
        var valor = {cod_centro: this.data.cod_centro,
                     cod_presupuesto: this.data.cod_presupuesto,
                     cod_cuenta_gasto: this.data.cod_cuenta_gasto}
         this.servicio.listarSegPartidashttp(valor).subscribe(
           response =>{
            this.dialogSpin.close();
             console.log(response,'detallesS');
               this.ELEMENT_DATA = [];
                   for (const i in response) {

                     this.ELEMENT_DATA.push({
                       cod_centro:response[i].cod_centro,
                       cod_presupuesto: response[i].cod_presupuesto,
                       operacion: response[i].operacion,
                       cant_presupuesto_desc:response[i].cant_presupuesto_desc,
                       cant_presupuesto_actual:response[i].cant_presupuesto_actual,
                       cant_presupuesto_cambio:response[i].cant_presupuesto_cambio,
                       comentario:response[i].comentario,
                       cod_usuario:response[i].cod_usuario,
                       des_usuario:response[i].des_usuario,
                       fecha_cambio:response[i].fecha_cambio,
                       hora_cambio:response[i].hora_cambio,
                     });
                   }
                   console.log(this.ELEMENT_DATA,'ELEMENT_DATA');
                    this.displayedColumns = [
                      'cod_presupuesto',
                      'operacion',
                      'cant_presupuesto_desc',
                      'cant_presupuesto_actual',
                      'cant_presupuesto_cambio',
                      'comentario',
                      'cod_usuario',
                      'des_usuario',
                      'fecha_cambio',
                      'hora_cambio',
                    ];
                   this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
                   this.dataSource1.paginator = this.paginator;
           },
           error =>{
            this.dialogSpin.close();
             console.log(error);
             console.log(<any>error.error.message);
             this.displayedColumns = [
              'cod_presupuesto',
              'cant_presupuesto_desc',
              'cant_presupuesto_actual',
              'cant_presupuesto_cambio',
              'cod_usuario',
              'des_usuario',
              'fecha_cambio',
              'hora_cambio',
            ];

             this.dataSource1 = new MatTableDataSource<any>();
             this.dataSource1.paginator = this.paginator;
             this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition});
           }
         );

         if(msg == 'actualizar'){
           this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition});
         }
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
