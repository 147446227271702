import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-devolver-producto-completo',
  templateUrl: './form-devolver-producto-completo.component.html',
  styleUrls: ['./form-devolver-producto-completo.component.css']
})
export class FormDevolverProductoCompletoComponent implements OnInit {

  @ViewChild('cant_dev',{static:true}) cant_dev:ElementRef;
  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  forma:FormGroup;
  producto:any=[];
  codigo:any=[];
  dialogSpin:any;
  accion:any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormDevolverProductoCompletoComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
      this.producto = data.des_producto;
      this.codigo = data.cod_producto;
      this.forma = fb.group({
        'cant_producto':data.cant_producto,
        'cant_dev':['',[Validators.required]]
      });
    }

  ngOnInit(): void {

  }

  devolucion_prod(xvalor:any){
    try {
        var xobj
        console.log(this.cant_dev.nativeElement.value, 'estamos aqui, aqui nos quedamos');
        if (!isNaN(this.cant_dev.nativeElement.value)) {
          if (parseFloat(this.cant_dev.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) === parseFloat(this.cant_producto.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) && parseFloat(this.cant_dev.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) >= 0  ) {
            xobj =  {cant_producto:this.data.cant_producto, des_producto:this.data.des_producto,  
                     cod_prod_mp:this.data.cod_prod_mp, tipo_producto_mp:this.data.cod_prod_mp,
                     cantidad_producto:this.data.cantidad_producto,
                     cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
                     cod_producto:this.data.cod_producto, des_prod_mp:this.data.des_prod_mp,
                     unidad_prod_mp:this.data.unidad_prod_mp, dev_producto:xvalor,
                     cod_documento:this.data.cod_documento
                    }
            this.dialogRefp.close(xobj);
          }else{
            this.snackBar.open('Debe enviar la cantidad total del producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }else{
          this.snackBar.open('Use solo numeros por favor.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
