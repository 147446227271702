import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource} from '@angular/material/table';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import { FormCrearOrNotasComponent } from '../form-crear-or-notas/form-crear-or-notas.component';
import { HelpersService } from '../../servicios/helpers.service';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-form-crear-oc-productosxproveedor',
  templateUrl: './form-crear-oc-productosxproveedor.component.html',
  styleUrls: ['./form-crear-oc-productosxproveedor.component.css']
})
export class FormCrearOcProductosxproveedorComponent implements OnInit {
  forma:FormGroup;
  accion:any;
  productos:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  ELEMENT_DATA3:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`
  ficha_proveedor: any;
  iva_proveedor: any;
  Subtotal:any = 0;
  SubtotalShow:any = 0;
  iva:any = 0 ;
  ret_iva:any = 0;
  ret_iva_25:any = 0;
  ret_ivaShow:any = 0;
  Total:any = 0;
  TotalShow:any = 0;
  flete:any = 0;
  moneda:any;
  subtotalIva: any = 0;
  subtotalIvaShow: any = 0;
  DATA:any;
  reten_prov:any = '0';
  nota = ''

  dataSource=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCrearOcProductosxproveedorComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any,private Helpers:HelpersService) { 

      this.forma = fb.group({
        moneda: false
      })

    }

  ngOnInit(): void {

    if(this.data.ver){

      this.mostrarTablaVer()

      this.moneda = '$'


    }else{

      this.moneda = '$'

      console.log(this.data)
      
      this.servicio.comprobarProveedorhttp(this.data.proveedor.cod_proveedor).subscribe(response =>{
  
        console.log(response,'response')
  
        response.data = this.data
  
  
          if(!response.existe){
  

            this.snackBar.open('Proveedor no creado, por favo comuniquese con Tecnologia','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});

          }else{
  
  
            this.ficha_proveedor = response.proveedor
  
  
            this.reten_prov = response.proveedor.reten_prov
  
            console.log(this.ficha_proveedor);
            
  
          }
  
      })
  
      this.mostrarTabla()

    }
    console.log(this.data.accion);

  }
  ponerPuntosyComas(event:any, id:any){

    let validacion = '1234567890'

    let inputCantidad = document.querySelector(`#${id}`) as HTMLInputElement

    if(inputCantidad.value.length == 1){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
      
    }else if(inputCantidad.value.length == 2){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length == 3){
      let noa = inputCantidad.value.split('')
      noa.unshift('0')
      inputCantidad.value = noa.join('')
    }else if(inputCantidad.value.length >= 5 ){
      let noa:any = inputCantidad.value.split('')
      for(let i = 0 ; i < 2; i++){
        if(noa[0] == 0){
          noa.shift()
        }else{
          break;
        }
      }
      inputCantidad.value = noa.join('')
    }

    var num = inputCantidad.value.replace(/\D/g,'');

    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    num = num.split('').reverse().join('').replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1.$2')
    inputCantidad.value = num;

    if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){

      return this.snackBar.open(  'Solo numeros',
                                  'Cerrar',
                                  { 
                                    duration:1500,
                                    horizontalPosition: this.horizontalPosition,
                                    verticalPosition: this.verticalPosition
                                  }
                                );
   
   }

    this.flete = parseFloat(num)
    this.Total = (this.flete + parseFloat(this.subtotalIva)).toFixed(2)
  }
  async mostrarTabla(){
    try {
      const data = await this.servicio.listarPorductosXProveedorOrdenesDCompra(this.data.proveedor.cod_proveedor).toPromise()

      for (const element of data.productos) {
        element.cant_cuenta_gasto_2 = element.cant_cuenta_gasto.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
        element.cant_cuenta_gasto = element.cant_cuenta_gasto.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
        element.precio_total_2 = element.precio_total.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
        element.departamento.toUpperCase()

        if(element.estatus_fiscal!== 'XCNT' && this.reten_prov == '75%'){
          let iva = Number(element.precio_total_2.replaceAll('.','').replace(',','.')) * 0.16
          let ivaRet = iva * 0.75
          element.precio_total_2 =( Number(element.precio_total_2.replaceAll('.','').replace(',','.')) + (iva - ivaRet)).toFixed(2).replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
        }
        
      }

      this.DATA = data.productos;
      console.log('aqui');
      this.DATA.length && (this.ELEMENT_DATA = this.DATA.filter(element => element.moneda == '$')) 

      console.log(this.ELEMENT_DATA);

      this.displayedColumns = [
          "departamento",
          "cant_cuenta_gasto",
          "cod_orden_req",
          "cod_cotizacion",
          "cod_producto",
          "des_producto",
          "cant_solicitada",
          "tipo_unidad_medida",
          "precio",
          "precio_total",
          'accion'
      ];

      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);

      if(this.data.accion === 'editar'){
        this.clickProductosPreOC()
      }
    } catch (error) {

      if(this.data.accion === 'editar'){

        try {
          const dialogMessage = this.dialog.open(FormmMsgComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'100%',
            data: {
                   id:'',
                   mensaje:'Los productos de esta Pre Orden han sido devueltos a cotización, Esta Preorden procedera a ser Anulada',
                   accion:'custom',
                  },
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
          });
  
          await dialogMessage.afterClosed().toPromise()
  
          await this.servicio.anularPreOC(this.data.cod_pre_orden_compra).toPromise()
  
          this.dialogRefp.close();
  
          return
          
        } catch (error) {
          this.dialogRefp.close();
          console.error(error);
          
        }
       
      }
        this.displayedColumns = [
          "departamento",
          "cant_cuenta_gasto",
          "cod_orden_req",
          "cod_cotizacion",
          "cod_producto",
          "des_producto",
          "cant_solicitada",
          "tipo_unidad_medida",
          "precio",
          "precio_total",
          'accion'
        ];
        console.error(error);
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
    }
  }
  async clickProductosPreOC(){
    try {
      console.log('aqui');

     const prodsPreOrde = await this.servicio.listarProductosPreOC(this.data.cod_pre_orden_compra).toPromise()
     const preOrden = await this.servicio.listarPreOCXCodigo(this.data.cod_pre_orden_compra).toPromise()
     console.log(preOrden.result[0].nota);
     console.log(preOrden.result[0]);
      this.nota = preOrden.result[0].nota
      for (const prods of prodsPreOrde.result) {

        const elBotton = document.getElementById(`${prods.cod_orden_req}-${prods.cod_producto}`)

        elBotton.click()

      }

    } catch (error) {
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  mostrarTablaVer(){

    console.log(this.data)

      this.DATA = this.data.productos;
      this.reten_prov = this.data.proveedor2.reten_prov;

      this.Subtotal = this.data.proveedor.subtotal;
      this.iva = this.data.proveedor.iva ;
      this.ret_iva = this.reten_prov == '75%'? (Number(this.data.proveedor.iva) * 0.75).toFixed(2)  :this.data.proveedor.reten_iva;
      this.ret_iva_25 = (Number(this.data.proveedor.iva) * 0.25).toFixed(2);
      this.Total = this.data.proveedor.total;
      this.subtotalIva = this.data.proveedor.subtotal_iva;
      this.flete = this.data.proveedor.flete;

      this.ELEMENT_DATA = this.DATA
                          .filter(element => element.moneda == '$')
                          
      this.ELEMENT_DATA.forEach(element => {
        if(element.tipo_unidad_medida.toLowerCase() == 'grs'){
          element.tipo_unidad_medida = 'KG'
          element.cant_producto_cot = (Number(element.cant_producto_cot.replace(',','.') )/ 1000).toFixed(2)
          element.precio = (Number(element.precio.replace(',','.') ) * 1000).toFixed(2)
        }
        element.precio_total_2 = element.precio_total
      })              

      console.log(this.ELEMENT_DATA);

      this.displayedColumns = [
            "cod_orden_req",
            "cod_cotizacion",
            "cod_producto",
            "des_producto",
            "cant_solicitada",
            "tipo_unidad_medida",
            "precio",
            "precio_total",
      ];

      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);


  }
  tomar_data(booleano:any, elemento:any, tabla:any){

    let precio_total = Number(elemento.precio_total.replaceAll('.','').replace(',','.'))


      if(booleano){ 

        this.ELEMENT_DATA.forEach(element=> {

          if(element.id_relacion_producto_orden_compras == elemento.id_relacion_producto_orden_compras){

            if(Number(element.cant_cuenta_gasto.replaceAll('.','').replace(',','.')) >= Number(elemento.precio_total_2.replaceAll('.','').replace(',','.'))){
    
              
    
                element.check_estatus = true
      
                this.ELEMENT_DATA2.push(elemento)

                console.log(elemento);
      
                
                this.Subtotal = (Number(this.Subtotal) + precio_total).toFixed(2)

                if(element.estatus_fiscal!== 'XCNT' && this.reten_prov != 'XCNT'){

                  this.iva = (Number(this.iva) + (precio_total * 0.16)).toFixed(2) 

                }
      
                
            
              
            }else{
    
              this.snackBar.open('Este producto no tiene presupuesto suficiente', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
    
            }
            

          }
          if(Number(element.cant_cuenta_gasto.replaceAll('.','').replace(',','.')) >= Number(elemento.precio_total_2.replaceAll('.','').replace(',','.'))){

            if(element.cod_presupuesto == elemento.cod_presupuesto && element.cod_cuenta_gasto == elemento.cod_cuenta_gasto){
              element.cant_cuenta_gasto = (Number(element.cant_cuenta_gasto.replaceAll('.','').replace(',','.')) - Number(elemento.precio_total_2.replaceAll('.','').replace(',','.'))).toFixed(2).replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
            }
          }
  
          
  
  
        })
  
  
      }else{
  
  
        this.ELEMENT_DATA.forEach(element=> {

  
          if(element.id_relacion_producto_orden_compras == elemento.id_relacion_producto_orden_compras ){
                
            element.check_estatus = false
  
            this.ELEMENT_DATA2 = this.ELEMENT_DATA2.filter(element =>  element.id_relacion_producto_orden_compras != elemento.id_relacion_producto_orden_compras)
  
            this.Subtotal = (Number(this.Subtotal) - precio_total).toFixed(2)

            if(element.estatus_fiscal!== 'XCNT' && this.reten_prov != 'XCNT'){

              this.iva = (Number(this.iva) - (precio_total * 0.16)).toFixed(2) 

            }
  
            
            
            
          }
  
          if(element.cod_presupuesto == elemento.cod_presupuesto && element.cod_cuenta_gasto == elemento.cod_cuenta_gasto){
            
            element.cant_cuenta_gasto = Number(Number(element.cant_cuenta_gasto.replaceAll('.','').replace(',','.')) + Number(elemento.precio_total_2.replaceAll('.','').replace(',','.'))).toFixed(2).replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
          }
  
        })
  
      }


      this.ret_iva = this.reten_prov == '75%' ? ( parseFloat(this.iva) * 0.75).toFixed(2) : this.iva

      this.ret_iva_25 =  this.reten_prov == '75%' ? ( parseFloat(this.iva) * 0.25).toFixed(2) : this.iva
      
      this.subtotalIva = this.reten_prov == '75%' ? (Number(this.Subtotal) + Number(this.ret_iva_25)).toFixed(2) : Number(this.Subtotal)
  
      this.Total = (this.flete + parseFloat(this.subtotalIva)).toFixed(2)
  
      this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
    
  }
  switchMoneda(ev:any){

    console.log(ev);

    this.DATA.forEach(element => {

      element.check_estatus = false
      
    });

    
    let inputCantidad = document.querySelector(`#flete`) as HTMLInputElement

    inputCantidad.value = '0'    

    this.Subtotal = 0;
    this.iva = 0 ;
    this.ret_iva = 0;
    this.ret_ivaShow = 0;
    this.Total = 0;
    this.subtotalIva = 0;
    this.flete = 0;
    this.TotalShow = 0;
    this.ELEMENT_DATA2 = []

    if(ev.checked){

      this.DATA = this.data.productos;

      this.moneda = 'Bs'

      this.ELEMENT_DATA = this.DATA.filter(element => element.moneda == 'Bs')

      console.log(this.data);


      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);


    }else{

      this.DATA = this.data.productos;

      this.moneda = '$'


      this.ELEMENT_DATA = this.DATA.filter(element => element.moneda == '$')

      console.log(this.data);

      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);

    }

  }
  async enviarPreCrearOrden(action:string){
    
    try {

      if(!this.ELEMENT_DATA2.length){

        this.snackBar.open( 'Debe elegir algun producto', 
                            'Cerrar',
                            { 
                              duration:15000,
                              horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,
                            }
                          )

        return ;
      }
  
      var dialogRef2 = this.dialog.open(FormCrearOrNotasComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
                nota:this.nota,
                accion:this.accion},
        disableClose: true,
        panelClass: 'modal-bg-transparent',
      });
  
      this.nota = await dialogRef2.afterClosed().toPromise()
  
      if(this.nota !== 'cerrar'){
        
        let obj = {
          cod_pre_orden_compra:this.data.cod_pre_orden_compra,
          proveedor: this.ficha_proveedor,
          productos:this.ELEMENT_DATA2,
          subtotal:this.Subtotal,
          iva:this.iva,
          reten_iva:this.ret_iva, 
          subtotalIva:this.subtotalIva,
          flete:this.flete, 
          total:this.Total,
          moneda: this.moneda,
          nota:this.nota,
          usuario: {
                      cod_usuario: localStorage.getItem('cod_usuario'),
                      des_usuario: localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido')
                    }
          }
    
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: {id:'',
                  accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });

        const modalRes = await dialogRef.afterClosed().toPromise()
  
        if (modalRes != 'cerrar') {

          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });

          !action
          ? await this.servicio.crearPreOrdenDCompra(obj).toPromise()
          : await this.servicio.crearOrdenDComprahttp(obj).toPromise()

          this.dialogSpin.close()
          this.dialogRefp.close('listo')
        }
  
      }
      
    } catch (error) {

      this.mostrarTabla()

      this.snackBar.open( error.error.message, 
                          'Cerrar',
                          { 
                            duration:15000,
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                          }
                        );
      
    }
    
  }
  async verNota(element:any){
    let dialogRef = this.dialog.open(FormCrearOrNotasComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
              accion:this.accion,
              nota:this.data.proveedor.nota,
              cod_orden_compra:this.data.proveedor.cod_orden_compra,
              ver: true
            },
      disableClose: true,
      panelClass: 'modal-bg-transparent',
    });
    let dataNota = await dialogRef.afterClosed().toPromise()
    console.log(dataNota);
    if(dataNota != 'cerrar'){
      this.data.proveedor.nota = dataNota
    }
  }
  async imprimirOrdenCompra(){
    try {
        var data:any = this.ELEMENT_DATA
        console.log(this.ELEMENT_DATA);
        const pdfMake = window["pdfMake"]
        
        const {
                cod_proveedor,
                des_proveedor,
                telefono1_prov,
                telefono2_prov,
                dir_prov,
                reten_prov
              } = this.data.proveedor2
        const {
                des_usuario,
                iva,
                subtotal,
                reten_iva,
                flete,
                total,
                nota,
                moneda,
                cod_orden_compra,
                fecha
              } = this.data.proveedor
        
        const subtotalMasIVA =    Number(subtotal)  +  Number(iva) + Number(flete)
        const NewCodProveedor = cod_proveedor.split('')
        NewCodProveedor.splice(1,0,'-')
        NewCodProveedor.splice(NewCodProveedor.length-1,0,'-')

        const tablaEnLista:any = this.generarTabla(data,moneda)

        const pdfDefinition: any = {
          pageSize: 'LETTER',
          background: [
            { image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/marca_compas_pdf.png"), width: 680, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
          ],
          info:{
            title:'Orden de compras',
            author: 'FACTORY COSMETICS 3DG, C.A',
            subject:'Orden de compras',
          },
          watermark: { text: this.data.pre_orden ?? '', angle: 45,opacity: 0.1 },
          content: [
            {
              style:'header',
              table:{
              body:
                [
                  [
                    {image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/logo-factory.png"), fit:[80,80],alignment:'left',},
                    {text:'\nFACTORY COSMETICS 3DG, C.A.\n \
                            Calle bolivar con Calle Regulo Franquiz Local S/N\n \
                            Sector Pueblo Arriba. Guarenas, Miranda.\n \
                            Zona Postal 1220. Telf.:(0212) 519.43.98/ 365.46.24/ 714.03.42/ 425.2.83 \n \
                            E-mail: contacto@factorycosmetics.com.ve / www.factorycosmetics.com.ve\n \
                            RIF.: J-41031797-3',alignment: 'center',fontSize:9},
                    {text:'    ', colSpan: 1,},
                  ],
                ],
              },layout: {
                defaultBorder: false,
              },
            },
            {
              style:'cuadros',
              table:{
                headerRows: 1,
                widths: [ 240, 27.5, 220,],
                body:
                  [
                    [
                      {text:`Orden de Compra realizada a: `,alignment: 'left',bold:true,border: [false, false, false, false],fontSize:9,},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:[
                        'Orden de Compra N°',
                        { text: cod_orden_compra, fontSize:8,bold:true},
                      ],alignment: 'right',border: [false, false, false, false],fontSize:9,},
                    ],
                    [
                      {text:[
                        'Proveedor:',
                        { text: des_proveedor, fontSize:8,bold:true},
                      ],alignment: 'left',border: [false, false, false, false],fontSize:8,},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:[
                        'Emisión:',
                        { text: fecha, fontSize:8,bold:true},
                      ],alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:[
                        'RIF:',
                        { text: NewCodProveedor, fontSize:8,bold:true},
                      ],alignment: 'left',border: [false, false, false, false],fontSize:8,},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:[
                        'Vencimiento:',
                        { text: moment(fecha,'DD/MM/YYYY').add(30,'days').format('DD/MM/YYYY'), fontSize:8,bold:true},
                      ],alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:[
                        'Telefonos:',
                        { text: telefono1_prov === telefono2_prov ? telefono2_prov : telefono1_prov+'/'+telefono2_prov, fontSize:8,bold:true},
                      ],alignment: 'left',border: [false, false, false, false],fontSize:8,},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:`Comprador: ${des_usuario} `,alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:[
                        'Direccion:',
                        { text:dir_prov, fontSize:6,bold:true},
                      ],alignment: 'left',border: [false, false, false, false],fontSize:8,},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:' ',alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                  ],
                },
            },
            { 
              style: 'Descripcion',
              table: {
                  headerRows: 1,
                  widths: [55,'*',50/* ,65 */,80,80],
                  body: tablaEnLista,
              }, 
            },
            {text:'',alignment:'left',fontSize:9,},
            {text:'Notas',bold:true, fontsSize:10,alignment:'left'},
            {
              style:'cuadros_inferiores',
              table:{
                headerRows: 1,
                widths: [ 220, 47.5, 220,],
                body:
                  [
                    [{
                      text:nota,rowSpan:8,border: [false, false, false, false],fontSize:6,
                      },
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'',alignment: 'right',bold:false,border: [false, false, false, false],fontSize:9,},
                      
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'',alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:`Items(${this.ELEMENT_DATA.length}): ${this.Helpers.puntoporcoma(subtotal) + moneda}`,alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:`Fletes: ${this.Helpers.puntoporcoma(flete)+ moneda}`/* `IVA: ${iva+' '+ moneda}` */,alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:`IVA: ${this.Helpers.puntoporcoma(iva) + moneda}`,alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:`Sub total: ${this.Helpers.puntoporcoma(subtotalMasIVA)+' '+ moneda}`/* `IVA a pagar: ${ this.reten_prov == '75%'? (Number(iva) * 0.25).toFixed(2) : 0 +' '+ moneda}` */,alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'',alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'',alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'Ret. ISRL  : 0$',alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false]},
                      {text:'', colSpan: 1,border: [false, false, false, false]},
                      {text:`Ret. IVA ${reten_prov !== 'XCNT' ? reten_prov : ''}: ${this.Helpers.puntoporcoma(reten_iva)+' '+ moneda}`,alignment:'right',border: [false, false, false, false],fontSize:8},
                    ],
                    [
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {text:'', colSpan: 1,border: [false, false, false, false],},
                      {
                        text: [
                          'Total Orden de Compra: ',
                          { text: `${this.Helpers.puntoporcoma(total)+ moneda}`, fontSize:10,bold:true},
                          
                        ], alignment:'right',border: [false, false, false, false],fontSize:8,},
                    ],
                  ],
                },
            },
              
          ],
          styles: {
            header: {
              margin: [ 0, 0, 0, 25]
            },
            cuadros: {
                margin: [ 0, 0, 0, 0]
            },
        },
    }
   
        const pdf = pdfMake.createPdf(pdfDefinition);
      
        pdf.open();

    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  generarTabla(dato:any[],moneda:string){

    let data:any= [...dato];
    let items:any= dato.length;
          
    for(let i = 0; i < 28; i++ ){
                    
      if(!data[i]){
        
        let articuloVacio =  {
             cod_producto: ' ',
             des_producto: ' ',
             cant_producto: ' ',
             partida: ' ',
             des_partida:'',
             tipo_pro:''
        }
        data[i] = articuloVacio
      }
    }
      let arry:any =[
        [
            {text: 'Codigo', alignment: 'center', fontSize: 7, border: [true, true, false, true],bold:true}, 
            {text: 'Descripción', alignment: 'center', fontSize: 7, border: [false, true, false, true],bold:true}, 
            {text: 'Cantidad', alignment: 'center', fontSize: 7,border: [false, true, false, true],bold:true},
            /* {text: 'UM', alignment: 'center', fontSize: 7,border: [false, true, false, true],bold:true}, */
            {text: 'Costo', alignment: 'center', fontSize: 7,border: [false, true, false, true],bold:true},
            {text: 'Total', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
        ],
    ]
    for (const i in data) {

        if(data[i].cod_producto > 0){

            items = data[i].length
        }
          console.log(Number(data[i].precio_total));
        let articulo:any = [
            {text: data[i].cod_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
            {text: data[i].des_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
            {text: data[i].cant_producto_cot && (Number(data[i].cant_producto_cot.replace(/\,/g, '.'))) +' '+data[i].tipo_unidad_medida, alignment: 'center', fontSize: 6,border: [false, false, false, false],},
            /* {text: data[i].tipo_unidad_medida, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, */
            {text: data[i].precio && this.Helpers.puntoporcoma(Number(data[i].precio.replace(/\,/g, '.')))+moneda , alignment: 'center', fontSize: 6,border: [false, false, false, false],},
            {text: data[i].precio_total && this.Helpers.puntoporcoma(Number(data[i].precio_total.replace(/\,/g, '.').replace(/\,/g, '.')))+moneda, alignment: 'center', fontSize: 6,border: [false, false, false, false],},
        ]
        arry.push(articulo)
    }
    return arry;
  }
  getBase64ImageFromURLdaily(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {

        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open( error.error.message, 
                          'Cerrar',
                          { 
                            duration:15000,
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                          }
                        );
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
