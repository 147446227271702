<div class="h-90">
    <div class="row h-90">
        <div class="col-md-12 col-sm-12 col-xs-12 h-90" style="border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content h-90">
                    <div class="modal-header " style="height: 12%; background-color: rgb(32, 50, 65); color: #fff;">
                        
                        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" margin: 0px;">
                                                        
                            <div class=" navbar-collapse  px-1" id="navbarSupportedContent">
                                <ul class="navbar-nav mr-auto ">
                                    <li class="nav-item "><h3 class="modal-title my-1 my-sm-0 mr-sm-1 text-left"><strong>Transferencia entre partidas</strong> </h3></li>
                                </ul>
                                <span class="my-2 my-sm-0 mr-sm-2" style=" font-size: 14px; color: rgb(228, 97, 9);"><b>{{alerta}} </b></span>
                                <span class="my-2 my-sm-0 mr-sm-2"  style="color: rgb(37, 71, 163); " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                    <mat-icon style="color: rgb(255, 94, 0);" *ngIf="!bandera2">report_problem</mat-icon>
                                </span>
                                
                            </div>
                            
                        </nav>
                    </div>
                    <div class="modal-body py-0" style="height: 76%; background-color: rgb(32, 50, 65); color: #fff;">
                     
                            <div class="row py-1" style="height: 50%;">
                                <div class="col-md-12 col-sm-12 ">
                                 
                                    <div class="row py-0" >
                                        <div class="col-md-5 col-sm-5 col-xs-5">
                                            <mat-form-field  class="materialinput py-0 ">
                                                <mat-label style="color: rgb(230, 91, 10); font-size: 12px;">Partida salida</mat-label>
                                                <mat-select  style="font-size: 12px; color: rgb(255, 255, 255);" (selectionChange)="buscarValoresCuentas(partida_salida)" placeholder="" formControlName="partida_salida" autocomplete="off">
                           
                                                    <mat-option  *ngFor="let item of almacen" [value]="item.cod_cuenta_gasto">{{item.cod_cuenta_gasto}}-{{item.des_cuenta_gasto}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2  px-2 ">
                                                <div class="py-2 px-5">
                                                    <mat-icon style="font-size: 30px; color: rgb(172, 32, 32);">trending_flat</mat-icon>
                                                </div>
                                                
                                        </div>
                                        <div class="col-md-5 col-sm-5 col-xs-5">
                                            <mat-form-field class="materialinput px-1">
                                                <mat-label style="color: rgb(230, 91, 10); font-size: 12px;">Partida entrada</mat-label>
                                                <mat-select style="font-size: 12px; color: rgb(255, 255, 255);" (selectionChange)="buscarValoresCuentas(partida_entrada)" placeholder=" " formControlName="partida_entrada" autocomplete="off">
 
                                                    <mat-option *ngFor="let item of almacen" [value]="item.cod_cuenta_gasto">{{item.cod_cuenta_gasto}}-{{item.des_cuenta_gasto}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row py-0" >
                                        <div class="col-md-5 col-sm-5 col-xs-5">
                                            <mat-form-field class="materialinput  py-0">
                                                <mat-label style="color: rgb(230, 91, 10); font-size: 12px;">Cantidad partida salida </mat-label>
                                                <input style="font-size: 12px; color: rgb(255, 255, 255); " maxlength="11" type="text " matInput placeholder="" formControlName="cant_salida" #cant_salida autocomplete="off" readonly>
                                                <span style="color: rgb(255, 255, 255); " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                    
                                                </span>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2  px-2 ">
                                                <div class=" px-5">
                                                    <mat-icon style="font-size: 30px; color: rgb(0, 0, 0);">label_important</mat-icon>
                                                </div>
                                                
                                        </div>
                                        <div class="col-md-5 col-sm-5 col-xs-5">
                                            <mat-form-field class="materialinput  px-1">
                                                <mat-label style="color: rgb(230, 91, 10); font-size: 12px;">Cantidad partida entrada </mat-label>
                                                <input style="font-size: 12px; color: rgb(255, 255, 255); " maxlength="11" type="text " matInput placeholder="" formControlName="cant_entrada" #cant_entrada autocomplete="off" readonly>
                                                <span style="color: rgb(255, 255, 255); " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                    
                                                </span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                            
                                           <!--  <span *ngIf="!bandera_" style="color: rgb(247, 101, 4);">Este producto no tiene unidad de manejo!</span>
                                            <span *ngIf="bandera" style="color: rgb(236, 160, 19);">Unidad de manejo por bultos!</span> -->

                                    <!-- <div class="row py-0" >
                                        <div class="col-md-2 col-sm-2 col-xs-2">
                            
                                            <mat-form-field class="materialinput" >
                                                <mat-label style="color: rgb(230, 91, 10); font-size: 12px;">Cantidad</mat-label>
                                                <input style="font-size: 12px; color: rgb(255, 255, 255);" maxlength="11" type="text " matInput placeholder="" #cant_lote formControlName="cant_lote"  autocomplete="off" >
                                                <span style="font-size: 12px; color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                    #
                                                    </span>
                                            </mat-form-field>
                                          
                                        </div>

                                         <div class="col-md-2 col-sm-2 col-xs-2">
                            
                                            <mat-form-field class="materialinput  px-1">
                                                <mat-label style="color: rgb(230, 91, 10); font-size: 12px;">Actual</mat-label>
                                                <input style="font-size: 12px; color: rgb(255, 255, 255); " maxlength="11" type="text " matInput placeholder="" #total_lote formControlName="total_lote"  autocomplete="off" readonly>
                                                <span style="font-size: 12px; color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                    #
                                                    </span>
                                            </mat-form-field>
                                          
                                        </div>
                                    </div> -->

                                    </div>
                                </div>
                                <div id="carrito_p6" class="row py-4" style="height: 45%;">
                                  
                                        
                                            <div class="col-md-7 col-sm-7 col-xs-7  table-responsive  h-100">
                                                <table #table mat-table [dataSource]="dataSource" class=" table-dark  text-center table-bordered table-hover">
                                                    <ng-container matColumnDef="partidas">
                                                        <th mat-header-cell *matHeaderCellDef class="text-center" style=" color: #fff; background-color: rgb(35, 38, 41);">
                                                            <h5><strong>Partidas</strong></h5>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style=" color: #fff; font-size: 12px;"><b>{{element.cod_lote}}</b></td>
                                                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(35, 38, 41); color: #fff;"></td>
                                                    </ng-container>
                                
                                                    <ng-container matColumnDef="cantidad">
                                                        <th mat-header-cell *matHeaderCellDef class="text-center" style=" color: #fff; background-color: rgb(35, 38, 41);">
                                                            <h5><strong>Cantidad</strong></h5>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style=" color: #fff; font-size: 12px;"><b>{{element.cant_lote}}</b></td>
                                                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(35, 38, 41); color: #fff;">Total: {{total_valor}}</td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="remov_elem">
                                                        <th mat-header-cell *matHeaderCellDef class="text-center" style=" color: #fff; background-color: rgb(35, 38, 41);">
                                                            <h5><strong>Eliminar</strong></h5>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" class="text-center" style=" color: #fff; font-size: 12px;">
                                                            <button (click)="eliminarItems(element)" matTooltip="QUITAR TRANSFERENCIA DE LA LISTA" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(35, 38, 41); border: solid 2px steelblue;">
                                                            <mat-icon >delete</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(35, 38, 41); color: #fff;"> Items : {{total_items}}</td>
                                                    </ng-container>
                                
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                                                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                                                </table>
                                            </div>
                                            <div class="col-md-5 col-sm-5 col-xs-5  h-100">
                                                
                                               <div class="row">
                                                <div class="col-md-5 col-sm-5 col-xs-5 " >
                                                    
                                                        <mat-form-field class="materialinput" >
                                                            <mat-label style="color: rgb(230, 91, 10); font-size: 12px;">Cantidad</mat-label>
                                                            <input style="font-size: 12px; color: rgb(255, 255, 255);" maxlength="11" type="text " matInput placeholder="" #cantidad  id="cantidad" (keyup)="ponerPuntosyComas($event)" formControlName="cantidad"  autocomplete="off" >
                                                            <span style="font-size: 12px; color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                                #
                                                                </span>
                                                        </mat-form-field>
                                                      
                                                    </div>
                                                <!-- style="justify-content: center; margin: 1rem 0;" -->
                                                <div class="col-md-7 col-sm-7 col-xs-7 " >
                                                    <div class=" ">
                                                        <button matTooltip="AGREGAR TRANSFERENCIA" class="my-2 my-sm-0 mr-sm-2 form-control" (click)='addPartidas()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);"><mat-icon >add</mat-icon></button>
                                                    </div>
                                                     
                                                </div>
                                               </div>
                
                                               <div class="row">
                                                   <div class="col-md-12 col-sm-12 col-xs-12 " >
                                                       <div class="">
                                                        <textarea  #comentarios style="font-size: 12px; color: rgb(0, 0, 0); background-color: white;" maxlength="200" matInput placeholder="Observaciones" formControlName="comentario" autocomplete="off" > </textarea>
                                                       </div>
                                                        
                                                   </div>
                                               </div>

                                               <div class="row">
                                                <!-- <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
                                                    
                                                    <div class=" navbar-collapse border py-1 px-1" id="navbarSupportedContent">
                                                        <ul class="navbar-nav mr-auto ">
                                                            <li class="nav-item"><button matTooltip="AGREGAR LOTES" class="my-2 my-sm-0 mr-sm-2 form-control" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);"><mat-icon >add</mat-icon></button></li>
                                                        </ul>
                                                    </div>
                                                    
                                                </nav> -->

                                           </div>
                                    </div>

                                </div>
                    </div>
                    <div class="modal-footer " style="height: 12%; background-color: rgb(32, 50, 65); color: #fff;">
                        <div class="form-group">
                            <button mat-button class=" btn-danger my-2 my-sm-0 mr-sm-2" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button mat-button class=" btn-primary my-2 my-sm-0 mr-sm-2" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

