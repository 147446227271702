import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { identity, result } from 'lodash';
import { filter } from '../form-comfirm-fabricacion/form-comfirm-fabricacion.component';
import { element } from 'protractor';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';

import { FormCuestionarioComprasComponent } from 'src/app/componentes/shared/modal/form-cuestionario-compras/form-cuestionario-compras.component';
import { ThemeService } from 'ng2-charts';


@Component({
  selector: 'app-form-crear-proveedor-compras',
  templateUrl: './form-crear-proveedor-compras.component.html',
  styleUrls: ['./form-crear-proveedor-compras.component.css']
})
export class FormCrearProveedorComprasComponent implements OnInit {

 
  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  /*PARA VER*/
    ver:any = false;
  /*PARA VER*/
  isLinear = true;
  pais:any = [];
  estado:any = [];
  ciudad:any = [];
  bancos:any = [];
  tiposProducto:any = [];
  nacional:any = false
  existe = false

  estadoG:any = [];
  ciudadG:any = [];
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  filteredOptionsEstados: Observable<any[]>;
  filteredOptionsCiudades: Observable<any[]>;

 
  @ViewChild(MatPaginator) paginator: MatPaginator;
 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 verticalPosition: MatSnackBarVerticalPosition = 'top';

 constructor( public dialog: MatDialog,fb:FormBuilder,
  public dialogRefp: MatDialogRef<FormCrearProveedorComprasComponent>,
  ///en el injectable llega la informacion del metodo padre en data
 @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices) { 

      if(data.existe){
        this.existe = true
      }  

    if(data.proveedor){

            const {
              cod_proveedor,
              des_proveedor,
              tipo_prov,
              dir_prov,
              pais_prov,
              estatus_prov,
              ciudad_prov,
              estado_prov,
              reten_prov,
              email_prov,
              telefono1_prov,
              telefono2_prov,
              cod_contacto_prov,
              des_contacto_prov,
              email_contacto_prov,
              telefono1_contacto_prov,
              telefono2_contacto_prov,
              cumpleanho_contacto_prov,
              cod_propietario_prov,
              des_propietario_prov,
              cumpleanho_propietario_prov,
              email_propietario_prov,
              cod_cuenta_bene,
              des_cuenta_bene,
              banco_cuenta_bene,
              cod_cuenta_banco_bene,
              telefono_cuenta_bene,
              moneda,
              cod_cuenta_bene2,
              des_cuenta_bene2,
              banco_cuenta_bene2,
              cod_cuenta_banco_bene2,
              telefono_cuenta_bene2,
              moneda2
            } = data.proveedor

        if(pais_prov === 'VENEZUELA'){
          this.nacional = true
        }

      this.firstFormGroup = fb.group({
      cod_proveedor: [{value:cod_proveedor,disabled:cod_proveedor.length ? true : false }],
      cod_proveedorHidde: cod_proveedor,
      des_proveedor: [des_proveedor, Validators.required],
      dir_prov: [dir_prov, Validators.required],
      pais_prov: [pais_prov, Validators.required],
      estado_prov: [estado_prov, Validators.required],
      ciudad_prov: [ciudad_prov, Validators.required],
      email_prov: [email_prov, [Validators.required, Validators.email]],
      telefono1_prov: [telefono1_prov, [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
      telefono2_prov: [telefono2_prov, [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
      reten_prov: [reten_prov, Validators.required],
      tipo_prov:[tipo_prov, Validators.required],
      estatus_prov: estatus_prov
      });

      this.secondFormGroup = fb.group({
      cod_contacto_prov: [cod_contacto_prov, [Validators.required,Validators.pattern(/^([VEJPG]{1})([0-9]{8})/g)]],
      des_contacto_prov: [des_contacto_prov, Validators.required],
      email_contacto_prov: [email_contacto_prov, [Validators.required,Validators.email]],
      telefono1_contacto_prov: [telefono1_contacto_prov, [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
      telefono2_contacto_prov: [telefono2_contacto_prov, [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
      cumpleanho_contacto_prov: cumpleanho_contacto_prov,
      cod_propietario_prov: [cod_propietario_prov,[Validators.pattern(/^([VEJPG]{1})([0-9]{8})/g)]],
      des_propietario_prov: des_propietario_prov,
      cumpleanho_propietario_prov: cumpleanho_propietario_prov,
      email_propietario_prov: [email_propietario_prov,[Validators.email]],
      });

      this.thirdFormGroup = fb.group({
      cod_cuenta_bene: [cod_cuenta_bene, [Validators.required,Validators.pattern(/^([VEJPG]{1})([0-9]{9})/g)]],
      des_cuenta_bene: [des_cuenta_bene, Validators.required],
      banco_cuenta_bene: [banco_cuenta_bene, Validators.required],
      cod_cuenta_banco_bene: [cod_cuenta_banco_bene,  [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{4})-([0-9]{4})-([0-9]{4})-([0-9]{4})/g)]],
      telefono_cuenta_bene: [telefono_cuenta_bene, [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
      moneda: [moneda, Validators.required],
      cod_cuenta_bene2: [cod_cuenta_bene2,[Validators.pattern(/^([VEJPG]{1})([0-9]{9})/g)]],
      des_cuenta_bene2: des_cuenta_bene2,
      banco_cuenta_bene2: banco_cuenta_bene2,
      cod_cuenta_banco_bene2: [cod_cuenta_banco_bene2, [Validators.pattern(/([0-9]{4})-([0-9]{4})-([0-9]{4})-([0-9]{4})-([0-9]{4})/g)]],
      telefono_cuenta_bene2: [telefono_cuenta_bene2,[Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
      moneda2: moneda2,
      });
    }else{

      this.firstFormGroup = fb.group({
        cod_proveedor: ['', [Validators.required,Validators.pattern(/^([VEJPG]{1})([0-9]{9})/g)]],
        des_proveedor: ['', Validators.required],
        dir_prov: ['', Validators.required],
        pais_prov: ['', Validators.required],
        estado_prov: ['', Validators.required],
        ciudad_prov: ['', Validators.required],
        email_prov: ['', [Validators.required,Validators.email]],
        telefono1_prov: ['', [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
        telefono2_prov: ['', [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
        reten_prov: ['', Validators.required],
        tipo_prov:['', Validators.required],
        estatus_prov:'A'
      });
  
      this.secondFormGroup = fb.group({
        cod_contacto_prov: ['', [Validators.required,Validators.pattern(/^([VEJPG]{1})([0-9]{8})/g)]],
        des_contacto_prov: ['', Validators.required],
        email_contacto_prov: ['', [Validators.required,Validators.email]],
        telefono1_contacto_prov: ['', [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
        telefono2_contacto_prov: ['', [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
        cumpleanho_contacto_prov: '',
        cod_propietario_prov: ['',[Validators.pattern(/^([VEJPG]{1})([0-9]{8})/g)]],
        des_propietario_prov: '',
        cumpleanho_propietario_prov: '',
        email_propietario_prov: ['',[Validators.email]],
      });
  
      this.thirdFormGroup = fb.group({
        cod_cuenta_bene: ['', [Validators.required,Validators.pattern(/^([VEJPG]{1})([0-9]{9})/g)]],
        des_cuenta_bene: ['', Validators.required],
        banco_cuenta_bene: ['', Validators.required],
        cod_cuenta_banco_bene: ['', [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{4})-([0-9]{4})-([0-9]{4})-([0-9]{4})/g)]],
        telefono_cuenta_bene: ['', [Validators.required,Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
        moneda: ['', Validators.required],
        cod_cuenta_bene2: ['',[Validators.pattern(/^([VEJPG]{1})([0-9]{9})/g)]],
        des_cuenta_bene2: '',
        banco_cuenta_bene2: '',
        cod_cuenta_banco_bene2: ['',[Validators.pattern(/([0-9]{4})-([0-9]{4})-([0-9]{4})-([0-9]{4})-([0-9]{4})/g)]],
        telefono_cuenta_bene2: ['',[Validators.pattern(/([0-9]{4})-([0-9]{7})/g)]],
        moneda2: '',
      });

  }
    /* }else if(data.infoCot){

      const {

        rif_cot_1,
        direccion_cot_1, 
        nombre_emp_cot_1, 
        rif_cot_2,
        direccion_cot_2, 
        nombre_emp_cot_2, 
        rif_cot_3,
        direccion_cot_3, 
        nombre_emp_cot_3, 
        
      } = data.infoCot

      const codigo = rif_cot_1 == data.data.proveedor.cod_proveedor ? rif_cot_1 : rif_cot_2 == data.data.proveedor.cod_proveedor ? rif_cot_2 : rif_cot_3
      const direccion = rif_cot_1 == data.data.proveedor.cod_proveedor ? direccion_cot_1 : rif_cot_2 == data.data.proveedor.cod_proveedor ? direccion_cot_2 : direccion_cot_3
      const nombre = rif_cot_1 == data.data.proveedor.cod_proveedor ? nombre_emp_cot_1 : rif_cot_2 == data.data.proveedor.cod_proveedor ? nombre_emp_cot_2 : nombre_emp_cot_3

      this.firstFormGroup = fb.group({
        cod_proveedor: [{value:codigo,disabled:true}, Validators.required],
        cod_proveedorHidde: codigo,
        des_proveedor: [nombre, Validators.required],
        dir_prov: [direccion, Validators.required],
        pais_prov: ['', Validators.required],
        estado_prov: ['', Validators.required],
        ciudad_prov: ['', Validators.required],
        email_prov: ['', Validators.required],
        telefono1_prov: ['', Validators.required],
        telefono2_prov: ['', Validators.required],
        reten_prov: ['', Validators.required],
        tipo_prov:['', Validators.required],
        estatus_prov:'A'
      });
  
      this.secondFormGroup = fb.group({
        cod_contacto_prov: ['', Validators.required],
        des_contacto_prov: ['', Validators.required],
        email_contacto_prov: ['', Validators.required],
        telefono1_contacto_prov: ['', Validators.required],
        telefono2_contacto_prov: ['', Validators.required],
        cumpleanho_contacto_prov: '',
        cod_propietario_prov: '',
        des_propietario_prov: '',
        cumpleanho_propietario_prov: '',
        email_propietario_prov: '',
      });
  
      this.thirdFormGroup = fb.group({
        cod_cuenta_bene: ['', Validators.required],
        des_cuenta_bene: ['', Validators.required],
        banco_cuenta_bene: ['', Validators.required],
        cod_cuenta_banco_bene: ['', Validators.required],
        telefono_cuenta_bene: ['', Validators.required],
        moneda: ['', Validators.required],
        cod_cuenta_bene2: '',
        des_cuenta_bene2: '',
        banco_cuenta_bene2: '',
        cod_cuenta_banco_bene2: '',
        telefono_cuenta_bene2: '',
        moneda2: '',
      });

    }else{

        this.firstFormGroup = fb.group({
          cod_proveedor: ['', Validators.required,],
          des_proveedor: ['', Validators.required],
          dir_prov: ['', Validators.required],
          pais_prov: ['', Validators.required],
          estado_prov: ['', Validators.required],
          ciudad_prov: ['', Validators.required],
          email_prov: ['', Validators.required],
          telefono1_prov: ['', Validators.required],
          telefono2_prov: ['', Validators.required],
          reten_prov: ['', Validators.required],
          tipo_prov:['', Validators.required],
          estatus_prov:'A'
        });
    
        this.secondFormGroup = fb.group({
          cod_contacto_prov: ['', Validators.required],
          des_contacto_prov: ['', Validators.required],
          email_contacto_prov: ['', Validators.required],
          telefono1_contacto_prov: ['', Validators.required],
          telefono2_contacto_prov: ['', Validators.required],
          cumpleanho_contacto_prov: '',
          cod_propietario_prov: '',
          des_propietario_prov: '',
          cumpleanho_propietario_prov: '',
          email_propietario_prov: '',
        });
    
        this.thirdFormGroup = fb.group({
          cod_cuenta_bene: ['', Validators.required],
          des_cuenta_bene: ['', Validators.required],
          banco_cuenta_bene: ['', Validators.required],
          cod_cuenta_banco_bene: ['', Validators.required],
          telefono_cuenta_bene: ['', Validators.required],
          moneda: ['', Validators.required],
          cod_cuenta_bene2: '',
          des_cuenta_bene2: '',
          banco_cuenta_bene2: '',
          cod_cuenta_banco_bene2: '',
          telefono_cuenta_bene2: '',
          moneda2: '',
        });
  
    } */
 }

  ngOnInit(): void {

    this.mostrarSelects()

  }

  mostrarSelects(){

    this.servicio.listaInfoPaisesCiudadEstadosVznlhttp().subscribe(result => {


      this.pais = result.paises
      this.estadoG = result.estadosVznl
      this.ciudadG = result.ciudadesVznl


      
    })

    this.servicio.listarBancosVznlhttp().subscribe(result => {


      this.bancos = result.result


    })

    this.servicio.listarTipoProductoshttp().subscribe(result => {

      this.tiposProducto = result.result


    })



  }
  private _filterCiudad(value: string): any[] {
    const filterValue = value.toLowerCase();  
    return this.ciudad.filter(option => option.ciudad.toLowerCase().includes(filterValue));
  }
  private _filterEstados(value: string): any[] {
    const filterValue = value.toLowerCase();  
    return this.estado.filter(option => option.estado.toLowerCase().includes(filterValue));
  }  


  filtrarCiudades(ev:any){


    let id = ev.source.value

    this.ciudad = this.ciudadG.filter(element => element.estado == id)

    this.filteredOptionsCiudades = this.firstFormGroup.controls.ciudad_prov.valueChanges.pipe(
      startWith(''),
      map(state => (state ? this._filterCiudad(state) : this.ciudad.slice())));
    
  }


  verificarPais(ev:any){

    if(ev.value == 'VENEZUELA'){

      this.nacional = true

      this.servicio.listaInfoPaisesCiudadEstadosVznlhttp().subscribe(result => {

        this.estado =  result.estadosVznl
        this.ciudadG = result.ciudadesVznl

  
        this.filteredOptionsEstados = this.firstFormGroup.controls.estado_prov.valueChanges.pipe(
          startWith(''),
          map(state => (state ? this._filterEstados(state) : this.estado.slice())));

  
      })
      
      
    }else{
      this.estado = []

    }

  }

  Send(){

    let obj = {...this.firstFormGroup.value,...this.secondFormGroup.value,...this.thirdFormGroup.value}
   /*  let obj = {...this.firstFormGroup.value} */

    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
              accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(
      result => {
      if (result != 'cerrar') {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
  
        this.servicio.crearOactuzalizarProveedorhttp(obj).subscribe(
        response =>{

          this.dialogSpin.close();
          this.dialogRefp.close(obj);
    
        },
        error =>{
    
          this.dialogSpin.close();
          this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
    
        })
      }
    },
      error => {
        this.dialogSpin.close()
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }


}
