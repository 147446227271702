import { Component, OnInit, Inject, Injectable, ElementRef, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {BehaviorSubject} from 'rxjs';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatPaginator } from '@angular/material/paginator';
import { FormPermisosAccionesComponent } from '../form-permisos-acciones/form-permisos-acciones.component';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

@Component({
  selector: 'app-form-permisos',
  templateUrl: './form-permisos.component.html',
  styleUrls: ['./form-permisos.component.css']
})

export class FormPermisosComponent implements OnInit {
  @ViewChild('table') table:MatTable<any>;
  @ViewChild('unidad_front') unidad_front:ElementRef;
  @ViewChild('menu_front') menu_front:ElementRef;
  @ViewChild('submenu_front',{static:true}) submenu_front:ElementRef;
  @ViewChild('submenu_hijo',{static:true}) submenu_hijo:ElementRef;
  
  dialogSpin:any;
  forma:FormGroup;
  accion:any;
  roles:any;
  rol = [];
  unidad=[];
  unidadp=[];
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  menur3:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
  rx:any;
  constructor(private service:nkServices,
    private fb:FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog, 
    private servicio:nkServices,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private notifi:ToastNotificationService,
    private point: BreakpointObserver,
    private dialogRef: MatDialogRef<FormPermisosComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    console.log(data, 'primero');
    this.accion = data.accion;
    this.unidad = data.unidad;
    for (const i in this.unidad) {
      if (parseInt(this.unidad[i].cod_unidad_padre) > 0) {
        this.unidadp.push(this.unidad[i]);
      }
    }
    for (const i in data.roles) {
              
              if (data.roles[i].cod_unidad === data.cod_unidad ) {
                this.rol.push(data.roles[i]);
                console.log(this.rol, data.cod_unidad);
              }
    }
    for (const i in data.menu) {
              
      if (data.menu[i].cod_unidad === data.cod_unidad) {
        this.menu.push(data.menu[i]);
        this.menu2.push(data.menu[i]);
      }else{
        
      }
}
    this.roles = data.roles;
      this.forma = fb.group({
        'cod_usuario_rol': data.cod_usuario_rol,
        'id':data.id,
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.nombre+' '+data.apellido,
        'cod_unidad':data.cod_unidad,
        'des_unidad':data.des_unidad,
        'nombre':data.nombre,
        'apellido':data.apellido,
        'unidad': '',
        'menu_padre': '',
        'submenu':'',
        'submenu1':'',
        'permisos_menu_usuario':'',
        'modo':''
      });
      this.mediaQuery();
     }

  ngOnInit(){
    this.listarPermisos();
    this.input_dirty = this.forma.pristine;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
    
  }
  mediaQuery(){
    this.point
    .observe(Breakpoints.Small)
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
      //AQUI SERA TRUE SOLO SI ESTA EN RESOLUCION DE CELULAR
      this.rx = 'phone';
    }
  });
  
  this.point
  .observe(Breakpoints.Web)
  .subscribe((state: BreakpointState) => {
    if (state.matches) {
      //AQUI SERA TRUE SOLO SI ES RESOLUCION PARA WEB
      this.rx = 'web';
    }
  });
  }

 async listarPermisos(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      this.ELEMENT_DATA = [];
      this.cant = false;
      var valor = {cod_usuario: this.data.cod_usuario};
     const response = await  this.servicio.buscarPermisoshttp(valor).toPromise();
     const arry = {};
          for (const i of response) {
            const permiso = i.cod_permiso;
              if (!arry[permiso]) {
                arry[permiso] = [];
              }
              arry[permiso].push(i.cod_permiso,i.des_menu);
          }
          for (const i in arry) {
            this.ELEMENT_DATA.push({
              cod_permiso:arry[i][0],
              menu:arry[i][1],
              submenu:arry[i][3],
              submenu1: arry[i][5]
            });
          }
          this.displayedColumns = [
            'cod_permiso',
            'menu',
            'submenu',
            'submenu1',
            'proceso'
           ];
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      this.displayedColumns = [
        'cod_permiso',
        'menu',
        'submenu',
        'submenu1',
        'proceso'
       ];
       this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
        await this.notifi.customToastNotification(error.error.message);
    }
  }

 async permisos_acciones_app(){
    try {
      if (this.ELEMENT_DATA.length > 0) {
        this.accion = '';
        var dialogRef = this.dialog.open(FormPermisosAccionesComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: {unidades:this.unidadp, 
            cod_usuario:this.data.cod_usuario,
            des_usuario:this.data.nombre+' '+this.data.apellido,
                accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal_permisos_acciones',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
           
            console.log(result);
            /* this.gestionarusuriorol(result); */
           
          } 
        });
      }else{
          await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.nada_en_lista);
      }
      
    } catch (error) {
      console.log(<any>error.error.message);
           await this.notifi.customToastNotification(error.error.message);
    }
    
  }

 async modalrespuesta(){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
      console.log(this.forma.value);
      if (this.forma.value.unidad === '' || 
          this.forma.value.menu_padre === '' || 
          this.forma.value.submenu === '' || 
          this.forma.value.submenu1 === '') {
          await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.ruta_menu_completa);
        return;
      }
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            var arry = [];
            var cod_permiso = 'PD'+this.forma.value.menu_padre.cod_menu.toString()+
                              this.forma.value.submenu.cod_menu.toString()+
                              this.forma.value.submenu1.cod_menu.toString();
            arry.push({ 
              cod_permiso: cod_permiso,
              cod_menu:this.forma.value.menu_padre.cod_menu,
              des_menu:this.forma.value.menu_padre.des_menu,
              padre_menu:this.forma.value.menu_padre.padre_menu,
              des_padre_menu: this.forma.value.menu_padre.des_padre_menu
            });
            arry.push({ 
              cod_permiso: cod_permiso,
              cod_menu:this.forma.value.submenu.cod_menu,
              des_menu:this.forma.value.submenu.des_menu,
              padre_menu:this.forma.value.submenu.padre_menu,
              des_padre_menu: this.forma.value.submenu.des_padre_menu
            });
            arry.push({ 
              cod_permiso: cod_permiso,
              cod_menu:this.forma.value.submenu1.cod_menu,
              des_menu:this.forma.value.submenu1.des_menu,
              padre_menu:this.forma.value.submenu1.padre_menu,
              des_padre_menu: this.forma.value.submenu1.des_padre_menu
            });
            this.forma.patchValue({
              permisos_menu_usuario:arry,
              modo:this.atrb.ACCION_CREAR
            });
            await this.util.cerrarSpinner();
           await this.gestionarusuriorol(this.forma.value);
          } else{
            await this.util.cerrarSpinner();
          }
        });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.util.notificacionErrorApp(error.error.message);
    }
  }
  async gestionarusuriorol(xvalor:any){
    try {
      console.log(xvalor);
        const response = await this.servicio.gestionUsuarioRolhttp(xvalor).toPromise();
          await  this.listarPermisos();
          await this.util.notificacionErrorApp(response.message);
    } catch (error) {
      await  this.listarPermisos();
      await this.util.notificacionErrorApp(error.error.message);
    }
   }

 async cargarmenu(cod:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      switch (this.input_dirty) {
        case false:
          this.menur=false;
          this.menur2 = false;
          this.menur3 = false;
          this.submenur = false;
          this.forma.value.menu_padre = '';
          this.forma.value.submenu = '';
          this.forma.value.submenu1 = '';
         const response = await  this.service.buscarMENUhttp(cod.value).toPromise();
         console.log(response);
              this.menu=[];
              this.submenu = [];
              this.submenuhijo = [];
              for (const i in response) {
                if (parseInt(response[i].cod_unidad)  === cod.value.cod_unidad && response[i].padre_menu === '0' || response[i].padre_menu === 0) {
                  this.menu.push(response[i]);
                  this.menur=true;
                }
              }
              this.xmenu = [];
          await this.util.cerrarSpinner();
          break;
        case true:
          this.input_dirty = this.forma.pristine;
          this.menur=false;
          this.menur2 = false;
          this.menur3 = false;
          this.submenur = false;
          const response_ = await this.service.buscarMENUhttp(cod.value).toPromise();
          console.log(response_);
              this.menu=[];
              this.submenu = [];
              this.submenuhijo = [];
              for (const i in response_) {
                    
                if (parseInt(response_[i].cod_unidad)  === cod.value.cod_unidad && response_[i].padre_menu === '0' || response_[i].padre_menu === 0) {
                  this.menu.push(response_[i]);
                  this.menur=true;
                }
              }
              this.xmenu = [];
              await this.util.cerrarSpinner();
            break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
      this.menur=false;
      this.menur2 = false;
      this.menur3 = false;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async cargarsubmenu(cod:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      this.menur2 = false;
      this.menur3 = false;
      this.submenur = false;
      this.forma.value.submenu = '';
        this.forma.value.submenu1 = '';
      const response = await this.service.buscarMENUhttp(cod.value).toPromise();
          this.submenu = [];
          this.submenuhijo = [];
          for (const i in response) {
           
            if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) <= 1 ) {
              if (parseInt(response[i].cod_unidad) === parseInt(cod.value.cod_unidad) &&
                parseInt(response[i].padre_menu) === cod.value.cod_menu &&
               parseInt(response[i].padre_menu)  != 0 
               ) {
                  this.submenu.push(response[i]);
                  this.menur2 = true;
                }
            }
            if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) >= 2 ) {
              if (parseInt(response[i].cod_unidad) === parseInt(cod.value.cod_unidad) &&
                  parseInt(response[i].padre_menu) === cod.value.cod_menu &&
                  parseInt(response[i].padre_menu)  != 0 && 
                  parseInt(response[i].cod_menu) !== parseInt(this.atrb.MENU_SISTEMA_GESTION)
                    ) {
                    this.submenu.push(response[i]);
                    this.menur2 = true;
                  }
            }
          }
          await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      this.menur2 = false;
      this.menur3 = false;
      this.submenu = [];
      await this.notifi.customToastNotification(error.error.message);
    }
    
  }
 async cargarsubmenuhijo(cod:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      this.menur3 = false;
      this.submenur = false;
      this.forma.value.submenu1 = '';
      const response = await this.service.buscarMENUhttp(cod.value).toPromise();
          this.submenuhijo = [];
          for (const i in response) {
            if (parseInt(response[i].cod_unidad) === parseInt(cod.value.cod_unidad) &&
                parseInt(response[i].padre_menu) === cod.value.cod_menu &&  parseInt(response[i].padre_menu)  != 0) {
              this.submenuhijo.push(response[i]);
              this.menur3 = true;
            }
          }
          await this.util.cerrarSpinner();
    } catch (error) {
      this.menur3 = false;
          this.submenuhijo = [];
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  activar(): void{
      this.submenur=true;
  }
  actualizardatos(res:any){
    console.log(this.menu);

      this.dialogRef.close(this.forma.value);
  
  }

  cerrarmodal(){
    let accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result != 'cerrar') {
        await this.util.cerrarSpinner();
        this.dialogRef.close('cerrar');
      }else{
        await this.util.cerrarSpinner();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 async getQuitarPermiso(xobjeto:any){
   try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            xobjeto.modo = this.atrb.ACCION_BORRAR;
            xobjeto.cod_usuario = this.data.cod_usuario;
           const response =  await this.servicio.gestionUsuarioRolhttp(xobjeto).toPromise();
           await this.util.cerrarSpinner();
            await  this.listarPermisos();
            await this.notifi.success(response.message);
          } else{
            await this.util.cerrarSpinner();
          }
        });
    
   } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await  this.listarPermisos();
    await this.notifi.customToastNotification(error.error.message);
   }
   }
}
