import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormConsultarFormulaMppComponent } from '../form-consultar-formula-mpp/form-consultar-formula-mpp.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const filter = (opt: string[], value: string): string[] => {
  console.log(opt, 'valores4');
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-form-confirmacion-inv-mpp',
  templateUrl: './form-confirmacion-inv-mpp.component.html',
  styleUrls: ['./form-confirmacion-inv-mpp.component.css']
})
export class FormConfirmacionInvMppComponent implements OnInit {
  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('inv_mp',{static:true}) inv_mp:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  stateGroupOptions4: Observable<StateGroup[]>;
  stateGroups4: StateGroup[];
  value1 = '';
  nombre:any;
  msg:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
dialogSpin:any;
producto:any = [];
codigo:any = [];
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
ELEMENT_DATA3:any[]=[];
cod_pro:any;
MP:any;
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormConfirmacionInvMppComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'cant_producto':'',
        'ord_producto':'',
        'estatus':this.data.estatus,
        'cod_orden_producto_mpp':data.cod_orden_producto_mpp,
        'cod_orden_producto':data.cod_orden_producto,
        'inv_mp':''

      });
    }

  ngOnInit(): void {
    this.cod_orden_producto.nativeElement.value = this.data.cod_orden_producto_mpp;
    this.listarProductoOrdenesProdMpp(this.data);

  }
  ngAfterViewInit() {

  }

  private filterGroup(value: string): StateGroup[] {
    console.log(value,'valores');
    if (value) {
     
      return this.stateGroups4
        .map(group => ({letter: group.letter, names: filter(group.names, value), code: filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups4;
  }

  listarProductoOrdenesProdMpp(xvalor:any){
   var valor = {cod_orden_producto:xvalor}
   console.log(valor);
   this.servicio.buscarProductoOrdenesProdMppConfirmhttp(xvalor).subscribe(
     response =>{
       console.log(response);
         this.ELEMENT_DATA = [];
         for (const i in response) {
          if (response[i].cod_formula === null || response[i].cod_formula === '') {
            response[i].cod_formula = 'SNFRML';
          }
           }
             for (const i in response) {

               this.ELEMENT_DATA.push({
                cod_formula:response[i].cod_formula,
                 cod_producto:response[i].cod_producto,
                 des_producto:response[i].des_producto,
                 cant_producto:parseFloat(response[i].cant_producto.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.')).toFixed(2).replace(/\D/g, "")
                 .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                 unidad_prod_mp:response[i].unidad_prod_mp 
               });
             }
             this.buscarDespositoFormula(this.ELEMENT_DATA[0]);
             this.ELEMENT_DATA3.push(this.ELEMENT_DATA[0]);
             this.cod_pro = this.ELEMENT_DATA[0].cant_producto;
             this.displayedColumns = [
                                       'cod_formula',
                                       'cod_producto',
                                       'des_producto',
                                       'cant_producto',
                                       'unidad_prod_mp',
                                       'detalle'
                                     ];
             this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);

     },
     error =>{
       console.log(error);
       console.log(<any>error.error.message);
       this.displayedColumns = [
                                 'cod_formula',
                                 'cod_producto',
                                 'des_producto',
                                 'cant_producto',
                                 'detalle'
                               ];
       this.dataSource1 = new MatTableDataSource<any>();
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
   );
 }
 listarFormulaprodMpp(xvalor:any){
  var valor = {cod_producto:xvalor}
  console.log(valor);
 this.servicio.buscarFormulaProdMpphttp(xvalor).subscribe(
   response =>{
     console.log(response);
       this.ELEMENT_DATA = [];
           for (const i in response.producto1) {

             this.ELEMENT_DATA.push({
               cod_producto:response.producto1[i].cod_prod_mp,
               des_producto:response.producto1[i].des_prod_mp,
               cant_producto:response.producto1[i].cantidad_prod_mp,
               requerido_mp:''
             });
           }
           for (const i in response.producto2) {

            this.ELEMENT_DATA2.push({
              cod_producto2:response.producto2[i].cod_producto,
              des_producto2:response.producto2[i].des_producto,
              cant_producto2:response.producto2[i].cant_producto
            });
          }
   },
   error =>{
     console.log(error);
     console.log(<any>error.error.message);

     this.dataSource1 = new MatTableDataSource<any>();
     this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
       verticalPosition: this.verticalPosition});
   }
 );
}

/* modalrespuesta(){
  var key = true;
  var xvalor;
  for (const i in this.ELEMENT_DATA) {
      xvalor = parseFloat(this.ELEMENT_DATA[i].cant_producto.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.') ) * parseFloat(this.data.cant_producto.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'));


    if (xvalor > parseFloat(this.ELEMENT_DATA2[i].cant_producto2.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.')) ||  this.ELEMENT_DATA2[i].cant_producto2 === null ||  this.ELEMENT_DATA2[i].cant_producto2 === "" ) {
      key = false;
      break;
    }
  }
  if (key) {
    this.accion = "enviarordenprodmpp";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
        maxWidth: '100vw',
        height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        for (const i in this.ELEMENT_DATA) {
          xvalor = parseFloat(this.ELEMENT_DATA[i].cant_producto.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.') ) * parseFloat(this.data.cant_producto.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'));

          this.ELEMENT_DATA[i].requerido_mp = xvalor.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      }
        this.forma.value.ord_producto = this.ELEMENT_DATA;
        console.log(this.forma.value);
        this.dialogRefp.close(this.forma.value);

      }
    });
  }else{
    this.snackBar.open('Materia prima insuficiente para la fabricacion de este producto', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
} */

modalrespuesta(){
   
  var split_mp = this.inv_mp.nativeElement.value.split('-');
  this.ELEMENT_DATA3[0].inventario_mp = split_mp[0];
  this.ELEMENT_DATA3[0].des_deposito = split_mp[1];
 this.servicio.validarInventarioProdFormulaMpphttp(this.ELEMENT_DATA3[0]).subscribe(
   response =>{
     console.log(response,'imventario');
       this.ELEMENT_DATA = [];
       this.ELEMENT_DATA2 = [];
           for (const i in response.producto1) {
              ///////////FORMULA//////////////
             this.ELEMENT_DATA.push({
               cod_producto:response.producto1[i].cod_prod_mp,
               des_producto:response.producto1[i].des_prod_mp,
               cant_producto:response.producto1[i].cantidad_prod_mp,
               requerido_mp:''
             });
           }
           for (const i in response.producto2) {
            ///////////////INVENTARIO//////////////////////
            this.ELEMENT_DATA2.push({
              cod_producto2:response.producto2[i].cod_producto,
              des_producto2:response.producto2[i].des_producto,
              cant_producto2:response.producto2[i].cant_producto
            });
          }
  
  var key = true;
  var xvalor;

    for (const i in this.ELEMENT_DATA) {
      
      xvalor = parseFloat(this.ELEMENT_DATA[i].cant_producto.replace('.', '')
      .replace('.', '')
      .replace('.', '')
      .replace(',', '.') ) * parseFloat(this.data.cant_producto.replace('.', '')
      .replace('.', '')
      .replace('.', '')
      .replace(',', '.'));
      console.log(xvalor,'this.ELEMENT_DATA2');
      console.log(this.ELEMENT_DATA2[i].cant_producto2,'this.ELEMENT_DATA2');
    if ( xvalor > parseFloat(this.ELEMENT_DATA2[i].cant_producto2.replace('.', '')
    .replace('.', '')
    .replace('.', '')
    .replace(',', '.')) ||  this.ELEMENT_DATA2[i].cant_producto2 === null ||  this.ELEMENT_DATA2[i].cant_producto2 === "") {
      key = false;
      break;
    }
  }
  if (key) {
    this.accion = "enviarordenprod";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
        maxWidth: '100vw',
        height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        for (const i in this.ELEMENT_DATA) {
          xvalor = parseFloat(this.ELEMENT_DATA[i].cant_producto.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) * parseFloat(this.data.cant_producto.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.'));

          this.ELEMENT_DATA[i].requerido_mp = xvalor.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      }
      this.forma.value.ord_producto2 = this.ELEMENT_DATA3;
        this.forma.value.ord_producto = this.ELEMENT_DATA;
        console.log(this.forma.value,'forma del objeto');
        this.dialogRefp.close(this.forma.value);

      }
    });
  }else{
    this.snackBar.open('Materia prima insuficiente para la fabricacion de este producto', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
   },
   error =>{
     console.log(<any>error.error.message);
      this.msg = <any>error.error.message;
      this.snackBar.open(this.msg, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
   }
 );
}

buscarDespositoFormula(xvalor:any){
  var valor = {cod_producto:xvalor}
 this.servicio.buscarDespositoFormulaMpphttp(xvalor).subscribe(
   response =>{
     console.log(response,'depositos');
    
     this.MP = response.mp;
    
     this.stateGroupOptions4 = this.forma.get('inv_mp')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this.filterGroup(value))
                                );
                  for (const i in response.mp) {
                    this.producto.push(response.mp[i].cod_deposito);
                    this.codigo.push(response.mp[i].cod_deposito+'-'+response.mp[i].des_deposito);
                  }
                 
                  this.stateGroups4= [{
                    letter: 'Depositos',
                    names: this.producto,
                    code: this.codigo
                  }];

   },
   error =>{
     console.log(error);
     console.log(<any>error.error.message);

     this.dataSource1 = new MatTableDataSource<any>();
     this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
       verticalPosition: this.verticalPosition});
   }
 );
}


cerrarmodal(): void {
  this.accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100%',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }

  });

}
cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 2000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }


async detalleOrdenProdMpp(xvalor:any, termino:any){
  var key = false;
  switch (termino) {
        case 'MP':
          if (this.forma.value.inv_mp) {
            var split = this.inv_mp.nativeElement.value.split('-');
            xvalor.deposito = split[0];
            key = true;
          }
        break;
    default:
      break;
  }
  if (key) {
    this.dialog.open(FormConsultarFormulaMppComponent, {
      width: '100vw',
        maxWidth: '100vw',
        height:'100%',
      data: {producto: xvalor,
        cod_producto_pt:this.data.cod_producto,
        cant_producto_pt:this.data.cant_producto},
      panelClass: 'pedidos_modal3',
      backdropClass: "bdrop"
    });
  }else{
    this.snackBar.open('Inventario no asignado o no seleccionado en esta orden de producción', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

}

