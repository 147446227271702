import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-seguimiento-ordenes-mpp',
  templateUrl: './seguimiento-ordenes-mpp.component.html',
  styleUrls: ['./seguimiento-ordenes-mpp.component.css']
})
export class SeguimientoOrdenesMppComponent implements OnInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  dialogSpin:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

  }

  ngOnInit(): void {
    this.listarSegOrdenesProdMpp(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }

  /////////////////////////////////////////////////////////////////////
  listarSegOrdenesProdMpp(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        var valor = {termino:true}
        this.servicio.listarSegOrdenesProdMpphttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
    
                    this.ELEMENT_DATA.push({
                      cod_orden_prod_mpp:response[i].cod_orden_prod_mpp,
                      estatus_actual:response[i].estatus_actual,
                      estatus_cambio: response[i].estatus_cambio,
                      cod_usuario_ini:response[i].cod_usuario_ini,
                      des_usuario_ini:response[i].des_usuario_ini,
                      tarea_ord:response[i].tarea_ord,
                      fecha_ini: response[i].fecha_ini,
                      hora_ini:response[i].hora_ini,
                      cod_usuario_fin:response[i].cod_usuario_fin,
                      des_usuario_fin:response[i].des_usuario_fin,
                      fecha_fin:response[i].fecha_fin,
                      hora_fin:response[i].hora_fin,
                      estatus_tarea:response[i].estatus_tarea,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.ELEMENT_DATA.sort();
                  this.displayedColumns = [ 'cod_orden_prod_mpp',
                                            'estatus_actual',
                                            'estatus_cambio',
                                            'cod_usuario_ini',
                                            'des_usuario_ini',
                                            'tarea_ord',
                                            'fecha_ini',
                                            'hora_ini',
                                            'cod_usuario_fin',
                                            'des_usuario_fin',
                                            'fecha_fin',
                                            'hora_fin',
                                            'estatus_tarea'
                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [ 'cod_orden_prod_mpp',
                                            'estatus_actual',
                                            'estatus_cambio',
                                            'cod_usuario_ini',
                                            'des_usuario_ini',
                                            'tarea_ord',
                                            'fecha_ini',
                                            'hora_ini',
                                            'cod_usuario_fin',
                                            'des_usuario_fin',
                                            'fecha_fin',
                                            'hora_fin',
                                            'estatus_tarea'
                                          ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    
  }

  cantidad_item() {
    try {
      console.log(this.ELEMENT_DATA);
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }

  modalrespuesta(obj:any, text:any){
    this.accion = text;
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '24%',
        height:'35%',
        data: {id:obj.cod_cliente,
              accion:this.accion},
        disableClose: true,
        panelClass: 'elem_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log('The dialog was closed');
          console.log(result);


        }
      });
  }

  editardatos(obj:any,text:any){
    var dialogRef:any;
    this.accion = text;
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    if (this.accion === 'actualizar') {
      dialogRef = this.dialog.open(FormOrdenesprodComponent, {
        width: '100%',
        height:'90%',
        data: {cod_lista_precio_producto:obj.cod_lista_precio_producto,
                cod_cliente:obj.cod_cliente,
                des_cliente:obj.des_cliente,
                cod_usuario:datos,
                des_usuario: nombre,
                fecha_inicio:obj.fecha_inicio,
                tasa_precio:obj.tasa_precio,
                tipo_lista:obj.tipo_lista,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
    }else{
      dialogRef = this.dialog.open(FormOrdenesprodComponent, {
        width: '100%',
        height:'91%',
        data: {cod_lista_precio_producto:'',
                cod_usuario:datos,
                des_usuario: nombre,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
    }

      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);

     this.gestionOrdenProd(result);
        }
      });
  }

public  gestionOrdenProd(xvalor:any){
  console.log(xvalor);
  var resultado = this.servicio.gestionOrdenProdhttp(xvalor).subscribe(
     response =>{
       console.log(response.resul,'una respuesta');
       if(response.resul){
         console.log(response.resul,'una respuesta');
         this.xobj2 = response;
         this.ELEMENT_DATA = [];
             for (const i in response.resul) {
                 console.log(response[i]);

               this.ELEMENT_DATA.push({
                cod_orden_producto:response.resul[i].cod_orden_producto,
                cod_usuario:response.resul[i].cod_usuario,
                des_usuario: response.resul[i].des_usuario,
                estatus:response.resul[i].estatus,
                fecha_inicio:response.resul[i].fecha_inicio,
                hora_inicio:response.resul[i].hora_inicio,
                cod_usuario_cierre: response.resul[i].cod_usuario_cierre,
                des_usuario_cierre:response.resul[i].des_usuario_cierre,
                fecha_fin:response.resul[i].fecha_fin,
                hora_fin:response.resul[i].hora_fin,
                accion:'',
                accion2:'',
                accion3:''
              });
             }
             this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'detalle',
                                        'Actualizar',
                                        'confirmar'
                                      ];
              /* this.dataSource = this.ELEMENT_DATA; */
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
             this.snackBar.open(response.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
       }
     },
     error =>{
       console.log(<any>error.error.message);
       this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'detalle',
                                        'Actualizar',
                                        'confirmar'
                                      ];
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   );

   return resultado;
 }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async detalleOrdenProd(xvalor:any){
      this.dialog.open(FormConsultaOrdenesComponent, {
        width: '100%',
        height:'99%',
        data: {cod_orden_producto: xvalor.cod_orden_producto},

        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });

 }
 async confirmacionOrdenProd(xvalor:any){
  this.dialog.open(FormConfirmacionInvMpComponent, {
    width: '100%',
    height:'90%',
    data: {cod_orden_producto: xvalor.cod_orden_producto},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });

}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

}










