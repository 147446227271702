<div id="modal_carrito" class=" container-fluid" style="border-radius: 1rem;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #c41919; background-color: rgb(61, 90, 184); border-radius: 1rem;">
            <div id="content_1" class="col-md-1 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 1rem; border: 2px solid #374fd6; background-color:rgb(61, 90, 184);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Lista N°: </mat-label>
                        <input style="font-size: 11px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_2" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:rgb(61, 90, 184);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Tasa del dia</mat-label>
                        <input style="font-size: 11px; color: #fff; " class="text-end" #tasa_base (keyup)="format(tasa_base)" maxlength="17" matInput placeholder="" autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        Bs.
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Productos</mat-label>
                        <input style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup2" #cod_producto>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                        <mat-autocomplete #autoGroup2="matAutocomplete">
                            <mat-optgroup *ngFor="let group of arry | async" [label]="group.letter">
                                <mat-option style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                    {{i}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>

                </div>
            </div>
            <div class="col-md-1 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Precio</mat-label>
                        <input style="font-size: 11px; color: #fff;" maxlength="6" (keyup)="format(precio_base )" type="text " matInput placeholder="" formControlName="precio_base" #precio_base autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">$</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6a9;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Buscar</mat-label>
                        <input style="font-size: 11px; color: #fff;" #cod_lista1 matInput maxlength="6" placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" style="color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                      <mat-icon >add_shopping_cart</mat-icon>
                    </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="cambioPrecio()" matTooltip="RECALCULAR SU LISTA" style="  color:rgb(255, 255, 255); background-color: rgb(223, 14, 205);">
                    <mat-icon >credit_card</mat-icon>
                  </button>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">

                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GUARDAR LISTA" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                  <mat-icon >save</mat-icon>
                </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row" style=" border: 2px solid #374fd6;">
            <div class="col-md-12 px-0  border h-100" style="background-color: rgb(61, 90, 184);">
                <table #table mat-table [dataSource]="dataSource1" class="table-primary text-center table-striped table-condensed table-hover">

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(61, 90, 184);">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style=" padding: 5px ; background-color: rgb(61, 90, 184); color: #fff;">{{cantidad_item()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(61, 90, 184);">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.des_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(61, 90, 184); color: #fff;"> Lista de precios para : {{data.des_cliente}}</td>
                    </ng-container>

                    <ng-container matColumnDef="estatus_fiscal">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(61, 90, 184);">
                            <h5><strong>Estatus fiscal</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.estatus_fiscal}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(61, 90, 184); color: #fff;">PRODUCTOS ASIGNADOS </td>
                    </ng-container>

                    <ng-container matColumnDef="precio_producto_base">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(61, 90, 184);">
                            <h5><strong>Precio base</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.precio_producto_base}}</td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(61, 90, 184); color: #fff;"> {{cantidad_asignado()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="precio_producto_base_bs">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(61, 90, 184);">
                            <h5><strong>Precio Bs</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.precio_producto_base_bs}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(61, 90, 184); color: #fff;"> {{cantidad_asignado()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="update_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(61, 90, 184);">
                            <h5><strong>Editar</strong></h5>
                        </th>
                        <td [ngClass]="{'resaltar_precio_asignado': element.observador} " mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="modalmodificarprecios(element)" matTooltip="CAMBIAR EL PRECIO BASE" mat-raised-button style="height: 35px; border: 2px solid rgb(48, 18, 182); color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);">
                              <mat-icon >sync</mat-icon>
                              </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(61, 90, 184); color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
    </form>

</div>