import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormCantDevComponent } from '../form-cant-dev/form-cant-dev.component';

import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-nota-credito',
  templateUrl: './form-nota-credito.component.html',
  styleUrls: ['./form-nota-credito.component.css']
})
export class FormNotaCreditoComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('producto_pedido') producto_pedido:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('num_dev',{static:true}) num_dev:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('lote_producto',{static:true}) lote_producto:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('cod_lista_precio',{static:true}) cod_lista_precio:ElementRef;
  @ViewChild('iva',{static:true}) iva:ElementRef;
  @ViewChild('items',{static:true}) items:ElementRef;
  @ViewChild('desc_pedido_monto',{static:true}) desc_pedido_monto:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('exento_monto',{static:true}) exento_monto:ElementRef;
  @ViewChild('dev_producto',{static:true})dev_producto:ElementRef;

  stateGroupOptions: Observable<StateGroup[]>;
  stateGroupOptions2: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
lista:any;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
tipo_cliente:any;
unidad:any;
unidadp:any = [];
arry:any = [];
dialogSpin:any;
menu:any;
key:any = false;
mensaje:any;
producto:any = [];
codigo:any = [];
codigprod:any;
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormNotaCreditoComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

      this.forma = fb.group({
        'id_pedido':data.id,
        'cod_cliente':data.cod_cliente,
        'des_cliente':data.des_cliente,
        'tipo_cliente':data.tipo_cliente,
        'cod_usuario':'',
        'des_usuario':'',
        'estatus_cliente':data.estatus_cliente,
        'estatus_pedido':data.estatus_pedido,
        'estatus_fiscal':'',
        'tipo2_cliente':data.tipo2_cliente,
        'credito_dias_cliente':data.credito_dias_cliente,
        'reten_cliente':data.reten_cliente,
        'comercializacion_pedido':data.comercializacion,
        'producto_pedido':'',
        'datos_nota':'',
        'lote_producto':'',
        'dispo_pedido':'',
        'cantidad_pedido':'',
        'cod_factura':data.cod_factura,
        'cod_dev':data.cod_dev,
        'iva_exento':'0,00',
        'monto_exento':'0,00',
        'subtotal_pedido':'0,00',
        'total_pedido':'0,00',
        'iva_pedido':'16',
        'iva_monto':'0,00',
        'desc_pedido':'0,00',
        'desc_pedido_monto':'0,00',
        'tipo_pedido':'F',
        'cod_lista_precio':'',
        'dev_producto':'',
        'dev_subtotal_producto':'',
        'cod_control':'',
        'tipo_nota':'dev',

      });

      this.stateForm = this.formb.group({
        stateGroup: '',
      });
    }

  ngOnInit(): void {
    this.buscarContadorFacturaNotaCredito(this.data);
    this.formulario();
  }
  ngAfterViewInit() {

  }
  formulario():void{
    /* this.ELEMENT_DATA = []; */
    this.cant = false;
    this.displayedColumns = [
      'cod_producto',
      'des_producto',
      'estatus_fiscal',
      'contador',
      'cod_dev',
      'cant_producto',
      'costo_producto',
      'descuento',
      'monto_prod_descuento',
      'dev_subtotal_producto',
     ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<PeriodicElementfacturas>(this.ELEMENT_DATA);
  }
  public buscarContadorFacturaNotaCredito(xvalor:any){
    var valor = {cod_factura:xvalor.cod_factura, 
      cod_pedido:xvalor.cod_pedido,
      tipo:xvalor.tipo, 
      cod_dev:this.data.cod_dev};
    try {
      this.servicio.buscarContadorFacturaNotaCreditohttp(valor).subscribe(
        response =>{
          console.log(response);
         
        for (const i in response.resul) {
          for (const k in response.resul3) {
            if (response.resul3[k].cod_producto === response.resul[i].cod_producto) {
              response.resul[i].desc_prod_monto = (parseInt(response.resul3[k].cant_producto.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) * parseFloat(response.resul[i].costo_producto.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) * parseFloat(response.resul[i].desc_prod.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) / 100);

              this.ELEMENT_DATA.push({
                cod_producto:response.resul[i].cod_producto,
                des_producto:response.resul[i].des_producto,
                cod_lista_precio:response.resul[i].cod_lista_precio_producto,
                cant_producto:response.resul3[k].cant_producto,
                cod_dev:response.resul3[k].cod_dev,
                contador:response.resul[i].cant_producto,
                costo_producto:response.resul[i].costo_producto,
                tipo_producto:response.resul[i].tipo_producto,
                desc_prod:response.resul[i].desc_prod,
                desc_prod_monto:response.resul[i].desc_prod_monto.toFixed(2).toString().replace(/\D/g, "")
                                                                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                subtotal_producto: response.resul[i].subtotal_producto,
                tamano_producto:response.resul[i].tamano_producto,
                estatus_fiscal:response.resul[i].estatus_fiscal,
                dev_producto:'0',
                dev_subtotal_producto:'0,00'
              });
            }
          } 
        }
        console.log(response.resul2);
        this.items.nativeElement.value = this.ELEMENT_DATA.length;
        this.desc_pedido.nativeElement.value = response.resul2[0].desc_pedido;
        this.exento_monto.nativeElement.value = '0,00';
        this.subtotal_pedido.nativeElement.value = '0,00';
        this.iva_monto.nativeElement.value = '0,00';
        this.desc_pedido_monto.nativeElement.value = '0,00';
        this.total_pedido.nativeElement.value = '0,00';
        this.cod_lista_precio.nativeElement.value = response.resul2[0].cod_lista_precio;
        this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);

        for (const i in this.ELEMENT_DATA) {
          this.tomar_data(this.ELEMENT_DATA[i]);
        }

        this.lista = true;
        },
        error =>{
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
          this.lista = false;
        }
      );

    } catch (error) {
      this.snackBar.open(error.toString(), 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  tomar_data(xvalor2:any){
   try {
    var resul_final;
    var resul_final2;
    var dialogRef
    var resul1:any= '0';
    var resul2:any;
    var descuento_prod:any;
    var total1:any;
    var final:any;
    if (xvalor2.estatus_fiscal !== null && xvalor2.estatus_fiscal !== '' && xvalor2.estatus_fiscal !== undefined) {
      console.log(xvalor2);
      
             if (parseFloat(xvalor2.cant_producto.replace('.','')
                                                  .replace('.','')
                                                  .replace('.','')
                                                  .replace(',','.')) <=  parseFloat(xvalor2.contador.replace('.','')
                                                  .replace('.','')
                                                  .replace('.','')
                                                  .replace(',','.'))) {
               resul1 =  parseFloat(xvalor2.cant_producto.replace('.','')
                                                          .replace('.','')
                                                          .replace('.','')
                                                          .replace(',','.')) * parseFloat(xvalor2.costo_producto.replace('.','')
                                                          .replace('.','')
                                                          .replace('.','')
                                                          .replace(',','.'));

                      var monto_prod_descuento = parseFloat(xvalor2.desc_prod_monto.replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                                                            .replace(',','.'));

                                              if (resul1 > monto_prod_descuento) {
                                                       resul1 = resul1 - monto_prod_descuento;
                                              }else{
                                                      resul1 = monto_prod_descuento - resul1;
                                              }

             for (const i in this.ELEMENT_DATA) {
               if (this.ELEMENT_DATA[i].cod_producto === xvalor2.cod_producto && this.ELEMENT_DATA[i].cod_dev === xvalor2.cod_dev) {

                   this.ELEMENT_DATA[i].dev_producto = xvalor2.cant_producto;
                   this.ELEMENT_DATA[i].dev_subtotal_producto = parseFloat(resul1).toFixed(2).toString().replace(/\D/g, "")
                                                                                            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
               }
             }

               if (this.arry.length === 0) {

                 this.arry.push(xvalor2);
                 console.log(resul1);
                 let porcentaje = (resul1  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                                                                                            .replace('.','')
                                                                                            .replace('.','')
                                                                                            .replace('.','')
                                                                                            .replace('.','')
                                                                                            .replace(',','.'))/100);
       console.log(porcentaje);
       let monto_sub = resul1 - porcentaje;

       this.subtotal_pedido.nativeElement.value = resul1.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

       let total = (monto_sub * parseFloat(this.iva.nativeElement.value) / 100);

       this.iva_monto.nativeElement.value = total.toFixed(2).replace(/\D/g, "")
                                            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

       let montoTotal = monto_sub + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
       .replace('.','')
       .replace('.','')
       .replace('.','')
       .replace('.','')
       .replace(',','.'));

                 console.log(porcentaje);
       this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

       if (xvalor2.estatus_fiscal.toUpperCase() === 'XCNT') {

         this.exento_monto.nativeElement.value = monto_sub.toFixed(2).replace(/\D/g, "")
         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

         var iva_exento = monto_sub * parseFloat(this.iva.nativeElement.value) / 100;

         var iva_menos_exento = parseFloat(this.iva_monto.nativeElement.value.replace('.','')
         .replace('.','')
         .replace('.','')
         .replace('.','')
         .replace('.','')
         .replace(',','.')) - iva_exento;

         this.iva_monto.nativeElement.value = iva_menos_exento.toFixed(2).replace(/\D/g, "")
         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

         var resta_iva = montoTotal - iva_exento;

         /* var porcentaje_exento = (resta_iva  *  parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
         .replace('.','')
         .replace('.','')
         .replace('.','')
         .replace('.','')
         .replace(',','.'))/100);

         this.desc_pedido_monto.nativeElement.value = porcentaje_exento.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");  */

         resul_final = resta_iva ;

       }else{

          resul_final = montoTotal;
       }
         console.log(resul_final);
       this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


               }else{
                 this.arry.push(xvalor2);

                 let porcentaje = (resul1  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.'))/100);

                 let monto_sub = resul1 - porcentaje;

                this.subtotal_pedido.nativeElement.value = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.'))) + (monto_sub);

                this.subtotal_pedido.nativeElement.value = parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                let total = parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) * parseFloat(this.iva.nativeElement.value) / 100;

                this.iva_monto.nativeElement.value = total.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                 let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.')));

                 let suma_descuentos = (parseFloat(this.desc_pedido_monto.nativeElement.value.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.') ) + porcentaje);


                       this.desc_pedido_monto.nativeElement.value = suma_descuentos.toFixed(2).replace(/\D/g, "")
                       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                       if (xvalor2.estatus_fiscal.toUpperCase() === 'XCNT') {

                         var sumar_exento = monto_sub + parseFloat(this.exento_monto.nativeElement.value.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.'));

                         this.exento_monto.nativeElement.value = sumar_exento.toFixed(2).replace(/\D/g, "")
                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                         var iva_exento = parseFloat(this.exento_monto.nativeElement.value.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.')) * parseFloat(this.iva.nativeElement.value) / 100;

                         var iva_menos_exento = parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.')) - iva_exento;

                         this.iva_monto.nativeElement.value = iva_menos_exento.toFixed(2).replace(/\D/g, "")
                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                         let montoTotal_especial = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace(',','.')));

                         /* let porcentaje_especial = (montoTotal_especial  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.'))/100);

                          this.desc_pedido_monto.nativeElement.value = porcentaje_especial.toFixed(2).replace(/\D/g, "")
                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".") */

                         var resta_iva = montoTotal - iva_exento;

                         resul_final = resta_iva;

                       }else{

                         if (parseFloat(this.exento_monto.nativeElement.value.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.')) > 0) {

                           var iva_exento_restar = parseFloat(this.exento_monto.nativeElement.value.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.')) * parseFloat(this.iva.nativeElement.value) / 100;

                         var iva_exento_restar_format = iva_exento_restar.toFixed(2).replace(/\D/g, "")
                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                         var iva_menos_exento_restar = parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.')) - parseFloat(iva_exento_restar_format.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.'));

                         this.iva_monto.nativeElement.value = iva_menos_exento_restar.toFixed(2).replace(/\D/g, "")
                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                         let montoTotal_especial = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace('.','')
                           .replace(',','.')));

                           var monto_especial_format = montoTotal_especial.toFixed(2).replace(/\D/g, "")
                           .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                           .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                         /* let porcentaje_especial = (parseFloat(monto_especial_format.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.'))  *  parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.'))/100);

                         console.log(monto_especial_format);

                         this.desc_pedido_monto.nativeElement.value = porcentaje_especial.toFixed(2).replace(/\D/g, "")
                         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".") */

                         var resta_iva_restar = (parseFloat(monto_especial_format.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.'))) - parseFloat(iva_exento_restar_format.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.'));

                         console.log(resta_iva_restar);

                         resul_final = montoTotal_especial;
                         }else{
                           console.log('aqui entro')
                           resul_final = montoTotal ;
                         }

                       }

                this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

               }
             }else{
               this.snackBar.open('Este producto ya fue devuelto en su totalidad', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
             }
   
  console.log(this.arry);
    }else{
      this.snackBar.open('El producto no tiene definido el estatus fiscal', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
   } catch (error) {
     console.log(error);
   }
  }

  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
    }else{
    console.log(this.forma.value);
    this.dialogRefp.close(this.forma.value);
    }
  }

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource1.filter = filterValue.trim().toLowerCase();
}

cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_producto)
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.')) ;
       });

     return suma;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
  }

  cantidad_dev() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].dev_producto)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });

       return suma;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    }

    cantidad_dev_sub() {
      try {
        var suma = 0;
        var matriz = [];
        for (const i in this.ELEMENT_DATA) {
          matriz.push(this.ELEMENT_DATA[i].dev_subtotal_producto)
        }
        matriz.forEach (function(numero){
               suma += parseFloat(numero.replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace(',','.')) ;
           });

         return suma.toFixed(2).replace(/\D/g, "")
         .replace(/([0-9])([0-9]{2})$/, '$1,$2')
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      }

  costo_producto() {

    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].costo_producto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });

       return suma.toFixed(3).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{3})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  sub_total_producto() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].subtotal_producto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });

       return suma.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  descuento() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].desc_prod)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });

       return suma.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  descuento_monto() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].desc_prod_monto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });

       return suma.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  modalrespuesta(){
    try {
      var xvalor = {
        val_def : 'F',
        tipo_operacion : 'NOTASDEV',
        cod_usuario : localStorage.getItem('cod_usuario')
      };
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response=>{
          if (this.arry.length === 0) {
            this.snackBar.open('No haz seleccionado ningun producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }else{
            this.accion = this.data.tipo;
            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
              data: {id:'',
                    accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(result => {
      
              if(result != 'cerrar'){
      
                    console.log(result);
                          let cod_usuario = localStorage.getItem('cod_usuario');
                          let nombre = localStorage.getItem('Nombre');
                          let apellido = localStorage.getItem('Apellido');
      
                          this.forma.value.cod_pedido = this.data.cod_pedido;
                          this.forma.value.cod_usuario = cod_usuario;
                          this.forma.value.des_usuario = nombre +' '+ apellido;
                          this.forma.value.cod_control = result;
      
                          this.forma.value.producto_pedido = this.arry;
                          this.forma.value.iva_monto = this.iva_monto.nativeElement.value;
                          this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                          this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                          this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                          this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                          this.forma.value.desc_pedido_monto = this.desc_pedido_monto.nativeElement.value;
                          this.forma.value.monto_exento = this.exento_monto.nativeElement.value;
      
                          this.forma.value.desc_pedido_monto = this.desc_pedido_monto.nativeElement.value;
                          this.forma.value.monto_exento = this.exento_monto.nativeElement.value;
                          console.log(this.forma.value);
                        this.dialogRefp.close(this.forma.value);
      
                /* let resultado = this.eliminarunidad(result.id); */
              }
            });
          }
        },
        error=>{
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
