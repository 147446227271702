<div class="container-fluid mat-elevation-z8" *ngIf="accion == 'insertar'" style="height: 100%; padding: 5%;">
    <form [formGroup]="forma" class="materialformtamaño">
        <div class="row">
            <div class="col-md-12 ">
                Productos MPP Factory Cosmetics.
                <hr>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Codigo del producto</mat-label>
                        <input matInput placeholder="Codigo del producto" autocomplete="off" formControlName="cod_producto">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>place</mat-icon>
                    </span>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Descripcion del producto</mat-label>
                        <textarea matInput placeholder="Descripcion del producto" autocomplete="off" formControlName="des_producto"> </textarea>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">

            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="estatus" placeholder="Estatus" formControlName="estatus_producto" autocomplete="off">
                            <mat-option value="A">A (Activo)</mat-option>
                            <mat-option value="I">I (Inactivo)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-3">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Peso</mat-label>
                        <input matInput placeholder="Pais" autocomplete="off" formControlName="unidad_medida_producto">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>place</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 ">
                <p>
                    <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="modalrespuesta()" [disabled]="forma.invalid" mat-raised-button color="accent">Actualizar</button>
                    <button *ngIf="accion == 'insertar'" class="btn-block" (click)="modalrespuesta()" [disabled]="forma.invalid" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
                </p>
            </div>
        </div>

    </form>
</div>


<!-- FORMULARIO PARA ACTUALIZAR CONDICIONADO -->

<div class="container-fluid mat-elevation-z8" *ngIf="accion == 'actualizar'" style="height: 100%; padding: 5%;">
    <form [formGroup]="forma" class="materialformtamaño">
        <div class="row">
            <div class="col-md-12 ">
                Productos MPP Factory Cosmetics.
                <hr>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Codigo del producto</mat-label>
                        <input matInput placeholder="Codigo del producto" autocomplete="off" formControlName="cod_producto" readonly>
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>place</mat-icon>
                    </span>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Descripcion del producto</mat-label>
                        <textarea matInput placeholder="Descripcion del producto" autocomplete="off" formControlName="des_producto"> </textarea>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">

            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="estatus" placeholder="Estatus" formControlName="estatus_producto" autocomplete="off">
                            <mat-option value="A">A (Activo)</mat-option>
                            <mat-option value="I">I (Inactivo)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-3">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Peso</mat-label>
                        <input matInput placeholder="Pais" autocomplete="off" formControlName="unidad_medida_producto">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>place</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 ">
                <p>
                    <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="modalrespuesta()" [disabled]="forma.invalid" mat-raised-button color="accent">Actualizar</button>
                    <button *ngIf="accion == 'insertar'" class="btn-block" (click)="modalrespuesta()" [disabled]="forma.invalid" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
                </p>
            </div>
        </div>

    </form>
</div>