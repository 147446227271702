<br>
<!-- FORMULARIO PARA Crear Producto de Materia Prima -->
<form [formGroup]="forma" class="materialformtamaño" *ngIf="accion == 'insertar'">
    <div class="row">
        <div class="col-md-12 ">
            <p>
                <strong>Inventario de MP</strong>
            </p>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Codigo</mat-label>
                    <input matInput placeholder="Codigo" formControlName="cod_producto" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>code</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-select name="Estatus" placeholder="Estatus" formControlName="estatus_producto" autocomplete="off">
                        <mat-option value="A">Activo</mat-option>
                        <mat-option value="I">Inactivo</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
        </div>        
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Descripcion</mat-label>
                    <input matInput placeholder="Descripcion" formControlName="des_producto" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>description</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>        
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Cantidad</mat-label>
                    <input matInput placeholder="Cantidad" formControlName="cant_producto" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>add_circle</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>               
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Costo</mat-label>
                    <input matInput placeholder="Costo" formControlName="cost_producto" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>attach_money</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>        
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-select name="Tipo de Producto" placeholder="Tipo de Producto" formControlName="tipo_producto_mp" autocomplete="off">
                        <mat-option value="MP">Materia Prima</mat-option>
                        <mat-option value="ME">Material de Empaque</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
        </div>        
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-select name="Unidad de Medida" placeholder="Unidad de Medida" formControlName="unidad_medida" autocomplete="off">
                        <mat-option value="c">Cantidad</mat-option>
                        <mat-option value="g">Gramo</mat-option>
                        <mat-option value="l">Litro</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
        </div>       
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>
                <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="actualizardatos()" mat-raised-button color="accent">Actualizar</button>
                <button *ngIf="accion == 'insertar'" class="btn-block" (click)="actualizardatos('insertar')" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
            </p>
        </div>
    </div>

</form>