<div class="fadeIn2 animated fast">
    <nav class="navbar navbar-expand-lg " style="height: 7%; border:1px solid #cccccccc ;">

        <div class=" navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <div class="mat-elevation-z8" style="margin: 1px 0px 1px 0px; background-color: steelblue; border-radius: 10px;">
                    <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color=""><strong>INV. PRODUCTOS ASEGURADOS</strong> <mat-icon style="margin: 0% 0% 0% 4%;">done_all</mat-icon></button></li>
                </div>
                <div class="mat-elevation-z8" style="margin: 1px 0px 1px 5px; border-radius: 10px;">
                    <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color="accent" (click)='listarInventarioSG("actualizar")'>Actualizar lista<mat-icon style="margin: 0% 0% 0% 4%;">sync</mat-icon></button></li>
                </div>
                <div class="mat-elevation-z8" style="margin: 1px 0px 1px 5px; border-radius: 10px;">
                    <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color="accent">Seguimiento<mat-icon style="margin: 0% 0% 0% 4%;">search</mat-icon></button></li>
                </div>
                <div class="mat-elevation-z8" style="margin: 1px 0px 1px 5px; border-radius: 10px;">
                    <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color="accent">Imprimir<mat-icon style="margin: 0% 0% 0% 4%;">print</mat-icon></button></li>
                </div>
                <div class="mat-elevation-z8" style="margin: 1px 0px 1px 5px; border-radius: 10px;">
                    <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color="accent">Descargar<mat-icon style="margin: 0% 0% 0% 4%;">get_app</mat-icon></button></li>
                </div>

            </ul>
            <div class=" form-group mat-elevation-z8 " style=" margin: 1px 0px 1px 0px; width:200px ;padding: 0px 10px 10px 10px; height: 40px;">
                <mat-form-field class="materialinput" style="padding: 0px; margin: 0px 0px 0px 0px;">
                    <mat-label>Buscar</mat-label>
                    <input #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
              <mat-icon>search</mat-icon>
              </span>
                </mat-form-field>
            </div>
        </div>
    </nav>
    <div class="container-fluid">
        <div class="">
            <div class="col-md-12 col-sm-12 col-xs-12 ">
                <br>
                <div class="example-container mat-elevation-z8 table-responsive">

                    <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-bordered table-condensed table-hover">
                        <!-- Weight Column -->
                        <ng-container matColumnDef="cod_inv">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Cod Inv </strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_inv}} </td>
                        </ng-container>

                        <ng-container matColumnDef="des_inv">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Inventario </strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_inv}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cod_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Cod producto </strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_producto}} </td>
                        </ng-container>

                        <ng-container matColumnDef="des_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Producto </strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_producto}} </td>
                        </ng-container>

                        <ng-container matColumnDef="lote_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Lote </strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.lote_producto}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cant_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Cantidad </strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_producto}} </td>
                        </ng-container>

                        <ng-container matColumnDef="estatus_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Estatus</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus_producto}} </td>
                        </ng-container>

                        <ng-container matColumnDef="traslados">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Traslados</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;" (click)='traslados(element)'>
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-raised-button color="accent" aria-label="Example icon button with a delete icon">
                                      <mat-icon matTooltip="TRASLADOS DE INVENTARIO">swap_horiz</mat-icon>
                                    </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="detalle">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Detalle</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;" (click)='detallepedido(element)'>
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                    <mat-icon>image_search</mat-icon>
                                  </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="imprimir">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Imprimir</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;" (click)='detallepedido(element)'>
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                    <mat-icon>print</mat-icon>
                                  </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="descargar">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Descargar</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;" (click)='detallepedido(element)'>
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                    <mat-icon>get_app</mat-icon>
                                  </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Actualizar">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                                <h5><strong>Editar</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center" (click)='editardatos(element, "actualizar")' style="padding: 5px;">
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="" style="margin: 1%;" aria-label="Example icon button with a delete icon">
                                    <mat-icon>sync</mat-icon>
                                  </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 7, 9]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>

    </div>

    <!--    <div class="container">
      <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
              <table class="table table-condensed">
                  <thead class="">
                      <th>NOMBRE</th>
                      <th>APELLIDO</th>
                      <th>DIRECCION</th>
                      <th>E-MAIL</th>
                      <th>ELIMINAR</th>
                      <th>ACTUALIZAR</th>
                  </thead>
                  <tbody>
                      <tr *ngFor="let i of obj" class="" style="font-size:120%;">
                          <td *ngIf="i.id">{{i.nombre_usu}} </td>
                          <td *ngIf="i.id">{{i.apellido_usu}}</td>
                          <td *ngIf="i.id">{{i.direccion_usu}}</td>
                          <td *ngIf="i.id">{{i.correo_usu}}</td>
                          <td *ngIf="i.id"><button (click)='eliminardatos(i)' class="btn btn-outline-light btn-block">Eliminar</button></td>
                          <td *ngIf="i.id"><button (click)='editardatos(i)' class="btn btn-outline-light btn-block">Editar</button></td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="col-md-1"></div>
      </div>
  </div> -->
</div>
