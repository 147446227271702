import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import * as fs from 'file-saver';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormPreciosComponent } from 'src/app/componentes/shared/modal/form-precios/form-precios.component';
import { FormConsultaListaComponent } from 'src/app/componentes/shared/modal/form-consulta-lista/form-consulta-lista.component';
import { FormPreciosActualizarComponent } from 'src/app/componentes/shared/modal/form-precios-actualizar/form-precios-actualizar.component';
import { FormInvSplComponent } from 'src/app/componentes/shared/modal/form-inv-spl/form-inv-spl.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { forIn } from 'lodash';
import { FormDepositosComponent } from 'src/app/componentes/shared/modal/form-depositos/form-depositos.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { Workbook } from 'exceljs';
import { FormReportesNotasDebitoComponent } from 'src/app/componentes/shared/modal/form-reportes-notas-debito/form-reportes-notas-debito.component';
import { FormTrasladosDepComponent } from 'src/app/componentes/shared/modal/form-traslados-dep/form-traslados-dep.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-depositos',
  templateUrl: './depositos.component.html',
  styleUrls: ['./depositos.component.css']
})
export class DepositosComponent implements OnInit {

  fecha1:any;
  fecha2:any;
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  DES_PROD:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    public dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService
    ) {}

async  ngOnInit(){
  await this.listarDepositos(null);
    await  this.listarProductos();
   await this.listarDefiniciones();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

 async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = await this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

  /////////////////////////////////////////////////////////////////////
 async listarDepositos(msj:any){
    try {
      await this.util.abrirSpinner();
      await  this.servicio.consultar_sesionhttp(null).toPromise();
        let msg = msj;
       const response = await this.servicio.listarDepositoshttp(this.atrb.COD_EMPRESA).toPromise();
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                var rif;
                var nom_final;
                  for (const i in response) {
                    if (response[i].tipo_deposito.toUpperCase() === await this.atrb.SPCL ||
                     response[i].tipo_deposito.toUpperCase() === await this.atrb.CDN ||  
                     response[i].tipo_deposito.toUpperCase() === await this.atrb.PVP || 
                      response[i].tipo_deposito.toUpperCase() === await this.atrb.DSTRBDR) {
                      rif = response[i].des_deposito.split('-');
                      nom_final = rif[0];
                    }else{
                      nom_final = response[i].des_deposito;
                    }

                    this.ELEMENT_DATA.push({
                      descrip_deposito:response[i].descrip_deposito.toUpperCase(),
                      cod_deposito:response[i].cod_deposito,
                      des_deposito: nom_final,
                      tipo_deposito:response[i].tipo_deposito,
                      tipo_manejo:response[i].tipo_manejo,
                      cod_usuario:response[i].cod_usuario,
                      des_usuario:response[i].des_usuario,
                      fecha:response[i].fecha,
                      hora:response[i].hora,
                      estatus:response[i].estatus,
                      comentario:response[i].comentario,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = await this.atrb.DEPOSITOS_ARRAY;
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            
        if(msg === await this.atrb.ACCION_ACTUALIZAR){
          await this.util.cerrarSpinner();
          await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
        }else{
          await this.util.cerrarSpinner();
        }
    } catch (error) {
      console.log(error);
                  this.displayedColumns = await this.atrb.DEPOSITOS_ARRAY;
                  this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
    }

  }

  async listarProductos(){
    try {
     const response = await this.servicio.listarProductoshttp(await this.atrb.COD_EMPRESA).toPromise();
            this.DES_PROD = [];
                for (const i in response) {
                  this.DES_PROD.push({
                    cod_producto:response[i].cod_producto,
                    des_producto: response[i].des_producto,
                    estatus_producto:response[i].estatus_producto,
                    estatus_fiscal:response[i].estatus_fiscal,
                    tamano_producto:response[i].tamano_producto,
                    unidad_medida_producto:response[i].unidad_medida_producto,
                    unidad_manejo:response[i].unidad_manejo,
                    tipo_producto:response[i].tipo_producto,
                    cod_formula:response[i].cod_formula,
                    accion:'',
                    accion2:'',
                    accion3:''
                  });
                }
    } catch (error) {
     console.log(error.error.message);
    }
  }

 async listarDefiniciones(){
    try {
      var sub_main ={cod_menu:localStorage.getItem('sub_main')};
      console.log(sub_main,'sub main');
      const response = await  this.servicio.listarDefinicionesConceptoshttp(sub_main).toPromise();
            this.tipo_deposito= [];
            this.depositos= [];
            this.tipo_manejo = [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === await this.atrb.DEPOSITOS.productos) {
                  this.depositos.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() === await this.atrb.DEPOSITOS.depositos) {
                  this.tipo_deposito.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() === await this.atrb.DEPOSITOS.tipo_de_manejo) {
                 this.tipo_manejo.push(response[i]);
             }
            }
    } catch (error) {
      console.log(error);
    }

  }

  ///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////
  async update_insert_data(obj:any, termino:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let val_def;
      if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) <= 0 ) {
        val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.almacen,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
          cod_usuario : await this.atrb.COD_USUARIO.cod_usuario
        }
      }else{
        val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.almacen,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
          cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
        }
      }
      await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
      let objeto = {
        cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
        mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
        des_parametro: await this.atrb.OPERACIONES.depositos
      };
      await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
      await this.util.cerrarSpinner();
         var dialogRef =   this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
               data: {cod_usuario:this.atrb.COD_USUARIO.cod_usuario,
                      des_usuario:this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
                      objeto: this.depositos,
                      objeto2:this.tipo_deposito,
                      objeto3:this.tipo_manejo,
                      accion:termino},
               panelClass: 'pedidos_modal_mini_form',
               disableClose: true,
               backdropClass: "bdrop"
             });
             dialogRef.afterClosed().subscribe(async result => {
              await this.util.abrirSpinner();
              if(result != 'cerrar'){
                await this.util.cerrarSpinner();
                this.gestionDepositos(result);
              }else{
                await this.util.cerrarSpinner();
              }
            });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
 }

 async  gestionDepositos(xvalor:any){
      try {
        const response = await this.servicio.gestionDepositoshttp(xvalor).toPromise();
          this.listarDepositos(null);
          await this.notifi.success(response.message);
      } catch (error) {
        console.log(error);
        this.listarDepositos(null);
        await this.notifi.customToastNotification(error.error.message);
      }
 }

 async getTrasladosInv(obj:any){
  try {
      await this.util.abrirSpinner();
      await  this.servicio.consultar_sesionhttp(null).toPromise();
      let val_def;
      if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) <= 0 ) {
        val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.almacen,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
          cod_usuario : await this.atrb.COD_USUARIO.cod_usuario
        }
      }else{
        val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.almacen,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
          cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
        }
      }
      await  this.servicio.validar_acceso_acciones_http(val_def).toPromise();
        await this.util.cerrarSpinner();
                var dialogRef:any;
                this.accion = this.atrb.ACCESO_OPERACION.traslados;
                  dialogRef = this.dialog.open(FormTrasladosDepComponent, {
                    width: '100vw',
                          maxWidth: '100vw',
                          height:'100vh',
                    data: {cod_dep:this.ELEMENT_DATA,
                            productos:this.DES_PROD,
                            accion:this.accion},
                    disableClose: true,
                    panelClass: 'pedidos_modal',
                    backdropClass: "bdrop"
                  });
                  dialogRef.afterClosed().subscribe(async result => {
                    await this.util.cerrarSpinner();
                  });
          } catch (error) {
            await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(error.error.message);
          }
}

 async consulta_depositos(obj:any){
  try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let val_def;
      if (parseInt(this.hashkey.decrypt(this.atrb.ROL_USUARIOS.cod_rol)) <= 0 ) {
        val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.almacen,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.select,
          cod_usuario : await this.atrb.COD_USUARIO.cod_usuario
        }
      }else{
        val_def = {
          val_def:await this.atrb.ACCESO_VALDEF.almacen,
          tipo_operacion:await this.atrb.ACCESO_OPERACION.select,
          cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
        }
      }
      await  this.servicio.validar_acceso_acciones_http(val_def).toPromise();
      await this.util.cerrarSpinner();
      var dialogRef = this.dialog.open(FormDepositosComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
             data: {objeto: obj},
             panelClass: 'pedidos_modal3',
             disableClose: true,
             backdropClass: "bdrop"
           });
           dialogRef.afterClosed().subscribe(async result => {
            await this.util.abrirSpinner();
            console.log(result,'ver esto');
            if(result != 'cerrar'){
              await this.util.cerrarSpinner();
              this.gestionDepositos(result);
            }else{
              await this.util.cerrarSpinner();
            }
          });
          
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}

 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
