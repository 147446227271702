import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { FormSpinnerComponent } from '../modal/form-spinner/form-spinner.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import axios from 'axios';
import { AppUtilService } from './app.util.service';
import { AtributosService } from './atributos.service';
import { HashKeyServices } from './app.hashkey.services';
import { nkServices } from './app.nkservicios.services';
import { environment } from 'src/environments/environment';
import { ToastNotificationService } from './toast-notification.service';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
 private dialogSpin:any;
 private accion:any;
 private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 private verticalPosition: MatSnackBarVerticalPosition = 'top';
 private titulo: any = this.atrb.MENSAJES_FIJOS.rft_excel;
 private path_0: any = this.atrb.IMAGENES_FIJAS.sin_img_prod;
 private direccion: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private tel_1: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private tel_2: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private tel_3: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private correo: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private web: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private rif: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private postal: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private mensaje_1: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private mensaje_2: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private mensaje_3: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private mensaje_3_2: any = this.atrb.MENSAJES_FIJOS.rft_excel;;
 private path_3: any = this.atrb.IMAGENES_FIJAS.sin_img_prod;;
 private path_2: any = this.atrb.IMAGENES_FIJAS.sin_img_prod;;
  constructor(
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private util:AppUtilService,
              private atrb:AtributosService,
              private hashkey:HashKeyServices,
              private servicio:nkServices,
              private notifi:ToastNotificationService,
             ){ }

  plantilla_pedido_producto(elemento:any){
    try {
          var xobjeto_ = [];
          var xobjeto= [];
          var fecha = false;
          for (const i in elemento) {
            xobjeto_.push({
              cod_producto:elemento[i].cod_producto,
              des_producto:elemento[i].des_producto,
              cantidad_pedido:parseInt(elemento[i].cant_producto) ,
              desc_prod: parseFloat(elemento[i].desc_prod) 
            });               
        }
        for (const i in xobjeto_) {
          xobjeto.push(xobjeto_[i]);
      }
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet('Pedido producto');
  let header=[
        'cod_producto',
        'des_producto',
        'cantidad_pedido',
        'desc_prod'
   ];
  let headerRow = worksheet.addRow(header);
            for (let x1 of xobjeto)
          {
                  let x2=Object.keys(x1);
                  let temp=[]
                  for(let y of x2)
                  {
                  temp.push(x1[y])
                  }
                  worksheet.addRow(temp)
          }
      var fecha_soporte = moment().format('DD/MM/YYYY');
      var hora_soporte = moment().format('hh:mm:ss a');
      let fname="Pedido  '"+fecha_soporte+' '+hora_soporte;
      workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
      });
    } catch (error) {
      console.log(error);
    }
  }
  plantilla_ventas_cuotas(elemento:any){
    try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        console.log(elemento);
        setTimeout(() => {
          this.dialogSpin.close();
          var xobjeto_ = [];
          var xobjeto= [];
          var fecha = false;
          for (const i in elemento) {
         
            xobjeto_.push({
              cod_producto:elemento[i].cod_producto,
              des_producto:elemento[i].des_producto,
              cantidad_pedido:parseInt(elemento[i].cant_producto) ,
              desc_prod: parseFloat(elemento[i].desc_prod) 
            });
                           
        }
        
        for (const i in xobjeto_) {
         
          xobjeto.push(xobjeto_[i]);
                         
      }

  let workbook = new Workbook();

  let worksheet = workbook.addWorksheet('Pedido producto');

  let header=[
        'cod_cliente',
        'des_cliente',
        'cuota',
        'porcentaje_cuota',
        'estatus_consig'
   ];
  let headerRow = worksheet.addRow(header);

            for (let x1 of xobjeto)
          {
                  let x2=Object.keys(x1);

                  let temp=[]
                  for(let y of x2)
                  {
                  temp.push(x1[y])
                  }
                  worksheet.addRow(temp)
          }
      var fecha_soporte = moment().format('DD/MM/YYYY');
      var hora_soporte = moment().format('hh:mm:ss a');
      let fname='Formato ventas vs cuotas';

      workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
      });
        }, 500);
        
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
  excelt_import_masivo(elemento:Object, name:String){
    try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        console.log(elemento);
        setTimeout(() => {
          this.dialogSpin.close();
          var fecha = false;
         
  let workbook = new Workbook();

  let worksheet = workbook.addWorksheet('Pedido producto');

  let header=Object.keys(elemento);
  let headerRow = worksheet.addRow(header);
          
            let ar = [elemento]

            for (let x1 of ar)
          {
                  let x2=Object.keys(x1);

                  let temp=[]
                  for(let y of x2)
                  {
                  temp.push(x1[y])
                  }
                  worksheet.addRow(temp)
          }
      var fecha_soporte = moment().format('DD/MM/YYYY');
      var hora_soporte = moment().format('hh:mm:ss a');
      let fname= `${name} ${fecha_soporte}-${hora_soporte}`;

      workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
      });
        }, 500);
        
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
  reporte_backorder_1(xdatos:any, fecha:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte Backorder activos",{views: [{showGridLines: false}]});
  
      /* let worksheet = workbook.addWorksheet("Reporte de ventas desde '"+this.fecha1+"' hasta '"+this.fecha2+"'"); */
      worksheet.mergeCells('B1:M1')
      worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D1').font={bold: true};
  
      worksheet.mergeCells('B2:M2')
      worksheet.getCell('D2').value='Rif J-41031797-3';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
      
      worksheet.mergeCells('B3:M3')
      worksheet.getCell('D3').value='Reporte Backorder activos';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};
  
      worksheet.mergeCells('B4:M4')
      worksheet.getCell('D4').value='Del "'+fecha.fecha_inicio+'" al "'+fecha.fecha_fin+'"';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};
      
      worksheet.mergeCells('B5:M5')
      worksheet.getCell('D5').value='';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};
  
      worksheet.mergeCells('B6:M6')
      
      worksheet.autoFilter = {
        from: 'B8',
        to: 'M8',
      }
      
      var fondo_gris_claro =['D7','E7','F7','G7','H7']
      fondo_gris_claro.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF'},
          /* bgColor: { argb: '91918e' } */
        };
      })
  
      let header=["","Back-order","Documento","Lista de precios","Cliente","Usuario","Estatus","Tipo","Fecha","Hora","Monto bs","Tasa", "Monto $"];
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}}
  
  
      worksheet.getCell('B8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('C8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('D8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('E8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('F8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('G8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('H8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('I8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('J8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('K8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      worksheet.getCell('L8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };

      worksheet.getCell('M8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
  
      var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          /* bgColor: { argb: 'adadaa' } */
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 17;
      worksheet.getColumn(4).width = 17;
      worksheet.getColumn(5).width = 17;
      worksheet.getColumn(6).width = 17;
      worksheet.getColumn(7).width = 17;
      worksheet.getColumn(8).width = 17;
      worksheet.getColumn(9).width = 17;
      worksheet.getColumn(10).width = 17;
      worksheet.getColumn(11).width = 17;
      worksheet.getColumn(12).width = 17;
      worksheet.getColumn(13).width = 17;
      var numFmtStr = '_("" #,##0.00_);_([Red]("" #,##0.00);_("-"??_);_(@_)';
      worksheet.getColumn(11).numFmt = numFmtStr;
      worksheet.getColumn(12).numFmt = numFmtStr;
      worksheet.getColumn(13).numFmt = numFmtStr;
  
      for (let x1 in xdatos)
    {
        let x2= xdatos[x1];
        console.log(x2,'x2');
        var temp=[]
        for(let y in x2)
        {
        temp.push(xdatos[x1][y])
        }
        worksheet.addRow(temp)
    }
  
    for (let i = 0; i <= xdatos.length; i++) {
  
      if(i == 0){
        worksheet.getCell('B9').border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          
        };
      }else{
        var numActu = i + 9 -1;
        var numActuString = numActu.toString()
  
        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
         
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}}
        };
        worksheet.getCell(`K${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}}
        };
        worksheet.getCell(`L${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`M${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
        
      }
      if(i == xdatos.length){
        var numActu = i + 9 -1;
        var final_mas_uno = i + 9 + 1
        var final_mas_uno_s = final_mas_uno.toString()
  
        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}}
        };
        worksheet.getCell(`K${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`L${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`M${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
  
      }
    }
   
    let fname="Reporte Backorder activos del '"+fecha.fecha_inicio+"' hasta '"+fecha.fecha_fin+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
    }
  reporte_backorder_2(xdatos:any, fecha:any){
      try {
        console.log(xdatos,'llego a la descarga');
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Reporte Backorder vs productos",{views: [{showGridLines: false}]});
    
        /* let worksheet = workbook.addWorksheet("Reporte de ventas desde '"+this.fecha1+"' hasta '"+this.fecha2+"'"); */
        worksheet.mergeCells('B1:H1')
        worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
        worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D1').font={bold: true};
    
        worksheet.mergeCells('B2:H2')
        worksheet.getCell('D2').value='Reporte Backorder vs productos';
        worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D2').font={bold: true};
        
        worksheet.mergeCells('B3:H3')
        worksheet.getCell('D3').value='Del "'+fecha.fecha_inicio+'" al "'+fecha.fecha_fin+'"';
        worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D3').font={bold: true};
    
        worksheet.mergeCells('B4:H4')
        worksheet.getCell('D4').value= fecha.des_cliente;
        worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D4').font={bold: true};
        
        worksheet.mergeCells('B5:H5')
        worksheet.getCell('D5').value=  " Lista de precios N: "+ fecha.cod_lista_precio +" / Tasa: "+fecha.tasa_precio+' bs';
        worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D5').font={bold: true};

        worksheet.mergeCells('B6:H6')
        
        worksheet.autoFilter = {
          from: 'B8',
          to: 'C8',
        }
        
        var fondo_gris_claro =['D7','E7','F7','G7','H7']
        fondo_gris_claro.map(celdas => {
          worksheet.getCell(celdas).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF'},
            /* bgColor: { argb: '91918e' } */
          };
        })
    
        let header=["","Codigo","Descripción","Cantidad","Precio $","Precio bs","Total $","Total bs"];
        let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}}
    
    
        worksheet.getCell('B8').border = {
          /* FF0000FF */
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
        worksheet.getCell('C8').border = {
          /* FF0000FF */
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
        worksheet.getCell('D8').border = {
          /* FF0000FF */
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
        worksheet.getCell('E8').border = {
          /* FF0000FF */
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
        worksheet.getCell('F8').border = {
          /* FF0000FF */
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
        worksheet.getCell('G8').border = {
          /* FF0000FF */
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
        worksheet.getCell('H8').border = {
          /* FF0000FF */
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      
    
        var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8']
        fondo_gris_claro_plus.map(celdas => {
          worksheet.getCell(celdas).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00111f'},
            /* bgColor: { argb: 'adadaa' } */
          };
        })
        fondo_gris_claro_plus.map(centrar => {
          worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
        })
        worksheet.getColumn(1).width = 3.5;
        worksheet.getColumn(2).width = 25;
        worksheet.getColumn(3).width = 50;
        worksheet.getColumn(4).width = 20;
        worksheet.getColumn(5).width = 20;
        worksheet.getColumn(6).width = 20;
        worksheet.getColumn(7).width = 20;
        worksheet.getColumn(8).width = 20;
        var numFmtStr = '_("" #,##0.00_);_([Red]("" #,##0.00);_("-"??_);_(@_)';
        worksheet.getColumn(4).numFmt = numFmtStr;
        worksheet.getColumn(5).numFmt = numFmtStr;
        worksheet.getColumn(6).numFmt = numFmtStr;
        worksheet.getColumn(7).numFmt = numFmtStr;
        worksheet.getColumn(8).numFmt = numFmtStr;
    
        for (let x1 in xdatos)
      {
          let x2= xdatos[x1];
          console.log(x2,'x2');
          var temp=[]
          for(let y in x2)
          {
          temp.push(xdatos[x1][y])
          }
          worksheet.addRow(temp)
      }
    
      for (let i = 0; i <= xdatos.length; i++) {
    
        if(i == 0){
          worksheet.getCell('B9').border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            
          };
        }else{
          var numActu = i + 9 -1;
          var numActuString = numActu.toString()
    
          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
         
        }
        if(i == xdatos.length){
          var numActu = i + 9 -1;
          var final_mas_uno = i + 9 + 1
          var final_mas_uno_s = final_mas_uno.toString()
    
          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
      }
     
      let fname="Reporte Backorder vs productos '"+fecha.fecha_inicio+"' hasta '"+fecha.fecha_fin+"'";
      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
      });
      } catch (error) {
        console.log(error);
        this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,})
      }
      }
  async reporte_clientes(xdatos:any){
    try {
          let workbook = new Workbook();
          let worksheet = workbook.addWorksheet("Reporte de Clientes",{views: [{showGridLines: false}]});
      
          const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
          const imageId2 = workbook.addImage({
            buffer: imageBuffer.data,
            extension: 'png',
          });
          
          worksheet.addImage(imageId2,'B2:B6')

          worksheet.mergeCells('B2:J2')
          worksheet.getCell('D2').value='Factory Cosmetics 3DG, C.A.';
          worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
          worksheet.getCell('D2').font={bold: true};
      
          worksheet.mergeCells('B3:J3')
          worksheet.getCell('D3').value='Rif J-41031797-3';
          worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
          worksheet.getCell('D3').font={bold: true};
          
          worksheet.mergeCells('B4:J4')
          worksheet.getCell('D4').value='Reporte de Clientes';
          worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
          worksheet.getCell('D4').font={bold: true};
      
          worksheet.mergeCells('B5:J5')
          worksheet.getCell('D5').value='Reflejando Clientes Activos e Inactivos';
          worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
          worksheet.getCell('D5').font={bold: true};
          
          worksheet.mergeCells('B6:J6')
          
          worksheet.autoFilter = {
            from: 'B7',
            to: 'J7',
          }
          
          let header=[" ","RIF","Cliente","Direccion","Correo","Estatus","Vendedor","Dias de Credito","Tipo de Cliente","Retencion","Deposito"];
          let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}}
          
          var headerIndices =  ['B','C','D','E','F','G','H','I','J','K']

          var border_header = ['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7']
          border_header.map(celdas => {
            worksheet.getCell(celdas).border = {
              top: {style:'double', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}}
            };
          })
  
          var fondo_gris_claro_plus=['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7']
          fondo_gris_claro_plus.map(celdas => {
            worksheet.getCell(celdas).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00111f'},
              /* bgColor: { argb: 'adadaa' } */
            };
          })
          fondo_gris_claro_plus.map(centrar => {
            worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
          })
          worksheet.getColumn(1).width = 3.5;
          worksheet.getColumn(2).width = 17;
          worksheet.getColumn(3).width = 40;
          worksheet.getColumn(4).width = 40;
          worksheet.getColumn(5).width = 40;
          worksheet.getColumn(6).width = 17;
          worksheet.getColumn(7).width = 40;
          worksheet.getColumn(8).width = 20;
          worksheet.getColumn(9).width = 20;
          worksheet.getColumn(10).width = 17;
          worksheet.getColumn(11).width = 17;
          var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
          worksheet.getColumn(8).numFmt = numFmtStr;
  
          /////////////////////wrap//////////////////////
          var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7']
          espacio_interno.map(celdas =>{
          worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
            
          })
          /////////////////////wrap//////////////////////
          
          /////////////////////ROW//////////////////////
          worksheet.getRow(6).height= 20
          worksheet.getRow(7).height= 30
          /////////////////////ROW//////////////////////
          for (let x1 in xdatos)
          {
            let x2= xdatos[x1];
            console.log(x2,'x2');
            var temp=[]
            for(let y in x2)
            {
              temp.push(xdatos[x1][y])
            }
            worksheet.addRow(temp)
          }
        
          for (let i = 0; i <= xdatos.length; i++) {
      
          if(i == 0){
            for (let j = 0; j <= headerIndices.length; j++){
              if(headerIndices[j]){

                var fila = `${headerIndices[j].toString()}`
                if(j == 0){

                  worksheet.getCell(`${fila}8`).border = {
                    bottom: {style:'thin', color: {argb:'00000000'}}, 
                  };
                  worksheet.getCell(`${fila}8`).alignment = { vertical: 'top', horizontal: 'center' }
                  
                }else{
                  
                  worksheet.getCell(`${fila}8`).alignment = { vertical: 'top', horizontal: 'center' }
                }
              }
            }
          }else{
            var numActu = i + 8 -1;
            var numActuString = numActu.toString()
            for (let j = 0; j <= headerIndices.length; j++){

              if(headerIndices[j]){ 
                var fila = `${headerIndices[j].toString()}`
                var ultimaFila = `${headerIndices[headerIndices.length - 1].toString()}`

                worksheet.getCell(`${fila}${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
                
                if(j == 0){

                  worksheet.getCell(`${fila}${numActuString}`).border = {
                    bottom: {style:'thin', color: {argb:'00000000'}},
                    left: {style:'double', color: {argb:'00000000'}},
                  };
                }else{
                  
                  worksheet.getCell(`${fila}${numActuString}`).border = {
                    bottom: {style:'thin', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`${ultimaFila}${numActuString}`).border = {
                    bottom: {style:'thin', color: {argb:'00000000'}},
                    right: {style:'double', color: {argb:'00000000'}},
                  };
                }
              }
            }
          }
          if(i == xdatos.length){
            var numActu = i + 8 -1;
            var final_mas_uno = i + 8 + 1
            var final_mas_uno_s = final_mas_uno.toString()

            for (let j = 0; j <= headerIndices.length; j++){
              if(headerIndices[j]){ 
                var fila = `${headerIndices[j].toString()}`
                var ultimaFila = `${headerIndices[headerIndices.length - 1].toString()}`
                if(j == 0){

                  worksheet.getCell(`${fila}${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                    left: {style:'double', color: {argb:'00000000'}},
                  };

                }else{
                  worksheet.getCell(`${fila}${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`${ultimaFila}${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                    right: {style:'double', color: {argb:'00000000'}},
                  };
                }
              }
            }    
          }
        }
       
        
        let fname="Reporte de Clientes";
        workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
        });
      } catch (error) {
        console.log(error);
        this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,})
      }
    }
  async reporte_facturas(xdatos:any, result:any){
          try {
            console.log(xdatos,'llego a la descarga');
            let workbook = new Workbook();
            let worksheet = workbook.addWorksheet("Reporte de Facturas",{views: [{showGridLines: false}]});

            const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
            const imageId2 = workbook.addImage({
              buffer: imageBuffer.data,
              extension: 'png',
            });
            
            worksheet.addImage(imageId2,'B2:B6')

            worksheet.mergeCells('B1:T1')
            worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
            worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D1').font={bold: true};
      
            worksheet.mergeCells('B2:T2')
            worksheet.getCell('D2').value='Rif J-41031797-3';
            worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D2').font={bold: true};
            
            worksheet.mergeCells('B3:T3')
            worksheet.getCell('D3').value='Reporte de Facturas';
            worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D3').font={bold: true};
      
            worksheet.mergeCells('B4:T4')
            worksheet.getCell('D4').value='A la fecha "'+result.fecha_inicio+'"-"'+result.fecha_fin+'" ';
            worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D4').font={bold: true};
      
            worksheet.mergeCells('B5:T5')
            worksheet.getCell('D5').value='Representado en Bolivares y Dolares';
            worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D5').font={bold: true};
      
            worksheet.mergeCells('B6:T6')
      
            worksheet.autoFilter = {
              from: 'B7',
              to: 'T7',
            }

            let header=[" ","Fecha","Hora","Nro. Factura","Nro. Control","RIF","Cliente","Vendedor","Tipo de cliente",
                        "Tasa","Base imponible","%Descuento","Descuento","%IVA","IVA","%Exento","Monto exento",
                        "Monto total VEF","Monto total USD","Estatus"];

            let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};
          
            var border =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7',
                        'M7','N7','O7','P7','Q7','R7','S7','T7']

          border.map(celdas =>{
            worksheet.getCell(celdas).border = {
              top: {style:'double', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}}
            };
          })

            var fondo_gris_claro_plus=['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7',
                        'M7','N7','O7','P7','Q7','R7','S7','T7']
            fondo_gris_claro_plus.map(celdas => {
              worksheet.getCell(celdas).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00111f'},
                
              };
            })
            fondo_gris_claro_plus.map(centrar => {
              worksheet.getCell(centrar).alignment = { vertical: 'middle', horizontal: 'center' };
            })

            worksheet.getColumn(1).width = 3.5;
            worksheet.getColumn(2).width = 17;
            worksheet.getColumn(3).width = 17;
            worksheet.getColumn(4).width = 17;
            worksheet.getColumn(5).width = 17;
            worksheet.getColumn(6).width = 17;
            worksheet.getColumn(7).width = 40;
            worksheet.getColumn(8).width = 25;
            worksheet.getColumn(9).width = 17;
            worksheet.getColumn(10).width = 17;
            worksheet.getColumn(11).width = 17;
            worksheet.getColumn(12).width = 17;
            worksheet.getColumn(13).width = 17;
            worksheet.getColumn(14).width = 17;
            worksheet.getColumn(15).width = 17;
            worksheet.getColumn(16).width = 17;
            worksheet.getColumn(17).width = 17;
            worksheet.getColumn(18).width = 17;
            worksheet.getColumn(19).width = 17;
            worksheet.getColumn(20).width = 17;
            var numFmtStr = '_("$" #,##0.00_);_([Red]("$" #,##0.00);_("-"??_);_(@_)';
            var numFmtStr_ = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
            worksheet.getColumn(10).numFmt = numFmtStr_;
            worksheet.getColumn(11).numFmt = numFmtStr_;
            worksheet.getColumn(12).numFmt = numFmtStr_;
            worksheet.getColumn(13).numFmt = numFmtStr_;
            worksheet.getColumn(14).numFmt = numFmtStr_;
            worksheet.getColumn(15).numFmt = numFmtStr_;
            worksheet.getColumn(16).numFmt = numFmtStr_;
            worksheet.getColumn(17).numFmt = numFmtStr_;
            worksheet.getColumn(18).numFmt = numFmtStr_;
            worksheet.getColumn(19).numFmt = numFmtStr;

            /////////////////////ROW//////////////////////
            worksheet.getRow(7).height= 30
            /////////////////////ROW//////////////////////  

            /////////////////////wrap//////////////////////
            var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7',
                        'M7','N7','O7','P7','Q7','R7','S7','T7']
            espacio_interno.map(celdas =>{
            worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
            })
            /////////////////////wrap//////////////////////

            for (let x1 of xdatos)
            {
              if(x1.fecha_inicio != '-'){

                x1.fecha_inicio = new Date(x1.fecha_inicio)
              }
              let x2=Object.keys(x1);
              let temp=[]
              for(let y of x2)
              {
                temp.push(x1[y])
              }
              worksheet.addRow(temp)
            }

            for (let i = 0; i <= xdatos.length; i++) {

              if(i == 0){
                worksheet.getCell('B7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('C7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('D7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('E7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('F7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('G7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('H7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('I7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('J7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('K7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('L7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('M7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('N7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('O7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('P7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('Q7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('R7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('S7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('T7').alignment = { vertical: 'middle', horizontal: 'center' }

              }else{
                var numActu = i + 8 -1;
                var numActuString = numActu.toString()
      
                worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`T${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }

      
                worksheet.getCell(`B${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                  left: {style:'double', color: {argb:'00000000'}},
                };
                worksheet.getCell(`C${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`D${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`E${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`F${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`G${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`H${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`I${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`J${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`K${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`L${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`M${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`N${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`O${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`P${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`Q${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`R${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`S${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`T${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                  right: {style:'double', color: {argb:'00000000'}},
                };
              }
              
              if(i == xdatos.length){
                  var numActu = i + 8 -1;
                  var final_mas_uno = i + 8 + 1
                  var final_mas_uno_s = final_mas_uno.toString()
      
                  worksheet.getCell(`B${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                    left: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`C${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`D${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`E${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`F${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`G${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`H${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`I${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`J${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`K${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`L${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`M${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`N${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`O${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`P${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`Q${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`R${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`S${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`T${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                    right: {style:'double', color: {argb:'00000000'}},
                  };
              }
            }
            let fname="Reporte de facturas desde '"+result.fecha_inicio+"' hasta '"+result.fecha_fin+"'";
            workbook.xlsx.writeBuffer().then((data) => {
              let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
            });
          } catch (error) {
            console.log(error);
            this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,})
          }
  }
  async reporte_general_ventas(xdatos:any , result:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte General",{views: [{showGridLines: false}]});

      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });
      
      worksheet.addImage(imageId2,'B2:B7')
      
      worksheet.mergeCells('B1:AD1')
      worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D1').font={bold: true};

      worksheet.mergeCells('B2:AD2')
      worksheet.getCell('D2').value='Rif J-41031797-3';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
      
      worksheet.mergeCells('B3:AD3')
      worksheet.getCell('D3').value='Reporte General';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};

      worksheet.mergeCells('B4:AD4')
      worksheet.getCell('D4').value='A la fecha "'+result.fecha_inicio+'"-"'+result.fecha_fin+'" ';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};

      worksheet.mergeCells('B5:AD5')
      worksheet.getCell('D5').value='Expresado en Bolivares y Dolares';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};

      worksheet.mergeCells('B6:AD6')

      worksheet.autoFilter = {
        from: 'B8',
        to: 'AD8',
      }
      
      var fondo_gris_claro =['D7','E7','F7','G7','H7']
      fondo_gris_claro.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF'},
          
        };
      })

      let header = [" ","Fecha","Hora","Nro. Factura","Nro. Control","Nro. Nota","Tipo de Nota","Nro. Nota de Credito",
                   "Nro. Nota de Debito","Factura Afectada","Tipo de Doc.","RIF","Cliente","Usuario","Tipo Nota de Credito","Concepto Doc",
                   "Vendedor","Tipo de Cliente","Tasa","Base Imponible VES","Base Imponible USD","Monto Exento",
                   "%Descuento","Descuento","%IVA","IVA","Monto Total VES","Monto Total USD","Estatus","Estatus Fiscal",] 
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

      var bordes_headers = ['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8','P8',
                            'Q8','R8','S8','T8','U8','V8','W8','X8','Y8','Z8','AA8','AB8','AC8','AD8']
      bordes_headers.map(celdas =>{
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })

      var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                                 'P8','Q8','R8','S8','T8','U8','V8','W8','X8','Y8','Z8','AA8','AB8','AC8','AD8']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 14;
      worksheet.getColumn(3).width = 14;
      worksheet.getColumn(4).width = 14;
      worksheet.getColumn(5).width = 14;
      worksheet.getColumn(6).width = 17;
      worksheet.getColumn(7).width = 14;
      worksheet.getColumn(8).width = 17;
      worksheet.getColumn(9).width = 17;
      worksheet.getColumn(10).width = 17;
      worksheet.getColumn(11).width = 17;
      worksheet.getColumn(12).width = 12;
      worksheet.getColumn(13).width = 51;
      worksheet.getColumn(14).width = 22;
      worksheet.getColumn(15).width = 25;
      worksheet.getColumn(16).width = 19;
      worksheet.getColumn(17).width = 22;
      worksheet.getColumn(18).width = 13;
      worksheet.getColumn(19).width = 17;
      worksheet.getColumn(20).width = 17;
      worksheet.getColumn(21).width = 17;
      worksheet.getColumn(22).width = 20;
      worksheet.getColumn(23).width = 20;
      worksheet.getColumn(24).width = 15;
      worksheet.getColumn(25).width = 17;
      worksheet.getColumn(26).width = 17;
      worksheet.getColumn(27).width = 21;
      worksheet.getColumn(28).width = 21;
      worksheet.getColumn(29).width = 21;
      worksheet.getColumn(30).width = 21;
      worksheet.getColumn(31).width = 21;
      worksheet.getColumn(32).width = 21;
      worksheet.getColumn(32).width = 21;
      /////////////////////wrap//////////////////////
      var espacio_interno =['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                                 'P8','Q8','R8','S8','T8','U8','V8','W8','X8','Y8','Z8','AA8','AB8','AC8','AD8']
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(8).height= 30
      /////////////////////ROW//////////////////////
      var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
      worksheet.getColumn(18).numFmt =numFmtStr;
      worksheet.getColumn(19).numFmt =numFmtStr;
      worksheet.getColumn(20).numFmt =numFmtStr;
      worksheet.getColumn(21).numFmt =numFmtStr;
      worksheet.getColumn(22).numFmt =numFmtStr;
      worksheet.getColumn(23).numFmt =numFmtStr;
      worksheet.getColumn(24).numFmt =numFmtStr;
      worksheet.getColumn(25).numFmt =numFmtStr;
      worksheet.getColumn(26).numFmt =numFmtStr;
      worksheet.getColumn(27).numFmt =numFmtStr;
      worksheet.getColumn(28).numFmt =numFmtStr;

      var subtotal_costo_pedido =0;
      var subtotal_costo_pedido_usd = 0;
      var monto_exento =0;
      var desc_pedido_monto =0;
      var iva_monto =0;
      var total_costo_pedido =0;
      var total_divisa =0;

  
      for (let x1 of xdatos)
    {
    let x2=Object.keys(x1);
    let temp=[]
    for(let y of x2)
    {
    temp.push(x1[y])
    }
    worksheet.addRow(temp)
    }

      for (const i in xdatos) {
        
        subtotal_costo_pedido += parseFloat(xdatos[i].subtotal_costo_pedido)
        monto_exento += parseFloat(xdatos[i].monto_exento)
        desc_pedido_monto += parseFloat(xdatos[i].desc_pedido_monto)
        iva_monto += parseFloat(xdatos[i].iva_monto)
        total_costo_pedido += parseFloat(xdatos[i].total_costo_pedido)

        if(xdatos[i].total_divisa != '--'){

          total_divisa += parseFloat(xdatos[i].total_divisa)
        }
        if(xdatos[i].subtotal_costo_pedid_usd != '--'){

          subtotal_costo_pedido_usd += parseFloat(xdatos[i].subtotal_costo_pedid_usd)
        }
      }
      for (let i = 0; i <= xdatos.length; i++) {

        if(i == 0){
          worksheet.getCell('B9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('C9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('D9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('E9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('F9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('G9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('H9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('I9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('J9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('K9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('L9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('M9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('N9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('O9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('P9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Q9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('R9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('S9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('T9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('U9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('V9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('W9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('X9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Y9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Z9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('AA9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('AB9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('AC9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('AD9').alignment = { vertical: 'top', horizontal: 'center' }
        }else{
          var numActu = i + 9 -1;
          var numActuString = numActu.toString()

          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`T${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`U${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`V${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`W${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`X${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Y${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Z${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`AA${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`AB${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`AC${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`AD${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }

          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`J${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`K${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`L${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`M${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`N${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`O${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`P${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Q${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`R${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`S${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`T${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`U${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`V${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`W${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`X${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Y${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Z${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`AA${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`AB${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`AC${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            };
          worksheet.getCell(`AD${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
        
        if(i == xdatos.length){
            var numActu = i + 9 -1;
            var final_mas_uno = i + 9 + 1
            var final_mas_uno_s = final_mas_uno.toString()

            worksheet.getCell(`T${final_mas_uno_s}`).value= subtotal_costo_pedido
            worksheet.getCell(`U${final_mas_uno_s}`).value= subtotal_costo_pedido_usd
            worksheet.getCell(`V${final_mas_uno_s}`).value= monto_exento
            worksheet.getCell(`X${final_mas_uno_s}`).value= desc_pedido_monto
            worksheet.getCell(`Z${final_mas_uno_s}`).value= iva_monto
            worksheet.getCell(`AA${final_mas_uno_s}`).value= total_costo_pedido
            worksheet.getCell(`AB${final_mas_uno_s}`).value= total_divisa
            
            worksheet.getCell(`T${final_mas_uno_s}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              top: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`U${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`V${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`X${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Z${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AA${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AB${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };

            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`K${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`L${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`M${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`N${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`O${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`P${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Q${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`R${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`S${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`T${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`U${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`V${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`W${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`X${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Y${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Z${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AA${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AB${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AC${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AD${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
        }
      }
    let fname="Reporte General desde '"+result.fecha_inicio+"' hasta '"+result.fecha_fin+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  async reporteMovimientosCDT(xdatos:any , result:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte Movimiento de Inventario",{views: [{showGridLines: false}]});

      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });
      
      worksheet.addImage(imageId2,'B2:B6')
      
      worksheet.mergeCells('B1:O1')
      worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D1').font={bold: true};

      worksheet.mergeCells('B2:O2')
      worksheet.getCell('D2').value='Rif J-41031797-3';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
      
      worksheet.mergeCells('B3:O3')
      worksheet.getCell('D3').value='Movimiento de Inventario';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};

      worksheet.mergeCells('B4:O4')
      worksheet.getCell('D4').value='A la fecha "'+result.fecha_inicio+'"-"'+result.fecha_fin+'" ';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};

      worksheet.mergeCells('B5:O5')
      worksheet.getCell('D5').value='Deposito: "'+xdatos[0].cod_inv+'"';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};

      worksheet.mergeCells('B6:O6')

      worksheet.autoFilter = {
        from: 'B8',
        to: 'O8',
      }
      
      var fondo_gris_claro =['D7','E7','F7','G7','H7']
      fondo_gris_claro.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF'},
          
        };
      })

      let header = [" ","Fecha","Hora","Deposito","Operacion","Documento","Inv. Salida","Inv. Entrada","Cod Producto",
                   "Producto","Usuario","Cant. Solicitada","Cant. Actual","Cant. Restante","Comentarios"] 
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

      var bordes_headers = ['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8']
      bordes_headers.map(celdas =>{
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })

      var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 17;
      worksheet.getColumn(4).width = 17;
      worksheet.getColumn(5).width = 17;
      worksheet.getColumn(6).width = 20;
      worksheet.getColumn(7).width = 30;
      worksheet.getColumn(8).width = 35;
      worksheet.getColumn(9).width = 17;
      worksheet.getColumn(10).width = 45;
      worksheet.getColumn(11).width = 20;
      worksheet.getColumn(12).width = 20;
      worksheet.getColumn(13).width = 20;
      worksheet.getColumn(14).width = 20;
      worksheet.getColumn(15).width = 75;
      /////////////////////wrap//////////////////////
      var espacio_interno =['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8']
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(8).height= 30
      /////////////////////ROW//////////////////////
      var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
      worksheet.getColumn(12).numFmt =numFmtStr;
      worksheet.getColumn(13).numFmt =numFmtStr;
      worksheet.getColumn(14).numFmt =numFmtStr;

  
      for (let x1 in xdatos)
      {
      let x2= xdatos[x1];
      x2.fecha_inicio = new Date(x2.fecha_inicio)
      console.log(x2,'x2');
      var temp=[]
      for(let y in x2)
      {
      temp.push(xdatos[x1][y])
      }
      worksheet.addRow(temp)
      }
      for (let i = 0; i <= xdatos.length; i++) {

        if(i == 0){
          worksheet.getCell('B9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('C9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('D9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('E9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('F9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('G9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('H9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('I9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('J9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('K9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('L9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('M9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('N9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('O9').alignment = { vertical: 'top', horizontal: 'center' }

        }else{
          var numActu = i + 9 -1;
          var numActuString = numActu.toString()

          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }

          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`J${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`K${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`L${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`M${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`N${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          }
          worksheet.getCell(`O${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          }
        }
        
        if(i == xdatos.length){
            var numActu = i + 9 -1;
            var final_mas_uno = i + 9 + 1
            var final_mas_uno_s = final_mas_uno.toString()

            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`K${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`L${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`M${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`N${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`O${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
        }
    }
    let fname="Reporte de Movimiento de Inventarios desde '"+result.fecha_inicio+"' hasta '"+result.fecha_fin+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  async reporteReporteOrdenesProduccion(xdatos:any , result:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte de Unidades Producidas",{views: [{showGridLines: false}]});

      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });
      
      worksheet.addImage(imageId2,'B2:B6')
      
      worksheet.mergeCells('B1:I1')
      worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D1').font={bold: true};

      worksheet.mergeCells('B2:I2')
      worksheet.getCell('D2').value='Rif J-41031797-3';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
      
      worksheet.mergeCells('B3:I3')
      worksheet.getCell('D3').value='Unidades Producidas';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};

      worksheet.mergeCells('B4:I4')
      worksheet.getCell('D4').value='A la fecha "'+result.fecha_inicio+'"-"'+result.fecha_fin+'" ';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};

      worksheet.mergeCells('B5:I5')
      worksheet.getCell('D5').value='Expresado en Unidades';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};

      worksheet.mergeCells('B6:I6')

      worksheet.autoFilter = {
        from: 'B8',
        to: 'I8',
      }
      
      var fondo_gris_claro =['D7','E7','F7','G7','H7']
      fondo_gris_claro.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF'},
          
        };
      })

      let header = [" ","Numero de OPT","Numero de Lote","Cod Producto","Producto","Cant Inicial","Cant Final","Fecha Inicio","Fecha Fin"] 
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

      var bordes_headers = ['B8','C8','D8','E8','F8','G8','H8','I8']
      bordes_headers.map(celdas =>{
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })

      var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 17;
      worksheet.getColumn(4).width = 17;
      worksheet.getColumn(5).width = 45;
      worksheet.getColumn(6).width = 20;
      worksheet.getColumn(7).width = 20;
      worksheet.getColumn(8).width = 20;
      worksheet.getColumn(9).width = 17;
      /////////////////////wrap//////////////////////
      var espacio_interno =['B8','C8','D8','E8','F8','G8','H8','I8']
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(8).height= 30
      /////////////////////ROW//////////////////////

      var numFmtStr = '_(#,##0_);_([Red](#,##0);_("-"??_);_(@_)';
      worksheet.getColumn(6).numFmt =numFmtStr;
      worksheet.getColumn(7).numFmt =numFmtStr;
      
      for (let x1 in xdatos)
      {
      let x2= xdatos[x1];
      x2.fecha_inicio = new Date(x2.fecha_inicio)
      console.log(x2,'x2');
      var temp=[]
      for(let y in x2)
      {
      temp.push(xdatos[x1][y])
      }
      worksheet.addRow(temp)
      }
      for (let i = 0; i <= xdatos.length; i++) {

        if(i == 0){
          worksheet.getCell('B9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('C9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('D9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('E9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('F9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('G9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('H9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('I9').alignment = { vertical: 'top', horizontal: 'center' }

        }else{
          var numActu = i + 9 -1;
          var numActuString = numActu.toString()

          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }

          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
        
        if(i == xdatos.length){
            var numActu = i + 9 -1;
            var final_mas_uno = i + 9 + 1
            var final_mas_uno_s = final_mas_uno.toString()

            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
        }
    }
    let fname="Reporte de Unidades Producidas desde '"+result.fecha_inicio+"' hasta '"+result.fecha_fin+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  async reporteReporteOrdenesProduccionDetallado(xdatos:any , result:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte de Unidades Producidas Detalladas",{views: [{showGridLines: false}]});

      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });
      
      worksheet.addImage(imageId2,'B2:B6')
      
      worksheet.mergeCells('B1:R1')
      worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D1').font={bold: true};

      worksheet.mergeCells('B2:R2')
      worksheet.getCell('D2').value='Rif J-41031797-3';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
      
      worksheet.mergeCells('B3:R3')
      worksheet.getCell('D3').value='Unidades Producidas';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};

      worksheet.mergeCells('B4:R4')
      worksheet.getCell('D4').value='A la fecha "'+result.fecha_inicio+'"-"'+result.fecha_fin+'" ';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};

      worksheet.mergeCells('B5:R5')
      worksheet.getCell('D5').value='Expresado en Unidades';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};

      worksheet.mergeCells('B6:R6')

      worksheet.autoFilter = {
        from: 'B8',
        to: 'R8',
      }
      
      var fondo_gris_claro =['D7','E7','F7','G7','H7']
      fondo_gris_claro.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF'},
          
        };
      })

      let header = [" ",
                    "Numero de OPT",
                    "Numero de Mpp",
                    "Numero de Lote",
                    "Cod Producto",
                    "Producto",
                    "Cant Inicial",
                    "Cant Final",
                    "Fecha Inicio",
                    "Fecha Fin",
                    "Estatus OPT",
                    "Cod MPP",
                    "Descripcion MPP",
                    "Cant Inicial",
                    "Cant Final",
                    "Fecha Inicio",
                    "Fecha Fin",
                    "Estatus MPP",
                  ] 
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

      var bordes_headers = ['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8','P8',
                            'Q8','R8',]
      bordes_headers.map(celdas =>{
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })

      var fondo_gris_claro_plus = ['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8','P8',
                                  'Q8','R8',]
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 17;
      worksheet.getColumn(4).width = 17;
      worksheet.getColumn(5).width = 17;
      worksheet.getColumn(6).width = 45;
      worksheet.getColumn(7).width = 20;
      worksheet.getColumn(8).width = 20;
      worksheet.getColumn(9).width = 17;
      worksheet.getColumn(10).width = 17;
      worksheet.getColumn(11).width = 20;
      worksheet.getColumn(12).width = 17;
      worksheet.getColumn(13).width = 45;
      worksheet.getColumn(14).width = 17;
      worksheet.getColumn(15).width = 17;
      worksheet.getColumn(16).width = 17;
      worksheet.getColumn(17).width = 17;
      worksheet.getColumn(18).width = 20;
      /////////////////////wrap//////////////////////
      var espacio_interno =['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8','P8',
                          'Q8','R8',]
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(8).height= 30
      /////////////////////ROW//////////////////////

      var numFmtStr = '_(#,##0_);_([Red](#,##0);_("-"??_);_(@_)';
      worksheet.getColumn(7).numFmt =numFmtStr;
      worksheet.getColumn(8).numFmt =numFmtStr;
      worksheet.getColumn(14).numFmt =numFmtStr;
      worksheet.getColumn(15).numFmt =numFmtStr;
      
      for (let x1 in xdatos)
      {
      let x2= xdatos[x1];
      if(x2.fecha_inicio != '-'){
        x2.fecha_inicio = new Date(x2.fecha_inicio)
      }
      if(x2.fecha_final != '-'){
        x2.fecha_final = new Date(x2.fecha_final)
      }
      if(x2.fecha_inicio_mpp != '-'){
        x2.fecha_inicio_mpp = new Date(x2.fecha_inicio_mpp)
      }
      if(x2.fecha_final_mpp != '-'){
        x2.fecha_final_mpp = new Date(x2.fecha_final_mpp)
      }
      console.log(x2,'x2');
      var temp=[]
      for(let y in x2)
      {
      temp.push(xdatos[x1][y])
      }
      worksheet.addRow(temp)
      }
      for (let i = 0; i <= xdatos.length; i++) {

        if(i == 0){
          worksheet.getCell('B9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('C9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('D9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('E9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('F9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('G9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('H9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('I9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('J9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('K9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('L9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('M9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('N9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('O9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('P9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Q9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('R9').alignment = { vertical: 'top', horizontal: 'center' }

        }else{
          var numActu = i + 9 -1;
          var numActuString = numActu.toString()

          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }

          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`J${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`K${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`L${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`M${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`N${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`O${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`P${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Q${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`R${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
        
        if(i == xdatos.length){
            var numActu = i + 9 -1;
            var final_mas_uno = i + 9 + 1
            var final_mas_uno_s = final_mas_uno.toString()

            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`K${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`L${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`M${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`N${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`O${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`P${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Q${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`R${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
        }
    }
    let fname="Reporte de Unidades Producidas Detalladas desde '"+result.fecha_inicio+"' hasta '"+result.fecha_fin+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  async descargarUnidadesVendidasFacturasNotas(xdatos:any,fecha1:any,fecha2:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte de Unidades Vendidas Facturas/Notas",{views: [{showGridLines: false}]});
  
      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });
      
      worksheet.addImage(imageId2,'B2:B7')
      
      worksheet.mergeCells('B2:J2')
      worksheet.getCell('D2').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
  
      worksheet.mergeCells('B3:J3')
      worksheet.getCell('D3').value='Rif J-41031797-3';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};
      
      worksheet.mergeCells('B4:J4')
      worksheet.getCell('D4').value='Reporte General Unidades Vendidas Facturas/Notas';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};
  
      worksheet.mergeCells('B5:J5')
      worksheet.getCell('D5').value='Del "'+fecha1+'" al "'+fecha2+'"';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};
      
      worksheet.mergeCells('B6:J6')
      worksheet.getCell('D6').value='Expresado en Unidades';
      worksheet.getCell('D6').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D6').font={bold: true};
  
      worksheet.mergeCells('B7:J7')
      
      worksheet.mergeCells('D8:F8')
      worksheet.getCell('E8').value='Ventas';
      worksheet.getCell('E8').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('E8').font={bold: true, color: {argb:'FFFFFF'}};
      worksheet.getCell('E8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
  
      worksheet.mergeCells('G8:I8')
      worksheet.getCell('G8').value='Devoluciones';
      worksheet.getCell('G8').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('G8').font={bold: true, color: {argb:'FFFFFF'}};
      worksheet.getCell('G8').border = {
        /* FF0000FF */
        top: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}}
      };
      
      worksheet.autoFilter = {
        from: 'B9',
        to: 'J9',
      }
      
      var fondo_gris_claro =['D8','E8','F8','G8','H8']
      fondo_gris_claro.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          /* bgColor: { argb: '91918e' } */
        };
      })
  
      let header=[" ","Cod producto","Producto","Facturas","Notas de entrega","Total","Facturas","Notas de entrega","Total","Gran total"];
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}}
  
      var border=['B9','C9','D9','E9','F9','G9','H9','I9','J9']
      border.map(celdas => {
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })
  
      var fondo_gris_claro_plus=['B9','C9','D9','E9','F9','G9','H9','I9','J9']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          /* bgColor: { argb: 'adadaa' } */
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 45;
      worksheet.getColumn(4).width = 17;
      worksheet.getColumn(5).width = 17;
      worksheet.getColumn(6).width = 17;
      worksheet.getColumn(7).width = 17;
      worksheet.getColumn(8).width = 17;
      worksheet.getColumn(9).width = 17;
      worksheet.getColumn(10).width = 17;

      var numFmtStr = '_(#,##0_);_([Red](#,##0);_("-"??_);_(@_)';
      worksheet.getColumn(4).numFmt = numFmtStr;
      worksheet.getColumn(5).numFmt = numFmtStr;
      worksheet.getColumn(6).numFmt = numFmtStr;
      worksheet.getColumn(7).numFmt = numFmtStr;
      worksheet.getColumn(8).numFmt = numFmtStr;
      worksheet.getColumn(9).numFmt = numFmtStr;
      worksheet.getColumn(10).numFmt = numFmtStr;
      
      /////////////////////wrap//////////////////////
      var espacio_interno_top =['D8','E8','F8','G8','H8','I8']
      espacio_interno_top.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
      })
      var espacio_interno =['B9','C9','D9','E9','F9','G9','H9','I9','J9',]
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(8).height= 30
      worksheet.getRow(9).height= 30
      /////////////////////ROW//////////////////////
    
      var facturas =0;
      var notas =0;
      var total =0;
      var facturasNc =0;
      var notasNc =0;
      var totalNc =0;
      var granTotal =0;
  
      for (let x1 in xdatos)
    {
    let x2= xdatos[x1];
    console.log(x2,'x2');
    var temp=[]
    for(let y in x2)
    {
    temp.push(xdatos[x1][y])
    }
    worksheet.addRow(temp)
    }
  
    for (const i in xdatos) {
  
      facturas += parseInt(xdatos[i].facturas)
      notas += parseInt(xdatos[i].notas)
      total += parseInt(xdatos[i].total)
      facturasNc += parseInt(xdatos[i].facturasNc)
      notasNc += parseInt(xdatos[i].notasNc)
      totalNc += parseInt(xdatos[i].totalNc)
      granTotal += parseInt(xdatos[i].granTotal)
  
  
    }
   
    for (let i = 0; i <= xdatos.length; i++) {
  
      if(i == 0){
        worksheet.getCell('B10').border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          
        };
      }else{
        var numActu = i + 10 -1;
        var numActuString = numActu.toString()
  
        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
         
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
      }
      if(i == xdatos.length){
        var numActu = i + 10 -1;
        var final_mas_uno = i + 10 + 1
        var final_mas_uno_s = final_mas_uno.toString()
  
        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
  
        worksheet.getCell(`D${final_mas_uno_s}`).value= facturas
        worksheet.getCell(`E${final_mas_uno_s}`).value= notas
        worksheet.getCell(`F${final_mas_uno_s}`).value= total
        worksheet.getCell(`G${final_mas_uno_s}`).value= facturasNc
        worksheet.getCell(`H${final_mas_uno_s}`).value= notasNc
        worksheet.getCell(`I${final_mas_uno_s}`).value= totalNc
        worksheet.getCell(`J${final_mas_uno_s}`).value= granTotal
        
        worksheet.getCell(`D${final_mas_uno_s}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          top: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${final_mas_uno_s}`).border = {
          top: {style:'thin', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${final_mas_uno_s}`).border = {
          top: {style:'thin', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${final_mas_uno_s}`).border = {
          top: {style:'thin', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${final_mas_uno_s}`).border = {
          top: {style:'thin', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${final_mas_uno_s}`).border = {
          top: {style:'thin', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${final_mas_uno_s}`).border = {
          top: {style:'thin', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${final_mas_uno_s}`).border = {
          top: {style:'thin', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
        };
      }
       
    }
   
    
    let fname="Reporte de Unidades Vendidas Facturas/Notas desde '"+fecha1+"' hasta '"+fecha2+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  async descarga_devoluciones_por_fecha_xlsx3(xobjeto:any,fechas:any){

    try {

      let xdatos = xobjeto.map(element => {

        const { cod_solicitud_devolucion,des_dev,cod_documento,cod_nota_credito,tipo_devolucion,cod_producto,
          des_producto,cant_producto,valor_1,fecha_inicio,hora_inicio,cod_cliente,des_cliente,estatus,costo_prod,
          cod_lista_precio_producto, costo_unitario,des_usuario} = element 

      let obj = {
        vacio: '',
        cod_cliente,
        des_cliente,
        des_dev,
        cod_lista_precio_producto,
        cod_solicitud_devolucion,
        cod_documento,
        cod_nota_credito,
        tipo_devolucion,
        cod_producto,
        des_producto,
        cant_producto,
        costo_unitario,
        costo_prod,
        des_usuario,
        fecha_inicio,
        hora_inicio,
        estatus: estatus == 'C'? 'Completada' : estatus == 'V' ? 'Verficada' : estatus == 'P' ? 'Pendiente' : 'Aceptada',
      }

      return obj

    })

  
      console.log(xdatos,'llego a la descarga');
      var indicesExcel = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V',
                            'W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN','AO',
                            'AP','AQ','AR','AS','AT','AU','AV','AW','AX','AY','AZ'];

      let header=['RIF','Cliente','Motivo de Devolución','Lista de precio','Devolucion','Factura N','Nota de Credito','Tipo','Cod Producto',
                  'Producto','Cantidad Devuelto','Costo Unitario','Costo Total','Usuario','Fecha','Hora','Estatus'];
      header.unshift(' ')

      var longitud = header.length;
      var indicesExcelConHeader= [...indicesExcel].splice(1,longitud - 1);
      var ultimoIndice = indicesExcelConHeader[indicesExcelConHeader.length - 1];

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte de Devoluciones",{views: [{showGridLines: false}]});
  
      worksheet.mergeCells(`${indicesExcelConHeader[0]}1:${ultimoIndice}1`)
      worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D1').font={bold: true};
  
      worksheet.mergeCells(`${indicesExcelConHeader[0]}2:${ultimoIndice}2`)
      worksheet.getCell('D2').value='Rif J-41031797-3';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
      
      worksheet.mergeCells(`${indicesExcelConHeader[0]}3:${ultimoIndice}3`)
      worksheet.getCell('D3').value='Reporte General Unidades Vendidas';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};
  
      worksheet.mergeCells(`${indicesExcelConHeader[0]}4:${ultimoIndice}4`)
      worksheet.getCell('D4').value='Del "'+fechas.fecha_inicio+'" al "'+fechas.fecha_fin+'"';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};
      
      worksheet.mergeCells(`${indicesExcelConHeader[0]}5:${ultimoIndice}5`)
      worksheet.getCell('D5').value='Expresado en Unidades';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};
  
      worksheet.mergeCells(`${indicesExcelConHeader[0]}6:${ultimoIndice}6`)
      
      worksheet.autoFilter = {
        from: `${indicesExcelConHeader[0]}7`,
        to: `${ultimoIndice}7`,
      }
      
    
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}}

      var border = ['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7','N7','O7','P7','Q7','R7']
      border.map(borde => {
        worksheet.getCell(borde).border = {
          /* FF0000FF */
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })
  
      var fondo_gris_claro_plus = ['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7','N7','O7','P7','Q7','R7']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          /* bgColor: { argb: 'adadaa' } */
        };
      })
  
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 45;
      worksheet.getColumn(4).width = 45;
      worksheet.getColumn(5).width = 17;
      worksheet.getColumn(6).width = 17;
      worksheet.getColumn(7).width = 17;
      worksheet.getColumn(8).width = 17;
      worksheet.getColumn(9).width = 17;
      worksheet.getColumn(10).width = 17;
      worksheet.getColumn(11).width = 45;
      worksheet.getColumn(12).width = 17;
      worksheet.getColumn(13).width = 17;
      worksheet.getColumn(14).width = 17;
      worksheet.getColumn(15).width = 17;
      worksheet.getColumn(16).width = 17;
      worksheet.getColumn(17).width = 17;
      worksheet.getColumn(18).width = 17;
      worksheet.getColumn(19).width = 17;

      var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
      var numFmtStr_int = '_(#,##0_);_([Red](#,##0);_("-"??_);_(@_)';
      worksheet.getColumn(12).numFmt = numFmtStr_int
      worksheet.getColumn(13).numFmt = numFmtStr
      worksheet.getColumn(14).numFmt = numFmtStr
      /////////////////////wrap//////////////////////
      var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7','N7','O7','P7','Q7','R7']
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(7).height= 30
      /////////////////////ROW//////////////////////
  
      for (let x1 in xdatos)
      {
        
      let x2= xdatos[x1];
      console.log(x2,'x2');
      var temp=[]
      for(let y in x2)
      {
      temp.push(xdatos[x1][y])
      }
      worksheet.addRow(temp)
      }

      for (let i = 0; i <= xdatos.length; i++) {

        var numActu = i + 8 -1;
        var numActuString = numActu.toString()

        if(i == 0){
          worksheet.getCell('B8').border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell('B8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('C8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('D8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('E8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('F8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('G8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('H8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('I8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('J8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('K8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('L8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('M8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('N8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('O8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('P8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Q8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('R8').alignment = { vertical: 'top', horizontal: 'center' }
        }else{
          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
    
          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`J${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`K${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`L${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`M${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`N${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`O${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`P${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Q${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`R${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
        if(i == xdatos.length){
          

          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`J${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`K${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`L${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`M${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`N${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`O${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`P${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Q${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`R${numActuString}`).border = {
            bottom: {style:'double', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
         
      }
     
  
      let fname="Reporte de Devoluciones desde '"+fechas.fecha_inicio+"' hasta '"+fechas.fecha_fin+"'";
      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
      });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }


  }
  async descarga_productos_pedidos(xdatos:any,fechas:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte de Pedidos D56",{views: [{showGridLines: false}]});
      
      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });
      
      worksheet.addImage(imageId2,'B2:B7')
  
      worksheet.mergeCells('B2:AH2')
      worksheet.getCell('D2').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
  
      worksheet.mergeCells('B3:AH3')
      worksheet.getCell('D3').value='Rif J-41031797-3';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};
      
      worksheet.mergeCells('B4:AH4')
      worksheet.getCell('D4').value='Reporte de Pedidos D56';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};
  
      worksheet.mergeCells('B5:AH5')
      worksheet.getCell('D5').value='Del "'+fechas.fecha_inicio+'" al "'+fechas.fecha_fin+'"';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};
      
      worksheet.mergeCells('B6:AH6')
      worksheet.getCell('D6').value='Expresado en Unidades y Dolares';
      worksheet.getCell('D6').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D6').font={bold: true};
  
      worksheet.mergeCells('B7:AH7')
      
      worksheet.autoFilter = {
        from: 'B9',
        to: 'AH9',
      }
      worksheet.addRow('')
      let header=["","Fecha","Mes","Fecha NC","Hora","RIF","Cliente","Nro. Documento","Nro Nota de Credito","Nro. Pedido","Tipo Pedido","Lista de precio","Tipo cliente","Lista precio cliente","Vendedor","Sub-total (BI)",
                "%IVA","IVA","%Descuento","Descuento","%Exento","Exento","Total","Cod producto","Producto","Unidades","Unidades devueltas","Precio Unitario",
                "Sub-total Prod","Precio Producto Dev","Sub-total Dev","Estatus Fiscal","%Descuento Prod","Descuento Prod","Estatus"];
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}}
  
      var border =['B9','C9','D9','E9','F9','G9','H9','I9','J9','K9','L9',
                  'M9','N9','O9','P9','Q9','R9','S9','T9','U9','V9','W9',
                  'X9','Y9','Z9','AA9','AB9','AC9','AD9','AE9','AF9','AG9',
                  'AH9','AI9'];

      border.map(celdas => {
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })
  
      var fondo_gris_claro_plus=['B9','C9','D9','E9','F9','G9','H9','I9','J9','K9','L9' ,
                                'M9','N9','O9','P9','Q9','R9','S9','T9','U9','V9','W9' ,
                                'X9','Y9','Z9','AA9','AB9','AC9','AD9','AE9','AF9','AG9' ,
                                'AH9','AI9'];
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          /* bgColor: { argb: 'adadaa' } */
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 17;
      worksheet.getColumn(4).width = 17;
      worksheet.getColumn(5).width = 17;
      worksheet.getColumn(6).width = 17;
      worksheet.getColumn(7).width = 40;
      worksheet.getColumn(8).width = 20;
      worksheet.getColumn(9).width = 20;
      worksheet.getColumn(10).width = 17;
      worksheet.getColumn(11).width = 17;
      worksheet.getColumn(12).width = 17;
      worksheet.getColumn(13).width = 21;
      worksheet.getColumn(14).width = 17;
      worksheet.getColumn(15).width = 25;
      worksheet.getColumn(16).width = 19;
      worksheet.getColumn(17).width = 17;
      worksheet.getColumn(18).width = 17;
      worksheet.getColumn(19).width = 17;
      worksheet.getColumn(20).width = 17;
      worksheet.getColumn(21).width = 17;
      worksheet.getColumn(22).width = 17;
      worksheet.getColumn(23).width = 17;
      worksheet.getColumn(24).width = 17;
      worksheet.getColumn(25).width = 40;
      worksheet.getColumn(26).width = 17;
      worksheet.getColumn(27).width = 17;
      worksheet.getColumn(28).width = 17;
      worksheet.getColumn(29).width = 17;
      worksheet.getColumn(30).width = 17;
      worksheet.getColumn(31).width = 17;
      worksheet.getColumn(32).width = 17;
      worksheet.getColumn(33).width = 17;
      worksheet.getColumn(34).width = 17;
      var numFmtStr = '_("$" #,##0.00_);_([Red]("$" #,##0.00);_("-"??_);_(@_)';
      var numFmtStrPorce = '_("%" #,##0.00_);_([Red]("%" #,##0.00);_("-"??_);_(@_)';
      worksheet.getColumn(14).numFmt = numFmtStr;
      worksheet.getColumn(15).numFmt = numFmtStr;
      worksheet.getColumn(16).numFmt = numFmtStr;
      worksheet.getColumn(17).numFmt = numFmtStrPorce;
      worksheet.getColumn(18).numFmt = numFmtStr;
      worksheet.getColumn(19).numFmt = numFmtStrPorce;
      worksheet.getColumn(20).numFmt = numFmtStr;
      worksheet.getColumn(21).numFmt = numFmtStrPorce;
      worksheet.getColumn(22).numFmt = numFmtStr;
      worksheet.getColumn(23).numFmt = numFmtStr;
      worksheet.getColumn(28).numFmt = numFmtStr;
      worksheet.getColumn(29).numFmt = numFmtStr;
      worksheet.getColumn(30).numFmt = numFmtStr;
      worksheet.getColumn(31).numFmt = numFmtStr;
      worksheet.getColumn(32).numFmt = numFmtStr;
      worksheet.getColumn(33).numFmt = numFmtStrPorce;
      worksheet.getColumn(34).numFmt = numFmtStr;

      /////////////////////wrap//////////////////////
      var espacio_interno =['B9','C9','D9','E9','F9','G9','H9','I9','J9','K9','L9' ,
                            'M9','N9','O9','P9','Q9','R9','S9','T9','U9','V9','W9' ,
                            'X9','Y9','Z9','AA9','AB9','AC9','AD9','AE9','AF9','AG9' ,
                            'AH9','AI9']
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(9).height= 30
      /////////////////////ROW//////////////////////
  
      for (let x1 in xdatos)
    {
    let x2= xdatos[x1];
    console.log(x2,'x2');
    var temp=[]
    for(let y in x2)
    {
    temp.push(xdatos[x1][y])
    }
    worksheet.addRow(temp)
    }
    
    for (let i = 0; i <= xdatos.length; i++) {
  
      if(i == 0){
        worksheet.getCell('B10').border = {
          bottom: {style:'thin', color: {argb:'00000000'}}, 
        };
        
        worksheet.getCell('B10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('C10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('D10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('E10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('F10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('G10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('H10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('I10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('J10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('K10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('L10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('M10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('N10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('O10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('P10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('Q10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('R10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('S10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('T10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('U10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('V10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('W10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('X10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('Y10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('Z10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AA10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AB10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AC10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AD10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AE10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AF10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AG10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AH10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('AI10').alignment = { vertical: 'top', horizontal: 'center' }
      }else{
        var numActu = i + 10 -1;
        var numActuString = numActu.toString()

        worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`T${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`U${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`V${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`W${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`X${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`Y${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`Z${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AA${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AB${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AC${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AD${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AE${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AF${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AG${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AH${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`AI${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }

  
        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`K${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`L${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`M${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`N${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`O${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`P${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`Q${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`R${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`S${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`T${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`U${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`V${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`W${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`X${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`Y${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`Z${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AA${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AB${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AC${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AD${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AE${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AF${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AG${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AH${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AI${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
      }
      if(i == xdatos.length){
        var numActu = i + 10 -1;
        var final_mas_uno = i + 10 + 1
  
        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`K${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`L${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`M${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`N${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`O${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`P${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`Q${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`R${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`S${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`T${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`U${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`V${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`W${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`X${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`Y${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`Z${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AA${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AB${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AC${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AD${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AE${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AF${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AG${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AH${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`AI${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
      }
    }
    
    
    let fname="Reporte de Pedidos D56 desde '"+fechas.fecha_inicio+"' hasta '"+fechas.fecha_fin+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  async descarga_facturacion_d56(xdatos:any,fechas:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte de Ventas D56",{views: [{showGridLines: false}]});

      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });
      
      worksheet.addImage(imageId2,'B2:B6')

      worksheet.mergeCells('B2:P2')
      worksheet.getCell('D2').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
  
      worksheet.mergeCells('B3:P3')
      worksheet.getCell('D3').value='Rif J-41031797-3';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};
      
      worksheet.mergeCells('B4:P4')
      worksheet.getCell('D4').value='Reporte de Ventas D56';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};
  
      worksheet.mergeCells('B5:P5')
      worksheet.getCell('D5').value='Del "'+fechas.fecha_inicio+'" al "'+fechas.fecha_fin+'"';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};
      
      worksheet.mergeCells('B6:P6')
      worksheet.getCell('D6').value='Expresado en Bolivares y Dolares';
      worksheet.getCell('D6').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D6').font={bold: true};
  
      worksheet.mergeCells('B7:P7')
      
      worksheet.autoFilter = {
        from: 'B9',
        to: 'P9',
      }
      worksheet.addRow('')
      let header=["","Documento","Fecha","Hora","RIF","Cliente","Vendedor","Moneda","Tasa","Monto Total","Monto Base VEF","Monto Base USD","Monto Exento","Monto IVA","Tipo de Cliente","A Cobrar"];
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}}
  
  
      var border =['B9','C9','D9','E9','F9','G9','H9','I9','J9','K9','L9',
                  'M9','N9','O9','P9']

      border.map(celdas =>{
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })
  
      var fondo_gris_claro_plus=['B9','C9','D9','E9','F9','G9','H9','I9','J9','K9','L9'
                                ,'M9','N9','O9','P9']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          /* bgColor: { argb: 'adadaa' } */
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 17;
      worksheet.getColumn(4).width = 17;
      worksheet.getColumn(5).width = 17;
      worksheet.getColumn(6).width = 40;
      worksheet.getColumn(7).width = 20;
      worksheet.getColumn(8).width = 17;
      worksheet.getColumn(9).width = 20;
      worksheet.getColumn(10).width = 20;
      worksheet.getColumn(11).width = 17;
      worksheet.getColumn(12).width = 17;
      worksheet.getColumn(13).width = 17;
      worksheet.getColumn(14).width = 17;
      worksheet.getColumn(15).width = 17;
      worksheet.getColumn(16).width = 17;
      var numFmtStr = '_("$" #,##0.00_);_([Red]("$" #,##0.00);_("-"??_);_(@_)';
      var numFmtStr_ = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
      worksheet.getColumn(8).numFmt = numFmtStr_;
      worksheet.getColumn(9).numFmt = numFmtStr_;
      worksheet.getColumn(10).numFmt = numFmtStr;
      worksheet.getColumn(11).numFmt = numFmtStr_;
      worksheet.getColumn(12).numFmt = numFmtStr;
      worksheet.getColumn(13).numFmt = numFmtStr_;
      worksheet.getColumn(14).numFmt = numFmtStr;
      worksheet.getColumn(16).numFmt = numFmtStr;
      
      /////////////////////wrap//////////////////////
      var espacio_interno =['B9','C9','D9','E9','F9','G9','H9','I9','J9','K9','L9'
                            ,'M9','N9','O9','P9']
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(9).height= 30
      /////////////////////ROW//////////////////////  
      for (let x1 in xdatos)
    {
    let x2= xdatos[x1];
    x2.fecha_inicio = new Date(x2.fecha_inicio)
    console.log(x2,'x2');
    var temp=[]
    for(let y in x2)
    {
    temp.push(xdatos[x1][y])
    }
    worksheet.addRow(temp)
    }
    var total_usd = 0;
    for (const i in xdatos) {
      total_usd += xdatos[i].subtotal_costo_pedid_usd
    }
    for (let i = 0; i <= xdatos.length; i++) {
  
      if(i == 0){
        worksheet.getCell('B10').border = {
          bottom: {style:'thin', color: {argb:'00000000'}}, 
        };
        worksheet.getCell('B10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('C10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('D10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('E10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('F10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('G10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('H10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('I10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('J10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('K10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('L10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('M10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('N10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('O10').alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell('P10').alignment = { vertical: 'top', horizontal: 'center' }
      }else{
        var numActu = i + 10 -1;
        var numActuString = numActu.toString()
        
        worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
        worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
  
        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`K${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`L${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`M${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`N${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`O${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
        };
        worksheet.getCell(`P${numActuString}`).border = {
          bottom: {style:'thin', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}},
        };
      }
      if(i == xdatos.length){
        var numActu = i + 10 -1;
        var final_mas_uno = i + 10 + 1
        var final_mas_uno_s = final_mas_uno.toString()
  
        worksheet.getCell(`B${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`C${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`D${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`E${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`F${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`G${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`H${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`I${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`J${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`K${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`L${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`M${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`N${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`O${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        };
        worksheet.getCell(`P${numActuString}`).border = {
          bottom: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}},
        };
        
        worksheet.getCell(`K${final_mas_uno_s}`).value= total_usd
        worksheet.getCell(`K${final_mas_uno_s}`).border = {
          top: {style:'thin', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
        };
      }
    }
    let fname="Reporte General de Ventas D56 desde '"+fechas.fecha_inicio+"' hasta '"+fechas.fecha_fin+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  async descarga_xlsx_asistencia(xdatos:any,fecha:any,fecha2:any){
    try {
        console.log(xdatos,'llego a la descarga');
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Asistencias",{views: [{showGridLines: false}]});

        const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
        const imageId2 = workbook.addImage({
          buffer: imageBuffer.data,
          extension: 'png',
        });
        worksheet.addImage(imageId2,'B2:B6')

        worksheet.mergeCells('B2:M2')
        worksheet.getCell('D2').value='Factory Cosmetics 3DG, C.A.';
        worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D2').font={bold: true};
  
        worksheet.mergeCells('B3:M3')
        worksheet.getCell('D3').value='Rif J-41031797-3';
        worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D3').font={bold: true};
        
        worksheet.mergeCells('B4:M4')
        worksheet.getCell('D4').value='Reporte de Asistencia por rango de fecha';
        worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D4').font={bold: true};
  
        worksheet.mergeCells('B5:M5')
        worksheet.getCell('D5').value='A la fecha "'+fecha.fecha1+'"-"'+fecha.fecha2+'" ';
        worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D5').font={bold: true};
  
        worksheet.mergeCells('B6:M6')

        worksheet.autoFilter = {
          from: 'B7',
          to: 'M7',
        }

        let header=[" ","Codigo","Nombres y Apellidos","Departamento","Horario","Fecha","Hora","Estatus","Ubicación","Retraso","Entrada-Salida","Comedor","Beneficio Comedor"];
        let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

        var bordes_headers = ['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7']
        bordes_headers.map(celdas =>{
          worksheet.getCell(celdas).border = {
            top: {style:'double', color: {argb:'00000000'}},
            bottom: {style:'double', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}}
          };
        })

        var fondo_gris_claro_plus=['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7']
        fondo_gris_claro_plus.map(celdas => {
          worksheet.getCell(celdas).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00111f'},
            
          };
        })
        fondo_gris_claro_plus.map(centrar => {
          worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
        })
        worksheet.getColumn(1).width = 3.5;
        worksheet.getColumn(2).width = 17;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 20;
        worksheet.getColumn(5).width = 14;
        worksheet.getColumn(6).width = 17;
        worksheet.getColumn(7).width = 17;
        worksheet.getColumn(8).width = 17;
        worksheet.getColumn(9).width = 14;
        worksheet.getColumn(10).width = 14;
        worksheet.getColumn(11).width = 14;
        worksheet.getColumn(12).width = 14;
        worksheet.getColumn(13).width = 14;

        /////////////////////wrap//////////////////////
        var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7']
        espacio_interno.map(celdas =>{
        worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
          
        })
        /////////////////////wrap//////////////////////
        
        /////////////////////ROW//////////////////////
        worksheet.getRow(7).height= 30
        /////////////////////ROW//////////////////////

        for (let x1 of xdatos)
        {
        let x2=Object.keys(x1);
        let temp=[]
        for(let y of x2)
        {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
        }

        for (let i = 0; i <= xdatos.length; i++) {

          if(i == 0){
            worksheet.getCell('B8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('C8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('D8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('E8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('F8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('G8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('H8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('I8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('J8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('K8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('L8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('M8').alignment = { vertical: 'top', horizontal: 'center' }
            
          }else{
            var numActu = i + 8 -1;
            var numActuString = numActu.toString()
  
            worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }

            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`K${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`L${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`M${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
          }
          
          if(i == xdatos.length){
              var numActu = i + 8 -1;
              var final_mas_uno = i + 8 + 1
              var final_mas_uno_s = final_mas_uno.toString()
  
              worksheet.getCell(`B${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
                left: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`C${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`D${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`E${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`F${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`G${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`H${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`I${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`J${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`K${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`L${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`M${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
                right: {style:'double', color: {argb:'00000000'}},
              };
          }
        }
        let fname="Asistencia";

        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname+'.xlsx');
        });
      } catch (error) {
          console.log(error);
          this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
      }
  }
  async descarga_xlsx_comedor(xdatos: any,fechas:any) {
  if (xdatos.length !== 0) {

    try {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Beneficio de comedor al personal, registros",{views: [{showGridLines: false}]});
  
      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });

      worksheet.addImage(imageId2,'B2:B6')

      worksheet.mergeCells('B2:M2')
      worksheet.getCell('D2').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
  
      worksheet.mergeCells('B3:M3')
      worksheet.getCell('D3').value='Rif J-41031797-3';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};
      
      worksheet.mergeCells('B4:M4')
      worksheet.getCell('D4').value='Reporte Beneficio de comedor al personal';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};
  
      worksheet.mergeCells('B5:M5')
      worksheet.getCell('D5').value='Del "'+fechas.fecha_inicio+'" al "'+fechas.fecha_fin+'"';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};
  
      worksheet.mergeCells('B6:M6')
      
      worksheet.autoFilter = {
        from: 'B7',
        to: 'J7',
      }
      let header = [
        ' ',
        'Codigo',
        'Nombre',
        'Departamento',
        'Horario',
        'Fecha',
        'Hora',
        'Estatus',
        'Ubicacion',
        'Descuento'
      ];

      worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

      var bordes_headers = ['B7','C7','D7','E7','F7','G7','H7','I7','J7']
      bordes_headers.map(celdas =>{
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })

      var fondo_gris_claro_plus=['B7','C7','D7','E7','F7','G7','H7','I7','J7']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00111f'},
          
        };
      })

      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 20;
      worksheet.getColumn(5).width = 17;
      worksheet.getColumn(6).width = 17;
      worksheet.getColumn(7).width = 14;
      worksheet.getColumn(8).width = 17;
      worksheet.getColumn(9).width = 17;
      worksheet.getColumn(10).width = 17;

      /////////////////////wrap//////////////////////
      var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7']
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        
      })
      /////////////////////wrap//////////////////////
      
      /////////////////////ROW//////////////////////
      worksheet.getRow(7).height= 30
      /////////////////////ROW//////////////////////

      //Iterando los datos para imprimir la tabla
      for (let x1 of xdatos) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
      }

      for (let i = 0; i <= xdatos.length; i++) {

        if(i == 0){
          worksheet.getCell('B8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('C8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('D8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('E8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('F8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('G8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('H8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('I8').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('J8').alignment = { vertical: 'top', horizontal: 'center' }

        }else{
          var numActu = i + 8 -1;
          var numActuString = numActu.toString()

          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }

          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`J${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
        
        if(i == xdatos.length){
            var numActu = i + 8 -1;
            var final_mas_uno = i + 8 + 1
            var final_mas_uno_s = final_mas_uno.toString()
            
            worksheet.getCell(`T${final_mas_uno_s}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              top: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`U${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`V${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`X${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Z${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AA${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AB${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };

            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
        }
    }
      //Nombre del archivo
      let fname = 'Beneficio de comedor al personal, registros"' ;

      //Descargar archivo Excelt
      workbook.xlsx.writeBuffer()
        .then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname + '-' + new Date().getDate() + '.xlsx');
        });

    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar', {
        duration: 10000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }else{
    this.snackBar.open('No existen datos en la tabla.', 'Cerrar', {
      duration: 1000, horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    })
  }
  }
  async descarga_xlsx_movimiento_producto(xdatos:any,fecha:any){
    try {
        const documentos = await this.servicio.listarDocumentoshttp(await this.atrb.COD_EMPRESA).toPromise();
        const tiempo = await this.servicio.buscarHoraServidorhttp().toPromise();
        let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.EXCEL.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            if (!doc) {
              await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_parametros);
              return;
            }
            var valor = {
                cod_documento: doc,
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
             if (parametro_documento.length <= 0) {
              await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_parametros);
              return;
            }
          for (const i in parametro_documento) {
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                  this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.titulo = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined) {
                  this.path_0 = parametro_documento[i].val_def ? environment.path_storage+parametro_documento[i].val_def : await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                  }else{
                    this.path_0 = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                  this.direccion = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.direccion = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1 ){
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                  this.tel_1 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.tel_1 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2 ){
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                  this.tel_2 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.tel_2 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3 ){
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                  this.tel_3 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.tel_3 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                    this.correo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.correo = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                    this.web = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.web = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                   parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined) {
                  this.rif = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase(): '';
                  }else{
                    this.rif = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                    this.postal = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.postal = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1 ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                    this.mensaje_1 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.mensaje_1 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2 ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                    this.mensaje_2 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.mensaje_2 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3 ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                    this.mensaje_3 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.mensaje_3 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4 ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                    this.mensaje_3_2 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                  }else{
                    this.mensaje_3_2 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                    parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined) {
                  this.path_3 = parametro_documento[i].val_def ? environment.path_storage+parametro_documento[i].val_def : await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                  }else{
                    this.path_3 = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                  }
              }
              if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR ) {
                if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined) {
                  this.path_2 = parametro_documento[i].val_def ? environment.path_storage+parametro_documento[i].val_def : await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                  }else{
                    this.path_2 = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                  }
              }
          }
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Movimientos de inventario",{views: [{showGridLines: false}]});
        const imageBuffer = await axios.get(this.path_0, { responseType: 'arraybuffer' });
        const imageId2 = workbook.addImage({
          buffer: imageBuffer.data,
          extension: 'png',
        });
        worksheet.addImage(imageId2,'B2:B6')
        worksheet.mergeCells('B2:M2')
        worksheet.getCell('D2').value = this.titulo.toUpperCase();
        worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D2').font={bold: true};
        worksheet.mergeCells('B3:M3')
        worksheet.getCell('D3').value= this.rif.toUpperCase();
        worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D3').font={bold: true};
        worksheet.mergeCells('B4:M4')
        worksheet.getCell('D4').value='Reporte Movimientos de inventario';
        worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D4').font={bold: true};
        worksheet.mergeCells('B5:M5')
        worksheet.getCell('D5').value='A la fecha '+tiempo.fecha+'-'+tiempo.hora;
        worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D5').font={bold: true};
        worksheet.mergeCells('B6:M6')
        worksheet.autoFilter = {
          from: 'B7',
          to: 'M7',
        }
        let header=[
          " ",
          "Inventario",
          "Salida",
          "Entrada",
          "Codigo",
          "Producto",
          "Operación",
          "Proceso",
          "PT",
          "MPP",
          "Factura",
          "Nota",
          "Pedido",
          "Cliente",
          "Cantidad",
          "Actual",
          "Restante",
          "Usuario",
          "Fecha",
          "Hora",
          "Comentarios",
        ];
        let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};
        var bordes_headers = ['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7','N7','O7','P7','Q7','R7','S7','T7','U7']
        bordes_headers.map(celdas =>{
          worksheet.getCell(celdas).border = {
            top: {style:'double', color: {argb:'00000000'}},
            bottom: {style:'double', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}}
          };
        })
        var fondo_gris_claro_plus=['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7','N7','O7','P7','Q7','R7','S7','T7','U7']
        fondo_gris_claro_plus.map(celdas => {
          worksheet.getCell(celdas).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00111f'},
          };
        })
        fondo_gris_claro_plus.map(centrar => {
          worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
        })
        worksheet.getColumn(1).width = 3.5;
        worksheet.getColumn(2).width = 17;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 20;
        worksheet.getColumn(5).width = 14;
        worksheet.getColumn(6).width = 17;
        worksheet.getColumn(7).width = 17;
        worksheet.getColumn(8).width = 17;
        worksheet.getColumn(9).width = 14;
        worksheet.getColumn(10).width = 14;
        worksheet.getColumn(11).width = 14;
        worksheet.getColumn(12).width = 14;
        worksheet.getColumn(13).width = 14;
        /////////////////////wrap//////////////////////
        var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7','M7','N7','O7','P7','Q7','R7','S7','T7','U7']
        espacio_interno.map(celdas =>{
        worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        })
        /////////////////////wrap//////////////////////
        /////////////////////ROW//////////////////////
        worksheet.getRow(7).height= 30
        /////////////////////ROW//////////////////////
        for (let x1 of xdatos)
        {
        let x2=Object.keys(x1);
        let temp=[]
        for(let y of x2)
        {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
        }
        for (let i = 0; i <= xdatos.length; i++) {
          if(i == 0){
            worksheet.getCell('B8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('C8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('D8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('E8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('F8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('G8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('H8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('I8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('J8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('K8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('L8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('M8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('N8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('O8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('P8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('Q8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('R8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('S8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('T8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('U8').alignment = { vertical: 'top', horizontal: 'center' }
          }else{
            var numActu = i + 8 -1;
            var numActuString = numActu.toString()
            worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`T${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`U${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`K${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`L${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`M${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`N${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`O${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`P${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Q${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`R${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`S${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`T${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`U${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
          }
          if(i == xdatos.length){
              var numActu = i + 8 -1;
              var final_mas_uno = i + 8 + 1
              var final_mas_uno_s = final_mas_uno.toString()
              worksheet.getCell(`B${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
                left: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`C${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`D${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`E${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`F${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`G${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`H${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`I${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`J${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`K${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`L${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`M${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`N${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`O${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`P${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`Q${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`R${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`S${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`T${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`U${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
                right: {style:'double', color: {argb:'00000000'}},
              };
          }
        }
        let fname="Movimientos de inventario"+' '+tiempo.fecha+'-'+tiempo.hora;
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname+'.xlsx');
        });
      } catch (error) {
          console.log(error);
          await this.notifi.customToastNotification(error.error.message);
      }
  }
  async descarga_xlsx_inventario_producto(xdatos:any,fecha:any){
    try {
      let xvalor = {
        cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
      }
        const documentos = await this.servicio.listarDocumentoshttp(xvalor).toPromise();
        const tiempo = await this.servicio.buscarHoraServidorhttp().toPromise();
        let doc;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.EXCEL.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            if (!doc) {
              await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_parametros);
              return;
            }
            var valor = {
                cod_documento: doc,
                cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
                      if (parametro_documento.length <= 0) {
                        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_parametros);
                        return;
                      }
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                            this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.titulo = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined) {
                            this.path_0 = parametro_documento[i].val_def ? environment.path_storage+parametro_documento[i].val_def : await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                            }else{
                              this.path_0 = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                            this.direccion = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.direccion = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1 ){
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                            this.tel_1 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.tel_1 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2 ){
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                            this.tel_2 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.tel_2 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3 ){
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                            this.tel_3 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.tel_3 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                              this.correo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.correo = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                              this.web = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.web = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                             parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined) {
                            this.rif = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase(): '';
                            }else{
                              this.rif = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                              this.postal = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.postal = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1 ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                              this.mensaje_1 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.mensaje_1 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2 ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                              this.mensaje_2 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.mensaje_2 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3 ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                              this.mensaje_3 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.mensaje_3 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4 ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined ) {
                              this.mensaje_3_2 = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                            }else{
                              this.mensaje_3_2 = await this.atrb.MENSAJES_FIJOS.rft_excel;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                              parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined) {
                            this.path_3 = parametro_documento[i].val_def ? environment.path_storage+parametro_documento[i].val_def : await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                            }else{
                              this.path_3 = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                            }
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR ) {
                          if (parametro_documento[i].estatus.toUpperCase() === await this.atrb.ACTIVO.toUpperCase() && 
                          parametro_documento[i].val_def !== null && parametro_documento[i].val_def !== undefined) {
                            this.path_2 = parametro_documento[i].val_def ? environment.path_storage+parametro_documento[i].val_def : await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                            }else{
                              this.path_2 = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                            }
                        }
                    }
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Inventario",{views: [{showGridLines: false}]});
        const imageBuffer = await axios.get(this.path_0, { responseType: 'arraybuffer' });
        const imageId2 = workbook.addImage({
          buffer: imageBuffer.data,
          extension: 'png',
        });
        worksheet.addImage(imageId2,'B2:B6')
        worksheet.mergeCells('B2:M2')
        worksheet.getCell('D2').value = this.titulo.toUpperCase();
        worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D2').font={bold: true};
        worksheet.mergeCells('B3:M3')
        worksheet.getCell('D3').value= this.rif.toUpperCase();
        worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D3').font={bold: true};
        worksheet.mergeCells('B4:M4')
        worksheet.getCell('D4').value='Reporte de inventario';
        worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D4').font={bold: true};
        worksheet.mergeCells('B5:M5')
        worksheet.getCell('D5').value='A la fecha '+tiempo.fecha+'-'+tiempo.hora;
        worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D5').font={bold: true};
        worksheet.mergeCells('B6:M6')
        worksheet.autoFilter = {
          from: 'B7',
          to: 'J7',
        }
        let header=[
          "",
          "Deposito",
          "Descripción",
          "Producto",
          "Tipo",
          "Nombre",
          "Cantidad",
          "Bultos",
          "Unidad de manejo",
          "Enlace de imagen"
          ];
        let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};
        var bordes_headers = ['B7','C7','D7','E7','F7','G7','H7','I7','J7']
        bordes_headers.map(celdas =>{
          worksheet.getCell(celdas).border = {
            top: {style:'double', color: {argb:'00000000'}},
            bottom: {style:'double', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}}
          };
        })
        var fondo_gris_claro_plus=['B7','C7','D7','E7','F7','G7','H7','I7','J7']
        fondo_gris_claro_plus.map(celdas => {
          worksheet.getCell(celdas).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00111f'},
          };
        })
        fondo_gris_claro_plus.map(centrar => {
          worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
        })
        worksheet.getColumn(1).width = 3.5;
        worksheet.getColumn(2).width = 17;
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(4).width = 20;
        worksheet.getColumn(5).width = 14;
        worksheet.getColumn(6).width = 17;
        worksheet.getColumn(7).width = 17;
        worksheet.getColumn(8).width = 17;
        worksheet.getColumn(9).width = 14;
        worksheet.getColumn(10).width = 14;
        worksheet.getColumn(11).width = 14;
        worksheet.getColumn(12).width = 14;
        worksheet.getColumn(13).width = 14;
        /////////////////////wrap//////////////////////
        var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7']
        espacio_interno.map(celdas =>{
        worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        })
        /////////////////////wrap//////////////////////
        /////////////////////ROW//////////////////////
        worksheet.getRow(7).height= 30
        /////////////////////ROW//////////////////////
        for (let x1 of xdatos)
        {
        let x2=Object.keys(x1);
        let temp=[]
        for(let y of x2)
        {
          temp.push(x1[y])
        }
        worksheet.addRow(temp)
        }
        for (let i = 0; i <= xdatos.length; i++) {
          if(i == 0){
            worksheet.getCell('B8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('C8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('D8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('E8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('F8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('G8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('H8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('I8').alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell('J8').alignment = { vertical: 'top', horizontal: 'center' }
          }else{
            var numActu = i + 8 -1;
            var numActuString = numActu.toString()
            worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'thin', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
          }
          if(i == xdatos.length){
              var numActu = i + 8 -1;
              var final_mas_uno = i + 8 + 1
              var final_mas_uno_s = final_mas_uno.toString()
              worksheet.getCell(`B${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
                left: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`C${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`D${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`E${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`F${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`G${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`H${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`I${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
              };
              worksheet.getCell(`J${numActuString}`).border = {
                bottom: {style:'double', color: {argb:'00000000'}},
                right: {style:'double', color: {argb:'00000000'}},
              };
          }
        }
        let fname="Inventario"+' '+tiempo.fecha+'-'+tiempo.hora;
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, fname+'.xlsx');
        });
      } catch (error) {
          console.log(error);
          await this.notifi.customToastNotification(error.error.message);
      }
  }
}
