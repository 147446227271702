<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">

    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li  class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarRolMenu("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="CREAR ROL" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","insertar")' ><mat-icon >add</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>DEFINICIONES DEL SISTEMA ROL MENU</b></span>
                <div class=" ">
                    <input class="form-control" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table ">
        <div class="col-md-12 px-0 border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_rol">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_rol}} </td>

                </ng-container>

                <ng-container matColumnDef="des_gerencia">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Unidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_gerencia | uppercase}} </td>
                </ng-container>

                <ng-container matColumnDef="des_dpto">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Sub unidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_dpto | uppercase}} </td>
                </ng-container>

                <ng-container matColumnDef="des_rol">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Rol</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_rol | uppercase}} </td>
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus}} </td>
                </ng-container>

                <ng-container matColumnDef="comentarios">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table col-3">
                        <h5><strong>comentarios</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.comentarios}} </td>
                </ng-container>

                <ng-container matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Procesos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center col-2">
                        <button class=" bg-primary mx-2" (click)='update_insert_data(element,"actualizar")'   matTooltip="EDITAR" mat-mini-fab>
                            <mat-icon >edit</mat-icon>
                        </button>
                        <button class="bg-dark mr-1" matTooltip="MAPA DE ACCESO" (click)='permisosUsuarios(element,"insertar")' mat-mini-fab >
                            <mat-icon >settings</mat-icon>
                        </button>
                        <button class=" bg-warning mr-1" matTooltip="MAPA DE ACCIONES" mat-mini-fab (click)='definicionesAcciones(element,"insertar")'>
                            <mat-icon >build</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
