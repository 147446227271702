import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import * as moment from 'moment';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-listar-empleado',
  templateUrl: './form-listar-empleado.component.html',
  styleUrls: ['./form-listar-empleado.component.css']
})
export class FormListarEmpleadoComponent implements OnInit {
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  
  Regex:any = /^[J,V]{1}\d{9}/;
  forma:FormGroup;
  accion:any;
  listas:any = [];
  almacen:any = [];
  actualizar:any = false;
  btnDesabilitado:boolean = true
  dialogSpin:any;

  datos: {} = {
    fecha_inicio: '',
    fecha_fin: ''
  }
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar, public dialog: MatDialog, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormListarEmpleadoComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
    console.log(data); 
     this.accion = data.accion;
     this.forma = fb.group({
       /* DATOS PERSONALES */
       'fecha_inicio':[data.fecha_inicio],
       'fecha_fin':[data.fecha_fin], 
       
     });
   }

  ngOnInit(): void {

  }
  leerDatos(e:any){

    if( this.datos[0] !== '' && this.datos[1] !== ''){
      this.btnDesabilitado = false
    }

    let fecha = moment(e.target.value).format('DD/MM/YYYY')
    let nombre = e.target.name 

    this.datos = {...this.datos, [nombre]:fecha}
  }
  enviarDatos(){
    return this.datos
  }

  Hoy(){
    this.datos = {
      fecha_inicio: moment().format('DD/MM/YYYY'),
      fecha_fin: moment().format('DD/MM/YYYY'),
    }
    return this.datos
  }

  modalrespuesta(){
    var accion2 = 'enviarcliente';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '50%',
      height:'50%',
      data: {id:this.data.Cod_usuario,
            accion:accion2},
      disableClose: true,
      panelClass: 'pedidos_modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(typeof this.forma.value.listas_cliente);
        console.log(this.forma.value, 'que llego aqui');
        this.dialogRefp.close(this.forma.value);
      }
    });
 }
  
  validaNumericos(event) {
    if(event.charCode >= 48 && event.charCode <= 57){
      return true;
     }
    return false;        
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
