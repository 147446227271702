<div id="modal_carrito" class=" container-fluid">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div class="row " style="height: 90%;">
            <div  class="col-md-1">
                
            </div>
            
            <div class="col-md-10 h-100" style="border-radius: 1rem 1rem 0 0;" >
                <div id="carrito_p8" class="row h-100" style=" border: 4px outset #6350cc; border-radius: 1rem 1rem 0 0; background-color:#29388f;">
                    <div class="col-md-12 col-sm-12 col-xs-12 px-2 py-2 table-responsive  h-100" >
                        <table #table mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">

                            <ng-container matColumnDef="cod_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Codigo</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="des_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Definición</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="descrip_definicion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Descripción</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.descrip_definicion}}</b></td>
                        
                            </ng-container>
        
                            <ng-container matColumnDef="cod_usuario">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Id</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_usuario}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="des_usuario">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Usuario</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_usuario}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="estatus">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Estatus</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="operacion">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Operación</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.operacion}}</b></td>
                        
                            </ng-container>

                            <ng-container matColumnDef="fecha">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Fecha</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="hora">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Hora</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora}}</b></td>
                        
                            </ng-container>
                            <ng-container matColumnDef="val_before">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Valor anterior</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;  border-radius: 1rem;"><b>{{element.val_before}}</b></td>
                        
                            </ng-container>

                            <ng-container matColumnDef="val_after">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; ">
                                    <h5><strong>Valor final</strong></h5>
                                </th>
                                <td  mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;  border-radius: 1rem;"><b>{{element.val_after}}</b></td>
                        
                            </ng-container>
                       
        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                        </table>
                        <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
        <div class="row " style="height: 10%;">
            <div  class="col-md-1">
                
            </div>
            <div  class="col-md-10 py-2" style=" border: 4px inset #2b69dd; border-radius: 0 0 1rem 1rem; background-color:#29388f;">
                <div class="row">
                    <div class="col-md-10 ">
                        <mat-form-field class="materialinput py-1 px-1 ">
                            <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                            <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                              <mat-icon>search</mat-icon>
                          </span>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 d-flex  justify-content-end py-2">
                        <button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(221, 19, 97); border: outset 3px rgb(216, 91, 139);" (click)="listar_historial_oper_ventas('actualizar')"><mat-icon >sync</mat-icon></button>
                        <button matTooltip="SALIR" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: #c71010; border: outset 3px #af4b4b;" (click)='cerrarmodal()'><mat-icon >forward</mat-icon></button>
                    </div>
                </div>
            </div>
            <div  class="col-md-1">
                
            </div>
        </div>
    </form>
</div>

