import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormSoporteComponent } from 'src/app/componentes/shared/modal/form-soporte/form-soporte.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';
interface interfacefactura{
  cod_lote: string;
  cant_producto:string;
  cod_producto:string;
  des_producto:string;
  cant_req:string;
  restante:string;
}
interface interdatosfacefactura{
cod_guia_despacho:string;
cod_factura:string;
cod_pedido:string;
fecha_inicio:string;
}
type TableRow = [string, string, string, string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-soporte-tecnico-admin',
  templateUrl: './soporte-tecnico-admin.component.html',
  styleUrls: ['./soporte-tecnico-admin.component.css']
})
export class SoporteTecnicoAdminComponent implements OnInit {

  auth_menu:any = [];

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  cod_orden_producto:any;
  cantidad_prod:any;
  cod_prod:any;
  des_prod:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    ) {

  }

  ngOnInit(): void {
    this.listarSolicitudesSoporte(null);
    this.listarTipoTareas();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

  /////////////////////////////////////////////////////////////////////

 async listarSolicitudesSoporte(msj:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
        let msg = msj;
        let valor = {
          cod_usuario: await this.hashkey.decrypt(await this.atrb.COD_USUARIO.cod_usuario),
          cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
        }
      const response = await  this.servicio.listarSolicitudesSoporteAdminhttp(valor).toPromise();
              this.cod_orden_producto = response[0].cod_orden_producto;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                      cod_ticket:response[i].cod_ticket,
                      tipo_solicitud:response[i].tipo_solicitud,
                      tipo_soporte:response[i].tipo_soporte,
                      cod_usuario: response[i].cod_usuario,
                      des_usuario: response[i].des_usuario,
                      estatus:response[i].estatus,
                      des_tecnico:response[i].des_tecnico,
                      cod_tecnico:response[i].cod_tecnico,
                      fecha_inicio:response[i].fecha_inicio,
                      hora_inicio:response[i].hora_inicio,
                      fecha_fin:response[i].fecha_fin,
                      hora_fin:response[i].hora_fin,
                      eval_soporte:parseFloat(response[i].eval_soporte).toFixed(2),
                      duracion_soporte:response[i].duracion_soporte,
                      descripcion_soporte:response[i].descripcion_soporte,
                      comentarios:'',
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [ 
                                              'cod_ticket',
                                              'tipo_solicitud',
                                              'tipo_soporte',
                                              'des_usuario',
                                              'estatus',
                                              'des_tecnico',
                                              'eval_soporte',
                                              'procesos'
                                            ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                if(msg === await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                    await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                }else{
                  await this.util.cerrarSpinner();
                }
    } catch (error) {
      this.displayedColumns = [ 
                                              'cod_ticket',
                                              'tipo_solicitud',
                                              'tipo_soporte',
                                              'des_usuario',
                                              'estatus',
                                              'des_tecnico',
                                              'eval_soporte',
                                              'procesos'
                                            ];
      this.dataSource = new MatTableDataSource<any>();
      this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }

  listarTipoTareas(){
    try {
      this.servicio.listarTipoTareashttp().subscribe(
        response =>{
          console.log(response);
          for (const i in response) {
            this.xobj4.push(response[i]);
          }
        },
        error =>{
          console.log(error);
        }
      );
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
 }
  
 //////////////////////FUNCION DE ACCION/////////////////////////

tomar_solicitud_soporte(xvalor:any): void {
  try {

this.servicio.consultar_sesionhttp(null).subscribe(
response =>{
if (response.resul) {
  this.accion = 'tomar_solicitud';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
    maxWidth: '100vw',
    height:'100%',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar' &&result != 'salir' ) {
      var usuario_tecnico = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
      var cod_tecnico = localStorage.getItem('cod_usuario');
      console.log(xvalor);
      xvalor.cod_usuario = cod_tecnico;
      xvalor.des_usuario = usuario_tecnico;
      xvalor.comentarios = result.comentario_solicitud;
      console.log(xvalor);
      this.gestion_solicitud_soporte(xvalor);
    }
  });
}else{
this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
},
error =>{
this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
); 
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
public  gestion_solicitud_soporte(xvalor:any){
  try {
    var resultado;
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      
      resultado = this.servicio.gestionTomarSolicitudhttp(xvalor).subscribe(
       response =>{
        this.dialogSpin.close();
         console.log(response.resul,'una respuesta');
         if(response.resul){
           console.log(response.resul,'una respuesta');
           this.xobj2 = response;
           this.ELEMENT_DATA = [];
           for (const i in response.resul) {
            console.log(response[i]);

          this.ELEMENT_DATA.push({
            cod_ticket:response.resul[i].cod_ticket,
            tipo_solicitud:response.resul[i].tipo_solicitud,
            tipo_soporte:response.resul[i].tipo_soporte,
           des_producto:response.resul[i].des_producto,
           cod_usuario:response.resul[i].cod_usuario,
           des_usuario: response.resul[i].des_usuario,
           estatus:response.resul[i].estatus,
           fecha_inicio:response.resul[i].fecha_inicio,
           hora_inicio:response.resul[i].hora_inicio,
           fecha_fin:response.resul[i].fecha_fin,
           hora_fin:response.resul[i].hora_fin,
           duracion_soporte:response.resul[i].duracion_soporte,
           descripcion_soporte:response.resul[i].descripcion_soporte,
           des_tecnico:response.resul[i].des_tecnico,
           cod_tecnico:response.resul[i].cod_tecnico,
           eval_soporte:parseFloat(response.resul[i].eval_soporte).toFixed(2),
           comentarios:'',
           accion:'',
           accion2:'',
           accion3:''
         });
        }

        this.displayedColumns = [ 'cod_ticket',
                                      'tipo_solicitud',
                                      'tipo_soporte',
                                      'des_usuario',
                                      'estatus',
                                      'des_tecnico',
                                      'eval_soporte',
                                      'comentarios',
                                      'tiempo',
                                      'descripcion_soporte',
                                      'confirmar',
                                      'finalizar'
                                    ];
                /* this.dataSource = this.ELEMENT_DATA; */
               this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
               this.dataSource.paginator = this.paginator;
               this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
         }
       },
       error =>{
        this.dialogSpin.close();
         console.log(<any>error.error.message);
         this.displayedColumns = [ 'cod_ticket',
                                      'tipo_solicitud',
                                      'tipo_soporte',
                                      'des_usuario',
                                      'estatus',
                                      'des_tecnico',
                                      'eval_soporte',
                                      'comentarios',
                                      'tiempo',
                                      'descripcion_soporte',
                                      'confirmar',
                                      'finalizar'
                                    ];
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
       }
     );
    }, 3000);
     return resultado;
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
 }
 //////////////////////FUNCION DE ACCION/////////////////////////

 //////////////////////FUNCION DE ACCION/////////////////////////

 finalizar_solicitud_soporte(xvalor:any): void {
   try {
  
this.servicio.consultar_sesionhttp(null).subscribe(
response =>{
if (response.resul) {
  this.accion = 'finalizar_solicitud';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
    maxWidth: '100vw',
    height:'100%',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      xvalor.comentarios = result.comentario_solicitud;
    
      this.gestion_solicitud_soporte_finalizar(xvalor);
    }
  });
}else{
this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
},
error =>{
this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
);  
   } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
   }
}
public  gestion_solicitud_soporte_finalizar(xvalor:any){
  try {
    var resultado;
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      console.log(xvalor);
      resultado = this.servicio.gestionFinalizarSolicitudhttp(xvalor).subscribe(
       response =>{
        this.dialogSpin.close();
         console.log(response.resul,'una respuesta');
         if(response.resul){
           console.log(response.resul,'una respuesta');
           this.xobj2 = response;
           this.ELEMENT_DATA = [];
           for (const i in response.resul) {
            console.log(response[i]);

          this.ELEMENT_DATA.push({
            cod_ticket:response.resul[i].cod_ticket,
            tipo_solicitud:response.resul[i].tipo_solicitud,
            tipo_soporte:response.resul[i].tipo_soporte,
           des_producto:response.resul[i].des_producto,
           cod_usuario:response.resul[i].cod_usuario,
           des_usuario: response.resul[i].des_usuario,
           estatus:response.resul[i].estatus,
           fecha_inicio:response.resul[i].fecha_inicio,
           hora_inicio:response.resul[i].hora_inicio,
           fecha_fin:response.resul[i].fecha_fin,
           hora_fin:response.resul[i].hora_fin,
           duracion_soporte:response.resul[i].duracion_soporte,
           descripcion_soporte:response.resul[i].descripcion_soporte,
           des_tecnico:response.resul[i].des_tecnico,
           cod_tecnico:response.resul[i].cod_tecnico,
           eval_soporte:parseFloat(response.resul[i].eval_soporte).toFixed(2),
           comentarios:'',
           accion:'',
           accion2:'',
           accion3:''
         });
        }


        this.displayedColumns = [ 'cod_ticket',
                                      'tipo_solicitud',
                                      'tipo_soporte',
                                      'des_usuario',
                                      'estatus',
                                      'des_tecnico',
                                      'eval_soporte',
                                      'comentarios',
                                      'tiempo',
                                      'descripcion_soporte',
                                      'confirmar',
                                      'finalizar'
                                    ];
                /* this.dataSource = this.ELEMENT_DATA; */
               this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
               this.dataSource.paginator = this.paginator;
               this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
         }
       },
       error =>{
        this.dialogSpin.close();
         console.log(<any>error.error.message);
         this.displayedColumns = [ 'cod_ticket',
                                      'tipo_solicitud',
                                      'tipo_soporte',
                                      'des_usuario',
                                      'estatus',
                                      'des_tecnico',
                                      'eval_soporte',
                                      'comentarios',
                                      'tiempo',
                                      'descripcion_soporte',
                                      'confirmar',
                                      'finalizar'
                                    ];
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
       }
     );
    }, 3000);

     return resultado;
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
 }
 //////////////////////FUNCION DE ACCION/////////////////////////

//////////////////////FUNCION DE ESTRUCTURA/////////////////////////
mostrar_descrip(obj:any, text:any){

  try {

this.servicio.consultar_sesionhttp(null).subscribe(
response =>{
if (response.resul) {
  this.accion = text;
  this.dialog.open(FormDireccionesComponent, {
    width: '100%',
    height:'100%',
    data: { descripcion_soporte:obj.descripcion_soporte,
            termino: this.accion
          },
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
  });
}else{
this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
},
error =>{
this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
); 

  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

mostrar_tiempo(obj:any, text:any){

  try {

this.servicio.consultar_sesionhttp(null).subscribe(
response =>{
if (response.resul) {
  this.accion = text;
  this.dialog.open(FormDireccionesComponent, {
    width: '100vw',
    maxWidth: '100vw',
    height:'100%',
    data: { fecha_ini:obj.fecha_inicio+' '+obj.hora_inicio,
            fecha_fin:obj.fecha_fin+' '+obj.hora_fin,
            cod_ticket:obj.cod_ticket,
            termino: this.accion
          },
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
  });
}else{
this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
},
error =>{
this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
); 
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

mostrar_comentarios(obj:any, text:any){

  try {

this.servicio.consultar_sesionhttp(null).subscribe(
response =>{
if (response.resul) {
  if (obj.estatus != 'SPRT-P') {
    this.accion = text;
    var ticket = {cod_ticket:obj.cod_ticket}
    this.servicio.buscarComentarioSolicitudhttp(ticket).subscribe(
      response =>{
       
     var  dialogRef =  this.dialog.open(FormmMsgComponent, {
          width: '100vw',
                  maxWidth: '100vw',
                  height:'100%',
          data: { comentario: response[0].comentario,
                  estatus:obj.estatus,
                  accion: this.accion
                },
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result != 'cerrar' && result != 'salir' ) {
            obj.comentarios = result.comentario_solicitud;
            console.log(obj);
           
            this.gestion_actualizar_comentarios(obj);
          }
        });
      },
      error =>{
        
        this.snackBar.open(<any>error.error[0].message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }else{
    this.snackBar.open('La solicitud aun no ha sido tomada por algún técnico', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}else{
this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
},
error =>{
this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
); 

  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

public  gestion_actualizar_comentarios(xvalor:any){
  try {
    var resultado;
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      
      resultado = this.servicio.gestionActualizarComentariosSolicitudhttp(xvalor).subscribe(
       response =>{
        this.dialogSpin.close();
         console.log(response.resul,'una respuesta');
         if(response.resul){
           console.log(response.resul,'una respuesta');
           this.xobj2 = response;
           this.ELEMENT_DATA = [];
           for (const i in response.resul) {
            console.log(response[i]);

          this.ELEMENT_DATA.push({
            cod_ticket:response.resul[i].cod_ticket,
            tipo_solicitud:response.resul[i].tipo_solicitud,
            tipo_soporte:response.resul[i].tipo_soporte,
           des_producto:response.resul[i].des_producto,
           cod_usuario:response.resul[i].cod_usuario,
           des_usuario: response.resul[i].des_usuario,
           estatus:response.resul[i].estatus,
           fecha_inicio:response.resul[i].fecha_inicio,
           hora_inicio:response.resul[i].hora_inicio,
           fecha_fin:response.resul[i].fecha_fin,
           hora_fin:response.resul[i].hora_fin,
           duracion_soporte:response.resul[i].duracion_soporte,
           descripcion_soporte:response.resul[i].descripcion_soporte,
           des_tecnico:response.resul[i].des_tecnico,
           cod_tecnico:response.resul[i].cod_tecnico,
           eval_soporte:parseFloat(response.resul[i].eval_soporte).toFixed(2),
           comentarios:'',
           accion:'',
           accion2:'',
           accion3:''
         });
        }


        this.displayedColumns = [ 'cod_ticket',
                                      'tipo_solicitud',
                                      'tipo_soporte',
                                      'des_usuario',
                                      'estatus',
                                      'des_tecnico',
                                      'eval_soporte',
                                      'comentarios',
                                      'tiempo',
                                      'descripcion_soporte',
                                      'confirmar',
                                      'finalizar'
                                    ];
                /* this.dataSource = this.ELEMENT_DATA; */
               this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
               this.dataSource.paginator = this.paginator;
               this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition,});
         }
       },
       error =>{
        this.dialogSpin.close();
         console.log(<any>error.error.message);
         this.displayedColumns = [ 'cod_ticket',
                                      'tipo_solicitud',
                                      'tipo_soporte',
                                      'des_usuario',
                                      'estatus',
                                      'des_tecnico',
                                      'eval_soporte',
                                      'comentarios',
                                      'tiempo',
                                      'descripcion_soporte',
                                      'confirmar',
                                      'finalizar'
                                    ];
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
       }
     );
    }, 3000);
     return resultado;
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
 }

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
