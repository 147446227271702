import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';

@Component({
  selector: 'app-form-facturar',
  templateUrl: './form-facturar.component.html',
  styleUrls: ['./form-facturar.component.css']
})
export class FormFacturarComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto',{static:true}) cod_producto:ElementRef;
  @ViewChild('cantidad_prod',{static:true}) cantidad_prod:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

value1 = '';
nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
dialogSpin:any;
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
inventario :any;
cod_deposito:any;
observador:any = false;
cant:any = false;
lote:any = true;
des:any;
tipo_cliente:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
displayedColumns:string[];
displayedColumns2:string[];
dataSource1=new MatTableDataSource<any>();
dataSource2=new MatTableDataSource<any>();
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
verticalPosition: MatSnackBarVerticalPosition = 'top';
tipo_documento:any;

  constructor(
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private servicio:nkServices,
    private formb:FormBuilder, 
    private fb:FormBuilder,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private dialogRefp: MatDialogRef<FormFacturarComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_pedido':data.cod_pedido,
        'cod_lista_precio':data.cod_lista_precio,
        'cod_deposito':data.cod_deposito,
        'des_deposito':data.des_deposito,
        'pedido':'',
        'cod_control':'',
        'comentarios':''

      });
    }

  ngOnInit() {
    this.listarFormulaprod(this.data);
  }
  ngAfterViewInit() {}

  async listarFormulaprod(xvalor:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      const response = await this.servicio.buscarProductoPedidohttp(xvalor).toPromise();
       console.log(response,'ver el objeto si esta vacio');
         this.ELEMENT_DATA = [];
        this.tipo_cliente = response.tipo_cliente.mod_consig;
         switch (response.tipo_cliente) {
           case 'SPCL':
                  this.inventario = this.data.des_deposito;
                  this.cod_deposito = this.data.cod_deposito;
             break;
           default:
                  this.inventario = this.data.des_deposito;
                  this.cod_deposito = this.data.cod_deposito;
             break;
         }
             for (const i in response.producto1) {
              if (parseFloat(response.producto2[i].cant_producto) < parseFloat( response.producto1[i].cant_producto)) {
                this.observador = true;
              }else{
                this.observador = false;
              }
               this.ELEMENT_DATA.push({
                 cod_producto:response.producto1[i].cod_producto,
                 des_producto:response.producto1[i].des_producto,
                 cant_unidad_producto:response.producto1[i].cant_producto,
                 cant_inicial:response.producto1[i].cant_producto,
                 costo_producto:response.producto1[i].costo_producto,
                 observador: this.observador
               });
             }
             for (const i in response.producto2) {

              if (parseFloat(response.producto2[i].cant_producto) < parseFloat( response.producto1[i].cant_producto)) {
                this.observador = true;
              }else{
                this.observador = false;
              }
              this.ELEMENT_DATA2.push({
                cod_producto2:response.producto2[i].cod_producto,
                des_producto2:response.producto2[i].des_producto,
                cant_producto2:response.producto2[i].cant_producto,
                observador: this.observador
              });
            }
             this.displayedColumns = [
                                       'cod_producto',
                                       'cant_inicial',
                                       'cant_unidad_producto',
                                       'costo_producto'
                                     ];
              this.displayedColumns2 = [
                                       'cod_producto',
                                       'des_producto',
                                       'cant_producto'
                                     ];
             this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
             await this.util.cerrarSpinner();
    } catch (error) {
            this.displayedColumns = [
              'cod_producto',
              'cant_inicial',
              'cant_unidad_producto',
              'costo_producto'
            ];
            this.displayedColumns2 = [
              'cod_producto',
              'des_producto',
              'cant_producto'
            ];
      this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
      await this.util.cerrarSpinner();
      await this.util.notificacionErrorApp(error.error.message);
    }
 }
 editar_cantidad_pedido(xvalor:any){
   try {
 
     var dialogRef;
     var valor = {
      val_def : 'F',
      tipo_operacion : 'EDITARP',
      cod_usuario : localStorage.getItem('cod_usuario')
     }
     this.servicio.validar_acceso_acciones_http(valor).subscribe(
       response =>{
        dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
          data: { cant_producto: xvalor.cant_unidad_producto,
                  accion:'EFBCK'},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        }); 
        dialogRef.afterClosed().subscribe(result => {
          if(result !== 'cerrar'){
            for (const i in this.ELEMENT_DATA) {
              if (this.ELEMENT_DATA[i].cod_producto === xvalor.cod_producto) {
                this.ELEMENT_DATA[i].cant_unidad_producto = result.cant_producto;
                
              }
            }
            this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
          }
          
      });
       },
       error=>{
        this.snackBar.open(error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
       }
     );
   } catch (error) {
    console.log(error);
   }
 }

 async modalrespuesta(){
  try {
    var key = true;
    var xvalor;
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      for (const i in this.ELEMENT_DATA) {
        if (this.ELEMENT_DATA2[i] === undefined || this.ELEMENT_DATA[i] === undefined ) {
          key = false;
          break;
        }else{
          if (parseInt(this.ELEMENT_DATA[i].cant_unidad_producto)  > parseInt(this.ELEMENT_DATA2[i].cant_producto2)  ) {
            if (this.tipo_cliente.toUpperCase() !== await this.atrb.NON.toUpperCase()) {
              key = false;
            }
            break;
          }
        }
      }
      if (this.ELEMENT_DATA.length === 0) {
        key = false;
      }
      if (key) {
        this.accion = "facturar_pedido";
        var dialogRef;
        let documento;
        if (this.data.tipo_pedido.toUpperCase() === await this.atrb.FACTURA.toUpperCase()) {
          documento = await this.atrb.TIPO_PEDIDO.F.toUpperCase();
        }
        if (this.data.tipo_pedido.toUpperCase() === await this.atrb.PRO_FORMA.toUpperCase()) {
          documento = await this.atrb.TIPO_PEDIDO.PF.toUpperCase();
        }
        if (documento === await this.atrb.TIPO_PEDIDO.F.toUpperCase() ||
            documento === await this.atrb.TIPO_PEDIDO.PF.toUpperCase()) {
           dialogRef = this.dialog.open(FormmMsgComponent, {
            width: '100vw',
                  maxWidth: '100vw',
                  height:'auto',
            data: {id:'',
                  accion:this.accion,
                  tipo_pedido:this.data.tipo_pedido},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
          });
        }else{
          this.accion = "facturar_nota";
          dialogRef = this.dialog.open(FormmMsgComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'auto',
            data: {id:'',
                  accion:this.accion,
                  tipo_pedido:this.data.tipo_pedido},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
          });
        }
    
        dialogRef.afterClosed().subscribe(async result => {
          if(result !== 'cerrar'){
            await this.util.cerrarSpinner();
            this.forma.value.pedido = this.ELEMENT_DATA;
            this.forma.value.cod_control = result.cod_control;
            this.forma.value.comentarios = result.comentarios;
             this.dialogRefp.close(this.forma.value);
          }else{
            await this.util.cerrarSpinner();
          }
        });
      }else{
        if (this.ELEMENT_DATA.length === 0) {
          this.snackBar.open('Inventario no asigando', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }else{
          this.snackBar.open('Inventario insuficiente para cumplir con el pedido Nº: '+this.data.cod_pedido+'', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      }
  } catch (error) {
      await this.util.cerrarSpinner();
      if (error.error === undefined) {
        console.log(error);
      }else{
        await this.util.notificacionErrorApp(error.error.message);
      }
  }
}

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  cantidad_productos() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].cant_unidad_producto)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
  
       return suma.toFixed(0).replace(/\D/g, "")
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  
    }
    cantidad_productos_2() {
      try {
        var suma = 0;
        var matriz = [];
        for (const i in this.ELEMENT_DATA2) {
          matriz.push(this.ELEMENT_DATA2[i].cant_producto2)
        }
        matriz.forEach (function(numero){
               suma += parseInt(numero.replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace(',','.')) ;
           });
  
         return suma.toFixed(0).replace(/\D/g, "")
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
  
      }
    cantidad_item() {
      try {
        return this.ELEMENT_DATA.length;
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
  
    }
    cantidad_item_2() {
      try {
        return this.ELEMENT_DATA2.length;
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
  
    }

    costo_producto() {

      try {
        var suma = 0;
        var matriz = [];
        for (const i in this.ELEMENT_DATA) {
          matriz.push(this.ELEMENT_DATA[i].costo_producto)
        }
        matriz.forEach (function(numero){
               suma += parseFloat(numero.replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace(',','.')) ;
           });
    
         return suma.toFixed(3).replace(/\D/g, "")
         .replace(/([0-9])([0-9]{3})$/, '$1,$2')
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

}
