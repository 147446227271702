
<div id="modal_carrito" class=" container-fluid">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #3649b6; background-color: rgb(37, 39, 150); ">
            <div id="content_1" class="col-md-4 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Codigo del producto</mat-label>
                        <input style="font-size: 11px; color: #fff;" type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup2" #cod_producto>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete #autoGroup2="matAutocomplete">
                            <mat-optgroup style="color: #000; font-size: 12px; " *ngFor="let group of arry | async" [label]="group.letter">
                                <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                    {{i}}
                                </mat-option>

                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_2" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Cantidad</mat-label>
                        <input style="font-size: 11px; color: #fff;" maxlength="8" type="text" matInput placeholder="Cantidad" formControlName="cant_producto" #cant_producto autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">
                            #
                    </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-3 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>search</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" style="color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                        <mat-icon >add_shopping_cart</mat-icon>
                      </button>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">

                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GENERAR ORDEN" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                    <mat-icon >save</mat-icon>
                  </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                          <mat-icon >exit_to_app</mat-icon>
                      </button>
                </div>
            </div>

        </div>
        <div id="carrito_p2" class="row" style=" border: 2px solid #374fd6;">
            <div class="col-md-12 px-0  border h-100" style="background-color: rgb(37, 39, 150);">
                <table #table mat-table [dataSource]="dataSource1" class=" table-primary  text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="cod_formula">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Formula</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_formula}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(37, 39, 150); color: #fff;"> Formula</td>
                    </ng-container>

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(37, 39, 150); color: #fff;"> Codigo</td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150); color: #fff;">Producto </td>
                    </ng-container>


                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.cant_producto}}</td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150); color: #fff;"> Cantidad</td>
                    </ng-container>

                    <ng-container matColumnDef="unidad_prod">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Unidad de medida</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.unidad_prod}}</td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150); color: #fff;"> Unidad de medida</td>
                    </ng-container>

                    <ng-container matColumnDef="detalle">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Detalle</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="detalleOrdenProd(element)" matTooltip="DETALLE" mat-raised-button style="height: 35px; border: 2px solid #374fd6; color:rgb(255, 255, 255); color:rgb(255, 255, 255); background-color: rgb(218, 29, 145);">
                                <mat-icon >image_search</mat-icon>
                                </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(37, 39, 150); color: #fff;">Detalle </td>
                    </ng-container>


                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(37, 39, 150);">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="eliminarItems(element)" matTooltip="QUITAR PRODUCTO" mat-raised-button style="height: 35px; border: 2px solid #374fd6; color:rgb(255, 255, 255); background-color: rgb(37, 39, 150);">
                                <mat-icon >remove_shopping_cart</mat-icon>
                                </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(37, 39, 150); color: #fff;"> Eliminar</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
    </form>

</div>