
<div id="modal_carrito" class=" container-fluid" style="background-color:rgb(22, 136, 73); border-radius: 1rem;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #2f3033; border-radius: 1rem 1rem 0 0;">
            <!-- <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#ca4aaf;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Cliente</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_cliente" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div> -->
            <div class="col-md-4 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:rgb(6, 95, 46)">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Productos</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="producto_pedido" [matAutocomplete]="autoGroup" #producto_pedido>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete (optionSelected)="buscarDataProductoUmCant($event.option.value)" #autoGroup="matAutocomplete" class="px-1">
                            <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                <mat-option   style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                    <b>{{i}}</b>
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_1" class="col-md-1 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:#2f3033">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Existencia</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput  formControlName="cantidad_existencia" #cantidad_existencia autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(255, 94, 0);" *ngIf="!bandera">report_problem</mat-icon>
                </span>
                    </mat-form-field>
                </div>
            </div>
            
            <div id="content_1" class="col-md-1 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:#2f3033">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Cantidad</mat-label>
                        <input style="font-size: 12px; color: #fff; " (keyup)="cantidad_bultos_prod()" maxlength="11" type="text " matInput placeholder="" formControlName="cantidad_bultos" #cantidad_bultos autocomplete="off">
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                </span>
                    </mat-form-field>
                </div>
            </div>

            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 1rem; border: 2px solid #374fd6; background-color:#2f3033">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Facturacón / Tipo cliente</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput  formControlName="mod_factur" #mod_factur autocomplete="off" readonly>
                     
                    </mat-form-field>
                </div>
            </div>

            <div id="content_1" class="col-md-1 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:rgb(6, 95, 46)">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">UM</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="unidad_manejo" #unidad_manejo autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(255, 94, 0);" *ngIf="!bandera_2">report_problem</mat-icon>
                </span>
                    </mat-form-field>
                </div>
            </div>

            <div id="content_1" class="col-md-1 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:rgb(6, 95, 46)">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Solicitado</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="cantidad_pedido" #num autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:rgb(6, 95, 46);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>search</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #2f3033;">

            <div id="content_1" class="col-md-4 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:rgb(6, 95, 46);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Cliente</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_cliente" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-1 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:#2f3033;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Descuento</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="6" #desc_prod matInput formControlName="desc_prod" autocomplete="off" (change)="protec_readonly('1')" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>%</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>

            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="send_product()" matTooltip="CREDENCIALES" style="border-radius: 1rem; border: outset 3px rgb(68, 161, 91); color:rgb(255, 255, 255); background-color: rgb(2, 161, 42);">
                  <mat-icon >lock</mat-icon>
                </button>
                </div>
            </div>

            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class=" fileUpload_listas btn btn-block btn-lg btn-light h-100 py-3" (click)="filter_date_print()"  matTooltip="BAJAR FORMATO" style="border-radius: 1rem; border: outset 3px rgb(65, 52, 131); color:rgb(255, 255, 255); background-color: rgb(38, 21, 126);" >
                        <mat-icon>file_download</mat-icon>
                    </button>
                </div>
            </div>

            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <div class=" fileUpload_listas btn btn-block btn-lg btn-light h-100 py-3"  matTooltip="SUBIR FORMATO" style="border-radius: 1rem; border: outset 3px rgb(102, 100, 100); color:rgb(255, 255, 255); background-color: rgb(29, 29, 29);" >
                        <mat-icon>file_upload</mat-icon>
                        <input class="upload_listas btn btn-block btn-lg btn-light" type="file" (change)="onFileChange($event)" multiple="true" accept=".xlsx, .csv">
                    </div>
                </div>
            </div>
            
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" style="border-radius: 1rem; border: outset 3px rgb(189, 72, 243); color:rgb(255, 255, 255); background-color: rgb(170, 18, 240);">
                  <mat-icon >add_shopping_cart</mat-icon>
                </button>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="recalcularPrecio('calcular','')" matTooltip="ACTUALIZAR LISTA DE PRECIOS" style="border-radius: 1rem; border: outset 3px rgb(222, 78, 210); color:rgb(255, 255, 255); background-color: rgb(223, 14, 205);" >
                <mat-icon >credit_card</mat-icon>
              </button>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GENERAR PEDIDO" style="border-radius: 1rem; border: outset 3px rgb(79, 87, 203); color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);" >
                      <mat-icon >save</mat-icon>
                  </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR" style="border-radius: 1rem; border: outset 3px rgb(199, 88, 80); color:rgb(255, 255, 255); background-color: rgb(201, 51, 40);">
                      <mat-icon >exit_to_app</mat-icon>
                  </button>
                </div>
            </div>
        </div>

        <div id="carrito_p6" class="row" style=" border: 2px solid rgb(22, 136, 73);">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100">
                <table #table mat-table [dataSource]="dataSource1" class=" table-primary  text-center table-striped table-condensed table-hover">
                    <ng-container matColumnDef="cod_lista_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Lista precios</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">TOTAL ITEMS ----> </td>
                    </ng-container>

                    <ng-container matColumnDef="tasa_lista_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Tasa</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tasa_lista_precio}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{cantidad_item()}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lista_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Lista precios N°</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">TOTAL ITEMS ----> </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.des_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">CANTIDAD DE PRODUCTOS EN EL PEDIDO -----> </td>
                    </ng-container>


                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{cantidad_productos()}} </td>
                    </ng-container>

                    <ng-container matColumnDef="costo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Costo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.costo_producto}} $</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{costo_producto()}} $ </td>
                    </ng-container>

                    <ng-container matColumnDef="descuento">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Descuento</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.desc_prod}} % </b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;"></td>
                    </ng-container>

                    <ng-container matColumnDef="monto_prod_descuento">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Monto descuento</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.desc_prod_monto}} $ </b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{costo_producto_descuento_monto()}} $ </td>
                    </ng-container>

                    <ng-container matColumnDef="subtotal_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Sub-total</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.subtotal_producto}} $ </b></td>
                        <td mat-footer-cell class="px-1" *matFooterCellDef style="background-color:#10339d; color: #fff;">{{sub_total_producto()}} $ </td>
                    </ng-container>

                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button class="rounded-pill" (click)="remove(element)" matTooltip="QUITAR PRODUCTO DE LA LISTA" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color:#2f3033; border: solid 2px rgba(8, 56, 100, 0.692);">
                                    <mat-icon >remove_shopping_cart</mat-icon>
                          </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>

        </div>
        <input style="font-size: 12px;" style="display: none;" placeholder="" formControlName="cod_lista_precio" #cod_lista_precio autocomplete="off" readonly>
        <input style="font-size: 12px;" style="display: none;" #iva placeholder="" formControlName="iva_pedido" autocomplete="off" readonly>
        <div id="carrito_p3" class="row mat-elevation-z8" style=" border: 2px solid rgb(22, 136, 73);">
            
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="recalcularPedido('calcular','')" matTooltip="CALCULAR" style="border-radius: 1rem; border: outset 3px rgb(77, 156, 113); color:rgb(255, 255, 255); background-color: rgb(6, 95, 46);"  >
                <mat-icon >announcement</mat-icon>
              </button>
                </div>
            </div>
                
            <div class="col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46); background-color:rgb(6, 95, 46);;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Descuento</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="6" #desc_pedido matInput (keyup)="porcentaje(desc_pedido)" matInput placeholder="" formControlName="desc_pedido" autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">%</span>
                    </mat-form-field>
                </div>

            </div>

            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46);; background-color:rgb(6, 95, 46);;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Exento</mat-label>
                        <input style="font-size: 12px; color: #fff;" #exento_monto matInput placeholder="Sub-total:" formControlName="monto_exento" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">$</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46);; background-color:rgb(6, 95, 46);;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Sub-total</mat-label>
                        <input style="font-size: 12px; color: #fff;" #subtotal_pedido matInput placeholder="Sub-total:" formControlName="subtotal_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">$</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46);; background-color:rgb(6, 95, 46);;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">I.V.A</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="I.V.A:" formControlName="iva_monto" autocomplete="off" #iva_monto readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">$</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46);; background-color:rgb(6, 95, 46);;">
                    <mat-form-field class="materialinput py-1 px-1 ">
                        <mat-label style="color: #fff; font-size: 11px;">Descuento total</mat-label>
                        <input style="font-size: 12px; color: #fff;" #desc_pedido_monto matInput placeholder="" formControlName="desc_pedido_monto" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">$</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(6, 95, 46);; background-color:rgb(6, 95, 46);;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Total</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" #total_pedido matInput placeholder="Total:" formControlName="total_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">$ </span>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </form>
</div>
