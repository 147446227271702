import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

@Component({
  selector: 'app-form-crear-qr-empleados',
  templateUrl: './form-crear-qr-empleados.component.html',
  styleUrls: ['./form-crear-qr-empleados.component.css']
})
export class FormCrearQrEmpleadosComponent implements OnInit {

  Regex:any = /^[J,V]{1}\d{9}/;

  forma:FormGroup;
  accion:any;
  listas:any = [];
  almacen:any = [];
  departamentos:any = [];
  cargos:any = []
  coordinaciones:any = [];
  actualizar:any = false;
  dialogSpin:any;
  existe:any = false;
  existe2:any = false;
  edit:any = false;
  gerencia:any;
  dpto:any;
  cargo:any;
  estatus:any;
 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
              private snackBar: MatSnackBar,
              private dialog: MatDialog, 
              private fb:FormBuilder,
              private dialogRef: MatDialogRef<FormCrearQrEmpleadosComponent>,
              private servicio:nkServices,
              private util:AppUtilService,
              private atrb:AtributosService,
              private hashkey:HashKeyServices,
              private notifi:ToastNotificationService,
    ///en el injectable llega la informacion del metodo padre en data
              @Inject(MAT_DIALOG_DATA) public data: any

  ){ 
     console.log(data,'primero'); 
    this.accion = data.accion;
    if(data.cod_usuario){
      this.edit = true;
    }
    this.forma = fb.group({
      'cod_usuario':[data.cod_usuario,[Validators.required, Validators.pattern(/([0-9]{8})/g)]],
      'dt_primer_apellido':[data.dt_primer_apellido,[Validators.required]], 
      'dt_segundo_apellido':[data.dt_segundo_apellido,[Validators.required]],
      'dt_primer_nombre':[data.dt_primer_nombre,[Validators.required]],
      'dt_segundo_nombre':[data.dt_segundo_nombre,[Validators.required]],
      'dt_dir_1':[data.dt_dir_1],
      'dt_email':[data.dt_email,[Validators.required]],
      'dt_tlfno_emp':[data.dt_tlfno_emp],
      'dt_gene_emp':[data.dt_gene_emp],
      'dt_fecha_naci':[data.dt_fecha_naci],
      'dt_des_pais_ciud_emp':[data.dt_des_pais_ciud_emp],
      'dt_edad':[data.dt_edad],
      'dt_edo_civil':[data.dt_edo_civil],
      'dt_alergia':[data.dt_alergia],
      'dt_mano_d':[data.dt_mano_d],
      'dt_nacion_emp':[data.dt_nacion_emp],
      'dt_vehiculo_emp':[data.dt_vehiculo_emp],
      'dt_licencia':[data.dt_licencia],
      'dt_habita_con':[data.dt_habita_con],
      'dt_habitacion':[data.dt_habitacion],
      'unidad':[data.unidad,[Validators.required]], 
      'dt_cargo':[data.dt_cargo,[Validators.required]],
      'dt_coordinacion':[data.dt_coordinacion,[Validators.required]],
      'estatus':[data.estatus,[Validators.required]],
      'dt_fecha_dispo':[data.dt_fecha_dispo],

      /* DATOS EDUCATIVOS */

      'dt_instit_prim':[data.dt_instit_prim],
      'dt_instit_prim_lugar':[data.dt_instit_prim_lugar],
      'dt_instit_prim_titulo':[data.dt_instit_prim_titulo],
      'dt_fecha_ini_prim':[data.dt_fecha_ini_prim],
      'dt_fecha_fin_prim':[data.dt_fecha_fin_prim],
      'dt_gradu_prim_emp':[data.dt_gradu_prim_emp],
      'dt_instit_secun':[data.dt_instit_secun],
      'dt_instit_secun_lugar':[data.dt_instit_secun_lugar],
      'dt_instit_secun_titulo':[data.dt_instit_secun_titulo],
      'dt_fecha_ini_secun':[data.dt_fecha_ini_secun],
      'dt_fecha_fin_secun':[data.dt_fecha_fin_secun],
      'dt_gradu_secun_emp':[data.dt_gradu_secun_emp],
      'dt_instit_tec':[data.dt_instit_tec],
      'dt_instit_tec_lugar':[data.dt_instit_tec_lugar],
      'dt_instit_tec_titulo':[data.dt_instit_tec_titulo],
      'dt_fecha_ini_tec':[data.dt_fecha_ini_tec], 
      'dt_fecha_fin_tec':[data.dt_fecha_fin_tec],
      'dt_gradu_tec_emp':[data.dt_gradu_tec_emp],
      'dt_instit_univ':[data.dt_instit_univ],
      'dt_instit_univ_lugar':[data.dt_instit_univ_lugar],
      'dt_instit_univ_titulo':[data.dt_instit_univ_titulo],
      'dt_fecha_ini_univ':[data.dt_fecha_ini_univ],
      'dt_fecha_fin_univ':[data.dt_fecha_fin_univ],
      'dt_gradu_univ_emp':[data.dt_gradu_univ_emp],
      'dt_estudios':[data.dt_estudios],
      'dt_instit_act_emp':[data.dt_instit_act_emp],
      'dt_especialidad':[data.dt_especialidad],
      'dt_niv_act_emp':[data.dt_niv_act_emp],
      'dt_horario_act_emp':[data.dt_horario_act_emp],

      /* DATOS FAMILIARES */

      'dt_des_fam_1':[data.dt_des_fam_1],
      'dt_cod_fam_1':[data.dt_cod_fam_1],
      'dt_parent_fam_1':[data.dt_parent_fam_1],
      'dt_edad_fam_1':[data.dt_edad_fam_1],
      'dt_gene_fam_1':[data.dt_gene_fam_1],
      'dt_edo_civil_fam_1':[data.dt_edo_civil_fam_1],
      'dt_ocup_fam_1':[data.dt_ocup_fam_1],
      'dt_niv_acad_fam_1':[data.dt_niv_acad_fam_1],
      'dt_des_fam_2':[data.dt_des_fam_2],
      'dt_cod_fam_2':[data.dt_cod_fam_2],
      'dt_parent_fam_2':[data.dt_parent_fam_2],
      'dt_edad_fam_2':[data.dt_edad_fam_2],
      'dt_gene_fam_2':[data.dt_gene_fam_2],
      'dt_edo_civil_fam_2':[data.dt_edo_civil_fam_2],
      'dt_ocup_fam_2':[data.dt_ocup_fam_2],
      'dt_niv_acad_fam_2':[data.dt_niv_acad_fam_2],
      'dt_des_fam_3':[data.dt_des_fam_3],
      'dt_cod_fam_3':[data.dt_cod_fam_3],
      'dt_parent_fam_3':[data.dt_parent_fam_3],
      'dt_edad_fam_3':[data.dt_edad_fam_3],
      'dt_gene_fam_3':[data.dt_gene_fam_3],
      'dt_edo_civil_fam_3':[data.dt_edo_civil_fam_3],
      'dt_ocup_fam_3':[data.dt_ocup_fam_3],
      'dt_niv_acad_fam_3':[data.dt_niv_acad_fam_3],
      'dt_des_fam_4':[data.dt_des_fam_4],
      'dt_cod_fam_4':[data.dt_cod_fam_4],
      'dt_parent_fam_4':[data.dt_parent_fam_4],
      'dt_edad_fam_4':[data.dt_edad_fam_4],
      'dt_gene_fam_4':[data.dt_gene_fam_4],
      'dt_edo_civil_fam_4':[data.dt_edo_civil_fam_4],
      'dt_ocup_fam_4':[data.dt_ocup_fam_4],
      'dt_niv_acad_fam_4':[data.dt_niv_acad_fam_4],
      'dt_des_fam_5':[data.dt_des_fam_5],
      'dt_cod_fam_5':[data.dt_cod_fam_5],
      'dt_parent_fam_5':[data.dt_parent_fam_5],
      'dt_edad_fam_5':[data.dt_edad_fam_5],
      'dt_gene_fam_5':[data.dt_gene_fam_5],
      'dt_edo_civil_fam_5':[data.dt_edo_civil_fam_5],
      'dt_ocup_fam_5':[data.dt_ocup_fam_5],
      'dt_niv_acad_fam_5':[data.dt_niv_acad_fam_5],
      
      /* DATOS DEL ANTERIOR EMPLEO DEL EMPLEADO */

      'dt_empre_ant_emp':[data.dt_empre_ant_emp],
      'dt_dir_emp_ant':[data.dt_dir_emp_ant],
      'dt_cargo_ant_ini':[data.dt_cargo_ant_ini],
      'dt_cargo_ant_fin':[data.dt_cargo_ant_fin],
      'dt_sueldo_ant_ini_emp':[data.dt_sueldo_ant_ini_emp],
      'dt_sueldo_ant_fin_emp':[data.dt_sueldo_ant_fin_emp],
      'dt_super_ant_emp':[data.dt_super_ant_emp],
      'dt_fecha_ing_emp_ant':[data.dt_fecha_ing_emp_ant],
      'dt_fecha_eg_emp_ant':[data.dt_fecha_eg_emp_ant],
      'dt_retiro_ant_emp':[data.dt_retiro_ant_emp],

      /* BENEFICIOS DEL EMPLEADO EN FACTORYNK */
      'dt_comedor':[data.dt_comedor],
      'cod_empresa':['']
    });

  }
  ngOnInit(){
    this.listarUnidad(null);
  }

  async listarUnidad(xvalor:any){
    try {
      await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        const response = await this.servicio.listarUnidadhttp().toPromise();
        this.cargo = [];
        this.estatus = await this.atrb.ESTATUS;
      if (xvalor !== null) {
        console.log(xvalor);
        if (parseInt(xvalor.value.cod_unidad_padre) <= 0) {
          let xobj = [];
        for (const i in response) {
        if (parseInt(xvalor.value.cod_unidad) ===  parseInt(response[i].cod_unidad_padre) ) {
          xobj.push(response[i]);
        }
        }
          this.dpto = xobj;
          await this.util.cerrarSpinner();
        }else{
          let valor = {
            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
            cod_dpto:xvalor.value.cod_unidad.toString()
          }
          console.log(valor);
          let response = await this.servicio.listarCargoshttp(valor).toPromise();
          console.log(response);
          let xobj = [];
          for (const i in response) {
            xobj.push(response[i].des_cargo);
          }
          this.cargo = xobj;
          await this.util.cerrarSpinner();
        }
    }else{
      let xobj = [];
      for (const i in response) {
       if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() !== await this.atrb.GENERAL.toUpperCase()) {
         xobj.push(response[i]);
       }
      }
         this.gerencia = xobj;
         await this.util.cerrarSpinner();
    }
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.util.notificacionErrorApp(error.error.message);
    }
  }
 async modalrespuesta(){
  try {
    await this.util.abrirSpinner();
    await this.servicio.consultar_sesionhttp(null).toPromise();
    await this.util.cerrarSpinner();
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: { 
              accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop" 
      });
      
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result !== 'cerrar'){
          await this.util.cerrarSpinner();
          this.forma.patchValue({
              unidad: this.forma.value.unidad.des_unidad,
              dt_coordinacion:this.forma.value.dt_coordinacion.des_unidad,
              cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
          });
          this.dialogRef.close(this.forma.value);
        } else{
          await this.util.cerrarSpinner();
        }
      });
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
  
}
 cerrarmodal(){
  this.accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'auto',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(async result => {
    await this.util.abrirSpinner()
    if (result != 'cerrar') {
      await this.util.cerrarSpinner();
      this.dialogRef.close('cerrar');
    }else{
      await this.util.cerrarSpinner()
    }
  });

}

  validaNumericos(event) {
    if(event.charCode >= 48 && event.charCode <= 57){
      return true;
     }
    return false;        
  }

}
