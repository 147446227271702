import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-devolver-formula-producto',
  templateUrl: './devolver-formula-producto.component.html',
  styleUrls: ['./devolver-formula-producto.component.css']
})
export class DevolverFormulaProductoComponent implements OnInit {

  @ViewChild('cant_dev',{static:true}) cant_dev:ElementRef;
  @ViewChild('cantidad_prod_mp',{static:true}) cantidad_prod_mp:ElementRef;

  forma:FormGroup;
  producto:any=[];
  codigo:any=[];

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<DevolverFormulaProductoComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
      this.producto = data.des_prod_mp;
      this.codigo = data.cod_prod_mp;
      this.forma = fb.group({
        'cantidad_prod_mp':data.cantidad_prod_mp,
        'cant_dev':['',[Validators.required]]
      });
    }

  ngOnInit(): void {

  }

  devolucion_prod(xvalor:any){
    try {
        var xobj
        console.log(this.cant_dev.nativeElement.value, 'estamos aqui, aqui nos quedamos');
        if (!isNaN(this.cant_dev.nativeElement.value)) {
          if (parseFloat(this.cant_dev.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) <= parseFloat(this.cantidad_prod_mp.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) && parseFloat(this.cant_dev.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) >= 0  ) {
            xobj =  {cantidad_prod_mp:this.data.cantidad_prod_mp, des_producto:this.data.des_producto,  
                     cod_prod_mp:this.data.cod_prod_mp, tipo_producto_mp:this.data.cod_prod_mp,
                     cantidad_producto:this.data.cantidad_producto,
                     cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
                     cod_producto:this.data.cod_producto, des_prod_mp:this.data.des_prod_mp,
                     unidad_prod_mp:this.data.unidad_prod_mp, dev_producto:xvalor,
                     cod_documento:this.data.cod_documento,
                     cod_usuario: this.data.cod_usuario,
                     des_usuario: this.data.des_usuario,
                    }
            this.dialogRefp.close(xobj);
          }else{
            this.snackBar.open('La cantidad debe ser menor ó igual a la cantidad del producto facturado ', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }else{
          this.snackBar.open('Use solo numeros por favor.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
