<nav class="navbar navbar-expand-md navbar-dark  nav" style="background-color: rgb(0, 0, 0);">
    <a class="navbar-brand"><img *ngIf="img" [src]="img" class="rounded-circle" alt="" width="20px" height="20px"> </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse " id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto ">
            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link " role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [routerLink]="[ '/inicio'] ">Inicio</a>
            </li>
            <ng-container *ngFor="let i of menu4">
                <li class="nav-item dropdown animate__animated animate__fadeIn animate__faster" routerLinkActive="active">
                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{i.des_menu}}
              </a>
                    <div class="dropdown-menu animate__animated animate__fadeIn animate__faster" aria-labelledby="navbarDropdown">
                        <ul class=" list-unstyled">
                            <li *ngFor="let item of menu3">
                                <a *ngIf="item.padre_menu === i.cod_menu" (click)="enviarcodmenu(item.cod_menu,item.dir_menu)" class=" dropdown-item ">{{item.des_menu}}</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ng-container>
        </ul>
        <div class="form-inline my-2 my-lg-0">
            <div (click)="cerrarsesion()" style="font-size: 1.2rem; color: azure;" role="button"><i class="fas fa-sign-out-alt"></i></div>
        </div>
    </div>
</nav>