import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormPtDevolucionesComponent } from '../form-pt-devoluciones/form-pt-devoluciones.component';
import { createViewChild } from '@angular/compiler/src/core';

@Component({
  selector: 'app-form-cantidad-productos-devueltos',
  templateUrl: './form-cantidad-productos-devueltos.component.html',
  styleUrls: ['./form-cantidad-productos-devueltos.component.css']
})
export class FormCantidadProductosDevueltosComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('dev_producto',{static:true})dev_producto:ElementRef;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  forma:FormGroup;
  stateForm: FormGroup;
  
  accion:any;
  dialogSpin:any;
  dataSource1=new MatTableDataSource<any>();
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();
  estatusDev:any; 

  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  xobj:any;
  xobj2:any;
  xobj3:any=[];
  xobj4:any=[];
  xobj5:any=[];
  key_hash:any;
  prueba = true;
  isDisabled= false
  cant:any=false;

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCantidadProductosDevueltosComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      this.accion = data.accion;
      this.estatusDev = data.estatus
      console.log(data)

      this.forma = fb.group({
        'cod_documento':data.cod_documento,
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto': data.cod_producto,
        'des_producto': data.des_producto,
        'cant_producto': data.cant_producto,
        'cod_solicitud_devolucion': data.cod_solicitud_devolucion,
        /* 'dev_producto':'', */
      });
      this.stateForm = this.formb.group({
        stateGroup:'',
      });              
    }

  ngOnInit(): void {
   /*   if(this.estatusDev=== 'A'){
      this.validador();
    }else{
      this.mostrarcantidadproductosdevueltos(this.data.cod_solicitud_devolucion);
    }  */
    this.mostrarcantidadproductosdevueltos(this.data.cod_solicitud_devolucion);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  validador(){

      this.accion = "nota_credito_devolucion";

      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {id:'',
        cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
        cod_documento:this.data.cod_documento,
        accion:this.accion},
        disableClose: true,
        panelClass:'pedidos_modal',
        backdropClass: "bdrop"
       });

       dialogRef.afterClosed().subscribe(result => {
        if(result !== 'cerrar'){
          this.mostrarcantidadproductosdevueltos(this.data.cod_solicitud_devolucion);
        }else{
          this.cerrarmodal();   
        }
      });
  }

  modalrespuesta(){

   for (const element of this.ELEMENT_DATA) {

    if(!element.validar_boton){
      return this.snackBar.open('Debe de seleccionar todos los productos', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
   }
      this.accion = "enviarcliente";

      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {id:'',
        accion:this.accion
        },
        disableClose: true,
        panelClass:'pedidos_modal',
        backdropClass: "bdrop",
      });
      dialogRef.afterClosed().subscribe(result => {
        
        if(result != 'cerrar'){
          this.ELEMENT_DATA2
          this.servicio.devolverProductosFormulashttp(this.ELEMENT_DATA2).subscribe(
            response =>{
      
              this.snackBar.open(response.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
            
            })
                  this.dialogRefp.close(this.forma.value);  
        } 
      });
  }

  mostrarcantidadproductosdevueltos(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width:'15%',
      height:'25%',
      data:{
          accion:this.accion},
      disableClose: true,
      panelClass:'spinner',
      backdropClass:"bdrop"
    });
    setTimeout( async() => {
      this.servicio.mostrarProductosDevueltoshttp(xvalor).subscribe(
        response =>{
          
          this.xobj3 = [];
          for (const i in response[0]){
            this.xobj3.push(response[0][i]);
          }
          if(response){
            this.dialogSpin.close();
            this.xobj2 = response;
            this.ELEMENT_DATA = [];
                for (const i in response) {
                  this.ELEMENT_DATA.push({           
                  cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,             
                  cod_documento:response[i].cod_documento,              
                  cod_producto:response[i].cod_producto,         
                  des_producto:response[i].des_producto,           
                  cant_producto:response[i].cant_producto,
                  validar_boton:false, 
                  accion:this.accion
                  });
                }
                this.displayedColumns = [
                    'cod_producto',
                    'des_producto',
                    'cant_producto',
                    'dev_producto', 
                 ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
          }
        }
      );
    },500);
    return this.xobj2;
  } 
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accio:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

 cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_producto)
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.'));
       });
    return suma;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  async formulas(obj:any,xvalor:any){

    console.log(obj,'prueba')
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
                            var dialogRef;
                            
                                dialogRef =  this.dialog.open(FormPtDevolucionesComponent, {
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    height:'100vh',
                                    data: {
                                           cod_producto: obj.cod_producto,
                                           cod_documento:this.data.cod_documento,
                                           cod_usuario:this.data.cod_usuario,
                                           des_usuario:this.data.des_usuario,
                                           des_producto: obj.des_producto,
                                           cod_prod_mp:obj.cod_prod_mp,
                                           des_prod_mp:obj.des_prod_mp,
                                           tipo_producto_mp:obj.tipo_producto_mp,
                                           cantidad_prod_mp:obj.cantidad_prod_mp,
                                           unidad_prod_mp:obj.unidad_prod_mp,
                                           cantidad_productos: obj.cant_producto,
                                           cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
                                           dev_producto:obj.dev_producto,
                                           ELEMENT_DATA2:this.ELEMENT_DATA2
                                          },
                                          disableClose: true,
                                  panelClass: 'pedidos_modal3',
                                  backdropClass: "bdrop"
                                });
                            dialogRef.afterClosed().subscribe(result => {
                            if(result != 'cerrar' && result != 'salir'){
                              

                              result.cod_dev = this.data.cod_solicitud_devolucion;
                              this.ELEMENT_DATA2 = result;
                             
                              for (let i in this.ELEMENT_DATA) {
                                for (let j in this.ELEMENT_DATA2) {
                               
                                  if(this.ELEMENT_DATA[i].cod_producto === this.ELEMENT_DATA2[j].cod_producto){
                                    let boton = document.getElementById(`${this.ELEMENT_DATA[i].cod_producto}`)
                                    boton.setAttribute("disabled", "true")
                                    boton.style.backgroundColor = 'black'
                                    boton.style.cursor = 'default'
                                    this.ELEMENT_DATA[i].validar_boton = true
                                    
                                    
                                    
                                  }
                                }
                              }
                             
                            }
                          });
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
    }
  }

}
