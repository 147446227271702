<div class="container-fluid">
    <div class="row mat-elevation-z8">
        <div class="col-md-12" style="margin: 0px; padding: 0px;">
            <nav class="navbar navbar-expand-md navbar-dark  nav" style="background-color:steelblue; padding: 7px; margin: 0px;">

                <div class="collapse navbar-collapse " id="navbarSupportedContent" style="padding: 0px; margin: 0px;">

                    <ul class="navbar-nav mr-auto " style="padding: 0px; margin: 0px;">
                        <li class="nav-item" style="padding: 0px; margin: 0px;">

                            <div class=" form-inline my-2 my-lg-0" style="padding: 0px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px; width: 930px; height: 50px; background-color:#cccccccc;">
                                    <div class=" form-group" style="margin: 0px 0px 0px 5px; padding: 0px; width: 120px;">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px;">
                                            <mat-label>Lista de precio N°: </mat-label>
                                            <input #cod_lista matInput placeholder="" autocomplete="off" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>#</mat-icon>
                                                </span>
                                        </mat-form-field>
                                    </div>
                                    <div class=" form-group" style="margin: 0px 0px 0px 10px; padding: 0px; width: 150px;">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px;">
                                            <mat-label>Tasa Base: </mat-label>
                                            <input #tasa_base matInput placeholder="" autocomplete="off" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon></mat-icon>Bs
                                                </span>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-inline my-2 my-lg-0" style="margin: 0px 0px 0px 10px; padding: 0px; width: 120px;">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px;">
                                            <mat-label>Fecha: </mat-label>
                                            <input #fecha_lista matInput placeholder="" autocomplete="off" readonly>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>event</mat-icon>
                                                </span>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                        </li>

                    </ul>

                    <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 100px; height: 50px; ">
                        <div class=" form-group mat-elevation-z8 " style="margin: 0px 0px 0px 4px; ; width:82px ; height: 40px; background-color:#000; color: #fff;">
                            <div class=" form-group  " style="margin:0px; padding: 0px;">
                                <mat-icon class="pedidos2 " (click)="cerrarmodal()" style="font-size: 30px; margin: 5px 5px 18px 26px;" title="Agregar productos">exit_to_app</mat-icon>
                            </div>
                        </div>

                    </div>
                </div>

            </nav>
        </div>
    </div>

    <div class="row container-precios mat-elevation-z8 " style="margin-top: 1%;">

        <div class="container-pedidos2  table-responsive">

            <table #table mat-table [dataSource]="dataSource1" class="  text-center table-striped table-bordered table-condensed table-hover">
                <!-- Weight Column -->
                <!-- <ng-container matColumnDef="id_factura">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                            <h5><strong>Id</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.id_factura}} </td>
                    </ng-container> -->

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.cod_producto}} </td>
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.des_producto}} </td>
                </ng-container>


                <ng-container matColumnDef="precio_producto_base">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                        <h5><strong>Precio base</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px;">{{element.precio_producto_base}}</td>
                </ng-container>

                <ng-container matColumnDef="precio_producto_base_bs">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;">
                        <h5><strong>Precio Bs</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px;"> {{element.precio_producto_base_bs}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
        </div>
    </div>
</div>