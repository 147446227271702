import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'; 
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-menu-usu',
  templateUrl: './menu-usu.component.html',
  styleUrls: ['./menu-usu.component.css']
})
export class MenuUsuComponent implements OnInit, AfterViewInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
 
  constructor(
              private servicio:nkServices,
              private dialog: MatDialog, 
              private util:AppUtilService,
              private atrb:AtributosService,
              private hashkey:HashKeyServices,
              private notifi:ToastNotificationService,
    ) {}
  ngOnInit(){
    this.listarmenu(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }
      async listarmenu(msj:any){
          try {
                await this.util.abrirSpinner();
                await this.servicio.consultar_sesionhttp(null).toPromise();
              let msg = msj;
            const response = await  this.servicio.listarMenuhttp().toPromise();
            this.xobj3 = [];
                  for (const i in response[1]) {
                    response[1][i].cod_unidad = response[1][i].cod_unidad.toString();
                    this.xobj3.push(response[1][i]);
                  }
                  /////////////MENU//////////////////
                  this.xobj4 = [];
                  for (const i in response[2]) {
                    response[2][i].cod_menu = response[2][i].cod_menu.toString();
                    this.xobj4.push(response[2][i]);
                  }
                    this.xobj2 = response;
                    this.ELEMENT_DATA = [];
                        for (const i in response[0]) {
                            
                          this.ELEMENT_DATA.push({
                            cod_menu:response[0][i].cod_menu.toString(),  
                            des_menu: response[0][i].des_menu, 
                            dir_menu:response[0][i].dir_menu, 
                            estatus_menu:response[0][i].estatus_menu,
                            ord_menu:response[0][i].ord_menu,
                            padre_menu:response[0][i].padre_menu,
                            des_padre_menu:response[0][i].des_padre_menu,
                            nivel_padre_menu:response[0][i].nivel_padre_menu,
                            cod_unidad:response[0][i].cod_unidad,
                            des_unidad:response[0][i].des_unidad
                          });
                        }
                        this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_MENU;
                        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                        this.dataSource.paginator = this.paginator;
            
                        if(msg == await this.atrb.ACCION_ACTUALIZAR){
                          await this.util.cerrarSpinner();
                          await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                      }else{
                        await this.util.cerrarSpinner();
                      }
          } catch (error) {
            await this.util.cerrarSpinner();
            this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_MENU;
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
              await this.notifi.customToastNotification(error.error.message);
          }
        }
      
      async update_insert_data(obj:any,text:any){
          try {
            await this.util.abrirSpinner();
            await this.servicio.consultar_sesionhttp(null).toPromise();
                                var dialogRef:any;
                                this.accion = text;
                                if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                                  if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                                    let val_def = {
                                      val_def:await this.atrb.ACCESO_VALDEF.gestionar_licencias,
                                      tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                      cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                                    }
                                    await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                                  }
                                  await this.util.cerrarSpinner();
                                  dialogRef = this.dialog.open(FormMenuComponent, {
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    height:'auto',
                                    data: {cod_menu:obj.cod_menu, 
                                      des_menu: obj.des_menu,
                                      dir_menu: obj.dir_menu, 
                                      estatus_menu:obj.estatus_menu,
                                      ord_menu:obj.ord_menu,
                                      padre_menu:obj.padre_menu,
                                      des_padre_menu:obj.des_padre_menu,
                                      nivel_padre_menu:obj.nivel_padre_menu,
                                      cod_unidad:obj.cod_unidad,
                                      des_unidad: obj.des_unidad,
                                      unidad:this.xobj3,
                                      menu:this.xobj4,
                                      des: this.xobj5,
                                      operacion: await this.atrb.ACCION_ACTUALIZAR,
                                      accion:this.accion},
                                    disableClose: true,
                                    panelClass: 'pedidos_modal_perfiles',
                                    backdropClass: "bdrop"
                                  });
                                }else{
                                  if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                                    let val_def = {
                                      val_def:await this.atrb.ACCESO_VALDEF.gestionar_licencias,
                                      tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                      cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                                    }
                                    await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                                  }
                                  await this.util.cerrarSpinner();
                                  dialogRef = this.dialog.open(FormMenuComponent, {
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    height:'auto',
                                    data: {
                                            menu:this.xobj4, 
                                            unidad:this.xobj3,
                                            operacion: await this.atrb.ACCION_CREAR, 
                                            accion:this.accion},
                                    disableClose: true,
                                    panelClass: 'pedidos_modal_perfiles',
                                    backdropClass: "bdrop"
                                  });
                                }
                                  dialogRef.afterClosed().subscribe(async result => {
                                    await this.util.abrirSpinner()
                                    if(result !== await this.atrb.CERRAR){
                                      this.gestionMenu(result);
                                    }else{
                                      await this.util.cerrarSpinner();
                                    }
                                  });
          } catch (error) {
              await this.util.cerrarSpinner();
              await this.notifi.customToastNotification(error.error.message);
          }
        }
      async  gestionMenu(xvalor:any){
          try {
            xvalor.api = await this.atrb.API_REST.gestionmenu;
          const response = await this.servicio.ApiResthttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
              await this.listarmenu(null);
                  await this.notifi.success(response.message);
          } catch (error) {
            await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(error.error.message);
            await this.listarmenu(null);
          }
        }
        public buscarMenuparam(xvalor:string){
            var valor:any = {valor:xvalor,termino:'sistemas'}
            console.log(valor);
          this.servicio.buscarMENUparamRhttp(valor).subscribe(
            response =>{
              if(response){
                console.log(response, 'busqueda' );
                this.ELEMENT_DATA = [];
                for (const i in response) {
                        
                  this.ELEMENT_DATA.push({
                    cod_menu:response[i].cod_menu,  
                    des_menu: response[i].des_menu, 
                    dir_menu:response[i].dir_menu, 
                    estatus_menu:response[i].estatus_menu,
                    ord_menu:response[i].ord_menu,
                    padre_menu:response[i].padre_menu,
                    des_padre_menu:response[i].des_padre_menu,
                    nivel_padre_menu:response[i].nivel_padre_menu,
                    cod_unidad:response[i].cod_unidad,  
                    des_unidad: response[i].des_unidad, 
                    accion:'',
                    accion2:'',
                    accion3:''
                  });
                }
              this.displayedColumns = ['cod_menu', 
                                        'des_menu',
                                        'dir_menu',
                                        'estatus_menu',
                                        'ord_menu',
                                        'padre_menu',
                                        'des_padre_menu',
                                        'nivel_padre_menu',
                                        'cod_unidad', 
                                        'des_unidad',
                                        'Eliminar', 
                                        'Actualizar'];
                  this.dataSource = new MatTableDataSource<PeriodicElementmenu>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
              }
            },
            error =>{
            }
          );
        }
        applyFilter(event: Event) {
          const filterValue = (event.target as HTMLInputElement).value;
          this.dataSource.filter = filterValue.trim().toLowerCase();
        }
}
