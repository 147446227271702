import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormConsultarFormulaMppComponent } from '../form-consultar-formula-mpp/form-consultar-formula-mpp.component';
import { FormAjustarOrdComponent } from '../form-ajustar-ord/form-ajustar-ord.component';
import { FormInspeccionOrdComponent } from '../form-inspeccion-ord/form-inspeccion-ord.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-revision-ord',
  templateUrl: './form-revision-ord.component.html',
  styleUrls: ['./form-revision-ord.component.css']
})
export class FormRevisionOrdComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('cod_orden_producto',{static:true}) cod_orden_producto:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
producto:any = [];
codigo:any = [];
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
cod_pro:any;

  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   dialogSpin:any;
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormRevisionOrdComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');


      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'cant_producto':'',
        'ord_producto':'',
        'estatus':this.data.estatus,
        'cod_orden_producto':data.cod_orden_producto

      });
    }

  ngOnInit(): void {
    this.cod_orden_producto.nativeElement.value = this.data.cod_orden_producto;
    this.listarProductoOrdenesProdPt(this.data.cod_orden_producto);

  }
  ngAfterViewInit() {

  }

  listarProductoOrdenesProdPt(xvalor:any){
    var valor = {cod_orden_producto:xvalor}
    console.log (valor, '1')
    this.servicio.buscarProductoOrdenesProdPthttp(valor).subscribe(
      response =>{
        console.log(response);
          this.ELEMENT_DATA = [];
              for (const i in response.formulado) {

                this.ELEMENT_DATA.push({
                  cod_producto:response.formulado[i].cod_producto,
                  des_producto:response.formulado[i].des_producto,
                  cant_producto:response.formulado[i].cant_producto,
                  unidad_prod_mp:response.formulado[i].unidad_prod_mp
                });
              }
              for (const i in response.fabricado) {

                this.ELEMENT_DATA2.push({
                  cod_producto2:response.fabricado[i].cod_producto,
                  des_producto2:response.fabricado[i].des_producto,
                  cant_producto2:response.fabricado[i].cant_prod_solicitada
                });
              }
              this.displayedColumns = [
                                        'cod_producto',
                                        'cant_producto',
                                        'unidad_prod_mp'
                                      ];
              this.displayedColumns2 = [
                                        'cod_producto2',
                                        'des_producto2',
                                        'cant_producto2'
                                      ];
              this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
      },
      error =>{
        console.log(error);
        console.log(<any>error.error.message);
        this.displayedColumns = [
                                  'cod_producto',
                                  'des_producto',
                                  'cant_producto'
                                ];
        this.displayedColumns2 = [
                                  'cod_producto2',
                                  'des_producto2',
                                  'cant_producto2'
                                ];
        this.dataSource1 = new MatTableDataSource<any>();
        this.dataSource2 = new MatTableDataSource<any>();

        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }
  listarFormulaprodMpp(xvalor:any){
   var valor = {cod_producto:xvalor}
  this.servicio.buscarFormulaProdMpphttp(valor).subscribe(
    response =>{
      console.log(response);
        this.ELEMENT_DATA = [];
            for (const i in response.producto1) {

              this.ELEMENT_DATA.push({
                cod_producto:response.producto1[i].cod_prod_mp,
                des_producto:response.producto1[i].des_prod_mp,
                cant_producto:response.producto1[i].cantidad_prod_mp,
                requerido_mp:''
              });
            }
            for (const i in response.producto2) {

             this.ELEMENT_DATA2.push({
               cod_producto2:response.producto2[i].cod_producto,
               des_producto2:response.producto2[i].des_producto,
               cant_producto2:response.producto2[i].cant_producto
             });
           }
    },
    error =>{
      console.log(error);
      console.log(<any>error.error.message);

      this.dataSource1 = new MatTableDataSource<any>();
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  );
 }
 cantidad_item() {
  try {
    return this.ELEMENT_DATA.length;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
cantidad_item_2() {
  try {
    return this.ELEMENT_DATA2.length;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

}

modalrespuesta(){
  var key = true;
  var xvalor;
  for (const i in this.ELEMENT_DATA) {
      xvalor = parseInt(this.ELEMENT_DATA[i].cant_producto.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.') ) * parseInt(this.cod_pro.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'));

    if (xvalor > parseInt(this.ELEMENT_DATA2[i].cant_producto2.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.')) ||  this.ELEMENT_DATA2[i].cant_producto2 === null ||  this.ELEMENT_DATA2[i].cant_producto2 === "" ) {
      key = false;
      break;
    }
  }
  if (key) {
    this.accion = "enviarordenprodmpp";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
  maxWidth: '100vw',
  height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        for (const i in this.ELEMENT_DATA) {
          xvalor = parseInt(this.ELEMENT_DATA[i].cant_producto.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.') )* parseInt(this.cod_pro.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'));

          this.ELEMENT_DATA[i].requerido_mp = xvalor.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      }
        this.forma.value.ord_producto = this.ELEMENT_DATA;
        console.log(this.forma.value);
        this.dialogRefp.close(this.forma.value);

      }
    });
  }else{
    this.snackBar.open('Materia prima insuficiente para la fabricacion de este producto', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

}

cerrarmodal(): void {
  this.accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
  maxWidth: '100vw',
  height:'100%',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }

  });

}
cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 2000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  async ajustarOrden(xvalor:any){
    console.log(xvalor);
    var dialogRef:any;
    dialogRef = this.dialog.open(FormInspeccionOrdComponent, {
      width: '100vw',
    maxWidth: '100vw',
  height:'100%',
      data: {cod_usuario:this.data.cod_usuario,
            des_usuario:this.data.des_usuario,
            cod_orden_producto_mpp: this.data.cod_orden_producto_mpp,
            cod_orden_producto: this.data.cod_orden_producto,
            objeto:this.ELEMENT_DATA,
              objeto2:this.ELEMENT_DATA2},
      disableClose: true,
        panelClass: 'pedidos_modal_rev',
        backdropClass: "bdrop"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);

          this.dialogRefp.close(result);
      }
    });

}

}
