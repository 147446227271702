<div id="modal_carrito" class=" container-fluid" style="background-color:rgb(76, 38, 180);">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid rgb(44, 17, 117);">
            <div id="content_1" class=" col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Clientes</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="" formControlName="cod_cliente" [matAutocomplete]="autoGroup" #cliente>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete #autoGroup="matAutocomplete">
                            <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                <mat-option style="font-size: 11px;" *ngFor="let i of group.code" [value]="i">
                                    {{i}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="  col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Cod de la factura</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput placeholder="" formControlName="cod_factura" #cod_factura autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            #
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="  col-md-2 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Concepto</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="" formControlName="producto_pedido" #producto_pedido autocomplete="off">
                    </mat-form-field>
                </div>
            </div>
            <div class="  col-md-2 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Monto</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="17" type="text " matInput placeholder="" (keyup)="format(num)" formControlName="cantidad_pedido" #num autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          #
                      </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" style=" color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                        <mat-icon >add_shopping_cart</mat-icon>
                      </button>
                </div>
            </div>
            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="GENERAR NOTA DE CREDITO" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                        <mat-icon >save</mat-icon>
                    </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div id="carrito_p2" class="row" style="background-color:rgb(76, 38, 180);">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100">
                <table #table mat-table [dataSource]="dataSource1" class=" table-primary text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="item_concepto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Item</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.item_nota}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: rgb(76, 38, 180); color: #fff;">{{cantidad_item()}}</td>
                    </ng-container>
                    <ng-container matColumnDef="lista_precio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Lista precios N°</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="lote_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Lote</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.lote_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="Concepto_nota">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Descripción</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.concepto_nota}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;">TOTAL DEL CONCEPTO ADMINISTRATIVO </td>
                    </ng-container>


                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="costo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Costo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.costo_producto}} </b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Tipo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tipo_producto}}</b> </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="tamaño_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Tamaño</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tamano_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;">TOTAL ITEMS </td>
                    </ng-container>

                    <ng-container matColumnDef="subtotal_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Sub-total</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.subtotal_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;"> {{sub_total_producto()}}</td>
                    </ng-container>

                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(76, 38, 180);">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="remove(element)" matTooltip="QUITAR PRODUCTO DE LA LISTA" mat-raised-button style="border: 2px solid rgb(44, 17, 117); height: 35px; color:rgb(255, 255, 255); background-color: rgb(76, 38, 180);">
                                <mat-icon >remove_shopping_cart</mat-icon>
                                </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(76, 38, 180); color: #fff;"></td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>

        </div>
        <input style="font-size: 12px;" style="display: none;" placeholder="" formControlName="cod_lista_precio" #cod_lista_precio autocomplete="off" readonly>
        <input style="font-size: 12px;" style="display: none;" #iva placeholder="" formControlName="iva_pedido" autocomplete="off" readonly>
        <div id="carrito_p3" class="row mat-elevation-z8" style=" border: 2px solid rgb(44, 17, 117);">
            <div class="col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Items</mat-label>
                        <input style="font-size: 12px; color: #fff;" #items matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-1 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Descuento</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="6" #desc_pedido matInput placeholder="" formControlName="desc_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">%</span>
                    </mat-form-field>
                </div>

            </div>

            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Monto restante</mat-label>
                        <input style="font-size: 12px; color: #fff;" #exento_monto matInput placeholder="Sub-total:" formControlName="monto_exento" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            Bs.
                            </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Sub-total</mat-label>
                        <input style="font-size: 12px; color: #fff;" #subtotal_pedido matInput placeholder="Sub-total:" formControlName="subtotal_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">I.V.A</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="I.V.A:" formControlName="iva_monto" autocomplete="off" #iva_monto readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1 ">
                        <mat-label style="color: #fff; font-size: 11px;">Descuento total</mat-label>
                        <input style="font-size: 12px; color: #fff;" #desc_pedido_monto matInput placeholder="" formControlName="desc_pedido_monto" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs.</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(44, 17, 117); background-color: rgb(76, 38, 180);">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Total</mat-label>
                        <input class="" style="font-size: 12px; color: #fff;" #total_pedido matInput placeholder="Total:" formControlName="total_pedido" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">Bs. </span>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </form>

</div>
