import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-descargar-monto-partidas',
  templateUrl: './form-descargar-monto-partidas.component.html',
  styleUrls: ['./form-descargar-monto-partidas.component.css']
})
export class FormDescargarMontoPartidasComponent implements OnInit {

  

  @ViewChild('cant_inv_sg',{static:true}) cant_inv_sg: ElementRef;
  @ViewChild('cantidad',{static:true}) cantidad: ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
cod_cuenta_gasto:any;
des_cuenta_gasto:any;
cant_cuenta_gasto:any;
presupuesto:any;
cod_centro:any;
dialogSpin:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
nombre_producto:any;
codigo_producto:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  msg:any;
  telRegex:any = /\d{2}-\d{5}\d/;

  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, fb:FormBuilder, private servicio:nkServices,
    public dialogRefp: MatDialogRef<FormDescargarMontoPartidasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
      this.cod_cuenta_gasto = this.data.cod_cuenta_gasto;
      this.cod_centro = this.data.cod_centro;
      this.des_cuenta_gasto = this.data.des_cuenta_gasto;
      this.cant_cuenta_gasto = this.data.cant_cuenta_gasto;


          this.forma = fb.group({
            'cod_usuario':'',
            'cod_cuenta_gasto':this.cod_cuenta_gasto,
            'cod_centro':this.cod_centro,
            'des_usuario':'',
            'comentarios':['',[Validators.required]],
            'cant_cuenta': this.cant_cuenta_gasto,
            'cantidad': '0',
            'tipo_operacion':'',
            'cod_presupesto': this.data.cod_presupesto,
            'des_cuenta_gasto': this.des_cuenta_gasto
          });
    }
  ngOnInit(): void {
  }

  buscar_Partida(xvalor:any){
    var valor = {cod_cuenta_gasto: xvalor,
                cod_centro: this.data.cod_centro}
    try {
        this.servicio.buscarPartidahttp(valor).subscribe(

          response =>{

            this.cant_inv_sg.nativeElement.value = response[0].cant_cuenta_gasto;

          },
          error =>{
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }

        );
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  calculos_cant_cuenta(valor:any):void{
    try {
      if ( isNaN(this.cantidad.nativeElement.value) || this.cantidad.nativeElement.value === '' || parseFloat(this.cantidad.nativeElement.value) <= 0) {
        this.snackBar.open('El campo necesita una cantidad a procesar y no se debe colocar letras', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.cantidad.nativeElement.value = '';
      }else{
        
        if (isNaN(valor.value) || valor.value === '') {
          this.cantidad.nativeElement.value = '0';
       }
      }
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  modalrespuesta(){
    try {
      console.log(this.forma.value.cant_cuenta,'cual');
      console.log(this.cantidad.nativeElement.value,'cual2');

      if (parseFloat(this.cantidad.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) > 0 ) {
            if(parseFloat(this.cantidad.nativeElement.value) != NaN){
            var accion2 = 'traslados';
        
            var cod_usuario = localStorage.getItem('cod_usuario');
            var des_usuario = localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido');

            this.forma.value.cod_usuario = cod_usuario;
            this.forma.value.des_usuario = des_usuario;
            this.forma.value.cant_partida = this.cantidad.nativeElement.value;
            this.forma.value.cant_producto_sg = this.cant_inv_sg.nativeElement.value;
            this.forma.value.cant_cuenta_gasto  =  this.cant_cuenta_gasto;
            this.forma.value.cod_cuenta_gasto  =  this.data.cod_cuenta_gasto;
            this.forma.value.cod_centro  =  this.data.cod_centro;
            this.forma.value.cod_presupuesto  =  this.data.cod_presupesto;
            this.forma.value.tipo_operacion  =  'carga';
            this.forma.value.des_cuenta_gasto  =  this.data.des_cuenta_gasto
            this.forma.value.comentarios  =  this.forma.value.comentarios,

            this.dialogRefp.close(this.forma.value);
            this.procesar_descarga(this.forma.value);
        
            }else{
              this.snackBar.open('Solo se deben ingresar numeros', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
      }else{
        this.snackBar.open('La cantidad debe ser igual y mayor a 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
        }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:20000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
}

procesar_descarga(xvalor:any):void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.servicio.procesarDescargahttp(xvalor).subscribe(
        response =>{
          this.dialogSpin.close();
            console.log(response,'valores');
            this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
              this.dialogRefp.close();

        },
        error =>{
          this.dialogSpin.close();
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
          console.log(<any>error.error.message,'error definiciones');
        }
      );
      
    }, 500);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
    }else{

    console.log(this.forma.value);
    this.dialogRefp.close(this.forma.value);
    }

  }

  cerrarmodal(): void {
    let accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  format(valor:any)
{
  try {
    var num = valor.value.replace(/\D/g,'');
if(!isNaN(num)){
num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
num = num.split('').reverse().join('').replace(/\D/g, "")
.replace(/([0-9])([0-9]{2})$/, '$1,$2')
.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
valor.value = num;

}
else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
  verticalPosition: this.verticalPosition,});
  valor.value = valor.value.replace(/[^\d\.]*/g,'');
}
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
ponerPuntosyComas(event:any){
  let validacion = '1234567890'
  console.log(event.key)
  let inputCantidad = document.querySelector('#cantidad') as HTMLInputElement
  if(inputCantidad.value.length == 1){
    let noa = inputCantidad.value.split('')
    noa.unshift('0')
    noa.unshift('0')
    inputCantidad.value = noa.join('')
  }else if(inputCantidad.value.length <= 2){
    let noa = inputCantidad.value.split('')
    noa.unshift('0')
    inputCantidad.value = noa.join('')
  }else if(inputCantidad.value.length >= 4){
    let noa:any = inputCantidad.value.split('')
    for(let i = 0 ; i < 2; i++){
      if(noa[0] == 0){
        noa.shift()
      }else{
        break;
      }
    }
    inputCantidad.value = noa.join('')
  }
  var num = inputCantidad.value.replace(/\D/g,'');
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  inputCantidad.value = num;
  if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
    this.snackBar.open('Solo numeros', 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

}
