import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { Element } from '@angular/compiler';
import { element } from 'protractor';
import * as moment from 'moment'
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormCotizacionReqComponent } from 'src/app/componentes/shared/modal/form-cotizacion-req/form-cotizacion-req.component';
import { FormAprobarCotizacionComponent } from 'src/app/componentes/shared/modal/form-aprobar-cotizacion/form-aprobar-cotizacion.component';
import { FormAprobarSolicitudCompraComponent } from 'src/app/componentes/shared/modal/form-aprobar-solicitud-compra/form-aprobar-solicitud-compra.component';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';


@Component({
  selector: 'app-form-cotizacion-ver-solicitudes',
  templateUrl: './form-cotizacion-ver-solicitudes.component.html',
  styleUrls: ['./form-cotizacion-ver-solicitudes.component.css']
})
export class FormCotizacionVerSolicitudesComponent implements OnInit {

  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dolar1:any = 0
  dolar2:any = 0
  dolar3:any = 0
  bolivar1:any = 0
  bolivar2:any = 0
  bolivar3:any = 0

  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  aprobar:any=false

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';



  constructor( public dialog: MatDialog,fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCotizacionVerSolicitudesComponent>,
    ///en el injectable llega la informacion del metodo padre en data
   @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices) {}


   ngOnInit(): void {
    this.listarSolicitudesCompra()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  listarSolicitudesCompra(){

    try {
      // let msg = msj;
       this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
         width: '15%',
         height:'25%',
         data: {
                 accion:this.accion},
         disableClose: true,
         panelClass: 'spinner',
         backdropClass: "bdrop"
       });
 
       setTimeout(() => {
         this.servicio.listarSolicituComprahttp(localStorage.getItem('cod_usuario')).subscribe(
           response =>{
             console.log(response)
             if(response){
               this.dialogSpin.close();
               console.log(response,'una respuesta');
               let xobj2 = response.result;
               let array = [];
                   for (const i in xobj2) {
 
                 array.unshift({
                       
                 id_solicitud_compra:xobj2[i].id_solicitud_compra,
                 cod_solicitud_compra: xobj2[i].cod_solicitud_compra,
                 cod_orden_req:xobj2[i].cod_orden_req ? xobj2[i].cod_orden_req : '-',
                 cod_cotizacion_req:xobj2[i].cod_cotizacion_req,
                 tipo_solicitud_compra:xobj2[i].tipo_solicitud_compra,
                 tipo_producto: xobj2[i].tipo_producto,
                 cod_usuario:xobj2[i].cod_usuario,
                 des_usuario:xobj2[i].des_usuario,
                 departamento: xobj2[i].departamento,
                 cod_centro_costo: xobj2[i].cod_centro_costo,
                 cod_cuenta_gasto: xobj2[i].cod_cuenta_gasto,
                 cod_presupuesto: xobj2[i].cod_presupuesto,
                 observaciones: xobj2[i].observaciones,
                 observaciones_aprobacion: xobj2[i].observaciones_aprobacion,
                 modalidad: xobj2[i].modalidad,
                 prioridad: xobj2[i].prioridad,
                 fecha_estimada: xobj2[i].fecha_estimada,
                 estatus:xobj2[i].estatus,
                 fecha:xobj2[i].fecha,
                  accion:''
                     });
                   }
 
                 
                 let aprobado = array.filter(element => element.estatus == 'SCA')
                 let desaprobado = array.filter(element => element.estatus == 'SCD')
 
                 this.ELEMENT_DATA = [...aprobado,...desaprobado]
               
                   this.displayedColumns = [
                     'cod_solicitud_compra',
                     'cod_orden_req',
                     'tipo_solicitud_compra',
                     'tipo_producto',
                     'estatus',
                     'departamento',
                     'fecha',
                     'fecha_estimada',
                     'prioridad',
                     'aprobado'
                   ];
                    /* this.dataSource = this.ELEMENT_DATA; */
                   this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                   this.dataSource.paginator = this.paginator;
             }
           },
           error =>{
             this.dialogSpin.close();
             console.log('una respuesta');
             console.log(error.error.message);
             this.displayedColumns = [
               'cod_solicitud_compra',
               'cod_orden_req',
               'tipo_solicitud_compra',
               'tipo_producto',
               'estatus',
               'departamento',
               'fecha',
               'fecha_estimada',
               'prioridad',
               'aprobado'
             ];
             this.dataSource = new MatTableDataSource<any>();
                   this.dataSource.paginator = this.paginator;
                   this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                     verticalPosition: this.verticalPosition,});
           }
         );
         return this.xobj2;
       }, 1000);
 
     } catch (error) {
       this.dialogSpin.close();
       this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
  }

  


  verAprobacion(element){
    try {

      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
      cod_menu: localStorage.getItem('sub_main'),
      cod_sesion: localStorage.getItem('cod_sesion'),
      dir_ip_client: localStorage.getItem('dir_ip'),
      sesion_unica:sessionStorage.getItem('sesion_unica')};

      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

        element.accion = 'ver'

        var dialogRef;
        dialogRef = this.dialog.open(FormAprobarSolicitudCompraComponent,{
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        disableClose:   true,
        data:element,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });

        dialogRef.afterClosed().subscribe(resul => {
          this.listarSolicitudesCompra()
        })

      })

      
    }  catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }


 
  

  en_construccion(): void {

    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    } catch (error) {
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
