

<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">

    <div class="row" style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: #045fb4; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>GESTIONAR CENTRO DE COSTO</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarCentroCosto("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="CREAR CENTRO DE COSTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='crear_centro_costo("","insertar")' ><mat-icon >add</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="IMPORTAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(79, 9, 243);" (click)='importar_crear_centro_costo("","insertar")' ><mat-icon >cloud_upload</mat-icon></button></li>
                    <li class="nav-item"><button matTooltip="GESTIONAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(79, 9, 243);" (click)='parametros_acciones_create("")' ><mat-icon >extension</mat-icon></button></li>


                </ul>
                 <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div> 
            </div>
        </nav>
    </div>

    <div class="row " style="height: 78.7%;">
        <div class="col-md-12 px-0  border h-100 " style="background-color: #045fb4; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class=" table-primary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_centro">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Cod centro de costo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_centro}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

                <ng-container matColumnDef="des_unidad">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Unidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_unidad}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

                <ng-container matColumnDef="cant_centro">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Monto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_centro}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;">Total {{total_cc | puntoporcoma}}</td>
                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Cedula</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_usuario}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Nombre</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha_inicio}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_inicio}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

                <ng-container matColumnDef="cuentas"> 
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Crear partidas</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button  matTooltip="Partidas" mat-raised-button (click)="cuentas(element,'dev')"  style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);"> <!-- *ngIf="create" -->
                            <mat-icon>contacts</mat-icon>
                        </button>
                       <button  *ngIf="!create" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >credit_card</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

               <ng-container matColumnDef="listar_cuentas"> 
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Listar partidas</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button  matTooltip="Listar partidas" mat-raised-button (click)="listar_cuentas(element,'dev')"  style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);"> <!-- *ngIf="create"  -->
                            <mat-icon>account_balance</mat-icon>
                        </button>
                       <button  *ngIf="!create" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >credit_card</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container>

                <ng-container matColumnDef="listar_partidas"> 
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #045fb4;">
                        <h5><strong>Listar presupuesto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button  matTooltip="Listar presupuesto" mat-raised-button (click)="listar_partidas(element,'dev')"  style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);"> <!-- *ngIf="create"  -->
                            <mat-icon>account_balance_wallet</mat-icon>
                        </button>
                       <button  *ngIf="!create" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >credit_card</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                </ng-container> 

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns" class="mat-paginator-sticky"></tr>
            </table>

           <mat-paginator class="w-100 mat-paginator-sticky border" style="background-color: #045fb4;" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 

        </div>
    
    </div>

</div>
