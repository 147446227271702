
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li  class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listardatos("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li  class="nav-item"><button matTooltip="CREAR USUARIO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","insertar")'><mat-icon >add</mat-icon></button></li>
                    <!-- <li  class="nav-item"><button matTooltip="INGRESO MASIVO DE DATOS EN EXCEL" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(15, 131, 44);" (click)='en_construccion()'><mat-icon >attach_file</mat-icon></button></li> -->
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>DEFINICIONES USUARIOS DEL SISTEMA </b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table ">

        <div class="col-md-12 px-0 border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_img">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table" >
                        <h5><strong>Imagen</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> <img width="50px" height="50px" class="rounded" [src]="element.cod_img" alt=""></td>
                </ng-container>
                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Id</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_usuario | uppercase}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_unidad">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Unidad </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_unidad}} </td>
                </ng-container>

                <ng-container matColumnDef="des_unidad">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Unidad </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_unidad}} </td>
                </ng-container>

                <ng-container matColumnDef="Nombre">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Nombre </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.nombre}} </td>
                </ng-container>

                <ng-container matColumnDef="Nombre_de_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Usuario </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.nom_usuario}} </td>
                </ng-container>

                <ng-container matColumnDef="Apellido">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Apellido </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.apellido}} </td>
                </ng-container>

                <ng-container matColumnDef="Estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Estatus </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus}} </td>
                </ng-container>

                <ng-container matColumnDef="licencia">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Licencia </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.mod_usuario}} </td>
                </ng-container>

                <ng-container  matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Procesos</strong></h5>
                    </th>

                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="col-md-1" >
                        <button class=" bg-primary mr-1"   matTooltip="EDITAR" mat-mini-fab (click)='update_insert_data(element, "actualizar")'>
                            <mat-icon >edit</mat-icon>
                        </button>
                        <button *ngIf="element.des_dpto === 'Administrador' || element.des_dpto === 'Empresas'" class=" bg-warn mr-1"   matTooltip="GALERIA" mat-mini-fab (click)='definicionesGaleria(element)'>
                            <mat-icon >image</mat-icon>
                        </button>
                        <!-- <button class=" bg-success mr-1" matTooltip="ACCESOS" mat-mini-fab (click)='permisosUsuarios(element,"insertar")'>
                            <mat-icon >lock</mat-icon>
                        </button>
                        <button class=" bg-info mr-1" matTooltip="ACCIONES" mat-mini-fab (click)='definicionesAcciones(element,"insertar")'>
                            <mat-icon >settings</mat-icon>
                        </button>
                        <button class=" bg-warn mr-1" matTooltip="CORREOS" mat-mini-fab (click)='definicionesCorreos(element)'>
                            <mat-icon >email</mat-icon>
                        </button>
                        <button class=" bg-danger"   matTooltip="ELIMINAR" mat-mini-fab (click)='en_construccion()'>
                            <mat-icon >delete</mat-icon>
                        </button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
 </div>
</div>