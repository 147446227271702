import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {Observable} from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { Cell, Columns, Img, PdfMakeWrapper, Stack, Table, Txt } from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormFacturaComponent } from 'src/app/componentes/shared/modal/form-factura/form-factura.component';
import { FormNotaCreditoComponent } from 'src/app/componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormNotaCreditoAdminComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import { FormReportesComponent } from 'src/app/componentes/shared/modal/form-reportes/form-reportes.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormReportesVentasComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas/form-reportes-ventas.component';
import { FormNotaCreditoAdminSingleComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin-single/form-nota-credito-admin-single.component';
import { FormNotaCreditoAdminBaseComponent } from 'src/app/componentes/shared/modal/form-nota-credito-admin-base/form-nota-credito-admin-base.component';
import * as moment from 'moment';
import { ConnectionServices } from '../../../../../componentes/shared/servicios/app.connection.services';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { FormReportesVentasNotasCreditoComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas-notas-credito/form-reportes-ventas-notas-credito.component';
import axios from 'axios';
import { FormDailyVistaPreviaComponent } from 'src/app/componentes/shared/modal/form-daily-vista-previa/form-daily-vista-previa.component';
import { PdfServices } from 'src/app/componentes/shared/servicios/app.pdf.services';
interface interfacefactura{
  lote_producto: string;
  cod_producto:string;
  des_producto:string;
  cant_producto:string;
  costo_producto:string;
  subtotal_producto:string;
}
interface interfacefactura2{
  des_concepto: string;
  subtotal_concepto:string;
}
interface interdatosfacefactura{
cod_factura:string;
des_cliente: string;
cod_cliente:string;
des_vendedor:string;
fecha_inicio:string;
fecha_v_factura:string;
cod_pedido:string;
telefono1_cliente:string;
telefono2_cliente:string;
}
type TableRow = [string, string, string, string, string, string];
type TableRow2 = [string, string];

@Component({
  selector: 'app-consultar-fact',
  templateUrl: './consultar-fact.component.html',
  styleUrls: ['./consultar-fact.component.css']
})
export class ConsultarFactComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  proforma_factura:any = 'PFF';

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  fecha1:any;
  fecha2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices,
              public dialog: MatDialog, 
              private snackBar: MatSnackBar,
              private pdf : PdfServices,
              private router:Router, 
              private conexion:ConnectionServices) {


    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                cod_menu: localStorage.getItem('sub_main'),
                cod_sesion: localStorage.getItem('cod_sesion'),
                dir_ip_client: localStorage.getItem('dir_ip'),
              sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }
  ngOnInit(): void {
    this.listarfactura(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }
  /////////////////////////////////////////////////////////////////////
 async listarfactura(msj:any){
 
    try {
     
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        this.servicio.listarFacturahttp().subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.respuesta) {

                    this.ELEMENT_DATA.push({
                      cod_factura:response.respuesta[i].cod_factura,
                      cod_pedido: response.respuesta[i].cod_pedido,
                      cod_lista_precio:response.respuesta[i].cod_lista_precio,
                      cod_cliente:response.respuesta[i].cod_cliente,
                      des_cliente:response.respuesta[i].des_cliente,
                      fecha_inicio: response.respuesta[i].fecha_inicio,
                      hora_inicio:response.respuesta[i].hora_inicio,
                      fecha_r_factura:response.respuesta[i].fecha_r_factura,
                      hora_recepcion:response.respuesta[i].hora_recepcion,
                      fecha_v_factura:response.respuesta[i].fecha_v_factura,
                      hora_vencimiento:response.respuesta[i].hora_vencimiento,
                      credito_dias_clientes:response.respuesta[i].credito_dias_clientes,
                      estatus_factura:response.respuesta[i].estatus_factura,
                      monto_base: response.respuesta[i].monto_pendiente_factura,
                      monto_pendiente: response.respuesta[i].monto_base_factura,
                      monto_cobrado:response.respuesta[i].monto_cobrado_factura,
                      remanente_cliente:response.respuesta[i].remanente_cliente,
                      comprobante_factura:response.respuesta[i].comprobante_factura,
                      fecha_c_factura:response.respuesta[i].fecha_c_factura,
                      monto_contador: '0,00',
                      accion:'',
                      accion2:'',
                      accion3:'',
                      accion4:''
                    });
                  }
                  this.displayedColumns = [
                                          'cod_pedido',
                                          'cod_factura',
                                          'cod_lista_precio',
                                          'des_cliente',
                                          'estatus_factura',
                                          'detalle',
                                          'fechas',
                                          'imprimir',
                                          'descargar',
                                          'nota_dev',
                                          'nota_admin',
                                          'anular'];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                      'cod_pedido',
                                      'cod_factura',
                                      'cod_lista_precio',
                                      'des_cliente',
                                      'estatus_factura',
                                      'detalle',
                                      'fechas',
                                      'imprimir',
                                      'descargar',
                                      'nota_dev',
                                      'nota_admin',
                                      'anular'];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
//////////////////////FUNCION DE ACCION/////////////////////////
  notas_credito_admin_single(obj:any,xvalor:any){
  try {
   
    this.key_hash = {};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
                          switch (xvalor) {
                      
                            case 'AD-SN':
                              dialogRef =  this.dialog.open(FormNotaCreditoAdminBaseComponent, {
                                width: '100vw',
                                maxWidth: '100vw',
                                height:'100vh',
                                data: {cod_factura: obj.cod_factura,
                                        cod_pedido: obj.cod_pedido,
                                        cod_lista_precio:obj.cod_lista_precio,
                                        cod_cliente:obj.cod_cliente,
                                        des_cliente:obj.des_cliente,
                                        iva_pedido:obj.iva_pedido,
                                        iva_monto:obj.iva_monto,
                                        desc_pedido:obj.desc_pedido,
                                        tipo_nota:xvalor,
                                        subtotal_costo_pedido:obj.subtotal_costo_pedido,
                                        total_costo_pedido:obj.total_costo_pedido,
                                        termini:'pedido'},
                                        disableClose: true,
                                panelClass: 'pedidos_modal3',
                                backdropClass: "bdrop"
                              });
                                break;
                            default:
                              break;
                          }
                    
                        dialogRef.afterClosed().subscribe(result => {
                    
                          if(result != 'cerrar' && result != 'salir'){
                    
                                console.log(result);
                    
                            this.gestionNotaCreditoAdminSingle(result);
                            /* let resultado = this.eliminarunidad(result.id); */
                          }
                        });
                         
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );

    
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
gestionNotaCreditoAdminSingle(xvalor:any){
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner'
  });
  setTimeout( async() => {
    xvalor.val_def = 'F';
    xvalor.tipo_operacion = 'NOTASDEV';
    xvalor.cod_usuario = localStorage.getItem('cod_usuario');
    this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
      response=>{
        this.servicio.gestionNotaCreditoAdminSinglehttp(xvalor).subscribe(
          response =>{
            this.dialogSpin.close();
            this.cod_nota_credito = response.nota;
            this.listarfactura(null);
            this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
      },
      error=>{
        this.dialogSpin.close();
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
   
    }, 1000);

  return this.xobj2;
}
 consultarDev(xvalor:any, text:any){
    try {

      if (xvalor.estatus_factura.toUpperCase() !== 'X') {
        this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
        this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
          response =>{
            if (response.resul) {
              
              var dialogRef =  this.dialog.open(FormmMsgComponent, {
                      width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                    data: {cod_factura: xvalor.cod_factura,
                            accion:text},
                            disableClose: true,
                    panelClass: 'pedidos_modal',
                    backdropClass: "bdrop"
                  });
           
            dialogRef.afterClosed().subscribe(result => {
              if(result != 'cerrar' && result != 'salir'){
                
                  xvalor.cod_dev = result.cod_dev;
  
                  console.log(xvalor);
                 this.notasCredito(xvalor,'dev');
              }
            });
  
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
      }else{
        this.snackBar.open('El documento " '+xvalor.cod_factura +' "  ya fue anulado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

 async notasCredito(obj:any,xvalor:any){
  try {

      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
            this.servicio.buscarDocumentoAnuladohttp(obj).subscribe(
              response =>{
                this.snackBar.open('El documento " '+obj.cod_factura +' "  ya fue anulado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition});
              },
              error =>{
                this.servicio.buscarDocumentoPFhttp(obj).subscribe(
                  response => {
                    
                      if (xvalor.toLowerCase() === 'dev') {
                        this.snackBar.open('El documento " '+obj.cod_factura +' "  es del tipo PRO-FORMA', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                      }
                      if (xvalor.toLowerCase() === 'admin') {
                        var dialogRef;
                        dialogRef =  this.dialog.open(FormNotaCreditoAdminComponent, {
                          width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                          data: {cod_factura: obj.cod_factura,
                                  cod_pedido: obj.cod_pedido,
                                  cod_lista_precio:obj.cod_lista_precio,
                                  cod_cliente:obj.cod_cliente,
                                  des_cliente:obj.des_cliente,
                                  iva_pedido:obj.iva_pedido,
                                  iva_monto:obj.iva_monto,
                                  desc_pedido:obj.desc_pedido,
                                  subtotal_costo_pedido:obj.subtotal_costo_pedido,
                                  total_costo_pedido:obj.total_costo_pedido,
                                  termini:'pedido'},
                                  disableClose: true,
                          panelClass: 'pedidos_modal3',
                          backdropClass: "bdrop"
                        });
                  
                  dialogRef.afterClosed().subscribe(result => {
                    if(result != 'cerrar' && result != 'salir'){
                      console.log(result);
              
                     this.gestionNotaCredito(result);
                    }
                  });
                      }
                  },
                  error =>{
                    var dialogRef;
                    switch (xvalor) {
                      case 'dev':
                        dialogRef =  this.dialog.open(FormNotaCreditoComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                          data: {cod_factura: obj.cod_factura,
                                  cod_pedido: obj.cod_pedido,
                                  cod_lista_precio:obj.cod_lista_precio,
                                  cod_cliente:obj.cod_cliente,
                                  cod_dev:obj.cod_dev,
                                  des_cliente:obj.des_cliente,
                                  iva_pedido:obj.iva_pedido,
                                  iva_monto:obj.iva_monto,
                                  desc_pedido:obj.desc_pedido,
                                  subtotal_costo_pedido:obj.subtotal_costo_pedido,
                                  total_costo_pedido:obj.total_costo_pedido,
                                  tipo:'nota_credito',
                                  termini:'pedido'},
                                  disableClose: true,
                          panelClass: 'pedidos_modal3',
                          backdropClass: "bdrop"
                        });
                        break;
                      case 'admin':
                        dialogRef =  this.dialog.open(FormNotaCreditoAdminComponent, {
                          width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                          data: {cod_factura: obj.cod_factura,
                                  cod_pedido: obj.cod_pedido,
                                  cod_lista_precio:obj.cod_lista_precio,
                                  cod_cliente:obj.cod_cliente,
                                  des_cliente:obj.des_cliente,
                                  iva_pedido:obj.iva_pedido,
                                  iva_monto:obj.iva_monto,
                                  desc_pedido:obj.desc_pedido,
                                  subtotal_costo_pedido:obj.subtotal_costo_pedido,
                                  total_costo_pedido:obj.total_costo_pedido,
                                  termini:'pedido'},
                                  disableClose: true,
                          panelClass: 'pedidos_modal3',
                          backdropClass: "bdrop"
                        });
                          break;
                      default:
                        break;
                    }
              
                  dialogRef.afterClosed().subscribe(result => {
                    if(result != 'cerrar' && result != 'salir'){
                      console.log(result);
              
                     this.gestionNotaCredito(result);
                    }
                  });
                  }
                );
               
              }
            );
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      ); 
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
gestionNotaCredito(xvalor:any){
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });
  setTimeout( async() => {
    xvalor.val_def = 'F';
    xvalor.tipo_operacion = 'NOTASDEV';
    xvalor.cod_usuario = localStorage.getItem('cod_usuario');
    this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
      response=>{
        this.servicio.gestionNotaCreditohttp(xvalor).subscribe(
          response =>{
            this.dialogSpin.close();
            this.cod_nota_credito = response.nota;
            this.listarfactura(null);
            this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
            
              setTimeout(() => {
                const pdf = new PdfMakeWrapper();
                var valor = {cod_factura:xvalor.cod_factura, cod_nota_credito:this.cod_nota_credito,tipo_nota:xvalor.tipo_nota}
                this.servicio.listarNotaCreditohttp(valor).subscribe(
                 async response  =>{
                  
                    switch (xvalor.tipo_nota) {
                      case 'dev':
                        response.pdf4 = response.pdf2;
                    response.pdf2[0].comentarios = '';
                    
                    response.pdf4[0].subtotal_costo_pedido = response.pdf4[0].subtotal_dev_costo;
                    response.pdf4[0].desc_pedido = response.pdf4[0].desc_dev;
                    response.pdf4[0].desc_pedido_monto = response.pdf4[0].desc_dev_monto;
                    response.pdf4[0].monto_exento = response.pdf4[0].dev_monto_exento;
                    response.pdf4[0].iva_pedido = response.pdf4[0].iva_dev;
                    response.pdf4[0].iva_monto = response.pdf4[0].iva_dev_monto;
                    response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;
    
                    response.pdf6[0].tasa_precio = response.pdf7[0].tasa_precio;
                    
                    response.pdf4[0].tipo_pedido = response.pdf2[0].tipo_nota_credito;
                        this.pdf.pdfVentas(response,'d');
                        
                        break;
                      case 'admin':
                        response.pdf4 = response.pdf2;
                    response.pdf2[0].comentarios = '';
                    
                    response.pdf4[0].subtotal_costo_pedido = response.pdf4[0].subtotal_dev_costo;
                    response.pdf4[0].desc_pedido = response.pdf4[0].desc_dev;
                    response.pdf4[0].desc_pedido_monto = response.pdf4[0].desc_dev_monto;
                    response.pdf4[0].monto_exento = response.pdf4[0].dev_monto_exento;
                    response.pdf4[0].iva_pedido = response.pdf4[0].iva_dev;
                    response.pdf4[0].iva_monto = response.pdf4[0].iva_dev_monto;
                    response.pdf4[0].total_costo_pedido = response.pdf4[0].total_dev_costo;
    
                    response.pdf6[0].tasa_precio = response.pdf7[0].tasa_precio;
                    
                    response.pdf4[0].tipo_pedido = response.pdf2[0].tipo_nota_credito;
    
                    response.pdf[0].cod_producto = 'N/A';
                    response.pdf[0].des_producto = response.pdf[0].des_concepto;
                    response.pdf[0].cant_producto = '1';
                    response.pdf[0].costo_producto = '0,00';
                    response.pdf[0].desc_prod = '0,00';
                    response.pdf[0].desc_prod_monto = '0,00';
                    response.pdf[0].subtotal_producto = response.pdf[0].subtotal_concepto ;
                        this.pdf.pdfVentas(response,'d');
                        
                          break;
                      default:
                        break;
                    }
                  },
                  error =>{
                    console.log(error);
                    this.dialogSpin.close();
                    console.log(<any>error.error.message);
                    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition});
                  }
                );
               }, 1000);
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
        );
      },
      error=>{
        this.dialogSpin.close();
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }, 1000);
  return this.xobj2;
}

descargar_reporte_ventas3(text:any){
  try {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef:any;
  this.accion = text;
  let datos = localStorage.getItem('cod_usuario');
  let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    dialogRef = this.dialog.open(FormReportesVentasNotasCreditoComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: {
              accion:this.accion},
      panelClass: 'pedidos_modal_rep',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);
        switch (result) {
          case 'xlsx':
            this.reportes_ventas_xlsx();
            break;
          case 'pdf':

            break;
          case 'txt':

            break;
          case 'csv':

            break;

          default:
            this.reportes_rango_fecha_ventas_xlsx3(result);
            break;
        }
      }
    });
                           
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
      
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

 reportes_rango_fecha_ventas_xlsx3(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.fecha1 = xvalor.fecha_inicio;
      this.fecha2 = xvalor.fecha_fin;
      this.servicio.buscarUnidadesPorFacturasNotashttp(xvalor).subscribe(
        response=>{
          this.dialogSpin.close();
          console.log(response);
          this.descarga_prod_ventas_por_fecha_xlsx3(response);
        },
        error=>{
          this.dialogSpin.close();
          console.log(error.error);
          console.log(error.error.message);
          this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
      );
    }, 3000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

async descarga_prod_ventas_por_fecha_xlsx3(xdatos:any){
  try {
    console.log(xdatos,'llego a la descarga');
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Reporte de ventas",{views: [{showGridLines: false}]});

    const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
    const imageId2 = workbook.addImage({
      buffer: imageBuffer.data,
      extension: 'png',
    });
    worksheet.addImage(imageId2,'B2:B5')
    /* let worksheet = workbook.addWorksheet("Reporte de ventas desde '"+this.fecha1+"' hasta '"+this.fecha2+"'"); */
    worksheet.mergeCells('B1:J1')
    worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
    worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D1').font={bold: true};

    worksheet.mergeCells('B2:J2')
    worksheet.getCell('D2').value='Rif J-41031797-3';
    worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D2').font={bold: true};
    
    worksheet.mergeCells('B3:J3')
    worksheet.getCell('D3').value='Reporte de Unidades Vendidas';
    worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D3').font={bold: true};

    worksheet.mergeCells('B4:J4')
    worksheet.getCell('D4').value='Del "'+this.fecha1+'" al "'+this.fecha2+'"';
    worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D4').font={bold: true};
    
    worksheet.mergeCells('B5:J5')
    worksheet.getCell('D5').value='Expresado en Unidades';
    worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('D5').font={bold: true};

    worksheet.mergeCells('B6:J6')
    
    worksheet.mergeCells('D7:F7')
    worksheet.getCell('E7').value='Ventas';
    worksheet.getCell('E7').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('E7').font={bold: true, color: {argb:'FFFFFF'}};
    worksheet.getCell('E7').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };

    worksheet.mergeCells('G7:I7')
    worksheet.getCell('G7').value='Devoluciones';
    worksheet.getCell('G7').alignment = { vertical: 'top', horizontal: 'center' };
    worksheet.getCell('G7').font={bold: true, color: {argb:'FFFFFF'}};
    worksheet.getCell('G7').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    
    worksheet.autoFilter = {
      from: 'B8',
      to: 'J8',
    }
    
    var fondo_gris_claro =['D7','E7','F7','G7','H7']
    fondo_gris_claro.map(celdas => {
      worksheet.getCell(celdas).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0050a0'},
        /* bgColor: { argb: '91918e' } */
      };
    })

    let header=[" ","Cod producto","Producto","Facturas","Notas de entrega","Total","Facturas","Notas de entrega","Total","Gran total"];
    let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}}


    worksheet.getCell('B8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    worksheet.getCell('C8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    worksheet.getCell('D8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    worksheet.getCell('E8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    worksheet.getCell('F8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    worksheet.getCell('G8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    worksheet.getCell('H8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    worksheet.getCell('I8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };
    worksheet.getCell('J8').border = {
      /* FF0000FF */
      top: {style:'double', color: {argb:'00000000'}},
      bottom: {style:'double', color: {argb:'00000000'}},
      left: {style:'double', color: {argb:'00000000'}},
      right: {style:'double', color: {argb:'00000000'}}
    };

    var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8','J8']
    fondo_gris_claro_plus.map(celdas => {
      worksheet.getCell(celdas).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0050a0'},
        /* bgColor: { argb: 'adadaa' } */
      };
    })
    fondo_gris_claro_plus.map(centrar => {
      worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
    })
    worksheet.getColumn(1).width = 3.5;
    worksheet.getColumn(2).width = 17;
    worksheet.getColumn(3).width = 45;
    worksheet.getColumn(4).width = 17;
    worksheet.getColumn(5).width = 17;
    worksheet.getColumn(6).width = 17;
    worksheet.getColumn(7).width = 17;
    worksheet.getColumn(8).width = 17;
    worksheet.getColumn(9).width = 17;
    worksheet.getColumn(10).width = 17;


    var facturas =0;
    var notas =0;
    var total =0;
    var ncfac =0;
    var ncnotas =0;
    var total_nc =0;
    var gran_total2 =0;
    var gran_total =0;

    for (let x1 in xdatos)
    {
    let x2= xdatos[x1];
    console.log(x2,'x2');
    var temp=[]
    for(let y in x2)
    {
    temp.push(xdatos[x1][y])
    }
    worksheet.addRow(temp)
    }

  for (const i in xdatos) {

    facturas += parseInt(xdatos[i].facturas)
    notas += parseInt(xdatos[i].notas)
    total += parseInt(xdatos[i].total)
    ncfac += parseInt(xdatos[i].ncfac)
    ncnotas += parseInt(xdatos[i].ncnotas)
    total_nc += parseInt(xdatos[i].total_nc)
    gran_total2 += parseInt(xdatos[i].gran_total)


  }
  gran_total = gran_total2 /* + total_nc */
  for (let i = 0; i <= xdatos.length; i++) {

    if(i == 0){
      worksheet.getCell('B9').border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
        
      };
    }else{
      var numActu = i + 9 -1;
      var numActuString = numActu.toString()

      worksheet.getCell(`B${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`C${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`D${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`E${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`F${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`G${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`H${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`I${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
       
      };
      worksheet.getCell(`J${numActuString}`).border = {
        bottom: {style:'thin', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}},
      };
    }
    if(i == xdatos.length){
      var numActu = i + 9 -1;
      var final_mas_uno = i + 9 + 1
      var final_mas_uno_s = final_mas_uno.toString()

      worksheet.getCell(`B${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
        left: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`C${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`D${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`E${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`F${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`G${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`H${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`I${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`J${numActuString}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
        right: {style:'double', color: {argb:'00000000'}},
      };


      worksheet.getCell(`D${final_mas_uno_s}`).value= facturas
      worksheet.getCell(`E${final_mas_uno_s}`).value= notas
      worksheet.getCell(`F${final_mas_uno_s}`).value= total
      worksheet.getCell(`G${final_mas_uno_s}`).value= ncfac
      worksheet.getCell(`H${final_mas_uno_s}`).value= ncnotas
      worksheet.getCell(`I${final_mas_uno_s}`).value= total_nc
      worksheet.getCell(`J${final_mas_uno_s}`).value= gran_total
      
      worksheet.getCell(`D${final_mas_uno_s}`).border = {
        bottom: {style:'double', color: {argb:'00000000'}},
        top: {style:'thin', color: {argb:'00000000'}},
      };
      worksheet.getCell(`E${final_mas_uno_s}`).border = {
        top: {style:'thin', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`F${final_mas_uno_s}`).border = {
        top: {style:'thin', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`F${final_mas_uno_s}`).border = {
        top: {style:'thin', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`G${final_mas_uno_s}`).border = {
        top: {style:'thin', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`H${final_mas_uno_s}`).border = {
        top: {style:'thin', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`I${final_mas_uno_s}`).border = {
        top: {style:'thin', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
      };
      worksheet.getCell(`J${final_mas_uno_s}`).border = {
        top: {style:'thin', color: {argb:'00000000'}},
        bottom: {style:'double', color: {argb:'00000000'}},
      };
    }
     
  }
 
  
  let fname="Reporte de ventas facturas/notas desde '"+this.fecha1+"' hasta '"+this.fecha2+"'";
  workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
  });
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
  }
//////////////////////FUNCION DE ACCION///////////////////////

//////////////////////FUNCION DE ACCION/////////////////////////
descargar_reporte_ventas(text:any){
  try {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef:any;
  this.accion = text;
  let datos = localStorage.getItem('cod_usuario');
  let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    dialogRef = this.dialog.open(FormReportesVentasComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: {
              accion:this.accion},
      panelClass: 'pedidos_modal_rep',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);
        switch (result) {
          case 'xlsx':
            this.reportes_ventas_xlsx();
            break;
          case 'pdf':

            break;
          case 'txt':

            break;
          case 'csv':

            break;

          default:
            this.reportes_rango_fecha_ventas_xlsx(result);
            break;
        }
      }
    });
                           
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
      
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
reportes_rango_fecha_ventas_xlsx(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.fecha1 = xvalor.fecha_inicio;
      this.fecha2 = xvalor.fecha_fin;
      this.servicio.reporteFacturashttp(xvalor).subscribe(
        response=>{
          this.dialogSpin.close();
          console.log(response.xobjeto_unico);
          this.descarga_prod_ventas_por_fecha_xlsx(response.xobjeto_unico);
        },
        error=>{
          this.dialogSpin.close();
          console.log(error.error.xobjeto_unico);
          console.log(error.error.message);
          this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
      );
    }, 3000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
reportes_ventas_xlsx(){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.servicio.listarProductoTerminadohttp().subscribe(
        response=>{
          this.dialogSpin.close();
          for (const i in response) {
            response[i].cant_producto = parseInt(response[i].cant_producto.replace('.','')
            .replace('.','')
            .replace('.','')
            .replace(',','.')).toFixed();
          }
          this.descarga_xlsx(response);
        },
        error=>{
          this.dialogSpin.close();
          console.log(error);
        }
      );
    }, 3000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }

}

descarga_xlsx(xdatos:any){
  try {
    console.log(xdatos,'llego a la descarga');
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Inventario(LOO1)");
    let header=["Id","Codigo","Producto", "Cantidad","Estatus","Unidad"];
    let headerRow = worksheet.addRow(header);
    for (let x1 of xdatos)
{
let x2=Object.keys(x1);
let temp=[]
for(let y of x2)
{
  temp.push(x1[y])
}
worksheet.addRow(temp)
}
let fname="Inventario de productos terminados (L001)";

workbook.xlsx.writeBuffer().then((data) => {
let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
});
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}

async descarga_prod_ventas_por_fecha_xlsx(xdatos:any){
  try {
            console.log(xdatos,'llego a la descarga');
            let workbook = new Workbook();
            let worksheet = workbook.addWorksheet("Reporte de Facturas",{views: [{showGridLines: false}]});

            const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
            const imageId2 = workbook.addImage({
              buffer: imageBuffer.data,
              extension: 'png',
            });
            
            worksheet.addImage(imageId2,'B2:B6')

            worksheet.mergeCells('B1:T1')
            worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
            worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D1').font={bold: true};
      
            worksheet.mergeCells('B2:T2')
            worksheet.getCell('D2').value='Rif J-41031797-3';
            worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D2').font={bold: true};
            
            worksheet.mergeCells('B3:T3')
            worksheet.getCell('D3').value='Reporte de Facturas';
            worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D3').font={bold: true};
      
            worksheet.mergeCells('B4:T4')
            worksheet.getCell('D4').value='A la fecha "'+this.fecha1+'"-"'+this.fecha2+'" ';
            worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D4').font={bold: true};
      
            worksheet.mergeCells('B5:T5')
            worksheet.getCell('D5').value='Representado en Bolivares y Dolares';
            worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D5').font={bold: true};
      
            worksheet.mergeCells('B6:T6')
      
            worksheet.autoFilter = {
              from: 'B7',
              to: 'T7',
            }

            let header=[" ","Fecha","Hora","Nro. Factura","Nro. Control","RIF","Cliente","Vendedor","Tipo de cliente",
                        "Tasa","Base imponible","%Descuento","Descuento","%IVA","IVA","%Exento","Monto exento",
                        "Monto total VEF","Monto total USD","Estatus"];

            let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};
          
            var border =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7',
                        'M7','N7','O7','P7','Q7','R7','S7','T7']

          border.map(celdas =>{
            worksheet.getCell(celdas).border = {
              top: {style:'double', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}}
            };
          })

            var fondo_gris_claro_plus=['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7',
                        'M7','N7','O7','P7','Q7','R7','S7','T7']
            fondo_gris_claro_plus.map(celdas => {
              worksheet.getCell(celdas).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0050a0'},
                
              };
            })
            fondo_gris_claro_plus.map(centrar => {
              worksheet.getCell(centrar).alignment = { vertical: 'middle', horizontal: 'center' };
            })

            worksheet.getColumn(1).width = 3.5;
            worksheet.getColumn(2).width = 17;
            worksheet.getColumn(3).width = 17;
            worksheet.getColumn(4).width = 17;
            worksheet.getColumn(5).width = 17;
            worksheet.getColumn(6).width = 17;
            worksheet.getColumn(7).width = 40;
            worksheet.getColumn(8).width = 25;
            worksheet.getColumn(9).width = 17;
            worksheet.getColumn(10).width = 17;
            worksheet.getColumn(11).width = 17;
            worksheet.getColumn(12).width = 17;
            worksheet.getColumn(13).width = 17;
            worksheet.getColumn(14).width = 17;
            worksheet.getColumn(15).width = 17;
            worksheet.getColumn(16).width = 17;
            worksheet.getColumn(17).width = 17;
            worksheet.getColumn(18).width = 17;
            worksheet.getColumn(19).width = 17;
            worksheet.getColumn(20).width = 17;
            var numFmtStr = '_("$" #,##0.00_);_([Red]("$" #,##0.00);_("-"??_);_(@_)';
            var numFmtStr_ = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
            worksheet.getColumn(10).numFmt = numFmtStr_;
            worksheet.getColumn(11).numFmt = numFmtStr_;
            worksheet.getColumn(12).numFmt = numFmtStr_;
            worksheet.getColumn(13).numFmt = numFmtStr_;
            worksheet.getColumn(14).numFmt = numFmtStr_;
            worksheet.getColumn(15).numFmt = numFmtStr_;
            worksheet.getColumn(16).numFmt = numFmtStr_;
            worksheet.getColumn(17).numFmt = numFmtStr_;
            worksheet.getColumn(18).numFmt = numFmtStr_;
            worksheet.getColumn(19).numFmt = numFmtStr;

            /////////////////////ROW//////////////////////
            worksheet.getRow(7).height= 30
            /////////////////////ROW//////////////////////  

            /////////////////////wrap//////////////////////
            var espacio_interno =['B7','C7','D7','E7','F7','G7','H7','I7','J7','K7','L7',
                        'M7','N7','O7','P7','Q7','R7','S7','T7']
            espacio_interno.map(celdas =>{
            worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
            })
            /////////////////////wrap//////////////////////

            for (let x1 of xdatos)
            {
              if(x1.fecha_inicio != '-'){

                x1.fecha_inicio = new Date(x1.fecha_inicio)
              }
              let x2=Object.keys(x1);
              let temp=[]
              for(let y of x2)
              {
                temp.push(x1[y])
              }
              worksheet.addRow(temp)
            }

            for (let i = 0; i <= xdatos.length; i++) {

              if(i == 0){
                worksheet.getCell('B7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('C7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('D7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('E7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('F7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('G7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('H7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('I7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('J7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('K7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('L7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('M7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('N7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('O7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('P7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('Q7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('R7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('S7').alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell('T7').alignment = { vertical: 'middle', horizontal: 'center' }

              }else{
                var numActu = i + 8 -1;
                var numActuString = numActu.toString()
      
                worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }
                worksheet.getCell(`T${numActuString}`).alignment = { vertical: 'middle', horizontal: 'center' }

      
                worksheet.getCell(`B${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                  left: {style:'double', color: {argb:'00000000'}},
                };
                worksheet.getCell(`C${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`D${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`E${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`F${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`G${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`H${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`I${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`J${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`K${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`L${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`M${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`N${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`O${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`P${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`Q${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`R${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`S${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                };
                worksheet.getCell(`T${numActuString}`).border = {
                  bottom: {style:'thin', color: {argb:'00000000'}},
                  right: {style:'double', color: {argb:'00000000'}},
                };
              }
              
              if(i == xdatos.length){
                  var numActu = i + 8 -1;
                  var final_mas_uno = i + 8 + 1
                  var final_mas_uno_s = final_mas_uno.toString()
      
                  worksheet.getCell(`B${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                    left: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`C${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`D${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`E${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`F${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`G${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`H${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`I${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`J${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`K${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`L${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`M${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`N${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`O${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`P${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`Q${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`R${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`S${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                  };
                  worksheet.getCell(`T${numActuString}`).border = {
                    bottom: {style:'double', color: {argb:'00000000'}},
                    right: {style:'double', color: {argb:'00000000'}},
                  };
              }
            }
            let fname="Reporte de facturas desde '"+this.fecha1+"' hasta '"+this.fecha2+"'";
            workbook.xlsx.writeBuffer().then((data) => {
              let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
            });
          } catch (error) {
            console.log(error);
            this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,})
          }
  }

  async descarga_prod_ventas_por_fecha_xlsx2(xdatos:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Reporte General",{views: [{showGridLines: false}]});

      const imageBuffer = await axios.get('../../../../../../assets/img/logo-factory.png', { responseType: 'arraybuffer' });
      const imageId2 = workbook.addImage({
        buffer: imageBuffer.data,
        extension: 'png',
      });
      
      worksheet.addImage(imageId2,'B2:B5')
      
      worksheet.mergeCells('B1:AD1')
      worksheet.getCell('D1').value='Factory Cosmetics 3DG, C.A.';
      worksheet.getCell('D1').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D1').font={bold: true};

      worksheet.mergeCells('B2:AD2')
      worksheet.getCell('D2').value='Rif J-41031797-3';
      worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D2').font={bold: true};
      
      worksheet.mergeCells('B3:AD3')
      worksheet.getCell('D3').value='Reporte General';
      worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D3').font={bold: true};

      worksheet.mergeCells('B4:AD4')
      worksheet.getCell('D4').value='A la fecha "'+this.fecha1+'"-"'+this.fecha2+'" ';
      worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D4').font={bold: true};

      worksheet.mergeCells('B5:AD5')
      worksheet.getCell('D5').value='Expresado en Bolivares y Dolares';
      worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
      worksheet.getCell('D5').font={bold: true};

      worksheet.mergeCells('B6:AD6')

      worksheet.autoFilter = {
        from: 'B8',
        to: 'AD8',
      }
      
      var fondo_gris_claro =['D7','E7','F7','G7','H7']
      fondo_gris_claro.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF'},
          
        };
      })

      let header = [" ","Fecha","Hora","Nro. Factura","Nro. Control","Nro. Nota","Tipo de Nota","Nro. Nota de Credito",
                   "Nro. Nota de Debito","Factura Afectada","Tipo de Doc.","RIF","Cliente","Usuario","Tipo Nota de Credito","Concepto Doc",
                   "Vendedor","Tipo de Cliente","Tasa","Base Imponible VES","Base Imponible USD","Monto Exento",
                   "%Descuento","Descuento","%IVA","IVA","Monto Total VES","Monto Total USD","Estatus","Estatus Fiscal",] 
      let headerRow = worksheet.addRow(header).font={bold: true, color: {argb:'FFFFFF'}};

      var bordes_headers = ['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8','P8',
                            'Q8','R8','S8','T8','U8','V8','W8','X8','Y8','Z8','AA8','AB8','AC8','AD8']
      bordes_headers.map(celdas =>{
        worksheet.getCell(celdas).border = {
          top: {style:'double', color: {argb:'00000000'}},
          bottom: {style:'double', color: {argb:'00000000'}},
          left: {style:'double', color: {argb:'00000000'}},
          right: {style:'double', color: {argb:'00000000'}}
        };
      })

      var fondo_gris_claro_plus=['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                                 'P8','Q8','R8','S8','T8','U8','V8','W8','X8','Y8','Z8','AA8','AB8','AC8','AD8']
      fondo_gris_claro_plus.map(celdas => {
        worksheet.getCell(celdas).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '0050a0'},
          
        };
      })
      fondo_gris_claro_plus.map(centrar => {
        worksheet.getCell(centrar).alignment = { vertical: 'top', horizontal: 'center' };
      })
      worksheet.getColumn(1).width = 3.5;
      worksheet.getColumn(2).width = 14;
      worksheet.getColumn(3).width = 14;
      worksheet.getColumn(4).width = 14;
      worksheet.getColumn(5).width = 14;
      worksheet.getColumn(6).width = 17;
      worksheet.getColumn(7).width = 14;
      worksheet.getColumn(8).width = 17;
      worksheet.getColumn(9).width = 17;
      worksheet.getColumn(10).width = 17;
      worksheet.getColumn(11).width = 17;
      worksheet.getColumn(12).width = 12;
      worksheet.getColumn(13).width = 51;
      worksheet.getColumn(14).width = 22;
      worksheet.getColumn(15).width = 25;
      worksheet.getColumn(16).width = 19;
      worksheet.getColumn(17).width = 22;
      worksheet.getColumn(18).width = 13;
      worksheet.getColumn(19).width = 17;
      worksheet.getColumn(20).width = 17;
      worksheet.getColumn(21).width = 17;
      worksheet.getColumn(22).width = 20;
      worksheet.getColumn(23).width = 20;
      worksheet.getColumn(24).width = 15;
      worksheet.getColumn(25).width = 17;
      worksheet.getColumn(26).width = 17;
      worksheet.getColumn(27).width = 21;
      worksheet.getColumn(28).width = 21;
      worksheet.getColumn(29).width = 21;
      worksheet.getColumn(30).width = 21;
      worksheet.getColumn(31).width = 21;
      worksheet.getColumn(32).width = 21;
      worksheet.getColumn(32).width = 21;
      /////////////////////wrap//////////////////////
      var espacio_interno =['B8','C8','D8','E8','F8','G8','H8','I8','J8','K8','L8','M8','N8','O8',
                                 'P8','Q8','R8','S8','T8','U8','V8','W8','X8','Y8','Z8','AA8','AB8','AC8','AD8']
      espacio_interno.map(celdas =>{
      worksheet.getCell(celdas).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
        
      })
      /////////////////////wrap//////////////////////
/*       var espacio_informacion = ['B','C','D','E','F','G','H','I','J','K','L','M','N','O',
      'P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC']

for (let i = 0; i <= xdatos.length; i++){
var i_suma = i + 9 - 1;
var i_string = i_suma.toString()
espacio_informacion.map(letras =>{
worksheet.getCell(letras+i_string).alignment = { wrapText: true, horizontal: 'center', vertical: 'middle' }
console.log(letras+i_string,'letras+i_string')
})
} */
      /////////////////////ROW//////////////////////
      worksheet.getRow(8).height= 30
      /////////////////////ROW//////////////////////
      var numFmtStr = '_(#,##0.00_);_([Red](#,##0.00);_("-"??_);_(@_)';
      worksheet.getColumn(18).numFmt =numFmtStr;
      worksheet.getColumn(19).numFmt =numFmtStr;
      worksheet.getColumn(20).numFmt =numFmtStr;
      worksheet.getColumn(21).numFmt =numFmtStr;
      worksheet.getColumn(22).numFmt =numFmtStr;
      worksheet.getColumn(23).numFmt =numFmtStr;
      worksheet.getColumn(24).numFmt =numFmtStr;
      worksheet.getColumn(25).numFmt =numFmtStr;
      worksheet.getColumn(26).numFmt =numFmtStr;
      worksheet.getColumn(27).numFmt =numFmtStr;
      worksheet.getColumn(28).numFmt =numFmtStr;

      var subtotal_costo_pedido =0;
      var subtotal_costo_pedido_usd = 0;
      var monto_exento =0;
      var desc_pedido_monto =0;
      var iva_monto =0;
      var total_costo_pedido =0;
      var total_divisa =0;

  
      for (let x1 of xdatos)
    {
    let x2=Object.keys(x1);
    let temp=[]
    for(let y of x2)
    {
    temp.push(x1[y])
    }
    worksheet.addRow(temp)
    }

      for (const i in xdatos) {
        
        subtotal_costo_pedido += parseFloat(xdatos[i].subtotal_costo_pedido)
        monto_exento += parseFloat(xdatos[i].monto_exento)
        desc_pedido_monto += parseFloat(xdatos[i].desc_pedido_monto)
        iva_monto += parseFloat(xdatos[i].iva_monto)
        total_costo_pedido += parseFloat(xdatos[i].total_costo_pedido)

        if(xdatos[i].total_divisa != '--'){

          total_divisa += parseFloat(xdatos[i].total_divisa)
        }
        if(xdatos[i].subtotal_costo_pedid_usd != '--'){

          subtotal_costo_pedido_usd += parseFloat(xdatos[i].subtotal_costo_pedid_usd)
        }
      }
      for (let i = 0; i <= xdatos.length; i++) {

        if(i == 0){
          worksheet.getCell('B9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('C9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('D9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('E9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('F9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('G9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('H9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('I9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('J9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('K9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('L9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('M9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('N9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('O9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('P9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Q9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('R9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('S9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('T9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('U9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('V9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('W9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('X9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Y9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('Z9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('AA9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('AB9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('AC9').alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell('AD9').alignment = { vertical: 'top', horizontal: 'center' }
        }else{
          var numActu = i + 9 -1;
          var numActuString = numActu.toString()

          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`B${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`C${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`D${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`E${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`F${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`G${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`H${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`I${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`J${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`K${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`L${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`M${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`N${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`O${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`P${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Q${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`R${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`S${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`T${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`U${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`V${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`W${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`X${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Y${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`Z${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`AA${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`AB${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`AC${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }
          worksheet.getCell(`AD${numActuString}`).alignment = { vertical: 'top', horizontal: 'center' }

          worksheet.getCell(`B${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            left: {style:'double', color: {argb:'00000000'}},
          };
          worksheet.getCell(`C${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`D${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`E${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`F${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`G${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`H${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`I${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`J${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`K${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`L${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`M${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`N${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`O${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`P${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Q${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`R${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`S${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`T${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`U${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`V${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`W${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`X${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Y${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`Z${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`AA${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`AB${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
          };
          worksheet.getCell(`AC${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            };
          worksheet.getCell(`AD${numActuString}`).border = {
            bottom: {style:'thin', color: {argb:'00000000'}},
            right: {style:'double', color: {argb:'00000000'}},
          };
        }
        
        if(i == xdatos.length){
            var numActu = i + 9 -1;
            var final_mas_uno = i + 9 + 1
            var final_mas_uno_s = final_mas_uno.toString()

            worksheet.getCell(`T${final_mas_uno_s}`).value= subtotal_costo_pedido
            worksheet.getCell(`U${final_mas_uno_s}`).value= subtotal_costo_pedido_usd
            worksheet.getCell(`V${final_mas_uno_s}`).value= monto_exento
            worksheet.getCell(`X${final_mas_uno_s}`).value= desc_pedido_monto
            worksheet.getCell(`Z${final_mas_uno_s}`).value= iva_monto
            worksheet.getCell(`AA${final_mas_uno_s}`).value= total_costo_pedido
            worksheet.getCell(`AB${final_mas_uno_s}`).value= total_divisa
            
            worksheet.getCell(`T${final_mas_uno_s}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              top: {style:'thin', color: {argb:'00000000'}},
            };
            worksheet.getCell(`U${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`V${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`X${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Z${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AA${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AB${final_mas_uno_s}`).border = {
              top: {style:'thin', color: {argb:'00000000'}},
              bottom: {style:'double', color: {argb:'00000000'}},
            };

            worksheet.getCell(`B${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              left: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`C${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`D${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`E${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`F${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`G${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`H${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`I${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`J${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`K${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`L${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`M${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`N${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`O${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`P${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Q${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`R${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`S${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`T${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`U${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`V${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`W${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`X${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Y${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`Z${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AA${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AB${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AC${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
            };
            worksheet.getCell(`AD${numActuString}`).border = {
              bottom: {style:'double', color: {argb:'00000000'}},
              right: {style:'double', color: {argb:'00000000'}},
            };
        }
    }
    let fname="Reporte General desde '"+this.fecha1+"' hasta '"+this.fecha2+"'";
    workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
    });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
    }

descargar_reporte_ventas2(text:any){
  try {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef:any;
  this.accion = text;
  let datos = localStorage.getItem('cod_usuario');
  let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    dialogRef = this.dialog.open(FormReportesVentasComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: {
              accion:this.accion},
      panelClass: 'pedidos_modal_rep',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);
        switch (result) {
          case 'xlsx':
            this.reportes_ventas_xlsx();
            break;
          case 'pdf':

            break;
          case 'txt':

            break;
          case 'csv':

            break;

          default:
            this.reportes_rango_fecha_ventas_xlsx2(result);
            break;
        }
      }
    });
                           
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
      
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
reportes_rango_fecha_ventas_xlsx2(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.fecha1 = xvalor.fecha_inicio;
      this.fecha2 = xvalor.fecha_fin;
      this.servicio.reporteGeneralFNENDNChttp(xvalor).subscribe(
        response=>{
          this.dialogSpin.close();
          console.log(response.xobjeto_unico);
          this.descarga_prod_ventas_por_fecha_xlsx2(response.xobjeto_unico);
        },
        error=>{
          this.dialogSpin.close();
          console.log(error.error.xobjeto_unico);
          console.log(error.error.message);
          this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
      );
    }, 3000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
//////////////////////FUNCION DE ACCION/////////////////////////
//////////////////////FUNCION DE ACCION/////////////////////////
imprimir_descargar(xvalor:any,text:any): void {
  try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          this.accion = text;
                          switch (this.accion) {
                            case 'imprimir':
                              this.accion = 'enviarpedido';
                              var dialogRef = this.dialog.open(FormmMsgComponent, {
                                width: '100vw',
                                maxWidth: '100vw',
                                height:'100vh',
                                data: {id:'',
                                      accion:this.accion},
                                disableClose: true,
                                panelClass: 'pedidos_modal',
                                backdropClass: "bdrop"
                              });
                              dialogRef.afterClosed().subscribe(result => {
                                if (result != 'cerrar' && result != 'salir' ) {
                                  dialogRef.close();
                                  this.imprimirfactura(xvalor);
                                }else{
                                  dialogRef.close();
                                }
                              });
                              break;
                              case 'descargar':
                              this.accion = 'enviarpedido';
                              var dialogRef = this.dialog.open(FormmMsgComponent, {
                                width: '100vw',
                                maxWidth: '100vw',
                                height:'100vh',
                                data: {id:'',
                                      accion:this.accion},
                                disableClose: true,
                                panelClass: 'pedidos_modal',
                                backdropClass: "bdrop"
                              });
                              dialogRef.afterClosed().subscribe(result => {
                                if (result != 'cerrar' && result != 'salir' ) {
                                  dialogRef.close();
                                  this.descargarFactura(xvalor);
                                }else{
                                  dialogRef.close();
                                }
                              });
                              break;
                            default:
                              break;
                          }
                          
                        }else{
                          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      },
                      error =>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
    
  } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
  imprimirfactura(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      const pdf = new PdfMakeWrapper();
      xvalor.val_def = 'F';
      xvalor.tipo_operacion = 'IMPRIMIRF';
      xvalor.cod_usuario = localStorage.getItem('cod_usuario');
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response =>{
          this.servicio.listarProdPedidohttp(xvalor).subscribe(
            async response  =>{
             this.dialogSpin.close();
               console.log(response);
               this.pdf.pdfVentas(response,'d');
               /* pdf.info({
                 title: 'Factura N°'+response.pdf2[0].cod_factura+'',
                 author: 'Factory cosmetics C.A.',
                 subject: 'Facturas',
             });
               pdf.pageMargins([20,115,20,60]);
               pdf.add(pdf.ln(1));
               pdf.add( this.numerofact9(response.pdf2));
               pdf.add(this.crearTabla29(response.pdf2,response.pdf3));
               pdf.pageSize('A4');
               pdf.add(this.headerTabla9());
               pdf.add(this.crearTabla9(response.pdf));
               pdf.add(this.crearTabla99(response.pdf));
               pdf.add(this.crearTabla999(response.pdf));
               pdf.add(pdf.ln(1));
               pdf.add(this.crearTabla49(response.pdf4,xvalor,response.pdf5,response.pdf6,response.pdf2));
               pdf.create().open(); */
             },
             error =>{
               this.dialogSpin.close();
               console.log(error);
               console.log(<any>error.error.message);
               this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition});
             }
           );
        },
        error =>{
          this.dialogSpin.close();
           
               this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition});
        }
      );
      
    }, 1000);
  }
  async descargarFactura(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      const pdf = new PdfMakeWrapper();
      xvalor.val_def = 'F';
      xvalor.tipo_operacion = 'IMPRIMIRF';
      xvalor.cod_usuario = localStorage.getItem('cod_usuario');
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response=>{ this.servicio.listarProdPedidohttp(xvalor).subscribe(
          async response  =>{
           this.dialogSpin.close();
             console.log(xvalor);
             this.pdf.pdfVentas(response, 'dd');
           },
           error =>{
             console.log(error);
             this.dialogSpin.close();
             console.log(<any>error.error.message);
             this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition});
           }
         );},
        error=>{
          this.dialogSpin.close();
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
     
    }, 1000);
  }

  //////////////////////FUNCION DE ACCION/////////////////////////
 
   //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////

   fechas(obj:any, text:any){
    this.accion = text;
    this.dialog.open(FormDireccionesComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: { fecha_inicio:obj.fecha_inicio,
              hora_inicio:obj.hora_inicio,
              fecha_r_factura:obj.fecha_r_factura,
              hora_recepcion:obj.hora_recepcion,
              fecha_v_factura:obj.fecha_v_factura,
              hora_vencimiento:obj.hora_vencimiento,
              fecha_c_factura:obj.fecha_c_factura,
              termino: this.accion
            },
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
    });
}
async detallepedido(obj:any, text:any){
  console.log(this.ELEMENT_DATA);
  console.log(obj);
  this.accion = text;
    this.dialog.open(FormDireccionesComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: { monto_pendiente:obj.monto_pendiente,
              monto_cobrado:obj.monto_cobrado,
              monto_contador:obj.monto_contador,
              termino: this.accion
            },
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
    });
}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  anular_factura(obj:any,termino:any){
        try {
          obj.val_def = 'F';
          obj.tipo_operacion = 'ANULACION';
          obj.cod_usuario = localStorage.getItem('cod_usuario');
          this.servicio.validar_acceso_acciones_http(obj).subscribe(
            response=>{
              this.servicio.buscarPedidoProductocodttp(obj).subscribe(
                response =>{
                  console.log(response.resul2[0].tipo_pedido);
                  if (response.resul2[0].tipo_pedido.toUpperCase() !== this.proforma_factura.toUpperCase()) {
                    this.servicio.buscarDocumentoAnuladohttp(obj).subscribe(
                      response =>{
                        this.snackBar.open('El documento " '+obj.cod_factura +' "  ya fue anulado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                      },
                      error =>{
                        var dialogRef:any;
                                this.accion = 'anulacion_ventas';
                                  dialogRef = this.dialog.open(FormmMsgComponent, {
                                    width: '100vw',
                                  maxWidth: '100vw',
                                  height:'100vh',
                                    data: {
                                            accion:this.accion},
                                    disableClose: true,
                                    panelClass: 'pedidos_modal',
                                    backdropClass: "bdrop"
                                  });
                            
                                  dialogRef.afterClosed().subscribe(result => {
                                    if(result !== 'cerrar'){
                                      obj.comentarios = result.comentarios;
                                      obj.cod_usuario = localStorage.getItem('cod_usuario');
                                      obj.des_usuario = localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido');
                                      console.log(obj);
                            
                                      this.anularFactura(obj);
                                    }
                              }
                            );
                      }
                    );
                  }else{
                    this.snackBar.open('Las facturas originadas de Pro-formas no se puede anular', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition});
                  }
                },
                error=>{
                  this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
                }
              );
            },
            error=>{
               this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                 verticalPosition: this.verticalPosition});
            }
          );
          
        } catch (error) {
          console.log(<any>error);
        }
      }

      anularFactura(xvalor:any){
        try {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          setTimeout( async() => {
            xvalor.val_def = 'VENTAS-ENV';
            xvalor.tipo_operacion = 'ANULARFACTURA';
            this.servicio.gestionAnulacionVentahttp(xvalor).subscribe(
              response =>{
                this.dialogSpin.close();
                this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition});
                  this.listarfactura(null);
              },
              error =>{
                this.dialogSpin.close();
                console.log('una respuesta');
                console.log(<any>error);
                this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition});
              }
            );
          }, 1000);
          return this.xobj2;
        } catch (error) {
          console.log(<any>error);
        }
      }

      dailyreport(){
        try {
          var dialogRef:any;
          let datos = localStorage.getItem('cod_usuario');
          let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
   
                this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
                  width: '15%',
                  height:'25%',
                  data: {
                          accion:this.accion},
                  disableClose: true,
                  panelClass: 'spinner',
                  backdropClass: "bdrop"
                });
        
                this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                            cod_menu: localStorage.getItem('sub_main'),
                            cod_sesion: localStorage.getItem('cod_sesion'),
                            dir_ip_client: localStorage.getItem('dir_ip'),
                          sesion_unica:sessionStorage.getItem('sesion_unica')};
            
                setTimeout(() => {
            
                  this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {
            
                    this.dialogSpin.close()
              
                    var dialogRef;
                    dialogRef = this.dialog.open(FormDailyVistaPreviaComponent,{
                    width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                    disableClose:   true,
                    panelClass: 'pedidos_modal3',
                    backdropClass: "bdrop"
                    });
              
                    dialogRef.afterClosed().subscribe(resul => {
              
                      if(resul != 'cerrar'){
              
                        console.log('Se cerro la wea')
                        
                      }
  
                      this.listarfactura(null);
                   
                  })
                  },
                  error =>{
                    this.dialogSpin.close()
                    this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                  })
                }, 1000);  
        } catch (error) {
          console.log(error);
        }    
      }
}
