<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); ">
                    <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" *ngIf="accion === 'ventas'" class=" text-center"><strong>Descargar reporte de  VENTAS a la fecha: {{fecha}}</strong> </h3>
                    </div>
                    <div class="modal-body">
                        <div class="row border py-2 my-2 px-1">
                            <div class="col-md-6 border py-3">
                                <div class=" text-center my-2">
                                    <p class="modal-title" *ngIf="accion === 'ventas'" style="font-size: 12px;"><strong>Reporte general de : VENTAS</strong> </p>
                                </div>
                            </div>
                            <div class="col-md-2  py-1">
                                <div class=" text-center" matTooltip="DESCARGAR XLSX">
                                    <img src="../../../../../../assets/img/excel.png" style="cursor: pointer;" width="65" height="65" alt="" (click)="en_construccion()">
                                </div>
                            </div>
                            <div class="col-md-2  py-1">

                                <div class=" text-center" matTooltip="DESCARGAR PDF">
                                    <img src="../../../../../../assets/img/pdf_pro.png" style="cursor: pointer;" width="65" height="65" alt="" (click)="en_construccion()">
                                </div>
                            </div>
                            <div class="col-md-2  py-1">
                                <div class=" text-center" matTooltip="DESCARGAR TXT">
                                    <img src="../../../../../../assets/img/txt_pro.png" style="cursor: pointer;" width="65" height="65" alt="" (click)="en_construccion()">
                                </div>
                            </div>
                        </div>
                        <div class="row border py-2 my-2 px-1">
                            <div class="col-md-6 border py-3">
                                <div class=" text-center my-2">
                                    <p class="modal-title " *ngIf="accion === 'ventas'" style="font-size: 12px;"><strong>Reporte por rango de fecha: VENTAS  </strong> </p>
                                </div>
                            </div>
                            <div class="col-md-6 border ">
                                <mat-form-field class="materialinput py-2">
                                    <mat-label>Seleccione un rango de fecha</mat-label>
                                    <mat-date-range-input [formGroup]="forma" [rangePicker]="picker">
                                        <input matStartDate formControlName="fecha_inicio" placeholder="Start date" autocomplete="off">
                                        <input matEndDate formControlName="fecha_fin" placeholder="End date" autocomplete="off">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>

                                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                                </mat-form-field>
                            </div>

                        </div>
                        <!-- <div class="row border py-2 my-2 px-1">
                            <div class="col-md-9 border">
                                <mat-form-field class="materialinput py-1 px-1">
                                    <mat-label style="color: #000;">Codigo del producto</mat-label>
                                    <input style="font-size: 11px; color: #000;" type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup2" #cod_producto>
                                    <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                        <mat-icon>search</mat-icon>
                                        </span>
                                    <mat-autocomplete #autoGroup2="matAutocomplete">
                                        <mat-optgroup style="color: #000; font-size: 12px; " *ngFor="let group of arry | async" [label]="group.letter">
                                            <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                                {{i}}
                                            </mat-option>
                                        </mat-optgroup>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 border">
                                
                            </div>
                        </div> -->
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class="btn btn-danger mr-1" (click)="cerrarmodal()">Cancelar</button>
                            <button mat-button [disabled]="forma.invalid" class="btn btn-success" (click)="reporte_fecha_rango()">Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>
