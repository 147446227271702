import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'; 

@Component({
  selector: 'app-form-inventario-mp',
  templateUrl: './form-inventario-mp.component.html',
  styleUrls: ['./form-inventario-mp.component.css']
})
export class FormInventarioMpComponent implements OnInit {

hide = true;
nombre:any;
forma:FormGroup;
accion:any;
unidad:any;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
displayedColumns:string[];
dataSource1=new MatTableDataSource<any>();
horizontalPosition: MatSnackBarHorizontalPosition = 'start';
verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor( fb:FormBuilder,
    public dialogRef: MatDialogRef<FormInventarioMpComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data, 'primero');
    this.accion = data.accion;
    
      this.forma = fb.group({
        'id_producto_base':data.id_producto_base,
        'cod_producto':data.cod_producto,
        'des_producto':data.des_producto,
        'cant_producto':data.cant_producto,
        'cost_producto':data.cost_producto,
        'estatus_producto':data.estatus_producto,
        'tipo_producto_mp':data.tipo_producto_mp,
        'unidad_medida':data.unidad_medida,
      });
    }

  ngOnInit(): void {
  }
  
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
    }else{
    
    console.log(this.forma.value);
    this.dialogRef.close(this.forma.value);
    }
    
  }
  
  cerrarmodal(): void {
    this.dialogRef.close('cerrar');
  }


}













