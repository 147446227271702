import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormPedidosComponent } from '../form-pedidos/form-pedidos.component';
import { FormPedidosNComponent } from '../form-pedidos-n/form-pedidos-n.component';
import { FormPedidosNBsComponent } from '../form-pedidos-n-bs/form-pedidos-n-bs.component';
import { FormPedidoFrecuencialComponent } from '../form-pedido-frecuencial/form-pedido-frecuencial.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-seleccion',
  templateUrl: './form-seleccion.component.html',
  styleUrls: ['./form-seleccion.component.css']
})
export class FormSeleccionComponent implements OnInit {

  forma:FormGroup;
  accion:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  termino:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private snackBar: MatSnackBar,public dialog: MatDialog, fb:FormBuilder,
    public dialogRef: MatDialogRef<FormSeleccionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.termino = data.termino;
      console.log(data, 'primero');
    this.accion = data.accion;
    switch (this.accion) {
      case 'eliminar':
        this.forma = fb.group({
          'id':data.id
        });
        break;

      default:
        break;
    }
    }

  ngOnInit(): void {
  }

  editardatos( text:any){
    var dialogRefn:any;
    var dialogReff:any;
    switch (text) {
      case 'factura':
        var termino1 = 'factura';
       dialogReff = this.dialog.open(FormSeleccionComponent, {
        width: '25%',
        height:'35%',
        data: {id:'', cod_cliente:this.data.cod_cliente,
                      des_cliente: this.data.des_cliente,
                      des_vendedor:this.data.des_vendedor,
                      tipo_cliente:this.data.tipo_cliente,
                      termino:'frecuencial',
                      termino2:termino1,
                      accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
      dialogReff.afterClosed().subscribe(result => {
        if(result != 'cerrar' && result != 'salir'){
          console.log(result);

          this.dialogRef.close(result);
        }else{
          if (result == 'cerrar') {
            this.dialogRef.close('cerrar');
          }
        }
      });
          break;
      case 'nota':
        var termino2 = 'nota';
      dialogRefn = this.dialog.open(FormSeleccionComponent, {
        width: '25%',
        height:'35%',
        data: {id:'', cod_cliente:this.data.cod_cliente,
                      des_cliente: this.data.des_cliente,
                      des_vendedor:this.data.des_vendedor,
                      tipo_cliente:this.data.tipo_cliente,
                      termino:'modena',
                      termino2:termino2,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
      dialogRefn.afterClosed().subscribe(result => {
        if(result != 'cerrar' && result != 'salir'){
          console.log(result);
          this.dialogRef.close(result);
        }else{
          if (result == 'cerrar') {
            this.dialogRef.close('cerrar');
          }
        }
      });
            break;
      default:
        break;
    }

  }

  facturas(text:any){
    var dialogRefn:any;
    var dialogRefB:any;
    console.log(this.xobj3,'UNIDADES');
    switch (text) {
      case 'normal':
        this.accion = text;
        dialogRefB = this.dialog.open(FormPedidosComponent, {
        width: '100%',
        height:'70%',
        data: {id:'', cod_cliente:this.data.cod_cliente,
                des_cliente: this.data.des_cliente,
                des_vendedor:this.data.des_vendedor,
                tipo_cliente:this.data.tipo_cliente,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
      dialogRefB.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);

          this.dialogRef.close(result);

         /* let resultado = this.gestionpedidos(result); */
        }else{
          if (result == 'cerrar') {
            this.dialogRef.close('cerrar');
          }
        }
      });
          break;
      case 'frecuencial':
        this.accion = text;
      dialogRefn = this.dialog.open(FormPedidoFrecuencialComponent, {
        width: '100%',
        height:'87%',
        data: {id:'', cod_cliente:this.data.cod_cliente,
                      des_cliente: this.data.des_cliente,
                      des_vendedor:this.data.des_vendedor,
                      tipo_cliente:this.data.tipo_cliente,
                      accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
      dialogRefn.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);
          this.dialogRef.close(result);
        /*  let resultado = this.gestionpedidos(result); */
        }else{
          if (result == 'cerrar') {
            this.dialogRef.close('cerrar');
          }
        }
      });
            break;
      default:
        break;
    }

  }

  notasEntrega(text:any){
    var dialogRefn:any;
    var dialogRefB:any;
    console.log(this.xobj3,'UNIDADES');
    switch (text) {
      case 'divisas':
        this.accion = text;
        dialogRefB = this.dialog.open(FormPedidosNComponent, {
        width: '100%',
        height:'87%',
        data: {id:'', cod_cliente:this.data.cod_cliente,
                des_cliente: this.data.des_cliente,
                des_vendedor:this.data.des_vendedor,
                tipo_cliente:this.data.tipo_cliente,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
      dialogRefB.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);

          this.dialogRef.close(result);

         /* let resultado = this.gestionpedidos(result); */
        }else{
          if (result == 'cerrar') {
            this.dialogRef.close('cerrar');
          }
        }
      });
          break;
      case 'bolivares':
        this.accion = text;
      dialogRefn = this.dialog.open(FormPedidosNBsComponent, {
        width: '100%',
        height:'87%',
        data: {id:'', cod_cliente:this.data.cod_cliente,
                      des_cliente: this.data.des_cliente,
                      des_vendedor:this.data.des_vendedor,
                      tipo_cliente:this.data.tipo_cliente,
                      accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
      dialogRefn.afterClosed().subscribe(result => {
        console.log(result);
        if(result != 'cerrar'){
          console.log(result);
          this.dialogRef.close(result);
        /*  let resultado = this.gestionpedidos(result); */
        }else{
          if (result == 'cerrar') {
            this.dialogRef.close('cerrar');
          }
        }
      });
            break;
      default:
        break;
    }

  }
  guardarregistros(res:any){

    if(res == 'guardar'){
      this.dialogRef.close('abrir');
    }
  }

  cerrarmodal(): void {
    this.dialogRef.close('salir');
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en deshabilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
}

}
