import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormFormulaUnidadComponent } from '../form-formula-unidad/form-formula-unidad.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-confirmar-fabricacion-pt',
  templateUrl: './form-confirmar-fabricacion-pt.component.html',
  styleUrls: ['./form-confirmar-fabricacion-pt.component.css']
})
export class FormConfirmarFabricacionPtComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('table2') table2:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('tasa_base',{static:true}) tasa_base:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  arry: Observable<StateGroup[]>;


  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
dialogSpin:any;
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormConfirmarFabricacionPtComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'cant_producto':'',
        'ord_producto':'',
        'ord_producto2':'',
        'diferencia':'',
        'cod_lote':data.cod_lote,
        'cod_orden_producto':data.cod_orden_producto,
        'estatus':''
      });
    }

  ngOnInit(): void {

    this.listarOrdenProd(this.data.cod_orden_producto);

  }
  ngAfterViewInit() {}

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }

 listarOrdenProd(xvalor:any){
  var valor = {cod_orden_producto:xvalor}
  console.log(xvalor);
 this.servicio.buscarOrdenPTProdhttp(valor).subscribe(
   response =>{
     console.log(response);
       this.ELEMENT_DATA = [];

           for (const i in response) {
             this.ELEMENT_DATA.push({
               cod_producto:response[i].cod_producto,
               des_producto:response[i].des_producto,
               cant_unidad_producto:response[i].cant_producto,
               cant_producto:response[i].cant_producto,
               unidad_prod_mp:response[i].unidad_prod_mp
             });
           }
           this.displayedColumns = [
                                     'cod_producto',
                                     'cant_producto',
                                     'unidad_medida'
                                   ];
        this.arry = this.forma.get('cod_producto')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }

                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.producto
                  }];

            this.displayedColumns2 = [
                                     'cod_producto',
                                     'des_producto',
                                     'cant_producto',
                                     'remove_elem'
                                   ];
           this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);

   },
   error =>{
     console.log(error);
     console.log(<any>error.error.message);
     this.displayedColumns = [
                              'cod_producto',
                              'cant_producto',
                              'unidad_medida'
                            ];
    this.displayedColumns2 = [
                              'cod_producto',
                              'des_producto',
                              'cant_producto',
                              'remove_elem'
                            ];
     this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
     this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
     this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
       verticalPosition: this.verticalPosition});
   }
 );
}

cantidad_item() {
  try {
    return this.ELEMENT_DATA.length;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

  addElement() {
    var codigo = true;
    let arry = [];
    let arry2 = [];
    var cod = this.forma.value.cod_producto.split(' ');
    var valor:any = {cod_producto:cod[0]}
    console.log(valor);
    this.forma.value.cant_producto = this.cant_producto.nativeElement.value;

    if (this.ELEMENT_DATA[0].cod_producto != cod[0] && cod[0] != '') {
      codigo = false;
    }

    if (parseInt(this.forma.value.cant_producto) > 0 && this.cod_producto.nativeElement.value != '' && codigo === true) {
     
    this.servicio.buscarPRODUCTOBttp(valor).subscribe(
      response =>{
        console.log(this.ELEMENT_DATA[0].cod_producto);
        console.log(response);

        if (this.ELEMENT_DATA2.length == 0) {
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];

          this.ELEMENT_DATA2.push({
            cod_producto:response[0].cod_producto,
            des_producto:response[0].des_producto,
            cant_producto: parseInt(this.forma.value.cant_producto).toFixed(0).replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
          });

          this.dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
          this.cod_producto.nativeElement.value = '';
          this.cant_producto.nativeElement.value = '';

        }else{

          console.log(this.ELEMENT_DATA2);
            for (const i in this.ELEMENT_DATA2) {
             arry.push(this.ELEMENT_DATA2[i].cod_producto);
            }
            var index = arry.includes(response[0].cod_producto);

            if (!index) {
              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];

              this.cod_producto.nativeElement.value = '';
              this.cant_producto.nativeElement.value = '';;


                this.snackBar.open('Solo puede asignar un producto en la orden de produccion', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});

            }else{

              if (index) {
                this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
                  this.cod_producto.nativeElement.value = '';
                  this.cant_producto.nativeElement.value = '';
              }

              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];


            }
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.cod_producto.nativeElement.value = '';
              this.cant_producto.nativeElement.value = '';
      }
    );
    }else{
        if (parseInt(this.cant_producto.nativeElement.value) <= 0 || isNaN(this.cant_producto.nativeElement.value) || this.cant_producto.nativeElement.value === "") {
          this.snackBar.open('La cantidad debe ser mayor a 0 y solo puede ingresar numeros', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.cod_producto.nativeElement.value  === "") {
          this.snackBar.open('Debes seleccionar al menos un producto', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (codigo === false) {
          this.snackBar.open('El codigo de producto ingresado no se encuentra en la orden de produccion que esta gestionando', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
            this.cant_producto.nativeElement.value = '';

        }
    }
  }

modalrespuesta(){

  if (this.ELEMENT_DATA2.length > 0 ) {
    if (parseInt(this.ELEMENT_DATA[0].cant_producto.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'))  === parseInt(this.ELEMENT_DATA2[0].cant_producto.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.')) ) {

        this.accion = "ordenC";
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: {id:'',
                accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){

            this.forma.value.ord_producto = this.ELEMENT_DATA2;
            this.forma.value.ord_producto2 = this.ELEMENT_DATA;
            this.forma.value.estatus = this.accion;
            console.log(this.forma.value);
            this.dialogRefp.close(this.forma.value);

          }
        });
    }

      if (parseInt(this.ELEMENT_DATA2[0].cant_producto.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) < parseInt(this.ELEMENT_DATA[0].cant_producto.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'))) {
        var diferencia_I =  parseInt(this.ELEMENT_DATA[0].cant_producto.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')) - parseInt(this.ELEMENT_DATA2[0].cant_producto.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'));
        this.accion = "ordenI";
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: {id:'',
                accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){

            this.forma.value.ord_producto = this.ELEMENT_DATA2;
            this.forma.value.ord_producto2 = this.ELEMENT_DATA;
            this.forma.value.diferencia = diferencia_I.toString();
            this.forma.value.estatus = this.accion;
            console.log(this.forma.value);
            this.dialogRefp.close(this.forma.value);

          }
        });
      }
      if (parseInt(this.ELEMENT_DATA2[0].cant_producto.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) > parseInt(this.ELEMENT_DATA[0].cant_producto.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'))) {
        var diferencia_E = parseInt(this.ELEMENT_DATA2[0].cant_producto.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')) - parseInt(this.ELEMENT_DATA[0].cant_producto.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'));
        this.accion = "ordenE";
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: {id:'',
                accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
            this.forma.value.ord_producto = this.ELEMENT_DATA2;
            this.forma.value.ord_producto2 = this.ELEMENT_DATA;
            this.forma.value.estatus = this.accion;
            this.forma.value.diferencia = diferencia_E.toString();
            console.log(this.forma.value);
            this.dialogRefp.close(this.forma.value);

          }
        });
      }
  }else{

    if (this.cod_producto.nativeElement.value  === "" || this.ELEMENT_DATA2.length === 0) {
      this.snackBar.open('Debes seleccionar al menos un producto', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
remove(val:any){
  this.dataSource2.data.splice(this.ELEMENT_DATA2.indexOf(val), 1);
   this.table2.renderRows();
 }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  async detalleOrdenProd(xvalor:any){
    console.log(xvalor);
    this.dialog.open(FormFormulaUnidadComponent, {
      width: '100vw',
      height:'100vh',
      data: {producto: xvalor},
      panelClass: 'pedidos_modal'
    });
}
format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
