import { Injectable } from '@angular/core';
import iziToast from 'izitoast';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationService {

  constructor() {}

  customToastNotification(title:string = 'Info', message:string = '<div class="text-decoration-none text-dark">Salir!</div>', icon:string = 'fas fa-bell'){
    iziToast.show({
      title: title,
      position: 'bottomRight',
      message: message,
      theme: 'dark',
      icon:  icon+' revealIn',
      color: 'rgba(168, 212, 255, 1)',
      progressBarColor: 'rgba(0, 71, 143, 0.92)',
      titleColor: '#000',
      messageColor: '#000',
      iconColor: '#000',
      timeout: 90000
    });
  }

  danger(title = 'Error',  message = '<a href="#" class="text-decoration-none text-dark">Click me!</a>'){
    iziToast.error({
      title: title,
      message: message,
      icon: 'fas fa-exclamation-triangle revealIn',
      titleColor: '#000',
      messageColor: '#000',
      iconColor: '#000',
      timeout: 90000
    });
  }

  warning(title = 'Alerta!', message = '<div class="text-decoration-none text-dark">Salir!</div>'){
    iziToast.warning({
      title: title,
      position: 'center',
      message: message,
      icon: 'fas fa-exclamation-triangle revealIn',
      titleColor: '#000',
      messageColor: '#000',
      iconColor: '#000',
      timeout: 90000
    });

  }
  success(title = 'Listo!', message = '<div class="text-decoration-none text-dark">Salir!</div>' ){
    iziToast.success({
      title: title,
      position: 'topRight',
      message: message,
      icon: '',
      titleColor: '#000',
      messageColor: '#000',
      iconColor: '#000',
      timeout: 90000
    });
  }
}
