<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav_componente nav py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarSolicitudesSoporte("actualizar")'><mat-icon >sync</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>GESTIONAR SOPORTE TÉCNICO</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>
    <div class="row altura_table">
        <div class="col-md-12 px-0  border h-100 table_overflow_color">
           <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped  table-condensed table-hover">

            <ng-container matColumnDef="cod_ticket">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Ticket</strong></h5>
                </th>
                <td [ngClass]="{'resaltar_soporte_tomado':  element.estatus === 'SPRT-T','resaltar_soporte_tomado_2':  element.estatus === 'SPRT-F','resaltar_soporte_tomado_3':  element.estatus === 'SPRT-C'} " mat-cell *matCellDef="let element" class="td_style">
                    {{element.cod_ticket}} </td>
                
            </ng-container>

            <ng-container matColumnDef="tipo_solicitud">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Tipo solicitud</strong></h5>
                </th>
                <td [ngClass]="{'resaltar_soporte_tomado':  element.estatus === 'SPRT-T','resaltar_soporte_tomado_2':  element.estatus === 'SPRT-F','resaltar_soporte_tomado_3':  element.estatus === 'SPRT-C'} " mat-cell *matCellDef="let element" class="td_style">
                    {{element.tipo_solicitud}} </td>
               
            </ng-container>

            <ng-container matColumnDef="tipo_soporte">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Tarea</strong></h5>
                </th>
                <td [ngClass]="{'resaltar_soporte_tomado':  element.estatus === 'SPRT-T','resaltar_soporte_tomado_2':  element.estatus === 'SPRT-F','resaltar_soporte_tomado_3':  element.estatus === 'SPRT-C'} " mat-cell *matCellDef="let element" class="td_style">
                    {{element.tipo_soporte}} </td>
                
            </ng-container>

            <ng-container matColumnDef="des_usuario">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Usuario</strong></h5>
                </th>
                <td [ngClass]="{'resaltar_soporte_tomado':  element.estatus === 'SPRT-T','resaltar_soporte_tomado_2':  element.estatus === 'SPRT-F','resaltar_soporte_tomado_3':  element.estatus === 'SPRT-C'} " mat-cell *matCellDef="let element" class="td_style">
                    {{element.des_usuario}} </td>
               
            </ng-container>

            <ng-container matColumnDef="estatus">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Estatus</strong></h5>
                </th>
                <td [ngClass]="{'resaltar_soporte_tomado':  element.estatus === 'SPRT-T','resaltar_soporte_tomado_2':  element.estatus === 'SPRT-F','resaltar_soporte_tomado_3':  element.estatus === 'SPRT-C'} " mat-cell *matCellDef="let element" class="td_style">
                    {{element.estatus}} </td>
                
            </ng-container>

            <ng-container matColumnDef="eval_soporte">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Evaluación</strong></h5>
                </th>
                <td [ngClass]="{'resaltar_soporte_tomado':  element.estatus === 'SPRT-T','resaltar_soporte_tomado_2':  element.estatus === 'SPRT-F','resaltar_soporte_tomado_3':  element.estatus === 'SPRT-C'} " mat-cell *matCellDef="let element" class="td_style">
                    {{element.eval_soporte}} </td>
               
            </ng-container>

            <ng-container matColumnDef="des_tecnico">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Técnico</strong></h5>
                </th>
                <td [ngClass]="{'resaltar_soporte_tomado':  element.estatus === 'SPRT-T','resaltar_soporte_tomado_2':  element.estatus === 'SPRT-F','resaltar_soporte_tomado_3':  element.estatus === 'SPRT-C'} " mat-cell *matCellDef="let element" class="td_style">
                    {{element.des_tecnico}} </td>
                
            </ng-container>

            <ng-container matColumnDef="procesos">
                <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                    <h5><strong>Procesos</strong></h5>
                </th>
                <td [ngClass]="{'resaltar_soporte_tomado':  element.estatus === 'SPRT-T','resaltar_soporte_tomado_2':  element.estatus === 'SPRT-F','resaltar_soporte_tomado_3':  element.estatus === 'SPRT-C'} " mat-cell *matCellDef="let element"style="padding: 1px; font-size: 12px;" class="col-md-4">
                    <button class="bg-primary mr-1" mat-mini-fab (click)='mostrar_descrip(element,"descrip")' matTooltip="DESCRIPCIÓN">
                        <mat-icon>description</mat-icon>
                    </button>
                    <button class="bg-primary mr-1" mat-mini-fab (click)='mostrar_comentarios(element,"actualizar_comentarios")'  matTooltip="COMENTARIOS">
                        <mat-icon>note_add</mat-icon>
                    </button>
                    <button class="bg-primary mr-1" mat-mini-fab (click)='mostrar_tiempo(element,"tiempo")' matTooltip="TIEMPO">
                        <mat-icon>alarm</mat-icon>
                    </button>
                    <button  [disabled]="element.estatus === 'SPRT-T' ||  element.estatus === 'SPRT-F' ||  element.estatus == 'SPRT-C'" class="bg-primary mr-1" mat-mini-fab (click)='tomar_solicitud_soporte(element)' matTooltip="TOMAR SOPORTE">
                        <mat-icon >build</mat-icon>
                    </button>
                    <button [disabled]="element.estatus != 'SPRT-T' ||  element.estatus == 'SPRT-F' ||  element.estatus == 'SPRT-C'" class="bg-primary mr-1" mat-mini-fab (click)='finalizar_solicitud_soporte(element)' matTooltip="FINALIZAR SOPORTE">
                        <mat-icon >assignment_turned_in</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
           </table>
           <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
  </div>