import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormSoporteComponent } from 'src/app/componentes/shared/modal/form-soporte/form-soporte.component';
import { FormEvaluacionSoporteComponent } from 'src/app/componentes/shared/modal/form-evaluacion-soporte/form-evaluacion-soporte.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';
import { throwIfEmpty } from 'rxjs/operators';
interface interfacefactura{
  cod_lote: string;
  cant_producto:string;
  cod_producto:string;
  des_producto:string;
  cant_req:string;
  restante:string;
}
interface interdatosfacefactura{
cod_guia_despacho:string;
cod_factura:string;
cod_pedido:string;
fecha_inicio:string;
}

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-soporte-it',
  templateUrl: './soporte-it.component.html',
  styleUrls: ['./soporte-it.component.css']
})
export class SoporteItComponent implements OnInit {

  auth_menu:any = [];

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  cod_orden_producto:any;
  cantidad_prod:any;
  cod_prod:any;
  des_prod:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private notifi:ToastNotificationService,
    ){}

  ngOnInit(): void {
    this.listarSolicitudesSoporte(null);
    this.listarTipoTareas();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

 async listarSolicitudesSoporte(msj:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
        let msg = msj;
        var valor = {
             cod_usuario: localStorage.getItem('cod_usuario'),
             cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
            };
        const response = await this.servicio.listarSolicitudesSoportehttp(valor).toPromise();
              this.ELEMENT_DATA = [];
                  for (const i in response) {

                    this.ELEMENT_DATA.push({
                      cod_ticket:response[i].cod_ticket,
                      tipo_solicitud:response[i].tipo_solicitud,
                      tipo_soporte:response[i].tipo_soporte,
                      cod_usuario: response[i].cod_usuario,
                      des_usuario: response[i].des_usuario,
                      estatus:response[i].estatus,
                      des_tecnico:response[i].des_tecnico,
                      cod_tecnico:response[i].cod_tecnico,
                      fecha_inicio:response[i].fecha_inicio,
                      hora_inicio:response[i].hora_inicio,
                      fecha_fin:response[i].fecha_fin,
                      hora_fin:response[i].hora_fin,
                      duracion_soporte:response[i].duracion_soporte,
                      descripcion_soporte:response[i].descripcion_soporte,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [
                                            'cod_ticket',
                                            'tipo_solicitud',
                                            'tipo_soporte',
                                            'des_usuario',
                                            'estatus',
                                            'des_tecnico',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'fecha_fin',
                                            'hora_fin',
                                            'duracion_soporte',
                                            'procesos'
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                  if(msg === await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                    await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                }else{
                    await this.util.cerrarSpinner();
                }
    } catch (error) {
      console.log(error);
      this.displayedColumns = [ 
                                            'cod_ticket',
                                            'tipo_solicitud',
                                            'tipo_soporte',
                                            'des_usuario',
                                            'estatus',
                                            'des_tecnico',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'fecha_fin',
                                            'hora_fin',
                                            'duracion_soporte',
                                            'procesos'
                                          ];
      this.dataSource = new MatTableDataSource<any>();
      this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
 async listarTipoTareas(){
    try {
      const response = await this.servicio.listarTipoTareashttp().toPromise();
          console.log(response);
          for (const i in response) {
            this.xobj4.push(response[i]);
          }
    } catch (error) {
      console.log(error);
    }
 }
 async confirmar_soporte(obj:any,text:any){
    try {
          this.servicio.consultar_sesionhttp(null).subscribe(
          response =>{
            var dialogRef:any;
            this.accion = text;
            let datos = localStorage.getItem('cod_usuario');
            let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
            if (this.accion === 'actualizar') {
              dialogRef = this.dialog.open(FormConsultaOrdenesComponent, {
                width: '100vw',
                maxWidth: '100vw',
                height:'100%',
                data: {cod_orden_producto: obj.cod_orden_producto,
                        cod_usuario:datos,
                        des_usuario: nombre,
                        accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal_rep',
                backdropClass: "bdrop"
              });
            }else{
              dialogRef = this.dialog.open(FormEvaluacionSoporteComponent, {
                width: '100vw',
                  maxWidth: '100vw',
                  height:'100%',
                data: {objeto_data:obj,
                        cod_usuario:datos,
                        des_usuario: nombre,
                        tarea_padre:this.xobj4,
                        accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal_rev',
                backdropClass: "bdrop"
              });
            }

              dialogRef.afterClosed().subscribe(result => {
                if(result != 'cerrar'){
                  console.log(result);

            this.gestionSolicitudEvaluar(result);
                }
              });
          },
          error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          }
          );
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  gestionSolicitudEvaluar(xvalor:any){
    try {
      var resultado
      console.log(xvalor);
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
         resultado = this.servicio.gestionSolicitudEvaluarhttp(xvalor).subscribe(
          response =>{
            console.log(response,'una respuesta');
            this.dialogSpin.close();
            if(response.resul){
              console.log(response.resul,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];

                  for (const i in response.resul) {
                      console.log(response[i]);

                    this.ELEMENT_DATA.push({
                      cod_ticket:response.resul[i].cod_ticket,
                      tipo_solicitud:response.resul[i].tipo_solicitud,
                      tipo_soporte:response.resul[i].tipo_soporte,
                     cod_usuario:response.resul[i].cod_usuario,
                     des_usuario: response.resul[i].des_usuario,
                     estatus:response.resul[i].estatus,
                     fecha_inicio:response.resul[i].fecha_inicio,
                     hora_inicio:response.resul[i].hora_inicio,
                     fecha_fin:response.resul[i].fecha_fin,
                     hora_fin:response.resul[i].hora_fin,
                     duracion_soporte:response.resul[i].duracion_soporte,
                     descripcion_soporte:response.resul[i].descripcion_soporte,
                     des_tecnico:response.resul[i].des_tecnico,
                     cod_tecnico:response.resul[i].cod_tecnico,
                     accion:'',
                     accion2:'',
                     accion3:''
                   });
                  }


                  this.displayedColumns = [ 'cod_ticket',
                                                'tipo_solicitud',
                                                'tipo_soporte',
                                                'des_usuario',
                                                'estatus',
                                                'des_tecnico',
                                                'fecha_inicio',
                                                'hora_inicio',
                                                'fecha_fin',
                                                'hora_fin',
                                                'duracion_soporte',
                                                'descripcion_soporte',
                                                'confirmar'
                                              ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
           this.dialogSpin.close();
            console.log(error);
            this.displayedColumns = [ 'cod_ticket',
                                                'tipo_solicitud',
                                                'tipo_soporte',
                                                'des_usuario',
                                                'estatus',
                                                'des_tecnico',
                                                'fecha_inicio',
                                                'hora_inicio',
                                                'fecha_fin',
                                                'hora_fin',
                                                'duracion_soporte',
                                                'descripcion_soporte',
                                                'confirmar'
                                              ];
            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
      }, 3000);

       return resultado;
    } catch (error) {
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

   }

 async update_insert_data(obj:any,text:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
            var dialogRef:any;
              this.accion = text;
              if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                await this.util.cerrarSpinner();
                dialogRef = this.dialog.open(FormConsultaOrdenesComponent, {
                  width: '100vw',
                  height:'100vh',
                  data: {
                          accion:this.accion
                        },
                  disableClose: true,
                  panelClass: 'pedidos_modal',
                  backdropClass: "bdrop"
                });
              }else{
                let val_def = {
                  val_def:await this.atrb.ACCESO_VALDEF.gestionar_soporte,
                  tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                  cod_usuario : await this.atrb.COD_USUARIO.cod_usuario
                }
                await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                let objeto = {
                  cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
                  mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
                  des_parametro: await this.atrb.OPERACIONES.soporte
                };
                await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
                await this.util.cerrarSpinner();
                dialogRef = this.dialog.open(FormSoporteComponent, {
                    width: '100vw',
                    height:'100vh',
                  data: {
                          tarea_padre:this.xobj4,
                          accion:this.accion},
                  disableClose: true,
                  panelClass: 'pedidos_modal',
                  backdropClass: "bdrop"
                });
              }
                dialogRef.afterClosed().subscribe(async result => {
                  await this.util.abrirSpinner();
                    if(result !== 'cerrar'){
                      await this.util.cerrarSpinner();
                        this.gestionSolicitudTareas(result);
                    }else{
                      await this.util.cerrarSpinner();
                    }
                });
    } catch (error) {
          await this.util.cerrarSpinner();
          console.log(error);
          await this.notifi.customToastNotification(error.error.message);
    }
  }

async  gestionSolicitudTareas(xvalor:any){
  try {
     const  response = await this.servicio.gestionSolicitudTareashttp(xvalor).toPromise();
        this.listarSolicitudesSoporte(null);
        await this.notifi.success(response.message);
  } catch (error) {
    await this.notifi.customToastNotification(error.error.message);
  }
 }

  mostrar_descrip(obj:any, text:any){

    try {
      this.accion = text;
      this.dialog.open(FormDireccionesComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'100%',
        data: { descripcion_soporte:obj.descripcion_soporte,
                termino: this.accion
              },
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
      });
    } catch (error) {
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
