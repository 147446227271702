import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodmppComponent } from 'src/app/componentes/shared/modal/form-ordenesprodmpp/form-ordenesprodmpp.component';
import { FormConsultaOrdenesMppComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes-mpp/form-consulta-ordenes-mpp.component';
import { FormConfirmacionInvMppComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mpp/form-confirmacion-inv-mpp.component';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormOrdHistorialPtComponent } from 'src/app/componentes/shared/modal/form-ord-historial-pt/form-ord-historial-pt.component';
import { FormOrdHistorialMppComponent } from 'src/app/componentes/shared/modal/form-ord-historial-mpp/form-ord-historial-mpp.component';
interface interfacefactura{
  cod_lote: string;
  cant_producto:string;
  cod_producto:string;
  des_producto:string;
  cant_req:string;
  restante:string;
}
interface interdatosfacefactura{
cod_guia_despacho:string;
cod_factura:string;
cod_pedido:string;
fecha_inicio:string;
}
type TableRow = [string, string, string, string, string];

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-gestion-ordenes-mpp',
  templateUrl: './gestion-ordenes-mpp.component.html',
  styleUrls: ['./gestion-ordenes-mpp.component.css']
})
export class GestionOrdenesMppComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  cod_orden_producto:any;
  cod_orden_producto_mpp:any;
  cantidad_prod:any;
  cod_prod:any;
  des_prod:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );

  }

  ngOnInit(): void {
    this.listarordenesprodmpp(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////
  listarordenesprodmpp(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        var valor = {termino:'P'}
        this.servicio.listarOrdenesProdMpphttp(valor).subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.cod_orden_producto = response[0].cod_orden_producto;
              this.cod_orden_producto_mpp = response[0].cod_orden_producto_mpp;
              this.listarProductoOrdenesProd(response[0].cod_orden_producto_mpp);
              this.ELEMENT_DATA = [];
              for (const i in response) {
                if (response[i].cod_formula === null || response[i].cod_formula === '') {
                  response[i].cod_formula = 'SNFRML';
                }
                 }
                  for (const i in response) {
    
                    this.ELEMENT_DATA.push({
                      cod_formula:response[i].cod_formula,
                      cod_orden_producto:response[i].cod_orden_producto,
                      cod_orden_producto_mpp:response[i].cod_orden_producto_mpp,
                      cod_usuario:response[i].cod_usuario,
                      des_usuario: response[i].des_usuario,
                      cod_producto: response[i].cod_producto,
                      des_producto: response[i].des_producto,
                      cant_producto: response[i].cant_producto,
                      estatus:response[i].estatus,
                      fecha_inicio:response[i].fecha_inicio,
                      hora_inicio:response[i].hora_inicio,
                      cod_usuario_cierre: response[i].cod_usuario_cierre,
                      des_usuario_cierre:response[i].des_usuario_cierre,
                      fecha_fin:response[i].fecha_fin,
                      hora_fin:response[i].hora_fin,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [ 'cod_orden_producto',
                                            'cod_orden_producto_mpp',
                                            'cod_producto',
                                            'des_producto',
                                            'cant_producto',
                                            'des_usuario',
                                            'estatus',
                                            'tiempo',
                                            'Imprimir',
                                            'confirmar'
                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [ 'cod_orden_producto',
                                            'cod_orden_producto_mpp',
                                            'cod_producto',
                                            'des_producto',
                                            'cant_producto',
                                            'des_usuario',
                                            'estatus',
                                            'tiempo',
                                            'Imprimir',
                                            'confirmar'
                                          ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  listarProductoOrdenesProd(xvalor:any){
    var valor = {cod_orden_producto:xvalor}
   this.servicio.buscarProductoOrdenesProdMpp2http(valor).subscribe(
     response =>{
       console.log(response);
         this.ELEMENT_DATA2 = [];
             for (const i in response) {

               this.ELEMENT_DATA2.push({
                 cod_producto:response[i].cod_producto,
                 des_producto:response[i].des_producto,
                 cant_producto:response[i].cant_producto
               });
             }
             this.cantidad_prod = this.ELEMENT_DATA2[0].cant_producto;
             this.cod_prod = response[0].cod_producto;
             this.des_prod = response[0].des_producto;

     },
     error =>{
       console.log(error);
       console.log(<any>error.error.message);

       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
   );
 }

  //////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////
 
 async confirmacionOrdenProdMpp(xvalor:any){
   try {
    this.key_hash = {};
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
                xvalor.val_def = 'PRDCCN';
                xvalor.tipo_operacion = 'LABMPP';
                xvalor.cod_usuario = localStorage.getItem('cod_usuario');
              this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
                response =>{
                
                    if (xvalor.estatus !== 'P') {
                      var valor = {cod_orden_producto:xvalor.cod_orden_producto}
                                  this.servicio.buscarOrdenProdPthttp(valor).subscribe(
                                    response =>{
                                      console.log(response);
                                      var dialogRef = this.dialog.open(FormConfirmacionInvMppComponent, {
                                        width: '100vw',
                                        maxWidth: '100vw',
                                        height:'100vh',
                                        data: {cod_usuario:localStorage.getItem('cod_usuario'),
                                              des_usuario:localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido'),
                                              cod_formula:xvalor.cod_formula,
                                              cod_orden_producto_mpp: xvalor.cod_orden_producto_mpp,
                                              cod_orden_producto: xvalor.cod_orden_producto,
                                              cod_producto:response[0].cod_producto,
                                              cant_producto:response[0].cant_producto,
                                              estatus:xvalor.estatus},
                                        disableClose: true,
                                        panelClass: 'pedidos_modal3',
                                        backdropClass: "bdrop"
                                      });
                                      dialogRef.afterClosed().subscribe(result => {
                                        if(result != 'cerrar'){
                                          console.log(result,'CONSULTA');
                                        this.gestionOrdenLaboratorioMpp(result);
                                        }
                                      });
                                    },
                                    error =>{
                                      console.log(error);
                                    }
                                  );
                    }else{
                      this.snackBar.open('Orden " '+xvalor.cod_orden_producto_mpp+' " aun esta pendiente por verificar, no se puede confirmar','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,});
                    }
                 
                },
                error =>{
                  this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
                }
              );
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 
     
   } catch (error) {
    console.log(error);
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
   }
  
}
public  gestionOrdenLaboratorioMpp(xvalor:any){
  console.log(xvalor);

  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });

  setTimeout(() => {
    xvalor.val_def = 'PRODUCCION-ENV';
    xvalor.tipo_operacion = 'LABMPP';
    var resultado = this.servicio.gestionOrdenLabMpphttp(xvalor).subscribe(
      response =>{
       this.dialogSpin.close();
        console.log(response.resul,'una respuesta');
        if(response.resul){
          console.log(response.resul,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response.resul) {
                  console.log(response[i]);

                  this.ELEMENT_DATA.push({
                    cod_orden_producto:response.resul[i].cod_orden_producto,
                    cod_orden_producto_mpp:response.resul[i].cod_orden_producto_mpp,
                    cod_usuario:response.resul[i].cod_usuario,
                    des_usuario: response.resul[i].des_usuario,
                    cod_producto: response.resul[i].cod_producto,
                    des_producto: response.resul[i].des_producto,
                    cant_producto: response.resul[i].cant_producto,
                    estatus:response.resul[i].estatus,
                    fecha_inicio:response.resul[i].fecha_inicio,
                    hora_inicio:response.resul[i].hora_inicio,
                    cod_usuario_cierre: response.resul[i].cod_usuario_cierre,
                    des_usuario_cierre:response.resul[i].des_usuario_cierre,
                    fecha_fin:response.resul[i].fecha_fin,
                    hora_fin:response.resul[i].hora_fin,
                    accion:'',
                    accion2:'',
                    accion3:''
                  });
              }
              this.displayedColumns = [ 'cod_orden_producto',
                                            'cod_orden_producto_mpp',
                                            'cod_producto',
                                            'des_producto',
                                            'cant_producto',
                                            'des_usuario',
                                            'estatus',
                                            'tiempo',
                                            'Imprimir',
                                            'confirmar'
                                          ];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
              this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
       this.dialogSpin.close();
        console.log(<any>error.error.message);
        this.displayedColumns = [ 'cod_orden_producto',
                                            'cod_orden_producto_mpp',
                                            'cod_producto',
                                            'des_producto',
                                            'cant_producto',
                                            'des_usuario',
                                            'estatus',
                                            'tiempo',
                                            'Imprimir',
                                            'confirmar'
                                          ];
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }, 3000);
   return null;
 }
 //////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////

 ///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////

 mostrar_tiempo(obj:any, text:any){

  try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
response =>{
if (response.resul) {
  this.accion = text;
  this.dialog.open(FormDireccionesComponent, {
    width: '100vw',
    maxWidth: '100vw',
    height:'100%',
    data: { fecha_ini:obj.fecha_inicio+' '+obj.hora_inicio,
            cod_orden_producto_mpp:obj.cod_orden_producto_mpp,
            termino: this.accion
          },
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
  });
}else{
this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
},
error =>{
this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
verticalPosition: this.verticalPosition,});
}
); 
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

 async historialOrdProd(xvalor:any){
  try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
    cod_menu: localStorage.getItem('sub_main'),
    cod_sesion: localStorage.getItem('cod_sesion'),
    dir_ip_client: localStorage.getItem('dir_ip'),
  sesion_unica:sessionStorage.getItem('sesion_unica')};
   this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
     response =>{
       if (response.resul) {
         this.dialog.open(FormOrdHistorialPtComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'100%',
           data: {termino: xvalor},
                     disableClose: true,
                     panelClass: 'pedidos_modal3',
                     backdropClass: "bdrop"
         });
       }else{
         this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition,});
       }
     },
     error =>{
       this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   ); 
  } catch (error) {
   this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
     verticalPosition: this.verticalPosition,})
  }
}

 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
sin_permiso():void{
  try {
    this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
} 
 imprimir_descargar(xvalor:any,text:any): void {
   try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
        if (response.resul) {

          this.accion = text;
          switch (this.accion) {
            case 'imprimir':
              this.accion = 'enviarpedido';
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                maxWidth: '100vw',
                height:'100%',
                data: {id:'',
                      accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cerrar' && result != 'salir' ) {
                  dialogRef.close();
                  this.imprimirordenesmpp(xvalor);
                }else{
                  dialogRef.close();
                  
                }
              });
              break;
              case 'descargar':
              this.accion = 'enviarpedido';
              var dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '25%',
                height:'25%',
                data: {id:'',
                      accion:this.accion},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result != 'cerrar' && result != 'salir' ) {
                  dialogRef.close();
                  this.imprimirordenesmpp(xvalor);
                }else{
                  dialogRef.close(); 
                }
            
              });
              break;
          
            default:
              break;
          }
      
        }else{
          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 
     
   } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
   }
}

 imprimirordenesmpp(xvalor:any){
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });

  var valor = {cod_orden_producto:xvalor.cod_orden_producto_mpp}
  console.log(valor);
  this.servicio.buscarProductoOrdenesProdMppConfirmhttp(xvalor).subscribe(
    response =>{
      console.log(response);
      this.cod_prod = response[0].cod_producto;
      this.des_prod = response[0].des_producto;
            setTimeout(() => {
              const pdf = new PdfMakeWrapper();
              var valor = {cod_producto:response[0].cod_producto,cod_orden_producto_mpp:xvalor.cod_orden_producto_mpp,cod_orden_producto:xvalor.cod_orden_producto}
             this.servicio.buscarFormulaProdMpphttp(response[0]).subscribe(
              async  response =>{
                this.dialogSpin.close();
                 console.log(response);
                 
                   this.ELEMENT_DATA = [];

                       for (const i in response.producto1) {
                         var resul = parseFloat(response.producto1[i].cantidad_prod_mp.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.')) * parseFloat(response.producto3[0].cant_producto.replace('.','')
                         .replace('.','')
                         .replace('.','')
                         .replace(',','.'));
                         this.ELEMENT_DATA.push({
                          cod_prod_mp:response.producto1[i].cod_prod_mp,
                          des_prod_mp:response.producto1[i].des_prod_mp,
                           cantidad_prod_mp:response.producto1[i].cantidad_prod_mp,
                           tipo_producto_mp:response.producto1[i].tipo_producto_mp,
                           cant_producto:resul.toFixed(2).replace(/\D/g, "")
                           .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                           .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                           unidad_prod_mp:response.producto1[i].unidad_prod_mp,
                         });
                       }
                       console.log(this.ELEMENT_DATA);
                       pdf.info({
                    title: 'Orden de Producción N°'+this.cod_orden_producto_mpp+'',
                    author: 'Factory cosmetics C.A.',
                    subject: 'Guia de despacho',
                });
                var titulo = 'FACTORY COSMETICS 3DG C.A.';
                var texto =
                      'Ordenes de Producción';
                  pdf.pageMargins([20,5,20,5]);
                  pdf.add(pdf.ln(1));
                  pdf.add(
                    new Columns([ new Table([['',titulo,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(14).bold().absolutePosition(20,58).end]).columnGap(5).end,
                                        );
                  pdf.add(
                    new Columns([ new Table([[await new Img("../../../../../../assets/img/Factory (1).png").build(),'','']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(10,15).end]).columnGap(5).end,
                                        );
                  pdf.add(
                        new Columns([ new Table([['',texto,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(12).absolutePosition(20,80).end]).columnGap(5).end,
                                        );

                  pdf.add(this.crearTabla25(xvalor,xvalor.fecha_inicio,xvalor.hora_inicio,this.cod_prod, this.des_prod, response.producto4[0].cant_producto));
                  pdf.pageSize('LETTER');
                  pdf.add(this.headerTabla5(this.ELEMENT_DATA));
                  pdf.add(this.crearTabla45(null));
                  pdf.add(this.crearTabla46(null));
                  pdf.add(pdf.ln(1));
                   pdf.create().open();

                   if (xvalor.estatus === 'P') {
                    this.servicio.cambiarEstatusOrdenProdMppVhttp(xvalor).subscribe(
                      response =>{
                        this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition});
                        this.listarordenesprodmpp(null);
                      },
                      error =>{
                        console.log(error,'error cambio estatus v');
                      }
                    );
                  }

               },
               error =>{
                this.dialogSpin.close();
                 console.log(error);
                 console.log(<any>error.error.message);
                 this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition});
               }
             );
            }, 3000);


    },
    error =>{
      console.log(error);
      console.log(<any>error.error.message);
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  );
}

//////////////////////REPORTES////////////////////////////

/* numerofactura(data:any):ITable{
  return new Table([
    ['Asociado a la Factura Nº:  '+data+'', '', '', '', ''],
    ]).widths([200,'*','*','*','*','*']).alignment('left').fontSize(10).layout('noBorders').end;
}
numerodespacho(data:any):ITable{
  return new Table([
    ['Guía de Despacho Nº:  '+data.cod_guia_despacho+'', '', '', '', '', 'Emisión: 00000000'],
    ]).widths([200,'*','*','*','*','100']).alignment('left').bold().fontSize(10).layout('noBorders').end;
}

headerTabla():ITable{
return new Table([
  ['Lote', 'Código', 'Descripción', 'Cantidad en Lote', 'Despacho', 'Restante']
]).layout({
  vLineWidth:(i:number, node:any, col:number)=>{
      return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
      return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
    }
}).widths(['*', '*', 200, '*', '*', '*']).alignment('center').fontSize(8).end;
}
crearTabla(data:interfacefactura[]):ITable{
return new Table([
...this.extraerData(data)

]).layout('lightHorizontalLines').widths(['*', '*', 200, '*', '*', '*']).alignment('center').fontSize(10).end;
}
extraerData(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[row.cod_lote,
                             row.cod_producto,
                             row.des_producto,
                             row.cant_producto,'','']);
    } */

    //////////////////////NOTAS ND////////////////////////////
getBase64ImageFromURL5(url) {
return new Promise((resolve, reject) => {
  var img = new Image();
  img.setAttribute("crossOrigin", "anonymous");

  img.onload = () => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    var dataURL = canvas.toDataURL("image/png");

    resolve(dataURL);
  };

  img.onerror = error => {
    reject(error);
  };

  img.src = url;
});
}
headerTabla5(data:any):ITable{
return new Table([
  ['Codigo del producto','Descripción del producto','Cantidad requerida', 'tipo','unidad'],
  ...this.extraerData5(data)
]).layout('lightHorizontalLines').widths([100,200,100,'*','*']).alignment('center').fontSize(10).absolutePosition(20,200).end;
}
crearTabla555(data:interfacefactura[]):ITable{
return new Table([
...this.extraerData5(data)

]).widths([20,'*','*','*','*','*']).alignment('left').fontSize(9).layout('lightHorizontalLines').absolutePosition(20,218).margin(40).end;
}
crearTabla55(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData55(data)

]).layout('lightHorizontalLines').widths(['*','*','*','*','*',20]).alignment('right').fontSize(9).absolutePosition(20,218).margin(40).end;
}
crearTabla5(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData555(data)

  ]).layout('lightHorizontalLines').widths(['*','*','*',200,'*','*']).alignment('left').fontSize(9).absolutePosition(20,218).margin(40).end;
  }
/* async cinta85(data:interdatosfacefactura[]){

return new Table([
  [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
} */
numerofact5(data:any):ITable{

return new Table([
  ['','','','', '','Orden de Producción Nº: '+data.cod_guia_despacho+''],
]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('lightHorizontalLines').absolutePosition(20,135).end;
}
crearTabla25(data:any, data1:any, data2:any, data3:any, data4:any, data5:any){
  if (data.cod_formula === null || data.cod_formula === undefined || data.cod_formula === 'SNFRML') {
    data.cod_formula = 'Formula no registrada';
  }
 
  return  new Columns([new Table([

      ['Orden de Producción Nº: '+data.cod_orden_producto_mpp+'','Usuario: '+data.des_usuario+''],
      ['Orden de Producción Primaria Nº: '+data.cod_orden_producto+'','Codigo del usuario: '+data.cod_usuario+''],
      ['Codigo del producto Nº: '+data3+'','Codigo del lote Nº: N/A'],
      ['Descripción '+data4+'','Formula: '+data.cod_formula+''],


    ]).widths([250,'*']).alignment('left').fontSize(9).bold().layout('noBorders').absolutePosition(20,125).end,
    new Table([

      [ '','Emisión: '+data1+''],
      [ '','Hora: '+data2+''],
      [ '',' Cantidad solicitada: '+data5+' grs'],
      ['',''],

    ]).widths(['*',250]).alignment('right').fontSize(9).bold().layout('noBorders').absolutePosition(50,125).end]).columnGap(5).end;
    }
/* crearTabla35(data:interdatosfacefactura[]):ITable{
  return new Table([
    ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
  ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
  } */
  crearTabla45(data:any){

    return  new Columns([ new Table([
      ['Observaciones:','','','','',''],
      [' ','','','', '',''],
      [' ','','','','',''],
      [' ','','','','',''],
    ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(10).bold().layout({
      vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
        },
        hLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
        }
    }).absolutePosition(20,700).end,
    new Table([
      [' ','','','','',''],
      [' ','','','','',''],
      [' ','','','','',''],
      [' ','','','','','']
    ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(10).layout({
      vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
        }
    }).absolutePosition(20,700).end
  ]).columnGap(5).end

    }

    crearTabla46(data:any){

      return  new Columns([ new Table([
        ['Coordinador de operaciones: ______________________','','','','',''],
        [' ','','','', '','']

      ]).widths([250,'*','*','*','*','*']).alignment('left').fontSize(10).bold().layout('noBorders').absolutePosition(20,660).end,
      new Table([
        [' ','','','','','Analista de inventario: ______________________'],
        [' ','','','','','']
      ]).widths(['*','*','*','*','*',250]).alignment('right').fontSize(10).layout('noBorders').absolutePosition(20,660).end
    ]).columnGap(5).end

      }


    extraerData5(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[row.cod_prod_mp,row.des_prod_mp,
        row.cant_producto, row.tipo_producto_mp,row.unidad_prod_mp]);
    }
    extraerData55(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[ '',
                          row.cant_producto, row.cant_producto,'','']);
    }
    extraerData555(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[
                           '', row.des_producto,
                          '', '','']);
    }

}
