<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: #2277FF; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255);"><mat-icon >layers</mat-icon></span>
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); "><b style="border-bottom: 1px solid rgb(255, 255, 255);">ALMACEN  LOTES DE PRODUCCIÓN</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarInventarioLT("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                    <li *ngIf="create" class="nav-item"><button matTooltip="CREAR LOTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("LTS","crear","")'><mat-icon >add</mat-icon></button></li>
                </ul>
                <div class="">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row" style="height: 78.7%; ">
        <div class="col-md-12 px-0  border h-100" style="background-color: #2277FF; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class="text-center table-striped table-condensed table-hover">
                <ng-container matColumnDef="cod_orden_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Produccion</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cod_orden_producto}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cant_orden">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cant_orden}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cod_lote">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Lote</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cod_lote}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="tipo_lote">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.tipo_lote}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cod_producto}} </td>
                   
                </ng-container>
                <ng-container matColumnDef="um_prod">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>UM</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.um_prod}} </td>
                   
                </ng-container>
                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Descripcion</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.des_producto}} </td>
                   
                </ng-container>
                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cant_producto}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="cant_blts">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Bultos</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cant_blts}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.estatus}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Fecha ini</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.fecha_inicio}} </td>
                  
                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Hora ini</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.hora_inicio}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="fecha_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Fecha fin</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.fecha_fin}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cant_prod_liberado">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Liberado</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cant_prod_liberado}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="hora_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue; font-size: 10px; ">
                        <h5><strong>Hora fin</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.hora_fin}} </td>
                   
                </ng-container>
                <ng-container matColumnDef="ajuste_lote">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Ajustar</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button  class="rounded-pill" *ngIf="create" matTooltip="AJUSTAR LOTE" mat-raised-button (click)='update_insert_data("LTS","ajustar",element)' style="border: outset 3px  rgb(188, 80, 202); height: 35px; color:rgb(255, 255, 255); background-color: rgb(161, 43, 177);">
                            <mat-icon >swap_vertical_circle</mat-icon>
                        </button>
                        <button  class="rounded-pill" *ngIf="!create" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="border: outset 3px  rgb(56, 55, 55); height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >swap_vertical_circle</mat-icon>
                        </button>
                    </td>
                    
                </ng-container>
                <ng-container matColumnDef="um_und">
                    <th mat-header-cell *matHeaderCellDef class="text-center tabla_head_almacen" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>UM/UND</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button  class="rounded-pill" *ngIf="create" matTooltip="LOTE UM/UND" mat-raised-button (click)='update_insert_data("LTS","consolidar",element)' style="border: outset 3px  rgb(67, 91, 202); height: 35px; color:rgb(255, 255, 255); background-color: rgb(43, 68, 177);">
                            <mat-icon >donut_large</mat-icon>
                        </button>
                        <button  class="rounded-pill" *ngIf="!create" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="border: outset 3px  rgb(56, 55, 55); height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >donut_large</mat-icon>
                        </button>
                    </td>
                    
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    </div>