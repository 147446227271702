<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente py-1">

            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li  class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarpedidosF("actualizar")'><mat-icon >sync</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>FACTURAR PEDIDOS</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table">

        <div class="col-md-12 px-0  border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_pedido">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_pedido}} </td>
                </ng-container>
                <ng-container matColumnDef="cod_lista_precio">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Lista precios</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_lista_precio}} </td>
                </ng-container>

                <ng-container matColumnDef="tasa">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Tasa</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tasa}} bs </td>
                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style" style=" font-size: 12px; text-align: center;">
                        <div style="display: flex;justify-content: center; align-items: center;"> {{element.des_cliente}}
                            <mat-icon matTooltip="DETALLES DEL CLIENTE" (click)='buscarClientes(element,"detalle")' style="border: outset 3px #313542; cursor: pointer; border-radius: 1rem; font-size: 14; background-color: #1e212c; display: flex;justify-content: center; align-items: center;"
                                class="mx-3">search</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="des_vendedor">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Vendedor</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_vendedor}} </td>
                </ng-container>

                <ng-container matColumnDef="tipo_pedido">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_pedido}} </td>
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.fecha_inicio}} {{element.hora_inicio}}</td>
                </ng-container>

                <ng-container  matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center header_paginator_table">
                        <h5><strong>Procesos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="col-md-1">
                        <button class=" bg-primary mr-1" mat-mini-fab mat-raised-button (click)="facturar_pedidos(element)" matTooltip="EDITAR">
                            <mat-icon >credit_card</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>