import { Component, OnInit ,AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { FileItem } from '../../../../shared/models/file-item';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { stringify } from 'querystring';
import { PeriodicElement2 } from '../../../../shared/models/interface2';
import { NgDropFilesDirective } from '../../../../shared/directives/ng-drop-files.directive';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
export interface Item { nombre: string; url: string; }

interface Food {
  value: string;
  viewValue: string;
}

interface Car {
  value: string;
  viewValue: string;
}

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-mensajeria',
  templateUrl: './mensajeria.component.html',
  styleUrls: ['./mensajeria.component.css']
})
export class MensajeriaComponent implements OnInit {
cod:any;
formas:FormGroup;
formulario:any[]=[];
obj:any[] = [];
tabla:any []=[];
xobj:any;
file :Array<File>;
ELEMENT_DATA:PeriodicElement2[]=[];
estaSobreElemento = false;
displayedColumns:string[];
dataSource=new MatTableDataSource<PeriodicElement2>();
  archivos: FileItem[] = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  
  private itemsCollection: AngularFirestoreCollection<Item>;
  items: Observable<Item[]>;
  constructor(private servicio:nkServices, private activatedRoute:ActivatedRoute, private snackBar: MatSnackBar, public dialog: MatDialog, private afs: AngularFirestore, private fb:FormBuilder) { 
    this.itemsCollection = afs.collection<Item>('img');
    console.log(this.itemsCollection);
    this.items = this.itemsCollection.valueChanges();         
  }
  selectedValue: string;
  selectedCar: string;

  foods: Food[] = [
    {value: 'HEADER', viewValue: 'HEADER'},
    {value: 'BODY', viewValue: 'BODY'},
    {value: 'MINI', viewValue: 'MINI'}
  ];

  cars: Car[] = [
    {value: '1', viewValue: '1'},
    {value: '2', viewValue: '2'},
    {value: '3', viewValue: '3'}
  ];
  info:any={};
  ngOnInit(): void {
    this.llenartabla(null);
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }
    
    llenartabla(xobj:any[]){
      console.log(xobj);
      
      this.tabla = [];
      
      if (xobj == null) {
        this.archivos = [];
      }
        for (const i in xobj) {
            this.tabla.push(xobj[i]);
            console.log(this.tabla[i]);
        }
        this.displayedColumns = ['Nombre_archivo',
                                 'Tamaño',
                                 'Tipo',
                                 'Nivel',
                                 'Enlace',
                                 'Mensaje',
                                 'Color',
                                 'Colorf',
                                 'Texto',
                                 'Progreso'];
        this.dataSource=new MatTableDataSource<PeriodicElement2>(this.tabla);
        this.dataSource.paginator = this.paginator;
      
    }
    enviarcorreos(form:NgForm){
      console.log(form);
      this.servicio.correohttp(form.value).subscribe(
        response =>{
          if(response){
                this.obj = response;
                
          }
          this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        },
        error =>{
          var err=<any>error.error;
          this.snackBar.open(err.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
      return this.obj;
    }

    subirimg(file:any){
      this.file = <Array<File>>file.target.files;
      console.log(this.file );
    }

/*     guardarimg(xvalor:any){
      console.log(xvalor);
     this.servicio.guardarimghttp(xvalor).subscribe(response =>{
      if(response){
            this.obj = response;
      }
      this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      alert(response.message);
    },
    error =>{
      var err=<any>error.error;
      this.snackBar.open(err[0], 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    });
    } */

cargarImagenes(fomulario:any) {
  console.log(fomulario._directives);
  let tipo = [];
  let nivel = [];
  let enlace=[];
  let texto=[];
  let color=[];
  let colorf=[];
  let colorfondo=[];
  let textob=[];
  for (const i in fomulario._directives) {
        if (fomulario._directives[i].name == 'tipo') {
          tipo.push(fomulario._directives[i].viewModel);
            
        }
        if (fomulario._directives[i].name == 'nivel') {
          nivel.push(fomulario._directives[i].viewModel);
            
        }
        if (fomulario._directives[i].name == 'enlace') {
          enlace.push(fomulario._directives[i].viewModel);
            
        }
        if (fomulario._directives[i].name == 'texto') {
          texto.push(fomulario._directives[i].viewModel);
            
        }
        if (fomulario._directives[i].name == 'color') {
          color.push(fomulario._directives[i].viewModel);
            
        }
        if (fomulario._directives[i].name == 'colorf') {
          colorf.push(fomulario._directives[i].viewModel);
            
        }
        if (fomulario._directives[i].name == 'colorfondo') {
          colorfondo.push(fomulario._directives[i].viewModel);
            
        }
        if (fomulario._directives[i].name == 'textob') {
          textob.push(fomulario._directives[i].viewModel);
            
        }
  }
  console.log(colorfondo,'color de fondo');
  for (const i in this.archivos) {
    this.archivos[i].tipo = tipo[i];
    this.archivos[i].nivel = nivel[i];
    this.archivos[i].enlace = enlace[i];
    this.archivos[i].texto = texto[i];
    this.archivos[i].color = color[i];
    this.archivos[i].colorf = colorf[i];
    this.archivos[i].colorfondo = colorfondo[i];
    this.archivos[i].textob = textob[i];
    
  }
  console.log(this.archivos, 'un intento desesperado');
 
  this.servicio.cargarImagenesFirebase( this.archivos);
}

limpiarArchivos() {
  this.llenartabla(null);
  this.snackBar.open('Bandeja vacia.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
}
codigocolor(event:any){
  
  console.log(event);
  return this.cod;
}
}
