
<div class="" id="modal" *ngIf="accion === 'crear'">
    <div class="row" style="border-radius: 1rem;">
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 1rem;">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                <div class="modal-content h-100" style="border-radius: 1rem;">
                    <div class="modal-header" style="background-color: rgb(55, 177, 55); color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" class=" text-left"><strong> Crear producto materia prima</strong> </h3>
    
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <div class="form-group">
                                                <label for="img">Imagen</label>
                                                <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0">
    
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Nombre del producto</mat-label>
                                                <textarea matInput placeholder="Descripcion del producto" autocomplete="off" formControlName="des_producto"> </textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Tipo producto</mat-label>
                                                <mat-select  formControlName="tipo_producto" autocomplete="off">
                                                    <mat-option *ngFor="let item of medida" [value]="item.concepto_definicion">{{item.descrip_definicion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Medida</mat-label>
                                                <input matInput  id="cant_peso" maxlength="12" class=" text-right" placeholder="0,00" (keyup)="format($event,'cant_peso', 'tasa')" autocomplete="off" formControlName="tamano_producto">
                                            </mat-form-field>
                                        </div>
    
                                    </div>
    
                                    <div class="row py-0">
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Estatus</mat-label>
                                                <mat-select formControlName="estatus_producto" autocomplete="off">
                                                    <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
    
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
    
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Unidad de medida</mat-label>
                                                <mat-select  formControlName="unidad_medida_producto" autocomplete="off">
                                                    <mat-option *ngFor="let item of definicion_2" [value]="item.concepto_definicion">{{item.descrip_definicion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
    
                                    </div>
                                    <div class="row py-0">
    
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
    
                                            <!-- <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Tipo producto</mat-label>
                                                <mat-select name="estatus" placeholder="" formControlName="tipo_producto" autocomplete="off">
                                                    <mat-option value="PT">Producto terminado (PT)</mat-option>
                                                    <mat-option value="PTE">Producto terminado exportación (PTE)</mat-option>
    
                                                </mat-select>
                                            </mat-form-field> -->

                                        </div>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                            <button mat-button class=" btn-primary" (click)="modalrespuesta('cant_peso')" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="" id="modal" *ngIf="accion === 'actualizar'">
    <div class="row" style="border-radius: 1rem;">
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 1rem;">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                <div class="modal-content h-100" style="border-radius: 1rem;">
                    <div class="modal-header" style="background-color: rgb(55, 177, 55); color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" class=" text-left"><strong> Editar producto materia prima</strong> </h3>

                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <div class="form-group">
                                                <label for="img">Imagen</label>
                                                <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="row py-0">
                                        <div class="col-md-6 col-sm-6 col-xs-6 py-0">

                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Codigo del producto</mat-label>
                                                <input matInput placeholder="Codigo del producto" autocomplete="off" formControlName="cod_producto" [readonly]="accion === 'actualizar'">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0">

                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Descripcion del producto</mat-label>
                                                <textarea matInput placeholder="Descripcion del producto" autocomplete="off" formControlName="des_producto"> </textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-6 col-sm-6 col-xs-6 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Unidad de medida</mat-label>
                                                <mat-select #u_med (selectionChange)="unidad_manejo(u_med)"  formControlName="unidad_medida_producto" autocomplete="off">
                                                    <mat-option *ngFor="let item of definicion_2" [value]="item.concepto_definicion">{{item.descrip_definicion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Medida</mat-label>
                                                <input matInput id="cant_peso" maxlength="12" class=" text-right" placeholder="0,00" (keyup)="format($event,'cant_peso', 'tasa')" autocomplete="off" formControlName="tamano_producto">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row py-0">
                                        <div class="col-md-6 col-sm-6 col-xs-6 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Estatus</mat-label>
                                                <mat-select formControlName="estatus_producto" autocomplete="off">
                                                    <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                            <button mat-button class=" btn-primary" (click)="modalrespuesta('cant_peso')" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>