<div class="body4 fadeIn2 animated fast bg-dark" style="z-index: -1;">
    <mat-drawer-container autosize>
        <mat-drawer #drawer class="sidebar" opened="true" mode="side">
            <a #link *ngFor="let i of menu3" class="text-center fw-bold " style="font-size: 11px;" (click)="cambiarrouter('entrar')" [routerLink]="[{ outlets: {'config': [i.dir_menu] } }]">
               {{i.des_menu | uppercase}}
            </a>
        </mat-drawer>
        <button class=" bg-success py-0"   matTooltip="MENU" mat-mini-fab (click)="drawer.toggle()" style="color: #fff; background-color: transparent; cursor: pointer; position: absolute;z-index: 1000; bottom: 15vh; margin-left: 5px;">
            <mat-icon >menu</mat-icon>
        </button>
        <div class="content" style="margin-left: 0;" *ngIf="ruta =='entrar'">
            <router-outlet name="config"></router-outlet>
        </div>
    </mat-drawer-container>
</div>