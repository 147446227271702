<div id="modal_carrito" style=" background-image: linear-gradient(to left top, #bdf9cb, #9ae5aa, #78d089, #54bc67, hsl(133, 55%, 65%));;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container" >

        <form  id="carrito_p1" class="row justify-content-center mt-1 mb-0" style=" height: auto; background-color: #f1ede9; border: 2px solid #28a745;">
            <!-- <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#ca4aaf;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Cliente</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_cliente" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div> -->

                <div id="content_2" class=" mx-1 mat-elevation-z8 my-2 col-2  " style="border-radius: 1rem; background-color: #28a745">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Codigo Orden de Requisición</mat-label>
                         <input style="font-size: 12px; color: #fff;" matInput placeholder="Cliente:"  [value]="data.cod_orden_req" autocomplete="off"  readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field> 
                </div>

            
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-2" style="border-radius: 1rem; background-color: #28a745">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Chequeado por:</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput  #num autocomplete="off" readonly>
                        
                     </mat-form-field>
                </div>


            <!-- <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#1f39ce">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Unidad de manejo</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="unidad_manejo" #unidad_manejo autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(255, 94, 0);" >report_problem</mat-icon>
                </span>
                    </mat-form-field>
                </div>
            </div> -->


                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-4" style="border-radius: 1rem; background-color: #28a745">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255); font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" (keyup)="applyFilter($event)" autocomplete="off"  > 
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>


                <div style="padding-top: 6px;" >

      
      
                    <button class="btn btn-sm btn-danger mat-elevation-z8 px-3 mt-1 mr-1" style="border-radius: 1rem; height: 80%;" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>

                </div>


        </form>  

        <div id="carrito_p6" class="row mt-2 mat-elevation-z8" style=" border: 2px solid #28a745; background-color: #f1ede9; ">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                <mat-horizontal-stepper [linear]="isLinear" #stepper>

                  <!-- FORMULARIO 1 -->
                    <mat-step *ngIf="!ver" >
                        <ng-template matStepLabel>Resumen</ng-template>
                        <div class="container mt-2">
                          <div class="row " style=" height: 90%;">
                           

                            <div class="col-12">
                              <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style=" background-color: #f1ede9;height: 93% !important; ">
                                <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                                    <table #table mat-table [dataSource]="dataSourceResumen" class=" text-center table-striped  table-condensed table-hover">
                      
                                        <ng-container matColumnDef="cod_producto">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28a745;">
                                                <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #28a745; color: #fff;"> </td>
                                          </ng-container>
                        
                                          <ng-container matColumnDef="des_producto">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28a745;">
                                                <h5 style="margin: 0;"><strong>Descripcion</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #28a745; color: #fff;"> </td>
                                          </ng-container>
                        
                                          <ng-container matColumnDef="cant_aprobada">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28a745;">
                                                <h5 style="margin: 0;"><strong>Cantidad Solicitada</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_solicitada | puntoporcoma}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #28a745; color: #fff;"> </td>
                                          </ng-container>
                                          
                                          <ng-container matColumnDef="cant_producto_cot">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28a745;">
                                                <h5 style="margin: 0;"><strong>Cantidad Cotizada</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong><span style="color: #00c6a0;">1-</span> {{element.cant_producto_cot[0] | puntoporcoma}} <br>
                                                                                                                                                      <span style="color: #00afc9;" *ngIf="CantidadCot == '3' && !ver">2-</span> {{element.cant_producto_cot[1] | puntoporcoma}}<br>
                                                                                                                                                      <span style="color: #008AD0;" *ngIf="CantidadCot == '3' && !ver">3-</span> {{element.cant_producto_cot[2] | puntoporcoma}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #28a745; color: #fff;"> </td>
                                          </ng-container>
                        
                        
                                          <ng-container matColumnDef="tipo_unidad_medida">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-image: linear-gradient(to right, #28a745, #28a745,#28a745,#28a745, #28a745,#28a745,#28a745,#00c6a0);">
                                                <h5 style="margin: 0;"><strong>Unidad de medida</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style=" background-image: linear-gradient(to right, #28a745, #28a745,#28a745,#28a745, #28a745,#28a745,#28a745,#00c6a0); color: #fff;"> </td>
                                          </ng-container>

                                          <ng-container matColumnDef="precio_cot_1">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-image: linear-gradient(to right,  #00c6a0,#00c6a0,#00c6a0, #00c6a0,#00c6a0,#00c6a0,#00afc9);">
                                                <h5 style="margin: 0;"><strong>Precio Propuesta 1</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_cot_1}}</strong> {{element.moneda_1}} </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-image: linear-gradient(to right, #00c6a0,#00c6a0,#00c6a0, #00c6a0,#00c6a0,#00c6a0,#00afc9); color: #fff;"> 
                                              <div>{{dolar1 | puntoporcoma}} $</div> 
                                              <div class="mt-2">{{bolivar1}} Bs</div> 
                                             </td>
                                          </ng-container>
                                          <ng-container matColumnDef="precio_cot_2">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-image: linear-gradient(to right,  #00afc9,#00afc9,#00afc9, #00afc9,#00afc9,#00afc9, #008AD0);">
                                                <h5 style="margin: 0;"><strong>Precio Propuesta 2</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_cot_2}}</strong> {{element.moneda_2}} </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-image: linear-gradient(to right,  #00afc9,#00afc9,#00afc9, #00afc9,#00afc9,#00afc9, #008AD0);; color: #fff;"> 
                                              <div>{{dolar2 | puntoporcoma}} $</div> 
                                              <div class="mt-2">{{bolivar2}} Bs</div> 
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="precio_cot_3">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #008AD0;">
                                                <h5 style="margin: 0;"><strong>Precio Propuesta 3</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_cot_3}}</strong> {{element.moneda_3}} </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> 
                                              <div>{{dolar3 | puntoporcoma}} $</div> 
                                              <div class="mt-2">{{bolivar3}} Bs</div> 
                                             </td>
                                          </ng-container>
    
                    
                    
                                        <ng-container matColumnDef="tomar_data_ingresada">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #28a745; padding: 5px; color: #fff;">
                                                <h5 style="margin: 0;"><strong>Seleccionar</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                                <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, '1')" [id]="element.cod_producto+'-1'" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                                    <mat-icon>add_circle</mat-icon>
                                                </button>
                                                <button matTooltip="BORRAR" (click)="tomar_data(false, element, '1')" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                                    <mat-icon>done</mat-icon>
                                                </button>
                    
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #28a745; color: #fff;"></td>
                                        </ng-container>
                      
                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsResumen" class="mat-paginator-sticky"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsResumen;"></tr>
                                      <tr mat-footer-row *matFooterRowDef="displayedColumnsResumen" class="mat-paginator-sticky"></tr>
                                      
                                    </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <button class="btn btn-primary rounded-pill ml-3" matTooltip="Siguiente" mat-button matStepperNext> <mat-icon>keyboard_arrow_right</mat-icon></button>  
                          </div> 
                        </div>
                    </mat-step>
                  <!-- FORMULARIO 1 -->
                  <!-- FORMULARIO 2 -->
                    <mat-step *ngIf="!ver" [stepControl]="firstFormGroup" >
                      <form [formGroup]="firstFormGroup">
                        <ng-template matStepLabel>Primera Propuesta</ng-template>
                        <div class="container mt-2">
                          <div class="row " style=" height: 90%;">
                            <div class="col-4">

                              <mat-form-field class="col-12">
                                <mat-label>R.I.F.</mat-label>
                                <input matInput  placeholder="J00000000000" readonly formControlName="rif" required>
                              </mat-form-field>  

                              <mat-form-field class="col-12">
                                <mat-label>Empresa</mat-label>
                                <input matInput  placeholder="Factory Cosmetics 3DG, C.A." readonly formControlName="nombre" required>
                              </mat-form-field>

                              <div class="col-12 mt-3">
                                <textarea formControlName="observacion" name="" id="" class="w-100 mat-elevation-z8 form-control"   cols="30" rows="10" placeholder="Observacion(Requerido)" readonly style="resize: none; border-radius: 1rem; padding: .5rem; border: 2px solid #00CFA9;" required ></textarea>
                              </div>

                            </div>

                            <div class="col-8">
                              <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style="  background-color: #f1ede9;height: 90% !important; ">
                                <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                                    <table #table mat-table [dataSource]="dataSource1" class=" text-center table-striped  table-condensed table-hover">

                        
                                          <ng-container matColumnDef="cod_producto">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                                <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                          </ng-container>
                        
                                          <ng-container matColumnDef="des_producto">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                                <h5 style="margin: 0;"><strong>Descripcion</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                          </ng-container>
                        
                                          <ng-container matColumnDef="cant_aprobada">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                                <h5 style="margin: 0;"><strong>Cantidad Solicitada</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_solicitada| puntoporcoma}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                          </ng-container>

                                          <ng-container matColumnDef="cant_producto_cot">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                                <h5 style="margin: 0;"><strong>Cantidad Cotizada</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                          </ng-container>
                        
                        
                                          <ng-container matColumnDef="tipo_unidad_medida">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                                <h5 style="margin: 0;"><strong>Unidad de medida</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                          </ng-container>

                                          <ng-container matColumnDef="precio">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                                <h5 style="margin: 0;"><strong>Precio Unitario</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio | puntoporcoma}}</strong> {{element.moneda}} </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;">Total </td>
                                          </ng-container>
    
                                          <ng-container matColumnDef="precio_total">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                                <h5 style="margin: 0;"><strong>Precio total</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total | puntoporcoma}}</strong> {{element.moneda}} </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> 
                                              <div>{{dolar1 | puntoporcoma}} $</div> 
                                              <div class="mt-2">{{bolivar1}} Bs</div> 
                                             </td>
                                          </ng-container>
                    
                    
                                        <ng-container matColumnDef="tomar_data_ingresada">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #00CFA9; padding: 5px; color: #fff;">
                                                <h5 style="margin: 0;"><strong>Seleccionar</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                                <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, '1')" [id]="element.cod_producto+'-1'" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                                    <mat-icon>add_circle</mat-icon>
                                                </button>
                                                <button matTooltip="BORRAR" (click)="tomar_data(false, element, '1')" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                                    <mat-icon>done</mat-icon>
                                                </button>
                    
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"></td>
                                        </ng-container>
                      
                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                      <tr mat-footer-row *matFooterRowDef="displayedColumns" class="mat-paginator-sticky"></tr>
                                      
                                    </table>
                    
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <button class="btn btn-primary rounded-pill mr-3" matTooltip="Regresar" mat-button matStepperPrevious> <mat-icon>keyboard_arrow_left</mat-icon></button>
                            <button class="btn btn-primary rounded-pill ml-3" matTooltip="Siguiente" mat-button matStepperNext> <mat-icon>keyboard_arrow_right</mat-icon></button> 
                          </div> 
                        </div>
                      </form>
                    </mat-step>
                  <!-- FORMULARIO 2 -->

                    <!-- FORMULARIO 3 -->
                    <mat-step *ngIf="CantidadCot == '3' && !ver" [stepControl]="secondFormGroup" >
                      <ng-template matStepLabel>Segunda Propuesta</ng-template>
                      <form [formGroup]="secondFormGroup" class="form-group">
                        <div class="container mt-2">
                          <div class="row " style=" height: 90%;">
                            <div class="col-4 align-self-start">

                              <mat-form-field class="col-12">
                                <mat-label>R.I.F.</mat-label>
                                <input matInput  placeholder="J00000000000" readonly formControlName="rif" required>
                              </mat-form-field>  

                              <mat-form-field class="col-12">
                                <mat-label>Empresa</mat-label>
                                <input matInput  placeholder="Factory Cosmetics 3DG, C.A." readonly formControlName="nombre" required>
                              </mat-form-field>

                              <div class="col-12 mt-3">
                                <textarea formControlName="observacion" name="" id="" class="w-100 mat-elevation-z8 form-control"   cols="30" rows="10" placeholder="Observacion(Requerido)" readonly style="resize: none; border-radius: 1rem; padding: .5rem; border: 2px solid #00AFC9;" required ></textarea>
                              </div>
                              

                            </div>

                            <div class="col-8">
                              <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style="  background-color: #f1ede9;height: 90% !important; ">
                                <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                                    <table #table mat-table [dataSource]="dataSource2" class=" text-center table-striped  table-condensed table-hover">
                      
                                      <ng-container matColumnDef="cod_producto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00AFC9;">
                                            <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>
                                      </ng-container>
                    
                                      <ng-container matColumnDef="des_producto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00AFC9;">
                                            <h5 style="margin: 0;"><strong>Descripcion</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>
                                      </ng-container>
                    
                                      <ng-container matColumnDef="cant_aprobada">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00AFC9;">
                                            <h5 style="margin: 0;"><strong>Cantidad Solicitada</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_solicitada| puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>
                                      </ng-container>

                                      <ng-container matColumnDef="cant_producto_cot">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00afc9;">
                                            <h5 style="margin: 0;"><strong>Cantidad Cotizada</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot| puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #00afc9; color: #fff;"> </td>
                                      </ng-container>
                    
                    
                                      <ng-container matColumnDef="tipo_unidad_medida">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00AFC9;">
                                            <h5 style="margin: 0;"><strong>Unidad de medida</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>
                                      </ng-container>

                                      <ng-container matColumnDef="precio">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00AFC9;">
                                            <h5 style="margin: 0;"><strong>Precio Unitario</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio| puntoporcoma}}</strong> {{element.moneda}}</td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;">Total </td>
                                      </ng-container>

                                      <ng-container matColumnDef="precio_total">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00AFC9;">
                                            <h5 style="margin: 0;"><strong>Precio total</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total | puntoporcoma}}</strong> {{element.moneda}}</td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> 
                                          <div>{{dolar2 | puntoporcoma}} $</div> 
                                          <div class="mt-2">{{bolivar2}} Bs</div> 
                                         </td>
                                      </ng-container>
                
                
                                    <ng-container matColumnDef="tomar_data_ingresada">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #00AFC9; padding: 5px; color: #fff;">
                                            <h5 style="margin: 0;"><strong>Seleccionar</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                            <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, '2')" [id]="element.cod_producto+'-2'" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                                <mat-icon>add_circle</mat-icon>
                                            </button>
                                            <button matTooltip="BORRAR" (click)="tomar_data(false, element, '2')" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                                <mat-icon>done</mat-icon>
                                            </button>
                
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>
                                    </ng-container>
                      
                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        <tr mat-footer-row *matFooterRowDef="displayedColumns" class="mat-paginator-sticky"></tr>
                                    </table>
                    
                                </div>
                              </div>
                            </div>
                          </div> 
                          <div class="row justify-content-center">
                            <button class="btn btn-primary rounded-pill mr-3" matTooltip="Regresar" mat-button matStepperPrevious> <mat-icon>keyboard_arrow_left</mat-icon></button>
                            <button class="btn btn-primary rounded-pill" matTooltip="Siguiente" mat-button matStepperNext> <mat-icon>keyboard_arrow_right</mat-icon></button> 
                          </div>
                        </div>
                      </form>
                      
                    </mat-step>
                    <!-- FORMULARIO 3 -->

                    <!-- FORMULARIO 4 -->
                    <mat-step *ngIf="CantidadCot == '3' && !ver" [stepControl]="thirdFormGroup" >
                      <ng-template matStepLabel>Tercera Propuesta</ng-template>
                      <form [formGroup]="thirdFormGroup">
                        <div class="container mt-2">
                          <div class="row " style=" height: 90%;">
                            <div class="col-4 ">

                              <mat-form-field class="col-12">
                                <mat-label>R.I.F.</mat-label>
                                <input matInput  placeholder="J00000000000" readonly formControlName="rif" required>
                              </mat-form-field>  

                              <mat-form-field class="col-12">
                                <mat-label>Empresa</mat-label>
                                <input matInput  placeholder="Factory Cosmetics 3DG, C.A." readonly formControlName="nombre" required>
                              </mat-form-field>

                              <div class="col-12 mt-3">
                                <textarea formControlName="observacion" name="" id="" class="w-100 mat-elevation-z8 form-control"   cols="30" rows="10" placeholder="Observacion(Requerido)" readonly style="resize: none; border-radius: 1rem; padding: .5rem; border: 2px solid #008AD0;" required ></textarea>
                              </div>
 

                            </div>

                            <div class="col-8">
                              <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style="  background-color: #f1ede9; height: 90% !important; ">
                                <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                                    <table #table mat-table [dataSource]="dataSource3" class=" text-center table-striped  table-condensed table-hover">
                      
                                      <ng-container matColumnDef="cod_producto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #008AD0;">
                                            <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                      </ng-container>
                    
                                      <ng-container matColumnDef="des_producto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #008AD0;">
                                            <h5 style="margin: 0;"><strong>Descripcion</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                      </ng-container>
                    
                                      <ng-container matColumnDef="cant_aprobada">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #008AD0;">
                                            <h5 style="margin: 0;"><strong>Cantidad Solicitada</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_solicitada | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                      </ng-container>

                                      <ng-container matColumnDef="cant_producto_cot">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #008AD0;">
                                            <h5 style="margin: 0;"><strong>Cantidad Cotizada</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                      </ng-container>
                    
                    
                                      <ng-container matColumnDef="tipo_unidad_medida">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #008AD0;">
                                            <h5 style="margin: 0;"><strong>Unidad de medida</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                      </ng-container>

                                      <ng-container matColumnDef="precio">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #008AD0;">
                                            <h5 style="margin: 0;"><strong>Precio Unitario</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio | puntoporcoma }}</strong> {{element.moneda}}</td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;">Total </td>
                                      </ng-container>

                                      <ng-container matColumnDef="precio_total">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #008AD0;">
                                            <h5 style="margin: 0;"><strong>Precio total</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total| puntoporcoma}}</strong> {{element.moneda}}</td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> 
                                          <div>{{dolar3 | puntoporcoma}} $</div> 
                                          <div class="mt-2">{{bolivar3}} Bs</div> 
                                         </td>
                                      </ng-container>
                
                
                                    <ng-container matColumnDef="tomar_data_ingresada">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #008AD0; padding: 5px; color: #fff;">
                                            <h5 style="margin: 0;"><strong>Seleccionar</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                            <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, '3')"  [id]="element.cod_producto+'-3'" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                                <mat-icon>add_circle</mat-icon>
                                            </button>
                                            <button matTooltip="BORRAR" (click)="tomar_data(false, element, '3')" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                                <mat-icon>done</mat-icon>
                                            </button>
                
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;" > </td>
                                    </ng-container>
                      
                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                      <tr mat-footer-row *matFooterRowDef="displayedColumns" class="mat-paginator-sticky"></tr>
                                    </table>
                    
                                </div>
                              </div>
                            </div>
                          </div> 
                          <div class="row justify-content-center">
                            <button class="btn btn-primary rounded-pill mr-3" matTooltip="Regresar" mat-button matStepperPrevious> <mat-icon>keyboard_arrow_left</mat-icon></button>
                            <button class="btn btn-primary rounded-pill" matTooltip="Siguiente" mat-button matStepperNext> <mat-icon>keyboard_arrow_right</mat-icon></button> 
                          </div>
                        </div>
                      </form>
                    </mat-step>
                    <!-- FORMULARIO 4 -->

                    <mat-step>
                      <ng-template matStepLabel>LISTO!</ng-template>
                      
                      <div class="container mt-2">
                        <div class="row " style=" height: 90%;">
                          
                          <div class="col-12">
                            <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style=" background-color: #f1ede9;height: 93% !important; ">
                              <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                                  <table #table mat-table [dataSource]="dataSourceFinal1" class=" text-center table-striped  table-condensed table-hover">
                                         
                                    <ng-container matColumnDef="cod_proveedor">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>RIF</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_proveedor}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                    </ng-container>
                  
                                    <ng-container matColumnDef="des_proveedor">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>Empresa</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_proveedor}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cod_producto">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                    </ng-container>
                  
                                    <ng-container matColumnDef="des_producto">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>Descripcion</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                    </ng-container>
                  
                                    <ng-container matColumnDef="cant_aprobada">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>Cantidad Solicitada</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_solicitada | puntoporcoma}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cant_producto_cot">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>Cantidad Cotizada</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                    </ng-container>
                  
                  
                                    <ng-container matColumnDef="tipo_unidad_medida">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>Unidad de medida</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="precio">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>Precio Unitario</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio | puntoporcoma}}</strong> {{element.moneda}}</td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;">Total </td>
                                    </ng-container>

                                    <ng-container matColumnDef="precio_total">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #00CFA9;">
                                          <h5 style="margin: 0;"><strong>Precio total</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total | puntoporcoma}}</strong> {{element.moneda}}</td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> 
                                        <div>{{dolar1Final}} $</div> 
                                        <div class="mt-2">{{bolivar1Final}} Bs</div> 
                                       </td>
                                    </ng-container>
              
              
                                  <ng-container matColumnDef="tomar_data_ingresada">
                                      <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #00CFA9; padding: 5px; color: #fff;">
                                          <h5 style="margin: 0;"><strong>Seleccionar</strong></h5>
                                      </th>
                                      <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                          <button matTooltip="BORRAR" (click)="deleteCarrito(element, '1')" class="btn btn-danger rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                              <mat-icon>delete_forever</mat-icon>
                                          </button>
              
                                      </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00CFA9; color: #fff;"> </td>
                                  </ng-container>
                    
                    
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsFinal"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsFinal;"></tr>
                                      <tr mat-footer-row *matFooterRowDef="displayedColumnsFinal" class="mat-paginator-sticky"></tr>
                                  </table>

                                  <table *ngIf="CantidadCot == '3'" #table mat-table [dataSource]="dataSourceFinal2" class=" text-center table-striped  table-condensed table-hover">
                                    
                                                          
                                    <ng-container matColumnDef="cod_proveedor">
                                     
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_proveedor}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>

                                    </ng-container>
                  
                                    <ng-container matColumnDef="des_proveedor">
                                      
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_proveedor}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>

                                    </ng-container>

                                    <ng-container matColumnDef="cod_producto">
                                      
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>

                                    </ng-container>
                  
                                    <ng-container matColumnDef="des_producto">
                                      
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>

                                    </ng-container>
                  
                                    <ng-container matColumnDef="cant_aprobada">
                                      
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_solicitada | puntoporcoma}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>

                                    </ng-container>

                                    <ng-container matColumnDef="cant_producto_cot">

                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00afc9; color: #fff;"> </td>
                                    </ng-container>
                  
                  
                                    <ng-container matColumnDef="tipo_unidad_medida">
                                      
                                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>

                                    </ng-container>

                                    <ng-container matColumnDef="precio">
                                      
                                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio | puntoporcoma }}</strong> {{element.moneda}}</td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;">Total </td>

                                    </ng-container>

                                    <ng-container matColumnDef="precio_total">
                                      
                                      <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total | puntoporcoma}}</strong> {{element.moneda}}</td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> 
                                        <div>{{dolar2Final | puntoporcoma}} $</div> 
                                        <div class="mt-2">{{bolivar2Final}} Bs</div> 
                                       </td>

                                    </ng-container>
              
              
                                  <ng-container matColumnDef="tomar_data_ingresada">
                                      
                                      <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">

                                          <button matTooltip="BORRAR" (click)="deleteCarrito(element, '2')" class="btn btn-danger rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                              <mat-icon>delete_forever</mat-icon>
                                          </button>
              
                                      </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #00AFC9; color: #fff;"> </td>

                                  </ng-container>
                    
                    
                                      
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsFinal;"></tr>
                                      <tr mat-footer-row *matFooterRowDef="displayedColumnsFinal" class="mat-paginator-sticky"></tr>
                                  </table>

                                  <table *ngIf="CantidadCot == '3'" #table mat-table [dataSource]="dataSourceFinal3" class=" text-center table-striped  table-condensed table-hover">
                                    
                                                          
                                    <ng-container matColumnDef="cod_proveedor">
                                      
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_proveedor}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                    </ng-container>
                  
                                    <ng-container matColumnDef="des_proveedor">
                                     
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_proveedor}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cod_producto">
                                     
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                    </ng-container>
                  
                                    <ng-container matColumnDef="des_producto">
                    
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                    </ng-container>
                  
                                    <ng-container matColumnDef="cant_aprobada">
                                      
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_solicitada | puntoporcoma }}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="cant_producto_cot">
                                      
                                      <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                    </ng-container>
                  
                  
                                    <ng-container matColumnDef="tipo_unidad_medida">
                                      
                                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="precio">
                                     
                                      <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio | puntoporcoma}}</strong> {{element.moneda}}</td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;">Total </td>
                                    </ng-container>

                                    <ng-container matColumnDef="precio_total">
                                      
                                      <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total | puntoporcoma}}</strong> {{element.moneda}}</td>
                                      <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> 
                                        <div>{{dolar3Final}} $</div> 
                                        <div class="mt-2">{{bolivar3Final}} Bs</div> 
                                       </td>
                                    </ng-container>
              
              
                                    <ng-container matColumnDef="tomar_data_ingresada">
                                        
                                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                            <button matTooltip="BORRAR" (click)="deleteCarrito(element, '3')" class="btn btn-danger rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                                <mat-icon>delete_forever</mat-icon>
                                            </button>
                
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #008AD0; color: #fff;"> </td>
                                    </ng-container>
                    
                    
                                    
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsFinal;"></tr>
                                      <tr mat-footer-row *matFooterRowDef="displayedColumnsFinal" class="mat-paginator-sticky"></tr>
                                  </table>
                  
                              </div>
                            </div>
                          </div>
                        </div> 
                        <div *ngIf="!ver" class="row justify-content-center">
                          <button class="btn btn-primary rounded-pill mr-3" matTooltip="Regresar" mat-button matStepperPrevious> <mat-icon>keyboard_arrow_left</mat-icon></button>
                          <button class="btn btn-danger rounded-pill ml-3" matTooltip="Siguiente" mat-button (click)="desaprobarTodaLaCotizacion()"> ❌ Desaprobar Todo</button>
                          <button class="btn btn-success rounded-pill" 
                                  mat-button 
                                  [disabled]="firstFormGroup.invalid &&
                                              secondFormGroup.invalid &&
                                              thirdFormGroup.invalid"
                                  (click)="enviarCotizacion()"
                                  matTooltip="Aprobar"
                          > <mat-icon>done_all</mat-icon></button> 
                        </div>
                      </div>
                      
                    </mat-step>
                    <!-- FORMULARIO 4 -->
                    
                  <!--   <ng-template matStepperIcon="phone">
                      <mat-icon>call_end</mat-icon>
                    </ng-template>
                    <ng-template matStepperIcon="chat">
                      <mat-icon>forum</mat-icon>
                    </ng-template> -->
                  </mat-horizontal-stepper>
            </div>

        </div>
        
    </div>
</div>
