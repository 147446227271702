import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormMenuComponent } from 'src/app/componentes/shared/modal/form-menu/form-menu.component';
import { FormSmtpComponent } from 'src/app/componentes/shared/modal/form-smtp/form-smtp.component';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { nkServices } from 'src/app/componentes/shared/servicios/app.nkservicios.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Component({
  selector: 'app-smtp-usu',
  templateUrl: './smtp-usu.component.html',
  styleUrls: ['./smtp-usu.component.css']
})
export class SmtpUsuComponent implements OnInit , AfterViewInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
 
  constructor(
              private servicio:nkServices,
              private dialog: MatDialog, 
              private util:AppUtilService,
              private atrb:AtributosService,
              private hashkey:HashKeyServices,
              private notifi:ToastNotificationService,
    ) {}
  ngOnInit(){
    this.listarSmtp(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

async listarSmtp(msj:any){
          try {
                await this.util.abrirSpinner();
                await this.servicio.consultar_sesionhttp(null).toPromise();
              let msg = msj;
              let xvalor={
                api: await this.hashkey.encrypt(await this.atrb.API_REST.listarSmtp),
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa,
                cod_rol: await this.atrb.ROL_USUARIOS.cod_rol
              }
              const response = await this.servicio.ApiResthttp(xvalor).toPromise();
                    this.ELEMENT_DATA = [];
                        for (const i in response) {
                          response[i].pass_smtp = await this.hashkey.decrypt(response[i].pass_smtp),
                          this.ELEMENT_DATA.push({
                            cod_smtp:response[i].cod_smtp,  
                            des_smtp: response[i].des_smtp, 
                            dir_smtp:response[i].dir_smtp, 
                            estatus:response[i].estatus,
                            host_smtp:response[i].host_smtp,
                            user_smtp:response[i].user_smtp,
                            pass_smtp:response[i].pass_smtp,
                            ssl_smtp:response[i].ssl_smtp,
                            port_smtp:response[i].port_smtp,
                            cod_empresa:response[i].cod_empresa
                          });
                        }
                        this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_SMTP;
                        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                        this.dataSource.paginator = this.paginator;
                        if(msg == await this.atrb.ACCION_ACTUALIZAR){
                          await this.util.cerrarSpinner();
                          await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                      }else{
                        await this.util.cerrarSpinner();
                      }
          } catch (error) {
            console.log(error);
            this.ELEMENT_DATA = [];
            this.displayedColumns = await this.atrb.TABLA_PRINCIPAL.TABLA_SMTP;
            this.dataSource = new MatTableDataSource<any>();
            this.dataSource.paginator = this.paginator;
            await this.util.cerrarSpinner();
              await this.notifi.customToastNotification(error.error.message);
          }
        }
async update_insert_data(obj:any,text:any){
          try {
            await this.util.abrirSpinner();
            await this.servicio.consultar_sesionhttp(null).toPromise();
                                var dialogRef:any;
                                this.accion = text;
                                if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                                  if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                                    let val_def;
                                        val_def = {
                                          val_def:await this.atrb.ACCESO_VALDEF.gestionar_smtp,
                                          tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                          cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                          api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                        }
                                    await this.servicio.ApiResthttp(val_def).toPromise();
                              }
                                  await this.util.cerrarSpinner();
                                  dialogRef = this.dialog.open(FormSmtpComponent, {
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    height:'auto',
                                    data: {
                                      data:obj, 
                                      operacion: await this.atrb.ACCION_ACTUALIZAR,
                                      accion:this.accion
                                    },
                                    disableClose: true,
                                    panelClass: 'pedidos_modal_perfiles',
                                    backdropClass: "bdrop"
                                  });
                                }else{
                                  if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                                    let val_def;
                                        val_def = {
                                          val_def:await this.atrb.ACCESO_VALDEF.gestionar_smtp,
                                          tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                          cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                          api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                        }
                                    await this.servicio.ApiResthttp(val_def).toPromise();
                                    let objeto = {
                                      cod_empresa: this.atrb.COD_EMPRESA.cod_empresa,
                                      mod_usuario: this.atrb.MOD_USUARIO.mod_usuario,
                                      des_parametro: await this.atrb.OPERACIONES.smtp,
                                      api: await this.hashkey.encrypt(await this.atrb.API_REST.validarLicenciaProcesos)
                                    };
                                    await this.servicio.ApiResthttp(objeto).toPromise();
                              }
                                  await this.util.cerrarSpinner();
                                  dialogRef = this.dialog.open(FormSmtpComponent, {
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    height:'auto',
                                    data: {
                                            operacion: await this.atrb.ACCION_CREAR, 
                                            accion:this.accion},
                                    disableClose: true,
                                    panelClass: 'pedidos_modal_perfiles',
                                    backdropClass: "bdrop"
                                  });
                                }
                                  dialogRef.afterClosed().subscribe(async result => {
                                    await this.util.abrirSpinner()
                                    if(result !== await this.atrb.CERRAR){
                                      this.gestionSmtp(result);
                                    }else{
                                      await this.util.cerrarSpinner();
                                    }
                                  });
          } catch (error) {
              await this.util.cerrarSpinner();
              await this.notifi.customToastNotification(error.error.message);
          }
        }
async  gestionSmtp(xvalor:any){
          try {
            xvalor.api = await this.hashkey.encrypt(await this.atrb.API_REST.gestionSmtp);
          const response = await this.servicio.ApiResthttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
              await this.listarSmtp(null);
                  await this.notifi.success(response.message);
          } catch (error) {
            await this.util.cerrarSpinner();
            await this.notifi.customToastNotification(error.error.message);
            await this.listarSmtp(null);
          }
        }
      applyFilter(event: Event) {
          const filterValue = (event.target as HTMLInputElement).value;
          this.dataSource.filter = filterValue.trim().toLowerCase();
        }
}
