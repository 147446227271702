import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { PdfMakeWrapper, Table, Columns, Img} from 'pdfmake-wrapper';
import { ITable} from 'pdfmake-wrapper/lib/interfaces';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormEntradaMpComponent } from 'src/app/componentes/shared/modal/form-entrada-mp/form-entrada-mp.component';
interface interfacefactura{
  cod_lote: string;
  cant_producto:string;
  cod_producto:string;
  des_producto:string;
  cant_req:string;
  restante:string;
}
interface interdatosfacefactura{
cod_guia_despacho:string;
cod_factura:string;
cod_pedido:string;
fecha_inicio:string;
}
type TableRow = [string, string, string, string, string];

@Injectable({
  providedIn: 'root'
})


@Component({
  selector: 'app-materia-prima',
  templateUrl: './materia-prima.component.html',
  styleUrls: ['./materia-prima.component.css']
})
export class MateriaPrimaComponent implements OnInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  cod_orden_producto:any;
  cantidad_prod:any;
  cod_prod:any;
  des_prod:any;
  dialogSpin:any;
  ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

  }

  ngOnInit(): void {
    this.listarordenesprod(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }

  /////////////////////////////////////////////////////////////////////
  listarordenesprod(msj:any){
    let msg = msj;
    var valor = {termino:'P'}
    this.servicio.listarOrdenesProdhttp(valor).subscribe(
      response =>{
        ////////////////UNIDADES//////////////////////
        this.xobj3 = [];
        for (const i in response[0]) {
          this.xobj3.push(response[0][i]);
        }
        console.log(this.xobj3);
        if(response){
          console.log(response,'una respuesta');
          this.xobj2 = response;
          this.cod_orden_producto = response[0].cod_orden_producto;
          this.listarProductoOrdenesProd(response[0].cod_orden_producto);
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                  cod_orden_producto:response[i].cod_orden_producto,
                  cod_usuario:response[i].cod_usuario,
                  des_usuario: response[i].des_usuario,
                  estatus:response[i].estatus,
                  fecha_inicio:response[i].fecha_inicio,
                  hora_inicio:response[i].hora_inicio,
                  cod_usuario_cierre: response[i].cod_usuario_cierre,
                  des_usuario_cierre:response[i].des_usuario_cierre,
                  fecha_fin:response[i].fecha_fin,
                  hora_fin:response[i].hora_fin,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Imprimir',
                                        'Actualizar',
                                        'confirmar'
                                      ];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log('una respuesta');
        console.log(<any>error.error.message);
        this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Imprimir',
                                        'Actualizar',
                                        'confirmar'
                                      ];
        this.dataSource = new MatTableDataSource<any>();
              this.dataSource.paginator = this.paginator;
      }
    );
    console.log(msg);
    if(msg == 'actualizar'){
      this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    return this.xobj2;
  }

  listarProductoOrdenesProd(xvalor:any){
    var valor = {cod_orden_producto:xvalor}
   this.servicio.buscarProductoOrdenesProdhttp(valor).subscribe(
     response =>{
       console.log(response);
         this.ELEMENT_DATA = [];
             for (const i in response) {

               this.ELEMENT_DATA.push({
                 cod_producto:response[i].cod_producto,
                 des_producto:response[i].des_producto,
                 cant_producto:response[i].cant_producto
               });
             }
             this.cantidad_prod = this.ELEMENT_DATA[0].cant_producto;
             this.cod_prod = response[0].cod_producto;
             this.des_prod = response[0].des_producto;

     },
     error =>{
       console.log(error);
       console.log(<any>error.error.message);

       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
   );
 }
   eliminarclientes(id:any){
    console.log('eliminar');
    this.servicio.eliminarclienteshttp(id).subscribe(
      response =>{
        this.xobj3 = [];
        this.listarordenesprod(null);
              this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.listarordenesprod(null);
      }
    );

    return this.obj;
  }

  modalrespuesta(obj:any, text:any){
    this.accion = text;
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '24%',
        height:'35%',
        data: {id:obj.cod_cliente,
              accion:this.accion},
        disableClose: true,
        panelClass: 'elem_modal'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log('The dialog was closed');
          console.log(result);
          let resultado = this.eliminarclientes(result.id);
          console.log(resultado);
        }
      });
  }

  editardatos(obj:any,text:any){
    var dialogRef:any;
    this.accion = text;
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    if (this.accion === 'actualizar') {
      dialogRef = this.dialog.open(FormConsultaOrdenesComponent, {
        width: '100%',
        height:'80%',
        data: {cod_orden_producto: obj.cod_orden_producto,
                cod_usuario:datos,
                des_usuario: nombre,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
    }else{
      dialogRef = this.dialog.open(FormEntradaMpComponent, {
        width: '100%',
        height:'80%',
        data: {cod_lista_precio_producto:'',
                cod_usuario:datos,
                des_usuario: nombre,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
    }

      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);

     this.gestionOrdenProd(result);
        }
      });
  }

public  gestionOrdenProd(xvalor:any){
  var resultado
  console.log(xvalor);
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner'
  });
  setTimeout(() => {
     resultado = this.servicio.gestionOrdenProdhttp(xvalor).subscribe(
      response =>{
        console.log(response,'una respuesta');
        this.dialogSpin.close();
        if(response.resul){
          console.log(response.resul,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
          this.listarordenesprod(null);
              for (const i in response.resul) {
                  console.log(response[i]);

                this.ELEMENT_DATA.push({
                 cod_orden_producto:response.resul[i].cod_orden_producto,
                 cod_usuario:response.resul[i].cod_usuario,
                 des_usuario: response.resul[i].des_usuario,
                 estatus:response.resul[i].estatus,
                 fecha_inicio:response.resul[i].fecha_inicio,
                 hora_inicio:response.resul[i].hora_inicio,
                 cod_usuario_cierre: response.resul[i].cod_usuario_cierre,
                 des_usuario_cierre:response.resul[i].des_usuario_cierre,
                 fecha_fin:response.resul[i].fecha_fin,
                 hora_fin:response.resul[i].hora_fin,
                 accion:'',
                 accion2:'',
                 accion3:''
               });
              }
              this.displayedColumns = [ 'cod_orden_producto',
                                         'cod_usuario',
                                         'des_usuario',
                                         'estatus',
                                         'fecha_inicio',
                                         'hora_inicio',
                                         'Imprimir',
                                         'Actualizar',
                                         'confirmar'
                                       ];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
              this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
       this.dialogSpin.close();
        console.log(<any>error.error.message);
        this.displayedColumns = [ 'cod_orden_producto',
                                   'cod_usuario',
                                   'des_usuario',
                                   'estatus',
                                   'fecha_inicio',
                                   'hora_inicio',
                                   'Imprimir',
                                   'Actualizar',
                                   'confirmar'
                                 ];
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }, 3000);

   return resultado;
 }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
   public buscarClientes(xvalor:string){
      var valor:any = {valor:xvalor}
      console.log(valor);
    this.servicio.buscarClienteshttp(valor).subscribe(
      response =>{
        if(response){
          console.log(response, 'busqueda' );
          this.ELEMENT_DATA = [];
          for (const i in response) {

            this.ELEMENT_DATA.push({
              id_cliente:response[i].id_cliente,
              cod_cliente:response[i].cod_cliente,
              des_cliente: response[i].des_cliente,
              tipo_cliente:response[i].tipo_cliente,
              dir_cliente:response[i].dir_cliente,
              pais_cliente:response[i].pais_cliente,
              pais_estado_cliente: response[i].pais_estado_cliente,
              des_vend_cliente:response[i].des_vend_cliente,
              tipo2_cliente:response[i].tipo2_cliente,
              estatus_cliente:response[i].estatus_cliente,
              credito_dias_cliente:response[i].credito_dias_cliente,
              reten_cliente:response[i].reten_cliente,
              telefono1_cliente:response[i].telefono1_cliente,
              telefono2_cliente:response[i].telefono2_cliente,
              correo_cliente:response[i].correo_cliente,
              comercializacion:response[i].comercializacion,
              accion:'',
              accion2:'',
              accion3:''
            });
          }
          this.displayedColumns = [ 'id_cliente',
                                        'cod_cliente',
                                        'des_cliente',
                                        'tipo_cliente',
                                        'pais_cliente',
                                        'pais_estado_cliente',
                                        'des_vend_cliente',
                                        'estatus_cliente',
                                        'tipo2_cliente',
                                        'credito_dias_cliente',
                                        'reten_cliente',
                                        'comercializacion',
                                        'dir_cliente',
                                        'telefonos_cliente',
                                        'correo_cliente',
                                        'Eliminar',
                                        'Actualizar'
                                      ];
             this.dataSource = new MatTableDataSource<PeriodicElementclientes>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log(<any>error.error[0].message);
        this.snackBar.open(<any>error.error[0].message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  async detalleOrdenProd(xvalor:any){
      this.dialog.open(FormConsultaOrdenesComponent, {
        width: '100%',
        height:'90%',
        data: {cod_orden_producto: xvalor.cod_orden_producto},

        panelClass: 'pedidos_modal'
      });

 }
 async confirmacionOrdenProd(xvalor:any){
  var dialogRef = this.dialog.open(FormConfirmacionInvMpComponent, {
    width: '100%',
    height:'80%',
    data: {cod_usuario:xvalor.cod_usuario,
           des_usuario:xvalor.des_usuario,
           cod_orden_producto: xvalor.cod_orden_producto,
           estatus:xvalor.estatus},
    disableClose: true,
    panelClass: 'pedidos_modal'
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result != 'cerrar'){
      console.log(result);
     this.gestionOrdenLaboratorio(result);
    }
  });
}
public  gestionOrdenLaboratorio(xvalor:any){
  var resultado;
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner'
  });
  setTimeout(() => {
    console.log(xvalor);
    resultado = this.servicio.gestionOrdenLabhttp(xvalor).subscribe(
     response =>{
      this.dialogSpin.close();
       console.log(response.resul,'una respuesta');
       if(response.resul){
         console.log(response.resul,'una respuesta');
         this.xobj2 = response;
         this.ELEMENT_DATA = [];
             for (const i in response.resul) {
                 console.log(response[i]);

               this.ELEMENT_DATA.push({
                cod_orden_producto:response.resul[i].cod_orden_producto,
                cod_usuario:response.resul[i].cod_usuario,
                des_usuario: response.resul[i].des_usuario,
                estatus:response.resul[i].estatus,
                fecha_inicio:response.resul[i].fecha_inicio,
                hora_inicio:response.resul[i].hora_inicio,
                cod_usuario_cierre: response.resul[i].cod_usuario_cierre,
                des_usuario_cierre:response.resul[i].des_usuario_cierre,
                fecha_fin:response.resul[i].fecha_fin,
                hora_fin:response.resul[i].hora_fin,
                accion:'',
                accion2:'',
                accion3:''
              });
             }
             this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'detalle',
                                        'Actualizar',
                                        'confirmar'
                                      ];
              /* this.dataSource = this.ELEMENT_DATA; */
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
             this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
       }
     },
     error =>{
      this.dialogSpin.close();
       console.log(<any>error.error.message);
       this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'detalle',
                                        'Actualizar',
                                        'confirmar'
                                      ];
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   );
  }, 3000);


   return resultado;
 }

  imprimirfactura(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner'
    });
    setTimeout(() => {
      const pdf = new PdfMakeWrapper();
      var valor = {cod_producto:this.cod_prod,cod_orden_producto:xvalor.cod_orden_producto}
     this.servicio.buscarFormulaProdhttp(valor).subscribe(
      async  response =>{
        this.dialogSpin.close();
         console.log(response.producto3[0].cant_producto);
           this.ELEMENT_DATA = [];

               for (const i in response.producto1) {
                 var resul = parseFloat(response.producto1[i].cantidad_prod_mp.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.')) * parseFloat(response.producto3[0].cant_producto.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.'));
                 this.ELEMENT_DATA.push({
                   cod_producto:response.producto1[i].cod_prod_mp,
                   des_producto:response.producto1[i].des_prod_mp,
                   cant_unidad_producto:response.producto1[i].cantidad_prod_mp,
                   tipo_producto_mp:response.producto1[i].tipo_producto_mp,
                   cant_producto:resul.toFixed(2).replace(/\D/g, "")
                   .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                   unidad_prod_mp:response.producto1[i].unidad_prod_mp,
                 });
               }
               console.log(this.ELEMENT_DATA);
               pdf.info({
            title: 'Orden de Producción N°'+this.cod_orden_producto+'',
            author: 'Factory cosmetics C.A.',
            subject: 'Guia de despacho',
        });
        var titulo = 'FACTORY COSMETICS 3DG C.A.';
        var texto =
                      'Calle Bolivar con Calle Regulo Franquiz Local S/N,\n\
                        Sector Pueblo Arriba. Guarenas, Miranda.\n\
                        Zona Postal 1220. Telf.: (0212) 519.43.98 / 365.46.24 / 714.03.42 /425.2.83\n\
                        E-mail:contacto@factorycosmetics.com.ve / www.factorycosmetics.com.ve \n\
                                      RIF.: J-41031797-3';
          pdf.pageMargins([20,5,20,5]);
          pdf.add(pdf.ln(1));
          pdf.add(
            new Columns([ new Table([['',titulo,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).bold().absolutePosition(20,58).end]).columnGap(5).end,
                                );
          pdf.add(
            new Columns([ new Table([[await new Img("../../../../../../assets/img/Factory (1).png").build(),'','']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(10,15).end]).columnGap(5).end,
                                );
          pdf.add(
                new Columns([ new Table([['',texto,'']]).widths([100,345,100]).layout('noBorders').alignment('center').fontSize(10).absolutePosition(20,70).end]).columnGap(5).end,
                                );

          pdf.add(this.crearTabla25(xvalor,xvalor.fecha_inicio,xvalor.hora_inicio,this.cod_prod, this.des_prod));
          pdf.pageSize('LETTER');
          pdf.add(this.headerTabla5(this.ELEMENT_DATA));
          pdf.add(this.crearTabla45(null));
          pdf.add(this.crearTabla46(null));
          pdf.add(pdf.ln(1));
           pdf.create().open();

       },
       error =>{
        this.dialogSpin.close();
         console.log(error);
         console.log(<any>error.error.message);
         this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
           verticalPosition: this.verticalPosition});
       }
     );
    }, 3000);
}

//////////////////////REPORTES////////////////////////////

/* numerofactura(data:any):ITable{
  return new Table([
    ['Asociado a la Factura Nº:  '+data+'', '', '', '', ''],
    ]).widths([200,'*','*','*','*','*']).alignment('left').fontSize(10).layout('noBorders').end;
}
numerodespacho(data:any):ITable{
  return new Table([
    ['Guía de Despacho Nº:  '+data.cod_guia_despacho+'', '', '', '', '', 'Emisión: 00000000'],
    ]).widths([200,'*','*','*','*','100']).alignment('left').bold().fontSize(10).layout('noBorders').end;
}

headerTabla():ITable{
return new Table([
  ['Lote', 'Código', 'Descripción', 'Cantidad en Lote', 'Despacho', 'Restante']
]).layout({
  vLineWidth:(i:number, node:any, col:number)=>{
      return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
    },
    hLineWidth:(i:number, node:any, col:number)=>{
      return i.valueOf() === 0 || i.valueOf() === 1 ? 1:0;
    }
}).widths(['*', '*', 200, '*', '*', '*']).alignment('center').fontSize(8).end;
}
crearTabla(data:interfacefactura[]):ITable{
return new Table([
...this.extraerData(data)

]).layout('lightHorizontalLines').widths(['*', '*', 200, '*', '*', '*']).alignment('center').fontSize(10).end;
}
extraerData(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[row.cod_lote,
                             row.cod_producto,
                             row.des_producto,
                             row.cant_producto,'','']);
    } */

    //////////////////////NOTAS ND////////////////////////////
getBase64ImageFromURL5(url) {
return new Promise((resolve, reject) => {
  var img = new Image();
  img.setAttribute("crossOrigin", "anonymous");

  img.onload = () => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    var dataURL = canvas.toDataURL("image/png");

    resolve(dataURL);
  };

  img.onerror = error => {
    reject(error);
  };

  img.src = url;
});
}
headerTabla5(data:any):ITable{
return new Table([
  ['Codigo del producto','Descripción del producto','Cantidad requerida', 'tipo','unidad'],
  ...this.extraerData5(data)
]).layout('lightHorizontalLines').widths([100,250,100,'*','*']).alignment('center').fontSize(10).absolutePosition(20,200).end;
}
crearTabla555(data:interfacefactura[]):ITable{
return new Table([
...this.extraerData5(data)

]).widths([20,'*','*','*','*','*']).alignment('left').fontSize(9).layout('lightHorizontalLines').absolutePosition(20,218).margin(40).end;
}
crearTabla55(data:interfacefactura[]):ITable{
return new Table([
  ...this.extraerData55(data)

]).layout('lightHorizontalLines').widths(['*','*','*','*','*',20]).alignment('right').fontSize(9).absolutePosition(20,218).margin(40).end;
}
crearTabla5(data:interfacefactura[]):ITable{
  return new Table([
    ...this.extraerData555(data)

  ]).layout('lightHorizontalLines').widths(['*','*','*',200,'*','*']).alignment('left').fontSize(9).absolutePosition(20,218).margin(40).end;
  }
/* async cinta85(data:interdatosfacefactura[]){

return new Table([
  [await new Img("../../../../../../assets/img/Factory.png").build(),'','','', '','Presupuesto Nº: '+data[0]+''],
]).widths(['*','*','*','*','*',200]).alignment('left').fontSize(15).layout('noBorders').end;
} */
numerofact5(data:any):ITable{

return new Table([
  ['','','','', '','Orden de Producción Nº: '+data.cod_guia_despacho+''],
]).widths(['*','*','*','*','*',200]).alignment('right').bold().fontSize(11).layout('lightHorizontalLines').absolutePosition(20,135).end;
}
crearTabla25(data:any, data1:any, data2:any, data3:any, data4:any){
  var nombre = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
  var codigo = localStorage.getItem('cod_usuario');
  return  new Columns([new Table([

      ['Orden de Producción Nº: '+data.cod_orden_producto+'','Usuario: '+nombre+''],
      ['Orden de Producción Primaria Nº: N/A','Codigo del usuario: '+codigo+''],
      ['Codigo del producto Nº: '+data3+'','Descripción: '+data4+''],


    ]).widths([200,'*']).alignment('left').fontSize(9).bold().layout('noBorders').absolutePosition(20,150).end,
    new Table([

      [ '','Emisión: '+data1+''],
      [ '','Hora: '+data2+''],
      [ '',' ']

    ]).widths(['*',250]).alignment('right').fontSize(9).bold().layout('noBorders').absolutePosition(50,150).end]).columnGap(5).end;
    }
/* crearTabla35(data:interdatosfacefactura[]):ITable{
  return new Table([
    ['Dirección: Las clavellinas, sector las mercedes, Guarenas.','','','', '',''],
  ]).widths([350,'*','*','*','*','*']).alignment('left').fontSize(9).layout('noBorders').end;
  } */
   crearTabla45(data:any){

    return  new Columns([ new Table([
      ['Observaciones:','','','','',''],
      [' ','','','', '',''],
      [' ','','','','',''],
      [' ','','','','',''],
    ]).widths([450,'*','*','*','*','*']).alignment('left').fontSize(10).bold().layout({
      vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 0:0;
        },
        hLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 7 ? 0:0;
        }
    }).absolutePosition(20,700).end,
    new Table([
      [' ','','','','',''],
      [' ','','','','',''],
      [' ','','','','',''],
      [' ','','','','','']
    ]).widths(['*','*','*','*','*','*']).alignment('right').fontSize(10).layout({
      vLineWidth:(i:number, node:any, col:number)=>{
          return i.valueOf() === 0 || i.valueOf() === 6 ? 1:0;
        }
    }).absolutePosition(20,700).end
  ]).columnGap(5).end

    }

    crearTabla46(data:any){

      return  new Columns([ new Table([
        ['Coordinador de operaciones: ______________________','','','','',''],
        [' ','','','', '','']

      ]).widths([250,'*','*','*','*','*']).alignment('left').fontSize(10).bold().layout('noBorders').absolutePosition(20,660).end,
      new Table([
        [' ','','','','','Analista de inventario: ______________________'],
        [' ','','','','','']
      ]).widths(['*','*','*','*','*',250]).alignment('right').fontSize(10).layout('noBorders').absolutePosition(20,660).end
    ]).columnGap(5).end

      }

    extraerData5(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[row.cod_producto,row.des_producto,
        row.cant_producto, row.tipo_producto_mp,row.unidad_prod_mp]);
    }
    extraerData55(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[ '',
                          row.cant_producto, row.cant_producto,'','']);
    }
    extraerData555(data:interfacefactura[]):TableRow[]{

      let rows =[];

      for (const i in data) {
       rows.push(data[i]);

      }
      console.log(rows);
      return rows.map(row =>[
                           '', row.des_producto,
                          '', '','']);
    }


  /* handleFile(event) {
    let file = event.target.files[0];

    var id = document.getElementById('file');
    console.log(file);
    var nombre = 'Codigo de Unidad';
    this.servicio.processFileToJson({}, file).subscribe(data => {
      let arry = data.sheets.Hoja1;
      console.log(arry);
      for (const i in arry) {
          console.log(arry[i]);
          this.result.push(arry[i]);
      }
      if (this.result.length != 0) {
        console.log('entro en el if');
          this.servicio.insertarmasivohttp(this.result).subscribe(
            response =>{
              if(response){
                console.log(response,'una respuesta');
                let xobj2 = response.resul;
                this.ELEMENT_DATA = [];
                    for (const i in xobj2) {

                      this.ELEMENT_DATA.push({id:xobj2[i].id, Nombre: xobj2[i].nombre_usu, Apellido:  xobj2[i].apellido_usu , Direccion:  xobj2[i].direccion_usu,  Correo: xobj2[i].correo_usu, Accion: '', Accion2: ''});
                    }
                   this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];

                    this.dataSource = new MatTableDataSource<PeriodicElement2>(this.ELEMENT_DATA);
                    this.dataSource.paginator = this.paginator;
                 var bool = true;
                 console.log(response.resul);
                this.snackBar.open(response.message,'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                 return bool;
              }
            },
            error =>{
              console.log(<any>error);
            }
          );
      }
      /* this.result = JSON.stringify(data['sheets'].Sheet1); */
      /* this.snackBar.open('El archivo se cargo de forma correcta en la base de datos', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,}); */
  /*   })
    event.srcElement.value = null;
  } */
  /////DATATABLE
  /* applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
  } */


}
