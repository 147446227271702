
<div id="modal_carrito" class=" container-fluid">

    <!--///////////////////////////ALERTA CODE////////////////////////////-->
<div class="alert alertSuccess alert-success alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10000; position: absolute;" role="alert">
   <span style="font-size: 20px;"><mat-icon >assignment_turned_in</mat-icon></span><strong class="mr-1">Listo!</strong> Operacion Exitosa.
   <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
     <span aria-hidden="true">&times;</span>
   </button> -->
 </div>
 <!--///////////////////////////ALERTA CODE////////////////////////////-->
   <form >
       <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #4979d3; border-radius: 12px 12px 0 0; background-color: #4979d3 ">
           <!-- <div id="content_1" class="col-md-3 px-0 py-2 h-100">
               <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                   <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff;">Partidas presupuestarias</mat-label>
                       <input style="font-size: 11px; color: #fff;" type="text" matInput placeholder="" formControlName="descrip_definicion" [matAutocomplete]="autoGroup2" #descrip_definicion>
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                           <mat-icon>search</mat-icon>
                           </span>
                       <mat-autocomplete #autoGroup2="matAutocomplete">
                           <mat-optgroup style="color: #000; font-size: 12px; " *ngFor="let group of arry | async" [label]="group.letter">
                               <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                   {{i}}
                               </mat-option>

                           </mat-optgroup>
                       </mat-autocomplete>
                   </mat-form-field>
               </div>
           </div>
           <div id="content_2" class="col-md-2 px-0 py-2 h-100">
               <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                   <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff;">Cantidad</mat-label>
                       <input style="font-size: 11px; color: #fff;" maxlength="8" type="text" matInput placeholder="Cantidad" formControlName="cant_cuenta_gasto" #cant_cuenta_gasto id="cantidad" (keyup)="ponerPuntosyComas($event)" autocomplete="off">
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">
                           #
                   </span>
                   </mat-form-field>
               </div>
           </div> -->
           <div class="col-md-5 px-0 py-2 h-100 d-flex w-100">
               <div id="content_3" class=" mx-1 mat-elevation-z8 h-100 w-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                   <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                       <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                         <mat-icon>search</mat-icon>
                     </span>
                   </mat-form-field>
               </div>
           </div>
           <div class=" col-md-5 px-0 py-2 h-100 d-flex">
               <div class=" fileUpload btn btn-primary w-100"  style="display: flex; justify-content: center; align-items: center;">
                   <span>Cargar</span>
                   <input class="upload" type="file" (change)="onFileChange($event)" multiple="false">
               </div>
               <div [formGroup]="forma" class="d-flex">
                   <mat-checkbox class='mr-2 d-flex justify-content-center align-items-center' formControlName="entrada">Entrada</mat-checkbox>
                   <mat-checkbox class='mr-2 d-flex justify-content-center align-items-center'  formControlName="salida">Salida</mat-checkbox>
                   <mat-checkbox class='d-flex justify-content-center align-items-center' formControlName="comedor">Comedor</mat-checkbox>
               </div>
           </div>

           


           
<!--             <div class=" col-md-1 px-0 py-2 h-100">
               <div class="  mx-1 h-100" style="display: flex; justify-content: center; align-items: center;">
                   <button class="btn btn-block btn-lg btn-light h-75 w-75" (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" style="color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                       <mat-icon >add_shopping_cart</mat-icon>
                     </button>
               </div>
           </div> -->
           <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
               <div class="  mx-1 h-100" style="display: flex; justify-content: center; align-items: center;">
                   <button class="btn btn-block btn-lg btn-light h-75 w-75" (click)="enviar()" matTooltip="CARGAR" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                   <mat-icon >save</mat-icon>
                 </button>
               </div>
           </div>
           <div class=" col-md-1 px-0 py-2 h-100">
               <div class="  mx-1 h-100" style="display: flex; justify-content: center; align-items: center;">
                   <button class="btn btn-block btn-lg btn-danger h-75 w-75" (click)="cerrarmodal()" matTooltip="SALIR">
                         <mat-icon >exit_to_app</mat-icon>
                     </button>
               </div>
           </div>

       </div>
       <div id="carrito_p2" class="row" style=" border: 2px solid #4979d3; border-radius: 0 0 12px 12px ;">
           <div class="col-md-12 px-0  border h-100" style="background-color: #4979d3; border-radius: 0 0 12px 12px;">
               <table #table mat-table [dataSource]="dataSource1" class=" table-primary text-center table-striped table-bordered table-condensed table-hover">

<!--                     <ng-container matColumnDef="cod_centro">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #4979d3;">
                           <h5><strong>Centro de costo</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_centro}} </td>
                       <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: #4979d3; color: #fff;">Centro de costo</td>
                   </ng-container> -->

                   <ng-container matColumnDef="cod_usuario">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #4979d3;">
                           <h5><strong>Codigo de Carnet</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_usuario}} </td>
                       <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: #4979d3; color: #fff;">Codigo de Carnet</td>
                   </ng-container>
                   <ng-container matColumnDef="des_usuario">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #4979d3;">
                           <h5><strong>Nombre </strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_usuario}} </td>
                       <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: #4979d3; color: #fff;">Nombre</td>
                   </ng-container>

                   <ng-container matColumnDef="hora_entrada">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #4979d3;">
                           <h5><strong>Hora de entrada</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_entrada}} </td>
                       <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: #4979d3; color: #fff;">Hora de entrada</td>
                   </ng-container>

                   <ng-container matColumnDef="hora_salida">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #4979d3;">
                           <h5><strong>Hora de salida</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.hora_salida}} </td>
                       <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: #4979d3; color: #fff;">Hora de salida</td>
                   </ng-container>
                   <ng-container matColumnDef="dt_ubicacion">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #4979d3;">
                           <h5><strong>Sede</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.dt_ubicacion}} </td>
                       <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: #4979d3; color: #fff;">Sede</td>
                   </ng-container>
                   <ng-container matColumnDef="fecha">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #4979d3;">
                           <h5><strong>Fecha</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.fecha}} </td>
                       <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: #4979d3; color: #fff;">Fecha</td>
                   </ng-container>

                   <ng-container matColumnDef="remov_elem">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #4979d3;">
                           <h5><strong>Eliminar</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                           <button (click)="eliminarItems(element)" matTooltip="QUITAR PRODUCTO" mat-raised-button style="height: 35px; border: 2px solid #4979d3; color:rgb(255, 255, 255); background-color: #4979d3;">
                               <mat-icon >remove_shopping_cart</mat-icon>
                               </button>
                       </td>
                       <td mat-footer-cell *matFooterCellDef style="padding: 5px; background-color: #4979d3; color: #fff;"> Eliminar</td>
                   </ng-container>

                   <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                   <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                   <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
               </table>
           </div>
       </div>
   </form>

</div>
