<div class="container-fluid mat-elevation-z8" style="height: 100%; padding: 5%;">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center ">
            <p>
                <b>Por favor seleccione una forma</b>
            </p>
            <hr>
        </div>
    </div>

    <div class="row" style="margin-top: 5%;">
        <div class="col-md-6 col-sm-6 col-xs-6 text-center">

            <button *ngIf="termino === 'formas'" class="btn-block" (click)="editardatos('factura')" mat-raised-button color="accent">FACTURAS</button>
            <button *ngIf="termino === 'modena'" class="btn-block" (click)="notasEntrega('divisas')" mat-raised-button color="accent">DIVISAS</button>
            <button *ngIf="termino === 'frecuencial'" class="btn-block" (click)="facturas('normal')" mat-raised-button color="accent">NORMAL</button>

        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 text-center">

            <button *ngIf="termino === 'formas'" class="btn-block" (click)="editardatos('nota')" mat-raised-button color="primary">NOTAS</button>
            <button *ngIf="termino === 'modena'" class="btn-block" (click)="notasEntrega('bolivares')" mat-raised-button color="primary">BOLIVARES</button>
            <button *ngIf="termino === 'frecuencial'" class="btn-block" (click)="en_construccion()" mat-raised-button color="primary">REFERENCIAL</button>
        </div>
    </div>
    <br>
    <div class="row" style="margin-top: 40px;">
        <div class="col-md-5 col-sm-2 col-xs-2 text-center">

        </div>
        <div class="col-md-5 col-sm-8 col-xs-8 text-center">

        </div>
        <div class="col-md-2 col-sm-2 col-xs-2 text-center">
            <mat-icon (click)="cerrarmodal()" class="btn_elem">close</mat-icon>
        </div>
    </div>
</div>