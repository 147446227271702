import {Component, ViewChild, OnInit, ComponentFactoryResolver, ApplicationRef, Injector, OnDestroy } from '@angular/core';
import {CdkPortal,DomPortalOutlet} from '@angular/cdk/portal';
import { Inject,AfterViewInit, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { nkServices } from './componentes/shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {FormLoginComponent} from './componentes/shared/modal/form-login/form-login.component';
import {LoginComponent} from './componentes/shared/login/login.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormSpinnerComponent } from './componentes/shared/modal/form-spinner/form-spinner.component';
@Component({
  selector: 'app-redirec-app',
  templateUrl: './redirec-app.component.html',
  styleUrls: ['./redirec-app.component.css']
})
export class RedirecAppComponent implements OnInit {

  constructor(){}

  ngOnInit(){
   
  }

}
