<div class="col-md-12 col-sm-12 col-xs-12 my-0" style=" border-radius: 1rem; ">
    <form [formGroup]="forma" class="">
        <div class="modal-content text-white " style=" width: 100%; background-color: #1E212C; border-radius: 1rem;">
            <div class="modal-header px-5" style="background-color: #1E212C;  border-radius: 1rem 1rem 0 0;">
                <h3 class="modal-title text-center" *ngIf="!msg"><strong class="text-center">¿Estas seguro que desea continuar?</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'facturar_pedido'"><strong>Debe colocar el codigo de control de su forma fiscal</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'facturar_nota'"><strong>Debe ingresa un comentario</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'anulacion_ventas'"><strong>Debe ingresa un comentario</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'nota_credito'"><strong> Debe colocar el codigo de control de su forma fiscal</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'finalizar_solicitud'"><strong>Debe agregar un comentario sobre el estatus</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'tomar_solicitud'"><strong>Debe agregar un comentario sobre el estatus</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'reinicio'"><strong>Debe agregar un comentario sobre el estatus</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'actualizar_comentarios'"><strong>Debe agregar un comentario sobre el estatus</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'ordenC'"><strong>¿Estas seguro que desea continuar?</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'ordenI'"><strong>¿Estas seguro que desea continuar?</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'ordenE'"><strong>¿Estas seguro que desea continuar?</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'recalcular_tasa'"><strong>Ingrese un valor en Bs.</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'activa_formula'"><strong>¿Estas seguro que desea continuar?</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'crear_definicion_sistema'"><strong>Definiciones del sistema</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'depositos_inventarios'"><strong>Generar nuevo deposito</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'crear_lista_precios'"><strong>Generar nueva lista</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'crear_documentos'"><strong>Generar nuevo documento</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'crear_app'"><strong>Generar nueva aplicación</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'crear_estructura'"><strong>Generar nueva estructura</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'nota_credito_devolucion'"><strong>Generar devolución</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'dev_nota'"><strong>Ingrese el codigo de la devolución</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'dscnt'"><strong>Ingrese un valor.</strong></h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'OPVNTS'"><strong>Ingrese un valor.</strong></h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'LTS'"><strong>Lote manual</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'LTS_A'"><strong>Ajustar lote</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'EFBCK'"><strong>Editar cantidad del producto</strong> </h3>
                <h3 class="modal-title text-center " *ngIf="msg === 'reportes'"><strong>Nuevo reporte</strong> </h3>
                <h3 class="modal-title" *ngIf="msg === 'custom'" class=" text-center" style=" font-size: 18px;"><strong> <i class="fas fa-exclamation-triangle text-danger"></i> {{elMensaje}}</strong> </h3>

            </div>
            <div class="modal-body bg-light">
                <div class="text-center" *ngIf="msg === 'custom'">

                    <p>
                        <b> Los productos que no elijas seran enviados al tablero de cotizaciones de nuevo para que vuelvan a ser cotizados</b>

                    </p>

                    <div *ngIf="segundos > 0">

                        <b>Espera {{segundos}} seg</b>

                    </div>
                    <div *ngIf="segundos == 0">
                        <b>Listo!</b>
                    </div>

                </div>
                <div class=" text-center " *ngIf="msg === 'ordenC'">
                    <p>
                        <b>El total de fabricación es IGUAL al Requerido.</b>
                    </p>
                    <hr>
                    <h3>Si desea continuar, la orden de producción actual estara COMPLETA.</h3>
                    <hr>
                </div>
                <div class=" text-center " *ngIf="msg === 'ordenI'">
                    <p>
                        <b>El total de fabricación es MENOR al Requerido.</b>
                    </p>
                    <hr>
                    <h3>Si desea continuar, la orden de producción actual pasara a REVISIÓN.</h3>
                    <hr>
                </div>
                <div class=" text-center " *ngIf="msg === 'ordenE'">
                    <p>
                        <b>El total de fabricación es MAYOR al Requerido.</b>
                    </p>
                    <hr>
                    <h3>Si desea continuar, la orden de producción actual pasara a REVISIÓN.</h3>
                    <hr>
                </div>
                <div class="form-group" *ngIf="msg === 'recalcular_tasa'">
                    <label for="recalcular_tasa">Cantidad en Bs</label>
                    <input id="recalcular_tasa" #tasa_base_input (keyup)="format(tasa_base_input)" placeholder="" class="form-control" formControlName="tasa_base" autocomplete="off">
                </div>
                <div class="form-group" *ngIf="msg === 'facturar_pedido'">
                    <div class="my-1">
                        <label for="cod_control">Codigo de control</label>
                        <input id="cod_control" placeholder="Ejemplo: 00-000000" class="form-control" formControlName="cod_control" autocomplete="off">
                    </div>
                    <div class="my-1">
                        <label for="comentarios">Comentarios ( 50 caracteres )</label>
                        <textarea id="comentarios" class="form-control" formControlName="comentarios"></textarea>
                    </div>
                    <div class="mt-3" *ngIf="data.tipo_pedido === 'PFF'">
                        <button mat-button style="border: outset 3px rgb(39, 68, 165);" class="rounded-pill btn btn-primary form-control" (click)="diferencial_cambiario()">Diferencial cambiario</button>
                    </div>

                </div>
                <div class="form-group" *ngIf="msg === 'facturar_nota'">

                    <div class="my-1">
                        <label for="comentarios">Comentarios (50 caracteres)</label>
                        <textarea id="comentarios" class="form-control" formControlName="comentarios"></textarea>
                    </div>

                </div>
                <div class="form-group" *ngIf="msg === 'anulacion_ventas'">

                    <div class="my-1">
                        <label for="comentarios">Comentarios (50 caracteres)</label>
                        <textarea id="comentarios" class="form-control" formControlName="comentarios"></textarea>
                    </div>

                </div>
                <div class="form-group" *ngIf="msg === 'nota_credito'">
                    <label for="cod_control">Codigo de control</label>
                    <input id="cod_control" placeholder="Ejemplo: 00-000000" class="form-control" formControlName="cod_control" autocomplete="off">
                </div>
                <div class="form-group" *ngIf="msg === 'dev_nota'">
                    <label for="cod_control">Codigo devolución</label>
                    <input id="cod_control" placeholder="" class="form-control" formControlName="cod_dev" autocomplete="off">
                </div>
                <div class="" *ngIf="msg === 'tomar_solicitud'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%;">Para continuar debe colocar un comentario de inicio</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label >Agregar su comentario</mat-label>
                                        <textarea style="font-size: 12px; color: black;" maxlength="150" matInput placeholder="" autocomplete="off" formControlName="comentario_solicitud"> </textarea>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'finalizar_solicitud'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%;">Para continuar debe colocar un comentario final</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label >Agregar su comentario</mat-label>
                                        <textarea style="font-size: 12px; color: black;" maxlength="150" matInput placeholder="" autocomplete="off" formControlName="comentario_solicitud"> </textarea>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'actualizar_comentarios'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%; ">Para continuar debe colocar un comentario</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <span class="font-size:12px" class="py-2"> <strong>Comentario actual: </strong> {{data.comentario}}</span>
                                    <hr>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;" *ngIf="comentario_status === 'SPRT-T'">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput py-1">
                                        <mat-label >Agregar su comentario</mat-label>
                                        <textarea style="font-size: 12px; color: black;" maxlength="150" matInput placeholder="" autocomplete="off" formControlName="comentario_solicitud"> </textarea>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'reinicio'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%; ">Para continuar debe colocar un comentario final</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label >Agregar su comentario</mat-label>
                                        <textarea style="font-size: 12px; color: black;" maxlength="150" matInput placeholder="" autocomplete="off" formControlName="comentario_solicitud"> </textarea>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'activa_formula'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%; ">Para continuar debe justificar la activación de la formula</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label >Agregar su comentario</mat-label>
                                        <textarea style="font-size: 12px; color: black;" maxlength="150" matInput placeholder="" autocomplete="off" formControlName="comentario"> </textarea>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'crear_definicion_sistema'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%; ">Nueva Definición del sistema</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput py-1 px-1">
                                        <mat-label>Menu</mat-label>
                                        <input style="font-size: 11px; color: #000;" type="text" matInput placeholder="" formControlName="cod_menu" [matAutocomplete]="autoGroup2" #cod_menu>
                                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                                <mat-icon>search</mat-icon>
                                                                </span>
                                        <mat-autocomplete #autoGroup2="matAutocomplete">
                                            <mat-optgroup style="color: #000; font-size: 12px; " *ngFor="let group of menu | async" [label]="group.letter">
                                                <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                                    {{i}}
                                                </mat-option>

                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label>Nueva definición</mat-label>
                                        <input type="text" style="font-size: 12px; " maxlength="150" matInput placeholder="" autocomplete="off" formControlName="comentario">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'depositos_inventarios'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%; ">Seleccione los datos necesarios</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label>Depositos existentes</mat-label>
                                        <mat-select    formControlName="des_deposito" autocomplete="off">
                                            <mat-option *ngFor="let i of deposito" [value]="i">({{i.concepto_definicion}}) {{i.descrip_definicion}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label>Tipo del deposito</mat-label>
                                        <mat-select   (selectionChange)="cargarUmDep($event)"  formControlName="tipo_prod" autocomplete="off">
                                            <mat-option *ngFor="let i of tipo_prod" [value]="i">({{i.concepto_definicion}}) {{i.descrip_definicion}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label>Tipo de manejo</mat-label>
                                        <mat-select   formControlName="tipo_manejo" autocomplete="off">
                                            <mat-option *ngFor="let i of tipo_manejo" [value]="i">({{i.concepto_definicion}}) {{i.descrip_definicion}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label  >Comentario</mat-label>
                                        <input   type="text" style="font-size: 12px; " maxlength="50" matInput placeholder="" autocomplete="off" formControlName="descripcion">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'reportes'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%; ">Seleccione los datos necesarios</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">


                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput py-1 px-1">
                                        <mat-label>Unidad</mat-label>
                                        <input style="font-size: 11px; " type="text" matInput placeholder="" formControlName="unidad" [matAutocomplete]="autoGroup5" #cod_menu>
                                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                                <mat-icon>assignment</mat-icon>
                                                                </span>
                                        <mat-autocomplete #autoGroup5="matAutocomplete">
                                            <mat-optgroup style=" font-size: 12px; " *ngFor="let group of unidad | async" [label]="group.letter">
                                                <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                                    {{i}}
                                                </mat-option>

                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput">
                                        <mat-label >Descripción</mat-label>
                                        <input type="text" style="font-size: 12px; " maxlength="50" matInput placeholder="" autocomplete="off" formControlName="descripcion">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'crear_lista_precios'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%;">Nueva Lista de precios</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput py-1 px-1">
                                        <mat-label>Tipo lista</mat-label>
                                        <input style="font-size: 11px; " type="text" matInput placeholder="" formControlName="tipo_cliente" [matAutocomplete]="autoGroup2">
                                        <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                                <mat-icon>search</mat-icon>
                                                                </span>
                                        <mat-autocomplete #autoGroup2="matAutocomplete">
                                            <mat-optgroup style=" font-size: 12px; " *ngFor="let group of tipo_lista | async" [label]="group.letter">
                                                <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                                    {{i}}
                                                </mat-option>

                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'crear_documentos'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%;">Nuevo documento</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field class="materialinput py-1 px-1">
                                        <mat-label>Tipo lista</mat-label>
                                        <input style="font-size: 11px; " type="text" matInput placeholder="" formControlName="documento" [matAutocomplete]="autoGroup2">
                                        <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                                <mat-icon>search</mat-icon>
                                                                </span>
                                        <mat-autocomplete #autoGroup2="matAutocomplete">
                                            <mat-optgroup style=" font-size: 12px; " *ngFor="let group of documentos | async" [label]="group.letter">
                                                <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                                    {{i}}
                                                </mat-option>

                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'crear_app'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%;">Nueva aplicación</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label >Empresa</mat-label>
                                        <mat-select formControlName="cod_usuario" autocomplete="off">
                                            <mat-option *ngFor="let i of usuarios" [value]="i.cod_usuario">{{i.cod_usuario | uppercase}}  {{i.nom_usuario | uppercase}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label style="color: #000; font-size: 12px;">Nombre de la aplicación</mat-label>
                                        <input style="font-size: 12px; color: #000; "  type="text " matInput  formControlName="des_app" #mod_factur autocomplete="off" >
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label style="color: #000; font-size: 12px;">Comentarios</mat-label>
                                        <input style="font-size: 12px; color: #000; "  type="text " matInput  formControlName="comentarios" #mod_factur autocomplete="off" >
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="" *ngIf="msg === 'crear_estructura'">
                    <table class="col-md-12 col-sm-12 col-xs-12" style="margin: 0px; padding: 0px; height: 100%;">
                        <thead style="margin: 0px; padding: 0px; height: 100%;">
                            <th style="margin: 0px; padding: 0px; height: 100%;">Nueva estructura</th>
                        </thead>
                        <br>
                        <tbody style="margin: 0px; padding: 0px; height: 100%;">
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <div class="col-auto py-1">
                                            <mat-form-field class="materialinput">
                                                <mat-select style="font-size: 12px; color: black;" (selectionChange)="listarUnidad($event)" placeholder="Gerencias" formControlName="gerencia" autocomplete="off">
                                                  <mat-option  *ngFor="let i of gerencia" [value]="i">
                                                    {{i.des_unidad}}
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                    </div>
                                </td>
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <div class="col-auto py-1">
                                        <mat-form-field class="materialinput">
                                            <mat-select style="font-size: 12px; color: black;" (selectionChange)="listarUnidad($event)" placeholder="Coordinación" formControlName="dpto" autocomplete="off">
                                              <mat-option  *ngFor="let i of dpto" [value]="i">
                                                {{i.des_unidad}}
                                              </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </td>
                            </tr>
                            <tr style="margin: 0px; padding: 0px; height: 100%;">
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <div class="col-auto py-1">
                                        <mat-form-field class="materialinput">
                                            <mat-select style="font-size: 12px; color: black;" (selectionChange)="cargarEstatus()"  placeholder="Cargos" formControlName="des_cargo" autocomplete="off">
                                              <mat-option  *ngFor="let i of cargo" [value]="i">
                                                {{i}}
                                              </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </td>
                                <td style="margin: 0px; padding: 0px; height: 100%;">
                                    <div class="col-auto py-1">
                                            <mat-form-field class="materialinput">
                                                <mat-select style="font-size: 12px; color: black;" placeholder="Estatus" formControlName="estatus" autocomplete="off">
                                                  <mat-option  *ngFor="let i of estatus" [value]="i.cod_estatus">
                                                    {{i.des_estatus}}
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group" *ngIf="msg === 'nota_credito_devolucion'">
                    <label for="cod_nota_credito">Codigo de nota de credito</label>
                    <input id="cod_nota_credito" placeholder="Ejemplo: 00000000" class="form-control" formControlName="cod_nota_credito" autocomplete="off">
                    <label for="cod_solicitud_devolucion">Codigo devolucion</label>
                    <input id="cod_solicitud_devolucion" class="form-control" formControlName="cod_solicitud_devolucion" autocomplete="off" readonly>
                    <label for="cod_documento">Codigo Documento</label>
                    <input id="cod_documento" class="form-control" formControlName="cod_documento" autocomplete="off" readonly>
                </div>
                <div class="form-group" *ngIf="msg === 'dscnt'">
                    <label for="descuento">Porcentaje descuento</label>
                    <input type="text" #descuento_ (keyup)="format(descuento_)" class="form-control" formControlName="descuento" autocomplete="off">
                </div>
                <div class="form-group" *ngIf="msg === 'OPVNTS'">
                    <label for="descuento">Monto</label>
                    <!-- <input type="text" #monto (keyup)="format(monto)" class="form-control" formControlName="monto" autocomplete="off"> -->
                    <input (keyup)="format($event,'monto','xx')" formControlName="monto"  id="monto" maxlength="12" placeholder="0,00"    type="text" class="text-right form-control font-weight-bold mr-1 p-1 w-100" >
                </div>
                <div class="form-group" *ngIf="msg === 'LTS'">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label>Productos</mat-label>
                        <input style="font-size: 11px; " type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup" #cod_menu>
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>search</mat-icon>
                                                </span>
                        <mat-autocomplete #autoGroup="matAutocomplete">
                            <mat-optgroup style="font-size: 12px; " *ngFor="let group of deposito | async" [label]="group.letter">
                                <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                    {{i}}
                                </mat-option>

                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                    <label for="cod_lote">Secuencia del lote</label>
                    <input type="text" class="form-control" placeholder="Ejemplo: 0000" formControlName="cod_lote" autocomplete="off">
                </div>
                <div class="form-group" *ngIf="msg === 'LTS_A'">
                    <label for="cod_lote">Cantidad del lote</label>
                    <input type="text" class="form-control" formControlName="cant_lote" autocomplete="off">
                </div>
                <div class="form-group" *ngIf="msg === 'EFBCK'">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="cod_lote">Actual</label>
                            <input type="text" class="form-control" formControlName="cant_actual" autocomplete="off" readonly>
                        </div>
                        <div class="col-md-6">
                            <label for="cod_lote">Cantidad</label>
                            <input type="text" class="form-control" formControlName="cant_producto" autocomplete="off">
                        </div>


                    </div>

                </div>

            </div>
            <div class="modal-footer bg-light" style="border-radius: 0 0 1rem 1rem;">
                <div class="form-group">
                    <button mat-button class=" btn-danger" (click)="cerrarmodal()" style=" margin-right: 5px;">Cancelar</button>

                    <button mat-button class=" btn-primary" *ngIf="accion == 'eliminar'" (click)="eliminarregistros('eliminar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'salir'" (click)="salir()">Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'eliminarmenu'" (click)="eliminarregistros('eliminar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'eliminarusuariorol'" (click)="eliminarregistros('eliminar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'eliminarunidad'" (click)="eliminarregistros('eliminar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'eliminarclientes'" (click)="eliminarregistros('eliminar')"> >Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'enviarpedido'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'enviarprecios'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'recalcular'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'enviarmp'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'enviarordenprod'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'formulacion'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'actualizarprov'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'enviarcliente'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'enviarProdPt'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'enviarmppp'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'formulacionmpp'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'enviarordenprodmpp'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'recalcularpedido'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'ordenC'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'ordenI'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'ordenE'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'facturar_pedido'" (click)="guardarregistros('facturar_pedido')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'facturar_nota'" (click)="guardarregistros('facturar_nota')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'nota_credito'" (click)="guardar_nota_credito('nota_credito')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'tomar_solicitud'" (click)="guardar_tomar_solicitud('tomar_solicitud')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'finalizar_solicitud'" (click)="guardar_finalizar_solicitud('finalizar_solicitud')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'actualizar_comentarios'" (click)="guardar_actualizar_comentarios('actualizar_comentarios')"> Aceptar</button>

                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'reinicio'" (click)="reinicio_estatus_orden('actualizar_comentarios')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'activa_formula'" (click)="activar_formula('actualizar_comentarios')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="!accion" (click)="continuar()"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'traslados'" (click)="guardarregistros('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'recalcular_tasa'" (click)="enviar_valor_tasa_base('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'crear_definicion_sistema'" (click)="crear_definicion_sistema('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'depositos_inventarios'" (click)="crear_deposito('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'crear_lista_precios'" (click)="crear_lista('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'crear_documentos'" (click)="crear_documentos('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'crear_app'" (click)="crear_aplicaciones('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'crear_estructura'" (click)="guardarEstructura('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'nota_credito_devolucion'" (click)="guardar_nota_credito_devolucion('nota_credito_devolucion')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'dev_nota'" (click)="consultarDevFactura('guardar')"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'anulacion_ventas'" (click)="confirmar_envio()"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'dscnt'" (click)="enviar_porcentaje()"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'OPVNTS'" (click)="enviar_porcentaje()"> Aceptar</button>
                    <button mat-button   class=" btn-primary" *ngIf="accion == 'custom'" (click)="continuar()"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'LTS'" (click)="enviar_data_lote()"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'LTS_A'" (click)="enviar_valor_lote()"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'EFBCK'" (click)="enviar_valor_producto()"> Aceptar</button>
                    <button [disabled]="forma.invalid" mat-button   class=" btn-primary" *ngIf="accion == 'reportes'" (click)="crear_reporte()"> Aceptar</button>
                </div>
            </div>
        </div>
    </form>
</div>