import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

@Component({
  selector: 'app-form-crear-tareas',
  templateUrl: './form-crear-tareas.component.html',
  styleUrls: ['./form-crear-tareas.component.css']
})
export class FormCrearTareasComponent implements OnInit {

  @ViewChild('tarea',{static:true}) tarea:ElementRef;

  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
forma:FormGroup;
accion:any;
dialogSpin:any;
unidad:any;
tareas:any;
listas:any = [];
almacen:any = [];
asociar:any = false;
actualizar:any = false;
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private snackBar: MatSnackBar, public dialog: MatDialog, fb:FormBuilder,
    public dialogRef: MatDialogRef<FormCrearTareasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.tareas = data.tareas;
    for (const i in this.data.listas) {
        this.listas.push(this.data.listas[i]);
    }
    for (const i in this.data.almacen) {
      this.almacen.push(this.data.almacen[i]);
  }
      this.forma = fb.group({
        'cod_solicitud':'',
        'des_solicitud':['',[Validators.required]],
        'cod_solicitud_padre':['',[Validators.required]],
        'des_solicitud_padre':'',
        'nivel_solicitud_padre':['',[Validators.required]],
        'estatus':['',[Validators.required]],
        'ord_solicitud':['',[Validators.required,Validators.maxLength(2)]]
      });
    }
  ngOnInit(): void {
  }
  modalrespuesta(){
        var accion2 = 'enviarpedido';
        var dialogRefp = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: {id:this.data.id_cliente,
                accion:accion2},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
          dialogRefp.afterClosed().subscribe(result => {
            if(result != 'cerrar'){
              switch (this.forma.value.cod_solicitud_padre) {
                case 'N/A':
                  this.forma.value.des_solicitud_padre = this.forma.value.cod_solicitud_padre;
                  this.dialogRef.close(this.forma.value);
                  break;
              
                default:
                  this.forma.value.des_solicitud_padre = this.forma.value.cod_solicitud_padre.des_solicitud;
                    this.forma.value.cod_solicitud_padre = this.forma.value.cod_solicitud_padre.cod_solicitud;
                    this.dialogRef.close(this.forma.value);
                  break;
              }
            }
          });
        }

  actualizardatos(res:any){
    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
    }else{

    console.log(this.forma.value);
    this.dialogRef.close(this.forma.value);
    }
  }

  nivel_tarea(xvalor:any){
    try {
      console.log(xvalor.value);
      if (xvalor.value === 'N/A') {
        this.actualizar = true;
    
      }else{
        this.actualizar = false;
      }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }


  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
        maxWidth: '100vw',
        height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRef.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
