import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { nkServices } from './componentes/shared/servicios/app.nkservicios.services';
import {MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {FormLoginComponent} from './componentes/shared/modal/form-login/form-login.component';
import { FormSpinnerComponent } from './componentes/shared/modal/form-spinner/form-spinner.component';
import { HashKeyServices } from './componentes/shared/servicios/app.hashkey.services';
import { FormVersionComponent } from './componentes/shared/modal/form-version/form-version.component';
import { environment } from 'src/environments/environment';
import { AppUtilService } from './componentes/shared/servicios/app.util.service';
import { AtributosService } from './componentes/shared/servicios/atributos.service';
import { ToastNotificationService } from './componentes/shared/servicios/toast-notification.service';
import { FormRecuperacionComponent } from './componentes/shared/modal/form-recuperacion/form-recuperacion.component';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit {

  idleState:any;
  timedOut = false;
  lastPing?: Date = null;
  title = 'VectorNerd';
  valor:any = false;
  cod_sesion:any;
  auth_sesion:any = false;
  value:any;
  dialogSpin:any;
  accion:any;
  valoroff:any;
  tiempo:any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  horizontalPosition1: MatSnackBarHorizontalPosition = 'center';
  verticalPosition1: MatSnackBarVerticalPosition = 'top';
  environment = environment
  constructor(
    private servicio:nkServices,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router:Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ){}
  reset() {
    this.idle.watch();
  }
 qwidle(){
  this.valoroff = localStorage.getItem('cod_usuario');
  if (this.valoroff) {
/////////////TIEMPO DE INACTIVIDAD/////////////////
  this.idle.setIdle(3600);
/////////////TIEMPO DE INACTIVIDAD/////////////////
/////////////TIEMPO DE PARA CANCELAR LA ACCION/////////////////
  this.idle.setTimeout(10);
/////////////TIEMPO DE PARA CANCELAR LA ACCION/////////////////
  this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

  this.idle.onIdleEnd.subscribe(() => {});
  this.idle.onTimeout.subscribe(() => {
    this.timedOut = true;
  });
  this.idle.onIdleStart.subscribe(() =>
  this.notifi.customToastNotification(this.atrb.MENSAJES_FIJOS.tiempo_espera));
  this.idle.onTimeoutWarning.subscribe((countdown) => {this.idleState = 'You will time out in ' + countdown + ' seconds!'
      if (countdown <= 1) {
        this.util.quitarLocalStorage();
        this.auth_sesion = false;
         window.location.reload();
      }
    }
    );
  this.keepalive.interval(15);
  this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
  this.reset();
  }else{
    this.idle.watch();
    this.idle.stop();
  }
 }
 async ngOnInit(){
    try {
      await this.util.abrirSpinner();
      if (this.isAuthenticated()) {
            await  this.servicio.consultar_sesionhttp(null).toPromise();
            this.auth_sesion = true;
          this.qwidle();
          await this.notifi.success('Bienvenid@: " '+await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido)+' "');
          this.router.navigate(['/inicio']);
      }else{
        await this.util.cerrarSpinner();
       this.router.navigate(['/']);
        this.qwidle();
        this.permisosUsuarios();
      }
    } catch (error) {
      console.log(error);
              await this.util.cerrarSpinner();
              await this.notifi.customToastNotification(error.error.message);
            await this.cerrarsesion();
    }
  }

 async permisosUsuarios(){
    try {
      var dialogRef = this.dialog.open(FormLoginComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {},
        disableClose: true,
        panelClass:'pedidos_modal_login'
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result.proceso.toUpperCase() !== await this.atrb.CERRAR.toUpperCase()){
          await this.util.cerrarSpinner();
          this.consultarusuario(result);
          /* this.qwidle(); */
        }else{
          await this.util.cerrarSpinner();
          this.recuperarPass();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async recuperarPass(){
    try {
      var dialogRef = this.dialog.open(FormRecuperacionComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {},
        disableClose: true,
        panelClass:'pedidos_modal_login'
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if(result.proceso.toUpperCase() !== await this.atrb.CERRAR.toUpperCase()){
          await this.util.cerrarSpinner();
          await this.permisosUsuarios();
        }else{
          await this.util.cerrarSpinner();
          await this.permisosUsuarios();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
 async consultarusuario(usuario:any){
    try {
      await this.util.abrirSpinner();
       const response = await  this.servicio.loginhttp(usuario).toPromise();
       console.log(response);
          await  this.util.agregarLocalStorage(response);
          /* this.title = localStorage.getItem(); */
         this.auth_sesion = true;
          await this.qwidle();
         /* this.router.navigate(['/inicio']); */
         await this.util.cerrarSpinner();
         window.location.reload();
    } catch (error) {
      console.log(error);
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
         await this.qwidle();
         window.location.reload();
    }
  }
  async cerrarsesion(){
    try {
      await this.util.abrirSpinner();
      this.auth_sesion = false;
      var datos_sesion = {cod_usuario:localStorage.getItem('cod_usuario'),
                          cod_sesion:localStorage.getItem('cod_sesion')}
     await this.servicio.cerrar_sesionhttp(datos_sesion).toPromise();
          await this.util.quitarLocalStorage();
  
            this.valoroff = false;
            this.qwidle();
            await this.util.cerrarSpinner();
           window.location.reload();
    } catch (error) {
      await this.util.quitarLocalStorage();
      this.qwidle();
      await this.util.cerrarSpinner();
      window.location.reload();
    }
  }

  public isAuthenticated(): boolean {
    this.tiempo = JSON.parse(localStorage.getItem('Tiempo'));
    return new Date().getTime() < this.tiempo;
  }
}
