import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormPtDevolucionesComponent } from '../form-pt-devoluciones/form-pt-devoluciones.component';
import { createViewChild } from '@angular/compiler/src/core';
import { FormDevolverProductoCompletoComponent } from '../form-devolver-producto-completo/form-devolver-producto-completo.component';

@Component({
  selector: 'app-form-cantidad-completa-devolucion',
  templateUrl: './form-cantidad-completa-devolucion.component.html',
  styleUrls: ['./form-cantidad-completa-devolucion.component.css']
})
export class FormCantidadCompletaDevolucionComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  forma:FormGroup;
  stateForm: FormGroup;
  
  accion:any;
  dialogSpin:any;
  dataSource1=new MatTableDataSource<any>();
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();
  estatusDev:any; 

  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  xobj:any;
  xobj2:any;
  xobj3:any=[];
  xobj4:any=[];
  xobj5:any=[];
  key_hash:any;

  prueba = true;

  isDisabled= false

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('dev_producto',{static:true})dev_producto:ElementRef;
 
  cant:any=false;

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCantidadCompletaDevolucionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      
      this.accion = data.accion;
      this.estatusDev = data.estatus
      console.log(data)

      this.forma = fb.group({
        'cod_documento':data.cod_documento,
        'cod_producto': data.cod_producto,
        'des_producto': data.des_producto,
        'cant_producto': data.cant_producto,
        'cod_solicitud_devolucion': data.cod_solicitud_devolucion,
        'dev_producto':'',
      });
      this.stateForm = this.formb.group({
        stateGroup:'',
      });              

    }

  ngOnInit(): void {
     if(this.estatusDev === 'A'){
      this.validador();
    }else{
      this.mostrarcantidadproductosdevueltos(this.data.cod_solicitud_devolucion);
    } 
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  validador(){

      this.accion = "nota_credito_devolucion";

      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {id:'',
        cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
        cod_documento:this.data.cod_documento,
        accion:this.accion},
        disableClose: true,
        panelClass:'pedidos_modal',
        backdropClass: "bdrop"
       });

       dialogRef.afterClosed().subscribe(result => {
        if(result !== 'cerrar'){
          this.mostrarcantidadproductosdevueltos(this.data.cod_solicitud_devolucion);
          
        }else{
          this.cerrarmodal();  
        }
      });
  }

  modalrespuesta(){

    console.log(this.ELEMENT_DATA2);

    if (this.ELEMENT_DATA.length != this.ELEMENT_DATA2.length) {
      this.snackBar.open('tiene que seleccionar todos los productos', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      }else{ 

            this.accion = "enviarcliente";

            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100%',
              data: {id:'',
              accion:this.accion
              },
              disableClose: true,
              panelClass:'pedidos_modal',
              backdropClass: "bdrop",
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result != 'cerrar' && result != 'salir'){
               
              this.dialogRefp.close(this.data);
              } 
            });
      }
  }

  mostrarcantidadproductosdevueltos(xvalor:any){
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width:'15%',
      height:'25%',
      data:{
          accion:this.accion},
      disableClose: true,
      panelClass:'spinner',
      backdropClass:"bdrop"
    });
    setTimeout( async() => {
      this.servicio.mostrarProductosDevueltoshttp(xvalor).subscribe(
        response =>{
          console.log(response, 'informacion 1')
          this.xobj3 = [];
          for (const i in response[0]){
            this.xobj3.push(response[0][i]);
          }
          if(response){
            this.dialogSpin.close();
            this.xobj2 = response;
            this.ELEMENT_DATA = [];
                for (const i in response) {
                  this.ELEMENT_DATA.push({           
                  cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,             
                  cod_documento:response[i].cod_documento,              
                  cod_producto:response[i].cod_producto,         
                  des_producto:response[i].des_producto,           
                  cant_producto:response[i].cant_producto,
                  dev_producto:'0',
                  validar_boton:this.prueba, 
                  accion:this.accion
                  });
                }
                this.displayedColumns = [
                    'cod_producto',
                    'des_producto',
                    'cant_producto',
                    'dev_producto',
                    'dev_producto_tomar_data', 
                 ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                this.dataSource.paginator = this.paginator;
          }
        }
      );
    },1000);
    return this.xobj2;
  } 
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accio:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

 cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_producto)
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.'));
       });
    return suma;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  tomar_data(xvalor:any,xvalor2:any,cantidad_prod_mp:any){
    try {
      if(xvalor){

          var dialogRef
                  dialogRef = this.dialog.open(FormDevolverProductoCompletoComponent, {
                    width:'100vw',
                    maxWidth:'100vw',
                    height:'100vw',
                    data: {des_producto:xvalor2.des_producto,
                            cod_documento:this.data.cod_documento,
                            cantidad_prod_mp: this.data.cantidad_productos,
                            cod_solicitud_devolucion:this.data.cod_solicitud_devolucion,
                            cant_producto:xvalor2.cant_producto,
                            tipo_producto_mp:xvalor2.tipo_producto_mp,
                            cod_prod_mp:xvalor2.cod_prod_mp, cod_producto:xvalor2.cod_producto,
                            des_prod_mp:xvalor2.des_prod_mp, unidad_prod_mp:xvalor2.unidad_prod_mp, dev_producto:xvalor2.dev_producto
                          },
                          disableClose:true,
                    panelClass:'pedidos_modal',
                    backdropClass:"bdrop"
                  });
                  dialogRef.afterClosed().subscribe(result => {

          for (const i in this.ELEMENT_DATA) {

            if (this.ELEMENT_DATA[i].cod_producto === result.cod_producto) {

              this.ELEMENT_DATA[i].dev_producto = result.dev_producto;

            }
          }

          this.ELEMENT_DATA2.push(result)

        })
      
      }else{

        for (const i in this.ELEMENT_DATA) {
          if (this.ELEMENT_DATA[i].cod_producto === xvalor2.cod_producto) {

              this.ELEMENT_DATA[i].dev_producto = '0';
          }
        }

        let filtrar = this.ELEMENT_DATA2.filter(producto => producto.cod_producto != xvalor2.cod_producto)

        this.ELEMENT_DATA2 = filtrar

        console.log(filtrar)

      }
    } catch (error) {
      console.log(error);
    }             
  }

  cantidad_dev() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].dev_producto)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.'));
         });
       return suma;
    } catch (error) {
       this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
