<div id="modal_carrito" style="background-color: #28A745;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container" >

        <form  id="carrito_p1" class="row justify-content-center mt-1 mb-0" style=" height: auto; background-color: #f1ede9; border: 2px solid #28A745;">
            <!-- <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#ca4aaf;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Cliente</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_cliente" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div> -->

                <div id="content_2" class=" mx-1 mat-elevation-z8 my-2 col-2  " style="border-radius: 1rem; background-color: #28A745">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Codigo solicitud compra</mat-label>
                         <input style="font-size: 12px; color: #fff;" matInput placeholder="Cliente:" [value]="data.cod_solicitud_compra" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field> 
                </div>

            
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-2" style="border-radius: 1rem; border: 2px solid #28A745; background-color: #28A745">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Chequeado por:</mat-label>
                        <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput [value]="NombreUsuario" #num autocomplete="off" readonly>
                        
                     </mat-form-field>
                </div>


            <!-- <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#1f39ce">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Unidad de manejo</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="unidad_manejo" #unidad_manejo autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(255, 94, 0);" >report_problem</mat-icon>
                </span>
                    </mat-form-field>
                </div>
            </div> -->


                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-4" style="border-radius: 1rem; border: 2px solid #28A745; background-color: #28A745">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255); font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" (keyup)="applyFilter($event)" autocomplete="off"  > 
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>


                <div style="padding-top: 6px;" >
      
                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" [disabled]="forma.invalid" *ngIf="validador" (click)="enviarAprobacion()" matTooltip="APROBAR" style=" color:rgb(255, 255, 255); background-color: #0076C7; border-radius: 1rem;height: 80%;">
                        <mat-icon >done_all</mat-icon>
                    </button>

                    <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" *ngIf="!validador" (click)="imprimirOrdenRequision()" matTooltip="IMPRIMIR" style=" color:rgb(255, 255, 255); background-color: #0076C7; border-radius: 1rem;height: 80%;">
                        <mat-icon >print</mat-icon>
                    </button>
      
      
                    <button class="btn btn-sm btn-danger mat-elevation-z8 px-3 mt-1 mr-1" style="border-radius: 1rem; height: 80%;" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>

                </div>


        </form>  

        <div id="carrito_p6" class="row mt-2 mat-elevation-z8" style=" background-color: #f1ede9; ">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                <table #table mat-table [dataSource]="dataSource" class=" text-center table-striped table-bordered table-condensed table-hover">
  
  
                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28A745;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                      </ng-container>
    
                      <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28A745;">
                            <h5><strong>Descripcion</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                      </ng-container>
    
                      <ng-container matColumnDef="cant_producto_soli">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28A745;">
                            <h5><strong>Cantidad Solicitada</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_soli| puntoporcoma}}</strong> </td>
                      </ng-container>
    
                      <ng-container matColumnDef="cant_aprobada">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28A745;">
                            <h5><strong>Cantidad Aprobada</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element"   [style.background-color]="element.cant_aprobada == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_aprobada | puntoporcoma | puntoporcoma}}</strong> </td>
                      </ng-container>
    
                      <ng-container matColumnDef="tipo_unidad_medida">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #28A745;">
                            <h5><strong>Unidad de medida</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                      </ng-container>


                    <ng-container matColumnDef="tomar_data_ingresada">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #28A745; padding: 5px; color: #fff;">
                            <h5><strong>Seleccionar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, $event)" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                <mat-icon>add_circle</mat-icon>
                            </button>
                            <button matTooltip="BORRAR" (click)="tomar_data(false, element, $event)" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                <mat-icon>done</mat-icon>
                            </button>

                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #28A745; color: #fff;"> </td>
                    </ng-container>
  
  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>

            </div>

        </div>
        <div class=" col-12 mt-1 p-1 mat-elevation-z8 d-flex justify-content-center align-items-center" style="background-color: white;border-radius: 1rem;">

                <div class="col-6 d-flex">
                    <p class="m-0  col-7">
                        Fecha de creacion: {{data.fecha}} <br>
                        Estimacion de entrega: {{data.fecha_estimada}}<br>
                        Responsable: {{data.des_usuario}}
                          
                    </p>
                    <p class="m-0 col-5">
                        Deparatamento: <span style="text-transform: uppercase;">{{data.departamento}}</span>  <br>
                        Prioridad: {{data.prioridad}} <br>
                        Sede: {{data.tipo_solicitud_compra}}  
                    </p>
                </div>
                


            <div class="col-6 " [formGroup]="forma">
                <textarea formControlName="observaciones_aprobacion" name="" id="" class="w-100 mat-elevation-z8 " [readonly]="!validador"  cols="30" rows="3" placeholder="Observaciones(Requerido)" style="resize: none; border-radius: 1rem; padding: .5rem; border: 2px solid #28A745;"></textarea>
            </div>
               
        </div>
</div>


