import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

@Component({
  selector: 'app-form-roles',
  templateUrl: './form-roles.component.html',
  styleUrls: ['./form-roles.component.css']
})
export class FormRolesComponent implements OnInit {

  nombre:any;
forma:FormGroup;
accion:any;
unidad:any;
menu:any;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());

  constructor( 
    private fb:FormBuilder,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private notifi:ToastNotificationService,
    public dialogRef: MatDialogRef<FormRolesComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data.des_menu_rol, 'primero');
    this.accion = data.accion;
    this.unidad = data.unidad;
    this.menu = data.codmenu;
      this.forma = fb.group({
        'cod_rol':data.cod_rol,
        'des_rol':data.des_rol,
        'tipo_rol':data.tipo_rol,
        'cod_unidad':data.cod_unidad,
        'des_unidad':data.des_unidad,
        'estatus_rol':data.estatus_rol,
      });
    }
  ngOnInit(){}
  
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
    }else{
    console.log(this.forma.value);
    this.dialogRef.close(this.forma.value);
    }
    
  }

  async cerrarmodal(){
    try {
      let accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: {id:'',
              accion:accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRef.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
    }
    }

}
