import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodComponent } from 'src/app/componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormRevisarOrdenesMppComponent } from 'src/app/componentes/shared/modal/form-revisar-ordenes-mpp/form-revisar-ordenes-mpp.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-ordenes-prod-mpp-revision',
  templateUrl: './ordenes-prod-mpp-revision.component.html',
  styleUrls: ['./ordenes-prod-mpp-revision.component.css']
})
export class OrdenesProdMppRevisionComponent implements OnInit {

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );

  }

  ngOnInit(): void {
    this.listarordenesprodmpp(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////
  listarordenesprodmpp(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        var valor = {termino:'R'}
        this.servicio.listarOrdenesProdMpphttp(valor).subscribe(
          response =>{
            //////////////
            this.dialogSpin.close();//UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3);
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {

                    this.ELEMENT_DATA.push({
                      cod_orden_producto:response[i].cod_orden_producto,
                      cod_orden_producto_mpp:response[i].cod_orden_producto_mpp,
                      cod_usuario:response[i].cod_usuario,
                      des_usuario: response[i].des_usuario,
                      estatus:response[i].estatus,
                      fecha_inicio:response[i].fecha_inicio,
                      hora_inicio:response[i].hora_inicio,
                      cod_usuario_cierre: response[i].cod_usuario_cierre,
                      des_usuario_cierre:response[i].des_usuario_cierre,
                      fecha_fin:response[i].fecha_fin,
                      hora_fin:response[i].hora_fin,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = ['cod_orden_producto',
                                            'cod_orden_producto_mpp',
                                            'cod_usuario',
                                            'des_usuario',
                                            'estatus',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'revisar'
                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = ['cod_orden_producto',
                                            'cod_orden_producto_mpp',
                                            'cod_usuario',
                                            'des_usuario',
                                            'estatus',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'revisar'
                                          ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }
  //////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////
 async RevisarOrdenProdMpp(xvalor:any){
   try {
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
      response =>{
        if (response.resul) {
          var dialogRef = this.dialog.open(FormRevisarOrdenesMppComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'100%',
              data: {cod_usuario:xvalor.cod_usuario,
                des_usuario:xvalor.des_usuario,
                cod_orden_producto_mpp: xvalor.cod_orden_producto_mpp,
                cod_orden_producto: xvalor.cod_orden_producto,
                estatus:xvalor.estatus},
                disableClose: true,
                panelClass: 'pedidos_modal3',
                backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result != 'cerrar'){
                console.log(result);
                  this.ajustardatosOredenProd(result);
              }
            });
        }else{
          this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    ); 
     
   } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
   }
}

ajustardatosOredenProd(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      console.log(xvalor);
      var resultado = this.servicio.ajustardatosOredenProdhttp(xvalor).subscribe(
        response =>{
          this.dialogSpin.close();
          console.log(response,'una respuesta');
          this.listarordenesprodmpp(null);
        },
        error =>{
          this.dialogSpin.close();
          console.log(<any>error);
          this.displayedColumns = [ 'cod_orden_producto',
                                           'cod_usuario',
                                           'des_usuario',
                                           'estatus',
                                           'fecha_inicio',
                                           'hora_inicio',
                                           'revisar'
                                         ];
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    }, 1000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
 //////////////////////////FUNCIONES DE ACCION//////////////////////////////////////////

 ///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////

 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
sin_permiso():void{
  try {
    this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
} 

}

