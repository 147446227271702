import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { forIn, parseInt } from 'lodash';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-transferir-partidas',
  templateUrl: './form-transferir-partidas.component.html',
  styleUrls: ['./form-transferir-partidas.component.css']
})
export class FormTransferirPartidasComponent implements OnInit {
  @ViewChild('table') table:MatTable<any>;
  @ViewChild('unidad_m',{static:true}) unidad_m:ElementRef;
  @ViewChild('manejo_salida',{static:true}) manejo_salida:ElementRef;
  @ViewChild('manejo_entrada',{static:true}) manejo_entrada:ElementRef;
  @ViewChild('producto_pedido',{static:true}) producto_pedido:ElementRef;
  @ViewChild('cant_lote',{static:true}) cant_lote:ElementRef;
  @ViewChild('total_lote',{static:true}) total_lote:ElementRef;
  @ViewChild('cant_um',{static:true}) cant_um:ElementRef;
  @ViewChild('cod_lote',{static:true}) cod_lote:ElementRef;
  @ViewChild('cantidad',{static:true}) cantidad:ElementRef;

  @ViewChild('cant_salida',{static:true}) cant_salida:ElementRef;
  @ViewChild('cant_entrada',{static:true}) cant_entrada:ElementRef;

  @ViewChild('comentarios',{static:true}) comentarios:ElementRef;

  @ViewChild('partida_salida',{static:true}) partida_salida:ElementRef;
  @ViewChild('partida_entrada',{static:true}) partida_entrada:ElementRef;

  emailRegex:any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  telRegex:any = /\d{4}-\d{6}\d/;
  Regex:any = /^[J,V]{1}\d{9}/;
  hide:any = true;
nombre:any;
stateGroupOptions: Observable<StateGroup[]>;
stateGroupOptions2: Observable<StateGroup[]>;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
forma:FormGroup;
accion:any;
unidad_manejo:any;
llave_1:any;
llave_2:any;
dialogSpin:any;
unidad:any;
listas:any = [];
tipo_cliente = [];
bandera_:any = true;
bandera:any = false;
bandera2:any = true;
alerta:any;
producto:any = [];
codigo:any = [];
producto2:any = [];
codigo2:any = [];
deposito_pt = [];
almacen:any = [];
productos:any = [];
tipo_um_und = [];
asociar:any = false;
actualizar:any = false;
displayedColumns:string[];
ELEMENT_DATA:any[]=[];
total_valor:any;
total_items:any;
   dataSource=new MatTableDataSource<any>();
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices,public router:Router, private snackBar: MatSnackBar, public dialog: MatDialog, fb:FormBuilder,
    public dialogRef: MatDialogRef<FormTransferirPartidasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

        for (const i in this.data.des_centro) {
          if (this.data.des_centro[i].estatus === 'A') {
            this.almacen.push(this.data.des_centro[i]);
          } 
      }
      
      this.forma = fb.group({
        'partida_salida':['',[Validators.required]],
        'partida_entrada':['',[Validators.required]],
        'cant_producto':'0',
        'comentario':['',[Validators.required]],
        'cant_salida':'0',
        'cant_entrada':'0',
        'cantidad': '0',
        'cod_usuario':localStorage.getItem('cod_usuario'),
        'des_usuario':localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido'),
      });

      this.cantidad_item();
      this.cantidad_productos();

    }
  ngOnInit(): void {
    this.formulario();
  }
  
  formulario():void{
    this.ELEMENT_DATA = [];

    this.displayedColumns = [
      'partidas',
      'cantidad',
      'remov_elem'
    ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }
  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups2
        .map(group2 => ({letter: group2.letter, names: filter(group2.names, value), code: filter(group2.code, value)}))
        .filter(group2 => group2.names.length > 0 || group2.code.length > 0 );
    }
    return this.stateGroups2;
  }
  cantidad_item() {
    try {
      this.total_items = this.ELEMENT_DATA.length;
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }

  cantidad_productos() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].cant_lote)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
         this.total_valor = suma;
       return suma;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
    }

        buscarValoresCuentas(xvalor:any):void{
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          this.dialogSpin.close();
          var partidas = {cod_centro: this.data.cod_centro,
                            cod_cuenta_gasto_1:this.forma.value.partida_salida, 
                            cod_cuenta_gasto_2:this.forma.value.partida_entrada,
                          }
          this.servicio.listarValorPartidashttp(partidas).subscribe(
            response =>{
              this.alerta = '';
              this.bandera2 = true;
              this.dialogSpin.close();
                console.log(response,'valores');
                this.cant_salida.nativeElement.value = response.partida_salida/* .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace(',', '.'); */
                this.cant_entrada.nativeElement.value = response.partida_entrada/* .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace(',', '.'); */
                console.log(this.cant_salida.nativeElement.value,'valor1')
                console.log(this.cant_entrada.nativeElement.value,'valor2')


                this.ELEMENT_DATA  = [];
                this.dataSource.data = [];
                this.total_valor = '0';
                this.total_items = '0';
                this.table.renderRows();

                this.producto2 = [];
                this.codigo2 = [];

            },
            error =>{
              this.dialogSpin.close();
              console.log(<any>error.error.message,'error definiciones');
              this.alerta = <any>error.error.message;
              this.bandera2 = false;
              this.producto2 = [];
              this.codigo2 = [];

              this.ELEMENT_DATA  = [];
              this.dataSource.data = [];
              this.total_valor = '0';
              this.total_items = '0';
              this.table.renderRows();
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );

        }, 500);
      } catch (error) {
        this.dialogSpin.close();
        console.log(error);
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

    addPartidas(){
      try {
        let arry = [];
                                                                         
            if (this.ELEMENT_DATA.length === 0) {

              console.log(this.forma.value.partida_entrada,'this.forma.value.partida_entrada')
              console.log(this.cantidad.nativeElement.value,'this.cantidad.nativeElement.value')

              
              this.ELEMENT_DATA.push({
                cod_lote: this.forma.value.partida_entrada,
                cant_lote: this.cantidad.nativeElement.value
              });

              if(parseInt(this.cantidad.nativeElement.value) >= this.cant_salida.nativeElement.value){
                this.snackBar.open('Excedes la cantidad disponible en la partida: " '+this.forma.value.partida_salida+' "', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,})
                  this.ELEMENT_DATA = [];
              }
              if(this.forma.value.partida_salida === this.forma.value.partida_entrada){
                this.snackBar.open('No se puede seleccionar las misma partida como salida y entrada en la transferencia: " '+this.forma.value.partida_salida+' "', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,})
                  this.ELEMENT_DATA = [];
              }
                      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                    
                      this.cantidad.nativeElement.value = '';
                    
                      this.cantidad_item();
                      this.cantidad_productos();
                      console.log(this.forma,'this.forma')
            }else{
    
              console.log(this.ELEMENT_DATA);
                for (const i in this.ELEMENT_DATA) {
                 arry.push(this.ELEMENT_DATA[i].cod_lote);
                }
                var index = arry.includes(this.forma.value.partida_entrada);
    
                if (!index) {
                  if (this.ELEMENT_DATA.length <= 1) {
  
                  this.ELEMENT_DATA.push({
                    cod_lote: this.forma.value.partida_entrada,
                    cant_lote: this.cantidad.nativeElement.value
                  });
                      
                        this.cantidad.nativeElement.value = '';
  
                        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  
                        this.cantidad_item();
                        this.cantidad_productos();
    
                  }else{
                    this.snackBar.open('Solo puede asignar 1 partida', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                  }
  
                }else{
    
                  if (index) {
                    this.snackBar.open('La partida ya fue seleccionada', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                      
                      this.cantidad.nativeElement.value = '';
                  }
                }
            }

            if (this.forma.value.partida_entrada  === "") {
              this.snackBar.open('Debes seleccionar al menos una partida', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
            if (parseInt(this.cantidad.nativeElement.value) > parseInt(this.cant_salida.nativeElement.value) && parseInt(this.cant_salida.nativeElement.value) > 0) {
              this.snackBar.open('Excedes la cantidad disponible en la partida: " '+this.forma.value.partida_salida+' "', 'Cerrar',{duration:6000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
  
      } catch (error) {
        console.log(error);
      }
    }

    calculos_cant_lote(valor:any):void{
      try {
        if ( isNaN(this.cantidad.nativeElement.value) || this.cantidad.nativeElement.value === '' || parseInt(this.cantidad.nativeElement.value) <= 0) {
          this.snackBar.open('El campo necesita una cantidad a procesar y no se debe colocar letras', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
            this.cantidad.nativeElement.value = '';
        }else{
          
          if (isNaN(valor.value) || valor.value === '') {
            this.cantidad.nativeElement.value = '0';
         }
        }
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

    eliminarItems(val:any){
      this.dataSource.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
       this.table.renderRows();
       this.cantidad_item();
       this.cantidad_productos();
     }

     modalrespuesta(){
      try {
        if (this.ELEMENT_DATA.length > 0 && this.comentarios.nativeElement.value !== '') {
           if (this.forma.value.partida_salida.toUpperCase() !== this.forma.value.partida_entrada.toUpperCase()) {

            if (parseFloat(this.ELEMENT_DATA[0].cant_lote.replace('.','')
                                                          .replace('.','')
                                                          .replace('.','')
                                                          .replace(',','.')) <= parseFloat(this.cant_salida.nativeElement.value.replace('.','')
                                                          .replace('.','')
                                                          .replace('.','')
                                                          .replace(',','.'))) {
              var accion2 = 'enviarcliente';
              var dialogRefp = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                data: {id:this.data.id_cliente,
                      accion:accion2},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              });
                dialogRefp.afterClosed().subscribe(result => {
                  if(result != 'cerrar'){
                    
                    this.forma.value.cod_centro = this.data.cod_centro;

                    this.forma.value.cant_entrada = this.cant_entrada.nativeElement.value;
                    this.forma.value.cant_salida = this.cant_salida.nativeElement.value;
                    this.forma.value.cant_partida_salida = this.ELEMENT_DATA[0].cant_lote;
  
                    this.forma.value.tipo_operacion = 'transferencia';
                    this.forma.value.tipo_operacion = 'transferencia';
  
                    this.forma.value.partida_entrada = this.forma.value.partida_entrada;
                    this.forma.value.partida_salida = this.forma.value.partida_salida;
  
                    console.log(this.forma.value,'this.forma.value');
                    this.procesar_transferencia(this.forma.value);
                  }
                });
             }else{
              this.snackBar.open('La cantidad solicitada no puede ser mayor a la cantidad de la partida de salida', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
             }
           }else{
            this.snackBar.open('No es posible usar la misma partida en la salida como en la entrada para el proceso de transferencia', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
           }
          
        }else{
            if (this.ELEMENT_DATA.length <= 0) {
              this.snackBar.open('Debes selecionar por lo menos una partida para la transferencia', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
  
            if (this.forma.value.partida_salida !== '' || this.forma.value.partida_entrada !== '' || this.comentarios.nativeElement.value !== '') {
              this.snackBar.open('Hay campos vacios', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
  
          this.snackBar.open('No debe dejar campos vacios, ni montos en 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
       
      } catch (error) {
        console.log(error);
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
          }

          procesar_transferencia(xvalor:any):void{
        try {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          setTimeout(() => {
            this.dialogSpin.close();
            this.servicio.procesarTransferenciaPartidashttp(xvalor).subscribe(
              response =>{
                this.dialogSpin.close();
                  console.log(response,'valores');
                  this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
                    this.dialogRef.close('enviar');

              },
              error =>{
                this.dialogSpin.close();
                console.log(<any>error.error.message,'error definiciones');
              }
            );
            
          }, 500);
        } catch (error) {
          this.dialogSpin.close();
          this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      }

    cerrarmodal(): void {
      this.accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
                maxWidth: '100vw',
                height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar') {
          this.cerrar();
        }
      });
    }

    cerrar():void{
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          this.dialogSpin.close();
          this.dialogRef.close('cerrar');
        }, 500);
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

    ponerPuntosyComas(event:any){
      let validacion = '1234567890'
      console.log(event.key)
      let inputCantidad = document.querySelector('#cantidad') as HTMLInputElement
      if(inputCantidad.value.length == 1){
        let noa = inputCantidad.value.split('')
        noa.unshift('0')
        noa.unshift('0')
        inputCantidad.value = noa.join('')
      }else if(inputCantidad.value.length <= 2){
        let noa = inputCantidad.value.split('')
        noa.unshift('0')
        inputCantidad.value = noa.join('')
      }else if(inputCantidad.value.length >= 4){
        let noa:any = inputCantidad.value.split('')
        for(let i = 0 ; i < 2; i++){
          if(noa[0] == 0){
            noa.shift()
          }else{
            break;
          }
        }
        inputCantidad.value = noa.join('')
      }
      var num = inputCantidad.value.replace(/\D/g,'');
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
      num = num.split('').reverse().join('').replace(/\D/g, "")
      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
      inputCantidad.value = num;
      if(validacion.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
        this.snackBar.open('Solo numeros', 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
    
}

