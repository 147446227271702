<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;"> 
  <!--///////////////////////////ALERTA CODE////////////////////////////-->
  <div class="alert alertSuccess alert-success alert-dismissible fade d-none justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10000; position: absolute;" role="alert">
   <span style="font-size: 20px;"><mat-icon >assignment_turned_in</mat-icon></span><strong class="mr-1">Listo!</strong> Operacion Exitosa.
   <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
     <span aria-hidden="true">&times;</span>
   </button> -->
 </div>
 <!--///////////////////////////ALERTA CODE////////////////////////////-->
     <div class="row " style="height: 8%; ">
       <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-image: linear-gradient(to left top, #90b9dc, #70a3d3, #518cca, #3276bf, #045fb4); padding: 5px 7px 5px 7px; margin: 0px;">
         <span class="navbar-brand d-flex align-items-center" style=" font-size: 14px; color: rgb(255, 255, 255); " > <mat-icon>local_shipping</mat-icon> <b style="text-decoration: underline;" class="ml-2">RECEPCION</b></span>
         <div class=" navbar-collapse" id="navbarSupportedContent">
             <ul class="navbar-nav mr-auto">
               <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarSolicitudesCompra("actualizar")'><mat-icon >sync</mat-icon></button></li>
               <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
               <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
               <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
               <li *ngIf="read" class="nav-item"><button matTooltip="RECEPCIONES FINALIZADAS" class="my-2 my-sm-0 mr-sm-2 bg-info text-light" mat-raised-button style="height: 35px;" (click)='verRecepcionesCompletas()'><mat-icon >alarm_on</mat-icon></button></li>
 
              <!--  <li *ngIf="create" class="nav-item"><button matTooltip="CREAR " class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='' ><mat-icon >add</mat-icon></button></li> -->
               
               
           </ul>
             <div class="">
               <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
           </div>
         </div>
       </nav>
     </div>
   
     <div class="row " style="height: 78.7%;">
       
         <div id="htmlData" class="col-md-12 px-0  border h-100 " style="background-color: steelblue; overflow-y: auto;">
            
               <table id="htmlData" #htmlData mat-table [dataSource]="dataSource" class=" text-center table-striped table-bordered table-condensed table-hover">
                 <!-- Weight Column -->
                 <ng-container matColumnDef="cod_orden_compra">
                   <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                       <h5><strong>Codigo de Orden de Compra</strong></h5>
                   </th>
                   <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_orden_compra}} </td>
                 </ng-container>
 
                 <ng-container matColumnDef="cod_proveedor">
                   <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                       <h5><strong>RIF/CI</strong></h5>
                   </th>
                   <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_proveedor}} </td>
                 </ng-container>
 
 
                 <ng-container matColumnDef="des_proveedor">
                   <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                       <h5><strong>Proveedor</strong></h5>
                   </th>
                   <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_proveedor}}</strong> </td>
                 </ng-container>
 
 
                 <ng-container matColumnDef="estatus">
                   <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                       <h5><strong>Estatus</strong></h5>
                   </th>
                   <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;" [ngSwitch]="element.estatus">
                     
                     
                       <div *ngSwitchCase="'P'" style="background-color: var(--warning); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Esperando Por Recepcion</strong></div> 
                       <div *ngSwitchCase="'C'" style="background-color: var(--success); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Recepcion completa</strong></div> 
                       <strong *ngSwitchDefault >{{element.estatus}}</strong> 
                       
                   </td>
                     
                 </ng-container>
 
 
                 <ng-container matColumnDef="fecha">
                   <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                       <h5><strong>Fecha</strong></h5>
                   </th>
                   <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.fecha}}</strong> </td>
                 </ng-container>
 
 
                 <ng-container  matColumnDef="recepcion">
                   <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                       <h5><strong>Recepcion</strong></h5>
                   </th>
                 
                   <td mat-cell *matCellDef="let element"   [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="text-center" >
                       <button matTooltip="RECEPCIONAR" [disabled]="element.estatus != 'P'" (click)='recibir(element, "ver")' class="btn btn-secondary btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); border: 2px solid; background-color: #1D70A4;">
                           <mat-icon>input</mat-icon>
                       </button>
                       <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                           <mat-icon>edit</mat-icon>
                       </button> -->
                   </td>
                 </ng-container>
 
                 <ng-container  matColumnDef="ver">
                   <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                       <h5><strong>Ver</strong></h5>
                   </th>
                 
                   <td mat-cell *matCellDef="let element"   [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="text-center" >
                       <button matTooltip="MOSTRAR" *ngIf="element.cod_solicitud_compra != ''"  (click)='verRecepcion(element, "ver")' class="btn btn-secondary btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); border: 2px solid; background-color: #A15A99;">
                           <mat-icon>remove_red_eye</mat-icon>
                       </button>
                       <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                           <mat-icon>edit</mat-icon>
                       </button> -->
                   </td>
                 </ng-container>
 
                 <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                 <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
 
             </table>
   
               <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 
             
         </div>
       
     </div>
   
     
   </div>