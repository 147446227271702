<!-- <div id="modal_carrito" class=" container-fluid">

    <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid rgb(88, 42, 126); background-color: rgb(131, 70, 180); border-radius: 1rem 1rem 0 0; ">

        <div class="col-md-10 px-0 py-2 h-100">
            <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(88, 42, 126);">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                    <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)="listar_notas_credito('actualizar')" matTooltip="ACTUALIZAR LISTA" style="color:rgb(255, 255, 255); background-color: rgb(160, 34, 122);">
                      <mat-icon >sync</mat-icon>
                    </button>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
            </div>
        </div>
    </div>
    <div id="carrito_p2" class="row" style=" border: 2px solid rgb(88, 42, 126); border-radius:  0 0 1rem 1rem">
        <div class="col-md-12 px-0  border h-100" style="background-color: rgb(131, 70, 180); border-radius:  0 0 1rem 1rem">
            <table mat-table [dataSource]="dataSource" class="table-danger text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_factura">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Factura</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_factura}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_nota_credito">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Nota credito</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_nota_credito}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_lista_precio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Lista precios</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_lista_precio}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Cod cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="tipo_nota">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Tipo nota</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.tipo_nota}} </td>

                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.des_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Cod usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_usuario}} </td>

                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.des_usuario}} </td>

                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.estatus}} </td>

                </ng-container>

                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Fecha inicio</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.fecha_inicio}} </td>

                </ng-container>

                <ng-container matColumnDef="hora">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Hora inicio</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.hora_inicio}} </td>

                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="imprimir_descargar(element,'imprimir')">
                        <button class="rounded-pill" matTooltip="IMPRIMIR NOTA DE CREDITO" mat-raised-button style="border: outset 3px rgb(74, 134, 245); height: 35px; color:rgb(255, 255, 255); background-color: rgb(42, 115, 250);">
                            <mat-icon >print</mat-icon>
                            </button>
                    </td>

                </ng-container>

                <ng-container matColumnDef="descargar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(131, 70, 180);">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="en_construccion()">
                        <button class="rounded-pill" matTooltip="DESCARGAR NOTA DE CREDITO" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(158, 0, 250);">
                              <mat-icon >get_app</mat-icon>
                              </button>
                    </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>

</div> -->

<div id="modal_carrito" class=" container-fluid" style="border-radius: 1rem;">

    <div id="carrito_p1" class="row mat-elevation-z8" style="border-radius: 1rem 1rem 0 0; border: 2px solid rgb(42, 73, 134); background-color: rgb(42, 73, 134); ">

        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)="listar_notas_credito('actualizar')" matTooltip="ACTUALIZAR" style="border-radius: 1rem; border: outset 3px rgb(173, 64, 144);  color:rgb(255, 255, 255); background-color: rgb(169, 35, 133);">
                        <mat-icon >sync</mat-icon>
                        </button>
            </div>
        </div>

        <div class="col-md-10 px-0 py-2 h-100">
            <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(42, 73, 134);">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                    <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                        </span>
                </mat-form-field>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" style="border-radius: 1rem; border: outset 3px rgb(182, 48, 31);" matTooltip="SALIR">
                            <mat-icon >exit_to_app</mat-icon>
                        </button>
            </div>
        </div>
    </div>
    <div id="carrito_p6" class="row" style="border-radius: 0 0 1rem 1rem; border: 2px solid rgb(42, 73, 134);">
        <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100" style="border-radius: 0 0 1rem 1rem; background-color: rgb(42, 73, 134);">
            <table #table mat-table [dataSource]="dataSource" class="table-dark text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_factura">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_factura}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_nota_credito">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Documento</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_nota_credito}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_lista_precio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Precio</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_lista_precio}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Rif</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.des_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.estatus}} </td>

                </ng-container>

                <ng-container matColumnDef="tipo_nota">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.tipo_nota}} </td>

                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.fecha_inicio}} </td>

                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.hora_inicio}} </td>

                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;" class="text-center" (click)="imprimirnota(element,'imprimir')">
                        <button matTooltip="IMPRIMIR" class="btn rounded-pill px-3" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 86, 247);">
                                <mat-icon >print</mat-icon>
                                </button>
                    </td>

                </ng-container>

                <ng-container matColumnDef="descargar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="imprimirbackorder(element)">
                        <button matTooltip="DESCARGAR" class="btn rounded-pill px-3" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(158, 0, 250);">
                                <mat-icon >get_app</mat-icon>
                                </button>
                    </td>

                </ng-container>

                <ng-container matColumnDef="detalles">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Pedido</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="update_insert_data(element)">
                        <button matTooltip="CONVERTIR EN PEDIDO" class="btn rounded-pill px-3 bg-info" style="height: 35px; color:rgb(255, 255, 255);">
                                <mat-icon >shopping_cart</mat-icon>
                                </button>
                    </td>

                </ng-container>

                <ng-container matColumnDef="enviar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Enviar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="en_construccion(element)">
                        <button matTooltip="ENVIAR" class="btn rounded-pill px-3 bg-success" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(158, 0, 250);">
                                <mat-icon >flight_takeoff</mat-icon>
                                </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>