import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-cant-dev',
  templateUrl: './form-cant-dev.component.html',
  styleUrls: ['./form-cant-dev.component.css']
})
export class FormCantDevComponent implements OnInit {
  @ViewChild('cant_dev',{static:true}) cant_dev:ElementRef;
  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;


  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;

accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCantDevComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
      this.producto = data.des_producto;
      this.codigo = data.cod_producto;
      this.forma = fb.group({
        'cod_dev':data.cod_dev,
        'cant_producto':data.cant_producto,
        'cant_dev':['',[Validators.required]]

      });

    }
    ngOnInit(): void {

    }
  devolucion_prod(xvalor:any){
    try {
        var xobj
        console.log(this.cant_dev.nativeElement.value);
        if (!isNaN(this.cant_dev.nativeElement.value)) {
          if (parseFloat(this.cant_dev.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.')) === parseFloat(this.cant_producto.nativeElement.value.replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace('.', '')
          .replace(',', '.'))) {
            xobj =  {cant_producto:xvalor,
              cod_producto:this.data.cod_producto,
              cod_dev:this.data.cod_dev}
            this.dialogRefp.close(xobj);
          }else{
            this.snackBar.open('Debe ingresar la cantidad total', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        }else{
          this.snackBar.open('Use solo numeros por favor.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2');
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
    cerrarmodal(): void {
      this.dialogRefp.close('cerrar');
    }

}
