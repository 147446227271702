import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { ConnectionServices } from '../../../../componentes/shared/servicios/app.connection.services';
import { FormMovimientosCuentasComponent } from '../form-movimientos-cuentas/form-movimientos-cuentas.component';
import { FormDescargarMontoPartidasComponent } from '../form-descargar-monto-partidas/form-descargar-monto-partidas.component';

@Component({
  selector: 'app-form-listar-cuentas-creadas',
  templateUrl: './form-listar-cuentas-creadas.component.html',
  styleUrls: ['./form-listar-cuentas-creadas.component.css']
})
export class FormListarCuentasCreadasComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_cent',{static:true}) cod_cent:ElementRef;
/*   @ViewChild('des_centro',{static:true}) des_centro:ElementRef; */
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  title = 'InternetTest';
  status = 'ONLINE';
  isConnected = true;

  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];

  value1 = '';
  nombre:any;
  forma:FormGroup;
  stateForm: FormGroup;
  accion:any;
  dialogSpin:any;
  unidad:any;
  unidadp:any = [];
  menu:any;
  xobj2:any;
  producto:any = [];
  codigo:any = [];
  lotepro:any = [];
  cant:any = false;
  lote:any = true;
  des:any;
  total_p:any;
  guard:any = false;
  email = new FormControl('', [Validators.required, Validators.email]);
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  dataSource=new MatTableDataSource<any>();
  serializedDate = new FormControl((new Date()).toISOString());
  ELEMENT_DATA:any[]=[];
    displayedColumns:string[];
     dataSource1=new MatTableDataSource<any>();
     horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
  

    constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
      public dialogRefp: MatDialogRef<FormListarCuentasCreadasComponent>,private conexion: ConnectionServices,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data, 'sera este');

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                cod_menu: localStorage.getItem('sub_main'),
                cod_sesion: localStorage.getItem('cod_sesion'),
                dir_ip_client: localStorage.getItem('dir_ip'),
              sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }

/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
      }

      ngOnInit(): void {
   
        this.cod_cent.nativeElement.value = this.data.cod_centro+' - '+this.data.des_unidad;
        this.listarCuentasCreadas(null);
      }

      @ViewChild(MatPaginator) paginator: MatPaginator;

      ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
      }

      listarCuentasCreadas(xvalor){
      try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = xvalor;
        var valor = {cod_centro:this.data.cod_centro}
         this.servicio.listarCuentasCreadashttp(valor).subscribe(
           response =>{
            this.dialogSpin.close();
             console.log(response,'detallesS');
               this.ELEMENT_DATA = [];
               this.total_p = 0;
                   for (const i in response) {
                    if(response[i].estatus == 'A'){

                      this.total_p += parseFloat(response[i].cant_cuenta_gasto.replace('.', '')
                                                                        .replace('.', '')
                                                                        .replace('.', '')
                                                                        .replace('.', '')
                                                                        .replace('.', '')
                                                                        .replace(',', '.'))
                    }

                     this.ELEMENT_DATA.push({
                       cod_centro:response[i].cod_centro,
                       des_centro:response[i].des_centro,
                       cod_presupuesto: response[i].cod_presupuesto,
                       cod_cuenta_gasto:response[i].cod_cuenta_gasto,
                       cant_cuenta_gasto:response[i].cant_cuenta_gasto,
                       des_cuenta_gasto:response[i].des_cuenta_gasto,
                       cod_usuario:response[i].cod_usuario,
                       des_usuario:response[i].des_usuario,
                       fecha_inicio:response[i].fecha_inicio,
                       estatus:response[i].estatus,
                     });
                   }
                   console.log(this.ELEMENT_DATA,'ELEMENT_DATA');
                    this.displayedColumns = [
                      'cod_cuenta_gasto',
                      'cod_presupuesto',
                      'des_cuenta_gasto',
                      'cant_cuenta_gasto',
                      'cod_usuario',
                      'des_usuario',
                      'fecha_inicio',
                      'estatus',
                      'movimientos',
                      'carga',
                      'descarga',
                      'anular'

                    ];
                   this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
                   this.dataSource1.paginator = this.paginator;
           },
           error =>{
            this.total_p = 0;
            this.dialogSpin.close();
             console.log(error);
             console.log(<any>error.error.message);
             this.displayedColumns = [
              'cod_cuenta_gasto',
              'cod_presupuesto',
              'des_cuenta_gasto',
              'cant_cuenta_gasto',
              'cod_usuario',
              'des_usuario',
              'fecha_inicio',
              'estatus',
              'movimientos',
              'carga',
              'descarga',
              'anular'
            ];

             this.dataSource1 = new MatTableDataSource<any>();
             this.dataSource1.paginator = this.paginator;
             this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition});
           }
         );

         if(msg == 'actualizar'){
           this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition});
         }
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  send_product(obj:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                cod_menu: localStorage.getItem('sub_main'),
                cod_sesion: localStorage.getItem('cod_sesion'),
                dir_ip_client: localStorage.getItem('dir_ip'),
              sesion_unica:sessionStorage.getItem('sesion_unica')};
  
  this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
  response =>{
  if (response.resul) {
  console.log(obj);
  var dialogRef:any;
  let datos = localStorage.getItem('cod_usuario');
  let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
  this.accion = 'TRNSFRNC';
    dialogRef = this.dialog.open(FormKeyComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {cod_centro: this.data.cod_centro,
              des_centro: this.ELEMENT_DATA,
              cod_cuenta_gasto:obj.cod_cuenta_gasto,
              des_cuenta_gasto:obj.des_cuenta_gasto,
              accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);
        this.listarCuentasCreadas('actualizar');
      }
    });
  }else{
  this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
  verticalPosition: this.verticalPosition,});
  }
  },
  error =>{
  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
  verticalPosition: this.verticalPosition,});
  }
  );
      
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
  }
  cargar_partidas(obj:any,termino:any){
    var dialogRef:any;
    this.accion = 'CRG';
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
      dialogRef = this.dialog.open(FormKeyComponent, {
        width: '100vw',
      maxWidth: '100vw',
      height:'100%',
        data: {cod_centro: this.data.cod_centro,
              des_centro: this.ELEMENT_DATA,
              cod_cuenta_gasto:obj.cod_cuenta_gasto,
              des_cuenta_gasto:obj.des_cuenta_gasto,
              objeto: obj,
              cant_cuenta_gasto:obj.cant_cuenta_gasto,
              cod_presupesto: obj.cod_presupuesto,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result !== 'cerrar'){
            console.log(result,'el result');
            this.listarCuentasCreadas('actualizar');
        }else{
          dialogRef.close('cerrar')
        }
      });
  }
  descargar_partidas(obj:any,termino:any){
    var dialogRef:any;
    this.accion = 'DSCRGP';
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
      dialogRef = this.dialog.open(FormKeyComponent, {
        width: '100vw',
      maxWidth: '100vw',
      height:'100%',
        data: {cod_centro: this.data.cod_centro,
              des_centro: this.ELEMENT_DATA,
              cod_cuenta_gasto:obj.cod_cuenta_gasto,
              des_cuenta_gasto:obj.des_cuenta_gasto,
              objeto: obj,
              cant_cuenta_gasto:obj.cant_cuenta_gasto,
              cod_presupesto: obj.cod_presupuesto,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result !== 'cerrar'){
            console.log(result,'el result');
            this.listarCuentasCreadas('actualizar');
        /* this.liberarProdTerminadoCuarentena(result); */
          
        }else{
          dialogRef.close('cerrar')
        }
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  anular_partida(obj:any,termino:any){
    try {
      var obj2 ={
        val_def: 'F',
        tipo_operacion: 'ANULACION',
        cod_usuario: localStorage.getItem('cod_usuario'),
      }
      console.log(obj,'obj');
      this.servicio.validar_acceso_acciones_http(obj2).subscribe(
        response=>{
          this.servicio.buscarPartidaAnuladahttp(obj).subscribe(
            response =>{
              this.snackBar.open('La partida " '+obj.des_cuenta_gasto +' "  ya fue anulada', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition});
            },
            error =>{
              var dialogRef:any;
              dialogRef = this.dialog.open(FormmMsgComponent, {
                width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
                data: {cod_dep:this.data.cod_dep,
                        productos:this.data.productos,
                        accion:'anulacion_ventas'},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop"
              }); 
              dialogRef.afterClosed().subscribe(result => {
                console.log(result,'result');
                if(result != 'cerrar'){

                  /* this.dialogRefp.close(result) */;
                  obj.comentarios = result.comentarios;
                  obj.cod_usuario = localStorage.getItem('cod_usuario');
                  obj.des_usuario = localStorage.getItem('Nombre') +' '+ localStorage.getItem('Apellido');
                  obj.des_centro = this.data.des_centro;
                  obj.tipo_operacion = 'ANULACION',
                  console.log(obj,'objgg');
                  this.anularPartida(obj);
                }
            });
            }
          );
        },
        error=>{
           this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition});
        }
      );
      
    } catch (error) {
      console.log(<any>error);
    }
  }

  anularPartida(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout( async() => {
        this.servicio.anularPartidahttp(xvalor).subscribe(
          response =>{
            this.dialogSpin.close();
            this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
              
            this.listarCuentasCreadas('actualizar');

          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
          
        );
      }, 1000);
      return this.xobj2;
    } catch (error) {
      console.log(<any>error);
    }
  }
  
  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  movimientos_cuentas(obj:any){
    var dialogRef:any;
    this.accion = 'INV';
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
      dialogRef = this.dialog.open(FormMovimientosCuentasComponent, {
        width: '100vw',
      maxWidth: '100vw',
      height:'100%',
        data: {cod_centro:obj.cod_centro,
              des_unidad: this.data.des_unidad,
              cod_cuenta_gasto:obj.cod_cuenta_gasto,
              des_cuenta_gasto:obj.des_cuenta_gasto,
              cod_presupuesto:obj.cod_presupuesto,
              objeto: obj,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
      });
  }

}
