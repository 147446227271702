import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Console } from 'console';
import { MatPaginator } from '@angular/material/paginator';
import { FormKeyComponent } from '../form-key/form-key.component';
import { FormHistorialParametrosVentasComponent } from '../form-historial-parametros-ventas/form-historial-parametros-ventas.component';
import { FormAccionesAccesosUsuariosComponent } from '../form-acciones-accesos-usuarios/form-acciones-accesos-usuarios.component';
import { FormAccesosCorreosUsuariosComponent } from '../form-accesos-correos-usuarios/form-accesos-correos-usuarios.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-parametros-correos',
  templateUrl: './form-parametros-correos.component.html',
  styleUrls: ['./form-parametros-correos.component.css']
})
export class FormParametrosCorreosComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('des_definicion',{static:true}) des_definicion:ElementRef;
  @ViewChild('operacion',{static:true}) operacion:ElementRef;
  @ViewChild('descrip_definicion',{static:true}) descrip_definicion:ElementRef;
  @ViewChild('var_defi',{static:true}) var_defi:ElementRef;

  producto:any = [];
  producto_2:any = [];
  dialogSpin:any;
  codigo:any = [];
  codigo_2:any = [];
  forma:FormGroup;
  accion:any;
  roles:any;
  stateForm: FormGroup;
  stateGroups: StateGroup[];
  stateGroups2: StateGroup[];
  arry: Observable<StateGroup[]>;
  arry2: Observable<StateGroup[]>;
  rol = [];
  unidad=[];
  unidadp=[];

  parametros_precios:any = [];
  parametros_operacion:any = [];
  parametros_montos:any = [];
  key_hash:any;
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  secuencia:any;
  menur3:any;
  unidades:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormParametrosCorreosComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

    console.log(data, 'primero');

    this.forma = fb.group({
      'cod_definicion': '',
      'des_definicion': '',
      'descrip_definicion':'',
      'cod_usuario':localStorage.getItem('cod_usuario'),
      'des_usuario':localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido'),
      'operacion':'',
      'tipo_monto':'',
      'val_def':'0,00'
    });
   }

ngOnInit(): void {
  this.listar_parametros_accesos_correos(null);
  this.listarDefiniciones(localStorage.getItem('sub_main'));
}
@ViewChild(MatPaginator) paginator: MatPaginator;

ngAfterViewInit() {
  this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    this.dataSource.paginator = this.paginator;
}
listarDefiniciones(objeto:any){
  try {
    var xvalor ={cod_menu:objeto};
      this.servicio.listarDefinicionesConceptoshttp(xvalor).subscribe(
        response =>{
          console.log(response);
          this.parametros_precios= [];
          this.parametros_operacion= [];
          for (const i in response) {
            if (response[i].des_definicion.toUpperCase() ===  'PARAMETROS CORREOS') {
              this.parametros_precios.push(response[i]);
            }
            console.log(this.parametros_precios);
            if (response[i].des_definicion.toUpperCase() === 'OPERACIONES CORREOS') {
              this.parametros_operacion.push(response[i]);
            }
          }
        },
        error =>{
          console.log(<any>error.error.message,'error definiciones');
        }
      );
      return null;
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:1000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
/////////////////////////////////////////////////////////////////////
listar_parametros_accesos_correos(msj:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {
      let msg = msj;
      this.ELEMENT_DATA = [];
      this.servicio.listarParametrosAccionesCorreoshttp().subscribe(
        response =>{
          console.log(response);
          this.dialogSpin.close();
          for (const i in response) {

            if (response[i].operacion.toUpperCase() === 'FCTRCN' && !this.accion) {
              response[i].operacion = 'FACTURACIÓN';
            }

            this.ELEMENT_DATA.push({
              cod_definicion:response[i].cod_definicion,
              des_definicion:response[i].des_definicion,
              descrip_definicion:response[i].descrip_definicion,
              cod_usuario:response[i].cod_usuario,
              des_usuario:response[i].des_usuario,
              estatus:response[i].estatus,
              operacion:response[i].operacion,
              val_def:response[i].val_def,
              fecha_inicio:response[i].fecha_inicio,
              hora_inicio:response[i].hora_inicio,
              accion:'',
              accion2:'',
              accion3:''
            });
          }
          if (!this.accion) {
            this.displayedColumns = [
              'cod_definicion',
              'des_definicion',
              'descrip_definicion',
              'val_def',
              'operacion',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'usuarios',
              'definiciones',
    
            ];
          }
          if (this.accion) {
            this.displayedColumns = [
              'cod_definicion',
              'des_definicion',
              'descrip_definicion',
              'val_def',
              'operacion',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'usuarios',
              'definiciones',
    
            ];
          }
    
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        },
        error =>{
          this.dialogSpin.close();
          console.log(<any>error.error);
          if (!this.accion) {
            this.displayedColumns = [
              'cod_definicion',
              'des_definicion',
              'descrip_definicion',
              'val_def',
              'operacion',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'definiciones',
    
            ];
          }
          if (this.accion) {
            this.displayedColumns = [
              'cod_definicion',
              'des_definicion',
              'descrip_definicion',
              'val_def',
              'operacion',
              'estatus',
              'fecha_inicio',
              'hora_inicio',
              'definiciones',
    
            ];
          }
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
        }
      );
     
      if(msg == 'actualizar'){
        this.dialogSpin.close();
        this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
      return null;
    }, 1000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
activar_desactivar_acciones(xvalor:any){
  try {
    this.key_hash = {};
   this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
     response =>{
    
            this.forma.value.cod_definicion = xvalor.cod_definicion;
            this.forma.value.des_definicion = xvalor.des_definicion;
            this.forma.value.descrip_definicion = xvalor.descrip_definicion;
            this.forma.value.val_def = xvalor.val_def;
            this.forma.value.estatus = xvalor.estatus;
            this.forma.value.modo = 'actualizar';
            console.log(xvalor);
            console.log(this.forma.value);
            this.gestionDefinicionesSistemaCorreos(this.forma.value);
       
     },
     error =>{
       this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   ); 
  } catch (error) {
   this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
     verticalPosition: this.verticalPosition,})
  }
}
modalrespuesta(){
  try {
    if ((document.getElementById("descrip_definicion") as HTMLInputElement).value !== '' &&
        (document.getElementById("des_definicion") as HTMLInputElement).value !== '' &&
        (document.getElementById("operacion") as HTMLInputElement).value !== '') {
     
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: { 
              accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop" 
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
         
          this.forma.value.objeto_definicion = this.ELEMENT_DATA;
          this.forma.value.modo = 'crear';
           this.gestionDefinicionesSistemaCorreos(this.forma.value);
         
        } 
      });
    }else{
      this.snackBar.open('Existen campos vacios', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
  } catch (error) {
    console.log(<any>error.error.message);
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
  }
}
gestionDefinicionesSistemaCorreos(xvalor:any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      var resultado = this.servicio.gestionDefinicionesSistemaOperCorreosAccesoshttp(xvalor).subscribe(
        response =>{
          this.dialogSpin.close();
          this.listar_parametros_accesos_correos(null);
         this.snackBar.open(response.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          
        },
        error =>{
          this.dialogSpin.close();
          console.log(<any>error.error.message);
          this.listar_parametros_accesos_correos(null);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
      
      return resultado;
    }, 500);
    
  } catch (error) {
    this.dialogSpin.close();
    console.log(error);
       this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
  }
 }
cerrarmodal(): void {
  var accion_ = 'salir'
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
    data: {id:'',
          accion:accion_},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
}
cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 500);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}
parametros_correos_usuarios(text:any){
    
  try {
    this.key_hash = {};
                  this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                    response =>{
                      if (response.resul) {
                       
                          var accion = text;
                          var dialogRef = this.dialog.open(FormAccesosCorreosUsuariosComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                            data: {id:'',
                                  cod_definicion:text.cod_definicion,
                                  accion:accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                          dialogRef.afterClosed().subscribe(result => {
                            if(result !== 'cerrar'){
                            }
                          });
                      }else{
                        this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    },
                    error =>{
                      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,});
                    }
                  );
  } catch (error) {
    this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

}
