import { Component, ElementRef, OnInit } from '@angular/core';
import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import { Routes, RouterModule, Router } from '@angular/router';

import { Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

import { FormInventarioMpComponent } from '../../../../shared/modal/form-inventario-mp/form-inventario-mp.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';

@Component({
  selector: 'app-devoluciones-producto',
  templateUrl: './devoluciones-producto.component.html',
  styleUrls: ['./devoluciones-producto.component.css']
})
export class DevolucionesProductoComponent implements OnInit {

  sesion:any;
  cod_menu:any;
  object1:any;
  menu:any=[];
  menu2:any = [];
  menu3:any = [];
  menu4:any = [];
  roulink:any;
  unidad:any;
  usuario:any;
  menuorder3:any;
  menuoreder4:any;
  mostrar:any;
  ruta:any;
  obj:any[] = [];
  dialogSpin:any;
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:any[];
  dataSource=new MatTableDataSource<any>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('link') link: ElementRef;
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router){}

  ngOnInit(): void {
    this.mostrar = parseInt(localStorage.getItem('cod_valid'));
    this.sesion = localStorage.getItem('cod_usuario');
    console.log(typeof this.mostrar);
    this.usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');
    this.object1 ={cod_usuario:this.sesion, cod_menu:this.cod_menu}
    this.listarCuaentenaProdDevoluciones(null);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }

  listarCuaentenaProdDevoluciones(msj:any){
    let msg = msj;
    this.servicio.listarCuaentenaProdDevolucioneshttp().subscribe(
      response =>{
        if(response){
          console.log(response[0],'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                    cod_dev:response[i].cod_dev,
                    cod_factura:response[i].cod_factura,
                    cod_nota_credito: response[i].cod_nota_credito,
                    estatus: response[i].estatus,
                    fecha_inicio:response[i].fecha_inicio,
                    hora_inicio:response[i].hora_inicio,
                    fecha_fin:response[i].fecha_fin,
                    hora_fin:response[i].hora_fin

                });
              }
              this.displayedColumns = [
                                        'cod_dev',
                                        'cod_factura',
                                        'cod_nota_credito',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'fecha_fin',
                                        'hora_fin',
                                        'liberar'
                                      ];
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;

        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.displayedColumns = [
                                  'cod_dev',
                                  'cod_factura',
                                  'cod_nota_credito',
                                  'estatus',
                                  'fecha_inicio',
                                  'hora_inicio',
                                  'fecha_fin',
                                  'hora_fin',
                                  'liberar'
                                ];
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      );

      if(msg == 'actualizar'){
        this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
  }
  traslados(obj:any){
    console.log(obj);
    var dialogRef:any;
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    this.accion = 'CRNTN';
      dialogRef = this.dialog.open(FormKeyComponent, {
        width: '25%',
        height:'45%',
        data: {cod_producto:obj.cod_producto,
              des_producto:obj.des_producto,
              cant_producto:obj.cant_producto,
              cod_orden_producto:obj.cod_orden_producto,
              cod_lote:obj.cod_lote,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);

      this.liberarProdTerminadoCuarentena(result);
        }
      });
  }

  liberarProdTerminadoCuarentena(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner'
      });
      setTimeout(() => {
        this.servicio.liberarProdTerminadohttp(xvalor).subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response.resul) {

                    this.ELEMENT_DATA.push({
                      cod_orden_producto:response.resul[i].cod_orden_producto,
                      cod_lote: response.resul[i].cod_lote,
                      cod_producto:response.resul[i].cod_producto,
                      des_producto:response.resul[i].des_producto,
                      cant_producto:response.resul[i].cant_producto,
                      estatus:response.resul[i].estatus,
                      fecha_inicio:response.resul[i].fecha_inicio,
                      hora_inicio:response.resul[i].estatus_producto,
                      fecha_fin:response.resul[i].fecha_fin,
                      hora_fin:response.resul[i].hora_fin,
                      accion:'',
                      accion2:'',
                      accion3:''
                    });
                  }
                  this.displayedColumns = [
                                            'cod_orden_producto',
                                            'cod_lote',
                                            'cod_producto',
                                            'des_producto',
                                            'cant_producto',
                                            'estatus',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'fecha_fin',
                                            'hora_fin',
                                            'liberar'
                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;

                  this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
            }
          },
          error =>{
            console.log('una respuesta en el error');
            this.dialogSpin.close(error);
            this.displayedColumns = [
                                      'cod_orden_producto',
                                      'cod_lote',
                                      'cod_producto',
                                      'des_producto',
                                      'cant_producto',
                                      'estatus',
                                      'fecha_inicio',
                                      'hora_inicio',
                                      'fecha_fin',
                                      'hora_fin',
                                      'liberar'
                                    ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(<any>error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
          }
        );
      }, 3000);



    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

}
