import { result } from 'lodash';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormOrdenesprodmppComponent } from 'src/app/componentes/shared/modal/form-ordenesprodmpp/form-ordenesprodmpp.component';
import { FormConsultaOrdenesMppComponent } from 'src/app/componentes/shared/modal/form-consulta-ordenes-mpp/form-consulta-ordenes-mpp.component';
import { FormConfirmacionInvMppComponent } from 'src/app/componentes/shared/modal/form-confirmacion-inv-mpp/form-confirmacion-inv-mpp.component';

@Injectable({
  providedIn: 'root'
})


@Component({
  selector: 'app-consultar-ordenes-mpp',
  templateUrl: './consultar-ordenes-mpp.component.html',
  styleUrls: ['./consultar-ordenes-mpp.component.css']
})
export class ConsultarOrdenesMppComponent implements OnInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

  }

  ngOnInit(): void {
    this.listarordenesprodmpp(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }

  /////////////////////////////////////////////////////////////////////
  listarordenesprodmpp(msj:any){
    let msg = msj;
    var valor = {termino:'P'}
    this.servicio.listarOrdenesProdMpphttp(valor).subscribe(
      response =>{
        ////////////////UNIDADES//////////////////////
        this.xobj3 = [];
        for (const i in response[0]) {
          this.xobj3.push(response[0][i]);
        }
        console.log(this.xobj3);
        if(response){
          console.log(response,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                  cod_orden_producto:response[i].cod_orden_producto,
                  cod_orden_producto_mpp:response[i].cod_orden_producto_mpp,
                  cod_usuario:response[i].cod_usuario,
                  des_usuario: response[i].des_usuario,
                  estatus:response[i].estatus,
                  fecha_inicio:response[i].fecha_inicio,
                  hora_inicio:response[i].hora_inicio,
                  cod_usuario_cierre: response[i].cod_usuario_cierre,
                  des_usuario_cierre:response[i].des_usuario_cierre,
                  fecha_fin:response[i].fecha_fin,
                  hora_fin:response[i].hora_fin,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_orden_producto_mpp',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Actualizar'
                                      ];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log('una respuesta');
        console.log(<any>error.error.message);
        this.displayedColumns = [ 'cod_orden_producto',
                                        'cod_orden_producto_mpp',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Actualizar'
                                      ];
        this.dataSource = new MatTableDataSource<any>();
              this.dataSource.paginator = this.paginator;
      }
    );
    console.log(msg);
    if(msg == 'actualizar'){
      this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    return this.xobj2;
  }
   eliminarclientes(id:any){
    console.log('eliminar');
    this.servicio.eliminarclienteshttp(id).subscribe(
      response =>{
        this.xobj3 = [];
        this.listarordenesprodmpp(null);
              this.snackBar.open(response.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.listarordenesprodmpp(null);
      }
    );

    return this.obj;
  }

  modalrespuesta(obj:any, text:any){
    this.accion = text;
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '24%',
        height:'35%',
        data: {id:obj.cod_cliente,
              accion:this.accion},
        disableClose: true,
        panelClass: 'elem_modal'
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log('The dialog was closed');
          console.log(result);
          let resultado = this.eliminarclientes(result.id);
          console.log(resultado);
        }
      });
  }

  editardatos(obj:any,text:any){
    var dialogRef:any;
    this.accion = text;
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    if (this.accion === 'actualizar') {
      dialogRef = this.dialog.open(FormConsultaOrdenesMppComponent, {
        width: '100%',
        height:'90%',
        data: {cod_orden_producto: obj.cod_orden_producto_mpp,
                cod_usuario:datos,
                des_usuario: nombre,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
    }else{
      dialogRef = this.dialog.open(FormOrdenesprodmppComponent, {
        width: '100%',
        height:'91%',
        data: {cod_lista_precio_producto:'',
                cod_usuario:datos,
                des_usuario: nombre,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
    }

      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);

     this.gestionOrdenProdMpp(result);
        }
      });
  }

public  gestionOrdenProdMpp(xvalor:any){
  console.log(xvalor);
  var resultado = this.servicio.gestionOrdenProdMpphttp(xvalor).subscribe(
     response =>{
       console.log(response.resul,'una respuesta');
       if(response.resul){
         console.log(response.resul,'una respuesta');
         this.xobj2 = response;
         this.ELEMENT_DATA = [];
             for (const i in response.resul) {
                 console.log(response[i]);

               this.ELEMENT_DATA.push({
                cod_orden_producto_mpp:response.resul[i].cod_orden_producto_mpp,
                cod_usuario:response.resul[i].cod_usuario,
                des_usuario: response.resul[i].des_usuario,
                estatus:response.resul[i].estatus,
                fecha_inicio:response.resul[i].fecha_inicio,
                hora_inicio:response.resul[i].hora_inicio,
                cod_usuario_cierre: response.resul[i].cod_usuario_cierre,
                des_usuario_cierre:response.resul[i].des_usuario_cierre,
                fecha_fin:response.resul[i].fecha_fin,
                hora_fin:response.resul[i].hora_fin,
                accion:'',
                accion2:'',
                accion3:''
              });
             }
             this.displayedColumns = [ 'cod_orden_producto_mpp',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'Actualizar',
                                        'confirmar'
                                      ];
              /* this.dataSource = this.ELEMENT_DATA; */
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
             this.snackBar.open(response.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
       }
     },
     error =>{
       console.log(<any>error.error.message);
       this.displayedColumns = [ 'cod_orden_producto_mpp',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Actualizar',
                                        'confirmar'
                                      ];
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   );

   return resultado;
 }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  async detalleOrdenProdMpp(xvalor:any){
    this.dialog.open(FormConsultaOrdenesMppComponent, {
      width: '100%',
      height:'90%',
      data: {cod_orden_producto_mpp: xvalor.cod_orden_producto_mpp},

      panelClass: 'pedidos_modal'
    });

 }
 async confirmacionOrdenProdMpp(xvalor:any){
  var dialogRef = this.dialog.open(FormConfirmacionInvMppComponent, {
    width: '100%',
    height:'90%',
    data: {cod_usuario:xvalor.cod_usuario,
           des_usuario:xvalor.des_usuario,
           cod_orden_producto_mpp: xvalor.cod_orden_producto_mpp,
           cod_orden_producto: xvalor.cod_orden_producto,
           estatus:xvalor.estatus},
    disableClose: true,
    panelClass: 'pedidos_modal'
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result != 'cerrar'){
      console.log(result);
     this.gestionOrdenLaboratorioMpp(result);
    }
  });

}
public  gestionOrdenLaboratorioMpp(xvalor:any){
  console.log(xvalor);

  var resultado = this.servicio.gestionOrdenLabMpphttp(xvalor).subscribe(
     response =>{
       console.log(response.resul,'una respuesta');
       if(response.resul){
         console.log(response.resul,'una respuesta');
         this.xobj2 = response;
         this.ELEMENT_DATA = [];
             for (const i in response.resul) {
                 console.log(response[i]);

               this.ELEMENT_DATA.push({
                cod_orden_producto_mpp:response.resul[i].cod_orden_producto_mpp,
                cod_usuario:response.resul[i].cod_usuario,
                des_usuario: response.resul[i].des_usuario,
                estatus:response.resul[i].estatus,
                fecha_inicio:response.resul[i].fecha_inicio,
                hora_inicio:response.resul[i].hora_inicio,
                cod_usuario_cierre: response.resul[i].cod_usuario_cierre,
                des_usuario_cierre:response.resul[i].des_usuario_cierre,
                fecha_fin:response.resul[i].fecha_fin,
                hora_fin:response.resul[i].hora_fin,
                accion:'',
                accion2:'',
                accion3:''
              });
             }
             this.displayedColumns = [ 'cod_orden_producto_mpp',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Actualizar',
                                        'confirmar'
                                      ];
              /* this.dataSource = this.ELEMENT_DATA; */
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
             this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
               verticalPosition: this.verticalPosition,});
       }
     },
     error =>{
       console.log(<any>error.error.message);
       this.displayedColumns = [ 'cod_orden_producto_mpp',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'hora_inicio',
                                        'Actualizar',
                                        'confirmar'
                                      ];
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition,});
     }
   );

   return resultado;
 }

}
