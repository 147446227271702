<div class=" container-fluid" style="border-radius: 1rem;">

    <div id="carrito_p1" class="row mat-elevation-z8" style="border-radius: 1rem 1rem 0 0; border: 2px solid rgb(42, 73, 134); background-color: rgb(42, 73, 134); ">

        <!--         <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100"   matTooltip="ACTUALIZAR" style="border-radius: 1rem; border: outset 3px rgb(173, 64, 144);  color:rgb(255, 255, 255); background-color: rgb(169, 35, 133);">
                    <mat-icon >sync</mat-icon>
                    </button>
            </div>
        </div> -->
        <!--   (click)="listarDailys('actualizar')" -->
        <!--  <div class=" col-md-2 px-0 py-3 h-100">
            <div class=" fileUpload  btn btn-primary" style="display: flex; justify-content: center; align-items: center; height: 20px;margin: 0 0 5 0 ;">
                <mat-icon class="mr-1">file_upload</mat-icon><span>Carga</span>
                <input class="upload" type="file" (change)="onFileChange($event)" multiple="false" style="opacity: 0;">
            </div>
            <div (click)="descarga_xlsx()" class=" fileUpload  btn btn-success" style="display: flex; justify-content: center; align-items: center;height: 20px;">
                <mat-icon class="mr-1">file_download</mat-icon><span>Descarga</span>

            </div>
        </div> -->

        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class=" fileUpload_listas btn btn-block btn-lg btn-light h-100 py-3" (click)="descarga_xlsx()" matTooltip="BAJAR FORMATO" style="border-radius: 1rem; border: outset 3px rgb(65, 52, 131); color:rgb(255, 255, 255); background-color: rgb(38, 21, 126);">
                    <mat-icon>file_download</mat-icon>
                </button>
            </div>
        </div>

        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <div class=" fileUpload_listas btn btn-block btn-lg btn-light h-100 py-3" matTooltip="SUBIR FORMATO" style="border-radius: 1rem; border: outset 3px rgb(102, 100, 100); color:rgb(255, 255, 255); background-color: rgb(29, 29, 29);">
                    <mat-icon>file_upload</mat-icon>
                    <input class="upload_listas btn btn-block btn-lg btn-light" type="file" (change)="onFileChange($event)" [multiple]="true" accept=".xlsx, .csv">
                </div>
            </div>
        </div>

        <div class="col-md-8 px-0 py-2 h-100">
            <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid rgb(42, 73, 134);">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                    <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <!-- <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
            <div class=" mx-1 h-100">
                <button  class="btn btn-block btn-lg btn-primary h-100" (click)="modalrespuesta()" matTooltip="GUARDAR" style=" border-radius: 1rem; border: outset 3px rgb(40, 51, 197);">
                <input class="upload" type="file" (change)="onFileChange($event)" multiple="false" style="opacity: 0;">
                <mat-icon>save</mat-icon>
                </button>
            </div>
        </div> -->
        <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
            <div class=" mx-1 h-100">
                <button class="btn btn-block btn-lg btn-dark h-100" (click)="modalrespuesta()" matTooltip="GUARDAR" style="border-radius: 1rem; border: outset 3px rgb(80, 89, 204); color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                  <mat-icon >save</mat-icon>
              </button>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" style="border-radius: 1rem; border: outset 3px rgb(182, 48, 31);" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
            </div>
        </div>
    </div>
    <div id="carrito_p6" class="row" style="border-radius: 0 0 1rem 1rem; border: 2px solid rgb(42, 73, 134);">
        <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100" style="border-radius: 0 0 1rem 1rem; background-color: rgb(42, 73, 134);">
            <table #table mat-table [dataSource]="dataSource1" class="table-dark text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>RIF</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cod_cliente}} </td>

                </ng-container>
                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.des_cliente}} </td>

                </ng-container>
                <ng-container matColumnDef="cuota">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>Cuota</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.cuota}} </td>

                </ng-container>
                <ng-container matColumnDef="porcentaje_cuota">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>%Cuota</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.porcentaje_cuota}} </td>

                </ng-container>
                <ng-container matColumnDef="estatus_consig">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(42, 73, 134);">
                        <h5><strong>CONSIGNACION</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px; color: #fff;"> {{element.estatus_consig}} </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>