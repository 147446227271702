<div class="container" >
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; ">
                <div class="modal-content" style="height: 100%; width: 100%; ">
                    <div class="modal-header" style="background-color: steelblue; color: #fff;">
                        <h3 class="modal-title" class=" text-center"><strong>Fecha de la consulta</strong> </h3>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row">
                                <form [formGroup]="forma" class="col-md-12 col-sm-12">
                     
                                                        <div id="sub_content_2" class=" mx-1 h-100">
                                                            <mat-form-field class="materialinput">
                                                                <mat-label style="color: #000">Cedula del Empleado</mat-label>
                                                                <input style="font-size: 12px; color: black;" type="number" maxlength="20" matInput placeholder="Cedula del Empleado" formControlName="cod_usuario" autocomplete="off">
                                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    
                                                                </span>
                                                            </mat-form-field>
                                                            <mat-form-field class="materialinput py-1 px-1">
                                                                <mat-label style="color: rgb(0, 49, 155); font-size: 12px;">Seleccione un rango de fecha</mat-label>
                                                                <mat-date-range-input  [rangePicker]="picker">
                                                                    <input style="color: rgb(0, 49, 155);" matStartDate formControlName="fecha_inicio" placeholder="Fecha inicio" autocomplete="off">
                                                                    <input style="color: rgb(0, 49, 155);" matEndDate formControlName="fecha_fin" placeholder="Fecha fin" autocomplete="off">
                                                                </mat-date-range-input>
                                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                <mat-date-range-picker #picker></mat-date-range-picker>
                                            
                                                                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                                                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                     
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
      
                            <button [disabled]="forma.invalid" mat-button (click)="modalrespuesta()" class=" mr-2 btn-primary"> Enviar</button>

                        </div>
                    </div>
                </div>
        </div>
    </div>
    <div class="row">

    </div>
</div>