<!-- <div class="container-fluid  mat-elevation-z8" style="border: 1px solid #cccccc; height: 100%; background-color: rgb(255, 255, 255); border-radius: 2% 2% 2% 2%;">
    <form [formGroup]="forma" class="">
        <div class="row" style="border-radius: 10px 10px 0px 0px; border-bottom: 1px solid #cccccccc; background-color: rgb(70, 128, 216); color: #fff; height: 20%; padding: 15px">
            <div class="col-md-12 col-sm-12 col-xs-12" style=" padding: 10px 5px 5px 10px;  border-radius: 5px;">
                <h2 mat-dialog-title style="border-bottom: 1px solid #fff;">Definir tareas de soporte</h2>
            </div>
        </div>
        
        <div class="row" style="height: 20%;  border-top: 1px solid rgba(128, 128, 128, 0.651); padding-top: 2.5%; padding-bottom: 2.5%;">
            <div class="col-md-12">
                <mat-dialog-actions align="end">
                    <button mat-button class=" btn-danger" (click)="cerrarmodal()">Cancelar</button>
                    <button [disabled]="forma.invalid" (click)="modalrespuesta()" mat-button class=" btn-primary">Enviar</button>
                </mat-dialog-actions>
            </div>
        </div>
    </form>
</div> -->




<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); ">
                    <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-center"><strong>Definir tareas de soporte</strong> </h3>
                    </div>
                    <div class="modal-body">
                        
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div class="row" style="height: 20%; margin: 5px 2.5px 5px 2.5px; padding: 15px 5px 15px 5px; border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Nombre de la tarea</mat-label>
                                                    <input style="font-size: 12px; color: black;" matInput placeholder="" formControlName="des_solicitud" autocomplete="off">
                                                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                    <mat-icon>task</mat-icon>
                                                    </span>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Tarea padre</mat-label>
                                                    <mat-select (selectionChange)="nivel_tarea(tarea)" #tarea class="" style="font-size: 12px; color: black; border: none;" name="estatus" placeholder="" formControlName="cod_solicitud_padre" autocomplete="off">
                                                        <mat-option value="N/A">N/A</mat-option>
                                                        <mat-option *ngFor="let item of tareas" [value]="item">( {{item.cod_solicitud}} ) {{item.des_solicitud}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                            
                                        </div>
                                    </div>
                                    <div class="row" style="height: 20%;margin: 5px 2.5px 5px 2.5px; padding: 15px 5px 15px 5px; border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Nivel padre</mat-label>
                                                    <mat-select class="" style="font-size: 12px; color: black; border: none;" #tipo_update name="estatus" placeholder="" formControlName="nivel_solicitud_padre" autocomplete="off">
                                                        <mat-option *ngIf="!actualizar" value="N/A">N/A</mat-option>
                                                        <mat-option *ngIf="actualizar" value="0">0</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Orden de la tarea</mat-label>
                                                    <input style="font-size: 12px; color: black;" matInput placeholder="" formControlName="ord_solicitud" autocomplete="off">
                                                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>task</mat-icon>
                                            </span>
                                                </mat-form-field>
                                            </div>
                            
                                        </div>
                                    </div>
                                    <div class="row" style="height: 20%;margin: 5px 2.5px 5px 2.5px; padding: 15px 5px 15px 5px; border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group">
                                                <mat-form-field class="materialinput">
                                                    <mat-label style="color: #000">Estatus</mat-label>
                                                    <mat-select class="" style="font-size: 12px; color: black; border: none;" #tipo_update name="estatus" placeholder="" formControlName="estatus" autocomplete="off">
                                                        <mat-option value="A">ACTIVA</mat-option>
                                                        <mat-option value="I">INACTIVA</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">

                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                            <button [disabled]="forma.invalid" (click)="modalrespuesta()" mat-button class=" btn-primary">Enviar</button> <button mat-button class=" btn-primary" *ngIf="accion == 'eliminar'" (click)="eliminarregistros('eliminar')"> Enviar</button>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>