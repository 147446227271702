import { Component, ElementRef, OnInit, ViewChild, Inject, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { element } from 'protractor';

@Component({
  selector: 'app-form-crear-toma-fisica',
  templateUrl: './form-crear-toma-fisica.component.html',
  styleUrls: ['./form-crear-toma-fisica.component.css']
})

export class FormCrearTomaFisicaComponent implements OnInit {

  @ViewChild('unidad_front') unidad_front:ElementRef;

  codigo_deposito:any;
  Auditores:any;
  LiderInventario:any;
  supervisorInventario:any;
  producto_deposito:any;
  input_dirty:any;
  countryid = 0;

  options:any;

  item:any
  cod_producto = new FormControl();
  forma:FormGroup;
  accion:any;
  dialogSpin:any;
  validacion:any;
  ProductosFormGroup:FormGroup;
  filteredOptions: Observable<string[]>;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private service:nkServices,private snackBar: MatSnackBar, public dialog: MatDialog, public fb:FormBuilder,
    public dialogRef: MatDialogRef<FormCrearTomaFisicaComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.accion = data.accion;
      this.forma = fb.group({
        /* datos para toma fisica completa */
        'tipo':[data.tipo, [Validators.required]],
        'cod_deposito':[data.cod_deposito, [Validators.required]],
        'des_auditor':[data.des_auditor, [Validators.required]],
        'des_lider_inv':[data.des_lider_inv, [Validators.required]],
        'des_supervisor_inv':[data.des_supervisor_inv, [Validators.required]],
        'cod_usuario':localStorage.getItem('cod_usuario'),
        'des_usuario':localStorage.getItem('Nombre'),
        /*datos para toma fisica ciclica */
        'cant_item_top':data.cant_item_top,
        'cant_item_random':data.cant_item_random,
        /*datos para toma fisica especifica  */
        'cod_producto':data.cod_producto,
        'productos_cant':fb.array([])
      });
     }

  ngOnInit(): void {
    this.input_dirty = this.forma.pristine;
    this.listarDepositos();
    this.listarAuditoresTF();
    this.listarLiderTF();
    this.listarSupervisorTF();
    this.filteredOptions = this.cod_producto.valueChanges.pipe(
      startWith(''),
      map(state => (state ? this._filter(state) : this.options.slice())));
  }

  get productos(){
      return this.forma.get('productos_cant') as FormArray;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.cod_producto.toLowerCase().includes(filterValue));
  }

 cerrarmodal(): void {
  this.accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
    maxWidth: '100vw',
    height:'100%',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
 }

cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRef.close('cerrar');
    }, 500);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}

  hideDiv(elem) {
    this.validacion = elem.value
  }
   
  modalrespuesta(){

    if(this.validacion == 'TFIE' ){
      
      if(!this.forma.value.productos_cant.length){
        return this.snackBar.open('No has elegido algún producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }

      for (const element of this.forma.value.productos_cant) {

        if(element.cod_producto == ''){
          return this.snackBar.open('Producto vacio', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }

        
      }

    }

    if(this.validacion == 'TFCL' ){

      console.log(this.forma.value)

      if(!this.forma.value.cant_item_top){

        return this.snackBar.open('Item Top está vacio', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
        
      }

      if(!this.forma.value.cant_item_random){

        return this.snackBar.open('Item Random está vacio', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});

      }

    }

    var accion2 = 'enviarcliente';
    var dialogRefp = this.dialog.open(FormmMsgComponent, {
      width:'100vw',
      maxWidth:'100vw',
      height:'100%',
      data: {id:this.data.cod_usuario,
            cod_usuario:localStorage.getItem('cod_usuario'),
            des_usuario:localStorage.getItem('Nombre'),
            accion:accion2},
            disableClose: true,
            panelClass: 'pedidos_modal',
            backdropClass: "bdrop"
    });
    dialogRefp.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(this.forma.value, 'informacion 2');
        this.dialogRef.close(this.forma.value);
      }
    });
   }

  listarDepositos(){
    this.service.listarDepositosTFhttp().subscribe(
      response =>{
        this.codigo_deposito = response

        console.log(response,'codigo_deposito')
      }
    )
  }

  listarAuditoresTF(){
    this.service.listarAuditoresTFhttp().subscribe(
      response =>{
        this.Auditores = response
      }
    )
  }

  listarLiderTF(){
    this.service.listarLiderTFhttp().subscribe(
      response =>{
        this.LiderInventario = response
      }
    )
  }

  listarSupervisorTF(){
    this.service.listarSupervisorTFhttp().subscribe(
      response =>{
        this.supervisorInventario = response
      }
    )

  }
  
  listarProductosDepItemEsp(xvalor:any){
    this.service.listarProductosDepItemEsphttp(xvalor.value).subscribe(
      response =>{
        this.producto_deposito = response
     
        this.options = response
      }
    )
  }


  agregarNumberCantProducto(){
    this.ProductosFormGroup = this.fb.group({
      'cod_producto':'',
      'cod_deposito':this.forma.value.cod_deposito
    })
      this.productos.push(this.ProductosFormGroup)
    console.log(this.forma, 'arrecho')
  }

  
  updateForm(ev: any, idd: any, componentid: any,position:any) {
    console.log(this.forma, 'arrecho')
    if (ev.isUserInput) {
      console.log(position)
      this.forma['value']['productos_cant'][position]['cod_producto'] = ev.source.value
        console.log(this.forma['value']['productos_cant'][position]['cod_producto'], 'este es el beta')
        console.log(ev.source.value, 'o este')
    }
  }

  updateForma(ev: any, idd: any, componentid: any,position:any) {
    console.log(this.forma, 'arrecho')
    if (ev.target.value) {
      this.forma['value']['productos_cant'][position]['cod_producto'] = ev.target.value
        console.log(this.forma['value']['productos_cant'][position]['cod_producto'], 'este es el beta')
        console.log(ev.target.value, 'o este')
    }
  }

}