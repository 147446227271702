import { Component, ElementRef, OnInit } from '@angular/core';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import { Routes, RouterModule, Router } from '@angular/router';

import { Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormMateriaPrimaComponent } from 'src/app/componentes/shared/modal/form-materia-prima/form-materia-prima.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';
import { environment } from 'src/environments/environment';
import { FormFormulacionComponent } from 'src/app/componentes/shared/modal/form-formulacion/form-formulacion.component';

@Component({
  selector: 'app-mp-producto-factory',
  templateUrl: './mp-producto-factory.component.html',
  styleUrls: ['./mp-producto-factory.component.css']
})
export class MpProductoFactoryComponent implements OnInit {

  medida:any = [];
  DEFINICIO_1:any = [];
  DEFINICIO_2:any = [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:any[];
  dataSource=new MatTableDataSource<any>();
  @ViewChild('link') link: ElementRef;
  constructor(
        private servicio:nkServices, 
        private dialog: MatDialog, 
        private snackBar: MatSnackBar, 
        private router:Router,
        private hashkey:HashKeyServices,
        private util:AppUtilService,
        private atrb:AtributosService,
        private notifi:ToastNotificationService,
    ){}
  ngOnInit(){
    this.listarProductoMateriaPrima(null);
    this.buscardefinicionesprod(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
  }
 async listarProductoMateriaPrima(msj:any){
    try {
      let msg = msj;
        const response = await this.servicio.listarMateriaPrimaConfighttp(await this.atrb.COD_EMPRESA).toPromise();
        console.log(response);
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    if (response[i].cod_formula.toLowerCase() === await this.atrb.SIN_FORMULA.toLowerCase()) {
                      response[i].cod_formula = await this.atrb.NO_APLICA;
                    }
                    if (response[i].cod_img === '' || response[i].cod_img === null) {
                      response[i].cod_img = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                    }else{
                      response[i].cod_img = environment.path_storage+response[i].cod_img
                    }
                    this.ELEMENT_DATA.push({
                        cod_img:response[i].cod_img,
                        cod_formula:response[i].cod_formula,
                        cod_producto:response[i].cod_producto,
                        des_producto: response[i].des_producto,
                        cod_prov:response[i].cod_prov,
                        des_prov: response[i].des_prov,
                        tamano_producto:response[i].tamano_producto,
                        estatus_producto:response[i].estatus_producto,
                        tipo_producto:response[i].tipo_producto,
                        unidad_medida_producto:response[i].unidad_medida_producto,
                        costo_prov:response[i].costo_prov,
                    });
                  }
                  this.displayedColumns = [
                                          'cod_img',
                                          'cod_formula',
                                          'cod_producto',
                                          'des_producto',
                                          'estatus_producto',
                                          'unidad_medida_producto',
                                          'tipo_producto',
                                          'procesos'
                                        ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                  if(msg === await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                      await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                  }else{
                    await this.util.cerrarSpinner();
                  }
    } catch (error) {
      this.displayedColumns = [
        'cod_img',
        'cod_formula',
        'cod_producto',
        'des_producto',
        'estatus_producto',
        'unidad_medida_producto',
        'tipo_producto',
        'procesos'
      ];
      this.dataSource = new MatTableDataSource<any>();
            this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
 async buscardefinicionesprod(xvalor:any){
    try {
      var valor = {cod_menu: localStorage.getItem('sub_main')};
        /* const response = await this.servicio.buscardefinicionesprodhttp(valor).toPromise(); */
        const response = await this.servicio.listarDefinicionesConceptoshttp(valor).toPromise();
            this.medida = [];
            this.DEFINICIO_1 = [];
            this.DEFINICIO_2 = [];
            for (const i in response) {
                  if (response[i].des_definicion.toUpperCase() === await this.atrb.PRODUCTOS_MP.estatus_mp.toUpperCase()) {
                      this.DEFINICIO_1.push(response[i]);
                  }
                  if (response[i].des_definicion.toUpperCase() === await this.atrb.PRODUCTOS_MP.producto_mp.toUpperCase()) {
                    this.medida.push(response[i]);
                  }
                  if (response[i].des_definicion.toUpperCase() === await this.atrb.PRODUCTOS_MP.medidas_mp.toUpperCase()) {
                    this.DEFINICIO_2.push(response[i]);
                  }
            }
    } catch (error) {
      this.medida = [];
      this.DEFINICIO_1 = [];
      this.DEFINICIO_2 = [];
     console.log(error);
    }
   }
  //////////////////////FUNCION DE ACCION/////////////////////////
 async update_insert_data(obj:any, text:any){
    try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
                  if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                    let img =  obj.cod_img.split(environment.path_storage);
                          let img_ruta = img[1].split('\\');
                          let img_ruta_final = img_ruta[0]+'\\\\'+img_ruta[1]+'\\\\'+img_ruta[2];
                          let val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_productos,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                            cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                          }
                          await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                          await this.util.cerrarSpinner();
                            this.accion = text;
                            var dialogRef = this.dialog.open(FormMateriaPrimaComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {id:obj.id,
                                     cod_producto: obj.cod_producto,
                                     des_producto: obj.des_producto,
                                     costo_prov: obj.costo_prov,
                                     des_prov:obj.des_prov,
                                     cod_prov:obj.cod_prov,
                                     estatus_producto: obj.estatus_producto,
                                     tipo_producto:obj.tipo_producto,
                                     unidad_medida_producto:obj.unidad_medida_producto,
                                     cod_img:obj.cod_img,
                                     tamano_producto:obj.tamano_producto,
                                     tipo_prod: this.medida,
                                     definicion_1:this.DEFINICIO_1,
                                     definicion_2:this.DEFINICIO_2,
                                    accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }else{
                            let val_def = {
                              val_def:await this.atrb.ACCESO_VALDEF.gestionar_productos,
                              tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                              cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                            }
                            await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                            let objeto = {
                              cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
                              mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
                              des_parametro: await this.atrb.OPERACIONES.productos
                            };
                            await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
                            await this.util.cerrarSpinner();
                            this.accion = text;
                             dialogRef = this.dialog.open(FormMateriaPrimaComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'auto',
                              data: {
                                  tipo_prod: this.medida,
                                 definicion_1:this.DEFINICIO_1,
                                 definicion_2:this.DEFINICIO_2,
                                  accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal_perfiles',
                              backdropClass: "bdrop"
                            });
                          }
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                            if(result != 'cerrar'){
                              await this.gestionMateriaPrima(result);
                            }else{
                              await this.util.cerrarSpinner();
                            }
                          });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async gestionMateriaPrima(xvalor:any){
    try {
        if (xvalor.formdata.get('prod_img') !== await this.atrb.UNDEFINED) {
              const img = await this.servicio.gestionarImagenesthttp(xvalor.formdata).toPromise();
                  xvalor.cod_img = img.file.path;
                  xvalor.des_img = img.file.filename;
          }else{
                  xvalor.cod_img = false;
                  xvalor.des_img = false;
          }
        const response = await this.servicio.gestionMateriaPrimahttp(xvalor).toPromise();
        await this.util.cerrarSpinner();
        await this.listarProductoMateriaPrima(null);
        await this.notifi.success(response.message);
    } catch (error) {
      console.log(error);
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
      await this.listarProductoMateriaPrima(null);
    }
 }
 async formulacion_prod_mpp(obj:any, text:any){
  try {
                await this.util.abrirSpinner();
                await this.servicio.consultar_sesionhttp(null).toPromise();
              if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                let val_def;
                    val_def = {
                      val_def:await this.atrb.ACCESO_VALDEF.gestionar_productos,
                      tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
                      cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                    }
                await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
              }
              await this.util.cerrarSpinner();
                          this.accion = 'formulacionpt';
                          var dialogRef = this.dialog.open(FormFormulacionComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                            data: {cod_usuario:'',
                                    des_usuario:'',
                                    cod_producto:obj.cod_producto,
                                   des_producto:obj.des_producto,
                                   cod_formula: obj.cod_formula,
                                   tipo_producto: obj.tipo_producto,
                                  accion:this.accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                  
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                            if(result != 'cerrar'){
                            }else{
                              await this.util.cerrarSpinner();
                           await this.listarProductoMateriaPrima(null);
                            }
                          });
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}
  //////////////////////FUNCION DE ACCION/////////////////////////
  //////////////////////PRODUCTO MASIVO/////////////////////////

 /*  handleFile(event) {
    let file = event.target.files[0];

    var id = document.getElementById('file');
    console.log(file);
    var nombre = 'Codigo de Unidad';
    this.servicio.processFileToJson({}, file).subscribe(data => {
      let arry = data.sheets.Hoja1;
      console.log(arry);
      for (const i in arry) {
          console.log(arry[i]);
          this.result.push(arry[i]);
      }
      console.log(this.result);
      if (this.result.length != 0) {
        console.log('entro en el if');
          this.servicio.insertarmasivoProductoFactorymphttp(this.result).subscribe(
            response =>{
              if(response){
                console.log(response,'una respuesta');
                let xobj2 = response.resul;
                this.ELEMENT_DATA = [];
                    for (const i in xobj2) {

                      this.ELEMENT_DATA.push({
                        id:xobj2[i].id,
                        cod_producto:xobj2[i].cod_producto,
                        des_producto: xobj2[i].des_producto,
                        cod_prov:xobj2[i].cod_prov,
                        des_prov: xobj2[i].des_prov,
                        estatus_producto:xobj2[i].estatus_producto,
                        tipo_producto:xobj2[i].tipo_producto,
                        unidad_medida_producto:xobj2[i].unidad_medida_producto,
                        costo_prov:xobj2[i].costo_prov,
                      accion:'',
                      accion2:'',
                      accion3:''
                      });
                    }
                    this.displayedColumns = ['cod_producto',
                                           'des_producto',
                                           'estatus_producto',
                                           'tipo_producto',
                                           'unidad_medida_producto',
                                          'procesos'];

                    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                    this.dataSource.paginator = this.paginator;
                 var bool = true;
                 console.log(response.resul);
                this.snackBar.open(response.message,'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                 return bool;
              }
            },
            error =>{
              console.log(<any>error);
              this.displayedColumns = ['cod_producto',
                                           'des_producto',
                                           'estatus_producto',
                                           'tipo_producto',
                                           'unidad_medida_producto',
                                          'procesos'];
            }
          );
      }

    })
    event.srcElement.value = null;
  } */

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
