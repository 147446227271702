import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitPipe'
})
export class SplitPipePipe implements PipeTransform {
  transform(xvalor:any): any {
    if (!xvalor) {
      return;
    }else{
      let arry = xvalor.split('-');
      return arry[0];
    } 
  }
}
