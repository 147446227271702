<!-- <div class=" " id="modal">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 5px;">
        <form class=" ">
          <div class="modal-content h-100">
            <div class="modal-header p-0 d-flex justify-content-center align-items-center" style="background-color: rgb(9, 138, 177); color: #fff;">
              <h3 class="modal-title my-2" class=" text-left"><strong>Buscar Asistencias</strong> </h3>
            </div>
            <div class="modal-body">
              <div>

                <div class="row">
                  <div class="col-md-12 col-sm-12">

                    <div class="d-flex justify-content-center">

                      <div class="">
                        <mat-form-field class="example-full-width" appearance="fill">
                          <mat-label>Fecha inicial</mat-label>
                          <input type="date" matInput name="fecha_inicio" (keyup)="leerDatos($event)" required>
                        </mat-form-field>
                      </div>

                    </div>

                    <div class="d-flex justify-content-center">

                      <div class="">
                        <mat-form-field class="example-full-width" appearance="fill">
                          <mat-label>Fecha final</mat-label>
                          <input type="date" matInput name="fecha_fin" (keyup)="leerDatos($event)" required>
                        </mat-form-field>
                      </div>
                      
                    </div>

                    <mat-dialog-actions style="    top: -10;
                    position: relative;
                    justify-content: center;">

                      <button mat-raised-button [mat-dialog-close]="enviarDatos()" type="submit" [disabled]="btnDesabilitado" color="primary" >Buscar</button>
                      <button mat-raised-button [mat-dialog-close]="Hoy()" color="warn">Hoy</button>
        
                    </mat-dialog-actions>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div> -->

  <div class="container" >
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; ">
                <div class="modal-content" style="height: 100%; width: 100%; ">
                    <div class="modal-header" style="background-color: steelblue; color: #fff;">
                        <h3 class="modal-title" class=" text-center"><strong>Fecha de la consulta</strong> </h3>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                     
                                                        <div id="sub_content_2" class=" mx-1 h-100">
                                                            <mat-form-field class="materialinput py-1 px-1">
                                                                <mat-label style="color: rgb(0, 49, 155); font-size: 12px;">Seleccione un rango de fecha</mat-label>
                                                                <mat-date-range-input [formGroup]="forma" [rangePicker]="picker">
                                                                    <input style="color: rgb(0, 49, 155);" matStartDate formControlName="fecha_inicio" placeholder="Fecha inicio" autocomplete="off">
                                                                    <input style="color: rgb(0, 49, 155);" matEndDate formControlName="fecha_fin" placeholder="Fecha fin" autocomplete="off">
                                                                </mat-date-range-input>
                                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                <mat-date-range-picker #picker></mat-date-range-picker>
                                            
                                                                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                                                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                                                            </mat-form-field>
                                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                     
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
      
                            <button [disabled]="forma.invalid" mat-button (click)="modalrespuesta()" class=" btn-primary"> Enviar</button>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <div class="row">

    </div>
</div>
