import { Component, OnInit, Inject, Injectable, ElementRef, ViewChild } from '@angular/core';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-form-parametro-fnc',
  templateUrl: './form-parametro-fnc.component.html',
  styleUrls: ['./form-parametro-fnc.component.css']
  })
export class FormParametroFncComponent implements OnInit {
  slides:any = [];
  accion:any;
  archivo:File;
  forma:FormGroup;
  formaImg:FormGroup;
  formaTarea:FormGroup;
  formaTareaInp:FormGroup;
  val_galeria:any = [];
  val_cat:any = [];
  data_func:any = [];
  sub_param:any = [];
  metodo:any;
  param_met:any;
  param_oper:any;
  oper_met:any;
  estatus:any;
  head_metod:any;
  objectURL:any;
  objectURL_0:any;
  objectURL_1:any;
  objectURL_2:any;
  objectURL_3:any;
  objectURL_4:any;
  objectURL_5:any;
  objectURL_6:any;
  objectURL_7:any;
  objectURL_8:any;
  objectURL_9:any;
  objectURL_10:any;
  param_prc_prcs_1:any = 1; /* base inicio menu */
  param_prc_prcs_2:any = false; /* parametro 1 */
  param_prc_prcs_3:any = [];
  param_prc_prcs_4:any; /* parametro 3 */
  param_prc_prcs_10:any; /* parametro 10 */
  funcionales:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:any;
   dataSource=new MatTableDataSource<any>();
   operacion:any;
   crear:any = this.atrb.ACCION_CREAR.toUpperCase();
   actualizar:any = this.atrb.ACCION_ACTUALIZAR.toUpperCase();
   borrar:any = this.atrb.ACCION_BORRAR.toUpperCase();
   tabla:any = true;
   tabla_op:any = false;
   des_tarea:any;
   tipo_tarea:any;
   valid_con:any = 0;
   padre_tarea:any;
   conten_tarea:any;
   frase_coma:any = [];
   frase_completa_oper:any;
   frase_completa_prin:any;
   imgArry:any = [];
  constructor(
    private service:nkServices,
    private fb:FormBuilder,
    private dialog: MatDialog, 
    private servicio:nkServices,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private notifi:ToastNotificationService,
    public dialogRefp: MatDialogRef<FormParametroFncComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    console.log(data, 'primero');
    this.objectURL = this.atrb.IMAGENES_FIJAS.sin_img_prod;
    this.objectURL_0 = false;
    this.objectURL_1 = false;
    this.objectURL_2 = false;
    this.objectURL_3 = false;
    this.objectURL_4 = false;
    this.objectURL_5 = false;
    this.objectURL_6 = false;
    this.objectURL_7 = false;
    this.objectURL_8 = false;
    this.objectURL_9 = false;
    this.objectURL_10 = false;
    let param = [];
    let func;
    let op_2;
    if (data.data.val_cat) {
      param = data.data.val_cat.split(',');
      for (const i in data.func) {
        func = data.func[i].concepto_definicion.split('-');
        if (func[0].toUpperCase() === atrb.TP_FUNC.toUpperCase()) {
          this.data_func.push(data.func[i]);
          if (param[1].toUpperCase() === func[1].toUpperCase()) {
              op_2 = data.func[i].concepto_definicion;
          }
        }
      }
    }else{
      for (const i in data.func) {
        func = data.func[i].concepto_definicion.split('-');
        if (func[0].toUpperCase() === atrb.TP_FUNC.toUpperCase()) {
          this.data_func.push(data.func[i]);
        }
      }
    }
    this.param_met = data.data.constante_app.split('_');
    this.param_oper = data.data.tipo_cat.split('-');
    if (this.param_met[0].toUpperCase() === this.atrb.FNC.toUpperCase()) {
      this.head_metod = this.atrb.MENSAJES_FIJOS.parametros_funcionales;
      this.metodo =  this.atrb.FNC.toUpperCase();
      this.forma = fb.group({
        'id_relacion_definicion_app': [data.data.id_relacion_definicion_app],
        'tipo': [atrb.FNCP.toUpperCase()],
        'tipo_parametro':[this.atrb.CONSTANTES_APLICACIONES.PRC_GLR_N],
        'estatus': [this.atrb.ACTIVO.toUpperCase(),Validators.required],
        'val_cat': [''],
        'op_1': [data.data.val_cat ? param[0].toUpperCase() : '',Validators.required],
        'op_2': [data.data.val_cat ? op_2.toUpperCase() : '',Validators.required],
        'op_3': [data.data.val_cat ? param[2].toUpperCase() : '',Validators.required],
        'op_4': [data.data.val_cat ? param[3].toUpperCase() : '',Validators.required],
        'op_5': [data.data.val_cat ? param[4].toUpperCase() : '',Validators.required],
        'op_6': [data.data.val_cat ? param[5].toUpperCase() : '',Validators.required],
        'cod_app': [data.data.cod_app],
        'cod_empresa': [''],
        'api': [''],
        'operacion': [this.atrb.ACCION_ACTUALIZAR],
      });
    }
    if (this.param_met[0].toUpperCase() === this.atrb.PRC.toUpperCase()) {
      this.metodo =  this.atrb.PRC.toUpperCase();
      this.head_metod = this.atrb.MENSAJES_FIJOS.parametros_operacionales;
      if (this.param_oper[0].toUpperCase() === this.atrb.OPERACIONALES.smtp.toUpperCase()) {
        this.oper_met = this.param_oper[0].toUpperCase();
        this.forma = fb.group({
          'id_relacion_definicion_app': [data.data.id_relacion_definicion_app],
          'tipo': [atrb.PRCP.toUpperCase()],
          'tipo_parametro':[this.atrb.CONSTANTES_APLICACIONES.PRC_GLR_N],
          'estatus': [this.atrb.ACTIVO.toUpperCase(),Validators.required],
          'val_cat': [''],
          'op_1': [data.data.val_cat ? param[0] : '',Validators.required],
          'op_2': [data.data.val_cat ? param[1] : '',Validators.required],
          'op_3': [data.data.val_cat ? param[2] : '',Validators.required],
          'op_4': [data.data.val_cat ? param[3] : '',Validators.required],
          'op_5': [data.data.val_cat ? param[4] : '',Validators.required],
          'op_6': [data.data.val_cat ? this.hashkey.decrypt(param[5])  : '',Validators.required],
          'op_7': [data.data.val_cat ? param[6].toUpperCase() : '',Validators.required],
          'op_8': [data.data.val_cat ? param[7].toUpperCase() : '',Validators.required],
          'cod_app': [data.data.cod_app],
          'cod_empresa': [''],
          'api': [''],
          'operacion': [this.atrb.ACCION_ACTUALIZAR],
        });
      }
      if (this.param_oper[0].toUpperCase() === this.atrb.OPERACIONALES.avatar.toUpperCase()) {
        let sub_p;
        for (const i in data.sub_oper) {
          sub_p = data.sub_oper[i].concepto_definicion.split('-');
          if (sub_p[3].toUpperCase() === this.atrb.CONSTANTES_APLICACIONES.PRC_GLR.toUpperCase()) {
            this.sub_param.push(
              {
                nivel_img:sub_p[0],
                des_definicion:sub_p[1]
              }
            );
          }
        }
       console.log(this.sub_param);
        this.oper_met = this.param_oper[0].toUpperCase();
        this.forma = fb.group({
          'id_relacion_definicion_app': [data.data.id_relacion_definicion_app],
          'tipo': [atrb.PRCP.toUpperCase()],
          'estatus': [this.atrb.ACTIVO.toUpperCase(),Validators.required],
          'cod_app': [data.data.cod_app],
          'tipo_cat': [data.data.tipo_cat],
          'nivel_img': [this.sub_param[0].nivel_img,[Validators.required]],
          'cod_empresa': [''],
          'archivo':[''],
          'formdata':[''],
          'cod_img':[''],
          'des_img':[''],
          'img_actual':[''],
          'tipo_parametro':[this.atrb.CONSTANTES_APLICACIONES.PRC_GLR],
          'definicion_img':[this.atrb.MENSAJES_FIJOS.img_avatar],
          'api': [''],
          'operacion': [this.atrb.ACCION_ACTUALIZAR],
        });
        this.listarParametroGaleriaOperacional(this.sub_param[0].nivel_img);
      }
      if (this.param_oper[0].toUpperCase() === this.atrb.OPERACIONALES.stripe.toUpperCase()) {
        if (data.data.cod_img) {
          this.objectURL = environment.path_storage+data.data.cod_img;
        }
        this.oper_met = this.param_oper[0].toUpperCase();
        this.forma = fb.group({
          'id_relacion_definicion_app': [data.data.id_relacion_definicion_app],
          'tipo': [atrb.PRCP.toUpperCase()],
          'tipo_parametro':[this.atrb.CONSTANTES_APLICACIONES.PRC_GLR_N],
          'estatus': [this.atrb.ACTIVO.toUpperCase(),Validators.required],
          'val_cat': [''],
          'op_1': [data.data.val_cat ? param[0] : '',Validators.required],
          'op_2': [data.data.val_cat ? param[1] : '',Validators.required],
          'op_3': [data.data.val_cat ? param[2].toUpperCase() : '',Validators.required],
          'cod_app': [data.data.cod_app],
          'cod_empresa': [''],
          'archivo':[''],
          'formdata':[''],
          'cod_img':[''],
          'des_img':[''],
          'img_actual':[''],
          'concepto_definicion':[this.param_oper[0].toUpperCase()],
          'api': [''],
          'operacion': [this.atrb.ACCION_ACTUALIZAR],
        });
      }
      if (this.param_oper[0].toUpperCase() === this.atrb.OPERACIONALES.paypal.toUpperCase()) {
        if (data.data.cod_img) {
          this.objectURL = environment.path_storage+data.data.cod_img;
        }
        this.oper_met = this.param_oper[0].toUpperCase();
        this.forma = fb.group({
          'id_relacion_definicion_app': [data.data.id_relacion_definicion_app],
          'tipo': [atrb.PRCP.toUpperCase()],
          'tipo_parametro':[this.atrb.CONSTANTES_APLICACIONES.PRC_GLR_N],
          'estatus': [this.atrb.ACTIVO.toUpperCase(),Validators.required],
          'val_cat': [''],
          'op_1': [data.data.val_cat ? param[0] : '',Validators.required],
          'op_2': [data.data.val_cat ? param[1] : '',Validators.required],
          'op_3': [data.data.val_cat ? param[2] : '',Validators.required],
          'cod_app': [data.data.cod_app],
          'cod_empresa': [''],
          'archivo':[''],
          'formdata':[''],
          'cod_img':[''],
          'des_img':[''],
          'img_actual':[''],
          'concepto_definicion':[this.param_oper[0].toUpperCase()],
          'api': [''],
          'operacion': [this.atrb.ACCION_ACTUALIZAR],
        });
      }
      if (this.param_oper[0].toUpperCase() === this.atrb.OPERACIONALES.membresia.toUpperCase()) {
        this.oper_met = this.param_oper[0].toUpperCase();
        this.forma = fb.group({
          'id_relacion_definicion_app': [data.data.id_relacion_definicion_app],
          'tipo': [atrb.PRCP.toUpperCase()],
          'tipo_parametro':[this.atrb.CONSTANTES_APLICACIONES.PRC_GLR_N],
          'estatus': [this.atrb.ACTIVO.toUpperCase(),Validators.required],
          'val_cat': [''],
          'op_1': [data.data.val_cat ? param[0].toUpperCase() : '',Validators.required],
          'op_2': [data.data.val_cat ? param[1].toUpperCase()  : '',Validators.required],
          'op_3': [data.data.val_cat ? this.util.remplazo_especial_restaurar(param[2])  : '',Validators.required],
          'op_4': [data.data.val_cat ? param[3].toUpperCase()  : '',Validators.required],
          'op_5': [data.data.val_cat ? param[4].toUpperCase() : '',Validators.required],
          'op_6': [data.data.val_cat ? this.util.remplazo_especial_restaurar(param[5])  : '',Validators.required],
          'op_7': [data.data.val_cat ? param[6].toUpperCase() : '',Validators.required],
          'op_8': [data.data.val_cat ? param[7].toUpperCase() : '',Validators.required],
          'op_9': [data.data.val_cat ? this.util.remplazo_especial_restaurar(param[8])  : '',Validators.required],
          'op_10': [data.data.val_cat ? param[9].toUpperCase()  : '',Validators.required],
          'op_11': [data.data.val_cat ? param[10].toUpperCase() : '',Validators.required],
          'op_12': [data.data.val_cat ? this.util.remplazo_especial_restaurar(param[11])  : '',Validators.required],
          'cod_app': [data.data.cod_app],
          'cod_empresa': [''],
          'api': [''],
          'operacion': [this.atrb.ACCION_ACTUALIZAR],
        });
      }
      if (this.param_oper[0].toUpperCase() === this.atrb.OPERACIONALES.inicio.toUpperCase()) {
        let sub_p;
        for (const i in data.sub_oper) {
          sub_p = data.sub_oper[i].concepto_definicion.split('-');
          if (sub_p[3].toUpperCase() === this.atrb.CONSTANTES_APLICACIONES.PRC_PRCSS.toUpperCase()) {
            this.sub_param.push(
              {
                nivel_sub:sub_p[0],
                des_definicion:data.sub_oper[i].descrip_definicion,
                param:sub_p[4]
              }
            );
          }
        }
        this.oper_met = this.param_oper[0].toUpperCase();
        this.formaImg = this.fb.group({
          'archivo':['',[Validators.required]],
          'cod_app': [data.data.cod_app],
          'formdata':[''],
          'cod_empresa': [''],
          'id_relacion_definicion_app': [''],
          'operacion': [this.atrb.ACCION_ACTUALIZAR],
          'tipo': [this.atrb.IMG_1],
          'cod_img': [''],
          'des_img': [''],
          'api': [''],
          'concepto_definicion': ['']
        });
        this.forma = fb.group({
          'tipo': [atrb.PRCP.toUpperCase()],
          'tipo_parametro':[this.atrb.CONSTANTES_APLICACIONES.PRC_GLR_N],
          'tipo_cat': [''],
          'termino': [data.data.tipo_def.toUpperCase()],
          'val_def': ['',Validators.required],
          'val_cat': ['',Validators.required],
          'estatus': ['',Validators.required],
          'constante': [data.data.constante_app],
          'concepto_definicion': [data.data.definicion_app],
          'cod_app': [data.data.cod_app],
          'cod_empresa': [''],
          'archivo':[''],
          'formdata':[''],
          'cod_img':[''],
          'des_img':[''],
          'img_actual':[''],
          'def':[''],
          'id_relacion_definicion_app': [''],
          'cod_pad': [''],
          'api': [''],
          'param':[''],
          'operacion': [this.atrb.ACCION_CREAR],
        });
        this.listarParametroAppInicio();
        this.dataSource.paginator = this.paginator;
      }
    }
  }

async  ngOnInit(){
    if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) <= 1) {
      this.tabla_op = true;
    }
    this.operacion = this.atrb.ACCION_CREAR.toUpperCase();
    this.estatus = this.atrb.ESTATUS;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
  }
  ngOnDestroy() {
    URL.revokeObjectURL(this.objectURL);
  }
  async cargar_img(xvalor:any){
    try {
      await this.util.abrirSpinner();
      this.objectURL = URL.createObjectURL(xvalor.target.files[0]);
     this.archivo = xvalor.target.files[0];
     
    await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
  async cargar_img_dinamica_storage(xval:any){
    try {
        let indice;
        let valor;
        let img;
        let codigo;
        let ruta;
        let xobj;
        let imgArry;
        const value = this.formaTarea.value.inputs;
        xobj = {
          concepto_definicion_codigo:parseInt(xval) === 2 ? await this.atrb.IMG_D1:
                                     parseInt(xval) === 3 ? await this.atrb.IMG_D2:
                                     parseInt(xval) === 7 ? await this.atrb.IMG_D3:'',
          api: await this.hashkey.encrypt(await this.atrb.API_REST.crearCodigo)
        }
        if (this.imgArry.length > 0) {
          for (const i in this.imgArry) {
            imgArry = {
              cod_img:this.imgArry[i],
              api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionarImgRemove)
            }
          const response = await this.servicio.ApiResthttp(imgArry).toPromise();
          console.log(response.message);
          }
          this.imgArry = [];
        }
        if (parseInt(xval) === 7) {
          const  formData = new FormData();
          let value = this.formaTarea.value;
          if (value.op_7) {
            codigo = await this.servicio.ApiResthttp(xobj).toPromise();
            formData.append(await this.atrb.GALERIA.app_img,value.op_7);
            this.forma.patchValue({
              formdata:formData,
            });
            img = await this.servicio.gestionarImgApphttp(formData).toPromise();
            this.forma.patchValue({
              cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
              cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
              cod_img : img.file.path,
              des_img : img.file.filename,
              formdata:formData,
              def:  codigo.resul[0].cod_correlativo,
              api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
            });
            ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
            this.formaTarea.patchValue({
              op_8:ruta.nombre
            });
           }
        }else{
          for (const i in value) {
            const  formData = new FormData();
            if (value[i].file) {
              codigo = await this.servicio.ApiResthttp(xobj).toPromise();
                  formData.append(await this.atrb.GALERIA.app_img,value[i].file);
                  this.forma.patchValue({
                    formdata:formData,
                  });
                  img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                  this.forma.patchValue({
                    cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                    cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                    cod_img : img.file.path,
                    des_img : img.file.filename,
                    formdata:formData,
                    def:  codigo.resul[0].cod_correlativo,
                    api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                  });
                  ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                  const inputGroup = this.inputs.at(parseInt(i)) as FormGroup;
                  inputGroup.patchValue({
                    cod_img:ruta.nombre
                });
            }
          }
        }
        return true;
    } catch (error) {
      console.log(error);
    }
  }
  async cargar_img_dinamica(xvalor:any,num:any){
    try {
      this.archivo = xvalor.target.files[0];
      const  formData = new FormData();
      let img;
      let ruta;
      if (!this.formaTarea.value.op_2) {
        /* this.formaImg.patchValue({
          archivo:''
        }); */
        return false;
      }else{
            if (parseInt(num) === 1) {
                  this.objectURL_0 = URL.createObjectURL(xvalor.target.files[0]);
                              formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_3 :ruta.nombre
                            });  
            }
            if (parseInt(num) === 2) {
              this.objectURL_1 = URL.createObjectURL(xvalor.target.files[0]);
                  
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_4 :ruta.nombre
                            });      
            }
            if (parseInt(num) === 3) {
              this.objectURL_2 = URL.createObjectURL(xvalor.target.files[0]);
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_5 :ruta.nombre
                            });
            }
            if (parseInt(num) === 4) {
              this.objectURL_3 = URL.createObjectURL(xvalor.target.files[0]);
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_6 :ruta.nombre 
                            });
            }
            if (parseInt(num) === 5) {
              this.objectURL_4 = URL.createObjectURL(xvalor.target.files[0]);
                
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_7 :ruta.nombre
                            });
            }
            if (parseInt(num) === 6) {
              this.objectURL_5 = URL.createObjectURL(xvalor.target.files[0]);
                
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_8 :ruta.nombre
                            });
            }
            if (parseInt(num) === 7) {
              this.objectURL_6 = URL.createObjectURL(xvalor.target.files[0]);
                  
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_9 :ruta.nombre
                            });
            }
            if (parseInt(num) === 8) {
              this.objectURL_7 = URL.createObjectURL(xvalor.target.files[0]);
                  
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_10 :ruta.nombre
                            });
            }
            if (parseInt(num) === 9) {
              this.objectURL_8 = URL.createObjectURL(xvalor.target.files[0]);
                
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_11 :ruta.nombre  
                            });
            }
            if (parseInt(num) === 10) {
              this.objectURL_9 = URL.createObjectURL(xvalor.target.files[0]);
                  
                formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                              this.forma.patchValue({
                                formdata:formData,
                              });
                              img = await this.servicio.gestionarImgApphttp(formData).toPromise();
                              this.forma.patchValue({
                                cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                                cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                                formdata:formData,
                                def:await this.atrb.IMG_D1+num,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.editarNombreArchivosHttp),
                              });
                              ruta = await this.servicio.ApiResthttp(this.forma.value).toPromise();
                              this.formaTarea.patchValue({
                                op_12 :ruta.nombre
                            });
            }
            return true;
      }
    } catch (error) {
      console.log(error);
    }
  }
  async cargar_img_avatar(){
    try {
      await this.util.abrirSpinner();
      if (!this.archivo) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.archi_vac);
        return;
      }
      const formData = new FormData();
      formData.append(await this.atrb.GALERIA.app_img,this.archivo);
      this.forma.patchValue({
        formdata:formData,
      });
      const img = await this.servicio.gestionarImgApphttp(this.forma.value.formdata).toPromise();
      this.forma.patchValue({
        cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
        cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
        cod_img : img.file.path,
        des_img : img.file.filename,
        formdata:formData,
        api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
      });
      const response = await this.servicio.ApiResthttp(this.forma.value).toPromise();
      await this.util.cerrarSpinner();
        await this.notifi.success(response.message);
        await this.listarParametroGaleriaOperacional(this.forma.value.nivel_img);
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
  }
  async listarParametroAPP(){
    try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
      var valor = {
        termino:this.data.termino,
        cod_rol: await this.atrb.ROL_USUARIOS.cod_rol,
        cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
        cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarParametroAplicaciones),
      };
     const response = await  this.servicio.ApiResthttp(valor).toPromise();
          let definicion_split;
          let definicion_valor;
        
        await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async listarParametroAppInicio(){
    try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        this.forma.patchValue({
          val_cat:'',
          val_def:'',
          estatus:'',
        });
      var valor = {
        param:this.param_prc_prcs_1,
        id_relacion_definicion_app:this.param_prc_prcs_2,
        cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
        cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarParametroAppInicio),
      };
     const response = await  this.servicio.ApiResthttp(valor).toPromise();
     this.ELEMENT_DATA = [];
     let xval;
     let xarry;
     let oper;
     if (parseInt(this.param_prc_prcs_1) === 6) {
      let tarea = response[0].val_cat.split(',');
      tarea = [...this.util.remplazo_dinamico(await this.atrb.reem_uc,tarea)];
      this.conten_tarea = tarea;
      this.tipo_tarea = parseInt(tarea[2]);
      for (const i in this.sub_param) {
        if (parseInt(this.sub_param[i].nivel_sub) === this.tipo_tarea) {
          this.valid_con = parseInt(this.sub_param[i].param);
          break;
        }
      }
      if (this.tipo_tarea === 0) {
        this.formaTarea = this.fb.group({
          'op_1': ['',[Validators.required]],
          'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'inputs': this.fb.array([]),
        });
        this.formaTarea.patchValue({
          op_1:tarea[2],
          op_2:tarea[3]
        });
        let objeto = [];
            objeto = [
                      {
                          text: tarea[4] ? tarea[4] : '',
                          text1:tarea[5] ? tarea[5]:''
                      },
                      {
                          text: tarea[6] ? tarea[6] : '',
                          text1:tarea[7] ? tarea[7]:''
                      },
                      {
                          text: tarea[8] ? tarea[8] : '',
                          text1:tarea[9] ? tarea[9]:''
                      },
                      {
                          text: tarea[10] ? tarea[10] : '',
                          text1:tarea[11] ? tarea[11]:''
                      },
                      {
                          text: tarea[12] ? tarea[12] : '',
                          text1:tarea[13] ? tarea[13]:''
                      },
                  ];
          for (const i in objeto) {
            if (objeto[i].text !== '' && objeto[i].text1 !== '') {
              this.inputs.push(this.fb.group({
                text:[objeto[i].text,[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
                text1:[objeto[i].text1,[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
            }
          }
        if (this.inputs.length <= 0) {
          this.inputs.push(this.fb.group({
            text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
            text1:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          }));
         }
      }
      if (this.tipo_tarea === 1) {
        this.formaTarea = this.fb.group({
          'op_1': ['',Validators.required],
          'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_4': ['',[Validators.required,Validators.maxLength(60)]],
          'inputs': this.fb.array([]),
        });
        this.formaTarea.patchValue({
          op_1:tarea[2],
          op_2:tarea[3],
          op_3:tarea[4],
          op_4:tarea[5],
        });
        let objeto = [];
            objeto = [
                      {
                          text: tarea[6] ? tarea[6] : ''
                      },
                      {
                          text: tarea[7] ? tarea[7] : ''
                      },
                      {
                          text: tarea[8] ? tarea[8] : ''
                      },
                      {
                          text: tarea[9] ? tarea[9] : ''
                      },
                      {
                          text: tarea[10] ? tarea[10] : ''
                      },
                      {
                          text: tarea[11] ? tarea[11] : ''
                      },
                      {
                          text: tarea[12] ? tarea[12] : ''
                      },
                      {
                          text: tarea[13] ? tarea[13] : ''
                      },
                  ];
          for (const i in objeto) {
            if (objeto[i].text !== '') {
              this.inputs.push(this.fb.group({
                text:[objeto[i].text,[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
            }
          }
          if (this.inputs.length <= 0) {
            this.inputs.push(this.fb.group({
              text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
            }));
          }
      }
      if (this.tipo_tarea === 2) {
        this.formaTarea = this.fb.group({
          'op_1': ['',[Validators.required]],
          'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'inputs': this.fb.array([]),
        });
        this.formaTarea.patchValue({
          op_1:tarea[2],
          op_2:tarea[3]
        });
        let objeto = [];
            objeto = [
                      {
                          img: tarea[4] ? environment.path_storage+tarea[4] : '',
                          cod_img: tarea[4] ? tarea[4] : '',
                          text:tarea[5] ? tarea[5]:''
                      },
                      {
                          img: tarea[6] ? environment.path_storage+tarea[6] : '',
                          cod_img: tarea[6] ? tarea[6] : '',
                          text:tarea[7] ? tarea[7]:''
                      },
                      {
                          img: tarea[8] ? environment.path_storage+tarea[8] : '',
                          cod_img: tarea[8] ? tarea[8] : '',
                          text:tarea[9] ? tarea[9]:''
                      },
                      {
                          img: tarea[10] ? environment.path_storage+tarea[10] : '',
                          cod_img: tarea[10] ? tarea[10] : '',
                          text:tarea[11] ? tarea[11]:''
                      },
                      {
                          img: tarea[12] ? environment.path_storage+tarea[12] : '',
                          cod_img: tarea[12] ? tarea[12] : '',
                          text:tarea[13] ? tarea[13]:''
                      },
                  ];
          for (const i in objeto) {
            if (objeto[i].text !== '' && objeto[i].img !== '') {
              this.inputs.push(this.fb.group({
                text:[objeto[i].text,[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
                file:[null],
                img:[objeto[i].img],
                cod_img:[objeto[i].cod_img],
                cod_file:[objeto[i].img,[Validators.required]],
              }));
            }
          }
          if (this.inputs.length <= 0) {
            this.inputs.push(this.fb.group({
              text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              file:[null],
              img:[await this.atrb.IMAGENES_FIJAS.sin_img_prod],
              cod_img:[''],
              cod_file:['',[Validators.required]],
            }));
          }
      }
      if (this.tipo_tarea === 3) {
        this.formaTarea = this.fb.group({
          'op_1': ['',Validators.required],
          'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_3': ['',Validators.required],
          'inputs': this.fb.array([]),
        });
        this.formaTarea.patchValue({
          op_1:tarea[2],
          op_2:tarea[3],
          op_3:tarea[4],
        });
        let objeto = [];
            objeto = [
                      {
                          img: tarea[5] ? environment.path_storage+tarea[5] : '',
                          cod_img: tarea[5] ? tarea[5] : '',
                      },
                      {
                          img: tarea[6] ? environment.path_storage+tarea[6] : '',
                          cod_img: tarea[6] ? tarea[6] : '',
                      },
                      {
                          img: tarea[7] ? environment.path_storage+tarea[7] : '',
                          cod_img: tarea[7] ? tarea[7] : '',
                      },
                      {
                          img: tarea[8] ? environment.path_storage+tarea[8] : '',
                          cod_img: tarea[8] ? tarea[8] : '',
                      },
                      {
                          img: tarea[9] ? environment.path_storage+tarea[9] : '',
                          cod_img: tarea[9] ? tarea[9] : '',
                      },
                  ];
          for (const i in objeto) {
            if (objeto[i].img !== '') {
              this.inputs.push(this.fb.group({
                file:[null],
                img:[objeto[i].img],
                cod_img:[objeto[i].cod_img],
                cod_file:[objeto[i].img,[Validators.required]],
              }));
            }
          }
          if (this.inputs.length <= 0) {
            this.inputs.push(this.fb.group({
              file:[null],
              img:[await this.atrb.IMAGENES_FIJAS.sin_img_prod],
              cod_img:[''],
              cod_file:['',[Validators.required]],
            }));
           }
      }
      if (this.tipo_tarea === 4) {
        this.formaTarea = this.fb.group({
          'op_1': ['',Validators.required],
          'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_4': ['',[Validators.required,Validators.maxLength(60)]],
          'inputs': this.fb.array([]),
        });
        this.formaTarea.patchValue({
          op_1:tarea[2],
          op_2:tarea[3],
          op_3:tarea[4],
          op_4:tarea[5],
        });
        let objeto = [];
            objeto = [
                      {
                          text: tarea[6] ? tarea[6] : ''
                      },
                      {
                          text: tarea[7] ? tarea[7] : ''
                      },
                      {
                          text: tarea[8] ? tarea[8] : ''
                      },
                      {
                          text: tarea[9] ? tarea[9] : ''
                      },
                      {
                          text: tarea[10] ? tarea[10] : ''
                      },
                      {
                          text: tarea[11] ? tarea[11] : ''
                      },
                      {
                          text: tarea[12] ? tarea[12] : ''
                      },
                      {
                          text: tarea[13] ? tarea[13] : ''
                      },
                  ];
          for (const i in objeto) {
            if (objeto[i].text !== '') {
              this.inputs.push(this.fb.group({
                text:[objeto[i].text,[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
            }
          }
          if (this.inputs.length <= 0) {
            this.inputs.push(this.fb.group({
              text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
            }));
          }
      }
      if (this.tipo_tarea === 5) {
        this.formaTarea = this.fb.group({
          'op_1': ['',Validators.required],
          'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_4': ['',[Validators.required,Validators.maxLength(60)]],
          'inputs': this.fb.array([]),
        });
        this.formaTarea.patchValue({
          op_1:tarea[2],
          op_2:tarea[3],
          op_3:tarea[4],
          op_4:tarea[5],
        });
        let objeto = [];
            objeto = [
                      {
                          text: tarea[6] ? tarea[6] : ''
                      },
                      {
                          text: tarea[7] ? tarea[7] : ''
                      },
                      {
                          text: tarea[8] ? tarea[8] : ''
                      },
                      {
                          text: tarea[9] ? tarea[9] : ''
                      },
                      {
                          text: tarea[10] ? tarea[10] : ''
                      },
                      {
                          text: tarea[11] ? tarea[11] : ''
                      },
                      {
                          text: tarea[12] ? tarea[12] : ''
                      },
                      {
                          text: tarea[13] ? tarea[13] : ''
                      },
                  ];
          for (const i in objeto) {
            if (objeto[i].text !== '') {
              this.inputs.push(this.fb.group({
                text:[objeto[i].text,[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
            }
          }
          if (this.inputs.length <= 0) {
            this.inputs.push(this.fb.group({
              text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
            }));
          }
      }
      if (this.tipo_tarea === 6) {
        this.formaTarea = this.fb.group({
          'op_1': ['',Validators.required],
          'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_4': ['',[Validators.required,Validators.maxLength(60)]],
          'inputs': this.fb.array([]),
        });
        let tare3 = tarea[4].split(' ');
        let tare4 = tarea[5].split(' ');
        await this.getFraseConfigListarPrin(tare3);
        await this.getFraseConfigListarOper(tare4);
        this.formaTarea.patchValue({
          op_1:tarea[2],
          op_2:tarea[3],
          op_3:this.frase_completa_prin,
          op_4:this.frase_completa_oper,
        });
        let objeto = [];
            objeto = [
                      {
                          text: tarea[6] ? tarea[6] : ''
                      },
                      {
                          text: tarea[7] ? tarea[7] : ''
                      },
                      {
                          text: tarea[8] ? tarea[8] : ''
                      },
                      {
                          text: tarea[9] ? tarea[9] : ''
                      },
                      {
                          text: tarea[10] ? tarea[10] : ''
                      },
                      {
                          text: tarea[11] ? tarea[11] : ''
                      },
                      {
                          text: tarea[12] ? tarea[12] : ''
                      },
                      {
                          text: tarea[13] ? tarea[13] : ''
                      },
                  ];
          for (const i in objeto) {
            if (objeto[i].text !== '') {
              this.inputs.push(this.fb.group({
                text:[objeto[i].text,[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
            }
          }
          if (this.inputs.length <= 0) {
            this.inputs.push(this.fb.group({
              text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
            }));
          }
      }
      if (this.tipo_tarea === 7) {
        this.formaTarea = this.fb.group({
          'op_1': ['',Validators.required],
          'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
          'op_4': ['',[Validators.required,Validators.maxLength(60)]],
          'op_5': [''],
          'op_6': ['',[Validators.required]],
          'op_7': [''],
          'op_8': [''],
          'inputs': this.fb.array([]),
        });
        let tare3 = tarea[4].split(' ');
        let tare4 = tarea[5].split(' ');
        await this.getFraseConfigListarPrin(tare3);
        await this.getFraseConfigListarOper(tare4);
        this.formaTarea.patchValue({
          op_1:tarea[2],
          op_2:tarea[3],
          op_3:this.frase_completa_prin,
          op_4:this.frase_completa_oper,
          op_5:tarea[6] ? environment.path_storage+tarea[6] : await this.atrb.IMAGENES_FIJAS.sin_img_prod,
          op_6:tarea[6],
          op_8:tarea[6],
        });
        let objeto = [];
            objeto = [
                      {
                          text: tarea[7] ? tarea[7] : ''
                      },
                      {
                          text: tarea[8] ? tarea[8] : ''
                      },
                      {
                          text: tarea[9] ? tarea[9] : ''
                      },
                      {
                          text: tarea[10] ? tarea[10] : ''
                      },
                      {
                          text: tarea[11] ? tarea[11] : ''
                      },
                      {
                          text: tarea[12] ? tarea[12] : ''
                      },
                      {
                          text: tarea[13] ? tarea[13] : ''
                      },
                      {
                          text: tarea[14] ? tarea[14] : ''
                      },
                  ];
          for (const i in objeto) {
            if (objeto[i].text !== '') {
              this.inputs.push(this.fb.group({
                text:[objeto[i].text,[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
            }
          }
          if (this.inputs.length <= 0) {
            this.inputs.push(this.fb.group({
              text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
            }));
          }
      }
      this.objectURL_2 = tarea[6] ? environment.path_storage+tarea[6] : await this.atrb.IMAGENES_FIJAS.sin_img_prod;
      await this.util.cerrarSpinner();
     }else{
      for (const i in response) {
                      if (response[i].cod_img) {
                        response[i].cod_img = environment.path_storage+response[i].cod_img;
                      }
                    if (response[i].tipo_cat.toUpperCase() === await this.atrb.CONSTANTES_APLICACIONES.PARAM_2_PRC_PRCS.toUpperCase() || 
                        response[i].tipo_cat.toUpperCase() === await this.atrb.CONSTANTES_APLICACIONES.PARAM_3_PRC_PRCS.toUpperCase() || 
                        response[i].tipo_cat.toUpperCase() === await this.atrb.CONSTANTES_APLICACIONES.PARAM_4_PRC_PRCS.toUpperCase() ||
                        response[i].tipo_cat.toUpperCase() === await this.atrb.CONSTANTES_APLICACIONES.PARAM_5_PRC_PRCS.toUpperCase()
                        ) {
                      xval = response[i].val_cat.split(',');
                      xarry = xval[1];
                    
                      if (parseInt(this.param_prc_prcs_2) === parseInt(xval[0])) {
                        this.ELEMENT_DATA.push({
                          id_relacion_definicion_app:response[i].id_relacion_definicion_app.toString(),
                          cod_app:response[i].cod_app,
                          cod_empresa:response[i].cod_empresa,
                          definicion_app:response[i].definicion_app,
                          constante_app:response[i].constante_app,
                          val_def:response[i].val_def,
                          estatus: response[i].estatus,
                          fecha_inicio: response[i].fecha_inicio,
                          hora_inicio: response[i].hora_inicio,
                          tipo_def: response[i].tipo_def,
                          tipo_cat: response[i].tipo_cat,
                          val_cat: xarry,
                          categoria: xval[3] ? xval[3].toUpperCase(): '',
                          cod_img: response[i].cod_img,
                        });
                      }
                    }else{
                      xarry = response[i].val_cat;
                      oper = xarry.split(',');
                      console.log(oper);
                      this.ELEMENT_DATA.push({
                        id_relacion_definicion_app:response[i].id_relacion_definicion_app.toString(),
                        cod_app:response[i].cod_app,
                        cod_empresa:response[i].cod_empresa,
                        definicion_app:response[i].definicion_app,
                        constante_app:response[i].constante_app,
                        val_def:response[i].val_def,
                        estatus: response[i].estatus,
                        fecha_inicio: response[i].fecha_inicio,
                        hora_inicio: response[i].hora_inicio,
                        tipo_def: response[i].tipo_def,
                        tipo_cat: response[i].tipo_cat,
                        val_cat: xarry,
                        categoria: oper[3] ? oper[3].toUpperCase(): '',
                        cod_img: response[i].cod_img,
                      });
                    }
                }
        this.displayedColumns = parseInt(this.param_prc_prcs_1) === 1 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_INICIO : 
                parseInt(this.param_prc_prcs_1) === 2 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_3 :
                parseInt(this.param_prc_prcs_1) === 3 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_1 : 
                parseInt(this.param_prc_prcs_1) === 4 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_3 :
                parseInt(this.param_prc_prcs_1) === 5 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_2 : '' ;
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        if (this.ELEMENT_DATA.length <= 0) {
          await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.escala_0);
        }
        await this.util.cerrarSpinner();
     }
    } catch (error) {
      this.ELEMENT_DATA = [];
      console.log(error),
      this.displayedColumns = parseInt(this.param_prc_prcs_1) === 1 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_INICIO : 
                                parseInt(this.param_prc_prcs_1) === 2 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_3 :
                                parseInt(this.param_prc_prcs_1) === 3 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_1 : 
                                parseInt(this.param_prc_prcs_1) === 4 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_3 :
                                parseInt(this.param_prc_prcs_1) === 5 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_2 : '' ;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  ////////////prototipo para formularios con inputs dinamicos///////////////////
  get inputs():FormArray{
    return this.formaTarea.get('inputs') as FormArray;
  }
async  addInput(){
  const value = this.formaTarea.value.inputs;
  await this.util.abrirSpinner();
  if (this.tipo_tarea === 0) {
    if (value.length === this.valid_con) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_completos);
      return;
    }
    this.inputs.push(this.fb.group({
      text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
      text1:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
    }));
  }
  if (this.tipo_tarea === 1) {
    if (value.length === this.valid_con) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_completos);
      return;
    }
    this.inputs.push(this.fb.group({
      text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]]
    }));
  }
  if (this.tipo_tarea === 2) {
    if (value.length === this.valid_con) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_completos);
      return;
    }
    this.inputs.push(this.fb.group({
      text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
      file:[null],
      img:[await this.atrb.IMAGENES_FIJAS.sin_img_prod],
      cod_img:[''],
      cod_file:['',[Validators.required]],
    }));
  }
  if (this.tipo_tarea === 3) {
    if (value.length === this.valid_con) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_completos);
      return;
    }
    this.inputs.push(this.fb.group({
      file:[null],
      img:[await this.atrb.IMAGENES_FIJAS.sin_img_prod],
      cod_img:[''],
      cod_file:['',[Validators.required]],
    }));
  }
  if (this.tipo_tarea === 4) {
    if (value.length === this.valid_con) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_completos);
      return;
    }
    this.inputs.push(this.fb.group({
      text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]]
    }));
  }
  if (this.tipo_tarea === 5) {
    if (value.length === this.valid_con) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_completos);
      return;
    }
    this.inputs.push(this.fb.group({
      text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]]
    }));
  }
  if (this.tipo_tarea === 6) {
    if (value.length === this.valid_con) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_completos);
      return;
    }
    this.inputs.push(this.fb.group({
      text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]]
    }));
  }
  if (this.tipo_tarea === 7) {
    if (value.length === this.valid_con) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_completos);
      return;
    }
    this.inputs.push(this.fb.group({
      text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]]
    }));
  }
    await this.util.cerrarSpinner();
  }
async removeInput(index:number){
    await this.util.abrirSpinner();
    let formaTarea = this.formaTarea.value;
    if (parseInt(formaTarea.op_1) === 2 || parseInt(formaTarea.op_1) === 3) {
            this.imgArry.push(this.inputs.value[index].cod_img);
        }
        if (this.inputs.length === 1) {
          await this.util.cerrarSpinner();
          this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.input_arry_uno);
          return;
        }else{
          this.inputs.removeAt(index);
          await this.util.cerrarSpinner();
        }
  }
  getValues(){
    const value = this.formaTarea.value.inputs;
    console.log(value);
  }
 async setValues(index:number,xvalor:any){
    let archivo = xvalor.target as HTMLInputElement;
    let img;
    const inputGroup = this.inputs.at(index) as FormGroup;
    console.log(inputGroup);
    if (inputGroup.value.cod_img) {
      this.imgArry.push(inputGroup.value.cod_img);
    }
    if (archivo.files && archivo.files.length > 0) {
      img = archivo.files[0];
      inputGroup.patchValue({file:img});
      const reader = new FileReader();
      reader.onload = (e:any)=>{
        inputGroup.patchValue({
          img:e.target.result,
          cod_file:e.target.result
        });
      }
      reader.readAsDataURL(img);
    }else{
      inputGroup.patchValue({
        img:await this.atrb.IMAGENES_FIJAS.sin_img_prod,
        cod_file:'',
        file:''
      });
    }
  }
 async setValues7(xvalor:any){
    let archivo = xvalor.target as HTMLInputElement;
    let img;
    if (this.formaTarea.value.op_8) {
      this.imgArry.push(this.formaTarea.value.op_8);
    }
    if (archivo.files && archivo.files.length > 0) {
      img = archivo.files[0];
      this.formaTarea.patchValue({op_7:img});
      const reader = new FileReader();
      reader.onload = (e:any)=>{
        this.formaTarea.patchValue({
          op_5:e.target.result,
          op_6:e.target.result
        });
      }
      reader.readAsDataURL(img);
    }else{
      this.formaTarea.patchValue({
        op_5:await this.atrb.IMAGENES_FIJAS.sin_img_prod,
        op_6:'',
        op_7:'',
      });
    }
  }
  ////////////prototipo para formularios con inputs dinamicos///////////////////
  async listarParametroGaleriaOperacional(xvalor:any){
    try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
        this.val_galeria = [];
      var valor = {
        constante_img: this.data.data.tipo_cat,
        nivel_img: xvalor,
        cod_rol: await this.atrb.ROL_USUARIOS.cod_rol,
        cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
        cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarParametroAplicacionesGaleria),
      };
     const response = await  this.servicio.ApiResthttp(valor).toPromise();
            for (const i in response) {
              response[i].val_def = environment.path_storage+response[i].val_def;
              this.val_galeria.push(response[i]);
            }
        await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async modalrespuesta(xvalor:any,num:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result.toUpperCase() !== await this.atrb.CERRAR.toUpperCase()){
            if (this.param_oper[0].toUpperCase() === this.atrb.OPERACIONALES.avatar.toUpperCase()) {
              await this.util.cerrarSpinner();
              await this.cargar_img_avatar();
            }else{
              if (this.param_met[0].toUpperCase() === await this.atrb.FNC.toUpperCase()) {
                let op_2 = this.forma.value.op_2.split('-');
                this.forma.patchValue({
                  cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                  cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                  val_cat: [this.forma.value.op_1.toLowerCase(),op_2[1].toLowerCase(),
                            this.forma.value.op_3.toLowerCase(),this.forma.value.op_4.toLowerCase(),this.forma.value.op_5.toLowerCase(),
                            this.forma.value.op_6.toLowerCase()].join(),
                  api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                });
              }
              if (this.param_met[0].toUpperCase() === await this.atrb.PRC.toUpperCase()) {
                if (this.param_oper[0].toUpperCase() === await this.atrb.OPERACIONALES.stripe.toUpperCase()) {
                  const formData = new FormData();
                  formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                  this.forma.patchValue({
                    formdata:formData,
                  });
                  if (formData.get(await this.atrb.GALERIA.app_img) !== await this.atrb.UNDEFINED) {
                    const img = await this.servicio.gestionarImgApphttp(this.forma.value.formdata).toPromise();
                        this.forma.patchValue({
                          cod_img : img.file.path,
                          des_img : img.file.filename,
                        });
                }else{
                  this.forma.patchValue({
                    cod_img : false,
                    des_img : false,
                  });
                }
                  this.forma.patchValue({
                    cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                    cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                    val_cat: [this.forma.value.op_1,this.forma.value.op_2,this.forma.value.op_3.toLowerCase()].join(),
                    formdata:formData,
                    api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                  });
                }
                if (this.param_oper[0].toUpperCase() === await this.atrb.OPERACIONALES.paypal.toUpperCase()) {
                  const formData = new FormData();
                  formData.append(await this.atrb.GALERIA.app_img,this.archivo);
                  this.forma.patchValue({
                    formdata:formData,
                  });
                      if (formData.get(await this.atrb.GALERIA.app_img) !== await this.atrb.UNDEFINED) {
                          const img = await this.servicio.gestionarImgApphttp(this.forma.value.formdata).toPromise();
                              this.forma.patchValue({
                                cod_img : img.file.path,
                                des_img : img.file.filename,
                              });
                      }else{
                        this.forma.patchValue({
                          cod_img : false,
                          des_img : false,
                        });
                      }
                  this.forma.patchValue({
                    cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                    cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                    val_cat: [this.forma.value.op_1,this.forma.value.op_2,this.forma.value.op_3.toLowerCase()].join(),
                    formdata:formData,
                    api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                  });
                }
                if (this.param_oper[0].toUpperCase() === await this.atrb.OPERACIONALES.smtp.toUpperCase()) {
                  this.forma.patchValue({
                    cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                    cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                    val_cat: [
                              this.forma.value.op_1,
                              this.forma.value.op_2,
                              this.forma.value.op_3,
                              this.forma.value.op_4,
                              this.forma.value.op_5,
                              await this.hashkey.encrypt(this.forma.value.op_6),
                              this.forma.value.op_7.toLowerCase(),
                              this.forma.value.op_8.toLowerCase(),
                            ].join(),
                    api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                  });
                }
                if (this.param_oper[0].toUpperCase() === await this.atrb.OPERACIONALES.membresia.toUpperCase()) {
                  let op_3_m = document.querySelector(`#${'op_3_m'}`) as HTMLInputElement;
                  let op_6_m = document.querySelector(`#${'op_6_m'}`) as HTMLInputElement;
                  let op_9_m = document.querySelector(`#${'op_9_m'}`) as HTMLInputElement;
                  let op_12_m = document.querySelector(`#${'op_12_m'}`) as HTMLInputElement;
                  this.forma.patchValue({
                    cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                    cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                    val_cat: [
                              this.forma.value.op_1.toLowerCase(),
                              this.forma.value.op_2.toLowerCase(),
                              await this.util.remplazo_especial(op_3_m.value),
                              this.forma.value.op_4.toLowerCase(),
                              this.forma.value.op_5.toLowerCase(),
                              await this.util.remplazo_especial(op_6_m.value),
                              this.forma.value.op_7.toLowerCase(),
                              this.forma.value.op_8.toLowerCase(),
                              await this.util.remplazo_especial(op_9_m.value),
                              this.forma.value.op_10.toLowerCase(),
                              this.forma.value.op_11.toLowerCase(),
                              await this.util.remplazo_especial(op_12_m.value),
                            ].join(),
                    api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                  });
                }
                if (this.param_oper[0].toUpperCase() === await this.atrb.OPERACIONALES.inicio.toUpperCase()) {
                  if (parseInt(this.param_prc_prcs_1) === 1) {
                    this.forma.patchValue({
                      tipo_cat: await this.atrb.CONSTANTES_APLICACIONES.PARAM_PRC_PRCS.toUpperCase(),
                      cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                      cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                      operacion: xvalor.toUpperCase() === this.crear ? this.crear : this.actualizar,
                      api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                    });
                  }
                  if (parseInt(this.param_prc_prcs_1) === 2) {
                    this.forma.patchValue({
                      val_cat:[this.param_prc_prcs_2.toLowerCase(),this.forma.value.val_cat.toLowerCase()].join(),
                      tipo_cat: await this.atrb.CONSTANTES_APLICACIONES.PARAM_2_PRC_PRCS.toUpperCase(),
                      cod_pad: this.param_prc_prcs_2.toLowerCase(),
                      cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                      cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                      operacion: xvalor.toUpperCase() === this.crear ? this.crear : this.actualizar,
                      api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                    });
                  }
                  if (parseInt(this.param_prc_prcs_1) === 3) {
                    this.forma.patchValue({
                      val_cat:[this.param_prc_prcs_2.toLowerCase(),this.forma.value.val_cat.toLowerCase()].join(),
                      tipo_cat: await this.atrb.CONSTANTES_APLICACIONES.PARAM_3_PRC_PRCS.toUpperCase(),
                      cod_pad: this.param_prc_prcs_2.toLowerCase(),
                      cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                      cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                      operacion: xvalor.toUpperCase() === this.crear ? this.crear : this.actualizar,
                      api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                    });
                  }
                  if (parseInt(this.param_prc_prcs_1) === 4) {
                    this.forma.patchValue({
                      val_cat:[this.param_prc_prcs_2.toLowerCase(),this.forma.value.val_cat.toLowerCase()].join(),
                      tipo_cat: await this.atrb.CONSTANTES_APLICACIONES.PARAM_4_PRC_PRCS.toUpperCase(),
                      cod_pad: this.param_prc_prcs_2.toLowerCase(),
                      cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                      cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                      operacion: xvalor.toUpperCase() === this.crear ? this.crear : this.actualizar,
                      api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                    });
                  }
                  if (parseInt(this.param_prc_prcs_1) === 5) {
                    this.forma.patchValue({
                      val_cat:[this.param_prc_prcs_2.toLowerCase(),this.forma.value.val_cat.toLowerCase(),0].join(),
                      tipo_cat: await this.atrb.CONSTANTES_APLICACIONES.PARAM_5_PRC_PRCS.toUpperCase(),
                      cod_pad: this.param_prc_prcs_2.toLowerCase(),
                      cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                      cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                      operacion: xvalor.toUpperCase() === this.crear ? this.crear : this.actualizar,
                      api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                    });
                  }
                  if (parseInt(this.param_prc_prcs_1) === 6) {
                    let formaTarea = this.formaTarea.value;
                      if (parseInt(formaTarea.op_1) === 2 || parseInt(formaTarea.op_1) === 3 || parseInt(formaTarea.op_1) === 7) {
                         /* let val =  await this.cargar_img_dinamica(xvalor,num); */
                         let val =  await this.cargar_img_dinamica_storage(formaTarea.op_1);
                         if (!val) {
                          await this.util.cerrarSpinner();
                          await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_titulo);
                          return;
                         }
                       let val_cat = [];
                       let formaTareaInp;
                       val_cat.push(this.padre_tarea.toLowerCase());
                       val_cat.push(this.des_tarea.toLowerCase());
                       val_cat.push(formaTarea.op_1.toLowerCase());
                       val_cat.push(formaTarea.op_2.toLowerCase());
                       formaTareaInp = this.formaTarea.value.inputs;
                       if (parseInt(formaTarea.op_1) === 2) {
                        for (const i in formaTareaInp) {
                         val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].cod_img));
                         val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].text));
                        }
                        }
                       if (parseInt(formaTarea.op_1) === 3) {
                        val_cat.push(formaTarea.op_3.toLowerCase());
                       for (const i in formaTareaInp) {
                        val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].cod_img));
                       }
                       }
                       if (parseInt(formaTarea.op_1) === 7) {
                        let objeto = new Set();
                        let val_prin = formaTarea.op_2.split(' ');
                        if (val_prin.length > 1) {
                          for (const i in val_prin) {
                            objeto.add(val_prin[i]);
                          }
                        }else{
                          objeto.add(val_prin);
                        }
                        
                        for (const i in formaTareaInp) {
                          if (objeto.has(formaTareaInp[i].text.toLowerCase())) {
                            await this.util.cerrarSpinner();
                            return await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_repetido+' '+formaTareaInp[i].text.toUpperCase());
                          }
                          objeto.add(formaTareaInp[i].text);
                        }
                        console.log(objeto);
                        let valor = this.formaTarea.value;
                          val_cat.push(formaTarea.op_3.toLowerCase());
                          val_cat.push(formaTarea.op_4.toLowerCase());
                          val_cat.push(await this.util.remplazo_especial_ruta_img(valor.op_8));
                        for (const i in formaTareaInp) {
                          val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].text));
                        }
                       }
                          this.forma.patchValue({
                            val_cat:val_cat.join(),
                            tipo_cat: await this.atrb.CONSTANTES_APLICACIONES.PARAM_6_PRC_PRCS.toUpperCase(),
                            id_relacion_definicion_app: this.param_prc_prcs_2.toLowerCase(),
                            cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                            cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                            cod_pad: this.padre_tarea,
                            operacion: this.actualizar,
                            cod_img:'',
                            des_img:'',
                            api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                          });
                      }else{
                        let imgArry;
                        if (this.imgArry.length > 0) {
                          for (const i in this.imgArry) {
                            imgArry = {
                              cod_img:this.imgArry[i],
                              api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionarImgRemove)
                            }
                          const response = await this.servicio.ApiResthttp(imgArry).toPromise();
                          console.log(response.message);
                          }
                          this.imgArry = [];
                        }
                        if (parseInt(formaTarea.op_1) === 6) {
                          let val = formaTarea.op_3.split(' ');
                          if (val.length <= 1) {
                            await this.util.cerrarSpinner();
                            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_mayor_prin_1);
                            return;
                          }else{
                            await this.getFraseConfigPrin(val);
                          }
                          let val_ = formaTarea.op_4.split(' ');
                          if (val_.length <= 1) {
                            await this.util.cerrarSpinner();
                            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.param_mayor_oper_1);
                            return;
                          }else{
                            await this.getFraseConfigOper(val_);
                          }
                        }
                        let val_cat = [];
                        let formaTareaInp;
                       val_cat.push(this.padre_tarea.toLowerCase());
                       val_cat.push(this.des_tarea.toLowerCase());
                       val_cat.push(formaTarea.op_1.toLowerCase());
                       val_cat.push(formaTarea.op_2.toLowerCase());
                       formaTareaInp = this.formaTarea.value.inputs;
                       if (parseInt(formaTarea.op_1) === 0) {
                        for (const i in formaTareaInp) {
                          val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].text));
                          val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].text1));
                         }
                       }
                       if (parseInt(formaTarea.op_1) === 1) {
                          val_cat.push(formaTarea.op_3.toLowerCase());
                          val_cat.push(formaTarea.op_4.toLowerCase());
                        for (const i in formaTareaInp) {
                          val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].text));
                         }
                       }
                       if (parseInt(formaTarea.op_1) === 4) {
                          val_cat.push(formaTarea.op_3.toLowerCase());
                          val_cat.push(formaTarea.op_4.toLowerCase());
                        for (const i in formaTareaInp) {
                          val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].text));
                         }
                       }
                       if (parseInt(formaTarea.op_1) === 5) {
                          val_cat.push(formaTarea.op_3.toLowerCase());
                          val_cat.push(formaTarea.op_4.toLowerCase());
                        for (const i in formaTareaInp) {
                          val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].text));
                         }
                       }
                       if (parseInt(formaTarea.op_1) === 6) {
                          val_cat.push(this.frase_completa_prin.toLowerCase());
                          val_cat.push(this.frase_completa_oper.toLowerCase());
                        for (const i in formaTareaInp) {
                          val_cat.push(await this.util.remplazo_especial_ruta_img(formaTareaInp[i].text));
                         }
                       }
                        val_cat = [...this.util.remplazo_dinamico(await this.atrb.reem_cu,val_cat)];
                       console.log(val_cat);
                        this.forma.patchValue({
                          val_cat: val_cat.join(),
                          tipo_cat: await this.atrb.CONSTANTES_APLICACIONES.PARAM_6_PRC_PRCS.toUpperCase(),
                          id_relacion_definicion_app: this.param_prc_prcs_2.toLowerCase(),
                          cod_empresa: await this.hashkey.encrypt(this.data.data.cod_empresa),
                          cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
                          cod_pad: this.padre_tarea,
                          operacion: this.actualizar,
                          api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
                        });
                      } 
                  }
                }
              }
              await this.gestionParametroApp(this.forma.value);
            }
          } else{
            await this.util.cerrarSpinner();
          }
        });
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
    
  }
  async gestionParametroApp(xvalor:any){
    try {
        const response = await this.servicio.ApiResthttp(xvalor).toPromise();
        this.operacion = await this.atrb.ACCION_CREAR.toUpperCase();
        await this.util.cerrarSpinner();
          if (this.param_oper[0].toUpperCase() !== await this.atrb.OPERACIONALES.inicio.toUpperCase()) {
            await this.notifi.success(response.message);
            this.dialogRefp.close('cerrar');
          }else{
            this.listarParametroAppInicio();
            await this.notifi.success(response.message);
          }
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
   }
  async cargarEscalaParam(xvalor:any) {
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      this.accion = 'salir';
      await this.util.cerrarSpinner();
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'auto',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        this.operacion = await this.atrb.ACCION_CREAR.toUpperCase();
        if (result.toUpperCase() !== await this.atrb.CERRAR.toUpperCase()) {
          if (parseInt(this.param_prc_prcs_1) === 1) {
            this.param_prc_prcs_1 = 2;
            this.param_prc_prcs_2 = xvalor.id_relacion_definicion_app;
            this.param_prc_prcs_3.push(this.param_prc_prcs_2);
            await this.util.cerrarSpinner();
            await this.listarParametroAppInicio();
          }else{
            if (parseInt(this.param_prc_prcs_1) === 2) {
              this.param_prc_prcs_1 = 3;
                this.param_prc_prcs_2 = xvalor.id_relacion_definicion_app;
                this.param_prc_prcs_3.push(this.param_prc_prcs_2);
                await this.util.cerrarSpinner();
                await this.listarParametroAppInicio(); 
            }else{
              if (parseInt(this.param_prc_prcs_1) === 3) {
                this.param_prc_prcs_1 = 4;
                this.param_prc_prcs_2 = xvalor.id_relacion_definicion_app;
                this.param_prc_prcs_3.push(this.param_prc_prcs_2);
                await this.util.cerrarSpinner();
                await this.listarParametroAppInicio(); 
              }else{
                if (parseInt(this.param_prc_prcs_1) === 4) {
                  this.param_prc_prcs_1 = 5;
                  this.param_prc_prcs_2 = xvalor.id_relacion_definicion_app;
                  this.param_prc_prcs_3.push(this.param_prc_prcs_2);
                  await this.util.cerrarSpinner();
                  await this.listarParametroAppInicio(); 
                }else{
                  this.param_prc_prcs_1 = 6;
                  this.padre_tarea = this.param_prc_prcs_2;
                  this.param_prc_prcs_2 = xvalor.id_relacion_definicion_app;
                  this.param_prc_prcs_3.push(this.param_prc_prcs_2);
                  await this.util.cerrarSpinner();
                  await this.editarDinamicas(xvalor); 
                }
              }
            }
          }
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async volverEscalaParam(xvalor:any) {
        this.accion = 'salir';
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'auto',
          data: {id:'',
                accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          this.operacion = await this.atrb.ACCION_CREAR.toUpperCase();
          this.formaImg.patchValue({
            archivo:''
          });
          if (result.toUpperCase() !== await this.atrb.CERRAR.toUpperCase()) {
            if (parseInt(this.param_prc_prcs_1) === 2) {
              this.param_prc_prcs_1 = 1;
              this.param_prc_prcs_2 = '';
              this.param_prc_prcs_3 = [];
              await this.util.cerrarSpinner();
              await this.listarParametroAppInicio();
            }else{
              this.param_prc_prcs_3 = this.param_prc_prcs_3.filter(i => i !== this.param_prc_prcs_2);
              if (parseInt(this.param_prc_prcs_1) === 3) {
                this.param_prc_prcs_1 = 2;
                this.param_prc_prcs_2 = this.param_prc_prcs_3[0];
                  await this.util.cerrarSpinner();
                  await this.listarParametroAppInicio(); 
              }else{
                if (parseInt(this.param_prc_prcs_1) === 4) {
                  this.param_prc_prcs_1 = 3;
                  this.param_prc_prcs_2 = this.param_prc_prcs_3[1];
                    await this.util.cerrarSpinner();
                    await this.listarParametroAppInicio(); 
                }else{
                  if (parseInt(this.param_prc_prcs_1) === 5) {
                    this.param_prc_prcs_1 = 4;
                    this.param_prc_prcs_2 = this.param_prc_prcs_3[2];
                      await this.util.cerrarSpinner();
                      await this.listarParametroAppInicio(); 
                  }else{
                    this.param_prc_prcs_1 = 5;
                    this.param_prc_prcs_2 = this.param_prc_prcs_3[3];
                    if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) <= 1) {
                      this.tabla_op = true;
                    }
                    this.tabla = true;
                    this.des_tarea ='';
                    this.imgArry = [];
                    await this.util.cerrarSpinner();
                    await this.listarParametroAppInicio(); 
                  }
                }
              }
            }
          }else{
            await this.util.cerrarSpinner();
          }
        });
  }
  async editarParametro(xvalor:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      await this.util.cerrarSpinner();
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'auto',
          data: { 
                accion:''},
                disableClose: true,
                panelClass: 'pedidos_modal',
                backdropClass: "bdrop" 
        });
        dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result.toUpperCase() !== await this.atrb.CERRAR.toUpperCase()){
            this.operacion = await this.atrb.ACCION_ACTUALIZAR.toUpperCase();
            this.forma.patchValue({
                        val_cat: xvalor.val_cat,
                        val_def: xvalor.val_def,
                        estatus: xvalor.estatus.toUpperCase(),
                        id_relacion_definicion_app:xvalor.id_relacion_definicion_app,
                        operacion:await this.atrb.ACCION_ACTUALIZAR,
            });
            await this.util.cerrarSpinner();
           }else{
            await this.util.cerrarSpinner();
           }
        });
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
    
  }
  async editarDinamicas(xvalor:any){
    try {
      await this.util.abrirSpinner();
          this.formaTarea = this.fb.group({
              'op_1': [xvalor,Validators.required],
              'op_2': ['',Validators.required],
              'op_3': ['',Validators.required],
              'op_4': ['',Validators.required],
              'op_5': ['',Validators.required],
              'op_6': ['',Validators.required],
              'op_7': ['',Validators.required],
              'op_8': ['',Validators.required],
              'op_9': ['',Validators.required],
              'op_10': ['',Validators.required],
              'op_11': ['',Validators.required],
              'op_12': ['',Validators.required],
          });
          this.tabla = false;
          this.tabla_op = false;
          this.des_tarea = xvalor.val_cat;
          await this.util.cerrarSpinner();
          await this.listarParametroAppInicio();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
    
  }
  async editarDinamicasForma(xvalor:any){
    try {
      let formarry = this.formaTarea.value.inputs;
      await this.util.abrirSpinner();
          if (formarry.length > 0) {
              for (const i in formarry) {
                if (formarry[i].cod_img) {
                    if (formarry[i].cod_img !== '') {
                      this.imgArry.push(formarry[i].cod_img);
                    }
                }
              }
          }
          this.tipo_tarea = parseInt(xvalor);
          for (const i in this.sub_param) {
            if (parseInt(this.sub_param[i].nivel_sub) === this.tipo_tarea) {
              this.valid_con = parseInt(this.sub_param[i].param);
              break;
            }
          }
          if (this.tipo_tarea === 0) {
            this.formaTarea = this.fb.group({
              'op_1': [xvalor,[Validators.required]],
              'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'inputs': this.fb.array([]),
            });
            this.inputs.push(this.fb.group({
              text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              text1:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
            }));
          }
          if (this.tipo_tarea === 1) {
            this.formaTarea = this.fb.group({
              'op_1': [xvalor,Validators.required],
              'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_4': ['',[Validators.required,Validators.maxLength(60)]],
              'inputs': this.fb.array([]),
            });
              this.inputs.push(this.fb.group({
                text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
          }
          if (this.tipo_tarea === 2) {
            this.formaTarea = this.fb.group({
              'op_1': [xvalor,[Validators.required]],
              'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'inputs': this.fb.array([]),
            });
            this.inputs.push(this.fb.group({
              text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              file:[null],
              img:[await this.atrb.IMAGENES_FIJAS.sin_img_prod],
              cod_img:[''],
              cod_file:['',[Validators.required]],
            }));
          }
          if (this.tipo_tarea === 3) {
            this.formaTarea = this.fb.group({
              'op_1': [xvalor,Validators.required],
              'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_3': ['',Validators.required],
              'inputs': this.fb.array([]),
            });
            this.inputs.push(this.fb.group({
              file:[null],
              img:[await this.atrb.IMAGENES_FIJAS.sin_img_prod],
              cod_img:[''],
              cod_file:['',[Validators.required]],
            }));
          }
          if (this.tipo_tarea === 4) {
            this.formaTarea = this.fb.group({
              'op_1': [xvalor,Validators.required],
              'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_4': ['',[Validators.required,Validators.maxLength(60)]],
              'inputs': this.fb.array([]),
            });
              this.inputs.push(this.fb.group({
                text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
          }
          if (this.tipo_tarea === 5) {
            this.formaTarea = this.fb.group({
              'op_1': [xvalor,Validators.required],
              'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_4': ['',[Validators.required,Validators.maxLength(60)]],
              'inputs': this.fb.array([]),
            });
              this.inputs.push(this.fb.group({
                text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
          }
          if (this.tipo_tarea === 6) {
            this.formaTarea = this.fb.group({
              'op_1': [xvalor,Validators.required],
              'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_4': ['',[Validators.required,Validators.maxLength(60)]],
              'inputs': this.fb.array([]),
            });
              this.inputs.push(this.fb.group({
                text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
          }
          if (this.tipo_tarea === 7) {
            this.formaTarea = this.fb.group({
              'op_1': [xvalor,Validators.required],
              'op_2': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_3': ['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              'op_4': ['',[Validators.required,Validators.maxLength(60)]],
              'op_5': [await this.atrb.IMAGENES_FIJAS.sin_img_prod],
              'op_6': ['',[Validators.required]],
              'op_7': [''],
              'op_8': [''],
              'inputs': this.fb.array([]),
            });
              this.inputs.push(this.fb.group({
                text:['',[Validators.required,Validators.pattern(await this.atrb.PATRONES.p_comillasdobles),Validators.maxLength(60)]],
              }));
          }
          await this.util.cerrarSpinner();
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  async cerrarmodal() {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
          maxWidth: '100vw',
          height:'auto',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result.toUpperCase() !== await this.atrb.CERRAR.toUpperCase()) {
        await this.util.cerrarSpinner();
        this.dialogRefp.close('cerrar');
      }else{
        await this.util.cerrarSpinner();
      }
    });
  }
  async format(event: any, valor:any, termino:any)
  {
    try {
      var num;
      if (termino.toUpperCase() !== this.atrb.BASE.toUpperCase() && termino.toUpperCase() !== this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
      if (termino.toUpperCase() === this.atrb.BASE.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        num = inputId.value.replace(/\D/g,'');
        if(!isNaN(parseFloat(num))){
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/\D/g, "")
                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          inputId.value = num;
          }else{ 
            if(await this.atrb.NUM.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
              await this.util.notificacionErrorApp(this.atrb.MENSAJES_FIJOS.solo_numeros);
          }
            inputId.value = inputId.value.replace(/[^\d\.]*/g,'');
          }
      }
      if (termino.toUpperCase() === this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getImgConfig(xobjeto:any){
    try {
       await this.util.abrirSpinner();
       if (xobjeto.cod_img !== null) {
         await this.util.cerrarSpinner();
         var dialogRef = this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'100vh',
           data: { 
                 accion: ''},
                 disableClose: true,
                 panelClass: 'pedidos_modal',
                 backdropClass: "bdrop" 
         });
         dialogRef.afterClosed().subscribe(async result => {
           await this.util.abrirSpinner();
           if(result !== await this.atrb.CERRAR){
             for (const i in this.ELEMENT_DATA) {
               if (this.ELEMENT_DATA[i].id_relacion_definicion_app === xobjeto.id_relacion_definicion_app) {
                 this.ELEMENT_DATA[i].cod_img = null;
               }
             }
             this.displayedColumns = parseInt(this.param_prc_prcs_1) === 1 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_INICIO : 
                                parseInt(this.param_prc_prcs_1) === 2 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_1 :
                                parseInt(this.param_prc_prcs_1) === 3 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_1 : 
                                parseInt(this.param_prc_prcs_1) === 4 ?  this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_1 : this.atrb.TABLA_PROCESOS.TABLA_FORM_PARAMETROS_1;
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
             await this.util.cerrarSpinner();
             await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.actualizar_img);
           } else{
             await this.util.cerrarSpinner();
           }
         });
         return;
       }
       if (this.formaImg.invalid) {
         await this.util.cerrarSpinner();
         await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.seleccione_imagen);
           return;
         }
       await this.servicio.consultar_sesionhttp(null).toPromise();
       await this.util.cerrarSpinner();
         var dialogRef = this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'auto',
           data: { 
                 accion:''},
                 disableClose: true,
                 panelClass: 'pedidos_modal',
                 backdropClass: "bdrop" 
         });
         dialogRef.afterClosed().subscribe(async result => {
           await this.util.abrirSpinner();
           if(result !== await this.atrb.CERRAR){
             await this.util.cerrarSpinner();
           const formData = new FormData();
           formData.append(await this.atrb.GALERIA.app_img,this.archivo);
           this.formaImg.patchValue({
             formdata:formData,
           });
           const img = await this.servicio.gestionarImgApphttp(this.formaImg.value.formdata).toPromise();
           this.formaImg.patchValue({
               cod_img : img.file.path,
               des_img : img.file.filename,
               cod_app: await this.hashkey.encrypt(this.data.data.cod_app),
               cod_empresa:await this.hashkey.encrypt(this.data.data.cod_empresa),
               id_relacion_definicion_app:xobjeto.id_relacion_definicion_app,
               api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionParametroAplicaciones),
           });
           const response = await this.servicio.ApiResthttp(this.formaImg.value).toPromise();
             await  this.listarParametroAppInicio();
             await this.notifi.success(response.message);
           } else{
             await this.util.cerrarSpinner();
           }
         });
    } catch (error) {
     console.log(error);
     await this.util.cerrarSpinner();
     await  this.listarParametroAppInicio();
     await this.notifi.customToastNotification(error.error.message);
    }
    }
  async getImgConfigDinamica(xobjeto:any,num:any){
    try {
       await this.util.abrirSpinner();
       if (xobjeto.cod_img !== null && xobjeto.cod_img !== '') {
         await this.util.cerrarSpinner();
         var dialogRef = this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'100vh',
           data: { 
                 accion: ''},
                 disableClose: true,
                 panelClass: 'pedidos_modal',
                 backdropClass: "bdrop" 
         });
         dialogRef.afterClosed().subscribe(async result => {
           await this.util.abrirSpinner();
           if(result !== await this.atrb.CERRAR){
             await this.util.cerrarSpinner();
             await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.actualizar_img);
           } else{
             await this.util.cerrarSpinner();
           }
         });
         return;
       }
       if (this.formaImg.invalid) {
         await this.util.cerrarSpinner();
         await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.seleccione_imagen);
           return;
         }
       await this.servicio.consultar_sesionhttp(null).toPromise();
       await this.util.cerrarSpinner();
         var dialogRef = this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'auto',
           data: { 
                 accion:''},
                 disableClose: true,
                 panelClass: 'pedidos_modal',
                 backdropClass: "bdrop" 
         });
         dialogRef.afterClosed().subscribe(async result => {
           await this.util.abrirSpinner();
           if(result !== await this.atrb.CERRAR){
             await this.util.cerrarSpinner();
           const formData = new FormData();
           formData.append(await this.atrb.GALERIA.app_img,this.archivo);
           this.formaImg.patchValue({
             formdata:formData,
           });
           const img = await this.servicio.gestionarImgApphttp(this.formaImg.value.formdata).toPromise();
             await this.notifi.success(await this.atrb.MENSAJES_FIJOS.img_cargada);
           } else{
             await this.util.cerrarSpinner();
           }
         });
    } catch (error) {
     console.log(error);
     await this.util.cerrarSpinner();
     await this.notifi.customToastNotification(error.error.message);
    }
    }
  async getFraseConfigPrin(val:any){
      try {
        let frase;
                            for (const k in this.frase_coma) {
                              this.frase_coma[k] = '';
                              for (const i in val) {
                                frase =  val[i].replace(',','_');
                                this.frase_coma[k] = frase ;
                                val = val.filter(valor => valor !== val[i]);
                                break;
                               }
                             }
                             let frase_mod_0 = this.frase_coma[0] !== '' ? this.frase_coma[0]:'/';
                            let frase_mod_1 = this.frase_coma[1] !== '' ? this.frase_coma[1]:'/';
                            let frase_mod_2 = this.frase_coma[2] !== '' ? this.frase_coma[2]:'/';
                            let frase_mod_3 = this.frase_coma[3] !== '' ? this.frase_coma[3]:'/';
                            let frase_mod_4 = this.frase_coma[4] !== '' ? this.frase_coma[4]:'/';
                            let frase_mod_5 = this.frase_coma[5] !== '' ? this.frase_coma[5]:'/';
                            this.frase_completa_prin = frase_mod_0+' '+frase_mod_1+' '+frase_mod_2+' '+frase_mod_3+' '+frase_mod_4+' '+frase_mod_5;
      } catch (error) {
        console.log(error);
      }
    }
  async getFraseConfigOper(val:any){
      try {
        let frase;
        this.frase_coma = ['','','','','','',''];
                            for (const k in this.frase_coma) {
                              this.frase_coma[k] = '';
                              for (const i in val) {
                                frase =  val[i].replace(',','_');
                                this.frase_coma[k] = frase ;
                                val = val.filter(valor => valor !== val[i]);
                                break;
                               }
                             }
                            let frase_mod_0 = this.frase_coma[0] !== '' ? this.frase_coma[0]:'/';
                            let frase_mod_1 = this.frase_coma[1] !== '' ? this.frase_coma[1]:'/';
                            let frase_mod_2 = this.frase_coma[2] !== '' ? this.frase_coma[2]:'/';
                            let frase_mod_3 = this.frase_coma[3] !== '' ? this.frase_coma[3]:'/';
                            let frase_mod_4 = this.frase_coma[4] !== '' ? this.frase_coma[4]:'/';
                            let frase_mod_5 = this.frase_coma[5] !== '' ? this.frase_coma[5]:'/';
                            this.frase_completa_oper = frase_mod_0+' '+frase_mod_1+' '+frase_mod_2+' '+frase_mod_3+' '+frase_mod_4+' '+frase_mod_5;
      } catch (error) {
        console.log(error);
      }
    }
    async getFraseConfigListarPrin(val:any){
      try {
        let frase;
        this.frase_coma = ['','','','','','',''];
                            for (const k in this.frase_coma) {
                              for (const i in val) {
                                frase =  val[i].replace('_',',');
                                this.frase_coma[k] = frase ;
                                val = val.filter(valor => valor !== val[i]);
                                break;
                               }
                             }
                             let frase_mod_0 = this.frase_coma[0] !== '/' ? this.frase_coma[0]:'';
                            let frase_mod_1 = this.frase_coma[1] !== '/' ? this.frase_coma[1]:'';
                            let frase_mod_2 = this.frase_coma[2] !== '/' ? this.frase_coma[2]:'';
                            let frase_mod_3 = this.frase_coma[3] !== '/' ? this.frase_coma[3]:'';
                            let frase_mod_4 = this.frase_coma[4] !== '/' ? this.frase_coma[4]:'';
                            let frase_mod_5 = this.frase_coma[5] !== '/' ? this.frase_coma[5]:'';
                            this.frase_completa_prin = frase_mod_0+' '+frase_mod_1+' '+frase_mod_2+' '+frase_mod_3+' '+frase_mod_4+' '+frase_mod_5;
                            this.frase_completa_prin = this.frase_completa_prin.trimEnd();
      } catch (error) {
        console.log(error);
      }
    }
    async getFraseConfigListarOper(val:any){
      try {
        let frase;
                            for (const k in this.frase_coma) {
                              for (const i in val) {
                                frase =  val[i].replace('_',',');
                                this.frase_coma[k] = frase ;
                                val = val.filter(valor => valor !== val[i]);
                                break;
                               }
                             }
                             let frase_mod_0 = this.frase_coma[0] !== '/' ? this.frase_coma[0]:'';
                            let frase_mod_1 = this.frase_coma[1] !== '/' ? this.frase_coma[1]:'';
                            let frase_mod_2 = this.frase_coma[2] !== '/' ? this.frase_coma[2]:'';
                            let frase_mod_3 = this.frase_coma[3] !== '/' ? this.frase_coma[3]:'';
                            let frase_mod_4 = this.frase_coma[4] !== '/' ? this.frase_coma[4]:'';
                            let frase_mod_5 = this.frase_coma[5] !== '/' ? this.frase_coma[5]:'';
                            this.frase_completa_oper = frase_mod_0+' '+frase_mod_1+' '+frase_mod_2+' '+frase_mod_3+' '+frase_mod_4+' '+frase_mod_5;
                            this.frase_completa_oper = this.frase_completa_oper.trimEnd();
      } catch (error) {
        console.log(error);
      }
    }
}
