import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormPedidosComponent } from '../form-pedidos/form-pedidos.component';
import { FormDiferencialCambiarioComponent } from '../form-diferencial-cambiario/form-diferencial-cambiario.component';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};
export const filter_ = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-formm-msg',
  templateUrl: './formm-msg.component.html',
  styleUrls: ['./formm-msg.component.css']
})
export class FormmMsgComponent implements OnInit {

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
stateGroups3: StateGroup[];
dialogSpin:any;
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
deposito:any;
tipo_prod:any;
tipo_lista:any;
documentos:any;
usuarios:any;
tipo_manejo:any;
producto:any = [];
codigo:any = [];
producto2:any = [];
producto3:any = [];
codigo2:any = [];
codigo3:any = [];
lotepro:any = [];
comentario_status:any;
comentario:any;
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
gerencia:any;
dpto:any;
cargo:any;
estatus:any;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  msg:any;
  telRegex:any = /\d{2}-\d{5}\d/;
  Regex:any = /\d{3}/;
  elMensaje=''
  timeOut = false
  segundos = 6
  constructor( 
    private dialog: MatDialog,
    private fb:FormBuilder,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private dialogRef: MatDialogRef<FormmMsgComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data:any,
    private snackBar: MatSnackBar, 
    private servicio:nkServices) {
      console.log(data, 'primero');
    this.accion = data.accion;
    this.comentario = data.estatus;
    this.forma = fb.group({
      'cod_nota_credito':'',
      'cod_solicitud_devolucion':'',
      'cod_documento':'',
      'cod_factura':'',
      'cod_control':'',
      'accion':this.accion
    });
    if (data.cod_control === '') {
      this.forma = fb.group({
        'cod_factura':data.des_producto,
        /* 'cod_control':[data.cod_control,[Validators.pattern(this.telRegex),Validators.required]], */
        'accion':this.accion
      });
    }

    switch (this.accion) {
      case 'eliminar':
        this.forma = fb.group({
          'id':data.id
        });
        break;

        case 'cerrar':
        this.forma = fb.group({
          'id':data.id
        });
        break;

      case 'eliminarmenu':
        this.forma = fb.group({
          'id':data.id
        });
          break;

      case 'eliminarusuariorol':
            this.forma = fb.group({
              'id':data.id,
              'accion':this.accion
            });
          break;
      case 'eliminarunidad':
            this.forma = fb.group({
              'id':data.id,
              'accion':this.accion
            });
          break;
      case 'eliminarclientes':
            this.forma = fb.group({
              'id':data.id,
              'accion':this.accion
            });
          break;
     case 'enviarpedido':
            this.forma = fb.group({
              'id':data.id,
              'accion':this.accion
            });
          break;
     case 'enviarprecios':
            this.forma = fb.group({
              'id':data.id,
              'accion':this.accion
            });
          break;
      case 'recalcular':
            this.forma = fb.group({
              'id':data.id,
              'accion':this.accion
            });
          break;
      case 'enviarmp':
            this.forma = fb.group({
              'id':data.id,
              'accion':this.accion
            });
        break;
      case 'enviarordenprod':
            this.forma = fb.group({
              'id':data.id,
              'accion':this.accion
            });
        break;
      case 'formulacion':
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
      break;
      case 'actualizarprov':
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
      break;
      case 'enviarcliente':
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
      break;
      case 'enviarProdpt':
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
      break;
      case 'enviarmppp':
        this.forma = fb.group({
          'id':data.id,
          'accion':this.accion
        });
        break;
        case 'formulacionmpp':
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
          break;
          case 'enviarordenprodmpp':
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
          case 'recalcularpedido':
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
            break;
            case 'ordenC':
              this.msg = this.accion;
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
            break;
            case 'ordenI':
              this.msg = this.accion;
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
            break;
            case 'ordenE':
              this.msg = this.accion;
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });
            break;
            case 'traslados':
          this.forma = fb.group({
            'id':data.id,
            'accion':this.accion
          });

            break;
            case 'facturar_pedido':
            if (this.data.tipo_pedido === 'F' || this.data.tipo_pedido === 'PFF') {
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'cod_factura':data.des_producto,
                        'cod_control':[data.cod_control,[Validators.pattern(this.telRegex),Validators.maxLength(9),Validators.required]],
                        'comentarios':['',[Validators.maxLength(50)]],
                        'accion':this.accion
                      });
            }else{
              this.msg = this.accion;
              this.forma = fb.group({
                'id':data.id,
                'accion':this.accion,
                'comentarios':[data.cod_control,[Validators.maxLength(50),Validators.required]]
              });
            }

            break;
            case 'anulacion_ventas':
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'comentarios':['',[Validators.maxLength(50)]],
                        'accion':this.accion
                      });
            break;
            case 'facturar_nota':
              this.msg = this.accion;
              this.forma = fb.group({
                'id':data.id,
                'accion':this.accion,
                'comentarios':[data.cod_control,[Validators.maxLength(50),Validators.required]]
              });

            break;
            case 'nota_credito':
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'cod_factura':data.des_producto,
                        'cod_control':[data.cod_control,[Validators.pattern(this.telRegex),Validators.maxLength(9),Validators.required]],
                        'accion':this.accion
                      });
            break;
            case 'nota_credito_devolucion':
              this.msg = this.accion;
                  this.forma = fb.group({
                'cod_solicitud_devolucion':data.cod_solicitud_devolucion,
                'cod_documento':data.cod_documento,
                'cod_nota_credito':['',[Validators.minLength(3),Validators.required]],
                'accion':this.accion
              });
            break;
            case 'dev_nota':
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'cod_factura':data.cod_factura,
                        'cod_dev':[data.cod_control,[Validators.maxLength(11),Validators.required]],
                        'accion':this.accion
                      });
            break;
            case 'tomar_solicitud':
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'comentario_solicitud':['',[Validators.minLength(20),Validators.required]],
                        'accion':this.accion
                      });
            break;
            case 'finalizar_solicitud':
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'comentario_solicitud':['',[Validators.minLength(20),Validators.required]],
                        'accion':this.accion
                      });
            break;
            case 'actualizar_comentarios':
                      
                      this.msg = this.accion;
                      this.comentario_status = this.comentario;
                          this.forma = fb.group({
                        'comentario_solicitud':['',[Validators.minLength(20),Validators.required]],
                        'accion':this.accion
                      });
            break;
            case 'reinicio':
                      
                      this.msg = this.accion;
                      this.comentario_status = this.comentario;
                          this.forma = fb.group({
                        'comentario_solicitud':['',[Validators.minLength(20),Validators.required]],
                        'accion':this.accion
                      });
            break;
            case 'activa_formula':
                      
                      this.msg = this.accion;
                      this.comentario_status = this.comentario;
                          this.forma = fb.group({
                        'comentario':['',[Validators.minLength(20),Validators.required]],
                        'accion':this.accion
                      });
            break;
            case 'crear_definicion_sistema':
               
                      this.msg = this.accion;
                      this.comentario_status = this.comentario;
                          this.forma = fb.group({
                        'comentario':['',[Validators.minLength(1),Validators.required]],
                        'cod_usuario':this.data.cod_usuario,
                        'des_usuario':this.data.des_usuario,
                        'cod_menu':['',[,Validators.required]],
                        'des_menu':'',
                        'accion':this.accion
                      });

                      this.menu = this.forma.get('cod_menu')!.valueChanges
                      .pipe(
                        startWith(''),
                        map(value => this._filterGroup(value))
                      );
                    for (const i in this.data.menu) {
                      this.producto.push(this.data.menu[i].des_menu +'-'+this.data.menu[i].cod_menu);
                      this.codigo.push(this.data.menu[i].cod_producto);
                    }

        this.stateGroups= [{
          letter: 'Menu',
          names: this.producto,
          code: this.producto
        }];
      
            break;
            case 'reportes':
               
                      this.msg = this.accion;
                      this.comentario_status = this.comentario;
                          this.forma = fb.group({
                        'descripcion':['',[Validators.minLength(1),Validators.required]],
                        'unidad':['',[Validators.minLength(1),Validators.required]],
                        'cod_usuario':this.data.cod_usuario,
                        'des_usuario':this.data.des_usuario,
                        'cod_empresa':this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
                        'accion':this.accion
                      });

                      this.unidad = this.forma.get('unidad')!.valueChanges
                      .pipe(
                        startWith(''),
                        map(value => this._filterGroup_(value))
                      );
                    for (const i in this.data.objeto) {
                      this.producto.push(this.data.objeto[i].concepto_definicion);
                      this.codigo.push(this.data.objeto[i].concepto_definicion);
                    }

        this.stateGroups= [{
          letter: 'Unidades',
          names: this.producto,
          code: this.producto
        }];

            break;
            case 'depositos_inventarios':
                                this.msg = this.accion;
                                this.comentario_status = this.comentario;
                                    this.forma = fb.group({
                                  'cod_usuario':this.data.cod_usuario,
                                  'des_usuario':this.data.des_usuario,
                                  'descripcion':['',[Validators.required]],
                                  'des_deposito':['',[Validators.required]],
                                  'descrip_deposito':'',
                                  'tipo_prod':['',[Validators.required]],
                                  'tipo_manejo':['',[Validators.required]],
                                  'descrip_tipo':'',
                                  'cod_empresa':this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
                                  'accion':this.accion
                                });
                                this.deposito = [...this.data.objeto2];
                                this.tipo_prod = [...this.data.objeto];
            break;
            case 'recalcular_tasa':
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'tasa_base':['',[Validators.required]]
                      });
            break;
            case 'dscnt':
                      
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'descuento':['',[Validators.required]],
                        'accion':this.accion
                      });
            break;
            case 'OPVNTS':
                      
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'monto':['',[Validators.required, Validators.maxLength(12)]],
                        'accion':this.accion
                      });
            break;
            case 'LTS':
                      
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'cod_producto':['',[Validators.required]],
                        'des_producto':'',
                        'cod_lote':['',[Validators.required,Validators.pattern(this.Regex)]],
                        'accion':this.accion
                      });

                      this.deposito = this.forma.get('cod_producto')!.valueChanges
                      .pipe(
                        startWith(''),
                        map(value => this._filterGroup(value))
                      );
                    for (const i in this.data.productos) {
                          this.producto.push(this.data.productos[i].cod_producto+'-'+this.data.productos[i].des_producto );
                          this.codigo.push(this.data.productos[i].cod_producto );
                    }

        this.stateGroups= [{
          letter: 'Productos',
          names: this.producto,
          code: this.producto
        }];
            break;
            case 'LTS_A':
                      
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'cant_lote':['',[Validators.required]],
                        'accion':this.accion
                      });    
            break;
            case 'custom':
                    this.msg = this.accion;
                    this.elMensaje = this.data.mensaje
            break;
            case 'EFBCK':
                      
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'cant_producto':['',[Validators.maxLength(6),Validators.required]],
                        'cant_actual':this.data.cant_producto,
                        'accion':this.accion
                      });    
            break;
            case 'crear_lista_precios':
                      
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'tipo_cliente':['',[Validators.required]],
                        'concepto_definicion':[''],
                        'descrip_definicion':[''],
                        'operacion':[''],
                      });
                      this.tipo_lista = this.forma.get('tipo_cliente')!.valueChanges
                      .pipe(
                        startWith(''),
                        map(value => this._filterGroup(value))
                      );
                    for (const i in this.data.tipo_cliente) {
                          this.producto.push(this.data.tipo_cliente[i].concepto_definicion+'-'+ this.data.tipo_cliente[i].descrip_definicion);
                          this.codigo.push(this.data.tipo_cliente[i].concepto_definicion );
                    }

                  this.stateGroups= [{
                    letter: 'Precio',
                    names: this.producto,
                    code: this.producto
                  }];    
            break;
            case 'crear_app':
                      
                      this.msg = this.accion;
                      this.usuarios = [...this.data.usuarios];
                          this.forma = fb.group({
                        'des_app':['',[Validators.required,Validators.maxLength(30)]],
                        'comentarios':['',[Validators.required,Validators.maxLength(100)]],
                        'cod_usuario':['',Validators.required],
                        'cod_empresa':[''],
                        'operacion':[''],
                        'api':['']
                      });   
            break;
            case 'crear_documentos':
                      
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'documento':['',[Validators.required]],
                        'concepto_definicion':[''],
                        'descrip_definicion':[''],
                        'operacion':[''],
                      });
                      this.documentos = this.forma.get('documento')!.valueChanges
                      .pipe(
                        startWith(''),
                        map(value => this._filterGroup(value))
                      );
                    for (const i in this.data.documentos) {
                          this.producto.push(this.data.documentos[i].concepto_definicion+'-'+ this.data.documentos[i].descrip_definicion);
                          this.codigo.push(this.data.documentos[i].concepto_definicion );
                    }

                  this.stateGroups= [{
                    letter: 'Documentos',
                    names: this.producto,
                    code: this.producto
                  }];    
            break;
            case 'crear_estructura':
                      this.msg = this.accion;
                          this.forma = fb.group({
                        'gerencia':['',[Validators.required]],
                        'dpto':['',[Validators.required]],
                        'des_cargo':['',[Validators.required,Validators.maxLength(40)]],
                        'estatus':['',[Validators.required]],
                        'operacion':[data.operacion],
                        'cod_empresa':[''],
                      });
                      this.listarUnidad(null);
                        
            break;
      default:
        break;
    }
    }

  ngOnInit(): void {
  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 );
    }
    console.log(this.stateGroups);
    return this.stateGroups;
 }

 private _filterGroup_(value: string): StateGroup[] {
  console.log(value);
  if (value) {
    return this.stateGroups
      .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
      .filter(group => group.names.length > 0 );
  }
  console.log(this.stateGroups);
  return this.stateGroups;
}

 private filterGroup(value: string): StateGroup[] {
  console.log(value);
  if (value) {
    return this.stateGroups2
      .map(group => ({letter: group.letter, names: filter(group.names, value), code: filter(group.code, value)}))
      .filter(group => group.names.length > 0 );
  }
  console.log(this.stateGroups2);
  return this.stateGroups2;
}

private filterGroup_(value: string): StateGroup[] {
  console.log(value);
  if (value) {
    return this.stateGroups3
      .map(group => ({letter: group.letter, names: filter_(group.names, value), code: filter_(group.code, value)}))
      .filter(group => group.names.length > 0 );
  }
  return this.stateGroups3;
}

  eliminarregistros(res:any){

    if(res == 'eliminar'){
      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
    }
  }

  confirmar_envio(){
    try {
     var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
    maxWidth: '100vw',
    height:'100vh',
        data: {cod_dep:this.data.cod_dep,
                productos:this.data.productos,
                accion:false},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      }); 
      dialogRefp.afterClosed().subscribe(result => {
        if(result !== 'cerrar'){
          dialogRefp.close(result);
          console.log(this.forma.value);
              this.dialogRef.close(this.forma.value);
        }else{
              dialogRefp.close('cerrar');
        }
    });
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
  enviar_porcentaje(){
    try {
     var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
    maxWidth: '100vw',
    height:'100vh',
        data: {cod_dep:this.data.cod_dep,
                productos:this.data.productos,
                accion:false},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      }); 
      dialogRefp.afterClosed().subscribe(result => {
        if(result !== 'cerrar'){
          dialogRefp.close(result);
              this.dialogRef.close(this.forma.value);
        }else{
              dialogRefp.close('cerrar');
        }
    });
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  guardarregistros(res:any){
    if(this.data.tipo_pedido === 'F' || this.data.tipo_pedido === 'PFF'){
          var valor = {cod_control:this.forma.value.cod_control}
          this.servicio.buscarCodControlFacthttp(valor).subscribe(
            response =>{
              this.dialogRef.close(this.forma.value);
            },
            error =>{
              console.log(error);
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition});
            }
          );
        }else{
          this.dialogRef.close(this.forma.value);
        }
  }

  guardar_nota_credito(res:any){
      var valor = {cod_control:this.forma.value.cod_control}
      this.servicio.buscarCodControlNothttp(valor).subscribe(
        response =>{
          this.dialogRef.close(this.forma.value.cod_control);
        },
        error =>{
          console.log(error);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
  }

  consultarDevFactura(res:any){
        var accion = '';
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
                maxWidth: '100vw',
                height:'100vh',
          data: {id:'',
                accion:accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          if(result != 'cerrar'){
         
            this.servicio.consultarDevhttp(this.forma.value).subscribe(
              response =>{
               
                this.dialogRef.close(this.forma.value);
              },
              error =>{
                
                this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition});
              }
            );
          }
        });
  }

  guardar_nota_credito_devolucion(res:any){

    var valor = {cod_nota_credito:this.forma.value.cod_nota_credito,
                 cod_solicitud_devolucion:this.forma.value.cod_solicitud_devolucion,
                  cod_documento:this.forma.value.cod_documento }
         
    this.servicio.devolucionNotasCreditohttp(valor).subscribe(
      response =>{
        console.log(response,'probando')
        this.dialogRef.close(this.forma);
      },
      error =>{
        console.log(error);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
}

  guardar_tomar_solicitud(res:any){
      try {
        var accion = '';
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
                maxWidth: '100vw',
                height:'100%',
          data: {id:'',
                accion:accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          if(result != 'cerrar'){
         
            this.dialogRef.close(this.forma.value);
            /* let resultado = this.eliminarunidad(result.id); */
          }
        });
        
      } catch (error) {
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
}

guardar_actualizar_comentarios(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != 'cerrar'){
     
        this.dialogRef.close(this.forma.value);
        /* let resultado = this.eliminarunidad(result.id); */
      }
    });
    
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

guardar_finalizar_solicitud(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100%',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != 'cerrar'){
     
        this.dialogRef.close(this.forma.value);
        /* let resultado = this.eliminarunidad(result.id); */
      }
    });
    
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

enviar_valor_tasa_base(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != 'cerrar'){
     console.log(this.forma.value);
        this.dialogRef.close(this.forma.value);
        /* let resultado = this.eliminarunidad(result.id); */
      }
    });
    
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

reinicio_estatus_orden(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != 'cerrar'){
     
        this.dialogRef.close(this.forma.value);
        /* let resultado = this.eliminarunidad(result.id); */
      }
    });
    
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

activar_formula(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != 'cerrar'){
     
        this.dialogRef.close(this.forma.value);
        /* let resultado = this.eliminarunidad(result.id); */
      }
    });
    
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

crear_reporte(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != 'cerrar'){
        
        this.dialogRef.close(this.forma.value);
        /* let resultado = this.eliminarunidad(result.id); */
      }
    });
    
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

crear_definicion_sistema(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != 'cerrar'){
        var valor =  this.forma.value.cod_menu.split('-');
        this.forma.value.des_menu = valor[0];
        this.forma.value.cod_menu = valor[1];
        this.dialogRef.close(this.forma.value);
        /* let resultado = this.eliminarunidad(result.id); */
      }
    });
    
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

crear_deposito(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result != 'cerrar'){
        console.log(result,'ver esto');
        var valor = this.forma.value.des_deposito.concepto_definicion;
        var valor2 = this.forma.value.des_deposito.descrip_definicion;
        var valor3 = this.forma.value.tipo_manejo.concepto_definicion;
        var valor4 = this.forma.value.tipo_prod.concepto_definicion;
        var valor5 = this.forma.value.tipo_prod.descrip_definicion;

        this.forma.patchValue({
          descrip_deposito:valor2,
          des_deposito:valor,
          descrip_tipo:valor5,
          tipo_prod:valor4,
          tipo_manejo:valor3,
        });
        this.dialogRef.close(this.forma.value);
      }
    });
    
  } catch (error) {
    console.log(error);
  }
}
crear_lista(xobjeto:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        var valor =  this.forma.value.tipo_cliente.split('-');
        this.forma.patchValue({
          concepto_definicion:valor[0],
          descrip_definicion:valor[1],
          operacion:this.atrb.ACCION_CREAR,
        });
        console.log(this.forma.value,'ver esto');
        
        this.dialogRef.close(this.forma.value);
        /* let resultado = this.eliminarunidad(result.id); */
      }
    });
    
  } catch (error) {
    this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
crear_documentos(xobjeto:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'cerrar'){
        var valor =  this.forma.value.documento.split('-');
        this.forma.patchValue({
          concepto_definicion:valor[0],
          descrip_definicion:valor[1],
          operacion:this.atrb.ACCION_CREAR,
        });
        this.dialogRef.close(this.forma.value);
      }
    });
  } catch (error) {
    console.log(error);
  }
}
async crear_aplicaciones(xobjeto:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result !== 'cerrar'){
        this.forma.patchValue({
          operacion: await this.atrb.ACCION_CREAR,
          cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa,
          cod_usuario: await this.hashkey.encrypt(this.forma.value.cod_usuario),
          api: await this.hashkey.encrypt(await this.atrb.API_REST.gestionAplicaciones),
        });
        this.dialogRef.close(this.forma.value);
      }
    });
  } catch (error) {
    console.log(error);
  }
}
async listarUnidad(xvalor:any){
  try {
    await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      const response = await this.servicio.listarUnidadhttp().toPromise();
      this.cargo = [];
    if (xvalor !== null) {
      console.log(xvalor);
      if (parseInt(xvalor.value.cod_unidad_padre) <= 0) {
        let xobj = [];
      for (const i in response) {
      if (parseInt(xvalor.value.cod_unidad) ===  parseInt(response[i].cod_unidad_padre) ) {
        xobj.push(response[i]);
      }
      }
        this.dpto = xobj;
        await this.util.cerrarSpinner();
      }else{
        let xobj = [];
        for (const i in this.data.cargos) {
          xobj.push(this.data.cargos[i].concepto_definicion);
        }
        this.cargo = xobj;
        await this.util.cerrarSpinner();
      }
  }else{
    let xobj = [];
    for (const i in response) {
     if (parseInt(response[i].cod_unidad_padre) <= 0 && response[i].des_unidad.toUpperCase() !== await this.atrb.GENERAL.toUpperCase()) {
       xobj.push(response[i]);
     }
    }
       this.gerencia = xobj;
       await this.util.cerrarSpinner();
  }
  
  } catch (error) {
    await this.util.cerrarSpinner();
    console.log(error);
    await this.util.notificacionErrorApp(error.error.message);
  }
}
async cargarEstatus(){
  try {
    await this.util.abrirSpinner();
    this.estatus = await this.atrb.ESTATUS;
    await this.util.cerrarSpinner();
  } catch (error) {
    await this.util.cerrarSpinner();
    console.log(error);
  }
}
async guardarEstructura(res:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner()
      if (result !== 'cerrar') {
        await this.util.cerrarSpinner();
        this.forma.patchValue({
          cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
        });
        this.dialogRef.close(this.forma.value);
      }else{
        await this.util.cerrarSpinner()
      }
    });
  } catch (error) {
    console.log(error);
  }
}
/* crear_estructura(xobjeto:any){
  try {
    var accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
            maxWidth: '100vw',
            height:'auto',
      data: {id:'',
            accion:accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'cerrar'){
        var valor =  this.forma.value.documento.split('-');
        this.forma.patchValue({
          concepto_definicion:valor[0],
          descrip_definicion:valor[1],
          operacion:this.atrb.ACCION_CREAR,
        });
        this.dialogRef.close(this.forma.value);
      }
    });
  } catch (error) {
    console.log(error);
  }
} */

enviar_data_lote(){
  try {
   var dialogRefp = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
  maxWidth: '100vw',
  height:'100vh',
      data: {
              accion:false},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    }); 
    dialogRefp.afterClosed().subscribe(result => {
      if(result !== 'cerrar'){
        dialogRefp.close(result);
          
          var des_producto = this.forma.value.cod_producto.split('-');
          this.forma.value.des_producto = des_producto[1];
          this.forma.value.cod_producto = des_producto[0];
          this.forma.value.cod_usuario = localStorage.getItem('cod_usuario');
          this.forma.value.des_usuario = localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido');

            this.dialogRef.close(this.forma.value);
      }else{
            dialogRefp.close('cerrar');
      }
  });
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

enviar_valor_lote(){
  try {
    if (isNaN(parseInt(this.forma.value.cant_lote))) {
      this.snackBar.open('Solo debe usar numeros', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }else{
      var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
    maxWidth: '100vw',
    height:'100vh',
        data: {
                accion:false},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      }); 
      dialogRefp.afterClosed().subscribe(result => {
        if(result !== 'cerrar'){
          dialogRefp.close(result);
            
              this.dialogRef.close(this.forma.value);
        }else{
              dialogRefp.close('cerrar');
        }
    });
    }
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

enviar_valor_producto(){
  try {
    if (isNaN(parseInt(this.forma.value.cant_producto))) {
      this.snackBar.open('Solo debe usar numeros', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }else{
      var dialogRefp = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
    maxWidth: '100vw',
    height:'100vh',
        data: {
                accion:false},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      }); 
      dialogRefp.afterClosed().subscribe(result => {
        if(result !== 'cerrar'){
          dialogRefp.close(result);

          if (parseInt(this.forma.value.cant_producto) > parseInt(this.data.cant_producto)) {
            this.snackBar.open('El valor a cambiar no puede ser mayor al valor actual', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }

          if (parseInt(this.forma.value.cant_producto) <= parseInt(this.data.cant_producto) && parseInt(this.forma.value.cant_producto) > 0) {
            this.dialogRef.close(this.forma.value);
          }

          if ( parseInt(this.forma.value.cant_producto) <= 0) {
            this.snackBar.open('El valor a cambiar debe ser mayor a 0', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
          }
              
        }else{
              dialogRefp.close('cerrar');
        }
    });
    }
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}
diferencial_cambiario(){
  try { 
    var dialogRef
      var valor = {cod_cliente:this.data.objeto.cod_cliente};
    this.servicio.buscarClienteshttp(valor).subscribe(
      response =>{
        console.log(response);
        
            dialogRef = this.dialog.open(FormDiferencialCambiarioComponent, {
             width: '100vw',
                 maxWidth: '100vw',
                 height:'100vh',
             data: {cod_pedido:this.data.objeto.cod_pedido,
                    cod_cliente:this.data.objeto.cod_cliente,
                    des_cliente: this.data.objeto.des_cliente,
                    des_vendedor:this.data.objeto.des_vendedor,
                    cod_lista_precio:this.data.objeto.cod_lista_precio,
                    tipo_cliente:response[0].tipo_cliente,
                    estatus_pedido:this.data.objeto.estatus_pedido,
                     mod_factur:this.data.objeto.mod_factur,
                     accion:this.data.objeto.tipo_pedido},
             disableClose: true,
             panelClass: 'pedidos_modal3',
             backdropClass: "bdrop"
           });
      
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          if(result != 'cerrar' && result != 'salir'){
            console.log(result);
            this.gestionpedidos_diferencial_cambiario(result);
          }
        });
       
          /*     dialogRef.afterClosed().subscribe(result => {
                if(result != 'cerrar'){
                  console.log(result);

                let resultado = this.gestionpedidos(result);
                }
              });
          */
      },
      error =>{
      }
    );
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition});
  }
}

gestionpedidos_diferencial_cambiario(xvalor:any){

  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });
  setTimeout(() => {
          xvalor.val_def = 'P';
          xvalor.tipo_operacion = 'PEDIDOS';
          xvalor.cod_usuario = localStorage.getItem('cod_usuario');
    this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
      response =>{
        var resultado = this.servicio.gestionpedido_factura_prohttp(xvalor).subscribe(
          response =>{
           if(response){
             this.dialogSpin.close();
                 this.snackBar.open(response.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
           }
          },
          error =>{
           this.dialogSpin.close();
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
      },
      error=>{
        this.dialogSpin.close();
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }, 1000);
   return null;
 }

 async cargarUmDep(xtermino:any){
  try {
      await this.util.abrirSpinner();
      let xvalor = [];
      this.forma.patchValue({
        tipo_manejo:''
      });
      if (xtermino.value.concepto_definicion.toUpperCase() === await this.atrb.TIPO_PRODUCTO.PT.toUpperCase()) {
        for (const i in this.data.objeto3) {
          if (this.data.objeto3[i].concepto_definicion.toUpperCase() !== await this.atrb.GRS) {
            xvalor.push(this.data.objeto3[i]);
          }
        }
        this.tipo_manejo = [...xvalor];
          await this.util.cerrarSpinner();
      }
      if (xtermino.value.concepto_definicion.toUpperCase() === await this.atrb.TIPO_PRODUCTO.PTE.toUpperCase() ||
          xtermino.value.concepto_definicion.toUpperCase() === await this.atrb.TIPO_PRODUCTO.PTO.toUpperCase()) {
        for (const i in this.data.objeto3) {
          if (this.data.objeto3[i].concepto_definicion.toUpperCase() !== await this.atrb.GRS && 
              this.data.objeto3[i].concepto_definicion.toUpperCase() !== await this.atrb.UNDP &&
              this.data.objeto3[i].concepto_definicion.toUpperCase() !== await this.atrb.UM) {
            xvalor.push(this.data.objeto3[i]);
          }
        }
        this.tipo_manejo = [...xvalor];
          await this.util.cerrarSpinner();
      }
      if (xtermino.value.concepto_definicion.toUpperCase() === await this.atrb.TIPO_PRODUCTO.MP.toUpperCase() ||
          xtermino.value.concepto_definicion.toUpperCase() === await this.atrb.TIPO_PRODUCTO.MPP.toUpperCase()) {
        for (const i in this.data.objeto3) {
          if (this.data.objeto3[i].concepto_definicion.toUpperCase() === await this.atrb.GRS) {
            xvalor.push(this.data.objeto3[i]);
          }
        }
        this.tipo_manejo = [...xvalor];
          await this.util.cerrarSpinner();
      }
      if (xtermino.value.concepto_definicion.toUpperCase() === await this.atrb.TIPO_PRODUCTO.ME.toUpperCase() ||
          xtermino.value.concepto_definicion.toUpperCase() === await this.atrb.TIPO_PRODUCTO.MT.toUpperCase() ||
          xtermino.value.concepto_definicion.toUpperCase() === await this.atrb.TIPO_PRODUCTO.EM.toUpperCase()) {
        for (const i in this.data.objeto3) {
          if (this.data.objeto3[i].concepto_definicion.toUpperCase() !== await this.atrb.GRS && 
              this.data.objeto3[i].concepto_definicion.toUpperCase() !== await this.atrb.UNDP && 
              this.data.objeto3[i].concepto_definicion.toUpperCase() !== await this.atrb.UM) {
            xvalor.push(this.data.objeto3[i]);
          }
        }
        this.tipo_manejo = [...xvalor];
          await this.util.cerrarSpinner();
      }
    } catch (error) {
      await this.util.cerrarSpinner();
      console.log(error);
    }
 }
 //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////
 //////////////////////FUNCION PERFECTA PARA DIGITOS/////////////////////////

  async format(event: any, valor:any, termino:any)
  {
    try {
      var num;
      if (termino.toUpperCase() !== this.atrb.BASE.toUpperCase() && termino.toUpperCase() !== this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
          this.forma.patchValue({
            monto:inputId.value
          });
      }
      if (termino.toUpperCase() === this.atrb.BASE.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        num = inputId.value.replace(/\D/g,'');
        if(!isNaN(parseFloat(num))){
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/\D/g, "")
                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
          inputId.value = num;
          }else{ 
            if(await this.atrb.NUM.indexOf(event.key) == -1 && event.key != 'Enter' && event.key != 'Backspace'){
              await this.util.notificacionErrorApp(this.atrb.MENSAJES_FIJOS.solo_numeros);
          }
            inputId.value = inputId.value.replace(/[^\d\.]*/g,'');
          }
      }
      if (termino.toUpperCase() === this.atrb.TASA.toUpperCase()) {
        let inputId = document.querySelector(`#${valor}`) as HTMLInputElement;
        if(inputId.value.length == 1){
          let noa = inputId.value.split('')
          noa.unshift('0')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length == 3){
          let noa = inputId.value.split('')
          noa.unshift('0')
          inputId.value = noa.join('')
        }else if(inputId.value.length >= 4 ){
          let noa:any = inputId.value.split('')
          for(let i = 0 ; i < 2; i++){
            if(noa[0] == 0){
              noa.shift()
            }else{
              break;
            }
          }
          inputId.value = noa.join('')
        }
        let counter = 0
        let comaExiste = false
        let num = inputId.value.replace(/\D/g,'');
        const array = num.replace(/\D/g, "").replace(/([0-9])([0-9]{2})$/, '$1,$2').split('')
          for(let i = array.length - 1; i > -1 ; i--){
            if(array[i] === ','){
              comaExiste = true
            }
            if(comaExiste){
              if(counter === 3){
                if(array[i-1]){
                  array.splice(i,0,'.')
                  counter = 1
                }
              }else{
                counter++
              }
            }
          }
          inputId.value = array.join('');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async cerrarmodal(){
    await this.util.abrirSpinner();
    this.dialogRef.close('cerrar');
    await this.util.cerrarSpinner();
  }

  salir(): void {
    this.dialogRef.close('salir');
  }

  continuar(): void {
    this.dialogRef.close('continuar');
  }
}
