import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';

@Component({
  selector: 'app-form-rolmenu',
  templateUrl: './form-rolmenu.component.html',
  styleUrls: ['./form-rolmenu.component.css']
})
export class FormRolmenuComponent implements OnInit {

  nombre:any;
forma:FormGroup;
accion:any;
unidad:any;
menu:any;
roles:any;
  rol = [];
  menur:any;
  submenu = [];
  xmenu = [];
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());

  constructor(private service:nkServices, fb:FormBuilder,
    public dialogRef: MatDialogRef<FormRolmenuComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
      console.log(data, 'primero');
    this.accion = data.accion;
    this.unidad = data.unidad;
    this.menu = data.codmenu;
    this.roles = data.roles;
      this.forma = fb.group({
        'cod_rol_menu':data.cod_rol_menu,
        'codrolmenu':data.cod_rol_menu,
        'cod_rol':data.cod_rol,
        'codrol':data.cod_rol,
        'des_rol':data.des_rol,
        'desrol':data.des_rol,
        'cod_menu':data.cod_menu,
        'codmenu':data.cod_menu,
        'des_menu':data.des_menu,
        'desmenu':data.des_menu,
        'tipo_menu':data.padre_menu,
        'cod_unidad':this.unidad,
        'des_unidad':data.des_unidad,
      });
    }
  ngOnInit(): void {
  }
  
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  actualizardatos(res:any){
    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
    }else{
    console.log(this.forma.value);
    this.dialogRef.close(this.forma.value);
    }
    
  }
  cargarmenu(cod:any){
    console.log(cod.value);
     this.service.buscarMENUhttp(cod.value).subscribe(
      response =>{
        this.xmenu = [];
        console.log('('+this.menu[0].cod_menu+')'+' '+this.menu[0].des_menu );
        this.menur = '('+this.menu[0].cod_menu+')'+' '+this.menu[0].des_menu;
        /* this.forma.controls['menu'].setValue(this.menur); */
        console.log(response[0]);
        for (const i in response[0]) {
          
          console.log(response[0][i]);
          
          if (response[0][i].tipo_menu != 0 || response[0][i].tipo_menu != '0'  ) {
            this.xmenu.push(response[0][i]);
          } 
        }
      },
      error =>{
        console.log(error.error[0].message);
      }
    ); 
  }

  cerrarmodal(): void {
    this.dialogRef.close('cerrar');
  }

}
