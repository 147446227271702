
<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>CALIDAD PRODUCTOS TERMINADOS </b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarCuaentenaProdTerminadoLote("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                </ul>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row " style="height: 78.7%;">

        <div class="col-md-12 px-0  border h-100 " style="background-color: steelblue; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class="table-secondary text-center table-striped table-condensed table-hover">
                <ng-container matColumnDef="cod_orden_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Ord Produccion</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cod_orden_producto}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cant_orden">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cantidad ord</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cant_orden}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cod_lote">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Lote</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cod_lote}} </td>
                   
                </ng-container>
                <ng-container matColumnDef="operacion">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Operación</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.operacion}} </td>
                   
                </ng-container>
                <ng-container matColumnDef="tipo_lote">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Tipo lote</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.tipo_lote}} </td>
                   
                </ng-container>
                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cod_producto}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="um_prod">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>UM</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.um_prod}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.des_producto}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cant_producto}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cant_blts">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Bultos</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.cant_blts}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.estatus}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha ini</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.fecha_inicio}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora ini</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.hora_inicio}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="fecha_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha fin</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.fecha_fin}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="hora_fin">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora fin</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 10px; "> {{element.hora_fin}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="liberar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Liberar productos</strong></h5>
                    </th>
                    <td [ngClass]="{'lote_und':  element.tipo_lote === 'UND','lote_um':  element.tipo_lote === 'UM','dev_prod':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 10px;"  >
                        <button *ngIf="special" matTooltip="LIBERAR PRODUCTOS" mat-raised-button (click)="unlock_product(element)" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(37, 47, 119);" class=" rounded-pill text-center">
                            <mat-icon >done_all</mat-icon>
                        </button>
                        <button *ngIf="!special" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);" class=" rounded-pill text-center" >
                            <mat-icon >done_all</mat-icon>
                        </button>
                    </td>
                   
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
 </div>
</div>