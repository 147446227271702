import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormAppEmpresaComponent } from 'src/app/componentes/shared/modal/form-app-empresa/form-app-empresa.component';
import { FormDocumentosEmpresaComponent } from 'src/app/componentes/shared/modal/form-documentos-empresa/form-documentos-empresa.component';
import { FormmMsgComponent } from 'src/app/componentes/shared/modal/formm-msg/formm-msg.component';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { nkServices } from 'src/app/componentes/shared/servicios/app.nkservicios.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-gestionar-app',
  templateUrl: './gestionar-app.component.html',
  styleUrls: ['./gestionar-app.component.css']
})
export class GestionarAppComponent implements OnInit {

  documentos:any = [];
  parametros:any = [];
  funcionalidad:any = [];
  operacionalidad:any = [];
  sub_operacionalidad:any = [];
  aplicaciones:any = [];
  rol:any;
  usuarios:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();

  constructor(
    private servicio:nkServices, 
    public dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ) {
      this.rol = hashkey.decrypt(atrb.ROL_USUARIOS.cod_rol);
    }

  ngOnInit(){
    this.listarAplicaciones(null);
    this.listardatos();
    this.buscardefiniciones();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
  }

  /////////////////////////////////////////////////////////////////////
async listarAplicaciones(msj:any){
    try {
      let msg = msj;
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      let xvalor = {
        cod_rol: await this.atrb.ROL_USUARIOS.cod_rol,
        cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa,
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarAplicaciones),
      }
       const response = await this.servicio.ApiResthttp(xvalor).toPromise();
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    this.ELEMENT_DATA.push({
                                cod_app:response[i].cod_app,
                                des_app:response[i].des_app,
                                comentarios:response[i].comentarios,
                                cod_empresa: response[i].cod_usuario,
                                estatus:response[i].estatus,
                                fecha_inicio:response[i].fecha_inicio,
                                hora_inicio:response[i].hora_inicio
                              });
                  }
                  this.displayedColumns = await this.atrb.TABLA_GESTION_APP;
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                  if(msg === await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                      await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                  }else{
                    await this.util.cerrarSpinner();
                  }
    } catch (error) {
      console.log(error);
      this.ELEMENT_DATA = [];
      this.displayedColumns = await this.atrb.TABLA_GESTION_APP;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(await error.error.message);
    }
  }
async  listardatos(){
    try {
      var xobjeto = {
        cod_rol:this.atrb.ROL_USUARIOS.cod_rol,
        cod_empresa:this.atrb.COD_EMPRESA.cod_empresa,
        cod_app: await this.hashkey.encrypt(environment.path_token),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.listarUsuarios)
      }
     const response =  await this.servicio.ApiResthttp(xobjeto).toPromise();

     for (const i in response) {
      if (parseInt(response[0][i].cod_rol) === 2 ) {
        this.usuarios.push(response[0][i]);
      }
     }
    } catch (error) {
      console.log(error);
    }
  }
async buscardefiniciones(){
    try {
      var xvalor = {
        cod_menu: localStorage.getItem('sub_main'),
        api: await this.hashkey.encrypt(await this.atrb.API_REST.buscardefiniciones),
      };
        const response = await this.servicio.ApiResthttp(xvalor).toPromise();
            this.parametros = [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === this.atrb.APLICACIONES.parametros_aplicaciones.toUpperCase()) {
                this.parametros.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() === this.atrb.APLICACIONES.funcionalidad_aplicaciones.toUpperCase()) {
                this.funcionalidad.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() === this.atrb.APLICACIONES.operacionalidad_aplicaciones.toUpperCase()) {
                this.operacionalidad.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() === this.atrb.APLICACIONES.sub_operacionalidad_aplicaciones.toUpperCase()) {
                this.sub_operacionalidad.push(response[i]);
              }
            }
    } catch (error) {
      console.log(error.error.message);
    }
  }
async  update_insert_data(obj:any, text:any){
  try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
                        if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def;
                                val_def = {
                                  val_def:await this.atrb.ACCESO_VALDEF.gestionar_aplicaciones,
                                  tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                                  cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                  api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                }
                            await this.servicio.ApiResthttp(val_def).toPromise();
                      }
                          await this.util.cerrarSpinner();
                          this.accion = text;
                          var dialogRef = this.dialog.open(FormmMsgComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {objeto:obj,
                                    accion:this.accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal_perfiles',
                            backdropClass: "bdrop"
                          });
                        }else{
                          if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                            let val_def;
                                val_def = {
                                  val_def:await this.atrb.ACCESO_VALDEF.gestionar_aplicaciones,
                                  tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                                  cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                  api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                                }
                            await this.servicio.ApiResthttp(val_def).toPromise();
                            let objeto = {
                              cod_empresa: this.atrb.COD_EMPRESA.cod_empresa,
                              mod_usuario: this.atrb.MOD_USUARIO.mod_usuario,
                              des_parametro: await this.atrb.OPERACIONES.aplicaciones,
                              api: await this.hashkey.encrypt(await this.atrb.API_REST.validarLicenciaProcesos)
                            };
                            await this.servicio.ApiResthttp(objeto).toPromise();
                      }
                          await this.util.cerrarSpinner();
                          this.accion = text;
                           dialogRef = this.dialog.open(FormmMsgComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {
                              usuarios:this.usuarios,
                              accion: await this.atrb.PARAMETROS_MSM.crear_app
                            },
                            disableClose: true,
                            panelClass: 'pedidos_modal_rep',
                            backdropClass: "bdrop"
                          });
                        }
                        dialogRef.afterClosed().subscribe(async result => {
                          await this.util.abrirSpinner();
                          if(result !== await this.atrb.CERRAR){
                            this.gestionApliacaciones(result);
                          }else{
                            await this.util.cerrarSpinner();
                          }
                        });
  } catch (error) {
    console.log(error);
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}
async  definicionesAplicaciones(obj:any,termino:any){
  try {
        await this.util.abrirSpinner();
        let parametros = [];
        let definicion_split;
        let definicion_valor;
        if (termino.toUpperCase() === await this.atrb.DEF.toUpperCase()) {
          for (const i in this.parametros) {
            definicion_split = this.parametros[i].concepto_definicion.split('_');
            definicion_valor = definicion_split[0];
            if (definicion_valor.toUpperCase() !== await this.atrb.FNC.toUpperCase()) {
              parametros.push(this.parametros[i]);
              
            }
          }
        }
        if (termino.toUpperCase() === await this.atrb.FUNC.toUpperCase()) {
          for (const i in this.parametros) {
            definicion_split = this.parametros[i].concepto_definicion.split('_');
            definicion_valor = definicion_split[0];
            if (definicion_valor.toUpperCase() === await this.atrb.FNC.toUpperCase()) {
              parametros.push(this.parametros[i]);
              
            }
          }
        }
        if (termino.toUpperCase() === await this.atrb.OPER.toUpperCase()) {
          for (const i in this.parametros) {
            definicion_split = this.parametros[i].concepto_definicion.split('_');
            definicion_valor = definicion_split[0];
            if (definicion_valor.toUpperCase() === await this.atrb.PRC.toUpperCase()) {
              parametros.push(this.parametros[i]);
              
            }
          }
        }
     
        await this.servicio.consultar_sesionhttp(null).toPromise();
                        if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                          let val_def;
                              val_def = {
                                val_def:await this.atrb.ACCESO_VALDEF.gestionar_aplicaciones,
                                tipo_operacion:await this.atrb.ACCESO_OPERACION.store_procedure,
                                cod_usuario : await this.atrb.COD_PERFIL.cod_perfil,
                                api: await this.hashkey.encrypt(await this.atrb.API_REST.validar_acceso_acciones_http)
                              }
                          await this.servicio.ApiResthttp(val_def).toPromise();
                    }
                          await this.util.cerrarSpinner();
                        let dialogRef = this.dialog.open(FormAppEmpresaComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {
                              data:obj,
                              termino:termino.toUpperCase(),
                              parametros:parametros,
                              funcionalidad:this.funcionalidad,
                              operacionalidad:this.operacionalidad,
                              sub_operacionalidad:this.sub_operacionalidad,
                             accion:''
                            },
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                        dialogRef.afterClosed().subscribe(async result => {
                          if(result != 'cerrar'){
                            await this.util.cerrarSpinner();
                          }else{
                            await this.util.cerrarSpinner();
                          }
                        });
  } catch (error) {
    await this.util.cerrarSpinner();
    console.log(error);
    await this.notifi.customToastNotification(error.error.message);
  }
}

async gestionApliacaciones(xvalor:any){
      try {
          const response = await this.servicio.ApiResthttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
          await this.listarAplicaciones(null);
          await this.notifi.success(response.message);
      } catch (error) {
        console.log(error);
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
        await this.listarAplicaciones(null);
      }
   }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
