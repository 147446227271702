import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { FormDailyVistaDocumentoComponent } from '../form-daily-vista-documento/form-daily-vista-documento.component';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { FormDailyVistaPreviaComponent } from '../form-daily-vista-previa/form-daily-vista-previa.component';
import { PdfServices } from '../../servicios/app.pdf.services';
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import { FormPedidosNBsUmComponent } from '../form-pedidos-n-bs-um/form-pedidos-n-bs-um.component';
import { FormPedidosNBsComponent } from '../form-pedidos-n-bs/form-pedidos-n-bs.component';
import { FormPedidosNUmComponent } from '../form-pedidos-n-um/form-pedidos-n-um.component';
import { FormPedidosNComponent } from '../form-pedidos-n/form-pedidos-n.component';
import { FormPedidosUmComponent } from '../form-pedidos-um/form-pedidos-um.component';
import { FormPedidosComponent } from '../form-pedidos/form-pedidos.component';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-form-backorder',
  templateUrl: './form-backorder.component.html',
  styleUrls: ['./form-backorder.component.css']
})
export class FormBackorderComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_cliente',{static:true}) cod_cliente:ElementRef;
  @ViewChild('des_cliente',{static:true}) des_cliente:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

value1 = '';
nombre:any;
forma:FormGroup;
stateForm: FormGroup;

obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dialogSpin:any;
  cod_nota_credito:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
tasa_precio:any;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog,
     private snackBar: MatSnackBar,
      private servicio:nkServices,
      private pdf: PdfServices,
      private formb:FormBuilder,
      private fb:FormBuilder,
      private dialogRefp: MatDialogRef<FormDailyVistaPreviaComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

    }

    ngOnInit(): void {
      this.listarBackOrder(null);
    }
    @ViewChild(MatPaginator) paginator: MatPaginator;
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
    }
    listarBackOrder(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        let msg = msj;
        this.servicio.listarBackOrderHttp(this.data).subscribe(
          response =>{
            this.dialogSpin.close();
            ////////////////UNIDADES//////////////////////
            this.ELEMENT_DATA = [];
           console.log(response);
                  for (const i in response) {

                    this.ELEMENT_DATA.push({
                      cod_backorder:response[i].cod_backorder,
                      cod_documento:response[i].cod_documento,
                      cod_lista_precio: response[i].cod_lista_precio,
                      cod_cliente:response[i].cod_cliente,
                      tipo_pedido:response[i].tipo_pedido,
                      des_cliente:this.data.des_cliente,
                      cod_usuario:response[i].cod_usuario,
                      estatus:response[i].estatus,
                      fecha_inicio: response[i].fecha_inicio,
                      hora_inicio:response[i].hora_inicio
                    });
  
                  }
                  
                this.displayedColumns = [
                  'cod_backorder',
                  'cod_documento',
                  'cod_cliente',
                  'des_cliente',
                  'cod_usuario',
                  'fecha_inicio',
                  'hora_inicio',
                  'detalles',
                  'descargar',
                  'enviar'
                ];
                                          
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.ELEMENT_DATA = [];
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition});
              this.displayedColumns = [
                'cod_backorder',
                'cod_documento',
                'cod_cliente',
                'des_cliente',
                'cod_usuario',
                'fecha_inicio',
                'hora_inicio',
                'detalles',
                'descargar',
                'enviar'
              ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 1000);
      
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }

  update_insert_data(obj:any){
    var dialogRef
    var valor = {cod_cliente:obj.cod_cliente};
  this.servicio.buscarClienteshttp(valor).subscribe(
    response =>{
      console.log(response);
      console.log(obj);
  switch (obj.tipo_pedido) {
    case 'F':
      if (response[0].mod_factur) {
        if (response[0].mod_factur === 'UNDP' || response[0].mod_factur === 'UND') {
         
          dialogRef = this.dialog.open(FormPedidosComponent, {
           width: '100vw',
               maxWidth: '100vw',
               height:'100vh',
           data: {cod_pedido:obj.cod_documento,
                  cod_cliente:obj.cod_cliente,
                  des_cliente: obj.des_cliente,
                  des_vendedor:response[0].des_vendedor,
                  tipo_cliente:response[0].tipo_cliente,
                  estatus_pedido:obj.estatus_pedido,
                   mod_factur:response[0].mod_factur,
                   accion:obj.tipo_pedido},
           disableClose: true,
           panelClass: 'pedidos_modal3',
           backdropClass: "bdrop"
         });
        }else{
         
          dialogRef = this.dialog.open(FormPedidosUmComponent, {
           width: '100vw',
               maxWidth: '100vw',
               height:'100vh',
           data: {cod_pedido:obj.cod_documento,
                  cod_cliente:obj.cod_cliente,
                  des_cliente: obj.des_cliente,
                  des_vendedor:response[0].des_vendedor,
                  tipo_cliente:response[0].tipo_cliente,
                  estatus_pedido:obj.estatus_pedido,
                   mod_factur:response[0].mod_factur,
                   accion:obj.tipo_pedido},
           disableClose: true,
           panelClass: 'pedidos_modal3',
           backdropClass: "bdrop"
         });
        }
       
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if(result != 'cerrar' && result != 'salir'){
          console.log(result);
          result.cod_backorder = obj.cod_backorder;
         let resultado = this.gestionpedidos(result);
        }
      });
      }else{
        this.snackBar.open('Este cliente no tiene unidad de manejo definida', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
   
      break;
      case 'PF':
      if (response[0].mod_factur) {
        if (response[0].mod_factur === 'UNDP' || response[0].mod_factur === 'UND') {
         
          dialogRef = this.dialog.open(FormPedidosComponent, {
           width: '100vw',
               maxWidth: '100vw',
               height:'100vh',
           data: {cod_pedido:obj.cod_documento,
                  cod_cliente:obj.cod_cliente,
                  des_cliente: obj.des_cliente,
                  des_vendedor:response[0].des_vendedor,
                  tipo_cliente:response[0].tipo_cliente,
                  estatus_pedido:obj.estatus_pedido,
                   mod_factur:response[0].mod_factur,
                   accion:obj.tipo_pedido},
           disableClose: true,
           panelClass: 'pedidos_modal3',
           backdropClass: "bdrop"
         });
        }else{
         
          dialogRef = this.dialog.open(FormPedidosUmComponent, {
           width: '100vw',
               maxWidth: '100vw',
               height:'100vh',
           data: {cod_pedido:obj.cod_documento,
                  cod_cliente:obj.cod_cliente,
                  des_cliente: obj.des_cliente,
                  des_vendedor:response[0].des_vendedor,
                  tipo_cliente:response[0].tipo_cliente,
                  estatus_pedido:obj.estatus_pedido,
                   mod_factur:response[0].mod_factur,
                   accion:obj.tipo_pedido},
           disableClose: true,
           panelClass: 'pedidos_modal3',
           backdropClass: "bdrop"
         });
        }
       
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if(result != 'cerrar' && result != 'salir'){
          console.log(result);
          result.cod_backorder = obj.cod_backorder;
         let resultado = this.gestionpedidos(result);
        }
      });
      }else{
        this.snackBar.open('Este cliente no tiene unidad de manejo definida', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
   
      break;

    case 'ND':
      if (response[0].mod_factur) {
        if (response[0].mod_factur === 'UNDP' || response[0].mod_factur === 'UND') {
          
          dialogRef = this.dialog.open(FormPedidosNComponent, {
            width: '100vw',
                maxWidth: '100vw',
                height:'100vh',
            data: {cod_pedido:obj.cod_documento,
                    cod_cliente:obj.cod_cliente,
                    des_cliente: obj.des_cliente,
                    des_vendedor:response[0].des_vendedor,
                    tipo_cliente:response[0].tipo_cliente,
                    estatus_pedido:obj.estatus_pedido,
                    mod_factur:response[0].mod_factur,
                    accion:obj.tipo_pedido},
            disableClose: true,
            panelClass: 'pedidos_modal3',
            backdropClass: "bdrop"
          });
        }else{
         
          dialogRef = this.dialog.open(FormPedidosNUmComponent, {
           width: '100vw',
               maxWidth: '100vw',
               height:'100vh',
           data: {cod_pedido:obj.cod_documento,
                  cod_cliente:obj.cod_cliente,
                  des_cliente: obj.des_cliente,
                  des_vendedor:response[0].des_vendedor,
                  tipo_cliente:response[0].tipo_cliente,
                  estatus_pedido:obj.estatus_pedido,
                   mod_factur:response[0].mod_factur,
                   accion:obj.tipo_pedido},
           disableClose: true,
           panelClass: 'pedidos_modal3',
           backdropClass: "bdrop"
         });
        }
       
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if(result != 'cerrar' && result != 'salir'){
          
          result.cod_backorder = obj.cod_backorder;
          console.log(result);
         let resultado = this.gestionpedidos(result);
        }
      });
      }else{
        this.snackBar.open('Este cliente no tiene unidad de manejo definida', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
      
       /* dialogRef = this.dialog.open(FormPedidosNComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'100%',
        data: {id:'', cod_pedido:obj.cod_documento,
                 cod_cliente:obj.cod_cliente,
              des_cliente: obj.des_cliente,
              des_vendedor:response[0].des_vendedor,
              tipo_cliente:response[0].tipo_cliente,
              estatus_pedido:obj.estatus_pedido,
        accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
      }); */
      break;

      case 'NB':
        if (response[0].mod_factur) {
          if (response[0].mod_factur === 'UNDP' || response[0].mod_factur === 'UND') {
           
            dialogRef = this.dialog.open(FormPedidosNBsComponent, {
              width: '100vw',
                  maxWidth: '100vw',
                  height:'100%',
              data: {cod_pedido:obj.cod_documento,
                    cod_cliente:obj.cod_cliente,
                    des_cliente: obj.des_cliente,
                    des_vendedor:response[0].des_vendedor,
                    tipo_cliente:response[0].tipo_cliente,
                    estatus_pedido:obj.estatus_pedido,
                      mod_factur:response[0].mod_factur,
                      accion:obj.tipo_pedido},
              disableClose: true,
              panelClass: 'pedidos_modal3',
              backdropClass: "bdrop"
            });
          }else{
            
            dialogRef = this.dialog.open(FormPedidosNBsUmComponent, {
             width: '100vw',
                 maxWidth: '100vw',
                 height:'100%',
             data: {cod_pedido:obj.cod_documento,
                    cod_cliente:obj.cod_cliente,
                    des_cliente: obj.des_cliente,
                    des_vendedor:response[0].des_vendedor,
                    tipo_cliente:response[0].tipo_cliente,
                    estatus_pedido:obj.estatus_pedido,
                     mod_factur:response[0].mod_factur,
                     accion:obj.tipo_pedido},
             disableClose: true,
             panelClass: 'pedidos_modal3',
             backdropClass: "bdrop"
           });
          }
         
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          if(result != 'cerrar' && result != 'salir'){
            console.log(result);
            result.cod_backorder = obj.cod_backorder;
           let resultado = this.gestionpedidos(result);
          }
        });
        }else{
          this.snackBar.open('Este cliente no tiene unidad de manejo definida', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        
      break;

    default:
      break;
  }
/*     dialogRef.afterClosed().subscribe(result => {
    if(result != 'cerrar'){
      console.log(result);

     let resultado = this.gestionpedidos(result);
    }
  });
*/
    },
    error =>{
      console.log(error);
      this.dataSource = new MatTableDataSource<any>();
            this.dataSource.paginator = this.paginator;
    }

  );
}
gestionpedidos(xvalor:any){
  this.tasa_precio = xvalor;
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });
setTimeout(async() => {
  console.log(xvalor);
  xvalor.val_def = 'P';
  xvalor.tipo_operacion = 'PEDIDOS';
  xvalor.cod_usuario = localStorage.getItem('cod_usuario');

  this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
    response=>{
      var resultado = this.servicio.gestionPedidosBackorderhttp(xvalor).subscribe(
        async  response =>{
          this.dialogSpin.close();
            console.log(response);
            this.snackBar.open(response.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
             setTimeout( async() => {
                const pdf = new PdfMakeWrapper();
                console.log(xvalor);
                xvalor.cod_pedido = response.pdf4[0].cod_pedido;
                this.servicio.listarProdPedidoParamhttp(xvalor).subscribe(
                  async response  =>{
                     console.log(response);
                     this.dialogSpin.close();
                     if (response.pdf4[0].tipo_pedido === 'F') {
                       response.pdf4[0].tipo_pedido = 'PSF';
                     }
                     if (response.pdf4[0].tipo_pedido === 'ND') {
                       response.pdf4[0].tipo_pedido = 'PSND';
                     }
                     if (response.pdf4[0].tipo_pedido === 'NB') {
                       response.pdf4[0].tipo_pedido = 'PSNB';
                     }
                     if (response.pdf4[0].tipo_pedido === 'PF') {
                       response.pdf4[0].tipo_pedido = 'PSPF';
                     }
                     response.pdf2[0].comentarios = 'N/A';
                     response.pdf6 = response.pdf5;
                      response.pdf6[0].cod_lista_precio_producto = response.pdf4[0].cod_lista_precio;
                      response.pdf6[0].comentarios = 'N/A';
                      this.pdf.pdfVentas(response,'dd');
                      this.listarBackOrder(null);
                   
                   },
                   error =>{
                     console.log(error);
                     this.dialogSpin.close();
                     this.listarBackOrder(null);
                     console.log(<any>error.error.message);
                     this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                       verticalPosition: this.verticalPosition});
                   }
                 );
               }, 1000);
          },
          error =>{
           this.dialogSpin.close();
           this.listarBackOrder(null);
            console.log(<any>error.error.message);
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
    },
    error=>{
      this.dialogSpin.close();
      this.listarBackOrder(null);
      this.snackBar.open(error.error.message,'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  );
  
}, 1000);
  return null;
}

  imprimirbackorder(xvalor:any){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
        xvalor.val_def = 'F';
        xvalor.tipo_operacion = 'IMPRIMIRNB';
        xvalor.cod_usuario = localStorage.getItem('cod_usuario');
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response =>{
          setTimeout(() => {
            const pdf = new PdfMakeWrapper();
            console.log(xvalor);
        
            var cod_usuario = localStorage.getItem('cod_usuario');
            var nombre_usuario = localStorage.getItem('Nombre');
            var apellido_usuario = localStorage.getItem('Apellido');
            var nombre = nombre_usuario+' '+apellido_usuario;
            var objeto = {};
            var objeto_ = [];
        
                this.servicio.ConsultarBackorderhttp(xvalor).subscribe(
                  async response  =>{
                  
                    
    
                     for (const i in response) {
                       objeto = {
                         cod_documento :xvalor.cod_documento,
                         cod_cliente :xvalor.cod_cliente,
                         des_cliente :xvalor.des_cliente,
                         cod_usuario :xvalor.cod_usuario,
                         des_usuario :nombre,
                         fecha_inicio :xvalor.fecha_inicio,
                         hora_inicio :xvalor.hora_inicio,
                        cod_backorder:response[i].cod_backorder,
                        cod_producto:response[i].cod_producto,
                        des_producto:response[i].des_producto,
                        cant_producto:response[i].cant_producto,
                        precio_producto:response[i].precio_producto,
                        total_monto_producto:response[i].total_monto_producto
                       }

                       objeto_.push({
                        cod_documento :xvalor.cod_documento,
                        cod_cliente :xvalor.cod_cliente,
                        des_cliente :xvalor.des_cliente,
                        cod_usuario :xvalor.cod_usuario,
                        des_usuario :nombre,
                        fecha_inicio :xvalor.fecha_inicio,
                        hora_inicio :xvalor.hora_inicio,
                       cod_backorder:response[i].cod_backorder,
                       cod_producto:response[i].cod_producto,
                       des_producto:response[i].des_producto,
                       cant_producto:response[i].cant_producto,
                       precio_producto:response[i].precio_producto,
                       total_monto_producto:response[i].total_monto_producto
                      }) ;
                     }
                    
                     response.documento = objeto;
                     response.pdf = objeto_;
                     console.log(response);
                            this.dialogSpin.close();
                            this.pdf.pdfBackOrder(response);
                   
                   },
                   error =>{
                    this.dialogSpin.close();
                    this.listarBackOrder(null);
                     console.log(error);
                     console.log(<any>error.error.message);
                     this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                       verticalPosition: this.verticalPosition});
                   }
                 );
            
            this.dialogSpin.close();
            this.listarBackOrder(null);
          }, 1000);
        },
        error =>{
          this.dialogSpin.close();
            this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
        }
      );
      
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

}
