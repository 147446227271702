<div class="container-fluid mat-elevation-z8" style="height: 100%; padding: 5%;" *ngIf="accion == 'actualizar'">
    <form [formGroup]="forma" class="materialformtamaño">
        <div class="row">
            <div class="col-md-12 ">
                <p>
                    Actualizar roles de usuario
                </p>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Descripcion del rol</mat-label>
                        <input matInput placeholder="Descripcion del rol:" formControlName="des_rol" autocomplete="off">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>group</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>

        </div>

        <div class="row">

            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Estatus actual</mat-label>
                        <input class="" matInput placeholder="Estatus actual:" formControlName="estatus_rol" autocomplete="off" readonly>
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>business</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="Estatus" placeholder="Estatus" formControlName="estatus_rol" autocomplete="off">
                            <mat-option value="1">(1) Activo</mat-option>
                            <mat-option value="0">(0) Inactivo</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">

            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Tipo actual</mat-label>
                        <input class="" matInput placeholder="Tipo actual:" formControlName="tipo_rol" autocomplete="off" readonly>
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>business</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="Tipo" placeholder="Tipo" formControlName="tipo_rol" autocomplete="off">
                            <mat-option value="0">(0) Administrador</mat-option>
                            <mat-option value="1">(1) usuario</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Descripcion de la unidad</mat-label>
                        <input class="" matInput placeholder="Descripcion de la unidad:" formControlName="des_unidad" autocomplete="off" readonly>
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>business</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="unidad" placeholder="Unidad" formControlName="cod_unidad" autocomplete="off">
                            <mat-option *ngFor="let item of unidad" [value]="item">({{item.cod_unidad}}) {{item.des_unidad}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="actualizardatos('actualizar')" mat-raised-button color="accent">Actualizar</button>
                    <button *ngIf="accion == 'insertar'" class="btn-block" (click)="actualizardatos()" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
                </p>
            </div>
        </div>
    </form>
</div>