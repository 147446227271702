import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { Element } from '@angular/compiler';
import { element } from 'protractor';
import * as moment from 'moment'
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormCotizacionReqComponent } from 'src/app/componentes/shared/modal/form-cotizacion-req/form-cotizacion-req.component';
import { FormAprobarCotizacionComponent } from 'src/app/componentes/shared/modal/form-aprobar-cotizacion/form-aprobar-cotizacion.component';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';



@Component({
  selector: 'app-form-cotizacion-ver-cotizacion',
  templateUrl: './form-cotizacion-ver-cotizacion.component.html',
  styleUrls: ['./form-cotizacion-ver-cotizacion.component.css']
})
export class FormCotizacionVerCotizacionComponent implements OnInit {
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dolar1:any = 0
  dolar2:any = 0
  dolar3:any = 0
  bolivar1:any = 0
  bolivar2:any = 0
  bolivar3:any = 0

  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  aprobar:any=false

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';



  constructor( public dialog: MatDialog,fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCotizacionVerCotizacionComponent>,
    ///en el injectable llega la informacion del metodo padre en data
   @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices) {}


   ngOnInit(): void {
    this.listarSolicitudesCompra()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  listarSolicitudesCompra(){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });


        this.servicio.listarCotizacionesNuevashttp().subscribe(
          response =>{
            console.log(response, )
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              let xobj2 = response.result;
              let array = []
              this.ELEMENT_DATA = [];

              xobj2.forEach(orden => {

                array.unshift({
                  id_solicitud_compra:orden.id_solicitud_compra,
                  cod_solicitud_compra: orden.cod_solicitud_compra,
                  cod_orden_req:orden.cod_orden_req ? orden.cod_orden_req : '-',
                  cod_cotizacion_req:orden.cod_cotizacion ? orden.cod_cotizacion : '-',
                  tipo_solicitud_compra:orden.tipo_solicitud_compra,
                  tipo_producto: orden.tipo_producto,
                  cod_usuario:orden.cod_usuario,
                  des_usuario:orden.des_usuario,
                  departamento: orden.departamento,
                  cod_centro_costo: orden.cod_centro_costo,
                  observaciones: orden.observaciones,
                  observaciones_aprobacion: orden.observaciones_aprobacion,
                  modalidad: orden.modalidad,
                  prioridad: orden.prioridad,
                  fecha_estimada: orden.fecha_estimada,
                  estatus:orden.estatus,
                  fecha:orden.fecha,
                  accion:''
                })

              })


               
               let aprobado = array.filter(element => element.estatus !== "CTP" && element.estatus !== "P" && element.estatus !== "X") 

               this.ELEMENT_DATA = aprobado

                 
              this.displayedColumns = [
                'cod_cotizacion_req',
                'estatus',
                'fecha',
                'cotizar',
                'ver'
              ];
                /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              'cod_cotizacion_req',
              'estatus',
              'fecha',
              'cotizar',
              'ver'
            ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
          }
        );
        return this.xobj2;
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  


  verCotizacion(info:any){

    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });


      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
      cod_menu: localStorage.getItem('sub_main'),
      cod_sesion: localStorage.getItem('cod_sesion'),
      dir_ip_client: localStorage.getItem('dir_ip'),
    sesion_unica:sessionStorage.getItem('sesion_unica')}


      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

        this.servicio.listarCotizacionesProAprobados(info.cod_cotizacion_req).subscribe(
          response => {

          this.dialogSpin.close()

          response.accion = 'ver'
  
          var dialogRef;
          dialogRef = this.dialog.open(FormCotizacionReqComponent,     {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data:response,
          disableClose:   true,
          panelClass: 'pedidos_modal3',
          backdropClass: "bdrop"
          });
  
          dialogRef.afterClosed().subscribe(resul => {
  
            if(resul != 'cerrar'){
  
              console.log('Se cerro la wea')
              this.listarSolicitudesCompra()
  
            }
  
         
        },
        error =>{
          this.dialogSpin.close()
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        )


        })

       
      },
      error =>{
        this.dialogSpin.close()
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
      )
      
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
   

  }


  aprobarCotizacion(info:any, accion:any){

    try {

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
      cod_menu: localStorage.getItem('sub_main'),
      cod_sesion: localStorage.getItem('cod_sesion'),
      dir_ip_client: localStorage.getItem('dir_ip'),
    sesion_unica:sessionStorage.getItem('sesion_unica')}


      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

        if(accion == 'ver'){

          this.servicio.listarCotizacionesAprobadashttp(info.cod_cotizacion_req).subscribe(result2 =>{

            this.dialogSpin.close()

            info.cotizacion = result2

            info.accion = 'ver'

            console.log(result2);

            var dialogRef;
            dialogRef = this.dialog.open(FormAprobarCotizacionComponent,{
            width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
            data:info,
            disableClose:   true,
            panelClass: 'pedidos_modal3',
            backdropClass: "bdrop"
            });
    
            dialogRef.afterClosed().subscribe(resul => {
    
              if(resul != 'cerrar'){
    
                console.log('Se cerro la wea')
                this.listarSolicitudesCompra()
    
              }
    
           
            })

          })



        }else{

          this.servicio.listarCotizacioneshttp(info.cod_cotizacion_req).subscribe(result2 => {

            this.dialogSpin.close()
    
            info.cotizacion = result2
    
            var dialogRef;
            dialogRef = this.dialog.open(FormAprobarCotizacionComponent,{
            width: '100vw',
            maxWidth: '100vw',
            height:'100vh',
            data:info,
            disableClose:   true,
            panelClass: 'pedidos_modal3',
            backdropClass: "bdrop"
            });
    
            dialogRef.afterClosed().subscribe(resul => {
    
              if(resul != 'cerrar'){
    
                console.log('Se cerro la wea')
                this.listarSolicitudesCompra()
    
              }
    
           
            })
          })
          
        }


      },
      error =>{
        this.dialogSpin.close()
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
      )
      
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
   

  }

  cotizacionesAporbadas(){
    
  }

  async imprimirCotizacion(){
    try {

        const fontsSize:any = 9 
        var data:any = this.data.resumen
        var items:any= 0;
        const pdfMake = window["pdfMake"]
        const tablaEnLista:any = generarTabla(data)
        var dia = moment(this.data.infoCot.fecha, 'DD/MM/YYYY').format('DD')
        var mes = moment(this.data.infoCot.fecha, 'DD/MM/YYYY').format('MM')
        var anho = moment(this.data.infoCot.fecha, 'DD/MM/YYYY').format('YYYY')

        function generarTabla(dato:any){
          
          /* for(let i = 0; i < 5 ; i++ ){
                          
            if(dato[i] === undefined){
              
              let articuloVacio =  {
                   cod_producto: ' ',
                   des_producto: ' ',
                   cant_producto: ' ',
                   partida: ' ',
                   des_partida:'',
                   tipo_pro:''
              }
              dato[i] = articuloVacio
            }
          } */
            let arry:any =[
              [
                  {text: 'Codigo', alignment: 'center', fontSize: 7, border: [true, true, true, true],bold:true}, 
                  {text: 'Descripción de producto/servicio', alignment: 'center', fontSize: 7, border: [false, true, true, true],bold:true}, 
                  {text: 'Unidad de medida', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'Cantidad solicitada', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  //PROV 1
                  {text: 'Cant. min de venta', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'P. Unitario', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'Total proveedor', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  //PROV 2
                  {text: 'Cant. min de venta', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'P. Unitario', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'Total proveedor', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  //PROV 3
                  {text: 'Cant. min de venta', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'P. Unitario', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
                  {text: 'Total proveedor', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
              ],
          ]
          for (const i in dato) {

              if(dato[i].cod_producto > 0){

                  items = dato[i].length
              }
                
              let articulo:any = [
                  {text: dato[i].cod_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
                  {text: dato[i].des_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
                  {text: dato[i].tipo_unidad_medida, alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].cant_solicitada, alignment: 'center', fontSize: 6,border: [false, false, true, false],},
                  //PROV 1
                  {text: dato[i].cant_producto_cot[0], alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_cot_1+' '+dato[i].moneda_1 , alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
                  {text: dato[i].precio_total_cot_1+' '+dato[i].moneda_1, alignment: 'center', fontSize: 6,border: [false, false, true, false],},
                  //PROV 2
                  {text: dato[i].cant_producto_cot[1], alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_cot_2 ? dato[i].precio_cot_2+' '+dato[i].moneda_2 : '-', alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_total_cot_2 ? dato[i].precio_total_cot_2+' '+dato[i].moneda_2 : '-', alignment: 'center', fontSize: 6,border: [false, false, true, false],},
                  //PROV 3
                  {text: dato[i].cant_producto_cot[2], alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_cot_3 ? dato[i].precio_cot_3+' '+dato[i].moneda_3 : '-', alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].precio_total_cot_3 ? dato[i].precio_total_cot_3+' '+dato[i].moneda_3 : '-', alignment: 'center', fontSize: 6,border: [false, false, true, false],},
              ]
              arry.push(articulo)
          }
          return arry;
        }
          
        
        const pdfDefinition: any = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          background: [
            { image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/fondo_pdf_horizontal.png"), width: 850, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
          ],
          info:{
            title:`Cuadro comparativo de cotización ${this.data.infoCot.cod_cotizacion}`,
            author: 'FACTORY COSMETICS 3DG, C.A',
            subject:`Cuadro comparativo de cotización ${this.data.infoCot.cod_cotizacion}`,
          },
          content: [
            {
              style:'header',
              table:{
              widths: [100, 480, 150,],
              body:
                [
                  [
                    {image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/logo-factory.png"), fit:[80,80],alignment:'left',},
                    {text:'\nFACTORY COSMETICS 3DG, C.A.\n \
                            RIF.: J-41031797-3',alignment:'center',fontSize:11,bold:true,},
                    /* {text:'    ', colSpan: 1,}, */
                    {style:'fecha',
                      table: {
                        body: [
                          [
                            {text:'Fecha',alignment:'center',fontSize:7,colSpan:3,border:[true, true, true, true],},
                            {text:'',alignment:'center',fontSize:7,},
                            {text:'',alignment:'center',fontSize:7,},
                          ],
                          [
                            {text:'Dia',alignment:'center',fontSize:7,border:[true, true, true, true],},
                            {text:'Mes',alignment:'center',fontSize:7,border:[true, true, true, true],},
                            {text:'Año',alignment:'center',fontSize:7,border:[true, true, true, true],},
                          ],
                          [
                            {text:dia,alignment:'center',fontSize:7,border:[true, true, true, true],},
                            {text:mes,alignment:'center',fontSize:7,border:[true, true, true, true],},
                            {text:anho,alignment:'center',fontSize:7,border:[true, true, true, true],},
                          ],
                        ],
                      },
                    },
                  ],
                ],
              },layout: {
                defaultBorder: false,
              },
            },
            {text:`CUADRO COMPARATIVO DE COTIZACIÓN - ${this.data.infoCot.cod_cotizacion}`, alignment:'center',bold:true,fontSize:11},
            {
              style:'proveedores',
              table: {
                widths: [40,190.8,148,148.2,148.1],
                body: [
                  [
                      {text:' ',border:[false, false, false, false],},
                      {text:' ',border:[false, false, false, false],},
                      {text:'PROVEEDOR 1',border:[true, true, true, false],bold:true,fontSize:7,alignment:'center'},
                      {text:'PROVEEDOR 2',border:[true, true, true, false],bold:true,fontSize:7,alignment:'center'},
                      {text:'PROVEEDOR 3',border:[true, true, true, false],bold:true,fontSize:7,alignment:'center'},
                  ],
                ],
              },
            },
            {
              style:'proveedores_info',
              table: {
                widths: [40,190.8,148,148.2,148.1],
                body: [
                  [
                      {text:' ',border:[false, false, false, false],},
                      {text:'Nombre o Razon Social',border:[true, true, true, false],fontSize:7,alignment:'center',},
                      {text:this.data.cotizacion_1.empresa ,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text:this.data.cotizacion_2.empresa == 'N/A' ? '-' : this.data.cotizacion_2.empresa ,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text:this.data.cotizacion_3.empresa == 'N/A' ? '-' : this.data.cotizacion_3.empresa,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                  ],
                  [
                      {text:' ',border:[false, false, false, false],},
                      {text:'RIF',border:[true, true, true, false],fontSize:7,alignment:'center',},
                      {text: this.data.cotizacion_1.rif,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text: this.data.cotizacion_2.rif == 'N/A' ? '-' : this.data.cotizacion_2.rif ,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text: this.data.cotizacion_3.rif == 'N/A' ? '-' : this.data.cotizacion_3.rif ,border:[true, true, true, false],bold:false,fontSize:6,alignment:'center'},
                  ],
                  [
                      {text:' ',border:[false, false, false, false],},
                      {text:'Direccion',border:[true, true, true, true],fontSize:7,alignment:'center',},
                      {text: this.data.cotizacion_1.direccion,border:[true, true, true, true],bold:false,fontSize:6,alignment:'center'},
                      {text:this.data.cotizacion_2.direccion == 'N/A' ? '-' : this.data.cotizacion_2.direccion ,border:[true, true, true, true],bold:false,fontSize:6,alignment:'center'},
                      {text:this.data.cotizacion_3.direccion == 'N/A' ? '-' : this.data.cotizacion_3.direccion ,border:[true, true, true, true],bold:false,fontSize:6,alignment:'center'},
                  ],
                ],
              },
            },
            {
              style:'vacio',
              table: {
                widths: [40,190.8,148,148.2,148.1],
                heights: [15,15,15,15,15],
                body: [
                  [
                      {text:'',border:[false, false, false, false],},
                      {text:'',border:[false, false, true, false],fontSize:7,alignment:'center',},
                      {text:'',border:[false, false, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text:'',border:[false, false, true, false],bold:false,fontSize:6,alignment:'center'},
                      {text:'',border:[false, false, true, false],bold:false,fontSize:6,alignment:'center'},
                  ],
                ],
              },
            },
            { 
              style: 'Descripcion',
              table: {
                  headerRows: 1,
                  widths: [23,80,50,60,35,25,70,35,25,70,35,25,70],
                  body: tablaEnLista,
              }, 
            },
            {
              style:'total_monto',
              table: {
                widths: [239.7,35.8,25,70,34.5,25,70.3,35,25,69.8],
                body: [
                  [
                      {text:'MONTO TOTAL',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center',},
                      {text:' ',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center',},
                      {text:'0.00',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text: this.dolar1+' $' ,border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text:' ',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text:'0.00',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center',},
                      {text: this.dolar2+' $',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center',},
                      {text:'',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text:'0.00',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                      {text: this.dolar3+' $',border:[true, true, true, true],bold:true,fontSize:7,alignment:'center'},
                  ],
                ],
              },
            },
            { style:'resumen',
              table: {
                widths:['auto','auto','auto','auto', 10],
                body: [
                  [
                    {text:'\n\nResumen:',alignment:'',rowSpan:4,fontSize:7,border:[false, false, false, false],bold:true,},
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'   ',alignment:'center',fontSize:7,border:[false, false, false, false],},
                  ],
                  [
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'PROVEEDOR',alignment:'',fontSize:7,border:[true, true, true, true],},
                    {text:'1',alignment:'center',fontSize:7,},
                    {text:this.dolar1+' $',alignment:'center',fontSize:7,},
                    {text:'   ',alignment:'center',fontSize:7,},
                  ],
                  [ 
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'PROVEEDOR',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:'2',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:this.dolar2+' $',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:'   ',alignment:'center',fontSize:7,border:[true, true, true, true],},
                  ],
                  [ 
                    {text:'',alignment:'center',fontSize:7,border:[false, false, false, false],},
                    {text:'PROVEEDOR',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:'3',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:this.dolar3+' $',alignment:'center',fontSize:7,border:[true, true, true, true],},
                    {text:'   ',alignment:'center',fontSize:7,border:[true, true, true, true],},
                  ],
                ],
              },
            },  
            { style:'firma',
              table: {
                widths:[120],
                body: [
                  [
                    {text:'Firma',alignment:'center',fontSize:10,border:[false, true, false, false],bold:true,},
                  ],
                  [
                    {text:'',alignment:'',fontSize:10,border:[false, false, false, false],bold:false,},
                  ],
                ],
              },
            },
          ],
          styles: {
            header: {
              margin: [ 0, 0, 0, 25]
            },
            cuadros: {
                margin: [ 0, 0, 0, 25]
            },
            titulo:{
              margin:[0,0,0,20]
            },
            proveedores_info:{
              margin:[0,0,0,0]
            },
            resumen:{
              margin:[555.5,20,0,0]
            },
            fecha:{
              margin: [51, 0, 0, 0]
            },
            firma:{
              margin: [100, 0, 0, 0]
            },
            proveedores:{
              margin: [0, 20, 0, 0]
            },
        },
    }
   
        const pdf = pdfMake.createPdf(pdfDefinition);
      
        pdf.open();

    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  getBase64ImageFromURLdaily(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

  en_construccion(): void {

    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    } catch (error) {
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
