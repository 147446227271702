
  <div class="" id="modal">

    <div class="row">

      <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 5px;">
      
        <form [formGroup]="forma" class="">

          <div class="modal-content h-100">
            <div class="modal-header" style="background-color: rgb(50, 45, 104); color: #fff;">
              <h3 class="modal-title" class=" text-left" *ngIf="edit"><strong>Editar Empleado</strong> </h3>
              <h3 class="modal-title" class=" text-left"  *ngIf="!edit"><strong>Crear Empleado</strong> </h3>

            </div>

                <div class="modal-body" style="height: 60vh; overflow-x: hidden;">
                  <div>

                    <div class="px-2"  style="background-color: rgb(50, 45, 104); color: #fff;">
                      <h3 class="modal-title" class=" text-left "><strong>Datos Personales</strong> </h3>
                    </div>

                    <!-- DATOS PERSONALES FORMULARIO -->

                    <div class="row">
                      <div class="col-md-12 col-sm-12">

                        <div class="row py-0"> 

                              
                                <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Primer Apellido:</mat-label>
                                      <input style="font-size: 12px; color: black;" maxlength="10" matInput placeholder="Primer Apellido" formControlName="dt_primer_apellido" autocomplete="off">
                                      <span mat-icon-button matSuffix >
                                      
                                      </span>
                                  </mat-form-field>
                                </div>
                              
                                <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Segundo Apellido:</mat-label>
                                      <input style="font-size: 12px; color: black;" maxlength="10" matInput placeholder="Segundo Apellido" formControlName="dt_segundo_apellido" autocomplete="off">
                                      <span mat-icon-button matSuffix >
                                     
                                      </span>
                                  </mat-form-field>
                                </div>
                            
                                <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Primer Nombre:</mat-label>
                                      <input style="font-size: 12px; color: black;" maxlength="10" matInput placeholder="Primer Nombre" formControlName="dt_primer_nombre" autocomplete="off">
                                      <span mat-icon-button matSuffix >
                                     
                                      </span>
                                  </mat-form-field>
                                </div>
                              
                                <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Segundo Nombre:</mat-label>
                                      <input style="font-size: 12px; color: black;" maxlength="10" matInput placeholder="Segundo Nombre" formControlName="dt_segundo_nombre" autocomplete="off">
                                      <span mat-icon-button matSuffix >
                                      
                                      </span>
                                  </mat-form-field>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                  <mat-form-field class="materialinput">
                                    <mat-label>Cedula :</mat-label>
                                    <input matInput placeholder="Cedula:" formControlName="cod_usuario" autocomplete="off" type="text" maxlength="8" [readonly]="edit">
                                    <mat-error *ngIf="forma.controls.cod_usuario.errors?.pattern">
                                      Cedula no valida
                                    </mat-error>
                                  </mat-form-field>
                                </div>
                              
                                <div class="col-md-6 col-sm-6 col-xs-6 py-0">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Dirección:</mat-label>
                                      <textarea style="font-size: 12px; color: black;resize: none;height: 18px; overflow: hidden;" matInput placeholder="Dirección" id="Dirección" autocomplete="off" formControlName="dt_dir_1"> </textarea>
                                  </mat-form-field>
                                </div>
                               
                                <div class="col-md-6 col-sm-6 col-xs-6 py-0">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Email corporativo:</mat-label>
                                      <textarea style="font-size: 12px; color: black;resize: none;height: 18px; overflow: hidden;" matInput placeholder="Dirección" autocomplete="off" formControlName="dt_email"> </textarea>
                                      <mat-error *ngIf="forma.controls.dt_email.errors?.email">
                                        No es un Email valido
                                      </mat-error>
                                  </mat-form-field>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Telefono:</mat-label>
                                      <input style="font-size: 13px; color: black;" matInput placeholder="0000-0000000" autocomplete="off" formControlName="dt_tlfno_emp">
                                      <span mat-icon-button matSuffix >
                                        <mat-icon>call</mat-icon>
                                      </span>
                                  </mat-form-field>
                                </div>

                                <div class="col-md-2 col-sm-2 col-xs-2">
                                  <mat-form-field class="materialinput">
                                      <mat-select style="font-size: 12px; color: black;" name="Sexo" placeholder="Sexo" formControlName="dt_gene_emp" autocomplete="off">
                                          <mat-option value="M">Masculino</mat-option>
                                          <mat-option value="F">Femenino</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                </div>
                            
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                  <mat-form-field>
                                    <mat-label>Fecha de nacimiento</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="dt_fecha_naci">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                  </mat-form-field>
                                </div>

                                <div class="col-md-2 col-sm-2 col-xs-2 ">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Pais y ciudad de origen:</mat-label>
                                      <input matInput style="font-size: 12px; color: black;" placeholder="Pais y ciudad" formControlName="dt_des_pais_ciud_emp" autocomplete="off">
                                      <span mat-icon-button matSuffix >
                                      <mat-icon>user</mat-icon>
                                      </span>
                                  </mat-form-field>
                                </div>

                                <div class="col-md-2 col-sm-2 col-xs-2">
                                  <mat-form-field class="materialinput">
                                      <mat-label style="color: #000">Edad:</mat-label>
                                      <input matInput style="font-size: 12px; color: black;" placeholder="edad" formControlName="dt_edad" autocomplete="off">
                                      <span mat-icon-button matSuffix >
                                      <mat-icon>user</mat-icon>
                                      </span>
                                  </mat-form-field>
                                </div>

                                <div class="col-md-2 col-sm-2 col-xs-2">
                                  <mat-form-field class="materialinput">
                                      <mat-select style="font-size: 12px; color: black;" name="Estado Civil" placeholder="Estado Civil" formControlName="dt_edo_civil" autocomplete="off">
                                          <mat-option value="S">Soltero</mat-option>
                                          <mat-option value="C">Casado</mat-option>
                                          <mat-option value="D">Divorsiado</mat-option>
                                          <mat-option value="V">Viudo</mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                </div>

                                  <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                      <mat-form-field class="materialinput">
                                          <mat-label style="color: #000">Alergia que padece:</mat-label>
                                          <textarea style="font-size: 12px; color: black;resize: none;height: 18px; overflow: hidden;" matInput placeholder="Alergias" autocomplete="off" formControlName="dt_alergia"> </textarea>
                                      </mat-form-field>
                                  </div>
                              
                                  <div class="col-md-2 col-sm-2 col-xs-2">
                                    <mat-form-field class="materialinput">
                                        <mat-select style="font-size: 12px; color: black;" name="Mano dominante" placeholder="Mano dominante" formControlName="dt_mano_d" autocomplete="off">
                                            <mat-option value="D">Derecho</mat-option>
                                            <mat-option value="Z">Zurdo</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                  </div>

                                  <div class="col-md-4 col-sm-4 col-xs-4">
                                    <mat-form-field class="materialinput">
                                        <mat-label style="color: #000">Nacionalidad:</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" placeholder="Nacionalidad" formControlName="dt_nacion_emp" autocomplete="off">
                                        <span mat-icon-button matSuffix >
                                        <mat-icon>user</mat-icon>
                                        </span>
                                    </mat-form-field>
                                  </div>

                                  <div class="col-md-2 col-sm-2 col-xs-2">
                                    <mat-form-field class="materialinput">
                                        <mat-select style="font-size: 12px; color: black;" name="Posee vehiculo" placeholder="Posee vehiculo" formControlName="dt_vehiculo_emp" autocomplete="off">
                                            <mat-option value="S">Si</mat-option>
                                            <mat-option value="N">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                  </div>

                                  <div class="col-md-4 col-sm-4 col-xs-4">
                                    <mat-form-field class="materialinput">
                                        <mat-label style="color: #000">Licencia de conducir:</mat-label>
                                        <input matInput style="font-size: 12px; color: black;" placeholder="Numero" formControlName="dt_licencia" autocomplete="off">
                                        <span mat-icon-button matSuffix >
                                        <mat-icon>user</mat-icon>
                                        </span>
                                    </mat-form-field>
                                  </div>

                                  <div class="col-md-2 col-sm-2 col-xs-2">
                                    <mat-form-field class="materialinput">
                                        <mat-select style="font-size: 12px; color: black;" name="Vive con" placeholder="Vive con:" formControlName="dt_habita_con" autocomplete="off">
                                            <mat-option value="PADRES">PADRES</mat-option>
                                            <mat-option value="SOLO">SOLO</mat-option>
                                            <mat-option value="CONYUGE E HIJOS">CONYUGE E HIJOS</mat-option>
                                            <mat-option value="OTROS">OTROS</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-2 col-sm-2 col-xs-2">
                                    <mat-form-field class="materialinput">
                                        <mat-select style="font-size: 12px; color: black;" name="Condicion de la vivienda" placeholder="Condicion de la vivienda" formControlName="dt_habitacion" autocomplete="off">
                                            <mat-option value="ALQUILADA">ALQUILADA</mat-option>
                                            <mat-option value="PROPIA">PROPIA</mat-option>
                                            <mat-option value="HIPOTECADA">HIPOTECADA</mat-option>
                                            <mat-option value="OTRO">OTRO</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-2 col-sm-2 col-xs-2 py-0" >
                                        <mat-form-field class="materialinput">
                                          <mat-select style="font-size: 12px; color: black;" (selectionChange)="listarUnidad($event)" placeholder="Gerencias" formControlName="unidad" autocomplete="off">
                                            <mat-option  *ngFor="let i of gerencia" [value]="i">
                                              {{i.des_unidad}}
                                            </mat-option>
                                          </mat-select>
                                       </mat-form-field>
                                  </div>
                                  <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                    <mat-form-field class="materialinput">
                                        <mat-select style="font-size: 12px; color: black;" (selectionChange)="listarUnidad($event)"  placeholder="Departamentos" formControlName="dt_coordinacion" autocomplete="off">
                                          <mat-option  *ngFor="let i of dpto" [value]="i">
                                            {{i.des_unidad}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                    <mat-form-field class="materialinput">
                                        <mat-select style="font-size: 12px; color: black;" placeholder="Cargo" formControlName="dt_cargo" autocomplete="off">
                                          <mat-option  *ngFor="let i of cargo" [value]="i">
                                            {{i}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                  </div>
                                  <div class="col-md-4 col-sm-4 col-xs-4">
                                    <mat-form-field>
                                      <mat-label>Fecha de Ingreso</mat-label>
                                      <input matInput [matDatepicker]="picker1" formControlName="dt_fecha_dispo">
                                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                      <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                  </div>
                        </div>
                      </div>
                    </div>

                    <!-- FIN DATOS PERSONALES FORMULARIO -->


                    <div class="px-2"  style="background-color: rgb(50, 45, 104); color: #fff;">
                      <h3 class="modal-title" class=" text-left"><strong>Datos Educativos</strong> </h3>
                    </div>


                    <!-- DATOS EDUCATIVOS -->

                    <div class="row">
                      <div class="col-md-12 col-sm-12">

                        <div class="row py-0">

                          <div style="margin: 1%;">
                            <h4>Primaria:</h4>
                          </div>


                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Instituto:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Instituto" formControlName="dt_instit_prim" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000">Lugar:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Lugar" formControlName="dt_instit_prim_lugar" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>



                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000">Titulo Obtenido:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Titulo Obtenido" formControlName="dt_instit_prim_titulo" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>




                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field>
                              <mat-label>Fecha Inicio:</mat-label>
                              <input matInput [matDatepicker]="picker2" formControlName="dt_fecha_ini_prim">
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field >
                              <mat-label>Fecha Final:</mat-label>
                              <input matInput [matDatepicker]="picker3" formControlName="dt_fecha_fin_prim">
                              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                              <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Graduado" placeholder="Graduado" formControlName="dt_gradu_prim_emp" autocomplete="off">
                                    <mat-option value="S">Si</mat-option>
                                    <mat-option value="N">No</mat-option>
                                
                                </mat-select>
                            </mat-form-field>
                          </div>






                          <div style="margin: 1%;">
                            <h4>Secundaria:</h4>
                          </div>


                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Instituto:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Instituto" formControlName="dt_instit_secun" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000">Lugar:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Lugar" formControlName="dt_instit_secun_lugar" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>



                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000">Titulo Obtenido:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Titulo Obtenido" formControlName="dt_instit_secun_titulo" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>




                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field >
                              <mat-label>Fecha Inicio:</mat-label>
                              <input matInput [matDatepicker]="picker4" formControlName="dt_fecha_ini_secun">
                              <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                              <mat-datepicker #picker4></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field>
                              <mat-label>Fecha Final:</mat-label>
                              <input matInput [matDatepicker]="picker5" formControlName="dt_fecha_fin_secun">
                              <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                              <mat-datepicker #picker5></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Graduado" placeholder="Graduado" formControlName="dt_gradu_secun_emp" autocomplete="off">
                                    <mat-option value="S">Si</mat-option>
                                    <mat-option value="N">No</mat-option>
                                
                                </mat-select>
                            </mat-form-field>
                          </div>





                          <div style="margin: 1%;">
                            <h4>Tecnica:</h4>
                          </div>


                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Instituto:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Instituto" formControlName="dt_instit_tec" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000">Lugar:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Lugar" formControlName="dt_instit_tec_lugar" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>



                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000">Titulo Obtenido:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Titulo Obtenido" formControlName="dt_instit_tec_titulo" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>




                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field>
                              <mat-label>Fecha Inicio:</mat-label>
                              <input matInput [matDatepicker]="picker6" formControlName="dt_fecha_ini_tec">
                              <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                              <mat-datepicker #picker6></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field >
                              <mat-label>Fecha Final:</mat-label>
                              <input matInput [matDatepicker]="picker7" formControlName="dt_fecha_fin_tec">
                              <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
                              <mat-datepicker #picker7></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Graduado" placeholder="Graduado" formControlName="dt_gradu_tec_emp" autocomplete="off">
                                    <mat-option value="S">Si</mat-option>
                                    <mat-option value="N">No</mat-option>
                                
                                </mat-select>
                            </mat-form-field>
                          </div>

                          <div style="margin: 1%;">
                            <h4>Universidad:</h4>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Instituto:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Instituto" formControlName="dt_instit_univ" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000">Lugar:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Lugar" formControlName="dt_instit_univ_lugar" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>
                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000">Titulo Obtenido:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Titulo Obtenido" formControlName="dt_instit_univ_titulo" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field >
                              <mat-label>Fecha Inicio:</mat-label>
                              <input matInput [matDatepicker]="picker8" formControlName="dt_fecha_ini_univ">
                              <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
                              <mat-datepicker #picker8></mat-datepicker>
                            </mat-form-field>
                          </div>
                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field >
                              <mat-label>Fecha Final:</mat-label>
                              <input matInput [matDatepicker]="picker9" formControlName="dt_fecha_fin_univ">
                              <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
                              <mat-datepicker #picker9></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Graduado" placeholder="Graduado" formControlName="dt_gradu_univ_emp" autocomplete="off">
                                    <mat-option value="S">Si</mat-option>
                                    <mat-option value="N">No</mat-option>
                                
                                </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Estudia Actualmente" placeholder="Estudia Actualmente?" formControlName="dt_estudios" autocomplete="off">
                                    <mat-option value="S">Si</mat-option>
                                    <mat-option value="N">No</mat-option>
                                
                                </mat-select>
                            </mat-form-field>
                          </div>


                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Instituto:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Instituto" formControlName="dt_instit_act_emp" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Especialidad:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Especialidad" formControlName="dt_especialidad" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Nivel:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Nivel" formControlName="dt_niv_act_emp" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Horario:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Horario" formControlName="dt_horario_act_emp" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>


                    <!--FIN DATOS EDUCATIVOS -->

                    <div class="px-2"  style="background-color: rgb(50, 45, 104); color: #fff;">
                      <h3 class="modal-title" class=" text-left"><strong>Datos Familiares (Conyuge,Hijos)</strong> </h3>
                    </div>

                    <!-- DATOS FAMILIARES --> 

                    <div class="row">

                      <div class="col-md-12 col-sm-12">

                        <div class="row py-0">

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Apellidos y Nombre:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Apellidos y Nombre" formControlName="dt_des_fam_1" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Cedula:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Cedula" formControlName="dt_cod_fam_1" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Parentesco:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Parentesco" formControlName="dt_parent_fam_1" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Edad:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Edad" formControlName="dt_edad_fam_1" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Sexo" placeholder="Sexo" formControlName="dt_gene_fam_1" autocomplete="off">
                                    <mat-option value="M">Masculino</mat-option>
                                    <mat-option value="F">Femenino</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                </mat-select>
                            </mat-form-field>
                          </div>



                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Estado Civil" placeholder="Estado Civil" formControlName="dt_edo_civil_fam_1" autocomplete="off">
                                    <mat-option value="S">Soltero</mat-option>
                                    <mat-option value="C">Casado</mat-option>
                                    <mat-option value="D">Divorsiado</mat-option>
                                    <mat-option value="V">Viudo</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                  </mat-select>
                                </mat-form-field>
                          </div>



                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Ocupacion:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Ocupacion" formControlName="dt_ocup_fam_1" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Nivel Academico:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Nivel Academico" formControlName="dt_niv_acad_fam_1" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Apellidos y Nombre:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Apellidos y Nombre" formControlName="dt_des_fam_2" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                                <br>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Cedula:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Cedula" formControlName="dt_cod_fam_2" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Parentesco:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Parentesco" formControlName="dt_parent_fam_2" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Edad:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Edad" formControlName="dt_edad_fam_2" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Sexo" placeholder="Sexo" formControlName="dt_gene_fam_2" autocomplete="off">
                                    <mat-option value="M">Masculino</mat-option>
                                    <mat-option value="F">Femenino</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                </mat-select>
                            </mat-form-field>
                          </div>



                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Estado Civil" placeholder="Estado Civil" formControlName="dt_edo_civil_fam_2" autocomplete="off">
                                    <mat-option value="S">Soltero</mat-option>
                                    <mat-option value="C">Casado</mat-option>
                                    <mat-option value="D">Divorsiado</mat-option>
                                    <mat-option value="V">Viudo</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                  </mat-select>
                                </mat-form-field>
                          </div>



                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Ocupacion:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Ocupacion" formControlName="dt_ocup_fam_2" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Nivel Academico:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Nivel Academico" formControlName="dt_niv_acad_fam_2" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>




                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Apellidos y Nombre:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Apellidos y Nombre" formControlName="dt_des_fam_3" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Cedula:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Cedula" formControlName="dt_cod_fam_3" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Parentesco:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Parentesco" formControlName="dt_parent_fam_3" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Edad:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Edad" formControlName="dt_edad_fam_3" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Sexo" placeholder="Sexo" formControlName="dt_gene_fam_3" autocomplete="off">
                                    <mat-option value="M">Masculino</mat-option>
                                    <mat-option value="F">Femenino</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                </mat-select>
                            </mat-form-field>
                          </div>



                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Estado Civil" placeholder="Estado Civil" formControlName="dt_edo_civil_fam_3" autocomplete="off">
                                    <mat-option value="S">Soltero</mat-option>
                                    <mat-option value="C">Casado</mat-option>
                                    <mat-option value="D">Divorsiado</mat-option>
                                    <mat-option value="V">Viudo</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                  </mat-select>
                                </mat-form-field>
                          </div>



                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Ocupacion:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Ocupacion" formControlName="dt_ocup_fam_3" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Nivel Academico:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Nivel Academico" formControlName="dt_niv_acad_fam_3" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>




                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Apellidos y Nombre:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Apellidos y Nombre" formControlName="dt_des_fam_4" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Cedula:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Cedula" formControlName="dt_cod_fam_4" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Parentesco:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Parentesco" formControlName="dt_parent_fam_4" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Edad:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Edad" formControlName="dt_edad_fam_4" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Sexo" placeholder="Sexo" formControlName="dt_gene_fam_4" autocomplete="off">
                                    <mat-option value="M">Masculino</mat-option>
                                    <mat-option value="F">Femenino</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                </mat-select>
                            </mat-form-field>
                          </div>



                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Estado Civil" placeholder="Estado Civil" formControlName="dt_edo_civil_fam_4" autocomplete="off">
                                    <mat-option value="S">Soltero</mat-option>
                                    <mat-option value="C">Casado</mat-option>
                                    <mat-option value="D">Divorsiado</mat-option>
                                    <mat-option value="V">Viudo</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                  </mat-select>
                                </mat-form-field>
                          </div>



                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Ocupacion:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Ocupacion" formControlName="dt_ocup_fam_4" utocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Nivel Academico:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Nivel Academico" formControlName="dt_niv_acad_fam_4" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>





                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Apellidos y Nombre:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Apellidos y Nombre" formControlName="dt_des_fam_5" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Cedula:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Cedula" formControlName="dt_cod_fam_5" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Parentesco:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Parentesco" formControlName="dt_parent_fam_5" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Edad:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Edad" formControlName="dt_edad_fam_5" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Sexo" placeholder="Sexo" formControlName="dt_gene_fam_5" autocomplete="off">
                                    <mat-option value="M">Masculino</mat-option>
                                    <mat-option value="F">Femenino</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                </mat-select>
                            </mat-form-field>
                          </div>



                          <div class="col-md-1 col-sm-1 col-xs-1">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" name="Estado Civil" placeholder="Estado Civil" formControlName="dt_edo_civil_fam_5" autocomplete="off">
                                    <mat-option value="S">Soltero</mat-option>
                                    <mat-option value="C">Casado</mat-option>
                                    <mat-option value="D">Divorsiado</mat-option>
                                    <mat-option value="V">Viudo</mat-option>
                                    <mat-option value="N">No aplica</mat-option>
                                  </mat-select>
                                </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Ocupacion:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Ocupacion" formControlName="dt_ocup_fam_5" tocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Nivel Academico:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Nivel Academico" formControlName="dt_niv_acad_fam_5" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                        </div>

                      </div>

                    </div>

                    <!--FIN DATOS FAMILIARES --> 

                    <div class="px-2"  style="background-color: rgb(50, 45, 104); color: #fff;">
                      <h3 class="modal-title" class=" text-left"><strong>Empleos Anteriores (Comience por el ultimo empleo)</strong> </h3>
                    </div>

                    <!-- DATOS EMPLEOS ANTERIORES -->

                    <div class="row">
                      <div class="col-md-12 col-sm-12">

                        <div class="row py-0">

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Nombre de la Empresa:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Nombre" formControlName="dt_empre_ant_emp" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Direccion:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Direccion" formControlName="dt_dir_emp_ant" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Cargo Inicial:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Cargo Inicial" formControlName="dt_cargo_ant_ini" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Cargo Final:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Cargo Final" formControlName="dt_cargo_ant_fin" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Sueldo inicial:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Sueldo inicial" formControlName="dt_sueldo_ant_ini_emp" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Sueldo final:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Sueldo final" formControlName="dt_sueldo_ant_fin_emp" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Supervisor Inmediato:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Supervisor" formControlName="dt_super_ant_emp" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>


                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field >
                              <mat-label>Fecha de ingreso:</mat-label>
                              <input matInput [matDatepicker]="picker10" formControlName="dt_fecha_ing_emp_ant">
                              <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
                              <mat-datepicker #picker10></mat-datepicker>
                            </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field >
                              <mat-label>Fecha de egreso:</mat-label>
                              <input matInput [matDatepicker]="picker11" formControlName="dt_fecha_eg_emp_ant">
                              <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
                              <mat-datepicker #picker11></mat-datepicker>
                            </mat-form-field>
                          </div>


                          <div class="col-md-2 col-sm-2 col-xs-2">
                            <mat-form-field class="materialinput">
                                <mat-label style="color: #000"> Motivo del retiro:</mat-label>
                                <input matInput style="font-size: 12px; color: black;" placeholder="Motivo del retiro" formControlName="dt_retiro_ant_emp" autocomplete="off">
                                <span mat-icon-button matSuffix >
                                <mat-icon>user</mat-icon>
                                </span>
                            </mat-form-field>
                          </div>

                        </div>
                      </div>
                    </div>

                    <!--FIN DATOS EMPLEOS ANTERIORES -->

                    <div class="px-2"  style="background-color: rgb(50, 45, 104); color: #fff;">
                      <h3 class="modal-title" class=" text-left"><strong>Beneficios del Empleado</strong> </h3>
                    </div>


                    <div class="row">

                      <div class="col-md-12 col-sm-12">

                        <div class="row py-0">

                          <div class="col-md-4 col-sm-4 col-xs-4">
                            <mat-form-field class="materialinput">
                                <mat-select style="font-size: 12px; color: black;" placeholder="Comedor" formControlName="dt_comedor" autocomplete="off">
                                  <mat-option  *ngFor="let i of estatus" [value]="i.cod_estatus">
                                    {{i.des_estatus}}
                                  </mat-option>
                                </mat-select>
                                </mat-form-field>
                          </div>

                          <div class="col-md-4 col-sm-4 col-xs-4">
                                <mat-form-field class="materialinput">
                                  <mat-select style="font-size: 12px; color: black;" placeholder="Estatus" formControlName="estatus" autocomplete="off">
                                    <mat-option  *ngFor="let i of estatus" [value]="i.cod_estatus">
                                      {{i.des_estatus}}
                                    </mat-option>
                                  </mat-select>
                              </mat-form-field>
                          </div>

                        </div>

                      </div>  

                    </div>  

                  </div>
                </div>

            <div class="modal-footer">
                <div class="form-group">
                    <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                    <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Guardar</button>
                </div>
            </div>

          </div>

        </form>
      
      </div>

    </div>

  </div>



 

