<div class="container-fluid rounded" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <div class="row">
        <form [formGroup]="forma" class="form-inline my-0 border w-100" (keydown.enter)="$event.preventDefault()">
            <div class="col-md-3 col-sm-12 col-xs-12 ">
                <mat-form-field class="materialinput">
                    <mat-label class="text-white">Parametros Galeria</mat-label>
                    <mat-select formControlName="concepto_definicion" autocomplete="off">
                        <mat-option *ngFor="let i of parametros" [value]="i">{{i.descrip_definicion | uppercase}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-9 col-sm-12 col-xs-12 d-flex justify-content-end">
                <input class="form-control mr-1" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                <button [disabled]="forma.invalid" matTooltip="AGREGAR PARAMETRO" (click)="modalrespuesta()" class="mr-1 col-md-1 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);"><mat-icon >add</mat-icon></button>
                <button matTooltip="SALIR" (click)="cerrarmodal()" class=" col-md-1 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(216, 68, 58);"><mat-icon >exit_to_app</mat-icon></button>
            </div>
        </form>
    </div>
    <div class="row modalgeneral" style=" border: 2px solid #2b69dd; background-color:#29388f;">
        <div class=" table-responsive  h-100" >
            <table  mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_empresa">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Empresa</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_empresa}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="definicion_app">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Descripción</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.definicion_img | uppercase}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="val_def">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Valor</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;" class=" col-md-3">
                        <div *ngIf="element.parametro === 'img'" class="col-md-12 d-flex justify-content-center">
                            <div class="row  w-100">
                                <div class="col-md-10 d-flex justify-content-center">
                                    <form *ngIf="element.val_def === null" [formGroup]="formaImg" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                                        <input (change)="cargar_img($event)"  type="file" class="form-control mr-1 p-1" formControlName="archivo"  accept="image/*" multiple>
                                    </form>
                                    <img *ngIf="element.val_def !== null" [src]="element.val_def" class="mr-2" width="50px" height="50px" class="rounded" >
                                </div>
                                <div class="col-md-2 d-flex justify-content-end py-1">
                                    <button   class=" bg-warn mx-1"   matTooltip="AGREGAR LOGO" mat-mini-fab (click)='getImgConfig(element)'>
                                        <mat-icon >edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="element.parametro === 'txt'" class="col-md-12 d-flex justify-content-center ">
                            <div class="row  w-100">
                                <div class="col-md-10 d-flex justify-content-center">
                                    <form *ngIf="element.val_def === null" [formGroup]="formaTxt" class="form-inline my-0 w-100 mx-1" (keydown.enter)="$event.preventDefault()">
                                        <input type="text" class="form-control mr-1 p-1 w-100" formControlName="val_tex">
                                    </form>
                                    <div class="mr-2 my-3" *ngIf="element.val_def !== null"><b>{{element.val_def}}</b></div>
                                </div>
                                <div class="col-md-2 d-flex justify-content-end py-1">
                                    <button   class=" bg-primary mx-1"   matTooltip="AGREGAR DATOS" mat-mini-fab (click)='getTxtConfig(element)'>
                                        <mat-icon >edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- <b *ngIf="element.parametro === 'dt'" class="col-md-4 d-flex justify-content-center ">{{element.val_def}}</b> -->
                    </td>
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Hora</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                        <h5><strong>Acción</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button  class=" bg-danger"   matTooltip="ELIMINAR" mat-mini-fab (click)='getQuitarParametro(element)'>
                            <mat-icon >clear</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>



