<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); border-radius: 1rem; ">
                    <div class="modal-header pb-0" style="background-color: #0076C7;      border-radius: 0.9rem 0.9rem 0 0">
                        <h3 class="col-12" class=" text-left" style="color: #FFF;" >
                            <strong >Ingrese datos de la factura</strong> <br> 
                            
                        </h3>
                        <div class="alert alert-danger alert-dismissible fade d-none justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
                            <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>{{AlertMensaje}}.
                            <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button> -->
                        </div>

                    </div>
                    <div class="modal-body container mb-5">
                            <form [formGroup]="forma" class="row">

                                <div class="col-12">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Tipo de Documento</mat-label>
                                        <mat-select name="tipoDoc" formControlName="tipo_documento" (selectionChange)="tipoDocu($event)">
                                            <mat-option *ngFor="let option of tipoDoc"  [value]="option.concepto_definicion">
                                                {{option.concepto_definicion}} |
                                                <small>{{option.descrip_definicion}}</small>
                                            </mat-option>
                                            <mat-option  value="FACTURA">
                                                FACTURA |
                                                <small>FCT</small>
                                            </mat-option>
                        
                                          </mat-select>
                                    </mat-form-field>

                                </div>
                                
                                <div class="col-6" *ngIf="tipoDocus">
                                    <div class="col">
                                        <mat-form-field >
                                            <mat-label>Codigo documento</mat-label>
                                            <input matInput placeholder="00000000" formControlName="cod_documento" >
                                        </mat-form-field>
                                        <mat-form-field class="materialinput py-1 px-1">
                                            <mat-label >Fecha Emision</mat-label>
                                            <input matInput placeholder="DD/MM/AAAA" formControlName="fecha_emision" id="fecha_emision" [matDatepicker]="picker1">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>                                         
                                          <!-- <mat-form-field >
                                            <mat-label>Flete</mat-label>
                                            <input matInput formControlName="flete" id="flete" (keyup)="ponerPuntosyComas($event, 'flete')" >
                                          </mat-form-field><span>{{moneda}}</span>
                                          <mat-form-field  >
                                            <mat-label>Sub Total</mat-label>
                                            <input matInput formControlName="sub_total" id="sub_total" (keyup)="ponerPuntosyComas($event, 'sub_total')" >
                                          </mat-form-field><span >{{moneda}}</span> -->
                                          
                                          <div class=" fileUpload btn btn-sm btn-primary" >
                                              <mat-label for="archivo" > Cargar Documento</mat-label>
                                              <input class="upload" type="file" (change)="tomarFoto($event)" name="archivo" id="archivo" multiple="false" accept="image/*">
                                          </div>
                                         

                                          <div *ngIf="previsualizacion" id="previsualizacion-caja">
                                                <img width="100px"  style="position: absolute;" class="mt-3 mat-elevation-z8" [src]="previsualizacion" alt="" accept="image/*" id="previsualizacion">
                                          </div>
                                    </div>

                                </div>
                                <div class="col-6"  *ngIf="tipoDocus">
                                    <div class="col">
                                        <mat-form-field >
                                            <mat-label>N° Control</mat-label>
                                            <input matInput placeholder="00000000" formControlName="cod_control" >
                                        </mat-form-field>
                                        <mat-form-field class="materialinput py-1 px-1">
                                            <mat-label >Fecha Recepcion</mat-label>
                                            <input matInput placeholder="DD/MM/AAAA"  formControlName="fecha_recepcion" id="fecha_recepcion" [matDatepicker]="picker">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>

                                        <!-- <mat-form-field *ngIf="tipoDocus == 'FAC'" >
                                            <mat-label>IVA</mat-label>
                                            <input matInput formControlName="iva"  id="iva"  (keyup)="ponerPuntosyComas($event, 'iva')" >
                                        </mat-form-field><span *ngIf="tipoDocus == 'FAC'">{{moneda}}</span>

                                        <mat-form-field *ngIf="tipoDocus == 'FAC'" >
                                            <mat-label>Ret. IVA</mat-label>
                                            <input matInput formControlName="ret_iva"  id="ret_iva" (keyup)="ponerPuntosyComas($event, 'ret_iva')" >
                                        </mat-form-field><span *ngIf="tipoDocus == 'FAC'" >{{moneda}}</span>
                                        
                                        <mat-form-field >
                                           <mat-label>Total</mat-label>
                                           <input matInput formControlName="total" id="total" (keyup)="ponerPuntosyComas($event, 'total')" readonly>
                                        </mat-form-field><span>{{moneda}}</span> -->

                                    </div>

                                </div>
                                   

                            </form>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">

                            <button mat-button class=" btn-danger " (click)="cerrarmodal()" style="margin-right: 5px; border-radius: 1rem;">Cancelar</button>

                            <button mat-button class=" btn-primary " style="border-radius: 1rem;" (click)="listo()" [disabled]="forma.invalid">Listo</button>


                        </div>

                    </div>
                </div>
        </div>
    </div>
</div>