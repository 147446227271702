<div class="col-md-12 col-sm-12 col-xs-12 my-0" style=" border-radius: 1rem; ">
    <form [formGroup]="forma">
        <div class="modal-content text-white " style=" width: 100%; background-color: #1E212C; border-radius: 1rem;">
            <div class="modal-header px-5" style="background-color: #1E212C;  border-radius: 1rem 1rem 0 0;">
                <h3 class="modal-title text-center"><strong class="text-center">Crear Ticket</strong> </h3>
            </div>
            <div class="modal-body bg-light">
                <div class="row px-2">
                    <div class="col-md-12 col-sm-12">
                        <div class="row my-1" style=" border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group">
                                    <mat-form-field class="materialinput">
                                        <mat-label style="color: #000">Tipo de solicitud</mat-label>
                                        <mat-select (selectionChange)="buscar_Tarea_hija(tarea.value)" #tarea class="" style="font-size: 12px; color: black; border: none;" name="estatus" placeholder="" formControlName="tipo_solicitud" autocomplete="off">
                                            <mat-option *ngFor="let item of solicitud" [value]="item">{{item.des_solicitud}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group">
                                    <mat-form-field class="materialinput">
                                        <mat-label style="color: #000">Tipo de Tarea</mat-label>
                                        <mat-select class="" style="font-size: 12px; color: black; border: none;" #tipo_update name="estatus" placeholder="" formControlName="tipo_soporte" autocomplete="off">
                                            <mat-option *ngFor="let item of tarea_hija" [value]="item">{{item.des_solicitud}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>
                        <div class="row my-1" style=" border: 1px solid rgba(128, 128, 128, 0.651); border-radius: 5px;">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <mat-form-field class="materialinput">
                                        <mat-label style="color: #000">Descripción</mat-label>
                                        <textarea style="font-size: 12px; color: black;" maxlength="150" matInput placeholder="" autocomplete="off" formControlName="descripcion_soporte"> </textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer bg-light" style="border-radius: 0 0 1rem 1rem;">
                <div class="form-group">
                    <button mat-button class=" btn-danger" (click)="cerrarmodal()" style=" margin-right: 5px;">Cancelar</button>
                    <button mat-button [disabled]="forma.invalid" class=" btn-primary" (click)="modalrespuesta()"> Enviar</button>
                </div>
            </div>
        </div>
    </form>
</div>