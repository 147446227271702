<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente py-1">
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li  class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarmenu("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li  class="nav-item"><button matTooltip="CREAR MENU" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","crear")'><mat-icon >add</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>DEFINICIONES MENU DEL SISTEMA</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>
    <div class="row altura_table ">
        <div class="col-md-12 px-0 border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-condensed table-hover">
             
                <ng-container matColumnDef="cod_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Id</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_menu}} </td>
                </ng-container>

                <ng-container matColumnDef="des_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Menu </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_menu}} </td>
                </ng-container>

                <ng-container matColumnDef="dir_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Ruta</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.dir_menu}} </td>
                </ng-container>

                <ng-container matColumnDef="estatus_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Estatus </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus_menu}} </td>
                </ng-container>

                <ng-container matColumnDef="ord_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Nivel </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.ord_menu}} </td>
                </ng-container>

                <ng-container matColumnDef="padre_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Cod m-p</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.padre_menu}} </td>
                </ng-container>

                <ng-container matColumnDef="des_padre_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Menu padre</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_padre_menu}} </td>
                </ng-container>

                <ng-container matColumnDef="nivel_padre_menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Nivel padre</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.nivel_padre_menu}} </td>
                </ng-container>

                <ng-container matColumnDef="cod_unidad">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Cod unidad </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_unidad}} </td>
                </ng-container>

                <ng-container matColumnDef="des_unidad">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Unidad </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_unidad}} </td>
                </ng-container>

                <ng-container matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Procesos</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class=" bg-primary mx-2"   matTooltip="EDITAR" mat-mini-fab (click)='update_insert_data(element,"actualizar")'>
                            <mat-icon >edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>