<div class="fadeIn2 animated fast">
    <nav class="navbar navbar-expand-lg " style="height: 7%; border:1px solid #cccccccc ;">

        <div class=" navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color="primary" (click)="listardatos('actualizar')"> <mat-icon style="margin: 0% 2% 0% 0%;">update</mat-icon>Actualizar lista</button></li>
                <li class="nav-item"><button class="my-2 my-sm-0 mr-sm-2" mat-button color="success" (click)='editardatos("","insertar")'><mat-icon style="margin: 0% 2% 0% 0%;">create</mat-icon>Agregar un registro</button></li>
                <li class="nav-item">
                    <div class="my-2 my-sm-0 mr-sm-2" style=" padding: 2.7%; width: 103%; cursor:pointer;"><input style="cursor:pointer;" #myInput type="file" (change)="handleFile($event)" title="Carga mavsiva xlxs" /></div>
                </li>
            </ul>
            <button (click)='eliminardatos("")' class="my-2 my-sm-0 mr-sm-2" mat-button color="warn"><mat-icon style="margin: 0% 2% 0% 0%;">delete</mat-icon>Limpiar tabla</button>
            <form class="form-inline my-auto my-lg-auto">
                <mat-form-field class="my-2 my-sm-0 mr-sm-2">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="buscarH(input.value)" placeholder="Buscar" autocomplete="off" #input>
                </mat-form-field>
            </form>
        </div>
    </nav>
    <div class="container-fluid">
        <div class="">
            <div class="col-md-12 col-sm-12 col-xs-12 ">
                <br>
                <div class="example-container mat-elevation-z8 table-responsive">

                    <table mat-table [dataSource]="dataSource" class="text-center table-striped table-bordered table-condensed table-hover">

                        <!-- Position Column -->
                        <ng-container matColumnDef="Nombre">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <h3><strong>Nombre</strong> </h3>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.Nombre}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="Apellido">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <h3><strong>Apellido</strong></h3>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.Apellido}} </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="Direccion">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <h3><strong>Direccion</strong></h3>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.Direccion}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Correo">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <h3><strong>Direccion de correo</strong></h3>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.Correo}} </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="Eliminar">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <h3><strong>Eliminar</strong></h3>
                            </th>
                            <td mat-cell *matCellDef="let element" (click)='eliminardatos(element.id)' class="text-center">
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="warn" aria-label="Example icon button with a delete icon">
                                      <mat-icon>delete</mat-icon>
                                    </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Actualizar">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <h3><strong>Actualizar</strong></h3>
                            </th>
                            <td mat-cell *matCellDef="let element" (click)='editardatos(element, "actualizar")' class="text-center">
                                <section>
                                    <div class="example-button-container" class="text-center">
                                        <button mat-icon-button color="primary" style="margin: 1%;" aria-label="Example icon button with a delete icon">
                                      <mat-icon>update</mat-icon>
                                    </button>
                                    </div>
                                </section>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[3, 6, 9]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>

    </div>

    <!--    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <table class="table table-condensed">
                    <thead class="">
                        <th>NOMBRE</th>
                        <th>APELLIDO</th>
                        <th>DIRECCION</th>
                        <th>E-MAIL</th>
                        <th>ELIMINAR</th>
                        <th>ACTUALIZAR</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of obj" class="" style="font-size:120%;">
                            <td *ngIf="i.id">{{i.nombre_usu}} </td>
                            <td *ngIf="i.id">{{i.apellido_usu}}</td>
                            <td *ngIf="i.id">{{i.direccion_usu}}</td>
                            <td *ngIf="i.id">{{i.correo_usu}}</td>
                            <td *ngIf="i.id"><button (click)='eliminardatos(i)' class="btn btn-outline-light btn-block">Eliminar</button></td>
                            <td *ngIf="i.id"><button (click)='editardatos(i)' class="btn btn-outline-light btn-block">Editar</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div> -->
</div>