import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { Element } from '@angular/compiler';
import { element } from 'protractor';
import * as moment from 'moment'
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

import {FormCantidadProductoReqCompraComponent} from '../form-cantidad-producto-req-compra/form-cantidad-producto-req-compra.component';




@Component({
  selector: 'app-form-aprobar-solicitud-compra',
  templateUrl: './form-aprobar-solicitud-compra.component.html',
  styleUrls: ['./form-aprobar-solicitud-compra.component.css']
})
export class  FormAprobarSolicitudCompraComponent implements OnInit {

  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`
  validador:any = false

  
  dataSource=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
 verticalPosition: MatSnackBarVerticalPosition = 'top';

 constructor( public dialog: MatDialog,fb:FormBuilder,
   public dialogRefp: MatDialogRef<FormAprobarSolicitudCompraComponent>,
   ///en el injectable llega la informacion del metodo padre en data
  @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices) {

    console.log(data, 'la data')
    this.forma = fb.group({
      'observaciones_aprobacion':[data.observaciones_aprobacion,[Validators.required, Validators.minLength(30)]]
    });

  }

  ngOnInit(): void {

    console.log(this.data.accion,'accion')
    if(this.data.accion == 'ver'){
      this.mostrarTablaVer()
      
    }else{
      this.mostrarTabla()
    }
    

  }

  mostrarTabla(){

    try {

      this.servicio.listarProductosSolicituComprahttp(this.data.cod_solicitud_compra).subscribe(result => {

        this.validador = true
  
        console.log(result.result)
  
        if(result.result){
          result.result.forEach(element => {
  
            let obj = {
                      cod_producto:element.cod_producto,
                      des_producto:element.des_producto,
                      cant_producto_soli:element.cant_producto_soli,
                      cant_aprobada:element.cant_producto_soli,
                      tipo_unidad_medida:element.tipo_unidad_medida,
                      check_estatus: true
                        }
            this.ELEMENT_DATA.push(obj)
          });
        }
  
        this.displayedColumns = [
          'cod_producto',
          'des_producto',
          'cant_producto_soli',
          'cant_aprobada',
          'tipo_unidad_medida',
          'tomar_data_ingresada', 
        ];
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      
  
      })
      
    } catch (error) {

      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
      this.displayedColumns = [
        'cod_producto',
        'des_producto',
        'cant_producto_soli',
        'cant_aprobada',
        'tipo_unidad_medida',
        'tomar_data_ingresada', 
      ];
      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    
      
    }

    

  }
  mostrarTablaVer(){

    try {

      this.servicio.listarProductosSolicituComprahttp(this.data.cod_solicitud_compra).subscribe(result => {

        console.log(result.result)
  
        if(result.result){
          result.result.forEach(element => {
  
            let obj = {
                      cod_producto:element.cod_producto,
                      des_producto:element.des_producto,
                      cant_producto_soli:element.cant_producto_soli,
                      cant_aprobada:element.cant_producto_ap != 'N/A' ? element.cant_producto_ap : '-',
                      tipo_unidad_medida:element.tipo_unidad_medida,
                      tipo_producto:element.tipo_producto,
                      check_estatus: false
                        }
            this.ELEMENT_DATA.push(obj)
          });
        }
  
        this.displayedColumns = [
          'cod_producto',
          'des_producto',
          'cant_producto_soli',
          'cant_aprobada',
          'tipo_unidad_medida',
        ];
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      
  
      })
  

    } catch (error) {

      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
      this.displayedColumns = [
        'cod_producto',
        'des_producto',
        'cant_producto_soli',
        'cant_aprobada',
        'tipo_unidad_medida',
        'tomar_data_ingresada', 
      ];
      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    
      
    }

   
  }
  
  tomar_data(xvalor:any, xvalor2:any, xvalor3:any){

    if(xvalor){ 
      var dialogRef = this.dialog.open(FormCantidadProductoReqCompraComponent, {
        width:'100vw',
        maxWidth:'100vw',
        height:'100%',
        data: {des_producto:xvalor2.des_producto,
              cant_producto_soli:xvalor2.cant_producto_soli,
              cod_producto:xvalor2.cod_producto,
              cant_ingresada:xvalor2.cant_ingresada,
              fase:this.data.fase
              },
              disableClose:true,
        panelClass:'pedidos_modal',
        backdropClass:"bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {

        if(result != 'cerrar'){

          this.ELEMENT_DATA.forEach(element=> {

            if(element.cod_producto == result.cod_producto){

              element.cant_aprobada = result.cant_ingresada
              element.check_estatus = true

            }

          })
  
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
          console.log(result)

        }else{

          
          this.ELEMENT_DATA.forEach(element=> {
            if(element.cod_producto == result.cod_producto){
              
              element.check_estatus = false

            }
          })
  
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);

        }

        console.log(xvalor3)
       
      })

    }else{

      this.ELEMENT_DATA.forEach(element=> {

        if(element.cod_producto == xvalor2.cod_producto){
              
          element.check_estatus = false

          element.cant_aprobada = '0'

        }

      })

    }

    console.log(xvalor2)


  }


  enviarAprobacion(){

    let ceros = 0;

    this.ELEMENT_DATA.forEach(element => {if(element.check_estatus == false)ceros++})

    if(ceros){

      this.accion = 'custom'
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
               accion:this.accion,
               mensaje: `Tienes ${ceros} productos en 0 ¿Estas seguros? `
              },
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        
        this.data.productos = this.ELEMENT_DATA
        this.data.observaciones_aprobacion = this.forma.value.observaciones_aprobacion
        this.data.usuario = {
                                des_usuario: localStorage.getItem('Nombre') + ' '+ localStorage.getItem('Apellido'),
                                cod_usuario: localStorage.getItem('cod_usuario')
                              }

        this.servicio.aprobarSolicitudComprahttp(this.data).subscribe(
        result=> {
          if(result != 'cerrar' ){

            this.dialogRefp.close()

          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        })
        
      });

    }else{
      
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
              },
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(() => {

        this.data.productos = this.ELEMENT_DATA
        this.data.observaciones_aprobacion = this.forma.value.observaciones_aprobacion
        this.data.usuario = {
          des_usuario: localStorage.getItem('Nombre') + ' '+ localStorage.getItem('Apellido'),
          cod_usuario: localStorage.getItem('cod_usuario')
        }

        this.servicio.aprobarSolicitudComprahttp(this.data).subscribe(result=> {
          if(result != 'cerrar' ){

            this.dialogRefp.close()

          }
        })
        
      });

    console.log(this.data)


    }

  }

  async imprimirOrdenRequision(){
    try {

        console.log('imprimir');
        var data:any = this.ELEMENT_DATA
        var items:any= this.ELEMENT_DATA.length;
        var creador_orden_req = this.data.des_usuario;
        var nro_orden_req=this.data.cod_orden_req;
        var fecha_estimada=this.data.fecha_estimada;
        var emision= moment().format('DD/MM/YYYY');
        var aprobador='';
        var departamento=this.data.departamento.toUpperCase();
        var prioridad=this.data.prioridad;
        var centro_costo=this.data.cod_centro_costo;
        var observaciones =this.data.observaciones_aprobacion
        var presupuesto = this.data.cod_presupuesto
        var partida = this.data.cod_cuenta_gasto


        const tablaEnLista:any = generarTabla(data)

        function generarTabla(dato:any){
          
          for(let i = 0; i < 28; i++ ){
                          
            if(dato[i] === undefined){
              
              let articuloVacio =  {
                   cod_producto: ' ',
                   des_producto: ' ',
                   cant_producto: ' ',
                   partida: ' ',
                   des_partida:'',
                   tipo_pro:''
              }
              dato[i] = articuloVacio
            }
          }
            let arry:any =[
              [
                  {text: 'Código', alignment: 'center', fontSize: 7, border: [true, true, false, true],bold:true}, 
                  {text: 'Descripción', alignment: 'center', fontSize: 7, border: [false, true, false, true],bold:true}, 
                  {text: 'Cantidad', alignment: 'center', fontSize: 7,border: [false, true, false, true],bold:true},
                  {text: 'UM', alignment: 'center', fontSize: 7,border: [false, true, false, true],bold:true},
                  {text: 'Tipo de producto', alignment: 'center', fontSize: 7,border: [false, true, true, true],bold:true},
              ],
          ]
          for (const i in dato) {

              if(dato[i].cod_producto > 0){

                  items = dato[i].length
              }
                
              let articulo:any = [
                  {text: dato[i].cod_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
                  {text: dato[i].des_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],}, 
                  {text: dato[i].cant_aprobada, alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].tipo_unidad_medida, alignment: 'center', fontSize: 6,border: [false, false, false, false],},
                  {text: dato[i].tipo_producto, alignment: 'center', fontSize: 6,border: [false, false, false, false],},
              ]
              arry.push(articulo)
          }
          return arry;
        }
          
        
        const pdfDefinition: any = {
          pageSize: 'LETTER',
          info:{
            title:`Orden de Requisición N° ${nro_orden_req}`,
            author: 'FACTORY COSMETICS 3DG, C.A',
            subject:`Orden de Requisición N° ${nro_orden_req}`,
          },
          background: [
            { image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/marca_compas_pdf.png"), width: 680, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
          ],
          content: [
            {
              style:'header',
              table:{
              body:
                [
                  [
                    {image: await this.getBase64ImageFromURLdaily("../../../../../../assets/img/logo-factory.png"), fit:[80,80],alignment:'left',},
                    {text:'\nFACTORY COSMETICS 3DG, C.A.\n \
                            Calle bolivar con Calle Regulo Franquiz Local S/N\n \
                            Sector Pueblo Arriba. Guarenas, Miranda.\n \
                            Zona Postal 1220. Telf.:(0212) 519.43.98/ 365.46.24/ 714.03.42/ 425.2.83 \n \
                            E-mail: contacto@factorycosmetics.com.ve / www.factorycosmetics.com.ve\n \
                            RIF.: J-41031797-3',alignment: 'center',fontSize:9, margin:[30,0,0,0]},
                    /* {text:'    ', colSpan: 1,}, */
                  ],
                ],
              },layout: {
                defaultBorder: false,
              },
            },
            {
              style:'cuadros',
              table:{
                headerRows: 1,
                widths: [ 220, 47.5, 220,],
              body:
                [
                  [
                    {text:`Solicitud realizada por: ${creador_orden_req}`,alignment: 'left',bold:true,border: [false, false, false, false],fontSize:9,},
                    {text:'', colSpan: 1,border: [false, false, false, false],},
                    {text:`Orden de Requisición N° ${nro_orden_req}`,alignment: 'right',bold:true,border: [false, false, false, false],fontSize:9,},
                  ],
                  [
                    {text:`Aprobador: ${aprobador}`,alignment: 'left',border: [false, false, false, false],fontSize:8,},
                    {text:'', colSpan: 1,border: [false, false, false, false],},
                    {text:`Emisión: ${emision}`,alignment:'right',border: [false, false, false, false],fontSize:8,},
                  ],
                  [
                    {text:`Departamento: ${departamento}`,alignment: 'left',border: [false, false, false, false],fontSize:8,},
                    {text:'', colSpan: 1,border: [false, false, false, false],},
                    {text:`Fecha estimada: ${fecha_estimada}`,alignment:'right',border: [false, false, false, false],fontSize:8,},
                  ],
                  [
                    {text:`Centro de Costo: ${centro_costo}`,alignment: 'left',border: [false, false, false, false],fontSize:8,},
                    {text:'', colSpan: 1,border: [false, false, false, false],},
                    {text:`Prioridad: ${prioridad}`,alignment:'right',border: [false, false, false, false],fontSize:8,},
                  ],
                  [
                    {text:`Presupuesto: ${presupuesto}`,alignment: 'left',border: [false, false, false, false],fontSize:8,},
                    {text:'', colSpan: 1,border: [false, false, false, false],},
                    {text:' ',alignment:'right',border: [false, false, false, false],fontSize:8,},
                  ],
                  [
                    {text:`Partida: ${partida}`,alignment: 'left',border: [false, false, false, false],fontSize:8,},
                    {text:'', colSpan: 1,border: [false, false, false, false],},
                    {text:' ',alignment:'right',border: [false, false, false, false],fontSize:8,},
                  ],
                ],
              },
            },
            { 
              style: 'Descripcion',
              table: {
                  headerRows: 1,
                  widths: [55,'*',50,80,80],
                  body: tablaEnLista,
              }, 
            },
              {text:`Items: ${items <= 0 ? 0 :items}`,alignment:'left',fontSize:7,},
              {text:`Observaciones:
                    \n ${observaciones}`,aligment:'left',bold:true,fontsSize:6},
          ],
          styles: {
            header: {
              margin: [ 0, 0, 0, 25]
            },
            cuadros: {
                margin: [ 0, 0, 0, 25]
            },
        },
    }
   
        const pdf = pdfMake.createPdf(pdfDefinition);
        pdf.open();

    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }

  getBase64ImageFromURLdaily(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

  
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

   

}
