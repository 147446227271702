import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-formulacion-mpp',
  templateUrl: './form-formulacion-mpp.component.html',
  styleUrls: ['./form-formulacion-mpp.component.css']
})
export class FormFormulacionMppComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_formula') cod_formula:ElementRef;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('cod_producto_mp') cod_producto_mp:ElementRef;
  @ViewChild('cantidad_prod_mp',{static:true}) cantidad_prod_mp:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;

  arry: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
dialogSpin:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormFormulacionMppComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_prod_mpp':data.cod_producto,
        'des_prod_mpp':data.des_producto,
        'cod_formula':data.cod_formula,
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'tipo_producto':data.tipo_producto,
        'cantidad_prod_mp':'',
        'cod_prod_mp':'',
        'unidad_prod_mp':'',
        'formula':''
      });
    }

  ngOnInit(): void {
    this.formulariompp(this.data);
    this.listarMpp();
  }
  ngAfterViewInit() {}

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
  listarMpp(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarMateriaPrimaPreparadahttp().subscribe(
      response =>{

        this.arry = this.forma.get('cod_prod_mp')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }

                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.codigo
                  }];

                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  formulariompp(cod:any):void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    var valor = {cod_producto: cod};
    this.servicio.buscarFormulaMpphttp(cod).subscribe(
      response =>{
        for (const i in response) {

          this.ELEMENT_DATA.push({
            cod_prod_mp:response[i].cod_prod_mp,
            des_prod_mp:response[i].des_prod_mp,
            cantidad_prod_mp: response[i].cantidad_prod_mp,
            unidad_prod_mp:response[i].unidad_prod_mp,
            tipo_producto_mp:response[0].tipo_producto_mp,
            accion:'',
            accion2:'',
            accion3:''
          });
        }
        this.displayedColumns = [
         
          'cod_prod_mp',
          'des_prod_mp',
          'cantidad_prod_mp',
          'tipo_producto_mp',
          'unidad_prod_mp',
          'remov_elem'
         ];

      this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
      },
      error =>{
        console.log(<any>error.error.message);
        this.displayedColumns = [
          
          'cod_prod_mp',
          'des_prod_mp',
          'cantidad_prod_mp',
          'tipo_producto_mp',
          'unidad_prod_mp',
          'remov_elem'
         ];

      this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  addElement() {
    let arry = [];
    let arry2 = [];

    console.log(this.forma);

    if (parseInt(this.cantidad_prod_mp.nativeElement.value) >= 0 && this.cod_producto_mp.nativeElement.value != '' &&this.cod_producto_mp.nativeElement.value != undefined) {
      var cod = this.cod_producto_mp.nativeElement.value;
    var valor:any = {cod_producto:cod,
                      tipo_producto:this.forma.value.tipo_producto}
    this.servicio.buscarPRODUCTOmpttp(valor).subscribe(
      response =>{
        console.log(response);

        if (this.ELEMENT_DATA.length == 0) {
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];

          this.listarMpp();
          this.ELEMENT_DATA.push({
            cod_prod_mp:response[0].cod_producto,
            des_prod_mp:response[0].des_producto,
            cantidad_prod_mp:this.cantidad_prod_mp.nativeElement.value,
            unidad_prod_mp:response[0].unidad_medida_producto,
            tipo_producto_mp:response[0].tipo_producto

          });

          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
          this.cod_producto_mp.nativeElement.value = '';
          this.cantidad_prod_mp.nativeElement.value = '';

        }else{
          console.log(this.ELEMENT_DATA);
            for (const i in this.ELEMENT_DATA) {
             arry.push(this.ELEMENT_DATA[i].cod_prod_mp);
            }
            let index = arry.includes(response[0].cod_producto);

            if (!index) {
              this.codigo = [];
              this.producto = [];
                this.listarMpp();
                this.ELEMENT_DATA.push({
                  cod_prod_mp:response[0].cod_producto,
                  des_prod_mp:response[0].des_producto,
                  cantidad_prod_mp:this.cantidad_prod_mp.nativeElement.value,
                  unidad_prod_mp:response[0].unidad_medida_producto,
                  tipo_producto_mp:response[0].tipo_producto
                });
                this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
                this.cod_producto_mp.nativeElement.value = '';
                this.cantidad_prod_mp.nativeElement.value = '';

            }else{
              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];
              this.listarMpp();

              this.cod_producto_mp.nativeElement.value = '';
              this.cantidad_prod_mp.nativeElement.value = '';


                this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
            }
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
    }else{

         if (parseInt(this.cantidad_prod_mp.nativeElement.value) <= 0 || isNaN(this.cantidad_prod_mp.nativeElement.value) || this.cantidad_prod_mp.nativeElement.value === "") {
          this.snackBar.open('La cantidad debe ser un numero positivo y solo se aceptan numeros', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if ( this.cod_producto_mp.nativeElement.value === "") {
          this.snackBar.open('Debe seleccionar un producto base', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    }
  }

  eliminarItems(val:any){
    this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
     this.table.renderRows();
   }

   //////////////////////FUNCION DE ACCION/////////////////////////

   update_insert_formula(){
    try {
      if (this.ELEMENT_DATA.length > 0) {
        this.accion = "formulacionmpp";
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'100%',
          data: {id:'',
                accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
    
            this.forma.value.formula = this.ELEMENT_DATA;
            console.log(this.forma.value);
          
            this.gestionformulaMpp(this.forma.value);
          }
        });
      }else{
        this.snackBar.open('Debes seleccionar un producto base.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
}

gestionformulaMpp(xvalor:any){
  try {
   this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
     width: '15%',
     height:'25%',
     data: {
             accion:this.accion},
     disableClose: true,
     panelClass: 'spinner',
     backdropClass: "bdrop"
   });
   setTimeout(() => {
     var resultado = this.servicio.gestionFormulaMpphttp(xvalor).subscribe(
      response =>{
        this.dialogSpin.close();
        console.log(response);
        this.cod_formula.nativeElement.value = response.resul[0].cod_formula;
        this.ELEMENT_DATA = [];
        for (const i in response.resul) {

          this.ELEMENT_DATA.push({
            cod_prod_mp:response.resul[i].cod_prod_mp,
            des_prod_mp:response.resul[i].des_prod_mp,
            cantidad_prod_mp: response.resul[i].cantidad_prod_mp,
            tipo_producto_mp: response.resul[i].tipo_producto_mp,
            unidad_prod_mp:response.resul[i].unidad_prod_mp,
            accion:'',
            accion2:'',
            accion3:''
          });
        }
        this.displayedColumns = [

          'cod_prod_mp',
          'des_prod_mp',
          'tipo_producto_mp',
          'cantidad_prod_mp',
          'unidad_prod_mp',
          'remov_elem'
         ];

      this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);

            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
      },
      error =>{
        this.dialogSpin.close();
        console.log(<any>error.error.message);
      this.displayedColumns = [

        'cod_prod_mp',
        'des_prod_mp',
        'tipo_producto',
        'cantidad_prod_mp',
        'unidad_prod_mp',
        'remov_elem'
       ];

    this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      }
     );
 
     return resultado;
   }, 3000);
  } catch (error) {
   this.dialogSpin.close();
   this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
     verticalPosition: this.verticalPosition});
  }
}

//////////////////////FUNCION DE ACCION/////////////////////////

cerrarmodal(): void {
  this.accion = 'salir';
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100%',
    data: {id:'',
          accion:this.accion},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result != 'cerrar') {
      this.cerrar();
    }
  });
}

cerrar():void{
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.dialogSpin.close();
      this.dialogRefp.close('cerrar');
    }, 2000);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
  format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}





