import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';


@Component({
  selector: 'app-form-detalle-guia',
  templateUrl: './form-detalle-guia.component.html',
  styleUrls: ['./form-detalle-guia.component.css']
})
export class FormDetalleGuiaComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_factura',{static:true}) cod_factura:ElementRef;
  @ViewChild('cod_pedido',{static:true}) cod_pedido:ElementRef;
 
  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
accion:any;
unidad:any;
unidadp:any = [];
dialogSpin:any;
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
inventario :any;
cod_deposito:any;
observador:any = false;
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
   dataSource1=new MatTableDataSource<any>();
   dataSource2=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormDetalleGuiaComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'cod_pedido':data.cod_pedido,
        'cod_lista_precio':data.cod_lista_precio,
        'cod_deposito':data.cod_deposito,
        'des_deposito':data.des_deposito,
        'pedido':'',
        'cod_control':''

      });
    }

  ngOnInit(): void {

    this.cod_factura.nativeElement.value = this.data.cod_factura;
    this.listarFormulaprod(this.data);

  }
  ngAfterViewInit() {

  }

  listarFormulaprod(xvalor:any){
    var valor = {cod_factura:xvalor.cod_factura}
   this.servicio.buscarProductoPedidoGuiahttp(valor).subscribe(
     response =>{
       console.log(response,'ver el objeto si esta vacio');
       this.cod_pedido.nativeElement.value = response[0].cod_pedido;
       var um;
        
         this.ELEMENT_DATA = [];
         
             for (const i in response) {
              if (xvalor.objeto.um_guia.toUpperCase() === 'UM') {
                um = parseInt(response[i].cant_producto.replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace(',', '.')) / parseInt(response[i].unidad_manejo.replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace(',', '.'));

                this.ELEMENT_DATA2.push({
                  cod_producto2:response[i].cod_producto,
                  des_producto2:response[i].des_producto,
                  cant_producto2:response[i].cant_producto,
                  cant_blts:um.toFixed(0).replace(/\D/g, "")
                              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
    
                });
              }else{
                um = 'N/A';
                this.ELEMENT_DATA2.push({
                  cod_producto2:response[i].cod_producto,
                  des_producto2:response[i].des_producto,
                  cant_producto2:response[i].cant_producto,
                  cant_blts:um,
    
                });
              }
              
             }
              this.displayedColumns2 = [
                                       'cod_producto',
                                       'des_producto',
                                       'cant_producto',
                                       'cant_blts'
                                     ];
            
             this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);
             
     },
     error =>{
       console.log(error);
       console.log(<any>error.error.message);
    
      this.displayedColumns2 = [
                                'cod_producto',
                                'des_producto',
                                'cant_producto',
                                'cant_blts'
                              ];
       this.dataSource1 = new MatTableDataSource<any>();
       this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
         verticalPosition: this.verticalPosition});
     }
   );
 }

 
  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  cantidad_productos() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA2) {
        matriz.push(this.ELEMENT_DATA2[i].cant_blts)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
  
       return suma.toFixed(0).replace(/\D/g, "")
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    }
    cantidad_productos_2() {
      try {
        var suma = 0;
        var matriz = [];
        for (const i in this.ELEMENT_DATA2) {
          matriz.push(this.ELEMENT_DATA2[i].cant_producto2)
        }
        matriz.forEach (function(numero){
               suma += parseInt(numero.replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace(',','.')) ;
           });
  
         return suma.toFixed(0).replace(/\D/g, "")
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      }
    cantidad_item() {
      try {
        return this.ELEMENT_DATA.length;
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
  
    }
    cantidad_item_2() {
      try {
        return this.ELEMENT_DATA2.length;
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

    costo_producto() {

      try {
        var suma = 0;
        var matriz = [];
        for (const i in this.ELEMENT_DATA) {
          matriz.push(this.ELEMENT_DATA[i].costo_producto)
        }
        matriz.forEach (function(numero){
               suma += parseFloat(numero.replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace('.','')
               .replace(',','.')) ;
           });
    
         return suma.toFixed(3).replace(/\D/g, "")
         .replace(/([0-9])([0-9]{3})$/, '$1,$2')
         .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
}
