import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { nkServices } from '../../servicios/app.nkservicios.services';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-mkp-modal-show-productos',
  templateUrl: './mkp-modal-show-productos.component.html',
  styleUrls: ['./mkp-modal-show-productos.component.css']
})
export class MkpModalShowProductosComponent implements OnInit {

  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  dolar1:any = 0
  dolar2:any = 0
  dolar3:any = 0
  bolivar1:any = 0
  bolivar2:any = 0
  bolivar3:any = 0
  urlDireccion:any;
  img:any

  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  aprobar:any=false

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';



  constructor( public dialog: MatDialog,
    public dialogRefp: MatDialogRef<MkpModalShowProductosComponent>,
    ///en el injectable llega la informacion del metodo padre en data
   @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices) {

    console.log(data, 'la data');
    let dataDir = JSON.parse(data.dir_data)

    this.urlDireccion =`https://www.google.com/maps?q=${dataDir.lat},${dataDir.lng}`

   }


   ngOnInit(): void {

    this.listarSolicitudesCompra()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  listarSolicitudesCompra(){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });


        this.servicio.listarCotizacionesNuevashttp().subscribe(
          response =>{
            console.log(response, )
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              let xobj2 = response.result;
              let array = []
              this.ELEMENT_DATA = [];

              xobj2.forEach(orden => {

                array.unshift({
                  id_solicitud_compra:orden.id_solicitud_compra,
                  cod_solicitud_compra: orden.cod_solicitud_compra,
                  cod_orden_req:orden.cod_orden_req ? orden.cod_orden_req : '-',
                  cod_cotizacion_req:orden.cod_cotizacion ? orden.cod_cotizacion : '-',
                  tipo_solicitud_compra:orden.tipo_solicitud_compra,
                  tipo_producto: orden.tipo_producto,
                  cod_usuario:orden.cod_usuario,
                  des_usuario:orden.des_usuario,
                  departamento: orden.departamento,
                  cod_centro_costo: orden.cod_centro_costo,
                  observaciones: orden.observaciones,
                  observaciones_aprobacion: orden.observaciones_aprobacion,
                  modalidad: orden.modalidad,
                  prioridad: orden.prioridad,
                  fecha_estimada: orden.fecha_estimada,
                  estatus:orden.estatus,
                  fecha:orden.fecha,
                  accion:''
                })

              })


               
               let aprobado = array.filter(element => element.estatus == "CTA") 

               this.ELEMENT_DATA = aprobado

                 
              this.displayedColumns = [
                'cod_cotizacion_req',
                'estatus',
                'fecha',
                'prioridad',
                'cotizar',
                'ver'
              ];
                /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              'cod_cotizacion_req',
              'estatus',
              'fecha',
              'prioridad',
              'cotizar',
              'ver'
            ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
          }
        );
        return this.xobj2;
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  en_construccion(): void {

    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    } catch (error) {
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
