
<div id="modal_carrito" class=" container-fluid">

    <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #3649b6; background-color: rgb(37, 39, 150); ">
        <div id="content_2" class="col-md-2 px-0 py-2 h-100">
            <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 12px;">Codigo cliente: </mat-label>
                    <input style="color: #fff; font-size: 12px;" #cod_cliente matInput placeholder="" autocomplete="off" readonly>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                </mat-form-field>
            </div>
        </div>
        <div id="content_2" class="col-md-4 px-0 py-2 h-100">
            <div id="sub_content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 12px;">Cliente</mat-label>
                    <input style="color: #fff; font-size: 12px;" #des_cliente matInput placeholder="" autocomplete="off" readonly>
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-4 px-0 py-2 h-100">
            <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                <mat-form-field class="materialinput py-1 px-1">
                    <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                    <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                    <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                    </span>
                </mat-form-field>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-light h-100" (click)="buscarInventarioSPL('actualizar')" matTooltip="ACTUALIZAR LISTA" style="color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                      <mat-icon >sync</mat-icon>
                    </button>
            </div>
        </div>
        <div class=" col-md-1 px-0 py-2 h-100">
            <div class="  mx-1 h-100">
                <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
            </div>
        </div>

    </div>
    <div id="carrito_p2" class="row" style=" border: 2px solid #374fd6;">
        <div class="col-md-12 px-0  border h-100" style="background-color: rgb(37, 39, 150);">
            <table #table mat-table [dataSource]="dataSource1" class=" table-secondary text-center table-striped table-bordered table-condensed table-hover">

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(41, 39, 146)">
                        <h5><strong>Cod producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.cod_producto}}</td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(41, 39, 146); color: #fff;"> {{cantidad_item()}}</td>
                </ng-container>

                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(41, 39, 146)">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(41, 39, 146); color: #fff;">
                        <h5><strong>Producto</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cant_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(41, 39, 146)">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cant_producto}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(41, 39, 146); color: #fff;">
                        <h5><strong>Cantidad</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(41, 39, 146)">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.estatus}} </td>
                    <td mat-footer-cell *matFooterCellDef style="background-color: rgb(41, 39, 146); color: #fff;">
                        <h5><strong>Estatus</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ajustar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Cargo de producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="ajustes_almacen_spl(element,'cargo')">
                        <button matTooltip="CARGAR PRODUCTO" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(28, 27, 82);">
                          <mat-icon >trending_up</mat-icon>
                          </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Cargo de producto</strong></h5>
                    </td>
                </ng-container>

                <ng-container matColumnDef="desajustar">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(53, 35, 50);">
                        <h5><strong>Descargo de producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" (click)="ajustes_almacen_spl(element,'descargo')">
                        <button matTooltip="DESCARGAR PRODUCTO" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 8, 114);">
                          <mat-icon >trending_down</mat-icon>
                          </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef style=" padding: 5px; background-color: rgb(53, 35, 50); color: #fff;">
                        <h5><strong>Descargo de producto</strong></h5>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
        </div>
    </div>

</div>