<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;"> 

    <div class="row " style="height: 8%; ">
      <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav justify-content-between" style=" background:#1e212c; padding: 5px 7px 5px 7px; margin: 0px;">
        <span class="navbar-brand d-flex align-items-center" style=" font-size: medium; color: rgb(255, 255, 255); " > <mat-icon>assignment_turned_in</mat-icon> <b class="ml-2">GESTIONAR SOLICITUD</b></span>
        <div>
          <ul class="navbar-nav mr-auto">
            <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="btn-danger my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button style="height: 35px; " (click)='listarSolicitudesCompra("actualizar")'><mat-icon >sync</mat-icon></button></li>
            <!-- <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
            <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
            <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
            <li *ngIf="read" class="nav-item"><button matTooltip="DESCARGA" class="my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button color="warn" style="height: 35px; background: green; " (click)='en_construccion()'><mat-icon >get_app</mat-icon></button></li> -->
            <li *ngIf="create" class="nav-item"><button matTooltip="CREAR SOLICITUD" class="btn-success my-2 my-sm-0 mr-sm-2 rounded-pill" mat-raised-button style="height: 35px; " (click)='crearSolicitudCompra("","insertar")' ><mat-icon >add</mat-icon></button></li>
            <li *ngIf="read" class="nav-item"><button matTooltip="SOLICITUDES APROBADAS" class="btn-info my-2 my-sm-0 mr-sm-2 bg-info text-light rounded-pill" mat-raised-button style="height: 35px;" (click)='verSolicitudesAprobadas()'><mat-icon >alarm_on</mat-icon></button></li>
          </ul>
        </div>
        <div class="">
          <input class="form-control me-2 rounded-pill" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
        </div>
      </nav>
    </div>
  
    <div class="row " style="height: 78.7%;">
      
        <div id="htmlData" class="col-md-12 px-0   h-100 " style="color: #000; background-color: #f4f6f9; overflow: auto;">
           
              <table id="htmlData" #htmlData mat-table [dataSource]="dataSource" class=" table-bordered text-center ">
                 <!-- Weight Column -->
                  <ng-container matColumnDef="cod_solicitud_compra">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Codigo de Solicitud</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> {{element.cod_solicitud_compra}} </td>
                  </ng-container>

                  <ng-container matColumnDef="cod_orden_req">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Codigo de Requisición</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> {{element.cod_orden_req}} </td>
                  </ng-container>
  
  
                  <ng-container matColumnDef="tipo_solicitud_compra">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Tipo de Solicitud</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> <strong>{{element.tipo_solicitud_compra}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="tipo_producto">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Tipo de Producto</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> <strong>{{element.tipo_producto}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="estatus">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;" [ngSwitch]="element.estatus">
                     
                      
                        <div *ngSwitchCase="'P'" style="background-color: var(--warning); height: 20px; display: flex; justify-content: center; align-items: center; -radius: 1rem;"><strong  style="color: #fff;">Esperando Aprobacion</strong></div> 
                        <div *ngSwitchCase="'SCA'" style="background-color: var(--success); height: 20px; display: flex; justify-content: center; align-items: center; -radius: 1rem;"><strong  style="color: #fff;">Aprobada</strong> </div>
                        <div *ngSwitchCase="'SCD'" style="background-color: var(--danger); height: 20px; display: flex; justify-content: center; align-items: center; -radius: 1rem;"><strong  style="color: #fff;">Desaprobada</strong></div>
                        <strong *ngSwitchDefault >{{element.estatus}}</strong> 
                        
                    </td>
                     
                  </ng-container>
  

                  <ng-container matColumnDef="departamento">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Deparatamento</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;text-transform: uppercase;">  <strong>{{element.departamento}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="prioridad">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Prioridad</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }" style="text-transform: uppercase;" [ngSwitch]="element.prioridad">  
                      
                      <div *ngSwitchCase="'Baja'" style="background-color: #00D0FF; height: 20px; display: flex; justify-content: center; align-items: center; -radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong></div> 
                      <div *ngSwitchCase="'Media'" style="background-color: #FFC35E; height: 20px; display: flex; justify-content: center; align-items: center; -radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong> </div>
                      <div *ngSwitchCase="'Alta'" style="background-color: red; height: 20px; display: flex; justify-content: center; align-items: center; -radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong></div>
                      <strong *ngSwitchDefault >{{element.prioridad}}</strong> 
                      
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Fecha</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> <strong>{{element.fecha}}</strong> </td>
                  </ng-container>

                  <ng-container matColumnDef="fecha_estimada">
                    <th *matHeaderCellDef class="  text-center" style=" color: #000; background-color: #f4f6f9;">
                        <h5><strong>Fecha Estimada</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style=" font-size: 12px; color: black;"> <strong>{{element.fecha_estimada}}</strong> </td>
                  </ng-container>

                  <ng-container  matColumnDef="actualizar">
                    <th *matHeaderCellDef class="  text-center" style="padding: 5px; color: #000; background-color: #f4f6f9;">
                        <h5><strong>Editar</strong></h5>
                    </th>
                  
                    <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="  text-center" >
                        <button matTooltip="EDITAR" (click)='update_insert_data(element, "actualizar")' class="btn rounded-pill btn-sm" [disabled]="element.estatus != 'P'" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(171, 20, 241);">
                            <mat-icon>edit</mat-icon>
                        </button>
                       <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon>edit</mat-icon>
                        </button> -->
                    </td>
                  </ng-container>

                  <ng-container disabled="aprobado" matColumnDef="aprobado">
                    <th *matHeaderCellDef class="  text-center" style="padding: 5px; color: #000; background-color: #f4f6f9;">
                        <h5><strong>Ver</strong></h5>
                    </th>
                  
                    <td mat-cell *matCellDef="let element"   [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="  text-center" >
                        <button matTooltip="MOSTRAR" *ngIf="element.cod_solicitud_compra != ''"  (click)='verAprobacion(element, "aprobar")' class="btn btn-secondary btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: #A15A99;">
                            <mat-icon>remove_red_eye</mat-icon>
                        </button>
                       <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon>edit</mat-icon>
                        </button> -->
                    </td>
                  </ng-container>

                  <ng-container disabled="aprobar" matColumnDef="aprobar">
                    <th *matHeaderCellDef class="  text-center" style="padding: 5px; color: #000; background-color: #f4f6f9;">
                        <h5><strong>Aprobar</strong></h5>
                    </th>
                  
                    <td mat-cell *matCellDef="let element" [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }" style="padding: 1px; font-size: 12px;" class="  text-center" >
                        <button matTooltip="APROBAR" *ngIf="element.cod_solicitud_compra != ''" [disabled]="element.estatus != 'P'"(click)='aprobarSolicitudCompra(element, "aprobar")' class="btn btn-success btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                            <mat-icon>done_all</mat-icon>
                        </button>
                       <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon>edit</mat-icon>
                        </button> -->
                    </td>
                  </ng-container>
  
  
                 
  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="  text-center"></tr>
    
              </table>
  
              <mat-paginator class="w-100 mat-paginator-sticky " [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator> 
            
        </div>
      
    </div>
  
    
  </div>