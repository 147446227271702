<div class="container-fluid rounded" *ngIf="accion" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <div class="row py-1">
            <div class="col-md-8 col-sm-12 col-xs-12 d-flex justify-content-start p-0">
                <h3 class="my-2">
                    <span class="fw-bold text-white mx-2">Acciones de procesos</span>
                    <span style="font-size: 12px;" class="text-white">( {{data.data.des_gerencia  | uppercase}} {{data.data.des_dpto  | uppercase}} {{data.data.des_rol  | uppercase}} ) </span>
                </h3>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12  d-flex justify-content-end p-0">
                <button matTooltip="SALIR" (click)="cerrarmodal()" class="my-2 col-md-2 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(216, 68, 58);"><mat-icon >exit_to_app</mat-icon></button>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12   d-flex justify-content-end p-0">
                <input class="form-control my-2 mx-1" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
            </div>
    </div>
    <div  class="row modalgeneral" style=" border: 2px solid #2b69dd; background-color:#29388f;">
            <div class=" table-responsive  h-100" >
                <table  mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">

                    <ng-container matColumnDef="cod_definicion" >
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_definicion}}</b></td>
                
                    </ng-container>

                    <ng-container matColumnDef="des_definicion">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Definición</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_definicion}}</b></td>
                
                    </ng-container>

                    <ng-container matColumnDef="descrip_definicion">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Descripción</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.descrip_definicion}}</b></td>
                
                    </ng-container>

                    <ng-container matColumnDef="cod_usuario">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Id</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_usuario}}</b></td>
                
                    </ng-container>
                    <ng-container matColumnDef="des_usuario">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Usuario</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.des_usuario}}</b></td>
                
                    </ng-container>
                    <ng-container matColumnDef="estatus">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Estatus</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.estatus}}</b></td>
                
                    </ng-container>
                    <ng-container matColumnDef="operacion">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Operación</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.operacion}}</b></td>
                
                    </ng-container>

                    <ng-container matColumnDef="fecha_inicio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Fecha</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.fecha_inicio}}</b></td>
                
                    </ng-container>
                    <ng-container matColumnDef="val_def">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Valor</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;  border-radius: 1rem; "><b>{{element.val_def}}</b></td>
                
                    </ng-container>
                    <ng-container matColumnDef="hora_inicio">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                            <h5><strong>Hora</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.hora_inicio}}</b></td>
                    </ng-container>

                    <ng-container matColumnDef="definiciones">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                            <h5><strong>A/I</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                            <button *ngIf="element.estatus === 'A'" class=" bg-success"   matTooltip="DESACTIVAR" mat-mini-fab (click)='activar_desactivar_acciones(element,"borrar")'>
                                <mat-icon >check_circle</mat-icon>
                            </button>
                            <button *ngIf="element.estatus === 'I'" class=" bg-danger"   matTooltip="ACTIVAR" mat-mini-fab (click)='activar_desactivar_acciones(element,"crear")'>
                                <mat-icon >check_circle_outline</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                </table>
                <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
            </div>
    </div>
</div>

