import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';
import * as fs from 'file-saver';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormPreciosComponent } from 'src/app/componentes/shared/modal/form-precios/form-precios.component';
import { FormConsultaListaComponent } from 'src/app/componentes/shared/modal/form-consulta-lista/form-consulta-lista.component';
import { FormPreciosActualizarComponent } from 'src/app/componentes/shared/modal/form-precios-actualizar/form-precios-actualizar.component';
import { FormInvSplComponent } from 'src/app/componentes/shared/modal/form-inv-spl/form-inv-spl.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { forIn, result } from 'lodash';
import { FormDepositosComponent } from 'src/app/componentes/shared/modal/form-depositos/form-depositos.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { Workbook } from 'exceljs';
import { FormReportesNotasDebitoComponent } from 'src/app/componentes/shared/modal/form-reportes-notas-debito/form-reportes-notas-debito.component';
import { FormReportesVentasComponent } from 'src/app/componentes/shared/modal/form-reportes-ventas/form-reportes-ventas.component';
import { FormFechasComponent } from 'src/app/componentes/shared/modal/form-fechas/form-fechas.component';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { FormReporteDevolucionesComponent } from 'src/app/componentes/shared/modal/form-reporte-devoluciones/form-reporte-devoluciones.component';
import { ExcelService } from 'src/app/componentes/shared/servicios/excel.service';
import { FormReporteFacturacionD56Component } from 'src/app/componentes/shared/modal/form-reporte-facturacion-d56/form-reporte-facturacion-d56.component';
import axios from 'axios';
import * as moment from 'moment';
import { FormReporteMovimientoCdtComponent } from 'src/app/componentes/shared/modal/form-reporte-movimiento-cdt/form-reporte-movimiento-cdt.component';
import { FormReporteCuotasVsVentasComponent } from '../../../shared/modal/form-reporte-cuotas-vs-ventas/form-reporte-cuotas-vs-ventas.component';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-consultar-reportes',
  templateUrl: './consultar-reportes.component.html',
  styleUrls: ['./consultar-reportes.component.css']
})
export class ConsultarReportesComponent implements OnInit {
  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  fecha1:any;
  fecha2:any;
  auth_menu:any = [];
  unidad_reporte:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  DES_PROD:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
      private servicio:nkServices,
      private dialog: MatDialog,
      private excel: ExcelService,
      private snackBar: MatSnackBar,
      private router:Router,
      private util:AppUtilService,
      private atrb:AtributosService,
      private hashkey:HashKeyServices
       ) {
  }

  ngOnInit(): void {
    this.listarReportes(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /////////////////////////////////////////////////////////////////////
  listarReportes(msj:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        let msg = msj;
        this.listarDefiniciones(localStorage.getItem('sub_main'));
        this.servicio.listarDepositoshttp(this.atrb.COD_EMPRESA).subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                var rif;
                var nom_final;
                
                    this.ELEMENT_DATA.push({
                      descrip_deposito:'REPORTE DE FACTURACIÓN',
                      tipo_deposito:'VENTAS',
                      estatus: 'A',
                      parametro:'1'
                      
                    },{
                      descrip_deposito:'REPORTE GENERAL',
                      tipo_deposito:'VENTAS',
                      estatus: 'A',
                      parametro:'2'
                      
                    },
                    {
                      descrip_deposito:'REPORTE GENERAL UNIDADES VENDIDAS',
                      tipo_deposito:'VENTAS',
                      estatus: 'A',
                      parametro:'3'
                      
                    },
                    {
                      descrip_deposito:'REPORTE DE ASISTENCIA',
                      tipo_deposito:'RRHH',
                      estatus: 'A',
                      parametro:'6'
                      
                    },
                    {
                      descrip_deposito:'REPORTE DE COMEDOR',
                      tipo_deposito:'RRHH',
                      estatus: 'A',
                      parametro:'7'
                      
                    },
                    {
                      descrip_deposito:'REPORTE DE DEVOLUCIONES',
                      tipo_deposito:'DEVOLUCIONES',
                      estatus: 'A',
                      parametro:'14'
                    },
                    {
                      descrip_deposito:'REPORTE PEDIDOS BACKORDER',
                      tipo_deposito:'VENTAS',
                      estatus: 'A',
                      parametro:'8'
                      
                    },
                    {
                      descrip_deposito:'REPORTE CLIENTE VS PRODUCTOS BACKORDER',
                      tipo_deposito:'VENTAS',
                      estatus: 'A',
                      parametro:'9'
                      
                    },
                    {
                      descrip_deposito:'REPORTE DE CUOTAS VS VENTAS',
                      tipo_deposito:'VENTAS',
                      estatus: 'A',
                      parametro:'10'
                      
                    },
                    {
                      descrip_deposito:'REPORTE DE PEDIDOS',
                      tipo_deposito:'VENTAS',
                      estatus: 'A',
                      parametro:'11'
                      
                    },
                    {
                      descrip_deposito:'REPORTE DE VENTAS D56',
                      tipo_deposito:'VENTAS',
                      estatus: 'A',
                      parametro:'12'
                      
                    },
                    {
                      descrip_deposito:'REPORTE DE CLIENTES',
                      tipo_deposito:'CLIENTES',
                      estatus: 'A',
                      parametro:'13'
                      
                    },
                    {
                      descrip_deposito:'REPORTE DE MOVIMIENTOS DE INVENTARIOS',
                      tipo_deposito:'INVENTARIOS',
                      estatus: 'A',
                      parametro:'15'
                      
                    },
                    {//reporte
                      descrip_deposito:'REPORTE DE ORDENES DE PRODUCCION',
                      tipo_deposito:'PRODUCCIÓN',
                      estatus: 'A',
                      parametro:'16'
                      
                    },//
                    {//reporte
                      descrip_deposito:'REPORTE DE ORDENES DE PRODUCCION DETALLADO',
                      tipo_deposito:'PRODUCCIÓN',
                      estatus: 'A',
                      parametro:'17'
                      //
                    },);
                  
                  this.displayedColumns = [
                                            'descrip_deposito',
                                            'tipo_deposito',
                                            'estatus',
                                            'imprimir',
                                            
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                    'descrip_deposito',
                                    'tipo_deposito',
                                    'estatus',
                                    'imprimir',
                                    
                                  ];
                  this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        console.log(msg);
        if(msg == 'actualizar'){
          this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
        return this.xobj2;
      }, 500);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }

  }

  update_insert_data(obj:any, termino:any){

    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
      
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {
         var dialogRef =   this.dialog.open(FormmMsgComponent, {
              width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
               data: {cod_usuario:localStorage.getItem('cod_usuario'),
                      des_usuario: localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido'),
                      objeto: this.unidad_reporte,
                      accion:termino},
               panelClass: 'pedidos_modal',
               disableClose: true,
               backdropClass: "bdrop"
             });
             dialogRef.afterClosed().subscribe(result => {
              console.log(result);
              if(result != 'cerrar'){
                
                /* this.gestionDepositos(result); */
              }
            });
            
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      ); 
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
 }

  listarDefiniciones(objeto:any){
    try {
      var xvalor ={cod_menu:objeto};
        this.servicio.listarDefinicionesConceptoshttp(xvalor).subscribe(
          response =>{
            console.log(response,'definiciones');
            this.tipo_deposito= [];
            this.unidad_reporte= [];
            this.tipo_manejo = [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === 'UNIDAD REPORTE') {
                  this.unidad_reporte.push(response[i]);
              }
            }
          },
          error =>{
            console.log(<any>error.error.message,'error definiciones');

          }
        );
        return this.xobj2;
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  descargar_reporte(text:any){
    try {
  
      this.key_hash = {};
                      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                        response =>{
                          if (response.resul) {
      
          switch (text.parametro) {
            case '1':
                this.reporteFacturas('listar');
              break;
            case '2':
                this.reporteGeneralVentas('listar');
              break;
            case '3':
              this.reporteVentasFacturasNotas(text.parametro, 'listar');  
              break;
              case '6':
                this.descargarAsistenciaEmpleados('listar');
              break;
              case '7':
                this.descargarDatacomedor('listar');
              break;
              case '8':
                this.REPORTENB(text.parametro, 'listar');
              break;
              case '9':
                this.REPORTENB(text.parametro,'listar');
              break;
              case '10':
                this.reporteCuotasVsVentas('listar');
              break;
              case '11':
                this.reportes_rango_fecha_pedidos('listar');
              break;
              case '12':
                this.reportes_rango_fecha_ventas_d56('listar');
              break;
              case '14':
                this.reporteDevoluciones('listar')
              break;
  
              case '13':
                this.REPORTECL('listar');
                break;
                
              case '15':
                      this.reporteCDT(text.parametro,'listar');
                break;
              case '16':
                      this.reporteOrdenesProduccion('listar');
                break;
              case '17':
                      this.reporteOrdenesProduccionDetallado('listar');
                break;
            default:
              this.snackBar.open('El reporte no existe','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
              break;
          }
         
                          }else{
                            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,});
                          }
                        },
                        error =>{
                          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,});
                        }
                      );
        
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  descargarDatacomedor(text:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
                          var xobjeto = {
                            val_def : 'RP',
                            tipo_operacion: 'COMEDOR',
                            cod_usuario: localStorage.getItem('cod_usuario')
                          };
                         
                    this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
                      response =>{
                      console.log(this.xobj3,'UNIDADES');
                      if (text == 'listar') {
                        this.accion = text;
                        dialogRef = this.dialog.open(FormFechasComponent, {
                          width: '100vw',
                          maxWidth: '100vw',
                          height:'100vh',
                          data: {
                                accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal',
                              backdropClass: "bdrop"
                        });
                      }

                      dialogRef.afterClosed().subscribe(result => {
                        if(result !== 'cerrar' && result !== 'salir'){ 
                                this.descargar_data_comedor(result);
                        }
                      });
                    },
                    error=>{
                      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,});
                    }
                  );
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }
  descargarAsistenciaEmpleados(xtext:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var xobjeto = {
                            val_def : 'RP',
                            tipo_operacion: 'ASISTENCIA',
                            cod_usuario: localStorage.getItem('cod_usuario')
                          };
                         
                    this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
                      response =>{
                            var dialogRef;
        
                            if (xtext === 'listar') {
                              this.accion = xtext;
                              dialogRef = this.dialog.open(FormFechasComponent, {
                                width: '100vw',
                                maxWidth: '100vw',
                                height:'100vh',
                                data: {
                                      accion:this.accion},
                                    disableClose: true,
                                    panelClass: 'pedidos_modal',
                                    backdropClass: "bdrop"
                              });
                            }
                            dialogRef.afterClosed().subscribe(result => {
                                if(result != 'cerrar'){
                                  console.log(result, 'que tenemos aqui');
                                  this.buscarAsistenciaRangoFecha(result);
                                  
                                }
                            });
                      },
                      error=>{
                        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,});
                      }
                    );
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }
  descargar_data_comedor(datosForm: any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.fechas = datosForm;
        //Se genera la peticion Http
        this.servicio.listarComedor(datosForm).subscribe(
          response => {
            if (response) {
              console.log(response,'fechas del rango');
              this.dialogSpin.close();
              //Se guardan los datos en una variable
              for (const i in response.result) {
                
                this.datos.push({
                  vacio: ' ',
                  cod_usuario: response.result[i].cod_usuario.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                  des_usuario: response.result[i].des_usuario.toUpperCase(),
                  unidad: response.result[i].unidad,
                  horario: response.result[i].horario,
                  fecha: response.result[i].fecha,
                  hora: response.result[i].hora,
                  estatus: response.result[i].estatus,
                  dt_ubicacion: response.result[i].dt_ubicacion,
                  descuento: response.result[i].descuento,
                })
                
              }
              this.excel.descarga_xlsx_comedor(this.datos,this.fechas);
            }
          },
          error => {
            this.dialogSpin.close();
        
            this.snackBar.open(error.error.message, 'Cerrar', {
              duration: 15000, horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            
          })
      }, 1500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }
  buscarAsistenciaRangoFecha(xvalor:any){
    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
       //this.listarDefiniciones(localStorage.getItem('sub_main'));
        this.servicio.listarAsistenciaRangoFechahttp(xvalor).subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              console.log(xvalor,'xvalor');
              let xobj2 = response.result;
              this.ELEMENT_DATA = [];
              this.fecha1 = xvalor.fecha_inicio;
              this.fecha2 = xvalor.fecha_fin;
                  for (const i in xobj2) {
                    
              this.ELEMENT_DATA.push({
                 vacio: ' ',
                 cod_usuario:xobj2[i].cod_usuario.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                 des_usuario: xobj2[i].des_usuario,
                 unidad: xobj2[i].unidad,
                 horario:xobj2[i].horario,
                 fecha:xobj2[i].fecha,
                 hora: xobj2[i].hora,
                 estatus:xobj2[i].estatus,
                 dt_ubicacion:xobj2[i].dt_ubicacion,
                 retraso: xobj2[i].retraso,
                 salida_temprano: xobj2[i].salida_temprano,
                 entrada_salida:xobj2[i].entrada_salida,
                 dt_comedor:xobj2[i].dt_comedor,
                 accion:''
                    });
                  }
                  this.excel.descarga_xlsx_asistencia(this.ELEMENT_DATA,this.fecha1,this.fecha2);
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);

                  this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
                  
          }
        );
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  } 
  getBase64ImageFromURLdaily(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }
  reportes_ventas_xlsx(){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.servicio.listarProductoTerminadohttp().subscribe(
          response=>{
            this.dialogSpin.close();
            for (const i in response) {
              response[i].cant_producto = parseInt(response[i].cant_producto.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')).toFixed();
            }
            this.descarga_xlsx(response);
          },
          error=>{
            this.dialogSpin.close();
            console.log(error);
          }
        );
      }, 3000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  
  }
  descarga_xlsx(xdatos:any){
    try {
      console.log(xdatos,'llego a la descarga');
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Inventario(LOO1)");
      let header=["Id","Codigo","Producto", "Cantidad","Estatus","Unidad"];
      let headerRow = worksheet.addRow(header);
      for (let x1 of xdatos)
  {
  let x2=Object.keys(x1);
  let temp=[]
  for(let y of x2)
  {
    temp.push(x1[y])
  }
  worksheet.addRow(temp)
  }
  let fname="Inventario de productos terminados (L001)";
  
  workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  fs.saveAs(blob, fname+'-'+new Date().getDate()+'.xlsx');
  });
    } catch (error) {
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  reporteVentasFacturasNotas(parametro:any,objeto:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
              response =>{
               var xobjeto = {
                  val_def : 'RP',
                  tipo_operacion: 'UVFN',
                  cod_usuario: localStorage.getItem('cod_usuario')
                };
               
          this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
            response =>{
                  var dialogRef;
                  
                  if (objeto === 'listar') {
                    this.accion = objeto;
                     dialogRef = this.dialog.open(FormFechasComponent, {
                      width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                      data: {
                             accion:this.accion,
                            parametro:parametro},
                          disableClose: true,
                          panelClass: 'pedidos_modal',
                          backdropClass: "bdrop"
                    });
                  }
                  dialogRef.afterClosed().subscribe(result => {
                      if(result != 'cerrar'){
                        console.log(result, 'que tenemos aqui');

                        if (parametro === '3') {
                              this.UnidadesVendidasFacturasNotas(result);
                        }
                      }
                  });
                    
            },
            error=>{
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
      
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }
  reporteDevoluciones(objeto:any){
    try {
      this.key_hash = {};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
              response =>{
               var xobjeto = {
                  val_def : 'RP',
                  tipo_operacion: 'DEVOLUCION',
                  cod_usuario: localStorage.getItem('cod_usuario')
                };
               
          this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
            response =>{
                  var dialogRef;
                  
                  if (objeto === 'listar') {
                    this.accion = objeto;
                     dialogRef = this.dialog.open(FormFechasComponent, {
                      width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                      data: {
                             accion:this.accion,},
                          disableClose: true,
                          panelClass: 'pedidos_modal',
                          backdropClass: "bdrop"
                    });
                  }
                  dialogRef.afterClosed().subscribe(result => {
                      if(result != 'cerrar'){
                        console.log(result, 'que tenemos aqui');
                        this.descargar_devoluciones(result);
                        
                      }
                  });
                    
            },
            error=>{
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
      
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }
  descargar_devoluciones(text:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.servicio.reporteDevolucioneshttp(text).subscribe(
          response=>{
            this.dialogSpin.close();
            this.excel.descarga_devoluciones_por_fecha_xlsx3(response,text);
          },
          error=>{
            this.dialogSpin.close();
            console.log(error.error);
            console.log(error.error.message);
            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,})
          }
        );
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  UnidadesVendidasFacturasNotas(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.fecha1 = xvalor.fecha_inicio;
        this.fecha2 = xvalor.fecha_fin;
        this.servicio.buscarUnidadesPorFacturasNotashttp(xvalor).subscribe(
          response=>{
            this.dialogSpin.close();
            console.log(response);
            this.excel.descargarUnidadesVendidasFacturasNotas(response,this.fecha1,this.fecha2);
          },
          error=>{
            this.dialogSpin.close();
            console.log(error.error);
            console.log(error.error.message);
            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,})
          }
        );
      }, 3000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  reporteCuotasVsVentas(text:any){
    try {
      this.key_hash = {};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
                          var xobjeto = {
                            val_def : 'RP',
                            tipo_operacion: 'FCUOTAS',
                            cod_usuario: localStorage.getItem('cod_usuario')
                          };
                         
                    this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
                      response =>{
                      console.log(this.xobj3,'UNIDADES');
                      if (text == 'listar') {
                        dialogRef = this.dialog.open(FormReporteCuotasVsVentasComponent, {
                          width: '100vw',
                          maxWidth: '100vw',
                          height:'100vh',
                          data: {
                                  accion:this.accion},
                          disableClose: true,
                          panelClass: 'pedidos_modal3',
                          backdropClass: "bdrop"
                        }); 
                      }

                      dialogRef.afterClosed().subscribe(result => {
                        if(result !== 'cerrar' && result !== 'salir'){ 
                                
                        }
                      });
                    },
                    error=>{
                      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,});
                    }
                  );
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }
  reportes_rango_fecha_pedidos(objeto:any){
   try {
      var dialogRef:any;
      this.key_hash = {};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          var xobjeto = {
            val_def : 'RP',
            tipo_operacion: 'REPEDIDOSD56',
            cod_usuario: localStorage.getItem('cod_usuario')
          };
          
                  
          this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
            response =>{
              if (objeto === 'listar') {
                this.accion = objeto;
                  dialogRef = this.dialog.open(FormFechasComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                  data: {
                          accion:this.accion,},
                      disableClose: true,
                      panelClass: 'pedidos_modal',
                      backdropClass: "bdrop"
                });
              }
              dialogRef.afterClosed().subscribe(result => {
                if(result != 'cerrar'){
                  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
                    width: '15%',
                    height:'25%',
                    data: {
                            accion:this.accion},
                    disableClose: true,
                    panelClass: 'spinner',
                    backdropClass: "bdrop"
                  }); 
                  setTimeout(() => {
                  this.servicio.reporteUnidadesVendidasD56http(result).subscribe(
                     response=>{
                      console.log(response);
                      this.dialogSpin.close();
                      this.excel.descarga_productos_pedidos(response, result);
                     
                    },
                 
                    error=>{
                      
                      this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,})
                    }
                  );
                    }, 500);
                  
               
                }
              }); 
            },
            error=>{
              this.dialogSpin.close();
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
            
          },
          error =>{
            this.dialogSpin.close();
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
      
    } catch (error) {
    this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    }
  }
  reportes_rango_fecha_ventas_d56(objeto:any){
   try {
      var dialogRef:any;
      this.key_hash = {};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          var xobjeto = {
            val_def : 'RP',
            tipo_operacion: 'REVENTASD56',
            cod_usuario: localStorage.getItem('cod_usuario')
          };
                
          this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
            response =>{

              if (objeto === 'listar') {
                this.accion = objeto;
                  dialogRef = this.dialog.open(FormFechasComponent, {
                  width: '100vw',
                  maxWidth: '100vw',
                  height:'100vh',
                  data: {
                          accion:this.accion,},
                      disableClose: true,
                      panelClass: 'pedidos_modal',
                      backdropClass: "bdrop"
                });
              }
              dialogRef.afterClosed().subscribe(result => {
                if(result != 'cerrar'){
                  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
                    width: '15%',
                    height:'25%',
                    data: {
                            accion:this.accion},
                    disableClose: true,
                    panelClass: 'spinner',
                    backdropClass: "bdrop"
                  }); 
                  setTimeout(() => {

                    this.servicio.reporteVentasD56http(result).subscribe(
                      response=>{
                        console.log(response);
                        this.dialogSpin.close()
                        this.excel.descarga_facturacion_d56(response, result);
                      },
                      error=>{
                        this.dialogSpin.close()
                        this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                          verticalPosition: this.verticalPosition,})
                      }
                    );
                  }, 500);
                }
              }); 
            },
            error=>{
              this.dialogSpin.close()
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
            
          },
          error =>{
            this.dialogSpin.close()
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
    } catch (error) {
      this.dialogSpin.close()
    this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    }
  }
  reporteGeneralVentas(objeto){

        try {
          var dialogRef:any;
          this.key_hash = {};
          this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                  response =>{
                    var xobjeto = {
                      val_def : 'RP',
                      tipo_operacion: 'REPORTEGVNT',
                      cod_usuario: localStorage.getItem('cod_usuario')
                    };
                    
              this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
                response =>{
    
                      if (objeto === 'listar') {
                        this.accion = objeto;
                          dialogRef = this.dialog.open(FormFechasComponent, {
                          width: '100vw',
                          maxWidth: '100vw',
                          height:'100vh',
                          data: {clientes:response,
                                  accion:this.accion,
                                parametro:'8'},
                              disableClose: true,
                              panelClass: 'pedidos_modal',
                              backdropClass: "bdrop"
                        });
                      }
                      dialogRef.afterClosed().subscribe(result => {
                          if(result != 'cerrar'){
                            
                            this.servicio.reporteGeneralFNENDNChttp(result).subscribe(
                              response=>{
                                console.log(response);
                                this.descargarreportegeneralventas(response.xobjeto_unico, result);
                              },
                              error=>{
                                
                                this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                                  verticalPosition: this.verticalPosition,})
                              }
                            );
                            }
                      }); 
                },
                error=>{
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
                }
              );
                
              },
              error =>{
                this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
            );
            } catch (error) {
            this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
            }
  }
  descargarreportegeneralventas(objeto: any, xobjeto:any){
        try {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          setTimeout(() => {
            this.dialogSpin.close();
            this.excel.reporte_general_ventas(objeto, xobjeto);
          }, 500);
        } catch (error) {
          this.dialogSpin.close();
          this.snackBar.open(error, 'Cerrar', {
            duration: 1500, horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          })
        }
  }
  reporteFacturas(objeto){
    try {
      var dialogRef:any;
      this.key_hash = {};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
              response =>{
                var xobjeto = {
                  val_def : 'RP',
                  tipo_operacion: 'REPORTEFT',
                  cod_usuario: localStorage.getItem('cod_usuario')
                };
                
          this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
            response =>{

                  if (objeto === 'listar') {
                    this.accion = objeto;
                      dialogRef = this.dialog.open(FormFechasComponent, {
                      width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                      data: {clientes:response,
                              accion:this.accion,
                            parametro:'8'},
                          disableClose: true,
                          panelClass: 'pedidos_modal',
                          backdropClass: "bdrop"
                    });
                  }
                  dialogRef.afterClosed().subscribe(result => {
                      if(result != 'cerrar'){
                        
                        this.servicio.reporteFacturashttp(result).subscribe(
                          response=>{
                            console.log(response);
                            this.descargarreportefacturas(response.xobjeto_unico, result);
                          },
                          error=>{
                            
                            this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                              verticalPosition: this.verticalPosition,})
                          }
                        );
                        }
                  });
                    
            },
            error=>{
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
            
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
    } catch (error) {
    this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    }
  }
  descargarreportefacturas(objeto: any, xobjeto:any){
        try {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          setTimeout(() => {
            this.dialogSpin.close();
            this.excel.reporte_facturas(objeto, xobjeto);
          }, 500);
        } catch (error) {
          this.dialogSpin.close();
          this.snackBar.open(error, 'Cerrar', {
            duration: 1500, horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          })
        }
  }
  REPORTENB(parametro:any,objeto:any){
        try {
          this.key_hash = {};
                        this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                  response =>{
                    var xobjeto = {
                      val_def : 'F',
                      tipo_operacion: 'REPORTENB',
                      cod_usuario: localStorage.getItem('cod_usuario')
                    };
                    
              this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
                response =>{

                  this.servicio.listarClientesActivoshttp(null).subscribe(
                    response=>{
                      var dialogRef;
                      
                      if (objeto === 'listar') {
                        this.accion = objeto;
                          dialogRef = this.dialog.open(FormFechasComponent, {
                          width: '100vw',
                          maxWidth: '100vw',
                          height:'100vh',
                          data: {clientes:response,
                                  accion:this.accion,
                                parametro:parametro},
                              disableClose: true,
                              panelClass: 'pedidos_modal',
                              backdropClass: "bdrop"
                        });
                      }
                      dialogRef.afterClosed().subscribe(result => {
                          if(result != 'cerrar'){
                            console.log(result, 'que tenemos aqui');

                            if (parametro === '8') {
                                  this.descargarreportebackorderFecha(result);
                            }
                            if (parametro === '9') {
                              console.log(result);
                              this.descargarreportebackorderFecha(result); 
                            }
                          }
                      });
                    },
                    error=>{
                      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,});
                    }
                  );
                        
                },
                error=>{
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
                }
              );
          
              },
              error =>{
                this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
            );
            } catch (error) {
            this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
            }
  }
  descargarreportebackorderFecha(objeto: any){
        try {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          setTimeout(() => {
            this.fechas = objeto;
            //Se genera la peticion Http
            this.servicio.listarbackoderRangoFechahttp(objeto).subscribe(
              response => {
                if (response.parametro === '8') {
                  console.log(response,'fechas del rango');
                  this.dialogSpin.close();
                  var xobj = [];
                  for (const i in response.result) {
                    xobj.push({
                      vacio: '',
                      cod_backorder:response.result[i].cod_backorder,
                      cod_documento: response.result[i].cod_documento,
                      cod_lista_precio:response.result[i].cod_lista_precio,
                      cod_cliente:response.result[i].cod_cliente,
                      cod_usuario:response.result[i].cod_usuario,
                      estatus: response.result[i].estatus,
                      tipo_pedido:response.result[i].tipo_pedido,
                      fecha_inicio:response.result[i].fecha_inicio,
                      hora_inicio:response.result[i].hora_inicio,
                      subtotal_bs:response.result[i].subtotal_bs,
                      tasa_precio:response.result[i].tasa_precio,
                      subtotal_producto:response.result[i].subtotal_producto
                    });
                  }
                  this.excel.reporte_backorder_1(xobj, objeto);
                }
                if (response.parametro === '9') {
                  console.log(response,'fechas del rango');
                  this.dialogSpin.close();
                  var xobj = [];
                  for (const i in response.result) {
                    xobj.push({
                      vacio: '',
                      cod_producto:response.result[i].cod_producto,
                      des_producto: response.result[i].des_producto,
                      cant_producto:response.result[i].cant_producto,
                      precio_producto_base:response.result[i].precio_producto_base,
                      precio_producto_base_bs:response.result[i].precio_producto_base_bs,
                      subtotal_producto_divisa: response.result[i].subtotal_producto_divisa,
                      subtotal_producto: response.result[i].subtotal_producto,
                      
                    });
                  }
                  objeto.cod_cliente = response.cliente[0].cod_cliente;
                  objeto.des_cliente = response.cliente[0].des_cliente;
                  objeto.cod_lista_precio = response.precio[0].cod_lista_precio_producto;
                  objeto.tasa_precio = response.precio[0].tasa_precio;
                  this.excel.reporte_backorder_2(xobj, objeto);
                }
              },
              error => {
                this.dialogSpin.close();
            
                this.snackBar.open(error.error.message, 'Cerrar', {
                  duration: 15000, horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,
                });
                
              })
          }, 500);
        } catch (error) {
          this.dialogSpin.close();
          this.snackBar.open(error, 'Cerrar', {
            duration: 1500, horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          })
        }
  }
  REPORTECL(text:any){
        try {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          setTimeout(() => {

            var xobjeto = {
              val_def : 'RP',
              tipo_operacion: 'REPORTECL',
              cod_usuario: localStorage.getItem('cod_usuario')
            };

            this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
              response =>{
                this.servicio.reporteClienteshttp().subscribe(
                  response=>{
                    this.dialogSpin.close();
                    this.excel.reporte_clientes(response);
                  },
                  error=>{
                    this.dialogSpin.close();
                    console.log(error.error);
                    console.log(error.error.message);
                    this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,})
                  }
                );
              },
              error =>{
                this.dialogSpin.close();
                    console.log(error.error);
                    console.log(error.error.message);
                    this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,})
              }
            );
            
          }, 1000);
        } catch (error) {
          this.dialogSpin.close();
          this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
  }
  reporteCDT(parametro:any,objeto:any){
      try {
        this.key_hash = {};
        this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
          response =>{
          if (response.resul) {
            var xobjeto = {
              val_def : 'RP',
              tipo_operacion: 'REINVENTARIO',
              cod_usuario: localStorage.getItem('cod_usuario')
            };

            this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
              response =>{
              this.servicio.listarDepositosReporthttp().subscribe( 
                response=>{ 
                  this.servicio.listarProductosReporthttp().subscribe(response2 =>{    
                  var dialogRef;
                  
                  var depositos = response
                  var productos = response2
        
                  if (objeto === 'listar') {
                    this.accion = objeto;
                    dialogRef = this.dialog.open(FormFechasComponent, {
                      width: '100vw',
                      maxWidth: '100vw',
                      height:'100vh',
                      data: {depositos: depositos,
                            parametro:parametro,
                            productos:productos,
                            objeto:objeto,
                            accion:this.accion},
                          disableClose: true,
                          panelClass: 'pedidos_modal',
                          backdropClass: "bdrop"
                    });
                  }
                  dialogRef.afterClosed().subscribe(result => {
                      if(result != 'cerrar'){
                        console.log(result, 'que tenemos aqui');
                        this.descargarReporteCDT(result);
                        
                      }
                  });
                })
              })
            },
              error =>{
                this.dialogSpin.close();
                    console.log(error.error);
                    console.log(error.error.message);
                    this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,})
              }
            );
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
      } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
      }
  }
  reporteOrdenesProduccion(objeto:any){
    try {
      this.key_hash = {};
      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
        response =>{
          if (response.resul) {

              var xobjeto = {
                val_def : 'RP',
                tipo_operacion: 'REORDENPROD',
                cod_usuario: localStorage.getItem('cod_usuario')
              };
  
              this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
                response =>{
                var dialogRef;
                if (objeto === 'listar') {
                  this.accion = objeto;
                  dialogRef = this.dialog.open(FormFechasComponent, {
                    width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                    data: {
                          objeto:objeto,
                          accion:this.accion},
                        disableClose: true,
                        panelClass: 'pedidos_modal',
                        backdropClass: "bdrop"
                  });
                }
                dialogRef.afterClosed().subscribe(result => {
                  if(result != 'cerrar'){
                    console.log(result, 'que tenemos aqui');
                    this.descargarReporteOrdenesProduccion(result);
                    
                  }
                });
              },
              error =>{
                this.dialogSpin.close();
                    console.log(error.error);
                    console.log(error.error.message);
                    this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,})
              }
            );
          }else{
            this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        },
        error =>{
          this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
    }
  }
  reporteOrdenesProduccionDetallado(objeto:any){
      try {
        this.key_hash = {};
          this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
            response =>{
            if (response.resul) {
              var xobjeto = {
                val_def : 'RP',
                tipo_operacion: 'REORDENPRODDE',
                cod_usuario: localStorage.getItem('cod_usuario')
              };
  
              this.servicio.validar_acceso_acciones_http(xobjeto).subscribe(
                response =>{     
                var dialogRef;
                
                var depositos = response
      
                if (objeto === 'listar') {
                  this.accion = objeto;
                  dialogRef = this.dialog.open(FormFechasComponent, {
                    width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
                    data: {
                          objeto:objeto,
                          accion:this.accion},
                        disableClose: true,
                        panelClass: 'pedidos_modal',
                        backdropClass: "bdrop"
                  });
                }
                dialogRef.afterClosed().subscribe(result => {
                    if(result != 'cerrar'){
                      console.log(result, 'que tenemos aqui');
                      this.descargarReporteOrdenesProduccionDetallado(result);
                      
                    }
                });
              },
              error =>{
                this.dialogSpin.close();
                    console.log(error.error);
                    console.log(error.error.message);
                    this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,})
                }
              );
                  
              }else{
                this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
            },
            error =>{
              this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
          } catch (error) {
          this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          }
  }
  descargarReporteCDT(objeto: any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.fechas = objeto;
        //Se genera la peticion Http
        this.servicio.reporteCargosDescargoshttp(objeto).subscribe(
          response => {
            if (response) {
              console.log(response,'fechas del rango');
              this.dialogSpin.close();
              var xobj = [];
             this.excel.reporteMovimientosCDT(response, objeto);
            }
          },
          error => {
            this.dialogSpin.close();
        
            this.snackBar.open(error.error.message, 'Cerrar', {
              duration: 15000, horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            
          })
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }
  descargarReporteOrdenesProduccion(objeto: any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.fechas = objeto;
        //Se genera la peticion Http
        this.servicio.reporteOrdenesProdhttp(objeto).subscribe(
          response => {
            if (response) {
              console.log(response,'fechas del rango');
              this.dialogSpin.close();
              var xobj = [];
             this.excel.reporteReporteOrdenesProduccion(response, objeto);
            }
          },
          error => {
            this.dialogSpin.close();
        
            this.snackBar.open(error.error.message, 'Cerrar', {
              duration: 15000, horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            
          })
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }
  descargarReporteOrdenesProduccionDetallado(objeto: any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.fechas = objeto;
        //Se genera la peticion Http
        this.servicio.reporteOrdenesProdOperacioneshttp(objeto).subscribe(
          response => {
            if (response) {
              console.log(response,'fechas del rango');
              this.dialogSpin.close();
              var xobj = [];
             this.excel.reporteReporteOrdenesProduccionDetallado(response, objeto);
            }
          },
          error => {
            this.dialogSpin.close();
        
            this.snackBar.open(error.error.message, 'Cerrar', {
              duration: 15000, horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            
          })
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }

      
  ///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////
 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

en_construccion():void{
  try {
    this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
sin_permiso():void{
  try {
    this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,})
  }
}
}
