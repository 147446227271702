import { Component, OnInit, ViewChild, EventEmitter  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import { FormCrearTomaFisicaComponent } from 'src/app/componentes/shared/modal/form-crear-toma-fisica/form-crear-toma-fisica.component';
import { FormTomaFisicaCompletaComponent } from 'src/app/componentes/shared/modal/form-toma-fisica-completa/form-toma-fisica-completa.component';
import { nkServices } from 'src/app/componentes/shared/servicios/app.nkservicios.services';




@Component({
  selector: 'app-crear-toma-fisica',
  templateUrl: './crear-toma-fisica.component.html',
  styleUrls: ['./crear-toma-fisica.component.css']
})
export class CrearTomaFisicaComponent implements OnInit {



  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;


  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];



  dialogSpin:any;
  accion:any;
  xobj2:any;
  ELEMENT_DATA:any[]=[];
  filtrado:any[]=[];


  displayedColumns:string[];
  dataSource=new MatTableDataSource<any>();


  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top'; 


  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private servicio:nkServices , public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
              cod_menu: localStorage.getItem('sub_main'),
              cod_sesion: localStorage.getItem('cod_sesion'),
              dir_ip_client: localStorage.getItem('dir_ip'),
            sesion_unica:sessionStorage.getItem('sesion_unica')};
    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
         /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }
    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
    }
     /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL//////////////////////////////////
      },
      error =>{
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
          console.log(error);
      }
    );
   }


  ngOnInit(): void {
    
   this.listarSolicitudTomaFisica();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }


  listarSolicitudTomaFisica(){

    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
              accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        this.servicio.listarSolicitudTomaFisicahttp().subscribe(
          response =>{
            this.dialogSpin.close();
            if(response){
              console.log(response,'una respuesta');
              this.xobj2 = response;
              this.ELEMENT_DATA = [];

                for (const i in response) {

              this.ELEMENT_DATA.push({
                      cod_solicitud_toma_fisica:response[i].cod_solicitud_toma_fisica,
                      cod_deposito:response[i].cod_deposito,
                      des_lider_inv:response[i].des_lider_inv,
                      des_supervisor_inv:response[i].des_supervisor_inv,
                      des_auditor:response[i].des_auditor,
                      fecha_inicio:response[i].fecha_inicio,
                      hora_inicio:response[i].hora_inicio,
                      estatus:response[i].estatus,
                      tipo: response[i].tipo,
                      fase:response[i].fase,
                      accion:this.accion
                    });
                  }
                  this.displayedColumns = ['cod_solicitud_toma_fisica',
                                            'cod_deposito',
                                            'des_lider_inv',
                                            'des_supervisor_inv',
                                            'des_auditor',
                                            'fecha_inicio',
                                            'hora_inicio',
                                            'estatus',
                                            'tipo',
                                            'fase',
                                            'boton',
                                          ];
                this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA.reverse());
                this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
                                      'cod_solicitud_toma_fisica',
                                      'cod_deposito',
                                      'des_lider_inv',
                                      'des_supervisor_inv',
                                      'des_auditor',
                                      'fecha_inicio',
                                      'hora_inicio',
                                      'estatus',
                                      'tipo',
                                      'fase',
                                      'boton',
                                  ];
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
          }
        );
        return this.xobj2;
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  crear_toma_fisica(obj:any, text:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
      cod_menu: localStorage.getItem('sub_main'),
      cod_sesion: localStorage.getItem('cod_sesion'),
      dir_ip_client: localStorage.getItem('dir_ip'),
    sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
      console.log(obj, 'segundo');
      if (text == 'insertar') {
        this.accion = text;
         dialogRef = this.dialog.open(FormCrearTomaFisicaComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: {tipo:obj.tipo,
                 cod_deposito: obj.cod_deposito,
                 des_auditor:obj.des_auditor,
                 des_lider_inv:obj.des_lider_inv,
                 des_supervisor_inv:obj.des_supervisor_inv,
                 cant_item_top:obj.cant_item_top,
                 cant_item_random:obj.cant_item_random,
                 cod_usuario:localStorage.getItem('cod_usuario'),
                 des_usuario:localStorage.getItem('Nombre'),
                 accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal3',
              backdropClass: "bdrop"
        });
      }else{
        this.accion = text;
        dialogRef = this.dialog.open(FormCrearTomaFisicaComponent, {
          width:'100vw',
              maxWidth:'100vw',
              height:'100%',
              data: {cod_usuario:localStorage.getItem('cod_usuario'),
                    des_usuario:localStorage.getItem('Nombre'),
                    accion:this.accion},
          disableClose: true,
          panelClass:'pedidos_modal3',
          backdropClass: "bdrop"
        });
      }
      dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
              try {
                this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
                  width: '15%',
                  height:'25%',
                  data: {
                          accion:this.accion},
                  disableClose: true,
                  panelClass: 'spinner',
                  backdropClass: "bdrop"
                });
                setTimeout(() => {
                  var resultado = this.servicio.crearSolicitudTomaFisicahttp(result).subscribe(
                    response =>{
                      console.log(response, 'este beta esta fino toma fisica completa')
                      this.dialogSpin.close();
                      this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                      console.log(result.cod_deposito, 'para el listar')
                      this.listarSolicitudTomaFisica();
                    },
                    error =>{
                      this.dialogSpin.close();
                      this.displayedColumns = [
                                                'cod_solicitud_toma_fisica',
                                                'cod_deposito',
                                                'des_lider_inv',
                                                'des_supervisor_inv',
                                                'des_auditor',
                                                'fecha_inicio',
                                                'hora_inicio',
                                                'estatus',
                                                'tipo',
                                                'boton',
                        ];
                      this.dataSource = new MatTableDataSource<FormCrearTomaFisicaComponent>(this.ELEMENT_DATA);
                      this.dataSource.paginator = this.paginator;
                      console.log(<any>error.error.message);
                      this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,});
                    }
                  );
                  return resultado;
                }, 1000);
              } catch (error) {
                this.dialogSpin.close();
                this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
          }
       });
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
         );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      }
  }

  insert_data(obj:any, text:any){
    console.log(obj.tipo,'super dupe')
     try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
              cod_menu: localStorage.getItem('sub_main'),
              cod_sesion: localStorage.getItem('cod_sesion'),
              dir_ip_client: localStorage.getItem('dir_ip'),
            sesion_unica:sessionStorage.getItem('sesion_unica')};
            this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
              response =>{
                if (response.resul) {
                      var dialogRef;

                      this.accion = text;
                      dialogRef = this.dialog.open(FormTomaFisicaCompletaComponent, {
                        width: '100vw',
                        maxWidth: '100vw',
                        height:'100%',
                        data: {cod_solicitud_toma_fisica:obj.cod_solicitud_toma_fisica,
                              cod_deposito:obj.cod_deposito,
                              cod_producto:obj.cod_producto,
                              des_producto:obj.des_producto,
                              tipo_producto:obj.tipo_producto,
                              um_producto:obj.um_producto,
                              cant_producto:obj.cant_producto,
                              cant_ingresada:'0',
                              tipo:obj.tipo,
                              fase:obj.fase,
                              fase_1:obj.fase_1,
                              fase_2:obj.fase_2,
                              fase_3:obj.fase_3,
                              accion:this.accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                      });
                      dialogRef.afterClosed().subscribe(response => {
                        
                        if(response != 'cerrar'){    
                            this.dialogSpin.close();
                              this.listarSolicitudTomaFisica();
                        }           
                        
                      });         
              }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
         );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        } 
  }
}