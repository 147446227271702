<div class=" container-fluid rounded" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <div class="row">
        <div class="col-md-7 col-sm-12 col-xs-12 py-0">
            <mat-form-field class="materialinput my-1 mr-1 col-md-2" >
                <mat-label style="color: #fff; font-size: 12px;">Almacen: </mat-label>
                <input style=" color: #fff; font-size: 12px;" #cod_dep matInput placeholder="" autocomplete="off" readonly>
                <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
            </mat-form-field>
            <mat-form-field class="materialinput my-1 mr-1 col-md-5" >
                <mat-label style="color: #fff; font-size: 12px;">Producto</mat-label>
                <input style=" color: #fff; font-size: 12px;" #des_prod matInput placeholder="" autocomplete="off" readonly>
                <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
            </mat-form-field>
            <mat-form-field class="materialinput py-1 px-1 mr-1 col-md-4">
                <mat-label style="color: #fff; font-size: 12px;">Seleccione un rango de fecha</mat-label>
                <mat-date-range-input [formGroup]="forma" [rangePicker]="picker">
                    <input  style="color: #fff;" matStartDate formControlName="fecha_inicio" placeholder="Fecha inicio" autocomplete="off">
                    <input (dateChange)="fecha_tomada($event)" style="color: #fff;" matEndDate formControlName="fecha_fin" placeholder="Fecha fin" autocomplete="off">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-5 col-sm-12 col-xs-12 d-flex justify-content-end  py-0">
                <!-- <button (click)="filter_date()" matTooltip="RANGO DE FECHA" color="accent" class="my-2 mr-1 col-md-1 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255);"><mat-icon >date_range</mat-icon></button> -->
                <button (click)="filter_date_print()" matTooltip="IMPRIMIR" color="primary" class="my-2 mr-1 col-md-1 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255);"><mat-icon >print</mat-icon></button>
                <button (click)="buscarProductoMovimientos('actualizar')" matTooltip="TODOS LOS MOVIMIENTOS" color="accent" class="my-2 mr-1 col-md-1 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255);"><mat-icon >donut_small</mat-icon></button>
                <button (click)="cerrarmodal()" matTooltip="SALIR"  class="my-2 mr-1 col-md-1 col-sm-12 col-xs-12 bg-danger" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);"><mat-icon >exit_to_app</mat-icon></button>
                <input class="form-control col-md-4 col-sm-12 col-xs-12 my-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
        </div>
    </div>
    <div class="row modalgeneral" style=" border: 2px solid #2b69dd; background-color:#29388f;">
        <div class=" table-responsive  h-100" >
            <table #table mat-table [dataSource]="dataSource1" class=" table-dark text-center table-striped  table-condensed table-hover">

                <ng-container matColumnDef="inventario_salida">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Salida</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;">{{element.inventario_salida}}</td>
                    
                </ng-container>

                <ng-container matColumnDef="inventario_entrada">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Entrada</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.inventario_entrada}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cod_orden_prod">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>PT</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cod_orden_prod}} </td>
                   
                </ng-container>

                <ng-container matColumnDef="cod_orden_prod_mpp">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>MPP</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cod_orden_prod_mpp}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cod_proceso">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Proceso</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cod_proceso}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="operacion">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Operación</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.operacion}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="cod_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cod_producto}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="des_producto">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Producto</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.des_producto}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cant_prod_solicitada">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Cantidad</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cant_prod_solicitada}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cant_prod_actual">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Actual</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cant_prod_actual}} </td>
                    
                </ng-container>
                <ng-container matColumnDef="cant_prod_restante">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Restante</strong></h5>
                    </th>
                    <td [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cant_prod_restante}} </td>
                    
                </ng-container>

                <ng-container matColumnDef="procesos">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Información</strong></h5>
                    </th>
                    <td class="col-md-2" [ngClass]="{'prod_embalaje':  element.operacion === 'embalaje','prod_anulacion':  element.operacion === 'anulacion','prod_traslado':  element.operacion === 'traslado','prod_faltante':  element.operacion === 'cargo orden incompleta','prod_excedente':  element.operacion === 'descargo orden excedente','prod_laboratorio':  element.operacion === 'laboratorio','prod_facturacion':  element.operacion === 'facturacion','prod_cargo':  element.operacion === 'cargo','prod_descargo':  element.operacion === 'descargo','prod_consignacion':  element.operacion === 'consignacion','prod_produccion':  element.operacion === 'produccion', 'prod_devolucion':  element.operacion === 'devolucion'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;">
                            <button  class=" bg-primary mr-1"   matTooltip="COMENTARIOS" mat-mini-fab (click)="mostrar_comentarios(element,'comentarios_m')">
                                <mat-icon >image_search</mat-icon>
                            </button>
                            <button  class=" bg-success mr-1"   matTooltip="TIEMPO" mat-mini-fab (click)="mostrar_tiempo(element,'tiempo_m')">
                                <mat-icon >alarm</mat-icon>
                            </button>
                            <button  class=" bg-info mr-1"   matTooltip="PERSONA" mat-mini-fab (click)="mostrar_persona(element,'persona_m')">
                                <mat-icon >perm_identity</mat-icon>
                            </button>
                            <button  class=" bg-info"   matTooltip="FACTURAS" mat-mini-fab (click)="mostrar_factura(element,'factura_m')">
                                <mat-icon >assignment</mat-icon>
                            </button>
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
