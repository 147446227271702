<div class="container-fluid rounded" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <div class="row">
        <div class="col-md-3">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                <div id="carrito_p1_c" class="row p-1" style=" border: 2px solid #475bca;">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label style="color: #fff;">Formula </mat-label>
                                <input style="font-size: 11px; color: #fff;" #cod_formula matInput placeholder="" formControlName="cod_formula" autocomplete="off" readonly>
                            </mat-form-field>
                </div>
                <div id="carrito_p1_c" class="row p-1" style=" border: 2px solid #475bca;">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label style="color: #fff;">Producto: </mat-label>
                                <input style="font-size: 11px; color: #fff;" #cod_producto1 matInput placeholder="" formControlName="cod_producto" autocomplete="off" readonly>
                            </mat-form-field>
                </div>
                <div id="carrito_p1_c" class="row p-1" style=" border: 2px solid #475bca;">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label style="color: #fff;">Cantidad</mat-label>
                                <input class="text-right"  id="cant_base_id" placeholder="0,00" (keyup)="format_final($event,'cant_base_id','')" style="font-size: 12px; color: #fff;" type="text" matInput formControlName="cant_prod"  autocomplete="off">
                            </mat-form-field>
                </div>
                <div id="carrito_p1_c" class="row p-1" style=" border: 2px solid #475bca;">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label style="color: #fff;">Codigo MP</mat-label>
                                <input style="font-size: 11px; color: #fff;" type="text" matInput placeholder="" formControlName="cod_producto_mp" [matAutocomplete]="autoGroup2" #cod_producto_mp>
                                <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                <mat-icon>search</mat-icon>
                                </span>
                                <mat-autocomplete #autoGroup2="matAutocomplete">
                                    <mat-optgroup style="color: #000; font-size: 11px; " *ngFor="let group of arry | async" [label]="group.letter">
                                        <mat-option style="color: #000; font-size: 12px; " *ngFor="let i of group.code" [value]="i">
                                            {{i}}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-autocomplete>
                            </mat-form-field>
                </div>
                <div id="carrito_p1_c" class="row p-1" style=" border: 2px solid #475bca;">
                </div>
                <div id="carrito_p1_c" class="row p-1" style=" border: 2px solid #475bca;">
                </div>
                <div id="carrito_p1_c" class="row p-1 " style=" border: 2px solid #475bca;">
                </div>
                <div id="carrito_p1_c" class="row p-1" style=" border: 2px solid #475bca;">
                </div>
            </form>
        </div>
        <div class="col-md-9">
        <div class="row border">
                <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-start p-1">
                    <span class="text-white fw-bold">Formula de ( {{data.des_producto | uppercase}} ) </span>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end p-1">
                    <input class="form-control mr-1" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                    <button matTooltip="GUARDAR" (click)="modalrespuesta()"  class=" mr-1 btn bg-primary text-white"><mat-icon >save</mat-icon></button>
                    <button matTooltip="AGREGAR" (click)="addElement()"  class="mr-1 btn bg-warning text-white"><mat-icon >add</mat-icon></button>
                    <button matTooltip="SALIR" (click)="cerrarmodal()" class="btn bg-danger text-white"><mat-icon >exit_to_app</mat-icon></button>
                </div>
        </div>
        <div class="row modalgeneral" style=" background-color:#29388f;">
            <div class=" table-responsive  h-100" >
                <table #table mat-table [dataSource]="dataSource1" class="table-dark text-center table-striped table-condensed table-hover">

                    <ng-container matColumnDef="cod_prod_mp">
                        <th mat-header-cell *matHeaderCellDef class="tdtr_formulario" >
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_prod_mp}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cod_img">
                        <th mat-header-cell *matHeaderCellDef class="tdtr_formulario" >
                            <h5><strong>Imagen</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="td_style"> <img width="50px" height="50px" class="rounded" [src]="element.cod_img" alt=""></td>
    
                    </ng-container>

                    <ng-container matColumnDef="des_prod_mp">
                        <th mat-header-cell *matHeaderCellDef class="tdtr_formulario" >
                            <h5><strong>Componente base</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_prod_mp}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo_producto">
                        <th mat-header-cell *matHeaderCellDef class="tdtr_formulario" >
                            <h5><strong>Tipo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="td_style">{{element.tipo_producto}}</td>
                    </ng-container>

                    <ng-container matColumnDef="unidad_prod_mp">
                        <th mat-header-cell *matHeaderCellDef class="tdtr_formulario" >
                            <h5><strong>Unidad de medida</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="td_style">{{element.unidad_prod_mp}}</td>
                    </ng-container>

                    <ng-container matColumnDef="cantidad_prod_mp">
                        <th mat-header-cell *matHeaderCellDef class="tdtr_formulario" >
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="td_style"> {{element.cantidad_prod_mp}} </td>
                    </ng-container>

                    <ng-container matColumnDef="procesos">
                        <th mat-header-cell *matHeaderCellDef class="tdtr_formulario">
                            <h5><strong>Procesos</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                            <button class=" bg-danger"   matTooltip="QUITAR PRODUCTO" mat-mini-fab (click)="eliminarItems(element)">
                                <mat-icon >clear</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="border"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
        </div>
    </div>
</div>