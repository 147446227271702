import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import 'hammerjs';
import { ChartsModule } from 'ng2-charts';


//angular material
import {LayoutModule} from '@angular/cdk/layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {MatRadioModule} from '@angular/material/radio';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSidenavModule} from '@angular/material/sidenav';


//IDLE

import {NgIdleKeepaliveModule} from '@ng-idle/keepalive'; // esto incluye el núcleo NgIdleModule pero incluye proveedores keepalive para una fácil conexión
import {MomentModule} from 'angular2-moment'; // opcional, proporciona canalizaciones de estilo de momento para el formato de fecha


//firebase

import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';

import { environment } from '../environments/environment';

//PDFMAKE

import {PdfMakeWrapper} from 'pdfmake-wrapper';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
PdfMakeWrapper.setFonts(pdfFonts);

//MASK

import { NgxMaskModule, IConfig } from 'ngx-mask'

const maskConfig: Partial<IConfig> = {
  validation: false,
};
//RUTAS

import { APP_ROUTING } from './app.rutas';

//SERVICIOS
import { nkServices } from './componentes/shared/servicios/app.nkservicios.services';
import { AuthGuardService } from '../app/componentes/shared/servicios/auth-guard.service';
import {NgxPermissionsGuard} from 'ngx-permissions';
import { HashKeyServices } from './componentes/shared/servicios/app.hashkey.services';
import { PdfServices } from './componentes/shared/servicios/app.pdf.services';


//COMPONENTES

import { AppComponent } from './app.component';
import { InicioComponent } from './componentes/shared/inicio/inicio.component';
import { FooterComponent } from './componentes/shared/footer/footer.component';
import { NavbarComponent } from './componentes/shared/navbar/navbar.component';
import { MercadeoComponent } from './componentes/publicidad/mercadeo/mercadeo.component';

import { EstadisticasComponent } from './componentes/publicidad/mercadeo/estadisticas/estadisticas.component';
import { PublicidadComponent } from './componentes/publicidad/mercadeo/publicidad/publicidad.component';
import { InventariosysComponent } from './componentes/sistemas/inventariosys/inventariosys.component';
import { UsuariosComponent } from './componentes/sistemas/usuarios/usuarios.component';

import { MensajeriaComponent } from './componentes/publicidad/mercadeo/publicidad/mensajeria/mensajeria.component';
import { ListarregistrosComponent } from './componentes/publicidad/mercadeo/publicidad/listarregistros/listarregistros.component';

import { ModificarregistrosComponent } from './componentes/publicidad/mercadeo/publicidad/modificarregistros/modificarregistros.component';
import { PubliacercadeComponent } from './componentes/publicidad/mercadeo/publicidad/publiacercade/publiacercade.component';

import { NgDropFilesDirective } from '../app/componentes/shared/directives/ng-drop-files.directive';
import { PerfilesComponent } from './componentes/sistemas/usuarios/app.perfiles/app.perfiles.component';
import { FormUsuariosComponent } from './componentes/shared/modal/form-usuarios/form-usuarios.component';
import { FormmMsgComponent } from './componentes/shared/modal/formm-msg/formm-msg.component';
import { FormPermisosComponent } from './componentes/shared/modal/form-permisos/form-permisos.component';
import { MenuUsuComponent } from './componentes/sistemas/config/menu-usu/menu-usu.component';
import { FormMenuComponent } from './componentes/shared/modal/form-menu/form-menu.component';
import { AcercadeComponent } from './componentes/sistemas/usuarios/acercade/acercade.component';
import { RolesComponent } from './componentes/sistemas/usuarios/roles/roles.component';
import { FormRolesComponent } from './componentes/shared/modal/form-roles/form-roles.component';
import { FormRolmenuComponent } from './componentes/shared/modal/form-rolmenu/form-rolmenu.component';
import { FormLoginComponent } from './componentes/shared/modal/form-login/form-login.component';
import { FacturasPendientesComponent } from './componentes/administracion/cobranzas/facturas-pendientes/facturas-pendientes.component';
import { ClientesPendientesComponent } from './componentes/administracion/cobranzas/clientes-pendientes/clientes-pendientes.component';
import { GestionCobrosComponent } from './componentes/administracion/cobranzas/gestion-cobros/gestion-cobros.component';
import { GestionClientesComponent } from './componentes/sistemas/configurador/gestion-clientes/gestion-clientes.component';
import { GestionarFacturasComponent } from './componentes/administracion/cobranzas/facturas-pendientes/gestionar-facturas/gestionar-facturas.component';
import { ProcesarCobrosComponent } from './componentes/administracion/cobranzas/gestion-cobros/procesar-cobros/procesar-cobros.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfigComponent } from './componentes/sistemas/config/config.component';
import { UnidadesComponent } from './componentes/sistemas/config/unidades/unidades.component';
import { ComputadorasComponent } from './componentes/sistemas/inventariosys/computadoras/computadoras.component';
import { PerifericosComponent } from './componentes/sistemas/inventariosys/perifericos/perifericos.component';

import { FacturacionComponent } from './componentes/administracion/ventas/facturacion/facturacion.component';
import { PedidosComponent } from './componentes/administracion/ventas/pedidos/pedidos.component';
import { FacturasComponent } from './componentes/administracion/ventas/facturacion/facturas/facturas.component';
import { ConsultarFactComponent } from './componentes/administracion/ventas/facturacion/consultar-fact/consultar-fact.component';
import { FormUnidadComponent } from './componentes/shared/modal/form-unidad/form-unidad.component';
import { GestionPedidosComponent } from './componentes/administracion/ventas/pedidos/gestion-pedidos/gestion-pedidos.component';
import { FormClientesComponent } from './componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from './componentes/shared/modal/form-direcciones/form-direcciones.component';
import { InventarioAComponent } from './componentes/logistica/almacen/inventario/inventario-a.component';
import { FormPedidosComponent } from './componentes/shared/modal/form-pedidos/form-pedidos.component';
import { ConsultarPedidosComponent } from './componentes/administracion/ventas/pedidos/consultar-pedidos/consultar-pedidos.component';
import { FormPedidosNComponent } from './componentes/shared/modal/form-pedidos-n/form-pedidos-n.component';
import { FormFacturaComponent } from './componentes/shared/modal/form-factura/form-factura.component';
import { ConfiguradorComponent } from './componentes/sistemas/configurador/configurador.component';
import { PreciosComponent } from './componentes/sistemas/configurador/precios/precios.component';
import { FormPreciosComponent } from './componentes/shared/modal/form-precios/form-precios.component';
import { FormConsultaListaComponent } from './componentes/shared/modal/form-consulta-lista/form-consulta-lista.component';
import { FormPreciosActualizarComponent } from './componentes/shared/modal/form-precios-actualizar/form-precios-actualizar.component';
import { FormModifiPrecioComponent } from './componentes/shared/modal/form-modifi-precio/form-modifi-precio.component';
import { GestionConsignacionesComponent } from './componentes/logistica/almacen/consignaciones/gestion-consignaciones/gestion-consignaciones.component';
import { ConsignacionesComponent } from './componentes/logistica/almacen/consignaciones/consignaciones.component';
import { ProductoFactoryComponent } from './componentes/sistemas/configurador/producto-factory/producto-factory.component';
import { OrdenesProduccionComponent } from './componentes/operaciones/produccion/ordenes-produccion/ordenes-produccion.component';
import { GestionOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/gestion-ordenes/gestion-ordenes.component';
import { FormProductosComponent } from './componentes/shared/modal/form-productos/form-productos.component';
import { FormFormulacionComponent } from './componentes/shared/modal/form-formulacion/form-formulacion.component';
import { MpProductoFactoryComponent } from './componentes/sistemas/configurador/mp-producto-factory/mp-producto-factory.component';
import { FormMateriaPrimaComponent } from './componentes/shared/modal/form-materia-prima/form-materia-prima.component';
import { GestionProveedoresComponent } from './componentes/sistemas/configurador/gestion-proveedores/gestion-proveedores.component';
import { FormProvComponent } from './componentes/shared/modal/form-prov/form-prov.component';
import { InventarioMpComponent } from './componentes/logistica/almacen/inventario/inventario-mp/inventario-mp.component';
import { InventarioPdComponent } from './componentes/logistica/almacen/inventario/inventario-pd/inventario-pd.component';
import { FormInventarioMpComponent } from './componentes/shared/modal/form-inventario-mp/form-inventario-mp.component';
import { FormInventarioPdComponent } from './componentes/shared/modal/form-inventario-pd/form-inventario-pd.component';
import { InventarioPtComponent } from './componentes/logistica/almacen/inventario/inventario-pt/inventario-pt.component';
import { FormOrdenesprodComponent } from './componentes/shared/modal/form-ordenesprod/form-ordenesprod.component';
import { FormConsultaOrdenesComponent } from './componentes/shared/modal/form-consulta-ordenes/form-consulta-ordenes.component';
import { FormConfirmacionInvMpComponent } from './componentes/shared/modal/form-confirmacion-inv-mp/form-confirmacion-inv-mp.component';
import { MpProductoPreparadoComponent } from './componentes/sistemas/configurador/mp-producto-preparado/mp-producto-preparado.component';
import { FormMateriaPrimaPreparadaComponent } from './componentes/shared/modal/form-materia-prima-preparada/form-materia-prima-preparada.component';
import { FormConsultarFormulaComponent } from './componentes/shared/modal/form-consultar-formula/form-consultar-formula.component';
import { GestionOrdenesElaboracionComponent } from './componentes/operaciones/produccion/ordenes-produccion/gestion-ordenes-elaboracion/gestion-ordenes-elaboracion.component';
import { SeguimientoOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/seguimiento-ordenes/seguimiento-ordenes.component';
import { FormFormulacionMppComponent } from './componentes/shared/modal/form-formulacion-mpp/form-formulacion-mpp.component';
import { GestionOrdenesMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/gestion-ordenes-mpp/gestion-ordenes-mpp.component';
import { FormOrdenesprodmppComponent } from './componentes/shared/modal/form-ordenesprodmpp/form-ordenesprodmpp.component';
import { FormConfirmacionInvMppComponent } from './componentes/shared/modal/form-confirmacion-inv-mpp/form-confirmacion-inv-mpp.component';
import { FormConsultarFormulaMppComponent } from './componentes/shared/modal/form-consultar-formula-mpp/form-consultar-formula-mpp.component';
import { OrdenesProduccionMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/ordenes-produccion-mpp.component';
import { GestionOrdenesElaboracionMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/gestion-ordenes-elaboracion-mpp/gestion-ordenes-elaboracion-mpp.component';
import { FormConsultaOrdenesMppComponent } from './componentes/shared/modal/form-consulta-ordenes-mpp/form-consulta-ordenes-mpp.component';
import { SeguimientoOrdenesMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/seguimiento-ordenes-mpp/seguimiento-ordenes-mpp.component';

import { FormSeleccionComponent } from './componentes/shared/modal/form-seleccion/form-seleccion.component';
import { FormFacturarComponent } from './componentes/shared/modal/form-facturar/form-facturar.component';
import { FormFormulaUnidadComponent } from './componentes/shared/modal/form-formula-unidad/form-formula-unidad.component';
import { FormFormulaUnidadMppComponent } from './componentes/shared/modal/form-formula-unidad-mpp/form-formula-unidad-mpp.component';
import { FormComfirmFabricacionComponent } from './componentes/shared/modal/form-comfirm-fabricacion/form-comfirm-fabricacion.component';
import { AuditoriaOrdProdComponent } from './componentes/operaciones/produccion/auditoria-ord-prod/auditoria-ord-prod.component';
import { OrdenesProdRevisionComponent } from './componentes/operaciones/produccion/auditoria-ord-prod/ordenes-prod-revision/ordenes-prod-revision.component';
import { InventarioLtComponent } from './componentes/logistica/almacen/inventario/inventario-lt/inventario-lt.component';
import { DespachoComponent } from './componentes/logistica/almacen/despacho/despacho.component';
import { GuiaDespachoComponent } from './componentes/logistica/almacen/despacho/guia-despacho/guia-despacho.component';
import { ConsultarOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/consultar-ordenes/consultar-ordenes.component';
import { ConfirmarOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/confirmar-ordenes/confirmar-ordenes.component';
import { EditarOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/editar-ordenes/editar-ordenes.component';
import { CrearOrdenesComponent } from './componentes/operaciones/produccion/ordenes-produccion/crear-ordenes/crear-ordenes.component';
import { ConsultarOrdenesMppComponent } from './componentes/operaciones/produccion/ordenes-produccion-mpp/consultar-ordenes-mpp/consultar-ordenes-mpp.component';
import { FormConsultasOrdenesComponent } from './componentes/shared/modal/form-consultas-ordenes/form-consultas-ordenes.component';
import { FormConfirmarFabricacionPtComponent } from './componentes/shared/modal/form-confirmar-fabricacion-pt/form-confirmar-fabricacion-pt.component';
import { InventarioSgComponent } from './componentes/logistica/almacen/inventario/inventario-sg/inventario-sg.component';
import { InventarioSplComponent } from './componentes/logistica/almacen/inventario/inventario-spl/inventario-spl.component';
import { FormInvSplComponent } from './componentes/shared/modal/form-inv-spl/form-inv-spl.component';
import { OrdenesProdMppRevisionComponent } from './componentes/operaciones/produccion/auditoria-ord-prod/ordenes-prod-mpp-revision/ordenes-prod-mpp-revision.component';
import { FormRevisarOrdenesMppComponent } from './componentes/shared/modal/form-revisar-ordenes-mpp/form-revisar-ordenes-mpp.component';
import { FormPedidosNBsComponent } from './componentes/shared/modal/form-pedidos-n-bs/form-pedidos-n-bs.component';
import { FormPedidoFrecuencialComponent } from './componentes/shared/modal/form-pedido-frecuencial/form-pedido-frecuencial.component';
import { FormTraladoInvComponent } from './componentes/shared/modal/form-tralado-inv/form-tralado-inv.component';
import { FormKeyComponent } from './componentes/shared/modal/form-key/form-key.component';
import { FormAjustarOrdComponent } from './componentes/shared/modal/form-ajustar-ord/form-ajustar-ord.component';
import { FormRevisionOrdComponent } from './componentes/shared/modal/form-revision-ord/form-revision-ord.component';
import { FormConsignacionesComponent } from './componentes/shared/modal/form-consignaciones/form-consignaciones.component';
import { FormConfirmConsignacionComponent } from './componentes/shared/modal/form-confirm-consignacion/form-confirm-consignacion.component';
import { ConsultarConsignacionesComponent } from './componentes/logistica/almacen/consignaciones/consultar-consignaciones/consultar-consignaciones.component';
import { ConsultarGuiaDespachoComponent } from './componentes/logistica/almacen/despacho/consultar-guia-despacho/consultar-guia-despacho.component';
import { FormInspeccionOrdComponent } from './componentes/shared/modal/form-inspeccion-ord/form-inspeccion-ord.component';
import { FormNotaCreditoComponent } from './componentes/shared/modal/form-nota-credito/form-nota-credito.component';
import { FormCantDevComponent } from './componentes/shared/modal/form-cant-dev/form-cant-dev.component';
import { FormSpinnerComponent } from './componentes/shared/modal/form-spinner/form-spinner.component';
import { FormNotaCreditoAdminComponent } from './componentes/shared/modal/form-nota-credito-admin/form-nota-credito-admin.component';
import { EntradaInventarioComponent } from './componentes/logistica/almacen/entrada-inventario/entrada-inventario.component';
import { MateriaPrimaComponent } from './componentes/logistica/almacen/entrada-inventario/materia-prima/materia-prima.component';
import { CuarentenaComponent } from './componentes/calidad/aseguramiento/cuarentena/cuarentena.component';
import { MateriaPrimaEntradaComponent } from './componentes/calidad/aseguramiento/cuarentena/materia-prima-entrada/materia-prima-entrada.component';
import { FormEntradaMpComponent } from './componentes/shared/modal/form-entrada-mp/form-entrada-mp.component';
import { OrdenesComprasComponent } from './componentes/administracion/compras/ordenes-compras/ordenes-compras/ordenes-compras.component';
import { GestionComprasComponent } from './componentes/administracion/compras/ordenes-compras/gestion-compras/gestion-compras.component';
import { FormOrdenesComprasComponent } from './componentes/shared/modal/form-ordenes-compras/form-ordenes-compras.component';
import { CalidadProductoTerminadoComponent } from './componentes/calidad/aseguramiento/cuarentena/calidad-producto-terminado/calidad-producto-terminado.component';
import { ControlExcepcionesComponent } from './componentes/sistemas/config/control-excepciones/control-excepciones.component';
import { ConsultarNotasComponent } from './componentes/administracion/ventas/facturacion/consultar-notas/consultar-notas.component';
import { ConsultarNotasEntregaComponent } from './componentes/administracion/ventas/facturacion/consultar-notas-entrega/consultar-notas-entrega.component';
import { DevolucionesProductoComponent } from './componentes/calidad/aseguramiento/cuarentena/devoluciones-producto/devoluciones-producto.component';
import { FormClientesNuevoComponent } from './componentes/shared/modal/form-clientes-nuevo/form-clientes-nuevo.component';
import { FormReportesComponent } from './componentes/shared/modal/form-reportes/form-reportes.component';
import { ConsultarNotasDebitoComponent } from './componentes/administracion/ventas/facturacion/consultar-notas-debito/consultar-notas-debito.component';
import { FormNotaDebitoComponent } from './componentes/shared/modal/form-nota-debito/form-nota-debito.component';
import { IntranetComponent } from './componentes/sistemas/intranet/intranet.component';
import { SoporteItComponent } from './componentes/sistemas/intranet/soporte-it/soporte-it.component';
import { FormSoporteComponent } from './componentes/shared/modal/form-soporte/form-soporte.component';
import { SoporteConfigComponent } from './componentes/sistemas/config/soporte-config/soporte-config.component';
import { FormCrearTareasComponent } from './componentes/shared/modal/form-crear-tareas/form-crear-tareas.component';
import { SistemasComponent } from './componentes/sistemas/sistemas/sistemas.component';
import { SoporteTecnicoAdminComponent } from './componentes/sistemas/sistemas/soporte-tecnico-admin/soporte-tecnico-admin.component';
import { FormEvaluacionSoporteComponent } from './componentes/shared/modal/form-evaluacion-soporte/form-evaluacion-soporte.component';
import { FormReportesVentasComponent } from './componentes/shared/modal/form-reportes-ventas/form-reportes-ventas.component';
import { FormNotaCreditoAdminSingleComponent } from './componentes/shared/modal/form-nota-credito-admin-single/form-nota-credito-admin-single.component';
import { FormNotaCreditoAdminBaseComponent } from './componentes/shared/modal/form-nota-credito-admin-base/form-nota-credito-admin-base.component';
import { FormPermisosAccionesComponent } from './componentes/shared/modal/form-permisos-acciones/form-permisos-acciones.component';
import { SesionesUsuariosComponent } from './componentes/sistemas/sistemas/sesiones-usuarios/sesiones-usuarios.component';
import { FormOrdHistorialPtComponent } from './componentes/shared/modal/form-ord-historial-pt/form-ord-historial-pt.component';
import { FormOrdHistorialMppComponent } from './componentes/shared/modal/form-ord-historial-mpp/form-ord-historial-mpp.component';
import { EstatusOrdenesProdComponent } from './componentes/sistemas/sistemas/estatus-ordenes-prod/estatus-ordenes-prod.component';
import { DefinicionesSistemaComponent } from './componentes/sistemas/config/definiciones-sistema/definiciones-sistema.component';
import { FormDefinicionesSistemaComponent } from './componentes/shared/modal/form-definiciones-sistema/form-definiciones-sistema.component';
import { FormDefinicionesModulosComponent } from './componentes/shared/modal/form-definiciones-modulos/form-definiciones-modulos.component';
import { DepositosComponent } from './componentes/logistica/almacen/inventario/depositos/depositos.component';
import { FormCrearDepositoComponent } from './componentes/shared/modal/form-crear-deposito/form-crear-deposito.component';
import { FormDepositosComponent } from './componentes/shared/modal/form-depositos/form-depositos.component';
import { FormMovimientosProdComponent } from './componentes/shared/modal/form-movimientos-prod/form-movimientos-prod.component';
import { FormFechasComponent } from './componentes/shared/modal/form-fechas/form-fechas.component';
import { ComedorComponent } from './componentes/rrhh/empleados/comedor/comedor.component';
import { EmpleadosComponent } from './componentes/rrhh/empleados/empleados.component';
import { EmpleadoComponent } from './componentes/rrhh/empleados/empleado/empleado.component';
import { FormCrearQrEmpleadosComponent } from './componentes/shared/modal/form-crear-qr-empleados/form-crear-qr-empleados.component';
import { FormListarEmpleadoComponent } from './componentes/shared/modal/form-listar-empleado/form-listar-empleado.component';
import { AsistenciasComponent } from './componentes/rrhh/empleados/asistencias/asistencias.component';
import { QRCodeModule } from 'angular2-qrcode';
import { FormPedidosUmComponent } from './componentes/shared/modal/form-pedidos-um/form-pedidos-um.component';
import { FormPedidosNUmComponent } from './componentes/shared/modal/form-pedidos-n-um/form-pedidos-n-um.component';
import { FormPedidosNBsUmComponent } from './componentes/shared/modal/form-pedidos-n-bs-um/form-pedidos-n-bs-um.component';
import { FormTrasladosDepComponent } from './componentes/shared/modal/form-traslados-dep/form-traslados-dep.component';
import { FormFechasEmpleadoComponent } from './componentes/shared/modal/form-fechas-empleado/form-fechas-empleado.component';
import { ReportGenAsistenciaComponent } from './componentes/rrhh/empleados/report-gen-asistencia/report-gen-asistencia.component';
import { ReportMesEmpleadoComponent } from './componentes/rrhh/empleados/report-mes-empleado/report-mes-empleado.component';
import { DevolucionesComponent } from './componentes/logistica/almacen/devoluciones/devoluciones.component';
import { DevolucionProductosComponent } from './componentes/logistica/almacen/devoluciones/devolucion-productos/devolucion-productos.component';
import { ProductosDevueltosComponent } from './componentes/logistica/almacen/devoluciones/productos-devueltos/productos-devueltos.component';
import { FormCantDevProductComponent } from './componentes/shared/modal/form-cant-dev-product/form-cant-dev-product.component';
import { FormCantidadProductosDevueltosComponent } from './componentes/shared/modal/form-cantidad-productos-devueltos/form-cantidad-productos-devueltos.component';
import { FormCrearDevolucionComponent } from './componentes/shared/modal/form-crear-devolucion/form-crear-devolucion.component';
import { FormListarProducDevolucionComponent } from './componentes/shared/modal/form-listar-produc-devolucion/form-listar-produc-devolucion.component';
import { FormPtDevolucionesComponent } from './componentes/shared/modal/form-pt-devoluciones/form-pt-devoluciones.component';
import { DevolverFormulaProductoComponent } from './componentes/shared/modal/devolver-formula-producto/devolver-formula-producto.component';
import { FormDetalleGuiaComponent } from './componentes/shared/modal/form-detalle-guia/form-detalle-guia.component';
import { DevolucionCompletaComponent } from './componentes/logistica/almacen/devoluciones/devolucion-completa/devolucion-completa.component';
import { FormCantidadCompletaDevolucionComponent } from './componentes/shared/modal/form-cantidad-completa-devolucion/form-cantidad-completa-devolucion.component';
import { FormDevolverProductoCompletoComponent } from './componentes/shared/modal/form-devolver-producto-completo/form-devolver-producto-completo.component';
import { ConnectionServices } from './componentes/shared/servicios/app.connection.services';
import { FormCantDevProdReComponent } from './componentes/shared/modal/form-cant-dev-prod-re/form-cant-dev-prod-re.component';
import { RedirecAppComponent } from './redirec-app.component';
import { FormReportesNotasDebitoComponent } from './componentes/shared/modal/form-reportes-notas-debito/form-reportes-notas-debito.component';
import { FormReportesVentasNotasCreditoComponent } from './componentes/shared/modal/form-reportes-ventas-notas-credito/form-reportes-ventas-notas-credito.component';
import { FormReportesVentasNotasComponent } from './componentes/shared/modal/form-reportes-ventas-notas/form-reportes-ventas-notas.component';
import { FormReportesConsignacionesComponent } from './componentes/shared/modal/form-reportes-consignaciones/form-reportes-consignaciones.component';
import { FormEnviarReporteDailyComponent } from './componentes/shared/modal/form-enviar-reporte-daily/form-enviar-reporte-daily.component';
import { FormCargarMontoDailyComponent } from './componentes/shared/modal/form-cargar-monto-daily/form-cargar-monto-daily.component';
import { ReportesAppComponent } from './componentes/sistemas/reportes/reportes-app/reportes-app.component';
import { ConsultarReportesComponent } from './componentes/sistemas/reportes/consultar-reportes/consultar-reportes.component';
import { ReportesComponent } from './componentes/sistemas/reportes/reportes.component';
import { OrdenRequisicionComponent } from './componentes/administracion/compras/ordenes-compras/orden-requisicion/orden-requisicion.component';
import { CotizacionComponent } from './componentes/administracion/compras/ordenes-compras/cotizacion/cotizacion.component';
import { FormAprobarSolicitudCompraComponent } from './componentes/shared/modal/form-aprobar-solicitud-compra/form-aprobar-solicitud-compra.component';
import { FormCantidadProductoReqCompraComponent } from './componentes/shared/modal/form-cantidad-producto-req-compra/form-cantidad-producto-req-compra.component';
import { FormCuestionarioComprasComponent } from './componentes/shared/modal/form-cuestionario-compras/form-cuestionario-compras.component';
import { FormOrdenReqComponent } from './componentes/shared/modal/form-orden-req/form-orden-req.component';
import { FormCrearOcProductosxproveedorComponent } from './componentes/shared/modal/form-crear-oc-productosxproveedor/form-crear-oc-productosxproveedor.component';
import { FormCrearProveedorComprasComponent } from './componentes/shared/modal/form-crear-proveedor-compras/form-crear-proveedor-compras.component';
import { FormCrearOrdenCompraComponent } from './componentes/shared/modal/form-crear-orden-compra/form-crear-orden-compra.component';
import { FormAprobarCotizacionComponent } from './componentes/shared/modal/form-aprobar-cotizacion/form-aprobar-cotizacion.component';
import { FormCotizacionReqComponent } from './componentes/shared/modal/form-cotizacion-req/form-cotizacion-req.component';
import { FormCrearCotizacionComponent } from './componentes/shared/modal/form-crear-cotizacion/form-crear-cotizacion.component';
import { FormPrecioCotizarComponent } from './componentes/shared/modal/form-precio-cotizar/form-precio-cotizar.component';
import { FormSelectTCotizacionComponent } from './componentes/shared/modal/form-select-t-cotizacion/form-select-t-cotizacion.component';
import { OrdenesfrontComprasComponent } from './componentes/administracion/compras/ordenes-compras/ordenes-front-compras.component';
import { GestionCentroCostoComponent } from './componentes/sistemas/configurador/gestion-centro-costo/gestion-centro-costo.component';
import { FormCrearCentroCostosComponent } from './componentes/shared/modal/form-crear-centro-costos/form-crear-centro-costos.component';
import { FormCrearCuentasComponent } from './componentes/shared/modal/form-crear-cuentas/form-crear-cuentas.component';
import { FormImportarCentroDeCostosComponent } from './componentes/shared/modal/form-importar-centro-de-costos/form-importar-centro-de-costos.component';
import { FormListarCuentasCreadasComponent } from './componentes/shared/modal/form-listar-cuentas-creadas/form-listar-cuentas-creadas.component';
import { FormCargarMontoPartidasComponent } from './componentes/shared/modal/form-cargar-monto-partidas/form-cargar-monto-partidas.component';
import { FormTransferirPartidasComponent } from './componentes/shared/modal/form-transferir-partidas/form-transferir-partidas.component';
import { FormListarPartidasCreadasComponent } from './componentes/shared/modal/form-listar-partidas-creadas/form-listar-partidas-creadas.component';
import { FormMovimientosCuentasComponent } from './componentes/shared/modal/form-movimientos-cuentas/form-movimientos-cuentas.component';
import { FormMovimientosPartidasComponent } from './componentes/shared/modal/form-movimientos-partidas/form-movimientos-partidas.component';
import { FormParametrosComponent } from './componentes/shared/modal/form-parametros/form-parametros.component';
import { FormHistorialParametrosVentasComponent } from './componentes/shared/modal/form-historial-parametros-ventas/form-historial-parametros-ventas.component';
import { FormCotizacionVerSolicitudesComponent } from './componentes/shared/modal/form-cotizacion-ver-solicitudes/form-cotizacion-ver-solicitudes.component';
import { FormCotizacionVerCotizacionComponent } from './componentes/shared/modal/form-cotizacion-ver-cotizacion/form-cotizacion-ver-cotizacion.component';
import { RecepcionComponent } from './componentes/logistica/almacen/recepcion/recepcion.component';
import { GestionarRecepcionesComponent } from './componentes/logistica/almacen/recepcion/gestionar-recepciones/gestionar-recepciones.component';
import { FormRecepcionFacturaComponent } from './componentes/shared/modal/form-recepcion-factura/form-recepcion-factura.component';
import { FormRecepcionModalComponent } from './componentes/shared/modal/form-recepcion-modal/form-recepcion-modal.component';
import { FormRecepcionOrdCompraComponent } from './componentes/shared/modal/form-recepcion-ord-compra/form-recepcion-ord-compra.component';
import { FormRecepcionVerDocumentoComponent } from './componentes/shared/modal/form-recepcion-ver-documento/form-recepcion-ver-documento.component';
import { FormRecepcionVerProductosRecepcionComponent } from './componentes/shared/modal/form-recepcion-ver-productos-recepcion/form-recepcion-ver-productos-recepcion.component';
import { FormRecepcionVerSegRecepcionComponent } from './componentes/shared/modal/form-recepcion-ver-seg-recepcion/form-recepcion-ver-seg-recepcion.component';
import { TomaFisicaInevntarioComponent } from './componentes/auditoria/inventario/toma-fisica-inevntario/toma-fisica-inevntario.component';
import { CrearTomaFisicaComponent } from './componentes/auditoria/inventario/toma-fisica-inevntario/crear-toma-fisica/crear-toma-fisica.component';
import { NuevaTomaFisicaComponent } from './componentes/auditoria/inventario/toma-fisica-inevntario/nueva-toma-fisica/nueva-toma-fisica.component';
import { FormTomaFisicaCompletaComponent } from './componentes/shared/modal/form-toma-fisica-completa/form-toma-fisica-completa.component';
import { FormCantidadTomaFisicaCompletaComponent } from './componentes/shared/modal/form-cantidad-toma-fisica-completa/form-cantidad-toma-fisica-completa.component';
import { FormCrearTomaFisicaComponent } from './componentes/shared/modal/form-crear-toma-fisica/form-crear-toma-fisica.component';
import { CalidadRecepcionComponent } from './componentes/calidad/aseguramiento/cuarentena/calidad-recepcion/calidad-recepcion.component';
import { RecepcionesRecibidasComponent } from './componentes/logistica/almacen/recepcion/recepciones-recibidas/recepciones-recibidas.component';
import { FormRecepcionVerLiberadasComponent } from './componentes/shared/modal/form-recepcion-ver-liberadas/form-recepcion-ver-liberadas.component';
import { FormRecepcionVerRecepcionesRecibidasComponent } from './componentes/shared/modal/form-recepcion-ver-recepciones-recibidas/form-recepcion-ver-recepciones-recibidas.component';
import { FormCalidadVerRecepcionLiberadaComponent } from './componentes/shared/modal/form-calidad-ver-recepcion-liberada/form-calidad-ver-recepcion-liberada.component';
import { FormOrdenComprasVerCompletasComponent } from './componentes/shared/modal/form-orden-compras-ver-completas/form-orden-compras-ver-completas.component';
import { FormRecepcionCantProductoCalidadComponent } from './componentes/shared/modal/form-recepcion-cant-producto-calidad/form-recepcion-cant-producto-calidad.component';
import { FormRecepcionesVerCompletasComponent } from './componentes/shared/modal/form-recepciones-ver-completas/form-recepciones-ver-completas.component';
import { FormNotacreditoNotasComponent } from './componentes/shared/modal/form-notacredito-notas/form-notacredito-notas.component';
import { PreFormaComponent } from './componentes/administracion/ventas/pre-forma/pre-forma.component';
import { GestionPreformaComponent } from './componentes/administracion/ventas/pre-forma/gestion-preforma/gestion-preforma.component';
import { ConsultarPreformaComponent } from './componentes/administracion/ventas/pre-forma/consultar-preforma/consultar-preforma.component';
import { FormDailyVistaDocumentoComponent } from './componentes/shared/modal/form-daily-vista-documento/form-daily-vista-documento.component';
import { FormDailyVistaPreviaComponent } from './componentes/shared/modal/form-daily-vista-previa/form-daily-vista-previa.component';
import { FormParametrosAccionesComponent } from './componentes/shared/modal/form-parametros-acciones/form-parametros-acciones.component';
import { FormAccionesAccesosUsuariosComponent } from './componentes/shared/modal/form-acciones-accesos-usuarios/form-acciones-accesos-usuarios.component';
import { authService } from './componentes/shared/servicios/auth.service';
import { FormReporteDevolucionesComponent } from './componentes/shared/modal/form-reporte-devoluciones/form-reporte-devoluciones.component';
import { FormVersionComponent } from './componentes/shared/modal/form-version/form-version.component';
import { FormBackorderComponent } from './componentes/shared/modal/form-backorder/form-backorder.component';
import { FormReporteCuotasVsVentasComponent } from './componentes/shared/modal/form-reporte-cuotas-vs-ventas/form-reporte-cuotas-vs-ventas.component';
import { FormImportReporteCuotasVsVentasComponent } from './componentes/shared/modal/form-import-reporte-cuotas-vs-ventas/form-import-reporte-cuotas-vs-ventas.component';
import { FormReporteFacturacionD56Component } from './componentes/shared/modal/form-reporte-facturacion-d56/form-reporte-facturacion-d56.component';
import { FciComponent } from './componentes/fci/fci.component';
import { PedidosFciComponent } from './componentes/fci/pedidos-fci/pedidos-fci.component';
import { MkpModalShowProductosComponent } from './componentes/shared/modal/mkp-modal-show-productos/mkp-modal-show-productos.component';
import { FormGestionarCcUsuariosComponent } from './componentes/shared/modal/form-gestionar-cc-usuarios/form-gestionar-cc-usuarios.component';
import { FormUsuariosCentroCostoComponent } from './componentes/shared/modal/form-usuarios-centro-costo/form-usuarios-centro-costo.component';
import { PuntoporcomaPipe } from './componentes/shared/pipes/puntoporcoma.pipe';
import { FormDescargarMontoPartidasComponent } from './componentes/shared/modal/form-descargar-monto-partidas/form-descargar-monto-partidas.component';
import { FormReporteMovimientoCdtComponent } from './componentes/shared/modal/form-reporte-movimiento-cdt/form-reporte-movimiento-cdt.component';
import { FormParametrosCorreosComponent } from './componentes/shared/modal/form-parametros-correos/form-parametros-correos.component';
import { FormImportMasivoAsistenciaComponent } from './componentes/shared/modal/form-import-masivo-asistencia/form-import-masivo-asistencia.component';
import { FormDiferencialCambiarioComponent } from './componentes/shared/modal/form-diferencial-cambiario/form-diferencial-cambiario.component';
import { FormAccesosCorreosUsuariosComponent } from './componentes/shared/modal/form-accesos-correos-usuarios/form-accesos-correos-usuarios.component';
import { FormCrearOrNotasComponent } from './componentes/shared/modal/form-crear-or-notas/form-crear-or-notas.component';
import { FormCDevolverProdCotOcComponent } from './componentes/shared/modal/form-c-devolver-prod-cot-oc/form-c-devolver-prod-cot-oc.component';
import { PreOrdenesComprasComponent } from './componentes/administracion/compras/ordenes-compras/pre-ordenes-compras/pre-ordenes-compras.component';
import { AppUtilService } from './componentes/shared/servicios/app.util.service';
import { AtributosService } from './componentes/shared/servicios/atributos.service';
import { RolUsuariosComponent } from './componentes/sistemas/config/rol-usuarios/rol-usuarios.component';
import { FormMenuRolComponent } from './componentes/shared/modal/form-menu-rol/form-menu-rol.component';
import { FormDefinicionesAccesosComponent } from './componentes/shared/modal/form-definiciones-accesos/form-definiciones-accesos.component';
import { MediaServicesService } from './componentes/shared/servicios/media-services.service';
import { LicenciamientoControlComponent } from './componentes/sistemas/config/licenciamiento-control/licenciamiento-control.component';
import { FormLicenciaComponent } from './componentes/shared/modal/form-licencia/form-licencia.component';
import { FormParametrosLicenciaComponent } from './componentes/shared/modal/form-parametros-licencia/form-parametros-licencia.component';
import { PipeServicesPipe } from './pipes/pipe-services.pipe';
import { GestionarDocumentosComponent } from './componentes/sistemas/configurador/gestionar-documentos/gestionar-documentos.component';
import { FormDocumentosEmpresaComponent } from './componentes/shared/modal/form-documentos-empresa/form-documentos-empresa.component';
import { GestionEstructuraEmpresaComponent } from './componentes/sistemas/configurador/gestion-estructura-empresa/gestion-estructura-empresa.component';
import { FormEstructuraComponent } from './componentes/shared/modal/form-estructura/form-estructura.component';
import { FormRecuperacionComponent } from './componentes/shared/modal/form-recuperacion/form-recuperacion.component';
import { PosComponent } from './componentes/administracion/ventas/pos/pos.component';
import { GestionPosComponent } from './componentes/administracion/ventas/pos/gestion-pos/gestion-pos.component';
import { FormPosComponent } from './componentes/shared/modal/form-pos/form-pos.component';
import { GestionarAppComponent } from './componentes/sistemas/configurador/gestionar-app/gestionar-app.component';
import { FormAppEmpresaComponent } from './componentes/shared/modal/form-app-empresa/form-app-empresa.component';
import { SmtpUsuComponent } from './componentes/sistemas/config/smtp-usu/smtp-usu.component';
import { FormSmtpComponent } from './componentes/shared/modal/form-smtp/form-smtp.component';
import { FormGaleriaEmpresaComponent } from './componentes/shared/modal/form-galeria-empresa/form-galeria-empresa.component';
import { FormParametroFncComponent } from './componentes/shared/modal/form-parametro-fnc/form-parametro-fnc.component';
import { SplitPipePipe } from './componentes/shared/pipes/split-pipe.pipe';

export function loadCrucialData() {
  return function() {
    // or use UserService
    return delay(1000);
  }
}

export function delay(delay: number) {
  return function() {
    return new Promise(function(resolve) {
        setTimeout(resolve, delay);
    });
  }
}
@NgModule({
  entryComponents:[
    ModificarregistrosComponent
  ],
  declarations: [
    AppComponent,
    InicioComponent,
    FooterComponent,
    NavbarComponent,
    MercadeoComponent,
    EstadisticasComponent,
    PublicidadComponent,
    InventariosysComponent,
    UsuariosComponent,
    MensajeriaComponent,
    ListarregistrosComponent,
    ModificarregistrosComponent,
    PubliacercadeComponent,
    NgDropFilesDirective,
    PerfilesComponent,
    FormUsuariosComponent,
    FormmMsgComponent,
    FormPermisosComponent,
    MenuUsuComponent,
    FormMenuComponent,
    AcercadeComponent,
    RolesComponent,
    FormRolesComponent,
    FormRolmenuComponent,
    FormLoginComponent,
    FacturasPendientesComponent,
    ClientesPendientesComponent,
    GestionCobrosComponent,
    GestionClientesComponent,
    GestionarFacturasComponent,
    ProcesarCobrosComponent,
    ConfigComponent,
    UnidadesComponent,
    ComputadorasComponent,
    PerifericosComponent,
    FacturacionComponent,
    PedidosComponent,
    FacturasComponent,
    ConsultarFactComponent,
    FormUnidadComponent,
    GestionPedidosComponent,
    FormClientesComponent,
    FormDireccionesComponent,
    InventarioAComponent,
    FormPedidosComponent,
    ConsultarPedidosComponent,
    FormPedidosNComponent,
    FormFacturaComponent,
    ConfiguradorComponent,
    PreciosComponent,
    FormPreciosComponent,
    FormConsultaListaComponent,
    FormPreciosActualizarComponent,
    FormModifiPrecioComponent,
    GestionConsignacionesComponent,
    ConsignacionesComponent,
    ProductoFactoryComponent,
    OrdenesProduccionComponent,
    GestionOrdenesComponent,
    FormProductosComponent,
    FormFormulacionComponent,
    MpProductoFactoryComponent,
    FormMateriaPrimaComponent,
    GestionProveedoresComponent,
    FormProvComponent,
    InventarioMpComponent,
    InventarioPdComponent,
    FormInventarioMpComponent,
    FormInventarioPdComponent,
    InventarioPtComponent,
    FormOrdenesprodComponent,
    FormConsultaOrdenesComponent,
    FormConfirmacionInvMpComponent,
    MpProductoPreparadoComponent,
    FormMateriaPrimaPreparadaComponent,
    FormConsultarFormulaComponent,
    GestionOrdenesElaboracionComponent,
    SeguimientoOrdenesComponent,
    FormFormulacionMppComponent,
    GestionOrdenesMppComponent,
    FormOrdenesprodmppComponent,
    FormConfirmacionInvMppComponent,
    FormConsultarFormulaMppComponent,
    OrdenesProduccionMppComponent,
    GestionOrdenesElaboracionMppComponent,
    FormConsultaOrdenesMppComponent,
    SeguimientoOrdenesMppComponent,
    FormSeleccionComponent,
    FormFacturarComponent,
    FormFormulaUnidadComponent,
    FormFormulaUnidadMppComponent,
    FormComfirmFabricacionComponent,
    AuditoriaOrdProdComponent,
    OrdenesProdRevisionComponent,
    InventarioLtComponent,
    DespachoComponent,
    GuiaDespachoComponent,
    ConsultarOrdenesComponent,
    ConfirmarOrdenesComponent,
    EditarOrdenesComponent,
    CrearOrdenesComponent,
    ConsultarOrdenesMppComponent,
    FormConsultasOrdenesComponent,
    FormConfirmarFabricacionPtComponent,
    InventarioSgComponent,
    InventarioSplComponent,
    FormInvSplComponent,
    OrdenesProdMppRevisionComponent,
    FormRevisarOrdenesMppComponent,
    FormPedidosNBsComponent,
    FormPedidoFrecuencialComponent,
    FormTraladoInvComponent,
    FormKeyComponent,
    FormAjustarOrdComponent,
    FormRevisionOrdComponent,
    FormConsignacionesComponent,
    FormConfirmConsignacionComponent,
    ConsultarConsignacionesComponent,
    ConsultarGuiaDespachoComponent,
    FormInspeccionOrdComponent,
    FormNotaCreditoComponent,
    FormCantDevComponent,
    FormSpinnerComponent,
    FormNotaCreditoAdminComponent,
    EntradaInventarioComponent,
    MateriaPrimaComponent,
    CuarentenaComponent,
    MateriaPrimaEntradaComponent,
    FormEntradaMpComponent,
    OrdenesComprasComponent,
    GestionComprasComponent,
    FormOrdenesComprasComponent,
    CalidadProductoTerminadoComponent,
    ControlExcepcionesComponent,
    ConsultarNotasComponent,
    ConsultarNotasEntregaComponent,
    DevolucionesProductoComponent,
    FormClientesNuevoComponent,
    FormReportesComponent,
    ConsultarNotasDebitoComponent,
    FormNotaDebitoComponent,
    IntranetComponent,
    SoporteItComponent,
    FormSoporteComponent,
    SoporteConfigComponent,
    FormCrearTareasComponent,
    SistemasComponent,
    SoporteTecnicoAdminComponent,
    FormEvaluacionSoporteComponent,
    FormReportesVentasComponent,
    FormNotaCreditoAdminSingleComponent,
    FormNotaCreditoAdminBaseComponent,
    FormPermisosAccionesComponent,
    SesionesUsuariosComponent,
    FormOrdHistorialPtComponent,
    FormOrdHistorialMppComponent,
    EstatusOrdenesProdComponent,
    DefinicionesSistemaComponent,
    FormDefinicionesSistemaComponent,
    FormDefinicionesModulosComponent,
    DepositosComponent,
    FormCrearDepositoComponent,
    FormDepositosComponent,
    FormMovimientosProdComponent,
    FormFechasComponent,
    ComedorComponent,
    EmpleadosComponent,
    FormCrearQrEmpleadosComponent,
    FormListarEmpleadoComponent,
    AsistenciasComponent,
    EmpleadoComponent,
    FormPedidosUmComponent,
    FormPedidosNUmComponent,
    FormPedidosNBsUmComponent,
    FormTrasladosDepComponent,
    FormFechasEmpleadoComponent,
    ReportGenAsistenciaComponent,
    ReportMesEmpleadoComponent,
    DevolucionesComponent,
    DevolucionProductosComponent,
    ProductosDevueltosComponent,
    FormCantDevProductComponent,
    FormCantidadProductosDevueltosComponent,
    FormCrearDevolucionComponent,
    FormListarProducDevolucionComponent,
    FormPtDevolucionesComponent,
    DevolverFormulaProductoComponent,
    FormDetalleGuiaComponent,
    DevolucionCompletaComponent,
    FormCantidadCompletaDevolucionComponent,
    FormDevolverProductoCompletoComponent,
    FormCantDevProdReComponent,
    RedirecAppComponent,
    FormReportesNotasDebitoComponent,
    FormReportesVentasNotasCreditoComponent,
    FormReportesVentasNotasComponent,
    FormReportesConsignacionesComponent,
    FormEnviarReporteDailyComponent,
    FormCargarMontoDailyComponent,
    ReportesAppComponent,
    ConsultarReportesComponent,
    ReportesComponent,
    OrdenRequisicionComponent,
    CotizacionComponent,
    FormAprobarSolicitudCompraComponent,
    FormCantidadProductoReqCompraComponent,
    FormCuestionarioComprasComponent,
    FormOrdenReqComponent,
    FormCrearOcProductosxproveedorComponent,
    FormCrearProveedorComprasComponent,
    FormCrearOrdenCompraComponent,
    FormAprobarCotizacionComponent,
    FormCotizacionReqComponent,
    FormCrearCotizacionComponent,
    FormPrecioCotizarComponent,
    FormSelectTCotizacionComponent,
    OrdenesfrontComprasComponent,
    GestionCentroCostoComponent,
    FormCrearCentroCostosComponent,
    FormCrearCuentasComponent,
    FormImportarCentroDeCostosComponent,
    FormListarCuentasCreadasComponent,
    FormCargarMontoPartidasComponent,
    FormTransferirPartidasComponent,
    FormListarPartidasCreadasComponent,
    FormMovimientosCuentasComponent,
    FormMovimientosPartidasComponent,
    FormParametrosComponent,
    FormHistorialParametrosVentasComponent,
    FormCotizacionVerSolicitudesComponent,
    FormCotizacionVerCotizacionComponent,
    RecepcionComponent,
    GestionarRecepcionesComponent,
    FormRecepcionFacturaComponent,
    FormRecepcionModalComponent,
    FormRecepcionOrdCompraComponent,
    FormRecepcionVerDocumentoComponent,
    FormRecepcionVerProductosRecepcionComponent,
    FormRecepcionVerSegRecepcionComponent,
    TomaFisicaInevntarioComponent,
    CrearTomaFisicaComponent,
    NuevaTomaFisicaComponent,
    FormTomaFisicaCompletaComponent,
    FormCantidadTomaFisicaCompletaComponent,
    FormCrearTomaFisicaComponent,
    CalidadRecepcionComponent,
    RecepcionesRecibidasComponent,
    FormRecepcionVerLiberadasComponent,
    FormRecepcionVerRecepcionesRecibidasComponent,
    FormCalidadVerRecepcionLiberadaComponent,
    FormOrdenComprasVerCompletasComponent,
    FormRecepcionCantProductoCalidadComponent,
    FormRecepcionesVerCompletasComponent,
    FormNotacreditoNotasComponent,
    PreFormaComponent,
    GestionPreformaComponent,
    ConsultarPreformaComponent,
    FormDailyVistaDocumentoComponent,
    FormDailyVistaPreviaComponent,
    FormParametrosAccionesComponent,
    FormAccionesAccesosUsuariosComponent,
    FormReporteDevolucionesComponent,
    FormVersionComponent,
    FormBackorderComponent,
    FciComponent,
    PedidosFciComponent,
    MkpModalShowProductosComponent,
    FormReporteCuotasVsVentasComponent,
    FormImportReporteCuotasVsVentasComponent,
    FormReporteFacturacionD56Component,
    FormGestionarCcUsuariosComponent,
    FormUsuariosCentroCostoComponent,
    PuntoporcomaPipe,
    FormDescargarMontoPartidasComponent,
    FormReporteMovimientoCdtComponent,
    FormImportMasivoAsistenciaComponent,
    FormDiferencialCambiarioComponent,
    FormParametrosCorreosComponent,
    FormAccesosCorreosUsuariosComponent,
    FormCrearOrNotasComponent,
    FormCDevolverProdCotOcComponent,
    PreOrdenesComprasComponent,
    RolUsuariosComponent,
    FormMenuRolComponent,
    FormDefinicionesAccesosComponent,
    LicenciamientoControlComponent,
    FormLicenciaComponent,
    FormParametrosLicenciaComponent,
    PipeServicesPipe,
    GestionarDocumentosComponent,
    FormDocumentosEmpresaComponent,
    GestionEstructuraEmpresaComponent,
    FormEstructuraComponent,
    FormRecuperacionComponent,
    PosComponent,
    GestionPosComponent,
    FormPosComponent,
    GestionarAppComponent,
    FormAppEmpresaComponent,
    SmtpUsuComponent,
    FormSmtpComponent,
    FormGaleriaEmpresaComponent,
    FormParametroFncComponent,
    SplitPipePipe
    
  ],
  imports: [
    BrowserModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    APP_ROUTING,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MatTableModule,
    MatSelectModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatTreeModule,
    MatCheckboxModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatTooltipModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(maskConfig),
    LayoutModule,
    MatProgressSpinnerModule,
    ChartsModule,
    QRCodeModule,
    PortalModule,
    MatRadioModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatSidenavModule,

  ],
  exports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatTreeModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    LayoutModule,
    MatProgressSpinnerModule,
    PuntoporcomaPipe

  ],
  providers: [
    nkServices,
    AuthGuardService,
    NgxPermissionsGuard,
    ConnectionServices,
    HashKeyServices,
    PdfServices,
    authService,
    AppUtilService,
    AtributosService,
    MediaServicesService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: loadCrucialData()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
