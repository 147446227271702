import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-version',
  templateUrl: './form-version.component.html',
  styleUrls: ['./form-version.component.css']
})
export class FormVersionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
