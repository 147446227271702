 
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormFechasComponent } from '../../../shared/modal/form-fechas/form-fechas.component'
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormmMsgComponent } from 'src/app/componentes/shared/modal/formm-msg/formm-msg.component';

@Component({
  selector: 'app-comedor',
  templateUrl: './comedor.component.html',
  styleUrls: ['./comedor.component.css']
})
export class ComedorComponent implements OnInit {

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
    cod_menu: localStorage.getItem('sub_main'),
    cod_sesion: localStorage.getItem('cod_sesion'),
    dir_ip_client: localStorage.getItem('dir_ip'),
  sesion_unica:sessionStorage.getItem('sesion_unica')};;

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }
/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this. mostrarTabla()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  ///////////////////////////////FUNCIONES DE ESTRUCTURA//////////////////////////////////////////

  mostrarTabla(){
    this.displayedColumns = [
                            'cod_usuario',
                            'des_usuario',
                            'unidad',
                            'horario',
                            'fecha',
                            'hora',
                            'estatus',
                            'dt_ubicacion',
                            'descuento'
                            ];
     /* this.dataSource = this.ELEMENT_DATA; */
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
}

  listarDatacomedor(datosForm: any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.fechas = datosForm
        //Se genera la peticion Http
        this.servicio.listarComedor(datosForm).subscribe(
          response => {
            if (response) {
              console.log(response,'fechas del rango');
              this.dialogSpin.close();
              //Se guardan los datos en una variable
              for (const i in response.result) {
                
                this.datos.push({
                  cod_usuario: response.result[i].cod_usuario.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                  des_usuario: response.result[i].des_usuario,
                  unidad: response.result[i].unidad,
                  horario: response.result[i].horario,
                  fecha: response.result[i].fecha,
                  hora: response.result[i].hora,
                  estatus: response.result[i].estatus,
                  dt_ubicacion: response.result[i].dt_ubicacion,
                  descuento: response.result[i].descuento,
                })
                
              }
              //Se declara la cabecera de la tabla
              this.displayedColumns = [
                'cod_usuario',
                'des_usuario',
                'unidad',
                'horario',
                'fecha',
                'hora',
                'estatus',
                'dt_ubicacion',
                'descuento'
            ];
              //se pasan los datos de la tabla
              this.dataSource = new MatTableDataSource<any>(this.datos);
              //genera la config del paginador
              this.dataSource.paginator = this.paginator;
            }
          },
          error => {
            this.dialogSpin.close();
            console.log(error);
            this.displayedColumns = [
              'cod_usuario',
              'des_usuario',
              'unidad',
              'horario',
              'fecha',
              'hora',
              'estatus',
              'dt_ubicacion',
              'descuento'
          ];
            this.dataSource = new MatTableDataSource<any>(this.datos);
            this.dataSource.paginator = this.paginator;
            
          })
      }, 1500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }
  buscarDatacomedor(obj:any, text:any){

    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
      console.log(obj, 'segundo');
      console.log(this.xobj3,'UNIDADES');
      if (text == 'listar') {
        this.accion = text;
         dialogRef = this.dialog.open(FormFechasComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: {fecha_inicio:obj.fecha_inicio,
                 fecha_fin:obj.fecha_fin,
                 accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
        });
      }

      dialogRef.afterClosed().subscribe(result => {
        if(result !== 'cerrar' && result !== 'salir'){      
                this.listar_data_comedor(result);
        }
      });
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }
  
  //Peticion HTTP al Backend
  listar_data_comedor(datosForm: any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.fechas = datosForm
        //Se genera la peticion Http
        this.servicio.listarComedor(datosForm).subscribe(
          response => {
            if (response) {
              console.log(response,'fechas del rango');
              this.dialogSpin.close();
              //Se guardan los datos en una variable
              for (const i in response.result) {
                
                this.datos.push({
                  cod_usuario: response.result[i].cod_usuario.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                  des_usuario: response.result[i].des_usuario,
                  unidad: response.result[i].unidad,
                  horario: response.result[i].horario,
                  fecha: response.result[i].fecha,
                  hora: response.result[i].hora,
                  estatus: response.result[i].estatus,
                  dt_ubicacion: response.result[i].dt_ubicacion,
                  descuento: response.result[i].descuento,
                })
                
              }
              //Se declara la cabecera de la tabla
              this.displayedColumns = [
                'cod_usuario',
                'des_usuario',
                'unidad',
                'horario',
                'fecha',
                'hora',
                'estatus',
                'dt_ubicacion',
                'descuento'
            ];
              //se pasan los datos de la tabla
              this.dataSource = new MatTableDataSource<any>(this.datos);
              //genera la config del paginador
              this.dataSource.paginator = this.paginator;
            }
          },
          error => {
            this.dialogSpin.close();
            console.log(error);
            this.displayedColumns = [
              'cod_usuario',
              'des_usuario',
              'unidad',
              'horario',
              'fecha',
              'hora',
              'estatus',
              'dt_ubicacion',
              'descuento'
          ];
            this.dataSource = new MatTableDataSource<any>(this.datos);
            this.dataSource.paginator = this.paginator;
            this.snackBar.open(error.error.message, 'Cerrar', {
              duration: 15000, horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            
          })
      }, 1500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion(): void {
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    } catch (error) {
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }

  descargarDatacomedor(obj:any, text:any){

    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
      console.log(obj, 'segundo');
      console.log(this.xobj3,'UNIDADES');
      if (text == 'listar') {
        this.accion = text;
         dialogRef = this.dialog.open(FormFechasComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: {fecha_inicio:obj.fecha_inicio,
                 fecha_fin:obj.fecha_fin,
                 accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
        });
      }

      dialogRef.afterClosed().subscribe(result => {
        if(result !== 'cerrar' && result !== 'salir'){      
                this.descargar_data_comedor(result);
        }
      });
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }

 descargar_data_comedor(datosForm: any){
  try {
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });
    setTimeout(() => {
      this.fechas = datosForm
      //Se genera la peticion Http
      this.servicio.listarComedor(datosForm).subscribe(
        response => {
          if (response) {
            console.log(response,'fechas del rango');
            this.dialogSpin.close();
            //Se guardan los datos en una variable
            for (const i in response.result) {
              
              this.datos.push({
                cod_usuario: response.result[i].cod_usuario.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                des_usuario: response.result[i].des_usuario,
                unidad: response.result[i].unidad,
                horario: response.result[i].horario,
                fecha: response.result[i].fecha,
                hora: response.result[i].hora,
                estatus: response.result[i].estatus,
                dt_ubicacion: response.result[i].dt_ubicacion,
                descuento: response.result[i].descuento,
              })
              
            }
            this.descarga_xlsx(this.datos);
          }
        },
        error => {
          this.dialogSpin.close();
          console.log(error);
          this.displayedColumns = [
              'cod_usuario',
              'des_usuario',
              'unidad',
              'horario',
              'fecha',
              'hora',
              'estatus',
              'dt_ubicacion',
              'descuento'
          ];
          this.dataSource = new MatTableDataSource<any>(this.datos);
          this.dataSource.paginator = this.paginator;
          this.snackBar.open(error.error.message, 'Cerrar', {
            duration: 15000, horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          
        })
    }, 1500);
  } catch (error) {
    this.dialogSpin.close();
    this.snackBar.open(error, 'Cerrar', {
      duration: 1500, horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    })
  }
}
  //Descargando el excelt//
  descarga_xlsx(xdatos: any) {
      console.log(this.fechas);
    if (xdatos.length !== 0) {

      try {

        //Crea una hoja de excelt
        let workbook = new Workbook();
        //Nombre de la Hoja
        let worksheet = workbook.addWorksheet("Beneficio de comedor al personal, registros",);
        //Nombres de las filas
        let header = [
          'Codigo',
          'Nombre',
          'Departamento',
          'Horario',
          'Fecha',
          'Hora',
          'Estatus',
          'Ubicacion',
          'Descuento'
        ];
        //Añadiendo las filas

        worksheet.addRow('');
        worksheet.addRow('');
        worksheet.addRow('');

        worksheet.addRow(['FACTORY COSMETICS 3DG, C.A']);
        worksheet.addRow(['R.I.F.: J-41031797-3']);

        worksheet.addRow('');
        worksheet.addRow('');
        worksheet.addRow('');

        worksheet.addRow(header);
 
        //Iterando los datos para imprimir la tabla
        for (let x1 of xdatos) {
          let x2 = Object.keys(x1);
          let temp = []
          for (let y of x2) {
            temp.push(x1[y])
          }
          worksheet.addRow(temp)
        }

        //Nombre del archivo
        let fname = 'Beneficio de comedor al personal, registros"' ;

        //Descargar archivo Excelt
        workbook.xlsx.writeBuffer()
          .then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, fname + '-' + new Date().getDate() + '.xlsx');
          });

      } catch (error) {
        console.log(error);
        this.snackBar.open(error, 'Cerrar', {
          duration: 10000, horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        })
      }
    }else{
      this.snackBar.open('No existen datos en la tabla.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }

  /* cargarTabla(){
    try {
  this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
    width: '15%',
    height:'25%',
    data: {
            accion:this.accion},
    disableClose: true,
    panelClass: 'spinner',
    backdropClass: "bdrop"
  });
  setTimeout(() => {
    this.displayedColumns = [
      'cod_usuario',
      'des_usuario',
      'unidad',
      'horario',
      'fecha',
      'estatus',
      'dt_ubicacion',
      'descuento' ];

    this.dataSource = new MatTableDataSource<any>(this.datos);
    this.dataSource.paginator = this.paginator;
    this.dialogSpin.close();
  }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  } */
}
