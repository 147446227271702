import { Injectable } from '@angular/core';
import { Router,
        ActivatedRouteSnapshot,
        RouterStateSnapshot,
        CanActivate
    }  from "@angular/router";
    import {
      MatSnackBar,
      MatSnackBarHorizontalPosition,
      MatSnackBarVerticalPosition,
    } from '@angular/material/snack-bar';
import { result } from 'lodash';
import { nkServices } from '../servicios/app.nkservicios.services';
import { AppComponent } from '../../../app.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { HashKeyServices } from './app.hashkey.services';
import { AppUtilService } from './app.util.service';
import { ToastNotificationService } from './toast-notification.service';
import { AtributosService } from './atributos.service';
@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private postrest:HttpClient,
    private snackBar: MatSnackBar,
    private router:Router,
    private servicio:nkServices,
    private appcomponent:AppComponent,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ) { }
  
  ruta:any;
  sesion:any;
  rlsr:any;
creed:any;
cod_sesion:any;
dir_ip:any;
sesion_unica:any;
rol:any;
result:any;
key:boolean;
key_menu:any = 'sub_main';
resultado:any;
horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  async canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot ){
    try {
            var body;
            const permiso = route.data['permiso'];
            const menu = route.data['menu'];
        body = {
          cod_usuario:await this.hashkey.decrypt(await this.atrb.COD_PERFIL.cod_perfil),
          des_menu:menu
        };
      
        await this.servicio.consultar_sesionhttp(null).toPromise();
        this.ruta = await this.servicio.buscarUsuariorolguardhttp(body).toPromise();
          if( parseInt(this.ruta[0].cod_rol)  === parseInt(permiso) && menu === this.ruta[0].des_menu.toLowerCase()){
            let per = true;
            localStorage.setItem(permiso,'true');
            localStorage.setItem(this.key_menu,this.ruta[0].cod_menu);
            console.log("Paso el guard");
            this.result = true;
            return this.result;
          }else{
              await this.notifi.customToastNotification('No tienes permisos para acceder a este modulo');
              this.result = false;
              return this.result;
          }   
    } catch (error) {
        if (error.error.resul.toLowerCase() === 'version') {
          await this.util.version_incorrecta();
        }
        if(error.error.resul.toLowerCase() === 'licencia'){
          await this.util.abrirSpinner();
          await this.notifi.customToastNotification(error.error.message);
          setTimeout(async () => {
            await this.util.cerrarSpinner();
            location.reload();
          }, 15000);
        }
        if(error.error.resul.toLowerCase() === 'rol'){
          await this.util.abrirSpinner();
          await this.notifi.customToastNotification(error.error.message);
          setTimeout(async () => {
            await this.util.cerrarSpinner();
            location.reload();
          }, 15000);
        }
        if(error.error.resul.toLowerCase() === 'sesion'){
          await this.util.abrirSpinner();
          await this.notifi.customToastNotification(error.error.message);
          setTimeout(async () => {
            await this.util.cerrarSpinner();
            location.reload();
          }, 15000);
        }
        if(error.error.resul.toLowerCase() === 'unica'){
          await this.util.abrirSpinner();
          await this.notifi.customToastNotification(error.error.message);
          setTimeout(async () => {
            await this.util.cerrarSpinner();
            location.reload();
          }, 15000);
        }
    }
  }
}
