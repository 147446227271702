import { Injectable } from '@angular/core';
import { forIn, reduce } from 'lodash';
import { Columns, Img, ITable, PdfMakeWrapper, Table } from 'pdfmake-wrapper';
import * as pdf from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import { AtributosService } from './atributos.service';
import { HashKeyServices } from './app.hashkey.services';
import { AppUtilService } from './app.util.service';
import { nkServices } from './app.nkservicios.services';
import { environment } from 'src/environments/environment';
import { ToastNotificationService } from './toast-notification.service';
(<any>pdf).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfServices {
private igtf:any = '3,00';
private igtf_monto:any;
private igtf_monto_total:any;

private titulo:any = null;
private direccion:any = null;
private tel_1:any = null;
private tel_2:any = null;
private tel_3:any = null;
private correo:any = null;
private web:any = null;
private rif:any = null;
private postal:any = null;
private  texto:any =
                  'Calle Bolivar con Calle Regulo Franquiz Local S/N,\n\
                     Sector Pueblo Arriba. Guarenas, Miranda.\n\
                     Zona Postal 1220. Telf.: (0212) 519.43.98 / 365.46.24 / 714.03.42 /425.2.83\n\
                    E-mail:contacto@factorycosmetics.com.ve / www.factorycosmetics.com.ve \n\
                                  RIF.: J-41031797-3';
private mensaje_1:any ='';
private mensaje_2:any ='';
private mensaje_3:any ='';
private mensaje_3_2:any = '';
private mensaje_4:any = (igtf, igtf_monto, igtf_monto_total)=>{
    return 'Si la factura es cancelada en divisas en efectivo trenda un recargo de '+igtf+' % por concepto IGTF./\
            IGTF: '+igtf_monto+' $'+' TOTAL A PAGAR CON IGTF: '+igtf_monto_total+' $';
 }
 private mensaje_4_variable:any;
private factura:any = 'Factura N°: ';
private nota_entrega:any  = 'Nota de entrega N°: ';
private nota_credito:any  = 'Nota de credito N°: ';
private nota_debito:any  = 'Nota de debito N°: ';
private pro_forma:any  = 'Pro-Forma N°: ';
private prepuesto:any  = 'Presupuesto N°: ';
private guia_almacen:any  = 'Guia de almacen N°: ';
private back_order:any  = 'Backorder N°: ';
private documento:any;
private numero_documento:any;
private numero_documento_2:any;
private numero_control:any;
private path_0:any;
private path_1:any;
private path_2:any;
private path_3:any;
private idea_hp_de_maduro = 1000000;
private resta_base_imp:any;
private base_imponible:any;
private base_factura:any;
private formato_base_impnible:any;
private factura_base:any;
private factura_base_divisa:any;
private formato_factura_base_divisa:any;
private factura_base_imponible_divisa:any;
private formato_factura_base_imponible_divisa:any;
private subtotal_divisa:any;
private formato_subtotal_divisa:any;
private iva_monto_divisa:any;
private formato_iva_monto_divisa:any;
private total_divisa:any;
private formato_total_divisa:any;
private des_divisa:any;
private formato_des_divisa:any;
private excento_divisa:any;
private formato_excento_divisa:any;
private b_fact:any;
private formato_b_fact:any;
private b_imp:any;
private formato_b_imp:any;
private r_excento:any;
private formato_r_excento:any;
private r_desc_pedido:any;
private formato_r_desc_pedido:any;
private r_subtotal_costo_pedido:any;
private formato_r_subtotal_costo_pedido:any;
private r_iva_monto:any;
private formato_r_iva_monto:any;
private r_total_costo_pedido:any;
private formato_r_total_costo_pedido:any;
private d:any = 'd';
private dd:any = 'dd';
private fecha_documento:any;
private fecha_documento_v:any;
private tipo_pedido:any
constructor(
    private servicio:nkServices,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private notifi:ToastNotificationService,
){}
  ////////////////////////////VENTAS////////////////////////////////////////////
   public async pdfVentas(response:any,termino:any){
       try {
            this.titulo = '';
            this.direccion = '';
            this.tel_1 = '';
            this.tel_2 = '';
            this.tel_3 = '';
            this.correo = '';
            this.web = '';
            this.rif = '';
            this.postal = '';
            this.mensaje_1 = '';
            this.mensaje_2 ='';
            this.mensaje_3 ='';
            this.mensaje_3_2 = '';
            this.path_0 = await this.atrb.IMAGENES_FIJAS.sin_img;
            this.path_1 = await this.atrb.IMAGENES_FIJAS.sin_img;
            this.path_2 = await this.atrb.IMAGENES_FIJAS.sin_img;
            this.path_3 = await this.atrb.IMAGENES_FIJAS.sin_img;
            let empresa = {
                cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
            }
        const documentos = await this.servicio.listarDocumentoshttp(empresa).toPromise();
        if (response.pdf2[0].fecha_v_factura === '' || response.pdf2[0].fecha_v_factura === undefined || response.pdf2[0].fecha_v_factura === null) {
            this.fecha_documento_v = response.pdf2[0].fecha_inicio;
        }else{
            this.fecha_documento_v = response.pdf2[0].fecha_v_factura;
        }
        const fontsSize = 9
        console.log(response);
        this.tipo_pedido = response.pdf4[0].tipo_pedido;
        if (response.pdf4[0].tipo_pedido.toUpperCase() === 'DEV' ||
         response.pdf4[0].tipo_pedido.toUpperCase() === 'ADMIN' ||
          response.pdf4[0].tipo_pedido.toUpperCase() === 'AD-SN' ||
           response.pdf4[0].tipo_pedido.toUpperCase() === 'NTNTRG' || 
           response.pdf4[0].tipo_pedido.toUpperCase() === 'ADMINNT' ||
           response.pdf4[0].tipo_pedido.toUpperCase() === 'NDB') {
            this.fecha_documento = response.fecha[0].fecha_inicio;
        }else{
            this.fecha_documento = response.pdf2[0].fecha_inicio;
        }
        this.calculos(response, this.fecha_documento);
        var tablaEnLista = this.generarTablaVentas(response.pdf, response.pdf2);
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'F' || response.pdf4[0].tipo_pedido.toUpperCase() === 'PFF') {
            let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.FACTURA.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            console.log(doc,'parametro_documento');
            let empresa_parametros = {
                cod_documento: doc,
                cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(empresa_parametros).toPromise();
             
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC) {
                                this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO) {
                                this.path_0 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG) {
                                this.direccion = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1) {
                                this.tel_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2) {
                                this.tel_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3) {
                                this.tel_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC) {
                                this.correo = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC) {
                                this.web = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC) {
                                this.rif = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC) {
                                this.postal = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1) {
                                this.mensaje_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2) {
                                this.mensaje_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3) {
                                this.mensaje_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4) {
                                this.mensaje_3_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE) {
                            this.path_3 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR) {
                            this.path_2 = environment.path_storage+parametro_documento[i].val_def;
                        }
                    }
                        /* this.mensaje_1 ='Factura expresada en USD, conforme a las Normativas Vigentes.'; */
                        /* this.mensaje_2 ='El monto en USD equivale a la tasa del BCV del dia.'; */
                        /* this.mensaje_3 ='Todo ajuste generado por aplicación de una tasa de conversión diferente, '; */
                        /* this.mensaje_3_2 = 'al momento del pago se emitira la respectiva Nota de Debito o Credito según sea el caso'; */
                        this.mensaje_4_variable = this.mensaje_4(this.igtf,this.igtf_monto,this.igtf_monto_total);
                        this.numero_documento = response.pdf2[0].cod_factura;
                        this.numero_control = response.pdf5[0].cod_control;
                        this.documento = this.factura ;
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'PFN') {

            let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.NOTA_PRO_FORMA.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            console.log(doc,'parametro_documento');
            var valor = {
                cod_documento: doc,
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
             
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC) {
                            this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO) {
                                this.path_0 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG) {
                                this.direccion = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1) {
                                this.tel_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2) {
                                this.tel_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3) {
                                this.tel_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC) {
                                this.correo = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC) {
                                this.web = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC) {
                                this.rif = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC) {
                                this.postal = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1) {
                                this.mensaje_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2) {
                                this.mensaje_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3) {
                                this.mensaje_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4) {
                                this.mensaje_3_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE) {
                            this.path_3 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR) {
                            this.path_2 = environment.path_storage+parametro_documento[i].val_def;
                        }
                    }
                        /* this.mensaje_1 ='Factura expresada en USD, conforme a las Normativas Vigentes.'; */
                        /* this.mensaje_2 ='El monto en USD equivale a la tasa del BCV del dia.'; */
                        /* this.mensaje_3 ='Todo ajuste generado por aplicación de una tasa de conversión diferente, '; */
                        /* this.mensaje_3_2 = 'al momento del pago se emitira la respectiva Nota de Debito o Credito según sea el caso'; */
                        this.mensaje_4_variable = this.mensaje_4(this.igtf,this.igtf_monto,this.igtf_monto_total);
                        this.numero_documento = response.pdf2[0].cod_nota;
                        this.numero_control = '00-000000';
                        this.documento = this.pro_forma ;
                        
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'ND') {
            let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.NOTA_ENTREGA_DIVISA.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            console.log(doc,'parametro_documento');
            var valor = {
                cod_documento: doc,
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
             
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC) {
                            this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO) {
                                this.path_0 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG) {
                                this.direccion = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1) {
                                this.tel_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2) {
                                this.tel_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3) {
                                this.tel_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC) {
                                this.correo = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC) {
                                this.web = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC) {
                                this.rif = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC) {
                                this.postal = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1) {
                                this.mensaje_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2) {
                                this.mensaje_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3) {
                                this.mensaje_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4) {
                                this.mensaje_3_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE) {
                            this.path_3 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR) {
                            this.path_2 = environment.path_storage+parametro_documento[i].val_def;
                        }
                    }
                        /* this.mensaje_1 ='Factura expresada en USD, conforme a las Normativas Vigentes.'; */
                        /* this.mensaje_2 ='El monto en USD equivale a la tasa del BCV del dia.'; */
                        /* this.mensaje_3 ='Todo ajuste generado por aplicación de una tasa de conversión diferente, '; */
                        /* this.mensaje_3_2 = 'al momento del pago se emitira la respectiva Nota de Debito o Credito según sea el caso'; */
                        this.mensaje_4_variable = '';
                        this.numero_documento = response.pdf2[0].cod_nota;
                        this.numero_control = '00-000000';
                        this.documento = this.nota_entrega;
                        
                        /* this.mensaje_1 ='UNICAS CUENTAS AUTORIZADAS PARA EL PAGO:';
                        this.mensaje_2 ='DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.';
                        this.mensaje_3 ='BANK OF AMERICA: 898110972780  BANESCO PANAMA: 110000075677  ZELLE: FACTORY COSMETICS INTERNATIONAL CORP.';
                        this.mensaje_3_2 = ''; */

                        this.formato_subtotal_divisa = response.pdf4[0].subtotal_costo_pedido;
                        this.formato_des_divisa = response.pdf4[0].desc_pedido_monto;
                        this.formato_factura_base_divisa = this.factura_base;
                        this.formato_total_divisa = this.factura_base;

                        this.formato_r_subtotal_costo_pedido = '0,00';
                        this.formato_r_desc_pedido = '0,00';
                        this.formato_b_fact = '0,00';
                        this.formato_r_excento = '0,00';
                        this.formato_b_imp = '0,00';
                        this.formato_r_iva_monto = '0,00';
                        this.formato_r_total_costo_pedido = '0,00';

                        this.formato_factura_base_imponible_divisa = '0,00';
                        this.formato_iva_monto_divisa = '0,00';
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'NB') {
            let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.NOTA_ENTREGA.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            console.log(doc,'parametro_documento');
            var valor = {
                cod_documento: doc,
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
             
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC) {
                            this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO) {
                                this.path_0 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG) {
                                this.direccion = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1) {
                                this.tel_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2) {
                                this.tel_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3) {
                                this.tel_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC) {
                                this.correo = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC) {
                                this.web = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC) {
                                this.rif = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC) {
                                this.postal = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1) {
                                this.mensaje_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2) {
                                this.mensaje_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3) {
                                this.mensaje_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4) {
                                this.mensaje_3_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE) {
                            this.path_3 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR) {
                            this.path_2 = environment.path_storage+parametro_documento[i].val_def;
                        }
                    }
            /* this.mensaje_1 ='TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A.';
            this.mensaje_2 ='DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.';
            this.mensaje_3 ='BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064  BANCAMIGA: 0172-0194-8419-4444-9550';
            this.mensaje_3_2 = '';
            this.mensaje_4_variable = ''; */
            this.numero_documento = response.pdf2[0].cod_nota;
            this.numero_control = '00-000000';
            this.documento = this.nota_entrega;

            this.formato_base_impnible = '0,00';
            this.formato_factura_base_imponible_divisa = '0,00';
            this.formato_iva_monto_divisa = '0,00';
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'PSF') {
            let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.PRESUPUESTO_FACTURA.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            console.log(doc,'parametro_documento');
            let empresa_parametros = {
                cod_documento: doc,
                cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(empresa_parametros).toPromise();
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC) {
                            this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO) {
                                this.path_0 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG) {
                                this.direccion = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1) {
                                this.tel_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2) {
                                this.tel_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3) {
                                this.tel_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC) {
                                this.correo = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC) {
                                this.web = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC) {
                                this.rif = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC) {
                                this.postal = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1) {
                                this.mensaje_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2) {
                                this.mensaje_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3) {
                                this.mensaje_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4) {
                                this.mensaje_3_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE) {
                            this.path_3 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR) {
                            this.path_2 = environment.path_storage+parametro_documento[i].val_def;
                        }
                    }
            /* this.mensaje_1 ='Factura expresada en USD, conforme a las Normativas Vigentes.';
            this.mensaje_2 ='El monto en USD equivale a la tasa del BCV del dia.';
            this.mensaje_3 ='Todo ajuste generado por aplicación de una tasa de conversión diferente, ';
            this.mensaje_3_2 = 'al momento del pago se emitira la respectiva Nota de Debito o Credito según sea el caso'; */
            this.mensaje_4_variable = this.mensaje_4(this.igtf,this.igtf_monto,this.igtf_monto_total);
            this.numero_documento = response.pdf2[0].cod_pedido;
            this.numero_control = '00-000000';
            this.documento = this.prepuesto ;
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'PSPF') {
            let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.PRESUPUESTO_PRO_FORMA.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            console.log(doc,'parametro_documento');
            var valor = {
                cod_documento: doc,
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC) {
                                this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO) {
                                this.path_0 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG) {
                                this.direccion = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1) {
                                this.tel_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2) {
                                this.tel_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3) {
                                this.tel_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC) {
                                this.correo = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC) {
                                this.web = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC) {
                                this.rif = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC) {
                                this.postal = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1) {
                                this.mensaje_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2) {
                                this.mensaje_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3) {
                                this.mensaje_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4) {
                                this.mensaje_3_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE) {
                            this.path_3 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR) {
                            this.path_2 = environment.path_storage+parametro_documento[i].val_def;
                        }
                    }
            /* this.mensaje_1 ='Factura expresada en USD, conforme a las Normativas Vigentes.';
            this.mensaje_2 ='El monto en USD equivale a la tasa del BCV del dia.';
            this.mensaje_3 ='Todo ajuste generado por aplicación de una tasa de conversión diferente, ';
            this.mensaje_3_2 = 'al momento del pago se emitira la respectiva Nota de Debito o Credito según sea el caso'; */
            this.mensaje_4_variable = this.mensaje_4(this.igtf,this.igtf_monto,this.igtf_monto_total);
            this.numero_documento = response.pdf2[0].cod_pedido;
            this.numero_control = '00-000000';
            this.documento = this.prepuesto ;
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'PSND') {
            let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.PRESUPUESTO_NOTA_DIVISA.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            console.log(doc,'parametro_documento');
            var valor = {
                cod_documento: doc,
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC) {
                            this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO) {
                                this.path_0 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG) {
                                this.direccion = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1) {
                                this.tel_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2) {
                                this.tel_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3) {
                                this.tel_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC) {
                                this.correo = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC) {
                                this.web = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC) {
                                this.rif = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC) {
                                this.postal = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1) {
                                this.mensaje_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2) {
                                this.mensaje_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3) {
                                this.mensaje_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4) {
                                this.mensaje_3_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE) {
                            this.path_3 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR) {
                            this.path_2 = environment.path_storage+parametro_documento[i].val_def;
                        }
                    }
            /* this.mensaje_1 ='UNIICAS CUENTAS AUTORIZADAS PARA EL PAGO:';
            this.mensaje_2 ='DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.';
            this.mensaje_3 ='BANK OF AMERICA: 898110972780  BANESCO PANAMA: 110000075677  ZELLE: FACTORY COSMETICS INTERNATIONAL CORP.';
            this.mensaje_3_2 = ''; */
            this.mensaje_4_variable = '';
            this.numero_documento = response.pdf2[0].cod_pedido;
            this.numero_control = '00-000000';
            this.documento = this.prepuesto ;

            this.formato_subtotal_divisa = response.pdf4[0].subtotal_costo_pedido;
            this.formato_des_divisa = response.pdf4[0].desc_pedido_monto;
            this.formato_factura_base_divisa = this.factura_base;
            this.formato_total_divisa = response.pdf4[0].total_costo_pedido;

            this.formato_r_subtotal_costo_pedido = '0,00';
            this.formato_r_desc_pedido = '0,00';
            this.formato_b_fact = '0,00';
            this.formato_r_excento = '0,00';
            this.formato_b_imp = '0,00';
            this.formato_r_iva_monto = '0,00';
            this.formato_r_total_costo_pedido = '0,00';

            this.formato_factura_base_imponible_divisa = '0,00';
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'PSNB') {
            let doc ;
            for (const i in documentos) {
                if (documentos[i].des_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.PRESUPUESTO_NOTA.toUpperCase()) {
                    doc = documentos[i].cod_documento;
                    break;
                }
            }
            console.log(doc,'parametro_documento');
            var valor = {
                cod_documento: doc,
                cod_empresa: await this.atrb.COD_EMPRESA.cod_empresa
              };
             const parametro_documento = await  this.servicio.listarParametroDocumentoshttp(valor).toPromise();
                    for (const i in parametro_documento) {
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DES_DOC) {
                            this.titulo = parametro_documento[i].val_def ? parametro_documento[i].val_def.toUpperCase() : '';
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_LOGO) {
                                this.path_0 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.DIR_LOG) {
                                this.direccion = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_1) {
                                this.tel_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_2) {
                                this.tel_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.TEL_3) {
                                this.tel_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MAIL_DOC) {
                                this.correo = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.WEB_DOC) {
                                this.web = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.RIF_DOC) {
                                this.rif = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.POSTAL_DOC) {
                                this.postal = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_1) {
                                this.mensaje_1 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_2) {
                                this.mensaje_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_3) {
                                this.mensaje_3 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.MSG_4) {
                                this.mensaje_3_2 = parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_PIE) {
                            this.path_3 = environment.path_storage+parametro_documento[i].val_def;
                        }
                        if (parametro_documento[i].constante_documento.toUpperCase() === await this.atrb.CONSTANTES_DOCUMENTOS.IMG_BAR) {
                            this.path_2 = environment.path_storage+parametro_documento[i].val_def;
                        }
                    }
           /*  this.mensaje_1 ='TRANSFERIR A NOMBRE DE FACTORY COSMETICS 3DG, C.A.';
            this.mensaje_2 ='DESPUES DE 48 HORAS EL CLIENTE ES RESPONSABLE POR LA MERCANCIA.';
            this.mensaje_3 ='BANPLUS: 0174-0101-7810-1426-2107  BANESCO: 0134-1085-2600-0100-4064  BANCAMIGA: 0172-0194-8419-4444-9550';
            this.mensaje_3_2 = ''; */
            this.mensaje_4_variable = '';
            this.numero_documento = response.pdf2[0].cod_pedido;
            this.numero_control = '00-000000';
            this.documento = this.prepuesto ;

            this.formato_base_impnible = '0,00';
            this.formato_factura_base_imponible_divisa = '0,00';
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'DEV') {
            this.mensaje_1 = 'AFECTA LA FACTURA Nº: '+response.pdf2[0].cod_factura;
            this.mensaje_2 ='CODIGO DE CONTROL FACTURA Nº: '+response.pdf5[0].cod_control;
            this.mensaje_3 ='FECHA DE LA FACTURA: '+response.fecha[0].fecha_inicio;
            this.mensaje_3_2 = '';
            this.mensaje_4_variable = '';
            this.numero_documento = response.pdf2[0].cod_nota_credito;
            this.numero_control = response.pdf6[0].cod_control;
            this.documento = this.nota_credito;
            
            response.pdf6[0].cod_lista_precio_producto = response.pdf7[0].cod_lista_precio_producto;
            response.pdf6[0].tasa_precio = response.pdf7[0].tasa_precio;

            if (termino.toUpperCase() === this.dd.toUpperCase()) {
                this.path_0 = "../../../assets/img/logo_hd.png";
                this.path_1 = "../../../assets/img/FC.png";
                this.path_2 = "../../../assets/img/bar.png";
                this.path_3 = "../../../assets/img/footer_2.png"
            }
            
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'ADMIN') {
            this.mensaje_1 = 'AFECTA LA FACTURA Nº: '+response.pdf2[0].cod_factura;
            this.mensaje_2 ='CODIGO DE CONTROL FACTURA Nº: '+response.pdf5[0].cod_control;
            this.mensaje_3 ='FECHA DE LA FACTURA: '+response.fecha[0].fecha_inicio;
            this.mensaje_3_2 = '';
            this.mensaje_4_variable = '';
            this.numero_documento = response.pdf2[0].cod_nota_credito;
            this.numero_control = response.pdf6[0].cod_control;
            this.documento = this.nota_credito;
            
            response.pdf6[0].cod_lista_precio_producto ='N/A';
            response.pdf6[0].tasa_precio = response.pdf7[0].tasa_precio;

            if (termino.toUpperCase() === this.dd.toUpperCase()) {
                this.path_0 = "../../../assets/img/logo_hd.png";
                this.path_1 = "../../../assets/img/FC.png";
                this.path_2 = "../../../assets/img/bar.png";
                this.path_3 = "../../../assets/img/footer_2.png"
            }
            
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'NDB') {
            this.mensaje_1 = 'AFECTA LA FACTURA Nº: '+response.pdf2[0].cod_factura;
            this.mensaje_2 ='CODIGO DE CONTROL FACTURA Nº: '+response.pdf6[0].cod_control;
            this.mensaje_3 ='FECHA DE LA FACTURA: '+response.pdf2[0].fecha_inicio;
            this.mensaje_3_2 = '';
            this.mensaje_4_variable = '';
            this.numero_documento = response.pdf2[0].cod_nota_debito;
            this.numero_control = response.pdf6[0].cod_control;
            this.documento = this.nota_debito;
            
            response.pdf6[0].cod_lista_precio_producto ='N/A';
            /* response.pdf6[0].tasa_precio = response.pdf7[0].tasa_precio; */

            /* this.formato_base_impnible = '0,00';
            this.formato_factura_base_imponible_divisa = '0,00';
            this.formato_iva_monto_divisa = '0,00'; */

            if (termino.toUpperCase() === this.dd.toUpperCase()) {
                this.path_0 = "../../../assets/img/logo_hd.png";
                this.path_1 = "../../../assets/img/FC.png";
                this.path_2 = "../../../assets/img/bar.png";
                this.path_3 = "../../../assets/img/footer_2.png"
            }
            
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'AD-SN') {
            this.mensaje_1 = 'AFECTA LA FACTURA Nº: '+response.pdf2[0].cod_factura;
            this.mensaje_2 ='CODIGO DE CONTROL FACTURA Nº: '+response.pdf6[0].cod_control;
            this.mensaje_3 ='FECHA DE LA FACTURA: '+response.fecha[0].fecha_inicio;
            this.mensaje_3_2 = '';
            this.mensaje_4_variable = '';
            this.numero_documento = response.pdf2[0].cod_nota_credito;
            this.numero_control = response.pdf5[0].cod_control;
            this.documento = this.nota_credito;
            
            response.pdf6[0].cod_lista_precio_producto ='N/A';
            
           }
           if (response.pdf4[0].tipo_pedido.toUpperCase() === 'NTNTRG' || response.pdf4[0].tipo_pedido.toUpperCase() === 'ADMINNT') {
            if (response.fecha[0].tipo_nota.toUpperCase() === 'ND') {
                this.mensaje_1 = 'AFECTA LA NOTA DE ENTREGA Nº: '+response.pdf2[0].cod_factura;
                    this.mensaje_2 ='CODIGO DE CONTROL NOTA DE ENTREGA Nº: '+'00-0000';
                    this.mensaje_3 ='FECHA DE LA NOTA DE ENTREGA: '+response.fecha[0].fecha_inicio;
                    this.mensaje_3_2 = '';
                    this.mensaje_4_variable = '';
                    this.numero_documento = response.pdf2[0].cod_nota_credito;
                    this.numero_control = '00-0000';
                    this.documento = this.nota_credito;
            
                this.path_0 = "../../../assets/img/logo_hd.png";
                this.path_1 = "../../../assets/img/FC.png";
                this.path_2 = "../../../assets/img/bar.png";
                this.path_3 = "../../../assets/img/blank_.png";

                this.formato_subtotal_divisa = response.pdf4[0].subtotal_costo_pedido;
                this.formato_des_divisa = response.pdf4[0].desc_pedido_monto;
                this.formato_factura_base_divisa = this.factura_base;
                this.formato_total_divisa = response.pdf4[0].subtotal_costo_pedido;
                this.formato_iva_monto_divisa = '0,00';

                this.formato_r_subtotal_costo_pedido = '0,00';
                this.formato_r_desc_pedido = '0,00';
                this.formato_b_fact = '0,00';
                this.formato_r_excento = '0,00';
                this.formato_b_imp = '0,00';
                this.formato_r_iva_monto = '0,00';
                this.formato_r_total_costo_pedido = '0,00';

                this.formato_factura_base_imponible_divisa = '0,00';
               
            }
            if (response.fecha[0].tipo_nota.toUpperCase() === 'NB') {
                this.mensaje_1 = 'AFECTA LA NOTA DE ENTREGA Nº: '+response.pdf2[0].cod_factura;
                    this.mensaje_2 ='CODIGO DE CONTROL NOTA DE ENTREGA Nº: '+'00-0000';
                    this.mensaje_3 ='FECHA DE LA NOTA DE ENTREGA: '+response.fecha[0].fecha_inicio;
                    this.mensaje_3_2 = '';
                    this.mensaje_4_variable = '';
                    this.numero_documento = response.pdf2[0].cod_nota_credito;
                    this.numero_control = '00-0000';
                    this.documento = this.nota_credito;
            
                this.path_0 = "../../../assets/img/logo_hd.png";
                this.path_1 = "../../../assets/img/FC.png";
                this.path_2 = "../../../assets/img/bar.png";
                this.path_3 = "../../../assets/img/blank_.png";

            this.formato_base_impnible = '0,00';
            this.formato_r_iva_monto = '0,00';
            this.formato_factura_base_imponible_divisa = '0,00';
            this.formato_iva_monto_divisa = '0,00';
            } 
            if (response.fecha[0].tipo_nota.toUpperCase() === 'PF') {
                this.mensaje_1 = 'AFECTA LA NOTA DE ENTREGA Nº: '+response.pdf2[0].cod_factura;
                    this.mensaje_2 ='CODIGO DE CONTROL NOTA DE ENTREGA Nº: '+'00-0000';
                    this.mensaje_3 ='FECHA DE LA NOTA DE ENTREGA: '+response.fecha[0].fecha_inicio;
                    this.mensaje_3_2 = '';
                    this.mensaje_4_variable = '';
                    this.numero_documento = response.pdf2[0].cod_nota_credito;
                    this.numero_control = '00-0000';
                    this.documento = this.nota_credito;
            
                this.path_0 = "../../../assets/img/logo_hd.png";
                this.path_1 = "../../../assets/img/FC.png";
                this.path_2 = "../../../assets/img/bar.png";
                this.path_3 = "../../../assets/img/blank_.png";

            } 
           }
                    if (termino.toUpperCase() === this.d.toUpperCase() ) {
                        var doc ={
                            pageSize: 'LETTER',
                            /* background: [
                                { image:  await this.getBase64ImageFromURL("../../../assets/img/marca_3.png") , width: 811, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
                              ], */
                              info:{
                                title:this.documento+this.numero_documento,
                                author: this.titulo,
                                subject:'DOCUMENTO',
                              },
                            content  :[
                                { 
                                    style: 'codigo',
                                    table: {
                                        headerRows: 1,
                                        widths: ['*'],
                                        fontSize: 8,
                                        body: [
                                            [    
                                                {   
                                                    text: this.documento+this.numero_documento,
                                                    fontSize: 9,
                                                    with: '*',
                                                    color:'#000',
                                                    bold: true,
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                            ]
                                        ],
                                    }, 
                                },
                                { 
                                    style: 'fila_1',
                                    table: {
                                        headerRows: 1,
                                        widths: [250, 130, '*','*'],
                                        fontSize: 7,
                                        body: [
                                            [    
                                                {   
                                                    text: 'Cliente: '+response.pdf3[0].des_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: 'Vendedor: '+response.pdf3[0].des_vendedor,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: '',
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: 'Emisión: '+response.pdf2[0].fecha_inicio,
                                                    fontSize: 7,
                                                    with: '*',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: 'Rif: '+response.pdf3[0].cod_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                
                                                {   
                                                    text: 'Telf: '+response.pdf3[0].telefono1_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: 'Celular: '+response.pdf3[0].telefono2_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
        
                                                {   
                                                    text: 'Venc: '+this.fecha_documento_v,
                                                    fontSize: 7,
                                                    with: '*',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                            ]
                                        ],
                                    }, 
                                },
                                { 
                                    style: 'fila_2',
                                    table: {
                                        headerRows: 1,
                                        widths: ['*'],
                                        fontSize: 8,
                                        body: [
                                            [    
                                                {   
                                                    text: 'Dirección: '+response.pdf3[0].dir_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                }
                                            ]
                                        ],
                                    }, 
                                },
                                { 
                                    style: 'data',
                                    table: {
                                        headerRows: 1,
                                        widths: ['*', 270, '*', '*', 50, '*', '*'],
                                        body: tablaEnLista,
                                        fontSize: 8,
                                    }, 
                                },
                                { 
                                    style: 'footer',
                                    table: {
                                        headerRows: 1,
                                        widths: ['*',80,60,60],
                                        fontSize: 8,
                                        body: [
                                            [    
                                                {   
                                                    text: 'Notas : '+response.pdf2[0].comentarios,
                                                    fontSize: 8,
                                                    margin:0,
                                                    padding:0,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, true, false, false],
                                                },
                                                {   
                                                    text: 'Sub-total: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, true, false, false],
                                                },
                                                {   
                                                    text:  this.formato_r_subtotal_costo_pedido +' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, true, false, false],
                                                },
                                                {   
                                                    text: this.formato_subtotal_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    bold:true,
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, true, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_1,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'Descuento: '+response.pdf4[0].desc_pedido+' %',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_r_desc_pedido+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text:this.formato_des_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    bold:true,
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_2,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'Monto base: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text:  this.formato_b_fact+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_factura_base_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_3,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'Total exento: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_r_excento+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_excento_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_3_2,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'Total base imponible: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_b_imp+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_factura_base_imponible_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: 'Lista de precios: '+response.pdf6[0].cod_lista_precio_producto,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'I.V.A: '+response.pdf4[0].iva_pedido+' %',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_r_iva_monto+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_iva_monto_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_4_variable,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, true],
                                                },
                                                {   
                                                    text: 'Total: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, true],
                                                },
                                                {   
                                                    text: this.formato_r_total_costo_pedido+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [true, true, false, true],
                                                },
                                                {   
                                                    text: this.formato_total_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, true, true, true],
                                                },
                                            ]
                                        ]
                                    }
                                }
                            ],
                            styles: {
                                codigo: {
                                    margin: [ -20, 100, -20, 0 ],
                                    padding:[0],
                                    lineHeight:[0.5],
                                },
                                fila_1: {
                                    margin: [ -20, 0, -20, 0],
                                    padding:[0,0,0,1],
                                    lineHeight:[0.6],
                                },
                                fila_2: {
                                    margin: [ -20, 0, -20, 2],
                                    padding:[0],
                                    lineHeight:[0.5],
                                },
                                data: {
                                    margin: [ -20, 0, -20, 0],
                                    lineHeight:[0.5],
                                },
                                footer: {
                                    margin: [ -20, 5, -20, 1 ],
                                    padding:[0],
                                    lineHeight:[0.777],
                                }
                            },
                        }
                        pdf.createPdf(doc).print();
                    }
                   
                    if (termino.toUpperCase() === this.dd.toUpperCase()) { 
                        var doc_ ={
                            pageSize: 'LETTER',
                            /* background: [
                                { image:  await this.getBase64ImageFromURL("../../../assets/img/marca_3.png") , width: 811, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
                              ], */
                              info:{
                                title:this.documento+this.numero_documento,
                                author: this.titulo,
                                subject:'DOCUMENTO',
                              },
                            content  :[
                                {   style: 'header',
                                    table:{
                                        headerRows: 1,
                                        widths: [80,400,80],
                                        fontSize: 8,
                                        body:[
                                            [  
                                               {image: await this.getBase64ImageFromURL(this.path_0), width: 80, height:85,alignment:'center'},
                                               {
                                               alignment:'center',
                                               border: [false, false, false, false],
                                               table: {
                                                headerRows: 1,
                                                widths: ['*'],
                                                body: [
                                                                [{
                                                                    text:this.titulo,
                                                                    fontSize:16,
                                                                    with: '*',
                                                                    color:'#000',
                                                                    bold: true,
                                                                    alignment:'center',
                                                                    padding:0,
                                                                    lineHeight:[0.8],
                                                                    border: [false, false, false, false],
                                                                }],
                                                                [{
                                                                    text:this.direccion+'. Zona Postal '+this.postal,
                                                                    fontSize:9,
                                                                    with: '*',
                                                                    color:'#000',
                                                                    bold: false,
                                                                    alignment:'center',
                                                                    padding:0,
                                                                    lineHeight:[0.5],
                                                                    border: [false, false, false, false],
                                                                }],
                                                                [{
                                                                    text:'Tel.: '+this.tel_1+'/'+this.tel_2+'/'+this.tel_3,
                                                                    fontSize:9,
                                                                    with: '*',
                                                                    color:'#000',
                                                                    bold: false,
                                                                    alignment:'center',
                                                                    padding:0,
                                                                    lineHeight:[0.5],
                                                                    border: [false, false, false, false],
                                                                }],
                                                                [{
                                                                    text:'Correo.: '+this.correo+'   Web.: '+this.web,
                                                                    fontSize:9,
                                                                    with: '*',
                                                                    color:'#29388f',
                                                                    bold: false,
                                                                    alignment:'center',
                                                                    padding:0,
                                                                    lineHeight:[0.5],
                                                                    border: [false, false, false, false],
                                                                }],
                                                                [{
                                                                    text:'Rif.: '+this.rif,
                                                                    fontSize:9,
                                                                    with: '*',
                                                                    color:'#000',
                                                                    bold: false,
                                                                    alignment:'center',
                                                                    padding:0,
                                                                    lineHeight:[0.5],
                                                                    border: [false, false, false, false],
                                                                }]
                                                        ]
                                                        },fontSize: 8,
                                                        layout: {
                                                            defaultBorder: false,
                                                        }
                                                    },
                                                {  style:'cod_control',
                                                    table: {
                                                   margin: [1,5,1,0],
                                                   body: [
                                                       [{
                                                        text:'N° DE CONTROL',
                                                        fontSize:9,
                                                        with: '*',
                                                        color:'#000',
                                                        bold: true,
                                                        alignment:'center',
                                                        border: [false, false, false, false],
                                                        }],
                                                       [{
                                                        text:this.numero_control,
                                                        fontSize:13,
                                                        with: '*',
                                                        color:'#9f0f0f',
                                                        bold: true,
                                                        alignment:'center',
                                                        border: [false, false, false, false],
                                                    }]
                                                   ]
                                               },fontSize: 8,
                                               layout: {
                                                   defaultBorder: false,
                                               }
                                           },
                                            ]
                                        ],  
                                    },layout: {
                                        defaultBorder: false,
                                    },
                                },
                                { 
                                   style: 'linea',
                                   table: {
                                       headerRows: 1,
                                       widths: ['*'],
                                       padding: 0,
                                       fontSize: 8,
                                       body: [
                                           [
                                               {
                                                image: await this.getBase64ImageFromURL(this.path_2),
                                                width: 560 , 
                                                height:8,
                                                alignment:'center',
                                                padding: 0,
                                                border: [false,false,false,false],
                                            },
                                           ]      
                                       ],
                                   },layout: {
                                       defaultBorder: false,
                                   },
                               },
                                { 
                                    style: 'codigo',
                                    table: {
                                        headerRows: 1,
                                        widths: ['*'],
                                        fontSize: 8,
                                        body: [
                                            [    
                                                {   
                                                    text: this.documento+this.numero_documento,
                                                    fontSize: 9,
                                                    with: '*',
                                                    color:'#000',
                                                    bold: true,
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                            ]
                                        ],
                                    }, 
                                },
                                { 
                                    style: 'fila_1',
                                    table: {
                                        headerRows: 1,
                                        widths: [250, 130, '*','*'],
                                        fontSize: 7,
                                        body: [
                                            [    
                                                {   
                                                    text: 'Cliente: '+response.pdf3[0].des_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: 'Vendedor: '+response.pdf3[0].des_vendedor,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: '',
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: 'Emisión: '+response.pdf2[0].fecha_inicio,
                                                    fontSize: 7,
                                                    with: '*',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: 'Rif: '+response.pdf3[0].cod_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                
                                                {   
                                                    text: 'Telf: '+response.pdf3[0].telefono1_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: 'Celular: '+response.pdf3[0].telefono2_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                },
        
                                                {   
                                                    text: 'Venc: '+this.fecha_documento_v,
                                                    fontSize: 7,
                                                    with: '*',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                            ]
                                        ],
                                    }, 
                                },
                                { 
                                    style: 'fila_2',
                                    table: {
                                        headerRows: 1,
                                        widths: ['*'],
                                        fontSize: 8,
                                        body: [
                                            [    
                                                {   
                                                    text: 'Dirección: '+response.pdf3[0].dir_cliente,
                                                    fontSize: 7,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [false, false, false, false],
                                                }
                                            ]
                                        ],
                                    }, 
                                },
                                { 
                                    style: 'data',
                                    table: {
                                        headerRows: 1,
                                        widths: ['*', 270, '*', '*', 50, '*', '*'],
                                        body: tablaEnLista,
                                        fontSize: 8,
                                    }, 
                                },
                                { 
                                    style: 'footer',
                                    table: {
                                        headerRows: 1,
                                        widths: ['*',80,60,60],
                                        fontSize: 8,
                                        body: [
                                            [    
                                                {   
                                                    text: 'Notas : '+response.pdf2[0].comentarios,
                                                    fontSize: 8,
                                                    margin:0,
                                                    padding:0,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, true, false, false],
                                                },
                                                {   
                                                    text: 'Sub-total: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, true, false, false],
                                                },
                                                {   
                                                    text: this.formato_r_subtotal_costo_pedido +' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, true, false, false],
                                                },
                                                {   
                                                    text: this.formato_subtotal_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    bold:true,
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, true, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_1,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'Descuento: '+response.pdf4[0].desc_pedido+' %',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_r_desc_pedido +' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text:this.formato_des_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    bold:true,
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_2,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'Monto base: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_b_fact+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_factura_base_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_3,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'Total exento: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_r_excento+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_excento_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_3_2,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'Total base imponible: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_b_imp+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_factura_base_imponible_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: 'Lista de precios: '+response.pdf6[0].cod_lista_precio_producto,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, false],
                                                },
                                                {   
                                                    text: 'I.V.A: '+response.pdf4[0].iva_pedido+' %',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_r_iva_monto+' bs',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, false],
                                                },
                                                {   
                                                    text: this.formato_iva_monto_divisa+' $',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, false, true, false],
                                                },
                                            ],
                                            [    
                                                {   
                                                    text: this.mensaje_4_variable,
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'left',
                                                    border: [true, false, false, true],
                                                },
                                                {   
                                                    text: 'Total: ',
                                                    fontSize: 8,
                                                    with: '*',
                                                    color:'#000',
                                                    alignment:'right',
                                                    border: [false, false, false, true],
                                                },
                                                {   
                                                    text: this.formato_r_total_costo_pedido+' bs',
                                                    fontSize: 9,
                                                    with: '*',
                                                    color:'#000',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [true, true, false, true],
                                                },
                                                {   
                                                    text: this.formato_total_divisa+' $',
                                                    fontSize: 9,
                                                    with: '*',
                                                    color:'blue',
                                                    bold:true,
                                                    alignment:'right',
                                                    border: [false, true, true, true],
                                                },
                                            ]
                                        ]
                                    }
                                },
                                {   style: 'x_footer',
                                    table:{
                                        headerRows: 1,
                                        widths: ['*'],
                                        padding: 0,
                                        fontSize: 8,
                                        body:[
                                            [
                                               {
                                                image: await this.getBase64ImageFromURL(this.path_3),
                                                width: 560, 
                                                height:42,
                                                alignment:'center',
                                                border: [false, false, false, false],
                                            },
                                            ],
                                        ],  
                                    },layout: {
                                        defaultBorder: false,
                                    },
                                },
                            ],
                            styles: {
                               cod_control: {
                                   margin: [ 0, 10, 0, 5 ]
                               },
                                header: {
                                    margin: [ -20, -20, -20, 0 ]
                                },
                                linea: {
                                   margin: [ -20, -5, -20, 0 ],
                                   padding:[0],
                                   lineHeight:[0.5],
                               },
                                codigo: {
                                    margin: [ -20, -4, -20, 0 ],
                                    padding:[0],
                                    lineHeight:[0.5],
                                },
                                fila_1: {
                                    margin: [ -20, 0, -20, 0],
                                    padding:[0,0,0,1],
                                    lineHeight:[0.6],
                                },
                                fila_2: {
                                    margin: [ -20, 0, -20, 2],
                                    padding:[0],
                                    lineHeight:[0.5],
                                },
                                data: {
                                    margin: [ -20, 0, -20, 0],
                                    lineHeight:[0.5],
                                },
                                footer: {
                                    margin: [ -20, 5, -20, 1 ],
                                    padding:[0],
                                    lineHeight:[0.777],
                                },
                                x_footer: {
                                   margin: [ -20, 2, -20, 1 ],
                                   padding:[0],
                               },
                                
                            },
                        }
                        pdf.createPdf(doc_).download(this.documento+this.numero_documento);
                    }
       } catch (error) {
        console.log(error);
           await this.notifi.customToastNotification(error.error.message);
       }
   }
   private  generarTablaVentas(data:any, data2:any){
   console.log(data);
    for (let i = 0; i < 45; i++) {

        if (data[i] === undefined ) {
            let xobjeto = [{text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false], }, 
            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],}, 
            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],},
            {text: ' ',alignment: 'center', fontSize: 7,border: [false, false, false, false], }, 
            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],}, 
            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],},
            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],}
           ]
            data[i] =  xobjeto; 
        }   
    }

   let arry =[
       [
           {text: 'Codigo', alignment: 'center',margin:[0,0,0,2], fontSize: 7, border: [true, true, false, true], },
           {text: 'Producto', alignment: 'center',margin:[0,0,0,2], fontSize: 7, border: [false, true, false, true], }, 
           {text: 'Cantidad', alignment: 'right',margin:[0,0,0,2], fontSize: 7, border: [false, true, false, true],}, 
           {text: 'Precio', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
           {text: 'Descuento (%)', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
           {text: 'Descuento', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
           {text: 'Sub-Total', alignment: 'center', fontSize: 7,border: [false, true, true, true],},
       ],
   ]

  for (const i in data) {
 
   if(data[i].cod_producto !== undefined){
       if (data[i].desc_prod === '' ||
       data[i].desc_prod === undefined ||
       data[i].desc_prod === null) {

           data[i].desc_prod = '0,00';
           data[i].desc_prod_monto = '0,00';
           
       }

      var fecha_soporte = moment('01/10/2021', 'DD/MM/YYYY');
      var fecha_doc =  moment(this.fecha_documento, 'DD/MM/YYYY') ;
      if (fecha_doc.isSameOrAfter(fecha_soporte)) {
    
      }else{
       
        /* data[i].costo_producto =  (parseFloat(data[i].costo_producto.replace('.','')
                                                                .replace('.','')
                                                                .replace('.','')
                                                                .replace('.','')
                                                                .replace('.','')
                                                                .replace(',','.')) / this.idea_hp_de_maduro).toFixed(2).replace(/\D/g, "")
                                                                                                                            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                                                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        data[i].desc_prod_monto =  (parseFloat(data[i].desc_prod_monto.replace('.','')
                                                                .replace('.','')
                                                                .replace('.','')
                                                                .replace('.','')
                                                                .replace('.','')
                                                                .replace(',','.')) / this.idea_hp_de_maduro).toFixed(2).replace(/\D/g, "")
                                                                                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    
        data[i].desc_prod_monto =  (parseFloat(data[i].desc_prod_monto.replace('.','')
                                                                       .replace('.','')
                                                                        .replace('.','')
                                                                         .replace('.','')
                                                                         .replace('.','')
                                                                          .replace(',','.')) / this.idea_hp_de_maduro).toFixed(2).replace(/\D/g, "")
                                                                                                                      .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                                                       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

        data[i].subtotal_producto =  (parseFloat(data[i].subtotal_producto.replace('.','')
                                                                           .replace('.','')
                                                                            .replace('.','')
                                                                             .replace('.','')
                                                                             .replace('.','')
                                                                              .replace(',','.')) / this.idea_hp_de_maduro).toFixed(2).replace(/\D/g, "")
                                                                                                                                     .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                                                                      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."); */
      }

       let articulo = 
                        [
                            {text: data[i].cod_producto, alignment: 'left', fontSize: 7,border: [false, false, false, false], }, 
                            {text: data[i].des_producto, alignment: 'left', fontSize: 7,border: [false, false, false, false],}, 
                            {text: data[i].cant_producto, alignment: 'right', fontSize: 7,border: [false, false, false, false],},
                            {text: data[i].costo_producto,alignment: 'right', fontSize: 7,border: [false, false, false, false], }, 
                            {text: data[i].desc_prod, alignment: 'right', fontSize: 7,border: [false, false, false, false],}, 
                            {text: data[i].desc_prod_monto, alignment: 'right', fontSize: 7,border: [false, false, false, false],},
                            {text: data[i].subtotal_producto, alignment: 'right', fontSize: 7,border: [false, false, false, false],}
                        ]
   arry.push(articulo);

   }else{
       let articulo = 
                        [
                            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false], }, 
                            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],}, 
                            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],},
                            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false], }, 
                            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],}, 
                            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],},
                            {text: ' ', alignment: 'center', fontSize: 7,border: [false, false, false, false],}
                            
                        ]
   arry.push(articulo);
   }
  }
  
   /* for (const i in data) {

       let articulo = 
       [
           {text: data[i].cod_producto, alignment: 'center', fontSize: 7,border: [false, false, false, false], }, 
           {text: 'Producto de prueba para el proceso para que esto funcione siempre hijo12345678910', alignment: 'left', fontSize: 7,border: [false, false, false, false],}, 
           {text: '10', alignment: 'center', fontSize: 7,border: [false, false, false, false],},
           {text: '10,25',alignment: 'center', fontSize: 7,border: [false, false, false, false], }, 
           {text: '1,50', alignment: 'center', fontSize: 7,border: [false, false, false, false],}, 
           {text: '1,50', alignment: 'center', fontSize: 7,border: [false, false, false, false],},
           {text: data.length, alignment: 'center', fontSize: 7,border: [false, false, false, false],}
           
       ]
       arry.push(articulo);
   } */
   return arry;
}
    private calculos(data:any, fecha:any){
    var llave = false;
      var fecha_soporte = moment('01/10/2021', 'DD/MM/YYYY');
      var fecha_doc =  moment(fecha, 'DD/MM/YYYY') ;
      if (fecha_doc.isSameOrAfter(fecha_soporte)) {
    
        llave = true;
      }else{
       
        llave = false;
      }

      console.log(llave,'llave');
      
    if (llave) {
    
              this.resta_base_imp = parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                            .replace(',','.')) - parseFloat(data.pdf4[0].monto_exento.replace('.','')
                                                                                                    .replace('.','')
                                                                                                    .replace('.','')
                                                                                                    .replace('.','')
                                                                                                    .replace('.','')
                                                                                                    .replace(',','.'));
    
              this.base_imponible = this.resta_base_imp - parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace(',','.'));
        
              this.base_factura = parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
              .replace(',','.')) - parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace(',','.'));
              this.formato_base_impnible = this.base_imponible.toFixed(2).replace(/\D/g, "")
                                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
              this.factura_base = this.base_factura.toFixed(2).replace(/\D/g, "")
                                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
              /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
              this.factura_base_divisa = (this.base_factura / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace(',','.')));
        
              this.formato_factura_base_divisa = this.factura_base_divisa.toFixed(2).replace(/\D/g, "")
                                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
              this.factura_base_imponible_divisa = (this.base_imponible / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')));
        
              this.formato_factura_base_imponible_divisa = this.factura_base_imponible_divisa .toFixed(2).replace(/\D/g, "")
              .replace(/([0-9])([0-9]{2})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
              this.subtotal_divisa = (parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')));
        
              this.formato_subtotal_divisa = this.subtotal_divisa.toFixed(2).replace(/\D/g, "")
              .replace(/([0-9])([0-9]{2})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
              this.iva_monto_divisa = (parseFloat(data.pdf4[0].iva_monto.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')));
        
              this.formato_iva_monto_divisa = this.iva_monto_divisa.toFixed(2).replace(/\D/g, "")
              .replace(/([0-9])([0-9]{2})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
              this.total_divisa = (parseFloat(data.pdf4[0].total_costo_pedido.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')));

              console.log(this.total_divisa);
        
              this.formato_total_divisa = this.total_divisa.toFixed(2).replace(/\D/g, "")
              .replace(/([0-9])([0-9]{2})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
              this.des_divisa = (parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                    .replace('.','')
                                                    .replace('.','')
                                                    .replace('.','')
                                                    .replace('.','')
                                                    .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                    .replace('.','')
                                                    .replace('.','')
                                                    .replace('.','')
                                                    .replace('.','')
                                                    .replace(',','.')));
        
              this.formato_des_divisa = this.des_divisa.toFixed(2).replace(/\D/g, "")
              .replace(/([0-9])([0-9]{2})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
              this.excento_divisa = (parseFloat(data.pdf4[0].monto_exento.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace('.','')
              .replace(',','.')));
        
              this.formato_excento_divisa = this.excento_divisa.toFixed(2).replace(/\D/g, "")
              .replace(/([0-9])([0-9]{2})$/, '$1,$2')
              .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        
              /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
    
          //////////////////////////RECONVERSION/////////////////////////////
          
          this.formato_b_fact = this.factura_base;
    
          this.formato_b_imp = this.formato_base_impnible;
    
          this.formato_r_excento = data.pdf4[0].monto_exento
    
          this.formato_r_desc_pedido = data.pdf4[0].desc_pedido_monto;
    
          this.formato_r_subtotal_costo_pedido = data.pdf4[0].subtotal_costo_pedido;
    
          this.formato_r_iva_monto = data.pdf4[0].iva_monto;
    
          this.formato_r_total_costo_pedido = data.pdf4[0].total_costo_pedido;
        //////////////////////////RECONVERSION/////////////////////////////
    
        this.igtf_monto = this.total_divisa * parseFloat(this.igtf.replace('.','')
                                                                    .replace('.','')
                                                                    .replace('.','')
                                                                    .replace('.','')
                                                                    .replace('.','')
                                                                    .replace(',','.')) / 100;
    
        this.igtf_monto_total = this.igtf_monto + this.total_divisa;
    
        this.igtf_monto_total = this.igtf_monto_total.toFixed(2).replace(/\D/g, "")
                                                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        this.igtf_monto = this.igtf_monto.toFixed(2).replace(/\D/g, "")
                                                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    }else{
        var idea_hp_de_maduro = 1000000;

        if (
            data.pdf4[0].tipo_pedido.toUpperCase() === 'DEV'   ||
            data.pdf4[0].tipo_pedido.toUpperCase() === 'ADMIN' || 
            data.pdf4[0].tipo_pedido.toUpperCase() === 'AD-SN' ||
            data.pdf4[0].tipo_pedido.toUpperCase() === 'NTNTRG' ||
            data.pdf4[0].tipo_pedido.toUpperCase() === 'ADMINNT' ||
            data.pdf4[0].tipo_pedido.toUpperCase() === 'NDB') {
                 data.pdf6[0].tasa_precio = parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace(',','.')) / idea_hp_de_maduro;
                    data.pdf6[0].tasa_precio = data.pdf6[0].tasa_precio.toFixed(2).replace(/\D/g, "")
                                                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.resta_base_imp = (parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace(',','.')) + parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace(',','.')) ) - parseFloat(data.pdf4[0].monto_exento.replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace('.','')
                                                                                        .replace(',','.'));
                                        
                                                        this.base_imponible = this.resta_base_imp - parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                                                .replace('.','')
                                                                                .replace('.','')
                                                                                .replace('.','')
                                                                                .replace('.','')
                                                                                .replace(',','.'));
                                        
                                                        this.base_factura = (parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace(',','.')) + parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace(',','.'))) - parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace(',','.'));
                                                        this.formato_base_impnible = this.base_imponible.toFixed(2).replace(/\D/g, "")
                                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                                                this.factura_base = this.base_factura.toFixed(2).replace(/\D/g, "")
                                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
                                                        this.factura_base_divisa = (this.base_factura / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                                                            .replace('.','')
                                                                            .replace(',','.')));
                                        
                                                        this.formato_factura_base_divisa = this.factura_base_divisa.toFixed(2).replace(/\D/g, "")
                                                                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.factura_base_imponible_divisa = (this.base_imponible / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                                                    .replace('.','')
                                                                                    .replace('.','')
                                                                                    .replace('.','')
                                                                                    .replace('.','')
                                                                                    .replace(',','.')));
                                        
                                                        this.formato_factura_base_imponible_divisa = this.factura_base_imponible_divisa.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.subtotal_divisa = (parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')) + parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.'))) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.'));
                                        
                                                        this.formato_subtotal_divisa = this.subtotal_divisa.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                        
                                                        this.iva_monto_divisa = (parseFloat(data.pdf4[0].iva_monto.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')));
                                        
                                                        this.formato_iva_monto_divisa = this.iva_monto_divisa.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.total_divisa = (parseFloat(data.pdf4[0].total_costo_pedido.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')));
                                        
                                                        this.formato_total_divisa = this.total_divisa.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.des_divisa = (parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')));
                                        
                                                        this.formato_des_divisa = this.des_divisa.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.excento_divisa = (parseFloat(data.pdf4[0].monto_exento.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')));
                                        
                                                        this.formato_excento_divisa = this.excento_divisa.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
                                        
                                                        //////////////////////////RECONVERSION/////////////////////////////
                                        
                                                        console.log(this.base_factura,'llego a la puta reconversion');
                                        
                                                        this.b_fact = this.base_factura /* / this.idea_hp_de_maduro */;
                                        
                                                        this.formato_b_fact = this.b_fact.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.formato_b_imp =  parseFloat(this.formato_base_impnible.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')) /* / this.idea_hp_de_maduro */;
                                                        this.formato_b_imp = this.formato_b_imp.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.formato_r_excento = parseFloat(data.pdf4[0].monto_exento.replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace(',','.'))  /* / this.idea_hp_de_maduro */;
                                                        this.formato_r_excento = this.formato_r_excento.toFixed(2).replace(/\D/g, "")
                                                            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.formato_r_desc_pedido = parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace('.','')
                                                                        .replace(',','.'))  /* / this.idea_hp_de_maduro */;
                                                        this.formato_r_desc_pedido = this.formato_r_desc_pedido.toFixed(2).replace(/\D/g, "")
                                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.formato_r_subtotal_costo_pedido = (parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace(',','.'))  + parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace('.','')
                                                                                                .replace(',','.')) ) /* / this.idea_hp_de_maduro */ ;
                                                        this.formato_r_subtotal_costo_pedido = this.formato_r_subtotal_costo_pedido.toFixed(2).replace(/\D/g, "")
                                                                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.formato_r_iva_monto = parseFloat(data.pdf4[0].iva_monto.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.')) /* / this.idea_hp_de_maduro */;
                                        
                                                        this.formato_r_iva_monto = this.formato_r_iva_monto.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.formato_r_total_costo_pedido = parseFloat(data.pdf4[0].total_costo_pedido.replace('.','')
                                                                                .replace('.','')
                                                                                .replace('.','')
                                                                                .replace('.','')
                                                                                .replace('.','')
                                                                                .replace(',','.')) /* / this.idea_hp_de_maduro */;
                                                        this.formato_r_total_costo_pedido = this.formato_r_total_costo_pedido.toFixed(2).replace(/\D/g, "")
                                                                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                        
                                                        this.igtf_monto = this.total_divisa * parseFloat(this.igtf.replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace(',','.')) / 100;
                                        
                                                        this.igtf_monto_total = this.igtf_monto + this.total_divisa;
                                        
                                                        this.igtf_monto_total = this.igtf_monto_total.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                                                        this.igtf_monto = this.igtf_monto.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        }else{
                /* data.pdf6[0].tasa_precio = parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace('.','')
                                                            .replace(',','.')) / idea_hp_de_maduro; */
                    data.pdf6[0].tasa_precio = parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.')).toFixed(2).replace(/\D/g, "")
                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.resta_base_imp = parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) - parseFloat(data.pdf4[0].monto_exento.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.'));

                this.base_imponible = this.resta_base_imp - parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                        .replace('.','')
                                        .replace('.','')
                                        .replace('.','')
                                        .replace('.','')
                                        .replace(',','.'));

                this.base_factura = parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace(',','.')) - parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace(',','.'));
                this.formato_base_impnible = this.base_imponible.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                        this.factura_base = this.base_factura.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////
                this.factura_base_divisa = (this.base_factura / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace('.','')
                                    .replace(',','.')));

                this.formato_factura_base_divisa = this.factura_base_divisa.toFixed(2).replace(/\D/g, "")
                            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.factura_base_imponible_divisa = (this.base_imponible / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                                            .replace('.','')
                                            .replace('.','')
                                            .replace('.','')
                                            .replace('.','')
                                            .replace(',','.')));

                this.formato_factura_base_imponible_divisa = this.factura_base_imponible_divisa.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.subtotal_divisa = (parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')));

                this.formato_subtotal_divisa = this.subtotal_divisa.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                this.iva_monto_divisa = (parseFloat(data.pdf4[0].iva_monto.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')));

                this.formato_iva_monto_divisa = this.iva_monto_divisa.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.total_divisa = (parseFloat(data.pdf4[0].total_costo_pedido.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')));

                this.formato_total_divisa = this.total_divisa.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.des_divisa = (parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')));

                this.formato_des_divisa = this.des_divisa.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.excento_divisa = (parseFloat(data.pdf4[0].monto_exento.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) / parseFloat(data.pdf6[0].tasa_precio.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')));

                this.formato_excento_divisa = this.excento_divisa.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                /////////////////////////////MONTOS EN DIVISAS PARA REFERENCIAS/////////////////////////////////////////////

                //////////////////////////RECONVERSION/////////////////////////////

                console.log(this.base_factura,'llego a la puta reconversion');

                this.b_fact = this.base_factura / this.idea_hp_de_maduro;

                this.formato_b_fact = this.b_fact.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.formato_b_imp =  parseFloat(this.formato_base_impnible.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) / this.idea_hp_de_maduro;
                this.formato_b_imp = this.formato_b_imp.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.formato_r_excento = parseFloat(data.pdf4[0].monto_exento.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.'))  / this.idea_hp_de_maduro;
                this.formato_r_excento = this.formato_r_excento.toFixed(2).replace(/\D/g, "")
                    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.formato_r_desc_pedido = parseFloat(data.pdf4[0].desc_pedido_monto.replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace('.','')
                                .replace(',','.'))  / this.idea_hp_de_maduro;
                this.formato_r_desc_pedido = this.formato_r_desc_pedido.toFixed(2).replace(/\D/g, "")
                                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.formato_r_subtotal_costo_pedido = parseFloat(data.pdf4[0].subtotal_costo_pedido.replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace('.','')
                                                        .replace(',','.'))  / this.idea_hp_de_maduro;
                this.formato_r_subtotal_costo_pedido = this.formato_r_subtotal_costo_pedido.toFixed(2).replace(/\D/g, "")
                                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.formato_r_iva_monto = parseFloat(data.pdf4[0].iva_monto.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) / this.idea_hp_de_maduro;

                this.formato_r_iva_monto = this.formato_r_iva_monto.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.formato_r_total_costo_pedido = parseFloat(data.pdf4[0].total_costo_pedido.replace('.','')
                                        .replace('.','')
                                        .replace('.','')
                                        .replace('.','')
                                        .replace('.','')
                                        .replace(',','.')) / this.idea_hp_de_maduro;
                this.formato_r_total_costo_pedido = this.formato_r_total_costo_pedido.toFixed(2).replace(/\D/g, "")
                                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                this.igtf_monto = this.total_divisa * parseFloat(this.igtf.replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace('.','')
                    .replace(',','.')) / 100;

                this.igtf_monto_total = this.igtf_monto + this.total_divisa;

                this.igtf_monto_total = this.igtf_monto_total.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
                this.igtf_monto = this.igtf_monto.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        }

    }
    }
////////////////////////////VENTAS/////////////////////////////////////////////
////////////////////////////OPERACIONES////////////////////////////////////////////
  public async pdfOperaciones(response:any){
    try {
        
         console.log(response);

        const fontsSize = 9
        const tablaEnLista = this.generarTablaOperaciones(response.pdf);

        if (response.documento.tipo_pedido.toUpperCase() === 'F') {
            this.texto = 'GUIA DE ALMACEN';
            this.numero_documento = response.documento.cod_guia_despacho;
            this.numero_documento_2 = response.documento.cod_factura;
            this.numero_control = response.documento.cod_guia_despacho;
            this.documento = this.guia_almacen ;
   
            this.path_0 = "../../../assets/img/logo_hd.png";
            this.path_1 = "../../../assets/img/FC.png";
            this.path_2 = "../../../assets/img/bar.png";
            this.path_3 = "../../../assets/img/blank.png";
           }
 
        if (response.documento.tipo_pedido.toUpperCase() === 'N') {
         this.texto = 'GUIA DE ALMACEN';
         this.numero_documento = response.documento.cod_guia_despacho;
         this.numero_documento_2 = response.documento.cod_nota;
         this.numero_control = response.documento.cod_guia_despacho;
         this.documento = this.guia_almacen ;

         this.path_0 = "../../../assets/img/logo_hd.png";
         this.path_1 = "../../../assets/img/FC.png";
         this.path_2 = "../../../assets/img/bar.png";
         this.path_3 = "../../../assets/img/blank.png";
        }

        if (response.documento.tipo_pedido.toUpperCase() === 'C') {
            this.texto = 'GUIA DE ALMACEN';
            this.numero_documento = response.documento.cod_guia_despacho;
            this.numero_documento_2 = response.documento.cod_consignacion;
            this.numero_control = response.documento.cod_guia_despacho;
            this.documento = this.guia_almacen ;
   
            this.path_0 = "../../../assets/img/logo_hd.png";
            this.path_1 = "../../../assets/img/FC.png";
            this.path_2 = "../../../assets/img/bar.png";
            this.path_3 = "../../../assets/img/blank.png";
           }
    
                     var doc_ ={
                         pageSize: 'LETTER',
                         background: [
                             { image:  await this.getBase64ImageFromURL("../../../assets/img/marca_3.png") , width: 811, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
                           ],
                           info:{
                             title:this.documento+this.numero_documento,
                             author: this.titulo,
                             subject:'DOCUMENTO',
                           },
                         content  :[
                             {   style: 'header',
                                 table:{
                                     headerRows: 1,
                                     widths: [80,400,80],
                                     fontSize: 8,
                                     body:[
                                         [  
                                            {image: await this.getBase64ImageFromURL(this.path_0), width: 80, height:85,alignment:'center'},
                                             {image: await this.getBase64ImageFromURL(this.path_1), width: 300, height:85,alignment:'center'},
                                             {  style:'cod_control',
                                                 table: {
                                                margin: [1,5,1,0],
                                                body: [
                                                    ['CODIGO:'],
                                                    [{text:this.numero_control,fontSize:12,alignment:'left',bold:true}]
                                                ]
                                            },fontSize: 8,
                                            layout: {
                                                defaultBorder: false,
                                            }
                                        },
                                         ]
                                     ],  
                                 },layout: {
                                     defaultBorder: false,
                                 },
                             },
                             { 
                                style: 'linea',
                                table: {
                                    headerRows: 1,
                                    widths: ['*'],
                                    fontSize: 8,
                                    body: [
                                        [
                                            {image: await this.getBase64ImageFromURL(this.path_2),width: 570 , height:42 ,alignment:'center',},
                                        ]      
                                    ],
                                },layout: {
                                    defaultBorder: false,
                                },
                            },
                             { 
                                 style: 'fila_1',
                                 table: {
                                     headerRows: 1,
                                     widths: ['*', 200, '*','*'],
                                     fontSize: 7,
                                     body: [
                                         [    
                                             {   
                                                 text:  'Codio cliente: '+response.documento.cod_cliente,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text:  'Cliente: '+response.documento.des_cliente,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text: 'Documento venta: '+ this.numero_documento_2,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text: 'Fecha: '+response.documento.fecha_inicio,
                                                 fontSize: 7,
                                                 with: '*',
                                                 alignment:'right',
                                                 border: [false, false, false, false],
                                             },
                                         ],
                                         [    
                                             {   
                                                 text: 'Usuario: '+response.documento.des_usuario,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             
                                             {   
                                                 text: 'Codigo usuario: '+response.documento.cod_usuario,
                                                 fontSize: 7,
                                                 with: '*',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text: 'Peso total: '+response.documento.total_peso+' Kg',
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
     
                                             {   
                                                 text: 'Hora: '+response.documento.hora_inicio,
                                                 fontSize: 7,
                                                 with: '*',
                                                 alignment:'right',
                                                 border: [false, false, false, false],
                                             },
                                         ]
                                     ],
                                 }, 
                             },
                             { 
                                 style: 'data',
                                 table: {
                                     headerRows: 1,
                                     widths: [65, 40, 200, 50, 25, 15, 15, '*',10],
                                     body: tablaEnLista,
                                     fontSize: 8,
                                 },layout: {
                                    defaultBorder: true,
                                },
                             }
                         ],
                         styles: {
                            cod_control: {
                                margin: [ 0, 10, 10, 5 ]
                            },
                             header: {
                                 margin: [ -20, -20, -20, 0 ]
                             },
                             linea: {
                                margin: [ -20, -15, -20, 0 ],
                                padding:[0],
                                lineHeight:[0.5],
                            },
                             fila_1: {
                                 margin: [ -20, 0, -20, 5],
                                 padding:[0,0,0,1],
                                 lineHeight:[0.6],
                             },
                             data: {
                                 margin: [ -20, 0, -20, 0],
                                 lineHeight:[0.6],
                             }
                             
                         },
                     }
        pdf.createPdf(doc_).download(this.documento+this.numero_documento);
                 
    } catch (error) {
        console.log(error);
    }
}
private  generarTablaOperaciones(data:any){

let arry =[
    [
        {text: 'Lote', alignment: 'left',margin:[0,0,0,2], fontSize: 7, border: [true, true, false, true], },
        {text: 'Codigo', alignment: 'left',margin:[0,0,0,2], fontSize: 7, border: [false, true, false, true], }, 
        {text: 'Descripción', alignment: 'left',margin:[0,0,0,2], fontSize: 7, border: [false, true, false, true],}, 
        {text: 'Peso', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
        {text: 'UND', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
        {text: 'UM', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
        {text: 'B', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
        {text: 'Peso T', alignment: 'right', fontSize: 7,border: [false, true, false, true],},
        {text: '(x)', alignment: 'right', fontSize: 7,border: [false, true, true, true],},
    ],
]

for (const i in data) {

    let articulo = 
[
    {text: data[i].cod_lote, alignment: 'left',margin:[0,0,0,2], fontSize: 8,border: [false, false, false, false], }, 
    {text: data[i].cod_producto, alignment: 'left',margin:[0,0,0,2], fontSize: 8,border: [false, false, false, false],}, 
    {text: data[i].des_producto, alignment: 'left',margin:[0,0,0,2], fontSize: 8,border: [false, false, false, false],},
    {text: data[i].tamano_producto+' grs',alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [true, true, true, true], }, 
    {text: data[i].cant_producto, alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [true, true, true, true],}, 
    {text: data[i].um_prod, alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [true, true, true, true],}, 
    {text: data[i].cant_blts, alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [true, true, true, true],},
    {text: data[i].total_peso+' grs', alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [true, true, true, true],},
    {text: '', alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [true, true, true, true],}
    
]
arry.push(articulo);


}

return arry;
}

public async pdfBackOrder(response:any){
    try {
        
         console.log(response);

        const fontsSize = 9
        const tablaEnLista = this.generarTablaBackOrder(response.pdf);

            this.texto = 'BackOrder report';
            this.numero_documento = response.documento.cod_documento;
            this.numero_documento_2 = '';
            this.numero_control = response.documento.cod_backorder;
            this.documento = this.back_order ;
   
            this.path_0 = "../../../assets/img/logo_hd.png";
            this.path_1 = "../../../assets/img/FC.png";
            this.path_2 = "../../../assets/img/bar.png";
            this.path_3 = "../../../assets/img/blank.png";
           
    
                     var doc_ ={
                         pageSize: 'LETTER',
                         background: [
                             { image:  await this.getBase64ImageFromURL("../../../assets/img/marca_3.png") , width: 811, margin:[0,0,0,0] ,absolutePosition: {x: 0, y: 0},},
                           ],
                           info:{
                             title:this.documento+this.numero_documento,
                             author: this.titulo,
                             subject:'DOCUMENTO',
                           },
                         content  :[
                             {   style: 'header',
                                 table:{
                                     headerRows: 1,
                                     widths: [80,400,80],
                                     fontSize: 8,
                                     body:[
                                         [  
                                            {image: await this.getBase64ImageFromURL(this.path_0), width: 80, height:85,alignment:'center'},
                                             {image: await this.getBase64ImageFromURL(this.path_1), width: 300, height:85,alignment:'center'},
                                             {  style:'cod_control',
                                                 table: {
                                                margin: [1,5,1,0],
                                                body: [
                                                    ['CODIGO:'],
                                                    [{text:this.numero_control,fontSize:12,alignment:'left',bold:true}]
                                                ]
                                            },fontSize: 8,
                                            layout: {
                                                defaultBorder: false,
                                            }
                                        },
                                         ]
                                     ],  
                                 },layout: {
                                     defaultBorder: false,
                                 },
                             },
                             { 
                                style: 'linea',
                                table: {
                                    headerRows: 1,
                                    widths: ['*'],
                                    fontSize: 8,
                                    body: [
                                        [
                                            {image: await this.getBase64ImageFromURL(this.path_2),width: 570 , height:42 ,alignment:'center',},
                                        ]      
                                    ],
                                },layout: {
                                    defaultBorder: false,
                                },
                            },
                             { 
                                 style: 'fila_1',
                                 table: {
                                     headerRows: 1,
                                     widths: ['*', 200, '*','*'],
                                     fontSize: 7,
                                     body: [
                                         [    
                                             {   
                                                 text:  'Codigo cliente: '+response.documento.cod_cliente,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text:  'Cliente: '+response.documento.des_cliente,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text: 'Documento venta: '+ this.numero_documento,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text: 'Fecha: '+response.documento.fecha_inicio,
                                                 fontSize: 7,
                                                 with: '*',
                                                 alignment:'right',
                                                 border: [false, false, false, false],
                                             },
                                         ],
                                         [    
                                             {   
                                                 text: 'Usuario: '+response.documento.des_usuario,
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             
                                             {   
                                                 text: 'Codigo usuario: '+response.documento.cod_usuario,
                                                 fontSize: 7,
                                                 with: '*',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
                                             {   
                                                 text: '',
                                                 fontSize: 7,
                                                 with: '*',
                                                 color:'#000',
                                                 alignment:'left',
                                                 border: [false, false, false, false],
                                             },
     
                                             {   
                                                 text: 'Hora: '+response.documento.hora_inicio,
                                                 fontSize: 7,
                                                 with: '*',
                                                 alignment:'right',
                                                 border: [false, false, false, false],
                                             },
                                         ]
                                     ],
                                 }, 
                             },
                             { 
                                 style: 'data',
                                 table: {
                                     headerRows: 1,
                                     widths: [65, '*', 50, 50, 50],
                                     body: tablaEnLista,
                                     fontSize: 8,
                                 },layout: {
                                    defaultBorder: true,
                                },
                             }
                         ],
                         styles: {
                            cod_control: {
                                margin: [ 0, 10, 10, 5 ]
                            },
                             header: {
                                 margin: [ -20, -20, -20, 0 ]
                             },
                             linea: {
                                margin: [ -20, -15, -20, 0 ],
                                padding:[0],
                                lineHeight:[0.5],
                            },
                             fila_1: {
                                 margin: [ -20, 0, -20, 5],
                                 padding:[0,0,0,1],
                                 lineHeight:[0.6],
                             },
                             data: {
                                 margin: [ -20, 0, -20, 0],
                                 lineHeight:[0.6],
                             }
                             
                         },
                     }
        pdf.createPdf(doc_).download(this.documento+this.numero_control);
                 
    } catch (error) {
        console.log(error);
    }
}
private  generarTablaBackOrder(data:any){

let arry =[
    [
        
        {text: 'Codigo', alignment: 'left',margin:[0,0,0,2], fontSize: 7, border: [false, true, false, true], }, 
        {text: 'Descripción', alignment: 'left',margin:[0,0,0,2], fontSize: 7, border: [false, true, false, true],}, 
        {text: 'UND', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
        {text: 'Precio', alignment: 'right',margin:[0,0,0,2], fontSize: 7,border: [false, true, false, true],},
        {text: 'Total', alignment: 'right', fontSize: 7,border: [false, true, false, true],},
        
    ],
]

for (const i in data) {

    let articulo = 
[
    
    {text: data[i].cod_producto, alignment: 'left',margin:[0,0,0,2], fontSize: 8,border: [false, false, false, false],}, 
    {text: data[i].des_producto, alignment: 'left',margin:[0,0,0,2], fontSize: 8,border: [false, false, false, false],},
    {text: data[i].cant_producto, alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [false, false, false, false],}, 
    {text: data[i].precio_producto, alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [false, false, false, false],},
    {text: data[i].total_monto_producto, alignment: 'right',margin:[0,0,0,2], fontSize: 8,border: [false, false, false, false],},
    
    
]
arry.push(articulo);

}

return arry;
}
////////////////////////////OPERRACIONES/////////////////////////////////////////////
 private getBase64ImageFromURL(url) {
    
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }
}