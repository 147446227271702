<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">

    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente">

            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarfactura("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTE FACTURA" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='descargar_reporte_ventas("ventas")'><mat-icon >list</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTE GENERAL" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='descargar_reporte_ventas2("ventas")'><mat-icon >list</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTE GENERAL UNIDADES VENDIDAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='descargar_reporte_ventas3("ventas")'><mat-icon >list</mat-icon></button></li>
                    <li *ngIf="create" class="nav-item"><button matTooltip="NOTAS DE CREDITO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color: rgb(255, 255, 255); background-color: rgb(3, 141, 84); " (click)='notas_credito_admin_single("","AD-SN")'><mat-icon >account_balance_wallet</mat-icon></button></li>
                    <!--  <li *ngIf="read" class="nav-item"><button matTooltip="ESTABLECER MONTO DEL DAILY" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)="ajustes_almacen(element,'cargo')"><mat-icon >attach_money</mat-icon></button></li> -->
                    <li *ngIf="read" class="nav-item"><button matTooltip="ENVIAR DAILY REPORT" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)="dailyreport()"><mat-icon >email</mat-icon></button></li>
                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); border-bottom: 1px solid rgb(255, 255, 255);"><b>CONSULTAR FACTURAS</b></span>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table ">
        <div class="col-md-12 px-0 border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_factura">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Factura</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_factura}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_pedido">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Pedido</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_pedido}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_lista_precio">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Precios</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_lista_precio}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Cod cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_cliente}} </td>

                </ng-container>

                <ng-container matColumnDef="estatus_factura">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Estatus </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus_factura}} </td>

                </ng-container>

                <ng-container matColumnDef="fechas">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Fechas</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="read" matTooltip="FECHAS DE ESTATUS" mat-raised-button (click)='fechas(element,"fech")' style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(33, 119, 69);">
                          <mat-icon >today</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!read" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >today</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detalle">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Detalle</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="read" matTooltip="DETALLE FACTURA" (click)='detallepedido(element,"montos")' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(8, 143, 221);">
                          <mat-icon >image_search</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!read" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >image_search</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="read" matTooltip="IMPRIMIR FACTURA" mat-raised-button (click)="imprimirfactura(element,'imprimir')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 86, 247);">
                            <mat-icon >print</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!read" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >print</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="descargar">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Descargar</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="read" matTooltip="DESCARGAR FACTURA" mat-raised-button (click)="descargarFactura(element,'descargar')" style="border: outset 3px rgb(185, 75, 248); height: 35px; color:rgb(255, 255, 255); background-color: rgb(158, 0, 250);">
                            <mat-icon >get_app</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!read" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >get_app</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nota_dev">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Devolución</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="create" matTooltip="CREAR NOTA DE CREDITO" mat-raised-button (click)="notasCredito(element,'dev')" style="border: outset 3px rgb(89, 72, 165); height: 35px; color:rgb(255, 255, 255); background-color: rgb(66, 43, 167);">
                            <mat-icon >credit_card</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!create" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="border: outset 3px rgb(56, 56, 56); height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >credit_card</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="nota_admin">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Administrativa</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="create" matTooltip="CREAR NOTA DE CREDITO" mat-raised-button (click)="notasCredito(element,'admin')" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(212, 30, 115);">
                            <mat-icon >business</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!create" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >business</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="anular">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Anular</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="delete" matTooltip="ANULAR FACTURA" mat-raised-button (click)="anular_factura(element)" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(214, 31, 31);">
                            <mat-icon >delete</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!delete" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>