import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Console } from 'console';
import { MatPaginator } from '@angular/material/paginator';
import { FormKeyComponent } from '../form-key/form-key.component';
import { FormHistorialParametrosVentasComponent } from '../form-historial-parametros-ventas/form-historial-parametros-ventas.component';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-parametros',
  templateUrl: './form-parametros.component.html',
  styleUrls: ['./form-parametros.component.css']
})
export class FormParametrosComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('des_definicion',{static:true}) des_definicion:ElementRef;
  @ViewChild('operacion',{static:true}) operacion:ElementRef;
  @ViewChild('descrip_definicion',{static:true}) descrip_definicion:ElementRef;
  @ViewChild('var_defi',{static:true}) var_defi:ElementRef;

  producto:any = [];
  producto_2:any = [];
dialogSpin:any;
codigo:any = [];
codigo_2:any = [];
  forma:FormGroup;
  accion:any;
  roles:any;
  stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
arry: Observable<StateGroup[]>;
arry2: Observable<StateGroup[]>;
  rol = [];
  unidad=[];
  unidadp=[];

  parametros_precios:any = [];
  parametros_operacion:any = [];
  parametros_montos:any = [];
  key_hash:any;
  cod_rol:any;
  menu = [];
  menu2 = [];
  menur:any;
  menur2:any;
  secuencia:any;
  menur3:any;
  unidades:any;
  input_dirty:any;
  submenur:any;
  submenu = [];
  submenuhijo = [];
  cant:any = false;
  xmenu = [];
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
   
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private dialog: MatDialog, 
    private servicio:nkServices,
    private formb:FormBuilder, 
    private fb:FormBuilder,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    private dialogRefp: MatDialogRef<FormParametrosComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

    console.log(data, 'primero');

    this.forma = fb.group({
      'cod_definicion': '',
      'des_definicion': '',
      'descrip_definicion':'',
      'cod_usuario':'',
      'des_usuario':'',
      'operacion':'',
      'tipo_monto':'',
      'val_def':'0,00',
      'objeto_definicion':'',
      'modo':'',
      'cambio_valor':'',
      'cod_empresa':''
    });
   }

ngOnInit(): void {
  this.listar_parametros_oper_ventas(null);
  this.listarDefiniciones(localStorage.getItem('sub_main'));
}
@ViewChild(MatPaginator) paginator: MatPaginator;

ngAfterViewInit() {
  this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
}
async listarDefiniciones(objeto:any){
  try {
    var xvalor ={cod_menu:objeto};
     const response = await this.servicio.listarDefinicionesConceptoshttp(xvalor).toPromise();
     console.log(response);
          this.parametros_precios= [];
          this.parametros_operacion= [];
          this.parametros_montos= [];
          for (const i in response) {
            if (response[i].des_definicion.toUpperCase() ===  await this.atrb.PARAMETROS_VENTAS.parametro_precio.toUpperCase()) {
              this.parametros_precios.push(response[i]);
            }
            if (response[i].des_definicion.toUpperCase() === await this.atrb.PARAMETROS_VENTAS.opereaciones_definidas.toUpperCase()) {
              this.parametros_operacion.push(response[i]);
            }
            if (response[i].des_definicion.toUpperCase() === await this.atrb.PARAMETROS_VENTAS.tipo_de_montos.toUpperCase()) {
              this.parametros_montos.push(response[i]);
            }
          }
  } catch (error) {
    await this.notifi.customToastNotification(error.error.message);
  }
}

/////////////////////////////////////////////////////////////////////
async listar_parametros_oper_ventas(msj:any){
  try {
    await this.util.abrirSpinner();
      let msg = msj;
      this.ELEMENT_DATA = [];
      let xvalor = {
        cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
      };
      const response = await this.servicio.listarParametrosOperVentashttp(xvalor).toPromise();
          for (const i in response) {
            if (response[i].operacion.toUpperCase() === 'FCTRCN' && !this.accion) {
              response[i].operacion = 'FACTURACIÓN';
            }
            this.ELEMENT_DATA.push({
              cod_definicion:response[i].cod_definicion,
              des_definicion:response[i].des_definicion,
              descrip_definicion:response[i].descrip_definicion,
              cod_usuario:response[i].cod_usuario,
              des_usuario:response[i].des_usuario,
              estatus:response[i].estatus,
              operacion:response[i].operacion,
              val_def:response[i].val_def,
              tipo_monto:response[i].tipo_monto,
              fecha_inicio:response[i].fecha_inicio,
              hora_inicio:response[i].hora_inicio,
              accion:'',
              accion2:'',
              accion3:''
            });
          }
          if (!this.accion) {
            this.displayedColumns = [await this.atrb.TABLE_PARAMETROS_OPER_VENTAS_FALSE];
          }
          if (this.accion) {
            this.displayedColumns = [await this.atrb.TABLE_PARAMETROS_OPER_VENTAS_TRUE];
          }
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      if(msg == await this.atrb.ACCION_ACTUALIZAR){
        await this.util.cerrarSpinner();
          await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
      }else{
        await this.util.cerrarSpinner();
      }
  } catch (error) {
    if (!this.accion) {
      this.displayedColumns = [await this.atrb.TABLE_PARAMETROS_OPER_VENTAS_FALSE];
    }
    if (this.accion) {
      this.displayedColumns = [await this.atrb.TABLE_PARAMETROS_OPER_VENTAS_TRUE];
    }
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
            await this.util.cerrarSpinner();
              await this.notifi.customToastNotification(error.error.message);
  }
}

async historial_parametros_ventas(element:any){
  try {    await this.util.abrirSpinner();
           await this.servicio.consultar_sesionhttp(null).toPromise();
           await this.util.cerrarSpinner();
                          var dialogRef = this.dialog.open(FormHistorialParametrosVentasComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'100vh',
                            data: {id:'',
                                  cod_definicion:element.cod_definicion,
                                  accion:''},
                            disableClose: true,
                            panelClass: 'pedidos_modal3',
                            backdropClass: "bdrop"
                          });
                          dialogRef.afterClosed().subscribe(async result => {
                            await this.util.abrirSpinner();
                            if(result != 'cerrar'){
                              await this.util.cerrarSpinner();
                            }else{
                              await this.util.cerrarSpinner();
                            }
                          });
  } catch (error) {
    await this.util.notificacionErrorApp(error.error.message);
  }
}

async monto_parametro_ventas(xvalor:any){
  try {
           await this.util.abrirSpinner();
           await this.servicio.consultar_sesionhttp(null).toPromise();
           await this.util.cerrarSpinner();
       xvalor.termino = 'OPVNTS';
       var dialogRef =  this.dialog.open(FormmMsgComponent, {
           width: '100vw',
           maxWidth: '100vw',
           height:'auto',
           data:    {
                      cod_dep:xvalor.cod_dep,
                      productos:xvalor.productos,
                      accion:'OPVNTS'},
                     disableClose: true,
                     panelClass: 'pedidos_modal',
                     backdropClass: "bdrop"
         });
         dialogRef.afterClosed().subscribe(async result => {
          await this.util.abrirSpinner();
          if(result != 'cerrar'){
            this.forma.patchValue({
              cod_usuario: await this.atrb.COD_USUARIO.cod_usuario,
              des_usuario: await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
              cod_definicion:xvalor.cod_definicion,
              des_definicion:xvalor.des_definicion,
              descrip_definicion:xvalor.descrip_definicion,
              tipo_monto:xvalor.tipo_monto,
              val_def:xvalor.val_def,
              cambio_valor:result.monto,
              modo: await this.atrb.ACCION_ACTUALIZAR,
              cod_empresa: await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa)
            });
            await this.gestionDefinicionesSistemaOperVentas(this.forma.value);
          }else{
            await this.util.cerrarSpinner();
          }
        });
  } catch (error) {
     await this.util.notificacionErrorApp(error.error.message);
  }
}

async modalrespuesta(){
  try {
      await this.util.abrirSpinner();
    if ((document.getElementById("descrip_definicion") as HTMLInputElement).value !== '' &&
        (document.getElementById("des_definicion") as HTMLInputElement).value !== '' &&
        (document.getElementById("operacion") as HTMLInputElement).value !== '') {
     
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'auto',
        data: { 
              accion:''},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop" 
      });
      dialogRef.afterClosed().subscribe(async result => {
        if(result != 'cerrar'){
          this.forma.patchValue({
            cod_usuario: await this.atrb.COD_USUARIO.cod_usuario,
            des_usuario: await this.hashkey.decrypt(this.atrb.NOMBRE_USUARIO.nombre)+' '+await this.hashkey.decrypt(this.atrb.APELLIDO_USUARIO.apellido),
            objeto_definicion:this.ELEMENT_DATA,
            modo:await this.atrb.ACCION_CREAR,
            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa)
          });
           await this.gestionDefinicionesSistemaOperVentas(this.forma.value);
        } else{
          await this.util.cerrarSpinner();
        }
      });
    }else{
      await this.util.cerrarSpinner();
        await this.util.notificacionErrorApp('Existen campos vacios');
    }
    
  } catch (error) {
    await this.util.cerrarSpinner();
        await this.util.notificacionErrorApp(error);
  }
}

async gestionDefinicionesSistemaOperVentas(xvalor:any){
  try {
      const response = await this.servicio.gestionDefinicionesSistemaOperVentashttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
          await this.util.notificacionErrorApp(response.message);
          await this.listar_parametros_oper_ventas(null);
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.util.notificacionErrorApp(error.error.message);
    await this.listar_parametros_oper_ventas(null);
  }
 }

async cerrarmodal(){
  await this.util.abrirSpinner();
  var accion_ = 'salir'
  var dialogRef = this.dialog.open(FormmMsgComponent, {
    width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
    data: {id:'',
          accion:accion_},
    disableClose: true,
    panelClass: 'pedidos_modal',
    backdropClass: "bdrop"
  });
  dialogRef.afterClosed().subscribe(async result => {
    if (result != 'cerrar') {
      await this.util.cerrarSpinner();
      this.dialogRefp.close('cerrar');
    }else{
      await this.util.cerrarSpinner();
    }
  });
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
