import { Component, Injectable, OnInit, ViewChild} from '@angular/core';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';

import 'offline-js'
//esto es de mi desarrollo 
import {MatDialog} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import { FormCotizacionReqComponent } from 'src/app/componentes/shared/modal/form-cotizacion-req/form-cotizacion-req.component';
import { FormAprobarCotizacionComponent } from 'src/app/componentes/shared/modal/form-aprobar-cotizacion/form-aprobar-cotizacion.component';
import { FormCrearCotizacionComponent } from 'src/app/componentes/shared/modal/form-crear-cotizacion/form-crear-cotizacion.component';
import { FormCrearProveedorComprasComponent } from 'src/app/componentes/shared/modal/form-crear-proveedor-compras/form-crear-proveedor-compras.component';
import { FormmMsgComponent } from '../../../shared/modal/formm-msg/formm-msg.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-gestion-proveedores',
  templateUrl: './gestion-proveedores.component.html',
  styleUrls: ['./gestion-proveedores.component.css']
})
export class GestionProveedoresComponent implements OnInit {

  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;

  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
  aprobar:any=false

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';



  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {
    
    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};


    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }
/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
    
  }

  ngOnInit(): void {
    this.listarSolicitudesCompra()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  listarSolicitudesCompra(){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
        this.servicio.listarProveedoreshttp().subscribe(
          response =>{
            console.log(response, )
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');

              this.ELEMENT_DATA = response;
    
              this.displayedColumns = [
                'cod_proveedor',
                'des_proveedor',
                'estatus',
                'tipo_prov',
                'actualizar',
                
              ];
              if(this.special){
                this.displayedColumns.push('activar')
              }
                /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              'cod_proveedor',
              'des_proveedor',
              'estatus',
              'tipo_prov',
              'actualizar',
              'activar',
            ];
            if(this.special){
              this.displayedColumns.push('activar')
            }
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
          }
        );
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  crearProveedor(){

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {

      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

        this.dialogSpin.close()

        var dialogRef;
        dialogRef = this.dialog.open(FormCrearProveedorComprasComponent,{
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data:'',
        disableClose:   true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });

        dialogRef.afterClosed().subscribe(resul => {

          if(resul != 'cerrar'){

            console.log('Se cerro la wea')
            this.listarSolicitudesCompra()

          }

      
      })},
      error =>{
        this.dialogSpin.close()
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
      )
      
    }, 1000);

  }

  activaOinactivarProveedor(element: any){

    if(element.estatus === 'P'){
      this.snackBar.open('Le faltan datos para poder activar este proveedor','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});

      return
    }
    const obj = this.ELEMENT_DATA.find(ele => ele.cod_proveedor === element.cod_proveedor)
    obj.loading = true
    this.servicio.validar_acceso_acciones_http({val_def:'CMPRS',tipo_operacion:'PROVEEDORCTV', cod_usuario: localStorage.getItem('cod_usuario')}).subscribe(
      ()=>{
        this.servicio.activaOinactivarProveedorhttp(element.cod_proveedor).subscribe(
          () => {
            const obj = this.ELEMENT_DATA.find(ele => ele.cod_proveedor === element.cod_proveedor)
            if(!Number(obj.isActive)){
              obj.isActive = '1'
            }else{
              obj.isActive = '0'
            }
            obj.loading = false
          },
          error => {
            obj.loading = false
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          })

      },
      error => {
        obj.loading = false
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    )
  }

  actualizarProveedor(elemento:any){

    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });

    setTimeout(() => {

      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};


      this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

        this.dialogSpin.close()

        
  
        var dialogRef;
        dialogRef = this.dialog.open(FormCrearProveedorComprasComponent,     {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data:{proveedor:elemento, existe: true},
        disableClose:   true,
        panelClass: 'pedidos_modal3',
        backdropClass: "bdrop"
        });
  
        dialogRef.afterClosed().subscribe(resul => {
  
          if(resul != 'cerrar'){
  
            console.log('Se cerro la wea')
            this.listarSolicitudesCompra()
  
          }
  
       
      })
      },
      error =>{
        this.dialogSpin.close()
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
      
      )
      
    }, 1000);


  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion():void{
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
  Number(data){
    return Number(data)
  }
  Activate(element){
    const obj = this.ELEMENT_DATA.find(ele => ele.cod_proveedor === element.cod_proveedor)
    if(!Number(obj.isActive)){
      obj.isActive = '1'
    }else{
      obj.isActive = '0'
    }
  }

  sin_permiso():void{
    try {
      this.snackBar.open('Permiso no habilitado.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:1500,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,})
    }
  }
}
