import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormModifiPrecioComponent } from '../form-modifi-precio/form-modifi-precio.component';
import { FormFormulaUnidadComponent } from '../form-formula-unidad/form-formula-unidad.component';

import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};


export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-entrada-mp',
  templateUrl: './form-entrada-mp.component.html',
  styleUrls: ['./form-entrada-mp.component.css']
})
export class FormEntradaMpComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_producto') cod_producto:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('cod_prov',{static:true}) cod_prov:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('factura_prov',{static:true}) factura_prov:ElementRef;
  @ViewChild('lote_prov',{static:true}) lote_prov:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('tasa_base',{static:true}) tasa_base:ElementRef;

  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;

  arry: Observable<StateGroup[]>;
  arry2: Observable<StateGroup[]>;


  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
proveedor:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormEntradaMpComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');


      this.forma = fb.group({
        'cod_usuario':data.cod_usuario,
        'des_usuario':data.des_usuario,
        'cod_producto':'',
        'cod_prov':'',
        'cant_producto':'',
        'lote_prov':'',
        'factura_prov':'',

      });
    }

  ngOnInit(): void {
    this.formulario();
    this.listarproducto();
    this.listarproveedor();

  }
  ngAfterViewInit() {

  }

  private _filterGroup(value: string): StateGroup[] {
    console.log(value);
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }
    return this.stateGroups;
 }
 private filterGroup(value: string): StateGroup[] {
  console.log(value);
  if (value) {
    return this.stateGroups2
      .map(group => ({letter: group.letter, names: filter(group.names, value), code: filter(group.code, value)}))
      .filter(group => group.names.length > 0 || group.code.length > 0 );
  }
  return this.stateGroups2;
}
  listarproducto(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarProductoMphttp().subscribe(
      response =>{

        this.arry = this.forma.get('cod_producto')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }

                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.producto
                  }];

                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  listarproveedor(){
    this.proveedor = [];

    this.servicio.listarProvhttp().subscribe(
      response =>{

        this.arry2 = this.forma.get('cod_prov')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this.filterGroup(value))
                                );
                  for (const i in response) {
                    this.proveedor.push(response[i].cod_prov+' '+'( '+response[i].des_prov+' )');
                  }

                  this.stateGroups2= [{
                    letter: 'Proveedores',
                    names: this.proveedor,
                    code: this.proveedor
                  }];

                  console.log(this.stateGroups);
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  formulario():void{
    this.ELEMENT_DATA = [];
    this.cant = false;
    this.displayedColumns = [
      'factura_prov',
      'lote_prov',
      'cod_producto',
      'des_producto',
      'cant_producto',
      'unidad_prod',
      'remov_elem'
     ];
  /* this.dataSource = this.ELEMENT_DATA; */
  this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  addElement() {
    let arry = [];
    let arry2 = [];
    this.forma.value.cant_producto = this.cant_producto.nativeElement.value.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.');

    console.log(this.forma);

    if (parseInt(this.cant_producto.nativeElement.value) > 0 && this.cod_producto.nativeElement.value != '' && 
    this.cod_prov.nativeElement.value != '' &&  this.factura_prov.nativeElement.value != '' && this.lote_prov.nativeElement.value != '') {
      var cod = this.forma.value.cod_producto.split(' ');
    var valor:any = {cod_producto:cod[0]}
    console.log(cod);
    this.servicio.buscarProdMpEntradahttp(valor).subscribe(
      response =>{
        console.log(response);

        if (this.ELEMENT_DATA.length == 0) {
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];

          this.listarproducto();
          this.ELEMENT_DATA.push({
            cod_producto:response[0].cod_producto,
            des_producto:response[0].des_producto,
            cant_producto:parseInt(this.forma.value.cant_producto).toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2'),
            unidad_prod:response[0].unidad_medida_producto,
            lote_prov:this.lote_prov.nativeElement.value,
            factura_prov:this.factura_prov.nativeElement.value,
            
          });

          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
          this.cod_producto.nativeElement.value = '';
          this.cant_producto.nativeElement.value = '';

        }else{

          console.log(this.ELEMENT_DATA);
            for (const i in this.ELEMENT_DATA) {
             arry.push(this.ELEMENT_DATA[i].cod_producto);
            }
            var index = arry.includes(response[0].cod_producto);

            if (!index) {
              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];
              this.listarproducto();

              this.ELEMENT_DATA.push({
                cod_producto:response[0].cod_producto,
                des_producto:response[0].des_producto,
                cant_producto:parseInt(this.forma.value.cant_producto).toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2'),
                unidad_prod:response[0].unidad_medida_producto,
                lote_prov:this.lote_prov.nativeElement.value,
                factura_prov:this.factura_prov.nativeElement.value
              });
    
              this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
              this.cod_producto.nativeElement.value = '';
              this.cant_producto.nativeElement.value = '';

            }else{

              if (index) {
                this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
                  this.cod_producto.nativeElement.value = '';
                  this.cant_producto.nativeElement.value = '';
              }

              this.stateGroups2 =[];
              this.lotepro = [];
              this.codigo = [];
              this.producto = [];
              this.listarproducto();


            }
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
          this.cod_producto.nativeElement.value = '';
              this.cant_producto.nativeElement.value = '';
      }
    );
    }else{
        if (parseInt(this.cant_producto.nativeElement.value) <= 0 || isNaN(this.cant_producto.nativeElement.value) || this.cant_producto.nativeElement.value === "") {
          this.snackBar.open('La cantidad debe ser mayor a 0 y solo puede ingresar numeros', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.cod_producto.nativeElement.value  === "") {
          this.snackBar.open('Debes seleccionar al menos un producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.factura_prov.nativeElement.value  === "" ) {
          this.snackBar.open('Debes colocar el numero de la factura de entrada', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.lote_prov.nativeElement.value  === "") {
          this.snackBar.open('Debes colocar el numero del lote entrante', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        if (this.cod_prov.nativeElement.value  === "") {
          this.snackBar.open('Debes seleccionar el proveedor', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
    }
  }

modalrespuesta(){
  if (this.ELEMENT_DATA.length > 0) {
    this.accion = "enviarordenprod";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '25%',
      height:'25%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){

       /*  this.ELEMENT_DATA[0].cant_producto =  this.ELEMENT_DATA[0].cant_producto.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.'); */
        console.log(this.ELEMENT_DATA);
        this.forma.value.ord_producto = this.ELEMENT_DATA;
        console.log(this.forma.value);
       /*  this.dialogRefp.close(this.forma.value); */

      }
    });
  }else{
    this.snackBar.open('Debes seleccionar al menos un producto', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
eliminarItems(val:any){
  this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
   this.table.renderRows();
 }
  cerrarmodal(): void {
    this.dialogRefp.close('cerrar');
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }
  async detalleOrdenProd(xvalor:any){
    console.log(xvalor);
    this.dialog.open(FormFormulaUnidadComponent, {
      width: '100%',
      height:'85%',
      data: {producto: xvalor},
      panelClass: 'pedidos_modal'
    });
}
format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
