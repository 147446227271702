import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormRecepcionCantProductoCalidadComponent } from '../form-recepcion-cant-producto-calidad/form-recepcion-cant-producto-calidad.component';
import { FormRecepcionVerDocumentoComponent } from '../form-recepcion-ver-documento/form-recepcion-ver-documento.component';
import {MatPaginator} from '@angular/material/paginator';
import { element, error } from 'protractor';
import { filter } from '../form-pedido-frecuencial/form-pedido-frecuencial.component';

@Component({
  selector: 'app-form-recepcion-ver-recepciones-recibidas',
  templateUrl: './form-recepcion-ver-recepciones-recibidas.component.html',
  styleUrls: ['./form-recepcion-ver-recepciones-recibidas.component.css']
})
export class FormRecepcionVerRecepcionesRecibidasComponent implements OnInit {

  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  productos:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`
  ficha_proveedor: any;
  iva_proveedor: any;
  Subtotal:any = 0;
  SubtotalShow:any = 0;
  iva:any = 0 ;
  ret_iva:any = 0;
  ret_ivaShow:any = 0;
  Total:any = 0;
  TotalShow:any = 0;
  flete:any = 0;
  moneda:any;
  subtotalIva: any = 0;
  subtotalIvaShow: any = 0;
  DATA:any;
  reten_prov:any = '0';

  calidad:any= false


  dataSource=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormRecepcionVerRecepcionesRecibidasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {

      this.data.accion == 'calidad' ? this.calidad = true : null

      console.log(this.data,'la data 123')


      if(this.calidad){

        this.mostrarTablaCalidad()

      }else{

        this.mostrarTabla()

      }

     

    }


    mostrarTablaCalidad(){
  
      this.servicio.verRecepcionRecibida(this.data.orden.cod_recepcion).subscribe(response => {

        console.log(response)

        response.result.forEach(element => {

          element.cant_prod_calidad = '0'
          element.check_estatus = false
          
        });
        
  
        this.ELEMENT_DATA = response.result;
  
        console.log(response);
  
        this.displayedColumns = [  
          "cod_producto",
          "des_producto",
          "tipo_unidad_medida",
          "cant_desc",
          "cant_prod_calidad",
          "accion",
        ];
  
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  
      },
      error =>{
  
        
        this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              "cod_producto",
              "des_producto",
              "tipo_unidad_medida",
              "cant_desc",
              "cant_prod_calidad",
              "accion",
          ];
  
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
  
      })
  
    }
  
  
  
    mostrarTabla(){
  
      this.servicio.verRecepcionRecibida(this.data.orden.cod_recepcion).subscribe(response => {

        console.log(response)
        
  
        this.ELEMENT_DATA = response.result;
  
        console.log(response);
  
        this.displayedColumns = [  
          "cod_producto",
          "des_producto",
          "cant_desc",
          "tipo_unidad_medida",
        ];
  
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  
      },
      error =>{
  
        
        this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              "cod_producto",
              "des_producto",
              "cant_desc",
              "tipo_unidad_medida",
          ];
  
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
  
      })
  
    }
    
    mostrarTablaVer(){
  
      this.servicio.verRecepcionRecibida(this.data.orden.cod_recepcion).subscribe(response => {

        console.log(response)
        
  
        this.ELEMENT_DATA = response.result;
  
        console.log(response);
  
        this.displayedColumns = [  
          "cod_producto",
          "des_producto",
          "cant_desc",
          "tipo_unidad_medida",
        ];
  
        /* this.dataSource = this.ELEMENT_DATA; */
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  
      },
      error =>{
  
        
        this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              "cod_producto",
              "des_producto",
              "cant_desc",
              "tipo_unidad_medida",
          ];
  
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
  
      })
  
    }


    verDocumento(element:any){


      console.log(element,)

      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });


      this.servicio.facturasXRecepcionhttp(this.data.orden.cod_recepcion).subscribe(response => {

        console.log(response)

        this.dialogSpin.close()
        var dialogRef = this.dialog.open(FormRecepcionVerDocumentoComponent, {
          width:'100vw',
          maxWidth:'100vw',
          height:'100vh',
          data: response.result[0],
                disableClose:true,
          panelClass:'pedidos_modal3',
          backdropClass:"bdrop"
        });

      },
      error=>{

        console.log(error)
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});

      })
    }

    tomar_data(booleano:any, elemento:any){

      if(booleano){ 
        var dialogRef = this.dialog.open(FormRecepcionCantProductoCalidadComponent, {
          width:'100vw',
          maxWidth:'100vw',
          height:'100%',
          data: {des_producto:elemento.des_producto,
                cant_producto_soli:elemento.cant_desc,
                cod_producto:elemento.cod_producto,
                cant_ingresada:elemento.cant_prod_calidad,
                cod_orden_req:elemento.cod_orden_req
                },
                disableClose:true,
          panelClass:'pedidos_modal',
          backdropClass:"bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
  
          if(result != 'cerrar'){
  
            this.ELEMENT_DATA.forEach(element=> {
  
              if(element.cod_producto == result.cod_producto && element.cod_orden_req == result.cod_orden_req){
  
                element.cant_prod_calidad = result.cant_ingresada
                element.check_estatus = true
  
              }
  
            })
    
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
            console.log(result)
  
          }else{
  
            
            this.ELEMENT_DATA.forEach(element=> {
              if(element.cod_producto == result.cod_producto){
                
                element.check_estatus = false
  
              }
            })
    
            this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  
          }
  
         
        })
  
      }else{
  
        this.ELEMENT_DATA.forEach(element=> {
  
          if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req){
                
            element.check_estatus = false
  
            element.cant_prod_calidad = '0'
  
          }
  
        })
  
      }

    }

    LiberarProducto(){



      for (let i = 0; i < this.ELEMENT_DATA.length; i++) {

        console.log(this.ELEMENT_DATA[i]);

        if(this.ELEMENT_DATA[i].cant_prod_calidad == '0'){
          return this.snackBar.open('Tienes productos en 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
        
      }

      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
               accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar') {

          let objUsuario = {
            cod_usuario: localStorage.getItem('cod_usuario'),
            des_usuario: this.NombreUsuario
          }
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });

          setTimeout(() => {

            this.servicio.liberarRecepcionHttp(this.ELEMENT_DATA,objUsuario,this.data).subscribe(
              response =>{

                console.log(response);

                this.dialogSpin.close()
                this.dialogRefp.close()
  
                 ///////////////////////////ALERTA CODE////////////////////////////
                let alerta = document.querySelector('.alertSuccess') as HTMLDivElement
                      
                
                alerta.classList.remove('d-none')
                alerta.classList.add('d-flex')
                alerta.classList.add('show')
              
                setTimeout(() => {
              
                  alerta.classList.remove('show')
                  alerta.classList.remove('d-flex')
                  alerta.classList.add('d-none')
              
                }, 3000);
                ///////////////////////////ALERTA CODE////////////////////////////
  
              },
              error =>{
                this.dialogSpin.close()
  
                console.log(error)
                this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              })
            
          }, 1000);

         
        }
      });
      
    }
  
    cerrarmodal(): void {
      this.accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
               accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar') {
          this.cerrar();
        }
      });
    } 
  
    cerrar():void{
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
  
          this.dialogSpin.close();
          this.dialogRefp.close('cerrar');
        }, 1000);
      } catch (error) {
        this.dialogSpin.close();
        console.log(error)
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  

}
