import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'; 
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-factura',
  templateUrl: './form-factura.component.html',
  styleUrls: ['./form-factura.component.css']
})
export class FormFacturaComponent implements OnInit, AfterViewInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('producto_pedido') producto_pedido:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('iva_pedido',{static:true}) iva_pedido:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('lote_producto',{static:true}) lote_producto:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('cliente',{static:true}) cliente:ElementRef;
  
  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;
bandera:any;
accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormFacturaComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
       
    }

  ngOnInit(): void {
    this.bandera = this.data.termino;
    this.cliente.nativeElement.value = this.data.cod_pedido;
    this.iva_pedido.nativeElement.value = this.data.iva_pedido;
    this.desc_pedido.nativeElement.value = this.data.desc_pedido;
    this.subtotal_pedido.nativeElement.value = this.data.subtotal_costo_pedido;
    this.iva_monto.nativeElement.value = this.data.iva_monto;
    this.total_pedido.nativeElement.value = this.data.total_costo_pedido;
    this.listarproductopedido(this.data.cod_pedido);

  }
  ngAfterViewInit() {
    
  }
 
  listarproductopedido(xvalor:any){
   
    this.servicio.listarProductoPedidohttp(xvalor).subscribe(
      response =>{
        console.log(response);
          this.ELEMENT_DATA = [];
              for (const i in response) {
                  
                this.ELEMENT_DATA.push({
                  cod_producto:response[i].cod_producto,  
                  lote_producto: response[i].lote_producto, 
                  des_producto:response[i].des_producto, 
                  cant_producto:response[i].cant_producto,
                  costo_producto:response[i].costo_producto,
                  cod_vendedor: response[i].cod_vendedor, 
                  tipo_producto:response[i].tipo_producto,
                  tamano_producto:response[i].tamano_producto,
                  subtotal_producto:response[i].subtotal_producto
                });
              }
              this.displayedColumns = [ 
                                        'cod_producto',
                                        'lote_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'costo_producto',
                                        'tipo_producto',
                                        'tamaño_producto',
                                        'subtotal_producto',
                                      ];
              this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
        
      },
      error =>{
        console.log(error);
        console.log(<any>error.error.message);
        this.displayedColumns = [ 
                                  'cod_producto',
                                  'lote_producto',
                                  'des_producto',
                                  'cant_producto',
                                  'costo_producto',
                                  'tipo_producto',
                                  'tamaño_producto',
                                  'subtotal_producto',
                                ];
        this.dataSource1 = new MatTableDataSource<any>();
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    );
  }

/* separador(val:any){
    var value;
    value = parseFloat(val.replace(/,/g, ""))
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.cantidad.nativeElement.value = value;
                    console.log(value);

  } */

  cerrarmodal(): void {
    this.dialogRefp.close('cerrar');
  }

}
