import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl, MinLengthValidator } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatPaginator} from '@angular/material/paginator';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { Element } from '@angular/compiler';
import { element, error } from 'protractor';
import * as moment from 'moment'
import { ThemeService } from 'ng2-charts';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

@Component({
  selector: 'app-form-cuestionario-compras',
  templateUrl: './form-cuestionario-compras.component.html',
  styleUrls: ['./form-cuestionario-compras.component.css']
})
export class FormCuestionarioComprasComponent implements OnInit {
  accion:any;
  forma:FormGroup;
  ELEMENT_DATA:any[]=[];
  centroCostoArray:any[]=[];
  displayedColumns:string[];
  aprobar:any=false
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`
  Unidad:string = localStorage.getItem('Unidad')
  centro_costo:string;
  partidas:any;
  AlertMensaje:string;
  departamento:string;
  alertaDanger = document.querySelector('.alert-danger') as HTMLDivElement;
  minDay:any = new Date() 
  presupuesto:any;
  tiposProducto:any;
  textValid:any = 0;

   dataSource=new MatTableDataSource<any>();
   @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor( public dialog: MatDialog,fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCuestionarioComprasComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any,private snackBar: MatSnackBar, private servicio:nkServices , private toast:ToastNotificationService) {

        
      this.forma = fb.group({
        'tipo_solicitud_compra':['', [Validators.required]],
        'modalidad_solicitud_compra':['', [Validators.required]],
        'prioridad_solicitud_compra':['', [Validators.required]],
        'des_usuario':this.NombreUsuario,
        'cod_usuario': localStorage.getItem('cod_usuario'),
        'tipo_producto':['', [Validators.required]],
        'cod_cuenta_gasto':['', [Validators.required]],
        'fecha_estimada':['', [Validators.required]],
        'observaciones': ['', [Validators.required, Validators.minLength(30)]],
      });
    }

  ngOnInit(): void {
    this.servicio.listarTipoProductoshttp().subscribe(result => {

      this.tiposProducto = result.result

      console.log(result.result)


    })


    this.buscarCentroDCosto()

  }

  fnTextValid(e:any){

    this.textValid = e.length

  }

  buscarCentroDCosto(){

    let cod_usuario = localStorage.getItem('cod_usuario')


      this.servicio.buscarCentroCostoshttp(cod_usuario).subscribe(respuesta => {

        this.centroCostoArray = respuesta.centro_costo

        console.log(respuesta)

    },
    error => {
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
  })   
  }

  centroCosto(ev:any){

    console.log(ev)

    let centro = this.centroCostoArray.find(element => element.cod_centro == ev.value)

    this.centro_costo = centro.cod_centro
    this.departamento = centro.des_unidad

    this.servicio.buscarPresupuestosPartidashttp(ev.value).subscribe(
      response => {

        this.partidas = response.partidas
        this.presupuesto = response.presupuesto

      },
      error => {

        this.toast.danger('Error',error.error.message);

      }
    )
   

    console.log(this.centro_costo)

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
              maxWidth: '100vw',
              height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.dialogRefp.close('cerrar');
      }

    });

  }

  validarPartida( ev:any){

    let laPartida = this.partidas.find(element => element.cod_cuenta_gasto == ev.value)
    if(laPartida.cant_cuenta_gasto == 0){
      this.snackBar.open('Esta partida se encuentra sin fondos','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }

  verForma(){
    
    console.log(this.forma)
    
    console.log(this.forma.value.fecha_estimada)

    console.log(document.querySelectorAll('.mat-checkbox-input'))

  }
  

   listo(){

    let laPartida = this.partidas.find(element => element.cod_cuenta_gasto == this.forma.value.cod_cuenta_gasto)

    if(laPartida.cant_cuenta_gasto == 0){
      return this.snackBar.open('Esta partida se encuentra sin fondos','Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

    var dialogRefx = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
              maxWidth: '100vw',
              height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRefx.afterClosed().subscribe(result => {

      if(result != 'cerrar'){

        

        this.forma.value.departamento =  this.departamento
        this.forma.value.centro_costo =  this.centro_costo
        this.forma.value.cod_presupuesto =  this.presupuesto

        this.forma.value.fecha_estimada =  moment(this.forma.value.fecha_estimada).format('DD/MM/YYYY')

        let json = {accion: 'listo',info:this.forma.value}

        this.dialogRefp.close(json);

      }

    })
    
  }

}
