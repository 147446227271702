import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-modifi-precio',
  templateUrl: './form-modifi-precio.component.html',
  styleUrls: ['./form-modifi-precio.component.css']
})
export class FormModifiPrecioComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('precio_base_dol',{static:true}) precio_base_dol:ElementRef;
  @ViewChild('tasa_base',{static:true}) tasa_base:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;

accion:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormModifiPrecioComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');

      this.forma = fb.group({
        'precio_producto_base': [data.precio_producto_base,[Validators.required]],
        'precio_producto_base_bs':data.precio_producto_base_bs

      });

    }
    ngOnInit(): void {

    }

    cambiarPrecio(xvalor:any){
      try {
        this.accion = "enviarprecios";
        var dialogRef = this.dialog.open(FormmMsgComponent, {
          width: '100vw',
              maxWidth: '100vw',
              height:'100vh',
          data: {id:'',
                accion:this.accion},
          disableClose: true,
          panelClass: 'pedidos_modal',
          backdropClass: "bdrop"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){

            this.cambiarPrecioProducto(xvalor);
          }
        });
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }

      }
  cambiarPrecioProducto(xvalor:any){
    try {
      if (isNaN(parseFloat(xvalor))) {
        this.snackBar.open('Debes ingresar una cantidad para procesar el calculo', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }else{
        if (parseFloat(xvalor.replace(',','.')) > 0) {
          var xobj = {valor:xvalor, cod_producto:this.data.cod_producto}
          this.dialogRefp.close(xobj);
        }else{
          this.snackBar.open('La cantidad ingresada debe ser mayor a 0', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
    cerrarmodal(): void {
      this.dialogRefp.close('cerrar');
    }
}
