import { Component, OnInit, Inject, ViewChild,} from '@angular/core';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { MatTableDataSource} from '@angular/material/table';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { element } from 'protractor';
import iziToast from 'izitoast';


@Component({
  selector: 'app-form-c-devolver-prod-cot-oc',
  templateUrl: './form-c-devolver-prod-cot-oc.component.html',
  styleUrls: ['./form-c-devolver-prod-cot-oc.component.css']
})
export class FormCDevolverProdCotOcComponent implements OnInit {

  dialogSpin:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`
  dataSource=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,
    public dialogRefp: MatDialogRef<FormCDevolverProdCotOcComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.mostrarTabla()
  }

  async mostrarTabla(){

    try {

      const { datos } = await this.servicio.listarPorductosPendientesOC().toPromise()
      
      console.log(datos);
      this.ELEMENT_DATA = datos.map(element => ({...element, check_estatus: false}))

      this.displayedColumns = [
        'cod_cotizacion',
        'des_proveedor',
        'cod_producto',
        'des_producto',
        'cant_producto_cot',
        'tipo_unidad_medida',
        'tomar_data_ingresada',
      ];
      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    
      
    } catch (error) {

      this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
      
      this.displayedColumns = [
        'cod_cotizacion',
        'des_proveedor',
        'cod_producto',
        'des_producto',
        'cant_producto_cot',
        'tipo_unidad_medida',
        'tomar_data_ingresada', 
      ];
      /* this.dataSource = this.ELEMENT_DATA; */
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    
      
    }

  }

  tomar_data(booleano:any, elemento:any, tabla:any){

    console.log(elemento);

    if(booleano){ 

      this.ELEMENT_DATA.forEach(element=> {

        if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req ){

          element.check_estatus = true

        }

      })

      this.ELEMENT_DATA2.push(elemento)


    }else{


      this.ELEMENT_DATA.forEach(element=> {

        if(element.cod_producto == elemento.cod_producto && element.cod_orden_req == elemento.cod_orden_req ){
              
          element.check_estatus = false


        }

      })
      this.ELEMENT_DATA2 =   this.ELEMENT_DATA2.filter(element => element.cod_producto != elemento.cod_producto )

      console.log('aqui estoy')
    }

    this.dataSource2 = new MatTableDataSource<any>(this.ELEMENT_DATA2);

  }

  enviar(){

    
      const usuario = {
                  cod_usuario: localStorage.getItem('cod_usuario'),
                  des_usuario: localStorage.getItem('Nombre')+' '+localStorage.getItem('Apellido')
                }

      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(
        result => {
        if (result != 'cerrar') {
          this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
            width: '15%',
            height:'25%',
            data: {
                    accion:this.accion},
            disableClose: true,
            panelClass: 'spinner',
            backdropClass: "bdrop"
          });
          this.servicio.volverACotizarProdOC(this.ELEMENT_DATA2,usuario).subscribe(result2 => {

            console.log(result2);
            iziToast.success({message:'Operación Exitosa'})
            this.dialogSpin.close();
            this.dialogRefp.close();
      
          },
          error =>{
            this.dialogSpin.close();
            this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
      
          }
          )
        }
      },
        error => {
          this.snackBar.open(JSON.stringify(error), 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );



    

  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100%',
      data: {id:'',
             accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }
    });
  } 

  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 1000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
