import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {FormmMsgComponent} from '../../../../shared/modal/formm-msg/formm-msg.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormPreciosComponent } from 'src/app/componentes/shared/modal/form-precios/form-precios.component';
import { FormConsultaListaComponent } from 'src/app/componentes/shared/modal/form-consulta-lista/form-consulta-lista.component';
import { FormPreciosActualizarComponent } from 'src/app/componentes/shared/modal/form-precios-actualizar/form-precios-actualizar.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-inventario-sg',
  templateUrl: './inventario-sg.component.html',
  styleUrls: ['./inventario-sg.component.css']
})
export class InventarioSgComponent implements OnInit {

  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

  }

  ngOnInit(): void {
    this.listarInventarioSG(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    console.log(this.dataSource.paginator);
  }

  /////////////////////////////////////////////////////////////////////
  listarInventarioSG(msj:any){
    let msg = msj;
    this.servicio.listarInventarioSGhttp().subscribe(
      response =>{
        if(response){
          console.log(response[0],'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response) {

                this.ELEMENT_DATA.push({
                  id_producto_pt_seg:response[i].id_producto_pt_seg,
                  cod_producto:response[i].cod_producto,
                  des_producto:response[i].des_producto,
                  cant_producto:response[i].cant_producto,
                  estatus_producto:response[i].estatus_producto,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = ['cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'estatus_producto',
                                        'traslados'];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log('una respuesta');
        console.log(<any>error.error[0].message);
        this.displayedColumns = ['cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'estatus_producto',
                                        'traslados'];
        this.dataSource = new MatTableDataSource<any>();
              this.dataSource.paginator = this.paginator;
      }
    );
    console.log(msg);
    if(msg == 'actualizar'){
      this.snackBar.open('Lista actualizada.', 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
    return this.xobj2;
  }

  editardatos(obj:any,text:any){
    var dialogRef:any;
    this.accion = text;
    let datos = localStorage.getItem('cod_usuario');
    let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
    if (this.accion === 'actualizar') {
      dialogRef = this.dialog.open(FormPreciosActualizarComponent, {
        width: '100%',
        height:'90%',
        data: {cod_lista_precio_producto:obj.cod_lista_precio_producto,
                cod_cliente:obj.cod_cliente,
                des_cliente:obj.des_cliente,
                cod_usuario:datos,
                des_usuario: nombre,
                fecha_inicio:obj.fecha_inicio,
                tasa_precio:obj.tasa_precio,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
    }else{
      dialogRef = this.dialog.open(FormPreciosComponent, {
        width: '100%',
        height:'91%',
        data: {cod_lista_precio_producto:'',
                cod_usuario:datos,
                des_usuario: nombre,
                accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal'
      });
    }

      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){
          console.log(result);

      this.gestionprecios(result);
        }
      });
  }
  public  gestionprecios(xvalor:any){
   console.log(xvalor);
   var resultado = this.servicio.gestionListaprecioshttp(xvalor).subscribe(
      response =>{
        console.log(response.resul,'una respuesta');
        if(response.resul){
          console.log(response.resul,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response.resul) {
                  console.log(response[i]);
                this.ELEMENT_DATA.push({
                  cod_lista_precio_producto:response.resul[i].cod_lista_precio_producto,
                  cod_usuario: response.resul[i].cod_usuario,
                  des_usuario:response.resul[i].des_usuario,
                  estatus:response.resul[i].estatus,
                  fecha_inicio:response.resul[i].fecha_inicio,
                  fecha_fin:response.resul[i].fecha_fin,
                  tasa_precio:response.resul[i].tasa_precio,
                  cod_cliente:response.resul[i].cod_cliente,
                  des_cliente:response.resul[i].des_cliente,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = ['cod_lista_precio_producto',
                                        'cod_cliente',
                                        'des_cliente',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'fecha_fin',
                                        'tasa_precio',
                                        'detalle',
                                        'imprimir',
                                        'descargar',
                                        'Actualizar'];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;
              this.snackBar.open(response.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
        }
      },
      error =>{
        console.log(<any>error.error.message);
        this.displayedColumns = ['cod_lista_precio_producto',
                                        'cod_cliente',
                                        'des_cliente',
                                        'cod_usuario',
                                        'des_usuario',
                                        'estatus',
                                        'fecha_inicio',
                                        'fecha_fin',
                                        'tasa_precio',
                                        'detalle',
                                        'imprimir',
                                        'descargar',
                                        'Actualizar'];
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );

    return resultado;
  }
   public buscarMenuparam(xvalor:string){
      var valor:any = {valor:xvalor,termino:'sistemas'}
      console.log(valor);
    this.servicio.buscarMENUparamRhttp(valor).subscribe(
      response =>{
        if(response){
          console.log(response, 'busqueda' );
          this.ELEMENT_DATA = [];
          for (const i in response) {

            this.ELEMENT_DATA.push({
              cod_lista_precio_producto:response[0][i].cod_lista_precio_producto,
              cod_usuario: response[0][i].cod_usuario,
              des_usuario:response[0][i].des_usuario,
              estatus:response[0][i].estatus,
              fecha_inicio:response[0][i].fecha_inicio,
              fecha_fin:response[0][i].fecha_fin,
              tasa_precio:response[0][i].tasa_precio,
              accion:'',
              accion2:'',
              accion3:''
            });
          }
          this.displayedColumns = ['cod_lista_precio_producto',
                                    'cod_usuario',
                                    'des_usuario',
                                    'estatus',
                                    'fecha_inicio',
                                    'fecha_fin',
                                    'tasa_precio',
                                    'detalle',
                                    'imprimir',
                                    'descargar',
                                    'Actualizar'];
             this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
             this.dataSource.paginator = this.paginator;
        }
      },
      error =>{
        console.log(<any>error.error[0].message);
        this.snackBar.open(<any>error.error[0].message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }
  async detallepedido(obj:any){


    this.dialog.open(FormConsultaListaComponent, {
       width: '100%',
       height:'91%',
       data: {cod_lista_precio_producto: obj.cod_lista_precio_producto,
              fecha_inicio:obj.fecha_inicio,
              tasa_precio:obj.tasa_precio},
       panelClass: 'pedidos_modal'
     });
 }

 traslados(obj:any){
  var dialogRef:any;
  let datos = localStorage.getItem('cod_usuario');
  let nombre = localStorage.getItem('Nombre')+' '+ localStorage.getItem('Apellido');
  this.accion = 'SG';
    dialogRef = this.dialog.open(FormKeyComponent, {
      width: '25%',
      height:'45%',
      data: {cod_producto:obj.cod_producto,
            des_producto:obj.des_producto,
            cant_producto:obj.cant_producto,
              accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        console.log(result);

    this.gestionTraslados_PT_SG(result);
      }
    });
}

gestionTraslados_PT_SG(xvalor:any){
  try {
    this.servicio.gestionTraslados_PT_SGhttp(xvalor).subscribe(
      response =>{
        if(response){
          console.log(response,'una respuesta');
          this.xobj2 = response;
          this.ELEMENT_DATA = [];
              for (const i in response.resul) {

                this.ELEMENT_DATA.push({
                  id_producto_pt:response.resul[i].id_producto_pt,
                  cod_inv: response.resul[i].cod_inv,
                  des_inv:response.resul[i].des_inv,
                  lote_producto:response.resul[i].lote_producto,
                  cod_producto:response.resul[i].cod_producto,
                  des_producto:response.resul[i].des_producto,
                  cant_producto:response.resul[i].cant_producto,
                  estatus_producto:response.resul[i].estatus_producto,
                  accion:'',
                  accion2:'',
                  accion3:''
                });
              }
              this.displayedColumns = ['cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'estatus_producto',
                                          'traslados'];
               /* this.dataSource = this.ELEMENT_DATA; */
              this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
              this.dataSource.paginator = this.paginator;

              this.snackBar.open(response.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition});
        }
      },
      error =>{
        console.log('una respuesta');
        this.displayedColumns = ['cod_producto',
                                        'des_producto',
                                        'cant_producto',
                                        'estatus_producto',
                                          'traslados'];
        this.dataSource = new MatTableDataSource<any>();
              this.dataSource.paginator = this.paginator;
              this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition});
      }
    );


  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
 applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  console.log(this.dataSource.filter);
}
  /* handleFile(event) {
    let file = event.target.files[0];

    var id = document.getElementById('file');
    console.log(file);
    var nombre = 'Codigo de Unidad';
    this.servicio.processFileToJson({}, file).subscribe(data => {
      let arry = data.sheets.Hoja1;
      console.log(arry);
      for (const i in arry) {
          console.log(arry[i]);
          this.result.push(arry[i]);
      }
      if (this.result.length != 0) {
        console.log('entro en el if');
          this.servicio.insertarmasivohttp(this.result).subscribe(
            response =>{
              if(response){
                console.log(response,'una respuesta');
                let xobj2 = response.resul;
                this.ELEMENT_DATA = [];
                    for (const i in xobj2) {

                      this.ELEMENT_DATA.push({id:xobj2[i].id, Nombre: xobj2[i].nombre_usu, Apellido:  xobj2[i].apellido_usu , Direccion:  xobj2[i].direccion_usu,  Correo: xobj2[i].correo_usu, Accion: '', Accion2: ''});
                    }
                   this.displayedColumns = ['Nombre', 'Apellido', 'Direccion', 'Correo', 'Eliminar','Actualizar'];

                    this.dataSource = new MatTableDataSource<PeriodicElement2>(this.ELEMENT_DATA);
                    this.dataSource.paginator = this.paginator;
                 var bool = true;
                 console.log(response.resul);
                this.snackBar.open(response.message,'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                 return bool;
              }
            },
            error =>{
              console.log(<any>error);
            }
          );
      }
      /* this.result = JSON.stringify(data['sheets'].Sheet1); */
      /* this.snackBar.open('El archivo se cargo de forma correcta en la base de datos', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,}); */
  /*   })
    event.srcElement.value = null;
  } */
  /////DATATABLE
  /* applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
  } */
}
