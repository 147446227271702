
<div id="modal_carrito" class=" container-fluid">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid #3649b6; background-color: rgb(36, 137, 219); ">
            <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff;">Orden de producción N°: </mat-label>
                        <input style="font-size: 11px; color: #fff;" #cod_orden_producto matInput placeholder="" autocomplete="off" readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>#</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#4979d3;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Dep MP</mat-label>
                        <input #inv_mp class="" style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="inv_mp" [matAutocomplete]="autoGroup22" >
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                            </span>
                        <mat-autocomplete #autoGroup22="matAutocomplete" class="px-1">
                            <mat-optgroup *ngFor="let group of stateGroupOptions4 | async" [label]="group.letter">
                                <mat-option style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                    <b>{{i}}</b>
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-5 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>search</mat-icon>
                      </span>
                    </mat-form-field>
                </div>
            </div>

            <div id="content_boton1" class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100" (click)="modalrespuesta()" matTooltip="CONFIRMAR ORDEN" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                    <mat-icon >done_all</mat-icon>
                  </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR">
                          <mat-icon >exit_to_app</mat-icon>
                      </button>
                </div>
            </div>

        </div>
        <div id="carrito_p2" class="row" style=" border: 2px solid #374fd6;">
            <div class="col-md-12 px-0  border h-100" style="background-color: rgb(36, 137, 219);">
                <table #table mat-table [dataSource]="dataSource1" class=" table-primary text-center table-striped table-bordered table-condensed table-hover">
                    
                    <ng-container matColumnDef="cod_formula">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(36, 137, 219);">
                            <h5><strong>Formula</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_formula}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(36, 137, 219); color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(36, 137, 219);">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.cod_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(36, 137, 219); color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(36, 137, 219);">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> {{element.des_producto}} </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(36, 137, 219); color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(36, 137, 219);">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.cant_producto}}</td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(36, 137, 219); color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="unidad_prod_mp">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(36, 137, 219);">
                            <h5><strong>Unidad de medida</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;">{{element.unidad_prod_mp}}</td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(36, 137, 219); color: #fff;"> </td>
                    </ng-container>

                    <ng-container matColumnDef="detalle">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: rgb(36, 137, 219);">
                            <h5><strong>Detalle</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button (click)="detalleOrdenProdMpp(element,'MP')" matTooltip="DETALLES" mat-raised-button style="height: 35px; border: 2px solid #374fd6; color:rgb(255, 255, 255); background-color: rgb(36, 137, 219);">
                                    <mat-icon >image_search</mat-icon>
                                    </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: rgb(36, 137, 219); color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>
    </form>

</div>