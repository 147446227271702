<div class="" id="modal">
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>{{AlertMensaje}}.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); border-radius: 1rem;">
                    <div class="modal-header" style="background-image: linear-gradient(to left top, #90b9dc, #70a3d3, #518cca, #3276bf, #045fb4); color: #fff; border-radius: 0.9rem 0.9rem 0 0;">
                        <h3 class="modal-title"><strong>Ingrese Cantidad de la factura y en fisico  del producto</strong> </h3>
                    </div>
                    <div class="modal-body">
                        <div style="width: 100%;">
                            <div class="row">

                                <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                                    Codigo: <strong>{{codigo}}</strong>
                                </div>
                                <br>
                                <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                                    Producto: <strong>{{producto}}</strong>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                                    Cantidad: <strong>{{aprobado | puntoporcoma}}</strong>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-center ">
                                <div class="col-6">
                                    <!-- <mat-form-field class="materialinput">
                                            <mat-label style="color: #000; font-size: 12px;">Cantidad a devolver</mat-label>
                                            <input style="font-size: 12px;" #cant_dev maxlength="6" matInput placeholder="" autocomplete="off" formControlName="cant_dev">
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          #
                                            </span>
                                    </mat-form-field> -->

                                    <div class="form-group">
                                        <label for="cant_recibida_fact">Cantidad Factura</label>
                                        <div class="input-group">
                                            <input  style=" text-align: right;" (keyup)="ponerPuntosyComas($event, 'cant_recibida_fact')" placeholder="0.00" id="cant_recibida_fact" class="form-control form-control-lg" type="text" autocomplete="off" formControlName="cant_recibida_fact">
                                            <div class="input-group-prepend">
                                                <div style=" text-align: right;" class="form-control" id="elSelect" (change)="cambiarMoneda($event)">
                                                    <p>{{data.tipo_unidad_medida}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <small id="cant_producto_cotHelp" *ngIf="!excedeCantRecibidaFact" class="form-text text-muted">Cantidad que esta en la factura</small>
                                        <small id="cant_producto_cotHelp" *ngIf="excedeCantRecibidaFact" class="form-text text-danger"><b>Si Excede lo solicitado necesitaras Aprobacion, Permisos y Hash-Key</b></small>
                                        <small id="cant_producto_cotHelp" *ngIf="menosCantRecibidaFact" class="form-text text-warning"><b>La cantidad ingrasada es menor a la Orden de Compra</b></small>
                                    </div>
                                    <div class="form-group">
                                        <label for="cant_fisico">Cantidad Fisico</label>
                                        <div class="input-group">
                                            <input  style=" text-align: right;" (keyup)="ponerPuntosyComas($event, 'cant_fisico')"  placeholder="0.00" id="cant_fisico" class="form-control form-control-lg" type="text" autocomplete="off" formControlName="cant_fisico">
                                            <div class="input-group-prepend">
                                                <div style=" text-align: right;" class="form-control" id="elSelect" (change)="cambiarMoneda($event)">
                                                    <p>{{data.tipo_unidad_medida}}</p>
                                                </div>
                                            </div>
                                        </div> 
                                        <small id="cant_producto_cotHelp"*ngIf="!excedeCantFisico" class="form-text text-muted">Cantidad que de productos contados que han sido recibido</small>                                   
                                        <small id="cant_producto_cotHelp" *ngIf="excedeCantFisico" class="form-text  text-danger"><b>Si Excede la cantidad Facturada necesitaras Aprobacion, Permisos y Hash-Key</b></small>                                   
                                        <small id="cant_producto_cotHelp" *ngIf="menosCantFisico" class="form-text  text-warning"><b>La cantidad ingrasada es menor a la cantidad Facturada</b></small>                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                      <div class="form-group">
                        <button class="btn btn-danger rounded-pill mr-2" (click)="cerrarmodal()" >Salir</button> <!-- [disabled]="forma.invalid" -->
                        <button class="btn btn-primary rounded-pill" (click)="devolucion_prod()" [disabled]="forma.invalid" >Aceptar</button> <!--  -->
                      </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


