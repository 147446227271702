<br>
<!-- FORMULARIO PARA Crear Producto de Materia Prima -->
<form [formGroup]="forma" class="materialformtamaño" *ngIf="accion == 'insertar'">
    <div class="row">
        <div class="col-md-12 ">
            <p>
                <strong>Inventario de Productos Dañados</strong>
            </p>
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Codigo</mat-label>
                    <input matInput placeholder="Codigo" formControlName="cod_producto" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>code</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Descripcion</mat-label>
                    <input matInput placeholder="Descripcion" formControlName="des_producto" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>description</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>        
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Razon</mat-label>
                    <input matInput placeholder="Razon" formControlName="razon" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>add_circle</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Fecha de Registro</mat-label>
                    <input matInput placeholder="Usuario" formControlName="fecha_registro" autocomplete="off" readonly>
                </mat-form-field>
            </p>
        </div>             
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Hora</mat-label>
                    <input matInput placeholder="Usuario" formControlName="hora" autocomplete="off" readonly>
                </mat-form-field>
            </p>
        </div>            
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Usuario</mat-label>
                    <input matInput placeholder="Usuario" formControlName="usuario" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>add_circle</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>        
        <div class="col-md-6">
            <p>
                <mat-form-field class="materialinput">
                    <mat-label>Almacen</mat-label>
                    <input matInput placeholder="Almacen" formControlName="almacen" autocomplete="off">
                    <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    <mat-icon>add_circle</mat-icon>
                    </span>
                </mat-form-field>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <p>
                <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="actualizardatos()" mat-raised-button color="accent">Actualizar</button>
                <button *ngIf="accion == 'insertar'" class="btn-block" (click)="actualizardatos('insertar')" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
            </p>
        </div>
        <div class="col-md-6">
            <p>
                <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
            </p>
        </div>
    </div>

</form>