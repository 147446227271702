import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormMenuComponent} from '../../../shared/modal/form-menu/form-menu.component';
import {FormmMsgComponent} from '../../../shared/modal/formm-msg/formm-msg.component';
import {FormPermisosComponent} from '../../../shared/modal/form-permisos/form-permisos.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { PeriodicElementmenu } from '../../../shared/models/interfacemenu';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { PeriodicElementunidad } from 'src/app/componentes/shared/models/interfaceunidades';
import { FormUnidadComponent } from 'src/app/componentes/shared/modal/form-unidad/form-unidad.component';
import { PeriodicElementfacturas } from 'src/app/componentes/shared/models/interfacefacturas';
import { PeriodicElementclientes } from 'src/app/componentes/shared/models/interfaceclientes';
import { FormClientesComponent } from 'src/app/componentes/shared/modal/form-clientes/form-clientes.component';
import { FormDireccionesComponent } from 'src/app/componentes/shared/modal/form-direcciones/form-direcciones.component';
import { FormProductosComponent } from 'src/app/componentes/shared/modal/form-productos/form-productos.component';
import { FormFormulacionComponent } from 'src/app/componentes/shared/modal/form-formulacion/form-formulacion.component';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormOrdHistorialMppComponent } from 'src/app/componentes/shared/modal/form-ord-historial-mpp/form-ord-historial-mpp.component';
import { FormKeyComponent } from 'src/app/componentes/shared/modal/form-key/form-key.component';
import { HashKeyServices } from 'src/app/componentes/shared/servicios/app.hashkey.services';
import { AppUtilService } from 'src/app/componentes/shared/servicios/app.util.service';
import { AtributosService } from 'src/app/componentes/shared/servicios/atributos.service';
import { environment } from 'src/environments/environment';
import { ToastNotificationService } from 'src/app/componentes/shared/servicios/toast-notification.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-producto-factory',
  templateUrl: './producto-factory.component.html',
  styleUrls: ['./producto-factory.component.css']
})
export class ProductoFactoryComponent implements OnInit {

  medida:any = [];
  tipo_producto:any = [];
  DEFINICIO_1:any = [];
  
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  xobj4:any = [];
  xobj5:any = [];
  dialogSpin:any;
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();
  constructor(
    private postrest:HttpClient, 
    private servicio:nkServices, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private router:Router,
    private hashkey:HashKeyServices,
    private util:AppUtilService,
    private atrb:AtributosService,
    private notifi:ToastNotificationService,
    ) {}

  ngOnInit(){
    this.listarProductos(null);
    this.buscardefinicionesprod(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
    this.dataSource.paginator = this.paginator;
  }

  /////////////////////////////////////////////////////////////////////
 async listarProductos(msj:any){
    try {
      let msg = msj;
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
       const response = await this.servicio.listarProductosConfighttp(await this.atrb.COD_EMPRESA).toPromise();
            ////////////////UNIDADES//////////////////////
            this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
              this.xobj2 = response;
              this.ELEMENT_DATA = [];
                  for (const i in response) {
                    if (response[i].cod_formula.toLowerCase() === await this.atrb.SIN_FORMULA.toLowerCase()) {
                      response[i].cod_formula = await this.atrb.NO_APLICA;
                    }
                    if (response[i].cod_img === '' || response[i].cod_img === null) {
                      response[i].cod_img = await this.atrb.IMAGENES_FIJAS.sin_img_prod;
                    }else{
                      response[i].cod_img = environment.path_storage+response[i].cod_img
                    }
                    this.ELEMENT_DATA.push({
                                cod_img:response[i].cod_img,
                                cod_producto:response[i].cod_producto,
                                des_producto: response[i].des_producto,
                                estatus_producto:response[i].estatus_producto,
                                estatus_fiscal:response[i].estatus_fiscal,
                                tamano_producto:response[i].tamano_producto,
                                unidad_medida_producto:response[i].unidad_medida_producto,
                                unidad_manejo:response[i].unidad_manejo,
                                tipo_producto:response[i].tipo_producto,
                                cod_formula:response[i].cod_formula,
                                accion:'',
                                accion2:'',
                                accion3:''
                              });
                  }
                  this.displayedColumns = [
                                            'cod_img',
                                            'cod_formula',
                                            'cod_producto',
                                            'des_producto',
                                            'estatus_producto',
                                            'estatus_fiscal',
                                            'unidad_manejo',
                                            'tipo_producto',
                                            'procesos'
                                          ];
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
                  if(msg === await this.atrb.ACCION_ACTUALIZAR){
                    await this.util.cerrarSpinner();
                      await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
                  }else{
                    await this.util.cerrarSpinner();
                  }
    } catch (error) {
      console.log(error);
      this.displayedColumns = [
        'cod_img',
        'cod_formula',
        'cod_producto',
        'des_producto',
        'estatus_producto',
        'estatus_fiscal',
        'unidad_manejo',
        'tipo_producto',
        'procesos'
      ];
      this.dataSource = new MatTableDataSource<any>();
            this.dataSource.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }

 async buscardefinicionesprod(xvalor:any){
    try {
      var valor = {cod_menu: localStorage.getItem('sub_main')};
        /* const response = await this.servicio.buscardefinicionesprodhttp(valor).toPromise(); */
        const response = await this.servicio.listarDefinicionesConceptoshttp(valor).toPromise();
            this.medida = [];
            this.DEFINICIO_1 = [];
            this.tipo_producto = [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === await this.atrb.PRODUCTOS.u_manejo.toUpperCase()) {
                let valor = response[i].concepto_definicion.split('-');
                this.medida.push(valor[1]);
              }
              if (response[i].des_definicion.toUpperCase() === await this.atrb.PRODUCTOS.u_medida.toUpperCase()) {
                this.DEFINICIO_1.push(response[i]);
              }
              if (response[i].des_definicion.toUpperCase() === await this.atrb.PRODUCTOS.tipo_producto.toUpperCase()) {
                this.tipo_producto.push(response[i]);
              }
            }
    } catch (error) {
      console.log(error);
    }
   }

//////////////////////FUNCION DE ACCION/////////////////////////

async  update_insert_data(obj:any, text:any){
  try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
                        if (text.toUpperCase() === await this.atrb.ACCION_ACTUALIZAR.toUpperCase()) {
                          let img =  obj.cod_img.split(environment.path_storage);
                          let img_ruta = img[1].split('\\');
                          let img_ruta_final = img_ruta[0]+'\\\\'+img_ruta[1]+'\\\\'+img_ruta[2];
                          let val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_productos,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.update,
                            cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                          }
                          await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                          await this.util.cerrarSpinner();
                          this.accion = text;
                          var dialogRef = this.dialog.open(FormProductosComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {id_producto:obj.id_producto,
                              cod_producto:obj.cod_producto,
                              des_producto:obj.des_producto,
                              estatus_producto:obj.estatus_producto,
                              tamano_producto:obj.tamano_producto,
                              estatus_fiscal:obj.estatus_fiscal,
                              unidad_medida_producto:obj.unidad_medida_producto,
                              unidad_manejo:obj.unidad_manejo,
                              tipo_producto:obj.tipo_producto,
                              medida: this.medida,
                              definicion_1:this.DEFINICIO_1,
                              tipo_prod:this.tipo_producto,
                              cod_img:obj.cod_img,
                              cod_img_sin:img_ruta_final,
                              accion:this.accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal_perfiles',
                            backdropClass: "bdrop"
                          });
                        }else{
                          let val_def = {
                            val_def:await this.atrb.ACCESO_VALDEF.gestionar_productos,
                            tipo_operacion:await this.atrb.ACCESO_OPERACION.create,
                            cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                          }
                          await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                          let objeto = {
                            cod_empresa: await this.hashkey.decrypt(this.atrb.COD_EMPRESA.cod_empresa),
                            mod_usuario: await this.hashkey.decrypt(this.atrb.MOD_USUARIO.mod_usuario),
                            des_parametro: await this.atrb.OPERACIONES.productos
                          };
                          await this.servicio.validarLicenciaProcesoshttp(objeto).toPromise();
                          await this.util.cerrarSpinner();
                          this.accion = text;
                           dialogRef = this.dialog.open(FormProductosComponent, {
                            width: '100vw',
                            maxWidth: '100vw',
                            height:'auto',
                            data: {medida: this.medida,
                              definicion_1:this.DEFINICIO_1,
                              tipo_prod:this.tipo_producto,
                             accion:this.accion},
                            disableClose: true,
                            panelClass: 'pedidos_modal_perfiles',
                            backdropClass: "bdrop"
                          });
                        }
                        dialogRef.afterClosed().subscribe(async result => {
                          await this.util.abrirSpinner();
                          if(result != 'cerrar'){
                            this.gestionProdPt(result);
                          }else{
                            await this.util.cerrarSpinner();
                          }
                        });
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
  }
}

async gestionProdPt(xvalor:any){
      try {
          if (xvalor.formdata.get('prod_img') !== await this.atrb.UNDEFINED) {
                const img = await this.servicio.gestionarImagenesthttp(xvalor.formdata).toPromise();
                    xvalor.cod_img = img.file.path;
                    xvalor.des_img = img.file.filename;
            }else{
                    xvalor.cod_img = false;
                    xvalor.des_img = false;
            }
          const response = await this.servicio.gestionProdPthttp(xvalor).toPromise();
          await this.util.cerrarSpinner();
          await this.listarProductos(null);
          await this.notifi.success(response.message);
      } catch (error) {
        console.log(error);
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(error.error.message);
        await this.listarProductos(null);
      }
   }
   //////////////////////FUNCION DE ACCION/////////////////////////
   //////////////////////FUNCION DE ACCION/////////////////////////

  async formulacion_prod_pt(obj:any, text:any){
    try {
                  await this.util.abrirSpinner();
                  await this.servicio.consultar_sesionhttp(null).toPromise();
                if (parseInt(await this.hashkey.decrypt(await this.atrb.ROL_USUARIOS.cod_rol)) > 0) {
                  let val_def;
                      val_def = {
                        val_def:await this.atrb.ACCESO_VALDEF.gestionar_productos,
                        tipo_operacion:await this.atrb.ACCESO_OPERACION.join,
                        cod_usuario : await this.hashkey.decrypt( await this.atrb.COD_PERFIL.cod_perfil)
                      }
                  await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
                }
                await this.util.cerrarSpinner();
                            this.accion = 'formulacionpt';
                            var dialogRef = this.dialog.open(FormFormulacionComponent, {
                              width: '100vw',
                              maxWidth: '100vw',
                              height:'100vh',
                              data: {cod_usuario:'',
                                      des_usuario:'',
                                      cod_producto:obj.cod_producto,
                                     des_producto:obj.des_producto,
                                     cod_formula: obj.cod_formula,
                                     tipo_producto: obj.tipo_producto,
                                    accion:this.accion},
                              disableClose: true,
                              panelClass: 'pedidos_modal3',
                              backdropClass: "bdrop"
                            });
                    
                            dialogRef.afterClosed().subscribe(async result => {
                              await this.util.abrirSpinner();
                              if(result != 'cerrar'){
                              }else{
                                await this.util.cerrarSpinner();
                             await this.listarProductos(null);
                              }
                            });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
  //////////////////////FUNCION DE ACCION/////////////////////////

  //////////////////////FUNCIONES DE ESTRUCTURA/////////////////////////

  async historialFormulas(xvalor:any){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
    let val_def = {
      val_def:await this.atrb.ACCESO_VALDEF.gestionar_productos,
      tipo_operacion:await this.atrb.ACCESO_OPERACION.select,
      cod_usuario : await this.atrb.COD_USUARIO.cod_usuario
    }
    await this.servicio.validar_acceso_acciones_http(val_def).toPromise();
    await this.util.cerrarSpinner();
         xvalor.termino = 'PT';
         var dialogRef =  this.dialog.open(FormOrdHistorialMppComponent, {
             width: '100vw',
             maxWidth: '100vw',
             height:'100vh',
             data:    {objeto: xvalor,
                        accion:'FRPT'},
                       disableClose: true,
                       panelClass: 'pedidos_modal',
                       backdropClass: "bdrop"
           });
           dialogRef.afterClosed().subscribe(async result => {
            await this.util.abrirSpinner();
            if(result != 'cerrar'){
            }else{
              await this.util.cerrarSpinner();
              this.listarProductos(null);
            }
          });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
 }

   handleFile(event) {
     try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner'
      });

      let file = event.target.files[0];
    var id = document.getElementById('file');
    console.log(file);
    var nombre = 'Codigo de Unidad';
    this.servicio.processFileToJson({}, file).subscribe(data => {
      let arry = data.sheets.Hoja1;
      console.log(arry);
      for (const i in arry) {
          console.log(arry[i]);
          this.result.push(arry[i]);
      }
      console.log(this.result);
      if (this.result.length != 0) {
        console.log('entro en el if');
          this.servicio.insertarmasivoProductoFactoryhttp(this.result).subscribe(
            response =>{
              if(response){
                this.dialogSpin.close();
                console.log(response,'una respuesta');
                let xobj2 = response.resul;
                this.ELEMENT_DATA = [];

                                            for (const i in xobj2) {

                                              this.ELEMENT_DATA.push({
                                                id_producto:xobj2[i].id_producto,
                                                cod_producto:xobj2[i].cod_producto,
                                                des_producto: xobj2[i].des_producto,
                                                estatus_producto:xobj2[i].estatus_producto,
                                                estatus_fiscal:xobj2[i].estatus_fiscal,
                                                tamano_producto:xobj2[i].tamano_producto,
                                                unidad_medida_producto:xobj2[i].unidad_medida_producto,
                                                unidad_manejo:xobj2[i].unidad_manejo,
                                                tipo_producto:xobj2[i].tipo_producto,
                                                cod_formula:xobj2[i].cod_formula,
                                                accion:'',
                                                accion2:'',
                                                accion3:''
                                              });
                                            }
                                            this.displayedColumns = [
                                              'cod_formula',
                                              'cod_producto',
                                              'des_producto',
                                              'estatus_producto',
                                              'estatus_fiscal',
                                              'unidad_manejo',
                                              'historial',
                                              'formulacion',
                                              'Actualizar'
                                            ];

                    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                    this.dataSource.paginator = this.paginator;
                 var bool = true;
                 console.log(response.resul);
                 return bool;
              }
            },
            error =>{
              this.dialogSpin.close();
              console.log(<any>error);
              this.displayedColumns = [
                'cod_formula',
                'cod_producto',
                'des_producto',
                'estatus_producto',
                'estatus_fiscal',
                'unidad_manejo',
                'historial',
                'formulacion',
                'Actualizar'
              ];
              this.dataSource = new MatTableDataSource<any>();
                    this.dataSource.paginator = this.paginator;
            }
          );
      }

    })
    event.srcElement.value = null;

     } catch (error) {
      this.dialogSpin.close();
     }

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
