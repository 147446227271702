
                <div class="modal-content border" style=" width: 100%; border-radius: 1rem; font-size: 8px;">
                    <div class="modal-header" style="background-color: steelblue; color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" class=" text-center"><strong>Detalles</strong> </h3>
                    </div> 
                    <div class="modal-body border">

                                <div class="col-md-12 col-sm-12  border">

                                    <div *ngIf="detalle">
                                        <table class="  table" >
                                            <tbody style="font-size: 12px;">
                                                <tr>
                                                    <td>Codigo: </td>
                                                    <td>{{data.objeto.cod_cliente}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cliente: </td>
                                                    <td>{{data.objeto.des_cliente}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Deposito asignado: </td>
                                                    <td>{{data.objeto.dep_spcl}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Precio asignado: </td>
                                                    <td>{{data.objeto.lista_precio_spcl}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Metodo de facturación: </td>
                                                    <td>{{data.objeto.mod_factur}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Metodo de consignación: </td>
                                                    <td>{{data.objeto.mod_consig}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Telefonos: </td>
                                                    <td>{{data.objeto.telefono1_cliente}} // {{data.objeto.telefono2_cliente}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Correo: </td>
                                                    <td>{{data.objeto.correo_cliente}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Dirección: </td>
                                                    <td>{{data.objeto.dir_cliente}}</td>
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="tiempo_m">
                                        <table class=" table" >
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Tiempo movimiento del producto</th>
                                                </tr>
                                                <tr>
                                                    <td>Fecha inicio: </td>
                                                    <td>{{tiempo_m[0]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="comentarios_m">
                                        <table class=" table" >
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Comentarios</th>
                                                </tr>
                                                <tr>
                                                    <td>Detalle: </td>
                                                    <td>{{comentarios_m[0]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="persona_m">
                                        <table class=" table" >
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Comentarios</th>
                                                </tr>
                                                <tr>
                                                    <td>Usuario: </td>
                                                    <td>{{persona_m[0]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cliente: </td>
                                                    <td>{{persona_m[1]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="factura_m">
                                        <table class=" table" >
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Comentarios</th>
                                                </tr>
                                                <tr>
                                                    <td>Factura: </td>
                                                    <td>{{factura_m[0]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Nota: </td>
                                                    <td>{{factura_m[1]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pedio: </td>
                                                    <td>{{factura_m[2]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="dir">
                                        <h3>Direccion: </h3><span>{{dir}}</span>
                                    </div>
                        
                                    <div *ngIf="descrips">
                                        <table class="table " style="margin: 0px;">
                                            <thead>
                                                <th>Descripción</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {{descrips}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                        
                                    <div *ngIf="mail">
                                        <table class="table " style="margin: 0px;">
                                            <thead>
                                                <th>E-mail</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {{mail}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="tel">
                        
                                        <table class="table " style="margin: 0px;">
                                            <thead>
                                                <th>Telefonos</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td *ngFor="let i of tel">
                                                        / {{i}} /
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                        
                                    <div *ngIf="fech">
                                        <table class=" table" >
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Fechas de estatus</th>
                                                </tr>
                                                <tr>
                                                    <td>Fecha de Emision: </td>
                                                    <td>{{fech[0]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha de Recepcion: </td>
                                                    <td>{{fech[1]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha de Vencimiento: </td>
                                                    <td>{{fech[2]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha de Comprobante: </td>
                                                    <td>{{fech[3]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="tiempo">
                                        <table class=" table" >
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Tiempo estimado de la solicitud</th>
                                                </tr>
                                                <tr>
                                                    <td>Ticket: </td>
                                                    <td>{{data.cod_ticket}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha inicio: </td>
                                                    <td>{{tiempo[0]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha fin: </td>
                                                    <td>{{tiempo[1]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Duracion : </td>
                                                    <td>{{tiempo[2]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                        
                                    <div *ngIf="tiempo_ord_pt">
                                        <table class=" table" style="margin: 0px; height: 100%;">
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Tiempo estimado de la orden</th>
                                                </tr>
                                                <tr>
                                                    <td>Orden: </td>
                                                    <td>{{data.cod_orden_producto}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha inicio: </td>
                                                    <td>{{tiempo_ord_pt[0]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Duracion : </td>
                                                    <td>{{tiempo_ord_pt[1]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div *ngIf="tiempo_ord_mp">
                                        <table class=" table" style="margin: 0px; height: 100%;">
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Tiempo estimado de la orden</th>
                                                </tr>
                                                <tr>
                                                    <td>Orden: </td>
                                                    <td>{{data.cod_orden_producto_mpp}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha inicio: </td>
                                                    <td>{{tiempo_ord_mp[0]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Duracion : </td>
                                                    <td>{{tiempo_ord_mp[1]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                   
                        
                                    <div *ngIf="tiempo_ordenes">
                                        <table class=" table" >
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Tiempo de duración</th>
                                                </tr>
                                                <tr>
                                                    <td>Orden: </td>
                                                    <td>{{data.cod_orden}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha inicio: </td>
                                                    <td>{{tiempo_ordenes[0]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fecha fin: </td>
                                                    <td>{{tiempo_ordenes[1]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Duracion : </td>
                                                    <td>{{tiempo_ordenes[2]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                        
                                    <div *ngIf="montos">
                                        <table class=" table">
                                            <tbody>
                                                <tr>
                                                    <th COLSPAN=2>Montos del documento</th>
                                                </tr>
                                                <tr>
                                                    <td>Monto original de la factura: </td>
                                                    <td>{{montos[0]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Monto pendiente por cobrar: </td>
                                                    <td>{{montos[1]}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Monto cobrado: </td>
                                                    <td>{{montos[2]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                        
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" >Salir</button>
                        </div>
                    </div>
                </div>

