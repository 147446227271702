<div id="modal_carrito" class=" container-fluid" style="background-color: white; border-radius: 1rem;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">

        <div id="carrito_p1" class="row mat-elevation-z8" style=" border: 2px solid steelblue; background: steelblue;">
            <div id="content_1" class=" col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: steelblue;">
                    <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff; font-size: 11px;">Codigo solicitud toma fisica</mat-label>
                        <input style="font-size: 12px; color: #fff;" matInput placeholder="Cliente:" formControlName="cod_solicitud_toma_fisica" autocomplete="off" readonly>
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field> 
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: steelblue;">
                   <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff; font-size: 11px;">Cod deposito </mat-label>
                       <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput placeholder="" formControlName="cod_deposito" #num autocomplete="off" readonly>
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-2 px-0 py-2 h-100">
                <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue; background-color: steelblue;">
                   <mat-form-field class="materialinput py-1 px-1">
                       <mat-label style="color: #fff; font-size: 11px;">Fase </mat-label>
                       <input style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput placeholder="" [value]="data.fase === 'N/A' ? 'Fase 1' : data.fase === 'fase_1' ? 'Fase 2' : 'Fase 3'" #num autocomplete="off" readonly>
                       <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
            </div>
            <div class=" col-md-3 px-0 py-2 h-100">
                <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid steelblue;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255); font-size: 14px;">Buscar</mat-label>
                        <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)" > <!-- (keyup)="applyFilter($event)" -->
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div id="content_boton1" class="col-md-1 px-0 py-2 h-100">
                <div class="mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100"  matTooltip="Imprimir excel" style=" color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);" (click)="excel()" > 
                        <mat-icon>print</mat-icon>
                    </button>
                </div>
            </div>
          <!--  <div id="content_boton1" class="col-md-1 px-0 py-2 h-100">
                <div class="mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100"  matTooltip="Actualizar inventario" style=" color:rgb(255, 255, 255); background-color: rgb(234, 0, 255);" (click)="actualizarInventario()" > 
                        <mat-icon>print</mat-icon>
                    </button>
                </div>
            </div> -->
            <div id="content_boton1" class="col-md-1 px-0 py-2 h-100">
                <div class="mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-light h-100"  matTooltip="Guardar toma fisica" [disabled]="data.fase === 'fase_3' ? true :false"  [style.background-color]="data.fase === 'fase_3' ? 'black' :'rgb(40, 51, 197)'" style=" color:rgb(255, 255, 255); " (click)="modalrespuesta()" >
                        <mat-icon>save</mat-icon>
                    </button>
                </div>
            </div>
            <div class=" col-md-1 px-0 py-2 h-100">
                <div class="  mx-1 h-100">
                    <button class="btn btn-block btn-lg btn-danger h-100"  matTooltip="salir" (click)="cerrarmodal()" >
                        <mat-icon >exit_to_app</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div id="carrito_p2" class="row" style=" border: 2px solid steelblue;">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border cj-table  h-100">

                <table #table mat-table [dataSource]="dataSource" class=" table-secondary  text-center table-striped table-bordered table-condensed table-hover">
                   
                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: steelblue; padding: 5px; color: #fff;">
                            <h5><strong>Codigo producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> <!-- CANTIDAD DE PRODUCTOS DEVUELTOS --> </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: steelblue; padding: 5px; color: #fff;">
                            <h5><strong>Descripcion producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.des_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>

                    <ng-container matColumnDef="tipo_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: steelblue; padding: 5px; color: #fff;">
                            <h5><strong>Tipo producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tipo_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>

                    <ng-container matColumnDef="unidad_medida_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: steelblue; padding: 5px; color: #fff;">
                            <h5><strong>Unidad de medida</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.unidad_medida_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>

                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: steelblue; padding: 5px; color: #fff;">
                            <h5><strong>Cantidad producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>

                    <ng-container matColumnDef="cant_ingresada">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: steelblue; padding: 5px; color: #fff;">
                            <h5><strong>Cantidad ingresada </strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_ingresada}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>

                    <ng-container matColumnDef="fase_1">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #00A2C8; padding: 5px; color: #fff;">
                            <h5><strong>Fase 1</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.fase_1}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #00A2C8; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>
                    <ng-container matColumnDef="dif_fase_1">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #00A2C8; padding: 5px; color: #fff;">
                            <h5><strong>Diferencia Fase 1</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"> <b >{{element.dif_fase_1}}</b>
                                                                                                                </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #00A2C8; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>

                    <ng-container matColumnDef="fase_2">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #00BFC5; padding: 5px; color: #fff;">
                            <h5><strong>Fase 2</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.fase_2}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #00BFC5; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>
                    <ng-container matColumnDef="dif_fase_2">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #00BFC5; padding: 5px; color: #fff;">
                            <h5><strong>Diferencia Fase 2</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.dif_fase_2}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #00BFC5; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>

                    <ng-container matColumnDef="fase_3">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #39D9AB; padding: 5px; color: #fff;">
                            <h5><strong>Fase 3</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.fase_3}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #39D9AB; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>
                    <ng-container matColumnDef="dif_fase_3">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #39D9AB; padding: 5px; color: #fff;">
                            <h5><strong>Diferencia Fase 3</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.dif_fase_3}}</b></td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: #39D9AB; color: #fff;"> </td>  <!-- {{cantidad_productos()}} --> 
                    </ng-container>


                    <ng-container matColumnDef="tomar_data_ingresada">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: steelblue; padding: 5px; color: #fff;">
                            <h5><strong>Seleccionar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                            <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, $event)" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" [disabled]="element.fase == 'fase_3'" mat-raised-button style="height: 35px; border:2px solid;">
                                <mat-icon>add_circle</mat-icon>
                            </button>
                            <button matTooltip="BORRAR" (click)="tomar_data(false, element, $event)" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                <mat-icon>done</mat-icon>
                            </button>

                        </td>
                        <td mat-footer-cell *matFooterCellDef style="background-color: steelblue; color: #fff;"> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                </table>
            </div>
        </div>


    </form>
</div>
