import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Inject,AfterViewInit, ViewChild, ɵConsole } from '@angular/core';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Router } from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import { nkServices } from '../../servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'; 
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.css']
})
export class FormLoginComponent implements OnInit {
  hide = true;
  nombre:any;
  forma:FormGroup;
  accion:any;
  unidad:any;
  menu:any;
  des:any;
  email = new FormControl('', [Validators.required, Validators.email]);
  date = new FormControl(new Date());
  date2 = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  
    constructor( 
      private fb:FormBuilder,
      private dialogRef: MatDialogRef<FormLoginComponent>,
      private hashkey:HashKeyServices,
      private util:AppUtilService,
      private atrb:AtributosService,
      private router:Router,
      private notifi:ToastNotificationService,
      ///en el injectable llega la informacion del metodo padre en data
      @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data, 'primero');
      this.accion = data.accion;
      this.menu = data.menu;
      this.des = data.des;
      this.unidad = data.unidad;
        this.forma = fb.group({
          'nom_usuario': [data.cod_menu,[Validators.required]],
          'pass_usuario': [data.des_menu,[Validators.required]],
          'cod_app': [''],
          'proceso': [''],
          'token': [hashkey.encrypt(atrb.TOKEN)],
          
        });
      }
    ngOnInit(){
    }
   async login(res:any){
        try {
          let pass = await this.hashkey.encrypt(this.forma.value.pass_usuario);
          this.forma.patchValue({
            pass_usuario:pass,
            proceso: await this.atrb.LOGIN,
            cod_app: await this.hashkey.encrypt(environment.path_token),
          });
        this.dialogRef.close(this.forma.value);
        } catch (error) {
          console.log(error);
        }
    }
   async recuperarPass(){
      try {
        let valor = {proceso:await this.atrb.CERRAR}
        this.dialogRef.close(valor);
      } catch (error) {
        console.log(error);
          await this.util.cerrarSpinner();
          await this.notifi.customToastNotification(error.error.message);
      }
    }
}
