<div class="" id="modal">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
                <div class="modal-content" style="height: 100%; width: 100%; border: solid 2px rgb(12, 40, 49); border-radius: 1rem; ">
                    <div class="modal-header pb-0" style="background-color: #0076C7;      border-radius: 0.9rem 0.9rem 0 0">
                        <h3 class="col-12" class=" text-left" style="color: #FFF;" >
                            <strong >¡Hola <span style="color: #00B0FF;">{{NombreUsuario}}</span> de <span style="color: #00B0FF;">{{Unidad}}</span> !</strong> <br> 
                            <strong >Hagamos tu solicitud de requisición</strong>
                        </h3>
                        <div class="alert alert-danger alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
                            <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>{{AlertMensaje}}.
                            <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button> -->
                        </div>

                    </div>
                    <div class="modal-body container">
                            <form [formGroup]="forma" class="row">
                               
                                    <div class="mb-3 col-6">

                                        <label id="example-radio-group-label" class="font-weight-bold">Tipo de solicitud </label>

                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-radio-group (change)="verForma()" formControlName="tipo_solicitud_compra" >
                                                <mat-radio-button value="SAF"  class="mr-2">SAF</mat-radio-button>
                                                <mat-radio-button value="SOF" >SOF</mat-radio-button>
                                            </mat-radio-group>
                                        </div>

                                    </div>
                                    
                                    <div class="mb-3 col-6">

                                        <label id="example-radio-group-label" class="font-weight-bold">Modo de la solicitud </label>

                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <mat-radio-group (change)="verForma()" formControlName="modalidad_solicitud_compra" >
                                                <mat-radio-button value="Servicios" disabled  class="mr-2">Servicio</mat-radio-button>
                                                <mat-radio-button value="Producto" >Producto</mat-radio-button>
                                            </mat-radio-group>
                                        </div>

                                    </div>

                                    <div class=" col-6 pt-3 ">
                                        <mat-form-field  >
                                            <mat-label>Centro de costo</mat-label>
                                            <!-- <input matInput type="text" id="centorDcosto"  value="centro_costo" class="text-center"readonly > -->
                                            <mat-select  (selectionChange)="centroCosto($event)" formControlName="centro_costo">
                                                <mat-option  *ngFor=" let option of centroCostoArray" [value]="option.cod_centro">
                                                    {{option.des_unidad | uppercase}} - {{option.cod_centro}}
                                                </mat-option>
                                             </mat-select>
                                          </mat-form-field>
                                    </div>

                                    <div class=" col-6 pt-3 ">
                                        <mat-form-field  >
                                            <mat-label>Partida Presupuestaria</mat-label>
                                            <mat-select (selectionChange)="validarPartida($event)" formControlName="cod_cuenta_gasto">
                                                <mat-option  *ngFor=" let option of partidas" [value]="option.cod_cuenta_gasto">
                                                    {{option.des_cuenta_gasto}}
                                                </mat-option>
                                             </mat-select>
                                        </mat-form-field>
                                    </div>


                                    <div class="mb-3 col-6 p-1">

                                        <mat-form-field appearance="fill">
                                            <mat-label><strong style="color: #000;">Tipo de Producto</strong></mat-label>
                                            <mat-select formControlName="tipo_producto">
                                              <mat-option *ngFor="let option of tiposProducto" [value]="option.tipo_producto">
                                                {{option.tipo_producto}} |
                                                <small>{{option.des_tipo_producto}}</small>
                                              </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </div>

                                    <div class=" col-6 p-1 ">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Fecha estimada</mat-label>
                                            <input matInput formControlName="fecha_estimada" [min]="minDay" [matDatepicker]="picker" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    
                                    <div class="col-12">

                                        <label id="example-radio-group-label" class="font-weight-bold">Prioridad </label>

                                        <div class="col-md-12 col-sm-12 col-xs-12 justify-content-center d-flex">
                                            <mat-radio-group formControlName="prioridad_solicitud_compra" >
                                                <mat-radio-button value="Alta" class="mr-2" ><span style="color: red;">Alta</span> </mat-radio-button>
                                                <mat-radio-button value="Media" class="mr-2" ><span style="color: #FFC35E;">Media</span></mat-radio-button>
                                                <mat-radio-button value="Baja" ><span style="color: #00D0FF;">Baja</span></mat-radio-button>
                                            </mat-radio-group>
                                        </div>

                                    </div>


                                    <div class=" col-12 p-1 d-flex flex-column justify-content-center align-items-center">
                                        <textarea formControlName="observaciones" name="" id="" cols="30" rows="3" placeholder="Observaciones(Requerido)" (ngModelChange)="fnTextValid($event)" style="resize: none; border-radius: 1rem; padding: .5rem;"></textarea>
                                        <div>
                                            <small class="mr-2">{{textValid}}/120</small>
                                            <small>min.30</small>
                                        </div>
                                        
                                    </div>

                                </form>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">

                            <button mat-button class=" btn-danger " (click)="cerrarmodal()" style="margin-right: 5px; border-radius: 1rem;">Cancelar</button>

                            <button mat-button class=" btn-primary " style="border-radius: 1rem;" (click)="listo()" [disabled]="forma.invalid">Listo</button>


                        </div>

                    </div>
                </div>
        </div>
    </div>
    <div class="row">

    </div>
</div>