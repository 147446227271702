<div class="container-fluid modlpos" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
    <div class="row">
        <div class="col-md-3 modlpos">
                <div class="row px-1" style=" border: 2px solid #475bca; height: 9vh;">
                            <mat-form-field appearance="outline" class="col-12 p-0 m-0 pb-0">
                                <mat-label class="text-white">Inventario</mat-label>
                                <mat-select formControlName="cod_deposito"  (selectionChange)="listarproductot($event)" autocomplete="off">
                                    <mat-option *ngFor="let i of depositos" [value]="i.cod_deposito">{{i.des_deposito}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                </div>
                <div class="row px-1" style=" border: 2px solid #475bca; height: 9vh;">
                            <mat-form-field appearance="outline" class="col-12" style="padding: 0 !important;">
                                <mat-label style="color: #fff; font-size: 11px;">Productos</mat-label>
                                <input class="" style="font-size: 12px; color: #fff;" type="text" matInput formControlName="producto_pedido" [matAutocomplete]="autoGroup" #producto_pedido>
                                <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                    <mat-icon>search</mat-icon>
                                    </span>
                                <mat-autocomplete (optionSelected)="buscarDataProductoUmCant($event.option.value)" #autoGroup="matAutocomplete" class="px-1">
                                    <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                        <mat-option   style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                            <b>{{i}}</b>
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-autocomplete>
                            </mat-form-field>
                </div>
                <div class="row px-1" style=" border: 2px solid #475bca; height: 9vh;">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label style="color: rgb(255, 255, 255); font-size: 11px;">CI/RIF</mat-label>
                        <input #cod_cliente style="font-size: 11px; color: #fff;" matInput formControlName="cod_cliente" autocomplete="off" >
                        <span role="button" (click)="buscarCliente(cod_cliente)" class="ml-2" style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
                <div class="row px-1" style=" border: 2px solid #475bca; height: 9vh;">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label style="color: rgb(255, 255, 255); font-size: 11px;">Nombre cliente</mat-label>
                        <input style="font-size: 11px; color: #fff;" matInput  formControlName="des_cliente" autocomplete="off" >
                    </mat-form-field>
                </div>
                <div class="row px-1" style=" border: 2px solid #475bca; height: 9vh;">
                    <mat-form-field appearance="outline" class="w-50">
                        <mat-label style="color: #fff; font-size: 11px;">Facturacón / Tipo cliente</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput  formControlName="mod_factur" #mod_factur autocomplete="off" readonly>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-50">
                        <mat-label style="color: #fff; font-size: 11px;">UM</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput  formControlName="unidad_manejo" #unidad_manejo autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(255, 94, 0);" *ngIf="!bandera_2">report_problem</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
                <div class="row px-1" style=" border: 2px solid #475bca; height: 9vh;">
                    <mat-form-field appearance="outline" class="w-50">
                        <mat-label style="color: #fff; font-size: 11px;">Cantidad</mat-label>
                        <input style="font-size: 12px; color: #fff; " (keyup)="cantidad_bultos_prod()" maxlength="11" type="text " matInput formControlName="cantidad_bultos" #cantidad_bultos autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-50">
                        <mat-label style="color: #fff; font-size: 11px;">Solicitado</mat-label>
                        <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput  formControlName="cantidad_pedido" #num autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field>
                </div>
                <div class="row px-1 " style=" border: 2px solid #475bca; height: 9vh;">
                    <mat-form-field appearance="outline" class="w-50">
                                <mat-label style="color: #fff; font-size: 11px;">Existencia</mat-label>
                                <input style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput  formControlName="cantidad_existencia" #cantidad_existencia autocomplete="off" readonly>
                                <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                    <mat-icon style="color: rgb(255, 94, 0);" *ngIf="!bandera">report_problem</mat-icon>
                                </span>
                    </mat-form-field>
                </div>
                <div class="row px-1 " style=" border: 2px solid #475bca; height: 9vh;">
                    <mat-form-field appearance="outline" class="w-50">
                        <mat-label style="color: #fff; font-size: 14px;">Descuento</mat-label>
                        <input class="text-right px-2" style="font-size: 12px; color: #fff;" (keyup)="format($event,'desc_prod', 'tasa')" placeholder="0,00" maxlength="6" #desc_prod matInput formControlName="desc_prod" id="desc_prod" autocomplete="off">
                        <span class="ml-2" style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>%</mat-icon>
                        </span>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-50">
                        <mat-label style="color: #fff; font-size: 14px;">Tasa del dia</mat-label>
                        <input class="text-right px-2" style="font-size: 12px; color: #fff;" (keyup)="format($event,'desc_prod', 'tasa')" placeholder="0,00" maxlength="6" #desc_prod matInput formControlName="desc_prod" id="desc_prod" autocomplete="off">
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'"><span class="ml-1">{{moneda}}</span></span>
                    </mat-form-field>
                </div>
                <div class="row px-1 " style=" border: 2px solid #475bca; height: 9vh;">
                    <mat-form-field appearance="outline" class="w-50">
                        <mat-label style="color: #fff; font-size: 14px;">Lista de precios</mat-label>
                        <input class="text-right px-2" style="font-size: 12px; color: #fff;" (keyup)="format($event,'desc_prod', 'tasa')" placeholder="0,00" maxlength="6" #desc_prod matInput formControlName="desc_prod" id="desc_prod" autocomplete="off">
                        <span class="ml-2" style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>%</mat-icon>
                        </span>
                    </mat-form-field>
                </div>
                <div class="row px-1 " style=" border: 2px solid #475bca; height: 9vh;">
                </div>
                <div class="row px-1 " style=" border: 2px solid #475bca; height: 9vh;">
                </div>
        </div>
        <input style="font-size: 12px;" style="display: none;" placeholder="" formControlName="cod_lista_precio" #cod_lista_precio autocomplete="off" readonly>
        <input style="font-size: 12px;" style="display: none;" #iva placeholder="" formControlName="iva_pedido" autocomplete="off" readonly>
        <div class="col-md-9 modlpos">
            <div class="row ">
                    <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-start p-1">
                        <span class="text-white fw-bold">Formula de ( {{data.des_producto | uppercase}} ) </span>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end p-1">
                        <input class="form-control mr-1" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                        <button matTooltip="GUARDAR" (click)="modalrespuesta()"  class=" mr-1 btn bg-primary text-white"><mat-icon >save</mat-icon></button>
                        <button matTooltip="AGREGAR" (click)="addElement()"  class="mr-1 btn bg-warning text-white"><mat-icon >add</mat-icon></button>
                        <button matTooltip="SALIR" (click)="cerrarmodal()" class="btn bg-danger text-white"><mat-icon >exit_to_app</mat-icon></button>
                    </div>
            </div>
            <div class="row modalpos" style=" background-color:#29388f;">
                <div class="table-responsive" >
                    <table #table mat-table [dataSource]="dataSource1" class=" table-primary  text-center table-striped table-condensed table-hover">

                        <ng-container matColumnDef="cod_img">
                            <th mat-header-cell *matHeaderCellDef class="text-center " style="padding: 5px; color: #fff; background-color:#2f3033;" >
                                <h5><strong>Imagen</strong></h5>
                            </th>
                            <td  mat-cell *matCellDef="let element"> <img width="50px" height="50px" class="rounded" [src]="element.cod_img" alt=""></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;"></td>
                        </ng-container>

                        <ng-container matColumnDef="cod_lista_precio">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Lista precios</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">TOTAL ITEMS ----> </td>
                        </ng-container>

                        <ng-container matColumnDef="tasa_lista_precio">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Tasa</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.tasa_lista_precio}}</b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;"> </td>
                        </ng-container>

                        <ng-container matColumnDef="cod_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Codigo</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_producto}}</b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{cantidad_item()}} </td>
                        </ng-container>
                        <ng-container matColumnDef="lista_precio">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Lista precios N°</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cod_lista_precio}}</b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">TOTAL ITEMS ----> </td>
                        </ng-container>

                        <ng-container matColumnDef="des_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Producto</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.des_producto}}</b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">CANTIDAD DE PRODUCTOS EN EL PEDIDO -----> </td>
                        </ng-container>

                        <ng-container matColumnDef="cant_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Cantidad</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.cant_producto}}</b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{cant_prod}} </td>
                        </ng-container>

                        <ng-container matColumnDef="costo_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Costo</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.costo_producto}} <span class="ml-1">{{moneda}}</span></b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{cost_prod}} <span class="ml-1">{{moneda}}</span> </td>
                        </ng-container>

                        <ng-container matColumnDef="costo_base_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Base</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.costo_base_producto}} <span class="ml-1">{{moneda}}</span></b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{cost_base}} <span class="ml-1">{{moneda}}</span> </td>
                        </ng-container>

                        <ng-container matColumnDef="descuento">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Descuento</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.desc_prod}} % </b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;"></td>
                        </ng-container>

                        <ng-container matColumnDef="monto_prod_descuento">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Monto descuento</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.desc_prod_monto}} <span class="ml-1">{{moneda}}</span> </b></td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;">{{cost_prod_desc_mont}} <span class="ml-1">{{moneda}}</span> </td>
                        </ng-container>

                        <ng-container matColumnDef="subtotal_producto">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Sub-total</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px;"><b>{{element.subtotal_producto}} <span class="ml-1">{{moneda}}</span> </b></td>
                            <td mat-footer-cell class="px-1" *matFooterCellDef style="background-color:#10339d; color: #fff;">{{sub_total_prod}} <span class="ml-1">{{moneda}}</span> </td>
                        </ng-container>

                        <ng-container matColumnDef="remov_elem">
                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color:#2f3033;">
                                <h5><strong>Eliminar</strong></h5>
                            </th>
                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                <button class="bg-dark"  matTooltip="QUITAR PRODUCTO DE LA LISTA" mat-mini-fab (click)="remove(element)">
                                        <mat-icon >remove_shopping_cart</mat-icon>
                            </button>
                            </td>
                            <td mat-footer-cell *matFooterCellDef style="background-color:#2f3033; color: #fff;"> </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1 px-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label style="color: #fff; font-size: 11px;">Descuento</mat-label>
                            <input class="text-right" style="font-size: 12px; color: #fff;" maxlength="6" id="desc_prod_id" (keyup)="format($event,'desc_prod_id','descuento')" #desc_pedido matInput placeholder="0,00" formControlName="desc_pedido" autocomplete="off">
                            <span class="ml-2" style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">%</span>
                        </mat-form-field>
                </div>
                <div class="col-md-2 px-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label style="color: #fff; font-size: 11px;">Exento</mat-label>
                            <input style="font-size: 12px; color: #fff;" #exento_monto matInput placeholder="Sub-total:" formControlName="monto_exento" autocomplete="off" readonly>
                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'"><span class="ml-1">{{moneda}}</span></span>
                        </mat-form-field>
                </div>
                <div class="col-md-2 px-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label style="color: #fff; font-size: 11px;">Sub-total</mat-label>
                            <input style="font-size: 12px; color: #fff;" #subtotal_pedido matInput placeholder="Sub-total:" formControlName="subtotal_pedido" autocomplete="off" readonly>
                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'"><span class="ml-1">{{moneda}}</span></span>
                        </mat-form-field>
                </div>
                <div class="col-md-2 px-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label style="color: #fff; font-size: 11px;">Descuento total</mat-label>
                            <input style="font-size: 12px; color: #fff;" #desc_pedido_monto matInput placeholder="" formControlName="desc_pedido_monto" autocomplete="off" readonly>
                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'"><span class="ml-1">{{moneda}}</span></span>
                        </mat-form-field>
                </div>
                <div class="col-md-2 px-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label style="color: #fff; font-size: 11px;">I.V.A</mat-label>
                            <input style="font-size: 12px; color: #fff;" matInput placeholder="I.V.A:" formControlName="iva_monto" autocomplete="off" #iva_monto readonly>
                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'"><span class="ml-1">{{moneda}}</span></span>
                        </mat-form-field>
                </div>
                <div class="col-md-2 px-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label style="color: #fff; font-size: 11px;">Total BS</mat-label>
                            <input class="" style="font-size: 12px; color: #fff;" #total_pedido matInput placeholder="Total:" formControlName="total_pedido" autocomplete="off" readonly>
                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'"><span class="ml-1">{{moneda}}</span> </span>
                        </mat-form-field>
                </div>
                <div class="col-md-1 px-1">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label style="color: #fff; font-size: 11px;">Total $</mat-label>
                            <input class="" style="font-size: 12px; color: #fff;" #total_pedido matInput placeholder="Total:" formControlName="total_pedido" autocomplete="off" readonly>
                            <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'"><span class="ml-1">$</span> </span>
                        </mat-form-field>
                </div>
            </div>
        </div>
    </div>
  </form>
</div>
