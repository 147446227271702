<!-- FORMULARIO PARA INSERTAR CONDICIONADO -->
<!-- <div class="container-fluid mat-elevation-z8" style="height: 100%; padding: 5%;" *ngIf="accion == 'insertar'">
    <form [formGroup]="forma" class="materialformtamaño">
        <div class="row">
            <div class="col-md-12 ">
                <p>
                    Proveedores Factory Cosmetics.

                </p>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Rif/Cedula</mat-label>
                        <input matInput placeholder="J000000000/V000000000" formControlName="cod_prov" autocomplete="off">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>business</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Descrip proveedor</mat-label>
                        <input style="color:#000; font-size:11px;" matInput placeholder="Descrip cliente:" formControlName="des_prov" autocomplete="off">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>group</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Correo</mat-label>
                        <input matInput placeholder="Correo@example.com" required formControlName="correo_prov" autocomplete="off">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>mail</mat-icon>
                            </span>
                    </mat-form-field>
                </p>
            </div>
        </div>

        <div class="row">

            <div class="col-md-3">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="estatus" placeholder="Tipo" formControlName="tipo_prov" autocomplete="off">
                            <mat-option value="MP">Materia Prima</mat-option>
                            <mat-option value="ME">Material de empaque</mat-option>

                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-3">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="estatus" placeholder="Estatus" formControlName="estatus_prov" autocomplete="off">
                            <mat-option value="A">A (Activo)</mat-option>
                            <mat-option value="I">I (Inactivo)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Dirección</mat-label>
                        <textarea style="color:#000; font-size:11px;" matInput placeholder="Dirección" autocomplete="off" formControlName="dir_prov"> </textarea>
                    </mat-form-field>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Telefono 1</mat-label>
                        <input matInput placeholder="0000-00000000" autocomplete="off" formControlName="telefono1_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>call</mat-icon>
                          </span>
                    </mat-form-field>
                </p>
            </div>

            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Telefono 2</mat-label>
                        <input matInput placeholder="0000-00000000" autocomplete="off" formControlName="telefono2_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>call</mat-icon>
                          </span>
                    </mat-form-field>
                </p>
            </div>

            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Pais</mat-label>
                        <input matInput placeholder="Pais" autocomplete="off" formControlName="pais_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>place</mat-icon>
                            </span>
                    </mat-form-field>
                </p>
            </div>


        </div>
        <div class="row">
            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Estado</mat-label>
                        <input matInput placeholder="Estado" autocomplete="off" formControlName="pais_estado_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>place</mat-icon>
                            </span>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Retenciones</mat-label>
                        <input matInput placeholder="Retenciones" autocomplete="off" formControlName="reten_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>assessment</mat-icon>
                          </span>
                    </mat-form-field>
                </p>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="modalrespuesta()" [disabled]="forma.invalid" mat-raised-button color="accent">Actualizar</button>
                    <button *ngIf="accion == 'insertar'" class="btn-block" (click)="modalrespuesta()" [disabled]="forma.invalid" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
                </p>
            </div>
        </div>
    </form>
</div> -->


<div class="" id="modal" *ngIf="accion == 'insertar'">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content h-100">
                    <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-left"><strong> Proveedor nuevo</strong> </h3>

                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div class="row py-0">
                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                            <mat-form-field class="materialinput">
                                                <mat-label style="color: #000">Rif/Cedula</mat-label>
                                                <input style="font-size: 12px; color: black;" maxlength="10" matInput placeholder="J000000000" formControlName="cod_prov" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>business</mat-icon>
                                            </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                            <mat-form-field class="materialinput">
                                                <mat-label style="color: #000">Descrip proveedor</mat-label>
                                                <input style="font-size: 12px; color: black;" style="color:#000; font-size:11px;" matInput placeholder="Descrip proveedor:" formControlName="des_prov" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>group</mat-icon>
                                            </span>
                                            </mat-form-field>
                                        </div>


                                        <div class="col-md-6 col-sm-6 col-xs-6 py-0">
                                            <mat-form-field class="materialinput">
                                                <mat-label style="color: #000">Correo</mat-label>
                                                <input style="font-size: 12px; color: black;" matInput placeholder="Correo@example.com" required formControlName="correo_prov" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>mail</mat-icon>
                                                </span>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-select name="estatus" placeholder="Tipo" formControlName="tipo_prov" autocomplete="off">
                                                    <mat-option value="MP">Materia Prima</mat-option>
                                                    <mat-option value="ME">Material de empaque</mat-option>

                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-select name="estatus" placeholder="Estatus" formControlName="estatus_prov" autocomplete="off">
                                                    <mat-option value="A">A (Activo)</mat-option>
                                                    <mat-option value="I">I (Inactivo)</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Dirección</mat-label>
                                                <textarea style="color:#000; font-size:11px;" matInput placeholder="Dirección" autocomplete="off" formControlName="dir_prov"> </textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row py-0">
                                        <div class="col-md-3 col-sm-3 col-xs-3 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Telefono 1</mat-label>
                                                <input matInput placeholder="0000-00000000" autocomplete="off" formControlName="telefono1_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>call</mat-icon>
                                                </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-3 col-sm-3 col-xs-3 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Telefono 2</mat-label>
                                                <input matInput placeholder="0000-00000000" autocomplete="off" formControlName="telefono2_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>call</mat-icon>
                                                </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Pais</mat-label>
                                                <input matInput placeholder="Pais" autocomplete="off" formControlName="pais_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                  <mat-icon>place</mat-icon>
                                                  </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Estado</mat-label>
                                                <input matInput placeholder="Estado" autocomplete="off" formControlName="pais_estado_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                  <mat-icon>place</mat-icon>
                                                  </span>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Retenciones</mat-label>
                                                <input matInput placeholder="Retenciones" autocomplete="off" formControlName="reten_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                  <mat-icon>assessment</mat-icon>
                                                </span>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- FORMULARIO PARA ACTUALIZAR CONDICIONADO -->


<!-- FORMULARIO PARA INSERTAR CONDICIONADO -->
<!-- <div class="container-fluid mat-elevation-z8" style="height: 100%; padding: 5%;" *ngIf="accion == 'actualizar'">
    <form [formGroup]="forma" class="materialformtamaño">
        <div class="row">
            <div class="col-md-12 ">
                <p>
                    Proveedores Factory Cosmetics.

                </p>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Rif/Cedula</mat-label>
                        <input matInput placeholder="J000000000/V000000000" formControlName="cod_prov" autocomplete="off" readonly>
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>business</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Descrip proveedor</mat-label>
                        <input style="color:#000; font-size:11px;" matInput placeholder="Descrip cliente:" formControlName="des_prov" autocomplete="off">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>group</mat-icon>
                        </span>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Correo</mat-label>
                        <input matInput placeholder="Correo@example.com" required formControlName="correo_prov" autocomplete="off">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>mail</mat-icon>
                            </span>
                    </mat-form-field>
                </p>
            </div>
        </div>

        <div class="row">

            <div class="col-md-3">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="estatus" placeholder="Tipo" formControlName="tipo_prov" autocomplete="off">
                            <mat-option value="MP">Materia Prima</mat-option>
                            <mat-option value="ME">Material de empaque</mat-option>

                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-3">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-select name="estatus" placeholder="Estatus" formControlName="estatus_prov" autocomplete="off">
                            <mat-option value="A">A (Activo)</mat-option>
                            <mat-option value="I">I (Inactivo)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Dirección</mat-label>
                        <textarea style="color:#000; font-size:11px;" matInput placeholder="Dirección" autocomplete="off" formControlName="dir_prov"> </textarea>
                    </mat-form-field>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Telefono 1</mat-label>
                        <input matInput placeholder="0000-00000000" autocomplete="off" formControlName="telefono1_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>call</mat-icon>
                          </span>
                    </mat-form-field>
                </p>
            </div>

            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Telefono 2</mat-label>
                        <input matInput placeholder="0000-00000000" autocomplete="off" formControlName="telefono2_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                          <mat-icon>call</mat-icon>
                          </span>
                    </mat-form-field>
                </p>
            </div>

            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Pais</mat-label>
                        <input matInput placeholder="Pais" autocomplete="off" formControlName="pais_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>place</mat-icon>
                            </span>
                    </mat-form-field>
                </p>
            </div>


        </div>
        <div class="row">
            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Estado</mat-label>
                        <input matInput placeholder="Estado" autocomplete="off" formControlName="pais_estado_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>place</mat-icon>
                            </span>
                    </mat-form-field>
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    <mat-form-field class="materialinput">
                        <mat-label>Retenciones</mat-label>
                        <input matInput placeholder="Retenciones" autocomplete="off" formControlName="reten_prov">
                        <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon>assessment</mat-icon>
                          </span>
                    </mat-form-field>
                </p>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <p>
                    <button *ngIf="accion == 'actualizar'" class="btn-block" (click)="modalrespuesta()" [disabled]="forma.invalid" mat-raised-button color="accent">Actualizar</button>
                    <button *ngIf="accion == 'insertar'" class="btn-block" (click)="modalrespuesta()" [disabled]="forma.invalid" mat-raised-button color="accent"><mat-icon>save</mat-icon>Guardar</button>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <button class="btn-block" (click)="cerrarmodal()" mat-raised-button color="primary"><mat-icon>reply</mat-icon>Salir</button>
                </p>
            </div>
        </div>
    </form>
</div> -->

<div class="" id="modal" *ngIf="accion == 'actualizar'">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 5px;">
            <form [formGroup]="forma" class="">
                <div class="modal-content h-100">
                    <div class="modal-header" style="background-color: rgb(9, 138, 177); color: #fff;">
                        <h3 class="modal-title" class=" text-left"><strong> Editar Proveedor </strong> </h3>

                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <div class="row py-0">
                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">
                                            <mat-form-field class="materialinput">
                                                <mat-label style="color: #000">Rif/Cedula</mat-label>
                                                <input style="font-size: 12px; color: black;" maxlength="10" matInput placeholder="J000000000" formControlName="cod_prov" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          <mat-icon>business</mat-icon>
                                          </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">
                                            <mat-form-field class="materialinput">
                                                <mat-label style="color: #000">Descrip proveedor</mat-label>
                                                <input style="font-size: 12px; color: black;" style="color:#000; font-size:11px;" matInput placeholder="Descrip proveedor:" formControlName="des_prov" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                          <mat-icon>group</mat-icon>
                                          </span>
                                            </mat-form-field>
                                        </div>


                                        <div class="col-md-6 col-sm-6 col-xs-6 py-0">
                                            <mat-form-field class="materialinput">
                                                <mat-label style="color: #000">Correo</mat-label>
                                                <input style="font-size: 12px; color: black;" matInput placeholder="Correo@example.com" required formControlName="correo_prov" autocomplete="off">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              <mat-icon>mail</mat-icon>
                                              </span>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-4 col-sm-4 col-xs-4 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-select name="estatus" placeholder="Tipo" formControlName="tipo_prov" autocomplete="off">
                                                    <mat-option value="MP">Materia Prima</mat-option>
                                                    <mat-option value="ME">Material de empaque</mat-option>

                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-select name="estatus" placeholder="Estatus" formControlName="estatus_prov" autocomplete="off">
                                                    <mat-option value="A">A (Activo)</mat-option>
                                                    <mat-option value="I">I (Inactivo)</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Dirección</mat-label>
                                                <textarea style="color:#000; font-size:11px;" matInput placeholder="Dirección" autocomplete="off" formControlName="dir_prov"> </textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row py-0">
                                        <div class="col-md-3 col-sm-3 col-xs-3 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Telefono 1</mat-label>
                                                <input matInput placeholder="0000-00000000" autocomplete="off" formControlName="telefono1_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              <mat-icon>call</mat-icon>
                                              </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-3 col-sm-3 col-xs-3 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Telefono 2</mat-label>
                                                <input matInput placeholder="0000-00000000" autocomplete="off" formControlName="telefono2_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              <mat-icon>call</mat-icon>
                                              </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Pais</mat-label>
                                                <input matInput placeholder="Pais" autocomplete="off" formControlName="pais_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>place</mat-icon>
                                                </span>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Estado</mat-label>
                                                <input matInput placeholder="Estado" autocomplete="off" formControlName="pais_estado_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>place</mat-icon>
                                                </span>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-xs-2 py-0">

                                            <mat-form-field class="materialinput">
                                                <mat-label>Retenciones</mat-label>
                                                <input matInput placeholder="Retenciones" autocomplete="off" formControlName="reten_prov">
                                                <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                <mat-icon>assessment</mat-icon>
                                              </span>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>