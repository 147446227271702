import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import * as moment from 'moment';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormKeyComponent } from '../form-key/form-key.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { MatPaginator } from '@angular/material/paginator';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../servicios/formato_datapick';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { FormDireccionesComponent } from '../form-direcciones/form-direcciones.component';
import { AppUtilService } from '../../servicios/app.util.service';
import { AtributosService } from '../../servicios/atributos.service';
import { HashKeyServices } from '../../servicios/app.hashkey.services';
import { ExcelService } from '../../servicios/excel.service';
import { ToastNotificationService } from '../../servicios/toast-notification.service';

@Component({
  selector: 'app-form-movimientos-prod',
  templateUrl: './form-movimientos-prod.component.html',
  styleUrls: ['./form-movimientos-prod.component.css'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})
export class FormMovimientosProdComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('cod_dep',{static:true}) cod_dep:ElementRef;
  @ViewChild('des_prod',{static:true}) des_prod:ElementRef;
  @ViewChild('descrip_dep',{static:true}) descrip_dep:ElementRef;
  @ViewChild('fecha_lista',{static:true}) fecha_lista:ElementRef;

  value1 = '';
  nombre:any;
forma:FormGroup;
stateForm: FormGroup;

accion:any;
dialogSpin:any;
unidad:any;
unidadp:any = [];
menu:any;
producto:any = [];
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
dataSource=new MatTableDataSource<any>();
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private dialog: MatDialog, 
    private snackBar: MatSnackBar, 
    private servicio:nkServices,
    private formb:FormBuilder, 
    private fb:FormBuilder,
    private util:AppUtilService,
    private atrb:AtributosService,
    private hashkey:HashKeyServices,
    private excel:ExcelService,
    private notifi:ToastNotificationService,
    private dialogRefp: MatDialogRef<FormMovimientosProdComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
      this.forma = fb.group({
        'cod_usuario':'',
        'des_usuario':'',
        'cod_producto':'',
        'fecha_inicio':'',
        'fecha_fin':'',
        'fecha':'',
        'accion':data.accion
      });
    }

  ngOnInit(){
    this.cod_dep.nativeElement.value = this.data.objeto.cod_deposito;
    this.des_prod.nativeElement.value = this.data.objeto.cod_producto.toUpperCase()+' '+this.data.objeto.des_producto;
    this.formatoLista(null);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = this.atrb.MENSAJES_FIJOS.registros_por_pagina;
  }

async  formatoLista(xvalor){
    try {
      await this.util.abrirSpinner();
        let msg = xvalor;
               this.ELEMENT_DATA = [];
                   this.displayedColumns = [
                                             'inventario_salida',
                                             'inventario_entrada',
                                             'cod_producto',
                                             'des_producto',
                                             'operacion',
                                             'cod_proceso',
                                             'cod_orden_prod',
                                             'cod_orden_prod_mpp',
                                             'cant_prod_solicitada',
                                             'cant_prod_actual',
                                             'cant_prod_restante',
                                             'procesos',
                                           ];
                   this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
                   this.dataSource1.paginator = this.paginator;
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.rango_fecha);
    } catch (error) {
      console.log(error);
    }
  }
async  buscarProductoMovimientos(xvalor){
    try {
      await this.util.abrirSpinner();
      await this.servicio.consultar_sesionhttp(null).toPromise();
      this.forma.patchValue({
        fecha_inicio:'',
        fecha_fin:'',
        fecha:''
      });
        let msg = xvalor;
        this.data.objeto.cod_empresa = await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa);
        const response = await  this.servicio.buscarProductoMovimientoshttp(this.data.objeto).toPromise();
               this.ELEMENT_DATA = [];
                   for (const i in response.resul) {
                    switch (response.resul[i].operacion.toUpperCase()) {
                      case 'FACTURACION':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].des_inv,
                          inventario_salida:response.resul[i].des_inv,
                          inventario_entrada:response.resul[i].operacion,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:'N/A',
                          cod_orden_prod_mpp:'N/A',
                          cod_factura:response.resul[i].cod_factura,
                          cod_nota:response.resul[i].cod_nota,
                          cod_pedido:response.resul[i].cod_pedido,
                          des_cliente:response.resul[i].des_cliente,
                          cant_prod_solicitada:response.resul[i].cant_pedido,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_cambio,
                          des_usuario:response.resul[i].des_usuario,
                          fecha_cambio:response.resul[i].fecha_cambio,
                          hora_cambio:response.resul[i].hora_cambio,
                          comentarios:response.resul[i].comentarios

                         });
                        break;
                        case 'CARGO':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:'N/A',
                           cod_orden_prod_mpp:'N/A',
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'CARGO ORDEN INCOMPLETA':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:response.resul[i].cod_orden_prod,
                           cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'DESCARGO':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:'N/A',
                           cod_orden_prod_mpp:'N/A',
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'DESCARGO ORDEN EXCEDENTE':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:response.resul[i].cod_orden_prod,
                           cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'PRODUCCION':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].ord_prod,
                          cod_orden_prod_mpp:'N/A',
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'PRODUCCIONMPP':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:'produccion',
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].cod_orden_prod,
                          cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'LABORATORIO':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].cod_orden_prod,
                          cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:'N/A'
                         });
                        break;
                        case 'CONSIGNACION':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:response.resul[i].cod_proceso,
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'TRASLADO':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'EMBALAJE':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:response.resul[i].ord_prod,
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'DEVOLUCION':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:response.resul[i].ord_prod,
                            cod_nota:response.resul[i].cod_nota,
                            cod_pedido:response.resul[i].cod_pedido,
                            des_cliente:response.resul[i].des_cliente,
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'ANULACION':
                            this.ELEMENT_DATA.push({
                              vacio:'',
                              inventario_gestionado:response.resul[i].des_inv,
                              inventario_salida:response.resul[i].operacion,
                              inventario_entrada:response.resul[i].des_inv,
                              cod_producto:response.resul[i].cod_producto,
                              des_producto:response.resul[i].des_producto,
                              operacion:response.resul[i].operacion,
                              cod_proceso:'N/A',
                              cod_orden_prod:'N/A',
                              cod_orden_prod_mpp:'N/A',
                              cod_factura:response.resul[i].cod_factura,
                              cod_nota:response.resul[i].cod_nota,
                              cod_pedido:response.resul[i].cod_pedido,
                              des_cliente:response.resul[i].des_cliente,
                              cant_prod_solicitada:response.resul[i].cant_pedido,
                              cant_prod_actual:response.resul[i].cant_prod_actual,
                              cant_prod_restante: response.resul[i].cant_prod_cambio,
                              des_usuario:response.resul[i].des_usuario,
                              fecha_cambio:response.resul[i].fecha_cambio,
                              hora_cambio:response.resul[i].hora_cambio,
                              comentarios:response.resul[i].comentarios
                             });
                            break;
                          case 'RECEPCION':
                            this.ELEMENT_DATA.push({
                              vacio:'',
                              inventario_gestionado:response.resul[i].inventario_entrada,
                              inventario_salida:response.resul[i].inventario_salida,
                              inventario_entrada:response.resul[i].inventario_entrada,
                              cod_producto:response.resul[i].cod_producto,
                              des_producto:response.resul[i].des_producto,
                              operacion:response.resul[i].operacion,
                              cod_proceso:'N/A',
                              cod_orden_prod:response.resul[i].ord_prod,
                              cod_orden_prod_mpp:'N/A',
                              cod_factura:'N/A',
                              cod_nota:'N/A',
                              cod_pedido:'N/A',
                              des_cliente:'N/A',
                              cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                              cant_prod_actual:response.resul[i].cant_prod_actual,
                              cant_prod_restante: response.resul[i].cant_prod_restante,
                              des_usuario:response.resul[i].des_usuario,
                              fecha_cambio:response.resul[i].fecha_cambio,
                              hora_cambio:response.resul[i].hora_cambio,
                              comentarios:response.resul[i].comentarios
                             });
                            break;
                    
                      default:
                        break;
                    } 
                   }
                   this.displayedColumns = [
                                             'inventario_salida',
                                             'inventario_entrada',
                                             'cod_producto',
                                             'des_producto',
                                             'operacion',
                                             'cod_proceso',
                                             'cod_orden_prod',
                                             'cod_orden_prod_mpp',
                                             'cant_prod_solicitada',
                                             'cant_prod_actual',
                                             'cant_prod_restante',
                                             'procesos',
                                           ];
                   this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
                   this.dataSource1.paginator = this.paginator;
         if(msg.toLowerCase() == await this.atrb.ACCION_ACTUALIZAR.toLowerCase()){
          await this.util.cerrarSpinner();
          await this.notifi.success(await this.atrb.MENSAJES_FIJOS.lista_actualizada);
         }else{
          await this.util.cerrarSpinner();
         }
    } catch (error) {
      await this.util.cerrarSpinner();
      this.displayedColumns = [
        'inventario_salida',
        'inventario_entrada',
        'cod_producto',
        'des_producto',
        'operacion',
        'cod_proceso',
        'cod_orden_prod',
        'cod_orden_prod_mpp',
        'cant_prod_solicitada',
        'cant_prod_actual',
        'cant_prod_restante',
        'procesos',

      ];
       this.dataSource1 = new MatTableDataSource<any>();
       this.dataSource1.paginator = this.paginator;
       await this.notifi.customToastNotification(error.error.message);
    }

  }

  mostrar_factura(obj:any, text:any){
    try {
          this.accion = text;
          this.dialog.open(FormDireccionesComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'auto',
            data: { cod_factura:obj.cod_factura,
                    cod_nota:obj.cod_nota,
                    cod_pedido:obj.cod_pedido,
                    termino: this.accion
                  },
                  disableClose: true,
                  panelClass: 'pedidos_modal_rev',
                  backdropClass: "bdrop"
          });
    } catch (error) {
      console.log(error);
    }
  }

  mostrar_persona(obj:any, text:any){
    try {
          this.accion = text;
          this.dialog.open(FormDireccionesComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'auto',
            data: { usuario:obj.des_usuario,
                    cliente:obj.des_cliente,
                    termino: this.accion
                  },
                  disableClose: true,
                  panelClass: 'pedidos_modal_rev',
                  backdropClass: "bdrop"
          });
    } catch (error) {
      console.log(error);
    }
  }

  mostrar_comentarios(obj:any, text:any){
    try {
    this.accion = text;
    this.dialog.open(FormDireccionesComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'auto',
      data: { comentarios:obj.comentarios,
              termino: this.accion
            },
            disableClose: true,
            panelClass: 'pedidos_modal_rev',
            backdropClass: "bdrop"
    });
    } catch (error) {
      console.log(error);
    }
  }

  mostrar_tiempo(obj:any, text:any){
    try {
          this.accion = text;
          this.dialog.open(FormDireccionesComponent, {
            width: '100vw',
            maxWidth: '100vw',
            height:'auto',
            data: { fecha_ini:obj.fecha_cambio+' '+obj.hora_cambio,
                    termino: this.accion
                  },
                  disableClose: true,
                  panelClass: 'pedidos_modal_rev',
                  backdropClass: "bdrop"
          });
    } catch (error) {
      console.log(error);
    }
  }

 async filter_date(){
    try {
      await this.util.abrirSpinner();
      console.log(this.forma.value);
      if (this.forma.value.fecha_inicio !== '' && this.forma.value.fecha_fin !== '' &&
          this.forma.value.fecha_inicio !== null && this.forma.value.fecha_fin !== null) {
        await this.servicio.consultar_sesionhttp(null).toPromise();
        this.data.objeto.cod_empresa = await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa);
        const response = await  this.servicio.buscarProductoMovimientoshttp(this.data.objeto).toPromise();
          var xobjeto = [];
          var fecha = false;
          this.ELEMENT_DATA = [];
                   for (const i in response.resul) {
                    switch (response.resul[i].operacion.toUpperCase()) {
                      case 'FACTURACION':
                        this.ELEMENT_DATA.push({
                          inventario_gestionado:response.resul[i].des_inv,
                          inventario_salida:response.resul[i].des_inv,
                          inventario_entrada:response.resul[i].operacion,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:'N/A',
                          cod_orden_prod_mpp:'N/A',
                          cod_factura:response.resul[i].cod_factura,
                          cod_nota:response.resul[i].cod_nota,
                          cod_pedido:response.resul[i].cod_pedido,
                          des_cliente:response.resul[i].des_cliente,
                          cant_prod_solicitada:response.resul[i].cant_pedido,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_cambio,
                          des_usuario:response.resul[i].des_usuario,
                          fecha_cambio:response.resul[i].fecha_cambio,
                          hora_cambio:response.resul[i].hora_cambio,
                          comentarios:response.resul[i].comentarios

                         });
                        break;
                        case 'CARGO':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:'N/A',
                           cod_orden_prod_mpp:'N/A',
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'CARGO ORDEN INCOMPLETA':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:response.resul[i].cod_orden_prod,
                           cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'DESCARGO':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:'N/A',
                           cod_orden_prod_mpp:'N/A',
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'DESCARGO ORDEN EXCEDENTE':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:response.resul[i].cod_orden_prod,
                           cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'PRODUCCION':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].ord_prod,
                          cod_orden_prod_mpp:'N/A',
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'PRODUCCIONMPP':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:'produccion',
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].cod_orden_prod,
                          cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'LABORATORIO':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].cod_orden_prod,
                          cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:'N/A'
                         });
                        break;
                        case 'CONSIGNACION':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:response.resul[i].cod_proceso,
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'TRASLADO':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'EMBALAJE':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:response.resul[i].ord_prod,
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'DEVOLUCION':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:response.resul[i].ord_prod,
                            cod_nota:response.resul[i].cod_nota,
                            cod_pedido:response.resul[i].cod_pedido,
                            des_cliente:response.resul[i].des_cliente,
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'ANULACION':
                            this.ELEMENT_DATA.push({
                              vacio:'',
                              inventario_gestionado:response.resul[i].des_inv,
                              inventario_salida:response.resul[i].operacion,
                              inventario_entrada:response.resul[i].des_inv,
                              cod_producto:response.resul[i].cod_producto,
                              des_producto:response.resul[i].des_producto,
                              operacion:response.resul[i].operacion,
                              cod_proceso:'N/A',
                              cod_orden_prod:'N/A',
                              cod_orden_prod_mpp:'N/A',
                              cod_factura:response.resul[i].cod_factura,
                              cod_nota:response.resul[i].cod_nota,
                              cod_pedido:response.resul[i].cod_pedido,
                              des_cliente:response.resul[i].des_cliente,
                              cant_prod_solicitada:response.resul[i].cant_pedido,
                              cant_prod_actual:response.resul[i].cant_prod_actual,
                              cant_prod_restante: response.resul[i].cant_prod_cambio,
                              des_usuario:response.resul[i].des_usuario,
                              fecha_cambio:response.resul[i].fecha_cambio,
                              hora_cambio:response.resul[i].hora_cambio,
                              comentarios:response.resul[i].comentarios
                             });
                            break;
                          case 'RECEPCION':
                            this.ELEMENT_DATA.push({
                              vacio:'',
                              inventario_gestionado:response.resul[i].inventario_entrada,
                              inventario_salida:response.resul[i].inventario_salida,
                              inventario_entrada:response.resul[i].inventario_entrada,
                              cod_producto:response.resul[i].cod_producto,
                              des_producto:response.resul[i].des_producto,
                              operacion:response.resul[i].operacion,
                              cod_proceso:'N/A',
                              cod_orden_prod:response.resul[i].ord_prod,
                              cod_orden_prod_mpp:'N/A',
                              cod_factura:'N/A',
                              cod_nota:'N/A',
                              cod_pedido:'N/A',
                              des_cliente:'N/A',
                              cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                              cant_prod_actual:response.resul[i].cant_prod_actual,
                              cant_prod_restante: response.resul[i].cant_prod_restante,
                              des_usuario:response.resul[i].des_usuario,
                              fecha_cambio:response.resul[i].fecha_cambio,
                              hora_cambio:response.resul[i].hora_cambio,
                              comentarios:response.resul[i].comentarios
                             });
                            break;
                      default:
                        break;
                    } 
                   }
          for (const i in this.ELEMENT_DATA) {
            var startDate = moment(this.forma.value.fecha_inicio, "DD/MM/YYYY");
            var endDate = moment(this.forma.value.fecha_fin, "DD/MM/YYYY");
            var testDate = moment(this.ELEMENT_DATA[i].fecha_cambio, "DD/MM/YYYY");
            var fecha_en_el_rango = testDate.isBetween(startDate, endDate, null, '[]');
            if (fecha_en_el_rango === true ) {
                fecha = fecha_en_el_rango
                xobjeto.push(this.ELEMENT_DATA[i]);
            }
  
        }
        this.ELEMENT_DATA = [];
        this.ELEMENT_DATA = [...xobjeto];
        this.displayedColumns = [
          'inventario_salida',
          'inventario_entrada',
          'cod_producto',
          'des_producto',
          'operacion',
          'cod_proceso',
          'cod_orden_prod',
          'cod_orden_prod_mpp',
          'cant_prod_solicitada',
          'cant_prod_actual',
          'cant_prod_restante',
          'procesos',
        ];
          this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource1.paginator = this.paginator;
          await this.util.cerrarSpinner();
          if (this.ELEMENT_DATA.length > 0) {
            await this.notifi.success(await this.atrb.MENSAJES_FIJOS.seguimiento_cargado);
          }else{
            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_seguimiento);
          }
      }else{
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.rango_fecha);
      }
    } catch (error) {
      this.ELEMENT_DATA = [];
      this.displayedColumns = [
        'inventario_salida',
        'inventario_entrada',
        'cod_producto',
        'des_producto',
        'operacion',
        'cod_proceso',
        'cod_orden_prod',
        'cod_orden_prod_mpp',
        'cant_prod_solicitada',
        'cant_prod_actual',
        'cant_prod_restante',
        'procesos',
      ];
        this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource1.paginator = this.paginator;
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }

async  filter_date_print(){
    try {
        await this.util.abrirSpinner();
        await this.servicio.consultar_sesionhttp(null).toPromise();
      if (this.ELEMENT_DATA.length <= 0) {
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_registos);
        return;
      }
      if (this.forma.value.fecha_inicio === '' || this.forma.value.fecha_inicio === null) {
        await this.util.cerrarSpinner();
        this.forma.patchValue({
            fecha:await this.atrb.MENSAJES_FIJOS.general
        });
      }else{
        await this.util.cerrarSpinner();
        this.forma.patchValue({
          fecha:moment(this.forma.value.fecha_inicio).format("DD/MM/YYYY")+'-'+moment(this.forma.value.fecha_fin).format("DD/MM/YYYY")
      });
      }
      await this.util.cerrarSpinner();
    this.accion = '';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(async result => {
      await this.util.abrirSpinner();
      if (result != 'cerrar') {
        await this.excel.descarga_xlsx_movimiento_producto(this.ELEMENT_DATA, this.forma.value);
        await this.util.cerrarSpinner();
        await this.notifi.success(await this.atrb.MENSAJES_FIJOS.reporte_excel);
      }else{
        await this.util.cerrarSpinner();
      }
    });
    } catch (error) {
      await this.util.cerrarSpinner();
      await this.notifi.customToastNotification(error.error.message);
    }
  }
async fecha_tomada(event:any){
  try {
    await this.util.abrirSpinner();
    if (event.value) {
      console.log(this.forma.value);
      if (this.forma.value.fecha_inicio !== '' && this.forma.value.fecha_fin !== '' &&
          this.forma.value.fecha_inicio !== null && this.forma.value.fecha_fin !== null) {
        await this.servicio.consultar_sesionhttp(null).toPromise();
        this.data.objeto.cod_empresa = await this.hashkey.decrypt(await this.atrb.COD_EMPRESA.cod_empresa);
        const response = await  this.servicio.buscarProductoMovimientoshttp(this.data.objeto).toPromise();
          var xobjeto = [];
          var fecha = false;
          this.ELEMENT_DATA = [];
                   for (const i in response.resul) {
                    switch (response.resul[i].operacion.toUpperCase()) {
                      case 'FACTURACION':
                        this.ELEMENT_DATA.push({
                          inventario_gestionado:response.resul[i].des_inv,
                          inventario_salida:response.resul[i].des_inv,
                          inventario_entrada:response.resul[i].operacion,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:'N/A',
                          cod_orden_prod_mpp:'N/A',
                          cod_factura:response.resul[i].cod_factura,
                          cod_nota:response.resul[i].cod_nota,
                          cod_pedido:response.resul[i].cod_pedido,
                          des_cliente:response.resul[i].des_cliente,
                          cant_prod_solicitada:response.resul[i].cant_pedido,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_cambio,
                          des_usuario:response.resul[i].des_usuario,
                          fecha_cambio:response.resul[i].fecha_cambio,
                          hora_cambio:response.resul[i].hora_cambio,
                          comentarios:response.resul[i].comentarios

                         });
                        break;
                        case 'CARGO':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:'N/A',
                           cod_orden_prod_mpp:'N/A',
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'CARGO ORDEN INCOMPLETA':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:response.resul[i].cod_orden_prod,
                           cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'DESCARGO':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:'N/A',
                           cod_orden_prod_mpp:'N/A',
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'DESCARGO ORDEN EXCEDENTE':
                        this.ELEMENT_DATA.push({
                           vacio:'',
                           inventario_gestionado:response.resul[i].inventario_entrada,
                           inventario_salida:response.resul[i].inventario_salida,
                           inventario_entrada:response.resul[i].inventario_entrada,
                           cod_producto:response.resul[i].cod_producto,
                           des_producto:response.resul[i].des_producto,
                           operacion:response.resul[i].operacion,
                           cod_proceso:'N/A',
                           cod_orden_prod:response.resul[i].cod_orden_prod,
                           cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                           cod_factura:'N/A',
                           cod_nota:'N/A',
                           cod_pedido:'N/A',
                           des_cliente:'N/A',
                           cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                           cant_prod_actual:response.resul[i].cant_prod_actual,
                           cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'PRODUCCION':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].ord_prod,
                          cod_orden_prod_mpp:'N/A',
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'PRODUCCIONMPP':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:'produccion',
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].cod_orden_prod,
                          cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:response.resul[i].comentarios
                         });
                        break;
                        case 'LABORATORIO':
                        this.ELEMENT_DATA.push({
                          vacio:'',
                          inventario_gestionado:response.resul[i].inventario_entrada,
                          inventario_salida:response.resul[i].inventario_salida,
                          inventario_entrada:response.resul[i].inventario_entrada,
                          cod_producto:response.resul[i].cod_producto,
                          des_producto:response.resul[i].des_producto,
                          operacion:response.resul[i].operacion,
                          cod_proceso:'N/A',
                          cod_orden_prod:response.resul[i].cod_orden_prod,
                          cod_orden_prod_mpp:response.resul[i].cod_orden_prod_mpp,
                          cod_factura:'N/A',
                          cod_nota:'N/A',
                          cod_pedido:'N/A',
                          des_cliente:'N/A',
                          cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                          cant_prod_actual:response.resul[i].cant_prod_actual,
                          cant_prod_restante: response.resul[i].cant_prod_restante,
                           des_usuario:response.resul[i].des_usuario,
                           fecha_cambio:response.resul[i].fecha_cambio,
                           hora_cambio:response.resul[i].hora_cambio,
                           comentarios:'N/A'
                         });
                        break;
                        case 'CONSIGNACION':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:response.resul[i].cod_proceso,
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'TRASLADO':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'EMBALAJE':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:response.resul[i].ord_prod,
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:'N/A',
                            cod_nota:'N/A',
                            cod_pedido:'N/A',
                            des_cliente:'N/A',
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'DEVOLUCION':
                          this.ELEMENT_DATA.push({
                            vacio:'',
                            inventario_gestionado:response.resul[i].inventario_entrada,
                            inventario_salida:response.resul[i].inventario_salida,
                            inventario_entrada:response.resul[i].inventario_entrada,
                            cod_producto:response.resul[i].cod_producto,
                            des_producto:response.resul[i].des_producto,
                            operacion:response.resul[i].operacion,
                            cod_proceso:'N/A',
                            cod_orden_prod:'N/A',
                            cod_orden_prod_mpp:'N/A',
                            cod_factura:response.resul[i].ord_prod,
                            cod_nota:response.resul[i].cod_nota,
                            cod_pedido:response.resul[i].cod_pedido,
                            des_cliente:response.resul[i].des_cliente,
                            cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                            cant_prod_actual:response.resul[i].cant_prod_actual,
                            cant_prod_restante: response.resul[i].cant_prod_restante,
                             des_usuario:response.resul[i].des_usuario,
                             fecha_cambio:response.resul[i].fecha_cambio,
                             hora_cambio:response.resul[i].hora_cambio,
                             comentarios:response.resul[i].comentarios
                           });
                          break;
                          case 'ANULACION':
                            this.ELEMENT_DATA.push({
                              vacio:'',
                              inventario_gestionado:response.resul[i].des_inv,
                              inventario_salida:response.resul[i].operacion,
                              inventario_entrada:response.resul[i].des_inv,
                              cod_producto:response.resul[i].cod_producto,
                              des_producto:response.resul[i].des_producto,
                              operacion:response.resul[i].operacion,
                              cod_proceso:'N/A',
                              cod_orden_prod:'N/A',
                              cod_orden_prod_mpp:'N/A',
                              cod_factura:response.resul[i].cod_factura,
                              cod_nota:response.resul[i].cod_nota,
                              cod_pedido:response.resul[i].cod_pedido,
                              des_cliente:response.resul[i].des_cliente,
                              cant_prod_solicitada:response.resul[i].cant_pedido,
                              cant_prod_actual:response.resul[i].cant_prod_actual,
                              cant_prod_restante: response.resul[i].cant_prod_cambio,
                              des_usuario:response.resul[i].des_usuario,
                              fecha_cambio:response.resul[i].fecha_cambio,
                              hora_cambio:response.resul[i].hora_cambio,
                              comentarios:response.resul[i].comentarios
                             });
                            break;
                          case 'RECEPCION':
                            this.ELEMENT_DATA.push({
                              vacio:'',
                              inventario_gestionado:response.resul[i].inventario_entrada,
                              inventario_salida:response.resul[i].inventario_salida,
                              inventario_entrada:response.resul[i].inventario_entrada,
                              cod_producto:response.resul[i].cod_producto,
                              des_producto:response.resul[i].des_producto,
                              operacion:response.resul[i].operacion,
                              cod_proceso:'N/A',
                              cod_orden_prod:response.resul[i].ord_prod,
                              cod_orden_prod_mpp:'N/A',
                              cod_factura:'N/A',
                              cod_nota:'N/A',
                              cod_pedido:'N/A',
                              des_cliente:'N/A',
                              cant_prod_solicitada:response.resul[i].cant_prod_solicitada,
                              cant_prod_actual:response.resul[i].cant_prod_actual,
                              cant_prod_restante: response.resul[i].cant_prod_restante,
                              des_usuario:response.resul[i].des_usuario,
                              fecha_cambio:response.resul[i].fecha_cambio,
                              hora_cambio:response.resul[i].hora_cambio,
                              comentarios:response.resul[i].comentarios
                             });
                            break;
                      default:
                        break;
                    } 
                   }
          for (const i in this.ELEMENT_DATA) {
            var startDate = moment(this.forma.value.fecha_inicio, "DD/MM/YYYY");
            var endDate = moment(this.forma.value.fecha_fin, "DD/MM/YYYY");
            var testDate = moment(this.ELEMENT_DATA[i].fecha_cambio, "DD/MM/YYYY");
            var fecha_en_el_rango = testDate.isBetween(startDate, endDate, null, '[]');
            if (fecha_en_el_rango === true ) {
                fecha = fecha_en_el_rango
                xobjeto.push(this.ELEMENT_DATA[i]);
            }
  
        }
        this.ELEMENT_DATA = [];
        this.ELEMENT_DATA = [...xobjeto];
        this.displayedColumns = [
          'inventario_salida',
          'inventario_entrada',
          'cod_producto',
          'des_producto',
          'operacion',
          'cod_proceso',
          'cod_orden_prod',
          'cod_orden_prod_mpp',
          'cant_prod_solicitada',
          'cant_prod_actual',
          'cant_prod_restante',
          'procesos',
        ];
          this.dataSource1 = new MatTableDataSource<any>(this.ELEMENT_DATA);
          this.dataSource1.paginator = this.paginator;
          await this.util.cerrarSpinner();
          if (this.ELEMENT_DATA.length > 0) {
            await this.notifi.success(await this.atrb.MENSAJES_FIJOS.seguimiento_cargado);
          }else{
            await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.sin_seguimiento);
          }
      }else{
        await this.util.cerrarSpinner();
        await this.notifi.customToastNotification(await this.atrb.MENSAJES_FIJOS.rango_fecha);
      }
    }
    await this.util.cerrarSpinner();
  } catch (error) {
    await this.util.cerrarSpinner();
    await this.notifi.customToastNotification(error.error.message);
    console.log(error);
  }
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();
  }

  async cerrarmodal(){
    try {
      this.accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(async result => {
        await this.util.abrirSpinner();
        if (result != 'cerrar') {
          await this.util.cerrarSpinner();
          this.dialogRefp.close('cerrar');
        }else{
          await this.util.cerrarSpinner();
        }
      });
    } catch (error) {
      console.log(error);
    }
    }
}
