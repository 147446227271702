
<div *ngIf="rlsr === '0' || rlsr === '1'">
    <div class="row" *ngIf="accion === 'insertar'">
            <div class="col-md-12 col-sm-12 col-xs-12" style=" border-radius: 5px;">
                <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                    <div class="modal-content">
                        <div class="modal-header" style="background-color: rgb(50, 45, 104); color: #fff;">
                            <h3 class="modal-title" class=" text-center"><strong>Definir usuario del sistema</strong> </h3>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <div class="form-group">
                                                <label for="img">Imagen</label>
                                                <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-4  p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Documento(V,E,J,G)</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="cod_usuario" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4  p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Correo</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="correo" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4  p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Tipo</mat-label>
                                                <mat-select formControlName="cod_rol" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                    <mat-option *ngFor="let i of roles" [value]="i">{{i.des_usuario}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Gerencia</mat-label>
                                                        <mat-select formControlName="cod_unidad" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                            <mat-option *ngFor="let i of gerencia" [value]="i">{{i.des_unidad}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Coordinacion</mat-label>
                                                        <mat-select formControlName="cod_dpto" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                            <mat-option *ngFor="let i of dpto" [value]="i">{{i.des_unidad}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Perfil</mat-label>
                                                        <mat-select formControlName="cod_perfil" autocomplete="off">
                                                            <mat-option *ngFor="let i of cargo" [value]="i">{{i.des_rol}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                    </div>
                                    <div class="row">
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Nombre</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="des_usuario" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Telefono</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="telefono" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Estatus</mat-label>
                                                        <mat-select formControlName="estatus" autocomplete="off">
                                                            <mat-option *ngFor="let i of estatus" [value]="i.cod_estatus">{{i.des_estatus}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                    </div>
                                    <div class="row">
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Nombre de usuario</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="nom_usuario" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Contraseña</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="pass_usuario" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4  p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Licencias</mat-label>
                                                        <mat-select formControlName="mod_usuario" autocomplete="off">
                                                            <mat-option *ngFor="let i of licencias" [value]="i.des_licencia">{{i.des_licencia}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                    </div>
                                    <div class="row">
                                                <div class="col-6 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Dirección</mat-label>
                                                        <textarea matInput   formControlName="direccion" autocomplete="off"></textarea>
                                                    </mat-form-field>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                                <button [disabled]="forma.invalid" (click)="modal_respuesta()" mat-button class=" btn-primary">Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
    <div class="row" id="modal" *ngIf="accion === 'actualizar'">
            <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
                <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                    <div class="modal-content" style="height: 100%; width: 100%;">
                        <div class="modal-header" style="background-color: rgb(50, 45, 104); color: #fff;">
                            <h3 class="modal-title" class=" text-center"><strong>Actualizar usuario del sistema</strong> </h3>
                        </div>
                        <div class="modal-body">
                            <div class="row px-2">
                                <div class="col-md-4">
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <div class="form-group">
                                                <label for="img">Imagen</label>
                                                <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Documento(V,E,J,G)</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="cod_usuario" [readonly]="accion === 'actualizar'">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Tipo</mat-label>
                                                        <mat-select formControlName="cod_rol" autocomplete="off">
                                                            <mat-option *ngFor="let i of roles" [value]="i.cod_usuario">{{i.des_usuario}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Correo</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="correo" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Gerencia</mat-label>
                                                        <mat-select formControlName="cod_unidad" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                            <mat-option *ngFor="let i of gerencia" [value]="i.cod_unidad">{{i.des_unidad}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Coordinacion</mat-label>
                                                        <mat-select formControlName="cod_dpto" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                            <mat-option *ngFor="let i of dpto" [value]="i.cod_unidad">{{i.des_unidad}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Perfil</mat-label>
                                                        <mat-select formControlName="cod_perfil" autocomplete="off">
                                                            <mat-option *ngFor="let i of cargo" [value]="i.cod_rol">{{i.des_rol}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                    </div>
                                    <div class="row">
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Nombre</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="des_usuario" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Telefono</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="telefono" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Estatus</mat-label>
                                                        <mat-select formControlName="estatus" autocomplete="off">
                                                            <mat-option *ngFor="let i of estatus" [value]="i.cod_estatus">{{i.des_estatus}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                    </div>
                                    <div class="row">
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Nombre de usuario</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="nom_usuario" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Contraseña</mat-label>
                                                        <input matInput style="font-size: 12px; color: black;" formControlName="pass_usuario" autocomplete="off">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Licencias</mat-label>
                                                        <mat-select formControlName="mod_usuario" autocomplete="off">
                                                            <mat-option *ngFor="let i of licencias" [value]="i.des_licencia">{{i.des_licencia}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                    </div>
                                    <div class="row">
                                                <div class="col-12 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label>Dirección</mat-label>
                                                        <textarea matInput   formControlName="direccion" autocomplete="off"></textarea>
                                                    </mat-form-field>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                                <button [disabled]="forma.invalid" (click)="modal_respuesta()" mat-button class=" btn-primary">Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
</div>
<div *ngIf="rlsr !== '0' && rlsr !== '1'">
    <div class="" id="modal" *ngIf="accion == 'insertar'">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
                <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                    <div class="modal-content" style="height: 100%; width: 100%;">
                        <div class="modal-header" style="background-color: rgb(144, 11, 144); color: #fff;">
                            <h3 class="modal-title" class=" text-center"><strong>Editar usuarios del sistema</strong> </h3>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <div class="form-group">
                                                <label for="img">Imagen</label>
                                                <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Documento(V,E,J,G)</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="cod_usuario" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Tipo</mat-label>
                                                        <mat-select formControlName="cod_rol" autocomplete="off">
                                                            <mat-option *ngFor="let i of roles" [value]="i">{{i.des_usuario}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Correo</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="correo" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Gerencia</mat-label>
                                                <mat-select formControlName="cod_unidad" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                    <mat-option *ngFor="let i of gerencia" [value]="i">{{i.des_unidad}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Coordinacion</mat-label>
                                                <mat-select formControlName="cod_dpto" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                    <mat-option *ngFor="let i of dpto" [value]="i">{{i.des_unidad}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Perfil</mat-label>
                                                <mat-select formControlName="cod_perfil" autocomplete="off">
                                                    <mat-option *ngFor="let i of cargo" [value]="i">{{i.des_rol}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Nombre</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="des_usuario" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Telefono</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="telefono" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Estatus</mat-label>
                                                <mat-select formControlName="estatus" autocomplete="off">
                                                    <mat-option *ngFor="let i of estatus" [value]="i.cod_estatus">{{i.des_estatus}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                                    <div class="col-md-4 p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Nombre de usuario</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="nom_usuario" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4 p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Contraseña</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="pass_usuario" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                    </div>
                                    <div class="row">
                                                    <div class="col-12 p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Dirección</mat-label>
                                                            <textarea matInput   formControlName="direccion" autocomplete="off"></textarea>
                                                        </mat-form-field>
                                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                                <button [disabled]="forma.invalid" (click)="modal_respuesta()" mat-button class=" btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="" id="modal" *ngIf="accion == 'actualizar'">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 5px;">
                <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                    <div class="modal-content" style="height: 100%; width: 100%;">
                        <div class="modal-header" style="background-color: rgb(144, 11, 144); color: #fff;">
                            <h3 class="modal-title" class=" text-center"><strong>Editar usuarios del sistema</strong> </h3>
                        </div>
                        <div class="modal-body">
                            <div class="row px-2">
                                <div class="col-md-4">
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <div class="form-group">
                                                <label for="img">Imagen</label>
                                                <input type="file" class="form-control-file form-control p-1" formControlName="archivo" id="img" name="archivo" (change)="cargar_img($event)" accept="image/*" multiple>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-0">
                                        <div class="col-md-12 col-sm-12 col-xs-12 py-0 d-flex justify-content-center">
                                            <img  [src]="objectURL | pipeServices "  style="aspect-ratio: 1/1; width:200px; object-fit: 100% 100%; border-radius: 1rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Documento(V,E,J,G)</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="cod_usuario" autocomplete="off" [readonly]="accion === 'actualizar'">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label >Tipo</mat-label>
                                                        <mat-select formControlName="cod_rol" autocomplete="off">
                                                            <mat-option *ngFor="let i of roles" [value]="i.cod_usuario">{{i.des_usuario}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Correo</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="correo" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Gerencia</mat-label>
                                                <mat-select formControlName="cod_unidad" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                    <mat-option *ngFor="let i of gerencia" [value]="i.cod_unidad">{{i.des_unidad}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Coordinacion</mat-label>
                                                <mat-select formControlName="cod_dpto" (selectionChange)="listarUnidad($event)" autocomplete="off">
                                                    <mat-option *ngFor="let i of dpto" [value]="i.cod_unidad">{{i.des_unidad}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Perfil</mat-label>
                                                <mat-select formControlName="cod_perfil" autocomplete="off">
                                                    <mat-option *ngFor="let i of cargo" [value]="i.cod_rol">{{i.des_rol}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Nombre</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="des_usuario" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Telefono</mat-label>
                                                <input matInput style="font-size: 12px; color: black;" formControlName="telefono" autocomplete="off">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4 p-1">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Estatus</mat-label>
                                                <mat-select formControlName="estatus" autocomplete="off">
                                                    <mat-option *ngFor="let i of estatus" [value]="i.cod_estatus">{{i.des_estatus}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                                    <div class="col-md-4 p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Nombre de usuario</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="nom_usuario" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4 p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Contraseña</mat-label>
                                                            <input matInput style="font-size: 12px; color: black;" formControlName="pass_usuario" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                    </div>
                                    <div class="row">
                                                    <div class="col-12 p-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Dirección</mat-label>
                                                            <textarea matInput   formControlName="direccion" autocomplete="off"></textarea>
                                                        </mat-form-field>
                                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                                <button [disabled]="forma.invalid" (click)="modal_respuesta()" mat-button class=" btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
