<div id="modal_carrito" style=" background-image: linear-gradient(to left top, #90b9dc, #70a3d3, #518cca, #3276bf, #045fb4);;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container" >

        <form  id="carrito_p1" class="row justify-content-center mt-1 mb-0" style=" height: auto; background-color: #f1ede9; border: 2px solid #045fb4;">
            <!-- <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#ca4aaf;">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255);">Cliente</mat-label>
                        <input [readonly]="ver" style="font-size: 11px; color: #fff;" matInput placeholder="Cliente:" formControlName="des_cliente" autocomplete="off" readonly>
                        <span style="color: rgb(255, 255, 255);" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                    #
                    </span>
                    </mat-form-field>
                </div>
            </div> -->

                <div id="content_2" class=" mx-1 mat-elevation-z8 my-2 col-2  " style="border-radius: 1rem; background-color: #045fb4">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Codigo Cotizacion</mat-label>
                         <input readonly style="font-size: 12px; color: #fff;" matInput placeholder="Cliente:"  [value]="cod" autocomplete="off"  readonly>
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">#</span>
                    </mat-form-field> 
                </div>

            
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-2" style="border-radius: 1rem; border: 2px solid #045fb4; background-color: #045fb4">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Chequeado por:</mat-label>
                        <input readonly style="font-size: 12px; color: #fff;" maxlength="11" type="text" matInput  #num autocomplete="off" readonly>
                        
                     </mat-form-field>
                </div>


            <!-- <div id="content_1" class="col-md-2 px-0 py-2 h-100">
                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#1f39ce">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: #fff; font-size: 11px;">Unidad de manejo</mat-label>
                        <input [readonly]="ver" style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="unidad_manejo" #unidad_manejo autocomplete="off" readonly>
                        <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(255, 94, 0);" >report_problem</mat-icon>
                </span>
                    </mat-form-field>
                </div>
            </div> -->


                <div id="sub_content_1" class=" mx-1 mat-elevation-z8 my-2 col-4" style="border-radius: 1rem; border: 2px solid #045fb4; background-color: #045fb4">
                    <mat-form-field class="materialinput py-1 px-1">
                        <mat-label style="color: rgb(255, 255, 255); font-size: 14px;">Buscar</mat-label>
                        <input [readonly]="ver" style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" (keyup)="applyFilter($event)" autocomplete="off"  > 
                        <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                        <mat-icon>search</mat-icon>
                        </span>
                    </mat-form-field>
                </div>


                <div style="padding-top: 6px;" >

                  <button class="btn  btn-sm btn-light mat-elevation-z8 px-3 mt-1 mr-1" *ngIf="ver" (click)="imprimirCotizacion()" matTooltip="IMPRIMIR" style=" color:rgb(255, 255, 255); background-color: #0076C7; border-radius: 1rem;height: 80%;">
                    <mat-icon >print</mat-icon>
                  </button>
      
      
                    <button class="btn btn-sm btn-danger mat-elevation-z8 px-3 mt-1 mr-1" style="border-radius: 1rem; height: 80%;" (click)="cerrarmodal()" matTooltip="SALIR">
                        <mat-icon >exit_to_app</mat-icon>
                    </button>

                </div>


        </form>  

        <div id="carrito_p6" class="row mt-2 mat-elevation-z8" style=" border: 2px solid #045fb4; background-color: #f1ede9; ">
            <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                <mat-horizontal-stepper [linear]="isLinear" #stepper>

                  <!-- FORMULARIO 1 -->
                    <mat-step [stepControl]="firstFormGroup" >
                      <form [formGroup]="firstFormGroup">
                        <ng-template matStepLabel>Primera Propuesta</ng-template>
                        <div class="container mt-2">
                          <div class="row " style=" height: 90%;">
                            <div class="col-4">

                              <mat-form-field class="col-12">
                                <mat-label>R.I.F.</mat-label>
                                <input maxlength="10" [readonly]="ver" style="font-size: 12px;" (keyup)="captar($event, 1)" required type="text" matInput placeholder="" class="inputP" placeholder="J00000000000" formControlName="rif" [matAutocomplete]="auto1" >
                                <mat-autocomplete (optionSelected)="captarlo($event, 1)" #auto1="matAutocomplete" class="px-1">
                                        <mat-option [matTooltip]="option.des_proveedor"  style="font-size: 14px; color: #000;" *ngFor="let option of filteredOptions1 | async"  [value]="option.cod_proveedor">
                                            <span>{{option.cod_proveedor}}</span>
                                        </mat-option>
                                </mat-autocomplete>
                              </mat-form-field> 
                              

                              <mat-form-field class="col-12">
                                <mat-label>Empresa</mat-label>
                                <input readonly matInput  placeholder="Factory Cosmetics 3DG, C.A." formControlName="nombre" id="nombre1" required>
                              </mat-form-field>

                              <div class="col-12 mt-3">
                                <textarea [readonly]="ver" formControlName="observacion" [ngClass]="{'is-invalid':firstFormGroup.controls.observacion.errors?.required}"  class="w-100 mat-elevation-z8 form-control"   cols="30" rows="10" placeholder="Observacion(Requerido)" style="resize: none; border-radius: 1rem; padding: .5rem;" required ></textarea>
                              </div>

                            </div>

                            <div class="col-8">
                              <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style=" border: 2px solid #045fb4; background-color: #f1ede9;height: 93% !important; ">
                                <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                                    <table #table mat-table [dataSource]="dataSource1" class=" text-center table-striped table-bordered table-condensed table-hover">
                      
                                        <ng-container matColumnDef="cod_orden_req">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                                <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_orden_req}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                          </ng-container>
                                        <ng-container matColumnDef="cod_producto">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                                <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                          </ng-container>
                        
                                          <ng-container matColumnDef="des_producto">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                                <h5 style="margin: 0;"><strong>Descripcion</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                          </ng-container>
                        
                                          <ng-container matColumnDef="cant_aprobada">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                                <h5 style="margin: 0;"><strong>Cantidad Solicitada</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_aprobada | puntoporcoma}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                          </ng-container>

                                          <ng-container matColumnDef="cant_producto_cot">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                                <h5 style="margin: 0;"><strong>Cantidad del Proveedor</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                          </ng-container>
                        
                        
                                          <ng-container matColumnDef="tipo_unidad_medida">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                                <h5 style="margin: 0;"><strong>Unidad de medida</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                          </ng-container>

                                          <ng-container matColumnDef="precio">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                                <h5 style="margin: 0;"><strong>Precio Unitario</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio | puntoporcoma}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;">Total </td>
                                          </ng-container>
    
                                          <ng-container matColumnDef="precio_total">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                                <h5 style="margin: 0;"><strong>Precio total</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total | puntoporcoma}}</strong> </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> 
                                              <div>{{dolar1 | puntoporcoma}} $</div> 
                                              <div class="mt-2">{{bolivar1 }} Bs</div> 
                                             </td>
                                          </ng-container>
                    
                    
                                        <ng-container matColumnDef="tomar_data_ingresada">
                                            <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #045fb4; padding: 5px; color: #fff;">
                                                <h5 style="margin: 0;"><strong>Seleccionar</strong></h5>
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                                <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, '1')" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                                    <mat-icon>add_circle</mat-icon>
                                                </button>
                                                <button matTooltip="BORRAR" (click)="tomar_data(false, element, '1')" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                                    <mat-icon>done</mat-icon>
                                                </button>
                    
                                            </td>
                                            <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"></td>
                                        </ng-container>
                      
                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                      <tr mat-footer-row *matFooterRowDef="displayedColumns" class="mat-paginator-sticky"></tr>
                                      
                                    </table>
                    
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row justify-content-center">
                            <button class="btn btn-primary rounded-pill ml-3" matTooltip="Siguiente" mat-button matStepperNext> <mat-icon>keyboard_arrow_right</mat-icon></button> 
                          </div> 
                        </div>
                      </form>
                    </mat-step>
                  <!-- FORMULARIO 1 -->

                    <!-- FORMULARIO 2 -->
                    <mat-step *ngIf="CantidadCot == '3'" [stepControl]="secondFormGroup" >
                      <ng-template matStepLabel>Segunda Propuesta</ng-template>
                      <form [formGroup]="secondFormGroup" class="form-group">
                        <div class="container mt-2">
                          <div class="row " style=" height: 90%;">
                            <div class="col-4 align-self-start">
 
                              <mat-form-field class="col-12">
                                <mat-label>R.I.F</mat-label>
                                <input [readonly]="ver" required style="font-size: 12px;" type="text" matInput placeholder="" (keyup)="captar($event, 2)" class="inputP" formControlName="rif" [matAutocomplete]="auto2" >
                                <mat-autocomplete (optionSelected)="captarlo($event, 2)"  #auto2="matAutocomplete" class="px-1">
                                        <mat-option [matTooltip]="option.des_proveedor"   style="font-size: 14px; color: #000;" *ngFor="let option of filteredOptions2 | async"  [value]="option.cod_proveedor">
                                            <span>{{option.cod_proveedor}}</span>
                                        </mat-option>
                                </mat-autocomplete>
                              </mat-form-field> 
                              

                              <mat-form-field class="col-12">
                                <mat-label>Empresa</mat-label>
                                <input readonly matInput  placeholder="Factory Cosmetics 3DG, C.A." formControlName="nombre"  id="nombre2" required>
                              </mat-form-field>


                              <div class="col-12 mt-3">
                                <textarea [readonly]="ver" formControlName="observacion" [ngClass]="{'is-invalid':secondFormGroup.controls.observacion.errors?.required}" name="" id="" class="w-100 mat-elevation-z8 form-control"   cols="30" rows="10" placeholder="Observacion(Requerido)" style="resize: none; border-radius: 1rem; padding: .5rem;" required ></textarea>
                              </div>
                              

                            </div>

                            <div class="col-8">
                              <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style=" border: 2px solid #045fb4; background-color: #f1ede9;height: 93% !important; ">
                                <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                                    <table #table mat-table [dataSource]="dataSource2" class=" text-center table-striped table-bordered table-condensed table-hover">
                      
                                      <ng-container matColumnDef="cod_producto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>
                    
                                      <ng-container matColumnDef="des_producto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Descripcion</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>
                    
                                      <ng-container matColumnDef="cant_aprobada">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Cantidad Solicitada</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_aprobada | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>

                                      <ng-container matColumnDef="cant_producto_cot">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Cantidad del Proveedor</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>
                    
                    
                                      <ng-container matColumnDef="tipo_unidad_medida">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Unidad de medida</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>

                                      <ng-container matColumnDef="precio">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Precio Unitario</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;">Total </td>
                                      </ng-container>

                                      <ng-container matColumnDef="precio_total">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Precio total</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> 
                                          <div>{{dolar2 | puntoporcoma}} $</div> 
                                          <div class="mt-2">{{bolivar2}} Bs</div> 
                                         </td>
                                      </ng-container>
                
                
                                    <ng-container matColumnDef="tomar_data_ingresada">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #045fb4; padding: 5px; color: #fff;">
                                            <h5 style="margin: 0;"><strong>Seleccionar</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                            <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, '2')" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                                <mat-icon>add_circle</mat-icon>
                                            </button>
                                            <button matTooltip="BORRAR" (click)="tomar_data(false, element, '2')" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                                <mat-icon>done</mat-icon>
                                            </button>
                
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                    </ng-container>
                      
                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        <tr mat-footer-row *matFooterRowDef="displayedColumns" class="mat-paginator-sticky"></tr>
                                    </table>
                    
                                </div>
                              </div>
                            </div>
                          </div> 
                          <div class="row justify-content-center">
                            <button class="btn btn-primary rounded-pill mr-3" matTooltip="Regresar" mat-button matStepperPrevious> <mat-icon>keyboard_arrow_left</mat-icon></button>
                            <button class="btn btn-primary rounded-pill" matTooltip="Siguiente" mat-button matStepperNext> <mat-icon>keyboard_arrow_right</mat-icon></button> 
                          </div>
                        </div>
                      </form>
                      
                    </mat-step>
                    <!-- FORMULARIO 2 -->

                    <!-- FORMULARIO 3 -->
                    <mat-step *ngIf="CantidadCot == '3'" [stepControl]="thirdFormGroup" >
                      <ng-template matStepLabel>Tercera Propuesta</ng-template>
                      <form [formGroup]="thirdFormGroup">
                        <div class="container mt-2">
                          <div class="row " style=" height: 90%;">
                            <div class="col-4 ">

                              <mat-form-field class="col-12">
                                <mat-label>R.I.F</mat-label>
                                <input [readonly]="ver" required style="font-size: 12px;" type="text" matInput placeholder="" (keyup)="captar($event, 3)" class="inputP" formControlName="rif" [matAutocomplete]="auto3" >
                                <mat-autocomplete (optionSelected)="captarlo($event, 3)" #auto3="matAutocomplete" class="px-1">
                                        <mat-option  [matTooltip]="option.des_proveedor" style="font-size: 14px; color: #000;" *ngFor="let option of filteredOptions3 | async"  [value]="option.cod_proveedor">
                                            <span>{{option.cod_proveedor}}</span>
                                        </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>  


                              <mat-form-field class="col-12">
                                <mat-label>Empresa</mat-label>
                                <input readonly matInput  placeholder="Factory Cosmetics 3DG, C.A." formControlName="nombre" id="nombre3" required>
                              </mat-form-field>

                            

                              <div class="col-12 mt-3">
                                <textarea [readonly]="ver" formControlName="observacion" name="" id="" class="w-100 mat-elevation-z8 form-control" [ngClass]="{'is-invalid':thirdFormGroup.controls.observacion.errors?.required}"  cols="30" rows="10" placeholder="Observacion(Requerido)" style="resize: none; border-radius: 1rem; padding: .5rem;" required ></textarea>
                              </div>
 

                            </div>

                            <div class="col-8">
                              <div id="carrito_p6" class="mt-2 mat-elevation-z8 " style=" border: 2px solid #045fb4; background-color: #f1ede9; height: 93% !important; ">
                                <div class="col-md-12 col-sm-12 col-xs-12 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                                    <table #table mat-table [dataSource]="dataSource3" class=" text-center table-striped table-bordered table-condensed table-hover">
                      
                                      <ng-container matColumnDef="cod_producto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Codigo</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cod_producto}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>
                    
                                      <ng-container matColumnDef="des_producto">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Descripcion</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.des_producto}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>
                    
                                      <ng-container matColumnDef="cant_aprobada">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Cantidad Solicitada</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_aprobada | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>

                                      <ng-container matColumnDef="cant_producto_cot">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Cantidad del Proveedor</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.cant_producto_cot | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>
                    
                    
                                      <ng-container matColumnDef="tipo_unidad_medida">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Unidad de medida</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_unidad_medida}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> </td>
                                      </ng-container>

                                      <ng-container matColumnDef="precio">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Precio Unitario</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;">Total </td>
                                      </ng-container>

                                      <ng-container matColumnDef="precio_total">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #FFF; background-color: #045fb4;">
                                            <h5 style="margin: 0;"><strong>Precio total</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element"   [style.background-color]="element.precio == 0 ? '#eab9bd' : '#b6edc2' "  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.precio_total | puntoporcoma}}</strong> </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;"> 
                                          <div>{{dolar3 | puntoporcoma}} $</div> 
                                          <div class="mt-2">{{bolivar3}} Bs</div> 
                                         </td>
                                      </ng-container>
                
                
                                    <ng-container matColumnDef="tomar_data_ingresada">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color: #045fb4; padding: 5px; color: #fff;">
                                            <h5 style="margin: 0;"><strong>Seleccionar</strong></h5>
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                            <button matTooltip="ACEPTAR" (click)="tomar_data(true, element, '3')" *ngIf="!element.check_estatus" class="btn btn-outline-secondary rounded-pill" mat-raised-button style="height: 35px; border:2px solid;">
                                                <mat-icon>add_circle</mat-icon>
                                            </button>
                                            <button matTooltip="BORRAR" (click)="tomar_data(false, element, '3')" *ngIf="element.check_estatus"class="btn btn-primary rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);">
                                                <mat-icon>done</mat-icon>
                                            </button>
                
                                        </td>
                                        <td mat-footer-cell *matFooterCellDef style="background-color: #045fb4; color: #fff;" > </td>
                                    </ng-container>
                      
                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                      <tr mat-footer-row *matFooterRowDef="displayedColumns" class="mat-paginator-sticky"></tr>
                                    </table>
                    
                                </div>
                              </div>
                            </div>
                          </div> 
                          <div class="row justify-content-center">
                            <button class="btn btn-primary rounded-pill mr-3" matTooltip="Regresar" mat-button matStepperPrevious> <mat-icon>keyboard_arrow_left</mat-icon></button>
                            <button class="btn btn-primary rounded-pill" matTooltip="Siguiente" mat-button matStepperNext> <mat-icon>keyboard_arrow_right</mat-icon></button> 
                          </div>
                        </div>
                      </form>
                      
                    </mat-step>
                    <mat-step *ngIf="!ver">
                      <ng-template matStepLabel>LISTO!</ng-template>
                      
                      <div *ngIf="CantidadCot == '3'">
                        <div 
                          class=" d-flex align-items-center justify-content-center flex-column" 
                          style="height: 90%;" 
                          *ngIf="!firstFormGroup.invalid &&
                                !secondFormGroup.invalid &&
                                !thirdFormGroup.invalid"
                        >
                          <p><strong>Todo listo para enviar!</strong> </p>
                          <!-- <img src="assets/img/caution-sign.png" alt="warning" style="height: 150px; width: 150px;"> -->
                        <img src="assets/img/success.png" alt="succes" style="height: 150px; width: 150px;">
                        </div>

                        <div 
                          class=" d-flex align-items-center justify-content-center flex-column" 
                          style="height: 90%;" 
                          *ngIf="firstFormGroup.invalid &&
                                secondFormGroup.invalid &&
                                thirdFormGroup.invalid"
                        >
                          <p><strong>Lo siento no has terminado!</strong> </p>

                          <img src="assets/img/caution-sign.png" alt="warning" style="height: 150px; width: 150px;">
                      
                        </div>
                        
                        <div class=" d-flex justify-content-center">
                          <button class="btn btn-primary mr-3 rounded-pill" mat-button matStepperPrevious> <mat-icon>keyboard_arrow_left</mat-icon></button>
                          <button class="btn btn-success rounded-pill" 
                                  mat-button 
                                  [disabled]="firstFormGroup.invalid &&
                                              secondFormGroup.invalid &&
                                              thirdFormGroup.invalid"
                                  (click)="enviarCotizacion()"
                          >Enviar <mat-icon>done_all</mat-icon></button>
                        </div>
                      </div>

                      <div *ngIf="!(CantidadCot == '3')">
                        <div 
                          class=" d-flex align-items-center justify-content-center flex-column" 
                          style="height: 90%;" 
                          *ngIf="!firstFormGroup.invalid "
                        >
                          <p><strong>Todo listo para enviar!</strong> </p>
                          <!-- <img src="assets/img/caution-sign.png" alt="warning" style="height: 150px; width: 150px;"> -->
                        <img src="assets/img/success.png" alt="succes" style="height: 150px; width: 150px;">
                        </div>

                        <div 
                          class=" d-flex align-items-center justify-content-center flex-column" 
                          style="height: 90%;" 
                          *ngIf="firstFormGroup.invalid"
                        >
                          <p><strong>Lo siento no has terminado!</strong> </p>

                          <img src="assets/img/caution-sign.png" alt="warning" style="height: 150px; width: 150px;">
                      
                        </div>
                        
                        <div class=" d-flex justify-content-center">
                          <button class="btn btn-primary mr-3 rounded-pill" mat-button matStepperPrevious> <mat-icon>keyboard_arrow_left</mat-icon></button>
                          <button class="btn btn-success rounded-pill" 
                                  mat-button 
                                  [disabled]="firstFormGroup.invalid"
                                  (click)="enviarCotizacion()"
                          >Enviar <mat-icon>done_all</mat-icon></button>
                        </div>
                      </div>
                      
                    </mat-step>
                    <!-- FORMULARIO 3 -->
                    
                  <!--   <ng-template matStepperIcon="phone">
                      <mat-icon>call_end</mat-icon>
                    </ng-template>
                    <ng-template matStepperIcon="chat">
                      <mat-icon>forum</mat-icon>
                    </ng-template> -->
                  </mat-horizontal-stepper>
            </div>

        </div>
    </div>
</div>
