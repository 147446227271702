import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import {MatPaginator} from '@angular/material/paginator';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-form-recepcion-ver-documento',
  templateUrl: './form-recepcion-ver-documento.component.html',
  styleUrls: ['./form-recepcion-ver-documento.component.css']
})
export class FormRecepcionVerDocumentoComponent implements OnInit {

  forma:FormGroup;
  response:any= [];
  sesion: any;
  cod_menu:any = '1';
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  productos:any;
  ELEMENT_DATA:any[]=[];
  ELEMENT_DATA2:any[]=[];
  displayedColumns:string[];
  displayedColumns2:string[];
  NombreUsuario:string = `${localStorage.getItem('Nombre')} ${localStorage.getItem('Apellido')}`
  ficha_proveedor: any;
  iva_proveedor: any;
  Subtotal:any = 0;
  SubtotalShow:any = 0;
  iva:any = 0 ;
  ret_iva:any = 0;
  ret_ivaShow:any = 0;
  Total:any = 0;
  TotalShow:any = 0;
  flete:any = 0;
  moneda:any;
  subtotalIva: any = 0;
  subtotalIvaShow: any = 0;
  DATA:any;
  reten_prov:any = '0';
  imagen:any;
  imagenZoom:any = false;
  tipoDoc:any = [];


  dataSource=new MatTableDataSource<any>();
  dataSource2=new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';


  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormRecepcionVerDocumentoComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {

      const {
              cod_documento,
              cod_control,
              fecha_emision,
              fecha_recepcion,
              flete,
              subtotal,
              iva,
              ret_iva,
              tipo_documento,
              total
      } = data

      this.forma = fb.group({
        'cod_documento':cod_documento,
        'cod_control':cod_control,
        'fecha_emision':fecha_emision,
        'fecha_recepcion':fecha_recepcion,
        'flete':flete,
        'sub_total':subtotal,
        'iva':iva,
        'ret_iva':ret_iva,
        'tipo_documento':tipo_documento,
        'total':total,
      });
      
     }

    ngOnInit(): void {

      this.imagen = `${environment.urlStatic}/subidas/facturas-recepcion/${this.data.imagen}`

    }

    listarDefiniciones(objeto:any){
      try {
        var xvalor ={cod_menu:objeto};
          this.servicio.listarDefinicionesConceptoshttp(xvalor).subscribe(
            response =>{
              console.log(response);
  
              for (const i in response) {
      
                if (response[i].des_definicion === 'tipo de documento') {
                  this.tipoDoc.push(response[i]);
                }
  
              }
              
            },
            error =>{
              console.log(<any>error.error.message,'error definiciones');
            }
          );
          return null;
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition});
      }
    }

    zoomImage(boolean:any,event:any){
      let elForm = document.querySelector('#div-forma') as HTMLDivElement

      if(boolean){
        
        this.imagenZoom = true
        elForm.classList.remove('d-flex')
        elForm.classList.add('d-none')

      }else{
        this.imagenZoom = false
        elForm.classList.remove('d-none')
        elForm.classList.add('d-flex')

      }

    }
  

  
    cerrarmodal(): void {
      this.accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100%',
        data: {id:'',
               accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar') {
          this.cerrar();
        }
      });
    } 
  
    cerrar():void{
      try {
        
  
          
          this.dialogRefp.close('cerrar');
        
      } catch (error) {
        
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
}
