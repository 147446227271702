
<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
    <div class="modal-header">
        <h2 class="modal-title"> <b>Notas</b> </h2>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <textarea class="form-control resize-none" maxlength="255" [readonly]="data.ver" id="notas" [(ngModel)]="datos.notas" #notas="ngModel" rows="10"></textarea>
        </div>
        
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="data.ver || data.nota " (click)="cerrar()" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" *ngIf="!data.ver" [disabled]="!datos.notas"  (click)="send()" class="btn btn-primary">Guarda</button>
    </div>
    </div>
</div>
