<div class="cajaDevelopmentMode" *ngIf="!environment.production">
  <div class="cintaDevelopmentMode">
      Desarrollo
  </div>
</div>
<div class="fast fadeIn2 animated" *ngIf="auth_sesion === true">
    <app-navbar class="fast fadeIn2 animated"></app-navbar>
    <router-outlet class="fast fadeIn2 animated"></router-outlet>
    <app-footer class="fast fadeIn2 animated"></app-footer>
</div>
