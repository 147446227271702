<div id="modal_carrito" style=" background-image: linear-gradient(to left top, #90b9dc, #70a3d3, #518cca, #3276bf, #045fb4);;border-radius: 1rem; border: 2px solid;">
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class="alert alert-warning alert-dismissible fade d-flex justify-content-center align-items-center" style="left:35vw ;top:1vh;z-index:10; position: absolute;" role="alert">
        <span style="font-size: 20px;"><mat-icon >priority_high</mat-icon></span><strong class="mr-1">Alerta!</strong>.
        <!-- <button type="button" class="close" (click)="cerrarAlert()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <!--///////////////////////////ALERTA CODE////////////////////////////-->
    <div class=" container" >

        <div class="row mt-3 justify-content-between px-3">
            <h2 class="m-0 text-light">Detalles de pedidos</h2>
            <button (click)="cerrar()" class="boton"> <mat-icon style="color: #ff2b34;" >clear</mat-icon> </button>
        </div>

        <div id="carrito_p6" class="row mt-2 mat-elevation-z8" style=" border: 2px solid #045fb4; background-color: #f1ede9; ">
            <div class="col-md-6 col-sm-6 col-xs-6 px-0 p-3 table-responsive border h-100 cj-table mat-elevation-z8">
                <div class="container">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label for="cod_pedido" class="font-weight-bold">Codigo de Pedido</label>
                            <input class="form-control-plaintext form-control-sm"  id="cod_pedido" type="text" [value]="data.cod_pedido" readonly>
                        </div>
                        <div class="col-6 mb-3" >
                            <label for="des_cliente"  class="font-weight-bold" >Nombre cliente</label>
                            <input class="form-control-plaintext form-control-sm"  id="des_cliente" type="text" [value]="data.des_cliente" readonly>
                        </div>
                        <div class="col-6 mb-3">
                            <label for="tef_cliente" class="font-weight-bold" >Telefono</label>
                            <input class="form-control-plaintext form-control-sm"  id="tef_cliente" type="text" [value]="data.tef_cliente" readonly>
                        </div>
                        <div class="col-6 mb-3">
                            <label for="email_cliente" class="font-weight-bold" >Email</label>
                            <input class="form-control-plaintext form-control-sm"  id="email_cliente" type="text" [value]="data.email_cliente" readonly>
                        </div>
                        <div class="col-12 mb-3">
                            <label for="direccion"  class="font-weight-bold" >Direccion</label>
                            <div class="input-group input-group-sm mb-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text d-flex justify-content-center" id="inputGroup-sizing-sm" matTooltip="Ir a GoogleMaps"> <a [href]="urlDireccion" target="_blank" rel="noopener noreferrer">  <img src="../../../../../assets/img/new-google-maps-icon-logo-263A01C734-seeklogo.com.png" width="32px" height="32px" alt=""></a></span>
                                </div>
                                <textarea class="form-control-plaintext p-2"  cols="30" rows="2"  id="direccion" type="text" [value]="data.direccion" style="resize: none;" readonly>
                                </textarea>
                            </div>
                                
                        </div>

                        <div class="col-4 mb-3">
                            <label for="fecha_inicio" class="font-weight-bold" >Creacion</label>
                            <div class="badge badge-primary text-wrap">
                                {{data.fecha_inicio+ ' '+ data.hora_inicio}}
                            </div>
                        </div>
                        <div class="col-4 mb-3" >
                            <label for="fecha_envio" class="font-weight-bold" >Envio</label>
                            <div class="badge badge-warning text-wrap"*ngIf="data.fecha_envio">
                                {{data.fecha_envio+ ' '+ data.hora_envio}}
                            </div>
                            <div class="badge badge-warning text-wrap"*ngIf="!data.fecha_envio">
                                No ha sido enviado todavia
                            </div>
                        </div>
                        <div class="col-4 mb-3" >
                            <label for="fecha_fin" class="font-weight-bold" >Recepcion</label>
                            <div class="badge badge-success text-wrap" *ngIf="data.fecha_fin">
                                {{data.fecha_fin+ ' '+ data.hora_fin}}
                            </div>
                            <div class="badge badge-success text-wrap" *ngIf="!data.fecha_fin">
                                No ha sido recibido todavia
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 px-0 table-responsive border h-100 cj-table mat-elevation-z8">
                <table id="htmlData" #htmlData mat-table [dataSource]="dataSource" class=" text-center table-striped table-bordered table-condensed table-hover">
                    <!-- Weight Column -->
                     
   
                     <ng-container matColumnDef="cod_cotizacion_req">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Codigo de Cotización</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_cotizacion_req}} </td>
                     </ng-container>
     
     
                     <ng-container matColumnDef="tipo_solicitud_compra">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Tipo de Solicitud</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_solicitud_compra}}</strong> </td>
                     </ng-container>
   
                     <ng-container matColumnDef="tipo_producto">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Tipo de Producto</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.tipo_producto}}</strong> </td>
                     </ng-container>
   
                     <ng-container matColumnDef="estatus">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Estatus</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;" [ngSwitch]="element.estatus">
                        
                         
                           <div *ngSwitchCase="'P'" style="background-color: var(--primary); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Esperando por Cotizacion</strong></div> 
                           <div *ngSwitchCase="'CTP'" style="background-color: var(--warning); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Esperando Aprobacion</strong></div> 
                           <div *ngSwitchCase="'CTA'" style="background-color: var(--success); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Aprobada</strong> </div>
                           <!-- <div *ngSwitchCase="'SCD'" style="background-color: var(--danger); height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">Desaprobada</strong></div>
                           <strong *ngSwitchDefault >{{element.estatus}}</strong> --> 
                           
                       </td>
                        
                     </ng-container>
     
   
                     <ng-container matColumnDef="departamento">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Deparatamento</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;text-transform: uppercase;">  <strong>{{element.departamento}}</strong> </td>
                     </ng-container>
   
                     <ng-container matColumnDef="prioridad">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Prioridad</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }" style="padding: 5px; font-size: 12px;text-transform: uppercase;" [ngSwitch]="element.prioridad">  
                         
                         <div *ngSwitchCase="'Baja'" style="background-color: #00D0FF; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong></div> 
                         <div *ngSwitchCase="'Media'" style="background-color: #FFC35E; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong> </div>
                         <div *ngSwitchCase="'Alta'" style="background-color: red; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 1rem;"><strong  style="color: #fff;">{{element.prioridad}}</strong></div>
                         <strong *ngSwitchDefault >{{element.prioridad}}</strong> 
                         
                       </td>
                     </ng-container>
   
                     <ng-container matColumnDef="fecha">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Fecha</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.fecha}}</strong> </td>
                     </ng-container>
   
                     <ng-container matColumnDef="fecha_estimada">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Fecha Estimada</strong></h5>
                       </th>
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"  style="padding: 5px; font-size: 12px; color: black;"> <strong>{{element.fecha_estimada}}</strong> </td>
                     </ng-container>
   
                     <ng-container  matColumnDef="actualizar">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Editar</strong></h5>
                       </th>
                     
                       <td mat-cell *matCellDef="let element"  [ngStyle]="{ 'background-color':element.estatus  === 'SCD' ? '#eab9bd' : null }"style="padding: 1px; font-size: 12px;" class="text-center" >
                           <button matTooltip="EDITAR" (click)='update_insert_data(element, "actualizar")' class="btn rounded-pill btn-sm" [disabled]="element.estatus != 'P'" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(171, 20, 241);">
                               <mat-icon>edit</mat-icon>
                           </button>
                          <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                               <mat-icon>edit</mat-icon>
                           </button> -->
                       </td>
                     </ng-container>
   
   
                     <ng-container  matColumnDef="cotizar">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Cotizar</strong></h5>
                       </th>
                     
                       <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="text-center" >
                           <button *ngIf="element.estatus == 'P'" matTooltip="COTIZAR"   (click)='Cotizar(element, "aprobar")' class="btn btn-success btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);border: 2px solid; background-color: #00887B;">
                               <mat-icon>monetization_on</mat-icon>
                           </button>
                           <button *ngIf="element.estatus != 'P' " matTooltip="Ver Cotizacion" (click)='verCotizacion(element, "aprobar")' class="btn btn-success btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255);border: 2px solid; background-color: #00887B;">
                             <mat-icon>remove_red_eye</mat-icon>
                         </button>
                          <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                               <mat-icon>edit</mat-icon>
                           </button> -->
                       </td>
                     </ng-container>
   
                     <ng-container disabled="aprobar" matColumnDef="aprobar">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Aprobar</strong></h5>
                       </th>
                     
                       <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="text-center" >
                           <button [disabled]="element.estatus != 'CTP'" matTooltip="APROBAR" *ngIf="element.cod_solicitud_compra != ''" (click)='aprobarCotizacion(element, "aprobar")' class="btn btn-success btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); border: 2px solid;">
                               <mat-icon>done_all</mat-icon>
                           </button>
                          <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                               <mat-icon>edit</mat-icon>
                           </button> -->
                       </td>
                     </ng-container>
   
   
                     <ng-container  matColumnDef="ver">
                       <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                           <h5><strong>Ver</strong></h5>
                       </th>
                     
                       <td mat-cell *matCellDef="let element"  style="padding: 1px; font-size: 12px;" class="text-center" >
                           <button matTooltip="Ver Aprobacion" [disabled]="element.estatus != 'CTA'" (click)='aprobarCotizacion(element, "ver")' class="btn btn-secondary btn-sm rounded-pill" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); border: 2px solid; background-color: #A15A99;">
                               <mat-icon>remove_red_eye</mat-icon>
                           </button>
                          <!--  <button *ngIf="!update"  matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                               <mat-icon>edit</mat-icon>
                           </button> -->
                       </td>
                     </ng-container>
                    
     
                     <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                     <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
       
                 </table>
     
            </div>

        </div>
    </div>
</div>
