<div class="container-fluid">
    <form [formGroup]="forma">
        <div class="row mat-elevation-z8">
            <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style="background-color:rgb(107, 2, 243); padding: 5px; margin: 0px;">

                <div class="collapse navbar-collapse col-md-12 col-sm-12 col-xs-12" id="navbarSupportedContent" style="padding: 0px; margin: 0px;">

                    <ul class="navbar-nav mr-auto col-md-12 col-sm-12 col-xs-12 " style="padding: 0px; margin: 0px; ">
                        <li class="nav-item col-md-3 col-sm-3 col-xs-3" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                            <mat-label style="color: #000; font-size: 11px;">Proveedor</mat-label>
                                            <input style="color: #000; font-size: 11px;" type="text" matInput placeholder="" formControlName="cod_prov" [matAutocomplete]="autoGroup2" #cod_prov>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                            <mat-icon>business</mat-icon>
                                            </span>
                                            <mat-autocomplete #autoGroup2="matAutocomplete">
                                                <mat-optgroup *ngFor="let group of arry2 | async" [label]="group.letter">
                                                    <mat-option style="color: #000; font-size: 11px;" *ngFor="let i of group.code" [value]="i">
                                                        {{i}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-3 col-sm-3 col-xs-3" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px; ">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field class="materialinput" style="padding: 0px; margin: 0px;">
                                            <mat-label style="color: #000; font-size: 11px;">Codigo del producto</mat-label>
                                            <input style="color: #000; font-size: 11px;" type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup" #cod_producto>
                                            <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                              <mat-icon>shop_two</mat-icon>
                                              </span>
                                            <mat-autocomplete #autoGroup="matAutocomplete">
                                                <mat-optgroup *ngFor="let group of arry | async" [label]="group.letter">
                                                    <mat-option style="color: #000; font-size: 11px;" *ngFor="let i of group.code" [value]="i">
                                                        {{i}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240); ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;   height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                            <mat-label style="color: #000; font-size: 11px;">Factura Nº</mat-label>
                                            <input style="font-size: 12px;" maxlength="8" type="text" matInput placeholder="" formControlName="factura_prov" #factura_prov autocomplete="off">
                                            <span mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">
                                            #
                                    </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240); ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;   height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                            <mat-label style="color: #000; font-size: 11px;">Lote proveedor</mat-label>
                                            <input style="font-size: 12px;" maxlength="8" type="text" matInput placeholder="" formControlName="lote_prov" #lote_prov autocomplete="off">
                                            <span mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">
                                            #
                                    </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240); ">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;   height: 50px; background-color:#cccccccc; ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; ">

                                        <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                            <mat-label style="color: #000; font-size: 10px;">Cantidad</mat-label>
                                            <input style="font-size: 12px;" maxlength="8" type="text" matInput placeholder="Cantidad" formControlName="cant_producto" #cant_producto autocomplete="off">
                                            <span mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">
                                              #
                                      </span>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px;  ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px; height: 100%;">
                                        <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;height: 100%; ">
                                            <div class="example-button-container form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px;background-color:#cccccccc; height: 100%;">
                                                <mat-icon (click)="addElement()" matTooltip="AGREGAR PRODUCTOS A SU LISTA">add_shopping_cart</mat-icon>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px;  ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px; height: 100%;">
                                        <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;height: 100%; ">
                                            <div class=" example-button-container form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px;background-color:#cccccccc; height: 100%;">
                                                <mat-icon matTooltip="GUARDAR" (click)="modalrespuesta()">save</mat-icon>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item col-md-1 col-sm-1 col-xs-1" style="padding: 0px; margin: 0px; border: 1px solid rgb(150, 199, 240);">
                            <div class=" form-inline my-2 my-lg-0 col-md-12 col-sm-12 col-xs-12" style="padding: 0px; margin: 0px;">

                                <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;  height: 50px;  ">
                                    <div class=" form-group col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px; padding: 0px; height: 100%;">
                                        <div class=" form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="padding: 5px; margin: 0px;height: 100%; ">
                                            <div class="example-button-container form-group mat-elevation-z8 col-md-12 col-sm-12 col-xs-12" style="margin: 0px 0px 0px 0px;background-color:#cccccccc; height: 100%;">
                                                <mat-icon (click)="cerrarmodal()" matTooltip="SALIR">exit_to_app</mat-icon>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>

                </div>

            </nav>
        </div>

        <div class="row container-precios mat-elevation-z8 " style="margin-top: 1%;">

            <div class="container-pedidos2  table-responsive">

                <table #table mat-table [dataSource]="dataSource1" class=" table-secondary  text-center table-striped table-bordered table-condensed table-hover">

                    <ng-container matColumnDef="cod_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                            <h5><strong>Codigo</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.cod_producto}} </td>
                    </ng-container>

                    <ng-container matColumnDef="des_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                            <h5><strong>Producto</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;"> {{element.des_producto}} </td>
                    </ng-container>


                    <ng-container matColumnDef="cant_producto">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                            <h5><strong>Cantidad</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.cant_producto}}</td>
                    </ng-container>

                    <ng-container matColumnDef="lote_prov">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                            <h5><strong>Lote proveedor</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.lote_prov}}</td>
                    </ng-container>

                    <ng-container matColumnDef="factura_prov">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                            <h5><strong>Factura proveedor</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.factura_prov}}</td>
                    </ng-container>

                    <ng-container matColumnDef="unidad_prod">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                            <h5><strong>Unidad de medida</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: black;">{{element.unidad_prod}}</td>
                    </ng-container>

                    <ng-container matColumnDef="detalle">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                            <h5><strong>Detalle</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" (click)="detalleOrdenProd(element)" class="text-center" style="padding: 5px;">
                            <section>
                                <div class="example-button-container" class="text-center">
                                    <button mat-icon-button color="" aria-label="Example icon button with a delete icon">
                                <mat-icon matTooltip="DETALLES">image_search</mat-icon>
                              </button>
                                </div>
                            </section>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="remov_elem">
                        <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: black;">
                            <h5><strong>Eliminar</strong></h5>
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px;">
                            <section>
                                <div class="example-button-container" class="text-center">
                                    <button (click)="eliminarItems(element)" mat-icon-button color="warn" aria-label="Example icon button with a delete icon">
                                <mat-icon matTooltip="ELIMINAR">remove_shopping_cart</mat-icon>
                              </button>
                                </div>
                            </section>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                </table>
            </div>
        </div>
    </form>
</div>


<!-- <div fxLayout='column' class="zero">

  <div fxFlex="33" class="one"></div>
  <div fxFlex="70%" [fxLayout]="" class="two">

      <div fxFlex="22%" class="two_one">tab</div>
      <div fxFlex="100%" class="two_two">
          <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style="background-color:rgb(233, 138, 138); padding: 5px; margin: 0px;">

              <div class="collapse navbar-collapse " id="navbarSupportedContent" style="padding: 0px; width: 100%; margin: 0px;">

                  <ul class="navbar-nav mr-auto " style="padding: 0px; margin: 0px; width: 100%;">
                      <li class="nav-item" style="padding: 0px; margin: 0px; width: 100%;">
                          <div class=" form-inline my-2 my-lg-0" style="padding: 0px; margin: 0px; width: 100%;">

                              <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px; width: 25%; height: 50px; background-color:#cccccccc; ">
                                  <div class=" form-group" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; width: 100%;">

                                      <mat-form-field style="margin: 0px; padding: 0px; width: 100%;">
                                          <mat-label style="color: #000;">Codigo del producto</mat-label>
                                          <input style="font-size: 12px;" type="text" matInput placeholder="" formControlName="cod_producto" [matAutocomplete]="autoGroup2" #cod_producto>
                                          <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                      <mat-icon>shop_two</mat-icon>
                                      </span>
                                          <mat-autocomplete #autoGroup2="matAutocomplete">
                                              <mat-optgroup *ngFor="let group of arry | async" [label]="group.letter">
                                                  <mat-option *ngFor="let i of group.code" [value]="i">
                                                      {{i}}
                                                  </mat-option>
                                              </mat-optgroup>
                                          </mat-autocomplete>
                                      </mat-form-field>
                                  </div>
                              </div>
                              <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 15%; height: 50px; background-color:#cccccccc; ">
                                  <div class=" form-group" style="margin: 0px 0px 0px 0px; padding: 0px 5px 0px 5px; width: 100%;">

                                      <mat-form-field class="materialinput" style="padding: 0px; margin: 0px; width: 100%;">
                                          <mat-label style="color: #000;">Cantidad</mat-label>
                                          <input style="font-size: 12px;" maxlength="8" type="number" matInput placeholder="Cantidad" formControlName="cant_producto" #cant_producto autocomplete="off">
                                          <span mat-icon-button matSuffix [attr.aria-label]=" 'Hide password' ">
                                          <mat-icon>#</mat-icon>
                                  </span>
                                      </mat-form-field>
                                  </div>
                              </div>

                              <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 42.5%; height: 50px; background-color:#cccccccc;">

                                  <div class=" form-group mat-elevation-z8" style="margin: 0px 0px 15px 0px; padding: 0px 5px 0px 5px; width: 100%; height: 40px;">
                                      <mat-form-field class="materialinput" style="padding: 0px; margin: 0px 0px 0px 0px;">
                                          <mat-label>Buscar</mat-label>
                                          <input #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                                          <span mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                <mat-icon>search</mat-icon>
                                </span>
                                      </mat-form-field>
                                  </div>

                              </div>
                              <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 5%; height: 50px; ">
                                  <div class=" form-group mat-elevation-z8 " style="margin: 0px 0px 0px 0px; padding: 0% 0% 15% 14%; width:100% ; height: 40px; background-color:#cccccccc;">
                                      <mat-icon class="pedidos" (click)="addElement()" style="font-size: 30px; margin: 0px;" matTooltip="AGREGAR PRODUCTOS">add_shopping_cart</mat-icon>
                                  </div>
                              </div>
                              <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 5%; height: 50px; ">
                                  <div class=" form-group mat-elevation-z8 " style="margin: 0px 0px 0px 0px; padding: 0% 0% 15% 14%; width:100% ; height: 40px; background-color:#cccccccc;">
                                      <mat-icon class="pedidos" (click)="modalrespuesta()" style="font-size: 30px; margin: 0px;" matTooltip="GUARDAR">save</mat-icon>
                                  </div>
                              </div>
                              <div class=" form-group mat-elevation-z8" style="padding: 5px; margin: 0px 0px 0px 5px; width: 5%; height: 50px; ">
                                  <div class=" form-group mat-elevation-z8 " style="margin: 0px 0px 0px 0px; padding: 0% 0% 15% 14%; width:100% ; height: 40px; background-color:#000; color: #fff;">
                                      <mat-icon class="pedidos2" (click)="cerrarmodal()" style="font-size: 30px; margin: 0px;" matTooltip="SALIR">exit_to_app</mat-icon>
                                  </div>

                              </div>
                          </div>
                      </li>

                  </ul>

              </div>

          </nav>
      </div>
      <div fxFlex="30" class="two_three"></div>

  </div>
  <div fxFlex class="three"></div>

</div> -->