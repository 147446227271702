import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';


export interface StateGroup {
  letter: any;
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-nota-credito-admin',
  templateUrl: './form-nota-credito-admin.component.html',
  styleUrls: ['./form-nota-credito-admin.component.css']
})
export class FormNotaCreditoAdminComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('producto_pedido') producto_pedido:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('lote_producto',{static:true}) lote_producto:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('cod_lista_precio',{static:true}) cod_lista_precio:ElementRef;
  @ViewChild('iva',{static:true}) iva:ElementRef;
  @ViewChild('items',{static:true}) items:ElementRef;
  @ViewChild('desc_pedido_monto',{static:true}) desc_pedido_monto:ElementRef;
  @ViewChild('cod_lista1',{static:true}) cod_lista1:ElementRef;
  @ViewChild('exento_monto',{static:true}) exento_monto:ElementRef;

  stateGroupOptions: Observable<StateGroup[]>;
  stateGroupOptions2: Observable<StateGroup[]>;

  value1 = '';
  nombre:any;
forma:FormGroup;
lista:any;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
tipo_cliente:any;
unidad:any;
unidadp:any = [];
dialogSpin:any;
menu:any;
nc_ad:any;
producto:any = [];
codigo:any = [];
codigprod:any;
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormNotaCreditoAdminComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;
      this.nc_ad = data.xvalor;
      this.forma = fb.group({
        'id_pedido':data.id,
        'cod_cliente':data.cod_cliente,
        'des_cliente':data.des_cliente,
        'tipo_cliente':data.tipo_cliente,
        'cod_factura':data.cod_factura,
        'cod_pedido':data.cod_pedido,
        'cod_vendedor':'',
        'des_vendedor':data.des_vendedor,
        'estatus_cliente':data.estatus_cliente,
        'estatus_pedido':data.estatus_pedido,
        'estatus_fiscal':'',
        'tipo2_cliente':data.tipo2_cliente,
        'credito_dias_cliente':data.credito_dias_cliente,
        'reten_cliente':data.reten_cliente,
        'comercializacion_pedido':data.comercializacion,
        'producto_pedido':'',
        'lote_producto':'',
        'dispo_pedido':'',
        'cantidad_pedido':'',
        'iva_exento':'0,00',
        'monto_exento':'0,00',
        'subtotal_pedido':'0,00',
        'total_pedido':'0,00',
        'iva_pedido':'16',
        'iva_monto':'0,00',
        'desc_pedido':'0,00',
        'desc_pedido_monto':'0,00',
        'tipo_pedido':'F',
        'cod_lista_precio':'',
        'tipo_nota':'admin',

      });

      this.stateForm = this.formb.group({
        stateGroup: '',
      });
    }

  ngOnInit(): void {
    this.items.nativeElement.value = '0';
    this.consultarPedido(this.data);
    this.lote = true;
    this.formulario();
  }
  ngAfterViewInit() {

  }
  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }

    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups2
        .map(group2 => ({letter: group2.letter, names: filter(group2.names, value), code: filter(group2.code, value)}))
        .filter(group2 => group2.names.length > 0 || group2.code.length > 0 );
    }

    return this.stateGroups2;
  }

  listarproductot(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarProductoTermhttp().subscribe(
      response =>{
        this.stateGroupOptions = this.forma.get('producto_pedido')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+' '+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }
                  console.log(this.producto);
                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.codigo
                  }];
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }
  listarClientePedidoTipo(xvalor:any){
    try {
      var valor={cod_cliente:xvalor}
      this.servicio.listarClientePedidoTipohttp(valor).subscribe(
        response =>{
          console.log(response);
          this.tipo_cliente = response.resul[0].tipo_cliente;
        },
        error =>{
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  anular_enter(e:any){
    if (e.key == "Enter") {
      // Prevenir
      e.preventDefault();
      return false;
    }
  }
  buscarLprecios(){

      var client = this.forma.value.cod_cliente.split(' ');
      var cod = this.forma.value.producto_pedido.split(' ');
      var lod = this.forma.value.lote_producto.split(' ');
      console.log(this.forma.value.tipo_cliente);
    var cod_p = cod[0].trim();
    var cod_l = lod[0].trim();
    var cliente = client[1];
    var tipo = this.forma.value.tipo_cliente;
    var valor:any = {cod_producto:cod_p,lote_producto:cod_l,cod_cliente:cliente,tipo_cliente:tipo}
    this.servicio.buscarLprecioshttp(valor).subscribe(
      response =>{
        console.log(response[0].cod_lista_precio_producto);
        this.cod_lista_precio.nativeElement.value = response[0].cod_lista_precio_producto;
        this.snackBar.open('Su lista de precios es la ultima actualización', 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );

  }

  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
    }else{
    console.log(this.forma.value);
    this.dialogRefp.close(this.forma.value);
    }

  }

consultarPedido(xvalor:any){
  console.log(xvalor);
  var valor = {cod_pedido:xvalor.cod_pedido, cod_factura:xvalor.cod_factura};
  try {
    this.servicio.buscarPedidoProductocodttp(valor).subscribe(
      response =>{
      console.log(response);
      /* for (const i in response.resul) {
        this.ELEMENT_DATA.push({
          cod_producto:response.resul[i].cod_producto,
          des_producto:response.resul[i].des_producto,
          cod_lista_precio:response.resul[i].cod_lista_precio_producto,
          cant_producto:response.resul[i].cant_producto,
          costo_producto:response.resul[i].costo_producto,
          tipo_producto:response.resul[i].tipo_producto,
          subtotal_producto: response.resul[i].subtotal_producto,
          tamano_producto:response.resul[i].tamano_producto,
          estatus_fiscal:response.resul[i].estatus_fiscal
        });
      } */
      this.items.nativeElement.value = this.ELEMENT_DATA.length;
      this.desc_pedido.nativeElement.value = '0,00';
      this.exento_monto.nativeElement.value = response.resul3[0].total_factura;
      this.subtotal_pedido.nativeElement.value = '0,00';
      this.iva_monto.nativeElement.value = '0,00';
      this.desc_pedido_monto.nativeElement.value = '0,00';
      this.total_pedido.nativeElement.value = '0,00';
      this.cod_lista_precio.nativeElement.value = response.resul2[0].cod_lista_precio;
      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      console.log(this.ELEMENT_DATA);
      this.lista = true;
      },
      error =>{
        console.log(<any>error.error.message);
        this.lista = false;
      }
    );

  } catch (error) {
    this.snackBar.open(error.toString(), 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }
}
formulario():void{
  /* this.ELEMENT_DATA = []; */
  this.cant = false;
  this.displayedColumns = [
    'item_concepto',
    'Concepto_nota',
    'subtotal_producto',
    'remov_elem'
   ];
/* this.dataSource = this.ELEMENT_DATA; */
this.dataSource1 = new MatTableDataSource<PeriodicElementfacturas>(this.ELEMENT_DATA);
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource1.filter = filterValue.trim().toLowerCase();
}

cantidad_productos() {
  try {
    var suma = 0;
    var matriz = [];
    for (const i in this.ELEMENT_DATA) {
      matriz.push(this.ELEMENT_DATA[i].cant_producto)
    }
    matriz.forEach (function(numero){
           suma += parseInt(numero.replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace('.','')
           .replace(',','.')) ;
       });

     return suma;
  } catch (error) {
    this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,});
  }

  }
  costo_producto() {

    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].costo_producto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
      

       return suma.toFixed(3).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{3})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  sub_total_producto() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].subtotal_producto)
      }
      matriz.forEach (function(numero){
             suma += parseFloat(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });
      

       return suma.toFixed(2).replace(/\D/g, "")
       .replace(/([0-9])([0-9]{2})$/, '$1,$2')
       .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
  cantidad_item() {
    try {
      return this.ELEMENT_DATA.length;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

  }

  addElement() {
    var resul_final;
    var item = 0;
    let arry = [];
    let arry2 = [];
    console.log(this.num.nativeElement.value);

    if (parseFloat(this.num.nativeElement.value) > 0  && this.producto_pedido.nativeElement.value != '' && parseFloat(this.num.nativeElement.value.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.')) <= parseFloat( this.exento_monto.nativeElement.value.replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace('.','')
    .replace(',','.'))) {


          let subtotal = parseFloat(this.num.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'));

        if (this.ELEMENT_DATA.length == 0) {
          item = 1;
          this.items.nativeElement.value = parseFloat(this.items.nativeElement.value) + 1;
          this.codigo = [];
          this.producto = [];
          this.stateGroups2 =[];

          this.lotepro = [];
          this.listarproductot();
          this.ELEMENT_DATA.push({
            item_nota: item,
            concepto_nota: this.producto_pedido.nativeElement.value,
            subtotal_producto: subtotal.toFixed(2).replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1,$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
          });

          let porcentaje = (subtotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'))/100);

          let monto_sub = subtotal - porcentaje;

          this.subtotal_pedido.nativeElement.value = monto_sub.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          let total = (monto_sub * parseFloat(this.iva.nativeElement.value) / 100);

          this.iva_monto.nativeElement.value = total.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')));

          /* let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'))/100); */

          this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          resul_final = montoTotal;

          this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

          this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);

          this.producto_pedido.nativeElement.value = '';
          this.num.nativeElement.value = '';
          this.forma.value.cantidad_pedido = '';

        }else{
          if (this.ELEMENT_DATA.length != 1) {
              item = item + 1;
            for (const i in this.ELEMENT_DATA) {
              arry.push(this.ELEMENT_DATA[i].cod_producto);
              arry2.push(this.ELEMENT_DATA[i].lote_producto);
             }
            console.log(this.num.nativeElement.value);

             let index = arry.includes(item);

             if (!index) {
              this.items.nativeElement.value = parseFloat(this.items.nativeElement.value) + 1;
               this.lotepro = [];
               this.stateGroups2 =[];
               this.codigo = [];
               this.producto = [];
                 this.listarproductot();
                 this.ELEMENT_DATA.push({
                  item_nota: item,
                  concepto_nota: this.producto_pedido.nativeElement.value,
                  subtotal_producto: subtotal.toFixed(2).replace(/\D/g, "")
                  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                 });


                 this.subtotal_pedido.nativeElement.value = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.'))) +  (subtotal);

                 this.subtotal_pedido.nativeElement.value = parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(2).replace(/\D/g, "")
                 .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                 let total = parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace('.','')
                 .replace(',','.')) * parseFloat(this.iva.nativeElement.value) / 100;

                 this.iva_monto.nativeElement.value = total.toFixed(2).replace(/\D/g, "")
                 .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                  let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.')));

                  let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.'))/100);

                        this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                        resul_final = montoTotal - porcentaje;

                 this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
                 .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                 .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


                 this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);

                 this.producto_pedido.nativeElement.value = '';
                 this.num.nativeElement.value = '';
                 this.forma.value.cantidad_pedido = '';

             }else{
               this.stateGroups2 =[];
               this.lotepro = [];
               this.codigo = [];
               this.producto = [];
               this.listarproductot();
               this.producto_pedido.nativeElement.value = '';
               this.num.nativeElement.value = '';
               this.forma.value.cantidad_pedido = '';
                 this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                   verticalPosition: this.verticalPosition,});
             }
          }else{
            this.snackBar.open('Solo puede agregar un maximo de  1 item', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }

        }

    }else{

        if (parseInt(this.num.nativeElement.value.replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace('.','')
        .replace(',','.')) <= 0 || isNaN(this.num.nativeElement.value) || this.num.nativeElement.value === "") {
          this.snackBar.open('La cantidad debe ser mayor a 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }

      if (this.producto_pedido.nativeElement.value === '') {
        this.snackBar.open('Debe agregar un concepto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }

      if (parseFloat(this.num.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) > parseFloat(this.exento_monto.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'))) {
        this.snackBar.open('El monto es mayor al restante de la factura', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

    console.log(this.ELEMENT_DATA);

  }

  remove(val:any){
    var resta_iva;
    var iva_menos_exento;
    var iva_exento;
    var resul_final2;
   let resta = this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
   this.items.nativeElement.value = parseInt(this.items.nativeElement.value) - 1;
   /* this.cod_lista1.nativeElement.value = ''; */

   this.subtotal_pedido.nativeElement.value = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
   .replace('.','')
   .replace('.','')
   .replace('.','')
   .replace('.','')
   .replace(',','.')) -  parseFloat(resta[0].subtotal_producto.replace('.','')
   .replace('.','')
   .replace('.','')
   .replace('.','')
   .replace('.','')
   .replace(',','.')));

   console.log(this.subtotal_pedido.nativeElement.value);

   this.subtotal_pedido.nativeElement.value = parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(2).replace(/\D/g, "")
   .replace(/([0-9])([0-9]{2})$/, '$1,$2')
   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

   let total = parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
   .replace('.','')
   .replace('.','')
   .replace('.','')
   .replace('.','')
   .replace(',','.')) * parseFloat(this.iva.nativeElement.value) / 100;

   this.iva_monto.nativeElement.value = total.toFixed(2).replace(/\D/g, "")
                .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace('.','')
                .replace(',','.')));

                  let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace('.','')
                  .replace(',','.'))/100);

                        this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(2).replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{2})$/, '$1,$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
                        console.log(resta);

                        resul_final2 = montoTotal - porcentaje;
   this.total_pedido.nativeElement.value = resul_final2.toFixed(2).replace(/\D/g, "")
   .replace(/([0-9])([0-9]{2})$/, '$1,$2')
   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

    this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
    this.table.renderRows();
  }
  buscarproductot(xvalor:any){
    console.log(xvalor);
    var valor:any = {valor:xvalor}
    this.servicio.buscarPRODUCTOTparamhttp(valor).subscribe(
      response =>{
        this.producto = response;
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  modalrespuesta(){

    try {
      var xvalor = {
        val_def : 'F',
        tipo_operacion : 'NOTASADM',
        cod_usuario : localStorage.getItem('cod_usuario')
      };
      this.servicio.validar_acceso_acciones_http(xvalor).subscribe(
        response=>{
          if (this.ELEMENT_DATA.length === 0) {
            this.snackBar.open('No haz seleccionado ningun producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }else{

            if (this.nc_ad === 'admin') {
              this.accion = "";
            }else{
              this.accion = "nota_credito";
            }
            
            var dialogRef = this.dialog.open(FormmMsgComponent, {
              width: '100vw',
                    maxWidth: '100vw',
                    height:'100vh',
              data: {id:'',
                    accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
            });
            dialogRef.afterClosed().subscribe(result => {
      
              if(result != 'cerrar'){
      
                    console.log(result);
                          let cod_usuario = localStorage.getItem('cod_usuario');
                          let nombre = localStorage.getItem('Nombre');
                          let apellido = localStorage.getItem('Apellido');
                          
                          this.forma.value.cod_pedido = this.data.cod_pedido;
                          this.forma.value.cod_usuario = cod_usuario;
                          this.forma.value.des_usuario = nombre +' '+ apellido;
                          this.forma.value.cod_control = result;
      
                          this.forma.value.producto_pedido = this.ELEMENT_DATA;
                          this.forma.value.iva_monto = this.iva_monto.nativeElement.value;
                          this.forma.value.desc_pedido = this.desc_pedido.nativeElement.value;
                          this.forma.value.subtotal_pedido = this.subtotal_pedido.nativeElement.value;
                          this.forma.value.total_pedido = this.total_pedido.nativeElement.value;
                          this.forma.value.cod_lista_precio = this.cod_lista_precio.nativeElement.value;
                          this.forma.value.desc_pedido_monto = this.desc_pedido_monto.nativeElement.value;
                          this.forma.value.monto_exento = this.exento_monto.nativeElement.value;
      
                          this.forma.value.desc_pedido_monto = this.desc_pedido_monto.nativeElement.value;
                          this.forma.value.monto_exento = this.exento_monto.nativeElement.value;
                          console.log(this.forma.value);
                        this.dialogRefp.close(this.forma.value);
      
                /* let resultado = this.eliminarunidad(result.id); */
              }
            });
          }
        },
        error=>{
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition});
        }
      );
    } catch (error) {
      
    }
   
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
              maxWidth: '100vw',
              height:'100%',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  porcentaje(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;


          let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')));

          let porcentaje = (montoTotal  * parseFloat(this.desc_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.'))/100);

          this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

          let resul_final = montoTotal - porcentaje;

          this.total_pedido.nativeElement.value = resul_final.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");


   if (this.desc_pedido.nativeElement.value === "") {
     var cero = '0,00';
     let porcentaje = (parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
     .replace('.','')
     .replace('.','')
     .replace('.','')
     .replace('.','')
     .replace(',','.')) * parseFloat(cero)/100);

      this.desc_pedido_monto.nativeElement.value = porcentaje.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")

          let montoTotal = ( parseFloat(this.subtotal_pedido.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) + parseFloat(this.iva_monto.nativeElement.value.replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace('.','')
          .replace(',','.')) - porcentaje );

          this.total_pedido.nativeElement.value = montoTotal.toFixed(2).replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, '$1,$2')
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
   /* this.subtotal_pedido.nativeElement.value = parseFloat(this.subtotal_pedido.nativeElement.value).toFixed(2).replace(/\D/g, "")
   .replace(/([0-9])([0-9]{2})$/, '$1,$2')
   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."); */
   }

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  format(valor:any)
  {
    try {
      var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

}
