
<div id="modal_carrito" class=" container-fluid">
    <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
        <div class="row h-100">
            <div class="col-md-6 h-100 border" style="background-color:#202333; border-radius: 1rem 0 0 1rem;">
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; border-radius: 1rem 0 0 0; background-color:#202333;">
                    <div class="col-md-6 px-0 py-2 h-100">
                        <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <mat-form-field  class="materialinput py-1 px-1 ">
                                <mat-label style="color: rgb(255, 255, 255); font-size: 12px;">Tipo consignación</mat-label>
                                <mat-select  #tipo_um  style="font-size: 12px; color: rgb(255, 255, 255);" (selectionChange)="listarClienteEspecial(tipo_um)"  placeholder="" formControlName="tipo_um" autocomplete="off">
           
                                    <mat-option  *ngFor="let item of tipo_consignacion" [value]="item.concepto_definicion">{{item.concepto_definicion}}-{{item.descrip_definicion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div id="content_1" class="col-md-6 px-0 py-2 h-100">
                        <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4;">
                             <mat-form-field class="materialinput py-1 px-1">
                                <mat-label style="color: #fff; font-size: 12px;">Cliente</mat-label>
                                <input style="color: #fff; font-size: 12px;" type="text" matInput placeholder="" formControlName="cod_cliente" [matAutocomplete]="autoGroup2" #cod_cliente>
                                <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            <mat-icon style="color: rgb(5, 5, 5);">account_circle</mat-icon>
                            </span>
                                <mat-autocomplete #autoGroup2="matAutocomplete">
                                    <mat-optgroup *ngFor="let group2 of stateGroupOptions2 | async" [label]="group2.letter">
                                        <mat-option style="font-size: 10px;" *ngFor="let i of group2.code" [value]="i">
                                            {{i}}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
        
                </div>
        
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
                    <div class="col-md-6 px-0 py-2 h-100">
                        <div id="content_2" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <mat-form-field class="materialinput py-1 px-1">
                                <mat-label style="color: #fff; font-size: 11px;">Productos</mat-label>
                                <input class="" style="font-size: 12px; color: #fff;" type="text" matInput placeholder="" formControlName="producto_pedido" [matAutocomplete]="autoGroup" #producto_pedido>
                                <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                    <mat-icon style="color: rgb(5, 5, 5);">note_add</mat-icon>
                                    </span>
                                <mat-autocomplete (optionSelected)="buscarDataProductoUmCant($event.option.value)" #autoGroup="matAutocomplete" class="px-1">
                                    <mat-optgroup *ngFor="let group of stateGroupOptions | async" [label]="group.letter">
                                        <mat-option   style="font-size: 14px; color: #000;" *ngFor="let i of group.code" [value]="i">
                                            <b>{{i}}</b>
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div id="content_1" class="col-md-6 px-0 py-2 h-100">
                        <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3616c2">
                            <mat-form-field class="materialinput py-1 px-1">
                                <mat-label style="color: #fff; font-size: 11px;">Existencia</mat-label>
                                <input class="text-center" style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="cantidad_existencia" #cantidad_existencia autocomplete="off" readonly>
                                <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                    <mat-icon style="color: rgb(255, 94, 0);" *ngIf="!bandera">report_problem</mat-icon>
                                    <mat-icon style="color: rgb(255, 94, 0);" *ngIf="bandera">feedback</mat-icon>
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
                    <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                        <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#0f0a6b">
                            <mat-form-field class="materialinput py-1 px-1">
                                <mat-label style="color: #fff; font-size: 11px;">Cantidad</mat-label>
                                <input class="text-center" style="font-size: 12px; color: #fff; " (keyup)="cantidad_bultos_prod()" maxlength="11" type="text " matInput placeholder="" formControlName="cantidad_bultos" #cantidad_bultos autocomplete="off">
                                <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                            #
                        </span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                        <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <mat-form-field class="materialinput py-1 px-1">
                                <mat-label style="color: #fff; font-size: 11px;">Unidad de manejo</mat-label>
                                <input class="text-center" style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="unidad_manejo" #unidad_manejo autocomplete="off" readonly>
                                <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                    <mat-icon style="color: rgb(255, 94, 0);" *ngIf="!bandera_2">report_problem</mat-icon>
                                    <mat-icon style="color: rgb(5, 5, 5);" *ngIf="bandera_2">extension</mat-icon>
                                </span>
                            </mat-form-field>
                        </div>
                    </div> 
                    <div id="content_1" class="col-md-3 px-0 py-2 h-100">
                        <div id="sub_content_1" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4">
                            <mat-form-field class="materialinput py-1 px-1">
                                <mat-label style="color: #fff; font-size: 11px;">Solicitado</mat-label>
                                <input class="text-center" style="font-size: 12px; color: #fff; " maxlength="11" type="text " matInput placeholder="" formControlName="cantidad_pedido" #num autocomplete="off" readonly>
                                <span style="color: #fff; " mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                    <mat-icon style="color: rgb(0, 0, 0);">add_shopping_cart</mat-icon>
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
                   
                    
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
        
                  
                    
        
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
        
                
        
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333;">
        
                  
                 
        
                </div>
                <div id="carrito_p1_c" class="row mat-elevation-z8" style=" border: 2px solid #475bca; background-color:#202333; border-radius: 0 0 0 1rem;">
                    <div class="col-md-6 px-0 py-2 h-100">
                        <div id="content_3" class=" mx-1 mat-elevation-z8 h-100" style="border-radius: 5px; border: 2px solid #374fd6; background-color:#3b3bc4;">
                            <mat-form-field class="materialinput py-1 px-1">
                                <mat-label style="color: #fff; font-size: 14px;">Buscar</mat-label>
                                <input style="font-size: 12px; color: #fff;" #cod_lista1 matInput placeholder="" autocomplete="off" (keyup)="applyFilter($event)">
                                <span style="color: #fff;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                  <mat-icon>search</mat-icon>
                              </span>
                            </mat-form-field>
                        </div>
                    </div>
                  
                    <div id="content_boton1" class=" col-md-2 px-0 py-2 h-100">
                        <div class=" mx-1 h-100">
                            <button class="btn btn-block btn-lg  h-100" (click)="addElement()" matTooltip="AGREGAR PRODUCTOS" style="border: outset 3px rgb(124, 56, 155); color:rgb(255, 255, 255); background-color: rgb(111, 2, 161);">
                          <mat-icon >add</mat-icon>
                        </button>
                        </div>
                    </div>
                    
                    <div id="content_boton1" class=" col-md-2 px-0 py-2 h-100">
                        <div class=" mx-1 h-100">
                            <button class="btn btn-block btn-lg  h-100" (click)="modalrespuesta()" matTooltip="GENERAR CONSIGNACIÓN" style="border: outset 3px rgb(72, 81, 199);  color:rgb(255, 255, 255); background-color: rgb(40, 51, 197);">
                              <mat-icon >save</mat-icon>
                          </button>
                        </div>
                    </div>
                    <div class=" col-md-2 px-0 py-2 h-100">
                        <div class="  mx-1 h-100">
                            <button class="btn btn-block btn-lg btn-danger h-100" (click)="cerrarmodal()" matTooltip="SALIR" style="border: outset 3px rgb(199, 72, 72);">
                              <mat-icon >exit_to_app</mat-icon>
                          </button>
                        </div>
                    </div>
        
                </div>
            </div>
            <div class="col-md-6 h-100 border" style="border-radius: 0 1rem 1rem 0;" >
                <div id="carrito_p8" class="row h-100" style=" border: 2px solid #2b69dd; border-radius: 0 1rem  1rem 0; background-color:#29388f;">
                    <div class="col-md-12 col-sm-12 col-xs-12 px-2 py-2 table-responsive  h-100" >
                        <table #table mat-table [dataSource]="dataSource1" class=" table-dark text-center table-striped table-condensed table-hover">
        
                            <ng-container matColumnDef="cod_producto">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px;  color: #fff; background-color: #29388f;">
                                    <h5><strong>Codigo</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.cod_producto}} </td>
                                <td mat-footer-cell *matFooterCellDef style="padding: 5px;  color: #fff; background-color: #29388f;">{{cantidad_item()}}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="des_producto">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; ; color: #fff; background-color: #29388f;">
                                    <h5><strong>Producto</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.des_producto}} </td>
                                <td mat-footer-cell *matFooterCellDef style="padding: 5px;  color: #fff; background-color: #29388f;"></td>
                            </ng-container>
                            <ng-container matColumnDef="estatus_fiscal">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; ; color: #fff; background-color: #29388f;">
                                    <h5><strong>Estatus fiscal</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.estatus_fiscal}} </td>
                                <td mat-footer-cell *matFooterCellDef style="padding: 5px;  color: #fff; background-color: #29388f;"> </td>
                            </ng-container>
                            <ng-container matColumnDef="cant_producto">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; ; color: #fff; background-color: #29388f;">
                                    <h5><strong>Cantidad</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;">{{element.cant_producto}}</td>
                                <td mat-footer-cell *matFooterCellDef style="padding: 5px;  color: #fff; background-color: #29388f;"></td>
                            </ng-container>
        
                            <ng-container matColumnDef="precio_producto_base_bs">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; ; color: #fff; background-color: #29388f;">
                                    <h5><strong>Precio Bs</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"> {{element.precio_producto_base_bs}} </td>
                                <td mat-footer-cell *matFooterCellDef style="padding: 5px;  color: #fff; background-color: #29388f;"></td>
                            </ng-container>
        
                            <ng-container matColumnDef="remov_elem">
                                <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: #29388f;">
                                    <h5><strong>Eliminar</strong></h5>
                                </th>
                                <td mat-cell *matCellDef="let element" class="text-center" style="padding: 5px; font-size: 12px;">
                                    <button class="rounded-pill" (click)="remove(element)" matTooltip="BORRAR" mat-raised-button style="border: outset 3px #ac2929; height: 35px; color:rgb(255, 255, 255); background-color: #df4e4e;">
                              <mat-icon >delete</mat-icon>
                              </button>
                                </td>
                                <td mat-footer-cell *matFooterCellDef style="padding: 5px;  color: #fff; background-color: #29388f;"> </td>
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                        </table>
                        
                    </div>
        
                </div>
            </div>
        </div>
        
    </form>
</div>