import { Component, ElementRef, OnInit, ViewChild, Inject} from '@angular/core';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import { Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormSpinnerComponent } from 'src/app/componentes/shared/modal/form-spinner/form-spinner.component';
import { FormListarEmpleadoComponent } from '../../../shared/modal/form-listar-empleado/form-listar-empleado.component';
import {MatPaginator} from '@angular/material/paginator';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormmMsgComponent } from 'src/app/componentes/shared/modal/formm-msg/formm-msg.component';
import { FormFechasComponent } from 'src/app/componentes/shared/modal/form-fechas/form-fechas.component';
import { FormImportMasivoAsistenciaComponent } from 'src/app/componentes/shared/modal/form-import-masivo-asistencia/form-import-masivo-asistencia.component';


@Component({
  selector: 'app-asistencias',
  templateUrl: './asistencias.component.html',
  styleUrls: ['./asistencias.component.css']
})
export class AsistenciasComponent implements OnInit {
  
  response:any= [];
  sesion: any;
  cod_menu: any;
  object1: any;
  menu: any = [];
  menu2: any = [];
  menu3: any = [];
  menu4: any = [];
  roulink: any;
  unidad: any;
  usuario: any;
  menuorder3: any;
  menuoreder4: any;
  mostrar: any;
  ruta: any;

  datos: any = [];
  fechas: any;
  read:any;
  create:any;
  update:any;
  delete:any;
  special:any;
  accion_1:any;
  accion_2:any;
  accion_3:any;
  accion_4:any;
  accion_5:any;
  key_hash:any;
  key1:any = 'leer';
  key2:any = 'crear';
  key3:any = 'editar';
  key4:any = 'borrar';
  key5:any = 'especial';
  auth_menu:any = [];
  depositos:any=[];
  tipo_deposito:any=[];
  tipo_manejo:any=[];
  obj:any[] = [];
  result: any= [];
  xobj:any;
  xobj2:any;
  xobj3:any = [];
  dialogSpin:any;
  xobj4:any = [];
  xobj5:any = [];
  accion:any;
  ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource=new MatTableDataSource<any>();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private servicio:nkServices, public dialog: MatDialog, private snackBar: MatSnackBar, private router:Router) {

    this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};

    servicio.validar_permisos_accioneshttp(this.key_hash).subscribe(
      response => {
        this.auth_menu = response;
        console.log(response);
          /////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
          localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
    for (const i in this.auth_menu) {
      switch (this.auth_menu[i].des_accion) {
        case this.key1:
          localStorage.setItem('accion_1', this.auth_menu[i].cod_accion);
        break;
        case this.key2:
          localStorage.setItem('accion_2', this.auth_menu[i].cod_accion);
        break;
        case this.key3:
          localStorage.setItem('accion_3', this.auth_menu[i].cod_accion);
        break;
        case this.key4:
          localStorage.setItem('accion_4', this.auth_menu[i].cod_accion);
        break;
        case this.key5:
          localStorage.setItem('accion_5', this.auth_menu[i].cod_accion);
        break;
        default:
          break;
      }
    }

    this.accion_1 = localStorage.getItem('accion_1');
    this.accion_2 = localStorage.getItem('accion_2');
    this.accion_3 = localStorage.getItem('accion_3');
    this.accion_4 = localStorage.getItem('accion_4');
    this.accion_5 = localStorage.getItem('accion_5');

    for (const i in this.auth_menu) {
      if (this.auth_menu[i].cod_accion === this.accion_1) {
        this.read = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_2) {
        this.create = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_3) {
        this.update = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_4) {
        this.delete = true;
      }
      if (this.auth_menu[i].cod_accion === this.accion_5) {
        this.special = true;
      }
      
    }
/////////////////////////VALIDADOR DE PERMISOS 4 NIVEL///////////////////////////////////
      },
      error =>{
        localStorage.removeItem('accion_1');
          localStorage.removeItem('accion_2');
          localStorage.removeItem('accion_3');
          localStorage.removeItem('accion_4');
          localStorage.removeItem('accion_5');
        console.log(error);
      }
    );
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.mostrar = parseInt(localStorage.getItem('cod_valid'));
    this.sesion = localStorage.getItem('cod_usuario');
    console.log(typeof this.mostrar);
    this.usuario = localStorage.getItem('Nombre') + ' ' + localStorage.getItem('Apellido');
    this.object1 = { cod_usuario: this.sesion, cod_menu: this.cod_menu }
    this.mostrarTabla()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por pagina';
  }

  /* funcion que llama al api para buscar las asistencias de los empleados 
   this.servicio.listarAsistenciaRangoFechahttp(xvalor) esta es llamada desde la funcion buscarAsistenciaEmpleados()
  */

   mostrarTabla(){
                  this.displayedColumns = [
                                            'cod_usuario',
                                            'des_usuario',
                                            'unidad',
                                            'retraso',
                                            'salida_temprano',
                                            'fecha',
                                            'hora',                                        
                                           'entrada_salida',
                                           'dt_comedor' 
                                          ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
   }

   listarDataAsistencia(xvalor:any){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
       //this.listarDefiniciones(localStorage.getItem('sub_main'));
        this.servicio.listarAsistenciaRangoFechahttp(xvalor).subscribe(
          response =>{
            console.log(response, )
            ////////////////UNIDADES//////////////////////
           /*  this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3); */
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              let xobj2 = response.result;
              this.ELEMENT_DATA = [];
                  for (const i in xobj2) {

              this.ELEMENT_DATA.push({
                      
                 cod_usuario:xobj2[i].cod_usuario.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                 des_usuario: xobj2[i].des_usuario,
                 unidad: xobj2[i].unidad,
                 horario:xobj2[i].horario,
                 fecha:xobj2[i].fecha,
                 hora: xobj2[i].hora,
                 estatus:xobj2[i].estatus,
                 dt_ubicacion:xobj2[i].dt_ubicacion,
                 retraso: xobj2[i].retraso,
                 salida_temprano: xobj2[i].salida_temprano,
                 entrada_salida:xobj2[i].entrada_salida,
                 dt_comedor:xobj2[i].dt_comedor,
                 accion:''
                    });
                  }
                  this.displayedColumns = [
                                            'cod_usuario',
                                            'des_usuario',
                                            'unidad',
                                            'retraso',
                                            'salida_temprano',
                                            'fecha',
                                            'hora',                                        
                                           'entrada_salida',
                                           'dt_comedor' 
                                          ];
                                          
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
              'cod_usuario',
              'des_usuario',
              'unidad',
              'retraso',
              'salida_temprano',
              'fecha',
              'hora',                                        
             'entrada_salida', 
             'dt_comedor'
            ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
          }
        );
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  listarAsistenciaRangoFecha(xvalor:any){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
       //this.listarDefiniciones(localStorage.getItem('sub_main'));
        this.servicio.listarAsistenciaRangoFechahttp(xvalor).subscribe(
          response =>{
            console.log(response, )
            ////////////////UNIDADES//////////////////////
           /*  this.xobj3 = [];
            for (const i in response[0]) {
              this.xobj3.push(response[0][i]);
            }
            console.log(this.xobj3); */
            if(response){
              this.dialogSpin.close();
              console.log(response,'una respuesta');
              let xobj2 = response.result;
              this.ELEMENT_DATA = [];
                  for (const i in xobj2) {

              this.ELEMENT_DATA.push({
                      
                 cod_usuario:xobj2[i].cod_usuario.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                 des_usuario: xobj2[i].des_usuario,
                 unidad: xobj2[i].unidad,
                 horario:xobj2[i].horario,
                 fecha:xobj2[i].fecha,
                 hora: xobj2[i].hora,
                 estatus:xobj2[i].estatus,
                 dt_ubicacion:xobj2[i].dt_ubicacion,
                 retraso: xobj2[i].retraso,
                 salida_temprano: xobj2[i].salida_temprano,
                 entrada_salida:xobj2[i].entrada_salida,
                 dt_comedor:xobj2[i].dt_comedor,
                 accion:''
                    });
                  }
                  this.displayedColumns = [
                    'cod_usuario',
                    'des_usuario',
                    'unidad',
                    'retraso',
                    'salida_temprano',
                    'fecha',
                    'hora',                                        
                   'entrada_salida', 
                   'dt_comedor' 
                  ];
                   /* this.dataSource = this.ELEMENT_DATA; */
                  this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
                  this.dataSource.paginator = this.paginator;
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(error.error.message);
            this.displayedColumns = [
              'cod_usuario',
              'des_usuario',
              'unidad',
              'retraso',
              'salida_temprano',
              'fecha',
              'hora',                                        
             'entrada_salida',
             'dt_comedor' 
            ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
          }
        );
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  /* funcion que llama al formulario para insertar la fecha del rango de busqueda
  esta llama a listarAsistenciaRangoFecha para hacer la busqueda y ser rederizada en la vista 
  */

  buscarAsistenciaEmpleados(obj:any, text:any){

    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
      console.log(obj, 'segundo');
      console.log(this.xobj3,'UNIDADES');
      if (text == 'listar') {
        this.accion = text;
         dialogRef = this.dialog.open(FormFechasComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100%',
          data: {fecha_inicio:obj.fecha_inicio,
                 fecha_fin:obj.fecha_fin,
                 accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
        });
      }

      dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
            console.log(result, 'que tenemos aqui');
              this.listarAsistenciaRangoFecha(result);
          }
      });
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }

  descargarAsistenciaEmpleados(obj:any, text:any){
    try {
      this.key_hash = {cod_usuario: localStorage.getItem('cod_usuario'),
                    cod_menu: localStorage.getItem('sub_main'),
                    cod_sesion: localStorage.getItem('cod_sesion'),
                    dir_ip_client: localStorage.getItem('dir_ip'),
                  sesion_unica:sessionStorage.getItem('sesion_unica')};
                    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(
                      response =>{
                        if (response.resul) {
                          var dialogRef;
      
      if (text == 'listar') {
        this.accion = text;
         dialogRef = this.dialog.open(FormFechasComponent, {
          width: '100vw',
          maxWidth: '100vw',
          height:'100vh',
          data: {fecha_inicio:obj.fecha_inicio,
                 fecha_fin:obj.fecha_fin,
                 accion:this.accion},
              disableClose: true,
              panelClass: 'pedidos_modal',
              backdropClass: "bdrop"
        });
      }
      dialogRef.afterClosed().subscribe(result => {
          if(result != 'cerrar'){
            console.log(result, 'que tenemos aqui');
              this.buscarAsistenciaRangoFecha(result);
          }
      });
            }else{
              this.snackBar.open(response.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          },
          error =>{
            this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
        } catch (error) {
        this.snackBar.open(error,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        }
  }

  buscarAsistenciaRangoFecha(xvalor:any){

    try {
     // let msg = msj;
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });

      setTimeout(() => {
       //this.listarDefiniciones(localStorage.getItem('sub_main'));
        this.servicio.listarAsistenciaRangoFechahttp(xvalor).subscribe(
          response =>{
            if(response){
              this.dialogSpin.close();
              
              let xobj2 = response.result;
              this.ELEMENT_DATA = [];
                  for (const i in xobj2) {

              this.ELEMENT_DATA.push({
                      
                 cod_usuario:xobj2[i].cod_usuario.replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."),
                 des_usuario: xobj2[i].des_usuario,
                 unidad: xobj2[i].unidad,
                 horario:xobj2[i].horario,
                 fecha:xobj2[i].fecha,
                 hora: xobj2[i].hora,
                 estatus:xobj2[i].estatus,
                 dt_ubicacion:xobj2[i].dt_ubicacion,
                 retraso: xobj2[i].retraso,
                 salida_temprano: xobj2[i].salida_temprano,
                 entrada_salida:xobj2[i].entrada_salida,
                 dt_comedor:xobj2[i].dt_comedor,
                 accion:''
                    });
                  }
                  this.displayedColumns = [
                    'cod_usuario',
                    'des_usuario',
                    'unidad',
                    'retraso',
                    'salida_temprano',
                    'fecha',
                    'hora',                                        
                   'entrada_salida', 
                   'dt_comedor' 
                  ];
                  
                  this.descarga_xlsx(this.ELEMENT_DATA);
            }
          },
          error =>{
            this.dialogSpin.close();
            console.log('una respuesta');
            console.log(<any>error.error.message);
            this.displayedColumns = [
              'cod_usuario',
              'des_usuario',
              'unidad',
              'retraso',
              'salida_temprano',
              'fecha',
              'hora',                                        
             'entrada_salida', 
             'dt_comedor' 
            ];
            this.dataSource = new MatTableDataSource<any>();
                  this.dataSource.paginator = this.paginator;
                  this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition});
                  
          }
        );
        return this.xobj2;
      }, 1000);

    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }
  cargarMasivo(){
    
    this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
      width: '15%',
      height:'25%',
      data: {
              accion:this.accion},
      disableClose: true,
      panelClass: 'spinner',
      backdropClass: "bdrop"
    });


    this.servicio.consultar_sesionhttp(this.key_hash).subscribe(result => {

      this.dialogSpin.close()

      var dialogRef;
      dialogRef = this.dialog.open(FormImportMasivoAsistenciaComponent,     {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data:'',
      disableClose:   true,
      panelClass: 'pedidos_modal3',
      backdropClass: "bdrop"
      });

      dialogRef.afterClosed().subscribe(resul => {

        if(resul != 'cerrar'){

          console.log('Se cerro la wea1234')
          this.mostrarTabla()

        }

     
    })
    },
    error =>{
      this.dialogSpin.close()
      this.snackBar.open(error.error.message,'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    
    )

}

  descarga_xlsx(xdatos:any){
    try {
        console.log(xdatos,'llego a la descarga');
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Asistencias");
        let header=["Codigo","Nombres y Apellidos","Departamento","Horario","Fecha","Hora","Estatus","Ubicación","Retraso","Salida Temprano","Entrada-Salida","Beneficio Comedor"];
        let headerRow = worksheet.addRow(header);
    for (let x1 of xdatos){
    let x2=Object.keys(x1);
    let temp=[]
    for(let y of x2)
    {

      x1[y] === 'N/A' ? x1[y] = "-" : x1[y]

      temp.push(x1[y])
    }
    worksheet.addRow(temp)
    }
    let fname="Asistencia";

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname+'.xlsx');
    });
      } catch (error) {
          console.log(error);
          this.snackBar.open(error, 'Cerrar',{duration:10000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,})
        }
  } 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  en_construccion(): void {
    try {
      this.snackBar.open('Esta funcionalidad esta en desarrollo.', 'Cerrar', {
        duration: 1000, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    } catch (error) {
      this.snackBar.open(error, 'Cerrar', {
        duration: 1500, horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      })
    }
  }
}
