<div class="" id="modal" *ngIf="accion === 'crear'">
    <div class="row" style="border-radius: 0.5rem;">
            <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 0.5rem;">
                <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                    <div class="modal-content h-100" style="border-radius: 0.5rem;">
                        <div class="modal-header" style="background-color: rgb(57, 55, 177); color: #fff; border-radius: 0.5rem 0.5rem 0 0;">
                            <h3 class="modal-title" class=" text-left"><strong> Crear smtp del sistema</strong> </h3>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="row py-0" *ngIf="admin">
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label >Empresa</mat-label>
                                                    <mat-select formControlName="cod_empresa" autocomplete="off">
                                                        <mat-option *ngFor="let item of empresa" [value]="item.cod_usuario">{{item.nombre}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row py-0" >
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Descripción</mat-label>
                                                    <input matInput  autocomplete="off" formControlName="des_smtp">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Correo</mat-label>
                                                    <input matInput  autocomplete="off" formControlName="dir_smtp">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row py-0" >
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Host</mat-label>
                                                    <input matInput  autocomplete="off" formControlName="host_smtp">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Puerto</mat-label>
                                                    <input matInput  autocomplete="off" formControlName="port_smtp">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row py-0" >
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Usuario</mat-label>
                                                    <input matInput  autocomplete="off" formControlName="user_smtp">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Contraseña</mat-label>
                                                    <input matInput  autocomplete="off" formControlName="pass_smtp">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row py-0" >
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label >Ssl</mat-label>
                                                    <mat-select formControlName="ssl_smtp" autocomplete="off">
                                                        <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label >Estatus</mat-label>
                                                    <mat-select formControlName="estatus" autocomplete="off">
                                                        <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="form-group">
                                <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>
                                <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
</div>
<div class="" id="modal" *ngIf="accion === 'actualizar'">
    <div class="row" style="border-radius: 1rem;">
        <div class="col-md-12 col-sm-12 col-xs-12 h-100" style="border-radius: 1rem;">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()" enctype="multipart/form-data">
                <div class="modal-content h-100" style="border-radius: 1rem;">
                    <div class="modal-header" style="background-color: rgb(57, 55, 177); color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" class=" text-left"><strong> Editar smtp del sistema</strong> </h3>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <div class="row py-0" >
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Descripción</mat-label>
                                                <input matInput  autocomplete="off" formControlName="des_smtp">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Correo</mat-label>
                                                <input matInput  autocomplete="off" formControlName="dir_smtp">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0" >
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Host</mat-label>
                                                <input matInput  autocomplete="off" formControlName="host_smtp">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Puerto</mat-label>
                                                <input matInput  autocomplete="off" formControlName="port_smtp">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0" >
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Usuario</mat-label>
                                                <input matInput  autocomplete="off" formControlName="user_smtp">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Contraseña</mat-label>
                                                <input matInput  autocomplete="off" formControlName="pass_smtp">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row py-0" >
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Ssl</mat-label>
                                                <mat-select formControlName="ssl_smtp" autocomplete="off">
                                                    <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 py-0">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Estatus</mat-label>
                                                <mat-select formControlName="estatus" autocomplete="off">
                                                    <mat-option *ngFor="let item of estatus" [value]="item.cod_estatus">{{item.des_estatus}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger" (click)="cerrarmodal()" style="margin-right: 5px;">Cancelar</button>

                            <button mat-button class=" btn-primary" (click)="modalrespuesta()" [disabled]="forma.invalid"> Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
