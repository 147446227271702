<div class="col-md-12 col-sm-12 col-xs-12" style="height: 100%; border-radius: 1rem;">
            <form [formGroup]="forma" (keydown.enter)="$event.preventDefault()">
                <div class="modal-content" style="height: 100%; width: 100%; border-radius: 1rem;">
                    <div class="modal-header head_formularios" style=" color: #fff; border-radius: 1rem 1rem 0 0;">
                        <h3 class="modal-title" class="text-center">
                            <strong *ngIf="accion === 'crear'">Crear licencias</strong>
                            <strong *ngIf="accion === 'actualizar'">Actualizar licencias</strong>
                        </h3>
                    </div>
                    <div class="modal-body">
                            <div class="row" *ngIf="accion === 'crear'">
                                <div class="col-md-12 col-sm-12">
                                            <!-- <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Tipo</mat-label>
                                                    <input style="font-size: 11px; " type="text" matInput placeholder="" formControlName="tipo_licencias" [matAutocomplete]="autoGroup">
                                                    <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                                            <mat-icon>search</mat-icon>
                                                                            </span>
                                                    <mat-autocomplete #autoGroup ="matAutocomplete">
                                                        <mat-optgroup style=" font-size: 12px; " *ngFor="let group of tipo_licencias | async" [label]="group.letter">
                                                            <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                                                {{i}}
                                                            </mat-option>
                                                        </mat-optgroup>
                                                    </mat-autocomplete>
                                            </mat-form-field> -->
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label >Tipo</mat-label>
                                                <mat-select formControlName="tipo_licencias"  autocomplete="off">
                                                    <mat-option *ngFor="let i of licencia" [value]="i.concepto_definicion">{{i.concepto_definicion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Descripción</mat-label>
                                                <input style="font-size: 11px; " type="text" matInput  placeholder="" formControlName="descrip_licencia" autocomplete="false">
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="w-50">
                                                <mat-label >Estatus</mat-label>
                                                <mat-select formControlName="estatus" autocomplete="off">
                                                <mat-option *ngFor="let i of estatus" [value]="i.cod_estatus">{{i.des_estatus}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                </div>
                            </div>
                            <div class="row" *ngIf="accion === 'actualizar'">
                                <div class="col-md-12 col-sm-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Tipo</mat-label>
                                                    <input style="font-size: 11px; " type="text" matInput placeholder="" formControlName="tipo_licencias" [matAutocomplete]="autoGroup">
                                                    <span style="color: #000;" mat-icon-button matSuffix [attr.aria-label]="'Hide password'">
                                                                            <mat-icon>search</mat-icon>
                                                                            </span>
                                                    <mat-autocomplete #autoGroup ="matAutocomplete">
                                                        <mat-optgroup style=" font-size: 12px; " *ngFor="let group of tipo_licencias | async" [label]="group.letter">
                                                            <mat-option style="font-size: 12px; color: #000;" *ngFor="let i of group.code " [value]="i">
                                                                {{i}}
                                                            </mat-option>
                                                        </mat-optgroup>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label>Descripción</mat-label>
                                                    <input style="font-size: 11px; " type="text" matInput  placeholder="" formControlName="descrip_licencia" autocomplete="false">
                                                </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label >Estatus</mat-label>
                                                    <mat-select formControlName="estatus" autocomplete="off">
                                                        <mat-option *ngFor="let i of estatus" [value]="i.cod_estatus">{{i.des_estatus}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="modal-footer">
                        <div class="form-group">
                            <button mat-button class=" btn-danger mx-2" (click)="cerrarmodal()">Cancelar</button>
                            <button [disabled]="forma.invalid" (click)="actualizardatos('insertar')" mat-button class=" btn-primary">Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
