<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">
    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav" style=" background-color: steelblue; padding: 5px 7px 5px 7px; margin: 0px;">
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255);"><mat-icon >aspect_ratio</mat-icon></span>
            <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); "><b style="border-bottom: 1px solid rgb(255, 255, 255);">GESTIONAR GUIAS DE ALMACEN</b></span>
            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarGuiaDespacho("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="REPORTES" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="warn" style="height: 35px; " (click)='en_construccion()'><mat-icon >list</mat-icon></button></li>
                </ul>
                <div class=" ">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row " style="height: 78.7%;">

        <div class="col-md-12 px-0  border h-100 " style="background-color: steelblue; overflow-y: auto;">
            <table mat-table [dataSource]="dataSource" class=" text-center table-striped  table-condensed table-hover">

                <ng-container matColumnDef="cod_factura">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Documento</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_factura}} </td>
                </ng-container>
                <ng-container matColumnDef="cod_guia_despacho">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.cod_guia_despacho}} </td>
                </ng-container>
                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Estatus</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.estatus}} </td>
                </ng-container>
                <ng-container matColumnDef="tipo_despacho">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Tipo</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.tipo_despacho}} </td>
                </ng-container>

                <ng-container matColumnDef="des_cliente">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Cliente</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_cliente}} </td>
                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Usuario</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.des_usuario}} </td>
                </ng-container>

                <ng-container matColumnDef="fecha_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Fecha Inicio</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.fecha_inicio}} </td>
                </ng-container>
                <ng-container matColumnDef="hora_inicio">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Hora Inicio</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.hora_inicio}} </td>
                </ng-container>

                <ng-container matColumnDef="um_guia">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>UM</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; "> {{element.um_guia}} </td>
                </ng-container>

                <ng-container matColumnDef="detalle">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Detalles</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="read" matTooltip="DETALLES" (click)="detalleGuiaPedido(element)" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(121, 8, 64);">
                            <mat-icon >list_alt</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!read" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon>list_alt</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="padding: 5px; color: #fff; background-color: steelblue;">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td [ngClass]="{'gdp_f':  element.tipo_despacho === 'F','gdp_n':  element.tipo_despacho === 'N','gdp_c':  element.tipo_despacho === 'C'} " mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center">
                        <button class="rounded-pill" *ngIf="read" matTooltip="IMPRIMIR GUIA DE ALMACEN" mat-raised-button (click)="imprimirguialamacen(element)" style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 86, 247);">
                            <mat-icon >print</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!read" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >print</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>