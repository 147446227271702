<div class="container-fluid rounded" *ngIf="accion" style=" border: 2px solid #2b69dd; background-color:#29388f;">
    <div class="row py-1">
        <form [formGroup]="forma" class="form-inline my-0 mr-2" (keydown.enter)="$event.preventDefault()">
            <div class="col-md-2 col-sm-12 col-xs-12 ">
                <mat-form-field class="materialinput">
                    <mat-label class="text-white">Unidades</mat-label>
                    <mat-select (selectionChange)="cargarmenu(unidad_front)" #unidad_front  formControlName="unidad" autocomplete="off">
                        <mat-option *ngFor="let i of unidadp" [value]="i">({{i.cod_unidad}}) {{i.des_unidad}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 ">
                <mat-form-field class="materialinput">
                    <mat-label class="text-white">Menu</mat-label>
                    <mat-select (selectionChange)="cargarsubmenu(menu_front)" #menu_front  formControlName="menu_padre" autocomplete="off">
                        <mat-option *ngFor="let item of menu" [value]="item">({{item.cod_menu}}) {{item.des_menu}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 ">
                <mat-form-field class="materialinput">
                    <mat-label class="text-white">Submenu</mat-label>
                    <mat-select (selectionChange)="cargarsubmenuhijo(submenu_front)" #submenu_front  formControlName="submenu" autocomplete="off">
                        <mat-option *ngFor="let item of submenu" [value]="item">({{item.cod_menu}}) {{item.des_menu}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 ">
                <mat-form-field class="materialinput">
                    <mat-label class="text-white">Submenu 2</mat-label>
                    <mat-select (selectionChange)="activar()" #submenu_hijo  formControlName="submenu1" autocomplete="off">
                        <mat-option *ngFor="let item of submenuhijo" [value]="item">({{item.cod_menu}}) {{item.des_menu}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 d-flex justify-content-end ">
                <button matTooltip="AGREGAR ACCESO" (click)="modalrespuesta()" class="mr-1 col-md-2 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);"><mat-icon >add</mat-icon></button>
                <button matTooltip="SALIR" (click)="cerrarmodal()" class="col-md-2 col-sm-12 col-xs-12 " mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(216, 68, 58);"><mat-icon >exit_to_app</mat-icon></button>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12 d-flex justify-content-center ">
                <input class="form-control" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
            </div>
        </form>
    </div>
    <div class="row modalgeneral" style=" border: 2px solid #2b69dd; background-color:#29388f;">
        <div class=" table-responsive  h-100" >
            <table  mat-table [dataSource]="dataSource" class=" table-dark text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_permiso" >
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Permiso</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.cod_permiso}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="menu">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Menu</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.menu}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="submenu">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Submenu</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.submenu}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="submenu1">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff; ">
                        <h5><strong>Submenu1</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 5px; font-size: 12px; color: #fff;"><b>{{element.submenu1}}</b></td>
            
                </ng-container>

                <ng-container matColumnDef="proceso">
                    <th mat-header-cell *matHeaderCellDef class="text-center" style="background-color:#29388f; padding: 5px; color: #fff;">
                        <h5><strong>Acción</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="text-center" >
                        <button  class=" bg-danger"   matTooltip="ELIMINAR" mat-mini-fab (click)='getQuitarPermiso(element)'>
                            <mat-icon >clear</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>
            </table>
            <mat-paginator class="w-100 mat-paginator-sticky" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
