import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

@Component({
  selector: 'app-form-cant-dev-product',
  templateUrl: './form-cant-dev-product.component.html',
  styleUrls: ['./form-cant-dev-product.component.css']
})
export class FormCantDevProductComponent implements OnInit {

  @ViewChild('cant_dev',{static:true}) cant_dev:ElementRef;
  @ViewChild('cant_producto',{static:true}) cant_producto:ElementRef;
  @ViewChild('valor_1',{static:true}) valor_1:ElementRef;
  @ViewChild('valor_2',{static:true}) valor_2:ElementRef;
  @ViewChild('valor_3',{static:true}) valor_3:ElementRef;
  @ViewChild('lote_1',{static:true}) lote_1:ElementRef;
  @ViewChild('lote_2',{static:true}) lote_2:ElementRef;
  @ViewChild('lote_3',{static:true}) lote_3:ElementRef;

  forma:FormGroup;
  producto:any = [];
  codigo:any = [];
  tipo_dev:any;
  dialogSpin:any;
  accion:any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormCantDevProductComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      console.log(data, 'primero');
      if (data.tipo_devolucion === 'DVLCN') {
        this.tipo_dev = true;
      }else{
        this.tipo_dev = false;
      }
      
      this.producto = data.des_producto;
      this.codigo = data.cod_producto;
      this.forma = fb.group({
        'cant_producto':data.cant_producto,
        'cant_dev':'',
        'cod_producto':data.cod_producto,
        'des_producto':data.des_producto,
        'cod_documento':data.cod_documento,
        'lote_1':'',
        'valor_1':'',
        'lote_2':'',
        'valor_2':'',
        'lote_3':'',
        'valor_3':'',
      });
    }

  ngOnInit(): void {
  }

  devolucion_prod(xvalor:any){
    try {
            this.servicio.validar_lotes_devhttp(this.forma.value).subscribe(
              response =>{
                console.log(response.message);
                if (!isNaN(this.cant_dev.nativeElement.value)) {
                  if (parseInt(this.cant_dev.nativeElement.value.replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace(',', '.')) <= parseInt(this.cant_producto.nativeElement.value.replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace(',', '.')) && parseInt(this.cant_dev.nativeElement.value.replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace(',', '.')) > 0  ) {

                    if (this.valor_1.nativeElement.value === '' ||
                         this.valor_1.nativeElement.value === null ||
                         this.valor_1.nativeElement.value === undefined) {
                      this.forma.value.valor_1 = '';
                    }
                    if (this.valor_2.nativeElement.value === '' ||
                         this.valor_2.nativeElement.value === null ||
                         this.valor_2.nativeElement.value === undefined) {
                      this.forma.value.valor_2 = '';
                    }
                    if (this.valor_3.nativeElement.value === '' ||
                         this.valor_3.nativeElement.value === null ||
                         this.valor_3.nativeElement.value === undefined) {
                      this.forma.value.valor_3 = '';
                    }

                    this.forma.value.cant_dev = this.cant_dev.nativeElement.value;
                    console.log(this.forma.value,'objeto valores');
                    this.dialogRefp.close(this.forma.value);
                  }else{
                    this.snackBar.open('La cantidad debe ser menor ó igual a la cantidad del producto y mayor a 0 ', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                      verticalPosition: this.verticalPosition,});
                  }
                }else{
                  this.snackBar.open('Use solo numeros por favor.', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
                }
              },
              error =>{
                this.snackBar.open(error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                  verticalPosition: this.verticalPosition,});
              }            
              );
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  sumar_dev_lote(numero:any,objeto:any){
    try {
            if (numero !== '') {
                      var  ingreso1 = (isNaN(parseInt(this.valor_1.nativeElement.value)))? 0 : parseInt(this.valor_1.nativeElement.value);
                      var  ingreso2 = (isNaN(parseInt(this.valor_2.nativeElement.value)))? 0 : parseInt(this.valor_2.nativeElement.value);
                      var  ingreso3 = (isNaN(parseInt(this.valor_3.nativeElement.value)))? 0 : parseInt(this.valor_3.nativeElement.value);
                      var resul = ingreso1 + ingreso2 + ingreso3;
                      this.cant_dev.nativeElement.value = resul.toFixed(0).replace(/\D/g, "")
                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");

                return null;

            }else{
              objeto.value = '';
              this.snackBar.open('Debe colocar el Lote para poder asignar un valor a enviar', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
                return null;
            }
    } catch (error) {
      console.log(error);
    }
  }
  restar_dev_lote(numero:any,objeto:any){
    try {
            if (objeto.value === '') {
              numero.value = '';
                      var  ingreso1 = (isNaN(parseInt(this.valor_1.nativeElement.value)))? 0 : parseInt(this.valor_1.nativeElement.value);
                      var  ingreso2 = (isNaN(parseInt(this.valor_2.nativeElement.value)))? 0 : parseInt(this.valor_2.nativeElement.value);
                      var  ingreso3 = (isNaN(parseInt(this.valor_3.nativeElement.value)))? 0 : parseInt(this.valor_3.nativeElement.value);
                      var resul = ingreso1 + ingreso2 + ingreso3;
                      this.cant_dev.nativeElement.value = resul.toFixed(0).replace(/\D/g, "")
                                                                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");                                    
                return null;

            }
    } catch (error) {
      console.log(error);
    }
  }

  cerrarmodal(): void {
    this.accion = 'salir';
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '100vw',
      maxWidth: '100vw',
      height:'100vh',
      data: {id:'',
            accion:this.accion},
      disableClose: true,
      panelClass: 'pedidos_modal',
      backdropClass: "bdrop"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != 'cerrar') {
        this.cerrar();
      }

    });

  }
  cerrar():void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.dialogRefp.close('cerrar');
      }, 2000);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }
}
