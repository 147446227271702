import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';
import { FormSpinnerComponent } from '../form-spinner/form-spinner.component';
import { Console } from 'console';

export interface StateGroup {
  letter: any;
  names: any;
  code: any
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-consignaciones',
  templateUrl: './form-consignaciones.component.html',
  styleUrls: ['./form-consignaciones.component.css']
})
export class FormConsignacionesComponent implements OnInit {

  @ViewChild('table') table:MatTable<any>;
  @ViewChild('producto_pedido') producto_pedido:ElementRef;
  @ViewChild('dispo_pedido',{static:true}) dispo_pedido:ElementRef;
  @ViewChild('cod_cliente',{static:true}) cod_cliente:ElementRef;
  @ViewChild('total_pedido',{static:true}) total_pedido:ElementRef;
  @ViewChild('num',{static:true}) num:ElementRef;
  @ViewChild('subtotal_pedido',{static:true}) subtotal_pedido:ElementRef;
  @ViewChild('iva_monto',{static:true}) iva_monto:ElementRef;
  @ViewChild('desc_pedido',{static:true}) desc_pedido:ElementRef;
  @ViewChild('tasa_precio',{static:true}) tasa_precio:ElementRef;
  @ViewChild('autoGroup2',{static:true}) autoGroup2:ElementRef;
  @ViewChild('precio_bs',{static:true}) precio_bs:ElementRef;
  @ViewChild('precio_base',{static:true}) precio_base:ElementRef;

  @ViewChild('unidad_manejo',{static:true}) unidad_manejo:ElementRef;
  @ViewChild('cantidad_existencia',{static:true}) cantidad_existencia:ElementRef;

  @ViewChild('cantidad_bultos',{static:true}) cantidad_bultos:ElementRef;
  @ViewChild('cantidad_pedido',{static:true}) cantidad_pedido:ElementRef;

  @ViewChild('tipo_um',{static:true}) tipo_um:ElementRef;

  stateGroupOptions: Observable<StateGroup[]>;
  stateGroupOptions2: Observable<StateGroup[]>;
  bandera:any = true;
  bandera_2:any = true;
  value1 = '';
  nombre:any;
termino:any;
forma:FormGroup;
stateForm: FormGroup;
stateGroups: StateGroup[];
stateGroups2: StateGroup[];
accion:any;
unidad:any;
dialogSpin:any;
unidadp:any = [];
menu:any;
producto:any = [];
cliente_especial = [];
nom_cliente:any;
codigo:any = [];
lotepro:any = [];
cant:any = false;
lote:any = true;
des:any;
guard:any = false;
tipo_consignacion:any;

date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
  displayedColumns:string[];
   dataSource1=new MatTableDataSource<any>();
   horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
    public dialogRefp: MatDialogRef<FormConsignacionesComponent>,
    ///en el injectable llega la informacion del metodo padre en data
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(data, 'primero');
    this.accion = data.accion;

      this.forma = fb.group({
        'cod_lista_precio_producto':data.id,
        'cod_usuario':'',
        'des_usuario':data.des_usuario,
        'tipo_cliente':data.tipo_cliente,
        'cod_vendedor':'',
        'des_vendedor':data.des_vendedor,
        'estatus_cliente':data.estatus_cliente,
        'tipo2_cliente':data.tipo2_cliente,
        'credito_dias_cliente':data.credito_dias_cliente,
        'reten_cliente':data.reten_cliente,
        'comercializacion_pedido':data.comercializacion,
        'producto_pedido':'',
        'cod_cliente':'',
        'des_cliente':'',
        'tasa_precio':'',
        'precio_base':'',
        'precio_bs':'',
        'termino':data.termino,
        'cantidad_pedido':'0',
        'cantidad_existencia':'0',
        'cantidad_bultos':'0',
        'cod_lista_precio':'',
        'unidad_manejo':'0',
        'iva_pedido':'',
        'tipo_um':''

      });
      this.stateForm = this.formb.group({
        stateGroup: '',
      });
    }

  ngOnInit(): void {
    this.lote = true;
    this.termino = this.data.termino;
    this.formulario();
    this.listarproductot();
      this.listarDefiniciones(localStorage.getItem('sub_main'));
  }
  ngAfterViewInit() {

  }

  listarDefiniciones(objeto:any){
    try {
      var xvalor ={cod_menu:objeto};
        this.servicio.listarDefinicionesConceptoshttp(xvalor).subscribe(
          response =>{
            console.log(response,'definiciones');
            this.tipo_consignacion= [];
            for (const i in response) {
              if (response[i].des_definicion.toUpperCase() === 'TIPO CONSIGNACION') {
                  this.tipo_consignacion.push(response[i]);
              }
            }
          },
          error =>{
            console.log(<any>error.error.message,'error definiciones');
          }
        );
        return null;
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:3000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition});
    }
  }

  cantidad_bultos_prod() {
    try {
      var canti_bultos_unidad = parseInt(this.cantidad_bultos.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.')) * parseInt(this.unidad_manejo.nativeElement.value.replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace('.','')
      .replace(',','.'))
      this.num.nativeElement.value = canti_bultos_unidad.toFixed(0).replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  buscarDataProductoUmCant(xvalor:any){
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      console.log(this.forma.value);

      if (this.forma.value.tipo_um === '' ||
          this.forma.value.tipo_um === null ||
          this.forma.value.tipo_um === undefined  ) {

            this.dialogSpin.close();
          this.snackBar.open('Seleccione la unidad de manejo', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
        
      }else{
        setTimeout(() => {
          var prod = xvalor.split('-');
          var producto = prod[0]
          var um = this.forma.value.tipo_um.split('-');
          var tipo_manejo = um[0];
          var datos = {cod_producto:producto, mod_factur:tipo_manejo}
          this.servicio.buscarDataProductoUmCanthttp(datos).subscribe(
            response =>{
              this.dialogSpin.close();
             
              
              if (parseInt(response.cant_producto) <= 0) {
                this.bandera = false;
              }else{
                this.bandera = true;
              }
  
              if (parseInt(response.unidad_manejo) <= 0 || response.unidad_manejo === null || response.unidad_manejo === undefined || response.unidad_manejo === '') {
                this.bandera_2 = false;
              }else{
                /* response.unidad_manejo = "1"; */
                this.bandera_2 = true;
              }

              if (this.forma.value.tipo_um.toUpperCase() === 'UM') {
                this.unidad_manejo.nativeElement.value = response.unidad_manejo;
              }else{
                this.unidad_manejo.nativeElement.value = '1';
              }
              this.cantidad_existencia.nativeElement.value = response.cant_producto+'   '+' ( '+response.cod_deposito+'  '+'( '+ response.des_deposito +' )'+' ) ';
            
            },
            error =>{
              this.dialogSpin.close();
              console.log(<any>error);
              this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
        }, 500);
      }
    } catch (error) {
      this.dialogSpin.close();
      console.log(error);
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map(group => ({letter: group.letter, names: _filter(group.names, value), code: _filter(group.code, value)}))
        .filter(group => group.names.length > 0 || group.code.length > 0 );
    }

    return this.stateGroups;
  }
  private _filterGroup2(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups2
        .map(group2 => ({letter: group2.letter, names: filter(group2.names, value), code: filter(group2.code, value)}))
        .filter(group2 => group2.names.length > 0 || group2.code.length > 0 );
    }

    return this.stateGroups2;
  }

  listarproductot(){
    this.codigo = [];
    this.producto = [];

    this.servicio.listarProductoTermhttp().subscribe(
      response =>{
        this.stateGroupOptions = this.forma.get('producto_pedido')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup(value))
                                );
                  for (const i in response) {
                    this.producto.push(response[i].cod_producto+'-'+response[i].des_producto);
                    this.codigo.push(response[i].cod_producto);
                  }
                  console.log(this.producto);
                  this.stateGroups= [{
                    letter: 'Productos',
                    names: this.producto,
                    code: this.producto
                  }];
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  /* listarClienteEspecial(){
    this.cliente_especial = [];
    this.producto = [];
    this.servicio.listarClienteEspecialhttp().subscribe(
      response =>{
        this.stateGroupOptions2 = this.forma.get('cod_cliente')!.valueChanges
                                .pipe(
                                  startWith(''),
                                  map(value => this._filterGroup2(value))
                                );
                  for (const i in response) {
                    this.cliente_especial.push(response[i].cod_cliente+' '+response[i].des_cliente);
                  }
                  console.log(this.producto);
                  this.stateGroups2= [{
                    letter: 'Productos',
                    names: this.cliente_especial,
                    code: this.cliente_especial
                  }];
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  } */

  listarClienteEspecial(termino:any):void{
    try {
      this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
        width: '15%',
        height:'25%',
        data: {
                accion:this.accion},
        disableClose: true,
        panelClass: 'spinner',
        backdropClass: "bdrop"
      });
      setTimeout(() => {
        this.dialogSpin.close();
        this.cliente_especial = [];
        this.producto = [];
        console.log(termino.value);
        var valor = {valor:termino.value}
        this.cod_cliente.nativeElement.value = '';
        this.producto_pedido.nativeElement.value = '';
        this.cantidad_existencia.nativeElement.value = '0';
        this.cantidad_bultos.nativeElement.value = '0';
        this.unidad_manejo.nativeElement.value = '0';
        this.num.nativeElement.value = '0';
        this.servicio.listarClienteEspecialhttp(valor).subscribe(
          response =>{
            this.stateGroupOptions2 = this.forma.get('cod_cliente')!.valueChanges
                                    .pipe(
                                      startWith(''),
                                      map(value => this._filterGroup2(value))
                                    );
                      for (const i in response) {
                        this.cliente_especial.push(response[i].cod_cliente+' '+response[i].des_cliente);
                      }
                      console.log(this.producto);
                      this.stateGroups2= [{
                        letter: 'Clientes',
                        names: this.cliente_especial,
                        code: this.cliente_especial
                      }];
          },
          error =>{
            console.log(<any>error);
            this.snackBar.open(<any>error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,});
          }
        );
      
      }, 500);
    } catch (error) {
      this.dialogSpin.close();
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
  }

  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRefp.close(this.forma.value);
    }else{
    console.log(this.forma.value);
    this.dialogRefp.close(this.forma.value);
    }

  }

formulario():void{
  this.ELEMENT_DATA = [];
  this.cant = false;
  this.displayedColumns = [

    'cod_producto',
    'estatus_fiscal',
    'des_producto',
    'cant_producto',
    'remov_elem'
   ];
/* this.dataSource = this.ELEMENT_DATA; */
this.dataSource1 = new MatTableDataSource<PeriodicElementfacturas>(this.ELEMENT_DATA);
}
applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource1.filter = filterValue.trim().toLowerCase();
}

  addElement() {
    let arry = [];
    let arry2 = [];

    console.log(this.forma);
    if(parseInt(this.num.nativeElement.value) > 0 &&
                 this.producto_pedido.nativeElement.value !== '' &&
                 this.cod_cliente.nativeElement.value !== ''){
      var prod = this.producto_pedido.nativeElement.value.split('-')
      var valor:any = {cod_producto:prod[0]}
      this.servicio.buscarPRODUCTOBttp(valor).subscribe(
        response =>{
          console.log(response);

           

          if (this.ELEMENT_DATA.length == 0) {
            this.codigo = [];
            this.producto = [];
            this.stateGroups2 =[];

            this.listarproductot();
            this.ELEMENT_DATA.push({
              cod_producto:response[0].cod_producto,
              des_producto:response[0].des_producto,
              estatus_fiscal:response[0].estatus_fiscal,
              cant_producto:this.num.nativeElement.value,
            });
            this.producto_pedido.nativeElement.value = '';
           this.cantidad_existencia.nativeElement.value = '0';
           this.cantidad_bultos.nativeElement.value = '0';
           this.unidad_manejo.nativeElement.value = '0';
           this.num.nativeElement.value = '0';
            this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);

          }else{
            console.log(response);
              for (const i in this.ELEMENT_DATA) {
               arry.push(this.ELEMENT_DATA[i].cod_producto);
              }
              let index = arry.includes(response[0].cod_producto);

              if (!index) {

                this.codigo = [];
                this.producto = [];
                  this.listarproductot();
                  this.ELEMENT_DATA.push({
                    cod_producto:response[0].cod_producto,
                    des_producto:response[0].des_producto,
                    estatus_fiscal:response[0].estatus_fiscal,
                    cant_producto:this.num.nativeElement.value,
                  });
                  this.producto_pedido.nativeElement.value = '';
                  this.cantidad_existencia.nativeElement.value = '0';
                  this.cantidad_bultos.nativeElement.value = '0';
                  this.unidad_manejo.nativeElement.value = '0';
                  this.num.nativeElement.value = '0';
                  this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);

              }else{
                this.stateGroups2 =[];
                this.lotepro = [];
                this.codigo = [];
                this.producto = [];
                this.listarproductot();
                this.producto_pedido.nativeElement.value = '';
                this.cantidad_existencia.nativeElement.value = '0';
                this.cantidad_bultos.nativeElement.value = '0';
                this.unidad_manejo.nativeElement.value = '0';
                this.num.nativeElement.value = '0';
                  this.snackBar.open('El producto ya fue seleccionado', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,});
              }
          }
        },
        error =>{
          console.log(<any>error.error.message);
          this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,});
        }
      );

      console.log(this.ELEMENT_DATA);
    }else{
      if (parseInt(this.num.nativeElement.value) <= 0 || isNaN(this.num.nativeElement.value) || this.num.nativeElement.value === "") {
        this.snackBar.open('La cantidad debe ser mayor a 0', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }

    if (this.producto_pedido.nativeElement.value === '') {
      this.snackBar.open('Debe seleccionar el producto', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    if (this.cod_cliente.nativeElement.value === '') {
      this.snackBar.open('Debe seleccionar un cliente', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }
    }
  }

  remove(val:any){
   let resta = this.dataSource1.data.splice(this.ELEMENT_DATA.indexOf(val), 1);
    this.table.renderRows();
  }
  buscarproductot(xvalor:any){
    console.log(xvalor);
    var valor:any = {valor:xvalor}
    this.servicio.buscarPRODUCTOTparamhttp(valor).subscribe(
      response =>{
        this.producto = response;
      },
      error =>{
        console.log(<any>error.error.message);
        this.snackBar.open(<any>error.error.message, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    );
  }

  modalrespuesta(){
    if (this.ELEMENT_DATA.length === 0  || this.cod_cliente.nativeElement.value === "") {
      this.snackBar.open('Debe seleccionar todos los datos solicitados', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }else{
      this.accion = "enviarprecios";
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
        maxWidth: '100vw',
        height:'100vh',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != 'cerrar'){

          console.log(this.ELEMENT_DATA);
          let cod_usuario = localStorage.getItem('cod_usuario');
          let cod = this.forma.value.cod_cliente.split(' ');
          console.log(cod);

          this.forma.value.cod_cliente = cod[0];
          this.forma.value.cod_usuario = cod_usuario;
          this.forma.value.producto_pedido = this.ELEMENT_DATA;
          var codigo = {cod_cliente:cod[0]}
          this.servicio.buscarClienteConsigttp(codigo).subscribe(
            response=>{
              console.log(response);
                this.nom_cliente = response[0].des_cliente;
                this.forma.value.des_cliente = this.nom_cliente;

                          console.log(this.forma.value);
                  this.dialogRefp.close(this.forma.value);
            },
            error=>{
              this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,});
            }
          );
        }
      });
    }
  }

  cantidad_productos() {
    try {
      var suma = 0;
      var matriz = [];
      for (const i in this.ELEMENT_DATA) {
        matriz.push(this.ELEMENT_DATA[i].cant_producto)
      }
      matriz.forEach (function(numero){
             suma += parseInt(numero.replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace('.','')
             .replace(',','.')) ;
         });

       return suma;
    } catch (error) {
      this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,});
    }

    }
    cantidad_item() {
      try {
        return this.ELEMENT_DATA.length;
      } catch (error) {
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }

    }

    cerrarmodal(): void {
      this.accion = 'salir';
      var dialogRef = this.dialog.open(FormmMsgComponent, {
        width: '100vw',
            maxWidth: '100vw',
            height:'100%',
        data: {id:'',
              accion:this.accion},
        disableClose: true,
        panelClass: 'pedidos_modal',
        backdropClass: "bdrop"
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != 'cerrar') {
          this.cerrar();
        }
      });
    }

    cerrar():void{
      try {
        this.dialogSpin = this.dialog.open(FormSpinnerComponent, {
          width: '15%',
          height:'25%',
          data: {
                  accion:this.accion},
          disableClose: true,
          panelClass: 'spinner',
          backdropClass: "bdrop"
        });
        setTimeout(() => {
          this.dialogSpin.close();
          this.dialogRefp.close('cerrar');
        }, 500);
      } catch (error) {
        this.dialogSpin.close();
        this.snackBar.open(error, 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,});
      }
    }

  formatobs(valor:any){
    console.log(valor.value);
    this.tasa_precio.nativeElement.value = parseInt(valor.value).toFixed(2).replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1,$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    return valor;
  }

  format(valor:any)
  {
  var num = valor.value.replace(/\D/g,'');
  if(!isNaN(num)){
  num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
  num = num.split('').reverse().join('').replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  valor.value = num;

  let xvalor = (parseFloat(this.precio_base.nativeElement.value.replace('.', '')
  .replace('.', '')
  .replace(',', '.')) * (parseFloat(this.tasa_precio.nativeElement.value.replace('.', '')
  .replace('.', '')
  .replace(',', '.'))));

    console.log(xvalor);

  this.precio_bs.nativeElement.value = xvalor.toFixed(2).replace(/\D/g, "")
  .replace(/([0-9])([0-9]{2})$/, '$1,$2')
  .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
  }
  else{ this.snackBar.open('Solo numeros por favor', 'Cerrar',{duration:15000,horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,});
    valor.value = valor.value.replace(/[^\d\.]*/g,'');
  }
  }
}
