<div class="fadeIn2 animated fast" style="height: 100%; width: 100%;">

    <div class="row " style="height: 8%; ">
        <nav class="col-md-12 col-sm-12 col-xs-12 navbar navbar-expand-md navbar-dark  nav nav_componente">

            <div class=" navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li *ngIf="read" class="nav-item"><button matTooltip="ACTUALIZAR" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="accent" style="height: 35px; " (click)='listarReportes("actualizar")'><mat-icon >sync</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="ESTADISTICAS" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="primary" style="height: 35px; " (click)='en_construccion()'><mat-icon >bar_chart</mat-icon></button></li>
                    <li *ngIf="read" class="nav-item"><button matTooltip="SEGUIMIENTO" class="my-2 my-sm-0 mr-sm-2" mat-raised-button color="" style="height: 35px; " (click)='en_construccion()'><mat-icon >search</mat-icon></button></li>
                    <li *ngIf="create" class="nav-item"><button matTooltip="CREAR REPORTE" class="my-2 my-sm-0 mr-sm-2" mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(236, 160, 19);" (click)='update_insert_data("","reportes")'><mat-icon >add</mat-icon></button></li>

                </ul>
                <span class="navbar-brand" style=" font-size: 14px; color: rgb(255, 255, 255); "><b style="border-bottom: 1px solid rgb(255, 255, 255);">CONSULTAR REPORTES </b></span>
                <div class="">
                    <input class="form-control me-2" type="text" #cod_lista1 placeholder="Buscar:" autocomplete="off" (keyup)="applyFilter($event)">
                </div>
            </div>
        </nav>
    </div>

    <div class="row altura_table">
        <div class="col-md-12 px-0  border h-100 table_overflow_color">
            <table mat-table [dataSource]="dataSource" class="table-primary text-center table-striped table-condensed table-hover">

                <ng-container matColumnDef="cod_deposito">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Codigo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_deposito}} </td>

                </ng-container>

                <ng-container matColumnDef="des_deposito">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Deposito</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_deposito}} </td>

                </ng-container>

                <ng-container matColumnDef="descrip_deposito">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Descripción</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.descrip_deposito}} </td>

                </ng-container>

                <ng-container matColumnDef="tipo_manejo">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Manejo</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_manejo}} </td>

                </ng-container>

                <ng-container matColumnDef="tipo_deposito">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Tipo </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.tipo_deposito}} </td>

                </ng-container>

                <ng-container matColumnDef="cod_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Id</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.cod_usuario}} </td>

                </ng-container>

                <ng-container matColumnDef="des_usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Usuario </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.des_usuario}} </td>

                </ng-container>
                <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Estatus </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.estatus}} </td>

                </ng-container>
                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Fecha </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.fecha}} </td>

                </ng-container>
                <ng-container matColumnDef="hora">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Hora </strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" class="td_style"> {{element.hora}} </td>

                </ng-container>

                <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="text-center  header_paginator_table">
                        <h5><strong>Imprimir</strong></h5>
                    </th>
                    <td mat-cell *matCellDef="let element" style="padding: 1px; font-size: 12px;" class="td_style">
                        <button class="rounded-pill" *ngIf="special" matTooltip="IMPRIMIR REPORTE" mat-raised-button (click)='descargar_reporte(element)' style="border: outset 3px rgb(77, 123, 184); height: 35px; color:rgb(255, 255, 255); background-color: rgb(32, 98, 184);">
                            <mat-icon >printer</mat-icon>
                        </button>
                        <button class="rounded-pill" *ngIf="!special" matTooltip="SIN PERMISOS" (click)='sin_permiso()' mat-raised-button style="height: 35px; color:rgb(255, 255, 255); background-color: rgb(0, 0, 0);">
                            <mat-icon >printer</mat-icon>
                        </button>
                    </td>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="text-center"></tr>

            </table>
            <mat-paginator class="w-100 mat-paginator-sticky border" [pageSizeOptions]="[25,50,75,100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>