import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { parse } from 'querystring';
import { PeriodicElementfacturas } from '../../models/interfacefacturas';
import { nkServices } from '../../../shared/servicios/app.nkservicios.services';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'; 
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ary } from 'lodash';
import { FormmMsgComponent } from '../formm-msg/formm-msg.component';

export interface StateGroup {
  letter: any; 
  names: any;
  code: any
}


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};
export const filter = (opt: string[], value: string): string[] => {
  const filterValue1 = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue1) === 0);
};

@Component({
  selector: 'app-form-materia-prima-preparada',
  templateUrl: './form-materia-prima-preparada.component.html',
  styleUrls: ['./form-materia-prima-preparada.component.css']
})
export class FormMateriaPrimaPreparadaComponent implements OnInit {

  
hide = true;
stateGroups: StateGroup[];
proveedor:any[];
arry: Observable<StateGroup[]>;
nombre:any;
forma:FormGroup;
accion:any;
accion2:any;
unidad:any;
email = new FormControl('', [Validators.required, Validators.email]);
date = new FormControl(new Date());
date2 = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
ELEMENT_DATA:any[]=[];
displayedColumns:string[];
dataSource1=new MatTableDataSource<any>();
horizontalPosition: MatSnackBarHorizontalPosition = 'start';
verticalPosition: MatSnackBarVerticalPosition = 'bottom';

constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private servicio:nkServices,private formb:FormBuilder, fb:FormBuilder,
  public dialogRef: MatDialogRef<FormMateriaPrimaPreparadaComponent>,
  ///en el injectable llega la informacion del metodo padre en data
  @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data, 'primero');
     
    this.accion = data.accion;
    this.forma = fb.group({
      'id_producto_preparado':data.id_producto_preparado,
      'cod_producto':[data.cod_producto,[Validators.required,Validators.maxLength(6)]],
      'des_producto':[data.des_producto,[Validators.required,Validators.maxLength(45)]],
      'estatus_producto':[data.estatus_producto,[Validators.required]],
      'unidad_medida_producto':[data.unidad_medida_producto,[Validators.required]],
      
    });
  }

  

  ngOnInit(): void {
  }
  
  actualizardatos(res:any){

    if(res == 'actualizar'){
      console.log(this.forma.value);
      this.dialogRef.close(this.forma.value);
    }else{
    
    console.log(this.forma.value);
    this.dialogRef.close(this.forma.value);
    }
    
  }

  modalrespuesta(){
 
    this.accion2 = "enviarmppp";
    var dialogRef = this.dialog.open(FormmMsgComponent, {
      width: '25%',
      height:'25%',
      data: {id:'', 
            accion:this.accion2},
      disableClose: true,
      panelClass: 'pedidos_modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != 'cerrar'){
        this.dialogRef.close(this.forma.value);
      } 
    });
  
  }
  
  cerrarmodal(): void {
    this.dialogRef.close('cerrar');
  }


}






